import React, { createRef, useEffect, useState } from "react";
import FeedMessage from "./FeedMessage";
import $ from 'jquery'
import ReactDynamicModal from "react-draggable-resizable-modal";
import SkeletonMessageFeed from "../../../loaders/SkeletonMessageFeed";
import SkeletonMessage from "../../../loaders/SkeletonMessage";
import moment from "moment";
import { TABLE_DATE_FORMAT } from "../../../utils/Constants";
import { GetSupportTeamService } from "../../../services/PostsAndFeedService";
import { useRef } from "react";

const FeedMessages = (props) => {

  const [videoArr, setVideoArr] = useState([]);
  const [currentVideo, setCurrentVideo] = useState('');
  const [modalOpen, setModalOpen] = useState(false)
  const [loader, setLoader] = useState(true);
  const [dateArr, setDateArr] = useState([]);
  const [noOfLoader, setNoOfLoader] = useState(2)
  const [hideList, setHideList] = useState("");
  const [team, setTeam] = useState([]);
  const [teamObj, setTeamObj] = useState([]);

  const closeModal = () => {
    setModalOpen(false);
    setCurrentVideo('')
  };

  useEffect(() => {
    setLoader(true)
    dateMethod();
    const t = setTimeout(() => {
      setLoader(false)
    }, 1000);

    return () => {clearTimeout(t)}
  }, [props.feeds.length])
  

  // useEffect(() => { setLoader(props.loadMore); setNoOfLoader(1); }, [props.loadMore == true])

  const dateMethod = () => {
    let tempArr = []
    props.feeds.map((item) => {
      let dateOnly = moment(item.updated_at, "Do MMMM YYYY, h:mm:ss a").format(TABLE_DATE_FORMAT)
      if (!tempArr.includes(dateOnly)) {
        tempArr.push(dateOnly)
      } else {
        tempArr.push("")
      }
      // if(item.user==null){
      //   item.user= item.adminuser
      //   item.user_id= item.admin_id
      // }
    })
    setDateArr(tempArr)
  }

  // console.log(props.feeds,"feeds")

  useEffect(() => {
    let mounted = true
    GetSupportTeamService({ room_id: props.roomId })
      .then((res) => {
        if (mounted) {
          setTeam(res.data.team);
          if (res.data.team) {
            let tempObj = Object.keys(res.data.team).map(item => {
              return {
                ...res.data.team[item], role: item
              }
            });
          setTeamObj(tempObj)
          }
        }
      })
      .catch((error) => { });

    return function cleanup() {
      mounted = false
    }
  }, []);

  // const [openId, setOpenId] = useState(null);
  // useEffect(() => {
  //   const clickOutsideHandle = (event) => {
  //     console.log("<<<<<<<<<event started!!>>>>>>>>>>>");
  //     const replyElement = document.querySelector(".reply-inserted"); // Use querySelector instead of getElementsByClassName to get only one element
  //     if (replyElement && ![
  //       "rdw-embedded-modal-btn",
  //       "rdw-editor-toolbar",
  //       "btn-input-action",
  //       "action-input-content",
  //       "rdw-option-wrapper",
  //       "filepond--image-preview-wrapper",
  //       "filepond--drop-label",
  //       "filepond--file-action-button",
  //       "rdw-editor-main",
  //       "rdw-suggestion-option",
  //       "edit_main_thread",
  //       "public-DraftStyleDefault-block",
  //       "post-input-content",
  //       "rdw-embedded-modal-link-input",
  //       "hold-show",
  //       "swal2-cancel",
  //       "swal2-confirm"
  //     ].some(cls => (event.target.classList.contains(cls) || event.target.parentNode.classList.contains(cls)))) {
  //       $(".reply-" + openId).show();
  //       $(".new-reply-box").hide().removeClass("reply-inserted");
  //     }
  //     const clickedElemClass = event.target.classList[0];
  //     if (clickedElemClass === "reply-static") {
  //       const id = event.target.classList[1];
  //       $(".reply-" + id).hide();
  //       $(".reply-box-toggle-" + id).show().addClass("reply-inserted");
  //       setOpenId(id);
  //     }
  //     console.log("<<<<<<<<<event ended!!>>>>>>>>>>>");
  //   };
  //   $("body").on("mousedown", clickOutsideHandle);
  //   return () => {
  //     $("body").off("mousedown", clickOutsideHandle);
  //   };
  // }, [openId]);

  const childRef = useRef(props.feeds.map((feed)=>createRef(feed.id)));
  let openId;
  const clickOutsideHandle = (event) =>{            
    const clickedElemClass = event.target.classList[0];
    let id;
    if (clickedElemClass === "reply-static") {
      id = event.target.classList[1];           
    }
    if(id!==null && id!==undefined){
      childRef.current[id].handleClickOutside(event);
      // setOpenId(id);
      openId = id;
    }else
    if(openId!==null && openId!==undefined){
      childRef.current[openId].handleClickOutside(event);
    }
  }
  useEffect(() => {
    $("body").on("mousedown", clickOutsideHandle);
    return () => {
      $("body").off("mousedown", clickOutsideHandle);
    };
  }, []);
  

  return (
    <>
      <div className="chat-msg-container ps-fd-card-body chat-space-lt" id='contenttwo'>
        {(props.loadMore || loader) && <SkeletonMessageFeed count={noOfLoader} />}
        {props.feeds.map((feed, index) => {
          return (
            <FeedMessage
              ind={index}
              islastIndex={(props.feeds.length-1)==index}
              key={`${feed.id}-${feed.room_id}-${index}`}
              feed={feed}
              showDate={dateArr[index] ? true : false}
              online={props.online}
              taggingUsers={props.taggingUsers}
              setCurrentVideo={(link) => { setCurrentVideo(link); setModalOpen(true); }}
              updateMsg={props.updateMsg}
              setOpenReplyId={props.setOpenReplyId}
              openReplyId={props.openReplyId}
              setShowHideEdit={props.setShowHideEdit}
              showHideEditArr={props.showHideEditArr}
              setShowHidePostEdit={props.setShowHidePostEdit}
              showHidePostEdit={props.showHidePostEdit}
              resetEditor={props.resetEditor}
              deleteHandle={props.deleteHandle}
              likeDislikeHandle={props.likeDislikeHandle}
              updatePostImportant={props.updatePostImportant}
              sendClick={props.sendClick}
              deletedFile={props.deletedFile}
              reduceCount={props.reduceCount}
              hideList={hideList}
              setHideList={setHideList}
              updateReadMessages={props.updateReadMessages}
              roomId={props.roomId}
              teamObj={teamObj}
              ref={elem => (childRef.current[index]=elem)}
            />
          );
        })}
        <div id='content' />
      </div>

      <div className="modal-update iframe_modal_full">
        <ReactDynamicModal
          data-backdrop="static"
          initWidth={640}
          initHeight={440}
          onRequestClose={closeModal}
          isOpen={modalOpen}

          data={
            <div className="body">
              {currentVideo != '' ?
                <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" src={`https://${currentVideo}`} frameborder="0"></iframe>
                :
                null
              }
            </div>
          }
        />
      </div>
    </>
  );
};

export default React.memo(FeedMessages);

// import React, { useEffect } from "react";
// import FeedMessage from "./FeedMessage";

// const FeedMessages = (props) => {
//   useEffect(() => {
//     // console.log("mounted feeds");


//     console.log("--------rahul--------")
//     console.log(props)
//   }, []);
//   return (
//     <>
//       <div className="ps-fd-card-body">
//         {props.feeds.map((feed) => {
//           return (
//             <FeedMessage
//               key={`${feed.id}-${feed.room_id}`}
//               feed={feed}
//               online={props.online}
//               taggingUsers={props.taggingUsers}
//             />
//           );
//         })}
//       </div>

//       {/* <div className="card-body post-wrap-body p-0">
        
//       </div> */}
//     </>
//   );
// };

// export default React.memo(FeedMessages);
