const initialState = {
  userstatus: {},
};

const userstatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_STATUS":
      state.userstatus = action.payload;
      return { userstatus: state.userstatus };
    default:
      return state;
  }
};

export default userstatusReducer;
