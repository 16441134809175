import React, { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../../store/actions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MOBILE_REGEX, NAME_REGEX } from "../../../utils/Constants";
import { IMAGE_URL } from "../../../utils/Constants";
import Swal from "sweetalert2";
import { setImage, setUserStatus } from "../../../store/actions";
import { MainContext } from "../../../contexts/MainContext";
import { ProfileImageDetailService, RemoveProfileImage } from "../../../services/ProfileService";
import { UpdateUserDetail, UpdateUserStatus, GetListOfUser, GetUserDetail, GetUserRoles, AllBrandList, AllBrandListCancelToken, GetUserRolesCancelToken, GetUserDetailCancelToken, GetListOfUserCancelToken } from "../../../services/UserService";
import cog from "../../../assets/images/myProfile.svg";
import Select from "react-select";
import { Day, Month, Year, Location, Company, Title, Status, Active } from "../../systemadministration/DataReactSelect";
import { useLocation, useParams } from "react-router-dom";
import { data } from "jquery";
import hasPermission from "../../../utils/hasMultiplePermission";
import PermissionsGate from "../../../utils/permissionGate";
import axios from "axios";

const AboutYou = () => {

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userDataId = useSelector((state) => state.user.UserID);
  const [user, setUser] = useState({});
  const { state, setState, status, setStatus } = useContext(MainContext);
  const initials = user && user.Firstname && user.Lastname ? user.Firstname[0] + user.Lastname[0] : "";
  const [userData, setUserData] = useState([]);
  const { id, tab } = useParams();
  const [userRole, setUserRole] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  // console.log("userrrrrrrrrrr---> ", user);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const fetchData = async () => {
      try {
      const res = await AllBrandListCancelToken(dropdownSource.token);
      let companyData=[];
      res.data.allBrandList.map((data)=>{
        companyData.push({label:data.brand_name,value:data.id,contact:data.contact})
      })
      setBrandList(companyData);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    fetchData();
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);
  
  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const fetchData = async () => {
      try {
      const res = await GetUserRolesCancelToken(dropdownSource.token);
      let user = [];
      let data = res?.data;
      data.map((val) => {
        user.push({ value: val.role_id, label: val.role_name })
      })
      setUserRole(user)
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    if (tab == "open") {
    fetchData();
    }
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    let userDetails = localStorage.getItem("state");
    const fetchData = async () => {
      try {
      const res = await GetUserDetailCancelToken({ UserID: pathname == "/profile" ? JSON.parse(userDetails).user?.UserID : id },dropdownSource.token);
       setUser(res && res.data ? res.data : user);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    if (id || pathname == "/profile") {
    fetchData();
    }
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const fetchData = async () => {
      try {
      const res = await GetListOfUserCancelToken({},dropdownSource.token);
      let x = { value: "", label: "" };
      x = res.data.map((data) => {
        return { ...x, value: `${data.UserID}`, label: `${data.Firstname + " " + data.Lastname}` };
      });
      setUserData(x);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    fetchData();
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Firstname: user?.Firstname,
      Lastname: user?.Lastname,
      Mobile: user?.Mobile ? user?.Mobile : "",
      // role_id: user.role_id,
      // Email: user.Email,
      nTitle: user?.nTitle,
      about_me: user?.about_me ? user.about_me : "",
      job_title: user?.job_title ? user.job_title : "",
      Birth_year: user?.Birth_year ? user?.Birth_year : "",
      Birth_month: user?.Birth_month ? user?.Birth_month : "",
      Birth_day: user?.Birth_day ? user?.Birth_day : "",
      Company: brandList[brandList?.findIndex(val=>val?.label === user?.Company || val?.value == user?.Company)]?.value,
      WorkNumber: user?.WorkNumber ? user?.WorkNumber : "",
      Location: user?.Location ? user?.Location : "",
      Report_to: user?.Report_to ? user?.Report_to : "",
      Report_to_Name: user?.report_user_Firstname ? user.report_user_Firstname + " " + user.report_user_Lastname : "",
      is_active: user.is_active !== null || user.is_active !== undefined ? user.is_active : "0",
      role_id: user?.role_id ? user?.role_id : ""
      // about_me: user.about_me,
    },
    validationSchema: Yup.object({
      nTitle: Yup.string().required("Title is required ").nullable(),
      Firstname: Yup.string().matches(NAME_REGEX, "Please enter valid first name").trim("The first name cannot include leading and trailing spaces").required("First Name is required"),
      Lastname: Yup.string().matches(NAME_REGEX, "Please enter valid last name").trim("The last name cannot include leading and trailing spaces").required("Last Name is required"),
      Mobile: Yup.string()
        .matches(MOBILE_REGEX, "Phone number should be 0-9 of 10 digits with country code")
        // .required("Mobile is required")
        .min(10, "Phone number should be 0-9 of 10 digits with country code")
        .max(16, "Phone number should be 0-9 of 10 digits with country code"),
      // role_id: Yup.string(),
      // status: Yup.string().required(),
      // Email: Yup.string().email("Invalid Email address").required("Email is required"),
      // title: Yup.string().required(),
      about_me: Yup.string(),
      job_title: Yup.string().trim("The job title cannot include leading and trailing spaces"),
      Birth_year: Yup.string().nullable(),
      Birth_month: Yup.string().nullable(),
      Birth_day: Yup.string().nullable(),
      // .required("Year is required")
      // .required("Month is required")
      // .required("Day is required")
      Company: Yup.string().required("Company is required").nullable(),
      Location: Yup.string(),
      WorkNumber: Yup.string().required().nullable(),
      Report_to: Yup.string(),
      is_active: Yup.string().required("Status is required"),
    }),
    onSubmit: (values) => {
      const body = {
        ...values,
        UserID: user.UserID,
        title: user.title,
        role_id: values?.role_id,
      };
      UpdateUserDetail(body)
        .then((res) => {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => { });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error?.response?.data?.message,
          }).then((result) => { });
        });
    },
  });

  // const [status, setStatus] = useState("online");

  const handleStatus = (value) => {
    // setStatus(e.target.value);
    setStatus(value);
    // dispatch(setUserStatus(e.target.value));
    dispatch(setUserStatus(value));
    const body = {
      UserID: user.UserID,
      // Status: e.target.value,
      Status: value,
    };
    UpdateUserStatus(body)
      .then((res) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        }).then((result) => { });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        }).then((result) => { });
      });
  };

  const handleProfileImage = (e) => {
    if (e.target.files.length) {
      const formInputs = new FormData();
      formInputs.append("photo", e.target.files[0]);
      formInputs.append("UserID", user.UserID);
      Swal.fire({
        title: "File Uploading....",
        onOpen: function () {
          Swal.showLoading();
        },
      });
      ProfileImageDetailService(formInputs)
        .then((res) => {
          Swal.close();
          dispatch(setImage(res.data.picture_me));
          setState(res.data.picture_me);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then((result) => { });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response.data.message,
          }).then((result) => { });
        });
    }
  };

  const removeProfileImage = () => {
    RemoveProfileImage({ UserID: user.UserID })
      .then((res) => {
        Swal.close();
        dispatch(setImage(""));
        setState("");
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Removed successfully",
        }).then((result) => { });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        }).then((result) => { });
      });
  };
  return (
    <>
      <div className="card card-profile-info-card">
        <form></form>

        <div className={`card-body `}>
          <div className="card-body-inr">
            <div className="profile-box-2 mb-4">
              <article>
                <figure>
                  {state && user.picture_me !== null ? <img src={`${IMAGE_URL}/${!id ? state && state.replace("/home/myaie/public_html/", "") : user.picture_me && user.picture_me.replace("/home/myaie/public_html/", "")}`} alt="Your AIE Profile Photo" /> : initials}

                  <span className={`profile-box-2-status ${status}`}>
                    <i className="fas fa-circle"></i>
                  </span>
                  <div className="upload-drop-down-wrap">
                    <div className="dropdown">
                      <button title="Profile Picture" className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fal fa-ellipsis-v"></i>
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <ul className="list-unstyled">
                          <li title="Upload Profile Picture">
                            <div className="upload-image-option">
                              <input type="file" name="avtar" accept="image/*" onChange={handleProfileImage} />
                              <i className="fal fa-camera"></i>Upload Profile Picture
                            </div>
                          </li>
                          <li title="Remove Profile Picture">
                            <button type="button" onClick={removeProfileImage}>
                              <i className="fal fa-trash-alt"></i>Remove Profile Picture
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </figure>
                <figcaption>
                  <h4>{user?.Firstname + " " + user?.Lastname}</h4>

                  <Select
                    className="form-control custom-select-box custom-profile-page"
                    name="Status"
                    value={status}
                    // onChange={(value) => formik.setFieldValue("Report_to", value.value)}
                    onChange={(value) => handleStatus(value.value)}
                    options={Status}
                    maxMenuHeight={175}
                    placeholder={status === "Donotdisturb" ? "Do not disturb" : status}
                  />
                  {/* <div className="status-select-form">
                    <label>Status</label>
                    <select className="form-control form-control-status  ml-3" placeholder="Status" value={status} onChange={handleStatus}>
                      <option value="Online">Online</option>
                      <option value="Offline">Offline</option>
                      <option value="Donotdisturb">Do Not Disturb</option>
                    </select>
                  </div> */}
                </figcaption>
              </article>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="form-icon-group mb-4">
                    <Select
                      className={"form-control custom-select-box" + (formik.errors.nTitle && formik.touched.nTitle ? " is-invalid" : "")}
                      name="nTitle"
                      value={Title.filter((val)=>val.value === formik.values.nTitle)}
                      onChange={(value) => {
                        if(value){
                          formik.setFieldValue("nTitle", value.value)
                        } else {
                          formik.setFieldValue("nTitle", "")
                        }
                      }}
                      onBlur={formik.handleBlur}
                      options={Title}
                      maxMenuHeight={175}
                      placeholder={formik.values.nTitle ? formik.values.nTitle : "Title *"}
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="form-icon-group mb-4">
                    <input
                      type="text"
                      className={"form-control" + (formik.errors.Firstname && formik.touched.Firstname ? " is-invalid" : "")}
                      name="Firstname"
                      placeholder="Name *"
                      title="Name"
                      value={formik.values.Firstname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.Firstname && formik.touched.Firstname ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="form-icon-group mb-4">
                    <input
                      type="text"
                      className={"form-control" + (formik.errors.Lastname && formik.touched.Lastname ? " is-invalid" : "")}
                      name="Lastname"
                      placeholder="Surname *"
                      title="Surname"
                      value={formik.values.Lastname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.Lastname && formik.touched.Lastname ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="form-icon-group mb-4">
                    <input
                      type="text"
                      className={"form-control" + (formik.errors.Mobile && formik.touched.Mobile ? " is-invalid" : "")}
                      name="Mobile"
                      title="Mobile"
                      placeholder="Enter mobile number"
                      value={formik.values.Mobile.replace(" ", "")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.Mobile && formik.touched.Mobile ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="form-icon-group mb-4">
                    <input
                      type="text"
                      className={"form-control" + (formik.errors.WorkNumber && formik.touched.WorkNumber ? " is-invalid" : "")}
                      placeholder="Work Number"
                      title="Work Number"
                      name="WorkNumber"
                      value={formik.values.WorkNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.WorkNumber && formik.touched.WorkNumber ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="form-icon-group mb-4">
                    <div className="form-grp">
                      <Select
                        className="form-control custom-select-box"
                        name="Birth_year"
                        value={Year.filter(val=>val.value === formik.values.Birth_year)}
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("Birth_year", value.value)
                          } else {
                            formik.setFieldValue("Birth_year", "")
                          }
                        }}
                        onBlur={formik.handleBlur}
                        options={Year}
                        maxMenuHeight={175}
                        placeholder={formik.values.Birth_year ? formik.values.Birth_year : "Year"}
                        isClearable
                      />
                      <Select
                        className="form-control custom-select-box"
                        name="Birth_month"
                        value={Month.filter(val=>val.value === formik.values.Birth_month)}
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("Birth_month", value.value)
                          } else {
                            formik.setFieldValue("Birth_month", "")
                          }
                        }}
                        onBlur={formik.handleBlur}
                        options={Month}
                        maxMenuHeight={175}
                        placeholder={formik.values.Birth_month ? formik.values.Birth_month : "Month"}
                        isClearable
                      />

                      <Select
                        className="form-control custom-select-box"
                        name="Birth_day"
                        value={Day.filter(val=>val.value === formik.values.Birth_day)}
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("Birth_day", value.value)
                          } else {
                            formik.setFieldValue("Birth_day", "")
                          }
                        }}
                        onBlur={formik.handleBlur}
                        options={Day}
                        maxMenuHeight={175}
                        placeholder={formik.values.Birth_day ? formik.values.Birth_day : "Day"}
                        isClearable
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-4">
                  <div className="form-icon-group mb-4">
                    <textarea
                      className={"form-control" + (formik.errors.about_me && formik.touched.about_me ? " is-invalid" : "")}
                      placeholder="About Me"
                      title="About Me"
                      name="about_me"
                      rows="9"
                      value={formik.values.about_me}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>
                    {formik.errors.about_me && formik.touched.about_me ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-md-6">    
                      <div className="form-icon-group mb-4">
                        <Select
                          className={"form-control custom-select-box" + (formik.errors.Company && formik.touched.Company ? " is-invalid" : "")}
                          name="Company"
                          value={brandList.filter((brand)=>{
                            return brand.value==formik.values.Company
                          })}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("Company", value.value)
                              formik.setFieldValue("WorkNumber", value.contact)
                            } else {
                              formik.setFieldValue("Company", "")
                              formik.setFieldValue("WorkNumber", "")
                            }
                          }}
                          onBlur={formik.handleBlur}
                          options={brandList}
                          maxMenuHeight={175}
                          placeholder={formik.values.Company ? formik.values.Company : "Search *"}
                          isClearable
                        />
                        {formik.errors.Company && formik.touched.Company ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-icon-group mb-4" title="Reports To">
                        <Select
                          className="form-control custom-select-box"
                          name="Report_to"
                          value={userData.filter(val=>val.label === formik.values.Report_to_Name)}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("Report_to", value.value);
                              formik.setFieldValue("Report_to_Name", value.label);
                            } else {
                              formik.setFieldValue("Report_to", "");
                              formik.setFieldValue("Report_to_Name", "");
                            }
                          }}
                          onBlur={formik.handleBlur}
                          options={userData}
                          maxMenuHeight={175}
                          placeholder={formik.values.Report_to_Name ? formik.values.Report_to_Name : "Search"}
                          isClearable
                        />
                        {formik.errors.Report_to && formik.touched.Report_to ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">   
                      <div className="form-icon-group mb-4">
                        <input
                          type="text"
                          className={"form-control" + (formik.errors.job_title && formik.touched.job_title ? " is-invalid" : "")}
                          name="job_title"
                          placeholder="Job title"
                          title="Job Title"
                          value={formik.values.job_title}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.job_title && formik.touched.job_title ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-icon-group mb-4" title="Location">
                        <Select
                          className="form-control custom-select-box"
                          name="Location"
                          value={Location.filter(val=>val.value === formik.values.Location)}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("Location", value.value)
                            } else {
                              formik.setFieldValue("Location", "")
                            }
                          }}
                          onBlur={formik.handleBlur}
                          options={Location}
                          maxMenuHeight={175}
                          placeholder={formik.values.Location ? formik.values.Location : "Location"}
                          isClearable
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {tab == "open" ? <div className="form-icon-group mb-4" title="Role">
                        <Select
                          className="form-control custom-select-box"
                          name="role_id"
                          value={userRole.filter((data) => {
                            return data.value == formik.values.role_id
                          })}
                          isDisabled={userDataId==id ? true : false}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("role_id", value.value)
                            } else {
                              formik.setFieldValue("role_id", "")
                            }
                          }}
                          onBlur={formik.handleBlur}
                          options={userRole}
                          maxMenuHeight={175}
                          placeholder={formik.values.role_id ? formik.values.role_id : "Role"}
                          isClearable
                        />
                      </div> : <div className="form-icon-group mb-4">
                        <input type="text" disabled className="form-control" placeholder={user.role_name} title="Role" name="role_id" />
                      </div>}
                    </div>
                    <div className="col-md-6">
                      <div className="form-icon-group mb-4" title="Status">
                        <Select
                          className="form-control custom-select-box"
                          name="Status"
                          value={Active.filter(item => item.value == formik.values.is_active)}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("is_active", value.value)
                            } else {
                              formik.setFieldValue("is_active", "")
                            }
                          }}
                          onBlur={formik.handleBlur}
                          options={Active}
                          maxMenuHeight={175}
                          placeholder={formik.values.is_active ? formik.values.is_active : "Status *"}
                          isClearable
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group form-group-save-cancel">
                <PermissionsGate errorProps={{ disabled: true }} scopes={["umedit"]}>
                  <button className="btn btn-save btn-success" type="submit" title="Save">
                    <i className="fal fa-save"></i>
                    Save
                  </button>
                </PermissionsGate>
                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => formik.resetForm()}>
                  <i className="fal fa-times"></i>
                  Cancel
                </button>
                {formik.touched.nTitle && formik.errors.nTitle ? <div className="invalid-feedback d-block">{formik.errors.nTitle}</div> : null}
                {formik.touched.Firstname && formik.errors.Firstname ? <div className="invalid-feedback d-block">{formik.errors.Firstname}</div> : null}
                {formik.touched.Lastname && formik.errors.Lastname ? <div className="invalid-feedback d-block">{formik.errors.Lastname}</div> : null}
                {formik.touched.Mobile && formik.errors.Mobile ? <div className="invalid-feedback d-block">{formik.errors.Mobile}</div> : null}
                {/* {formik.touched.role_id && formik.errors.role_id ? <div className="invalid-feedback d-block">{formik.errors.role_id}</div> : null} */}
                {formik.touched.job_title && formik.errors.job_title ? <div className="invalid-feedback d-block">{formik.errors.job_title}</div> : null}
                {formik.touched.Company && formik.errors.Company ? <div className="invalid-feedback d-block">{formik.errors.Company}</div> : null}
                {formik.touched.Location && formik.errors.Location ? <div className="invalid-feedback d-block">{formik.errors.Location}</div> : null}
                {formik.touched.Report_to && formik.errors.Report_to ? <div className="invalid-feedback d-block">{formik.errors.Report_to}</div> : null}
                {formik.touched.about_me && formik.errors.about_me ? <div className="invalid-feedback d-block">{formik.errors.about_me}</div> : null}
                {formik.touched.WorkNumber && formik.errors.WorkNumber ? <div className="invalid-feedback d-block">{formik.errors.WorkNumber}</div> : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutYou;
