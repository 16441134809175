export const Color = (text) => {
  switch (text.toUpperCase()) {
    case "V1":
      return "cat-skyblue";
    case "V2":
      return "cat-red";
    case "V3":
      return "cat-yellow";
    case "V4":
      return "cat-blue";
    case "V5":
      return "cat-greenhex";
    case "V6":
      return "cat-skyblue";
    case "V7":
      return "cat-red";
    case "V8":
      return "cat-yellow";
    case "V9":
      return "cat-blue";
    case "V10":
      return "cat-greenhex";
    case "COD":
      return "cat-blue";
    case "ONLINE":
      return "cat-greenhex";
    case "SCHOOL":
      return "cat-skyblue";
    case "SCHOOL1":
      return "cat-skyblue";
    case "SCHOOL2":
      return "cat-red";
    case "SCHOOL3":
      return "cat-yellow";
  }
};
