import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { IMAGE_URL, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { GetListOfStudentHubTabList, GetListOfStudentHubBrandList, DeleteStudentTabList, GetStudentHubListFilters } from "../../../services/StudentService";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import hasPermission from "../../../utils/hasMultiplePermission";
import Tablefilter from "../../common/Tablefilter";
import { RenderHubPublished, RenderLinkedBrands } from "../../../utils/CommonStatusItems";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const TabList = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState({ arr: [], checkObj: {} });
  const [linkedBrand, setLinkedBrand] = useState({ arr: [], checkObj: {} });
  const [deleterow, setDeleteRow] = useState(false);
  const [tablistData, setTablistData] = useState([]);
  const [filterData, setFilterData] = useState({ published: [], brand: [] })
  const [isLoaded, setIsLoaded] = useState(true);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [isLoaded])

  useEffect(() => {
    let response = hasPermission({
      scopes: ["shview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const getFilters = async ()=>{
      try {
        const res = await GetStudentHubListFilters(filterSource.token);
        if(res.status==200){
          setFilterData({
            ...res.data, 
            published: res.data.publishFilter, 
            brand: res.data.brandFilter 
          })
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }

    getFilters();

    return () => {
      filterSource.cancel('Component unmounted');      
    }
  }, [])


  useEffect(() => {
    const cancelTokenSources = [];

    const getHubTabList = async () =>{
      setIsLoaded(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetListOfStudentHubTabList(source.token);
        if(res.status==200){
          setTablistData(res?.data?.student_hub);
          setIsLoaded(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoaded(false);
        }
      }
    }

    getHubTabList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };

  }, [deleterow]);

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // TODO Reset Filter
  const resetFilter = () => {
    setVisible({ arr: [], checkObj: {} });
    setLinkedBrand({ arr: [], checkObj: {} });
    setSearch("");
  };
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateHubTabList"));
    if (storedFilters) {
      setVisible(storedFilters.visible);
      setLinkedBrand(storedFilters.linkedBrand);
      
    }
  }, []);
  useEffect(() => {
    const filterState = {
      visible,
      linkedBrand
    };
    localStorage.setItem("filterStateHubTabList", JSON.stringify(filterState));
  }, [  visible, linkedBrand]);
  // TODO Data
  const dataToRender = () => {
    let updatedData = [];
    let allData = tablistData;
    if (search?.length) {
      let tempTabName = allData.filter((item) => {
        let includes =
          item.tab_name &&
          item.tab_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempVisible = allData.filter((item) => {
        let includes =
          item.visible &&
          item.visible.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempIcon = allData.filter((item) => {
        let includes = item.icon && item.icon.includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempLinkedBrand = allData.filter((item) => {
        let includes =
          item.domain &&
          item.domain.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        // ...tempId,
        ...tempTabName,
        ...tempVisible,
        ...tempIcon,
        ...tempLinkedBrand,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (visible.arr.length) {
      let tempVisible = updatedData;
      let tempResult = tempVisible.filter((item) => {
        const startsWith = visible.arr.find(function (visible, index) {
          if (
            visible.toString() === item.visible.toString()
              ? item.visible.toString()
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (linkedBrand.arr.length) {
      let tempLinkedBrand = updatedData;
      let tempSearch = tempLinkedBrand.filter((item) => {
        const startsWith = linkedBrand.arr.find(function (brand, index) {
          let tempVal = item.AssignTabBrands && item.AssignTabBrands.find((value) => value.brand_id == brand)
          if (tempVal)
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }

    if (visible.arr.length || search.length || linkedBrand.arr.length) {
      return updatedData;
    } else {
      return tablistData;
    }
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "tab_name",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        <div className="assigned-title-block-new" title={row.tab_name}>
          <div className="assigned-title-itm">
            <PermissionsGate scopes={["shedit"]} RenderError={() => <p>{row.tab_name}</p>} >
              <Link
                className="as-text-blue curser textLimit100"
                to={`/support/student-hub-library/student-hub/open/${row.id}`}
              >
                {row.tab_name ? (row.tab_name) : "-"}
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
    {
      name: "Published",
      selector: "visible",
      sortable: true,
      cell: (row) => (
        <span className={row.visible == 1 ? "as-green-bg as-widget " : "as-red-bg as-widget "}>
          {row.visible && row.visible == 1 ? "Yes" : "No"}
        </span>
      ),
    },
    {
      name: "Icon",
      selector: "icon",
      sortable: false,
      cell: (row) => (row.icon ? <i className={row.icon}></i> : "-"),
    },
    {
      name: "Linked Brands",
      selector: "is_active",
      sortable: true,
      cell: (row) => {
        // let domain = row.domain.split("___");
        return (
          <p className="tag-list-blk">
            {row.AssignTabBrands && row.AssignTabBrands.map((brand, key) => {
              return (
                <span className={"as-widget" + (brand.Brands?.topbar_color ? "" : " card-blue-1")}
                  style={
                    brand.Brands?.topbar_color ? { background: brand.Brands?.topbar_color, color: "#fff" } : { color: "#fff" }
                  } key={key}>{brand.Brands?.brand_name}</span>
              )
            })}
          </p>
        );
      },
    },
    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate scopes={["shedit"]} RenderError={() => (
              <button
                disabled
                className="btn btn-primary rounded-circle"
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </button>
            )}>
              <Link to={`/support/student-hub-library/student-hub/open/${row.id}`}
                className="btn btn-primary rounded-circle"
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" title="More" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <PermissionsGate scopes={["shedit"]} RenderError={() => (
                  <button
                    disabled
                    className="btn btn-primary rounded-circle"
                    title="Duplicate"
                  >
                    <i className="fal fa-copy"></i>
                  </button>
                )}>
                  <Link to={`/support/student-hub-library/student-hub/duplicateStudentTabList/${row.id}`} className="btn btn-primary rounded-circle" title="Duplicate">
                    <i className="fal fa-copy"></i>
                  </Link>
                </PermissionsGate>
                <PermissionsGate errorProps={{ disabled: true }} scopes={["shdelete"]}>
                  <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => handleDelete(row.id)}>
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteStudentTabList({ id: ID }).then((res) =>
          setDeleteRow(!deleterow)
        );
        Swal.fire("Deleted!", "Your user has been deleted.", "success");
      }
    });
  };


  // TODO Excel
  // TODO Csv
  // TODO Pdf
  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Published", "Icon", "LinkedBrand"];
    data = data?.map((row) => ({
      ...row,
      Name: row?.tab_name,
      Published: row?.visible === 1 ? "Yes" : "No",
      Icon: row?.icon,
      LinkedBrand: row?.domain.split("___",).join(),
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.tab_name, row.Published, row.Icon, row.LinkedBrand];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        Student <span>Hub</span>
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button
                            onClick={() => history.goBack()}
                            title="Back"
                            className="btn btn-white-bordered"
                          >
                            <i className="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  <ul className="nav nav-pills mb-4" id="tabs">
                    <li className="nav-item">
                      <Link to="/support/student-hub-library/student-hub" className="nav-link active" id="pills-tab1" >
                        <i className="fal fa-users-cog"></i>
                        Tab List
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/support/student-hub-library/student-hub/student-audit-trail" className="nav-link " id="pills-tab2" >
                        <i className="fal fa-history"></i>Audit trail
                      </Link>
                    </li>
                  </ul>
                  <div className="my-tickets-info-list Tickets-main-wrap">
                    <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
                      <div className="search-filter-div">
                        <div className="search-filter-div-left">
                          <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                              <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                  <div id="assessment-table-main_filter" className="dataTables_filter">
                                    <label>
                                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                                    </label>
                                    <div className="filter-eff filter-data-btn">
                                      <button className="filter-buttons">
                                        <i className="fal fa-filter"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="filter-button-group">
                              <div className="filter-scroll">
                                <div className={`filter-scroll-inner  filter-custom-new`}>
                                  <Tablefilter
                                    filterName={"Published"}
                                    optionArr={filterData.published}
                                    state={visible}
                                    setState={setVisible}
                                    renderLabelFunction={RenderHubPublished}
                                  />
                                  <Tablefilter
                                    filterName={"Linked Brands"}
                                    optionArr={filterData.brand}
                                    state={linkedBrand}
                                    setState={setLinkedBrand}
                                    renderLabelFunction={RenderLinkedBrands}
                                    uniqueId="brands"
                                  />


                                </div>
                              </div>
                              <div className="reset-btn-group">
                                <div className="button-reset dropdown-comman" title="Reset">
                                  <button className="btn btn-primary" onClick={resetFilter} >
                                    <i className="fal fa-redo"></i>Reset
                                  </button>
                                </div>
                                <div className="files-export-group">
                                  <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "TabList"); }} title="Export spreadsheet">
                                    <i className="fal fa-file-excel icon"></i>
                                  </button>
                                  <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "TabList"); }} title="Export CSV">
                                    <i className="fal fa-file-csv icon"></i>
                                  </button>
                                  <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "TabList"); }} title="Export PDF">
                                    <i className="fal fa-file-pdf icon"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="search-filter-div-right">
                          <div className="">
                            <PermissionsGate scopes={["stuhmreregedit"]} RenderError={() => (
                              <button disabled className="btn btn-primary mr-2" title="Re-arrange">
                                <i className="far fa-random"></i>Re-arrange
                              </button>
                            )}>
                              <Link to={`/support/student-hub-library/student-hub/rearrange`}>
                                <button className="btn btn-primary mr-2" title="Re-arrange">
                                  <i className="far fa-random"></i>Re-arrange
                                </button>
                              </Link>
                            </PermissionsGate>
                            <PermissionsGate scopes={["shadd"]} RenderError={() => (
                              <button disabled className="btn btn-primary" title="Create New">
                                <i className="fal fa-plus"></i>Create New
                              </button>
                            )}>
                              <Link to={`/support/student-hub-library/student-hub/create`}>
                                <button className="btn btn-primary" title="Create New">
                                  <i className="fal fa-plus"></i>Create New
                                </button>
                              </Link>
                            </PermissionsGate>
                          </div>
                        </div>
                      </div>


                      {isLoaded ? <SkeletonTicketList />
                        : <DataTable
                          data={dataToRender()}
                          defaultSortField="tab_name"
                          defaultSortAsc={true}
                          columns={columns}
                          pagination={true}
                          noDataComponent={Str.noRecord}
                          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabList;
