import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { getCurrentTimeHomePage } from "../components/common/Helper";
import RegisterNewStudent from "../components/StudentAdministration/registration/RegisterNewStudent";
// import EbooksExport from "../components/StudentAdministration/Ebooks/eBooksExport";
import ProgrammeReRegistration from "../components/StudentAdministration/ProgrammeReRegistration/programRegistration";
import OpenRegistrationTab from "../components/StudentAdministration/registration/OpenRegistrationTab";
import CourseAndProgrammeTab from "../components/StudentAdministration/registration/CourseAndProgrammeTab";
import RegistrationPage from "../components/StudentAdministration/registration/RegistrationPage";
import AuditTrails from "../components/StudentAdministration/registration/AuditTrails";
import { TrimText } from "../components/common/TrimText";
import { GlobalIconNames } from "../utils/GlobalIconNames";
import ProgrammeArticulation from "../components/StudentAdministration/ProgramArticulations/programmeArticulation";
import hasPermission from "../utils/hasMultiplePermission";
import { useSelector } from "react-redux";

const ProgramArticulations = () => {
  const { tab, page } = useParams();
  const history = useHistory();
  const [tabName, setTabName] = useState("");
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const studentId = params.get('studentId');
  const studentName = params.get('studentName') ? params.get('studentName') : "";
  const description = params.get('description') ? params.get('description') : ""
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    if (page === "studentlist") {
      changeTabName("Registrations");
    }
    else if (page === "e-book") {
      changeTabName("E-Books Export");
    } else if (page === "open-re-registration" && tab === "add") {
      changeTabName("Programme Re-Registration");
    } else if (page === "open-re-registration") {
      changeTabName("Re-Registration: " + description);
    } else if (page === "list-articulations" || page == "audittrails") {
      changeTabName("Articulation Registrations")
    } else if (page === "create") {
      changeTabName("Register Student");
    } else if (page === "open" && tab === "registration") {
      changeTabName("Registration: " + studentName)
    } else if (page === "open" && tab === "courseAndProgramme") {
      changeTabName("Registration: " + studentName)

    }
    else if (page === "open" && tab == "audit-trail") {
      changeTabName("Registration: " + studentName)
    }
  }, [page]);

  function changeTabName(name = "Registration") {
    setTabName(name);
  }

  useEffect(() => {
    let response = hasPermission({
      scopes: ["cparview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic" title={tabName.split(": ")[0] === "Articulation Registration" ? tabName.split(": ")[1] : ""}>
                        <>{tabName.split(" ")[0]}</> <span>{tabName.split(" ")[1] ? TrimText(tabName.substr(tabName.indexOf(' ') + 1), 177) : null}</span>
                      </h4>
                    </div>
                        <div className="text-md-right action2-blk">
                          <div className="ticket-view-btn d-flex align-items-center">
                            <div className="add-new-ticket-bx">
                              <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                <i className="fal fa-angle-left"></i>Back
                              </button>
                            </div>
                          </div>
                        </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  {page === "create" ? (
                    <RegisterNewStudent />
                  ) : page === "open" ? (
                    <>
                      <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                        <li className="nav-item" onClick={() => { history.push(`/studentadministration/registration/open/registration?studentId=${studentId}&studentName=${studentName}`); changeTabName("Registration: " + studentName); }} >
                          <a className={"nav-link" + (tab === "registration" ? " active" : "")} id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true" >
                          {GlobalIconNames("details").html}
                          </a>
                        </li>
                        <li className="nav-item" onClick={() => { history.push(`/studentadministration/registration/open/courseAndProgramme?studentId=${studentId}&studentName=${studentName}`); changeTabName("Registration: " + studentName); }} >
                          <a className={"nav-link" + (tab === "courseAndProgramme" ? " active" : "")} id="pills-tab2" data-toggle="pill" href="#pills-tab12" role="tab" aria-controls="pills-tab12" aria-selected="true" >
                            {GlobalIconNames("assignedprogrammes").html}
                          </a>
                        </li>
                        <li className="nav-item" onClick={() => { history.push(`/studentadministration/registration/open/audit-trail?studentId=${studentId}&studentName=${studentName}`); changeTabName("Registration: " + studentName); }} >
                          <a className={"nav-link" + (tab === "audit-trail" ? " active" : "")} id="pills-tab3" data-toggle="pill" href="#pills-tab13" role="tab" aria-controls="pills-tab13" aria-selected="true" >
                          {GlobalIconNames("auditTrail").html}
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div className={"tab-pane fade" + (tab === "registration" ? " active show" : "")} id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                          {tab === "registration" && <OpenRegistrationTab />}
                        </div>
                        <div className={"tab-pane fade" + (tab === "courseAndProgramme" ? " active show" : "")} id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
                          {tab === "courseAndProgramme" && <CourseAndProgrammeTab />}
                        </div>

                        {tab === "audit-trail" && <div className={"tab-pane fade" + (tab === "audit-trail" ? " active show" : "")} id="pills-tab13" role="tabpanel" aria-labelledby="pills-tab3">
                          {tab === "audit-trail" && <AuditTrails />}
                        </div>}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="tab-content" id="pills-tabContent">
                        <div className={"tab-pane fade" + (page === "studentlist" ? " active show" : "")} id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                          {page === "studentlist" && <RegistrationPage changeName={changeTabName} />}
                        </div>
                        {/* <div className={"tab-pane fade" + (page === "e-book" ? " active show" : "")} id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
                          {page === "e-book" && <EbooksExport />}

                        </div> */}
                        <div className={"tab-pane fade active show"} id="pills-tab13" role="tabpanel" aria-labelledby="pills-tab3">
                          {/* {page === "open-re-registration" && <ProgrammeReRegistration />}
                          {page === "list-re-registration" && <ProgrammeReRegistration />}
                          {page === "list-student-status" && <ProgrammeReRegistration />} */}
                          {(page === "open-articulation-registration" || page === "list-articulations" || page === "list-student-status" || page === "audittrails") && <ProgrammeArticulation changeName={changeTabName} />}
                          {/* <h1>3</h1> */}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProgramArticulations;
