const initialState = {
    values: {}
};

const registrationDrowpdownValues = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_VALUES':
            return { ...state.values, ...action.payload }
        case 'UNSET_VALUES':
            return state.values = {}
        default:
            return state;
    }
}

export default registrationDrowpdownValues;