import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";

import { useFormik } from "formik";

import { getCurrentTimeHomePage } from "../../common/Helper";
import Tabs from "../Tabs";
import { useHistory } from "react-router";
import { AddDetail, GetListOfRole } from "../../../services/UserService";

import AboutYou from "./AboutYouNewUser";
import LoginDetails from "./LoginDetailsNewUser";
import { Link, useParams } from "react-router-dom";

const AddNewUser = () => {
  const history = useHistory();
  const { tab } =useParams();

  // useEffect(() => {
  //   GetListOfRole()
  //     .then((res) => {
  // console.log("data", res.data);
  //   setRoles(res.data);
  // })
  // .catch((err) => console.log(err));
  // }, []);

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className=" wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                <div class="d-flex align-items-center ">
                    <div class="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                      Create <span>User</span>
                      </h4>
                    </div>
                    <div class="text-md-right action2-blk">
                      <div class="ticket-view-btn d-flex align-items-center">
                        <div class="add-new-ticket-bx">
                          <button onClick={() => history.goBack()} title="Back" class="btn btn-white-bordered">
                            <i class="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="tabs-wrap">
                  <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li className="nav-item" onClick={() => history.push("/systemAdministration/userManagement/new")}>
                      <Link className="nav-link active" id="pills-tab1" data-toggle="pill" to="/systemAdministration/userManagement/table" role="tab" aria-controls="pills-tab1" aria-selected="true">
                        <i className="fal fa-info-circle"></i>About You
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <a className="nav-link" id="pills-tab2" data-toggle="pill" href="#pills-tab22" role="tab" aria-controls="pills-tab2" aria-selected="false">
                        <i className="fal fa-user"></i>Login Details
                      </a>
                    </li> */}
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      {tab === "new" && <AboutYou />}
                    </div>
                    {/* <div className="tab-pane fade" id="pills-tab22" role="tabpanel" aria-labelledby="pills-tab2">
                      <LoginDetails />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewUser;
