import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import EmailTab from "../components/Notification/EmailManagement/EmailTab";
import EmailGeneratedTab from "../components/Notification/EmailManagement/EmailGeneratedTab";
import EmailPendingTab from "../components/Notification/EmailManagement/EmailPendingTab";
import EmailStoppedTab from "../components/Notification/EmailManagement/EmailStoppedTab";
import { TrimText } from "../components/common/TrimText";
import { GlobalIconNames } from "../utils/GlobalIconNames";
import EmailAuditTrail from "../components/Notification/EmailManagement/EmailAuditTrail";

const EmailManagement = () => {
  const history = useHistory();
  const { tab, type, id } = useParams();
  const search = useLocation().search;
  const [name, setName] = useState(new URLSearchParams(search).get("name"));

  const renderTitle = () => {
    if (tab === "list" && type === "edit") {
      return (
        <>
          Notification <span>Templates: {TrimText(name)}</span>
        </>
      )
    }
    else if (tab === "auditTrail" && id) {
      return (
        <>
          Notification <span>Templates: {TrimText(name)}</span>
        </>
      )
    }
    else if (tab === "auditTrail" && type === "table") {
      return (<>Notification <span>Templates</span></>)
    }
    else {
      return (<>Add Notification <span>Templates</span></>)
    }
  }

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        {renderTitle()}
                      </h4>
                    </div>
                    {
                      (
                        <div className="text-md-right action2-blk">
                          <div className="ticket-view-btn d-flex align-items-center">
                            <div className="add-new-ticket-bx">
                              <button
                                onClick={() => history.goBack()}
                                title="Back"
                                className="btn btn-white-bordered"
                              >
                                {GlobalIconNames("Back", "fa-angle-left", "Back").html}
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className="tabs-wrap">
                  {type !== "add" && <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    {(tab === "list" && type === "table") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push("/notification/email/list/table")
                      }
                    >
                      <Link
                        className={`nav-link ${tab === "list" ? "active" : ""}`}
                        id="pills-tab1"
                        data-toggle="pill"
                        to={"/notification/email/list/table"}
                        href="#pills-tab11"
                        role="tab"
                        aria-controls="pills-tab11"
                        aria-selected="true"
                      >
                        {GlobalIconNames("List", "fa-users", "List").html}
                      </Link>
                    </li>}
                    {((tab === "list" || tab === "auditTrail") && (type === "edit" || type !== "add" || type === "table")) && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/notification/email/list/edit/${id}?name=${name}`)
                      }
                    >
                      <Link
                        className={`nav-link ${tab === "list" ? "active" : ""}`}
                        id="pills-tab1"
                        data-toggle="pill"
                        to={`/notification/email/list/edit/${id}?name=${name}`}
                        href="#pills-tab11"
                        role="tab"
                        aria-controls="pills-tab11"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Details").html}
                      </Link>
                    </li>}
                    {(tab === "generated" && type === "table") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push("/notification/email/generated/table")
                      }
                    >
                      <Link
                        className={`nav-link ${tab === "generated" ? "active" : ""
                          }`}
                        id="pills-tab2"
                        data-toggle="pill1"
                        to={"/notification/email/generated/table"}
                        href="#pills-tab12"
                        role="tab"
                        aria-controls="pills-tab12"
                        aria-selected="false"
                      >
                        {GlobalIconNames("Generated", "fa-users", "Generated").html}
                      </Link>
                    </li>}
                    {tab === "pending" && <li
                      className="nav-item"
                      onClick={() =>
                        history.push("/notification/email/pending/table")
                      }
                    >
                      <Link
                        className={`nav-link ${tab === "pending" ? "active" : ""
                          }`}
                        id="pills-tab3"
                        data-toggle="pill1"
                        to={"/notification/email/pending/table"}
                        href="#pills-tab13"
                        role="tab"
                        aria-controls="pills-tab13"
                        aria-selected="false"
                      >
                        {/* <i className="fal fa-users"></i>
                        Pending */}
                        {GlobalIconNames("Pending", "fa-users", "Pending").html}
                      </Link>
                    </li>}
                    {tab === "stopped" && <li
                      className="nav-item"
                      onClick={() =>
                        history.push("/notification/email/stopped/table")
                      }
                    >
                      <Link
                        className={`nav-link ${tab === "stopped" ? "active" : ""
                          }`}
                        id="pills-tab4"
                        data-toggle="pill1"
                        to={"/notification/email/stopped/table"}
                        href="#pills-tab14"
                        role="tab"
                        aria-controls="pills-tab14"
                        aria-selected="false"
                      >
                        {/* <i className="fal fa-users"></i>
                        Stopped */}
                        {GlobalIconNames("Stopped", "fa-users", "Stopped").html}
                      </Link>
                    </li>}
                    {((tab === "list" || tab === "auditTrail") && (type === "table" || type === "edit" || type !== "add")) && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/notification/email/auditTrail/table/${id}?name=${name}`)
                      }
                    >
                      <Link
                        className={`nav-link ${tab === "auditTrail" ? "active" : ""}`}
                        id="pills-tab60"
                        data-toggle="pill"
                        // to={"/notification/email/list/table"}
                        to={`/notification/email/auditTrail/table/${id}?name=${name}`}
                        href="#pills-tab11"
                        role="tab"
                        aria-controls="pills-tab60"
                        aria-selected="true"
                      >
                        {GlobalIconNames("audittrail").html}
                      </Link>
                    </li>}
                  </ul>}
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab11"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      {tab === "list" && <EmailTab setName={setName} />}
                      {tab === "generated" && <EmailGeneratedTab setName={setName} />}
                      {tab === "pending" && <EmailPendingTab setName={setName} />}
                      {tab === "stopped" && <EmailStoppedTab setName={setName} />}
                      {tab === "auditTrail" && <EmailAuditTrail setName={setName} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailManagement;
