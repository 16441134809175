import React, { useEffect, useMemo, useState } from "react";
import { useParams, useLocation } from "react-router";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import { GetQuizAttempts, GetQuizAttemptsCancelToken } from "../../../../../services/SmartAssessmentService";
import Str from "../../../../common/Str";
import moment from "moment";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import hasPermission from "../../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../../utils/Constants";
import { secToMin } from "../../../../common/SecnodsToMinutes";
import { RoundNumber } from "../../../../../utils/RoundNumber";
import { RenderQuizAttemptResultStatus } from "../../../../../utils/CommonStatusItems";
import Tablefilter from "../../../../common/Tablefilter";
import { capitalize, handleTableScroll, twoDecimalRound } from "../../../../../utils/commonFunction";
import { useDispatch } from "react-redux";
import { setAssessmentId, setIntakeId } from "../../../../../store/actions";
import axios from "axios";

const SubmissionList = ({ setSubmissionName }) => {
  const { id, subId } = useParams();
  const history = useHistory();
  const location = useLocation()
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [type, setType] = useState([]);
  const [searchTypeCheck, setSearchTypeCheck] = useState({});
  const [data, setData] = useState([]);
  const [status, setStatus] = useState({arr : [],  checkObj: {}})
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const dispatch = useDispatch();
  const searchID = window.location.search;
  const IDs = new URLSearchParams(searchID)
  const intake_id = IDs.get('intake_id');
  const assessment_id = IDs.get('assess_id');
  const [isHybrid, setIsHybrid] = useState(false);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    let response = hasPermission({ scopes: ["casubmissionresultview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
        setLoading(true);
        const params = {
          quid:id,
          id: subId.toString().includes('?') ? subId.split('?')[0] : subId,
          aid: assessment_id,
        }
      try {
        const res = await GetQuizAttemptsCancelToken(params,dropdownSource.token);
        if(res.status == 200){
          let flag = false;
          let tempArr = await res?.data?.data?.attempts?.map((item,index)=>{ 
            let score_obtained = formatDecimal(item?.score_obtained);
            let percentage_obtained = formatDecimal(item?.percentage_obtained);
            if(item.isHybird==true || item.isHybird=="true"){
              flag=true;
            }
            return (
            {
              ...item,
              score_obtained: score_obtained,
              percentage_obtained: percentage_obtained,
              attemptedQuizResult:item.serialNo + '. Attempted Quiz Result'
            }
            )
          })
          setData(tempArr);
          setSubmissionName(`${res?.data?.data?.description?.quiz_name} - ${res?.data?.data?.user[0]?.first_name && capitalize(res?.data?.data?.user[0]?.first_name)} ${res?.data?.data?.user[0]?.last_name && capitalize(res?.data?.data?.user[0]?.last_name)}`)
          setIsHybrid(flag);
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  function formatDecimal(number) {
    const formattedNumber = parseFloat(number).toFixed(2);
    return formattedNumber % 1 === 0 ? parseInt(formattedNumber) : formattedNumber;
  }
  
  const columns = useMemo(() => [
    {
      name: "Attempt",
      selector: "attemptedQuizResult",
      sortable: true,
      
      cell: (row) => {
        let urlQueryParams = `?intake_id=${intake_id}&assess_id=${assessment_id}`
        let urlPathname = `/courseAdministration/assessment/quizzes/open/submissionquestionresult/${row?.rid}/${row?.uid}`;
        let url = urlPathname.includes(urlQueryParams) ? urlPathname : `${urlPathname}${urlQueryParams}`;
        return (
      <Link 
        className="as-text-blue curser" 
        to={{pathname: url} }
        title={row?.attemptedQuizResult}>
          {row?.attemptedQuizResult ? row?.attemptedQuizResult : "-"}
        </Link>
        )
        },
    },
    {
      name: "Attempt Date",
      selector: "start_time",
      sortable: true,
      cell: (row) => (row?.start_time ? moment.unix(row?.start_time).format(TABLE_DATE_FORMAT) + " " + moment.unix(row?.start_time).format(TABLE_TIME_FORMAT) : "-")
    },
    {
      name: "Time Spent (Min)",
      selector: "total_time",
      sortable: true,
      cell: (row) => (row?.total_time ? secToMin(row?.total_time) : "-")
    },
    {
      name: "Score",
      selector: "score_obtained",
      sortable: true,
      cell: (row) => (((row?.normalQuestionScore?.toString() && row?.hybirdQuestionScore?.toString()) ? twoDecimalRound(row?.score_obtained) : "?") + (" / " + row.total_score)),
    },
    {
      name: "Percentage (%)",
      selector: "percentage_obtained",
      sortable: true,
      cell: (row) => {
        return ((row?.normalQuestionScore?.toString() && row?.hybirdQuestionScore?.toString() && row?.percentage_obtained?.toString()) ? twoDecimalRound(Number(row?.percentage_obtained)) +"%" : "?")
      }
    },
    {
      name: "Status",
      selector: "result_status",
      sortable: true,
      cell: (row) => ((row?.normalQuestionScore?.toString() && row?.hybirdQuestionScore?.toString() && row?.result_status) ? RenderQuizAttemptResultStatus(row?.result_status).html : "?")
    },
    {
      name: "Actions",
      maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              className="btn btn-primary rounded-circle"
              title="Open"
              to={{pathname: `/courseAdministration/assessment/quizzes/open/submissionquestionresult/${row?.rid}/${row?.uid}?intake_id=${intake_id}&assess_id=${assessment_id}`} }
            >
              <i className="fal fa-folder-open"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ]);
  
  const hybridColumns = useMemo(() => [
    {
      name: "Attempt",
      selector: "attemptedQuizResult",
      sortable: true,
      
      cell: (row) => {
        let urlQueryParams = `?intake_id=${intake_id}&assess_id=${assessment_id}`
        let urlPathname = `/courseAdministration/assessment/quizzes/open/submissionquestionresult/${row?.rid}/${row?.uid}`;
        let url = urlPathname.includes(urlQueryParams) ? urlPathname : `${urlPathname}${urlQueryParams}`;
        return (
      <Link 
        className="as-text-blue curser" 
        to={{pathname: url} }
        title={row?.attemptedQuizResult}>
          {row?.attemptedQuizResult ? row?.attemptedQuizResult : "-"}
        </Link>
        )
        },
    },
    {
      name: "Attempt Date",
      selector: "start_time",
      sortable: true,
      cell: (row) => (row?.start_time ? moment.unix(row?.start_time).format(TABLE_DATE_FORMAT) + " " + moment.unix(row?.start_time).format(TABLE_TIME_FORMAT) : "-")
    },
    {
      name: "Time Spent (Min)",
      selector: "total_time",
      sortable: true,
      cell: (row) => (row?.total_time ? secToMin(row?.total_time) : "-")
    },
    {
      name: "Hybrid Score",
      selector: "score_obtained",
      sortable: true,
      cell: (row) => (((row?.hybirdQuestionScore?.toString()) ? twoDecimalRound(row?.hybirdQuestionScore) : "?") + (" / " + row.hybirdQuestionTotalScore.toString()))
    },
    {
      name: "Smart Score",
      selector: "score_obtained",
      sortable: true,
      cell: (row) => (((row?.normalQuestionScore?.toString()) ? twoDecimalRound(row?.normalQuestionScore) : "?") + (" / " + row.normalQuestionTotalScore.toString()))       
    },
    {
      name: "Score",
      selector: "score_obtained",
      sortable: true,
      cell: (row) => (((row?.normalQuestionScore?.toString() && row?.hybirdQuestionScore?.toString()) ? twoDecimalRound(row?.score_obtained) : "?") + (" / " + row.total_score)),
    },
    {
      name: "Percentage (%)",
      selector: "percentage_obtained",
      sortable: true,
      cell: (row) => {
        return ((row?.normalQuestionScore?.toString() && row?.hybirdQuestionScore?.toString() && row?.percentage_obtained?.toString()) ? twoDecimalRound(Number(row?.percentage_obtained)) +"%" : "?")
      }
    },
    {
      name: "Status",
      selector: "result_status",
      sortable: true,
      cell: (row) => ((row?.normalQuestionScore?.toString() && row?.hybirdQuestionScore?.toString() && row?.result_status) ? RenderQuizAttemptResultStatus(row?.result_status).html : "?")
    },
    {
      name: "Actions",
      maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              className="btn btn-primary rounded-circle"
              title="Open"
              to={{pathname: `/courseAdministration/assessment/quizzes/open/submissionquestionresult/${row?.rid}/${row?.uid}?intake_id=${intake_id}&assess_id=${assessment_id}`} }
            >
              <i className="fal fa-folder-open"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ]);

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let tempname = allData.filter((item) => {
        let includes = item?.attemptedQuizResult.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStatus = allData.filter((item) => {
        let includes = item?.result_status.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempdata = [
        ...tempname,
        ...tempStatus,
      ];
      let unique = [...new Set(tempdata)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.arr.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        const startsWith = status.arr.find((post, index) => {
          if (post.toString() === item.result_status.toString())
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    return updatedData;
  };

  const handleFilter = (e, typeName = "") => {
    const value = e.target.value;
    if (typeName == "search") {
      setSearch(value);
    } else if (typeName == "type") {
      let arr = type;
      if (arr.includes(value)) {
        arr.splice(arr.indexOf(value), 1);
      } else {
        arr.push(value);
      }
      setType(arr);
      let id = e.target.id;
      setSearchTypeCheck({ ...searchTypeCheck, [id]: !searchTypeCheck[id] });
    } 
  };

  const resetFilter = () => {
    setSearch("");
    setStatus({arr :[], checkObj: {}});
  };

  const exportData = async (fileType, fileName) => {
    let data = dataToRender();
    let header;
    if(isHybrid==true){
      header = ["Attempt", "Attempt Date", "Time Spent (Min)", "Hybrid Score", "Smart Score", "Score", "Percentage (%)", "Status"];
    }else{
      header = ["Attempt", "Attempt Date", "Time Spent (Min)", "Score", "Percentage (%)", "Status"];

    }
    data = data?.map((row) => ({
      ...row,
      Attempt: row?.attemptedQuizResult ? row?.attemptedQuizResult : "-",
      "Attempt Date": (row?.start_time ? moment.unix(row?.start_time).format(TABLE_DATE_FORMAT) + " " + moment.unix(row?.start_time).format(TABLE_TIME_FORMAT) : "-"),
      ["Time Spent (Min)"]: row?.total_time ? secToMin(row?.total_time) : "-",
      ["Hybrid Score"]: (((row?.hybirdQuestionScore?.toString()) ? twoDecimalRound(row?.hybirdQuestionScore) : "?") + (" / " + row.hybirdQuestionTotalScore.toString())),
      ["Smart Score"]: (((row?.normalQuestionScore?.toString()) ? twoDecimalRound(row?.normalQuestionScore) : "?") + (" / " + row.normalQuestionTotalScore.toString())),
      ["Score"]: (((row?.normalQuestionScore?.toString() && row?.hybirdQuestionScore?.toString()) ? twoDecimalRound(row?.score_obtained) : "?") + (" / " + row.total_score)),
      ["Percentage (%)"]: ((row?.normalQuestionScore?.toString() && row?.hybirdQuestionScore?.toString() && row?.percentage_obtained?.toString()) ? twoDecimalRound(Number(row?.percentage_obtained)) +"%" : "?"),
      ["Status"]: (row?.normalQuestionScore?.toString() && row?.hybirdQuestionScore?.toString() && row?.result_status) ? RenderQuizAttemptResultStatus(row.result_status).text : "?",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      let compatibleData
      if(isHybrid==true){
        compatibleData = data.map((row) => {
          return [row["Attempt"], row["Attempt Date"], row["Time Spent (Min)"], row["Hybrid Score"], row["Smart Score"], row["Score"], row["Percentage (%)"], row["Status"]];
        });  
      }else{
        compatibleData = data.map((row) => {
          return [row["Attempt"], row["Attempt Date"], row["Time Spent (Min)"], row["Score"], row["Percentage (%)"], row["Status"]];
        });
      }
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);
      return false;
    }
  };

  return (
    <div className="">
      <div className="">
        <div className="">
          {/* <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="edit-icon new-card-header">
              <div className="card-header">List of Submissions</div>
            </div>
          </div> */}
          <div className="custom-table-div filter-search-icon card card-table-custom">
            <div className="search-filter-div">
              <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                      <div id="assessment-table-main_filter" className="dataTables_filter">
                        <label>
                          <input type="search" placeholder="Search" aria-controls="assessment-table-main" onChange={(e) => handleFilter(e, "search")} value={search} />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                    <div className="filter-scroll">
                      <div className={`filter-scroll-inner  filter-custom-new`}>
                        <Tablefilter
                        filterName={"Status"}
                        optionArr={
                          [
                            {
                              label: "Pass",
                              value: "pass"
                            },
                            {
                              label:"Fail",
                              value:"fail"
                            }
                          ]
                        }
                        state={status}
                        setState={setStatus}
                        renderLabelFunction={RenderQuizAttemptResultStatus}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button className="btn btn-primary" title="Reset" onClick={resetFilter}>
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button type="button" className="btn btn-files" title="Export spreadsheet" onClick={() => { exportData("xlsx", "SubmissionsList"); }} >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button type="button" className="btn btn-files" title="Export CSV" onClick={() => { exportData("csv", "SubmissionsList"); }} >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button type="button" className="btn btn-files" title="Export PDF" onClick={() => { exportData("pdf", "SubmissionsList"); }}>
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? <SkeletonTicketList /> : <DataTable
              data={dataToRender()}
              defaultSortField="start_time"
              defaultSortAsc={false}
              columns={isHybrid ? hybridColumns :  columns}
              pagination={true}
              noDataComponent={Str.noRecord}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmissionList;