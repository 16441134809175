import $ from 'jquery';
import "jspdf-autotable";
import JsPDF from "jspdf";
import moment from "moment";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useMemo, useState } from "react";
import listIcon from "../../../../assets/images/list-tree.svg"
import CoursesTable from "./CoursesTable";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import { Link } from 'react-router-dom';
import { ColorCode } from '../../../systemadministration/usermanagement/CheckRole';
import { RenderLearningMethod } from '../../../../utils/CommonGroupingItem';
import { TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from '../../../../utils/Constants';
import { handleTableScroll } from '../../../../utils/commonFunction';

function AvailableQualificationAndCourses(props) {

  const [studentData, setStudentData] = useState();
  const [dropdownValues, setDropdownValues] = useState(useSelector((state) => state.registerDropdownValues));
  const [salesPerson, setSalesPerson] = useState([]);
  const [availQualifications, setAvailQualifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [idsToAssign, setIdsToAssign] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);

  const [intake, setIntake] = useState([]);
  const [intakeYear, setIntakeYear] = useState([])
  const [searchIntakeYearCheck, setSearchIntakeYearCheck] = useState({});

  const [deliveryMethodArr, setDeliveryMethodArr] = useState([]);
  const [deliveryMethod, setDeliveryMethod] = useState([])
  const [deliverymethodCheck, setSearchIntakeNumberCheck] = useState({});
  const [courseType, setCourseType] = useState([]);
  const [courseTypeCheck, setCourseTypeCheck] = useState({});

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  function getValue(arrName, label) {
    let labelName = "";
    if (arrName === "sales_person") {
      for (let item of salesPerson) {
        if (item.value.toString() == label.toString()) {
          labelName = item.label;
          break;
        }
      }
      return labelName;
    } else {
      for (let item of dropdownValues[arrName]) {
        if (item.value.toString() == label.toString()) {
          labelName = item.label;
          break;
        }
      }

      if (labelName === "" && arrName === "re_registration_status") return label;
      return labelName;
    }
  }

  useEffect(() => {
    setAvailQualifications(props.data);
    setDeliveryMethodArr(props.dropdownValues.study_type);
    setIntakeYear(props.dropdownValues.intake_year);
  }, [props])

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "course",
      sortable: true,
      cell: (row) => (
          row.course ? (
            <span
              className="as-text-blue curser feature-name"
              onClick={() => {
                showCourses(row);
              }}
              title={row.course}
            >
              <span className='textLimit100'>{row.course}</span>
            </span>
          ) : (
            "-"
          )
      ),
    },
    {
      name: "Start",
      selector: "start",
      sortable: true,
      cell: (row) => (
        <>
          {row.start ? (
            <div className="dateTime">
              <p className="right-space">{moment.unix(row.start).format(TABLE_DATE_FORMAT)}</p>
              {/* <p>{moment.unix(row.start).format(TABLE_TIME_FORMAT)}</p> */}
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "End",
      selector: "end",
      sortable: true,
      cell: (row) => (
        <>
          {row.end ? (
            <div className="dateTime">
              <p className="right-space">{moment.unix(row.end).format(TABLE_DATE_FORMAT)}</p>
              {/* <p>{moment.unix(row.end).format(TABLE_TIME_FORMAT)}</p> */}
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => <>{row.year ? row.year + "-" + row.number : "-"}</>,
    },
    {
      name: "Type",
      selector: "programs_course_type",
      sortable: true,
      cell: (row) => <div>{row.programs_course_type ? ColorCode("rereg_" + row.programs_course_type) : "-"}</div>,
    },
    {
      name: "Learning Method",
      selector: "type",
      sortable: true,
      cell: (row) => <div>{row.type ? RenderLearningMethod(row.type).html : "-"}</div>,
    },
    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        row && row.programs_course_type && row.programs_course_type === "programs" ? row && row.getSubjects && row.getSubjects.length ? (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons d-flex">
              <button title="View" className="btn btn-primary rounded-circle" onClick={() => { showCourses(row); }}>
                <img src={listIcon} height="15px" width="15px" />
              </button>

              <div className="dropdown btn-dropdown-item ">
                <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="More">
                  <i className="fal fa-ellipsis-h-alt"></i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <Link className="btn btn-primary rounded-circle" to={{ pathname: `/courseAdministration/Programmes/programme/open/${row.id}/details` }} title="Open">
                    <i onClick={()=>$("#available").modal("hide")} className="fal fa-folder-open"></i>
                  </Link>

                  <button title="Add" className="btn btn-primary rounded-circle" onClick={() => { props.assignCourse(row, true); }}>
                    <i className="fal fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="assessment-08">
            <div className="">
              <Link className="btn btn-primary rounded-circle" onClick={()=>$("#available").modal("hide")} to={{ pathname: `/courseAdministration/Programmes/programme/open/${row.id}/details` }} title="Open">
                <i className="fal fa-folder-open"></i>
              </Link>
            </div>
          </div>
        ) : (
          <>
          <div className="assessment-08 btn-dropdown-grp">
            <div className='as-buttons'>
            <Link className="btn btn-primary rounded-circle" onClick={()=>$("#available").modal("hide")} to={{ pathname: `/courseAdministration/Programmes/programme/open/${row.id}/details` }} title="Open">
              <i className="fal fa-folder-open"></i>
            </Link>
            <button title="Add" className="btn btn-primary rounded-circle" onClick={() => { props.assignCourse(row, true); }}>
              <i className="fal fa-plus"></i>
            </button>
          </div>
          </div>
        </>
        )
      ),
    },
  ]);

  function dataToRender() {
    let updatedData = [];
    let allData = availQualifications;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.id && item.id.toString().toLowerCase().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempName = allData.filter((item) => {
        let includes = item.course && item.course.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        // ...tempId,
        ...tempName
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (intake.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = intake.find(function (user, index) {
          if (user.toString().toLowerCase() == (item.year ? item.year.toString().toLowerCase() : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (deliveryMethod.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = deliveryMethod.find(function (user, index) {
          if (user.toString().toLowerCase() == (item.type ? item.type.toString().toLowerCase() : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }
    if (courseType.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = courseType.find(function (user, index) {
          if (user.toString().toLowerCase() == (item.programs_course_type ? item.programs_course_type.toString().toLowerCase() : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }

    if (intake.length || search.length || deliveryMethod.length || courseType.length) {
      return updatedData;
    } else {
      return availQualifications;
    }
  }

  const handleFilters = (e, type = "") => {
    const value = e.target.value;
    if (type === "search") {
      setSearch(value);
    }
  };

  const handleIntakeFilter = (e) => {
    const value = e.target.value;
    let arr = intake;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setIntake(arr);
    let id = e.target.id;
    setSearchIntakeYearCheck({ ...searchIntakeYearCheck, [id]: !searchIntakeYearCheck[id] });
  }

  const handleDeliveryMethodFilter = (e) => {
    const value = e.target.value;
    let arr = deliveryMethod;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setDeliveryMethod(arr);
    let id = e.target.id;
    setSearchIntakeNumberCheck({ ...deliverymethodCheck, [id]: !deliverymethodCheck[id] });
  }

  const handleTypeFilter = (e) => {
    const value = e.target.value;
    let arr = courseType;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setCourseType(arr);
    let id = e.target.id;
    setCourseTypeCheck({ ...courseTypeCheck, [id]: !courseTypeCheck[id] });
  }

  function resetFilter() {
    setSearch("");
    setIntake([]);
    setSearchIntakeYearCheck({});
    setDeliveryMethod([]);
    setSearchIntakeNumberCheck({});
    setCourseType([]);
    setCourseTypeCheck({});
  }

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Start", "End", "Intake", "Learning Method"];

    data = data?.map((row) => ({
      ...row,
      "Name": row.course,
      "Start": row.start ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : "-",
      "End": row.end ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : "-",
      "Intake": row.year ? row.year + "-" + row.number : "",
      "Learning Method": row.type ? RenderLearningMethod(row.type).text : "-"
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Name, row["Start"], row["End"], row.Intake, row["Learning Method"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  function showCourses(courses) {
    setSelectedCourses(courses);
    $("#viewAvailCourses").modal('show');
  }

  function closeCourses() {
    setSelectedCourses([]);
    $("#viewAvailCourses").modal('hide');
  }

  function setAvailable(data) {
    props.assignCourse(data);
  }

  return (
    <>
      <div className="l-o-c-t custom-table-div filter-search-icon card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
          <div className="system-administration-table table-responsive">
          <div className="table-responsive-div">
            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                <div id="assessment-table-main_filter" className="dataTables_filter">
                  <label>
                    <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={(e) => handleFilters(e, "search")} value={search} />
                  </label>
                  <div className="filter-eff filter-data-btn">
                    <button className="filter-buttons">
                      <i className="fal fa-filter"></i>
                    </button>
                  </div>
              </div>
            </div>
          </div>
          <div className="filter-button-group">
            <div className="filter-scroll">
              <div className={`filter-scroll-inner  filter-custom-new`}>
                <div className="assessment-table-filter">
                <div className="btn-type-filter dropdown-comman">
                  <div className="dropdown multiselect">
                    <button
                      className={`btn btn-default dropdown-toggle ${deliveryMethod.length ? "btn-selected" : ""} `}
                      type="button"
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                      title="Learning Method"
                    >
                      <span>
                      Learning Method <i className="fal fa-angle-down"></i>
                      </span>
                    </button>
                    <form className="dropdown-menu remove-list-style">
                      <ul aria-labelledby="dropdownMenu2">
                        {deliveryMethodArr &&
                          deliveryMethodArr.map((item, index) => {
                            return (
                              <li className="cat" key={item.value}>
                                <input type="checkbox" id={"availableDeliveryMethod" + item.value} value={item.value} onClick={handleDeliveryMethodFilter} checked={deliverymethodCheck["availableDeliveryMethod" + item.value]} />
                                <label htmlFor={"availableDeliveryMethod" + item.value}>
                                  {RenderLearningMethod(item.value).html}
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    </form>
                  </div>
                </div>
                </div>
                <div className="assessment-table-filter">
                  <div className="btn-type-filter dropdown-comman">
                    <div className="dropdown multiselect">
                      <button className={`btn btn-default dropdown-toggle ${courseType.length ? "btn-selected" : ""} `} type="button" id="dropdownMenu3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" title="Type" >
                        <span>
                          Type <i className="fal fa-angle-down"></i>
                        </span>
                      </button>
                      <form className="dropdown-menu remove-list-style">
                        <ul aria-labelledby="dropdownMenu3">
                          <li>
                            <input type="checkbox" id={"availPrograms"} value="programs" onClick={handleTypeFilter} checked={courseTypeCheck.availPrograms} />
                            <label htmlFor="availPrograms">
                              <span className="cat cat-yellowshade">
                                <i className="fas fa-circle mr-1"></i>Programs</span>
                            </label>
                          </li>
                          <li>
                            <input type="checkbox" id={"availshortCourse"} value="shortcourse" onClick={handleTypeFilter} checked={courseTypeCheck.availshortCourse} />
                            <label htmlFor="availshortCourse">
                              <span className="cat cat-blue">
                                <i className="fas fa-circle mr-1"></i>Short Course</span>
                            </label>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="reset-btn-group">
              <div className="button-reset dropdown-comman">
                <button className="btn btn-primary" title="Reset"
                  onClick={resetFilter}
                >
                  <i className="fal fa-redo"></i>Reset
                </button>
              </div>
              <div className="files-export-group">
                <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Available_Qualification_And_Course"); }} title="Export spreadsheet" > <i className="fal fa-file-excel icon"></i> </button>
                <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Available_Qualification_And_Course"); }} title="Export CSV" > <i className="fal fa-file-csv icon"></i> </button>
                <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Available_Qualification_And_Course"); }} title="Export PDF" > <i className="fal fa-file-pdf icon"></i> </button>
              </div>
            </div>
          </div>
        </div>
         
          </div>
      </div>
      {props.loading ? <SkeletonTicketList />
          : <DataTable
            data={dataToRender()}
            defaultSortField="start"
            defaultSortAsc={false}
            columns={columns}
            pagination={true}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />}
      </div>


      <div className="topic-add-modal modal fade" id="viewAvailCourses" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered available-assessment-modal" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">Courses</h5>
              <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { closeCourses() }}><span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <CoursesTable getValue={getValue} courses={selectedCourses} assignCourse={setAvailable} isRemoveBtn={false} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AvailableQualificationAndCourses;
