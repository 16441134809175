import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import PermissionsGate from "../../utils/permissionGate";
// import axios from "../../utils/axios";
import axios from "axios";
import {
  ColorRender,
  DepartmentRender,
  Level,
  StatusRender,
  StudentStatusRender,
  ColorRenderDept,
} from "../systemadministration/usermanagement/CheckRole";
import { getCurrentTimeHomePage, InitialRender } from "../common/Helper";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../utils/Constants";
import Swal from "sweetalert2";
import JsPDF from "jspdf";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import moment from "moment";
import {
  GetlistTicketForDashboard,
  GetfilterlistDashboard,
  GetTicketSummary,
  ListTicketDropdown,
  GetlistTicketForDashboardCancelToken,
  GetTicketSummaryCancelToken,
  ListTicketDropdownCancelToken,
} from "../../services/DashBoardServices";
import { TrimText } from "../common/TrimText";
import Hover from "../common/Hover";
import FilterComponent from "../common/FilterComponent";
import Tablefilter from "../common/Tablefilter";
import { RenderProgrammeStudentStatus, RenderTicketStatus } from "../../utils/CommonStatusItems";
import { RenderTicketLevel } from "../../utils/CommonGroupingItem";
import { handleTableScroll } from "../../utils/commonFunction";

function Summary({ Departments, AssignedTo, Category }) {
  const history = useHistory();
  const location = useLocation();
  const { type,tab } = useParams()
  const [summary, setSummary] = useState([
    type.charAt(0).toUpperCase() + type.slice(1),
  ]);
  const [summaryStatus, setSummaryStatus] = useState(true);
  const [value, onChange] = useState([]);
  const [valueCategory, onChangeCategory] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [statusIds, setStatusIds] = useState([]);
  const [intake, setIntake] = useState([]);
  const [intakeNum, setIntakeNum] = useState([]);
  const [studyType, setStudyType] = useState([]);
  const [isdashboard, setIsDashboard] = useState(false);
  const [dashboardList, setDashBoardList] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [department, setDepartment] = useState([]);
  const [departmentIds, setDepartmentIds] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryIds, setCategoryIds] = useState([]);
  const [assignTo, setAssignTo] = useState([]);
  const [assignToIds, setAssignToIds] = useState([]);
  const [level, setLevel] = useState({arr: [], checkObj: {}});
  const [levelIds, setLevelIds] = useState([]);
  const [studentData, setStudentData] = useState({ arr: [], checkObj: {} });
  const [studentDataIds, setStudentDataIds] = useState([]);
  const [courseDetails, setCourseDetails] = useState({});
  const [searchIntakeCheck, setSearchIntakeCheck] = useState({});
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [searchLearningCheck, setSearchLearningCheck] = useState({});
  const [searchQualificationCheck, setSearchQualificationCheck] = useState({});
  const [searchStudentDataCheck, setSearchStudentDataCheck] = useState({});
  const [searchDepartmentCheck, setSearchDepartmentCheck] = useState({});
  const [searchAssignToCheck, setSearchAssignToCheck] = useState({});
  const [searchLevelCheck, setSearchLevelCheck] = useState({});
  const [searchIntakeNumCheck, setSearchIntakeNumCheck] = useState({});
  const [searchCategoryCheck, setSearchCategoryCheck] = useState({});
  const [summarydata, setSummarydata] = useState([]);
  const [dropdownData, setDropdowndata] = useState({studentStatus: [], level: [], department: [], category: [], user: []});
  const [isCoursesList, setIsCoursesList] = useState(false);
  const [courses, setCourses] = useState([]);
  const [sortkey, setSortKey] = useState("date_add");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [ticketTab, setTicketTab] = useState([
    type.charAt(0).toUpperCase() + type.slice(1),
  ]);
  const bottomScroll = React.createRef();
  const [showing, setShowing] = useState([10]);
  const [searchShowing, setSearchShowing] = useState({ action41: true });

  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [debouncedTerm, setDebouncedTerm] = useState("")

  useEffect(() => {
    setPage(1);
  }, [location]);

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  // useEffect(() => {


  //   return () => {
  //     resetFilter()
  //   }
  // }, [summary])

  useEffect(() => {
    handleTableScroll()
  }, [location, loading]);

  useEffect(() => {
    const cancelTokenSources = [];

    const getDashboard = async () => {
      setLoading(true);  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const dataParam = {
        pageNo: page,
        size: perPage,
        viaStatus: statusIds,
        viaLevel: level.arr,
        viaStudentStatus: studentData.arr,
        viaDepartment: departmentIds,
        viaCategory: categoryIds,
        viaAssignedTo: assignToIds,
        viaSearch: search,
        key: sortkey,
        sort: sortOrder,
        exportStatus: false,
        viaTicket: ticketTab,
        showing: type === "all" ? showing : 0,
      };
  
      try {
        const res = await GetlistTicketForDashboardCancelToken(dataParam, source.token);
        if (res.status === 200) {
          setDashBoardList(res.data.data);
          setTotalRows(res.data.total);
          setIsDashboard(true);
          setCourses(res.data);
          setIsCoursesList(true);
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsDashboard(true);
          setIsCoursesList(true);
          setLoading(false);
        }
      }
    };
  
    getDashboard();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [
    page,
    perPage,
    studentData,
    searchStudentDataCheck,
    department,
    searchDepartmentCheck,
    category,
    searchCategoryCheck,
    assignTo,
    searchAssignToCheck,
    level,
    searchLevelCheck,
    status,
    searchStatusCheck,
    search,
    sortkey,
    sortOrder,
    ticketTab,
    categoryIds,
    assignToIds,
    showing
  ]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const fetchData = async () => {
      const data={
        viaTicket: ticketTab
      }
      try {
        const res = await ListTicketDropdownCancelToken(data, dropdownSource.token);
          if(res.status ==200){
            setDropdowndata({studentStatus:[], level:[], department:[], category:[], user:[]});
            setDropdowndata({studentStatus:res.data.studentStatus, level:res.data.level, department:res.data.departments, category:res.data.category, user:res.data.assignUser});
          }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [ticketTab]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const res = await GetTicketSummaryCancelToken(dropdownSource.token);
          if(res.status ==200){
            setSummaryStatus(false);
            setIsDashboard(true);
            setSummarydata(res && res.data);
          }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);


  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleDepartmentFilter = (e, data) => {
    const value = data.value;
    let arr = department;
    // let arrIds = departmentIds;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
      // arrIds.splice(arrIds.indexOf(data.value), 1);
    } else {
      arr.push(value);
      // arrIds.push(data.value);
    }
    setDepartment(arr);
    setDepartmentIds(arr);
    let id = e.target.id;

    setSearchDepartmentCheck({
      ...searchDepartmentCheck,
      [id]: !searchDepartmentCheck[id],
    });
  };
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateTicketsDashboard"));
    if (storedFilters) {
      setStudentData(storedFilters.studentData);
      setStudentDataIds(storedFilters.studentDataIds);
      setSearchStudentDataCheck(storedFilters.searchStudentDataCheck);
      setDepartment(storedFilters.department);
      setDepartmentIds(storedFilters.departmentIds);
      setSearchDepartmentCheck(storedFilters.searchDepartmentCheck);
      setStatus(storedFilters.status);
      setStatusIds(storedFilters.statusIds);
      setSearchStatusCheck(storedFilters.searchStatusCheck);
      setCategory(storedFilters.category);
      setCategoryIds(storedFilters.categoryIds);
      setSearchCategoryCheck(storedFilters.searchCategoryCheck);
      setAssignTo(storedFilters.assignTo);
      setAssignToIds(storedFilters.assignToIds);
      setSearchAssignToCheck(storedFilters.searchAssignToCheck);
      setLevel(storedFilters.level);
      setLevelIds(storedFilters.levelIds);
      setSearchLevelCheck(storedFilters.searchLevelCheck);
      onChange(storedFilters.value);
      onChangeCategory(storedFilters.valueCategory);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      studentData,
      studentDataIds,
      searchStudentDataCheck,
      department,
      departmentIds,
      searchDepartmentCheck,
      status,
      statusIds,
      searchStatusCheck,
      category,
      categoryIds,
      searchCategoryCheck,
      assignTo,
      assignToIds,
      searchAssignToCheck,
      level,
      levelIds,
      searchLevelCheck,
      value,
      valueCategory
    };
    localStorage.setItem("filterStateTicketsDashboard", JSON.stringify(filterState));
  }, [ studentData, studentDataIds, searchStudentDataCheck, department, departmentIds, searchDepartmentCheck, status, statusIds, searchStatusCheck, category, categoryIds, searchCategoryCheck, assignTo, assignToIds, searchAssignToCheck, level, levelIds, searchLevelCheck, value, valueCategory]);

  const resetFilter = () => {
    setSearch("");
    setDebouncedTerm("")

    setStudentData({ arr: [], checkObj: {} });
    setStudentDataIds([]);
    setSearchStudentDataCheck({});
    setDepartment([]);
    setDepartmentIds([]);
    setSearchDepartmentCheck({});
    setStatus([]);
    setStatusIds([]);
    setSearchStatusCheck({});
    setCategory([]);
    setCategoryIds([]);
    setSearchCategoryCheck({});
    setAssignTo([]);
    setAssignToIds([]);
    setSearchAssignToCheck({});
    setLevel({arr: [], checkObj: {} });
    setLevelIds([]);
    setSearchLevelCheck({});
    onChange([]);
    onChangeCategory([])
  };
  // data to render end

  // columns start

  const columns = useMemo(() => [
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      wrap: true,
      sortField: "subject",
      cell: (row) => (
        <a
          className="as-text-blue curser feature-name"
          href={`/editticket/${row.id_ticket}`}
        >
          <p className="textLimit100" title={row.subject}>
            {row.subject ? row.subject : "-"}
          </p>
        </a>
      ),
    },
    {
      name: "Submitted By",
      selector: "student_first_name",
      sortable: true,
      sortField: "student_first_name",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className={"assigned-title-blk  name-icon cat-dark-red "}>
              {row.imgUrl ? (
                <img
                  src={`${IMAGE_URL}/${row.imgUrl.replaceAll(
                    "/home/myaie/public_html/",
                    ""
                  )}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([row.student_first_name, row.student_last_name])
              )}
                <span className={`profile-box-2-status ${row.submited_activity_status ? row.submited_activity_status.toLowerCase()=="online" ? "Online" :row.submited_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                  <i className="fas fa-circle"></i>
                </span>
              <Hover
                firstName={row.studentname.split(" ")[0]}
                lastName={row.studentname.replace(/ +/g, " ").split(" ")[1]}
                photo={row.imgUrl}
                email={row?.student_email}
                mobile={row.student_mobile}
                right={true}
                status={row.submited_status} 
                activity_status={row.submited_activity_status}
                showNumber={true} 
                number={row.student_crm_id}
              />
            </span>
            <p
              className="as-text-blue curser textLimit51"
              title={row?.studentname}
              onClick={() =>
                history.push(
                  `/studentAdministration/students/open/${row.id_student}/personal`
                )
              }
            >
              {row?.studentname ? row?.studentname : "-"}
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      sortField: "status",
      cell: (row) => RenderTicketStatus(row.status).html,
    },

    {
      name: "Level",
      selector: "level",
      sortable: true,
      sortField: "level",
      cell: (row) =>row.level ? RenderTicketLevel(row.level).html : "-"
    },
    {
      name: "Student Status",
      selector: "student_status",
      sortable: true,
      sortField: "studentStatus",
      cell: (row) => row.student_status ? RenderProgrammeStudentStatus(row.student_status).html : "-"
    },
    {
      name: "Department",
      selector: "department",
      wrap: true,
      sortable: true,
      sortField: "department",
      cell: (row) => (
        <span className="feature-name" title={row.department}>
          {" "}
          <span className="textLimit100">{row.department}</span>
        </span>
      ),
    },
    {
      name: "#Overdue",
      selector: "dueDays",
      sortable: true,
      sortField: "dueDays",

      cell: (row) => <span className="days-cell-width"> {row.dueDays}</span>,
    },
    {
      name: "Created",
      selector: "date_add",
      sortable: true,
      sortField: "date_add",
      cell: (row) => (
        <>
          <p>{moment(row.date_add).format(TABLE_DATE_FORMAT + ",")}</p>
          <p>{moment(row.date_add).format(TABLE_TIME_FORMAT)}</p>
        </>
      ),
    },
    {
      name: "Modified",
      selector: "last_update",
      sortable: true,
      sortField: "last_update",
      cell: (row) => (
        <>
          <p>{moment(row.last_update).format(TABLE_DATE_FORMAT + ",")}</p>
          <p>{moment(row.last_update).format(TABLE_TIME_FORMAT)}</p>
        </>
      ),
    },
    {
      name: "Assigned To",
      selector: "Firstname",
      sortable: true,
      sortField: "Firstname",
      cell: (row) =>
        row.Firstname ? (
          <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span className={"assigned-title-blk  name-icon cat-dark-red "}>
                {row.picture_me ? (
                  <img
                    src={`${IMAGE_URL}/${row.picture_me.replaceAll(
                      "/home/myaie/public_html/",
                      ""
                    )}`}
                    alt="AIE"
                  />
                ) : (
                  InitialRender([
                    row.Firstname ? row.Firstname : "N",
                    row.Lastname ? row.Lastname : "A",
                  ])
                )}
                  <span className={`profile-box-2-status ${row.assign_to_activity_status ? row.assign_to_activity_status.toLowerCase()=="online" ? "Online" :row.assign_to_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                    <i className="fas fa-circle"></i>
                  </span>
                <Hover
                  firstName={row.assignName.split(" ")[0]}
                  lastName={row.assignName.replace(/ +/g, " ").split(" ")[1]}
                  photo={row.picture_me}
                  email={row?.Email}
                  mobile={row.Mobile}
                  right={false}
                  status={row.assign_to_status}
                  activity_status={row.assign_to_activity_status}
                />
              </span>
              <p
                className="as-text-blue curser textLimit51"
                title={row?.assignName}
                onClick={() =>
                  history.push(
                    `/systemAdministration/userManagement/open/${row.assign_to}/aboutYou`
                  )
                }
              >
                {row?.assignName ? row?.assignName : "NA"}
              </p>
            </div>
          </div>
        ) : (
          "-"
        ),
    },

    {
      name: "Category",
      selector: "category",
      wrap: true,
      sortable: true,
      sortField: "category",
      cell: (row) => (
        <span className="textLimit50" title={row.category}>
          {row.category ? row.category : "-"}
        </span>
      ),
    },
    {
      name: "Related To",
      selector: "cname",
      wrap: true,
      sortable: true,
      // sortField: "category",
      cell: (row) => (
        <>
          <span className="textLimit50" title={row.cname}>
            <Link
              className="as-text-blue curser textLimit100"
              to={`/courseAdministration/coursesdetails/${row.id_subject}/dashboard/show`}
            >
              {row.cname ? row.cname : "-"}
            </Link>
          </span>
        </>
      ),
    },

    {
      name: "Actions",
      selector: "Action",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              className="btn btn-primary rounded-circle"
              target={"_blank"}
              to={`/editticket/${row.id_ticket}`}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ]);
  // column end

  // export data start
  const exportData = (fileType, fileName) => {
    let data = [];
    const header = [
      "Id",
      "Subject",
      "Submitted By",
      "Status",
      "Level",
      "Student Status",
      "Department",
      "Days Overdue",
      "Created",
      "Modified",
      "Assigned To",
      "Category",
      "Related To"
    ];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });


    const dataParam = {
      pageNo: page,
      size: perPage,
      viaStatus: statusIds,
      viaLevel: level.arr,
      viaStudentStatus: studentData.arr,
      viaDepartment: departmentIds,
      viaCategory: categoryIds,
      viaAssignedTo: assignToIds,
      viaSearch: search,
      key: sortkey,
      sort: sortOrder,
      viaTicket: ticketTab,
      exportStatus: "true",
      showing: type === "all" ? showing : 0,
    };

    GetlistTicketForDashboard(dataParam)
      .then((res) => {
        data = res.data;
        data = data?.map((row) => ({
          Id: row.id_ticket,
          Subject: row.subject ? row.subject : "-",
          "Submitted By": row.student_first_name
            ? row.student_first_name + " " + row.student_last_name
            : "-",
          Status: row.status ? row.status : "-",
          Level: row.level ? RenderTicketLevel(row.level).text : "-",
          "Student Status": row.student_status ? RenderProgrammeStudentStatus(row.student_status).text : "-",
          Department: row.department ? row.department : "-",
          "Days Overdue": row.dueDays,
          Created: row.date_add
            ? `${moment(row.date_add).format(TABLE_DATE_FORMAT)}`
            : "-",
          Modified: row.last_update
            ? `${moment(row.last_update).format(TABLE_DATE_FORMAT)}`
            : "-",
          "Assigned To": row.Firstname
            ? row.Firstname + " " + row.Lastname
            : "-",
          Category: row.category ? row.category : "-",
          "Related To": row.cname ? row.cname : "-"
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });

          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {

          const compatibleData = data.splice(data.length - 100, 100).map(
            (row) => {
              return [
                row["Id"],
                row["Subject"],
                row["Submitted By"],
                row["Status"],
                row["Level"],
                row["Student Status"],
                row["Department"],
                row["Days Overdue"],
                row["Created"],
                row["Modified"],
                row["Assigned To"],
                row["Category"],
                row["Related To"]
              ];
            }
          );
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });

          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => console.error(err));
  };
  // export data end

  const handleShowingFilter = (e) => {
    const value = e.target.value;
    if (showing.includes(value)) {
      setShowing([]);
    } else {
      setShowing([value]);
    }

    let id = e.target.id;
    if (id == "action41") {
      setSearchShowing({ action41: !searchShowing.action41 });
    } else {
      setSearchShowing({
        [id]: !searchShowing[id],
        action41: false,
      });
    }
  };

  return (
    <>
      {/* tabs start */}
      <div className="tabs-wrap">
        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
          <li
            className="nav-item"
            onClick={() => {
              setSummary(["Summary"]);
              history.push("/home/tickets/summary");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "summary" ? "active" : ""
                }`}
              id="pills-tab32"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab32"
              aria-selected="true"
            >
              <i className="fal fa-books"></i>Summary
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setTicketTab(["all"]);
              setSummary(["all"]);
              history.push("/home/tickets/all");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "all" ? "active" : ""
                }`}
              id="pills-tab2"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab12"
              aria-selected="true"
            >
              <i className="fal fa-globe-africa"></i>All Tickets
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setTicketTab(["TotalDangerTicket"]);
              setSummary(["TotalDangerTicket"]);
              history.push("/home/tickets/totalDangerTicket");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "totalDangerTicket" ? "active" : ""
                }`}
              id="pills-tab2"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab12"
              aria-selected="true"
            >
              <i className="fal fa-file-exclamation"></i>Tickets in Danger Zone
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setSummary(["TotalNewTicket"]);
              setTicketTab(["TotalNewTicket"]);
              history.push("/home/tickets/totalNewTicket");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "totalNewTicket" || type === "TotalNewTicket"
                ? "active"
                : ""
                }`}
              id="pills-tab3"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab13"
              aria-selected="true"
            >
              <i className="fal fa-laptop"></i>New
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setTicketTab(["TotalPendingAcademyTicket"]);
              setSummary(["TotalPendingAcademyTicket"]);
              history.push("/home/tickets/totalPendingAcademyTicket");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "totalPendingAcademyTicket" ||
                type === "TotalPendingAcademyTicket"
                ? "active"
                : ""
                }`}
              id="pills-tab1"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab11"
              aria-selected="true"
            >
              <i className="fal fa-file-spreadsheet"></i>
              Pending-Academy
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setTicketTab(["TotalPendingStudentTicket"]);
              setSummary(["TotalPendingStudentTicket"]);
              history.push("/home/tickets/totalPendingStudentTicket");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "totalPendingStudentTicket" ? "active" : ""
                }`}
              id="pills-tab1"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab11"
              aria-selected="true"
            >
              <i className="fal fa-file-alt"></i>
              Pending Student
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setTicketTab(["Pending-Investigation"]);
              setSummary(["Pending-Investigation"]);
              history.push("/home/tickets/Pending-Investigation");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "Pending-Investigation" ? "active" : ""
                }`}
              id="pills-tab1"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab11"
              aria-selected="true"
            >
              <i className="fal fa-file-search"></i>
              Pending-Investigation
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setTicketTab(["TotalClosedTicket"]);
              setSummary(["TotalClosedTicket"]);
              history.push("/home/tickets/totalClosedTicket");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${type === "totalClosedTicket" || type === "TotalClosedTicket"
                ? "active"
                : ""
                }`}
              id="pills-tab1"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab11"
              aria-selected="true"
            >
              <i className="fal fa-file-check"></i>
              Tickets Closed
            </a>
          </li>
        </ul>
      </div>

      {summary != "Summary" ? (
        <div
          className={`custom-table-div filter-search-icon card-table-custom new-table-box days-overdue-cell-width`}
        >
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={e => setDebouncedTerm(e.target.value)}
                          value={debouncedTerm}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="filter-scroll">
                    <div className={`filter-scroll-inner  filter-custom-new`}>
                    {type == "all" && (
                      <div className="assessment-table-filter">
                        <div className="btn-type-filter dropdown-comman">
                          <div className="dropdown multiselect">
                            <button
                              className={`btn btn-default dropdown-toggle ${showing.length ? "btn-selected" : ""
                                }`}
                              type="button"
                              id="dropdownMenu5"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              title="Showing"
                            >
                              <span>
                                Showing <i className="fal fa-angle-down"></i>
                              </span>
                            </button>
                            <form className="dropdown-menu remove-list-style">
                              <ul aria-labelledby="dropdownMenu5">
                                <li className="">
                                  <input
                                    type="checkbox"
                                    id="action41"
                                    value="10"
                                    onClick={handleShowingFilter}
                                    checked={searchShowing["action41"]}
                                  />
                                  <label htmlFor="action41">
                                    <span className="cat ">10 days</span>
                                  </label>
                                </li>
                                <li className="">
                                  <input
                                    type="checkbox"
                                    id="action42"
                                    value="20"
                                    onClick={handleShowingFilter}
                                    checked={searchShowing["action42"]}
                                  />
                                  <label htmlFor="action42">
                                    <span className="cat ">20 days</span>
                                  </label>
                                </li>
                                <li className="">
                                  <input
                                    type="checkbox"
                                    id="action43"
                                    value="30"
                                    onClick={handleShowingFilter}
                                    checked={searchShowing["action43"]}
                                  />
                                  <label htmlFor="action43">
                                    <span className="cat ">30 days</span>
                                  </label>
                                </li>
                                <li className="">
                                  <input
                                    type="checkbox"
                                    id="action45"
                                    value="90"
                                    onClick={handleShowingFilter}
                                    checked={searchShowing["action45"]}
                                  />
                                  <label htmlFor="action45">
                                    <span className="cat ">90 days</span>
                                  </label>
                                </li>
                                <li className="">
                                  <input
                                    type="checkbox"
                                    id="action44"
                                    value="-1"
                                    onClick={handleShowingFilter}
                                    checked={searchShowing["action44"]}
                                  />
                                  <label htmlFor="action44">
                                    <span className="cat ">All</span>
                                  </label>
                                </li>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                    )}
                      <Tablefilter
                        filterName="Level"
                        optionArr={dropdownData.level}
                        state={level}
                        setState={setLevel}
                        renderLabelFunction={RenderTicketLevel}
                      />
                      <Tablefilter
                        filterName="Student Status"
                        optionArr={dropdownData.studentStatus}
                        state={studentData}
                        setState={setStudentData}
                        renderLabelFunction={RenderProgrammeStudentStatus}
                      />
                      {/* End of Course filter */}

                      {/* Qualification filter */}
                      <div className="assessment-table-filter">
                        <div className="btn-type-filter dropdown-comman">
                          <div className="dropdown multiselect">
                            <button
                              className={`btn btn-default dropdown-toggle ${department.length ? "btn-selected" : ""
                                } `}
                              type="button"
                              id="dropdownMenu1"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                              title="Department"
                            >
                              <span>
                                Department <i className="fal fa-angle-down"></i>
                              </span>
                            </button>
                            <form className="dropdown-menu remove-list-style">
                              <ul aria-labelledby="dropdownMenu1">
                                {dropdownData && dropdownData.department.length &&
                                  dropdownData.department.map((data, index) => (
                                    <li
                                      className={"cat "}
                                      key={index}
                                      title={data.label}
                                    >
                                      <input
                                        type="checkbox"
                                        id={"department" + index}
                                        value={data.value}
                                        onClick={(e) => {
                                          handleDepartmentFilter(e, data);
                                        }}
                                        checked={
                                          searchDepartmentCheck[
                                          "department" + index
                                          ]
                                        }
                                      />
                                      <label htmlFor={"department" + index}>
                                        <span className="cat ">
                                          {" "}
                                          {TrimText(data.label, 20)}
                                        </span>
                                      </label>
                                    </li>
                                  ))}
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* End of Qualification filter */}

                      {/* Intake year filter */}
                      <div className="assessment-table-filter">
                        <div className="btn-type-filter dropdown-comman assesment-dropdown">
                          <div className="dropdown multiselect">
                            <button
                              className={`btn btn-default dropdown-toggle ${value?.length ? "btn-selected" : ""
                                } `}
                              type="button"
                              id="dropdownMenu1"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                              title="Assigned To"
                            >
                              <span>
                                Assigned To <i className="fal fa-angle-down"></i>
                              </span>
                            </button>
                            <form className="dropdown-menu remove-list-style">
                              <ul aria-labelledby="dropdownMenu1">
                                <div className="dropdown-filter-pagination">
                                  {dropdownData && dropdownData.user && dropdownData.user.length ? (
                                    <FilterComponent
                                      value={value}
                                      onChange={onChange}
                                      dataResponse={dropdownData.user}
                                      backendResponse={setAssignToIds}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="assessment-table-filter">
                        <div className="btn-type-filter dropdown-comman assesment-dropdown">
                          <div className="dropdown multiselect">
                            <button
                              className={`btn btn-default dropdown-toggle ${valueCategory?.length ? "btn-selected" : ""
                                } `}
                              type="button"
                              id="dropdownMenu1"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                              title="Category"
                            >
                              <span>
                                Category <i className="fal fa-angle-down"></i>
                              </span>
                            </button>
                            <form className="dropdown-menu remove-list-style">
                              <ul aria-labelledby="dropdownMenu1">
                                <div className="dropdown-filter-pagination">
                                  {dropdownData && dropdownData.category && dropdownData.category.length ? (
                                    <FilterComponent
                                      value={valueCategory}
                                      onChange={onChangeCategory}
                                      dataResponse={dropdownData.category}
                                      backendResponse={setCategoryIds}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>

                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "TicketSummary");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "TicketSummary");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "TicketSummary");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DataTable
            paginationDefaultPage={page}
            data={dashboardList}
            columns={columns}
            defaultSortAsc={false}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            defaultSortField="date_add"
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
            onSort={handleSort}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        </div>
      ) : (
        <div className="row">
          <div className="col-md-6">
            <div className="card-new card card-profile-info-card">
              <div className="row ticket-detail-container">
                <div className="col-lg-6 ticket-detail-section">
                  <div className="ticket-detail-item danger-ticket-item">
                    <span className="danger-ticket title">
                      Total Danger Zone Tickets
                    </span>
                    <span className="data danger-ticket">
                      {summarydata?.TotalDangerTicket > -1
                        ? summarydata?.TotalDangerTicket
                        : <i className="fas fa-cog fa-spin"></i>}
                    </span>
                  </div>
                  <div className="ticket-detail-item">
                    <span className="title">
                      Total New Tickets
                    </span>
                    <span className="data">
                      {summarydata?.TotalNewTicket > -1
                        ? summarydata?.TotalNewTicket
                        : <i className="fas fa-cog fa-spin"></i>}
                    </span>
                  </div>
                  <div className="ticket-detail-item">
                    <span className="title">
                      Total Tickets Closed
                    </span>
                    <span className="data">
                    {summarydata?.TotalClosedTicket > -1 ? summarydata?.TotalClosedTicket
                     :<i className="fas fa-cog fa-spin"></i>}
                    </span>
                  </div>
                </div>

                <div className="col-lg-6 ticket-detail-section">
                  <div className="ticket-detail-item">
                    <span className="title">
                      Total Pending - Academy
                    </span>

                    <span className="data">
                      {summarydata?.TotalPendingAcademyTicket > -1
                        ? summarydata?.TotalPendingAcademyTicket
                        : <i className="fas fa-cog fa-spin"></i>}
                    </span>
                  </div>
                  <div className="ticket-detail-item">
                    <span className="title">
                      Total Pending - Investigation
                    </span>

                    <span className="data">
                      {summarydata?.TotalPendingAcademyTicket > -1
                        ? summarydata?.TotalPendingAcademyTicket
                        : <i className="fas fa-cog fa-spin"></i>}
                    </span>
                  </div>
                  <div className="ticket-detail-item">
                    <span className="title">
                      Total Pending - Student
                    </span>
                    <span className="data">
                      {summarydata?.TotalPendingStudentTicket > -1
                        ? summarydata?.TotalPendingStudentTicket
                        : <i className="fas fa-cog fa-spin"></i>}
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4">
                <div className="ticket-response-section">
                  <h5>New Ticket Response Time</h5>
                  <div class="clock-box"><i class="fal fa-clock as-red"></i></div>
                  <p>Our average Response Time</p>
                  {!summarydata?.new_ticket_response ? (
                    <span class="date-time-box"><i className="fas fa-cog fa-spin"></i></span>
                  ) : (
                    <span className="date-time-box as-red">
                      {`${((summarydata?.new_ticket_response?.d && summarydata?.new_ticket_response?.d!="") ? summarydata?.new_ticket_response?.d.toString().split(".")[0] : "0") + "d"}
                      : ${((summarydata?.new_ticket_response?.h && summarydata?.new_ticket_response?.h!="") ? summarydata?.new_ticket_response?.h.toString().split(".")[0] : "0") + "h"}
                      : ${((summarydata?.new_ticket_response?.m && summarydata?.new_ticket_response?.m!="") ? summarydata?.new_ticket_response?.m.toString().split(".")[0] : "0") + "m"}
                      : ${((summarydata?.new_ticket_response?.s && summarydata?.new_ticket_response?.s!="") ? summarydata?.new_ticket_response?.s.toString().split(".")[0] : "0") + "s"}`}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
              <div className="ticket-response-section">
            <h5>Pending-Academy Response Time</h5>
            <div class="clock-box"><i class="fal fa-clock as-red"></i></div>
            <p>Our average Response Time</p>
            {!summarydata?.pending_ticket_response ? (
              <span class="date-time-box"><i className="fas fa-cog fa-spin"></i></span>
            ) : (
              <span className="as-red date-time-box">
                {`${((summarydata?.pending_ticket_response?.d && summarydata?.pending_ticket_response?.d!="") ? summarydata?.pending_ticket_response?.d.toString().split(".")[0] : "0") + "d"}
                : ${((summarydata?.pending_ticket_response?.h && summarydata?.pending_ticket_response?.h!="") ? summarydata?.pending_ticket_response?.h.toString().split(".")[0] : "0") + "h"}
                : ${((summarydata?.pending_ticket_response?.m && summarydata?.pending_ticket_response?.m!="") ? summarydata?.pending_ticket_response?.m.toString().split(".")[0] : "0") + "m"}
                : ${((summarydata?.pending_ticket_response?.s && summarydata?.pending_ticket_response?.s!="") ? summarydata?.pending_ticket_response?.s.toString().split(".")[0] : "0") + "s"}`}                
              </span>
            )}
          </div>
              </div>
            </div>
          </div>

          
        </div>
      )}
      {/* tabs end */}
    </>
  );
}
export default Summary;
