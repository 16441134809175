import React, { useState } from 'react'
import $ from "jquery";

function ClassSeriesPopup({ popId, heading, message, onSubmit }) {
    const [selectedValue, setSelectedValue] = useState(1);
    return (
        <div
            className="topic-add-modal modal able_modal01 fade"
            id={popId}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body p-0">
                        <div className="modal-header modal-header-custom">
                            <h5 className="modal-title"><i className="fal fa-folder-open"></i> {heading}</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="t p-30px">
                            <h6 className="font-weight-bold">{message}</h6>
                            <div className="row popup-zindex">
                                <div className="col-md-12">
                                    <div
                                        className="custom-control custom-radio custom-control-inline"
                                        title="Just this one"
                                    >
                                        <input
                                            type="radio"
                                            className="custom-control-input cursor-pointer"
                                            id="Just this one"
                                            name="editData"
                                            // checked={selectedValue === 0}
                                            onChange={() => {
                                                setSelectedValue(0)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="Just this one">
                                            Just this one
                                        </label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div
                                        className="custom-control custom-radio custom-control-inline"
                                        title="The entries series"
                                    >
                                        <input
                                            type="radio"
                                            className="custom-control-input cursor-pointer"
                                            id="The entries series"
                                            name="editData"
                                            // checked={selectedValue === 1}
                                            onChange={() => {
                                                setSelectedValue(1)
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor="The entries series">
                                            The entries series
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer d-block">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                $(`#${popId}`).modal("hide");
                                onSubmit(selectedValue)
                            }
                            } >
                            <i className="fal fa-check"></i>
                            Ok
                        </button>
                        <button
                            type="button"
                            className="btn btn-danger"
                            data-dismiss="modal"
                        >
                            <i className="fal fa-times"></i>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClassSeriesPopup