import React, { createRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ResizableTextarea from "../../common/ResizableTextarea";
import TextareaAutosize from "react-autosize-textarea";
import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { API_BASE, IMAGE_URL } from '../../../utils/Constants';
import { getToken, removeUserSession } from "../../../utils/Auth";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../../assets/css/filePond.css";


/** mention users in editor */
import { MentionsInput, Mention } from 'react-mentions'
import defaultStyle from '../../../utils/defaultStyle'
import defaultMentionStyle from '../../../utils/defaultMentionStyle'
import { InitalRender, originalValues } from "./../../common/Helper";

/** emoji plugin */
//import 'emoji-mart/css/emoji-mart.css'
//import { Picker } from 'emoji-mart'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, RichUtils, CompositeDecorator, ContentState, convertFromHTML, convertFromRaw } from "draft-js";
import HtmlInputEditor from "../../common/HtmlInputEditorChat";
import draftToHtml from "draftjs-to-html";
import { useRef, useContext } from "react";
import { MainContext } from "../../../../src/contexts/MainContext";
import Hover from "../../common/Hover";

registerPlugin(FilePondPluginImagePreview);


const FeedMessageForm = (props) => {
  const user = useSelector((state) => state.user);
  const [InptMessage, setInptMessage] = useState("");
  const postTxt = createRef();
  const btnAttachment = createRef();
  const filePondRef = createRef();
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [showFilePond, setShowFilePond] = useState(false);
  const [removeFile, setRemoveFile] = useState(null);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [copyText, setCopyText] = useState(0);
  const [isImportant, setIsImportant] = useState(false);
  const [users, setUsers] = useState('');
  const [showEditor, setShowEditor] = useState(false);
  const [resetFeed, setResetFeed] = useState(1);
  const [inputError, setInputError] = useState('');
  const [subjectLine, setSubjectLine] = useState('');
  const [showcurvalue, setShowcurValue] = useState(false)
  const { state, status, setStatus } = useContext(MainContext);

  const HANDLE_REGEX = /\@[\w]+/g;
  const styles = {
    editor: { border: "1px solid #ddd", cursor: "text", fontSize: 16, minHeight: "40px", padding: 10, background: "#000" },
    handle: { color: "rgba(98, 177, 254, 1.0)", direction: "ltr", unicodeBidi: "bidi-override" },
  };

  function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  useEffect(() => {
    if (props.taggingUsers && props.taggingUsers.length > 0) {
      let usersArr = props.taggingUsers
      usersArr.map(i => {
        i.text = i.display
        i.value = i.display
        i.url = i.id + "-" + i.type
      })
      setUsers(usersArr)
    }
  }, [props.taggingUsers]);

  // useEffect(()=>{
  //   if(attachments.length<1){
  //     console.log('the size of the attachments are ', attachments)
  //     setShowFilePond(false)
  //   }
  // },[attachments])
  const editorRef = useRef(null);
  const [editorShow, setStateEditorShow] = useState(false);
  const handleClickOutside = (event) => {
    if (editorRef.current && !editorRef.current.contains(event.target) && event.target.classList.contains('rdw-embedded-modal-btn') != true && event.target.classList.contains('rdw-suggestion-option') != true
    ) {
      setStateEditorShow(false)
      setAttachments([])
    } else if (editorRef.current && editorRef.current.contains(event.target) && event.target.classList.contains('new_discussion') == true) {
      setStateEditorShow(true)
    }
  }
  
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const HandleSpan = (props) => {
    return (
      <span style={styles.handle} data-offset-key={props.offsetKey}>
        {props.children}
      </span>
    );
  };

  function handleStrategy(contentBlock, callback, contentState) {
    findWithRegex(HANDLE_REGEX, contentBlock, callback);
  }

  const compositeDecorator = new CompositeDecorator([{ strategy: handleStrategy, component: HandleSpan }]);
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

  useEffect(() => {
    let tempBlock = convertToRaw(editorState.getCurrentContent())
    if ((tempBlock && tempBlock.blocks && tempBlock.blocks.length > 1) || (tempBlock && tempBlock.blocks && (tempBlock.blocks[0].text != ""))) {
      setInptMessage('yes')
    }
    else {
      setInptMessage('')
    }
  }, [editorState]);

  const handleInptMessage = (e) => {
    setInptMessage(e.target.value);
    props.TypingMessage();
  };

  const handleBtnMessage = () => {
    // const messageValue = convertToRaw(editorState.getCurrentContent());
    const messageValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let blocks = messageValue.blocks
    if (subjectLine == '') {
      setInputError('Please add subject')
    }
    else {

      // blocks.splice(0, 0, {
      //   data: {},
      //   depth: 0,
      //   entityRanges: [],
      //   inlineStyleRanges: [
      //     { offset: 0, length: subjectLine.length, style: 'BOLD' },
      //     { offset: 0, length: subjectLine.length, style: 'fontsize-16' }
      //   ],
      //   key: "1sjda",
      //   text: subjectLine,
      //   type: "unstyled"
      // })
      // blocks.splice(1, 0, {
      //   data: {},
      //   depth: 0,
      //   entityRanges: [],
      //   inlineStyleRanges: [],
      //   key: "1sjdb",
      //   text: '',
      //   type: "unstyled"
      // })
      // messageValue.blocks = blocks

      // const messageValueUpdated = JSON.stringify(messageValue);

      const entityMap = convertToRaw(editorState.getCurrentContent()).entityMap;
      const mentions = [];
      Object.values(entityMap).forEach(entity => {
        if (entity.type === 'MENTION') {
          mentions.push(entity.data.text);
        }
      });

      let messagpost = {
        subject: subjectLine,
        message: messageValue,
        files: attachments,
        sizes: sizes,
        important: isImportant,
        taggedUsers: mentions
      };

      props.pSendMessage(messagpost);
      setEditorState(EditorState.push(editorState, ContentState.createFromText('')))
      setInptMessage("");
      setFiles([]);
      setAttachments([]);
      setShowFilePond(false);
      setInputError('')
      setSubjectLine('')

      setResetFeed(Math.floor((Math.random() * 10000) + 1))
      setTimeout(() => {
        // setShowEditor(false)
        props.setShowNewStatus(false)
        setStateEditorShow(false)
      }, 1000);
    }
  };

  const handleAttahment = () => {
    setShowFilePond(true);
    btnAttachment.current.click();
  };
  const handleFiles = async (event) => {
    const ufiles = await event.target.files;
    if (ufiles.length > 0) {
      if (ufiles[0]) {
        // if(filePondRef.current) {
        filePondRef.current.addFile(ufiles[0])
          .then((file) => {
            console.log("success");
          })
          .catch((error) => console.log(error));
        // }
      }
    }
  };
  const handleUploadedFiles = (response) => {
    // setAttachments((attachments) => [...attachments, response]);
    let newObj = {}
    let blocks = isJsonString(response)
    if (blocks) {
      newObj = JSON.parse(response)
      setAttachments((attachments) => [...attachments, newObj.fullDocFilePath]);
      setSizes((pre) => [...pre, newObj.size]);
    }
  }

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const handlePaste = (e) => {
    if (e.clipboardData.types[0] == 'Files') {

      if (e.clipboardData.items == undefined) {
        return;
      };

      for (var i = 0; i < e.clipboardData.items.length; i++) {
        // Skip content if not image
        if (e.clipboardData.items[i].type.indexOf("image") == -1) continue;
        // Retrieve image on clipboard as blob
        var blob = e.clipboardData.items[i].getAsFile();
      }

      setTimeout(() => {
        filePondRef.current.addFile(blob)
      }, 1000);

      setShowFilePond(true);
      handleFilePondInit(e);
    }
  }
  const handleFilePondInit = () => {

  }
  const handleUserTagged = (user) => {
    const extendedList = taggedUsers;
    setTaggedUsers([...extendedList, user]);
  }
  const addEmoji = (emoji) => {
    const appendText = InptMessage + emoji.native;
    setInptMessage(appendText);

  }
  const copyEmailText = (e) => {
    setCopyText(1);
    navigator.clipboard.writeText(user.Email);
    setTimeout(() => {
      setCopyText(0)
    }, 1000);
  }

  const handleDragEnter = event => {
    setShowFilePond(true);
  };

  const updateSubjectLine = (e) => {
    setSubjectLine((e.target.value).trimLeft())
  }

  const attachmentsArrUpdate = (file) => {
    let tempAttach = attachments
    for (var i = 0; i < tempAttach.length; i++) {
      if (tempAttach[i].includes(file.split('.')[0])) {
        tempAttach.splice(i, 1);
        break;
      }
    }
    // console.log('tempAttach value would be ', tempAttach)
    if (tempAttach.length < 1) {
      setTimeout(() => {
        setShowFilePond(false)
        setShowcurValue(!showcurvalue)
      }, 500);
    }
  }

  const openStatus = () => {
    props.setShowNewStatus(true)
    // setShowEditor(true)
  }

  return (
    <>
      <div className="ps-fd-card-head mt-4 new-discussion-box">
        {/* <div className={props.showNewStatus ? " post-by" : "post-area reply-profile-hide"}> */}
        <div className={editorShow ? "post-by" : "post-by reply-profile-hide"}>
          <div className="post-area-left">

            <div className="post-area-img assigned-title-blk">
              <div className="post-by-img">
                {user.picture_me ?
                  <img src={IMAGE_URL + user.picture_me.replace("/home/myaie/public_html", "").replace("uploads/", "/uploads/")} alt={user.Firstname} />
                  :
                  InitalRender(originalValues(user.Firstname + " " + user.Lastname, 23))
                }
              </div>
              <span className={
                // "portal-status-icon online-u"
                `portal-status-icon ${status.toLowerCase() == 'online' ? 'online-u' : status.toLowerCase() == 'donotdisturb' ? 'donotdisturb-u' : 'offline-u'}`
                }></span>
              <Hover
                fullName={user.fullname}
                firstName={user.Firstname}
                lastName={user.Lastname}
                photo={user.picture_me}
                email={user.Email}
                mobile={user.Mobile}
                status={user.is_active}
                activity_status={user.Status}
                right={true}
                showNumber={user.student_crm_id ? true : false} 
                number={user.student_crm_id}
              />
            </div>
          </div>


          <div className="post-area-right filepond-height-fixed" ref={editorRef}>
            {/* {isImportant ? <i class="fas fa-check-circle cat-greenhex"></i> : null} */}
            {/* {props.showNewStatus ? */}
            {editorShow ?
              <div className="post-input-block no-bg-editor" onDragEnter={handleDragEnter}>
                <HtmlInputEditor
                  editorState={editorState}
                  setEditorState={setEditorState}
                  isInValid={editorState}
                  data={users}
                  placeholder='Start a new conversation with your class'
                  attachmentClick={handleAttahment}
                  sendClick={handleBtnMessage}
                  showToggle={true}
                  resetFeed={resetFeed}
                  subjectLine={subjectLine}
                  updateSubjectLine={(e) => updateSubjectLine(e)}
                  attachments={attachments}
                  setIsImportant={setIsImportant}
                  isImportant={isImportant}
                  // showEditor={showEditor}
                  // setShowEditor={setShowEditor}
                  showcurservalue={showcurvalue}
                />

                <input
                  type="file"
                  ref={btnAttachment}
                  style={{ display: "none" }}
                  onChange={(e) => handleFiles(e)}
                />
                {inputError != '' ? <div className="invalid-feedback d-block">{inputError}</div> : null}

                <div className={!showFilePond ? 'hideFilePond' : 'showFilePond'}>

                  <FilePond
                    name="post"
                    allowMultiple={true}
                    maxFiles={3}
                    ref={filePondRef}
                    files={files}
                    onupdatefiles={setFiles}
                    beforeRemoveFile={(f) => { setRemoveFile(f.file.name); attachmentsArrUpdate(f.file.name) }}
                    init={handleFilePondInit}
                    onaddfile={(err, fileItem) => {
                      setShowFilePond(true);

                    }}
                    server=
                    {
                      {
                        url: API_BASE,
                        process: {
                          url: '/addFeedAttachments',
                          headers: {
                            'Authorization': `Bearer ${getToken()}`
                          },
                          onload: (response) => {
                            handleUploadedFiles(response);
                          },
                          ondata: (formData) => {
                            formData.append('datasize', true);
                            return formData;
                          },
                        },
                        revert: {

                          url: `/removeFeedAttachments/${removeFile}`,
                          headers: {
                            'Authorization': `Bearer ${getToken()}`
                          },
                          onload: (response) => {
                            setRemoveFile(null);
                          }
                        }

                      }
                    }

                  />
                </div>
              </div>
              :
              <button onClick={() => { openStatus() }} className="btn btn-primary new_discussion" title="New Discussion"><i className="fal fa-plus"></i>New Discussion</button>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(FeedMessageForm);
