import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
//import moment from "moment";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Str from "../../common/Str";
import Swal from "sweetalert2";
import { GetListofBookingFiltersCancelToken, DeleteBooking, UpdateBookingStatus, GetListofBookings } from "../../../services/BookingServices";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../utils/permissionGate";
import { TABLE_ROWS_PER_PAGE, IMAGE_URL } from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Tablefilter from "../../common/Tablefilter";
import { handleTableScroll } from "../../../utils/commonFunction";
import { ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import { InitialRender } from "../../common/Helper";
import { RenderUserRole } from "../../../utils/CommonGroupingItem";
import { RenderBookingStatus } from "../../../utils/CommonStatusItems";
import Hover from "../../common/Hover";
import $ from "jquery";
import axios from "axios";


const ListofBookings = () => {

    const history = useHistory();
    const [bookingData, setBookingData] = useState([]);
    const [latestInfo, setLatestInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [updateData, setUpdateData] = useState(true);
    // const [originalData, setOriginalData] = useState([]);
    const [totalRows, setTotalRows] = useState(0)
    const [dummy, setDummy] = useState(false);
    const [roles, setRoles] = useState({ arr: [], checkObj: {} });
    const [rooms, setRooms] = useState({ arr: [], checkObj: {} });
    const [linkedCourses, setLinkedCourses] = useState({ arr: [], checkObj: {} });
    const [status, setStatus] = useState({ arr: [], checkObj: {} });
    const [filterData, setFilterData] = useState({ roles: [], rooms: [], linkedCourses: [], status: [] })
    const [deletedId, setDeletedId] = useState();
    const [statusUpdated, setStatusUpdated] = useState("");

    const [search, setSearch] = useState("");

    const userData = useSelector((state) => state.user);


    useEffect(() => {
        handleTableScroll()
    }, [loading])

    useEffect(() => {
        $(document).ready(function () {
            $(".dropdown-toggle").click(function () {
                $('.rdt_TableCell').css('z-index', 0)
                $(this).parents('.rdt_TableCell').css('z-index', 22)
            });
        });
    })

    useEffect(() => {
        const storedFilters = JSON.parse(sessionStorage.getItem("filterBookingList"));
        if (storedFilters) {
          setRooms(storedFilters.rooms);
          setRoles(storedFilters.roles);
          setLinkedCourses(storedFilters.linkedCourses);
          setStatus(storedFilters.status);
        }
      }, []);
      useEffect(() => {
        const filterState = {
            roles,
            rooms,
            linkedCourses,
            status,
        };
          sessionStorage.setItem('filterBookingList', JSON.stringify(filterState));
      }, [roles, rooms, linkedCourses, status]);

    useEffect(() => {
        const cancelTokenSources = [];

        const getBookings = async () => {
            setLoading(true);

            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            try {
                const res = await GetListofBookings();
                setBookingData(res.data.listBooking);
                setLatestInfo(res.data.latestInfos);
                setTotalRows(res.data.total)
                if (res.status === 200) {
                    setLoading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    setLoading(false);
                }
            }
        };

        getBookings();

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [updateData, deletedId, statusUpdated]);

    useEffect(() => {
        const filterSource = axios.CancelToken.source();
        const getFilters = async () => {
            try {
                const res = await GetListofBookingFiltersCancelToken(filterSource.token);
                if (res.status == 200) {

                    setFilterData({
                        roles: res.data.roleFilters,
                        rooms: res.data.roomNoFilters,
                        linkedCourses: res.data.linkedCourseFilters,
                        status: res.data.bookingStatusFilters
                    })
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        }

        getFilters()
        return () => {
            filterSource.cancel('Component unmounted');
        };
    }, [])

    const myNameFilter = (a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
    };

    const dataToRender = () => {
        let updatedData = [];
        let allData = bookingData;
       // console.log(allData);
        if (search.length) {
          let tempBookingName = allData.filter((item) => {
            let includes =
              item.name &&
              item.name.toString().toLowerCase().includes(search.toLowerCase());
            if (includes) {
              return includes;
            } else return null;
          });
          let tempFullName = allData.filter((item) => {
            let includes =
              item.user_full_name &&
              item.user_full_name.toString().toLowerCase().includes(search.toLowerCase());
            if (includes) {
              return includes;
            } else return null;
          });
          let tempCampus = allData.filter((item) => {
            let includes =
              item.campus &&
              item.campus.toString()
                .toLowerCase()
                .includes(search.toLowerCase());
            if (includes) {
              return includes;
            } else return null;
          });
          let tempLinkedCourse = allData.filter((item) => {
            let includes =
              item.linked_course &&
              item.linked_course.toString().toLowerCase().includes(search.toLowerCase());
            if (includes) {
              return includes;
            } else return null;
          });
          let tempdata = [
            ...tempFullName,
            ...tempCampus,
            ...tempLinkedCourse,
            ...tempBookingName,
          ];
          let unique = [...new Set(tempdata)];
          updatedData = unique;
        } else {
          updatedData = allData;
        }
        if (search.length) {
          return updatedData;
        }
        if (roles.arr.length) {
          let tempRole = updatedData;
          let tempSearch = tempRole.filter((item) => {
            const startsWith = roles.arr.find(function (post, index) {
              if (
                post.toLowerCase() ==
                (item.role_name ? item.role_name.toLowerCase() : "")
              )
                return item;
            });
            if (startsWith) {
              return startsWith;
            } else return null;
          });
    
          updatedData = tempSearch;
        }
        if (rooms.arr.length) {
            let tempRoom = updatedData;
            let tempSearch = tempRoom.filter((item) => {
              const startsWith = rooms.arr.find(function (post, index) {
                if (
                  post.toLowerCase() ==
                  (item.room_no ? item.room_no.toLowerCase() : "")
                )
                  return item;
              });
              if (startsWith) {
                return startsWith;
              } else return null;
            });
      
            updatedData = tempSearch;
        }
        if (linkedCourses.arr.length) {
            let tempLinkedCourse = updatedData;
            let tempSearch = tempLinkedCourse.filter((item) => {
              const startsWith = linkedCourses.arr.find(function (post, index) {
                if (
                  post.toLowerCase() ==
                  (item.linked_course ? item.linked_course.toLowerCase() : "")
                )
                  return item;
              });
              if (startsWith) {
                return startsWith;
              } else return null;
            });
      
            updatedData = tempSearch;
        }
        if (status.arr.length) {
            let tempStatus = updatedData;
            let tempSearch = tempStatus.filter((item) => {
              const startsWith = status.arr.find(function (post, index) {
                if (
                  post.toLowerCase() ==
                  (item.booking_status ? item.booking_status.toLowerCase() : "")
                )
                  return item;
              });
              if (startsWith) {
                return startsWith;
              } else return null;
            });
      
            updatedData = tempSearch;
        }
        if (search.length || roles.arr.length || rooms.arr.length || linkedCourses.arr.length || status.arr.length) {
          return updatedData;
        } else {
          return bookingData;
        }
      };

      const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearch(value);
      };
    
      const resetFilter = () => {
        setSearch("");
        setLinkedCourses({ arr: [], checkObj: {} });
        setRoles({ arr: [], checkObj: {} });
        setRooms({ arr: [], checkObj: {} });
        setStatus({ arr: [], checkObj: {} });
        
      };

    const exportData = (fileType, fileName) => {
        let data = dataToRender();
        const header = [
            "Name",
            "Requested By",
            "Role",
            "Venue",
            "Campus",
            "Date & Time",
            "Linked Course",
            "Status"
        ];
        
        data = data?.map((row) => ({
            ...row,
            "Name": row?.name ? row.name : "-",
            "Requested By": row?.user_full_name ? row.user_full_name : "-",
            "Role": row?.role_name ? RenderUserRole(row.role_name).text : "-",
            "Venue": row?.room_no ? row.room_no : "-",
            "Campus": row?.campus ? RenderUserRole(row.campus).text : "-",
            "Date & Time": (() => {
                if (row.booking_date && row.booking_start_time && row.booking_end_time) {
                    const bookingDate = new Date(row.booking_date);
                    const options = {
                        weekday: "long",
                        day: "numeric",
                        month: "short",
                        year: "2-digit",
                    };
        
                    const formattedDate = bookingDate.toLocaleDateString("en-US", options);
                    const startTime = row.booking_start_time.substring(0, 5); // Extracting hours and minutes
                    const endTime = row.booking_end_time.substring(0, 5);
        
                    return `${formattedDate}\n${startTime} - ${endTime}`;
                } else {
                    return "-";
                }
            })(),
            "Linked Course" : row?.linked_course ? row.linked_course : "-",
            "Status": row?.booking_status ? RenderBookingStatus(row.booking_status).text : "-"
        }))
        

          if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
      
            const blobURL = window.URL.createObjectURL(blob);
      
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = [
              "text/csv",
              anchor.download,
              anchor.href,
            ].join(":");
            anchor.click();
      
            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              URL.revokeObjectURL(blobURL);
            }, 1000);
          } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.forEach((col) => {
                    obj[col] = row[col];
                });
                return obj;
            });
      
            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
              header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);
      
            // Returning false as downloading of file is already taken care of
            return false;
          }
          if (fileType === "pdf") {
            const compatibleData = data.map((row) => {
              return [
                row["Name"],
                row["Requested By"],
                row["Role"],
                row["Venue"],
                row["Campus"],
                row["Date & Time"],
                row["Linked Course"],
                row["Status"]
              ];
            });
            const doc = new JsPDF();
            doc.autoTable({
              head: [header],
              body: compatibleData,
              styles: {
                minCellHeight: 10,
                minCellWidth: 5,
                halign: "left",
                // valign: "center",
                fontSize: 8,
              },
            });
            doc.save(`${fileName}.pdf`);
      
            return false;
          }
    };

    const handleDelete = (ID) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            DeleteBooking(ID)
              .then((res) => {
                Swal.fire("Deleted!", "Your booking has been deleted.", "success");
                setDummy(!dummy);
                setDeletedId(ID);
              })
              .catch((error) => console.log(error));
          }
        });
      };

      const handleUpdateStatus = async (row, status, userData) => {

        const { id, additional_information } = row;
        
        const updateData = {
            id: id,
            additional_information: additional_information,
            booking_status: status,
            user_id: userData.UserID,
            user_role: userData.role_name
        };
    
        const result = await Swal.fire({
            title: status === "APPROVED" ? "Approve Booking?" : "Reject Booking?",
            text: status === "APPROVED" ? "You are going to approve this booking!" : "You are going to reject this booking!",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: status === "APPROVED" ? "Yes, approve it!" : "Yes, reject it!",
        });
    
        if (result.isConfirmed) {
            try {
                
                await UpdateBookingStatus(updateData);

                if (status === "APPROVED") {
                    Swal.fire("Approved!", "This booking has been approved.", "success");
                } else {
                    Swal.fire("Rejected!", "This booking has been rejected.", "success");
                }
                //setDummy(!dummy);
                setStatusUpdated(status);
            } catch (error) {
                console.error(error);
            }
        }
    };
    
    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "name",
            sortable: true,
            wrap: true,
            sortFunction: myNameFilter,
            cell: (row) => {
                return (
                    <PermissionsGate
                        scopes={["pmcpedit"]}
                        RenderError={() => (
                            <span title={row.name} className="feature-name">
                                {" "}
                                <span className="textLimit100">{row.name}</span>
                            </span>
                        )}
                    >
                        <Link
                            to={`/campusAdministration/bookings/edit/${row.id}`}
                            title={row.name}
                            className="as-text-blue curser feature-name"
                        >
                            <span title={row.name} className="textLimit100">
                                {row.name}
                            </span>
                        </Link>
                    </PermissionsGate>
                );
            },
        },

        {
            name: "Requested By",
            selector: "user_full_name",
            sortField: "user_full_name",
            sortable: true,
            cell: (row) =>
                row.user_first_name ? (
                    <div className="reports-profile-view assigned-title-block-new">
                        <div className="assigned-title-itm">
                            <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(row.role_name)}>
                                {row.picture_me ? (
                                    <img src={`${IMAGE_URL}/${row.picture_me.replace("/home/myaie/public_html/", "")}`} alt="AIE" />
                                ) : (
                                    InitialRender([row.user_first_name, row.user_last_name])
                                )}
                                <span className={`profile-box-2-status ${row.user_active_status ? row.user_active_status.toLowerCase() == "online" ? "Online" : row.user_active_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                    <i className="fas fa-circle"></i>
                                </span>
                                <div>
                                <Hover
                                    firstName={row.user_full_name.split(" ")[0]}
                                    lastName={row.user_full_name.replace(/ +/g, " ").split(" ")[1]}
                                    photo={row.picture_me}
                                    email={row.user_email}
                                    mobile={row.user_phone ? row.user_phone : ""}
                                    right={false}
                                    status={row.user_status}
                                    activity_status={row.user_active_status}
                                    role={row.role_name}
                                    style={{left:"-150px"}}
                                />
                                </div>
                            </span>
                            <PermissionsGate scopes={['umedit']} RenderError={() => <p>{row.user_first_name}</p>}>
                                <span
                                    title={row.user_full_name}
                                    className="as-text-blue curser feature-name width-155"
                                    onClick={() =>
                                        history.push(
                                            `/systemAdministration/userManagement/open/${row.user_id}/aboutYou`
                                        )
                                    }
                                >
                                    <span className="textLimit100">{row.user_full_name ? row["user_full_name"] : "-"}</span>
                                </span>
                            </PermissionsGate>
                        </div>
                    </div>
                ) : (
                    "-"
                ),
        },

        {
            name: "Role",
            selector: "role_name",
            sortField: "role",
            sortable: true,
            cell: (row) => row.role_name ? RenderUserRole(row.role_name).html : "-",
        },

        {
            name: "Venue",
            selector: "room_no",
            sortField: "room",
            sortable: true,
            cell: (row) => row.room_no ? row.room_no : "-",
        },

        {
            name: "Campus",
            selector: "campus",
            sortField: "campus",
            sortable: true,
            cell: (row) => row.campus ?
                <span>
                    <i className="fal fa-university">
                    </i>
                    &nbsp;
                    {row.campus}
                </span>
                : "-",
        },

        {
            name: "Date & Time",
            selector: "booking_date",
            sortField: "booking_date",
            sortable: true,
            cell: (row) => {
                if (row.booking_date && row.booking_start_time && row.booking_end_time) {
                    const bookingDate = new Date(row.booking_date);
                    const options = {
                        weekday: "long",
                        day: "numeric",
                        month: "short",
                        year: "2-digit",
                    };

                    const formattedDate = bookingDate.toLocaleDateString("en-US", options);
                    const startTime = row.booking_start_time.substring(0, 5); // Extracting hours and minutes
                    const endTime = row.booking_end_time.substring(0, 5);

                    return (
                        <span>
                            {formattedDate}
                            <br />
                            {startTime} - {endTime}
                        </span>
                    );
                } else {
                    return "-";
                }
            }
        },

        {
            name: "Linked Course",
            selector: "linked_course",
            sortField: "linked_course",
            sortable: true,
            cell: (row) => (
                <div className="assigned-title-block-new">
                    <div
                        className="assigned-title-itm overflow-ellipsis2 fst_line2"
                        title={row.linked_course}
                    >
                        {/* <PermissionsGate scopes={["capview"]} errorProps={{ disabled: true }}> */}
                        <Link
                            className="as-text-blue curser textLimit2"
                            to={`/courseAdministration/coursesdetails/${row.linked_course_id}/detailCourses/show`}
                            title={row.linked_course}
                        >
                            {row.linked_course ? row.linked_course : "-"}
                        </Link>


                        {/* </PermissionsGate> */}
                    </div>
                </div>
            ),
        },

        {
            name: "Status",
            selector: "booking_status",
            sortField: "booking_status",
            sortable: true,
            cell: (row) => {
                if (row.booking_status) {
                    let statusText = RenderBookingStatus(row.booking_status).html;
                    const bookingId = row.id;
                    let additionalInfo = latestInfo.find(info => info.booking_id === bookingId)?.additional_info || "-";
                    if (additionalInfo.length > 35) {
                        additionalInfo = additionalInfo.substring(0, 35) + "..." + " (See More)";
                    }
                    return (
                        <span>
                            {statusText} <br />
                            {additionalInfo}
                        </span>
                    );
                } else {
                    return "-";
                }
            },
        },

        {
            name: "Action",
            selector: "",
            // maxWidth: "50px",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons">
                    <PermissionsGate scopes={["cabmedit"]} errorProps={{ disabled: true }}>

                        <Link className="btn btn-primary rounded-circle" title="Open"  to={`/campusAdministration/bookings/edit/${row.id}`}>
                            <i className="fal fa-folder-open"></i>
                            </Link>
                            </PermissionsGate>
                        <div className="dropdown btn-dropdown-item">
                            <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fal fa-ellipsis-h-alt"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                               {/* <PermissionsGate scopes={["cabmedit"]} errorProps={{ disabled: true }}> */}
                                    <button
                                        className="btn btn-primary rounded-circle"
                                        title="Approve"
                                        data-toggle="modal"
                                        data-target="#notifications"
                                        onClick={() =>{
                                            let status = "APPROVED";
                                            if (userData) {
                                                handleUpdateStatus(row, status, userData);
                                            }else{
                                                console.log("User data still not loaded");
                                            }
                                        }}
                                    >
                                        <i className="fal fa-check-circle"></i>
                                    </button>
                                {/* </PermissionsGate> */}

                                {/* <PermissionsGate scopes={["cabmedit"]} errorProps={{ disabled: true }}> */}
                                    <button
                                        className="btn btn-primary rounded-circle"
                                        title="Reject"
                                        data-toggle="modal"
                                        data-target="#notifications"
                                        onClick={() =>{
                                            let status = "REJECTED";
                                            if (userData) {
                                                handleUpdateStatus(row, status, userData);
                                            }else{
                                                console.log("User data still not loaded");
                                            }
                                        }}
                                    >
                                        <i className="fal fa-times-circle"></i>
                                    </button>
                                {/* </PermissionsGate> */}

                                {/* <PermissionsGate scopes={["cabmdelete"]} errorProps={{ disabled: true }}> */}
                                    <button
                                        className="btn btn-danger rounded-circle"
                                        title="Delete"
                                        data-toggle="modal"
                                        data-target="#notifications"
                                        onClick={() => handleDelete(row.id)}
                                    >
                                        <i className="fal fa-trash-alt"></i>
                                    </button>
                                {/* </PermissionsGate> */}
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
    ], [userData, statusUpdated, latestInfo]);

    return (
        <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="custom-table-div filter-search-icon card card-table-custom program-table">
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                        <label>
                                            <input
                                                type="search"
                                                className=""
                                                placeholder="Search"
                                                aria-controls="assessment-table-main"
                                                onChange={handleSearchFilter}
                                                value={search}
                                            />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner filter-custom-new`}>
                                        <Tablefilter
                                            filterName="Role"
                                            optionArr={filterData.roles}
                                            state={roles}
                                            setState={setRoles}
                                            renderLabelFunction={RenderUserRole}
                                            //  isOptionReversed = {true}
                                        />
                                        <Tablefilter
                                            filterName="Room"
                                            optionArr={filterData.rooms}
                                            state={rooms}
                                            setState={setRooms}
                                            // renderLabelFunction={RenderUserRole}
                                            // isOptionReversed = {true}
                                        />
                                        <Tablefilter
                                            filterName="Linked Course"
                                            optionArr={filterData.linkedCourses}
                                            state={linkedCourses}
                                            setState={setLinkedCourses}
                                            // renderLabelFunction={RenderUserRole}
                                            // isOptionReversed = {true}
                                        />
                                        <Tablefilter
                                            filterName="Status"
                                            optionArr={filterData.status}
                                            state={status}
                                            setState={setStatus}
                                            renderLabelFunction={RenderBookingStatus}
                                            // isOptionReversed = {true}
                                        />

                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button
                                            className="btn btn-primary"
                                            onClick={resetFilter}
                                            title="Reset"
                                        >
                                            <i className="fal fa-redo"></i>Reset
                                        </button>
                                    </div>
                                    <div className="files-export-group">
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("xlsx", "Bookings_list");
                                            }}
                                            title="Export spreadsheet"
                                        >
                                            <i className="fal fa-file-excel icon"></i>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("csv", "Bookings_list");
                                            }}
                                            title="Export CSV"
                                        >
                                            <i className="fal fa-file-csv icon"></i>
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("pdf", "Bookings_list");
                                            }}
                                            title="Export PDF"
                                        >
                                            <i className="fal fa-file-pdf icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="search-filter-div-right">
                        <div className=" filter-search-bar-blk">
                            <div className="add-ticket-blk button-reset dropdown-comman">
                                {/* <PermissionsGate
                                    RenderError={() => (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            title="Create new"
                                            // disabled
                                        >
                                            <i className="fal fa-plus"></i>Create New
                                        </button>
                                    )}
                                    scopes={[""]}
                                > */}
                                    <Link to={`/campusAdministration/bookings/add`}>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            title="Create new"
                                        >
                                            <i className="fal fa-plus"></i>Create New
                                        </button>
                                    </Link>
                                {/* </PermissionsGate> */}
                            </div>
                        </div>
                    </div>
                </div>

                {loading ? (
                    <SkeletonTicketList />
                ) : (
                    <DataTable
                        keyField="Id"
                        data={dataToRender()}
                        defaultSortField="time"
                        defaultSortAsc={true}
                        columns={columns}
                        pagination={true}
                        noDataComponent={Str.noRecord}
                        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    />
                )}
            </div>
        </div>

    )

}

export default ListofBookings;