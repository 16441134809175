import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { AddTicketDepartmentSetting, EditTicketDepartmentSetting, GetTicketDepartmentSettingDetail, GetTicketDepartmentSettingDetailCancelToken } from "../../../services/TicketsSettingServices";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import PermissionsGate from "../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import axios from "axios";

function TicketForm() {
  const { id, type } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [ticketData, setTicketData] = useState();
  const [updated, setUpdated] = useState(false);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    if(type==="add"){
      let response = hasPermission({
        scopes: ["ticketsettingdepartmentadd"],
        permissions: givenPermsisions,
      });
      if (!response) {
        history.push("/noaccess");
      }
    }
    if(type==="open"){
      let response = hasPermission({
        scopes: ["ticketsettingdepartmentedit"],
        permissions: givenPermsisions,
      });
      if (!response) {
        history.push("/noaccess");
      }
    }
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetTicketDepartmentSettingDetailCancelToken(id, dropdownSource.token);
        if(res.data.support_department && res?.status == 200){
          setTicketData(res.data?.support_department);
          setIsLoading(false)
        }
        else {
          Swal.fire({
            icon: "Info",
            title: "Info",
            text: "No record found with this id",
          })
          history.push("/administration/ticketSettings/department/table")
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoading(false)
        }
      }
    };
    if (type !== "add") {
      fetchData();
    } else {
      setIsLoading(false)
    }
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
}, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Name: ticketData && ticketData.title ? ticketData.title : "",
      Department: ticketData && ticketData.helper_department_id ? ticketData.helper_department_id : "",
      Status: ticketData && ticketData.status ? ticketData.status.toString() : "",
    },
    validationSchema: Yup.object({
      Name: Yup.string().required("Name is required").max(255, "Name can't be more than 255 characters"),
      Department: Yup.string().required("Department Id Is Required").test(
        "is-number",
        "Department Id must be a number",
        function (value) {
          var isNumber = /^\d+$/;
          return isNumber.test(value);
        }
      ),
      Status: Yup.string().required("Status Is Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setDisabled(true);
      let formData = new FormData();
      formData.append("title", values?.Name);
      formData.append("helper_department_id", values?.Department);
      formData.append("status", values?.Status);
      type !== "add" && formData.append("id", id);
      if (type == "add") {
        AddTicketDepartmentSetting(formData)
          .then((res) => {
            setDisabled(false);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added successfully",
            });
            resetForm();
            history.push("/administration/ticketSettings/department/table")
          })
          .catch((err) => {
            setDisabled(false);
            console.log("error: ", err?.response?.data[0]);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data}`,
            });
          });
      } else {
        EditTicketDepartmentSetting(formData)
          .then((res) => {
            setDisabled(false);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated successfully",
            });
            setUpdated(true)
          })
          .catch((err) => {
            setDisabled(false);
            console.log("error: ", err?.response?.data[0]);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data}`,
            });
          });
      }
    },
  });

  return (
    <div className="card card-body-inr">
      {isLoading ? <SkeletonTicketList/> :
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4" title="Name">
            <label htmlFor="Name">Name *</label>
            <div className="form-icon-group mb-4" >
              <input
                type="text"
                className={"form-control" + (formik.errors.Name && formik.touched.Name ? " is-invalid" : "")}
                name="Name"
                id="Name"
                placeholder="Name"
                title="Name"
                value={formik.values.Name}
                onChange={(e)=>{formik.handleChange(e); setUpdated(false);}}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4" title="Department Id">
            <label htmlFor="Department">Department Id *</label>
            <div className="form-icon-group mb-4" >
              <input
                type="text"
                className={"form-control" + (formik.errors.Department && formik.touched.Department ? " is-invalid" : "")}
                name="Department"
                id="Department"
                placeholder="Department Id"
                title="Department Id"
                value={formik.values.Department}
                onChange={(e)=>{formik.handleChange(e); setUpdated(false);}}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4" title="Status">
              <label htmlFor="Status">Status *</label>
              <div className="form-icon-group" >
                <div className="custon_rd_new">
                  <div class="custom-control custom-radio custom-control-inline" title="Active">
                    <input
                      type="radio"
                      id="customRadioInline1"
                      name="customRadioInline"
                      class="custom-control-input"
                      checked={formik.values.Status == "1"}
                      onChange={() => {formik.setFieldValue("Status", "1"); setUpdated(false);}}
                      onBlur={formik.handleBlur}
                    />
                    <label class="custom-control-label" for="customRadioInline1">
                      Active
                    </label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline" title="In Active">
                    <input
                      type="radio"
                      id="customRadioInline2"
                      name="customRadioInline"
                      class="custom-control-input"
                      title="Status"
                      checked={formik.values.Status == "2"}
                      onChange={() => {formik.setFieldValue("Status", "2"); setUpdated(false);}}
                      onBlur={formik.handleBlur}
                    />
                    <label class="custom-control-label" for="customRadioInline2">
                      In Active
                    </label>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className="form-group form-group-save-cancel">
        {type == "open" ? (
              <PermissionsGate scopes={["tsedit"]} errorProps={{ disabled: true }}>
              <button className="btn btn-save btn-success" type="submit" title="Save" >
                {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                {/* <i className="fal fa-save"></i> */}
                Save
              </button>
            </PermissionsGate>
            ) : (
              <PermissionsGate scopes={["tsadd"]} errorProps={{ disabled: true }}>
              <button className="btn btn-save btn-success" type="submit" title="Save">
                {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                {/* <i className="fal fa-save"></i> */}
                Save
              </button>
            </PermissionsGate>
            )}
          {/* <button className="btn btn-save btn-success" type="submit" title="Save" disabled={type == "open" ? !formik.dirty : false }>
            {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save
          </button> */}
          <button className="btn btn-close btn-danger" type="reset" title="Cancel" onClick={() => formik.resetForm()}>
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {formik.touched.Name && formik.errors.Name ? <div className="invalid-feedback d-block">{formik.errors.Name}</div> : null}
          {formik.touched.Department && formik.errors.Department ? <div className="invalid-feedback d-block">{formik.errors.Department}</div> : null}
          {formik.touched.Status && formik.errors.Status ? <div className="invalid-feedback d-block">{formik.errors.Status}</div> : null}
        </div>
      </form>}
    </div>
  );
}

export default TicketForm;
