import React, { useEffect, useState } from "react";
import { getCurrentTimeHomePage } from "../common/Helper";
import Header from "../common/Header";
import SideBar from "../common/SideBar";
import Tabs from "./Tabs";
import { useFormik } from "formik";
import StudentsModalTable from "./addticketmodals/StudentsModalTable";
import CourseModalTable from "./addticketmodals/CourseModalTable";
import Select from "react-select";
import { GetListOfCategory, AddTicketService, AddTicketAttachment, RemoveTicketAttachment, GetListOfDepartmentsCancelToken } from "../../services/SupportService";
import { GetListOfUser, GetListOfUserCancelToken } from "../../services/UserService";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router";
import Swal from "sweetalert2";
import * as Yup from "yup";
import axios from "axios";

const AddTicket = () => {
  const {pathname} = useLocation();
  const { id, stdName } = useParams();
  const [studentName, setStudentName] = useState("");
  const [studentID, setStudentID] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [courseID, setCourseID] = useState();
  const [deptName, setDeptName] = useState();
  const [category, setCategory] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [sendFile, setSendFile] = useState([]);
  const [stopSubmit, setStopSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const sub_via = [
    { value: "Email", label: "Email" },
    { value: "Phone", label: "Phone" },
    { value: "Social Media", label: "Student Support" },
    { value: "Walk In", label: "Walk In" },
    { value: "Web", label: "Web" },
  ];

  useEffect(() => {
    if (pathname.includes("addticketStudent")) {
      setSelectedStudent([{first_name: stdName , id : id}])
    }
  }, [id, stdName])
  

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetListOfDepartmentsCancelToken(source.token);
        if (res.status == 200) {
          let x = { value: "", label: "" };
          x = res.data.sort((a, b) => { return a.title > b.title ? 1 : -1 }).map((data) => {
            return { ...x, value: `${data.title}`, label: `${data.title}`, id: `${data.id}` };
          });
          setDeptName(x);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }

    getData()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    }
  }, []);

  useEffect(() => {
      const cancelTokenSources = [];
  
      const getData = async () => {

    
        cancelTokenSources.forEach(source => {
          source.cancel('New request made');
        });
    
        const source = axios.CancelToken.source();
        cancelTokenSources.push(source);

        try {
          const res = await GetListOfUserCancelToken({}, source.token);
          if (res.status == 200) {
            let x = [];
            res.data.map((data) => {
              if(data.is_active){
                x.push({value: `${data.UserID}`, label: `${data.Firstname + " " + data.Lastname}` });
              }
            });
            setUserData(x);
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
        }
      }
  
     getData();
  
     return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  const handleDeptCategory = async (id) => {
    GetListOfCategory(id).then((res) => {
      let x = { value: "", label: "" };
      x = res.data.sort((a,b)=>{return a.title > b.title ? 1 : -1}).map((data) => {
        return { ...x, value: `${data.title}`, label: `${data.title}`, id: `${data.id}` };
      });
      setCategory(x);
    });
  };

  const setSelectedStudent = (names) => {
    let studentname = names.map((name) => name.first_name);
    let studentID = names.map((name) => name.id);
    setStudentName(studentname);
    setStudentID(studentID);
    formik.setFieldValue("Student", studentname);
    formik.setFieldValue("id_student", studentID);
  };

  const setSelectedCourse = (names) => {
    let coursename = names.map((name) => name.name);
    let courseID = names.map((name) => name.id);
    setCourseName(coursename);
    setCourseID(courseID);
    formik.setFieldValue("Course", coursename);
    formik.setFieldValue("id_subject", courseID);
  };

  const getSelectedFile = (e) => {
    let newFile = Object.values(e.target.files);
    setSelectedFile((files) => [...files, ...newFile]);

    const formInputs = new FormData();
    selectedFile.length >= 0 && formInputs.append(`post`, newFile[0]);
    selectedFile.length >= 0 && AddTicketAttachment(formInputs).then((res) => {

      // let message = res?.data.split('/');
      // setSendFile((files) => [...files, message[message.length-1]]);
      let message = res?.data;
      setSendFile((files) => [...files, message]);

    }).catch((err) => console.log(err));

    
    
  };

  const deleteSelectedFile = (index) => {
    const arr = selectedFile;
    arr.splice(index, 1);
    setSelectedFile(arr);
    const x = sendFile;
    RemoveTicketAttachment({fileName : x[index]}).catch((err) => console.log(err));
    x.splice(index,1);
    setSendFile(x);
    setStopSubmit(true);
    setTimeout(() => setStopSubmit(false), 1000);
  };

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      subject: "",
      id_student: studentID,
      Student: studentName,
      department: "",
      category: "",
      submitted_via: "",
      id_subject: courseID,
      Course: courseName,
      message: "",
      assign_to: "",
      AssignToName: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Ticket subject is required"),
      department: Yup.string().required("Department is required"),
      // category: Yup.string().required("Category is required"),
      assign_to: Yup.string().required("Assigning is required"),
      submitted_via: Yup.string().required("Assigning is required"),
      message: Yup.string().required("Message is required"),
      id_student: Yup.array().min(1, "Student Name is required"),
      id_subject: Yup.array().min(1, "Course is required"),
    }),

    onSubmit: (values) => {
      
      let data = {...values, filePath  : sendFile.length > 0 ? sendFile : [""]};
      setLoading(true);
      if (!stopSubmit) {
        // const formInputs = new FormData();
        // selectedFile.map((item, index) => {
        //   // formInputs.append(`file_${index + 1}`, item);
        //   formInputs.append(`files`, item);
        // });
        // selectedFile.length > 0 ? formInputs.set("file", true) : formInputs.set("file", false);
        // Object.keys(values).map((item) => {
        //   formInputs.append(item, values[item]);
        // });
        // formInputs.append("filePath",sendFile);

        AddTicketService(data)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Submittted",
              timer: 3000,
              onOpen: function () {
                Swal.showLoading();
              },
            }).then(function (result) {
              setLoading(false);
            });
            history.push(id? `/studentAdministration/students/open/${id}/tickets` : "/support")
          })
          .catch((error) => {console.log(error) });
      }
    },
  });

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className=" wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                <div class="d-flex align-items-center ">
                  <div class="name-w-head d-flex align-items-center ">
                    <h4 class="text-left-align landing-heading heading_color_dynamic">Create <span>Ticket</span></h4>
                  </div>
                  <div class="text-md-right action2-blk">
                    <div class="ticket-view-btn d-flex align-items-center">
                      <div class="add-new-ticket-bx">
                        <button onClick={()=>history.goBack()} title="Back" class="btn btn-white-bordered">
                          <i class="fal fa-angle-left"></i>Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="tabs-wrap">
                  <div className="card card-body-inr card-profile-info-card">
                    <div className="">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="row">
                            <div className="col-md-6 col-lg-4">
                              <div className="form-group-blk mb-3">
                                <label>Ticket Subject*</label>
                                <input
                                  title="Ticket Subject"
                                  name="subject"
                                  type="text"
                                  className={"form-control" + (formik.errors.subject && formik.touched.subject ? " is-invalid" : "")}
                                  placeholder="Ticket Subject"
                                  value={formik.values.subject}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.errors.subject && formik.touched.subject ? (
                                  <span className="exclamation">
                                    <i className="fal fa-exclamation-circle"></i>
                                  </span>
                                ) : null}
                              </div>
                              <div className="form-group-blk mb-3">
                                <label>Student Name*</label>
                                <div className="form-grp-btn">
                                  <input
                                    title="Student Name"
                                    name="id_student"
                                    type="text"
                                    className={"form-control" + (formik.errors.id_student && formik.touched.id_student ? " is-invalid" : "")}
                                    placeholder={formik.values.Student || "Student Name"}
                                    disabled
                                  />
                                  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#add-student" title="Select" disabled={pathname.includes("addticketStudent")}>
                                    Select
                                  </button>
                                </div>
                                {formik.errors.id_student && formik.touched.id_student ? (
                                  <span className="exclamation">
                                    <i className="fal fa-exclamation-circle"></i>
                                  </span>
                                ) : null}
                              </div>
                              <div className="form-group-blk mb-3" title="Department">
                                <label>Department*</label>
                                <Select
                                  className={"form-control custom-select-box" + (formik.errors.department && formik.touched.department ? " is-invalid" : "")}
                                  name="department"
                                  value={formik.values.department || ""}
                                  onChange={(value) => {
                                    formik.setFieldValue("department", value.value);
                                    handleDeptCategory(value.id);
                                  }}
                                  onBlur={formik.handleBlur}
                                  options={deptName}
                                  maxMenuHeight={175}
                                  placeholder={formik.values.department ? formik.values.department : "Department"}
                                />

                                {formik.errors.department && formik.touched.department ? (
                                  <span className="exclamation">
                                    <i className="fal fa-exclamation-circle"></i>
                                  </span>
                                ) : null}
                              </div>
                              <div className="form-group-blk mb-3" title="Category">
                                <label>Category</label>
                                <Select
                                  className={"form-control custom-select-box" + (formik.errors.category && formik.touched.category ? " is-invalid" : "")}
                                  name="category"
                                  value={category.filter(item => {
                                    return item.value === formik.values.category
                                  })}
                                  onChange={(value) => {
                                    if (value) {
                                      formik.setFieldValue("category", value.value)
                                    } else {
                                      formik.setFieldValue("category", "")
                                    }
                                  }}
                                  onBlur={formik.handleBlur}
                                  options={category}
                                  maxMenuHeight={175}
                                  placeholder={formik.values.category ? formik.values.category : "Category"}
                                  isClearable
                                />
                              </div>
                              <div className="form-group-blk mb-3" title="Submitted Via">
                                <label>Submitted Via*</label>
                                <Select
                                  className={"form-control custom-select-box" + (formik.errors.submitted_via && formik.touched.submitted_via ? " is-invalid" : "")}
                                  name="submitted_via"
                                  value={sub_via?.filter(val=>val.value === formik.values.submitted_via)}
                                  onChange={(value) => {
                                    if(value){
                                      formik.setFieldValue("submitted_via", value.value)
                                    } else {
                                      formik.setFieldValue("submitted_via", "")
                                    }
                                  }}
                                  onBlur={formik.handleBlur}
                                  options={sub_via}
                                  maxMenuHeight={175}
                                  placeholder={formik.values.submitted_via ? formik.values.submitted_via : "Submitted Via"}
                                  isClearable
                                />
                                {formik.errors.submitted_via && formik.touched.submitted_via ? (
                                  <span className="exclamation">
                                    <i className="fal fa-exclamation-circle"></i>
                                  </span>
                                ) : null}
                              </div>
                              <div className="form-group-blk mb-3" title="Course">
                                <label>Course*</label>
                                <div className="form-grp-btn">
                                  <input
                                    name="id_subject"
                                    type="text"
                                    className={"form-control" + (formik.errors.id_subject && formik.touched.id_subject ? " is-invalid" : "")}
                                    placeholder={formik.values.Course || "Course"}
                                    disabled
                                  />
                                  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#add-course" title="Select">
                                    Select
                                  </button>
                                </div>

                                {formik.errors.id_subject && formik.touched.id_subject ? (
                                  <span className="exclamation">
                                    <i className="fal fa-exclamation-circle"></i>
                                  </span>
                                ) : null}
                              </div>
                              <div className="form-group-blk mb-3" title="Assign To">
                                <label>Assign To*</label>
                                <Select
                                  className={"form-control custom-select-box" + (formik.errors.assign_to && formik.touched.assign_to ? " is-invalid" : "")}
                                  name="AssignTo"
                                  value={userData?.filter(val=>val.value === formik.values.assign_to)}
                                  onChange={(value) => {
                                    if (value) {
                                      formik.setFieldValue("assign_to", value.value);
                                      formik.setFieldValue("AssignToName", value.label);
                                    } else {
                                      formik.setFieldValue("assign_to", "");
                                      formik.setFieldValue("AssignToName", "");
                                    }
                                  }}
                                  onBlur={formik.handleBlur}
                                  options={userData.sort((a,b)=>{return a.label > b.label ? 1 : -1})}
                                  maxMenuHeight={175}
                                  placeholder={formik.values.AssignToName ? formik.values.AssignToName : "Assign To"}
                                  isClearable
                                />
                                {formik.errors.assign_to && formik.touched.assign_to ? (
                                  <span className="exclamation">
                                    <i className="fal fa-exclamation-circle"></i>
                                  </span>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-8">
                              <div className="form-group" title="Message">
                                <label>Message*</label>
                                <textarea
                                  className={"form-control" + (formik.errors.message && formik.touched.message ? " is-invalid" : "")}
                                  name="message"
                                  id=""
                                  rows="31"
                                  value={formik.values.message}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          <div className="atttach-file my-2">
                            <label>
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input type="file" id="input" multiple="" className="form-control  form-control-aatch-file" name="file[]" onChange={(e) => getSelectedFile(e)} />
                            </label>
                            <span className="ticketAttachments">
                              {selectedFile?.length > 0 &&
                                selectedFile.map((file, index) => {
                                  return (
                                    <div key={file.name}>
                                      {file.name}
                                      <button className="btn btn-danger" title="Delete" onClick={() => deleteSelectedFile(index)}>
                                        <i className="fal fa-trash-alt"></i>
                                      </button>
                                    </div>
                                  );
                                })}
                            </span>
                          </div>
                          <div className="form-group m-0 col-12 d-flex p-0">
                            <div className="my-2 mr-2">
                              <button type="submit" className="btn btn-primary" title="Create" disabled={loading}>
                              {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}Create
                              </button>
                            </div>
                            <div className="m-2" title="Cancel">
                              <a className="btn btn-danger" onClick={() => history.goBack()}>
                                <i className="fal fa-times"></i>Cancel
                              </a>
                            </div>
                          </div>
                            {formik.touched.subject && formik.errors.subject ? <div className="invalid-feedback d-block">{formik.errors.subject}</div> : null}
                            {formik.touched.department && formik.errors.department ? <div className="invalid-feedback d-block">{formik.errors.department}</div> : null}
                            {formik.touched.category && formik.errors.category ? <div className="invalid-feedback d-block">{formik.errors.category}</div> : null}
                            {formik.touched.assign_to && formik.errors.assign_to ? <div className="invalid-feedback d-block">{formik.errors.assign_to}</div> : null}
                            {formik.touched.message && formik.errors.message ? <div className="invalid-feedback d-block">{formik.errors.message}</div> : null}
                            {formik.touched.id_student && formik.errors.id_student ? <div className="invalid-feedback d-block">{formik.errors.id_student}</div> : null}
                            {formik.touched.id_subject && formik.errors.id_subject ? <div className="invalid-feedback d-block">{formik.errors.id_subject}</div> : null}
                          <div className="topic-add-modal modal fade" id="add-student" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                              <div className="modal-content">
                              <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Add Student</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
                                <div className="modal-body">
                                    <StudentsModalTable selection={setSelectedStudent} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="topic-add-modal modal fade" id="add-course" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                              <div className="modal-content">
                              <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Add Course</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
                                <div className="modal-body">
                                    <CourseModalTable setCourseName={setSelectedCourse} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTicket;
