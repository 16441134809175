import axiosInstance from "../utils/axios";

export const GetStudentsList = async (data) => {
  return await axiosInstance().post(`/getStudentsLists`,data);
};

export const GetStudentsListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getStudentsLists`, values, {
    cancelToken: cancelToken
  });
};

export const GetstudentaddData = async () => {
  return await axiosInstance().get(`/getstudentaddData`);
};

export const AddStudents = async (data) => {
  return await axiosInstance().post(`/addStudents`, data);
};

export const ChangeStudentStaus = async (data) => {
  return await axiosInstance().post(`/changeStudentStaus`, data);
};

export const GetStudentEditData = async (data) => {
  return await axiosInstance().post(`/getStudentEditData`, data);
};

export const GetStudentDropdown = async () => {
  return await axiosInstance().get(`/getStudentDropdown`);
};

export const GetStudentNotificationList = async (sId) => {
  return await axiosInstance().get(
    `/studentNotificationListData?intake=${sId}`
  );
};

export const GetStudentNotificationListCancelToken = async (sId, cancelToken) => {
  return await axiosInstance().get(
    `/studentNotificationListData?intake=${sId}`, {cancelToken}
  );
};

export const GetStudentNotificationDetail = async (notificationId, stdId) => {
  return await axiosInstance().get(
    `/getStudentNotificationDetail?id=${notificationId}&student_id=${stdId}`
  );
};

export const StudentEduDetails = async (stdId) => {
  return await axiosInstance().get(`/studentEduDetails/${stdId}`);
};

export const StudentEduDetailsCancelToken = async (stdId, cancelToken) => {
  return await axiosInstance().get(`/studentEduDetails/${stdId}`, {cancelToken});
};

export const UpdateHighSchoolDetails = async (data) => {
  return await axiosInstance().post(
    `/studentEduDetails/updateHighSchoolDetails`,
    data
  );
};

export const MatricCertificate = async (data) => {
  return await axiosInstance().post(
    `/studentEduDetails/matricCertificate`,
    data
  );
};

// export const UpdateHighSchoolDetails = async (stdId) => {
//     return await axiosInstance().get(`/studentEduDetails/matricCertificate?student=6917&type=metric_certificate&from_admin=1`);
// };

export const UpdateHighestSchoolDetails = async (data) => {
  return await axiosInstance().post(
    `/studentEduDetails/updateHighestSchoolDetails`,
    data
  );
};

export const HighestSchoolCertificate = async (data) => {
  return await axiosInstance().post(`/HighestSchoolCertificate`, data);
};

export const DeleteCertificate = async (stdId, certId, type,tbname) => {
  return await axiosInstance().get(
    `/DeleteCertificate?student_id=${stdId}&certificate_id=${certId}&type=${type}&tbname=${tbname}`
  );
};

export const DeleteHighestSchoolDetails = async (stdId, qualId) => {
  return await axiosInstance().get(
    `/deleteHighestSchoolDetails?student_id=${stdId}&qualification_id=${qualId}`
  );
};

export const StudentAccountDetails = async (stdId, qualId) => {
  return await axiosInstance().get(`/StudentAccountDetails/${stdId}`);
};

export const StudentAccountDetailsCancelToken = async (stdId, cancelToken) => {
  return await axiosInstance().get(`/StudentAccountDetails/${stdId}`, {cancelToken});
};

export const StudentUpdateAccountData = async (data) => {
  return await axiosInstance().post(`/studentUpdateAccountData`, data);
};

export const GetAssignedSubject = async (stdId) => {
  return await axiosInstance().get(`/getAssignedSubject?student_id=${stdId}`);
};

export const GetAssignedSubjectCancelToken = async (stdId, cancelToken) => {
  return await axiosInstance().get(`/getAssignedSubject?student_id=${stdId}`, {cancelToken});
};

export const AssessmentsData = async (data) => {
  return await axiosInstance().post(`/assessmentsDataList`, data);
};

export const AssessmentsDataCancelToken = async (data, cancelToken) => {
  return await axiosInstance().post(`/assessmentsDataList`, data, {cancelToken});
};

export const GetCommonContent = async () => {
  return await axiosInstance().get(`/getCommonContent`);
};

export const getStatement = async (data) => {
  return await axiosInstance().post(`/getStatement`, data, {responseType:'arraybuffer'})
}

export const getDebtCheck = async (data) => {
  return await axiosInstance().post(`/getDebtCheck`, data);
};

export const UpdateStudentNotification = async (data) => {
  return await axiosInstance().post(`updateStudentNotification`, data);
};

export const PreviewStudentNotification = async (data) => {
  return await axiosInstance().post(`previewStudentNotification`, data);
};

export const GetProgramsNotificationDetails = async (id) => {
  return await axiosInstance().get(`getProgramsNotificationDetails?id=${id}`);
};

export const GetProgramsNotificationDetailsCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`getProgramsNotificationDetails?id=${id}`, {cancelToken});
};

export const GetClassAttendance = async (value) => {
  return await axiosInstance().post(`classAttendance`,value);
};

export const GetClassAttendanceCancelToken = async (value, cancelToken) => {
  return await axiosInstance().post(`classAttendance`,value, {cancelToken});
};

export const GetAppealsList = async (id) => {
  return await axiosInstance().get("/getStudentAppealList?uid=" + id);
};

export const GetAppealsListCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get("/getStudentAppealList?uid=" + id, {cancelToken});
};

export const GetAppealsCommonComponent = async () => {
  return await axiosInstance().get("/getAppealCommonContent");
};

export const ViewAppeal = async (stdId, assId) => {
  return await axiosInstance().get(
    `/viewAppeal?student_id=${stdId}&assessment_id=${assId}`
  );
};

export const ApproveOrDeclineAppeal = async (data) => {
  return await axiosInstance().post("/approveOrDeclineAppeal", data);
};

export const UpdateStudentPersonalInfo = async (data) => {
  return await axiosInstance().post("/updateStudentPersonalInfo", data);
};

export const SendMenualNotification = async (data) => {
  return await axiosInstance().post("/sendMenualNotification", data);
};

export const UpdateStudentGeneralDetails = async (data) => {
  return await axiosInstance().post("/updateStudentGeneralDetails", data);
};

export const GenerateStudentCard = async (data) => {
  return await axiosInstance().post("/generateStudentCard", data);
};

export const DeleteStudentCard = async (stdId, type) => {
  return await axiosInstance().get(
    `/deleteStudentCard?student_id=${stdId}&type=${type}`
  );
};

export const ClassAttendanceDropdowns = async (stdId) => {
  return await axiosInstance().get(
    `/classAttendanceDropdowns?student_id=${stdId}`
  );
};

export const GetAvailableSubject = async (data) => {
return await axiosInstance().post(`/getAvailableSubject`, data);
};

export const GetAvailableSubjectCancelToken = async (data, cancelToken) => {
return await axiosInstance().post(`/getAvailableSubject`, data, {cancelToken});
};

export const AssignedSubject = async (data) => {
  return await axiosInstance().post(`/AssignedSubject`, data);
};

export const GetCommonContentReReg = async () => {
  return await axiosInstance().get(
    `/getCommonContent?api_section=re_registration`
  );
};

export const GetCommonContentReRegCancelToken = async (cancelToken) => {
  return await axiosInstance().get(
    `/getCommonContent?api_section=re_registration`, { cancelToken }
  );
};

export const CreateMicrosoftAc = async (data) => {
  return await axiosInstance().post(`/createMicrosoftAc`, data);
};

export const SendMicrosoftNotification = async (data) => {
  return await axiosInstance().post(`/sendMicroSoftNotification`, data);
};

export const DeleteStudentNote = async (data) => {
  return await axiosInstance().post(`/deleteStudentNote`, data);
};

export const UpdateStudentAttendacne = async (data) => {
  return await axiosInstance().post(`/updateStudentAttendacne`, data);
};

export const GetStudentAuditTrail = async (action_id) => {
  return await axiosInstance().get(
    `/getStudentAuditTrail?action_id=${action_id}`
  );
};

export const GetStudentAuditTrailData = async (data, cancelToken)=>{
  return await axiosInstance().post(`/getStudentAuditTrailData`,data, {cancelToken});
}

export const GetStudentAuditTrailDataCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getStudentAuditTrailData`, values, {
    cancelToken: cancelToken
  });
};
export const GetStudentAuditFilters = async (params,cancelToken)=>{
  return await axiosInstance().get(`/getStudentAuditFilters`, {params, cancelToken});
}

export const GetStudentPortalAuditTrail = async (data,cancelToken)=>{
  return await axiosInstance().post(`/getStudentPortalAuditTrail`,data, {cancelToken});
}

export const GetStudentPortalAuditFilters = async (param,cancelToken)=>{
  return await axiosInstance().get(`/getStudentPortalAuditFilters`,{params:param,cancelToken});
}

export const GetStudentDetails = async (id) => {
  return await axiosInstance().get(`/getStudentsDetail?id=${id}`);
};

export const GetStudentDetailsCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getStudentsDetail?id=${id}`, {cancelToken: cancelToken});
};

export const ClassAttendanceSubjectView = async (id) => {
  return await axiosInstance().get(`/ClassAttendanceSubjectView?student_id=${id}`);
};

export const GetRegistrationData = async (data) => {
  return await axiosInstance().post(`/getRegistrationData`, data);
};

export const GetRegistrationDataCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getRegistrationData`, values, {
    cancelToken: cancelToken
  });
};

export const GetMicrosoftAcount = async (data) => {
  return await axiosInstance().post(`/getMicrosoftAcount`, data);
};

export const GetMicrosoftAcountCancelToken = async (data, cancelToken) => {
  return await axiosInstance().post(`/getMicrosoftAcount`, data, {cancelToken});
};

export const DeleteStudentNotification = async (id) => {
  return await axiosInstance().get(`/deleteNotifications?id=${id}`);
};

export const GetNetsuiteCustomerDetails = async (value, email, contactID, bookingId) => {
  return await axiosInstance().get(
    `/getNetsuiteCustomerDetails?customerId=${value}&&email=${email}&&NScontactID=${contactID}&&bookingId=${bookingId}`
  );
};
  
export const ResponsibleAccount = async (data) => {
  return await axiosInstance().post(`/responsibleAccount`, data);
};

export const StatmentIndividual = async (id) => {
  return await axiosInstance().post(`/statmentIndividual`,id);
};

export const StudentStatmentResult = async (id) => {
  return await axiosInstance().post(`/studentStatementResult`,id);
};

export const GetStudentAssementAttachment = async (id) => {
  return await axiosInstance().get(`/getStudentAssementAttachment?student=${id}`);
};

export const GetAvailableSubjectFilters = async (stdId) => {
  return await axiosInstance().get(`/getAvailableSubjectFilters?student_id=${stdId}`);
};

export const SendStudentLoginEmails = async (data) => {
  return await axiosInstance().post("/sendStudentLoginEmails", data);
};

export const AssessmentsDataFilters = async (data) => {
  return await axiosInstance().get(`/assessmentsDataFilters?student_id=${data}`);
};

export const StudentGeneralNotificationPreview = async (data) => {
  return await axiosInstance().post(`/studentGeneralNotificationPreview`,data);
}

export const GetStudentsListsFilter = async () => {
  return await axiosInstance().get(`/getStudentsListsFilter`);
};

export const GetStudentsListsFilterCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getStudentsListsFilter`, {
    cancelToken: cancelToken
  });
};

export const getStudentExamRegData = async (id) => {
  return await axiosInstance().get(`/getStudentExamRegData?student_id=${id}`);
};

export const GetStudentExamRegDataCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getStudentExamRegData?student_id=${id}`, {
    cancelToken: cancelToken
  });
};

export const getStudentCrmData = async (id) => {
  return await axiosInstance().get(`/getStudentCrmData?student_id=${id}`);
};

export const GetStudentCrmDataDataCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getStudentCrmData?student_id=${id}`, {
    cancelToken: cancelToken
  });
};

export const getStudentElectricCard = async (id) => {
  return await axiosInstance().get(`/getStudentElectricCard?student_id=${id}`);
};

export const GetStudentElectricCardCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getStudentElectricCard?student_id=${id}`, {
    cancelToken: cancelToken
  });
};

export const getStudentEmailLogs = async (id) => {
  return await axiosInstance().get(`/getStudentEmailLogs?student_id=${id}`);
};

export const GetStudentEmailLogsCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getStudentEmailLogs?student_id=${id}`, {
    cancelToken: cancelToken
  });
};

export const getStudentData = async (id) => {
  return await axiosInstance().get(`/getStudentData?student_id=${id}`);
};

export const getStudentDataCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getStudentData?student_id=${id}`, {cancelToken});
};

export const getStudentDocs = async (id) => {
  return await axiosInstance().get(`/getStudentDocs?student_id=${id}`);
};

export const getStudentDocsCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getStudentDocs?student_id=${id}`, {cancelToken});
};

export const GetStudentAuditFiltersCancelToken = async (params,cancelToken) => {
  return await axiosInstance().get(`/getStudentAuditFilters`, { 
    params,
    cancelToken: cancelToken
  });
};