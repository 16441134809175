import React, { useState } from "react";

const OnlineClassType = ({ row }) => {

  const [isExpanded, setIsExpanded] = useState(false);
  row.multiple_room = row.multiple_room.filter(item => item.campus_room);

  return (
    <div>
      <span
        className={row.class_type === "bbb_class" || row.class_type == "braincert_class" || row.class_type == "joinme_class" ? "cat-blue" : row.class_type === "on_campus" ? "cat-green" : "cat-red"}
      >
        {row.class_type === "bbb_class" || row.class_type == "braincert_class" || row.class_type == "joinme_class" ? (
          <div className="as-virtual-bg as-widget text-white no-text-transformation" title="Virtual"><i className="fal fa-house-signal" />&nbsp;Virtual</div>
        ) : row.class_type === "on_campus" ? (
          <div className="as-campus-bg as-widget no-text-transformation" title="On-campus"><i className="fal fa-university" />&nbsp;On Campus</div>
        ) : (
          <div className="as-hybrid-bg as-widget no-text-transformation" title="Hybrid"><i className="fal fa-university" /><i className="fal fa-plus" /><i className="fal fa-house-signal" />&nbsp;Hybrid</div>
        )}
      </span>
      {row.multiple_room &&
        row.multiple_room.map((data, index) => {
          if (isExpanded) {
              return data?.campus_room?.room_no && data?.campus_room?.class_campus?.campus_name ? <div className="textLimit100" key={index}>
                {/* {data.campus_room != null && index ? " | " : ""} */}
                <i className="fal fa-university" />&nbsp;
                {data?.campus_room?.room_no + (data?.campus_room?.class_campus?.campus_name ? ", " + data?.campus_room?.class_campus?.campus_name : "")}
              </div> : ""
          } else {
            if (!index) {
              return data?.campus_room?.room_no && data?.campus_room?.class_campus?.campus_name ? <div className="textLimit100" key={index}>
                {/* {data.campus_room != null && index ? " | " : ""} */}
                <i className="fal fa-university" />&nbsp;
                {data?.campus_room?.room_no + (data?.campus_room?.class_campus?.campus_name ? ", " + data?.campus_room?.class_campus?.campus_name : "")}
              </div> : ""
            }
          }
        })}
        {row.multiple_room && row.multiple_room?.length > 1 ? <div onClick={() => setIsExpanded(!isExpanded)} className="table-expand-btn">{isExpanded ? "See Less" : "See More"}</div> : ""}
    </div>
  );
};

export default OnlineClassType;
