import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { GetExamCentresAuditFilters, GetExamCentresAuditTrails } from "../../../../services/ExternalExamServices";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import AuditTrailsTable from "../../../common/AuditTrailsTable";

function ExamCenterAuditTrail() {
  const { id } = useParams();
  const history = useHistory();
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"];

  useEffect(() => {
    let response = hasPermission({ scopes: ["eeecview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, []);

  return (
    <AuditTrailsTable 
      apiFunction={GetExamCentresAuditTrails}
      filterArrApi={GetExamCentresAuditFilters}
      columnsToShow={columnsToShow}
      exportFileName={"Exam_Center_Audit_Trail"}
      isResourceNameShown={true}
      apiParams={{action_id: id}}
      actionId={id}
      isShowingResourceTypeFilter
    />
  );
}

export default ExamCenterAuditTrail;
