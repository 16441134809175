import React, { useEffect, useMemo, useState } from "react";
import { CourseDetailsLink } from "../../../common/Links";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import { ClassAttendanceDropdowns,  GetClassAttendance, GetClassAttendanceCancelToken } from "../../../../services/StudentsSettingServices";
import moment from "moment";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import DatePicker from "react-datepicker";
import StatusRender from "../../../systemadministration/usermanagement/CheckRole";
import { FORM_TIME_FORMAT, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE } from "../../../../utils/Constants";
import PermissionsGate from "../../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission"
import { handleTableScroll } from "../../../../utils/commonFunction";
import Swal from "sweetalert2";
import Tablefilter from "../../../common/Tablefilter";
import { RenderSClassType } from "../../../../utils/CommonGroupingItem";
import OnlineClassType from "../../../common/OnlineClassType";
import { RenderAttendanceStatus, RenderStatusForAttendance } from "../../../../utils/CommonStatusItems";
import { AddTimer } from "../../../../services/CourseService";
import $ from "jquery";
import UserColumn from "../../../common/UserColumn";
import axios from "axios";

function ClassAttendance() {
  const { id } = useParams();
  const [classAttendanceData, setClassAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [attStatus, setAttStatus] = useState({ arr: [], checkObj: {} });
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [courseFilterState, setCourseFilterState] = useState({ arr: [], checkObj: [] });
  const [setSearchAttStatusCheck] = useState({});
  const [attType, setAttType] = useState({ arr: [], checkObj: {} });
  const [qualification, setQualification] = useState({ arr: [], checkObj: [] });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [attId, setAttId] = useState();
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("class_date");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [filterData, setFilterData] = useState({programme : [],courseFilter  : [], type : [], status : [], attendanceStatus:[]})
  const [refresh, setRefresh] = useState(false);
  const [attendanceLoading, setAttendanceLoading] = useState(false)
  const [isShowingAllUser, setIsShowingAllUser] = useState([]);


  const history = useHistory();

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    const cancelTokenSources = [];

    const getAttendanceList = async () => {
      setLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const apiData = {
        pageNo: page,
        limit: perPage,
        search: search ? search : "",
        key: sortkey,
        sort: sortOrder,
        student_id: id,
        viaClassType: attType.arr,
        viaQualification: qualification.arr,
        viaCourse: courseFilterState.arr,
        viaAttendance: attStatus.arr,
        viaStatus : status.arr
      };
  
      try {
        const res = await GetClassAttendanceCancelToken(apiData, source.token);
        if (res.status === 200) {
          setClassAttendanceData(res.data?.result1);
          setTotalRows(res.data?.Pagination?.total);
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    getAttendanceList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [
    page,
    perPage,
    search,
    sortOrder,
    sortkey,
    id,
    attType,
    qualification,
    courseFilterState,
    attStatus,
    refresh,
    status
  ]);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["scaview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }

    ClassAttendanceDropdowns(id)
      .then((res) => {
        setFilterData({...res.data, programme : res.data.qualificationType, courseFilter : res.data.subjectyType, type : res.data.attendanceType, attendanceStatus : res.data.attendanceStatusType, status:res.data.statusType})
        
      })
      .catch((err) => {
        console.log("error :", err);
      });
  }, []);

  const handleGetDates = (data) => {
    setMinDate(data?.class_from_timestamp);
    setMaxDate(data?.class_to_timestamp);
    setStartDate(data?.time_in !== "" ? data?.time_in?.length == 10 ? new Date(moment.unix(data?.time_in).format(TABLE_DATE_TIME_FORMAT)) : new Date(data?.time_in) : "");
    setEndDate(data?.time_out !== "" ? data?.time_out?.length == 10 ? new Date(moment.unix(data?.time_out).format(TABLE_DATE_TIME_FORMAT)) : new Date(data?.time_out) : "");
    setAttId(data?.portal_class_id);
    // (moment.unix(parseInt(`${data.class_date_timestamp}`))))
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };
  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handleShowingUser = (isShowing, id) => {
    let tempArr = isShowingAllUser;
    if (isShowing) {
      tempArr.splice(tempArr.indexOf(id), 1)
    } else {
      tempArr.push(id)
    }
    setIsShowingAllUser([...tempArr])
  }

  const columns = useMemo(() => [
    {
      name: "Class Title",
      selector: "class_title",
      sortField:"class_title",
      sortable: true,
      cell: (row, index) => (
            <span
              title={row.class_title + " " + row.class_id}
              className="as-text-blue curser feature-name"
              // to={CourseDetailsLink(row.intake_id)}
              data-toggle="modal"
              data-target="#setAttendance"
              onClick={() => handleGetDates(row)}
            >
              <span className="textLimit100">{row.class_title}</span> <span className="textLimit100">{row.class_id}</span>
            </span>
      ),
    },
    {
      name: "Date",
      selector: "class_date",
      sortField: "class_date",
      sortable: true,
      cell: (row) => {
        return (
          <span className="feature-name">
            <p className="right-space">
              {moment(row.class_date, "YYYY-MM-DD").format(TABLE_DATE_FORMAT)}
            </p>
            <span className="textLimit100">
              {moment(row.class_from, "h:mm A").format(FORM_TIME_FORMAT)} -{" "}
              {moment(row.class_to, "h:mm A").format(FORM_TIME_FORMAT)}
            </span>
          </span>
        );
      },
    },
    {
      name: "Lecturer",
      selector: "Firstname",
      sortField:"lecture",
      sortable: false,
      cell: (row) => {
        return isShowingAllUser.includes(row.id) ? <div>
          <div className="assigned-profile-tit">
            {row.allLecturer.length ? row.allLecturer.map((data, index) => {
            return <UserColumn
              firstName={data.get_lecture_details?.Firstname}
              lastName={data.get_lecture_details?.Lastname}
              imgUrl={data.get_lecture_details?.picture_me}
              email={data.get_lecture_details?.Email}
              mobile={data.get_lecture_details?.Mobile}
              userId={data.get_lecture_details?.lecturer_id}
              userActiveStatus={data.get_lecture_details?.Status}
              role="lecturer"
              key={index}
              // isUserNameHidden={!(isShowingAllUser.includes(data.class_id) || row.allLecturer.length == 1)}
              isPopUpRight={true}
            />
            }) : "-"}</div>
          {row.allLecturer.length && row.allLecturer.length > 1 ? <div
            className="table-expand-btn"
            onClick={() => handleShowingUser(true, row.id)}>
            See Less
          </div> : ""}
        </div> : <div className="assigned-profile-overlay-out">
          <div className="assigned-profile-overlay">
            {row.allLecturer.length ? row.allLecturer.map((data, index) => {
              return index < 3 ? <UserColumn
                firstName={data?.get_lecture_details?.Firstname}
                lastName={data?.get_lecture_details?.Lastname}
                imgUrl={data?.get_lecture_details?.picture_me}
                email={data?.get_lecture_details?.Email}
                mobile={data?.get_lecture_details?.Mobile}
                userId={data?.lecturer_id}
                userActiveStatus={data?.get_lecture_details?.Status}
                role="lecturer"
                key={index}
                isUserNameHidden={!(isShowingAllUser.includes(data?.class_id) || row.allLecturer.length == 1)}
                isPopUpRight={true}
              /> : ""
            }) : "-"}</div>
          {row.allLecturer.length && row.allLecturer.length > 1 ? <div
            className="table-expand-btn"
            onClick={() => handleShowingUser(false, row.id)}>
            See More
          </div> : ""}
        </div>
      },
    },
    {
      name: "Type",
      selector: "type",
      sortable: false,
      // cell: (row) => (
      //   <span
      //     className={
      //       row.class_type === "bbb_class" ||
      //       row.class_type == "braincert_class" ||
      //       row.class_type == "joinme_class"
      //         ? "cat-blue"
      //         : row.class_type === "on_campus"
      //         ? "cat-green"
      //         : "cat-red"
      //     }
      //   >
      //     {row.class_type === "bbb_class" ||
      //     row.class_type == "braincert_class" ||
      //     row.class_type == "joinme_class" ? (
      //       <i title="Virtual" className="fal fa-wifi"></i>
      //     ) : row.class_type === "on_campus" ? (
      //       <i title="On-campus" className="fal fa-university"></i>
      //     ) : (
      //       <i title="Hybrid" className="fal fa-house-signal"></i>
      //     )}
      //   </span>
      // ),
      cell: row => <OnlineClassType row={row} />
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      cell: (row) =>
        row?.class_status ? (
          <div className="textLimit100">
            <span title={row.class_status.toUpperCase()}><StatusRender status={row.class_status.toLowerCase()} /></span>
            {row.class_subStatus && (
              <span title={row.class_subStatus.toUpperCase()}><StatusRender status={row.class_subStatus?.toLowerCase()} /></span>
            )}
            {row?.class_status == "Live" && row?.class_subStatus == "Launched" ? (
              <>
                <p>{row?.started_at_Date}</p> {row?.nestedStarted_by}{" "}
              </>
            ) : (
              ""
            )}

            {row.class_status == "Past" &&
              row.class_subStatus == "Ended" &&
              (row.ended_by == row.lect_id || row.ended_by == 0 || row.ended_by == "" ? (
                <p>
                  {row.ended_at_Date ? " at " : ""}
                  {row.ended_at_Date}
                  {row.nestedEnded_by ? " by " : ""}
                  {row.nestedEnded_by
                    ? row.nestedEnded_by
                    : ` by ${row.lecturer && row.lecturer.Firstname != null && row.lecturer.Firstname != ""  ? row.lecturer.Firstname : ""} ${row.lecturer && row.lecturer.Lastname != null && row.lecturer.Lastname != ""  ? row.lecturer.Lastname : ""}`}
                </p>
              ) : (
                ""
              ))}
          </div>
        ) : (
          "-"
        ),
    },
    {
      name: "Course",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => (
            <Link
              title={row?.name}
              className="as-text-blue curser feature-name"
              to={`/courseAdministration/coursesdetails/${row?.intake_id}/dashboard/show`}
            >
              <span className="textLimit100">{row?.name ? row?.name : "-"}</span>
            </Link>
          ),
    },
    {
      name: "Qualification",
      selector: "course",
      sortField: "course",
      sortable: true,
      cell: (row) => (

            <Link
              title={row?.course}
              className="as-text-blue curser feature-name"
              to={`/courseAdministration/Programmes/programme/open/${row?.qualification_id}/details`}
            >
              <span className="textLimit100">{row?.course ? row?.course : "-"}</span>
            </Link>
            ),
    },

    {
      name: "Attendance Status",
      selector: "attendance_status",
      sortField: "attendance_status",
      sortable: true,
      cell: (row) => (
        <div title={row?.attendance_status}>
          {row?.attendance_status ? RenderAttendanceStatus(row?.attendance_status).html : "-"}
        </div>
      ),
    },
    {
      name: "Attendance Type",
      selector: "attendance_type",
      sortField:"attendance_type",
      sortable: false,
      cell: (row) => row?.attendance_type_status ? RenderSClassType(row?.attendance_type_status).html : "-"
    },
    {
      name: "Rating",
      selector: "student_rating",
      sortable: false,
      cell: (row) => (row.student_rating ? row.student_rating : "-"),
    },
    {
      name: "Recording",
      selector: "recording",
      sortable: false,
      cell: (row) => row.recording ? <span className="feature-name">
        <span className="textLimit100">
            <p className="right-space">
            {moment.unix(row.recording).format(TABLE_DATE_TIME_FORMAT)}
            </p>
        </span>
        </span> : "-"
    },
    // {
    //   name: "Time Joined ",
    //   selector: "time_in ",
    //   sortable: false,
    //   cell: (row) => row.time_in ?
    //   <span className="feature-name">
    //     <span className="textLimit100">
    //         <p className="right-space">
    //         {moment.unix(row.time_in).format(TABLE_DATE_TIME_FORMAT)}
    //         </p>
    //     </span>
    //     </span> : "-",
    // },
    // {
    //   name: "Time Left",
    //   selector: "time_out",
    //   sortable: true,
    //   cell: (row) => row.time_out ? 
    //   <span className="feature-name">
    //     <span className="textLimit100">
    //         <p className="right-space">
    //         {moment.unix(row.time_out).format(TABLE_DATE_TIME_FORMAT)}
    //         </p>
    //     </span>
    //     </span> : "-"
    // },
    // {
    //   name: "Total Time Attended",
    //   selector: "duration",
    //   sortable: false,
    //   cell: (row) => (row.duration ? row.duration : "-"),
    // },
    // {
    //   name: "Total % Attended",
    //   selector: "percentage",
    //   sortable: false,
    //   cell: (row) => (row.percentage ? row.percentage : "-"),
    // },
    {
      name: "Insights",
      selector: "percentage",
      sortable: false,
      cell: (row) => {
        return (
          <span>
            {row?.duration ? row?.duration : "0"}
            {" "}
            {row?.percentage ? `(${row?.percentage}%)` : "(0%)"}
            <br />
            {row?.time_in ? `I: ${moment.unix(row?.time_in).format(TABLE_DATE_TIME_FORMAT)}` : "I: 0"}
            <br />
            {row?.time_out ? `O: ${moment.unix(row?.time_out).format(TABLE_DATE_TIME_FORMAT)}` : "O: 0"}
          </span>
        )
        },
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              data-toggle="modal"
              data-target="#setAttendance"
              title="Edit"
              onClick={() => handleGetDates(row)}
              // disabled={moment.unix(row.class_date_timestamp)._d != new Date()}
            >
              <i className="fal fa-pencil"></i>
            </button>
            {/* <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button className="btn btn-primary rounded-circle" title="Subject View" 
                // to={`/studentAdministration/students/open/${id}/notifications/editNotification/${row.id}`}
                >
                  <i className="fal fa-eye"></i>
                </button> */}
            <PermissionsGate
              scopes={["sndelete"]}
              RenderError={() => (
                <button
                  // to={CourseDetailsLink(row.intake_id)}
                  disabled
                  className="btn btn-primary rounded-circle"
                  title="Open"
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                to={CourseDetailsLink(row.intake_id)}
                className="btn btn-primary rounded-circle"
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            {/* </div>
            </div> */}
          </div>
        </div>
      ),
    },
  ], []);


  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
    setAttStatus({ arr: [], checkObj: [] });
    setAttType({ arr: [], checkObj: [] });
    setQualification({ arr: [], checkObj: [] });
    setCourseFilterState({ arr: [], checkObj: [] });
    setStatus({ arr: [], checkObj: [] });
  };

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = [
      "Class Title",
      "Date",
      "Lecturer",
      "Type",
      "Status",
      "Subject",
      "Qualification",
      "Attendance Status",
      "Attendance Type",
      "Rating",
      "Recording",
      // "Time Joined",
      // "Time Left",
      // "Total Time Attended",
      // "Total % Attended",
      "Insights",
    ];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const classData = {
      pageNo: page,
      limit: perPage,
      search: search ? search : "",
      key: sortkey,
      sort: sortOrder,
      student_id: id,
      viaClassType: attType.arr,
      viaQualification: qualification.arr,
      viaCourse: courseFilterState.arr,
      viaStatus: attStatus.arr,
      exportStaus: "true",
      viaStatus : status.arr
      
    };
    GetClassAttendance(classData)
      .then((res) => {
        data=res?.data?.result1;
        data = data?.map((row) => ({
          ...row,
          "Class Title": row.class_title ? row.class_title : "-",
          Date: `${moment(row.class_date).format(TABLE_DATE_FORMAT)} ${moment(row?.class_from, "h:mm A").format(FORM_TIME_FORMAT)} - ${moment(row.class_to, "h:mm A").format(FORM_TIME_FORMAT)}`,
          Lecturer: row.lecturer || row.allLecturer.length ? `${row.lecturer ? row?.lecturer?.Firstname +" "+ row?.lecturer?.Lastname : ""} ${row.allLecturer.map((item)=>item?.get_lecture_details?.Firstname +" " +item?.get_lecture_details?.Lastname)}` : "-",
          // Lecturer: row.lecture_name
          // ? `${row.lecture_name}${row.multiple_lecture ? "," : ""}${row.multiple_lecture.map(
            //     (data) =>
            //       (data.get_lecture_details.Firstname +
            //       " " +
            //       data.get_lecture_details.Lastname +"" ) 
            //   )}`
            // : "-",
          Type: (row.class_type === "bbb_class" || row.class_type == "braincert_class" || row.class_type == "joinme_class" ? "Virtual" : row.class_type === "on_campus" ? "On-campus" : "Hybrid"),
          Status: row.class_status ? (row.class_status + " " + row.class_subStatus + (row.class_status == "Live" && row.class_subStatus == "Launched" ? (row.started_at_Date + row.nestedStarted_by) : "")) : "-",
          Subject: row.name ? row.name : "-",
          Qualification: row.course ? row.course : "-",
          "Attendance Status": row.attendance_status ? RenderAttendanceStatus(row.attendance_status).text : "-",
          "Attendance Type": row.attendance_type_status ? RenderSClassType(row.attendance_type_status).text :  "-",
          Rating: row.student_rating ? row.student_rating : "-",
          Recording: row.recording ? moment.unix(row.recording).format(TABLE_DATE_TIME_FORMAT) : "-",
          // "Time Joined": row.time_in ? row.time_in : "-",
          // "Time Left": row.time_out ? row.time_out : "-",
          // "Total Time Attended": row.duration ? row.duration : "-",
          // "Total % Attended": row.percentage ? row.percentage : "-",
          Insights: (row.duration ? row.duration : "0") + " " + (row.percentage ? `(${row.percentage}%)` : "(0%)") + " " + (row.time_in ? `I: ${moment.unix(row.time_in).format(TABLE_DATE_TIME_FORMAT)}` : "I: 0") + " " + (row.time_out ? `O: ${moment.unix(row.time_out).format(TABLE_DATE_TIME_FORMAT)}` : "O: 0"),
        }));
    
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
    
          const blobURL = window.URL.createObjectURL(blob);
    
          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();
    
          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });
    
          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
    
          // Returning false as downloading of file is already taken care of
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Class Title"],
              row["Date"],
              row["Lecturer"],
              row["Type"],
              row["Status"],
              row["Subject"],
              row["Qualification"],
              row["Attendance Status"],
              row["Attendance Type"],
              row["Rating"],
              row["Recording"],
              // row["Time Joined"],
              // row["Time Left"],
              // row["Total Time Attended"],
              // row["Total % Attended"],
              row["Insights"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
        
      })
      .catch((err) => {
        console.log("err :", err);
      });
    

   
  };

  const handleUpdateDate = () => {
    setAttendanceLoading(true)
    const data = new FormData();
    data.append("UserID", id);
    data.append("portal_class_id", attId);
    data.append("start_time", new Date(startDate).getHours() + ":" + new Date(startDate).getMinutes());
    data.append("end_time", new Date(endDate).getHours() + ":" + new Date(endDate).getMinutes());
    AddTimer(data)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        }).then((res)=>{
          setAttendanceLoading(false)
          // setRefresh(!refresh);
          $("#setAttendance").modal("hide");
        })
       
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data[0].message,
        });
        setAttendanceLoading(false)
        console.log("error :", err);
      });
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom program-table">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
            <div className="table-responsive-div">
              <div
                id="assessment-table-main_wrapper"
                className="dataTables_wrapper no-footer"
              >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                </div>
              </div>
            </div>
            <div className="filter-button-group">
              <div className="filter-scroll">
                <div className={`filter-scroll-inner  filter-custom-new`}>
                  <Tablefilter
                    filterName="Programme"
                    optionArr={filterData.programme}
                    state={qualification}
                    setState={setQualification}
                    isSearchFilter
                  />
                  <Tablefilter
                    filterName="Course"
                    optionArr={filterData.courseFilter}
                    state={courseFilterState}
                    setState={setCourseFilterState}
                    isSearchFilter
                  />
                 <Tablefilter
                    filterName="Attendance Type"
                    optionArr={filterData.type}
                    state={attType}
                    setState={setAttType}
                    renderLabelFunction={RenderSClassType}
                  />
                  <Tablefilter
                    filterName="Attendance Status"
                    optionArr={filterData.attendanceStatus}
                    state={attStatus}
                    setState={setAttStatus}
                    renderLabelFunction={RenderAttendanceStatus}
                  />                 
                  <Tablefilter
                    filterName="Status"
                    optionArr={filterData.status}
                    state={status}
                    setState={setStatus}
                    renderLabelFunction={RenderStatusForAttendance}
                  />   
                </div>
              </div>
              <div className="reset-btn-group">
                <div className="button-reset dropdown-comman">
                  <button
                    className="btn btn-primary"
                    onClick={resetFilter}
                    title="Reset"
                  >
                    <i className="fal fa-redo"></i>Reset
                  </button>
                </div>
                <div className="files-export-group">
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("xlsx", "class_attendance_list");
                    }}
                    title="Export spreadsheet"
                  >
                    <i className="fal fa-file-excel icon"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("csv", "class_attendance_list");
                    }}
                    title="Export CSV"
                  >
                    <i className="fal fa-file-csv icon"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("pdf", "class_attendance_list");
                    }}
                    title="Export PDF"
                  >
                    <i className="fal fa-file-pdf icon"></i>
                  </button>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
              {/* <Link to={`/studentAdministration/students/add`}> */}
              <Link
                className="btn btn-primary"
                title="Subject View"
                to={`/studentAdministration/students/open/${id}/subjectView`}
              >
                <i className="fal fa-eye"></i>Subject View
              </Link>
              {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
          <DataTable
            paginationDefaultPage={page}
            progressPending={loading}
            data={classAttendanceData}
            defaultSortField="class_date"
            defaultSortAsc={false}
            columns={columns}
            pagination={true}
            progressComponent={<SkeletonTicketList />}
            // noDataComponent={Str.noRecord}
            onSort={handleSort}
            sortServer
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
            paginationServer
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
      </div>
      <div
        className="topic-add-modal modal fade"
        id="setAttendance"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <form onSubmit={handleUpdateDate}>
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-pencil"></i> Edit Attendance
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="t p-30px">
                  <div className="row">
                    <div className="col-12">
                      <label htmlFor="StudentNumber">Start Time*</label>
                      <div className="form-icon-group mb-4 d-block">
                        <DatePicker
                          showTimeSelect
                          showTimeSelectOnly
                          dateFormat={FORM_TIME_FORMAT}
                          timeFormat={FORM_TIME_FORMAT}
                          placeholderText="Select time"
                          timeIntervals={15}
                          onChangeRaw={(e) => e.preventDefault()}
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                          }}
                          minTime={moment.unix(minDate)._d}
                          maxTime={moment.unix(maxDate)._d}
                          className="form-control w-100"
                          timeCaption="Time"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="StudentNumber">End Time*</label>
                      <div className="form-icon-group mb-4 d-block">
                        <DatePicker
                          showTimeSelect
                          showTimeSelectOnly
                          disabled={!startDate}
                          dateFormat={FORM_TIME_FORMAT}
                          timeFormat={FORM_TIME_FORMAT}
                          placeholderText="Select time"
                          timeIntervals={15}
                          onChangeRaw={(e) => e.preventDefault()}
                          selected={endDate}
                          onChange={(date) => {
                            setEndDate(date);
                          }}
                          minTime={startDate > minDate ? startDate : moment.unix(minDate)._d}
                          maxTime={moment.unix(maxDate)._d}
                          className="form-control w-100"
                          timeCaption="Time"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel">
                    <PermissionsGate
                      scopes={["snedit"]}
                      errorProps={{ disabled: true }}
                    >
                      <button
                        className="btn btn-save btn-primary"
                        type="submit"
                        title="Save"
                        // data-dismiss="modal"
                        // disabled={
                        //   !startDate ||
                        //   !endDate ||
                        //   moment(minDate)._d !== new Date()
                        // }
                        onClick={() => {
                          handleUpdateDate();
                        }}
                      >
                       {attendanceLoading ? <i className="fas fa-cog fa-spin"></i>  : <i className="fal fa-save"></i>}
                        Save
                      </button>
                    </PermissionsGate>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      title="Close"
                    >
                      <i className="fal fa-times"></i>
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassAttendance;
