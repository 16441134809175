import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Summary from './Summary';

const TicketDetailCourses = ({ Departments, AssignedTo, Category }) => {
  const { id, tab } = useParams();
  const history = useHistory();
  const [basicInfo, setBasicInfo] = useState({});
  const [tabName, setTabName] = useState("Summary");

  // useEffect(() => {
  //     getFirstGraphData();
  // }, [])

  // const getFirstGraphData = () => {
  //     GetOnlineClassGraphData(id).then(response => {
  //         setBasicInfo(response && response.data ? response.data : {})
  //     }).catch(error => {
  //     })
  // }

  return (
    <>
      <div className="tabs-wrap">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade active show"
            id="pills-tab11"
            role="tabpanel"
            aria-labelledby="pills-tab1"
          >
            {tabName === "Summary" && (
              <Summary
                userId={id}
                Departments={Departments}
                AssignedTo={AssignedTo}
                Category={Category}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketDetailCourses;
