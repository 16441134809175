import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import Str from "../../../../common/Str";
import { TrimText } from "../../../../common/TrimText";
import { handleTableScroll, netSuiteDateFormat, netSuiteTimeFormat } from "../../../../../utils/commonFunction";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../../utils/Constants";

function NetSuiteDataStudent({ tempData, loading }) {
  const [search, setSearch] = useState("");

  const [studentData, setStudentData] = useState([]);
  const [salesPerson, setSalesPerson] = useState([]);
  const [erpData, setErpData] = useState([]);
  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState([]);
  const [company, setCompany] = useState([]);
  const [searchCompanyCheck, setSearchCompanyCheck] = useState([]);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    if (tempData) {
      setStudentData(tempData);
    }
  }, [tempData]);

  const resetFilter = () => {
    setSearch("");
    setStatus([]);
    setSearchStatusCheck({});
    setCompany([]);
    setSearchCompanyCheck({});
  };

  const handleStatusFilter = (e) => {
    const value = e.target.value;
    let arr = status;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setStatus(arr);
    let id = e.target.id;
    setSearchStatusCheck({
      ...searchStatusCheck,
      [id]: !searchStatusCheck[id],
    });
  };

  const handleCompanyFilter = (e) => {
    const value = e.target.value;
    let arr = company;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setCompany(arr);
    let id = e.target.id;
    setSearchCompanyCheck({
      ...searchCompanyCheck,
      [id]: !searchCompanyCheck[id],
    });
  };

  function dataToRender() {
    let updatedData = [];
    let allData = studentData;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes =
      //     item.id && item.id.toLowerCase().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempName = allData.filter((item) => {
        let includes = item.title
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDate = allData.filter((item) => {
        let includes = item.dateCreated
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempType = allData.filter((item) => {
        let includes = item.type
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStatus = allData.filter((item) => {
        let includes = item.salesStage
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSource = allData.filter((item) => {
        let includes = item.leadsource
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempAssignedTo = allData.filter((item) => {
        let includes = item.salesRep
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        // ...tempId,
        ...tempName,
        ...tempDate,
        ...tempType,
        ...tempStatus,
        ...tempSource,
        ...tempAssignedTo,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.length) {
      let tempStatus = updatedData;

      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.find(function (post, index) {
          if (
            post.toString() === item.salesStage.toString()
              ? item.salesStage.toString()
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (company.length) {
      let tempCompany = updatedData;

      let tempResult = tempCompany.filter((item) => {
        const startsWith = company.find(function (post, index) {
          if (
            post.toString().toLowerCase() === item.type.toString().toLowerCase()
              ? item.type.toString().toLowerCase()
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    return updatedData;
  }

  const handleFilters = (e, type = "") => {
    const value = e.target.value;
    if (type === "search") {
      setSearch(value);
    }
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = [
      "Title",
      "Date",
      "Type",
      "Status",
      "Lead Source",
      "Assigned To",
    ];

    data = data?.map((row) => ({
      ...row,
      Title: row?.title ? row?.title : "-",
      Date: row.dateCreated
        ? netSuiteDateFormat(row.dateCreated) +
        " " + netSuiteTimeFormat(row.dateCreated)
        : "-",
      Type: row.type ? row.type : "-",
      Status: row.salesStage == "Closed Won" ? "CLOSED WON" : row.salesStage == "LEAD-New" ? "LEAD-New" : row.salesStage == "CUSTOMER-Closed Won" ? "CUSTOMER-CLOSED WON" : row.salesStage == "Called" ? "Called" : "-",
      "Lead Source": row.leadsource ? row.leadsource : "-",
      "Assigned To": row.salesRep ? row.salesRep : "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row.Title,
          row.Date,
          row.Type,
          row.Status,
          row["Lead Source"],
          row["Assigned To"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };
  const sortingDate = (rowA, rowB) => {
    if (rowA?.dateCreated != null && rowB?.dateCreated != null && rowA?.dateCreated) {
      const name1 = new Date(`${netSuiteDateFormat(rowA?.dateCreated)} ${netSuiteTimeFormat(rowA?.dateCreated)}`);
      const name2 = new Date(`${netSuiteDateFormat(rowB?.dateCreated)} ${netSuiteTimeFormat(rowB?.dateCreated)}`);
      return name1 - name2;
    }
  };

  const columns = useMemo(() => [
    {
      name: "Title",
      selector: "title",
      sortable: true,
      cell: (row) => (
        // <a className="as-text-blue">{row.title ? row.title : "-"}</a>
        <a
          target="_blank"
          // rel="noopener noreferrer"
          href={row.linkToResource}
          title={row.title}
          className="as-text-blue curser feature-name"
        >
          <span className="textLimit100">{row.title}</span>
        </a>
      ),
    },
    {
      name: "Date",
      selector: "dateCreated",
      sortable: true,
      sortFunction: sortingDate,
      cell: (row) => (
        <>
          {row.dateCreated ? (
            <div className="dateTime">
              <p className="right-space">

                {/* {moment(row.dateCreated).format(TABLE_DATE_FORMAT)} */}

                {/* {row.dateCreated} */}
                {/* {row.dateCreated.replaceAll('/', '-')} */}
                {/* {moment(row.dateCreated.replaceAll('/', '-')).format(`DD-MMM-YYY`)} */}
                {/* {moment(row.dateCreated, `DD/MM/YYYY`).format(TABLE_DATE_FORMAT)} */}
                {netSuiteDateFormat(row.dateCreated)}
                {/* {console.log(" =-=-=-=-=-> ", moment("23-08-2022", `DD-MMM-YYY`).format(`YYYY-MM-DD`))} */}

              </p>
              <p className="right-space">

                {netSuiteTimeFormat(row.dateCreated)}

                {/* {moment(row.dateCreated).format(TABLE_TIME_FORMAT)} */}

                {/* {row.dateCreated} */}
                {/* {moment("23-08-2022", `DD-MMM-YYY`).format(`YYYY-MM-DD`)} */}
                {/* {moment(row.dateCreated.replaceAll('/', '-')).format(TABLE_TIME_FORMAT)} */}
              </p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (
        // <>
        //   <p>{row.type ? row.type : "-"}</p>

        //   {/* {row.current_status ? RegStudentColorComb(row.current_status.toLowerCase()) : "-"} */}
        // </>
        //  row.type ? (
        // ) : (
        //   <p className="cat">
        //     <i className="fas fa-circle mr-1"></i> NA
        //   </p>
        // ),
        <>
          {row.type.toLowerCase() == "opportunity" ? (
            <span className="cat cat-skyblue">
              <i className="fas fa-circle mr-1"></i>
              {TrimText("Opportunity", 20)}
            </span>
          ) : row.type.toLowerCase() == "customer" ? (
            <span className="cat cat-greenhex">
              <i className="fas fa-circle mr-1"></i>
              {TrimText("Customer", 20)}
            </span>
          ) : row.type.toLowerCase() == "lead" ? (
            <span className="cat cat-blue">
              <i className="fas fa-circle mr-1"></i>
              {TrimText("Lead", 20)}
            </span>
          ) : row.type.toLowerCase() == "booking" ? (
            <span className="cat cat-purple">
              <i className="fas fa-circle mr-1"></i>
              {TrimText("Booking", 20)}
            </span>
          ) : (
            <span className="cat cat-red">
              <i className="fas fa-circle mr-1"></i>
              {TrimText("Contact", 20)}
            </span>
          )}
        </>
      ),
    },
    // {
    //   name: "Assigned To",
    //   selector: "assingnee",
    //   sortable: true,
    //   cell: (row) => (
    //     <p className="as-text-blue">{row.assingnee ? row.assingnee : "-"}</p>
    //   ),
    // },
    {
      name: "Status",
      selector: "salesStage",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (
        <>
          {/* {row.current_salesStage ? RegStudentColorComb(row.current_salesStage.toLowerCase()) : "-"} */}
          {/* {row.salesStage} */}
          <span className={(row.salesStage == "Closed Won" ? "as-green-bg" : row.salesStage == "LEAD-New" ? "as-skyblue-bg" : row.salesStage == "CUSTOMER-Closed Won" ? "as-red-bg" : row.salesStage == "Called" ? "as-blue-bg" : "") + " as-widget"}>
            {row.salesStage == "Closed Won" ? "CLOSED WON" : row.salesStage == "LEAD-New" ? "LEAD-New" : row.salesStage == "CUSTOMER-Closed Won" ? "CUSTOMER-CLOSED WON" : row.salesStage == "Called" ? "Called" : "-"}
          </span>
        </>
      ),
    },
    {
      name: "Lead Source",
      selector: "leadsource",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (
        <span className="feature-name" title={row.leadsource}>
          {/* {row.current_leadsource ? RegStudentColorComb(row.current_leadsource.toLowerCase()) : "-"} */}

          <span className="textLimit100"><span>{row.leadsource ? row.leadsource : "-"}</span></span>
        </span>
      ),
    },
    {
      name: "Assigned To",
      selector: "salesRepName",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (
        <span className="feature-name">
          <span className="textLimit100" title={row.salesRepName}>
            {row.salesRepName ? row.salesRepName : "-"}
          </span>
        </span>
      ),
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <a
              target="_blank"
              href={row.linkToResource}
              className="btn btn-primary rounded-circle"
              title="Open"
            // rel="noopener noreferrer"
            >
              {/* <button title="Open" className="btn btn-primary rounded-circle"> */}
              <i className="fal fa-folder-open"></i>
              {/* </button> */}
            </a>
          </div>
        </div>
      ),
    },
  ]);

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">ERP Data</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <div className="custom-table-div filter-search-icon card-table-custom course-table-filter-sec marLeft135 top-5">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                      <div id="assessment-table-main_filter" className="dataTables_filter">
                        <label>
                          <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={(e) => handleFilters(e, "search")}
                            value={search}
                          />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                      <div className="filter-scroll">
                          <div className={`filter-scroll-inner filter-custom-new`}>
                            <div className="assessment-table-filter">
                              <div className="btn-type-filter dropdown-comman">
                                <div className="dropdown multiselect">
                                  <button
                                    className={`btn btn-default dropdown-toggle ${status.length ? "btn-selected" : ""
                                      }`}
                                    type="button"
                                    id="dropdownMenu1"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    title="Status"
                                  >
                                    <span>
                                      Status <i className="fal fa-angle-down"></i>
                                    </span>
                                  </button>
                                  <form className="dropdown-menu remove-list-style erp-d-transform">
                                    <ul aria-labelledby="dropdownMenu1">
                                      <li className="item-text-blue">
                                        <input
                                          type="checkbox"
                                          id="action3"
                                          value="Called"
                                          onClick={handleStatusFilter}
                                          checked={searchStatusCheck.action3}
                                        />
                                        <label htmlFor="action3">
                                          <span className="as-blue-bg as-widget">
                                            Called
                                          </span>
                                        </label>
                                      </li>
                                      <li className="item-text-green">
                                        <input
                                          type="checkbox"
                                          id="action1"
                                          value="Closed Won"
                                          onClick={handleStatusFilter}
                                          checked={searchStatusCheck.action1}
                                        />
                                        <label htmlFor="action1">
                                          <span className="as-green-bg as-widget">
                                            Closed Won
                                          </span>
                                        </label>
                                      </li>

                                      <li className="item-text-red">
                                        <input
                                          type="checkbox"
                                          id="action2"
                                          value="CUSTOMER-Closed Won"
                                          onClick={handleStatusFilter}
                                          checked={searchStatusCheck.action2}
                                        />
                                        <label htmlFor="action2">
                                          <span className="as-red-bg as-widget">
                                            CUSTOMER-Closed Won
                                          </span>
                                        </label>
                                      </li>
                                      <li className="item-text-skyblue">
                                        <input
                                          type="checkbox"
                                          id="action4"
                                          value="LEAD-New"
                                          onClick={handleStatusFilter}
                                          checked={searchStatusCheck.action4}
                                        />
                                        <label htmlFor="action4">
                                          <span className="as-skyblue-bg as-widget">
                                            LEAD-New
                                          </span>
                                        </label>
                                      </li>
                                    </ul>
                                  </form>
                                </div>
                              </div>
                            </div>
                            <div className="assessment-table-filter">
                              <div className="btn-type-filter dropdown-comman">
                                <div className="dropdown multiselect">
                                  <button
                                    className={`btn btn-default dropdown-toggle ${company.length ? "btn-selected" : ""
                                      }`}
                                    type="button"
                                    id="dropdownMenu1"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    title="Type"
                                  >
                                    <span>
                                      Type <i className="fal fa-angle-down"></i>
                                    </span>
                                  </button>
                                  <form className="dropdown-menu remove-list-style">
                                    <ul aria-labelledby="dropdownMenu1">
                                      <li className="cat cat-greenhex" title="Customer">
                                        <input
                                          type="checkbox"
                                          id="action14"
                                          value="Customer"
                                          onClick={handleCompanyFilter}
                                          checked={searchCompanyCheck.action14}
                                        />
                                        <label htmlFor="action14">
                                          <span className="cat cat-greenhex">
                                            <i className="fas fa-circle mr-1"></i>
                                            {TrimText("Customer", 20)}
                                          </span>
                                        </label>
                                      </li>
                                      <li className="cat cat-blue" title="Lead">
                                        <input
                                          type="checkbox"
                                          id="action15"
                                          value="Lead"
                                          onClick={handleCompanyFilter}
                                          checked={searchCompanyCheck.action15}
                                        />
                                        <label htmlFor="action15">
                                          <span className="cat cat-blue">
                                            <i className="fas fa-circle mr-1"></i>Lead
                                          </span>
                                        </label>
                                      </li>
                                      <li className="cat cat-skyblue" title="Opportunity">
                                        <input
                                          type="checkbox"
                                          id="action13"
                                          value="Opportunity"
                                          onClick={handleCompanyFilter}
                                          checked={searchCompanyCheck.action13}
                                        />
                                        <label htmlFor="action13">
                                          <span className="cat cat-skyblue">
                                            <i className="fas fa-circle mr-1"></i>
                                            {TrimText("Opportunity", 20)}
                                          </span>
                                        </label>
                                      </li>
                                    </ul>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div className="reset-btn-group">
                        <div className="button-reset dropdown-comman">
                          <button
                            className="btn btn-primary"
                            title="Reset"
                            type="button"
                            onClick={resetFilter}
                          >
                            <i className="fal fa-redo"></i>Reset
                          </button>
                        </div>
                        <div className="files-export-group">
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("xlsx", "ERP_Data_list");
                            }}
                            title="Export spreadsheet"
                          >
                            <i className="fal fa-file-excel icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("csv", "ERP_Data_list");
                            }}
                            title="Export CSV"
                          >
                            <i className="fal fa-file-csv icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("pdf", "ERP_Data_list");
                            }}
                            title="Export PDF"
                          >
                            <i className="fal fa-file-pdf icon"></i>
                          </button>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
              </div>
          </div>
      </div>
        {loading ? <SkeletonTicketList />
          : <DataTable
            data={dataToRender()}
            defaultSortField="dateCreated"
            defaultSortAsc={false}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />}
      </div>
    </div>
  );
}

export default NetSuiteDataStudent;
