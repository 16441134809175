import React from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { GlobalIconNames } from "../../../utils/GlobalIconNames";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import AuditTrail from "./AuditTrail";
import ListOfFeatures from "./ListOfFeatures";

function Features() {
  const { tab, type, id } = useParams();
  const history = useHistory();

  return (
    <>
      <Header></Header>
      <div
        className="content-wrapper d-flex flex-wrap new-standard-box"
        style={{ fontWeight: "0px" }}
      >
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        Features & <span>Benefits</span>
                      </h4>
                    </div>
                      <div className="text-md-right action2-blk">
                        <div className="ticket-view-btn d-flex align-items-center">
                          <div className="add-new-ticket-bx">
                            <button
                              onClick={() => history.goBack()}
                              title="Back"
                              className="btn btn-white-bordered"
                            >
                              <i className="fal fa-angle-left"></i>Back
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                {type !== "add" && type !== "duplicate" && <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          type == "table"
                            ? "/program-management-center/features/benefits/table"
                            : `/program-management-center/features/benefits/table`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "benefits" && (type == "table" || type == "open") ? "active" : ""
                          }`}
                        id="pills-tab1"
                        data-toggle="pill"
                        to="/program-management-center/features/benefits/table"
                        href="#pills-tab11"
                        role="tab"
                        aria-controls="pills-tab11"
                        aria-selected="true"
                      >
                        {GlobalIconNames("featuresandbenefits").html}
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          type == "auditTrail"
                            ? "/program-management-center/features/benefits/auditTrail"
                            : `/program-management-center/features/benefits/auditTrail`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${ tab=="benefits" &&type == "auditTrail" ? "active" : ""
                          }`}
                        id="pills-tab2"
                        data-toggle="pill"
                        to="/program-management-center/features/benefits/auditTrail"
                        href="#pills-tab12"
                        role="tab"
                        aria-controls="pills-tab12"
                        aria-selected="true"
                      >
                        <i className="fal fa-history"></i>Audit trail
                      </Link>
                    </li>
                  </ul>}
                  <div className="tab-content" id="pills-tabContent">
                  <div
                      className="tab-pane fade active show"
                      id="pills-tab11"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      {tab == "benefits" && type == "table" && <ListOfFeatures/>}
                    </div>
                   
                    
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab12"
                      role="tabpanel"
                      aria-labelledby="pills-tab2"
                    >
                      {tab == "benefits" && type=="auditTrail" && <AuditTrail />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
