import React, { useMemo, useState, useEffect } from 'react';
import PermissionsGate from '../../../utils/permissionGate';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import hasPermission from '../../../utils/hasMultiplePermission';
import DataTableComponent from '../../common/DataTableComponent';
import Swal from 'sweetalert2';
import {
  GetCertificateTemplates,
  GetCertificateTemplatesFilters,
  PreviewCertificate,
  deleteCertificateTemplateById,
} from '../../../services/CertificateServices';
import { IMAGE_URL } from '../../../utils/Constants';
import { RenderQuizTopic } from '../../../utils/CommonStatusItems';
import { RenderBrands } from '../../../utils/CommonGroupingItem';

const CertificateTemplates = () => {
  const [data, setData] = useState([]); // State to store the fetched data
  const [loading, setLoading] = useState(true); // State to manage loading state

  const [search, setSearch] = useState('');
  const [dummy, setDummy] = useState(true);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [topics, setTopics] = useState({ arr: [], checkObj: {} });
  const [brands, setBrands] = useState({ arr: [], checkObj: {} });
  const [size, setSize] = useState({ arr: [], checkObj: {} });
  const [filterData, setFilterData] = useState({ brands: [], topics: [], SizeFilter: [] });
  const [totalRows, setTotalRows] = useState(0);
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortOrder: "ASC",
    sortKey: "Name"
  })
  const history = useHistory();

  useEffect(() => {
    // Fetch data from the API when the component mounts
    async function fetchData() {
      try {
        setLoading(true); // Set loading to true while fetching data

        const data = {
          search: search,
          viaBrand: brands.arr,
          viaSize: size.arr,
          viaTopics: topics.arr,
          exportStatus: false,
          page: tableState.page,
          limit: tableState.perPage,
          key: tableState.sortKey,
          sort: tableState.sortOrder,
        }
        const response = await GetCertificateTemplates(data);

        setData(response.data.data); // Store the data in the state
        setTotalRows(response.data.total)
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false when the request is complete
      }
    }

    // Trigger fetchData immediately when search term changes
    fetchData();
  }, [dummy, search, topics, brands, size, tableState]);

  useEffect(() => {
    let response = hasPermission({
      scopes: ['ccertview'],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push('/noaccess');
    }
  }, []);

  useEffect(() => {
    GetCertificateTemplatesFilters()
      .then((res) => {
        setFilterData({ ...res.data, });
      })
      .catch((err) => console.log(err));
  }, []);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCertificateTemplateById(id) // Pass the id directly
          .then((res) => {
            Swal.fire(
              'Deleted!',
              'Certificate Template deleted successfully.',
              'success'
            );
            setDummy(!dummy);
          })
          .catch((error) => {
            console.log(error);
            Swal.fire(
              'Error',
              'Failed to delete Certificate Template.',
              'error'
            );
          });
      }
    });
  };

  const handlePreviewTemplate = (id) => {
    PreviewCertificate({ id }).then(res => {
      if (res.data.error) {
        Swal.fire("Error", res.data.message, "error")
        return
      }
      const url = IMAGE_URL + "/" + (res.data.data.replace("home/myaie/public_html/", "").replace("public/", ""))
      const aTag = document.createElement("a")
      aTag.href = url
      aTag.download = url
      aTag.target = "_blank"
      document.body.appendChild(aTag)
      aTag.click()
    }).catch(err => {
      console.log("error :", err)
      Swal.fire("Error", err.response.data.message, "error")
    })
  }

  const columns = useMemo(() => [
    {
      name: 'Name',
      selector: 'Name',
      sortable: true,
      sortField: 'name',
      minWidth: '190px',
      cell: (row) => (
        <PermissionsGate scopes={['ccertview']} errorProps={{ disabled: true }}>
          <Link
            title={row.Name}
            className="as-text-blue curser feature-name"
            to={`/courseAdministration/certificates/details/${row.id}`}
          >
            <span className="textLimit100">{row.Name ? row.Name : ''} </span>
          </Link>
        </PermissionsGate>
      ),
    },
    {
      name: 'Brand',
      selector: 'brand_name',
      sortable: true,
      sortField: 'brand_name',
      cell: (row) => <p>{row.brand_name ? RenderBrands(row.brand_name, row.topbar_color).html : '-'}</p>,
    },
    {
      name: 'Topics',
      selector: 'certificateTopics',
      sortable: false,
      sortField: 'certificateTopics',
      cell: (row) => {
        if (row.certificateTopics.length) {
          return row.certificateTopics.map((topic, index) => <React.Fragment key={index}>{RenderQuizTopic(topic.name, topic.color).html}</React.Fragment>)
        } else {
          return <p>-</p>; // Handle the case where "Topics" is not provided
        }
      },
    },
    {
      name: 'Keywords',
      selector: 'Keywords',
      sortable: false,
      sortField: 'Keywords',
      cell: (row) => {
        if (row.Keywords) {
          const keywordsArray = row.Keywords.split(',');
          const keywordsList = keywordsArray.map((keyword, index) => (
            <span
              key={index}
              style={{
                backgroundColor: '#E2E2E2',
                color: '#252423',
                margin: '4px',
                padding: '3px',
              }}
            >
              {keyword}
            </span>
          ));

          return <div>{keywordsList}</div>;
        } else {
          return <p>-</p>; // Handle the case where "Topics" is not provided
        }
      },
    },
    {
      name: 'Size',
      selector: 'Size',
      sortable: false,
      sortField: 'Size',
      cell: (row) => (row.Size ? row.Size : '-'),
    },
    {
      name: 'Action',
      selector: '',
      maxWidth: '50px',
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons  d-flex">
            <Link
              className="btn btn-primary rounded-circle"
              title="Open"
              to={`/courseAdministration/certificates/details/${row.id}`}
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            <div className="dropdown btn-dropdown-item">
              <button
                className="btn btn-primary rounded-circle dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="More"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <PermissionsGate scopes={['ccertadd']} RenderError={() => <button
                  className="btn btn-primary rounded-circle"
                  title="Duplicate"
                  disabled
                >
                  <i className="fal fa-copy"></i>
                </button>} >
                  <Link
                    className="btn btn-primary rounded-circle"
                    title="Duplicate"
                    to={`/courseAdministration/certificates/duplicateTemplate/${row.id}`}
                  >
                    <i className="fal fa-copy"></i>
                  </Link>
                </PermissionsGate>
                <button
                  className="btn btn-primary rounded-circle"
                  title="Preview"
                  onClick={() => handlePreviewTemplate(row?.id)}
                >
                  <i className="fal fa-eye"></i>
                </button>
                <PermissionsGate scopes={["ccertdelete"]} errorProps={{ disabled: true }}>
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Delete"
                    onClick={() => handleDelete(row?.id)}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    const header = ["Name", "Brand", "Topics", "Keywords", "Size"];
    const data = {
      search: search,
      viaBrand: brands.arr,
      viaSize: size.arr,
      viaTopics: topics.arr,
      exportStatus: true,
      page: tableState.page,
      limit: tableState.perPage,
      key: tableState.sortKey,
      sort: tableState.sortOrder,
    }
    GetCertificateTemplates(data).then(res => {
        let data = res.data;
        data = data?.map((row) => {
            let obj = {
                Name: row.Name ? row.Name : "-",
                "Brand": row.brand_name ? RenderBrands(row.brand_name, row.topbar_color).text : "-",
                "Topics": row?.certificateTopics ? row.certificateTopics.map(item => item.name).join(",") : "-",
                "Keywords": row?.Keywords ? row?.Keywords : "-",
                "Size": row.Size ? row.Size : "-"
            }
            return obj
        });

        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(blobURL);
            }, 1000);
            Swal.close();

        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);
            Swal.close();
            return false;
        }
        if (fileType === "pdf") {
            const compatibleData = data.map(item => Object.values(item));
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);
            Swal.close();
            return false;
        }
    }).catch((err) => { console.log(err); Swal.close() })
};

  return (

    <DataTableComponent
      columns={columns}
      loading={loading}
      setSearch={setSearch}
      data={data}
      state={tableState}
      setState={setTableState}
      totalRows={totalRows}
      exportFunction={exportData}
      exportFileName='Certificate_templates'
      filters={[
        {
          filterName: 'Brand',
          optionArr: filterData.brands,
          state: brands,
          setState: setBrands,
          renderLabelFunction: RenderBrands,
          uniqueId: 'brands'
        },
        {
          filterName: 'Topics',
          optionArr: filterData.topics,
          state: topics,
          setState: setTopics,
          renderLabelFunction: RenderQuizTopic,
          uniqueId: "topic"
        },
        {
          filterName: 'Size',
          optionArr: filterData.SizeFilter,
          state: size,
          setState: setSize,
        },
      ]}
      tableButton={[
        <PermissionsGate
          scopes={['ccertadd']}
          RenderError={() => (
            <button
              className="btn btn-primary"
              title="Create New"
              disabled
            >
              <i className="fal fa-plus"></i>Create New
            </button>
          )}
        >
          <Link to="/courseAdministration/certificates/add">
            <button
              className="btn btn-primary"
              title="Create New"
            >
              <i className="fal fa-plus"></i>Create New
            </button>
          </Link>
        </PermissionsGate>,
      ]}
    />
  );
};

export default CertificateTemplates;
