import axiosInstance from "../utils/axios";

export const LoginService = async (values) => {
  return await axiosInstance().post("/login/", values);
};

export const ForgotPasswordService = async (values) => {
  return await axiosInstance().post("/forgotPassword/", values);
};

export const ResetPasswordService = async (values) => {
  return await axiosInstance().post("/resetPassword/", values);
};

export const getMobileNumberByEmail = async (values) => {
  return await axiosInstance().post("/getMobileNumberByEmail/", values);
};

export const LoginCheckService = () => {
  return axiosInstance().get("/me/");
};
