import axiosInstance from '../utils/axios';

export const GetListofSchedules = async (
  page = 1,
  perPage = 10,
  date = 'today',
  campusId = 0,
  roomId = 0,
  lecturerId = 0,
  courseId = 0,
  startDate = date === 'custom' ? startDate.toISOString() : new Date(),
  endDate = date === 'custom' ? endDate.toISOString() : new Date()
) => {
  return await axiosInstance().get(
    `/listUpdatedClasses?page=${page}&perPage=${perPage}&dateFilter=${date}&campusId=${campusId}&lecturerId=${lecturerId}&roomId=${roomId}&courseId=${courseId}&startDate=${startDate}&endDate=${endDate}`
  );
};

export const GetListofScheduleBookings = async (
  page = 1,
  perPage = 10,
  date = 'today',
  campusId = 0,
  roomId = 0,
  courseId = 0,
  lecturerId = 0
) => {
  return await axiosInstance().get(
    `/listUpdatedBookings?page=${page}&perPage=${perPage}&dateFilter=${date}&campusId=${campusId}&roomId=${roomId}&courseId=${courseId}&lecturerId=${lecturerId}`
  );
};

export const GetConflictSchedules = async (dateFilter) => {
  if (dateFilter !== 'past') {
    return await axiosInstance().get(`/checkConflicts?dateFilter=${dateFilter}`);
  }
  else {
    return await axiosInstance().get(`/checkConflicts`);
  }
};

export const GetScheduleCourses = async ({ page, limit,search = "" ,isPast = 0}) => {
  return await axiosInstance().get(`/coursesSchedule`, {
    params: {
      page,
      limit,
      search,
      isPast
    },
  });
};

export const GetClassesCalendarView = async (
  date = 'daily',
  campusId = 0,
  roomId = 0,
  lecturerId = 0,
  courseId = 0,
  startDate = new Date(),
  endDate = new Date(),
) => {
  const params = new URLSearchParams({
    viewFilter: date,
    campusId,
    roomId,
    lecturerId,
    courseId,
    startDate: date === 'custom' ? startDate.toISOString() : undefined,
    endDate: date === 'custom' ? endDate.toISOString() : undefined,
  });

  return await axiosInstance().get(`/listCalendarClasses?${params.toString()}`);
};

export const GetBookingsCalendarView = async(
  date = 'daily',
  campusId = 0,
  roomId = 0,
  courseId = 0,
  startDate = new Date(),
  endDate = new Date()
) => {
  const params = new URLSearchParams({

    viewFilter: date,
    campusId,
    roomId,
    courseId,
    startDate : date ==='custom' ? startDate.toISOString() : undefined,
    endDate :  date === 'custom' ? endDate.toISOString() : undefined

  })

  return await axiosInstance().get(`/listCalendarBookings?${params.toString()}`);
}

export const GetScheduleLecturersList = async () => {
  
  return await axiosInstance().get(`/scheduleLecturersList`);

}
