import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from "react-data-table-component";

import SkeletonTicketList from '../../../loaders/SkeletonTicketList';
import { GetRoles, UpdateRolePermission } from '../../../services/PermissionService';
import { useParams } from "react-router-dom";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";

const RolesEdit = (props) => {
    const { id } = useParams()
    
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState("");
    const [perWithName, setPerWithName] = useState([]);
    const [givenPermission, setGivenPermission] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [unSelectedIds, setUnSelectedIds] = useState([]);

    useEffect(() => {
        getPermissionRoles();
    }, [])

    useEffect(() => {
        handleTableScroll()
      }, [loader])

    // console.log("props.isNewPortal ---> ", props.isNewPortal);

    const getPermissionRoles = () => {
        setPerWithName([]);
        setGivenPermission([]);
        setSelectedIds([]);
        setUnSelectedIds([]);
        setLoader(true);
        GetRoles(id, props.isNewPortal).then((data) => {
            setPerWithName(data && data.data && data.data.permissions && data.data.permissions.length ? data.data.permissions : [])
            setGivenPermission(data && data.data && data.data.givenPermission && data.data.givenPermission.length ? data.data.givenPermission : [])
            setLoader(false);
        }).catch((err) => {
            setLoader(false);
        })
    }

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearch(value);
    };

    const dataToRender = () => {
        let updatedData = [];
        let allData = perWithName;
        if (search.length) {
            let module_name = allData.filter((item) => {
                let includes = item.module_name.toString().toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let data = [...module_name];
            let unique = [...new Set(data)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (search.length) {
            return updatedData;
        } else {
            return perWithName;
        }
    };

    const columns = useMemo(() => [
        {
            name: "Module",
            selector: "module_name",
            sortable: true,
            // minWidth: "400px",
            cell: (row, index) => (
                <p>
                    <span>{row.module_name}</span>
                </p>
            ),
        },
        {
            name: "View",
            selector: "view",
            sortable: false,
            maxWidth: "250px",
            cell: (row, index) => (
                <p>
                    {row && row.rights && <>
                        {row.rights.map(elem => (
                            <>
                                {elem.order == 1 && <input type="checkbox" checked={checkCheckbox(elem.permission_id)} onChange={() => checkUncheck(elem.permission_id)} />}
                                {/*  */}
                            </>
                        ))}
                    </>}
                </p>
            ),
        },
        {
            name: "Add",
            selector: "add",
            sortable: false,
            maxWidth: "250px",
            cell: (row, index) => (
                <p>
                    {row && row.rights && <>
                        {row.rights.map(elem => (
                            <>
                                {elem.order == 2 && <input type="checkbox" checked={checkCheckbox(elem.permission_id)} onChange={() => checkUncheck(elem.permission_id)} />}
                                {/*  */}
                            </>
                        ))}
                    </>}
                </p>
            ),
        },
        {
            name: "Edit",
            selector: "menuEdit",
            sortable: false,
            maxWidth: "250px",
            // justifyContent: "left",
            cell: (row, index) => (
                <p>
                    {row && row.rights && <>
                        {row.rights.map(elem => (
                            <>
                                {elem.order == 3 && <input type="checkbox" checked={checkCheckbox(elem.permission_id)} onChange={() => checkUncheck(elem.permission_id)} />}
                                {/*  */}
                            </>
                        ))}
                    </>}
                </p>
            ),
        },
        {
            name: "Delete",
            selector: "delete",
            sortable: false,
            maxWidth: "250px",
            // justifyContent: "left",
            cell: (row, index) => (
                <p>
                    {row && row.rights && <>
                        {row.rights.map(elem => (
                            <>
                                {elem.order == 4 && <input type="checkbox" checked={checkCheckbox(elem.permission_id)} onChange={() => checkUncheck(elem.permission_id)} />}
                                {/*  */}
                            </>
                        ))}
                    </>}
                </p>
            ),
        },
    ])

    const checkCheckbox = (permission_id) => {
        var __FOUND = givenPermission.find(function (post, index) {
            if (post.permission_id == permission_id) return true;
        })
        if (__FOUND === undefined) {
            return false;
        } else {
            return true;
        }
    }

    const checkUncheck = (permission_id) => {
        let id;
        var __Found = givenPermission.find(function (post, index) {
            if (post.permission_id == permission_id) {
                id = post.id;
                givenPermission.splice(index, 1);
                setGivenPermission([...givenPermission]);
                dataToRender();
                return post;
            }
        })


        if (__Found === undefined) {
            setGivenPermission([...givenPermission, { role_id: id, permission_id: permission_id }]);
            setSelectedIds([...selectedIds, permission_id]);
            // updatePermission({ role_id: props.data, permission_id: permission_id, permission_type: 1, isNewTable: props.isNewPortal == 1 ? '1' : '0' });
        } else {
            setUnSelectedIds([...unSelectedIds, id]);
            // updatePermission({ role_id: props.data, permission_id: permission_id, permission_type: 2, id: id, isNewTable: props.isNewPortal == 1 ? '1' : '0' });
        }
    }

    const submit = () => {

        let obj = {
            role_id: id,
            item_id: selectedIds,
            id: unSelectedIds
        }
        // console.log("data ========= > ", obj);
        // return

        updatePermission(obj);
    }

    const updatePermission = (obj) => {
        // return
        UpdateRolePermission(obj).then((data) => {
            getPermissionRoles();
        }).catch((err) => {
        })
    }

    const resetFilter = () => {
        setSearch("");
    };

    /**
     * Function to export the table data in pdf, csv, xlsx format
     * 
     * @param {*} fileType 
     * @param {*} fileName 
     * @returns 
     */

    const modifiedData = () => {
        let data = dataToRender();

        let arr = [];
        data = data?.map((row, index) => {
            let obj = { "Module": row.module_name };
            if (row && row.rights && row.rights.length) {
                row.rights.map((elem, i) => {
                    if (elem.order === 2) {
                        if (checkCheckbox(elem.permission_id) === true) {
                            obj["Add"] = "1";
                        } else {
                            obj["Add"] = "0";
                        }
                    }
                    if (elem.order === 1) {
                        if (checkCheckbox(elem.permission_id) === true) {
                            obj["View"] = "1";
                        } else {
                            obj["View"] = "0";
                        }
                    }
                    if (elem.order === 3) {
                        if (checkCheckbox(elem.permission_id) === true) {
                            obj["Edit"] = "1";
                        } else {
                            obj["Edit"] = "0";
                        }
                    }
                    if (elem.order === 4) {
                        if (checkCheckbox(elem.permission_id) === true) {
                            obj["Delete"] = "1";
                        } else {
                            obj["Delete"] = "0";
                        }
                    }
                    if (row.rights.length - 1 == i) arr.push(obj)
                })
            }
            if (index === data.length - 1) {
                return arr
            }
        });
        return arr
    }

    const exportData = async (fileType, fileName) => {
        let data = modifiedData();
        const header = ["Module", "Add", "View", "Edit", "Delete"];

        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(blobURL);
            }, 1000);
        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);

            // Returning false as downloading of file is already taken care of
            return false;
        }
        if (fileType === "pdf") {
            const compatibleData = data.map((row) => {
                return [row.Module, row.View, row.Add, row.Edit, row.Delete];
            });
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    // valign: "center",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }
    };

    return (
        <div className="custom-table-div filter-search-icon card card-table-custom">
            <div className="search-filter-div">
                <div className="search-filter-div-left">
                    <div className="system-administration-table table-responsive">
                        <div className="table-responsive-div">
                            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                <div id="assessment-table-main_filter" className="dataTables_filter">
                                    <label>
                                        <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                                    </label>
                                    <div className="filter-eff filter-data-btn">
                                        <button className="filter-buttons">
                                            <i className="fal fa-filter"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="filter-button-group">
                            <div className="filter-scroll">
                                <div className={`filter-scroll-inner  filter-custom-new`}>
                                    
                                </div>
                            </div>
                            <div className="reset-btn-group">
                                <div className="button-reset dropdown-comman">
                                    <button className="btn btn-primary" onClick={resetFilter}>
                                        <i className="fal fa-redo"></i>Reset
                                    </button>
                                </div>

                                <div className="files-export-group">
                                    <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Roles"); }} >
                                        <i className="fal fa-file-excel"></i>
                                    </button>
                                    <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Roles"); }} >
                                        <i className="fal fa-file-csv"></i>
                                    </button>
                                    <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Roles"); }} >
                                        <i className="fal fa-file-pdf"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loader && <SkeletonTicketList />}

            <DataTable data={dataToRender()} columns={columns} defaultSortField="role" defaultSortAsc={false} pagination={false} paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE} />

            <div className="add-ticket-blk dropdown-comman form-group-save-cancel">
                <button className="btn btn-primary" onClick={() => { submit() }}>
                    <i className="fa fa-save"></i>Save
                </button>
                <button className="btn btn-close btn-danger" onClick={() => { getPermissionRoles() }}>
                    <i className="fa fa-times"></i>Cancel
                </button>
            </div>
        </div>
    )

}

export default RolesEdit;