import React from "react";
// { useEffect, useState }from "react";
import { useHistory, useParams} from "react-router-dom";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import ListofBookings from "./ListofBookings";
// import { GetStudentDetailsCancelToken } from "../../../services/StudentsSettingServices";
// import { GetAllBookings } from "../../../services/BookingServices";
import CreateBooking from "./CreateBooking";
import EditBooking from "./EditBooking";
// import Bookings from "./Bookings";
import { GlobalIconNames } from "../../../utils/GlobalIconNames";
// import axios from "axios";



function BookingManagement() {
  const history = useHistory();
  const { type, id } = useParams();


  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        Booking<span> Management</span>
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div
                          className="add-new-ticket-bx"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                            <button
                              onClick={() => history.goBack()}
                              title="Back"
                              className="btn btn-white-bordered"
                            >
                              <i className="fal fa-angle-left"></i>Back
                            </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      {type == "table" && !id && <ListofBookings />}
                      {type === 'add' && !id && <CreateBooking />}
                      {type === 'edit' && id && <EditBooking/>}
                      {/* {type == "add" && <CreateBookings />} */}
                      {/* {type == "open" && <Bookings />} */}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}


export default BookingManagement;