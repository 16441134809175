import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import SideBar from "../common/SideBar";
import Program from "./program/Program.js";
import { useParams, Link, useHistory } from "react-router-dom";
import ProgramDetail from "./program/ProgramDetail";
import AuditTrail from "./program/AuditTrail";
import AssignedTable from "./program/ProgramDetailBlock/AssignedTable";
import FeaturesBenefits from "./program/FeaturesBenefits";
import { GetProgramDetailCancelToken } from "../../services/ProgramService";
import { TrimText } from "../common/TrimText";
import { GlobalIconNames } from "../../utils/GlobalIconNames";
import axios from "axios";

const Programs = () => {
  const history = useHistory();
  const { tab, type, id } = useParams();
  const [programId] = useState(id);
  const [programName, setProgrammeName] = useState("");
  const [detail, setDetail] = useState();

  useEffect(() => {
    const cancelTokenSources = [];

    const programmeDetails = async () => {

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetProgramDetailCancelToken({ PMC_ID: id }, source.token);
        if (res.status === 200) {
          setProgrammeName(res.data[0].SAQA_Name)
          setDetail(res.data[0]);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    programmeDetails();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [type, tab, id])
  // useEffect(() => {
  //   if ((type == "table" && (tab=="assigned-modules" || tab=="features-benefits")) || type=="open" || type == "duplicate") {
  //     GetProgramDetail({ PMC_ID: id })
  //   .then(res => setProgrammeName(res.data[0].SAQA_Name))
  //   .catch(err => console.error("error", err))
  //   }
  // }, [type, tab, id])


  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap new-standard-box">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        {(tab == "programme" || tab == "auditTrail") && type == "table" ? "Programmes " : tab == "programme" && type == "add" ? "Add " : "Programme: "}
                        {((tab == "programme" && type !== "table") || (tab == "auditTrail" && type !== "table") || tab == "features-benefits" || tab == "assigned-modules") ? <span title={programName}>{tab == "programme" && type == "add" ? "Programme" : TrimText(programName, 60)}</span> : ""}
                        {/* Programmes { tab != "programme" || tab != "auditTrail" ? ":" : ""}  {tab != "programme" && <span>{programName}</span>} */}
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button
                            onClick={() => history.goBack()}
                            title="Back"
                            className="btn btn-white-bordered"
                          >
                            <i className="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  {type !== "add" && type !== "duplicate" && <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          type == "table" && (tab == "programme" || tab == "auditTrail") ?
                            "/program-management-center/programmes/programme/table" : `/program-management-center/programmes/programme/open/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "programme" ? "active" : ""}`}
                        id="pills-tab1"
                        data-toggle="pill"
                        to="/program-management-center/programmes/programme/table"
                        href="#pills-tab11"
                        role="tab"
                        aria-controls="pills-tab11"
                        aria-selected="true"
                      >
                        {(tab == "programme" || tab == "auditTrail") && type == "table" ? GlobalIconNames("Programmes").html : GlobalIconNames("details").html}
                      </Link>
                    </li>
                    {(tab == "programme" && type == "open") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/programmes/features-benefits/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "features-benefits" ? "active" : ""}`}
                        id="pills-tab4"
                        data-toggle="pill"
                        to="/program-management-center/programmes/features-benefits/table"
                        href="#pills-tab14"
                        role="tab"
                        aria-controls="pills-tab14"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Features & Benefits", "fa-bars", "Features & Benefits").html}
                      </Link>
                    </li>
                    }
                    {(tab == "features-benefits") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/programmes/features-benefits/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "features-benefits" ? "active" : ""}`}
                        id="pills-tab4"
                        data-toggle="pill"
                        to="/program-management-center/programmes/features-benefits/table"
                        href="#pills-tab14"
                        role="tab"
                        aria-controls="pills-tab14"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Features & Benefits", "fa-bars", "Features & Benefits").html}
                      </Link>
                    </li>
                    }
                    {(tab == "assigned-modules") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/programmes/features-benefits/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "features-benefits" ? "active" : ""}`}
                        id="pills-tab4"
                        data-toggle="pill"
                        to="/program-management-center/programmes/features-benefits/table"
                        href="#pills-tab14"
                        role="tab"
                        aria-controls="pills-tab14"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Features & Benefits", "fa-bars", "Features & Benefits").html}
                      </Link>
                    </li>
                    }
                    {(tab == "auditTrail" && type == "open") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(`/program-management-center/programmes/features-benefits/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "features-benefits" ? "active" : ""}`}
                        id="pills-tab4"
                        data-toggle="pill"
                        to="/program-management-center/programmes/features-benefits/table"
                        href="#pills-tab14"
                        role="tab"
                        aria-controls="pills-tab14"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Features & Benefits", "fa-bars", "Features & Benefits").html}
                      </Link>
                    </li>
                    }
                    {(tab == "programme" && type == "open") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          `/program-management-center/programmes/assigned-modules/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "assigned-modules" ? "active" : ""}`}
                        id="pills-tab3"
                        data-toggle="pill"
                        to="/program-management-center/programmes/assigned-modules/table"
                        href="#pills-tab13"
                        role="tab"
                        aria-controls="pills-tab13"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Modules").html}
                      </Link>
                    </li>}
                    {(tab == "assigned-modules") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          `/program-management-center/programmes/assigned-modules/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "assigned-modules" ? "active" : ""}`}
                        id="pills-tab3"
                        data-toggle="pill"
                        to="/program-management-center/programmes/assigned-modules/table"
                        href="#pills-tab13"
                        role="tab"
                        aria-controls="pills-tab13"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Modules").html}
                      </Link>
                    </li>}
                    {(tab == "features-benefits") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          `/program-management-center/programmes/assigned-modules/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "assigned-modules" ? "active" : ""}`}
                        id="pills-tab3"
                        data-toggle="pill"
                        to="/program-management-center/programmes/assigned-modules/table"
                        href="#pills-tab13"
                        role="tab"
                        aria-controls="pills-tab13"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Modules").html}
                      </Link>
                    </li>}
                    {(tab == "auditTrail" && type == "open") && <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          `/program-management-center/programmes/assigned-modules/table/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "assigned-modules" ? "active" : ""}`}
                        id="pills-tab3"
                        data-toggle="pill"
                        to="/program-management-center/programmes/assigned-modules/table"
                        href="#pills-tab13"
                        role="tab"
                        aria-controls="pills-tab13"
                        aria-selected="true"
                      >
                        {GlobalIconNames("Modules").html}
                      </Link>
                    </li>}
                    <li
                      className="nav-item"
                      onClick={() =>
                        history.push(
                          tab == "programme" && type == "table" ?
                            "/program-management-center/programmes/auditTrail/table" : `/program-management-center/programmes/auditTrail/open/${id}`
                        )
                      }
                    >
                      <Link
                        className={`nav-link ${tab == "auditTrail" ? "active" : ""}`}
                        id="pills-tab2"
                        data-toggle="pill"
                        to="/program-management-center/programmes/auditTrail/table"
                        href="#pills-tab12"
                        role="tab"
                        aria-controls="pills-tab12"
                        aria-selected="true"
                      >
                        {GlobalIconNames("audittrail").html}
                      </Link>
                    </li>
                  </ul>}
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab11"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      {(tab == "programme") && type == "table" && <Program />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab14"
                      role="tabpanel"
                      aria-labelledby="pills-tab4"
                    >
                      {tab == "features-benefits" && type == "table" && <FeaturesBenefits />}


                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab13"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      {(tab == "assigned-modules") && type == "table" &&
                        <AssignedTable
                          // data={assigned_data}
                          // refresh={handlerefresh}
                          id={programId}
                        // filterData={filterData}
                        />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab12"
                      role="tabpanel"
                      aria-labelledby="pills-tab2"
                    >
                      {tab == "programme" && (type == "open" || type == "add" || type == "duplicate") && <ProgramDetail detail={detail} />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab12"
                      role="tabpanel"
                      aria-labelledby="pills-tab2"
                    >
                      {tab == "auditTrail" && <AuditTrail />}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Programs;
