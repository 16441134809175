import React, { useEffect, useMemo, useState } from 'react'
import DataTable from 'react-data-table-component';
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Str from "../../common/Str";
import { Link, useHistory } from 'react-router-dom';
import { TrimText } from '../../common/TrimText';
import { pmcStatus } from '../program/GroupItems';
import PermissionsGate from '../../../utils/permissionGate';
import hasPermission from '../../../utils/hasMultiplePermission';
import { useSelector } from 'react-redux';
import { RenderSchool } from '../../../utils/CommonGroupingItem';
import { GetCommonContentReReg } from '../../../services/ProgrammeServices';
import Tablefilter from '../../common/Tablefilter';
import { TABLE_ROWS_PER_PAGE } from '../../../utils/Constants';
import { handleTableScroll } from '../../../utils/commonFunction';

function AssignedTable({data, loading}) {
    const history = useHistory();
    const [search, setSearch] = useState("");
    const [school, setSchool] = useState({ arr: [], checkObj: {} });
    const [schoolArr, setSchoolArr] = useState([]);

    useEffect(() => {
        handleTableScroll()
      }, [loading])

    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
        let response = hasPermission({ scopes: ["matpview"], permissions: givenPermsisions });
        if (!response) {
            history.push("/noaccess");
        }
    }, [])

    useEffect(() => {
        GetCommonContentReReg().then(res =>
            setSchoolArr(res.data.school_type)
        ).catch(err => console.error("err in common data api :", err))
    }, [])

    const resetFilter = () => {
        setSearch("");
        setSchool({ arr: [], checkObj: {} });
    };

    const exportData = (fileType, fileName) => {
        let data, header;
        data = dataToRender();
        header = ["Name", "Code", "SAQA Link", "Status", "#NQF", "School"];
        data = data?.map((row) => ({
            ...row,
            Name: row?.P_SAQA_Name ? row?.P_SAQA_Name : "-",
            Code: row?.P_Code ? row?.P_Code : "-",
            "SAQA Link": row?.P_SAQA_Link ? row?.P_SAQA_Link : "-",
            "Status": row?.status ? row?.status : '-',
            '#NQF': row?.P_NQF_Level ? row?.P_NQF_Level : "-",
            School: row?.P_School ? row?.P_School : "-",
        }));

        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = [
                "text/csv",
                anchor.download,
                anchor.href,
            ].join(":");
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(blobURL);
            }, 1000);
        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.forEach((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);

            // Returning false as downloading of file is already taken care of
            return false;
        }
        if (fileType === "pdf") {
            let compatibleData = data.map((row) => {
                return [
                    row["Name"],
                    row["Code"],
                    row["SAQA Link"],
                    row["Status"],
                    row["#NQF"],
                    row["School"],
                ];
            });

            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    // valign: "center",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }
    };

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearch(value);
    };

    const myNameFilter = (a, b) => {
        return a.SAQA_Name && a.SAQA_Name.toLowerCase() > b.SAQA_Name && b.SAQA_Name.toLowerCase() ? 1 : -1;
    };
    const myCodeFilter = (a, b) =>
        a.Code.toLowerCase() > b.Code.toLowerCase() ? 1 : -1;
    const myNQFFilter = (a, b) =>
        Number(a.NQF_Level) > Number(b.NQF_Level) ? 1 : -1;

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "P_SAQA_Name",
            sortable: true,
            wrap: true,
            sortFunction: myNameFilter,
            cell: (row) => (
                <PermissionsGate
                    scopes={["matpedit"]}
                    RenderError={() => <span className="feature-name"> <span className="textLimit100">{row.P_SAQA_Name}</span></span>}
                >
                    <Link
                        to={`/program-management-center/programmes/programme/open/${row.ProgrammeId}`}
                        title={row.P_SAQA_Name}
                        className="as-text-blue curser feature-name"
                    >
                        <span className="textLimit100">{row.P_SAQA_Name}</span>
                    </Link>
                </PermissionsGate>
            ),
        },
        {
            name: "Code",
            selector: "P_Code",
            sortFunction: myCodeFilter,
            sortable: true,
            cell: (row, index) => (
                <div className="ticket-id">
                    <span title={row.P_Code} className="overflow-ellipsis2">
                        {TrimText(row.P_Code, 10)}
                    </span>
                </div>
            ),
        },

        {
            name: "SAQA Link",
            selector: "P_SAQA_Link",
            sortable: true,
            wrap: true,
            cell: (row) =>
                row.P_SAQA_Link ? (
                    <a
                        // href={row.P_P_SAQA_Link}
                        href={
                            row.P_SAQA_Link.includes("http")
                                ? row.P_SAQA_Link
                                : "http://" + row.P_SAQA_Link
                        }
                        target={"_blank"}
                        className="as-text-blue curser"
                        title={row.P_SAQA_Link}
                    >
                        {TrimText(row.P_SAQA_Link, 15)}
                    </a>
                ) : (
                    "-"
                ),
        },
        {
            name: "Status",
            selector: "status",
            sortable: true,
            wrap: true,
            cell: (row) =>
                row?.status != null ? pmcStatus(row?.status && row?.status) : "-",
        },
        {
            name: "#NQF",
            selector: "P_NQF_Level",
            sortFunction: myNQFFilter,
            sortable: true,
            wrap: true,
            cell: (row) => <div title={row?.P_NQF_Level}>{row?.P_NQF_Level}</div>,
        },
        {
            name: "School",
            selector: "P_School",
            sortable: true,
            wrap: true,
            cell: (row) => row.P_School ? RenderSchool(row.P_School).html : "-",
        },
        {
            name: "Actions",
            selector: "",
            cell: (row) => (
                <div className="assessment-08">
                    <div className="as-buttons">
                        <PermissionsGate
                            scopes={["matpedit"]}
                            RenderError={() => (
                                <button
                                    className="btn btn-primary rounded-circle"
                                    title="Open"
                                    disabled
                                >
                                    <i className="fal fa-folder-open"></i>
                                </button>
                            )}
                        >
                            <Link
                                to={`/program-management-center/programmes/programme/open/${row.ProgrammeId}`}
                                className="btn btn-primary rounded-circle"
                                title="Open"
                            >
                                <i className="fal fa-folder-open"></i>
                            </Link>
                        </PermissionsGate>
                        <PermissionsGate
                            scopes={["matpadd"]}
                            RenderError={() => (
                                <button
                                    className="btn btn-primary rounded-circle"
                                    title="Open"
                                    disabled
                                >
                                    <i className="fal fa-folder-open"></i>
                                </button>
                            )}
                        >
                            <Link
                                to={`/program-management-center/programmes/programme/duplicate/${row.ProgrammeId}`}
                                className="btn btn-primary rounded-circle"
                                title="Duplicate"
                            >
                                <i className="fal fa-copy"></i>
                            </Link>
                        </PermissionsGate>
                    </div>
                </div>
            ),
        },
    ], []);

    const dataToRender = () => {
        let updatedData = [];
        let allData = data;
        if (search.length) {
            let tempCode = allData.filter((item) => {
                let includes =
                    item.Code &&
                    item.Code.toString().toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempName = allData.filter((item) => {
                let includes =
                    item.P_SAQA_Name &&
                    item.P_SAQA_Name.toString()
                        .toLowerCase()
                        .includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempLink = allData.filter((item) => {
                let includes =
                    item.P_SAQA_Link &&
                    item.P_SAQA_Link.toString()
                        .toLowerCase()
                        .includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempP_NQF_Level = allData.filter((item) => {
                let includes =
                    item.P_NQF_Level &&
                    item.P_NQF_Level.toString()
                        .toLowerCase()
                        .includes(search.toLowerCase());

                if (includes) {
                    return includes;
                } else return null;
            });
            let tempP_School = allData.filter((item) => {
                let includes =
                    item.P_School &&
                    item.P_School.toString().toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempP_Quality_Council = allData.filter((item) => {
                let includes =
                    item.P_Quality_Council &&
                    item.P_Quality_Council.toString()
                        .toLowerCase()
                        .includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });

            let tempdata = [
                ...tempCode,
                ...tempName,
                ...tempLink,
                ...tempP_NQF_Level,
                ...tempP_School,
                ...tempP_Quality_Council,
            ];
            let unique = [...new Set(tempdata)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (school.arr.length) {
            let tempRole = updatedData;
            let tempSearch = tempRole.filter((item) => {
                const startsWith = school.arr.find(function (user, index) {
                    if (
                        user.toLowerCase() ==
                        (item.P_School ? item.P_School.toLowerCase() : "")
                    )
                        return item;
                });
                if (startsWith) {
                    return startsWith;
                } else return null;
            });

            updatedData = tempSearch;
        }

        if (search.length || school.arr.length) {
            return updatedData;
        } else {
            return data;
        }
    };
    return (
        <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
            <div
                className="custom-table-div filter-search-icon card card-table-custom"
            >
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div
                                    id="assessment-table-main_wrapper"
                                    className="dataTables_wrapper no-footer"
                                >
                                    <div
                                        id="assessment-table-main_filter"
                                        className="dataTables_filter"
                                    >
                                        <label>
                                            <input
                                                type="search"
                                                className=""
                                                placeholder="Search"
                                                aria-controls="assessment-table-main"
                                                onChange={handleSearchFilter}
                                                value={search}
                                            />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner  filter-custom-new`}>
                                        <Tablefilter
                                            filterName="School"
                                            optionArr={schoolArr}
                                            state={school}
                                            setState={setSchool}
                                            renderLabelFunction={RenderSchool}
                                        />
                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => resetFilter()}
                                            title="Reset"
                                        >
                                            <i className="fal fa-redo"></i>Reset
                                        </button>
                                    </div>
                                    <div className="files-export-group">
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("xlsx", "PMC");
                                            }}
                                            title="Export spreadsheet"
                                        >
                                            <i className="fal fa-file-excel icon"></i>
                                        </button>

                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("csv", "PMC");
                                            }}
                                            title="Export CSV"
                                        >
                                            <i className="fal fa-file-csv icon"></i>
                                        </button>

                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            onClick={() => {
                                                exportData("pdf", "PMC");
                                            }}
                                            title="Export PDF"
                                        >
                                            <i className="fal fa-file-pdf icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    data={dataToRender()}
                    columns={columns}
                    pagination={true}
                    progressComponent={<SkeletonTicketList />}
                    progressPending={loading}
                    noDataComponent={Str.noRecord}
                    defaultSortAsc={true}
                    defaultSortField={"P_SAQA_Name"}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
            </div>
        </div>
    )
}

export default AssignedTable