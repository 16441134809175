import React, { useState, useEffect } from "react";
import Header from "../common/Header";
import SideBar from "../common/SideBar";
import { useHistory, useParams } from "react-router";
import { ViewTicketDetails, CloseTicketService, AddComment, UpdateTicket, GetListOfCategory, GetListOfDepartments } from "../../services/SupportService";
import { StatusRender } from "../systemadministration/usermanagement/CheckRole";
import { TrimText } from "../common/TrimText";
import { IMAGE_URL, TABLE_DATE_TIME_FORMAT, downloadURL } from "../../utils/Constants";
import moment from "moment";
import { InitialRender } from "../common/Helper";
import Comments from "./viewticketblock/Comments";
import { useFormik } from "formik";
import Select from "react-select";
import { GetListOfUser } from "../../services/UserService";
import Swal from "sweetalert2";
import CourseModalTable from "./addticketmodals/CourseModalTable";
import Hover from "../common/Hover";
import PermissionsGate from "../../utils/permissionGate";
import { GetStudentDetails } from "../../services/StudentsSettingServices";
import { checkIfImageExists } from "../../utils/commonFunction";
import { Link } from "react-router-dom";
import { RenderProgrammeStudentStatus } from "../../utils/CommonStatusItems";
import { GlobalIconNames } from "../../utils/GlobalIconNames";
import axios from "axios";

const ViewTicket = () => {
  const history = useHistory();
  const { id } = useParams();

  const [ticket, setTicket] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [ticketThread, setTicketThread] = useState([]);
  const [dummyState, setdummyState] = useState(false);

  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [studentDetail, setStudentDetail] = useState([]);
  const [deptName, setDeptName] = useState();
  const [category, setCategory] = useState([]);

  const handleTicketThread = (value) => {
    AddComment(value)
      .then((res) => {})
      .catch((error) => { console.log(error); })
      .finally(()=>{setdummyState(!dummyState);});
    
  }

  useEffect(() => {
    ViewTicketDetails(id).then((res) => {
      setTicket(res?.data?.tickets?.ticket);
      setAttachments(res.data.attachments)
      GetStudentDetails(res?.data?.tickets?.ticket?.id_student)
        .then((res) => {
          setStudentDetail(res.data.data_list);
        })
        .catch((err) => console.log(err));
      if (res.data.tickets.threadList) setTicketThread(res.data.tickets.threadList);
      else setTicketThread({});
    });
  }, [dummyState]);

  useEffect(() => {
    GetListOfDepartments().then((res) => {
      let x = { value: "", label: "" };
      x = res.data.sort((a,b)=>{return a.title > b.title ? 1 : -1}).map((data) => {
        return { ...x, value: `${data.title}`, label: `${data.title}`, id: `${data.id}` };
      });
      setDeptName(x);
      let deptId=ticket?.department && x.filter((data)=>{
        if(data.value == ticket?.department){
          return data.id
        }
      })
      if(deptId && deptId.length && deptId[0].id){
        GetListOfCategory(deptId[0].id).then((res) => {
          let x = { value: "", label: "" };
          x = res.data.sort((a,b)=>{return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1}).map((data) => {
            return { ...x, value: `${data.title}`, label: `${data.title}`, id: `${data.id}` };
          });
          setCategory(x);
        });
      }
      
    });
  }, [ticket])
  

  useEffect(() => {
    GetListOfUser().then((res) => {
      let x = [];
      res.data.map((data) => {
        if(data.is_active){
          x.push({value: `${data.UserID}`, label: `${data.Firstname + " " + data.Lastname}` });
        }
      });
      setUserData(x);
    });
  }, []);

  const handleCloseTicket = () => {
    const value = { ticketId: id, status: "Closed" };
    CloseTicketService(value)
      .then((res) => {
        setTicket({ ...ticket, status: "Closed" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const levelist = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ];

  const status = [
    { value: "Open", label: "Open" },
    { value: "New", label: "New" },
    { value: "Closed", label: "Closed" },
    { value: "Pending-Investigation", label: "Pending-Investigation" },
    { value: "Pending-Student", label: "Pending-Student" },
    { value: "Pending-Academy", label: "Pending-Academy" },
  ];

  const setSelectedCourse = (names) => {
    let coursename = names.map((name) => name.name);
    let courseID = names.map((name) => name.id);
    let courseIntake = names.map((name) => name.intakeId);
    formik.setFieldValue("courseName", coursename);
    formik.setFieldValue("course", courseID);
    formik.setFieldValue("courseIntake",courseIntake);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      level: ticket?.level ? ticket?.level?.toString() : "",
      assignTo: ticket?.assign_to ? ticket.assign_to : "",
      AssignToName: ticket?.assign_to_name ? ticket.assign_to_name : "",
      status: ticket?.status,
      course: ticket?.CourseId ? ticket?.CourseId : "",
      courseName: ticket?.courseName ? ticket?.courseName : "",
      department: ticket?.department ? ticket?.department : "",
      category: ticket?.category ? ticket?.category : "",
      courseIntake : ticket?.intake_redirect_id && ticket?.intake_redirect_id!=null ? ticket?.intake_redirect_id : "" 
    },
    onSubmit: (values) => {
      delete (values.courseIntake)
      setLoading(true);
      UpdateTicket({ ...values, ticketId: ticket?.id_ticket }).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Submittted",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          setLoading(false);
        });
      }).catch((error) => { console.log(error) });

    }
  })

  const handleDeptCategory = async (id) => {
    GetListOfCategory(id).then((res) => {
      let x = { value: "", label: "" };
      x = res.data.sort((a,b)=>{return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1}).map((data) => {
        return { ...x, value: `${data.title}`, label: `${data.title}`, id: `${data.id}` };
      });
      setCategory(x);
    });
  };


  const downloadAs = (url, name) => {
    axios.get(url, {
      responseType: "blob"
    })
      .then(response => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = name;
        a.click();
      })
      .catch(err => {
        console.log("error", err);
      });
  };
  
  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="Tickets-detail-head d-flex mb-0">
                  <div className="my-tickets-heading-blk flex-direction mb-2 shift-move view-ticket-head">
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="name-w-head d-flex align-items-center flex-wrap">
                        <h4 className="text-left-align landing-heading heading_color_dynamic">
                          Tickets <span>Details</span>
                        </h4>
                        <div className="Tickets-head-rgt-new ticket-status-blk">
                          <h3 className="m-0" title={ticket?.subject}>{ticket?.subject}</h3>
                          {ticket?.status ? <StatusRender title={ticket?.status} status={ticket?.status} statusLength={ticket?.status?.length} /> : null}
                        </div>
                        <div className="text-md-right">
                          <div className="ticket-view-btn d-flex align-items-center">
                            

                            <div className="text-md-right action2-blk d-flex">
                            {ticket?.status != "Closed" && ticket?.status != undefined && (
                              <div className="add-new-ticket-bx" onClick={() => handleCloseTicket()}>
                                <button title="Close Ticket" className="btn btn-primary ">
                                  <i className="fal fa-times"></i>Close Ticket
                                </button>
                              </div>
                            )}
                            <div className="add-new-ticket-bx ml-3" onClick={() => history.goBack()}>
                              <a title="Back" className="btn btn-white-bordered">
                                <i className="fal fa-angle-left"></i>Back
                              </a>
                            </div>
                            </div>
                            <div className="text-md-right action2-blk">
                              <div className="ticket-view-btn d-flex align-items-center">
                                <div className="add-new-ticket-bx">
                                  <PermissionsGate scopes={["sstudview"]} RenderError={() => (
                                    <button
                                      title="Student View"
                                      className="btn btn-white-bordered"
                                      disabled
                                    >
                                      {GlobalIconNames("studentview").html}
                                    </button>
                                  )}>
                                    <a
                                      title="Student View"
                                      className="btn btn-white-bordered"
                                      href={`https://student.myaie.ac/viewStudent?email=${studentDetail && studentDetail.email ? studentDetail.email : ""}&password=${studentDetail && studentDetail.password ? studentDetail.password : ""
                                        }`}
                                      target="_blank"
                                    >
                                      {GlobalIconNames("studentview").html}
                                    </a>
                                  </PermissionsGate>
                                </div>
                              </div>
                            </div>

                            <div className="text-md-right action2-blk">
                              <div className="ticket-view-btn d-flex align-items-center">
                                <div className="add-new-ticket-bx">
                                  <PermissionsGate scopes={["sview"]} RenderError={() => (
                                    <button
                                      title="Student Profile"
                                      className="btn btn-white-bordered"
                                      disabled
                                    >
                                      {GlobalIconNames("studentprofile").html}
                                    </button>
                                  )}>
                                    <Link
                                      title="Student Profile"
                                      className="btn btn-white-bordered"
                                      to={`/studentAdministration/students/open/${studentDetail?.id}/general`}
                                    >
                                      {GlobalIconNames("studentprofile").html}
                                    </Link>
                                  </PermissionsGate>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="ml-auto"></div> */}
                  </div>
                </div>
                <div className="tabs-wrap">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="Tickets-detail-body row">
                      <div className="ticket-details col-md-3">
                        <div className="ac-white-card p-0">
                          <div className="t p-30px">
                            <div className="row mb-3 align-items-center">
                              <div className="col-md-6">
                                <h2 className="ticket-id">#{ticket?.id_ticket}</h2>
                              </div>
                              <div className="col-md-6">
                                <label className="lb-black">
                                  <b>Student</b>
                                </label>
                                <div className="assigned-title-block-new">
                                  <div className="assigned-title-itm">
                                    <span className="assigned-title-blk  name-icon cat-dark-red">
                                      {ticket && ticket?.photo && checkIfImageExists(`${IMAGE_URL}/${ticket?.photo?.replace("public/","").replaceAll("/home/myaie/public_html/","")}`) ? (
                                        <img src={`${IMAGE_URL}/${ticket?.photo?.replace("public/","").replaceAll("/home/myaie/public_html/","")}`} />
                                      ) : (
                                        InitialRender([ticket?.studentName ? ticket?.studentName?.split(' ')[0] : "N", ticket?.studentName ? ticket?.studentName?.split(' ')[1] : "A"])
                                      )}
                                       <span className={`profile-box-2-status ${ticket?.activity_status ? ticket?.activity_status.toLowerCase()=="online" ? "Online" :ticket?.activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                                          <i className="fas fa-circle"></i>
                                        </span>
                                      <Hover firstName={ticket?.studentName?.split(' ')[0]} lastName={ticket?.studentName?.split(' ')[1]} photo={ticket?.photo} email={TrimText(ticket?.email, 20)} mobile={ticket?.mobile} status={ticket?.student_status} activity_status={ticket?.activity_status} showNumber={true} number={ticket?.student_crm_id} right={true}/>
                                    </span>
                                  <a
                                    // href={`https://www.myaie.ac/cadcocms/student_list_edit_personal.php?uid=${ticket.id_student}&section=personal`}
                                    href={`/studentAdministration/students/open/${ticket?.id_student}/general`}
                                     target="_blank" >
                                      {ticket?.studentName ? ticket?.studentName : "NA"}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <label className="lb-black"><b>Date Modified</b></label>
                                <p>{moment(ticket?.last_update).format(TABLE_DATE_TIME_FORMAT)}</p>
                              </div>
                              <div className="col-md-6">
                                <label className="lb-black"><b>Date Created</b></label>
                                <p>{moment(ticket?.date_add).format(TABLE_DATE_TIME_FORMAT)}</p>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group-blk mb-3" title="Department">
                                  <label className="lb-black" style={{ color: "#06172a" }}><b>Department</b></label>
                                  <Select
                                    className={"form-control custom-select-box" + (formik.errors.department && formik.touched.department ? " is-invalid" : "")}
                                    name="department"
                                    value={deptName ? deptName.filter((data)=>{
                                      return data.value == formik.values.department
                                    }) : ""}
                                    onChange={(value) => {
                                      formik.setFieldValue("department", value.value);
                                      handleDeptCategory(value.id);
                                    }}
                                    onBlur={formik.handleBlur}
                                    options={deptName}
                                    maxMenuHeight={175}
                                    placeholder={formik.values.department ? formik.values.department : "Department"}
                                  />

                                  {formik.errors.department && formik.touched.department ? (
                                    <span className="exclamation">
                                      <i className="fal fa-exclamation-circle"></i>
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group-blk mb-3" title="Category">
                                  <label className="lb-black" style={{ color: "#06172a" }}><b>Category</b></label>
                                  <Select
                                    className={"form-control custom-select-box" + (formik.errors.category && formik.touched.category ? " is-invalid" : "")}
                                    name="category"
                                    value={category.filter((data)=>{
                                      return data.value.toString().toLowerCase() == formik.values.category.toString().toLowerCase()
                                    })}
                                    onChange={(value) => {
                                      if (value) {
                                        formik.setFieldValue("category", value.value)
                                      } else {
                                        formik.setFieldValue("category", "")
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                    options={category}
                                    maxMenuHeight={175}
                                    placeholder={formik.values.category ? formik.values.category : "Category"}
                                    isClearable
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <label className="lb-black"><b>Submitted Via</b></label>
                                <p>{ticket?.submitted_via ? ticket?.submitted_via : "NA"}</p>
                              </div>
                              <div className="col-md-6">
                                <label className="lb-black"><b>Student Status</b></label>
                                <p>{ticket?.student_status ? RenderProgrammeStudentStatus(ticket?.student_status).html : "NA"}</p>
                              </div>

                              <div className="col-md-4">
                                <div className="form-group-blk mb-3">
                                  <label className="lb-black" style={{ color: "#06172a" }}><b>Level</b></label>

                                  <Select
                                    className={"form-control custom-select-box " + (formik.errors.level && formik.touched.level ? " is-invalid" : "")}
                                    name="level"
                                    value={levelist?.filter(val=>val.value === formik.values.level)}
                                    onChange={(value) => {
                                      if (value) {
                                        formik.setFieldValue("level", value.value)
                                      } else {
                                        formik.setFieldValue("level", "")
                                      }
                                    }}
                                    options={levelist}
                                    maxMenuHeight={175}
                                    placeholder={formik.values.level ? formik.values.level : "Version"}
                                    isClearable
                                  />
                                </div>
                              </div>

                              <div className="col-md-8">
                                <div className="form-group-blk mb-3">
                                  <label className="lb-black" style={{ color: "#06172a" }}><b>Status</b></label>
                                  <Select
                                    className={"form-control custom-select-box" + (formik.errors.status && formik.touched.status ? " is-invalid" : "")}
                                    name="status"
                                    value={status?.filter(val=>val.value === formik.values.status)}
                                    onChange={(value) => {
                                      if (value) {
                                        formik.setFieldValue("status", value.value);
                                      } else {
                                        formik.setFieldValue("status", "");
                                      }
                                      // formik.setFieldValue("AssignToName", value.label);
                                    }}
                                    onBlur={formik.handleBlur}
                                    options={status}
                                    maxMenuHeight={175}
                                    placeholder={formik.values.status ? formik.values.status : "status"}
                                    isClearable
                                  />
                                  {formik.errors.status && formik.touched.status ? (
                                    <span className="exclamation">
                                      <i className="fal fa-exclamation-circle"></i>
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group-blk form-group mb-3">
                                  <label className="lb-black" style={{ color: "#06172a" }}><b>Course</b></label>
                                  <div className="form-grp-btn">
                                    <input
                                      name="id_subject"
                                      type="text"
                                      className={"form-control" + (formik.errors.course && formik.touched.course ? " is-invalid" : "")}
                                      placeholder={formik.values.courseName || "Course"}
                                      onClick={()=>history.push(`/courseAdministration/coursesdetails/${formik.values.courseIntake}/detailCourses/show`)}
                                      disabled={!formik.values.courseName}
                                      role='button'
                                    />
                                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#add-course" title="Select">
                                      Select
                                    </button>
                                  </div>

                                  {formik.errors.course && formik.touched.course ? (
                                    <span className="exclamation">
                                      <i className="fal fa-exclamation-circle"></i>
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                          
                              <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                  <label className="lb-black" style={{ color: "#06172a" }}><b>Assign To</b></label>
                                  <Select
                                    className={"form-control custom-select-box" + (formik.errors.assignTo && formik.touched.assignTo ? " is-invalid" : "")}
                                    name="assignTo"
                                    value={userData?.filter(val=>val.value === formik.values.assignTo)}
                                    onChange={(value) => {
                                      if (value) {
                                        formik.setFieldValue("assignTo", value.value);
                                        formik.setFieldValue("AssignToName", value.label);
                                      } else {
                                        formik.setFieldValue("assignTo", "");
                                        formik.setFieldValue("AssignToName", "");
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                    options={userData.sort((a,b)=>{return a.label > b.label ? 1 : -1})}
                                    maxMenuHeight={175}
                                    placeholder={formik.values.AssignToName ? formik.values.AssignToName : "Assign To"}
                                    isClearable
                                  />
                                  {formik.errors.assignTo && formik.touched.assignTo ? (
                                    <span className="exclamation">
                                      <i className="fal fa-exclamation-circle"></i>
                                    </span>
                                  ) : null}
                                </div>
                              </div>

                              {attachments && attachments.length > 0 && attachments[0].attachment && (
                                <div className="col-md-12">
                                  <label className="lb-black"><b>Attachments</b></label>
                                  <div className="doc-list">
                                    {attachments?.map((i) => (
                                      <div key={i} className="doc-list-in d-flex relative-class mb-2">
                                        <div className="icon-w-box">
                                          <i className="fal fa-paperclip"></i>
                                        </div>
                                        <div className="doc-list-info">
                                          <p className="as-text-blue mb-0" title={i.extentsion}>
                                            <a className="as-text-blue curser" onClick={()=>downloadAs(downloadURL + (!i.attachment.includes("http") ? IMAGE_URL+"/" : "") + i.attachment.replace("/home/myaie/public_html/","").replace("public/",""), i.originalName)}>
                                              {i.originalName}
                                            </a>
                                          </p>
                                          <span className=" attch-text-font">
                                            {i.extentsion}
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                          <div className="col-md-12">
                          <div className="form-group form-group-save-cancel ml-3 mb-3">
                            
                              <button type="submit" className="btn btn-primary" title="Submit" disabled={loading}>
                                {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}Save
                              </button>
                          
                              <button title="Cancel" className="btn btn-danger" onClick={() => formik.resetForm()}>
                                <i className="fal fa-times"></i>Cancel
                              </button>

                          </div>
                          </div>
                          </div>
                        </div>
                      </div>
                      <div className="comment-details col-md-9">
                        <Comments ticketThread={ticketThread} ticketData={ticket} handleTicketThreadList={handleTicketThread} message={ticket?.message ? ticket?.message : ""} />
                      </div>
                    </div>

                    <div className="topic-add-modal modal fade" id="add-course" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                          <div className="modal-body">
                            <CourseModalTable setCourseName={setSelectedCourse} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTicket;
