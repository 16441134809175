import React, { useState, useMemo, useEffect } from "react";
import { InitialRender } from "../common/Helper";
import { Link, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import { useFormik } from "formik";
import * as Yup from "yup";
import "jspdf-autotable";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import { FORM_DATE_TIME_FORMAT, FORM_TIME_FORMAT, IMAGE_URL, TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../utils/Constants";
import moment from "moment";
import Swal from "sweetalert2";
import {
  GetMentoredStudentsList,
  GetMentoredStudentsListCancelToken,
  GetMentoredStudentsDropdownCancelToken,
} from "../../services/DashBoardServices";
import { ColorRender } from "../systemadministration/usermanagement/CheckRole";
import Hover from "../common/Hover";
import $ from "jquery";
import FilterComponent from "../common/FilterComponent";
import Tablefilter from "../common/Tablefilter";
import { RenderActivityStatus, RenderProgrammeStudentStatus } from "../../utils/CommonStatusItems";
import { AddOrUpdateStudentNote, GetNoteSubjectList } from "../../services/TickectService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import axios from "axios";
import { handleTableScroll } from "../../utils/commonFunction";

function NotesPendingResponseSLP({ subjectStatus }) {
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const [value, onChange] = useState([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [activity, setActivity] = useState({ arr: [], checkObj: {} });
  const [dashboardList, setDashBoardList] = useState([]);
  const [virtualDropdown, setVirtualDropdown] = useState({course : [], programme : [], status : [], mStatus: [], lecturer :[], activity : []});
  const [courseIds, setCourseIds] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [sortkey, setSortKey] = useState("note_date");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [notesRow, setNotesRow] = useState({});
  const [relatedCourse, setRelatedCourse] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [fileName, setFileName] = useState();
  const [filePreview, setFilePreview] = useState(false);
  const [studentID, setStudentID] = useState('')
  const [relatedCourses, setRelatedCourses] = useState([]);
  const [reload, setReload] = useState(false)

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })


  const DetailsColumn = ({ details }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
      setExpanded(!expanded);
    };
  
    const truncatedText = details.slice(0, 105);
    const displayText = expanded ? details : truncatedText.length > 105 ? truncatedText + "..." : truncatedText;
    const seeMoreText = expanded ? ' See Less' : ' See More';
  
    return (
      <>
      {/* <div className={`${expanded ? 'expanded-mentored' : ''}`}> */}
      <div style={{whiteSpace: "break-spaces"}}>
        <span>{displayText}</span>
        {details.length > 100 && (
          <span onClick={toggleExpand} className="as-text-blue curser">
            {seeMoreText}
          </span>
        )}
        </div>
      </>
    );
  };

   useEffect(() => {
     setPage(1);
   }, [location]);

   useEffect(() => {
    let timeOut = setTimeout(() => {
      setSearch(debouncedTerm);
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [debouncedTerm]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  useEffect(() => {
    const cancelTokenSources = [];

    const getDashboard = async () => {
      setLoading(true);

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      const data = {
        viaLecturer:  [],
        pageNo: page,
        size: perPage,
        key: sortkey,
        sort: sortOrder,
        viaCourse: courseIds.length > 0 ? courseIds : [],
        viaProgramme:  [],
        viaSubjectStatus:  [],
        viaMentoringStatus:  [],
        viaStatus: status.arr.length > 0 ? status.arr : [],
        viaSearch: search,
        mtsStatus: subjectStatus,
        activity: activity.arr.length ? activity.arr : []  
      };
  
      try {
        const res = await GetMentoredStudentsListCancelToken(data, source.token);
        setDashBoardList(res.data?.result);
        setTotalRows(res.data?.Pagination?.total);
        if (res.status === 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
    
    getDashboard();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [
    search,
    status,
    page,
    perPage,
    sortkey,
    sortOrder,
    courseIds,
    reload,
    activity
  ]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();

    const fetchData = async () => {
      let data ={
        mtsStatus: subjectStatus
      }
      try {
        const res = await GetMentoredStudentsDropdownCancelToken(data, dropdownSource.token);
        setVirtualDropdown({...res.data, course : [] ,programme : [], status : [], mStatus : [], lecturer : [], activity: []})
        setVirtualDropdown({...res.data, course : res.data.Course ,programme : res.data.Programme, status : res.data.studentStatus, mStatus : res.data.MentoringStatus, lecturer : res.data.lecturer, activity: res.data.activity})
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
   
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };


  const resetFilter = () => {
    setSearch("");
    setDebouncedTerm("");
    setStatus({ arr: [], checkObj: {} });
    setActivity({ arr: [], checkObj: {} });
    setCourseIds([]);
    onChange([]);
  };

  const columns = useMemo(() => [
    {
      name: "Submitted By",
      selector: "studentFname",
      sortable: true,
      sortField: "student",
      cell: (row) => (
        <>
          {row.studentName ? (
            <div className="assigned-title-block-new">
              <div className="assigned-title-itm">
                <span
                  className={
                    "assigned-title-blk  name-icon cat-dark-red " +
                    ColorRender("student support")
                  }
                >
                  {row.studentImage ? (
                    <img
                      src={`${IMAGE_URL}/${row.studentImage.replaceAll(
                        "/home/myaie/public_html/",
                        ""
                      )}`}
                      alt="AIE"
                    />
                  ) : (
                    InitialRender([row.studentFname, row.studentLname])
                  )}
                  <span className={`profile-box-2-status ${row.student_activity_status ? row.student_activity_status.toLowerCase()=="online" ? "Online" :row.student_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                    <i className="fas fa-circle"></i>
                  </span>
                  <Hover
                    firstName={row.studentFname}
                    lastName={row.studentLname}
                    photo={row.studentImage}
                    email={row.studentEmail}
                    mobile={row.studentMobile}
                    right={true}
                    role={"lecturer"}
                    status={row.studentSatus}
                    activity_status={row.student_activity_status}
                    showNumber={true} 
                    number={row.student_crm_id}
                  />
                </span>
                <Link
                 title={row.studentName}
                 className="as-text-blue curser feature-name"
                 to={ `/studentAdministration/students/open/${row.stuid}/personal`}
                >
               
                  <span className="textLimit100" title={row.studentName}>{row["studentName"]}</span>
                </Link>
              </div>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Status",
      selector: "studentSatus",
      sortable: true,
      sortField: "studentstatus",
      cell: (row) => row.studentSatus ?  RenderProgrammeStudentStatus(row.studentSatus).html : "-",
    },
    {
      name: "Activity",
      selector: "studentSatus",
      // sortable: true,
      sortField: "studentstatus",
      cell: (row) => row?.activity ? RenderActivityStatus(row?.activity).html : "-",
    },
    {
      name: "Related To",
      selector: "courseName",
      sortable: true,
      sortField: "course",
      cell: (row) => (
        row.courseName ? <span className="overflow-ellipsis2 fst_line2" title={row.courseName}>
          <Link
            to={`/courseAdministration/coursesdetails/${row.intakeID}/dashboard/show`}
            className="as-text-blue curser font-weight-bold"
          >
            {row.courseName}
          </Link>
        </span> : "-"
      ),
    },
    {
      name: "Date",
      selector: "note_date",
      sortable: true,
      sortField: "note_date",
      cell: (row) => (
        <>
        {row.note_date ? <p className="right-space">
            {moment(row.note_date, "YYYY-MM-DD HH:MM:SS").format(TABLE_DATE_FORMAT)} at {moment(row.note_date, "YYYY-MM-DD HH:MM:SS").format(TABLE_TIME_FORMAT)}
          </p> : "-"}
        </>
      ),
    },
    
    {
      name: "Details",
      selector: "note_content",
      // sortable: true,
      sortField: "note_content",
      cell: (row) => row?.note_content ? <DetailsColumn details={row?.note_content} /> : "-"
    },
    {
      name: "Progress",
      selector: "lecturer_name",
      // sortable: true,
      // sortField: "lecturer",
      cell: (row) => (
        <>
        <div className="d-flex">
        <span title="Progress of learning material" className="mr-2"><i className="fal fa-books as-text-blue mr-1"></i>{row?.progress?.learning_material_percentage}%</span>
        <span title="Progress of assessments"><i className="fal fa-ballot-check as-text-blue mr-1"></i>{row?.progress?.assessment_percentage}%</span>
        </div>
        </>
      ),
    },
    {
      name: "Email",
      selector: "studentEmail",
      // sortable: true,
      // sortField: "lecturer",
      cell: (row) => (
        <>
          <div className="d-flex">
            <span title="Email" className="mr-2"><i className="fal fa-envelope as-text-blue"></i></span>
            <span title="Email" className="mr-2">{row?.studentEmail}</span>
        </div>
        </>
      ),
    },
    {
      name: "Attachments",
      selector: "files",
      // sortable: true,
      cell: (row) => row?.files ? <Link
          title="View Attachment"
          className="as-text-blue curser"
          to={``}
          // to={{pathname: `/courseAdministration/assessment/quizzes/open/submissionList/${row?.quiz_id}/${row?.quiz_student_id}?intake_id=${id}&assess_id=${subId}`,state: { id: id,subId: subId }}}
        >
          View Attachment
        </Link> : "-"
    },
    {
      name: "Actions",
      selector: "Action",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
                <button
                    className="btn btn-primary rounded-circle"
                    data-target="#createNote"
                    data-toggle="modal"
                    title="Create Note"
                    onClick={()=>{
                      setNotesRow({});
                      setRelatedCourse("");
                      setStartDate(new Date());
                      setStudentID(row?.stuid)
                      formik.setFieldValue("type","");
                      getCourseNotes(row?.stuid)
                      setFileName("");
                      setFilePreview("");
                      formik.setFieldValue("file","");
                    }}
                  // onClick={() => window.open(`https://aiesuperadmin.devtechnosys.tech/addticket`)}
                  >
                   <i className="fal fa-comment"></i>
                  </button>
            <div className="dropdown btn-dropdown-item">
              <button
                className="btn btn-primary rounded-circle dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="More"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <Link
                  className="btn btn-primary rounded-circle"
                  to={`/studentAdministration/students/open/${row.stuid}/general`}
                  title="Open"
                >
                  <i className="fal fa-folder-open"></i>
                </Link>
                <Link
                  to={`/courseAdministration/coursesdetails/${row.intakeID}/dashboard/show`}
                  className="btn btn-primary rounded-circle"
                  title="Open Course"
                >
                  <i className="fal fa-chalkboard"></i>
                </Link>
                <Link
                  className="btn btn-primary rounded-circle"
                  title="Create Ticket"
                  to={`/addticket`}
                >
                  <i className="fal fa-ticket-alt"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ], []);

  const exportData = (fileType, fileName) => {
    let data;
    const header = [
      "Submitted By",
      "Status",
      "Activity",
      "Related To",
      "Date",
      "Details",
      "Email"
    ];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    GetMentoredStudentsList({
      viaLecturer: [],
      key: sortkey,
      sort: sortOrder,
      viaCourse: courseIds.length > 0 ? courseIds : [],
      viaProgramme: [],
      viaSubjectStatus: [],
      viaMentoringStatus: [],
      viaStatus: status.arr.length > 0 ? status.arr : [],
      mtsStatus: subjectStatus,
      exportStatus: "true",
      activity: activity.arr.length ? activity.arr : []  
    }).then((res) => {
      data = res.data.result;
      data = data.map((row) => ({
        "Submitted By": row?.studentFname ? row["studentFname"] + " " + row["studentLname"] : "-",
        "Status":  row?.studentSatus ? RenderProgrammeStudentStatus(row["studentSatus"]).text : "-",
        "Activity": row?.activity ? RenderActivityStatus(row?.activity).text : "-",
        "Related To": row?.courseName ? row["courseName"] : "-",
        "Date": row?.note_date ? `${moment(row.note_date).format(TABLE_DATE_FORMAT)}` : "-",
        "Details": row?.note_content ? row?.note_content : "-",
        "Email": row?.studentEmail ? row?.studentEmail : "-"
      }));

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
       
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        setTimeout(() => {
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.forEach((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [
            row["Submitted By"],
            row["Status"],
            row["Activity"],
            row["Related To"],
            row["Date"],
            row["Details"],
            row["Email"]
          ];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: notesRow?.type ? notesRow?.type : "",
      details: notesRow?.details ? notesRow?.details : "",
      file: notesRow?.file ? notesRow?.file : "",
      note_front_show : notesRow?.note_front_show== "1" ? true : false
    },

    validationSchema: Yup.object({
      details: Yup.string().required("Details is required").trim(),
      type: Yup.string().required("Course is required"),
      // file: Yup.string().required("Attachment is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      setDisabled(true)
      const formInputs = new FormData();
      formInputs.append("id_student", studentID);
      formInputs.append("id_intake", values.type);
      formInputs.append("note_date", moment().format("YYYY-MM-DD HH:MM:SS"));
      formInputs.append("note_content", values.details);
      formInputs.append("note_front_show", values.note_front_show ===true ? 1 : 0);
      formInputs.append("photo", values.file);
      if(notesRow?.note_id){
        formInputs.append("id", notesRow?.note_id);
      }

      AddOrUpdateStudentNote(formInputs)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: notesRow?.note_id ? "Updated successfully" : "Added successfully",
          });
          $("#createNote").modal("hide");
          setReload(!reload)
          setRelatedCourse("")
          formik.resetForm();
          setFileName("");
          setFilePreview("");
          setDisabled(false)
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.data}`,
          });
          setDisabled(false)
        });
    },
  });

  const myNoteCompare = (rowA, rowB) => {
    const name1 = rowA.label && rowA.label.toString();
    const name2 = rowB.label && rowB.label.toString();
    return name1.localeCompare(name2);
  };

  const getCourseNotes = (id) => {
    GetNoteSubjectList(id)
    .then((res) => {
      let subject = res?.data?.subject_list || [];
      subject = subject.filter((data)=>{
        return data.label!==null && data.label!=='' && data.value !==null && data.value !=='' 
      })
      setRelatedCourses(subject.sort(myNoteCompare));      
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const handleAttachment=()=>{
    setFileName("");
    setFilePreview("");
    formik.setFieldValue("file","")
  }

  return (
    <>
         <div
        className={`custom-table-div notes-pending-table filter-search-icon card-table-custom new-table-bo`}
      >
          <div className="search-filter-div">
            <div className="search-filter-div-left">

              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                      <div
                        id="assessment-table-main_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={handleSearchFilter}
                            value={debouncedTerm}
                          />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="filter-scroll">
                    <div className={`filter-scroll-inner  filter-custom-new`}>
                        {/* filter on Type start */}
                            <Tablefilter
                            filterName="Status"
                            optionArr={virtualDropdown.status}
                            state={status}
                            setState={setStatus}
                            renderLabelFunction={RenderProgrammeStudentStatus}
                          />
                        {/* Course filter */}
                        <div className="assessment-table-filter">
                          <div className="btn-type-filter dropdown-comman assesment-dropdown">
                            <div className="dropdown multiselect">
                              <button
                                className={`btn btn-default dropdown-toggle ${
                                  value?.length ? "btn-selected" : ""
                                } `}
                                type="button"
                                id="dropdownMenu112"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                                title="Course"
                              >
                                <span>
                                  Course <i className="fal fa-angle-down"></i>
                                </span>
                              </button>
                              <form className="dropdown-menu remove-list-style">
                                <ul aria-labelledby="dropdownMenu112">
                                  <div className="dropdown-filter-pagination">
                                    {virtualDropdown.course && virtualDropdown.course.length ? (
                                      <FilterComponent
                                        value={value}
                                        onChange={onChange}
                                        dataResponse={virtualDropdown.course}
                                        backendResponse={setCourseIds}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </ul>
                              </form>
                            </div>
                          </div>
                        </div>
                        {/* End of Course filter */}
                        <Tablefilter
                            filterName="Activity"
                            optionArr={virtualDropdown.activity}
                            state={activity}
                            setState={setActivity}
                            renderLabelFunction={RenderActivityStatus}
                          />
                    </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>

                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "Notes Pending Response");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "Notes Pending Response");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "Notes Pending Response");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>

        <DataTable
          paginationDefaultPage={page}
          data={dashboardList}
          columns={columns}
          pagination={true}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          defaultSortAsc={false}
          defaultSortField="note_date"
          onSort={handleSort}
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          sortServer
        />
      </div>

      {/* create notes popup starts */}

      <div className="topic-add-modal modal fade" id="createNote" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                  <i className="fal fa-plus"></i> Add Note
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
                <div className="t p-30px">
                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-md-6 col-lg-12">
                        <div className="form-group-blk mb-3" title="Date">
                          <label htmlFor="StudentNumber">Date</label>
                          <DatePicker
                            disabled
                            selected={startDate}
                            dateFormat={FORM_DATE_TIME_FORMAT}
                            timeFormat={FORM_TIME_FORMAT}
                            onChange={(date) => {
                              setStartDate(date);
                            }}
                            className="form-control w-100"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-12">
                        <div className="form-group-blk mb-3" title="Related Course">
                          <label>Related Course *</label>
                          <Select
                            className={"form-control custom-select-box" + (formik.errors.type && formik.touched.type ? " is-invalid" : "")}
                            name="Related Course"
                            value={relatedCourses?.filter(val=>val.value === formik.values.type)}
                            onChange={(value) => {
                              if (value) {
                                setRelatedCourse(value.label);
                                formik.setFieldValue("type", value.value);
                              } else {
                                setRelatedCourse("");
                                formik.setFieldValue("type", "");
                              }                              
                            }}
                            onBlur={formik.handleBlur}
                            options={relatedCourses}
                            maxMenuHeight={175}
                            placeholder={"Select Related Course"}
                            isClearable
                          />

                        </div>
                      </div>
                      <div className="col-md-6 col-lg-12">
                        <div className="form-group-blk mb-3">
                          <label>Attachment </label>
                          <div className="form-group atttach-file m-0">
                            <label className={formik.errors.file && formik.touched.file && "file-req is-invalid"}>
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                className="form-control  form-control-aatch-file"
                                onChange={(e) => {
                                  if (e.target.files.length) {
                                    setFilePreview(e.target.files[0].name);
                                    formik.setFieldValue("file", e.target.files[0]);
                                    setFileName(URL.createObjectURL(e.target.files[0]));
                                  }
                                }}
                              />
                            </label>
                          </div>
                          {fileName && (
                            <>
                              <div className="frm-group">
                                <ul className="list-unstyled attached-file-ul m-0">
                                  <li>
                                    <a
                                      title={filePreview}
                                      href={
                                        fileName.includes("blob")
                                          ? fileName
                                          : `${IMAGE_URL}/${fileName}`.replace(
                                              "/public",
                                              ""
                                            ).replace("home/myaie_html/","")
                                      }
                                      target="_blank"
                                    >
                                      <i className="fal fa-file"></i>
                                      &nbsp;
                                      {filePreview}
                                    </a>
                                    <button
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      onClick={() => handleAttachment()}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-12">
                        <div className="form-icon-group">
                          <div className="custom-control custom-checkbox text-left">
                            <input
                              style={{cursor:'pointer'}}
                              type="checkbox"
                              className="custom-control-input"
                              id="note_front_show"
                              name="note_front_show"
                              checked={formik.values.note_front_show}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="note_front_show"
                            >
                              Visible to Student
                            </label>
                          </div>
                        </div>                        
                      </div>
                      <div className="col-md-6 col-lg-12">
                        <div className="form-group-blk mb-3">
                          <label>Details *</label>
                          <textarea
                              cols={10}
                              type="text"
                              id="details"
                              className={"form-control" + (formik.errors.details && formik.touched.details ? " is-invalid" : "")}
                              name="details"
                              placeholder="Enter Details"
                              value={formik.values.details}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              title="Details"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel mt-3">
                    <div className="form-group-save-cancel">
                      <>
                        <button type="submit" title="Save" className="btn btn-save btn-success" disabled={disabled}>
                          {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                          Save
                        </button>
                        <button type="button" title="Cancel" className="btn btn-close btn-danger" data-dismiss="modal"
                          onClick={() => {
                            setRelatedCourse("");
                            formik.resetForm();
                            setFileName("");
                            setFilePreview("");
                          }
                          }>
                          <i className="fal fa-times"></i>
                          Cancel
                        </button>
                      </>
                      {formik.touched.type && formik.errors.type ? <div className="invalid-feedback d-block">{formik.errors.type}</div> : null}
                      {formik.touched.file && formik.errors.file ? <div className="invalid-feedback d-block">{formik.errors.file}</div> : null}
                      {formik.touched.details && formik.errors.details ? <div className="invalid-feedback d-block">{formik.errors.details}</div> : null}
                    </div>
                  </div>
                </div>
              </div>
               
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotesPendingResponseSLP