const initialState = {
    // givenPermission: ['umadd', 'pmview', 'pmadd', 'pmedit']
    givenPermission: {}
};

const givenPermissionReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'SET_GIVEN_PERMISSION':
            // return state.givenPermission.concat(action.payload)
        return {...state.givenPermission,...action.payload}
        case 'UNSET_GIVEN_PERMISSION':
            return state.givenPermission = {}
        default:
            return state;
    }

}

export default givenPermissionReducer;