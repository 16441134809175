import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  GetClassAttendanceCount,
} from "../../services/DashBoardServices";
import PastOnlineClasses from "./PastOnlineClasses";
import UpcomingOnlineClasses from "./UpcomingOnlineClasses";
import TodayOnlineClasses from "./TodayOnlineClasses";

const ListofDashBoard = () => {

  const history = useHistory();

  const { type: urlType, tab } = useParams();
  const [date, setDate] = useState([
    urlType.charAt(0).toUpperCase() + urlType.slice(1)
  ]);
  const [countStatusData, setCountStatusData] = useState([]);

  useEffect(() => {
    if (tab == "virtualClass") {
      var check = setInterval(() => {
        GetClassAttendanceCount().then((res) => {
          let demo = res.data;
          setCountStatusData(demo ? demo : []);
        }).catch(err => { console.log(err); })
      }, 10000);
    }
    return () => clearInterval(check);
  }, [])

  return (
    <>
      {/* tabs start */}
      <div className="tabs-wrap">
        <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
          <li
            className="nav-item"
            onClick={() => {
              setDate(["Today"]);
              history.push("/home/virtualClass/today");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${urlType === "today" ? "active" : ""
                }`}
              id="pills-tab2"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab12"
              aria-selected="true"
            >
              <i className="fal fa-calendar"></i>Today
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setDate(["Upcoming"]);
              history.push("/home/virtualClass/Upcoming");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${urlType === "Upcoming" ? "active" : ""
                }`}
              id="pills-tab3"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab13"
              aria-selected="true"
            >
              <i className="fa fa-arrow-circle-right"></i>Upcoming
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              setDate(["Past"]);
              history.push("/home/virtualClass/Past");
            }}
          >
            <a
              className={`nav-link cursor-pointer ${urlType === "Past" ? "active" : ""
                }`}
              id="pills-tab1"
              data-toggle="pill"
              role="tab"
              aria-controls="pills-tab11"
              aria-selected="true"
            >
              <i className="fal fa-history"></i>
              Past
            </a>
          </li>
        </ul>
      </div>
      {/* tabs end */}
      {urlType === "today" ? <TodayOnlineClasses /> : urlType === "Upcoming" ? <UpcomingOnlineClasses /> : urlType === "Past" ? <PastOnlineClasses /> : 
      "sldkdfj"}
    </>
  );
};

export default ListofDashBoard;
