import React, { useState, useEffect } from 'react';
import { ScheduleFilterHeader, StyledRoomFilters } from './Custom';
import { GetCampusRoomsListCancelToken } from '../../../services/CampusMgtService';
import { GetConflictSchedules } from '../../../services/ScheduleManagementService';
import moment from 'moment';
import Select from 'react-select';

export const RoomFilters = ({
  campusId__ = 0,
  campusName_ = '',
  onRoomChange = () => {},
  calendarEvent,
  activeRoomId,
  selectedRoom__
}) => {

  const [campusRooms_, setCampusRooms_] = useState([]);
  const [campusRoomList, setCampusRoomList] = useState([]);
  const [isCampusRoomsLoaded, setIsCampusRoomLoaded] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [selectedRoom_, setSelectedRoom_] = useState(selectedRoom__);
  // const [activeRoomId, setActiveRoomId] = useState(-1);
  const [filterCampusRoom, setFilterCampusRoom] = useState([]);

  useEffect(() => {
    if (selectedRoom__ !== null) {
      setSelectedRoom_(selectedRoom__);
    }
    else {
      setSelectedRoom_(null)
    }

  }, [selectedRoom__]);



  useEffect(() => {
    const getRoomsByCampusId = async () => {
      try {
        if (campusId__) {
          const campusRoomsResponse = await GetCampusRoomsListCancelToken(
            campusId__
          );
          const rooms = campusRoomsResponse.data.data_list.map((room) => ({
            value: room.room_name,
            label: room.room_name,
            id: room.id,
          }));
          setFilterCampusRoom(rooms);

          const campusRoomConflict = await GetConflictSchedules(calendarEvent);


          const { nextScheduledClasses, availableSlotsFromClasses } =
          campusRoomConflict.data;

          const filteredScheduledClasses = nextScheduledClasses?.filter(
            (classItem) => classItem.campus_id === Number(campusId__)
          );

          const filteredAvailableSlots = availableSlotsFromClasses?.filter(
            (slot) => slot.campus_id === Number(campusId__)
          );

          const availableSlotsMap = filteredAvailableSlots?.reduce((acc, slot) => {
            if (!acc[slot.room_id]) {
              acc[slot.room_id] = [];
            }
            acc[slot.room_id].push(slot);
            return acc;
          }, {});

          let mergedData = [];

          if (filteredScheduledClasses.length > 0) {
            mergedData = filteredScheduledClasses.map((scheduledClass) => {
              const nextAvailableSlot =
                availableSlotsMap[scheduledClass.room_id] || [];

              return {
                ...scheduledClass,
                nextAvailableSlot: nextAvailableSlot[0],
              };
            });
          } else if (filteredAvailableSlots.length > 0) {
            mergedData = Object.keys(availableSlotsMap).map((roomId) => {
              const nextAvailableSlot = availableSlotsMap[roomId][0];
              return {
                room_id: roomId,
                room_name: nextAvailableSlot.room_name,
                room_no: nextAvailableSlot.room_no,
                campus_id: nextAvailableSlot.campus_id,
                campus_name: nextAvailableSlot.campus_name,
                nextAvailableSlot: nextAvailableSlot,
                noScheduledClasses: true,
              };
            });
          }

          setCampusRooms_(mergedData);
          setCampusRoomList(mergedData);
          setIsCampusRoomLoaded(true);
        }
      } catch (error) {
        console.log(error);
      }
          
    };
    getRoomsByCampusId();
  }, [campusId__, calendarEvent]);

  const handleRoomFilters = (roomId, roomName) => {
    // setActiveRoomId(roomId);
    onRoomChange(roomId, roomName);
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const campusRoomsToDisplay = showMore
    ? campusRooms_
    : campusRooms_.slice(0, 4);

  const handleSelectedRoom = (selectedRoom) => {
    if (selectedRoom) {
      onRoomChange(selectedRoom.id, selectedRoom.value);
    } else {
      onRoomChange(0, '');
    }

    setSelectedRoom_(selectedRoom);
  };



  return (
    <>
      <ScheduleFilterHeader headerName={campusName_} />
      <div className="card-main">
        {isCampusRoomsLoaded &&
          campusRoomsToDisplay.map((room) => {
            const isActive = room.room_id === activeRoomId;
            return (
              <>
                <div className="card-top ml-3 mb-4" key={room.room_id}>
                  <div className="card-header1" id="headingOne">
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={() =>
                        handleRoomFilters(room.room_id, room.room_name)
                      }
                    >
                      <>
                        <div className={`schedule-title-border-box ${isActive ? 'active' : ''}`}>
                          <StyledRoomFilters
                            room_header={room.room_name ?? 'N/A'}
                            availability={
                              room.nextAvailableSlot.status ?? 'N/A'
                            }
                            nextAvailableSlotStartTime={
                              room.nextAvailableSlot
                                ? moment(room.nextAvailableSlot.start, 'YYYY-MM-DD hh:mm a').format('HH:mm')
                                : 'N/A'
                            }
                            nextAvailableSlotEndTime={
                              room.nextAvailableSlot
                                ? moment(room.nextAvailableSlot.end, 'YYYY-MM-DD hh:mm a').format('HH:mm')
                                : 'N/A'
                            }
                            nextScheduledClassStartTime={
                              room.noScheduledClasses
                                ? 'N/A'
                                 : moment(room.class_start, 'hh:mm a').format('HH:mm') ?? 'N/A'
                            }
                            nextScheduledClassEndTime={moment(room.class_end, 'hh:mm a').format('HH:mm')?? 'N/A'}
                            scheduledClassDate={
                              room.noScheduledClasses
                                ? room.nextAvailableSlot.start
                                : room.class_date ?? 'N/A'
                            }
                            availableSlotDate={
                              room.noScheduledClasses
                                ? room.nextAvailableSlot.start
                                : room.class_date ?? 'N/A'
                            }
                          />
                        </div>
                        <div className="header-status"></div>
                      </>
                    </button>
                  </div>
                </div>
              </>
            );
          })}
      </div>
      {isCampusRoomsLoaded && campusRoomsToDisplay.length > 4 && (
        <div>
          <button
            className="btn btn-link"
            type="button"
            onClick={handleShowMore}
          >
            {showMore ? 'See Less' : 'See More'}{' '}
            <i class="fal fa-chevron-down rotate-icon"></i>
          </button>
        </div>
      )}

      <div>
        <div className="schedule-settings-header">
          <i className="fal fa-cog mr-2"></i>
          Settings
        </div>
        <div className="form-icon-group">
          <Select
            className={'form-control custom-select-box m-3'}
            options={filterCampusRoom}
            value={selectedRoom_}
            name="Room"
            onChange={handleSelectedRoom}
            isClearable
            maxMenuHeight={175}
            placeholder="Select Room"
          />
        </div>
      </div>
    </>
  );
};
