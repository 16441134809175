import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { FORM_DATE_FORMAT } from "../../../utils/Constants";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import Select from "react-select";
import {
    registrationStudentSearchList,
    issuedResource,
    getResourceDetails,
    registrationStudentSearchListCancelToken,
    getResourceDetailsCancelToken,
} from "../../../services/StudentService";
import { useParams } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import { GetCommonContent, GetCommonContentCancelToken } from "../../../services/ProgrammeServices";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import axios from "axios";

const FoundResource = ({ setResourceName }) => {

    const history = useHistory();
    const { id } = useParams()

    const [language, setLanguage] = useState([]);
    const [issueTo, setIssueTo] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [basicInfo, setBasicInfo] = useState({})
    const [infoData, setInfoData] = useState({})

    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
        let response = hasPermission({
            scopes: ["sresourcemedit"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, []);

    useEffect(() => {
        const dropdownSource = axios.CancelToken.source();
        
        const fetchData = async () => {
            try {
            const data = await GetCommonContentCancelToken(dropdownSource.token);
            setLanguage(data?.data?.language)
            } catch (error) {
            if (!axios.isCancel(error)) {
                console.error(error);
            }
            }
        };
        
        fetchData();
        
        return () => {
            dropdownSource.cancel('Component unmounted');
        };
    }, []);

    useEffect(() => {
        const dropdownSource = axios.CancelToken.source();
        
        const fetchData = async () => {
            try {
            const resp = await registrationStudentSearchListCancelToken({type:"dropdown"},dropdownSource.token);
            let respData = resp?.data?.data
            setIssueTo([...respData])
            } catch (error) {
            if (!axios.isCancel(error)) {
                console.error(error);
            }
            }
        };
        
        fetchData();
        
        return () => {
            dropdownSource.cancel('Component unmounted');
        };
    }, []);

    useEffect(() => {
        const cancelTokenSources = [];
    
        const getResourceData = async () => {
      
          cancelTokenSources.forEach(source => {
            source.cancel('New request made');
          });
      
          const source = axios.CancelToken.source();
          cancelTokenSources.push(source);
      
          try {
            const res = await getResourceDetailsCancelToken(id, source.token);
            setBasicInfo(res.data?.resourceDetails)
            setResourceName(res.data?.resourceDetails?.title)
            if (res.data?.ResourceInformationDetails) {
                setInfoData(res.data?.ResourceInformationDetails)

            }
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error(error);
            }
          }
        };
      
        getResourceData();
    
        return () => {
          cancelTokenSources.forEach(source => {
            source.cancel('Component unmounted');
          });
        };
    }, []);

    // useEffect(() => {

    //     GetCommonContent().then((data) => {
    //         setLanguage(data?.data?.language)
    //     });

    //     registrationStudentSearchList({type:"dropdown"}).then((resp) => {
    //         let respData = resp?.data?.data
    //         // let tempIssueTo = [];
    //         // issueTo.map((issue) => {
    //         //     tempIssueTo.push({ value: issue.id, label: issue.first_name + " " + issue.last_name })
    //         // })
    //         setIssueTo([...respData])
    //     });

    //     getResourceDetails(id)
    //         .then((res) => {
    //             setBasicInfo(res.data?.resourceDetails)
    //             setResourceName(res.data?.resourceDetails?.title)
    //             if (res.data?.ResourceInformationDetails) {
    //                 setInfoData(res.data?.ResourceInformationDetails)

    //             }
    //         }).catch((err) => { console.log(err); });
    // }, [])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: basicInfo?.title || "",
            found_date: "",
            language: basicInfo?.language || "",
            found_by: infoData?.misplaced_by || "",
            comments: "",
            founderName:infoData?.markedByname || ""
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            found_by: Yup.string().required("Found By is required"),
            found_date: Yup.string().required("Found Date is required"),
            comments: Yup.string().required("Comments is required"),
            language: Yup.string().required("Language is required"),
        }),
        onSubmit: (values) => {
            setIsDisabled(true)
            const body = {
                id: infoData.id,
                resource_id: id,
                status: "found",
                language: values.language,
                found_by: values.found_by,
                comment: values.comments,
                found_date: new Date(values.found_date).getTime().toString() / 1000,
            }
            issuedResource(body)
                .then((res) => {
                    setIsDisabled(false)
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated successfully",
                        onClose: goToListing
                    });
                })
                .catch((err) => {
                    setIsDisabled(false)
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: `${err?.response?.data?.data}`,
                    });
                });
        },
    });

    const goToListing = () => {
        history.push(`/support/student-hub-library/resource-management/table`)
    }

    return (
        <div className="card-new card card-profile-info-card">
            <div className="card-header">Mark as Found</div>
            <div className="card-body-inr card-body-info">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Title*</label>
                                <div className="form-icon-group mb-4">
                                    <input
                                        type="text"
                                        className={"form-control" + (formik.errors.title && formik.touched.title ? " is-invalid" : "")}
                                        name="title"
                                        placeholder="Title"
                                        title="Title"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        readOnly
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group" >
                                <label>Found By*</label>
                                <div className="form-icon-group mb-4">
                                    <div className="form-control custom-select-box">
                                        <Select
                                            className={"form-control custom-select-box" + (formik.errors.found_by && formik.touched.found_by ? " is-invalid" : "")}
                                            name="found_by"
                                            value={issueTo?.filter(val => val.value === formik.values.found_by)}
                                            onChange={(value) => {
                                                if (value) {
                                                    formik.setFieldValue("found_by", value.value);
                                                    formik.setFieldValue("founderName", value.label);
                                                } else {
                                                    formik.setFieldValue("found_by", "");
                                                    formik.setFieldValue("founderName", "");
                                                }
                                            }}
                                            placeholder={formik.values.founderName ? formik.values.founderName : "Select..."}
                                            onBlur={formik.handleBlur}
                                            options={issueTo}
                                            maxMenuHeight={175}
                                            isClearable
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label>Date Found*</label>
                                <DatePicker
                                    autoComplete="off"
                                    selected={formik.values.found_date}
                                    onChange={(date) => {
                                        formik.setFieldValue("found_date", date);
                                    }}
                                    name="date"
                                    dateFormat={FORM_DATE_FORMAT}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormatCalendar="MMMM"
                                    className={
                                        "form-control cursor-pointer" +
                                        (formik.errors.found_date &&
                                            formik.touched.found_date
                                            ? " is-invalid"
                                            : "")
                                    }
                                    title="Date Found"
                                    placeholderText="Date Found"
                                    timeIntervals={15}
                                    onChangeRaw={(e) => {
                                        e.preventDefault();
                                    }}
                                    minDate={new Date()}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label>Comments*</label>
                                <div className="form-icon-group mb-4">
                                    <input
                                        type="text"
                                        className={"form-control" + (formik.errors.comments && formik.touched.comments ? " is-invalid" : "")}
                                        name="comments"
                                        placeholder="Comments"
                                        title="Comments"
                                        value={formik.values.comments}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group" >
                                <label>Language*</label>
                                <div className="form-icon-group mb-4">
                                    <div className="form-control custom-select-box">
                                        <Select
                                            className={"form-control custom-select-box" + (formik.errors.language && formik.touched.language ? " is-invalid" : "")}
                                            name="language"
                                            value={language?.filter(val => val.value === formik.values.language)}
                                            onChange={(value) => {
                                                if (value) {
                                                    formik.setFieldValue("language", value.value);
                                                } else {
                                                    formik.setFieldValue("language", "");
                                                }
                                            }}
                                            placeholder={formik.values.language ? formik.values.language : "Select..."}
                                            onBlur={formik.handleBlur}
                                            options={language}
                                            maxMenuHeight={175}
                                            isClearable
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group-save-cancel ">
                        <PermissionsGate errorProps={{ disabled: true }} scopes={["shedit"]}>
                            <button className="btn btn-save btn-success" type="button" onClick={formik.handleSubmit} title="Save" >
                                {isDisabled === false ? <i className="fal fa-save"></i> : <i class="fas fa-cog fa-spin"></i>}
                                Save
                            </button>
                        </PermissionsGate>
                        <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()} >
                            <i className="fal fa-times"></i>
                            Cancel
                        </button>
                    </div>
                    <div className="form-group form-group-save-cancel">
                        {Object.keys(formik.errors).map((key) =>
                            formik.touched[key] && formik.errors[key] ? (
                                <div className="invalid-feedback d-block">
                                    {formik.errors[key]}
                                </div>
                            ) : null
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default FoundResource;
