import React, { useState } from 'react';

function CopyToClipboard({ data }) {
  const [show, setShow] = useState(false);
  const handleCopy = () => {
    const range = document.createRange();
    const container = document.createElement('div');
    container.innerHTML = data;
    document.body.appendChild(container); // Append the container to the body

    range.selectNode(container);
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(range);

    try {
      document.execCommand('copy');
      setShow(true);
    } catch (error) {
      console.error('Copy failed: ', error);
    }

    document.getSelection().removeAllRanges();
    document.body.removeChild(container); // Remove the container from the body

    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  return show ? (
    <i className="fas fa-check-circle cat-greenhex ml-1"></i>
  ) : (
    <i className="fal fa-copy hover-color-blue ml-1" onClick={handleCopy}></i>
  );
}

export default CopyToClipboard;