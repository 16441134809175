import React from 'react'
import { useParams } from 'react-router-dom'
import InProgressCourses from './InProgressCourses';
import SelfPlacedCourses from './SelfPlacedCourses';
import UpcomingCourses from './UpcomingCourses';
import AllCourses from './AllCourses';
import PastCourses from './PastCourses';

function ListOfCourses() {
  const { tab } = useParams();

  return (
     tab === "In Progress" ? <InProgressCourses />
      : tab === "selfplaced" ? <SelfPlacedCourses />
        : tab === "upcoming" ? <UpcomingCourses />
          : tab === "allCourses" ? <AllCourses />
          : <PastCourses />
  )
}

export default ListOfCourses