import React, { useState } from 'react'
import PageComponent from '../../../common/PageComponent'
import QuestionForm from './QuestionForm'

function AddQuestion() {
  const [questionType, setQuestionType] = useState("Multiple Choice Single Answer")
  return (
    <PageComponent
          heading={["Create", "Questions"]}
          isDetail = {false}
            // heading={tab === "summary" ? ["Attendance Summary", classTitle] : ["Class Attendance", classTitle]}
            isBackButtonShown
            isAuditTrailButtonShown={false}
            isTabShown={false}
            disableColonShown
            componentToRender={[
                {
                    condition: true,
                    component: <QuestionForm setQuestionType={setQuestionType}/>
                },
            ]}
            tabs={[]}
            extraHeading={questionType}
        />
  )
}

export default AddQuestion