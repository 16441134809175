import axiosInstance from "../utils/axios";

export const GetListOfStudentHubTabList = async (cancelToken) => {
  return await axiosInstance().get(`/getStudentHubList`,{cancelToken});
};

export const GetListOfStudentHubBrandList = async () => {
  return await axiosInstance().get(`/getBrandSettingList`, {});
};

export const GetListOfStudentHubBrandListCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getBrandSettingList`, { cancelToken: cancelToken });
};

export const DeleteStudentTabList = async (values) => {
  return await axiosInstance().post(`/deleteStudentHub`, values);
};


export const GetStudentTabListDetail = async (values) => {
  return await axiosInstance().get(`/getStudentHubDetail?id=${values.id}`);
};

export const GetStudentHubTabDetail = async (param) => {
  return await axiosInstance().get(`/gettabdetail`, {params : param});
};

export const GetStudentHubTabDetailCancelToken = async (param,cancelToken) => {
  return await axiosInstance().get(`/gettabdetail`, {params : param, cancelToken: cancelToken});
};

export const GetCategoryDetail = async (values) => {
  return await axiosInstance().get(`/getCategoryDetail?categoryId=${values.categoryId}`);
};

export const AddStudentTabListData = async (values) => {
  return await axiosInstance().post(`/addStudentHub`, values);
};

export const AddStudentHubCategoryData = async (values) => {
  return await axiosInstance().post(`/addStudentHubCategories`, values);
};

export const EditStudentTabListData = async (values) => {
  return await axiosInstance().post(`/editStudentHub`, values);
};

export const EditStudentHubCategoryData = async (values) => {
  return await axiosInstance().post(`/editStudentHubCategories`, values);
};

export const EditResource = async (values) => {
  return await axiosInstance().post(`/editResource`, values);
};

export const GetListOfStudentHubCategories = async (values) => {
  return await axiosInstance().get(`/getStudentHubCategories?tab_id=${values.tab_id}`);
};

export const GetListOfResources = async (values) => {
  return await axiosInstance().post(`/getResourceList`, values);
};

export const AddResources = async (values) => {
  return await axiosInstance().post(`/addResource`, values);
};

export const GetListOfResourcesDetail = async (values) => {
  return await axiosInstance().post(`/getResourceDetail`, values);
};

export const DeleteCategoryList = async (values) => {
  return await axiosInstance().post(`/deleteStudentHubCategories`, values);
};

export const DeleteResourceList = async (values) => {
  return await axiosInstance().post(`/deleteResource`, values);
};

export const GetStudentAuditTrail = async (values) => {
  return await axiosInstance().post(`/getStudentHubAuditTrail`, values);
};

export const getResourceManagement = async (values, cancelToken) => {
  return await axiosInstance().post(`/getResourceManagement`, values, {cancelToken});
};

export const GetResourceManagementCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getResourceManagement`, values, {
    cancelToken: cancelToken
  });
};

export const addResourceManagement = async (values) => {
  return await axiosInstance().post(`/addResourceManagement`, values);
};

export const getResourceDetails = async (id) => {
  return await axiosInstance().get(`/getResourceDetails?resource_id=${id}`);
};

export const getResourceDetailsCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getResourceDetails?resource_id=${id}`, {cancelToken});
};

export const deleteResourceManagement = async (id) => {
  return await axiosInstance().get(`/deleteResourceManagement?id=${id}`);
};

export const registrationStudentSearchList = async (param) => {
  return await axiosInstance().get(`/registrationStudentSearchList`, {params:param});
};

export const registrationStudentSearchListCancelToken = async (param,cancelToken)=>{
  return await axiosInstance().get(`/registrationStudentSearchList`,{params:param,cancelToken});
}

export const getStudentLibraryAuditTrial = async (param) => {
  return await axiosInstance().get(`/getStudentLibraryAuditTrial`, {params:param});
};

export const getStudentLibraryAuditTrialCancelToken = async (param, cancelToken) => {
  return await axiosInstance().get(`/getStudentLibraryAuditTrial`, {params:param, cancelToken: cancelToken});
};

export const getLibraryBorrowersList = async (values, cancelToken) => {
  return await axiosInstance().post(`/getLibraryBorrowersList`, values,{cancelToken});
};

export const issuedResource = async (values) => {
  return await axiosInstance().post(`/issuedResource`, values);
};

export const getLibraryBorrowersDetails = async (values) => {
  return await axiosInstance().post(`/getLibraryBorrowersDetails`, values);
};

export const getLibraryBorrowersDetailsCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getLibraryBorrowersDetails`, values, {
    cancelToken: cancelToken
  });
};

export const getBrandsTabs = async () => {
  return await axiosInstance().get(`/getbrandsTabs`);
};

export const getBrandsTabsCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getbrandsTabs`, {cancelToken: cancelToken});
};

export const reArrangeStudentHub = async (values) => {
  return await axiosInstance().post(`/reArrangeStudentHub`, values);
};

export const reArrangeStudentHubCategories = async (values) => {
  return await axiosInstance().post(`/reArrangeStudentHubCategories`, values);
};

export const addResourceToSection = async (values) => {
  return await axiosInstance().post(`/addResourceToSection`, values);
};

export const unlinkResource = async (data) => {
  return await axiosInstance().post(`/unlinkRecourse`, data);
};

export const GetResourceManagementFilters = async (cancelToken) => {
  return await axiosInstance().get(`/getResourceManagementFilters`,{cancelToken});
};

export const GetStudentHubListFilters = async (cancelToken) => {
  return await axiosInstance().get(`/getStudentHubListFilters`,{cancelToken});
};

export const GetLibraryBorrowersListFilters = async (cancelToken) => {
  return await axiosInstance().get(`/getLibraryBorrowersListFilters`, {cancelToken});
};

export const updateStudentProgress = async (reasonDesc, sendEmail, data) => {
  return await axiosInstance().put(`/updateStudentProgressStatus`,
    { reasonDesc, sendEmail, data});
}

export const GetCampusDropdown = async () => {
  return await axiosInstance().get(`/getCampusDropdown`);
};

export const GetCampusDropdownCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getCampusDropdown`, {
    cancelToken: cancelToken
  });
};

export const GetLibraryBorrowersDetailsFilter = async (param) => {
  return await axiosInstance().get(`/getLibraryBorrowersDetailsFilter`, {params:param});
};

