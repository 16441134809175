import React from 'react';
import { ScheduleFilterHeader } from './Custom';


export const CampusFilters = ({
  campusList,
  onCampusChange,
  
}) => {
  

  const handleCampusFilters = (campusId_, campus_name) => {
    onCampusChange(campusId_, campus_name);
  };


  return (
    <>
      <ScheduleFilterHeader headerName={'Campus Selection'} />
      <div className="card-main">
        {campusList &&
          campusList.map((campus, index) => (
            <div className="card-top ml-3 mb-4" key={index}>
              <div className="card-header1" id="headingOne">
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() =>
                    handleCampusFilters(campus.value, campus.label)
                  }
                >
                  <div className="schedule-title-border-box">
                    {campus.label}
                  </div>
                  <div className="header-status"></div>
                </button>
              </div>
            </div>
          ))}
      </div>

    </>
  );
};
