import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import { GetListOfUserAuditTrail } from "../../../services/UserService";
import {
  IMAGE_URL,
  TABLE_DATE_FORMAT,
  TABLE_DATE_TIME_FORMAT,
  TABLE_ROWS_PER_PAGE,
  TABLE_TIME_FORMAT,
} from "../../../utils/Constants";
import Str from "../../common/Str";
import {
  ColorRender,
} from "./../../systemadministration/usermanagement/CheckRole";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Swal from "sweetalert2";
import moment from "moment";
import { InitialRender } from "../../common/Helper";
import Hover from "../../common/Hover";
import { GetStudentRegAuditTrail } from "../../../services/RegistrationService";
import { TrimText } from "../../common/TrimText";
import { Link, useParams } from "react-router-dom";
import { checkIfImageExists, handleTableScroll } from "../../../utils/commonFunction";
import { GetArticulationRegistrationsAuditTrailsCancelToken, getArticulationRegistrationsAuditTrails } from "../../../services/programArticulationService";
import generateArrayOfYears from "../../common/generateArrayOfYears";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { RenderAuditTrailActionType } from "../../../utils/CommonGroupingItem";
import axios from "axios";

const AuditTrails = () => {
  const { id } = useParams();

  const history = useHistory();
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState([]);
  const [actiontype, setActionType] = useState([]);
  const [searchActionTypeCheck, setSearchActionTypeCheck] = useState({});
  const [month, setMonth] = useState([]);
  const [searchMonthCheck, setSearchMonthCheck] = useState({});
  const [year, setYear] = useState([]);
  const [searchYearCheck, setSearchYearCheck] = useState({});
  const [loading, setloading] = useState(true);
  const [sortkey, setSortKey] = useState("date_time");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
      let response = hasPermission({scopes: ["cparview"],permissions: givenPermsisions});
      if (!response) {
        history.push("/noaccess");
      }
    }, [])

  const fixDataFormat = (arr) => {
    let tempArr = [];
    arr.map((item) => {
      let singleItem = {
        id: item.id,
        action_id: item.action_id,
        description: JSON.parse(item.description),
        resource_type: item.resource_type,
        action_type: item.action_type,
        resource_name: item?.resource_name
      };
      tempArr.push(singleItem);
    });
    setUserData(tempArr);
  };

  useEffect(() => {
        const cancelTokenSources = [];

        const getData = async () => {
            setloading(true);
        
            cancelTokenSources.forEach(source => {
              source.cancel('New request made');
            });
        
            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);
        
            const params = {
              exportStatus: "false",
              action_id: id,
              page: page,
              limit: perPage,
              key: sortkey,
              sort: sortOrder,
              search: search,
              viaYears: year,
              viaMonths: month,
              viaActionType: actiontype,
            }
        
            try {
              const res = await GetArticulationRegistrationsAuditTrailsCancelToken(params, source.token);
              fixDataFormat(
                res && res.data && res.data.audit_trail?.data ? res.data.audit_trail?.data : []
              );
              setTotalRows(res?.data?.audit_trail?.total);
              if (res.status === 200) {
                setloading(false);
              }
            } catch (error) {
              if (!axios.isCancel(error)) {
                console.error(error);
                setloading(false);
              }
            }
          };
        
          getData();
      
          return () => {
            cancelTokenSources.forEach(source => {
              source.cancel('Component unmounted');
            });
          };
  }, [id, page, perPage, sortkey, sortOrder, search, searchYearCheck, year, month, searchMonthCheck, actiontype, searchActionTypeCheck]);

  const sortingDate = (rowA, rowB) => {
    const date1 = new Date(rowA.description.timestamp);
    const date2 = new Date(rowB.description.timestamp);

    if (date2 > date1) {
      // console.log(`${date2} is greater than ${date1}`)
      return 1;
    } else if (date2 < date1) {
      // console.log(`${date1} is greater than ${date2}`)
      return -1;
    } else {
      // console.log(`Both dates are equal`)
      return 0;
    }
  };

  const sortingName = (rowA, rowB) => {
    const name1 = rowA.description.object.definition.New.Firstname.toString();
    const name2 = rowB.description.object.definition.New.Firstname.toString();
    return name1.localeCompare(name2);
  };

  const sortingUser = (rowA, rowB) => {
    const name1 = rowA.description.User.name.toString();
    const name2 = rowB.description.User.name.toString();
    return name1.localeCompare(name2);
  };

  const difference = (obj1, obj2) => {
    let keyFound = [];
    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        return keyFound.push(key + ":" + obj1[key]);
      }
    });
    return keyFound;
  };

  const columns = useMemo(() => [
    {
      name: "User",
      selector: "user",
      sortable: true,
      sortFunction: sortingUser,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className="assigned-title-blk name-icon">
              {row.description.User.URL &&
              checkIfImageExists(
                `${
                  !row.description.User.URL.includes("https://myaie.ac")
                    ? IMAGE_URL + "/"
                    : ""
                }${row.description.User.URL.replaceAll(
                  "/home/myaie/public_html/",
                  ""
                )
                  .replaceAll("/home/myaie/public_html/", "")
                  .replace("public/", "")
                  .replace("adminapi.myaie.ac17278/", "")
                  .replace("devadminapi.myaie.ac1788/", "")}`
              ) ? (
                <img
                  src={`${
                    !row.description.User.URL.includes("https://myaie.ac")
                      ? IMAGE_URL + "/"
                      : ""
                  }${row.description.User.URL.replaceAll(
                    "/home/myaie/public_html/",
                    ""
                  )
                    .replaceAll("/home/myaie/public_html/", "")
                    .replace("public/", "")
                    .replace("adminapi.myaie.ac17278/", "")
                    .replace("devadminapi.myaie.ac1788/", "")}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([
                  row.description.User.name,
                  row.description.User.name,
                ])
              )}

              <span
                className={`profile-box-2-status ${
                  row.description.User.status
                    ? row.description.User.status
                    : "Offline"
                }`}
              >
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.description.User.name.split(" ")[0]}
                lastName={row.description.User.name.split(" ")[1]}
                photo={`${row.description.User.URL.replace(
                  "https://myaie.ac",
                  ""
                )
                  .replace("/home/myaie/public_html/", "")
                  .replaceAll("/home/myaie/public_html/", "")
                  .replace("public/", "")
                  .replace("adminapi.myaie.ac17278/", "")
                  .replace("devadminapi.myaie.ac1788/", "")}`}
                email={row.description.User.mbox}
                mobile={row.description.User.Mobile}
                status={""}
                role={""}
                right={true}
              />
            </span>
            <p>
              <b>{row.description.User.name}</b>
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Action Type",
      // sortable: true,
      cell: (row) =>( RenderAuditTrailActionType(row?.action_type || row?.description?.verb?.Action).html)
      
    },

    {
      name: "Resource Type",
      // sortable: true,
      cell: (row) =>
        row.resource_type ? (
          <span className="feature-name" title={row.resource_type}>
            <span className="textLimit100"> {row.resource_type}</span>
          </span>
        ) : (
          ""
        ),
    },
    {
      name: "Resource Name",
      selector: "resource_name",
      sortable: true,
      cell: (row) => {
        // return row?.resource_name ?row?.resource_name : ""
        if (row.action_type === "Deleted") {
          return (
            <span
              className="feature-name"
              title={row.resource_name}
            >
              <span className="textLimit100">
                {row.resource_name}
              </span>
            </span>
          );
        } else {
          return row.resource_name ? (
            <Link
              className="as-text-blue curser feature-name"
              title={row.resource_name}
              to={`/courseAdministration/articulation/open-articulation-registration/open/${row?.action_id}?description=${row?.resource_name}`}
            >
              <span className="textLimit100">
                {" "}
                {row.resource_name}
              </span>
            </Link>
          ) : (
            "-"
          );
        }
      },
    },
    {
      name: "Old Value",
      // sortable: true,
      cell: (row) => {
        const entries = Object.entries(row.description.object.definition?.Old);

        if (row.description.verb.Action === "Created") {
          return "-";
        } else if (row.description.verb.Action === "Edited") {
          const diff = difference(
            row.description.object.definition?.Old,
            row.description.object.definition?.New
          );
          return diff.length !== 0 ? (
            <div
              className="tooltip-custom"
              title={`${diff.sort().map((entry) => entry.toString())}`
                .split(",")
                .join("\n")}
            >
              {/* <span className="tooltip-custom-box">{diff.sort().map(entry => <p>{entry.toString()}</p>)}</span> */}
              {diff.sort().map((entry, index) => {
                return (
                  index < 2 && (
                    <p key={index}>
                      {entry.toString().length > 30
                        ? entry.toString().slice(0, 30) + "..."
                        : entry}
                    </p>
                  )
                );
              })}
              {diff.length > 2 ? "..." : null}
            </div>
          ) : (
            "-"
          );
        } else {
          return entries.length ? (
            <div
              className="tooltip-custom"
              title={`${entries.map(
                (entry) => entry[0] + ":" + (entry[1] ? entry[1] : "-")
              )}`
                .split(",")
                .join("\n")}
            >
              {entries.map((entry, index) => {
                if (index < 2) {
                  return (
                    <p key={index} title={String(entry[1])}>
                      {entry[0]}:
                      {entry[1]
                        ? entry[1].toString().length > 30
                          ? entry[1].toString().slice(0, 30) + "..."
                          : entry[1]
                        : "-"}
                    </p>
                  );
                }
              })}
              {entries.length > 2 ? "..." : null}
            </div>
          ) : (
            "-"
          );
        }
      },
    },
    {
      name: "New Value",
      // sortable: true,
      cell: (row) => {
        const entries = Object.entries(row.description.object.definition?.New);
        if (row.description.verb.Action === "Edited") {
          const diff = difference(
            row.description.object.definition?.New,
            row.description.object.definition?.Old
          );
          return diff.length !== 0 ? (
            <div
              className="tooltip-custom"
              title={`${diff.sort().map((entry) => entry.toString())}`
                .split(",")
                .join("\n")}
            >
              {diff.sort().map((entry, index) => {
                return (
                  index < 2 && (
                    <p key={index}>
                      {entry.toString().length > 30
                        ? entry.toString().slice(0, 30) + "..."
                        : entry}
                    </p>
                  )
                );
              })}
              {diff.length > 2 ? "..." : null}
            </div>
          ) : (
            "-"
          );
        } else {
          return entries.length ? (
            <div
              className="tooltip-custom"
              title={`${entries.map(
                (entry) => entry[0] + ":" + (entry[1] ? entry[1] : "-")
              )}`
                .split(",")
                .join("\n")}
            >
              {entries.map((entry, index) => {
                return (
                  index < 2 && (
                    <p key={index}>
                      {entry[0]}:
                      {entry[1]
                        ? entry[1].toString().length > 30
                          ? entry[1].toString().slice(0, 30) + "..."
                          : entry[1]
                        : "-"}
                    </p>
                  )
                );
              })}
              {entries.length > 2 ? "..." : null}
            </div>
          ) : (
            "-"
          );
        }
      },
    },
    {
      name: "Date",
      sortable: true,
      selector: "Date",
      sortFunction: sortingDate,
      cell: (row) => {
        let today = new Date().toLocaleDateString();
        return row.description.timestamp ? (
          <>
            <p>
              {moment(row.description.timestamp).format(
                TABLE_DATE_FORMAT + ","
              )}
            </p>
            <p>{moment(row.description.timestamp).format(TABLE_TIME_FORMAT)}</p>
          </>
        ) : (
          "-"
        );
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              onClick={() => {
                Swal.fire({
                  html: `<div className="audit-trial-json"><pre>${JSON.stringify(
                    row.description,
                    undefined,
                    1
                  )}</pre></div>`,
                });
              }}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleActionTypeFilter = (e) => {
    const value = e.target.value;
    let arr = actiontype;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setActionType(arr);
    let id = e.target.id;
    setSearchActionTypeCheck({
      ...searchActionTypeCheck,
      [id]: !searchActionTypeCheck[id],
    });
  };

  const handleMonthFilter = (e) => {
    const value =
      Number(Number(e.target.value) + 1) <= 9
        ? "0" + Number(Number(e.target.value) + 1)
        : Number(Number(e.target.value) + 1);
    let arr = month;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setMonth(arr);
    let id = e.target.id;
    setSearchMonthCheck({ ...searchMonthCheck, [id]: !searchMonthCheck[id] });
  };

  const handleYearFilter = (e) => {
    const value = e.target.value;
    let arr = year;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setYear(arr);
    let id = e.target.id;
    setSearchYearCheck({ ...searchYearCheck, [id]: !searchYearCheck[id] });
  };

  const renderMonthUI = () => {
    let arr = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return arr.map((i, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            id={index}
            value={index}
            onClick={handleMonthFilter}
            checked={searchMonthCheck[index]}
          />
          <label htmlFor={index}>
            <span className="cat">{i}</span>
          </label>
        </li>
      );
    });
  };

  const renderYearUI = () => {
    let arr = generateArrayOfYears().splice(0, 3);
    return arr.map((i, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            id={i}
            value={i}
            onClick={handleYearFilter}
            checked={searchYearCheck[i]}
          />
          <label htmlFor={i}>
            <span className="cat">{i}</span>
          </label>
        </li>
      );
    });
  };


  const dataToRender = () => {
    let updatedData = [];
    let allData = userData;
    if (search.length) {
      let tempUser = allData.filter((item) => {
        let includes =
          item.description.User.name &&
          item.description.User.name
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempName = allData.filter((item) => {
        let includes =
          item.description.object.definition.New.Firstname &&
          item.description.object.definition.New.Firstname.toString().includes(
            search.toLowerCase()
          );
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempUser, ...tempName];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (actiontype.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        const startsWith = actiontype.find((post, index) => {
          if (post.toString() === item.description.verb.Action.toString())
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }
    if (month.length) {
      let tempMonth = updatedData;
      let tempResult = tempMonth.filter((item) => {
        const startsWith = month.find((post, index) => {
          let monthNumber = moment
            .unix(item.description.timestamp)
            .format("MMM");

          if (
            post.toString().toLowerCase() ===
            monthNumber.toString().toLowerCase()
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (year.length) {
      let tempYear = updatedData;
      let tempResult = tempYear.filter((item) => {
        const startsWith = year.find((post, index) => {
          let yearNumber = moment
            .unix(item.description.timestamp)
            .format("YYYY");
          if (post.toString() === yearNumber.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    return updatedData;
  };

  const resetFilter = () => {
    setSearch("");
    setActionType([]);
    setSearchActionTypeCheck({});
    setMonth([]);
    setSearchMonthCheck({});
    setYear([]);
    setSearchYearCheck({});
  };

  const oldValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.Old);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(
        row.description.object.definition?.Old,
        row.description.object.definition?.New
      );
      return diff.length !== 0 ? `${diff.sort()}` : "-";
    }
    // else if (row.description.verb.Action === "Profile Picture Edited") {
    //   return  row.description.object.definition?.Old?.picture_me;
    // }
    else {
      return `${entries.map(
        (entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "-"}`
      )}`;
    }
  };

  const NewValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.New);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(
        row.description.object.definition?.New,
        row.description.object.definition?.Old
      );
      return diff.length !== 0 ? `${diff.sort()}` : "-";
    } else if (row.description.verb.Action === "Profile Picture Edited") {
      return row.description.object.definition?.New?.picture_me.toString()
        .length > 30
        ? "picture_me:" + row.description.object.definition?.New?.picture_me
        : row.description.object.definition?.New?.picture_me;
    } else {
      return `${entries.map(
        (entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "-"}`
      )}`;
    }
  };

  const exportData = (fileType, fileName) => {
    let data = [];
    const params = {
      exportStatus: "true",
      action_id: id,
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      viaYears: year,
      viaMonths: month,
      viaActionType: actiontype,
    }
    const header = [
      "User",
      "Action Type",
      "Resource Type",
      "Resource Name",
      "Old Value",
      "New Value",
      "Date",
    ];
      getArticulationRegistrationsAuditTrails(params)
        .then((res) => {
          const parsedData = res?.data?.audit_trail?.map(item => ({ ...item, description: JSON.parse(item.description) }));
          data = parsedData?.map((row) => ({
            ...row,
            // ID: row?.id,
            // User: row?.authFullName || row?.description?.User?.name,
            // "Resource Name":
            //   row?.action_type && row?.resource_name ?
            //     handleResourceName(true, "", row?.resource_name)
            //     : row?.description?.verb?.Action === "Deleted"
            //       ? handleResourceName(false, row?.resource_type, row?.description?.object?.definition?.Old, "Old")
            //       : handleResourceName(false, row?.resource_type, row?.description?.object?.definition?.New, "New"),
            // // "Resource Name": row.description.object.definition.New.brand_name
            // //   ? row.description.object.definition.New.brand_name
            // //   : row.description.object.definition.Old.brand_name,
            // "Resource Type": row.resource_type,
            // "Action Type": row?.action_type || row?.description?.verb?.Action,
            // // "Old Value": Object.keys(row.description.object.definition.Old).length !== 0 ? `${Object.entries(row.description.object.definition.Old).map((entry) => entry[0] + ":" + entry[1])}` : "NA",
            // "Old Value":
            //   Object.keys(row.description.object.definition.Old).length !== 0
            //     ? oldValue(row)
            //     : "NA",
            // // "New Value": Object.keys(row.description.object.definition.New).length !== 0 ? `${Object.entries(row.description.object.definition.New).map((entry) => entry[0] + ":" + entry[1])}` : "NA",
            // "New Value":
            //   row.description &&
            //     Object.keys(row.description.object.definition.New).length !== 0
            //     ? NewValue(row)
            //     : "NA",
            // Date: new Date(row.description.timestamp).toLocaleDateString(),
            User: row?.authFullName || row?.description?.User?.name,
            "Action Type": row?.action_type || row?.description?.verb?.Action,
            "Resource Type": row.resource_type ? row.resource_type : "-",
            "Resource Name": row.description.object.definition.Old.description
              ? row.description.object.definition.Old.description
              : row.description.object.definition.New.description,
            // "Old Value": Object.keys(row.description.object.definition.Old).length !== 0 ? `${Object.entries(row.description.object.definition.Old).map((entry) => entry[0] + ":" + entry[1])}` : "-",
            "Old Value":
              Object.keys(row.description.object.definition.Old).length !== 0
                ? oldValue(row)
                : "-",
            // "New Value": Object.keys(row.description.object.definition.New).length !== 0 ? `${Object.entries(row.description.object.definition.New).map((entry) => entry[0] + ":" + entry[1])}` : "-",
            "New Value":
              Object.keys(row.description.object.definition.New).length !== 0
                ? NewValue(row)
                : "-",
            Date: moment(row.description.timestamp).format(TABLE_DATE_TIME_FORMAT),
          }))
          if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            // console.log(csvString);
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = [
              "text/csv",
              anchor.download,
              anchor.href,
            ].join(":");
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              URL.revokeObjectURL(blobURL);
            }, 1000);
            Swal.close();
          } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
              const obj = {};
              header.map((col, index) => {
                obj[col] = row[col];
              });
              return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
              header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);
            Swal.close();
            // Returning false as downloading of file is already taken care of
            return false;
          }
          if (fileType === "pdf") {
            const compatibleData = data.map((row) => {
              return [
                row["User"],
                row["Action Type"],
                row["Resource Type"],
                row["Resource Name"],
                row["Old Value"],
                row["New Value"],
                row["Date"],
              ];
            });
            const doc = new JsPDF();
            doc.autoTable({
              head: [header],
              body: compatibleData,
              columnStyles: {
                0: {
                  columnWidth: 20,
                },
                1: {
                  columnWidth: 24,
                },
                2: {
                  columnWidth: 24,
                },
                3: {
                  columnWidth: 24,
                },
                4: {
                  columnWidth: 40,
                },
                5: {
                  columnWidth: 40,
                },
                6: {
                  columnWidth: 20,
                },
              },
              styles: {
                minCellHeight: 10,
                minCellWidth: 5,
                halign: "left",
                fontSize: 8,
              },
            });
            doc.save(`${fileName}.pdf`);
            Swal.close();
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  // function firstLetterCap(name) {
  //     return name[0].toUpperCase() + name.substr(1);
  // }

  const handleSort = (column, sortDirection) => {
    setSortKey(column.selector);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${
                              actiontype.length ? "btn-selected" : ""
                            }`}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title="Action Type"
                          >
                            <span>
                              Action Type <i className="fal fa-angle-down"></i>
                            </span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">
                              <li className="cat cat-skyblue">
                                <input
                                  type="checkbox"
                                  id="action1"
                                  value="Created"
                                  onClick={handleActionTypeFilter}
                                  checked={searchActionTypeCheck.action1}
                                />
                                <label htmlFor="action1">
                                  <span className="cat cat-skyblue">
                                    <i className="fas fa-circle mr-1"></i>{" "}
                                    Created
                                  </span>
                                </label>
                              </li>
                              <li className="cat cat-red">
                                <input
                                  type="checkbox"
                                  id="action3"
                                  value="Deleted"
                                  onClick={handleActionTypeFilter}
                                  checked={searchActionTypeCheck.action3}
                                />
                                <label htmlFor="action3">
                                  <span className="cat cat-red">
                                    <i className="fas fa-circle mr-1"></i>{" "}
                                    Deleted
                                  </span>
                                </label>
                              </li>
                              <li className="cat cat-greenhex">
                                <input
                                  type="checkbox"
                                  id="action2"
                                  value="Edited"
                                  onClick={handleActionTypeFilter}
                                  checked={searchActionTypeCheck.action2}
                                />
                                <label htmlFor="action2">
                                  <span className="cat cat-greenhex">
                                    <i className="fas fa-circle mr-1"></i>{" "}
                                    Edited
                                  </span>
                                </label>
                              </li>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${
                              month.length ? "btn-selected" : ""
                            }`}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title="Month"
                          >
                            <span>
                              Month <i className="fal fa-angle-down"></i>
                            </span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">{renderMonthUI()}</ul>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${
                              year.length ? "btn-selected" : ""
                            }`}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title="Year"
                          >
                            <span>
                              Year <i className="fal fa-angle-down"></i>
                            </span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">{renderYearUI()}</ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>

                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Articulation Audit Trail");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Articulation Audit Trail");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Articulation Audit Trail");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <DataTable
            data={userData}
            defaultSortField="date_time"
            defaultSortAsc={false}
            onSort={handleSort}
            columns={columns}
            noDataComponent={Str.noRecord}
            progressPending={loading}
            paginationDefaultPage={page}
            progressComponent={<SkeletonTicketList />}
            pagination
            paginationServer
            sortServer
            highlightOnHover={false}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
      </div>
    </div>
  );
};

export default AuditTrails;
