import React from "react";
import { useParams } from "react-router-dom";
import AddResource from "./AddResource";
import EditResourceManagement from "./EditResource";
import IssueResource from "./IssueResource";
import FoundResource from "./MarkAsFound";
import MissingResource from "./MarkAsMissing";
import ReserveResource from "./ReserveResource";
import ResourceDashBoard from "./ResourceDashboard";
import ResourceList from "./resourceList";
import ReturnResource from "./ReturnResource";

function ResourceTab({setResourceName}) {
  const { tab, type, id, editType } = useParams();
  return (
    <>
      {type && type == "table" && !id && <ResourceList setResourceName={setResourceName}/>}
      {type && type == "add" && !id && <AddResource />}
      {type && type == "edit" && editType == "details" && <EditResourceManagement setResourceName={setResourceName} bulk_id={id} />}
      {type && type == "edit" && editType == "dashboard" && <ResourceDashBoard setResourceName={setResourceName}/>}
      {type && type == "edit" && editType == "issue" && <IssueResource setResourceName={setResourceName}/>}
      {type && type == "edit" && editType == "missing" && <MissingResource setResourceName={setResourceName} />}
      {type && type == "edit" && editType == "found" && <FoundResource setResourceName={setResourceName} />}
      {type && type == "edit" && editType == "return" && <ReturnResource  setResourceName={setResourceName}/>}
      {type && type == "edit" && editType == "reserve" && <ReserveResource setResourceName={setResourceName}/>}
    </>
  );
}

export default ResourceTab;
