import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../../../../loaders/SkeletonTicketList";
import Str from "../../../../../common/Str";
import { QuizStatusRender } from "../../../../../systemadministration/usermanagement/CheckRole";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import { assignedQuizToQuestion, GetQuestionAssignedOrNotAssignedList, GetQuizTopics, ListQuestionFilter, QuestionDetails, AddaQuestion, AssignBulkQuestion, GetQuizTopicsCancelToken, GetQuestionAssignedOrNotAssignedListCancelToken, ListQuestionFilterCancelToken } from "../../../../../../services/SmartAssessmentService";
import { useHistory, useParams } from "react-router";
import { formatQuestion } from "../../../../../../utils/FormatQuestion";
import { Link } from "react-router-dom";
import DisplayQuestion from "../../../DisplayQuestion";
import SekletonLearningMaterial from "../../../../../../loaders/SekletonLearningMaterial";
import PermissionsGate from "../../../../../../utils/permissionGate";
import QBTable from "./QBTable";
import { useSelector } from "react-redux";
import hasPermission from "../../../../../../utils/hasMultiplePermission";
import $ from "jquery"
import striptags from "striptags";
import { TABLE_ROWS_PER_PAGE, TABLE_DATE_FORMAT } from "../../../../../../utils/Constants";
import Tablefilter from "../../../../../common/Tablefilter";
import { RenderQuestionLevel, RenderQuestionType } from "../../../../../../utils/CommonGroupingItem";
import { RenderDate, RenderQuizTopic } from "../../../../../../utils/CommonStatusItems";
import PopupComponent from "../../../../../common/PopupComponent";
import Select from "react-select";
import { useFormik } from "formik";
import HtmlParser from "react-html-parser";
import * as Yup from "yup";
import { handleTableScroll } from "../../../../../../utils/commonFunction";
import axios from "axios";

const nqfOptions = [
  { label: "NQF 1", value: "NQF 1", data: "1" },
  { label: "NQF 2", value: "NQF 2", data: "2" },
  { label: "NQF 3", value: "NQF 3", data: "3" },
  { label: "NQF 4", value: "NQF 4", data: "4" },
  { label: "NQF 5", value: "NQF 5", data: "5" },
  { label: "NQF 6", value: "NQF 6", data: "6" },
  { label: "NQF 7", value: "NQF 7", data: "7" },
  { label: "NQF 8", value: "NQF 8", data: "8" },
  { label: "NQF 9", value: "NQF 9", data: "9" },
  { label: "NQF 10", value: "NQF 10", data: "10" },
];

const AssignedQTable = ({refresh}) => {
  const [search, setSearch] = useState("");

  const [type, setType] = useState({ arr: [], checkObj: {} });
  const [level, setLevel] = useState({ arr: [], checkObj: {} })
  const [questionLoading, setQuestionLoading] = useState(false);
  const [questionData, setquestionData] = useState({});
  const { id } = useParams();
  const history = useHistory();
  const [loading1, setLoading1] = useState(true);
  const [qdata, setQData] = useState([]);
  const [sortkey, setSortKey] = useState("last_modified_date");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dummy, setDummy] = useState();
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [topicArr, setTopicArr] = useState([]);
  const [topics, setTopics] = useState({ arr: [], checkObj: {} });
  const [Month, setMonth] = useState({ arr: [], checkObj: {} });
  const [Year, setYear] = useState({ arr: [], checkObj: {} });
  const [filterDataAssign, setFilterDataAssign] = useState({type : [], level : [], topics : [], Month : [], Year : [] });
  const [debouncedTerm, setDebouncedTerm] = useState("")

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  useEffect(() => {
    handleTableScroll()
  }, [loading1])

  useEffect(() => {
    let response = hasPermission({ scopes: ["cquizmquview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await ListQuestionFilterCancelToken(id,"assigned",dropdownSource.token);
        if(res.status == 200){
          setFilterDataAssign({...res.data, type : res.data.questionTypeFilters, level : res.data.questionLevelFilter, topics : res.data.topicFilters, Month : res.data.month, Year : res.data.year})
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getQuestionAssignedOrNotAssignedList = async () => {
      setLoading1(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const data = {
        type: "assigned",
        quizId: id,
        viaType: type.arr.length ? type.arr : [],
        viaLevel: level.arr.length ? level.arr  : [],
        page: page,
        limit: perPage,
        viaSearch: search,
        key: sortkey,
        sort: sortOrder,
        exportStatus: false,
        viaTopics: topics.arr.length > 0 ? topics.arr : [],
        viaMonth: Month.arr.length > 0 ? Month.arr : [],
        viaYear: Year.arr.length > 0 ? Year.arr : []
      }
  
      try {
        const res = await GetQuestionAssignedOrNotAssignedListCancelToken(data, source.token);
        if (res.status === 200) {
          setQData(res.data.res.data)
          setTotalRows(res.data.res.total)
          setLoading1(false)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading1(false)
        }
      }
    };
  
    getQuestionAssignedOrNotAssignedList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [dummy, type, level, page, perPage, search, sortkey, sortOrder,topics, Month, Year]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetQuizTopicsCancelToken({},dropdownSource.token);
        if(res.status == 200){
          let tempArr = res?.data?.map(item => {
            return { value: item?.id, label: HtmlParser(`<span style="color:#fff;background:${item?.color};" title=${item?.options}>${item?.options}</span>`) }
          });
          setTopicArr(res?.data)
          setQuizTopics(tempArr);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const handlerefresh = () => {
    setDummy(!dummy);
    refresh();
  }

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const handleViewQuestionDetails = (ID) => {
    setQuestionLoading(true)
    setquestionData({});
    QuestionDetails(ID).then((res) => {
      setquestionData(res.data[0]);
      setQuestionLoading(false)
    }).catch((errors) => {
      console.error("errorrs", errors)
      setQuestionLoading(false)
    })
  }

  const wordSpliter = (str, type) => {
    const strArr = Array.isArray(JSON.parse(str)) ? JSON.parse(str) : [];
    const newStrArr = strArr.length ? strArr?.map(item => {
      if (type === "topics") {
        // const topic = topicArr.find(val => val.id == item.replaceAll('\"', "").replaceAll("\\","").replaceAll('[', "").replaceAll(']', ""));
        const topic = topicArr.find(val => val.id == item);
        return item ? <p className="as-widget mr-1 mt-1" style={{color: '#fff',background: topic?.color}}>{topic?.options}</p> : '-';
      } else {
        return item ? <p className="as-widget mr-1 mt-1" style={{color: '#333333', background: '#e6e6e6'}}>{item}</p> : '-';
      }
    }) : "-";
    return newStrArr;
  }
  const wordSpliterTopics = (str) => {
    let htmlOutput = []
    const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length ? strArr?.map(item => {
      filterDataAssign.topics.map((val)=>{
        if(val.value == item){
          htmlOutput.push(RenderQuizTopic(val.label,val.color).html)
        }
        return;
      });   
    }) : "-";
    return htmlOutput.length ? htmlOutput : []
  }
  const wordSpliterTopicsExport = (str) => {
    let htmlOutput = []
    const strArr = str && Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length ? strArr?.map(item => {
      filterDataAssign.topics.map((val)=>{
        if(val.value == item){
          htmlOutput.push(RenderQuizTopic(val.label,val.color).text)
        }
        return;
      });   
    }) : "-";
    return htmlOutput.length ? htmlOutput.join(",") : []
  }

  // Function to extract months and return as objects with value and label
  function extractMonths(dateArray) {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const monthsArray = [];
    dateArray.forEach((monthObj) => {
        const month = monthObj.value;
          const monthName = monthNames[month - 1];
            monthsArray.push({
              value: month,
              label: monthName,
            });
    });
  
    return monthsArray;
  }
  const columns1 = useMemo(() => [
    {
      name: "Questions Order",
      selector: "questions_order",
      sortField: "questions_order",
      // sortFunction: sortName,
      sortable: true,
      cell: (row, index) => (        
            <p className="textLimit100">{row.questions_order ? row.questions_order : "-"}</p>                
        )
    },
    {
      name: "Name",
      selector: "question_name",
      sortField: "question_name",
      // sortFunction: sortName,
      sortable: true,
      cell: (row, index) => {
        let question = (row.question_name);
        return (<PermissionsGate scopes={["cquizmquview"]} errorProps={{ disabled: true }}>
          <Link className="as-text-blue curser " title={striptags(question)} to={"/courseAdministration/smartAssessment/questionBank/question/view/" + row?.qid} >
            <p className="textLimit100">{formatQuestion(question)}</p>
          </Link>
        </PermissionsGate>
        )
      },
    },
    {
      name: "Type",
      selector: "question_type",
      sortField: "question_type",
      sortable: true,
      cell: (row, index) => (
        <>
          {row.question_type ? RenderQuestionType(row.question_type.trim()).html : "-"}
        </>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row, index) => (
        <>
          {row.status ? QuizStatusRender(row) : "-"}
        </>
      ),
    },
    {
      name: "Level",
      selector: "level_name",
      sortField: "level_name",
      sortable: true,
      cell: (row, index) =>
        row.level_name ? RenderQuestionLevel(row.level_name).html : "-",
    },
    {
      name: "NQF Level",
      selector: "nqf_level",
      sortField: "nqf_level",
      sortable: true,
      cell: (row, index) =>
        row.nqf_level ? <span>NQF {row.nqf_level.replace("NQF ","")}</span>: "-",
    },
    {
      name: "#Linked Quizzes",
      selector: "AssigendToassessmentCount",
      sortField: "AssigendToassessmentCount",
      sortable: true,
      cell: (row, index) => <p>{row?.AssigendToassessmentCount?.toString() ? row?.AssigendToassessmentCount : "-"}</p>,
    },
    {
      name: "Last Modified Date",
      selector: "last_modified_date",
      sortField: "last_modified_date",
      sortable: true,
      cell: (row) => {
        const moment = require('moment');
        // Check if the last_modified_date exists
        if (row.last_modified_date) {
          // Use Moment.js to format the date
          const formattedDate = moment(row.last_modified_date).format(TABLE_DATE_FORMAT).toString() === "Invalid date" ? "-" : moment(row.last_modified_date).format(TABLE_DATE_FORMAT);
      
          return formattedDate;
        } else {
          return "-";
        }
      },
    },
    {
      name: "Keywords",
      cell: (row) => row?.keywords ? <div className="d-flex flex-wrap">{wordSpliter(row?.keywords,"keywords")}</div> : '-',
    },
    {
      name: "Topics",
      cell: (row) => row?.topic ? <div className="d-flex flex-wrap">{wordSpliterTopics(row?.topic).length ? wordSpliterTopics(row?.topic).map((item, index) => <React.Fragment key={index}>{item} &nbsp;</React.Fragment>) : ""}</div> : '-',
    },
    {
      name: "Actions",
      maxWidth: "50px",
      cell: (row, index) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <PermissionsGate scopes={["cquizmquview"]} RenderError={() => (
              <button className="btn btn-primary rounded-circle" title="Open" disabled >
                <i className="fal fa-folder-open"></i>
              </button>
            )
            }>
              <Link className="btn btn-primary rounded-circle" title="Open" to={"/courseAdministration/smartAssessment/questionBank/question/view/" + row?.qid} >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            <div className="dropdown btn-dropdown-item ml-1">
              <button className="btn btn-primary rounded-circle dropdown-toggle" title="More" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button
                  className="btn btn-primary rounded-circle"
                  data-toggle="modal"
                  data-target="#questionView"
                  title="View Question"
                  onClick={() => {
                    handleViewQuestionDetails(row.qid)
                  }}
                >
                  <i className="fal fa-eye"></i>
                </button>
                <PermissionsGate scopes={["cquizmquedit"]} errorProps={{ disabled: true }}>
                  <button className="btn btn-danger rounded-circle" title="Remove" onClick={() => handleDelete(row.qid)} >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        assignedQuizToQuestion({ quizId: id, questionId: ID.toString(), deleteQulification: "true" }).then((res) => {
          // Swal.fire("Removed!", "Your question has been removed.", "success");
          handlerefresh();
        }).catch(err => Swal.fire({ icon: "error", title: "Error", text: err.response.data.message }));

      }
    })
  };

  const handleFilter = (e, typeName = "") => {
    const value = e.target.value;
    if (typeName == "search") {
      setDebouncedTerm(value);
    } 
  };

  function resetFilter() {
    setSearch("");
    setDebouncedTerm("")
    setType({ arr: [], checkObj: {} });
    setLevel({ arr: [], checkObj: {} });
    setTopics({ arr: [], checkObj: {} });
    setMonth({ arr: [], checkObj: {} });
    setYear({ arr: [], checkObj: {} });
  }

  const exportData = async (fileType, fileName) => {
    let data;
    const header = ["Questions Order", "Name", "Type", "Status", "Level", "NQF Level", "#Linked Quizzes", "Last Modified Date", "Keywords", "Topics"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    GetQuestionAssignedOrNotAssignedList({
      type: "assigned",
      quizId: id,
      viaType: type.arr.length ? type.arr : [],
      viaLevel: level.arr.length ? level.arr : [],
      page: page,
      limit: perPage,
      viaSearch: search,
      key: sortkey,
      sort: sortOrder,
      exportStatus: true,
      viaTopics: topics.arr.length > 0 ? topics.arr : [],
      viaMonth: Month.arr.length > 0 ? Month.arr : [],
      viaYear: Year.arr.length > 0 ? Year.arr : [],
    })
      .then((res) => {
        data = res.data.res
        data = data?.map((row) => ({
          ...row,
          "Questions Order": row?.questions_order ? row?.questions_order : "-",
          Name: row?.question_name ? row?.question_name : "-",
          Type: row?.question_type ? RenderQuestionType(row?.question_type).text : "-",
          Status: row?.status ? row?.status : "-",
          Level: row.level_name ? RenderQuestionLevel(row.level_name).text : "-",
          "NQF Level": row?.nqf_level ? `NQF ${row?.nqf_level.replace("NQF ","")}` : "-",
          "#Linked Quizzes": row?.AssigendToassessmentCount?.toString() ? row?.AssigendToassessmentCount : "-",
          Keywords: (row?.keywords && row?.keywords.length > 0 && Array.isArray(JSON.parse(row?.keywords))) ? JSON.parse(row?.keywords)?.map(item=>item).join(", ") : "-",
          Topics: (row?.topic && wordSpliterTopicsExport(row?.topic).length) ? wordSpliterTopicsExport(row?.topic) : "-" ,
          last_modified_date: row?.last_modified_date ? row?.last_modified_date : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

          const blobURL = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Questions Order"],
              row["Name"],
              row["Type"],
              row["Status"],
              row["Level"],
              row["NQF Level"],
              row["#Linked Quizzes"],
              // row["% Correct"],
              row["#Last Modified Date"],
              row["Keywords"],
              row["Topics"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            columnStyles: {
              0: {
                columnWidth: 30,
              },
              1: {
                columnWidth: 30,
              },
              2: {
                columnWidth: 20,
              },
              3: {
                columnWidth: 20,
              },
              4: {
                columnWidth: 20,
              },
              5: {
                columnWidth: 20,
              },
              6: {
                columnWidth: 20,
              },
              7: {
                columnWidth: 20,
              },
              8: {
                columnWidth: 20,
              },
              9: {
                columnWidth: 20,
              },
            },
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })

  };

  const [quizTopics, setQuizTopics] = useState([]);
  const [disabled, setDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      topics: [],
      keywords: [],
      quizId: id,
      nqf_level:"",
      limit : "",      
      // isSaving: false,
    },
    validationSchema: Yup.object({
      keywords: Yup.array().min(1, "Atleast one Keyword is required").required("Keyword is required").nullable(),
      topics: Yup.array().min(1, "Atleast one Topic is required").required("Topics is required").nullable(),
      // nqf_level: Yup.string().required("NQF Level is required"),
      // limit: Yup.string().required("Limit is required"),
    }),
    onSubmit: (values) => {
      const sendData = {
        quizId: id,
        topics: values.topics.map(item => item.value),
        keywords: values.keywords,
        nqf_level:values.nqf_level,
        limit : values.limit,
      }
      AssignBulkQuestion(sendData)
        .then((res) => {
          Swal.fire({ icon: "success", title: "Success", text: "Added Successfully" });
          setDisabled(false);
          formik.resetForm();
          formik.setFieldValue("keywords", []);
          $("#addBulkQuestion").modal('hide')
          handlerefresh();
        })
        .catch((err) => {
          Swal.fire({ icon: "error", title: "Error", text: err.response.data.message });
          setDisabled(false);
        });
    },
  })

  const handleKeywords = (event) => {
    var keyword = formik.values.keywords;
    if (event.key === "Enter") {
      if (event.target.value) {
        keyword.push(event.target.value);
        event.target.value = ""
        formik.setFieldValue("keywords", keyword);
      }
    }
  };

  const handleRemoveKeywords = (index) => {
    let keyword = [...formik.values.keywords];
    keyword.splice(index, 1);
    formik.setFieldValue('keywords', keyword)
    //setFillOption([...keyword])
  };

  const multiSelectColorStyles = {
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      '& span': {
        background: 'transparent !important',
        color: '#333 !important'
      }
    }),
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={(e) => { handleFilter(e, "search") }} value={debouncedTerm} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                  <Tablefilter
                    filterName="Type"
                    optionArr={filterDataAssign.type}
                    state={type}
                    setState={setType}
                    renderLabelFunction={RenderQuestionType}
                    uniqueId="assignType"
                  />
                  <Tablefilter
                    filterName="Level"
                    optionArr={filterDataAssign.level}
                    state={level}
                    setState={setLevel}
                    uniqueId="assignLebel"
                    renderLabelFunction={RenderQuestionLevel}
                  />
                  <Tablefilter
                    filterName="Topic"
                    optionArr={filterDataAssign.topics}
                    state={topics}
                    setState={setTopics}
                    renderLabelFunction={RenderQuizTopic}
                    uniqueId = "assignTopic"
                  />
                   <Tablefilter
                    filterName="Month"
                    optionArr={extractMonths(filterDataAssign.Month)}
                    state={Month}
                    setState={setMonth}
                  />
                  <Tablefilter
                    filterName="Year"
                    optionArr={filterDataAssign.Year}
                    state={Year}
                    setState={setYear}
                    renderLabelFunction={RenderDate}
                  />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button type="button" className="btn btn-files" title="Export spreadsheet" onClick={() => { exportData("xlsx", "Assigned Questions"); }} >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button type="button" className="btn btn-files" title="Export CSV" onClick={() => { exportData("csv", "Assigned Questions"); }} >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button type="button" className="btn btn-files" title="Export PDF" onClick={() => { exportData("pdf", "Assigned Questions"); }}  >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate scopes={["cquizmquadd"]} errorProps={{ disabled: true }}>
                  <button type="button" className="btn btn-primary" title="Add Bulk" onClick={() => {
                    // formik.setFieldValue("isSaving", false)
                    // formik.handleSubmit()
                    $("#addBulkQuestion").modal('show')
                  }}>
                    <i className="fal fa-file-alt"></i>
                    Add Bulk
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <button
                  className="btn btn-primary"
                  title="Group View"
                  onClick={() => {
                    history.push(`/courseAdministration/assessment/quizzes/open/groupview/${id}`);
                  }}
                >
                  <i className="fal fa-eye"></i>Group View
                </button>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate scopes={["cquizmquadd"]} errorProps={{ disabled: true }}>
                  <button
                    className="btn btn-primary"
                    title="Assign Question"
                    data-toggle="modal" data-target="#assignQuestion"
                  // onClick={() => {
                  //   history.push(`/courseAdministration/smartAssessment/addquestion/${id}`);
                  // }}
                  >
                    <i className="fal fa-plus"></i>Assign Question
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>

        </div>


        <DataTable
          data={qdata}
          //  data={dataToRender()}
          defaultSortField="last_modified_date"
          defaultSortAsc={false}
          columns={columns1}
          pagination={true}
          noDataComponent={Str.noRecord}
          progressPending={loading1}
          progressComponent={<SkeletonTicketList />}
          onSort={handleSort}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationServer
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
        <div
          className="topic-add-modal modal fade"
          // style={{ display: "block" }}
          id="questionView"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content modal-border-update">
              <div className="modal-body p-0">

                <div className="modal-header modal-header-custom">
                  <h5 class="modal-title">
                    View Question
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      // formik.resetForm()
                      $("#questionView").modal("hide");
                      // setEditorState1("");
                      // setShowCkEditor(false);

                      // formik.setFieldValue("type", "");
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div className="t p-30px">
                  {/* <div className="topic-add-modal-content text-center font-weight-bold">
                    View Question
                  </div>
                  <hr /> */}
                  {questionLoading ? <SekletonLearningMaterial /> :
                    <DisplayQuestion question={questionData} from="assignedQTable" />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




      {/* notification details popup view icon start */}
      <div className="topic-add-modal modal fade" id="assignQuestion" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered available-assessment-modal" role="document">

          <div className="modal-content">
            {/* <div className="modal-body"> */}
            <div class="modal-header modal-header-custom"><h5 class="modal-title"><i class="fal fa-plus"></i> Assign Question</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>

            <QBTable refresh={handlerefresh} />
          </div>
        </div>
      </div>
      {/* <PopupComponent
                quiz = {id}
                id="addBulkQuestion"
                type="addBulkQuestion"
                header={{
                    iconName: "fa-bars",
                    heading: "Add Bulk Question"
                }}
                modalBody={<DisplayQuestion question={addBulkQuestionData} from="assignedQTable" />}
            /> */}
      <PopupComponent
        // quiz = {id}
        id="addBulkQuestion"
        type="form"
        header={{
          iconName: "fa-bars",
          heading: "Add Bulk Question"
        }}
        inputs={[
          <>
            <div className="col-md-6">
              <div className="form-group-blk mb-3" title="Topics">
                <label htmlFor="Topic">Topics *</label>
                <Select
                  styles={multiSelectColorStyles}
                  className={"form-control color-drop custom-select-box is-multi" + (formik.errors.topics && formik.touched.topics ? " is-invalid" : "")}
                  name="topics"
                  title="Topics"
                  value={formik.values.topics}
                  onChange={(value) => formik.setFieldValue("topics", value)}
                  onBlur={formik.handleBlur}
                  options={quizTopics}
                  maxMenuHeight={175}
                  isMulti={true}
                  placeholder="Select Topics"
                />
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <label>Keywords *</label>
              <div className="form-icon-group">
                <div className="form-control custom-select-box">
                  <input
                    type="text"
                    className={"form-control " + (formik.errors.keywords && formik.touched.keywords ? " is-invalid" : "")}
                    name="keywords"
                    placeholder="Keywords"
                    title="Keywords"
                    onBlur={formik.handleBlur}
                    onKeyDown={handleKeywords}
                  />
                  {formik.errors.keywords && formik.touched.keywords ? (<span className="exclamation"><i className="fal fa-exclamation-circle"></i></span>) : null}
                </div>
              </div>
              {formik.values.keywords.length ? (
                <ul className="tag-list mb-0">
                  {formik.values.keywords?.map((ele, index) => (
                    <li key={index}>
                      {ele}
                      <span onClick={() => handleRemoveKeywords(index)}>
                        <i className="fal fa-times"></i>
                      </span>
                    </li>
                  ))}
                </ul>
              ) : ""}
            </div>
            <div className="col-md-6">              
              <label>NQF Level</label>
              <div className="form-icon-group mb-4" title="NQF Level">
                <Select
                    className={"form-control custom-select-box custom-profile-page" + (formik.errors.nqf_level && formik.touched.nqf_level ? " is-invalid" : "")}
                    name="nqf_level"
                    value={nqfOptions?.filter(val => val.data === formik.values.nqf_level)}
                    onChange={(value) => {
                        if (value) {
                            formik.setFieldValue("nqf_level", value.data);
                        } else {
                            formik.setFieldValue("nqf_level", "");
                        }
                    }}
                    onBlur={formik.handleBlur}
                    options={nqfOptions}
                    maxMenuHeight={175}
                    placeholder={"Select"}
                    isClearable
                />
              </div>
            </div>            
            <div className="col-md-6">
              <label>Limit</label>
              <div className="form-icon-group mb-4">
                <input
                  type="text"
                  className={"form-control" + (formik.errors.limit && formik.touched.limit ? " is-invalid" : "")}
                  placeholder="Limit"
                  title="Limit"
                  name="limit"
                  value={formik.values.limit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.limit &&
                  formik.touched.limit ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </>,
        ]}
        bottomButton={[
          <button type="button" className="btn btn-primary" disabled={disabled} title="Save" onClick={() => {
            // formik.setFieldValue("isSaving", true)
            formik.handleSubmit()
          }}>
            {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save
          </button>,
          <button type="button" className="btn btn-danger" data-dismiss="modal" title="Cancel" onClick={()=>{formik.resetForm();formik.setFieldValue("keywords", []);}}>
            <i className="fal fa-times"></i>Cancel
          </button>,
          Object.keys(formik.values).map((key, ind) => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block" key={ind}>
                  {formik.errors[key]}
                </div>
              )
            }
          })
        ]}
        otherProps={
          {
            secondPopupCss: "zindexadd"
          }
        }
      />
    </div>
  );
};

export default AssignedQTable;
