import React, { useEffect, useMemo, useState } from "react";
import {
  downloadURL,
  FORM_DATE_FORMAT,
  FORM_DATE_TIME_FORMAT,
  IMAGE_URL,
  STUDENT_FILE_DOWNLOAD,
  TABLE_DATE_TIME_FORMAT,
  TABLE_ROWS_PER_PAGE,
} from "../../../../utils/Constants";
import PermissionsGate from "../../../../utils/permissionGate";
import { InitialRender, convertToPlain } from "../../../common/Helper";
import Swal from "sweetalert2";
import Papa from "papaparse";
import { TrimText } from "../../../common/TrimText";
import {
  AddAndGetTicketComment,
  DeleteAssessmentSubmissionUpload,
  DownloadAssessmentsStudentResults,
  GetdownloadAssements,
  GetimportMarks,
  GetMarkasCompelete,
  GradeLetterTemplateList,
  SendAssignmentInstruction,
  updateGrademixMarkSubmission,
  UploadMarkAssessment,
} from "../../../../services/CourseService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  ApproveOrDeclineAppeal,
  ViewAppeal,
} from "../../../../services/AppealsService";
import moment from "moment";
import Hover from "../../../common/Hover";
import { useFormik } from "formik";
import * as Yup from "yup";
import $ from "jquery";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useRef } from "react";
import {
  checkIfImageExists,
  formatBytes,
  twoDecimalRound,
} from "../../../../utils/commonFunction";
import DataTableComponentFrontPagination from "../../../common/DataTableComponentFrontPagination";
import {
  RenderQuizAttemptResultStatus,
  RenderQuizResult,
  RenderSubmissionAndResultStatus,
} from "../../../../utils/CommonStatusItems";
import {
  RenderLearningMethodGradebook,
  RenderSubmissionStatus,
} from "../../../../utils/CommonGroupingItem";
import PopupComponent from "../../../common/PopupComponent";
import { useSelector } from "react-redux";
import axios from "axios";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import * as XLSX from "xlsx";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Str from "../../../common/Str";

import ExcelJS from "exceljs";
import {
  getCourseAssessment,
  getCourseGradebook,
} from "../../../../services/GradeSettingService";
import jsPDF from "jspdf";
import {
  getAssessmentSubmissionResultGrademix,
  getGrademixListViaAssessment,
} from "../../../../services/GradeMixService";
import GradeBookSaveBar from "../../../common/GradeBookSaveBar";
import { Link, Prompt, useHistory, useParams } from "react-router-dom";
import hasPermission from "../../../../utils/hasMultiplePermission";

function GradeMixSubmissionAndResults({ assName }) {
  const history = useHistory();
  const { id, type, subId, subType, subSubId, statusValue } = useParams();
  const [search, setSearch] = useState("");
  const [attachmentSearch, setAttachmentSearch] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [submissionStatus, setSubmissionStatus] = useState({
    arr: [],
    checkObj: {},
  });
  const [comment, setComment] = useState("");
  const [deleterow, setDeleteRow] = useState(false);
  const [userData, setUserData] = useState([]);
  const [headerData, setHeaderData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [submissionId, setSubmissionId] = useState();
  const [startDate, setStartDate] = useState(new Date());

  const [studentMessage, setStudentMessage] = useState("");
  const [studentId, setStudentId] = useState();

  const [appealsDetails, setAppealsDetails] = useState();

  const [disableNeeded, setDisableNeeded] = useState(false);
  const [instructionLoading, setInstructionLoading] = useState(false);
  const [checkAssignment, setcheckAssignment] = useState("false");
  const [appealDisable, setAppealDisable] = useState("");
  const [messageError, setMessageError] = useState("");
  const [fileLoading, setFileLoading] = useState(false);
  const [finalTotal, setFinalTotal] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [importLoading, setImportLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [options, setOptions] = useState([
    { label: "", value: ""},
    { label: "Red", value: "0" },
    { label: "Yellow", value: "49" },
    { label: "Green", value: "100" },
  ]);
  const [updatedata, setupDatedata] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGrademix, setLoadingGrademix] = useState(false);
  const [gearid, setGearid] = useState("");
  const [hGear, setHGear] = useState("");
  const [notificationval, setNotificationVal] = useState(false);
  const [downloadZip, setDownloadZip] = useState([]);
  const inputRef = useRef(null);
  const [zipfileupload, setZipFileUpload] = useState("");
  const [zipfilename, setZipFileName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmissionMarked, setIsSubmissionMarked] = useState(false);
  const [FinalLoadedData, setFinalLoadedData] = useState(0);
  const [isHybrid, setIsHybrid] = useState(false);
  const downloadRef = React.useRef(null);
  const [paginationState, setPaginationState] = useState({
    from: 0,
    to: 0,
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
  });
  const [tableState, setTableState] = useState({ page: 1, limit: 10 });
  const [percentageView, setPercentageView] = useState(false);
  const [tableWidth, setTableWidth] = useState(0);
  const [assessmentHeader, setAssessmentHeader] = useState([]);
  const [oldValue, setOldValue] = useState({});
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [attachmentId, setAttacmentId] = useState("");
  const [commentLoading, setCommentLoading] = useState(false);
  const [updateValueArr, setUpdateValueArr] = useState([]);
  const [saveGradesLoading, setSaveGradesLoading] = useState(false);
  const [isCourseEdited, setIsCourseEdited] = useState(0)
  const [mousePressed, setMousePressed] = useState(false);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["casubmissionresultview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  const shouldBlockNavigation = updateValueArr.length;

  useEffect(() => {
    setLoadingGrademix((prevState) => ({ ...prevState, list: true }));
    const Values = {
      assessment_id: subId,
      key: "",
      sort: "DESC",
      exportStatus: "false",
      search: search,
      page: tableState.page,
      limit: tableState.limit,
    };
    getAssessmentSubmissionResultGrademix(Values)
      .then((res) => {
        let tempStatus = false;
        const hybridRecord = res.data.body.filter(
          (item) => item.isHybrid == "true"
        );
        if (hybridRecord.length) {
          setIsHybrid(true);
          if (
            hybridRecord.filter(
              (element) => element.submission_status != "MARKED"
            ).length
          ) {
            tempStatus = true;
          }
        } else if (
          res.data.body.filter(
            (item) => item.submission_status == "SUBMITTED" && !item.mark
          ).length
        ) {
          tempStatus = true;
        }
        setIsSubmissionMarked(tempStatus);
        if (res && res.data && res.data.body) {
          res.data.body.forEach(item => {
            if (item.grademixArr && Array.isArray(item.grademixArr)) {
              item.grademixArr.forEach(subItem => {
                if (subItem.letterGrades && Array.isArray(subItem.letterGrades)) {
                  subItem.letterGrades.unshift({ value: "", label: "" });
                }
              });
            }
          });
        }

        setUserData(res?.data?.body);
        setHeaderData(res?.data?.header);
        if (
          res.data.header.grading_type &&
          res.data.header.grading_type === 2
        ) {
          GradeLetterTemplateList({ exportStatus: true }).then((result) => {
            if (result.status == 200) {
              let respData = result.data.list_data;
              respData = respData.find(
                (item) => item.id === res.data.header.grading_template_id
              );
              const optionData = respData.gradeOptions.map((item) => ({
                label: item.text,
                value: item.id,
                minValue: item.min_score,
                maxValue: item.max_score,
              }));
              setOptions([...optionData]);
            }
          });
        }
        let pagination = res?.data?.pagination;
        setPaginationState({
          from: pagination?.from,
          to: pagination?.to,
          total: pagination?.total,
          perPage: pagination?.per_page,
          currentPage: pagination?.current_page,
          lastPage: pagination?.last_page,
        });
        setIsLoading(false);
        setLoading(false);
        setLoadingGrademix((prevState) => ({ ...prevState, list: false }));
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
        setLoading(false);
        setLoadingGrademix((prevState) => ({ ...prevState, list: false }));
      });
  }, [updatedata, id, tableState, search, deleterow]);

  useEffect(() => {
    GetdownloadAssements(subId)
      .then((res) => {
        setDownloadZip(res?.data?.assignment_details);
        setZipFileName(res.data.assignments_codes.name);
        let checkTotalFiles = calculateFileSize(
          res?.data?.assignment_details
        ).checkTotal;
        setTotalFiles(checkTotalFiles);
      })
      .catch((err) => {});
  }, [updatedata, deleterow]);

  const dataToRender = () => {
    let updatedData = [];
    let allData = userData;
    if (search.length) {
      let tempId = allData.filter((item) => {
        let includes =
          item.id && item.id.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempname = allData.filter((item) => {
        let includes =
          item.name && item.name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempId, ...tempname];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.arr.find(function (post, index) {
          if (
            item.status && post.toLowerCase() == item.status.toLowerCase()
              ? item.status
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (submissionStatus.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = submissionStatus.arr.find(function (post, index) {
          if (
            item.submission_status &&
            post.toLowerCase() == item.submission_status.toLowerCase()
              ? item.submission_status
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (status.arr.length || search.length || submissionStatus.arr.length) {
      return updatedData;
    } else {
      return userData;
    }
  };

  const attachmentDataToRender = () => {
    let updatedData = [];
    let filteredUserData = userData.filter((item) => item.id === attachmentId);
    let allData =
      filteredUserData.length > 0 &&
      filteredUserData[0].multipleFiles.length > 0 &&
      filteredUserData[0].multipleFiles.filter((item) => item.type == 1);
    if (attachmentSearch.length) {
      let tempname = allData.filter((item) => {
        let includes =
          item.path &&
          item.path.split("/").reverse()[0] &&
          item.path
            .split("/")
            .reverse()[0]
            .toLowerCase()
            .includes(attachmentSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempname];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (attachmentSearch.length) {
      return updatedData;
    } else {
      return allData || [];
    }
  };

  const addFileFun = (e, rowId) => {
    let tempUserData = userData;
    const data = new FormData();
    const listFile = e.target.files;
    setFileLoading(rowId);
    data.append("submission", rowId);
    Object.keys(listFile).map((key, index) => {
      data.append(`file[${index}]`, listFile[key]);
    });

    UploadMarkAssessment(data)
      .then((res) => {
        setDeleteRow(!deleterow);
        Swal.fire("Success!", "Successfully Uploaded.", "success");
      })
      .catch((err) => {
        Swal.fire("Error!", "File Not Uploaded.", "error");
        console.log("error :", err);
      })
      .finally(() => {
        setFileLoading("");
      });
    tempUserData.map((item) => {
      if (item.id == rowId) {
        item.fileName = Object.keys(listFile).map((key) => listFile[key].name);
        item.file = e.target.files;
        return item;
      } else {
        return item;
      }
    });

    setUserData((tempUserData) => [...tempUserData]);
  };

  const sortAttachmentName = (rowA, rowB) => {
    const name1 = rowA.path.split("/").reverse()[0]
      ? rowA.path.split("/").reverse()[0].toString().toLowerCase().trim()
      : "";
    const name2 = rowB.path.split("/").reverse()[0]
      ? rowB.path.split("/").reverse()[0].toString().toLowerCase().trim()
      : "";
    return name1.localeCompare(name2);
  };


  const attachmentColumns = useMemo(
    () => [
      {
        name: "Attachment",
        selector: "attachment",
        sortable: true,
        sortFunction: sortAttachmentName,
        cell: (row) => (
          <a
            className="as-text-blue curser feature-name"
            href={`${downloadURL}${IMAGE_URL.replaceAll(
              "http:",
              "https:"
            )}/${encodeURI(row.path)}`
              .replaceAll("/home/myaie/public_html/", "")
              .replace("public/", "")}
            target="_blank"
            title={row.path.split("/").reverse()[0]}
          >
            {row?.path?.split("/").reverse()[0]
              ? row?.path?.split("/").reverse()[0]
              : "-"}
          </a>
        ),
      },
      {
        name: "Action",
        selector: "",
        cell: (row) => (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
              <button
                className="btn btn-danger rounded-circle"
                title="Delete"
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      DeleteAssessmentSubmissionUpload({
                        submission_id: row.id,
                        intake: id,
                        type: row.type,
                        submited_type: row.submited_type,
                      })
                        .then((res) => {
                          Swal.fire(
                            "Deleted!",
                            "File has been deleted.",
                            "success"
                          );
                          setupDatedata(!updatedata);
                        })
                        .catch((err) => {
                          console.log("error :", err);
                          Swal.fire(
                            "Not Deleted!",
                            "File not deleted.",
                            "error"
                          );
                        });
                    }
                  });
                }}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </div>
          </div>
        ),
      },
    ],
    [attachmentId, deleterow, updatedata]
  );

  const exportData = (fileType, fileName) => {
    const Values = {
      assessment_id: subId,
      key: "",
      sort: "DESC",
      exportStatus: "true",
      search: search,
      page: tableState.page,
      limit: tableState.limit,
    };
    getAssessmentSubmissionResultGrademix(Values)
      .then((res) => {
        const respData = res?.data?.body;
        const header = [
          "Student Name",
          "Student Number",
          "GradeMix Name",
          "GradeMix Result",
          "Submission Status",
          "Submission Date",
        ];
        const data = [];
        respData.map((item) => {
          let dataItem = {
            "Student Name": `${item.name}`,
            "Student Number": item?.student_num
              ? item?.student_num
              : item?.student_crm_id,
            "GradeMix Name": "",
            "GradeMix Result": "",
            "Submission Status": item.submission_status
              ? RenderSubmissionStatus(item.submission_status).text
              : "-",
            "Submission Date": item?.submission_date
              ? item?.submission_date
              : "-",
          };
          data.push(dataItem);
          item.grademixArr &&
            item.grademixArr.map((row, index) => {
              let subDataItem = {
                "Student Name": "",
                "Student Number": "",
                "GradeMix Name": assessmentHeader[index]?.name,
                "GradeMix Result": row?.grant_exemption
                  ? "Exempted"
                  :  row.pass_status == "N/A"
                  ? "N/A"
                  : percentageView
                  ? row?.ass_percentage + "%"
                  : row?.grading_type == "0"
                  ? row?.ass_percentage + "%"
                  : row?.grading_type == "3"
                  ? `${row?.ass_mark}/${row?.ass_total_mark}`
                  : row?.gradeText,
                "Submission Status": "",
                "Submission Date": "",
              };
              data.push(subDataItem);
            });
        });
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Student Name"],
              row["Student Number"],
              row["GradeMix Name"],
              row["GradeMix Result"],
              row["Submission Status"],
              row["Submission Date"],
            ];
          });
          const doc = new jsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => console.error(err));
  };

  const exportAttachmentData = () => {
    let data = attachmentDataToRender();
    let header;
    let pdfData;
    header = ["Attachment"];

    data = data?.map((row) => ({
      ...row,
      Attachment: row?.path.split("/").reverse()[0],
    }));
    pdfData = data.map((row) => {
      return [row.Attachment];
    });

    return {
      data,
      header,
      pdfData,
    };
  };

  const handleGetComment = (submissionID) => {
    const data = new FormData();
    data.append("assignment_id", subId);
    data.append("stdid", submissionID);
    data.append("action_type", "get_comment");
    AddAndGetTicketComment(data)
      .then((response) => {
        setComment(response.data.comment);
      })
      .catch((error) => {
        console.log("AddAndGetTicketComment error:", error);
      });
  };

  const handleGetAppealDetails = (ID) => {
    setStudentMessage("")
    ViewAppeal(ID, subId)
      .then((res) => {
        setStudentMessage(res.data.student_appeal.student_message);
        // let tempDate = startDate.setDate(startDate.getDate() + 3)
        if (
          res.data.student_appeal.appeal_status !== "Approved" &&
          res.data.student_appeal.appeal_status !== "Declined"
        ) {
          setStartDate(moment(new Date()).add(3, "days")._d);
        } else {
          setStartDate(
            moment.unix(res.data.student_appeal.ass_ext_close_date)._d
          );
        }
        setAppealsDetails(res.data);
        setDisableNeeded(
          res.data.student_appeal.ass_status == "Closed-Not Submitted"
        );
      })
      .catch((err) => console.log("err :", err));
  };

  const formik = useFormik({
    initialValues: {
      comment: comment,
    },
    validationSchema: Yup.object({
      comment: Yup.string().required("Assessment Name is required").trim(),
    }),
    onSubmit: (values) => {
      setCommentLoading(true);
      const data = new FormData();
      data.append("comment", values.comment);
      data.append("assignment_id", subId);
      data.append("stdid", submissionId);
      data.append("action_type", "save_comment");
      AddAndGetTicketComment(data)
        .then((response) => {
          setCommentLoading(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Posted comment successfully",
          }).then(() => {
            formik.resetForm();
            $("#notifications").modal("hide");
          });
        })
        .catch((error) => {
          setCommentLoading(false);
          console.log("AddAndGetTicketComment error :", error);
        });
    },
  });

  const handleSendResponse = (status, appealId) => {
    if (status === "Decline" && !studentMessage) {
      setMessageError("Message is Required");
      return;
    }
    setMessageError("");
    setAppealDisable(status);
    const tempDateObj = new Date(startDate);
    const dayOfMonth = tempDateObj.getDate();
    const month = tempDateObj.getMonth() + 1; // add 1 to month since it is 0-based
    const year = tempDateObj.getFullYear();
    const hours = tempDateObj.getHours();
    const minutes = tempDateObj.getMinutes();
    const tempDate = `${year}-${month < 10 ? "0" + month : month}-${
      dayOfMonth < 10 ? "0" + dayOfMonth : dayOfMonth
    }T${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }:00.000Z`;
    const data = new FormData();
    data.append("student_id", studentId);
    data.append("student_message", studentMessage ? studentMessage : "");
    data.append("assessment_id", subId);
    data.append("assessment_close_date", tempDate);
    data.append("status", status);
    data.append("gradebookType", 0);
    data.append("appealOutcome", appealsDetails?.student_appeal?.appeal_outcome);
    ApproveOrDeclineAppeal(data)
      .then((res) => {
        handleGetAppealDetails(appealId);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: res.data.message,
        });
        setAppealDisable("");
        setupDatedata(!updatedata)
        setStudentMessage("")
        $("#notifications1").modal("hide");
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err.response.data.message,
        });
        setStudentMessage("")
        $("#notifications1").modal("hide");
        console.log("error : ", err);
        setAppealDisable("");
      });
  };

  // mycode start
  // const handlegradeChange = (e, idvalue, type, maxMark, item) => {
  //   console.log(idvalue,"item.grading_type")
  //   let entervalue = "";
  //   setGearid(idvalue);
  //   if (type === "inputbox") {
  //     if (item.grading_type == 0 && e.target.value > 100) {
  //       setErrorMessage("Sorry you can't put mark more then 100%");
  //       return false;
  //     } else if ((e.target.value > maxMark) && (item.grading_type == 3)) {
  //       setErrorMessage("Sorry you can't put mark more then assignment mark");
  //       return false;
  //     } else {
  //       entervalue = e.target.value;
  //     }
  //   } else if (type === "selectbox") {
  //     entervalue = e?.target?.value;
  //   }

  //   let formData = new FormData();

  //   if (type === "inputbox") {
  //     formData.set("obtMark", entervalue);
  //     formData.set("id", idvalue);
  //     formData.set("ass_total_mark", maxMark);
  //     formData.set(
  //       "custome_marking_deadline_date",
  //       headerData.marking_deadline_date
  //     );
  //     formData.set("assgrade", item.grading_type);
  //     formData.set("assessmentID", subId);
  //     formData.set("grademix_id", item?.grade_id);
  //   } else {
  //     if (item.grading_type == 1) {
  //       if (entervalue === "0") {
  //         formData.set("obtMark", 0);
  //         formData.set("id", idvalue);
  //         formData.set("ass_total_mark", maxMark);
  //         formData.set("grade", "robot");
  //         formData.set("assessmentID", subId);
  //         formData.set("grademix_id", item?.grade_id);
  //       } else if (entervalue === "49") {
  //         formData.set("obtMark", 49);
  //         formData.set("id", idvalue);
  //         formData.set("ass_total_mark", maxMark);
  //         formData.set("grade", "robot");
  //         formData.set("assessmentID", subId);
  //         formData.set("grademix_id", item?.grade_id);
  //       } else if (entervalue === "100") {
  //         formData.set("obtMark", 100);
  //         formData.set("id", idvalue);
  //         formData.set("ass_total_mark", maxMark);
  //         formData.set("grade", "robot");
  //         formData.set("assessmentID", subId);
  //         formData.set("grademix_id", item?.grade_id);
  //       } else {
  //         formData.set("obtMark", "");
  //         formData.set("id", idvalue);
  //         formData.set("ass_total_mark", maxMark);
  //         formData.set("grade", "robot");
  //         formData.set("assessmentID", subId);
  //         formData.set("grademix_id", item?.grade_id);
  //       }
  //     } else if (item.grading_type == 2) {
  //       formData.set("id", idvalue);
  //       formData.set("ass_total_mark", maxMark);
  //       formData.set("grade", "letter");
  //       formData.set("grade_id", entervalue);
  //       formData.set("assessmentID", subId);
  //       formData.set("grademix_id", item?.grade_id);
  //     }
  //   }
  //   setLoading(true);
  //   UpDateMarkssubmit(formData)
  //     .then((res) => {
  //       // Swal.fire({
  //       //   icon: "success",
  //       //   title: "Success",
  //       //   text: "Updated successfully",4
  //       // });
  //       setErrorMessage("");
  //       setupDatedata(!updatedata);
  //     })
  //     .catch((err) => {
  //       setLoading(true);
  //       Swal.fire({
  //         icon: "error",
  //         title: "Error",
  //         text: `${err?.response?.data?.data}`,
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const setNotificationvalue = (e) => {
    const check = e.target.checked;
    check === true ? setNotificationVal(true) : setNotificationVal(false);
  };

  const submitNotification = (e, type) => {
    const data = new FormData();
    data.append("assignmentid", subId);
    data.append("intake", id);
    data.append("act", type == "reOpen" ? 0 : 1);
    data.append("notifyToAll", notificationval);
    GetMarkasCompelete(data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "successfully",
        });
        setNotificationVal(false);
        setupDatedata(!updatedata);
        $("#exampleModal2").modal("hide");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error",
        });
      });
  };

  function calculateFileSize(data, maxSizePerArray) {
    let totalSize = 0;
    let urlArray = [];
    let checkTotal = 0;
    let urlArrays = [[]]; // Start with one empty array
    let currentArrayIndex = 0;
    let currentArraySize = 0;

    function addToCurrentArray(path, size, fileName, currentSize) {
      const urlInfo = {
        path: path,
        size: size,
        loaded_Size: 0,
        flname: fileName,
      };
      urlArrays[currentArrayIndex].push(urlInfo);
      urlArray.push(urlInfo);
      currentArraySize += currentSize;
    }

    for (const item of data) {
      if (currentArraySize > maxSizePerArray) {
        currentArrayIndex++;
        urlArrays.push([]);
        currentArraySize = 0;
      }

      if (item.multipleFiles && item.multipleFiles.length) {
        for (const file of item.multipleFiles) {
          checkTotal++;
          const size = parseFloat(file.size);
          if (!isNaN(size)) {
            totalSize += size;
            if (currentArraySize + size <= maxSizePerArray) {
              addToCurrentArray(file.path, file.size, item.flname, size);
            } else {
              currentArrayIndex++;
              urlArrays.push([]);
              currentArraySize = 0;
              addToCurrentArray(file.path, file.size, item.flname, size);
            }
            urlArray.push({
              path: file.path,
              size: file.size,
              loaded_Size: 0,
              flname: item.flname,
            });
          }
        }
      } else {
        if (item.path && item.path.trim() !== "") {
          checkTotal++;
          const size = parseFloat(item.size);
          if (!isNaN(size)) {
            totalSize += size;
            if (currentArraySize + size <= maxSizePerArray) {
              addToCurrentArray(item.path, item.size, item.flname, size);
            } else {
              currentArrayIndex++;
              urlArrays.push([]);
              currentArraySize = 0;
              addToCurrentArray(item.path, item.size, item.flname, size);
            }
            urlArray.push({
              path: item.path,
              size: item.size,
              loaded_Size: 0,
              flname: item.flname,
            });
          }
        }
        if (item.return && item.return.trim() !== "") {
          checkTotal++;
          const size = parseFloat(item.return_size);
          if (!isNaN(size)) {
            totalSize += size;
            if (currentArraySize + size <= maxSizePerArray) {
              addToCurrentArray(
                item.return,
                item.return_size,
                item.flname,
                size
              );
            } else {
              currentArrayIndex++;
              urlArrays.push([]);
              currentArraySize = 0;
              addToCurrentArray(
                item.return,
                item.return_size,
                item.flname,
                size
              );
            }
            urlArray.push({
              path: item.return,
              size: item.return_size,
              loaded_Size: 0,
              flname: item.flname,
            });
          }
        }
      }

      if (item.quizSubmitedDocs && item.quizSubmitedDocs.length) {
        for (const file of item.quizSubmitedDocs) {
          checkTotal++;
          const size = 0;
          if (!isNaN(size)) {
            urlArrays[currentArrayIndex].push({
              path: file.docs,
              size: 0,
              loaded_Size: 0,
              flname: item.flname,
            });
            urlArray.push({
              path: file.docs,
              size: 0,
              loaded_Size: 0,
              flname: item.flname,
            });
          }
        }
      }
    }

    return {
      totalSize,
      urlArray,
      checkTotal,
      urlArrays,
    };
  }

  const MAX_CONCURRENT_DOWNLOADS = 5; // Limit the number of parallel downloads

  const handleDownloadAssessment = async () => {
    try {
      let data = downloadZip;
      let demoData = downloadZip;
      const header = [
        "ROW",
        "SID",
        "CID",
        "STUDENT",
        "SURNAME",
        "ASSIGNMENT",
        "ASSESSMENT NAME",
        "HIGHEST MARK ACHIEVABLE",
        "MARKED ACHIEVED",
      ];

      data = data?.map((row) => ({
        ...row,
        ROW: row?.id ? row?.id : "",
        CID: row.intake ? row.intake : "",
        SID: row?.student ? row?.student : "",
        STUDENT: row?.flname ? row?.flname : "",
        SURNAME: row?.lname ? row?.lname : "",
        ASSIGNMENT: row?.crm_id ? row?.crm_id : "",
        // TOTALMARK: row?.assignment ? row?.assignment : "-",
        "ASSESSMENT NAME": zipfilename ? zipfilename.trim() : "",
        "HIGHEST MARK ACHIEVABLE":
          row.ass_total_mark >= 0 ? row.ass_total_mark : "",
        // ATTACHMENT: row?.ass_weighting.toString() ? row?.ass_weighting.toString() == "0" ? "0" : row?.ass_weighting : "-",
        // "MARKED ACHIEVED": row.ass_mark ?  row.ass_mark : "-",
        // REMARK: row.path || row.return ? `${row.path ? row.path : ""} ${row.return ? row.return : ""}` : "",
        "MARKED ACHIEVED":
          row.ass_mark != null && row.ass_mark.toString() && row?.grade != 1
            ? row.ass_mark
            : row?.mark != null &&
              row?.mark.toString() &&
              row?.grade == 1 &&
              row?.mark.toString().replace(/%\s?/g, "") >= 49
            ? "Green"
            : row?.mark != null &&
              row?.mark.toString() &&
              row?.grade == 1 &&
              row?.mark.toString().replace(/%\s?/g, "") <= 49 &&
              row?.mark.toString() &&
              row?.grade == 1 &&
              row?.mark.toString().replace(/%\s?/g, "") >= 0
            ? "Yellow"
            : row?.mark != null &&
              row?.mark.toString() &&
              row?.grade == 1 &&
              row?.mark.toString().replace(/%\s?/g, "") <= 0
            ? "Red"
            : "",
      }));

      let pending = 0;
      const maxSizePerArray = 1536 * 1024 * 1024;
      let fileDetails = calculateFileSize(demoData, maxSizePerArray);
      setFinalTotal(fileDetails.totalSize.toFixed(1));
      let loadedArr = JSON.parse(JSON.stringify(fileDetails.urlArrays));

      let zipPromises = [];
      for (
        let i = 0;
        i < fileDetails.urlArrays.length;
        i += MAX_CONCURRENT_DOWNLOADS
      ) {
        const batch = fileDetails.urlArrays.slice(
          i,
          i + MAX_CONCURRENT_DOWNLOADS
        );
        const batchPromises = batch.map(async (urlArray, index) => {
          let zip = new JSZip();
          let sizeArr = Array(urlArray.length).fill(0);
          let loadedBytes = 0;

          let promise = Promise.all(
            urlArray.map(async (row, rowIndex) => {
              const fileExt = row.path
                .split("/")
                .reverse()[0]
                .trim()
                .replace(/[\t\r\n]/g, "");
              let fileURL =
                downloadURL +
                `${
                  row.path.includes("s3.af-south-1.amazonaws.com")
                    ? ""
                    : IMAGE_URL.replaceAll("http:", "https:") + "/"
                }${row.path
                  .replaceAll("/home/myaie/public_html/", "")
                  .replace("public/", "")}`;
              if (isHybrid === true) {
                fileURL =
                  downloadURL +
                  STUDENT_FILE_DOWNLOAD +
                  `/${row.path
                    .replaceAll("/home/myaie/public_html/", "")
                    .replace("public/", "")}`;
              }
              let loadedFromArr = 0;
              try {
                const resp = await axios({
                  url: fileURL,
                  method: "get",
                  responseType: "blob",
                  onDownloadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    loadedArr[index][rowIndex] = loaded;
                    loadedFromArr = 0;
                    loadedArr.map((item) =>
                      item.map((subItem) => {
                        if (typeof subItem === "number") {
                          loadedFromArr = loadedFromArr + subItem;
                        }
                      })
                    );
                    loadedBytes = loaded;
                    if (fileDetails.totalSize > loadedFromArr) {
                      setFinalLoadedData(loadedFromArr);
                    } else {
                      setFinalLoadedData(fileDetails.totalSize);
                    }
                  },
                });
                zip
                  .folder(
                    `${
                      row.flname +
                      "_" +
                      zipfilename.trim().replace(/[\t\r\n]/g, "")
                    }`
                  )
                  .file(`${fileExt}`, resp.data, { binary: true });
              } catch (err) {
                console.log(err, "zip generation error");
              }
            })
          );

          promise = promise.then(() => {
            const textFileData = data.filter((item) => item.dataAns);
            if (textFileData.length) {
              textFileData.map((row) => {
                let fileContent = "";
                if (Array.isArray(row.dataAns) && row.dataAns.length) {
                  row.dataAns.map((element) => {
                    fileContent +=
                      "Question : " +
                      element.questionName +
                      "Answer : " +
                      element.UserAnswer +
                      "\n" +
                      "\n";
                  });
                }
                if (fileContent != "") {
                  zip
                    .folder(
                      `${
                        row.flname +
                        "_" +
                        zipfilename.trim().replace(/[\t\r\n]/g, "")
                      }`
                    )
                    .file("question.txt", fileContent);
                }
              });
            }
            const csvString = Papa.unparse({ fields: header, data });
            zip.file(`${zipfilename + "_" + index + ".csv"}`, csvString);
            return zip.generateAsync({ type: "blob" });
          });

          try {
            const zipBlob = await promise;
            const zipFileName = `${zipfilename}_${
              i / MAX_CONCURRENT_DOWNLOADS + index + 1
            }_.zip`;
            saveAs(zipBlob, zipFileName);
          } catch (err) {
            console.error("Error generating or downloading zip files:", err);
          }
        });
        zipPromises.push(Promise.all(batchPromises));
      }

      await Promise.all(zipPromises);
    } catch (error) {
      console.error("Error generating or downloading zip files:", error);
    } finally {
      // Cleanup after download completes, if necessary
      // ... (your code for cleanup, if any)

      setTimeout(() => {
        $("#downloadAssessment").modal("hide");
        setFinalLoadedData(0);
        setFinalTotal(0);
      }, 1000);
    }
  };

  const handleStudentDownload = async (rowData, st_data) => {
    var zip = new JSZip();
    var zipVal = {};
    let totalSize = 0;
    let data = rowData;
    if (Array.isArray(data) && data.length) {
      let sizeArr = Array(data.length).fill(0);
      let myPromise = Promise.all(
        data.map(async (row, index) => {
          if (row.path && row.path != null && row.path != "") {
            const size = parseFloat(row?.size);
            if (!isNaN(size)) {
              totalSize += size;
              setFinalTotal(totalSize.toFixed(1));
            }
            const fileExt = row.path.split("/").reverse()[0];
            const fileURL =
              downloadURL +
              `${
                row.path.includes("s3.af-south-1.amazonaws.com")
                  ? ""
                  : IMAGE_URL.replaceAll("http:", "https:") + "/"
              }${encodeURI(
                row.path
                  .replaceAll("/home/myaie/public_html/", "")
                  .replace("public/", "")
              )}`;
            let loadedFromArr = 0;
            try {
              const resp = await axios({
                url: fileURL,
                method: "get",
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                  const { loaded, total } = progressEvent;
                  sizeArr[index] = loaded;
                  loadedFromArr = 0;
                  sizeArr.map((item) => (loadedFromArr = loadedFromArr + item));
                  if (totalSize > loadedFromArr) {
                    setFinalLoadedData(loadedFromArr);
                  } else {
                    setFinalLoadedData(totalSize);
                  }
                },
              });

              zip.file(`${fileExt}`, resp.data, { binary: true });
            } catch (err) {}
          }
          zipVal = zip;
        })
      );

      myPromise.then(() => {
        zipVal
          .generateAsync({ type: "blob" })
          .then(function (content) {
            setTimeout(() => {
              $("#downloadAssessment").modal("hide");
              setFinalLoadedData(0);
              setFinalTotal(0);
            }, 1000);
            saveAs(
              content,
              `${assName}_${st_data.name}_${st_data.student_crm_id}` + ".zip"
            );
          })
          .catch((errr) => {
            console.log(errr);
          });
      });
    } else {
      if (data && data != null && data != "") {
        setcheckAssignment("true");
        const fileExt = data.path.split("/").reverse()[0];
        const fileURL =
          // downloadURL +
          `${
            data.path.includes("s3.af-south-1.amazonaws.com")
              ? ""
              : IMAGE_URL.replaceAll("http:", "https:") + "/"
          }${encodeURI(
            data.path
              .replaceAll("/home/myaie/public_html/", "")
              .replace("public/", "")
          )}`;
        try {
          const resp = await axios({
            url: fileURL,
            method: "get",
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const size = parseFloat(data?.size);
              if (!isNaN(size)) {
                totalSize = size;
              }
              setFinalTotal(totalSize.toFixed(1));
              if (totalSize > loaded) {
                setFinalLoadedData(loaded);
              } else {
                setFinalLoadedData(totalSize);
              }
            },
          });
          const href = URL.createObjectURL(resp.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", fileExt); //or any other extension
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            $("#downloadAssessment").modal("hide");
            setFinalLoadedData(0);
            setFinalTotal(0);
          }, 1000);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const submitZipForm = () => {
    const data = new FormData();
    data.append("import", zipfileupload[0].file);
    setImportLoading(true);
    GetimportMarks(data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "successfully",
        });
        setZipFileUpload("");
        setImportLoading(false);
        $("#inputfileupload").val("");
        $("#exampleModalCenter").modal("hide");
      })
      .catch((error) => {
        console.log("zipupload error", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error",
        });
        setImportLoading(false);
      });
  };
  // mycode end

  const userID = useSelector((state) => state.user.UserID);

  const sendInstruction = () => {
    setInstructionLoading(true);
    const sendData = {
      assessment_marker: headerData.assessment_marker,
      sender: userID,
      assignmentid: headerData.assessment_id,
      close_date_time: headerData.close_date_time,
      marking_deadline_date: headerData.marking_deadline_date,
      intake: headerData.intake,
    };
    SendAssignmentInstruction(sendData)
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Instructions sent successfully",
          });
          setInstructionLoading(false);
        }
      })
      .catch((err) => {
        setInstructionLoading(false);
        console.log(err);
      });
  };

  const handleDownloadResults = () => {
    setDownloading(true);
    const parameter = { assignment: subId };
    DownloadAssessmentsStudentResults(parameter)
      .then((res) => {
        if (res.status === 200) {
          const respData = res.data.data;
          const header = [
            "Student Number",
            "Student Name",
            "Final Mark",
            "Question Name",
            "Question",
            "Student Answer",
            "Correct Answer",
            "Mark",
            "Result",
          ];

          const formatData = [];

          respData.map((data) => {
            let dataItem = {
              "Student Number": data.student_crm_id,
              "Student Name": `${data.first_name} ${data.last_name}`,
              "Final Mark": `${data.ass_mark}/${data.ass_total_mark}`,
              "Question Name": "",
              Question: "",
              "Student Answer": "",
              "Correct Answer": "",
              Mark: "",
              Result: "",
            };
            formatData.push(dataItem);
            data.result &&
              data.result.dataAns &&
              data.result.dataAns.map((quesData, index) => {
                if (
                  quesData.questionType != "Document Upload" &&
                  quesData.questionType != "Long Answer"
                ) {
                  let subDataItem = {
                    "Student Number": "",
                    "Student Name": "",
                    "Final Mark": "",
                    "Question Name":
                      quesData.question_name.length < 32000
                        ? quesData.question_name
                        : "Data length is too large",
                    Question: quesData.question
                      ? convertToPlain(quesData.question).length < 32000
                        ? convertToPlain(quesData.question)
                        : "Data length is too large"
                      : "NA",
                    "Student Answer":
                      convertToPlain(quesData.UserAnswer).length < 32000
                        ? convertToPlain(quesData.UserAnswer)
                        : "Data length is too large",
                    "Correct Answer":
                      convertToPlain(quesData.correctAnswer).length < 32000
                        ? convertToPlain(quesData.correctAnswer)
                        : "Data length is too large",
                    Mark: `${Math.round(
                      data.result.scoreArray[index]
                    )}/${Math.round(
                      data.result.total_score_individual[index]
                    )}`,
                    Result: (
                      data.result?.total_score_individual
                        ? data.result?.total_score_individual[index] == 0
                        : data.result?.scoreArray[index] == 0
                    )
                      ? RenderQuizResult("0").text
                      : data.result?.scoreArray[index] == 0
                      ? RenderQuizResult("0").text
                      : (quesData.questionType ==
                          "Multiple Choice Multiple Answer" ||
                          quesData.questionType == "Match the Column") &&
                        (data.result?.total_score_individual
                          ? data.result.scoreArray[index] /
                              data.result?.total_score_individual[index] !=
                            0
                          : data.result.scoreArray[index] /
                              data.result.scoreArray[index] !=
                            0)
                      ? RenderQuizResult(
                          (
                            data.result.scoreArray[index] /
                            data.result?.total_score_individual[index]
                          ).toString()
                        ).text
                      : parseInt(data.result.scoreArray[index]) ==
                        (data.result?.total_score_individual[index]
                          ? data.result?.total_score_individual[index]
                          : data.result.scoreArray[index])
                      ? RenderQuizResult("1").text
                      : RenderQuizResult("0").text,
                  };
                  formatData.push(subDataItem);
                }
              });
          });
          const compatibleData = formatData.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `Smart Assessment Results.xlsx`);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  // Grademix Start
  useEffect(() => {
    setLoadingGrademix((prevState) => ({ ...prevState, heading: true }));
    getGrademixListViaAssessment(subId)
      .then((res) => {
        setAssessmentHeader(res.data?.getGrademix);
        setLoadingGrademix((prevState) => ({ ...prevState, heading: false }));
      })
      .catch((err) => {
        console.error("error", err);
        setLoadingGrademix((prevState) => ({ ...prevState, heading: false }));
      });
  }, []);

  useEffect(() => {
    let timeOut = setTimeout(() => {
      setSearch(debouncedTerm);
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [debouncedTerm]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  const resetFilter = () => {
    setDebouncedTerm("");
  };

  const exportXlsxData = async (fileName) => {
    try {
      const Values = {
        assessment_id: subId,
        key: "",
        sort: "DESC",
        exportStatus: "false",
        search: search,
        page: tableState.page,
        limit: tableState.limit,
      };
      const res = await getAssessmentSubmissionResultGrademix(Values);
      const respData = res?.data?.body;
      let learning_method = res?.data?.getCourse?.type;
      const header = [
        "Name",
        "Surname",
        "ID Number",
        "Student Number",
        "Submission Date",
        ...assessmentHeader.map((assessment) => assessment?.name),
        "Submission Status",
      ];

      const data = respData.flatMap((item) => {
        const rowData = [
          item.name.split(" ")[0],
          item.name.split(" ")[1],
          item?.student_crm_id ? item?.student_crm_id : " ",
          item?.student_num ? item?.student_num : " ",
          item?.submission_date ? item?.submission_date : " ",
          ...assessmentHeader.map((assessment) => {
            const assessmentData = item.grademixArr?.find(
              (row) => row.grade_id === assessment.id
            );
            if (assessmentData) {
              return assessmentData?.grant_exemption
                ? "Exempted"
                : assessmentData.pass_status == "N/A"
                ? "N/A"
                : percentageView
                ? assessmentData?.ass_percentage + "%"
                : assessmentData?.grading_type == "0"
                ? assessmentData?.ass_percentage + "%"
                : assessmentData?.grading_type == "3"
                ? `${assessmentData?.ass_mark}/${assessmentData?.ass_total_mark}`
                : assessmentData?.gradeText;
            }
            return "";
          }),
          item.submission_status
            ? RenderSubmissionStatus(item.submission_status).text
            : " ",
        ];
        return [rowData];
      });

      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet("Excel Data");

      // const courseNameColumn = ws.getColumn(1);
      // courseNameColumn.width = courseName.length + 2;

      // Set border for the entire worksheet
      ws.views = [
        {
          state: "frozen",
          ySplit: 1,
          xSplit: 1,
        },
      ];
      ws.properties.showGridLines = true;

      const columnStyles = [];
      header.forEach((col) => {
        const style = {};
        if (
          [
            "Name",
            "Surname",
            "ID Number",
            "Student Number",
            "Submission Date",
          ].includes(col)
        ) {
          style.font = { bold: true };
          style.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFDBDBDB" }, // Light gray background color
          };
          style.border = {
            top: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
            right: { style: "thin", color: { argb: "FF000000" } },
          };
        } else if (col === "Submission Status") {
          style.font = { color: { argb: "FFFFFF" } };
          style.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF7030A0" }, // Purple background color
          };
          style.border = {
            top: { style: "thin", color: { argb: "FF000000" } }, // Black top border
            bottom: { style: "thin", color: { argb: "FF000000" } }, // Black bottom border
            left: { style: "thin", color: { argb: "FF000000" } }, // Black left border
            right: { style: "thin", color: { argb: "FF000000" } }, // Black right border
          };
        } else {
          style.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFDBDBDB" }, // Light gray background color
          };
          style.border = {
            top: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
            right: { style: "thin", color: { argb: "FF000000" } },
          };
        }
        columnStyles.push(style);
      });
      const staticRow = ws.addRow([fileName, "", "", "", ""]);
      staticRow.height = 16;
      staticRow.getCell(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF66CCFF" },
      };
      staticRow.getCell(1).font = { bold: true };
      staticRow.getCell(1).alignment = {
        horizontal: "center",
        vertical: "bottom",
      };
      staticRow.getCell(1).border = {};
      staticRow.getCell(5).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF203764" },
      };

      ws.mergeCells(`A${staticRow.number}:D${staticRow.number}`);

      // for (let colIndex = 3; colIndex <= header.length; colIndex++) {
      //   const cell = staticRow.getCell(colIndex);
      //   cell.value = '';
      //   cell.fill = { type: 'pattern', pattern: 'none' }; // Clear fill color
      //   cell.border = {}; // Clear border
      // }

      const headerRow = ws.addRow(header);
      headerRow.eachCell((cell, colNumber) => {
        if (columnStyles[colNumber - 1].font) {
          cell.font = columnStyles[colNumber - 1].font;
        }
        cell.fill = columnStyles[colNumber - 1].fill;
        cell.border = columnStyles[colNumber - 1].border;
      });

      data.forEach((row) => {
        const dataRow = ws.addRow(row);
        dataRow.eachCell((cell, colNumber) => {
          cell.border = columnStyles[colNumber - 1].border;
        });
      });

      const buffer = await wb.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.click();

      Swal.close();
    } catch (err) {
      console.log(err);
    }
  };

  const slider = document.querySelector(".table-book-mid");

  useEffect(() => {
    if (slider) {
      let mouseDown = false;
      let startX, scrollLeft;

      let startDragging = function (e) {
        mouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      };
      let stopDragging = function (event) {
        mouseDown = false;
      };

      slider.addEventListener("mousemove", (e) => {
        e.preventDefault();
        if (!mouseDown) {
          return;
        }
        const x = e.pageX - slider.offsetLeft;
        const scroll = x - startX;
        slider.scrollLeft = scrollLeft - scroll;
      });

      // Add the event listeners
      slider.addEventListener("mousedown", startDragging, false);
      slider.addEventListener("mouseup", stopDragging, false);
      slider.addEventListener("mouseleave", stopDragging, false);
    }
  }, [slider]);

  const handleResize = () => {
    let pageWidth = document.querySelector(
      ".my-tickets-info-list"
    )?.offsetWidth;
    setTableWidth(pageWidth);
  };

  useEffect(() => {
    var resizeRequested = false;
    handleResize();
    window.addEventListener(
      "resize",
      function () {
        if (!resizeRequested) {
          resizeRequested = true;
          window.requestAnimationFrame(function () {
            setTableWidth(
              document.querySelector(".my-tickets-info-list")?.clientWidth
            );
            resizeRequested = false;
          });
        }
      },
      100
    );
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleWidth = () => {
    const leftTableWidth =
      document.querySelector(".table-book-left").clientWidth;
    const midTableWidth = document.querySelector(".grade-acc-in").clientWidth;
    const rightTableWidth =
      document.querySelector(".table-book-right").clientWidth;
    let maxAllowedWidth = tableWidth - leftTableWidth - rightTableWidth;
    let lastTableClass =
      document.querySelector(".table-book-right").firstChild.className;
    let firstTableClass =
      document.querySelector(".table-book-left").firstChild.className;
    if (maxAllowedWidth < midTableWidth) {
      document.querySelector(".table-book-mid").style.overflowY = "auto";
      document.querySelector(".table-book-right").style.maxWidth =
        "fit-content";
      if (!lastTableClass.includes("border-shadow-custom-table")) {
        document.querySelector(
          ".table-book-right"
        ).firstChild.className = `${lastTableClass} border-shadow-custom-table`;
      }
      if (!firstTableClass.includes("border-shadow-custom-table")) {
        document.querySelector(
          ".table-book-left"
        ).firstChild.className = `${firstTableClass} border-shadow-custom-table`;
      }
    } else {
      document.querySelector(".table-book-mid").style.overflowY = "unset";
      document.querySelector(".table-book-right").style.maxWidth = "unset";
      document.querySelector(".table-book-right").firstChild.className =
        lastTableClass.replaceAll("border-shadow-custom-table", "");
      document.querySelector(".table-book-left").firstChild.className =
        firstTableClass.replaceAll("border-shadow-custom-table", "");
    }
  };
  let firstLoaded = true;
  useEffect(() => {
    if (userData?.length && tableWidth) {
      if (firstLoaded) {
        setTimeout(() => {
          handleWidth();
          firstLoaded = false;
        }, 500);
      } else {
        handleWidth();
      }
    }
  }, [userData, tableWidth]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (shouldBlockNavigation) {
        event.preventDefault();
        // event.returnValue = ''; // This is necessary for Chrome/Firefox
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldBlockNavigation]);

  const handleChangeAppealOutcome = (outcome) =>{
    const appealData = {...appealsDetails};
    appealData.student_appeal.appeal_outcome = outcome;
    setAppealsDetails({...appealData})
  }

  const handleUpdateMarks = (e, idvalue, type, maxMark, item) => {
    let entervalue = e.target.value
    if (entervalue === oldValue) {
      // setOldValue();
      return;
    }
    
    if (entervalue > 100 && item.grading_type == "0") {
      setErrorMessage("Sorry you can't put mark more than 100%");
      return;
    } else if (item.grading_type == "3" && entervalue > maxMark) {
      setErrorMessage("Sorry you can't put mark more then assignment mark");
      return;
    }
    setErrorMessage("");

    // for showing loading effect update below state
    let updateArray = [...updateValueArr];

    const existingIndex = updateArray.findIndex(
      (grade) => grade.grademix_id === item?.grade_id && grade.student_id === idvalue
    );

    if (existingIndex !== -1) {
      updateArray[existingIndex] = {
        ...updateArray[existingIndex],
        student_id: idvalue,
        grade_type: item.grading_type,
        grade: entervalue ? entervalue : "",
      };
    } else {
      updateArray.push({
        student_id: idvalue,
        grademix_id: item?.grade_id,
        grade_type: item.grading_type,
        grade: entervalue ? entervalue : "",
      });
    }

    // let tempArr = userData;
    // let isdisabledcheck = tempArr.map((item) => {
    //   if (item.student_id === stdId) {
    //     return { ...item, isDis: false };
    //   }
    //   return { ...item };
    // });
    // setUserData(isdisabledcheck);
    setUpdateValueArr([...updateArray]);
  };

  const handleMouseUp = (e) => {
    setMousePressed(false); // Mouse button released
    if (!mousePressed) {
      setOldValue(e.target.value);
    }
  };

  const handleMouseDown = () => {
    setMousePressed(true); // Mouse button pressed
  };

  const handleNavigation = (location, page, states) => {
    if (shouldBlockNavigation) {
      if (Swal.isVisible()) {
        return false;
      }
      Swal.fire({
        title: "Warning",
        text: "You Have Unsaved Changes Left! Do you want to save changes",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, Leave",
        confirmButtonText: "Yes, Save Changes",
      }).then((value) => {
        if (value.isConfirmed) {
          Swal.fire({
            title: "Please wait",
            text: "Saving changes...",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
              const values = {
                type: 1,
                assessmnet_id: subId,
                grademix: updateValueArr,
              };
              updateGrademixMarkSubmission(values)
                .then((res) => {
                  setUpdateValueArr([]);
                  Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Updated Successfully",
                  }).then(() => {
                    if (location) {
                      // window.location.assign(location.pathname); // Redirect to the desired location
                      history.push(location.pathname)
                    } else if (!location && page) {
                      if (page == "exempted") {
                        // handleExemption(...states)
                      } else {
                        setTableState((prevState) => ({
                          ...prevState,
                          page:
                            page === "firstPage"
                              ? 1
                              : page === "prevPage"
                                ? paginationState.currentPage - 1
                                : page === "nextPage"
                                  ? +paginationState.currentPage + 1
                                  : paginationState.lastPage,
                        }));
                      }
                    }
                  });
                })
                .catch((err) => {
                  Swal.close(); // Close the "Saving changes..." popup if there was an error
                  Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${err?.response?.data?.data}`,
                  });
                });
            },
          });
        } else {
          setUpdateValueArr([]);
          if (location) {
            // window.location.assign(location.pathname); // Redirect to the desired location
            history.push(location.pathname)
          } else if (!location && page) {
            if (page == "exempted") {
              // handleExemption(...states)
            } else {
              setTableState((prevState) => ({
                ...prevState,
                page:
                  page === "firstPage"
                    ? 1
                    : page === "prevPage"
                      ? paginationState.currentPage - 1
                      : page === "nextPage"
                        ? +paginationState.currentPage + 1
                        : paginationState.lastPage,
              }));
            }
          }
        }
      });

      return false; // Prevent the default navigation behavior
    } else if (page == "exempted") {
      // handleExemption(...states)
    }
  };

  const handleSaveGrades = (studentID) => {
    Swal.fire({
      title: "Updating",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    setSaveGradesLoading(true);
    const values = {
      type: 1,
      assessmnet_id: subId,
      grademix: updateValueArr,
    };
    if (!studentID) {
      delete values.student_id;
    }
    updateGrademixMarkSubmission(values)
      .then((res) => {
        setSaveGradesLoading(false);
        if (studentID) {
          const filteredArr = updateValueArr.filter(
            (item) => item.student_id !== studentID
          );
          setUpdateValueArr(filteredArr);
        } else {
          setUpdateValueArr([]);
        }
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated Successfully",
        }).then((res) => setupDatedata(!updatedata));
      })
      .catch((err) => {
        setSaveGradesLoading(false);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      });
  };


const stdIds = [];

  const handleSaveSingleGrade = (stdId) => {
    let otherIds = stdIds.filter((item) => item !== stdId);
    if (otherIds.length) {
      Swal.fire({
        title: "Are You Sure?",
        text: "You have unsaved data in other students",
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        html: `
          <div class="custom-swal-button">
            <p>You have unsaved data in other students!</p>
            <button class="swal2-confirm swal2-styled" onclick='triggerSwall({type: "saveAll", updatedArr: ${JSON.stringify(
          updateValueArr
        )}})' >Save All</button>
            <button class="swal2-confirm swal2-styled" onclick='triggerSwall({type: "save", stdId: ${stdId}, updatedArr: ${JSON.stringify(
          updateValueArr
        )}})'>Save</button>
            <button class="swal2-cancel swal2-styled" onclick="triggerSwall({type: 'cancel'})" style="background-color: rgb(221, 51, 51);">Cancel</button>
          </div>`,
      });
    } else {
      handleSaveGrades(stdId, updateValueArr);
    }
  };

  const checkbtnValue = (values) => {
    if (values.type === "saveAll") {
      handleSaveGrades(undefined, values.updatedArr);
    } else if (values.type === "save") {
      handleSaveGrades(values.stdId, values.updatedArr);
    }
    Swal.close();
  };

  return (
    <>
     <Prompt when={!!shouldBlockNavigation} message={handleNavigation} />
      <div className="card-new card card-profile-info-card mb-30">
        <div className="">
          <div className="card-header">
            Marking Management{" "}
            {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
          </div>
          <div className="card-body-inr card-body-info">
            <div className="basic-info-box">
              <div className="row">
                {headerData && headerData.quiz_id && isHybrid == false ? (
                  ""
                ) : (
                  <div className="col-md-6 col-lg-4">
                    <div className="basic-info-box-list">
                      <ul>
                        <li>
                          <span className="title">Mark Status :</span>
                          <span>
                            {headerData ? (
                              headerData.mark_status == "Past Due" ? (
                                <span style={{ color: "#FF0000" }}>
                                  {" "}
                                  &nbsp;Past Due
                                </span>
                              ) : (
                                headerData.mark_status
                              )
                            ) : (
                              "-"
                            )}
                            <span>
                              &nbsp;
                              <button
                                onClick={() => {
                                  sendInstruction();
                                }}
                                type="button"
                                className="btn-save btn btn-primary"
                              >
                                {instructionLoading ? (
                                  <i className="fas fa-cog fa-spin"></i>
                                ) : (
                                  ""
                                )}{" "}
                                Send Instruction
                              </button>
                            </span>
                          </span>
                        </li>
                        <li>
                          <span className="title ">Marking deadline :</span>
                          {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                          <span>
                            {headerData && headerData.MarkingDeadLine
                              ? headerData.MarkingDeadLine
                              : "-"}
                          </span>
                          {/* </Link> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li style={{ color: "#FF0000" }}>
                        <span className="title">Submissions :</span>
                        <span>
                          &nbsp;
                          {headerData && headerData.submissions
                            ? headerData.submissions
                            : "-"}
                        </span>
                      </li>
                      <li style={{ color: "#FF0000" }}>
                        <span className="title">Submission rate :</span>
                        <span>
                          &nbsp;
                          {headerData && headerData.submissions_rate
                            ? headerData.submissions_rate
                            : "-"}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li>
                        <span className="title">Appeals:</span>
                        <span>
                          {headerData &&
                          headerData.TOTAL_APPEALES &&
                          headerData.TOTAL_APPEALES.toString()
                            ? headerData.TOTAL_APPEALES
                            : "-"}
                        </span>
                      </li>
                      {/* Hidden only on Grade mix */}
                      {/* <li>
                        <span className="title">Average Mark:</span>
                        <span>
                          {headerData &&
                          headerData.AvgMarks &&
                          headerData.AvgMarks.toString()
                            ? headerData.AvgMarks
                            : "-"}
                        </span>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* {/ button section start /} */}
          <div className="button-reset dropdown-comman">
            {!(headerData && headerData.quiz_id && isHybrid === false) ? (
              <>
                <button
                  type="button"
                  className="btn btn-save btn-success"
                  title="Download Assessments"
                  onClick={() => {
                    handleDownloadAssessment();
                  }}
                  data-toggle="modal"
                  data-target="#downloadAssessment"
                >
                  <i className="fal fa-arrow-down"></i>Download Assessments
                </button>
                &nbsp; &nbsp;
              </>
            ) : null}
            {headerData && headerData.quiz_id && !isHybrid ? (
              ""
            ) : (
              <>
                <button
                  className="btn btn-primary"
                  title="Import Marks"
                  data-toggle="modal"
                  disabled={headerData?.notify_assessed}
                  data-target="#exampleModalCenter"
                >
                  <i className="fal fa-arrow-up"></i>Import Marks
                </button>
                &nbsp; &nbsp;
              </>
            )}
            <>
              {headerData?.notify_assessed ? (
                <button
                  title="Re-Open Marking"
                  className="btn btn-primary"
                  onClick={(e) => submitNotification(e, "reOpen")}
                >
                  <i className="fal fa-bell"></i>Re-Open Marking
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  title="Mark as Complete"
                  data-toggle="modal"
                  data-target="#exampleModal2"
                  disabled={false /*isSubmissionMarked}>*/}
                >
                  <i className="fal fa-bell"></i>Mark as Complete
                </button>
              )}
              &nbsp; &nbsp;
            </>
            {headerData && headerData.quiz_id ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDownloadResults();
                }}
                title="Download Smart Assessment Results"
              >
                {downloading ? (
                  <i className="fas fa-cog fa-spin"></i>
                ) : (
                  <i className="fal fa-arrow-down"></i>
                )}{" "}
                Download Smart Assessment Results
              </button>
            ) : null}
          </div>
          {/* {/ button end /} */}
        </div>
      </div>

      {/* {/ popup1 section start /} */}
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Import csv for mark capture
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="import_capture_inputs">
                <form onSubmit={submitZipForm}>
                  {/* <label htmlFor="fileID">Study Guide</label> */}
                  {/* <div className="form-control" onClick={handlefileinput}>
                    {zipfileupload?.name ? <i className="fas fa-file-archive"></i> : ""}
                    &nbsp;
                    {zipfileupload ? zipfileupload?.name : null}
                  </div> */}
                  <div className="drag_card_btn">
                    <FilePond
                      name="CSVFILE"
                      allowMultiple={false}
                      // maxFiles={1}
                      ref={inputRef}
                      files={zipfileupload}
                      onupdatefiles={setZipFileUpload}
                      acceptedFileTypes="application/csv,text/csv,.csv"
                    />
                  </div>
                  {/* <input type="file" id="inputfileupload" ref={inputRef} onChange={handleFileChange} accept=".csv" style={{ display: "none" }} /> */}
                </form>
                {/* {zipfileupload && zipfileupload?.name ? <button className="btn btn-primary" onClick={handlefileinput}>Change</button> : <button className="btn btn-primary" onClick={handlefileinput}>Browse</button>} */}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                disabled={!zipfileupload}
                className="btn btn-primary"
                onClick={submitZipForm}
              >
                Import
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={() => setZipFileUpload("")}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*popup1 section end */}

      {/* / popup2 section start / */}
      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Notification !
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                disabled={notificationval}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input
                type="checkbox"
                id="checkboxid"
                name="notificationval"
                checked={notificationval}
                onChange={(e) => setNotificationvalue(e)}
              />
              &nbsp;&nbsp;
              <label htmlFor="checkboxid">Notify All Students</label>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={submitNotification}
              >
                <i className="fal fa-check"></i>Send
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
              >
                <i className="fal fa-times"></i>Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* {isLoading ? <SkeletonTicketList /> : <DataTableComponentFrontPagination
        data={dataToRender()}
        columns={(headerData && headerData.quiz_id) ? (isHybrid===true ? hybridColumns : quizColumns) : columns}
        loading={isLoading}
        search={search}
        setSearch={setSearch}
        exportData={exportData}
        exportFileName={"Submission-And-Result"}
        defaultSort={{ defaultSortColumn: "name", defaultSortAsc: true }}
        filters={[
          {
            filterName: "Status",
            optionArr: [
              { label: "ACTIVE", value: "ACTIVE" },
              { label: "CANCELLED", value: "Cancelled" },
              { label: "DROPOUT", value: "Drop Out" },
              { label: "FINISHED", value: "Finished" },
              { label: "INACTIVE", value: "In Active" },
              { label: "ONHOLD", value: "On Hold" },
              { label: "RESULTWITHELD", value: "Results Witheld" },
            ],
            state: status,
            setState: setStatus,
            renderLabelFunction: RenderSubmissionAndResultStatus,
          },
          {
            filterName: "Submission Status",
            optionArr: [
              { label: "Appeal-Approved", value: "Appeal-Approved" },
              { label: "Appeal-Pending", value: "Appeal-Pending" },
              { label: "Draft", value: "DRAFT" },
              { label: "Marked", value: "MARKED" },
              { label: "Not Submitted", value: "NOT SUBMITTED" },
              { label: "Submitted", value: "SUBMITTED" },
            ],
            state: submissionStatus,
            setState: setSubmissionStatus,
            renderLabelFunction: RenderSubmissionStatus,
          },
        ]}
      />} */}
      <>
        {/* <Prompt when={!!shouldBlockNavigation} message={handleNavigation} /> */}
        <div className="my-tickets-info-list Tickets-main-wrap course-gradebook grademix-subandres">
          <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
            <div className="search-filter-div">
              <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div
                      id="assessment-table-main_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <div
                        id="assessment-table-main_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={handleSearchFilter}
                            value={debouncedTerm}
                          />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        className="btn btn-primary"
                        title="Reset"
                        onClick={resetFilter}
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportXlsxData(`${assName} GradeMix`);
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", `${assName} GradeMix`);
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", `${assName} GradeMix`);
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <div>
                    <button
                      className="btn btn-primary"
                      title={
                        percentageView ? "Standard View" : "Percentage View"
                      }
                      onClick={() => setPercentageView(!percentageView)}
                    >
                      <i className="fal fa-eye"></i>{" "}
                      {percentageView ? "Standard View" : "Percentage View"}
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
            {loadingGrademix.heading || loadingGrademix.list ? (
              <SkeletonTicketList />
            ) : userData?.length ? (
              <div className="table-book-responsive">
                <div className="table-book row m-0">
                  <div className={"table-book-left "}>
                    <table
                      className={
                        "table-book-main smart-grademix-table " +
                        (percentageView
                          ? assessmentHeader?.length < 9
                            ? ""
                            : "border-shadow-custom-table"
                          : assessmentHeader?.length < 9
                          ? ""
                          : "border-shadow-custom-table")
                      }
                    >
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>Submission Status</th>
                          <th>Submission Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <div className="assigned-title-block-new">
                                <div className="assigned-title-itm">
                                  <span
                                    className={
                                      "assigned-title-blk  name-icon cat-dark-red "
                                    }
                                  >
                                    {row.image ? (
                                      <img
                                        src={`${IMAGE_URL.replaceAll(
                                          "http:",
                                          "https:"
                                        )}/${row.image.replaceAll(
                                          "/home/myaie/public_html/",
                                          ""
                                        )}`}
                                        alt="AIE"
                                      />
                                    ) : row.name ? (
                                      InitialRender([
                                        row.name.split(" ")[0],
                                        row.name.split(" ")[1],
                                      ])
                                    ) : (
                                      InitialRender(["N", "A"])
                                    )}
                                    <span
                                      className={`profile-box-2-status ${
                                        row.activity_status
                                          ? row.activity_status.toLowerCase() ==
                                            "online"
                                            ? "Online"
                                            : row.activity_status.toLowerCase() ==
                                              "away"
                                            ? "Away"
                                            : "Offline"
                                          : "Offline"
                                      }`}
                                    >
                                      <i className="fas fa-circle"></i>
                                    </span>
                                    <Hover
                                      firstName={
                                        row.name ? row.name.split(" ")[0] : ""
                                      }
                                      lastName={
                                        row.name ? row.name.split(" ")[1] : ""
                                      }
                                      photo={row.image}
                                      email={row.email}
                                      mobile={row.mobile}
                                      right={true}
                                      role={row.role_name}
                                      status={row.status}
                                      activity_status={row.activity_status}
                                      showNumber={true}
                                      number={row.student_crm_id}
                                    />
                                  </span>
                                  <PermissionsGate
                                    scopes={["umedit"]}
                                    RenderError={() => <p>{row.name}</p>}
                                  >
                                    <Link
                                      title={row.name}
                                      className="as-text-blue curser "
                                      to={`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}/update/${row.id}`}
                                    >
                                      {TrimText(row.name, 15)}
                                    </Link>
                                  </PermissionsGate>
                                </div>
                              </div>
                            </td>
                            <td>
                              <>
                                {row.submission_status
                                  ? RenderSubmissionStatus(
                                      row.submission_status
                                    ).html
                                  : "-"}
                                {(row.student_id != "" &&
                                  row.student_id != null) ||
                                row.appeal_status == "Approved" ||
                                row.appeal_status == "Declined" ? (
                                  <button
                                    className="btn btn-primary mt-1"
                                    title="View Appeal"
                                    data-toggle="modal"
                                    data-target="#notifications1"
                                    onClick={() => {
                                      setStudentId(row.student_id);
                                      handleGetAppealDetails(row.student_id);
                                    }}
                                  >
                                    <i className="fal fa-gavel"></i> View Appeal
                                  </button>
                                ) : (
                                  ""
                                )}
                              </>
                            </td>
                            <td>
                              <div>
                                <span>
                                  {row.submission_date
                                    ? row.submission_date
                                    : "NA"}
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className={
                      "table-book-mid " +
                      (percentageView
                        ? assessmentHeader?.length < 9
                          ? "less-items"
                          : "overflow-auto"
                        : assessmentHeader?.length < 9
                        ? "less-items"
                        : "overflow-auto")
                    }
                  >
                    <div className="grade-acc">
                      <div className={"grade-acc-in active"}>
                        <table className="table-book-main  smart-grademix-table table-book-main-contetn">
                          <thead>
                            <tr>
                              {assessmentHeader?.length
                                ? assessmentHeader.map((item, index) => (
                                    <th key={index}>
                                      <div className="tb-book-tit">
                                        <Link
                                          className="curser"
                                          to={`/courseAdministration/gradeMix/${item?.qualification_id}/details/${item?.id}/open`}
                                          title={item?.name}
                                        >
                                          <p>{isHybrid ? TrimText(item?.name, 25) : TrimText(item?.name, 20)}</p>
                                        </Link>
                                      </div>
                                      {<div className="tb-book-subtitle">
                                        <tr>
                                          {isHybrid && <td>Hybrid Result</td>}
                                          <td>{isHybrid ? "Smart Result" : "Student Result"}</td>
                                          <td>Mark</td>
                                        </tr>                                        
                                      </div>}
                                    </th>
                                  ))
                                : ""}
                            </tr>
                          </thead>
                          <tbody>
                            {userData?.map((item, i) => (
                              <tr key={i}>
                                {item?.grademixArr?.map((ass, index) => {
                                  return (
                                    <td
                                      key={index}
                                      className={
                                        ass?.grant_exemption
                                          ? "exempted-td"
                                          : ""
                                      }
                                    >
                                      {ass?.grant_exemption ? (
                                        <>
                                          <div
                                            className="as-widget-text grade-info-plus mt-2 curser"
                                            title="Remove from Exemption"
                                            // onClick={() =>
                                            //   handleNavigation(null, "exempted", [ass?.assessment_id,
                                            //     item?.student_id,
                                            //     ass?.grant_exemption ? 0 : 1])
                                            //   // handleExemption(
                                            //   //   ass?.assessment_id,
                                            //   //   item?.student_id,
                                            //   //   ass?.grant_exemption ? 0 : 1
                                            //   // )
                                            // }
                                          >
                                            {ass?.grant_exemption ? (
                                              <i className="fal fa-user-plus"></i>
                                            ) : (
                                              <i className="fal fa-user-plus"></i>
                                            )}
                                            <div
                                              title="Exempted"
                                              className="exempted-text"
                                            >
                                              Exempted{" "}
                                              <i className="fal fa-info-circle"></i>
                                            </div>
                                          </div>
                                        </>
                                      ) : ass.pass_status == "N/A" ? (
                                        <div>
                                          <span>N/A</span>
                                        </div>
                                      ) : (
                                        <tr className="smart-grademix-content">                                                                                    
                                          <>
                                            {isHybrid ? <>
                                              <td>
                                                <div className="d-flex">
                                                  <div className="marks-number-info">
                                                    <div className="marks-input-text">
                                                      {percentageView ? (
                                                        <div className="resultstatus_flex">
                                                          <div className="form-icon-group status-select-form">
                                                            <input
                                                              className={ass.hybrid_percentage == "0" ? "input-red" : ""}
                                                              type="number"
                                                              value={ass.hybrid_percentage == 0 && ass.submited == "0" ? "" : ass.hybrid_percentage}
                                                              disabled
                                                            />{" "}
                                                            %
                                                          </div>
                                                        </div>
                                                      ) : ass.grading_type === 0 ? (
                                                        <div className="resultstatus_flex">
                                                          <div className="form-icon-group status-select-form">                                                          
                                                            <input
                                                              className={ass.hybrid_percentage == "0" ? "input-red" : ""}
                                                              type="number"
                                                              defaultValue={
                                                                (ass.ass_hybrid_marks == 0 && ass.submited == "0") || ass.hybrid_percentage==null ? "" : ass.hybrid_percentage
                                                              }
                                                              disabled
                                                            />                                                                                                                    
                                                            <span className="ml-1">%</span>                                                          
                                                          </div>
                                                        </div>
                                                      ) : ass.grading_type === 1 ? (
                                                        <div className="form-icon-group marks-input-text">
                                                          <select
                                                            className={"mid-scroll-custom-table-select " + (ass.hybrid_percentage == "0" ? "percentage-select-red" : "")}
                                                            defaultValue={((!ass.hybrid_percentage || ass.hybrid_percentage == 0) && ass.submited == "0") || ass.hybrid_percentage==null ? "" : ass?.hybrid_percentage == 0 ? "0" : (ass?.hybrid_percentage > 0 && ass?.hybrid_percentage <= 49) ? "49" : "100" }
                                                            disabled
                                                          >
                                                            {options?.length ? options.map((opt, index) => (
                                                              <option key={index} value={opt.value}>{opt.label}</option>
                                                            ))
                                                            : ""}
                                                          </select>
                                                        </div>
                                                      ) : ass.grading_type === 2 ? (
                                                        <div className="form-icon-group marks-input-text">
                                                          <select 
                                                            className={"mid-scroll-custom-table-select " + (ass.hybrid_percentage == "0" ? "percentage-select-red": "")}
                                                            defaultValue={(ass.hybrid_percentage == 0 && ass.submited == "0") || ass.hybrid_percentage==null ? "" : ass?.hybrid_assCalculateGradeStatus?.grade}
                                                            disabled
                                                          >
                                                            {ass?.letterGrades && ass?.letterGrades?.length
                                                              ? ass?.letterGrades.map((opt, index) => (
                                                                    <option key={index} value={opt.value}>
                                                                      {opt.label}
                                                                    </option>
                                                                  )
                                                                )
                                                              : ""}
                                                          </select>
                                                        </div>
                                                      ) : (
                                                        <div className="resultstatus_flex">
                                                          <div className="form-icon-group status-select-form">                                                          
                                                            <input
                                                              className={ass.ass_hybrid_marks == "0" ? "input-red" : ""}
                                                              type="number"
                                                              disabled
                                                              defaultValue={(ass.ass_hybrid_marks == 0 && ass.submited == "0") || ass.ass_hybrid_marks==null ? "" : twoDecimalRound(ass.ass_hybrid_marks)}
                                                            />
                                                            <span className="ml-1 min-w-40">
                                                              / {ass.ass_hybrid_total_marks}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="d-flex">
                                                  <div className="marks-number-info">
                                                    <div className="marks-input-text">
                                                      {percentageView ? (
                                                        <div className="resultstatus_flex">
                                                          <div className="form-icon-group status-select-form">
                                                            <input
                                                              className={ass.normal_percentage == "0" ? "input-red" : ""}
                                                              type="number"
                                                              value={ass.normal_percentage == 0 && ass.submited == "0" ? "" : ass.normal_percentage}
                                                              disabled
                                                            />{" "}
                                                            %
                                                          </div>
                                                        </div>
                                                      ) : ass.grading_type === 0 ? (
                                                        <div className="resultstatus_flex">
                                                          <div className="form-icon-group status-select-form">                                                          
                                                            <input
                                                              className={ass.normal_percentage == "0" ? "input-red" : ""}
                                                              type="number"
                                                              defaultValue={ass.normal_percentage == 0 && ass.submited == "0" ? "" : ass.normal_percentage}
                                                              disabled
                                                            />
                                                            {<span className="ml-1">%</span>}
                                                          </div>
                                                        </div>
                                                      ) : ass.grading_type === 1 ? (
                                                        <div className="form-icon-group marks-input-text">                                                        
                                                          <select
                                                            className={"mid-scroll-custom-table-select " +(ass.normal_percentage == "0" ? "percentage-select-red" : "")}
                                                            defaultValue={(!ass.normal_percentage || ass.normal_percentage == 0) && ass.submited == "0" ? "" 
                                                              : ass?.normal_percentage == 0 ? "0"
                                                              : ass?.normal_percentage > 0 && ass?.normal_percentage <= 49
                                                              ? "49" : "100"
                                                            }
                                                            disabled
                                                          >
                                                            {options?.length ? options.map((opt, index) => (
                                                                <option key={index} value={opt.value}>
                                                                  {opt.label}
                                                                </option>
                                                              ))
                                                            : ""}
                                                          </select>
                                                        </div>
                                                      ) : ass.grading_type === 2 ? (
                                                        <div className="form-icon-group marks-input-text">                                                        
                                                          <select 
                                                            className={"mid-scroll-custom-table-select " + (ass.normal_percentage == "0" ? "percentage-select-red": "")}
                                                            defaultValue={ass.normal_percentage == 0 && ass.submited == "0" ? "" : ass?.normal_assCalculateGradeStatus?.grade}
                                                            disabled
                                                          >
                                                            {ass?.letterGrades && ass?.letterGrades?.length ? 
                                                            ass?.letterGrades.map((opt, index) => (
                                                                <option key={index} value={opt.value}>
                                                                  {opt.label}
                                                                </option>
                                                              ))
                                                            : ""}
                                                          </select>
                                                        </div>
                                                      ) : (
                                                        <div className="resultstatus_flex">
                                                          <div className="form-icon-group status-select-form">                                                          
                                                            <input
                                                              className={ass.ass_normal_marks == "0" ? "input-red" : ""}
                                                              type="number"
                                                              disabled
                                                              defaultValue={ass.normal_percentage == 0 && ass.submited == "0" ? "" : twoDecimalRound(ass.ass_normal_marks)}                                                              
                                                            />
                                                            <span className="ml-1 min-w-40">
                                                              / {ass.ass_normal_total_marks}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                            </> 
                                            :
                                            <td>
                                              <div className="d-flex">
                                                <div className="marks-number-info">
                                                  <div className="marks-input-text">
                                                    {percentageView ? (
                                                      <div className="resultstatus_flex">
                                                        <div className="form-icon-group status-select-form">
                                                          <input
                                                            className={
                                                              ass.ass_percentage ==
                                                              "0"
                                                                ? "input-red"
                                                                : ""
                                                            }
                                                            type="number"
                                                            value={ass.ass_percentage == 0 && ass.submited == "0" ? "" : ass.ass_percentage}
                                                            disabled
                                                          />{" "}
                                                          %
                                                        </div>
                                                      </div>
                                                    ) : ass.grading_type === 0 ? (
                                                      <div className="resultstatus_flex">
                                                        <div className="form-icon-group status-select-form">
                                                          <PermissionsGate
                                                            scopes={[((headerData &&
                                                              headerData.quiz_id) ||
                                                            isHybrid === true) ? "overidersmart" : "cagbedit"]}
                                                            errorProps={{
                                                              disabled: true,
                                                            }}
                                                          >
                                                            <input
                                                              className={
                                                                ass.ass_percentage ==
                                                                "0"
                                                                  ? "input-red"
                                                                  : ""
                                                              }
                                                              type="number"
                                                              // title={
                                                              //   ass.ass_percentage
                                                              // }
                                                              defaultValue={
                                                                ass.ass_percentage == 0 && ass.submited == "0" ? "" : ass.ass_percentage
                                                              }
                                                              onBlur={(e) => {
                                                                stdIds.push(
                                                                  item.quiz_student_id
                                                                );
                                                                handleUpdateMarks(
                                                                  e,
                                                                  item.quiz_student_id,
                                                                  "inputbox",
                                                                  ass.ass_total_mark,
                                                                  ass
                                                                )
                                                              }}
                                                              disabled={
                                                                ass.lock_grades ==
                                                                  "1" 
                                                              }
                                                              onMouseUp={handleMouseUp}
                                                              onMouseDown={handleMouseDown}
                                                            />
                                                          </PermissionsGate>
                                                          {
                                                            <span className="ml-1">
                                                              %
                                                            </span>
                                                          }
                                                        </div>
                                                      </div>
                                                    ) : ass.grading_type === 1 ? (
                                                      <div className="form-icon-group marks-input-text">
                                                        <PermissionsGate
                                                          scopes={[((headerData &&
                                                            headerData.quiz_id) ||
                                                          isHybrid === true) ? "overidersmart" : "cagbedit"]}
                                                          errorProps={{
                                                            disabled: true,
                                                          }}
                                                        >
                                                          <select
                                                            className={
                                                              "mid-scroll-custom-table-select " +
                                                              (ass.ass_percentage ==
                                                              "0"
                                                                ? "percentage-select-red"
                                                                : "")
                                                            }
                                                            defaultValue={
                                                              ass.ass_percentage == 0 && ass.submited == "0"
                                                              ? "" 
                                                              : ass?.grade == 0
                                                                ? "0"
                                                                : ass?.grade > 0 &&
                                                                  ass?.grade <= 49
                                                                ? "49"
                                                                : "100"
                                                            }
                                                            disabled={
                                                              ass.lock_grades ==
                                                                "1" 
                                                            }
                                                            onChange={(e) => {
                                                              stdIds.push(
                                                                item.quiz_student_id
                                                              );
                                                              handleUpdateMarks(
                                                                e,
                                                                item.quiz_student_id,
                                                                "selectbox",
                                                                ass.ass_total_mark,
                                                                ass
                                                              )
                                                            }}
                                                            onMouseUp={handleMouseUp}
                                                            onMouseDown={handleMouseDown}
                                                          >
                                                            {options?.length
                                                              ? options.map(
                                                                  (opt, index) => (
                                                                    <option
                                                                      key={index}
                                                                      value={
                                                                        opt.value
                                                                      }
                                                                    >
                                                                      {opt.label}
                                                                    </option>
                                                                  )
                                                                )
                                                              : ""}
                                                          </select>
                                                        </PermissionsGate>
                                                      </div>
                                                    ) : ass.grading_type === 2 ? (
                                                      <div className="form-icon-group marks-input-text">
                                                        <PermissionsGate
                                                          scopes={[((headerData &&
                                                            headerData.quiz_id) ||
                                                          isHybrid === true) ? "overidersmart" : "cagbedit"]}
                                                          errorProps={{
                                                            disabled: true,
                                                          }}
                                                        >
                                                          <select
                                                            className={
                                                              "mid-scroll-custom-table-select " +
                                                              (ass.ass_percentage ==
                                                              "0"
                                                                ? "percentage-select-red"
                                                                : "")
                                                            }
                                                            defaultValue={ass.ass_percentage == 0 && ass.submited == "0" ? "" : ass?.grade}
                                                            disabled={
                                                              ass.lock_grades ==
                                                                "1" 
                                                            }
                                                            onChange={(e) => {
                                                              stdIds.push(
                                                                item.quiz_student_id
                                                              );
                                                              handleUpdateMarks(
                                                                e,
                                                                item.quiz_student_id,
                                                                "selectbox",
                                                                ass.ass_total_mark,
                                                                ass
                                                              )
                                                            }}
                                                            onMouseUp={handleMouseUp}
                                                            onMouseDown={handleMouseDown}
                                                          >
                                                            {ass?.letterGrades &&
                                                            ass?.letterGrades?.length
                                                              ? ass?.letterGrades.map(
                                                                  (opt, index) => (
                                                                    <option
                                                                      key={index}
                                                                      value={
                                                                        opt.value
                                                                      }
                                                                    >
                                                                      {opt.label}
                                                                    </option>
                                                                  )
                                                                )
                                                              : ""}
                                                          </select>
                                                        </PermissionsGate>
                                                      </div>
                                                    ) : (
                                                      <div className="resultstatus_flex">
                                                        <div className="form-icon-group status-select-form">
                                                          <PermissionsGate
                                                            scopes={[((headerData &&
                                                              headerData.quiz_id) ||
                                                            isHybrid === true) ? "overidersmart" : "cagbedit"]}
                                                            errorProps={{
                                                              disabled: true,
                                                            }}
                                                          >
                                                            <input
                                                              className={
                                                                ass.ass_mark == "0"
                                                                  ? "input-red"
                                                                  : ""
                                                              }
                                                              type="number"
                                                              disabled={
                                                                ass.lock_grades ==
                                                                  "1" 
                                                              }
                                                              defaultValue={
                                                                ass.ass_percentage == 0 && ass.submited == "0" ? "" : ass.ass_mark
                                                              }
                                                              onBlur={(e) => {
                                                                stdIds.push(
                                                                  item.quiz_student_id
                                                                );
                                                                handleUpdateMarks(
                                                                  e,
                                                                  item.quiz_student_id,
                                                                  "inputbox",
                                                                  ass.ass_total_mark,
                                                                  ass
                                                                )
                                                              }}
                                                              onMouseUp={handleMouseUp}
                                                              onMouseDown={handleMouseDown}
                                                            />
                                                          </PermissionsGate>
                                                          <span className="ml-1 min-w-40">
                                                            / {ass.ass_total_mark}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </td>}
                                            <td>                                              
                                              {isHybrid == true ? ((ass?.mark && item?.showValue) ? 
                                                <p className="m-0">
                                                  {ass?.grading_type === 0 ? `${(ass?.gradeText)}`
                                                  : ass?.grading_type === 3 ? `${(ass?.gradeText)}/${ass?.ass_total_mark}` 
                                                  : ass.gradeText}
                                                </p> 
                                              : headerData?.notify_assessed == 1 ?
                                              ass?.grading_type === 3 ? `${ass?.gradeText}/${ass?.ass_total_mark}`
                                              : ass.gradeText : "N/A") : 
                                              (((ass?.mark && ass?.submited > 0) || headerData?.notify_assessed == 1) ? <p className="m-0">
                                              {ass?.grading_type === 0 ? `${(ass?.gradeText)}`
                                              : ass?.grading_type === 3 ? `${(ass?.gradeText)}/${ass?.ass_total_mark}` 
                                              : ass?.gradeText}
                                              </p>
                                              : "N/A")}  
                                            </td> 
                                          </>
                                        </tr>                                        
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      "table-book-right smart-grademix-table " +
                      (percentageView
                        ? assessmentHeader?.length < 9
                          ? "many-items"
                          : "max-width-fit-content"
                        : assessmentHeader?.length < 9
                        ? "many-items"
                        : "max-width-fit-content")
                    }
                  >
                    <table
                      className={
                        "table-book-main " +
                        (percentageView
                          ? assessmentHeader?.length < 9
                            ? ""
                            : "border-shadow-custom-table"
                          : assessmentHeader?.length < 9
                          ? ""
                          : "border-shadow-custom-table")
                      }
                    >
                      <thead>
                        <tr>
                          <th className="third-last-cell">
                            <span className="right-border-grade"></span>
                            <div className="tb-book-tit">
                              Student Submission
                            </div>
                          </th>
                          <th className="upload-file-width">
                            <div className="tb-book-tit">Upload File</div>
                          </th>
                          <th>
                            <div className="tb-book-tit text-left">Actions</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData.map((row, index) => (
                          <tr key={index}>
                            <td>
                              <span
                                className={
                                  "right-border-grade " +
                                  (userData.length - 1 == index
                                    ? "last-cell-border"
                                    : "")
                                }
                              ></span>
                              <div className="as-widget-outer-submission">
                                {row?.multipleFiles?.filter(
                                  (item) => item?.type === 0
                                )?.length ? (
                                  <p
                                    data-toggle="modal"
                                    data-target="#downloadAssessment"
                                    onClick={() =>
                                      handleStudentDownload(
                                        row?.multipleFiles?.length &&
                                          row?.multipleFiles?.filter(
                                            (item) => item?.type === 0
                                          )?.length
                                          ? row.multipleFiles?.filter(
                                              (item) => item?.type === 0
                                            )
                                          : row,
                                        row
                                      )
                                    }
                                    className="as-text-blue curser"
                                  >
                                    Download Assignment
                                  </p>
                                ) : row?.quiz_student_id &&
                                  row?.quiz_id &&
                                  row?.submissionCount ? (
                                  <Link
                                    title="View Submissions"
                                    className="as-text-blue curser"
                                    to={{
                                      pathname: `/courseAdministration/assessment/quizzes/open/submissionList/${row?.quiz_id}/${row?.quiz_student_id}?intake_id=${id}&assess_id=${subId}`,
                                      state: { id: id, subId: subId },
                                    }}
                                  >
                                    View Submissions
                                  </Link>
                                ) : (
                                  <span className="d-flex text-center">-</span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="frm-group">
                                <div className=" atttach-file m-0">
                                  <label>
                                    {fileLoading == row.id ? (
                                      <i className="fas fa-cog fa-spin"></i>
                                    ) : (
                                      <i className="fal fa-paperclip"></i>
                                    )}
                                    <span>Add Attachment</span>
                                    <PermissionsGate
                                      scopes={["castedit"]}
                                      errorProps={{ disabled: true }}
                                    >
                                      <input
                                        disabled={fileLoading == row.id}
                                        type="file"
                                        multiple
                                        className="form-control  form-control-aatch-file"
                                        onChange={(e) => addFileFun(e, row.id)}
                                      />
                                    </PermissionsGate>
                                  </label>
                                </div>
                                <div className="view-attach">
                                  {row.multipleFiles &&
                                    row.multipleFiles.filter(
                                      (item) => item.type == 1
                                    ).length > 0 && (
                                      <span
                                        className="as-text-blue curser"
                                        data-toggle="modal"
                                        data-target="#attachmentModal"
                                        onClick={() => setAttacmentId(row.id)}
                                      >
                                        {row.multipleFiles &&
                                        row.multipleFiles.filter(
                                          (item) => item.type == 1
                                        ).length > 1
                                          ? "View Attachments"
                                          : "View Attachment"}
                                      </span>
                                    )}
                                </div>
                                {/* <ul className="list-unstyled attached-file-ul m-0">
                                {row.multipleFiles && row.multipleFiles.filter(item=>item.type==1).map((element, ind)=>(
                                  <li key={ind}>
                                    <a
                                      href={`${downloadURL}${IMAGE_URL.replaceAll("http:","https:")}/${encodeURI(element.path)}`.replaceAll("/home/myaie/public_html/", "").replace("public/", "")} 
                                      target="_blank" 
                                      title={element.path.split("/").reverse()[0]}>
                                      <i className="fal fa-file"></i> {TrimText(element?.path?.split("/").reverse()[0], 15)}
                                    </a>
                                    <button
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      onClick={() => {
                                        Swal.fire({
                                          title: "Are you sure?",
                                          text: "You won't be able to revert this!",
                                          icon: "warning",
                                          showCancelButton: true,
                                          confirmButtonColor: "#3085d6",
                                          cancelButtonColor: "#d33",
                                          confirmButtonText:
                                            "Yes, delete it!",
                                        }).then((result) =>{ 
                                          if(result.isConfirmed){
                                            DeleteAssessmentSubmissionUpload({ submission_id: element.id, intake: id, type : element.type, submited_type : element.submited_type  }).then(res => {
                                              Swal.fire(
                                                "Deleted!",
                                                "File has been deleted.",
                                                "success"
                                              );
                                              setupDatedata(!updatedata)
                                            }).catch(err => {
                                              console.log("error :", err)
                                              Swal.fire(
                                                "Not Deleted!",
                                                "File not deleted.",
                                                "error"
                                              );
                                            })
                                          }
                                        })
                                      }}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </li>
                                ))}
                              </ul> */}
                              </div>
                            </td>
                            <td className="justify-content-end d-flex calendar-cart-item-inner">
                              <div className="assessment-08 btn-dropdown-grp">
                                {headerData.quiz_id && !row.upload_file ? <div className="as-buttons">
                                  <button
                                    className="btn btn-primary rounded-circle"
                                    data-toggle="modal"
                                    data-target="#notifications"
                                    onClick={() => {
                                      handleGetComment(row.id);
                                      setSubmissionId(row.id);
                                    }}
                                    title="Comment"
                                  >
                                    <i className="fal fa-comment"></i>
                                  </button>
                                  <button
                                    className="btn btn-primary rounded-circle"
                                    onClick={() =>
                                      history.push(
                                        `/studentAdministration/students/open/${row.quiz_student_id}/general`
                                      )
                                    }
                                    title="Open Student Profile"
                                  >
                                    <i className="fal fa-user"></i>
                                  </button>
                                </div> : headerData.quiz_id && row.upload_file ? <div className="as-buttons">
                                  <a
                                    className="btn btn-primary rounded-circle"
                                    title="Download Submission"
                                    href={
                                      IMAGE_URL.replaceAll(
                                        "http:",
                                        "https:"
                                      ) +
                                      "/" +
                                      row.upload_file.replaceAll(
                                        "public/",
                                        ""
                                      )
                                    }
                                    target="_blank"
                                    download={"submission"}
                                  >
                                    <i className="fal fa-arrow-down"></i>
                                  </a>
                                  <div className="dropdown btn-dropdown-item">
                                    <button
                                      className="btn btn-primary rounded-circle dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      title="More"
                                    >
                                      <i className="fal fa-ellipsis-h-alt"></i>
                                    </button>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >

                                      <button
                                        className="btn btn-primary rounded-circle"
                                        data-toggle="modal"
                                        data-target="#notifications"
                                        onClick={() => {
                                          handleGetComment(row.id);
                                          setSubmissionId(row.id);
                                        }}
                                        title="Comment"
                                      >
                                        <i className="fal fa-comment"></i>
                                      </button>

                                      <button
                                        className="btn btn-primary rounded-circle"
                                        onClick={() =>
                                          history.push(
                                            `/studentAdministration/students/open/${row.quiz_student_id}/general`
                                          )
                                        }
                                        title="Open Student Profile"
                                      >
                                        <i className="fal fa-user"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div> : <div className="as-buttons">
                                  <button
                                    className="btn btn-primary rounded-circle"
                                    onClick={() =>
                                      history.push(
                                        `/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}/update/${row.id}`
                                      )
                                    }
                                    title="Open"
                                  >
                                    <i className="fal fa-folder-open"></i>
                                  </button>
                                  <div className="dropdown btn-dropdown-item">
                                    <button
                                      className="btn btn-primary rounded-circle dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      title="More"
                                    >
                                      <i className="fal fa-ellipsis-h-alt"></i>
                                    </button>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      {row.upload_file && (
                                        <a
                                          className="btn btn-primary rounded-circle"
                                          title="Download Submission"
                                          href={
                                            IMAGE_URL.replaceAll(
                                              "http:",
                                              "https:"
                                            ) +
                                            "/" +
                                            row.upload_file.replaceAll(
                                              "public/",
                                              ""
                                            )
                                          }
                                          target="_blank"
                                          download={"submission"}
                                        >
                                          <i className="fal fa-arrow-down"></i>
                                        </a>
                                      )}

                                      <button
                                        className="btn btn-primary rounded-circle"
                                        data-toggle="modal"
                                        data-target="#notifications"
                                        onClick={() => {
                                          handleGetComment(row.id);
                                          setSubmissionId(row.id);
                                        }}
                                        title="Comment"
                                      >
                                        <i className="fal fa-comment"></i>
                                      </button>

                                      <button
                                        className="btn btn-primary rounded-circle"
                                        onClick={() =>
                                          history.push(
                                            `/studentAdministration/students/open/${row.quiz_student_id}/general`
                                          )
                                        }
                                        title="Open Student Profile"
                                      >
                                        <i className="fal fa-user"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div className="custom-table-msg">{Str.noRecord}</div>
            )}
            <nav className="pagination-nav">
              <div>
                {errorMessage ? (
                  <div className="invalid-feedback d-block">{errorMessage}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="second-child">
                <span>Rows per page:&nbsp;</span>
                <div className="pagination-select">
                  <select
                    defaultValue={tableState?.limit}
                    onChange={(e) => {
                      let value = e?.target?.value;
                      setTableState((prevState) => ({
                        ...prevState,
                        limit: value ? value : 10,
                        page: 1,
                      }));
                    }}
                  >
                    {TABLE_ROWS_PER_PAGE.map((page, index) => (
                      <option key={index}>{page}</option>
                    ))}
                  </select>
                </div>
                <span className="mx-3">
                  {paginationState.from}-
                  {paginationState.to > paginationState.total
                    ? paginationState.total
                    : paginationState.to}{" "}
                  of {paginationState.total}
                </span>
                <div className="pagination-btns">
                  <button
                    type="button"
                    disabled={
                      paginationState.currentPage == 1 ||
                      paginationState.currentPage == 0
                    }
                    onClick={() => {
                      if (updateValueArr?.length) {
                        handleNavigation(null, "firstPage");
                      } else {
                      setTableState((prevState) => ({ ...prevState, page: 1 }));
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                      <path fill="none" d="M24 24H0V0h24v24z"></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    disabled={
                      paginationState.currentPage == 1 ||
                      paginationState.currentPage == 0
                    }
                    onClick={() => {
                      if (updateValueArr?.length) {
                      handleNavigation(null, "prevPage");
                      } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page: paginationState.currentPage - 1,
                      }));
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    disabled={
                      paginationState.currentPage == paginationState.lastPage
                    }
                    onClick={() => {
                      if (updateValueArr?.length) {
                        handleNavigation(null, "nextPage");
                      } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page: +paginationState.currentPage + 1,
                      }));
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                      <path d="M0 0h24v24H0z" fill="none"></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    disabled={
                      paginationState.currentPage == paginationState.lastPage
                    }
                    onClick={() => {
                      if (updateValueArr?.length) {
                        handleNavigation(null, "lastPage");
                      } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page: paginationState.lastPage,
                      }));
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </nav>
          </div>
          {userData?.length && updateValueArr?.length ? <GradeBookSaveBar handleClick={() => handleSaveGrades()} disabled={errorMessage} saveGradesLoading={saveGradesLoading} /> : <></>}
        </div>
      </>
      <PopupComponent
        id="notifications"
        type="form"
        header={{
          iconName: "fa-comment",
          heading: "Add Comment",
        }}
        inputs={[
          <div className="col-md-12">
            <div className="form-group-blk mb-3">
              <label>Comment *</label>
              <textarea
                name="comment"
                type="text"
                className={
                  "form-control" +
                  (formik.errors.comment && formik.touched.comment
                    ? " is-invalid"
                    : "")
                }
                value={formik.values.comment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>,
        ]}
        bottomButton={[
          <PermissionsGate scopes={["cedit"]} errorProps={{ disabled: true }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={formik.handleSubmit}
            >
              {commentLoading ? (
                <i className="fas fa-cog fa-spin"></i>
              ) : (
                <i className="fal fa-save"></i>
              )}
              Post
            </button>
          </PermissionsGate>,
          <button
            type="button"
            className="btn btn-danger"
            data-dismiss="modal"
            onClick={() => {
              formik.resetForm();
            }}
          >
            <i className="fal fa-times"></i>
            Close
          </button>,
          formik.touched.comment && formik.errors.comment ? (
            <div className="invalid-feedback d-block">
              {formik.errors.comment}
            </div>
          ) : null,
        ]}
        closeFunction={() => {
          formik.resetForm();
        }}
      />
      <PopupComponent
        closeFunction={()=> setStudentMessage("")}
        id="notifications1"
        type="multiple_details_blocks"
        header={{
          iconName: "fa-comment",
          heading: "Appeals"
        }}
        detailsBlocks={[
          {
            heading: "Assessment Detail",
            columns: [
              <>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title">Assessment Name:</span>
                        <span>
                          {appealsDetails &&
                            appealsDetails.assignment.name}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title">Assessment Status:</span>
                        <span>
                          {appealsDetails &&
                            appealsDetails.student_appeal &&
                            appealsDetails.student_appeal?.ass_status}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title ">Opening Date:</span>
                        <span>
                          {appealsDetails &&
                            appealsDetails.assignment.open_date_time &&
                            moment
                              .unix(appealsDetails.assignment.open_date_time)
                              .format(TABLE_DATE_TIME_FORMAT)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title">Code:</span>
                        <span>
                          {appealsDetails && appealsDetails.assignment.code}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title ">Due Date:</span>
                        <span>
                          {appealsDetails &&
                            appealsDetails.assignment.close_date_time &&
                            moment
                              .unix(appealsDetails.assignment.close_date_time)
                              .format(TABLE_DATE_TIME_FORMAT)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title ">Your Result:</span>
                        <span>{appealsDetails && appealsDetails.student_appeal && appealsDetails.student_appeal.assessment_mark_per}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul className="course-name-merge">
                      <li>
                        <span className="title ">
                          Appeal Submission Date:
                        </span>
                        <span>
                          {appealsDetails &&
                            appealsDetails.student_appeal &&
                            moment
                              .unix(
                                appealsDetails?.student_appeal?.submit_date
                              )
                              .format(TABLE_DATE_TIME_FORMAT)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
               { appealsDetails && appealsDetails.student_appeal && appealsDetails.student_appeal.timeLimitActive == 1 ?<div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul className="course-name-merge">
                      <li>
                        <span className="title ">Assessment Launched:</span>
                        <span>
                          {appealsDetails &&
                            appealsDetails.assignment.close_date_time &&
                            moment
                              .unix(appealsDetails.assignment.close_date_time)
                              .format(TABLE_DATE_TIME_FORMAT)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div> : ""}
                <div className="col-md-6">
                  <div className="basic-info-box-list modal-info-list">
                    <ul>
                      <li>
                        <span className="title ">Submission Status:</span>
                        <span>{appealsDetails && appealsDetails.student_appeal ? appealsDetails.student_appeal.appeal_status == "Declined" ? "Appeal-Declined" : appealsDetails.student_appeal.appeal_status : "NA"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            ]
          },
          {
            heading: "Registered Appeal",
            columns: [
              <div className="col-md-12">
                <div className="basic-info-box-list modal-info-list">
                  <ul>
                    <li>
                      <span className="title">
                        Reason for not submitting assessment:{" "}
                      </span>
                      <span>
                        {appealsDetails &&
                          appealsDetails.student_appeal &&
                          appealsDetails.student_appeal.ass_reason}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>,
              <div className="col-md-12">
                <div className="basic-info-box-list modal-info-list">
                  <ul>
                    <li>
                      <span className="title">Description: </span>
                      <span className="text-break">
                        {appealsDetails &&
                          appealsDetails.student_appeal &&
                          appealsDetails.student_appeal.description}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>,
              <div className="col-md-12">
                <div className="basic-info-box-list modal-info-list">
                  <ul className="appeal-submission-list">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <li>
                            <span className="title ">
                              Supporting File :
                            </span>
                            <div className="d-block">
                              {appealsDetails &&
                                appealsDetails.student_appeal &&
                                appealsDetails.student_appeal.files &&
                                appealsDetails.student_appeal.files.length ?
                                appealsDetails.student_appeal.files.map(item => {
                                  return <a href={`${downloadURL}${item.file_url}`} target="_blank" className="d-block" title={item.file}>{item.file.toString().split("/").reverse()[0]}&nbsp;</a>
                                }) : "-"}
                            </div>
                          </li>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <li>
                            <span className="title ">
                              Message Student :
                            </span>
                            <textarea
                              disabled={
                                (appealsDetails?.student_appeal?.appeal_status == "Approved" || appealsDetails?.student_appeal?.appeal_status == "Declined")
                                  ? true
                                  : false
                              }
                              type="text"
                              className="form-control"
                              value={studentMessage}
                              onChange={(e) =>
                                setStudentMessage(e.target.value)
                              }
                              cols={10}
                              id="message"
                              placeholder="Enter Message"
                              title="Enter Message"
                            />
                          </li>
                        </div>
                      </div>
                      {appealsDetails?.student_appeal?.appeal_status !== 'Declined' ? <div className="col-md-12 mb-2">
                        <div className="form-group-blk">
                          <li>
                            <span className="title ">
                              Appeal Outcome :
                            </span>
                            <div className="form-icon-group" title="">
                              <div className="prefix-sufix-box">
                                <div
                                  style={{ zIndex: "inherit" }}
                                  className="custom-control custom-radio custom-control-inline"
                                  title="Grant Extension"
                                >
                                  <input
                                    type="radio"
                                    id="grantExtension"
                                    name="appealOutcome"
                                    disabled={!(appealsDetails?.student_appeal?.appeal_status != 'Approved' && appealsDetails?.student_appeal?.appeal_status != 'Declined')}
                                    checked={appealsDetails && appealsDetails?.student_appeal && appealsDetails?.student_appeal?.appeal_outcome == 0}
                                    onChange={() => {handleChangeAppealOutcome(0)}}
                                    className="custom-control-input"
                                    // onBlur={formik.handleBlur}
                                  />
                                  <label
                                    className="custom-control-label custom-control-inline"
                                    htmlFor="grantExtension"
                                  >
                                    Grant Extension
                                  </label>
                                </div>
                                <div
                                  style={{ zIndex: "inherit" }}
                                  className="custom-control custom-radio custom-control-inline"
                                  title="Move to Sick Test"
                                >
                                  <input
                                    type="radio"
                                    id="movetosick"
                                    disabled={(!(appealsDetails?.student_appeal?.appeal_status != 'Approved' && appealsDetails?.student_appeal?.appeal_status != 'Declined'))}
                                    name="appealOutcome"
                                    checked={appealsDetails && appealsDetails?.student_appeal && appealsDetails?.student_appeal?.appeal_outcome == 1}
                                    onChange={() => {handleChangeAppealOutcome(1)}}
                                    className="custom-control-input"
                                    // onBlur={formik.handleBlur}
                                  />
                                  <label
                                    className="custom-control-label custom-control-inline"
                                    htmlFor="movetosick"
                                  >
                                    Move to Sick Test
                                  </label>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                      </div> : ""}
                      {(appealsDetails?.student_appeal?.appeal_status=="Declined" || appealsDetails?.student_appeal?.appeal_outcome == 1) ? <></> :
                        <div className="col-md-12">
                          <div className="form-group-blk">
                            <li>
                              <span className="title ">
                                Extended assessment date :
                              </span>
                              <span className="form-group-blk w-100">
                                <DatePicker
                                  disabled={!(appealsDetails?.student_appeal?.appeal_status != 'Approved' && appealsDetails?.student_appeal?.appeal_status != 'Declined')}
                                  selected={startDate}
                                  onChange={(date) => {
                                    setStartDate(date);
                                  }}
                                  minDate={new Date()}
                                  className="form-control w-100"
                                  showTimeSelect
                                  dateFormat={FORM_DATE_TIME_FORMAT}
                                />
                              </span>
                            </li>
                          </div>
                        </div>
                      }
                    </div>
                  </ul>
                </div>
              </div>
            ]
          },
        ]}
        bottomButton={appealsDetails?.student_appeal?.appeal_status && appealsDetails?.student_appeal?.appeal_status !== "Approved" && appealsDetails?.student_appeal?.appeal_status !== "Declined" ? [
          <button
            type="button"
            className="btn btn-success"
            // data-dismiss="modal"
            // data-target="#approve"
            // data-toggle="modal"
            title="Approve"
            onClick={() => handleSendResponse("Approve", appealsDetails.student?.student_id)}
            disabled={appealDisable === "Approve"}
          >
            {appealDisable === "Approve" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-check"></i>}
            Approve
          </button>,
          <button
            type="button"
            className="btn btn-danger"
            // data-dismiss="modal"
            title="Decline"
            onClick={() => handleSendResponse("Decline", appealsDetails.student?.student_id)}
            // data-target="#decline"
            // data-toggle="modal"
            disabled={appealDisable === "Decline"}
          >
            {appealDisable === "Decline" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-times"></i>}
            Decline
          </button>
        ] : []}
        validationErrorMessage={[
          messageError
        ]}
      />
      <div
        className="downloading-progress modal fade"
        id="downloadAssessment"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={downloadRef}
        data-backdrop="static"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="swal2-header">
                <div className="swal2-icon swal2-info swal2-icon-show d-flex">
                  <div className="swal2-icon-content">i</div>
                </div>
                <h2 className="swal2-title d-flex mb-4">Downloading...</h2>
                <div className="progress w-100 mb-2">
                  {/* <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={checkTotal == 1 ? percent : ((checkPending / checkTotal) * 100).toFixed(2)} aria-valuemin="0" aria-valuemax="100" style={{ width: checkTotal == 1 ? `${percent}%` : `${((checkPending / checkTotal) * 100).toFixed(2)}%` }}>
                    {checkTotal == 1 ? percent : ((checkPending / checkTotal) * 100).toFixed(2)}%</div> */}
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow={
                      FinalLoadedData && finalTotal > 0
                        ? ((FinalLoadedData / finalTotal) * 100).toFixed(1)
                        : 0
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width:
                        FinalLoadedData && finalTotal > 0
                          ? `${((FinalLoadedData / finalTotal) * 100).toFixed(
                              1
                            )}%`
                          : `0%`,
                    }}
                  >
                    {FinalLoadedData && finalTotal > 0
                      ? ((FinalLoadedData / finalTotal) * 100).toFixed(2)
                      : 0}
                    %
                  </div>
                </div>
                {/* <>File Size : {loaded.toFixed(1)}/{total}  Pending Files : {checkPending}/{checkTotal}</> */}
                {/* {console.log(FinalLoadedData,finalTotal,"finalTotal")} */}
                <>
                  File Size :{" "}
                  {FinalLoadedData && finalTotal > 0
                    ? `${formatBytes(FinalLoadedData, 1)} / ${formatBytes(
                        finalTotal,
                        1
                      )}`
                    : `0 / 0`}
                  {/* Pending Files : {checkPending}/{checkTotal} */}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        // ref={editorRef}
        className="topic-add-modal modal fade"
        id="attachmentModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-attachments-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-file"></i> Attachments
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setAttacmentId("")}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {isLoading ? (
                <SkeletonTicketList />
              ) : (
                <DataTableComponentFrontPagination
                  data={attachmentDataToRender()}
                  columns={attachmentColumns}
                  loading={isLoading}
                  search={attachmentSearch}
                  setSearch={setAttachmentSearch}
                  exportData={exportAttachmentData}
                  exportFileName={"Attachment List"}
                  isInsidePopUp
                  isActionAlignRight
                  defaultSort={{
                    defaultSortColumn: "attachment",
                    defaultSortAsc: true,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GradeMixSubmissionAndResults;
