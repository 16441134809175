import axiosInstance from '../utils/axios';

export const AddBooking = async (values) => {
    return await axiosInstance().post(`/createBooking`, values);
};

export const GetListofBookings = async () => {
    return await axiosInstance().get(`/listBookings`);
};
  
export const GetListofBookingsCancelToken = async (cancelToken) => {
    return await axiosInstance().post(`/listBookings`, {}, {cancelToken});
};

export const GetBookingById = async (id) => {
    return await axiosInstance().get(`/getBookingById/${id}`);
}

export const GetListOnRequestType = async (requestType) => {
    return await axiosInstance().get(`/getListOnRequestType/${requestType}`);
}


export const GetListofBookingFiltersCancelToken = async (cancelToken) => {
    return await axiosInstance().get("/listBookingFilters",{cancelToken});
};

export const DeleteBooking = async (bookingId) => {
    return await axiosInstance().put(`/deleteBooking/${bookingId}`);
};

export const UpdateBookingStatus = async (data) => {
    return await axiosInstance().put("/updateBookingStatus", data);
}

export const UpdateBooking = async (bookingId, data) => {
    return await axiosInstance().put(`updateBooking/${bookingId}`, data);
}

export const GetBookingLinkedCourses = async (request_type,id) => {
    return await axiosInstance().get(`/linked-courses/${request_type}/${id}`);
}