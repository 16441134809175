import React, { useMemo, useState, useEffect } from "react";

import "jspdf-autotable";
import { Link, useParams, useHistory } from "react-router-dom";
import OnlineClassesTable from "./OnlineClassesBlocks/OnlineClassesTable";
import OnlineClassesForm from "./OnlineClassesBlocks/OnlineClassesForm";
import ClassReport from "../ClassReport";
import SelectClass from "./OnlineClassesBlocks/SelectClass";

const OnlineClasses = (props) => {

  const { type } = useParams();

  return (
    // <>
    //   <div className="card-new card card-profile-info-card mb-30">
    //     <div className="card-body">

    //     </div>

    //   </div>


    // </>
    <div>
      {type == "show" ? <OnlineClassesTable userId={props.userId} /> : type == "classReport" ? <ClassReport /> : type=="select"? <SelectClass/>: <OnlineClassesForm />}
    </div>
  );
};

export default OnlineClasses;
