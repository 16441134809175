import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import Swal from "sweetalert2";
import PermissionsGate from "../../../../../utils/permissionGate";
import { DeleteStudentNotification, GetStudentNotificationDetail, GetStudentNotificationListCancelToken } from "../../../../../services/StudentsSettingServices";
import $ from "jquery";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../../utils/Constants";
import hasPermission from "../../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import HtmlParser from "react-html-parser";
import { handleTableScroll } from "../../../../../utils/commonFunction";
import axios from "axios";

function ListOfNotification(props) {
  const [search, setSearch] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [isNotificationData, setIsNotificationData] = useState(true);
  const [notificationDetails, setNotificationDetails] = useState();
  const [deleteRow, setDeleteRow] = useState(true);
  const { id } = useParams();
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const history = useHistory();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    setloading(true);
    let response = hasPermission({ scopes: ["snview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getNotificationList = async () => {
      setloading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetStudentNotificationListCancelToken(id, source.token);
        if (res.status === 200) {
          setNotifications(res.data.data);
          setIsNotificationData(false);
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsNotificationData(false);
          setloading(false);
        }
      }
    };
  
    getNotificationList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [deleteRow]);

  const handleViewNotification = (notificationId) => {
    GetStudentNotificationDetail(notificationId, id)
      .then((res) => {
        setNotificationDetails(res.data.notification_data);
      })
      .catch((err) => console.log("err", err));
  };

  const handleReturningIcon = (row) => {
    return (
      <>
        {row.isEmailActive ? (
          <>
            <i title="Email notification" className="fal fa-envelope"></i>&nbsp;
          </>
        ) : (
          ""
        )}
        {row.isSmsActive ? (
          <>
            <i title="SMS notification" className="fal fa-comment"></i>&nbsp;
          </>
        ) : (
          ""
        )}
        {row.isPushnotificationActive ? <i title="Mobile notification" className="fal fa-mobile"></i> : ""}

        {!row.isPushnotificationActive && !row.isSmsActive && !row.isEmailActive ? "-" : ""}
      </>
    );
  };

  const handleDelete = (ID) => {
    // console.log(ID);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteStudentNotification(ID)
          .then((res) => {
            setDeleteRow(!deleteRow);
            Swal.fire("Deleted!", "Notification has been deleted.", "success");
          })
          .catch((err) => {
            setDeleteRow(!deleteRow);
            Swal.fire("Not Deleted!", "Somthing Went Wrong.", "warning");
            console.log("error :", err);
          });
      }
    });
  };

  const GetNotificationDetails = (row) => {
    setNotificationDetails(row);
  };

  const sortingSubject = (rowA, rowB) => {
    const name1 = rowA.subject.toString().trim();
    const name2 = rowB.subject.toString().trim();
    return name1.localeCompare(name2);
  };

  const sortingDate = (rowA, rowB) => {
    const name1 = rowA?.start.toString().length > 10 ? rowA?.start/1000 : rowA?.start
    const name2 = rowB?.start.toString().length > 10 ? rowB?.start/1000 : rowB?.start
    return new Date(name1) - new Date(name2);
  };

  const notificationColumns = useMemo(() => [
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      sortFunction: sortingSubject,
      wrap: true,
      // maxWidth: "250px",
      cell: (row) =>
        row.subject ? (
          <span className="">
            {!row.draft_notification ? (
                <span className="as-text-blue curser feature-name" onClick={() => handleViewNotification(row.id)} data-toggle="modal" data-target="#notifications" title={row.subject}>
                  <span className="textLimit100">{row.subject.trim()}</span>
                </span>
              ) : (
                <Link className="as-text-blue curser feature-name" to={`/studentAdministration/students/open/${id}/notifications/editNotification/${row.id}`} title={row.subject}>
                  <span className="textLimit100">{row.subject.trim()}</span>
                </Link>
              )}
          </span>
        ) : (
          // <Link className="as-text-blue curser " title={row.subject}>{TrimText(row.subject,20)}</Link>
          <p className="cat">
            <i className="fas fa-circle mr-1"></i> -
          </p>
        ),
    },
    {
      name: "Sent Via",
      selector: "sentVia",
      sortable: false,
      wrap: true,
      // maxWidth: "250px",
      cell: (row) => (
        <div className="ticket-id">
          <span className="overflow-ellipsis2">
            {row.draft_notification ? <div className="as-green-bg as-widget" title={row.start && "Draft Saved On " + moment.unix(row.start).format(TABLE_DATE_TIME_FORMAT)}>{"Draft Saved On " + moment.unix(row.start).format(TABLE_DATE_TIME_FORMAT)}</div> : handleReturningIcon(row)}
          </span>
        </div>
      ),
    },
    // {
    //   name: "Related To",
    //   selector: "related_to",
    //   sortable: true,
    //   wrap: true,
    //   // maxWidth: "250px",
    //   cell: (row) => (row.related_to ? <p>{row.related_to}</p> : <p className="cat">{/* <i className="fas fa-circle mr-1"></i> N/A */}-</p>),
    // },
    {
      name: "Sent By",
      selector: "sendBy",
      // sortFunction: sortingSendBy,
      // sortable: true,
      // maxWidth: "80px",
      // minWidth: "80px",
      cell: (row) => (
        <span className="feature-name" title={row?.sendByStudent && row?.sendByStudent?.log}>
          <span className="textLimit100">{row?.sendByStudent && row?.sendByStudent?.log != null ? row?.sendByStudent?.log : "-"}</span>
        </span>
      ),
    },
    {
      name: "Sent Date",
      selector: "start",
      sortable: true,
      wrap: true,
      sortFunction: sortingDate,
      // maxWidth: "250px",
      cell: (row) => (
        <>
          {row.start ? (
            <div className="dateTime">
              <p className="right-space">{moment.unix(row.start.toString().length == 13 ? row.start/1000 : row.start).format(TABLE_DATE_FORMAT)}</p>
              <p>{moment.unix(row.start.toString().length == 13 ? row.start/1000 : row.start).format(TABLE_TIME_FORMAT)}</p>
            </div>
          ) : (
            <p className="cat">
              <i className="fas fa-circle mr-1"></i> -
            </p>
          )}
        </>
      ),
    },
    // {
    //   name: "Read",
    //   selector: "read",
    //   sortable: true,
    //   wrap: true,
    //   // maxWidth: "250px",
    //   cell: (row) => (row.read ? <p>{row.read}</p> : <p className="cat">{/* <i className="fas fa-circle mr-1"></i> N/A */}-</p>),
    // },
    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            {row.draft_notification ? (
              <>
                <button className="btn btn-primary rounded-circle" title="View" data-toggle="modal" data-target="#notifications" onClick={() => handleViewNotification(row.id)}>
                  <i className="fal fa-eye"></i>
                </button>
                <div className="dropdown btn-dropdown-item">
                  <button className="btn btn-primary rounded-circle dropdown-toggle" title="More" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fal fa-ellipsis-h-alt"></i>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <Link className="btn btn-primary rounded-circle" title="Open" to={`/studentAdministration/students/open/${id}/notifications/editNotification/${row.id}`}>
                      <i className="fal fa-folder-open"></i>
                    </Link>
                    <PermissionsGate scopes={["sndelete"]} errorProps={{ disabled: true }}>
                      <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => handleDelete(row.id)}>
                        <i className="fal fa-trash-alt"></i>
                      </button>
                    </PermissionsGate>
                  </div>
                </div>
              </>
            ) : (
              <>
                <button className="btn btn-primary rounded-circle" title="View" data-toggle="modal" data-target="#notifications" onClick={() => GetNotificationDetails(row)}>
                  <i className="fal fa-eye"></i>
                </button>
                <PermissionsGate scopes={["sndelete"]} errorProps={{ disabled: true }}>
                  <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => handleDelete(row.id)}>
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </>
            )}
          </div>
        </div>
      ),
    },
  ], []);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const notificationsToRender = () => {
    let updatedData = [];
    let allData = notifications;
    if (search.length) {
      let tempName = allData.filter((item) => {
        let includes = item.subject.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      //moment(row.sentDate).format(TABLE_DATE_FORMAT)
      let tempDate = allData.filter((item) => {
        let includes = item.start && moment.unix(item.start).format(TABLE_DATE_FORMAT).toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSentBy = allData.filter((item) => {
        let includes = item?.sendByStudent?.log?.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        // ...tempId,
        ...tempName,
        ...tempDate,
        ...tempSentBy,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search.length) {
      return updatedData;
    } else {
      return notifications;
    }
  };

  const resetFilter = () => {
    setSearch("");
  };

  const exportData = (fileType, fileName) => {
    let data = notificationsToRender();
    const header = ["Subject", "Sent Via", "Sent By", "Sent Date"];
    data = data?.map((row, index) => ({
      ...row,
      // ID: row.id,
      Subject: row.subject ? row.subject : "-",
      "Sent Via": row.draft_notification ? "Draft Saved On " + moment.unix(row.start).format(TABLE_DATE_TIME_FORMAT) : ((row.isEmailActive ? "Email " : "") + (row.isPushnotificationActive ? " Push" : "")),
      ["Sent By"]: row.sendByStudent ? row.sendByStudent.log : "-",
      ["Sent Date"]: row.start ? moment.unix(row.start).format(TABLE_DATE_TIME_FORMAT) : "-"
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });

      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Subject, row["Sent Via"], row["Sent By"], row["Sent Date"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <>
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                      <div id="assessment-table-main_filter" className="dataTables_filter">
                        <label>
                          <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                      <div className="filter-scroll">
                          <div className={`filter-scroll-inner filter-custom-new`}>

                          </div>
                      </div>
                      <div className="reset-btn-group">
                        <div className="button-reset dropdown-comman">
                          <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                            <i className="fal fa-redo"></i>Reset
                          </button>
                        </div>
                        <div className="files-export-group">
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("xlsx", "Notifications");
                            }}
                            title="Export spreadsheet"
                          >
                            <i className="fal fa-file-excel icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("csv", "Notifications");
                            }}
                            title="Export CSV"
                          >
                            <i className="fal fa-file-csv icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("pdf", "Notifications");
                            }}
                            title="Export PDF"
                          >
                            <i className="fal fa-file-pdf icon"></i>
                          </button>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="search-filter-div-right">
              <div class=" filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <PermissionsGate
                    scopes={["snadd"]}
                    // errorProps={{ disabled: true }}
                    RenderError={() => (
                      <button disabled className="btn btn-primary" title="New Notification">
                        <i className="fal fa-plus"></i>New Notification
                      </button>
                    )}
                  >
                    <Link to={`/studentAdministration/students/open/${id}/notifications/addNotification`}>
                      <button className="btn btn-primary" title="New Notification">
                        <i className="fal fa-plus"></i>New Notification
                      </button>
                    </Link>
                  </PermissionsGate>
                </div>
              </div>
          </div>
      </div>

        {isNotificationData ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            data={notificationsToRender()}
            columns={notificationColumns}
            pagination
            // paginationServer
            defaultSortField="start"
            defaultSortAsc={false}
            // onChangePage={handlePageChange}
            // paginationTotalRows={totalRows}
            // progressPending={isNotificationData}
            // progressComponent={<SkeletonTicketList />}
            // onChangeRowsPerPage= {handleRowChange}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
          // <DataTable
          //   paginationDefaultPage={page}
          //   progressPending={loading}
          //   data={notifications}
          //   progressComponent={<SkeletonTicketList />}
          //   defaultSortField={sortkey}
          //   defaultSortAsc={false}
          //   columns={notificationColumns}
          //   pagination={true}
          //   // noDataComponent={Str.noRecord}
          //   onSort={handleSort}
          //   sortServer
          //   paginationServer
          //   paginationTotalRows={totalRows}
          //   onChangeRowsPerPage={handlePerRowsChange}
          //   onChangePage={handlePageChange}
          //   highlightOnHover={false}
          //   paginationPerPage={perPage}
          //   paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          // />
        )}
      </div>
      <div className="topic-add-modal modal fade" id="notifications" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
            <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fas fa-bars"></i> Notification Details</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>

              <div className="t p-30px">
                <div className="topic-add-modal-content">
                  <div className="row center-label">
                    <div className="col-md-12 col-lg-12">
                      <div className="form-group-blk mb-3">
                        <div className="notification-view basic-info-box-list">
                          <ul className="p-0 course-name-merge">
                            <li>
                              <span className="title">Subject/Title : </span>
                              <span>{notificationDetails && notificationDetails.subject}</span>
                            </li>
                            <li>
                              <span className="title ">Received Date/Time : </span>
                              <span>{notificationDetails && notificationDetails.start && moment.unix(notificationDetails.start).format(TABLE_DATE_TIME_FORMAT)}</span>
                            </li>
                          </ul>
                          <ul className="p-0 course-name-merge">
                            <li>
                              <span className="title">Related Programme/Subject : </span>
                              <span>{notificationDetails && notificationDetails.regarding}</span>
                            </li>
                          </ul>
                          <ul className="p-0 course-name-merge">
                            <li>
                              <span className="title">Content : </span>
                              <span className="word-break-all">{notificationDetails?.content ? HtmlParser(notificationDetails?.content) : 'NA'}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                          
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-3">
                  <a
                    href={!notificationDetails || !notificationDetails.attachment ? "" : IMAGE_URL + "/" + notificationDetails.attachment.replace("/home/myaie/public_html/", "").replace("public/", "")}
                    target="_blank"
                    download
                  >
                    <button
                      className="btn btn-primary mr-1"
                      title="Download Document"
                      disabled={!notificationDetails || notificationDetails.attachment == "" || notificationDetails.attachment == null || !notificationDetails.attachment}
                    >
                      <i className="fal fa-arrow-down"></i>
                      Download Document
                    </button>
                  </a>

                  <button type="button" title="Close" className="btn btn-close btn-danger ml-1" data-dismiss="modal">
                    <i className="fal fa-times"></i>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListOfNotification;
