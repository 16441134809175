import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../common/Header';
import SideBar from '../../common/SideBar';
import { Scrollbar } from 'react-scrollbars-custom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DisplaySchedule } from './DisplaySchedules';
import { CampusFilters } from './CampusFilters';
import { GetCampusMgtList } from '../../../services/CampusMgtService';
import axios from 'axios';
import { RoomFilters } from './RoomFilters';
import { CalendarView } from './Calendar';
import Select from 'react-select';
// import { GetListOnRequestType } from '../../../services/BookingServices';
import { GetScheduleCourses, GetScheduleLecturersList } from '../../../services/ScheduleManagementService';
import debounce from 'lodash/debounce';
import AsyncSelect from 'react-select/async';

export default function ScheduleManagement() {
  const history = useHistory();
  const [campusArr, setCampusArr] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [campusValues_, setCampusValues_] = useState({
    campusId_: 0,
    campusName_: '',
  });
  const [roomValues_, setRoomValues_] = useState({
    roomId_: 0,
    roomName_: '',
  });
  const [courseId, setCourseId] = useState(0);
  const [lecturerId, setLecturerId] = useState(0);
  const [calendarView, setCalendarView] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedLecturer, setSelectedLecturer] = useState(null);
  const [lecturer, setLecturer] = useState([]);
  const [isLecturerFilterChecked, setIsLecturerFilterChecked] = useState(false);
  const [isCourseFilterChecked, setIsCourseFilterChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [courseOptions, setCourseOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [calendarEvent_, setCalendarEvent] = useState('');
  const [activeRoomId, setActiveRoomId] = useState(-1);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const limit = 50;
  let isPast = 0;

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach((source) => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        let res = await GetCampusMgtList(source.token);
        if (res.status == 200) {
          let tempCampusArr = [];
          res.data.data_list.map((data) => {
            tempCampusArr.push({
              value: `${data.id}`,
              label: `${data.campus_name}`,
            });
          });
          setCampusArr(tempCampusArr);
          setIsDataLoaded(true);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    getData();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  useEffect(() => {
    if (isLecturerFilterChecked) {
      const getListOnRequestType = async () => {
        try {
          const response = await GetScheduleLecturersList();
          const listoflecturers = response.data?.data?.map((user) => ({
            label: `${user.Firstname} ${user.Lastname}`,
            value: user.UserID,
          }));
          setLecturer(listoflecturers);
        } catch (error) {
          console.log('Error fetching lecturers:', error);
        }
      };
      getListOnRequestType();
    }
  }, [isLecturerFilterChecked]);

  const loadOptions = async (inputValue, callback, newPage = 1) => {
    try {
      setIsLoading(true);
      const response = await GetScheduleCourses({
        page: newPage,
        limit: limit,
        search: inputValue,
        isPast,
      });
      const options = response.data?.data?.map((course) => ({
        label: course.course_name,
        value: course.intake_id,
      }));
      if (newPage === 1) {
        setCourseOptions(options);
        callback(options);
      } else {
        setCourseOptions((prevOptions) => [...prevOptions, ...options]);
        callback([...courseOptions, ...options]);
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
      callback([]);
    } finally {
      setIsLoading(false);
    }
  };
  const debouncedLoadOptions = useCallback(
    debounce((inputValue, callback) => {
      loadOptions(inputValue, callback);
    }, 500),
    []
  );

  const handleCourseId = (selectedOption) => {
    setSelectedCourse(selectedOption);
    setCourseId(selectedOption ? selectedOption.value : 0);
  };

  const handleMenuScrollToBottom = () => {
    const newPage = page + 1;
    setPage(newPage);
    loadOptions(
      inputValue,
      (options) => {
        setCourseOptions((prevOptions) => [...prevOptions, ...options]);
      },
      newPage
    );
  };

  useEffect(() => {
    if (isCourseFilterChecked) {
      setPage(1);
      loadOptions('', () => {});
    }
  }, [isCourseFilterChecked]);

  const handleCourseFilter = (event) => {
    setIsCourseFilterChecked(event.target.checked);
    if (event.target.checked) {
      setPage(1);
    }
    else {
      setSelectedCourse(null);
      setCourseId(0);
    }
  };

  const handleLecturerId = (selectedOption) => {
    setSelectedLecturer(selectedOption);
    setLecturerId(selectedOption ? selectedOption.value : 0);
  };

  const handleLectureFilter = (event) => {
    setIsLecturerFilterChecked(event.target.checked);
    if (!event.target.checked) {
      setSelectedLecturer(null);
      setLecturerId(0);
    }
    
  };

  const handleCampusId = (campusId, campusRoom) => {
    setCampusValues_({
      campusId_: campusId,
      campusName_: campusRoom,
    });
  };

  const handleRoomId = (roomId, roomName) => {
    setSelectedRoom({ value : roomName, label: roomName, id: roomId });
    setActiveRoomId(roomId)
    setRoomValues_({
      roomId_: roomId,
      roomName_: roomName,
    });
  };

  const handleResetButton = () => {
    setSelectedLecturer(null);
    setSelectedRoom(null)
    setIsLecturerFilterChecked(false);
    setSelectedCourse(null);
    setIsCourseFilterChecked(false);
    setLecturerId(0);
    setCourseId(0);
    setRoomValues_({ ...roomValues_, roomId_: 0 });
    setActiveRoomId(-1);
  };

  const handleCalendarView = (isCalendarView,calendarEvent) => {
    setCalendarView(isCalendarView);
    setCalendarEvent(calendarEvent)
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    setPage(1);
    return newValue;
  };

  return (
    <>
      <>
        <Header />
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar />
          <div className="schedule-sidebar-right">
            <div className="calendar-wrap">
              {!calendarView && (
                <div className="event-calendar-main d-flex h-100">
                  <div className="schedule-calendar-drawer-left bg-white">
                    <div className="calendar-panel-main">
                      <div className="calendar-panel-title">
                        {campusValues_.campusId_ !== 0 && (
                          <button
                            onClick={() => {
                              setCampusValues_({
                                campusId_: 0,
                                campusName_: '',
                              });
                              setRoomValues_({
                                roomId_: 0,
                                roomName_: 0,
                              });
                            }}
                            title="Back"
                            className="schedule-back-btn"
                          >
                            <i className="fal fa-angle-left mr-2"></i>Back
                          </button>
                        )}
                        <div className="calendar-panel-left"></div>
                        <div className="calendar-panel-right">
                          <div className="custom-control custom-checkbox text-left box-tm"></div>
                        </div>
                      </div>
                      <div className="calendar-panel-body">
                        {isDataLoaded && (
                          <Scrollbar>
                            {campusValues_.campusId_ === 0 && (
                              <CampusFilters
                                campusList={campusArr}
                                onCampusChange={(campusId_, campusName_) =>
                                  handleCampusId(campusId_, campusName_)
                                }
                                key={campusValues_.campusId_}
                              />
                            )}
                            {campusValues_.campusId_ !== 0 && (
                              <RoomFilters
                                campusName_={campusValues_.campusName_}
                                campusId__={campusValues_.campusId_}
                                onRoomChange={(room_id, room_name) =>
                                  handleRoomId(room_id, room_name)
                                }
                                key={campusValues_.campusId_}
                                activeRoomId={activeRoomId}
                                calendarEvent={calendarEvent_}
                                selectedRoom__={selectedRoom}
                              />
                            )}
                            <div>
                              {campusValues_.campusId_ === 0 && (
                                <div className="schedule-settings-header">
                                  <i className="fal fa-cog mr-2"></i>
                                  Settings
                                </div>
                              )}
                              <div className="form-group-blk mt-4 ml-3">
                                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="filterLecture"
                                    name="filterLecture"
                                    onChange={(e) => handleLectureFilter(e)}
                                    checked={isLecturerFilterChecked}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="filterLecture"
                                  >
                                    Filter by Employee
                                  </label>
                                </div>
                              </div>
                              {isLecturerFilterChecked && (
                                <div className="form-icon-group">
                                  <Select
                                    className={
                                      'form-control custom-select-box m-3'
                                    }
                                    options={lecturer}
                                    value={selectedLecturer}
                                    name="lecturer"
                                    onChange={handleLecturerId}
                                    isClearable
                                    maxMenuHeight={175}
                                    placeholder="Select Lecturer"
                                  />
                                </div>
                              )}
                              <div className="form-group-blk mt-4 ml-3">
                                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="filterCourse"
                                    name="filterCourse"
                                    onChange={(e) => handleCourseFilter(e)}
                                    checked={isCourseFilterChecked}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="filterCourse"
                                  >
                                    Filter by Course
                                  </label>
                                </div>
                              </div>
                              {isCourseFilterChecked && (
                                <div className="form-icon-group">
                                  <div className="form-control custom-select-box m-3">
                                    <AsyncSelect
                                      cacheOptions
                                      defaultOptions={courseOptions}
                                      loadOptions={debouncedLoadOptions}
                                      onChange={handleCourseId}
                                      value={selectedCourse}
                                      onMenuScrollToBottom={
                                        handleMenuScrollToBottom
                                      }
                                      onInputChange={handleInputChange}
                                      placeholder="Select Course"
                                      isClearable
                                      isLoading={isLoading}
                                      options={courseOptions}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </Scrollbar>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="schedule-event-calendar-right">
                    <div className="my-tickets-heading-blk flex-direction mb-2">
                      <div className="d-flex align-items-center ">
                        <div className="name-w-head d-flex align-items-center ">
                          <h4 className="text-left-align landing-heading heading_color_dynamic">
                            Schedule<span> Management</span>
                          </h4>
                        </div>
                        <div className="text-md-right action2-blk">
                          <div className="ticket-view-btn d-flex align-items-center">
                            <div
                              className="add-new-ticket-bx"
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <button
                                onClick={() => history.goBack()}
                                title="Back"
                                className="btn btn-white-bordered"
                              >
                                <i className="fal fa-angle-left"></i>Back
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <>
                        <DisplaySchedule
                          campusID={campusValues_.campusId_}
                          campusName={campusValues_.campusName_}
                          roomID={roomValues_.roomId_}
                          roomName={roomValues_.roomName_}
                          lecturerID={lecturerId}
                          courseID={courseId}
                          // isLecturerFilterChecked_={isLecturerFilterChecked}
                          // isCourseFilterChecked_={isCourseFilterChecked}
                          onResetButtonClick={handleResetButton}
                          onCalendarView={(isCalendarView,calendarEvent) =>
                            handleCalendarView(isCalendarView,calendarEvent)
                          }
                        />
                      </>
                    </div>
                  </div>
                </div>
              )}
              {calendarView && (
                <>
                  <div className="my-tickets-heading-blk flex-direction mb-2">
                    <div className="d-flex align-items-center" style={{padding : '20px 0px 0px 48px' }}>
                      <div className="name-w-head d-flex align-items-center ">
                        <h4 className="text-left-align landing-heading heading_color_dynamic">
                          Schedule<span> Management</span>
                        </h4>
                      </div>
                      <div className="text-md-right action2-blk">
                        <div className="ticket-view-btn d-flex align-items-center">
                          <div
                            className="add-new-ticket-bx"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <button
                              onClick={() => setCalendarView(false)}
                              title="Back"
                              className="btn btn-white-bordered"
                            >
                              <i className="fal fa-angle-left"></i>Back
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <CalendarView
                      onListView={handleCalendarView}
                      campusID={campusValues_.campusId_}
                      roomID={roomValues_.roomId_}
                      campusName_={campusValues_.campusName_}
                      roomName={roomValues_.roomName_}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
}
