import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { GetUserDetail, UpdateUserLoginDetail } from "../../../services/UserService";

const LoginDetailsEditUser = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState();

  useEffect(() => {
    GetUserDetail({ UserID: id })
      .then((res) => {
        // console.log("editdata", res.data);
        setUserData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Email: userData?.Email,
      Password: userData?.Password,
      Password_confirmation: "",
      Webphone_server_address : "",
      Webphone_username : "",
      Webphone_password : ""
    },
    validationSchema: Yup.object({
      Email: Yup.string().email("Invalid email address").required("Email is required"),
      Password: Yup.string()
        .required("Password is required")
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"),
      Password_confirmation: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("Password"), null], "Passwords must match"),
        Webphone_server_address: Yup.string().required("Server address is required"),
        Webphone_username: Yup.string().required("Username is required"),
        Webphone_password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      // console.log("values", values);
      const body = {
        ...values,
        UserID: id,
      };
      UpdateUserLoginDetail(body)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
          resetForm();
        })
        .catch((error) => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops!!!",
            text: "Something went wrong...",
          }).then((result) => {});
        });
    },

    onSubmitWebphone: (values, { resetForm }) => {
      // console.log("values", values);
      const body = {
        ...values,
        UserID: id,
      };
      UpdateUserLoginDetail(body)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
          resetForm();
        })
        .catch((error) => {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops!!!",
            text: "Something went wrong...",
          }).then((result) => {});
        });
    },
  });
  return (
    <>
      <div className="card card-profile-info-card">
        <div className="card-header">Login Details</div>
        <div className={`card-body `}>
          <div className="card-body-inr card-body-info">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Email *</label>
                    <input
                      type="email"
                      className={"form-control" + (formik.errors.Email && formik.touched.Email ? " is-invalid" : "")}
                      name="Email"
                      disabled
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.Email && formik.touched.Email ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                  <div className="password-criteria mt-3">
                    <h5>Password Criteria!</h5>
                    <ul className="text-danger">
                      <li>Minimum 8 characters.</li>
                      <li>At least 1 uppercase letter.</li>
                      <li>At least 1 lowercase letter.</li>
                      <li>At least 1 number.</li>
                      <li>At least 1 special character.</li>
                    </ul>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>Password *</label>
                    <input
                      type="password"
                      className={"form-control" + (formik.errors.Password && formik.touched.Password ? " is-invalid" : "")}
                      name="Password"
                      value={formik.values.Password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.Password && formik.touched.Password ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>Confirm Password *</label>
                    <input
                      type="password"
                      className={"form-control" + (formik.errors.Password_confirmation && formik.touched.Password_confirmation ? " is-invalid" : "")}
                      name="Password_confirmation"
                      value={formik.values.Password_confirmation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.Password_confirmation && formik.touched.Password_confirmation ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group form-group-save-cancel">
                <button className="btn btn-save btn-success" type="submit" title="Save" disabled={!(formik.dirty && formik.isValid)}>
                  <i className="fal fa-save"></i>
                  Save
                </button>
                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => formik.resetForm()}>
                  <i className="fal fa-times"></i>
                  Cancel
                </button>
              </div>
              {formik.touched.Email && formik.errors.Email ? <div className="invalid-feedback d-block">{formik.errors.Email}</div> : null}
              {formik.touched.Password && formik.errors.Password ? <div className="invalid-feedback d-block">{formik.errors.Password}</div> : null}
              {formik.touched.Password_confirmation && formik.errors.Password_confirmation ? <div className="invalid-feedback d-block">{formik.errors.Password_confirmation}</div> : null}
            </form>
            <form onSubmitWebphone={formik.handleSubmit}>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label>Server Address *</label>
                    <input
                      type="text"
                      className={"form-control" + (formik.errors.Webphone_server_address && formik.touched.Webphone_server_address ? " is-invalid" : "")}
                      name="Webphone_server_address"
                      disabled
                      value={formik.values.Webphone_server_address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.Webphone_server_address && formik.touched.Webphone_server_address ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>Username *</label>
                    <input
                      type="Webphone_username"
                      className={"form-control" + (formik.errors.Webphone_username && formik.touched.Webphone_username ? " is-invalid" : "")}
                      name="Password"
                      value={formik.values.Password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.Password && formik.touched.Password ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>Confirm Password *</label>
                    <input
                      type="password"
                      className={"form-control" + (formik.errors.Password_confirmation && formik.touched.Password_confirmation ? " is-invalid" : "")}
                      name="Password_confirmation"
                      value={formik.values.Password_confirmation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.Password_confirmation && formik.touched.Password_confirmation ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="form-group form-group-save-cancel">
                <button className="btn btn-save btn-success" type="submit" title="Save" disabled={!(formik.dirty && formik.isValid)}>
                  <i className="fal fa-save"></i>
                  Save
                </button>
                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => formik.resetForm()}>
                  <i className="fal fa-times"></i>
                  Cancel
                </button>
              </div>
              {formik.touched.Email && formik.errors.Email ? <div className="invalid-feedback d-block">{formik.errors.Email}</div> : null}
              {formik.touched.Password && formik.errors.Password ? <div className="invalid-feedback d-block">{formik.errors.Password}</div> : null}
              {formik.touched.Password_confirmation && formik.errors.Password_confirmation ? <div className="invalid-feedback d-block">{formik.errors.Password_confirmation}</div> : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginDetailsEditUser;
