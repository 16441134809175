import React, { createRef, useEffect, useState, useMemo } from "react";
import {
  GetListOfRole,
  GetUserDetail,
  UpdateUserDetail,
  GetListOfUser,
} from "../../../services/UserService";
import {
  ProfileImageDetailService,
  RemoveProfileImage,
} from "../../../services/ProfileService";
import { useFormik } from "formik";
import {
  MOBILE_REGEX,
  NAME_REGEX,
  IMAGE_URL,
  API_BASE,
  URL_REGEX,
  VIMEO_REGEX,
  YOUTUBE_REGEX
} from "../../../utils/Constants";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import cog from "../../../assets/images/myProfile.svg";
import Select from "react-select";
import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../../assets/css/filePond.css";
// import { Day, Month, Year, Location, Company, Title, Active } from "../DataReactSelect";
import {
  AddStudentTabListData,
  GetListOfStudentHubBrandList,
  GetStudentTabListDetail,
  EditStudentTabListData,
  GetListOfStudentHubTabList,
  GetListOfStudentHubCategories,
  DeleteCategoryList,
  AddStudentHubCategoryData,
  EditStudentHubCategoryData,
  GetCategoryDetail,
  GetListOfResources,
  GetListOfResourcesDetail,
  AddResources,
  EditResource,
} from "../../../services/StudentService";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import { Link, useLocation, useParams } from "react-router-dom";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import ReactDOM from "react-dom";
import {
  EditorState,
  convertToRaw,
  RichUtils,
  CompositeDecorator,
  ContentState,
  convertFromHTML,
  convertFromRaw,
  AtomicBlockUtils
} from "draft-js";
// import Editor from "draft-js-plugins-editor";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "draft-js-mention-plugin";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import { id } from "date-fns/locale";
import { getToken } from "../../../utils/Auth";
import { Editor } from "react-draft-wysiwyg";
import { RenderAttachList } from "../../common/RenderAttachList";
import PermissionsGate from "../../../utils/permissionGate";

let Id = "";
let TabId = "";
let ResourceId = "";
let Action = "";

registerPlugin(FilePondPluginImagePreview);
const ResourceAction = () => {
  const history = useHistory();
  const location = useLocation();
  const [userData, setUserData] = useState();
  const [visibleList, setVisibleList] = useState([
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ]);
  const [userList, setUserListData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [resourceslistData, setResourceslistData] = useState([]);
  const [resourceData, setResourceData] = useState("");
  const [tabslistData, setTabslistData] = useState([]);
  const [linkedBrandList, setLinkedBrandList] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [selectKeywords, setSelectedKeywords] = useState([]);
  const [types, setTypes] = useState([
    {
      value: "Document",
      label: "Document",
    },
    {
      value: "Vimeo",
      label: "Vimeo",
    },
    {
      value: "Youtube",
      label: "Youtube",
    },
    {
      value: "Link",
      label: "Link",
    },
    {
      value: "Article",
      label: "Article",
    },
    {
      value: "Label",
      label: "Label",
    },
  ]);
  const [deleterow, setDeleteRow] = useState(false);
  const [search, setSearch] = useState("");
  const [isLoaded, setIsLoaded] = useState(true);
  const filePondRef = createRef();
  const btnAttachment = createRef();
  const [showFilePond, setShowFilePond] = useState(false);
  const [isZip, setIsZip] = useState(false);
  const [isPdf, setIsPdf] = useState(false);
  const [isDocx, setIsDocx] = useState(false);
  const [isRar, setIsRar] = useState(false);
  const [isXlsx, setIsXlsx] = useState(false);
  const [docType, setDocType] = useState("");
  const [fileName, setFileName] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [files, setFiles] = useState([]);
  const [removeFile, setRemoveFile] = useState(null);
  // const [insertedId, setInsertedId] = useState("");

  const HandleSpan = (props) => {
    return (
      <span style={styles.handle} data-offset-key={props.offsetKey}>
        {props.children}
      </span>
    );
  };

  const compositeDecorator = new CompositeDecorator([
    { strategy: handleStrategy, component: HandleSpan },
  ]);

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  const HANDLE_REGEX = /\@[\w]+/g;

  function handleStrategy(contentBlock, callback, contentState) {
    findWithRegex(HANDLE_REGEX, contentBlock, callback);
  }

  function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  const styles = {
    editor: {
      border: "1px solid #ddd",
      cursor: "text",
      fontSize: 16,
      minHeight: "40px",
      padding: 10,
      background: "#000",
    },
    handle: {
      color: "rgba(98, 177, 254, 1.0)",
      direction: "ltr",
      unicodeBidi: "bidi-override",
    },
  };

  const INLINE_STYLES = [
    { label: <i>I</i>, style: "ITALIC" },
    { label: <b>B</b>, style: "BOLD" },
    { label: <u>U</u>, style: "UNDERLINE" },
  ];

  const StyleButton = (props) => {
    let onClickButton = (e) => {
      e.preventDefault();
      props.onToggle(props.style);
    };
    return (
      <button
        className="fontStyle draft-editor-btn"
        onMouseDown={onClickButton}
      >
        {props.label}
      </button>
    );
  };

  const InlineStyleControls = (props) => {
    return (
      <div>
        {INLINE_STYLES.map((type, index) => (
          <StyleButton
            key={index}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        ))}
      </div>
    );
  };

  const onInlineClick = (e) => {
    let nextState = RichUtils.toggleInlineStyle(editorState, e);
    setEditorState(nextState);
  };

  const { tabId, catId, id } = useParams();
  TabId = tabId;
  Id = catId;
  ResourceId = id;
  if (history.location.state) {
    const { action } = history.location.state;
    // Id = catId;
    Action = action;
  }


  useEffect(() => {
    if (
      location.pathname !=
      `/support/student-hub-library/student-hub/${TabId}/section/${Id}/resource/create`
    ) {
      GetListOfResourcesDetail({ resourceId: ResourceId })
        .then((res) => {
          let resource = res?.data?.hub_resource;
          setFileName(resource?.link?.substring(resource?.link?.lastIndexOf('/') + 1))
          // alert(filename);
          // resource.links = resource.link;

          let type =
            resource?.type?.charAt(0).toUpperCase() + resource?.type?.slice(1);
          resource.type = type;
          let link;
          if (resource && resource.link && resource.link.includes("https")) {
            link = resource.link
          } else {
            if (resource && resource.link && resource.link.includes("public/")) {
              link = IMAGE_URL + "/" + resource.link.replace("public/", "");
            } else if (resource && resource.link && !resource.link.includes("public/") && resource.type == "Link") {
              link = resource.link;
            } else if (resource && resource.link && !resource.link.includes("public/")) {
              link = IMAGE_URL + "/" + resource.link;
            } else {
              link = ""
            }
          }
          // console.log("resource.link",resource.link)
          // isPdf = resource.link.includes("png") || resource.link.includes("jpg") || resource.link.includes("jpeg")
          if (resource?.link?.includes(".pdf")) {
            setIsPdf(true);
            setDocType("pdf")
          }
          else if (resource?.link?.includes(".zip")) {
            setIsZip(true);
            setDocType("zip")
          }
          else if (resource?.link?.includes(".docx")) {
            setIsDocx(true);
            setDocType("docx")
          }
          else if (resource?.link?.includes(".rar")) {
            setIsRar(true);
            setDocType("rar")
          }
          else if (resource?.link?.includes(".docx")) {
            setIsDocx(true);
            setDocType("docx")
          } else {
            setDocType("docx")
          }
          resource.links = link;
          setResourceData(resource);
          setSelectedKeywords(resource?.keywords != "" ? resource?.keywords?.split(",") : []);
          // setEditorState(
          //   EditorState.createWithContent(
          //     ContentState.createFromBlockArray(
          //       convertFromHTML(resource?.textBody)
          //     )
          //   )
          // );
          const contentBlock = convertFromRaw(JSON.parse(resource.textBody));
          setEditorState(EditorState.createWithContent(contentBlock));
          //   setSelectedKeywords(res.data?.hub_categories.categoryKeywords?.split(","));
        })
        .catch((err) => console.log(err));

    }

  }, []);

  const [image, setImage] = useState();

  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
  const value = blocks
    .map((block) => (!block.text.trim() && "\n") || block.text)
    .join("\n");



  const formik = useFormik({
    enableReinitialize:
      location.pathname ==
        `/support/student-hub-library/student-hub/${TabId}/section/${Id}/resource/create`
        ? false
        : true,
    initialValues: {
      title: resourceData?.title,
      description: resourceData?.description,
      isPublished:
        resourceData?.isPublished && resourceData?.isPublished == 1
          ? true
          : false,
      keywords: selectKeywords,
      type: resourceData?.type,

      // document: resourceData?.document,
      isFeatured:
        resourceData?.isFeatured && resourceData?.isFeatured == 1
          ? true
          : false,
      link: resourceData?.links,
      duration: resourceData?.duration,
      videoId: resourceData?.video_id,
      textBody: resourceData?.textBody,
      // vimeo: resourceData?.links,
      // youtube: resourceData?.links,
    },



    validationSchema: Yup.object({
      title: Yup.string().required("Name is required"),
      keywords: Yup.array()
        .min(1, "At least one keyword is required")
        .required(),
      isPublished: Yup.boolean().oneOf([true], "Published is required"),
      description: Yup.string().required("Description is required"),
      type: Yup.string().required("Type is required"),
      link: Yup.string().when("type",{
        is:(type) => {
          return type == "Link"
        },
        then:Yup.string().url("Please Enter URL").matches(URL_REGEX, "URL is not valid").required("Url Is Required")
      }),
      // vimeo:Yup.string().url("Please Enter Vimeo URL").matches(VIMEO_REGEX, "Vimeo URL is not valid").required("Vimeo Url Is Required"),
      // youtube:Yup.string().url("Please Enter Youtube URL").matches(YOUTUBE_REGEX, "Youtube URL is not valid").required("Youtube Url Is Required"),
      // textBody: Yup.string().required("Link is required"),
      // isFeatured:  Yup.boolean().when(formik.values.type == "Vimeo" || formik.values.type == "Vimeo",{is: true, then: Yup.boolean().oneOf([true],"Is Featured is required")}),
    }),

    onSubmit: (values) => {
      // values = Action && Action == "open" ? { ...values, id: Id } : { ...values };

      // const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
      // const value = blocks
      //   .map((block) => (!block.text.trim() && "\n") || block.text)
      //   .join("\n");

      const editorJSON = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

      if (formik.values.type == "Article" || formik.values.type == "Label") {
        values.textBody = editorJSON;
      }
      values.isPublished = values.isPublished == true ? 1 : 0;
      values.isFeatured = values.isFeatured == true ? 1 : 0;
      values.pdf = files;


      const formInputs = new FormData();
      formInputs.append("categoryId", Id);
      formInputs.append("title", values.title);
      // if(selectKeywords.length>0){
      //   selectKeywords?.map((keyword)=>{
      formInputs.append("keywords", JSON.stringify(selectKeywords));
      //   })
      // }
      formInputs.append("isPublished", values.isPublished == true ? 1 : 0);
      formInputs.append(
        "type",
        resourceData?.type
          ? resourceData?.type
          : values.type.charAt(0).toUpperCase() + values.type.slice(1)
      );
      formInputs.append("description", values.description);
      if (values.type == "Document") {
        formInputs.append("pdf_upload", files[0].file);
      }
      if (values.type == "Article" || values.type == "Label") {
        formInputs.append("textBody", editorJSON);
      }
      if (values.type == "Vimeo") {
        formInputs.append("link", values.link);
        formInputs.append("isFeatured", values.isFeatured);
      }
      if (values.type == "Youtube") {
        formInputs.append("link", values.link);
        formInputs.append("isFeatured", values.isFeatured);
      }
      if (values.type == "Link") {
        formInputs.append("link", values.link);
      }
      if (
        location.pathname ==
        `/support/student-hub-library/student-hub/${TabId}/section/${Id}/resource/create`
      ) {
        AddResources(formInputs)
          .then((res) => {
            // setInsertedId(res?.data?.inserted_record)
            Id = res?.data?.inserted_record;
            Action = "open";
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated successfully",
              onClose: goToListing,
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data?.data}`,
            });
          });
      } else {
        if (Action && Action == "open") {
          // values = { ...values, categoryId: Id };
          formInputs.append("id", ResourceId);
          EditResource(formInputs)
            .then((res) => {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Updated successfully",
                onClose: goToListing,
              });
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: `${err?.response?.data?.data}`,
              });
            });
        }
      }
    },
  });

  const goToListing = () => {
    // history.push(
    //   `/support/student-hub-library/student-hub/${TabId}/section/open/${catId}`,
    //   {
    //     id: TabId,
    //     action: "open",
    //   }
    // );
    history.push(
      `/support/student-hub-library/student-hub/open/${TabId}`,
      {
        id: TabId,
        action: "open",
      }
    );
    
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "title",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            {/* <PermissionsGate scopes={['umedit']} RenderError={() => <p>{row.Firstname}</p>}> */}
            <p className="as-text-blue curser ">{row.title}</p>
            {/* </PermissionsGate> */}
          </div>
        </div>
      ),
    },

    {
      name: "Type",
      selector: "type",
      sortable: true,
      cell: (row) => row.type,
    },
    {
      name: "Visible",
      selector: "isPublished",
      sortable: true,
      cell: (row) => (row.isPublished == 1 ? "Yes" : "No"),
    },
    {
      name: "Featured",
      selector: "isFeatured",
      sortable: true,
      cell: (row) => (row.isFeatured == 1 ? "Yes" : "No"),
    },
    {
      name: "Keywords",
      selector: "keywords",
      sortable: true,
      cell: (row) => {
        let keywords = row.keywords.split(",");
        return (
          <p>
            {keywords?.map((keywords) => {
              return (
                <span className={"as-green-bg as-widget ml-2"}>{keywords}</span>
              );
            })}
          </p>
        );
      },
    },

    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            {/* <PermissionsGate scopes={['umedit']}> */}
            <button className="btn btn-primary rounded-circle">
              <i className="fal fa-folder-open"></i>
            </button>
            <button
              className="btn btn-danger rounded-circle"
              title="Delete"
              onClick={() => handleDelete(row?.id)}
            >
              <i className="fal fa-trash-alt"></i>
            </button>

          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    // console.log(ID);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteCategoryList({ categoryId: ID }).then((res) =>
          setDeleteRow(!deleterow)
        );

        Swal.fire("Deleted!", "Your user has been deleted.", "success");
      }
    });
  };

  const onAdtypeHandleChange = (data) => {
    const domainSelected = data?.map((obj) => {
      return obj.value;
    });
    setSelectedDomain(domainSelected);
    formik.setFieldValue("domain", domainSelected);
  };

  const dataToRender = () => {
    let updatedData = [];
    // console.log("tablistData from dataToRender", tabslistData);
    let allData = resourceslistData;
    if (search?.length) {
      let tempId = allData.filter((item) => {
        let includes = item?.categoryId
          .toString()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCatName = allData.filter((item) => {
        let includes =
          item.categoryName &&
          item.categoryName.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempResource = allData.filter((item) => {
        let includes =
          item.categoryHashValue &&
          item.categoryHashValue.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempVisible = allData.filter((item) => {
        let includes =
          item.visible && item.visible.includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempKeyword = allData.filter((item) => {
        let includes =
          item.Keywords &&
          item.Keywords.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        ...tempId,
        ...tempCatName,
        ...tempVisible,
        ...tempResource,
        ...tempKeyword,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search.length) {
      return updatedData;
    } else {
      return resourceslistData;
    }
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Id", "Name", "Resource", "Visible", "Keywords"];
    data = data?.map((row) => ({
      ...row,
      Id: row?.id,
      Name: row?.categoryName,
      Visible: row?.isPublished,
      Resource: row?.categoryHashValue,
      Keywords: row?.Keywords,
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row.id,
          row.categoryName,
          row.visible,
          row.categoryHashValue,
          row.Keywords,
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleKeyDown = (event) => {
    var keyword = selectKeywords;
    if (event.key === "Enter") {
      if (event.target.value) {
        keyword[0] == "" && keyword.pop()
        keyword.push(event.target.value);
        event.target.value = "";
        setSelectedKeywords(keyword);
        formik.setFieldValue("keywords", keyword);
      }

    }
  };

  const removeKeyword = (index) => {
    var keyword = selectKeywords;
    var keywords = keyword.splice(index, 1);
    setSelectedKeywords((keyword) => [...keyword]);
    formik.setFieldValue("keywords", keyword);
  };

  function onKeyDown1(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const handleUploadedFiles = (response) => {
    setAttachments((attachments) => [...attachments, response]);
  };

  const onEditorStateChange = editorState => setEditorState(editorState);


  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }


  const insertImage = (url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'IMAGE',
      'IMMUTABLE',
      { src: url })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  };


  const handlePastedFiles = async (files) => {
    getBase64(files[0])
      .then(
        data => {
          setEditorState(insertImage(data))
        }
      )
      .catch((error) => {
        console.log(error);
      });
  }


  function uploadImageCallBack(file) {
    return new Promise(
      (resolve, reject) => {
        getBase64(file)
          .then(
            data => {
              resolve({ data: { link: data } })
            }
          )
          .catch((error) => {
            reject(error);
          });
      },
    );
  }

  //   const initials = userData?.Firstname[0] + userData?.Lastname[0];
  return (
    <div className="card card-profile-info-card">
      <div className={`card-body `}>
        <div className="card-header">Resource Data</div>
        <div className="card-body-inr card-body-info">
          {/* formik.handleSubmit */}
          <form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown1}>
            <div className="row">
              <div className="col-md-4">
                <label>Name*</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.title && formik.touched.title
                        ? " is-invalid"
                        : "")
                    }
                    name="title"
                    placeholder="Name"
                    title="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.title && formik.touched.title ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="col-md-4">
                <label>Keywords*</label>
                <div className="form-icon-group">
                  <div className="form-control custom-select-box">


                    <input
                      type="text"
                      className={
                        "form-control" +
                        (formik.errors.keywords && formik.touched.keywords
                          ? " is-invalid"
                          : "")
                      }
                      name="keywords"
                      placeholder="keywords"
                      title="keywords"
                      // value={valueView}
                      // onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                    />

                    {formik.errors.keywords && formik.touched.keywords ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>

                </div>
                {/* {console.log("selectKeywords.length",selectKeywords.length)} */}
                {(selectKeywords.length > 0 && selectKeywords != '') && (
                  <ul className="tag-list">
                    {selectKeywords?.map((ele, index) => (
                      <li>
                        {ele}
                        <span onClick={() => removeKeyword(index)}>
                          <i className="fal fa-times"></i>
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="col-md-4">
                <div className="form-group" title="Reports To">
                  <label>Visibility*</label>
                  <div className="custom-control custom-checkbox text-left mt-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="isPublished"
                      name="isPublished"
                      checked={formik.values.isPublished}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="isPublished"
                    >
                      Published
                    </label>
                    {formik.errors.isPublished && formik.touched.isPublished ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <label>Type*</label>
                <div className="form-icon-group mb-4">
                  <div className="form-control custom-select-box">
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.type && formik.touched.type
                          ? " is-invalid"
                          : "")
                      }
                      name="type"
                      value={formik.values.type || ""}
                      onChange={(value) => {
                        if (resourceData) {
                          let tempResData = resourceData;
                          tempResData.type = value.value;
                          setResourceData(tempResData);
                        }

                        formik.setFieldValue("type", value.value);
                      }}
                      onBlur={formik.handleBlur}
                      options={types}
                      maxMenuHeight={175}
                      placeholder={
                        formik.values.type
                          ? formik.values.type
                          : "Select Type  "
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <label>Description*</label>
              <div className="form-icon-group mb-4">
                <textarea
                  rows={10}
                  className={
                    "form-control" +
                    (formik.errors.description && formik.touched.description
                      ? " is-invalid"
                      : "")
                  }
                  name="description"
                  placeholder="description"
                  title="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.description && formik.touched.description ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
            {(formik.values.type == "Article" ||
              resourceData.type == "Article" ||
              resourceData.type == "Label" ||
              formik.values.type == "Label") && (
                <div className="">
                  <div className=" mb-4">
                    <label>Text Body</label>

                    {/* <Editor
                    style={styles.editor}
                    editorState={editorState}
                    onChange={setEditorState}
                  /> */}

                    <Editor
                      editorState={editorState}
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange}
                      // ref={filePondRef}
                      handlePastedFiles={
                        // (files) => {
                        // console.log("number of files pasted: " + files);
                        handlePastedFiles
                        // }
                      }
                      toolbar={{
                        image: { urlEnabled: true, uploadEnabled: true, uploadCallback: uploadImageCallBack }
                      }}
                    />
                    {/* <div className="draft-editor">
                    <InlineStyleControls onToggle={onInlineClick} />
                  </div> */}
                  </div>
                </div>
              )}

            {(formik.values.type == "Document" ||
              resourceData.type == "Document") && (
                <div className="">
                  <div className=" mb-4">
                    <label>Upload Document</label>

                    <FilePond
                      files={files}
                      allowReorder={true}
                      allowMultiple={true}
                      onupdatefiles={setFiles}
                      labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    />

                    {formik.errors.link && formik.touched.link ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
              )}
            {files.length <= 0 && (formik.values.type == "Document" ||
              resourceData.type == "Document") && (docType) &&
              RenderAttachList({
                fileName: fileName,
                // size: "1205",
                filePath: resourceData.links,
                showClose: false,
                docType: docType
              })}



            {(formik.values.type == "Vimeo" ||
              resourceData.type == "Vimeo" || resourceData.type == "Youtube" ||
              formik.values.type == "Youtube") && (
                <div className="">
                  <div className=" mb-4">
                    <label>Video URL</label>

                    <input
                      type="text"
                      className={
                        "form-control" +
                        (formik.errors.link && formik.touched.link
                          ? " is-invalid"
                          : "")
                      }
                      name="link"
                      placeholder="Link"
                      title="link"
                      value={formik.values.link}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.link && formik.touched.link ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                  <div className="">
                    <div className="form-group" title="Reports To">
                      {/* <label>Is Featured</label> */}
                      <div className="custom-control custom-checkbox text-left mt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="isFeatured"
                          name="isFeatured"
                          checked={formik.values.isFeatured}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="isFeatured"
                        >
                          Is Featured
                        </label>
                        {formik.errors.isFeatured && formik.touched.isFeatured ? (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {/* {(
              resourceData.type == "Youtube" ||
              formik.values.type == "Youtube") && (
              <div className="">
                <div className=" mb-4">
                  <label>Video URL</label>

                  <input
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.youtube && formik.touched.youtube
                        ? " is-invalid"
                        : "")
                    }
                    name="link"
                    placeholder="Link"
                    title="link"
                    value={formik.values.youtube}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.youtube && formik.touched.youtube ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
                <div className="">
                  <div className="form-group" title="Reports To">
                    <div className="custom-control custom-checkbox text-left mt-2">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="isFeatured"
                        name="isFeatured"
                        checked={formik.values.isFeatured}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="isFeatured"
                      >
                        Is Featured
                      </label>
                      {formik.errors.isFeatured && formik.touched.isFeatured ? (
                        <span className="exclamation">
                          <i className="fal fa-exclamation-circle"></i>
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )} */}
            {(resourceData.type == "Link" || formik.values.type == "Link") && (
              <div className="">
                <div className=" mb-4">
                  <label>URL</label>

                  <input
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.link && formik.touched.link
                        ? " is-invalid"
                        : "")
                    }
                    name="link"
                    placeholder="Link"
                    title="link"
                    value={formik.values.link}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.link && formik.touched.link ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
            )}

            <div className="form-group-save-cancel mt-3">
              <PermissionsGate errorProps={{ disabled: true }} scopes={["shedit"]}>
                <button
                  className="btn btn-save btn-success"
                  type="submit"
                  title="Save"
                >
                  <i className="fal fa-save"></i>
                  Save
                </button>
              </PermissionsGate>
              <button
                className="btn btn-close btn-danger"
                type="button"
                title="Cancel"
                onClick={() => history.goBack()}
              >
                <i className="fal fa-times"></i>
                Cancel
              </button>
            </div>
            <div className="form-group form-group-save-cancel ml-new">
              {formik.touched.title && formik.errors.title ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.title}
                </div>
              ) : null}
              {formik.touched.keywords && formik.errors.keywords ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.keywords}
                </div>
              ) : null}
              {formik.touched.isPublished && formik.errors.isPublished ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.isPublished}
                </div>
              ) : null}
              {formik.touched.type && formik.errors.type ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.type}
                </div>
              ) : null}
              {formik.touched.description && formik.errors.description ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.description}
                </div>
              ) : null}
              {
                formik.values.type == "Link" && formik.touched.link && formik.errors.link ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.link}
                  </div>
                ) : null}
              {/* {
                formik.values.type == "Youtube" && formik.touched.youtube && formik.errors.youtube ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.youtube}
                  </div>
                ) : null
              }
              {
                formik.values.type == "Vimeo" && formik.touched.vimeo && formik.errors.vimeo ? (
                  <div className="invalid-feedback d-block">
                    {formik.errors.vimeo}
                  </div>
                ) : null
              } */}

            </div>
          </form>
        </div>

        {/* {location.pathname !=
          `/support/student-hub-library/student-hub/${TabId}/section/${Id}/resource/create` ? (
          <>
            <div className="card-header">Resources</div>
            <div className="custom-table-div filter-search-icon card card-table-custom new-datatable-blk1 ">
              <div className=" filter-search-bar-blk">
                <div className="filter-button-group">
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Categories");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Categories");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Categories");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <Link
                    to={`/support/student-hub-library/student-hub/${TabId}/category/${Id}/resource/create`}
                  >
                    <button className="btn btn-primary">
                      <i className="fal fa-plus"></i>Create New
                    </button>
                  </Link>
                </div>
              </div>
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <div
                        id="assessment-table-main_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={handleSearchFilter}
                            value={search}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {isLoaded && <SkeletonTicketList />}
              <DataTable
                data={dataToRender()}
                defaultSortField="Name"
                defaultSortAsc={true}
                columns={columns}
                pagination={true}
                noDataComponent={Str.noRecord}
              />
            </div>
          </>
        ) : null} */}
      </div>
    </div>
  );
};

export default ResourceAction;
