import React, { useEffect, useState, useMemo } from "react";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { GetLibraryBorrowersDetailsFilter, getLibraryBorrowersDetails, getLibraryBorrowersDetailsCancelToken, getResourceDetails, getResourceManagement } from "../../../services/StudentService";
import StatusRender, { Campus, CampusBlock, Format } from "../../systemadministration/usermanagement/CheckRole";
import { TrimText } from "../../common/TrimText";
import PermissionsGate from "../../../utils/permissionGate";
import Swal from "sweetalert2";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import DataTable from "react-data-table-component";
import Str from "../../common/Str";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import Tablefilter from "../../common/Tablefilter";
import { RenderCampusDropdown } from "../../../utils/CommonGroupingItem";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const LibraryDetails = ({ setBorrowerName }) => {
    const [basicInfo, setBasicInfo] = useState({})
    const { id } = useParams();
    const history = useHistory();
    const [search, setSearch] = useState("");
    const [visible, setVisible] = useState([]);
    const [searchVisibleCheck, setSearchVisibleCheck] = useState({});
    const [linkedBrand, setLinkedBrand] = useState([]);
    const [searchLinkedBrandCheck, setSearchLinkedBrandCheck] = useState({});
    const [searchReportCheck, setSearchReportCheck] = useState({});
    const [deleterow, setDeleteRow] = useState(false);
    const [tablistData, setTablistData] = useState([]);
    const [studentstatus, setStudentStatus] = useState([]);
    const [searchStudentStatusCheck, setStudentSearchStatusCheck] = useState({});

    const [availabilitystatus, setAvailabilityStatus] = useState([]);
    const [availabilitySearchStatusCheck, setAvailabilitySearchStatusCheck] = useState({});

    const [isLoaded, setIsLoaded] = useState(true);
    const [status, setStatus] = useState([]);
    const [searchStatusCheck, setSearchStatusCheck] = useState({});

    const [sortkey, setSortKey] = useState("student");
    const [sortOrder, setSortOrder] = useState("ASC");
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [filterData, setFilterData] = useState([])
    const [campus, setCampus] = useState({ arr: [], checkObj: {} });
    const [debouncedTerm, setDebouncedTerm] = useState("")

    const campusData = useSelector(state => state.registerDropdownValues.campus);

    useEffect(() => {
        const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
        return () => {
          clearTimeout(timer);
        }
      }, [debouncedTerm])

    useEffect(() => {
        handleTableScroll()
      }, [isLoaded])

    const getLibDetail = () => {
        setIsLoaded(true);
        const data = {
            page: page,
            limit: perPage,
            viaSearch: search ? search : "",
            exportStatus: false,
            key: sortkey,
            sort: sortOrder,
            viaCampus: campus.arr.length > 0 ? campus.arr : "",
            student_id: id,
            // viaAvailability: availabilitystatus.length > 0 ? availabilitystatus : "",
            viaFormat: status.length > 0 ? status : ""
        };
        getLibraryBorrowersDetails(data)
            .then((res) => {
                // console.log("data from GetListOfStudentHubTabList", res.data?.student_hub[0].domain.split("___"));
                GetLibraryBorrowersDetailsFilter({student_id : id}).then((response)=>{
                    setFilterData(response.data.result);

                    let Data = res.data.result.data.map(item =>({
                        ...item,
                        campus : (item.campus && item.campus!="") ? response.data.result.filter(val=>(val.value.toString()===item.campus))[0] : ""
                    }))
                    setTablistData([...Data])
                    setIsLoaded(false);
                }).catch(error=>{console.log(error)})
                setBasicInfo(res?.data?.student);
                setBorrowerName(res?.data?.student?.first_name + " " + res?.data?.student?.last_name)
                setTotalRows(res?.data?.result?.total)
            })
            .catch((err) => {
                console.log(err);
                setIsLoaded(false);
            });
    };

    useEffect(() => {
        const cancelTokenSources = [];
    
        const getLibDetail = async () => {
          setIsLoaded(true);
      
          cancelTokenSources.forEach(source => {
            source.cancel('New request made');
          });
      
          const source = axios.CancelToken.source();
          cancelTokenSources.push(source);
      
          const data = {
            page: page,
            limit: perPage,
            viaSearch: search ? search : "",
            exportStatus: false,
            key: sortkey,
            sort: sortOrder,
            viaCampus: campus.arr.length > 0 ? campus.arr : "",
            student_id: id,
            // viaAvailability: availabilitystatus.length > 0 ? availabilitystatus : "",
            viaFormat: status.length > 0 ? status : ""
          };
      
          try {
            const res = await getLibraryBorrowersDetailsCancelToken(data, source.token);
            if (res.status === 200) {
            GetLibraryBorrowersDetailsFilter({student_id : id}).then((response)=>{
                setFilterData(response.data.result);

                let Data = res.data.result.data.map(item =>({
                    ...item,
                    campus : (item.campus && item.campus!="") ? response.data.result.filter(val=>(val.value.toString()===item.campus))[0] : ""
                }))
                setTablistData([...Data])
                setIsLoaded(false);
            }).catch(error=>{console.log(error)})
            setBasicInfo(res?.data?.student);
            setBorrowerName(res?.data?.student?.first_name + " " + res?.data?.student?.last_name)
            setTotalRows(res?.data?.result?.total)
            }
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error(error);
              setIsLoaded(false);
            }
          }
        };
      
        getLibDetail();
    
        return () => {
          cancelTokenSources.forEach(source => {
            source.cancel('Component unmounted');
          });
        };
      }, [deleterow, search, campus,
        page,
        perPage, sortkey,
        sortOrder, status, studentstatus,
        availabilitystatus, searchStatusCheck, searchStudentStatusCheck, availabilitySearchStatusCheck]);


    // useEffect(() => {
    //     getLibDetail();

    // }, [deleterow, search, campus,
    //     page,
    //     perPage, sortkey,
    //     sortOrder, status, studentstatus,
    //     availabilitystatus, searchStatusCheck, searchStudentStatusCheck, availabilitySearchStatusCheck]);

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearch(value);
    };


    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleSort = (column, sortDirection) => {
        setSortKey(column.selector);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };
    
    const resetFilter = () => {
        setVisible([]);
        setSearchVisibleCheck({});
        setLinkedBrand([]);
        setSearchLinkedBrandCheck({});
        setSearchReportCheck({});
        setSearch("");
        setDebouncedTerm("")
        setStudentStatus([])
        setStudentSearchStatusCheck({})
        setCampus({ arr: [], checkObj: {} })
    };



    const columns = useMemo(() => [
        {
            name: "Title",
            selector: "title",
            sortable: true,
            // minWidth: "190px",
            cell: (row) => (
                <div className="assigned-title-block-new" title={row.title}>
                    <div className="assigned-title-itm">
                        <PermissionsGate scopes={["umedit"]} RenderError={() => <p>{row.title}</p>} >
                            <Link to={`/support/student-hub-library/resource-management/edit/${row.id}/dashboard`} className="as-text-blue curser textLimit100">{row.title}</Link>
                        </PermissionsGate>
                    </div>
                </div>
            ),
        },
        {
            name: "Author",
            selector: "author",
            sortable: true,
            cell: (row) => (
                <span className="textLimit100" title={row.author}>
                    {row.author ? row.author : "NA"}
                </span>
            )
        },
        // {
        //     name: "Edition",
        //     selector: "edition",
        //     sortable: true,
        //     cell: (row) => (
        //         <span>
        //             {row.edition ? row.edition : "-"}
        //         </span>
        //     )
        // },
        {
            name: "Format",
            selector: "type",
            sortable: true,
            cell: (row) => (
                <Format status={row?.type?.toString()} />
            ),
        },
        {
            name: "Campus",
            selector: "campus",
            sortable: true,
            cell: (row) => (row.campus ?
                RenderCampusDropdown(row.campus).html : "NA"),
        },
        {
            name: "Barcode",
            selector: "barcode",
            sortable: true,
            cell: (row) => (
                <span title={row.barcode}>
                    {row.barcode ? row.barcode : "NA"}
                </span>
            )
        },
        {
            name: "Issue Date",
            selector: "issued_date",
            sortable: true,
            cell: (row) => (
                <span className="textLimit100" title={moment.unix(Number(row?.issued_date)).format(TABLE_DATE_FORMAT)}>
                    {Number(row?.issued_date) ? moment.unix(Number(row?.issued_date)).format(TABLE_DATE_FORMAT) : "NA"}
                </span>
            )
        },
        {
            name: "Due Date",
            selector: "due_date",
            sortable: true,
            cell: (row) => (
                row.status === "reserved" ?
                    <span className="textLimit100" title={moment.unix(Number(row?.reserved_due_date)).format(TABLE_DATE_FORMAT)}>
                        {Number(row?.reserved_due_date) ? moment.unix(Number(row?.reserved_due_date)).format(TABLE_DATE_FORMAT) : "NA"}
                    </span>
                    :
                    <span className="textLimit100" title={moment.unix(Number(row?.due_date)).format(TABLE_DATE_FORMAT)}>
                        {Number(row?.due_date) ? moment.unix(Number(row?.due_date)).format(TABLE_DATE_FORMAT) : "NA"}
                    </span>
            )
        },
        {
            name: "Days Overdue",
            selector: "due_days",
            sortable: true,
            cell: (row) => row.due_days ? row.due_days : "-"
        },

        {
            name: "Actions",
            selector: "",
            // maxWidth: "50px",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons">
                        <PermissionsGate scopes={["umedit"]}>
                            <Link to={`/support/student-hub-library/resource-management/edit/${row.id}/dashboard`} className="btn btn-primary rounded-circle"
                                title="Open" >
                                <i className="fal fa-folder-open"></i>
                            </Link>
                        </PermissionsGate>
                    </div>
                </div>
            ),
        },
    ]);

    const exportData = (fileType, fileName) => {
        let data
        const header = ["Title", "Author", "Format", "Campus", "Barcode", "Issue Date", "Due Date", "Days Overdue"];

        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        getLibraryBorrowersDetails({
            page: page,
            limit: perPage,
            viaSearch: search ? search : "",
            exportStatus: 'true',
            key: sortkey,
            sort: sortOrder,
            viaCampus: campus.arr.length > 0 ? campus.arr : "",
            viaFormat: status.length > 0 ? status : "",
            student_id: id,
        }).then((res) => {
            data = res.data.result;
            data = data.map((row) => ({
                Title: row?.title,
                Author: row.author ? row.author : "N/A",
                Format: row.type ? row.type : "N/A",
                Campus: row?.campus ? filterData.filter(val => val.value == row.campus)[0].label : "N/A",
                Barcode: row?.barcode ? row?.barcode : "N/A",
                "Issue Date": Number(row?.issued_date) ? moment.unix(Number(row?.issued_date)).format(TABLE_DATE_FORMAT) : "N/A",
                "Due Date": Number(row?.due_date) ? moment.unix(Number(row?.due_date)).format(TABLE_DATE_FORMAT) : "N/A",
                "Days Overdue": row?.due_days ? row?.due_days : "N/A",
            }));

            if (fileType === "csv") {
                const csvString = Papa.unparse({ fields: header, data });

                const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

                const blobURL = window.URL.createObjectURL(blob);

                const anchor = document.createElement("a");
                anchor.download = fileName;
                anchor.href = blobURL;
                anchor.dataset.downloadurl = [
                    "text/csv",
                    anchor.download,
                    anchor.href,
                ].join(":");
                anchor.click();

                setTimeout(() => {
                    URL.revokeObjectURL(blobURL);
                }, 1000);
                Swal.close();
            } else if (fileType === "xlsx") {
                const compatibleData = data.map((row) => {
                    const obj = {};
                    header.map((col, index) => {
                        obj[col] = row[col];
                    });
                    return obj;
                });

                let wb = XLSX.utils.book_new();
                let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                    header,
                });
                XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                XLSX.writeFile(wb, `${fileName}.xlsx`);
                Swal.close();
                return false;
            }
            if (fileType === "pdf") {
                const compatibleData = data.map((row) => {
                    return [
                        row["Title"],
                        row["Author"],
                        row["Format"],
                        row["Campus"],
                        row["Barcode"],
                        row["Issue Date"],
                        row["Due Date"],
                        row["Days Overdue"],
                    ];
                });
                const doc = new JsPDF();
                doc.autoTable({
                    head: [header],
                    body: compatibleData,

                    // columnStyles: {
                    //   0: {
                    //     columnWidth: 10,
                    //   },
                    //   1: {
                    //     columnWidth: 20,
                    //   },
                    //   2: {
                    //     columnWidth: 15,
                    //   },
                    //   3: {
                    //     columnWidth: 15,
                    //   },
                    // },
                    // styles: {
                    //   minCellHeight: 10,
                    //   minCellWidth: 5,
                    //   halign: "left",
                    //   fontSize: 8,
                    // },
                });
                doc.save(`${fileName}.pdf`);
                Swal.close();
                return false;
            }
        });


    };

    return (
        <>
            <div className="card-new card card-profile-info-card mb-30 dash-box course-dashborad">
                <div className="card-header">Borrower Profile</div>
                <div className="card-body-inr card-body-info">
                    <div className="basic-info-box">
                        <div className="row col-width">
                            <div className="col-md-3 col-lg-3">
                                <div className="basic-info-box-list">
                                    <ul className="p-0">
                                        <li>
                                            <span className="title">Title :</span>
                                            <span>{basicInfo.title ? TrimText(basicInfo.title) : "NA"}</span>
                                        </li>
                                        <li>
                                            <span className="title">Cell :</span>
                                            <span>{basicInfo.mobile ? basicInfo.mobile : "NA"}</span >
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="basic-info-box-list">
                                    <ul className="p-0">
                                        <li>
                                            <span className="title">Name :</span>
                                            <span>{basicInfo.first_name ? TrimText(basicInfo.first_name) : "NA"}</span>
                                        </li>
                                        <li>
                                            <span className="title">Email :</span>
                                            <span>{basicInfo.email ? TrimText(basicInfo.email) : "NA"}</span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="basic-info-box-list">
                                    <ul className="p-0">
                                        <li>
                                            <span className="title">Surname :</span>
                                            <span>{basicInfo.last_name ? TrimText(basicInfo.last_name) : "NA"}</span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <div className="basic-info-box-list">
                                    <ul className="p-0">
                                        <li>
                                            <span className="title">Pending Items :</span>
                                            <span>{totalRows ? totalRows : "NA"}</span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tabs-wrap">

                <div className="my-tickets-info-list Tickets-main-wrap">
                    <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom course-table-filter-sec">
                        <div className="search-filter-div">
                            <div className="search-filter-div-left">
                                <div className="system-administration-table table-responsive search-bar">
                                    <div className="table-responsive-div">
                                        <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                                <div id="assessment-table-main_filter" className="dataTables_filter">
                                                    <label>
                                                        <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={e => setDebouncedTerm(e.target.value)} value={debouncedTerm} />
                                                    </label>
                                                    <div className="filter-eff filter-data-btn">
                                                        <button className="filter-buttons">
                                                            <i className="fal fa-filter"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-button-group">
                                        <Tablefilter
                                            filterName="Campus"
                                            optionArr={filterData}
                                            state={campus}
                                            setState={setCampus}
                                            uniqueId="campus"
                                            renderLabelFunction={RenderCampusDropdown}
                                        />
                                        <div className="reset-btn-group">
                                            <div className="button-reset dropdown-comman" title="Reset">
                                                <button className="btn btn-primary" onClick={resetFilter} >
                                                    <i className="fal fa-redo"></i>Reset
                                                </button>
                                            </div>

                                            <div className="files-export-group">
                                                <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Library borrower"); }} title="Export spreadsheet">
                                                    <i className="fal fa-file-excel icon"></i>
                                                </button>
                                                <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Library borrower"); }} title="Export CSV">
                                                    <i className="fal fa-file-csv icon"></i>
                                                </button>
                                                <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Library borrower"); }} title="Export PDF">
                                                    <i className="fal fa-file-pdf icon"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* {isLoaded && <SkeletonTicketList />} */}

                        <DataTable
                            data={tablistData}
                            defaultSortField="student"
                            defaultSortAsc={true}
                            columns={columns}
                            pagination={true}
                            noDataComponent={Str.noRecord}
                            paginationDefaultPage={page}
                            progressPending={isLoaded}
                            progressComponent={<SkeletonTicketList />}
                            onSort={handleSort}
                            paginationServer
                            sortServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            highlightOnHover={false}
                            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default LibraryDetails;
