import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { AddaQuestion, GetAssessmentAssignedOrNotAssignedList, GetAssessmentAssignedOrNotAssignedListCancelToken, GetListOfCourses, GetListOfCoursesCancelToken, GetQuizTopics, GetQuizTopicsCancelToken, UpdateQuestion, ViewaQuestion, ViewaQuestionCancelToken } from "../../../../services/SmartAssessmentService";
import { getCurrentTimeHomePage } from "../../../common/Helper";
import { ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState, AtomicBlockUtils } from "draft-js";
import { useFormik } from "formik";
import Select from "react-select";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { Editor } from "react-draft-wysiwyg";
import { tryParseJSONObject } from "../../../../utils/TryParseJSONObject";
import Assigned from "./AssessmentTables/Assigned";
import Available from "./AssessmentTables/Available";
import Swal from "sweetalert2";
import { TrimText } from "../../../common/TrimText";
import * as Yup from "yup";
import $ from 'jquery';
import DisplayQuestion from "../DisplayQuestion";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../../utils/permissionGate";
import SelectDropDown from "../../../common/SelectDropDown";
import draftToHtml from "draftjs-to-html";
import HtmlParser from "react-html-parser";
import { RenderPMCStatus } from "../../../../utils/CommonGroupingItem";
import { CKEditor } from "ckeditor4-react";
import Header from "../../../common/Header";
import SideBar from "../../../common/SideBar";
import { API_BASE, IMAGE_URL } from "../../../../utils/Constants";
import axios from "axios";

const DuplicateQuestion = () => {

  const { subtab, id, tabName, tab } = useParams();
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [selectedQ, setSelectedQ] = useState();
  const [courses, setCourses] = useState([]);
  const [dummy, setDummy] = useState(true);
  const [selectedAns, setselectedAns] = useState([]);
  const [checkedAns, setCheckedAns] = useState([]);
  const [editorState1, setEditorState1] = React.useState(""); // question
  const [editorState2, setEditorState2] = React.useState(""); // description
  const [editorState3, setEditorState3] = React.useState({}); // options array
  const [state, setState] = useState([]);
  const [title, setTitle] = useState("");
  const [assigneddata, setAssignedData] = useState();
  const [availabledata, setAvailabledata] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [questionData, setQuestionData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState();
  const newstate = { ...editorState3 };
  const [selectKeywords, setSelectedKeywords] = useState([]);
  const [topicArray,setTopicArray] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [possibleAnswers, setPossibleAnswers] = useState([]);

  const multiSelectColorStyles = {      
    multiValueLabel: (styles, { data }) => ({
      ...styles,
        '& span':{
          background: 'transparent !important',
          color: '#333 !important',
        }
    }),
  };

  useEffect(() => {
    if (subtab === "view") {
      let response = hasPermission({ scopes: ["cquestionbadd"], permissions: givenPermsisions });
      if (!response) {
        history.push("/noaccess");
      }
    }
  }, [])

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetQuizTopicsCancelToken({},dropdownSource.token);
        if(res.status == 200){
          let tempArr = res?.data?.map(item=>{return {value:item?.id, label: HtmlParser(`<span style="color:#fff;background:${item?.color};" title=${item?.options}>${item?.options}</span>`)}});
          setTopicList(tempArr);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const arrayOfEditors = () => {
    let arr = data.map((element) => {
      let check = tryParseJSONObject(element?.q_option ? element?.q_option : "");
      if (element) {
        if (check) {
          try {
            return element.q_option;
            // const blocksFromHTML2 = convertFromRaw(JSON.parse(element.q_option ? element.q_option : ""));
            // return (EditorState.createWithContent(blocksFromHTML2));
          } catch (error) {
            return element.q_option;
            // const descBlock = convertFromHTML(element.q_option ? element.q_option : "");
            // const desc = ContentState.createFromBlockArray(descBlock?.contentBlocks, descBlock?.text);
            // return (EditorState.createWithContent(desc));
            // const questionBlock = convertFromHTML(element?.q_option);
            // return EditorState.create(ContentState.createFromBlockArray(questionBlock));
          }
        } else {
          return element.q_option;
          // const questionBlock = convertFromHTML(element?.q_option ? element?.q_option : "");
          // const question = ContentState.createFromBlockArray(questionBlock?.contentBlocks, questionBlock?.entityMap);
          // return (EditorState.createWithContent(question));
        }
      }
    });
    return arr;
  }

  // useEffect(() => {
  //   let tempCourses = []
  //   GetListOfCourses().then((res) => {
  //     let x = { value: "", label: "" };
  //     x = res?.data?.map((data) => ({ ...x, value: `${data.id}`, label: `${data.name}` }))
  //     setCourses(x);
  //     tempCourses = x;
  //   }).catch(error=>console.log(error));

  //   ViewaQuestion({ qId: id }).then((res) => {
  //       setData(res?.data);
  //       setSelectedQ(res?.data[0]?.question_type)
  //       // const keywordData = res.data[0].keywords.length > 0 ? res.data[0].keywords.split(",").map(item=>item.replaceAll('\"',"").replaceAll("\\","").replaceAll('[', "").replaceAll(']', "")) : [];
  //       // const selectedTopicData = res.data[0].topic.length > 0 ? res.data[0].topic.split(",").map(item => item.replaceAll('\"', "").replaceAll("\\","").replaceAll('[', "").replaceAll(']', "")) : [];
  //       const keywordData = (res.data[0].keywords && res.data[0].keywords.length > 0 && Array.isArray(JSON.parse(res.data[0].keywords))) ? JSON.parse(res.data[0].keywords).map(item=>item) : [];
  //       const selectedTopicData = (res.data[0].topic && res.data[0].topic.length > 0 && Array.isArray((res.data[0].topic).split(","))) ? ((res.data[0].topic).split(",")).map(item=>item) : [];
  //       setTopicArray(selectedTopicData)
  //       setSelectedKeywords(keywordData)

  //       if (res?.data[0]?.question_type === "Short Answer") {
  //         if (res.data[0].q_option !== null) {
  //           setPossibleAnswers(res.data[0].q_option.split("~@~"))
  //         }
  //       }
  //       // set options array
  //       if (res?.data[0]?.question_type === "Multiple Choice Single Answer" || res?.data[0]?.question_type === "Multiple Choice Multiple Answer") {
  //         let obj = {};
  //         let arr1 = res.data.map((element, ind) => {
  //           let check = tryParseJSONObject(element.q_option ? element.q_option : "");
  //           if (check) {
  //             const q_optionBlock = convertFromRaw(JSON.parse(element.q_option ? element.q_option : ""));
  //             return (EditorState.createWithContent(q_optionBlock));
  //           } else {
  //             obj['customCheck' + ind] = element.q_option;
  //             return element.q_option;
  //           }
  //         });
  //         setEditorState3(obj);
  //       }
  //       let arr = res?.data?.map((o) => o.score ? true : false);
  //       setCheckedAns(arr);

  //       let index = arr.indexOf(true);
  //       setselectedAns(index.toString());

  //       // set question and description
  //       if (res.data[0]) {
  //         setEditorState1(res.data[0].question);
  //         setEditorState2(res.data[0].description);
  //       }
  //   }).catch(error=>console.log(error));
  // }, [dummy]);

  useEffect(() => {
    const courseSource = axios.CancelToken.source();
    const viewQuesSource = axios.CancelToken.source();
  
    const fetchCourseData = async () => {
      let tempCourses = []
      try {
        const res = await GetListOfCoursesCancelToken(courseSource.token);
        if(res.status == 200){
          let x = { value: "", label: "" };
          x = res?.data?.map((data) => ({ ...x, value: `${data.id}`, label: `${data.name}` }))
          setCourses(x);
          tempCourses = x;
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const fetchViewAQuestion = async () => {
      try {
        const res = await ViewaQuestionCancelToken({ qId: id }, viewQuesSource.token);
        if(res.status == 200){
          setData(res?.data);
          setSelectedQ(res?.data[0]?.question_type)
          // const keywordData = res.data[0].keywords.length > 0 ? res.data[0].keywords.split(",").map(item=>item.replaceAll('\"',"").replaceAll("\\","").replaceAll('[', "").replaceAll(']', "")) : [];
          // const selectedTopicData = res.data[0].topic.length > 0 ? res.data[0].topic.split(",").map(item => item.replaceAll('\"', "").replaceAll("\\","").replaceAll('[', "").replaceAll(']', "")) : [];
          const keywordData = (res.data[0].keywords && res.data[0].keywords.length > 0 && Array.isArray(JSON.parse(res.data[0].keywords))) ? JSON.parse(res.data[0].keywords).map(item=>item) : [];
          const selectedTopicData = (res.data[0].topic && res.data[0].topic.length > 0 && Array.isArray((res.data[0].topic).split(","))) ? ((res.data[0].topic).split(",")).map(item=>item) : [];
          setTopicArray(selectedTopicData)
          setSelectedKeywords(keywordData)

          if (res?.data[0]?.question_type === "Short Answer") {
            if (res.data[0].q_option !== null) {
              setPossibleAnswers(res.data[0].q_option.split("~@~"))
            }
          }
          // set options array
          if (res?.data[0]?.question_type === "Multiple Choice Single Answer" || res?.data[0]?.question_type === "Multiple Choice Multiple Answer") {
            let obj = {};
            let arr1 = res.data.map((element, ind) => {
              let check = tryParseJSONObject(element.q_option ? element.q_option : "");
              if (check) {
                const q_optionBlock = convertFromRaw(JSON.parse(element.q_option ? element.q_option : ""));
                return (EditorState.createWithContent(q_optionBlock));
              } else {
                obj['customCheck' + ind] = element.q_option;
                return element.q_option;
              }
            });
            setEditorState3(obj);
          }
          let arr = res?.data?.map((o) => o.score ? true : false);
          setCheckedAns(arr);

          let index = arr.indexOf(true);
          setselectedAns(index.toString());

          // set question and description
          if (res.data[0]) {
            setEditorState1(res.data[0].question);
            setEditorState2(res.data[0].description);
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchCourseData();
    fetchViewAQuestion();

    return () => {
      courseSource.cancel('Component unmounted');
      viewQuesSource.cancel('Component unmounted');
    };
  }, [dummy]);

  useEffect(() => {
    const assignedSource = axios.CancelToken.source();
    const availableSource = axios.CancelToken.source();
    setLoading1(true);
    setLoading2(true);

    const fetchAssignedData = async () => {
      let tempCourses = []
      try {
        const res = await GetAssessmentAssignedOrNotAssignedListCancelToken({ "questionId": id, "type": "assigned" }, assignedSource.token);
        if(res.status == 200){
          setAssignedData(res?.data?.assessment);
          setLoading1(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading1(false);
        }
      }
    };

    const fetchAvailableData = async () => {
      let tempCourses = []
      try {
        const res = await GetAssessmentAssignedOrNotAssignedListCancelToken({ "questionId": id, "type": "available" }, availableSource.token);
        if(res.status == 200){
          setAvailabledata(res?.data?.assessment);
          setLoading2(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading2(false);
        }
      }
    };
  
    fetchAssignedData();
    fetchAvailableData();

    return () => {
      assignedSource.cancel('Component unmounted');
      availableSource.cancel('Component unmounted');
    };
  }, [refresh]);

  // useEffect(() => {
  //   setLoading1(true);
  //   setLoading2(true);
  //   GetAssessmentAssignedOrNotAssignedList({ "questionId": id, "type": "assigned" }).then((res) => {
  //     setAssignedData(res?.data?.assessment);
  //     setLoading1(false);
  //   }).catch((err) => console.error(err));
  //   GetAssessmentAssignedOrNotAssignedList({ "questionId": id, "type": "available" }).then((res) => {
  //     setAvailabledata(res?.data?.assessment);
  //     setLoading2(false);
  //   }).catch((err) => console.error(err));
  // }, [refresh])

  const handlerefresh = () => {
    setRefresh(!refresh);
  }

  const question = [
    { value: "Multiple Choice Single Answer", label: "Multiple Choice Single Answer" },
    { value: "Multiple Choice Multiple Answer", label: "Multiple Choice Multiple Answer" },
    { value: "Match the Column", label: "Match The Column" },
    // { value: "Fill in the missing word", label: "Fill in the missing word" },
    { value: "Short Answer", label: "Short Answer" },
    { value: "Long Answer", label: "Long Answer" },
  ];

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let background = RenderPMCStatus(data.value).bgColor;
      let color = RenderPMCStatus(data.value).colorCode;

      return {
        ...styles,
        backgroundColor: background,
        color: color,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  const nqfOptions = [
    { label: "NQF 1", value: "NQF 1", data: "1" },
    { label: "NQF 2", value: "NQF 2", data: "2" },
    { label: "NQF 3", value: "NQF 3", data: "3" },
    { label: "NQF 4", value: "NQF 4", data: "4" },
    { label: "NQF 5", value: "NQF 5", data: "5" },
    { label: "NQF 6", value: "NQF 6", data: "6" },
    { label: "NQF 7", value: "NQF 7", data: "7" },
    { label: "NQF 8", value: "NQF 8", data: "8" },
    { label: "NQF 9", value: "NQF 9", data: "9" },
    { label: "NQF 10", value: "NQF 10", data: "10" },
  ];

  // const nqfOptions = [
  //   { label: "NQF Level 1", value: "NQF Level 1", data: "1" },
  //   { label: "NQF Level 2", value: "NQF Level 2", data: "2" },
  //   { label: "NQF Level 3", value: "NQF Level 3", data: "3" },
  //   { label: "NQF Level 4", value: "NQF Level 4", data: "4" },
  //   { label: "NQF Level 5", value: "NQF Level 5", data: "5" },
  //   { label: "NQF Level 6", value: "NQF Level 6", data: "6" },
  //   { label: "NQF Level 7", value: "NQF Level 7", data: "7" },
  //   { label: "NQF Level 8", value: "NQF Level 8", data: "8" },
  //   { label: "NQF Level 9", value: "NQF Level 9", data: "9" },
  //   { label: "NQF Level 10", value: "NQF Level 10", data: "10" },
  // ];

  const statusArr = [
    {
      value: "Draft",
      label: HtmlParser(`<span class="red" title="Draft">Draft</span> `)
    },
    {
      value: "New",
      label: HtmlParser(`<span class="skyblue" title="New">New </span>`)
    },
    {
      value: "Final",
      label: HtmlParser(`<span class="green" title="Final">Final</span>`)
    },
    {
      value: "Approved",
      label: HtmlParser(`<span class="yellowshade" title="Approved">Approved</span>`)
    },
    {
      value: "Inactive",
      label: HtmlParser(`<span class="blue" title="Inactive">Inactive</span>`)
    },
  ]

  const level = [
    { value: "1", label: "Easy" },
    { value: "2", label: "Medium" },
    { value: "3", label: "Hard" }
  ];
  let selectedTopics = [];
  let topicss = topicList?.map(item=>{
    return topicArray?.map(val=>{
      if(item.value == val){
        return selectedTopics.push(item);
      }
    })
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // cid: data[0]?.cid ? data[0]?.cid : "",
      coursename: data[0]?.name ? data[0]?.name : "",
      lid: data[0]?.lid ? data[0]?.lid : "",
      levelname: data[0]?.level_name ? data[0]?.level_name : "",
      question_type: selectedQ,
      shortAnswer: data && data[0] && data[0].q_option !== null ? data[0].q_option : "",
      matchItemArr: data ? data : [],
      selectedOption: data ? data.length : 0,
      editorState3: "",
      question: editorState1,
      // question: (convertToRaw(editorState1.getCurrentContent())).blocks[0].text,
      isSaving: false,
      question_name: data[0]?.question_name ? data[0]?.question_name : "",
      nqf_level: data[0]?.nqf_level ? ("NQF "+data[0]?.nqf_level) : "",
      nqf_level_label: "",
      status: data[0]?.status ? data[0]?.status : "",
      score: data[0]?.correct_score ? data[0]?.correct_score : "",
      keywords: selectKeywords,
      topic: selectedTopics,
    },
    validationSchema: Yup.object({
      question_name: Yup.string().required('Name is required'),
      status: Yup.string().required('Status is required'),
      keywords: Yup.array().min(1, "Atleast one Keyword is required").required("Keyword is required").nullable(),
      topic: Yup.array().min(1, "Atleast one Topic is required").required("Topic is required").nullable(),
      question: Yup.string().test("required", "Question is Required", (value) => {
        const editorJSON1 = editorState1;
        let isInvalid = (editorJSON1 == "")
        return !isInvalid;
      }),
      editorState3: Yup.string().test("required", "Option is required", (value) => {
        let isValid = true;
        if (selectedQ === "Multiple Choice Multiple Answer" || selectedQ === "Multiple Choice Single Answer") {
          isValid = Object.keys(editorState3).length == (formik.values.selectedOption)
          Object.keys(editorState3).map(async (key, index) => {
            if (isValid) {
              let tempEditor;
              tempEditor = editorState3[key]
              isValid = (editorState3[key] !== "")
            }
          });
          if (!isValid) {
            return isValid
          }
        }
        if (selectedQ === "Multiple Choice Multiple Answer") {
          isValid = !(!checkedAns.length) // two times ! will return a true or false value instead of thruthy and falsy value
          return isValid
        } else if (selectedQ === "Multiple Choice Single Answer") {
          isValid = !(!selectedAns) // two times ! will return a true or false value instead of thruthy and falsy value
          return isValid
        } else if (selectedQ === "Match The Column" || selectedQ === "Match the Column") {
          formik.values.matchItemArr.map((item) => {
            if (item.q_option == "" || item.q_option_match == "") {
              isValid = false
              return isValid
            }
          })
          return isValid;
        }
        return isValid
      }),
      shortAnswer: Yup.string()
        .when("question_type", {
          is: "Short Answer",
          then: Yup.string().required("Possible Answers is required").trim(),
        }),
    }),

    onSubmit: (values) => {
      if (values.isSaving) {
        setDisabled(true)
        // const editorStateJSON1 = draftToHtml(
        //   convertToRaw(editorState1.getCurrentContent())
        // );
        // const editorStateJSON2 = draftToHtml(
        //   convertToRaw(editorState2.getCurrentContent())
        // );
        // const editorJSON1 = HtmlParser(editorStateJSON1)
        // const editorJSON2 = HtmlParser(editorStateJSON2)
        const editorJSON1 = editorState1;
        // const editorJSON1 = JSON.stringify(convertToRaw(editorState1.getCurrentContent()));
        const editorJSON2 = editorState2;
        // const editorJSON2 = JSON.stringify(convertToRaw(editorState2.getCurrentContent()));
        let val = []
        if (selectedQ === "Multiple Choice Single Answer" || selectedQ === "Multiple Choice Multiple Answer" || selectedQ === "Match The Column" || selectedQ === "Match the Column") {
          let arr = { ...editorState3 };
          Object.keys(arr).map((key, index) => {
            // if (key.hasOwnProperty('_immutable')) {
            //   val.push({ optionId: data[index].oid, "q_option": JSON.stringify(convertToRaw(key.getCurrentContent())), "score": selectedQ === "Multiple Choice Single Answer" ? (selectedAns.toString() === index.toString() ? "1" : "0") : (checkedAns[index] ? `${1 / checkedAns.filter(Boolean).length}` : "0") })
            // }
            // else {
            val.push({ optionId: data[index].oid, "q_option": arr[key], "score": selectedQ === "Multiple Choice Single Answer" ? (selectedAns.toString() === index.toString() ? "1" : "0") : (checkedAns[index] ? `${1 / checkedAns.filter(Boolean).length}` : "0") })
            // }

          })
          values = { ...values, optArr: val, question: editorJSON1, description: editorJSON2 };
        } else if (selectedQ === "Short Answer") {
          values = { ...values, optArr: [{ q_option: values.shortAnswer, score: "1", oid: data[0].oid }], question: editorJSON1, description: editorJSON2 };

        } else {
          values = { ...values, question: editorJSON1, description: editorJSON2 };
        }
        // return
        // values = { ...values, cid: selectedCourse.value }
        const tempArr = values.topic.map(item=>{return item?.value});
        const topics = tempArr;
        const keywords = values.keywords;
          values = { ...values, duplicateId: id, keywords: JSON.stringify(keywords), topic: topics.join(",") }
          AddaQuestion(values).then((res) => {
            Swal.fire({ icon: "success", title: "Success", text: "Added Successfully" });
            history.push("/courseAdministration/smartAssessment/questionBank");
            setDisabled(false)
          }).catch((err) => {
            Swal.fire({ icon: "error", title: "Error", text: err.response.data.message });
            setDisabled(false)
          })
        
      } else {
        const editorJSON1 = editorState1;
        // const editorJSON1 = JSON.stringify(convertToRaw(editorState1.getCurrentContent()));
        const editorJSON2 = editorState2;
        // const editorJSON2 = JSON.stringify(convertToRaw(editorState2.getCurrentContent()));
        let option = []
        if (selectedQ === "Multiple Choice Single Answer" || selectedQ === "Multiple Choice Multiple Answer") {
          option = Object.keys(editorState3).map((key) => {
            return { q_option: editorState3[key].blocks ? JSON.stringify(editorState3[key]) :  HtmlParser(editorState3[key]), q_option_match: "", oid: Math.floor(Math.random() * 10) + 1 }
          })
        } else {
          option = formik.values.matchItemArr ? formik.values.matchItemArr.map((item) => {
            return item
          }) : []
        }
        // let option = data.map((item) => {
        //   return { q_option: item.q_option, q_option_match: item.q_option_match ? item.q_option_match : "", oid: item.oid }
        // })=
        $("#previewQuestion").modal('show');
        setQuestionData({ question_type: values.question_type, question: editorJSON1, description: editorJSON2, options: option })
      }
    }
  });

  function updateOptions(key, value) {
    setEditorState3((prev) => {
      let obj = { ...prev }
      Object.keys(obj).map((k, i) => {
        if (k === key) {
          obj[k] = value;
        }
      })
      return obj;
    })
  }

  const optionArray = () => {
    let arr = arrayOfEditors();

    const handleEditorState = (e, index) => {
      arr[index] = e;
      const newstate = { ...editorState3 }
      newstate[index] = e

      const stateEditor = [...state]
      stateEditor[index] = e
      setState(stateEditor)
      setEditorState3(newstate);
    }

    const getBase64 = async (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    function uploadImageCallBack(file) {
      return new Promise((resolve, reject) => {
        getBase64(file)
          .then((data) => {
            resolve({ data: { link: data } });
          })
          .catch((error) => {
            reject(error);
          });
      });
    }

    const handlePastedFiles = async (files, i) => {
      getBase64(files[0])
        .then((data) => {
          const newstate = { ...editorState3 }
          newstate[i] = insertImage(data, i)
          setEditorState3(newstate);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const insertImage = (url, i) => {
      const contentState = editorState3[i].getCurrentContent();
      const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: url });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState3[i], { currentContent: contentStateWithEntity });
      return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
    };

    const handleCheck = (event, position) => {
      const updatedCheckedState = checkedAns.map((item, index) => index === position ? !item : item);
      setCheckedAns(updatedCheckedState);
    };

    return arr.map((element, i) => {
      return (
        <div key={i} className="form-icon-group d-block">
          {selectedQ === "Multiple Choice Single Answer" ?
            <>
              <div>
                <input type="radio" checked={selectedAns === i.toString()} onChange={() => setselectedAns(i.toString())} name="ID"
                // disabled={subtab === "view"}
                />
                <label className="ml-1">Option {i + 1}</label>
              </div>
            </> :
            <>
              <div className="custom-control custom-checkbox text-left mt-4">
                <input type="checkbox" name="" className="custom-control-input" id={`customCheck${i}`} onChange={(e) => handleCheck(e, i)} checked={checkedAns[i]}
                // disabled={subtab === "view"}
                />
                <label className="custom-control-label" htmlFor={`customCheck${i}`}>Option {i + 1}</label>
              </div>
            </>}

          <div className="mb-4" >
            <HtmlInputEditor 
              editorState={editorState3[('customCheck' + i)]} 
              updateOptions={(editor) => {
                updateOptions('customCheck' + i, editor);
              }} 
              hideSign={true} 
              isCKEditor={true} 
              from = "quizManagement"
            />
            {/* {data && <CKEditor
              initData={HtmlParser(element)}
              onChange={({ editor }) => {
                updateOptions('customCheck' + i, editor.getData());
                // newstate['customCheck' + i] = editor.getData()
                // setEditorState3(newstate);
              }}
              onInstanceReady={({ editor }) => {
                editor.setData(HtmlParser(element));
              }}
              config={{
                preset: 'full',
                // use these plugins if ckeditor preset is set to standard and not full
                extraPlugins: 'forms,iframe,font,colordialog,colorbutton,embed,emoji,justify,bidi,exportpdf,templates,smiley,showblocks,selectall,scayt,find,entities,basicstyles,mathjax',
                // extraPlugins:'dialog',
                // toolbar: 'full',
                //below code is to run videos in ckeditor
                embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
                font_names: 'default;Arial;Comic Sans MS;Courier New;Georgia;Lucida Sans Unicode;Tahoma;Times New Roman;Trebuchet MS;Verdana;Segeo UI Light;',
                uploadUrl:'/uploadEditorFiles',
                filebrowserUploadUrl:`${API_BASE}/uploadEditorFiles`,
                mathJaxLib: '//cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-AMS_HTML',
                filebrowserBrowseUrl:`/fileSystem` // to open file system on browse button click 
              }}
              onFileUploadRequest={async function( evt ) {
                var fileLoader = evt.data.fileLoader,
                    formData = new FormData(),
                    xhr = fileLoader.xhr;
                // let userToken = await localStorage.getItem("token") // user authorization
                // console.log("lkjlkiono", userToken)
                // xhr.setRequestHeader( 'Authorization', `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOjQ1NCwiaWF0IjoxNjc5NjMzNjIxLCJleHAiOjE2Nzk2Njk2MjF9.y5lLd6r7GLv6-VHwK6CGtYv2CQltOtGnJWNMYmcs7VA` );
                xhr.open( 'POST', fileLoader.uploadUrl, true );
                formData.append( 'document', fileLoader.file, fileLoader.fileName);
                fileLoader.xhr.send( formData );
            
                // Prevented the default behavior.
                evt.stop();
              }}
            onFileUploadResponse={function( evt ) {
              // Prevent the default response handler.
              evt.stop();
          
              // Get XHR and response.
              var data = evt.data,
                  xhr = data.fileLoader.xhr,
                  response = xhr.responseText.split( '|' );
                  response[0] = IMAGE_URL + response[0].replace("home/myaie/public_html/", "").replace("public", "");
          
              if ( response[ 1 ] ) {
                  // An error occurred during upload.
                  data.message = response[ 1 ];
                  evt.cancel();
              } else {
                  data.url = response[ 0 ];
              }
            } }
            />} */}
            {/* <Editor
              editorClassName="editorClassName"
              defaultEditorState={element}
              onChange={(e) => handleEditorState(e, i)}
              handlePastedFiles={(e) => handlePastedFiles(e, i)}
              toolbar={{
                image: { urlEnabled: true, uploadEnabled: true, uploadCallback: uploadImageCallBack },
              }}
              key={i}
            /> */}
          </div>

        </div>
      )
    }
    )
  }

  const renderColumn = () => {
    const handleChangeNonLinked = (e, ID, values) => {
      let tempArr = formik.values.matchItemArr;
      tempArr.map((val) => {
        if (val.oid == ID) {
          val[values] = e.target.value;
          return val;
        } else {
          return val;
        }
      })
      formik.setFieldValue("matchItemArr", tempArr)
    };

    return formik.values.matchItemArr.map((element, i) => {
      return (
        <div key={i} className="form-icon-group d-block">
          <div className="row"><div className="col-4">
            <label>Item *</label>
            <div className="form-icon-group mb-4"
              title="Label">
              <input type="text"
                className={"form-control" + (formik.errors.editorState3 && formik.touched.editorState3 ? " is-invalid" : "")}
                name="item"
                placeholder="Label"
                title="Item"
                value={element.q_option}
                onChange={(e) => {
                  handleChangeNonLinked(e, element.oid, "q_option")
                }}
              />
            </div>
          </div>
            <div className="col-4">
              <label>Match *</label>
              <div className="form-icon-group mb-4"
                title="Label">
                <input type="text"
                  className={"form-control" + (formik.errors.editorState3 && formik.touched.editorState3 ? " is-invalid" : "")}
                  name="match"
                  placeholder="Label"
                  title="Match"
                  value={element.q_option_match}
                  onChange={(e) => {
                    handleChangeNonLinked(e, element.oid, "q_option_match")
                  }}
                />
              </div>
            </div></div>
        </div>
      );
    });
  };

  const handleKeyDown1 = (event) => {
    let keywords = selectKeywords;
    if (event.key === "Enter") {
      if (event.target.value) {
        keywords.push(event.target.value);
        event.target.value = ""
        formik.setFieldValue("keywords", keywords);
      }
    }
  };
  const removeKeywords = (index) => {
    let keyword = selectKeywords;
    let keywords = keyword.splice(index, 1);
    formik.setFieldValue("keywords", keyword);
  };
  const handleKeyDown = (event) => {
    let answers = possibleAnswers;
    if (event.key === "Enter") {
      if (event.target.value) {
        answers.push(event.target.value);
        event.target.value = ""
        setPossibleAnswers((answers) => [...answers]);
        formik.setFieldValue("shortAnswer", answers);
      }
    }
  };

  const removeAns = (index) => {
    let keyword = possibleAnswers;
    keyword.splice(index, 1)
    setPossibleAnswers((keyword) => [...keyword])
    formik.setFieldValue("shortAnswer", keyword);
  };

  return (
    <>
      
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block tabs-wrap">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        Duplicate <span> Question </span>
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                            <i className="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              <div className="card card-profile-info-card">
                <div className={"card-body"}>
                  <div className="card-body-inr card-body-info">
                      <form onSubmit={formik.handleSubmit}>
                        <>
                          <div className="row">
                            <div className="col-md-6 col-lg-3 d-none">
                              <label>Question Type *</label>
                              <div className="form-icon-group mb-4">
                                <Select
                                  className="form-control custom-select-box custom-profile-page"
                                  name="question"
                                  value={selectedQ}
                                  onChange={(value) => {
                                    if (value) {
                                      setSelectedQ(value.value);
                                    } else {
                                      setSelectedQ("");
                                    }
                                  }}
                                  options={question}
                                  maxMenuHeight={175}
                                  placeholder={selectedQ}
                                  isDisabled={true}
                                  isClearable
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <label htmlFor="question_name">Name *</label>
                              <div className="form-icon-group mb-4">
                                <input
                                  type="text"
                                  // className={"form-control"}
                                  className={"form-control" + (formik.errors.question_name && formik.touched.question_name ? " is-invalid" : "")}
                                  name="question_name"
                                  id="question_name"
                                  title="Name"
                                  value={formik.values.question_name}
                                  placeholder="Name"
                                  onChange={(e) => formik.setFieldValue("question_name", e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-2">
                              <label>NQF Level</label>
                              <div className="form-icon-group mb-4" title="NQF Level">
                                <Select
                                  className={"form-control custom-select-box" + (formik.errors.nqf_level && formik.touched.nqf_level ? " is-invalid" : "")}
                                  name="nqf_level"
                                  value={nqfOptions?.filter(val=>val.value === formik.values.nqf_level)}
                                  onChange={(value) => { 
                                    if (value) {
                                      formik.setFieldValue("nqf_level", value.value); 
                                    } else {
                                      formik.setFieldValue("nqf_level", ""); 
                                    }
                                  }}
                                  isClearable
                                  onBlur={formik.handleBlur}
                                  options={nqfOptions}
                                  maxMenuHeight={175}
                                  placeholder={formik.values.nqf_level ? formik.values.nqf_level : "Select"}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-2" title="Difficulty">
                              <label>Lavel</label>
                              <div className="form-icon-group mb-4">
                                <Select
                                  className={"form-control custom-select-box custom-profile-page" + (formik.errors.lid && formik.touched.lid ? " is-invalid" : "")}
                                  name="lid"
                                  value={level?.filter(val=>val.value == formik.values.lid)}
                                  onChange={(value) => {
                                    if (value) {
                                      formik.setFieldValue("lid", value.value);
                                      formik.setFieldValue("levelname", value.label);
                                    } else {
                                      formik.setFieldValue("lid", "");
                                      formik.setFieldValue("levelname", "");
                                    }
                                  }}
                                  isClearable
                                  onBlur={formik.handleBlur}
                                  options={level}
                                  maxMenuHeight={175}
                                  placeholder={formik.values.lid ? formik.values.levelname : "Select"}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-2">
                              <label>Status *</label>
                              <div className="form-icon-group mb-4" title="Status">
                                <Select
                                  styles={colourStyles}
                                  className={"form-control color-drop custom-select-box" + (formik.errors.status && formik.touched.status ? " is-invalid" : "")}
                                  name="status"
                                  value={statusArr?.filter(val=> val.value === formik.values.status)}
                                  onChange={(value) => {
                                    if (value) {
                                      formik.setFieldValue("status", value.value)
                                    } else {
                                      formik.setFieldValue("status", "")
                                    }
                                  }}
                                  isClearable
                                  // menuIsOpen={true}
                                  onBlur={formik.handleBlur}
                                  options={statusArr}
                                  maxMenuHeight={175}
                                  placeholder={formik.values.status ? formik.values.status : "Select"}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <label htmlFor="score">Score *</label>
                              <div className="form-icon-group mb-4">
                                <input
                                  type="text"
                                  // className={"form-control"}
                                  className={"form-control" + (formik.errors.score && formik.touched.score ? " is-invalid" : "")}
                                  name="score"
                                  id="score"
                                  title="Score"
                                  value={formik.values.score}
                                  placeholder="Score"
                                  onChange={(e) => formik.setFieldValue("score", e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-2 mb-4">
                              <label>Keywords*</label>
                              <div className="form-icon-group">
                                <div className="form-control custom-select-box">
                                  <input
                                    type="text"
                                    className={"form-control " + (formik.errors.keywords && formik.touched.keywords ? " is-invalid" : "")}
                                    name="keywords"
                                    placeholder="keywords"
                                    title="keywords"
                                    onBlur={formik.handleBlur}
                                    onKeyDown={handleKeyDown1}
                                  />
                                  {formik.errors.keywords && formik.touched.keywords ? (<span className="exclamation"><i className="fal fa-exclamation-circle"></i></span>) : null}
                                </div>
                              </div>
                              {selectKeywords.length > 0 && (
                                <ul className="tag-list mb-0">
                                  {selectKeywords?.map((ele, index) => (
                                    <li key={index}>
                                      {ele}
                                      <span onClick={() => removeKeywords(index)}>
                                        <i className="fal fa-times"></i>
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                            <div className="col-md-6 col-lg-2 mb-4">
                              <div className="form-group-blk" title="Topic">
                                <label>Topic*</label>
                                <Select
                                  styles={multiSelectColorStyles}
                                  className={"form-control color-drop custom-select-box is-multi" + (formik.errors.topic && formik.touched.topic ? " is-invalid" : "")}
                                  name="topic"
                                  value={formik.values.topic}
                                  onChange={(value) => formik.setFieldValue("topic", value)}
                                  onBlur={formik.handleBlur}
                                  options={topicList}
                                  maxMenuHeight={175}
                                  isMulti={true}
                                  placeholder="Select Topic"
                                />
                              </div>
                            </div>
                          </div>
                        </>
                        <>
                          <div className="form-icon-group d-block">
                            <label>Question *</label>
                            {<HtmlInputEditor editorState={editorState1} setEditorState={setEditorState1} hideSign={true} isCKEditor={true} from = "quizManagement"/> }
                          </div>
                          <div className="form-icon-group d-block">
                            <label>Description</label>
                            {<HtmlInputEditor editorState={editorState2} setEditorState={setEditorState2} hideSign={true} isCKEditor={true} from = "quizManagement"/> }
                          </div>
                        </>
                        <>
                          {(selectedQ === "Multiple Choice Single Answer" || selectedQ === "Multiple Choice Multiple Answer") ? optionArray() : null}
                        </>
                        <div className="course-info-sec">
                          <div className="row">
                            <>
                              {selectedQ === "Short Answer" ?
                                <div className="col-md-6 col-lg-4 mb-4">
                                  <label>Possible Answers *</label>
                                  <div className="form-icon-group" title="Label">
                                    <input type="text"
                                      className={"form-control" + (formik.errors.shortAnswer && formik.touched.shortAnswer ? " is-invalid" : "")}
                                      name="shortAnswer"
                                      placeholder="Possible Answers"
                                      title="Possible Answers"
                                      // value={formik.values.shortAnswer}
                                      // onChange={(e) => {
                                      //   formik.setFieldValue("shortAnswer", e.target.value)
                                      // }}
                                      onKeyDown={handleKeyDown}
                                      onBlur={formik.handleBlur} />
                                  </div>
                                  {possibleAnswers.length > 0 && (
                                      <ul className="tag-list mb-0">
                                        {possibleAnswers?.map((ele, index) => (
                                          <li>
                                            {ele}
                                            <span onClick={() => removeAns(index)}>
                                              <i className="fal fa-times"></i>
                                            </span>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                </div> : null}
                            </>
                          </div>
                        </div>
                        <>{selectedQ === "Match The Column" || selectedQ === "Match the Column" ? renderColumn() : null}</>
                        <div className="form-group form-group-save-cancel mt-4">
                          <PermissionsGate scopes={["cquestionbedit"]} errorProps={{ disabled: true }}>
                            <button type="button" className="btn btn-primary" disabled={disabled} title="Save" onClick={() => {
                              formik.setFieldValue("isSaving", true)
                              formik.handleSubmit()
                            }}>
                              {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                              Save
                            </button>
                          </PermissionsGate>
                          <button type="button" className="btn btn-primary" title="Preview" onClick={() => {
                            formik.setFieldValue("isSaving", false)
                            formik.handleSubmit()
                          }}>
                            <i className="fal fa-file-alt"></i>
                            Preview
                          </button>
                          <button type="button" className="btn btn-danger" onClick={() => history.goBack()} title="Cancel"><i className="fal fa-times"></i>Cancel</button>
                          {Object.keys(formik.errors).map((key)=> (formik.errors[key] && formik.touched[key] ? <div className="invalid-feedback d-block">{formik.errors[key]}</div> : null))}
                        </div>
                      </form>
                  </div>
                </div>
                <div
                  className="topic-add-modal modal fade"
                  id="previewQuestion"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered modal-lg"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="topic-add-modal-content text-center font-weight-bold">
                          View Question
                        </div>
                        <hr />
                        <DisplayQuestion question={questionData} from="duplicateQuestion" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DuplicateQuestion;
