import React from "react";
import { connect } from "react-redux";
import { setUnreadNotificationCount, setWidget, unsetWidget } from "../store/actions";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import HomeSlider from "../components/common/HomeSlider";
import HomeWishes from "../components/common/HomeWishes";
import ListofDashBoard from "../components/DashBoardComp/ListofDashBoard";
import ListofAssessment from "../components/DashBoardComp/ListofAssessmenttable";
import ListofMentored from "../components/DashBoardComp/ListofMentored";
import ListofCourse from "../components/DashBoardComp/Course";
import ListofAppeals from "../components/DashBoardComp/Appeals";
import TicketDetailCourses from "../components/DashBoardComp/Ticket";
import { CreateOrUpdateQuickLink, GetDashboardAllCounts, GetDashboardAllCountsCancelToken, GetDropdownCategory, GetDropdownCategoryCancelToken, GetDropdownCourseList, GetDropdownCourseListCancelToken, GetDropdownDepartments, GetDropdownDepartmentsCancelToken, GetDropdownLecturer, GetDropdownLecturerCancelToken, GetDropdownProgrammes, GetDropdownProgrammesCancelToken, GetLinkList, GetMentoredStudentsCount, GetMentoredStudentsCountCancelToken, GetUnreadCouresNotificationCount, GetUnreadCouresNotificationCountCancelToken } from "../services/DashBoardServices";
import Swal from "sweetalert2";
import $ from "jquery";
import { URL_REGEX } from "../utils/Constants";
import moment from "moment";
import { TABLE_TIME_FORMAT } from "../utils/Constants";
import axios from "axios";

export const StaticQuickLinks = [
  {
    url_name: "NetSuite",
    url: "https://system.netsuite.com/pages/customerlogin.jsp?country=US",
    status: "static",
    permission: "qlnsview",
  },
  {
    url_name: "Payspace",
    url: "https://identity.yourhcm.com/Account/Login",
    status: "static",
    permission: "qlpsview",
  },
  {
    url_name: "Sharepoint",
    url: "https://cadcorporation.sharepoint.com/sites/AIEGroup",
    status: "static",
    permission: "qlspview",
  },
];

// import { loadWidgetChat }  from '../utils/commonFunction.js';

/** map actions */
function mapDispatchToProps(dispatch) {
  return {
    setUnreadNotificationCount: (payload) => dispatch(setUnreadNotificationCount(payload)),
    setWidget: (payload) => dispatch(setWidget(payload)),
    unsetWidget: (payload) => dispatch(unsetWidget()),
  };
}

/** map state */
const mapStateToProps = (state) => ({ notification: state.notification, user: state.user, widget: state.widget });

class HomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ariaboolean: false,
      quickLinks: [...StaticQuickLinks],
      isDisabled: false,
      isResponded: false,
      linkUrl: "",
      linkName: "",
      Departments: [],
      Category: [],
      Programmes: [],
      Lecturer: [],
      Course: [],

      unreadMessages: null,
      allCount: {},
      mentoredCount: {},
      isValid: true,
      requiredField: {
        name: false,
        url: false,
      },
    };
    this.dropdownDepartmentsSource = axios.CancelToken.source();
    this.dropdownCategorySource = axios.CancelToken.source();
    this.dropdownProgrammesSource = axios.CancelToken.source();
    this.dropdownLecturerSource = axios.CancelToken.source();
    this.dropdownCourseListSource = axios.CancelToken.source();
    this.unreadCouresNotificationCountSource = axios.CancelToken.source();
    this.dashboardAllCountsSource = axios.CancelToken.source();
    this.mentoredStudentsCountSource = axios.CancelToken.source();
  }

  componentDidMount() {
    // this.GetQuickLink();
    // this.GetDashboardFilter();
    this.GetDropdownDepartmentsFunction();
    this.GetDropdownCategoryFunction();
    this.GetDropdownProgrammesFunction();
    this.GetDropdownLecturerFunction();
    this.GetDropdownCourseListFunction();
    this.GetUnreadCouresNotificationCountFunction();
    this.GetDashboardAllCountsFunction();
    this.GetMentoredStudentsCountFunction();
    this.props.unsetWidget();
    this.props.setWidget({ status: 2 });    
  }
  
  GetDropdownDepartmentsFunction = async () => {
    try {
      const res = await GetDropdownDepartmentsCancelToken(this.dropdownDepartmentsSource.token);
      this.setState({ Departments: res.data.data });
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    }
  }

  GetDropdownCategoryFunction = async () => {
    try {
      const res = await GetDropdownCategoryCancelToken(this.dropdownCategorySource.token);
      this.setState({ Category: res.data.data });
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    }
  }

  GetDropdownProgrammesFunction = async () => {
    try {
      const res = await GetDropdownProgrammesCancelToken(this.dropdownProgrammesSource.token);
      this.setState({ Programmes: res.data.data });
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    }
  }

  GetDropdownLecturerFunction = async () => {
    try {
      const res = await GetDropdownLecturerCancelToken(this.dropdownLecturerSource.token);
      this.setState({ Lecturer: res.data.data });
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    }
  }

  GetDropdownCourseListFunction = async () => {
    try {
      const res = await GetDropdownCourseListCancelToken(this.dropdownCourseListSource.token);
      this.setState({ Course: res.data.data });
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    }
  }

  GetUnreadCouresNotificationCountFunction = async () => {
    try {
      const res = await GetUnreadCouresNotificationCountCancelToken(this.unreadCouresNotificationCountSource.token);
      this.setState({ unreadMessages: res.data.allUnreadMessage });
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    }
  }

  GetDashboardAllCountsFunction = async () => {
    try {
      const res = await GetDashboardAllCountsCancelToken(this.dashboardAllCountsSource.token);
      this.setState({ allCount: res?.data })
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    }
  }

  GetMentoredStudentsCountFunction = async () => {
    try {
      const res = await GetMentoredStudentsCountCancelToken(this.mentoredStudentsCountSource.token);
      this.setState({ mentoredCount: res?.data })
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    }
  }

  // GetDashboardFilter = () => {
  //   GetDropdownDepartments().then((res) => {
  //     this.setState({ Departments: res.data.data });
  //   }).catch((err) => {
  //     console.log(err);
  //   })
  //   GetDropdownCategory()
  //     .then((res) => {
  //       this.setState({ Category: res.data.data });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   GetDropdownProgrammes()
  //     .then((res) => {
  //       this.setState({ Programmes: res.data.data });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   GetDropdownLecturer()
  //     .then((res) => {
  //       this.setState({ Lecturer: res.data.data });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   GetDropdownCourseList()
  //     .then((res) => {
  //       this.setState({ Course: res.data.data });
  //       // this.setState({ Course: finalCourseArr });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   GetUnreadCouresNotificationCount()
  //   .then((res) => {
  //     this.setState({ unreadMessages: res.data.allUnreadMessage });

  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   })
  //   GetDashboardAllCounts()
  //   .then((res) => {
  //     this.setState({ allCount: res?.data })
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   })
  //   GetMentoredStudentsCount()
  //   .then((res) => {
  //     this.setState({ mentoredCount: res?.data })
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   })

  // }

  componentWillUnmount() {
    this.dropdownDepartmentsSource.cancel('Component unmounted');
    this.dropdownCategorySource.cancel('Component unmounted');
    this.dropdownProgrammesSource.cancel('Component unmounted');
    this.dropdownLecturerSource.cancel('Component unmounted');
    this.dropdownCourseListSource.cancel('Component unmounted');
    this.unreadCouresNotificationCountSource.cancel('Component unmounted');
    this.dashboardAllCountsSource.cancel('Component unmounted');
    this.mentoredStudentsCountSource.cancel('Component unmounted');
  }

  // GetQuickLink = () => {
  //   GetLinkList()
  //     .then((res) => {
  //       this.setState({ isResponded: true });
  //       this.setState({ quickLinks: [...StaticQuickLinks, ...res.data.data] });
  //     })
  //     .catch((err) => console.log(err));
  // };

  // AddQuickLink = () => {

  //   this.setState({ isDisabled: true });
  //   !this.state?.linkUrl ? (this.state?.requiredField.url = true) : (this.state?.requiredField.url = false);
  //   !this.state?.linkName ? (this.state?.requiredField.name = true) : (this.state?.requiredField.name = false);
  //   if (this.state?.requiredField.name === true || this.state?.requiredField.url === true) {
  //     this.setState({ isDisabled: false });
  //     return;
  //   }
  //   this.regex = new RegExp(URL_REGEX);
  //   this.state?.isValid = this.regex.test(this.state?.linkUrl);
  //   if (this.state?.isValid) {
  //     Swal.fire({
  //       title: "Please Wait !",
  //       allowOutsideClick: false,
  //       onBeforeOpen: () => {
  //         Swal.showLoading();
  //       },
  //     });
  //     this.setState({ requiredField: { url: false } });
  //     this.setState({ requiredField: { name: false } });
  //     const data = new FormData();
  //     data.append("url", this.state?.linkUrl);
  //     data.append("url_name", this.state?.linkName);
  //     CreateOrUpdateQuickLink(data)
  //       .then((res) => {
  //         Swal.close();
  //         Swal.fire("Added!", "Your Link has been Added.", "success").then(() => $("#quickLinkForm").modal("hide"));
  //         this.GetQuickLink();
  //       })
  //       .catch((err) => {
  //         Swal.fire("Error!", "Your Link has Not been Added.", "error").then(() => $("#quickLinkForm").modal("hide"));
  //       });
  //   }
  //   this.setState({ isDisabled: false });
  // };

  nextClass = () => {
    const timeInStamp = this.state?.allCount?.nextClassTime * 1000;
    const time = new Date(this.state?.allCount?.nextClassTime * 1000);
    const now = new Date();
    const nowInSec = now.getTime();
    let milliSeconds = (timeInStamp - nowInSec) > 0 ? (timeInStamp - nowInSec) : 0;
    const min = Math.floor(milliSeconds / 1000 / 60);
    const hour = Math.floor(min / 60);

    if (time.getTime() > now.getTime()) {
      return <div className="next__class__timer ml-2">{`${hour}h ${min > 60 ? min - 60 : min}min`}</div>;
    } else {
      return <span>&nbsp;No classess left today</span>;
    }
  }

  
  render() {
    return (
      <>
        <Header></Header>
        <div className="content-wrapper d-flex flex-wrap">
          <SideBar></SideBar>
          <div className="sidebar-right">
            <div className="">
              <HomeWishes />
              <HomeSlider></HomeSlider>
              {/* collapse div start */}
              <div
                className="admin-home-acc accordion ac_over_none"
                id="accordionExample"
              >
                <div className="card-main">
                  <div className="card-top">
                    <div className="card-header1" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded={this.props.match.url.includes("/home/virtualClass") ? "true" : "false"}
                          aria-controls="collapseOne"
                          onClick={() =>
                            this.props.history.push(this.props.match.url.includes("/home/virtualClass/today") ? "/home" : "/home/virtualClass/today")
                          }
                        >
                          <div className={`title-border-box`}>
                            <i className="fal fa-desktop mr-1"></i>  Online Virtual
                            Classes
                            <div className="d-flex ml-auto">
                              {((this.state?.allCount?.todayClassCount !== null && this.state?.allCount?.todayClassCount !== undefined) || (this.state?.allCount?.nextClassTime !== null && this.state?.allCount?.nextClassTime !== undefined)) ?
                                <>
                                  <span className="nav-fa-comments" title="Amount of Classes Today">
                                    <small className={"notify-count "  + ((this.state?.allCount?.todayClassCount>999 && this.state?.allCount?.todayClassCount < 100000) ? "four-digit" : (this.state?.allCount?.todayClassCount>9999 && this.state?.allCount?.todayClassCount < 1000000) ? "five-digit" : (this.state?.allCount?.todayClassCount>99 && this.state?.allCount?.todayClassCount < 1000) ? "three-digit" : "")}>
                                      {this.state?.allCount?.todayClassCount}
                                    </small>
                                    <i className="fal fa-calendar comments"></i>
                                  </span>
                                  {((this.state?.allCount?.nextClassTime !== null && this.state?.allCount?.nextClassTime !== undefined ) && (this.props.user?.role_name.includes("Lecturer"))) ?
                                      <div className="next__class__wrapper d-flex align-items-center mx-2">
                                          <span>Next Class:</span>
                                          {/* <NextClassTime classTime={this.state?.allCount.nextClassTime * 1000} /> */}
                                          <span className="next__class__timer ml-2">{moment(new Date(this.state?.allCount.nextClassTime * 1000)).format(TABLE_TIME_FORMAT)}</span>
                                          {/* <span className="next__class__timer ml-2">&nbsp;No classes left today</span> */}

                                      </div>
                                    :null  }
                                </>
                                : <span className="dashboard-loading"> <i className="fas fa-cog fa-spin"></i></span>}
                            </div>
                          </div>{" "}
                          <div className="header-status">
                            <div className="status-gol card-blue-1">
                              <i className="fal fa-chevron-down rotate-icon"></i>
                            </div>
                          </div>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne"
                      className={`collapse ${this.props.match.params.tab == "virtualClass"
                        ? "show"
                        : ""
                        }  `}
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card card-body">
                        {this.props.match.params.tab == "virtualClass" && (
                          <ListofDashBoard
                            Course={this.state?.Course}
                            Lecturer={this.state?.Lecturer}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-main">
                  <div className="card-top">
                    <div className="card-header1" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded={this.props.match.url.includes("/home/assessment/Today") ? "true" : "false"}
                          aria-controls="collapseTwo"
                          onClick={() =>
                            this.props.history.push(this.props.match.url.includes("/home/assessment/Today") ? "/home" : "/home/assessment/Today")
                          }
                        >
                          <div className={`title-border-box`}>
                            <i className="fal fa-file-alt"></i> Assessments
                            <div
                              className="d-flex ml-auto"
                            >
                              {((this.state?.allCount?.startToday !== null && this.state?.allCount?.startToday!==undefined) 
                              || (this.state?.allCount?.markingDueToday  !== null && this.state?.allCount?.markingDueToday !==undefined)
                              || (this.state?.allCount?.markingOverdue  !== null && this.state?.allCount?.markingOverdue !==undefined)) ?
                                <>
                                  <span className="nav-fa-comments" title="Starts Today">
                                    <small className={"notify-count "  + ((this.state?.allCount?.startToday>999 && this.state?.allCount?.startToday < 100000) ? "four-digit" : (this.state?.allCount?.startToday>9999 && this.state?.allCount?.startToday < 1000000) ? "five-digit" : (this.state?.allCount?.startToday>99 && this.state?.allCount?.startToday < 1000) ? "three-digit" : "")}>
                                      {this.state?.allCount?.startToday}
                                    </small>
                                    <i className="fal fa-calendar comments"></i>
                                  </span>
                                  <span className="nav-fa-comments ml-3" title="Marking Due Today">
                                    <small className={"notify-count "  + ((this.state?.allCount?.markingDueToday>999 && this.state?.allCount?.markingDueToday < 100000) ? "four-digit" : (this.state?.allCount?.markingDueToday>9999 && this.state?.allCount?.markingDueToday < 1000000) ? "five-digit" : (this.state?.allCount?.markingDueToday>99 && this.state?.allCount?.markingDueToday < 1000) ? "three-digit" : "")}>
                                      {this.state?.allCount?.markingDueToday}
                                    </small>
                                    <i className="fal fa-calendar-edit comments"></i>
                                  </span>
                                  <span className="nav-fa-comments ml-3" title="Marking Overdue">
                                    <small className={"notify-count " + ((this.state?.allCount?.markingOverdue>999 && this.state?.allCount?.markingOverdue < 100000) ? "four-digit" : (this.state?.allCount?.markingOverdue>9999 && this.state?.allCount?.markingOverdue < 1000000) ? "five-digit" : (this.state?.allCount?.markingOverdue>99 && this.state?.allCount?.markingOverdue < 1000) ? "three-digit" : "")}>
                                      {this.state?.allCount?.markingOverdue}
                                    </small>
                                    <i className="fal fa-calendar-exclamation comments"></i>
                                  </span>
                                </>
                                : <span className="dashboard-loading"> <i className="fas fa-cog fa-spin"></i></span>}
                            </div>
                          </div>{" "}
                          <div className="header-status">
                            <div className="status-gol card-blue-1">
                              <i className="fal fa-chevron-down rotate-icon"></i>
                            </div>
                          </div>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className={`collapse ${this.props.match.params.tab == "assessment" ? "show" : ""
                        }  `}
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card card-body">
                        {this.props.match.params.tab == "assessment" && (
                          <ListofAssessment Course={this.state?.Course} />
                        )}
                      </div>
                    </div>
                  </div>
                </div> 
                <div className="card-main">
                  <div className="card-top">
                    <div className="card-header1" id="headingFour">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded={this.props.match.url.includes("/home/courses/In Progress") ? "true" : "false"}
                          aria-controls="collapseFour"
                          onClick={() =>
                            this.props.history.push(this.props.match.url.includes("/home/courses/In Progress") ? "/home" : "/home/courses/In Progress")
                          }
                        >
                          <div className={`title-border-box`}>
                            <i className="fal fa-book"></i>Courses
                            <div
                              className="d-flex ml-auto"
                            // className="nav-tpFixed-comments"
                            // title="You have unread messages"
                            >
                              {(this.state?.unreadMessages !== null && this.state?.unreadMessages !== undefined) ? 
                              <span className="nav-fa-comments" title="You have unread messages">
                                <small className={"notify-count "  + ((this.state?.unreadMessages>999 && this.state?.unreadMessages < 100000) ? "four-digit" : (this.state?.unreadMessages>9999 && this.state?.unreadMessages < 1000000) ? "five-digit" : (this.state?.unreadMessages>99 && this.state?.unreadMessages < 1000) ? "three-digit" : "")}>
                                  {this.state?.unreadMessages}
                                </small>
                                <i className="fal fa-comments comments"></i>
                              </span> 
                              : <span className="dashboard-loading"> <i className="fas fa-cog fa-spin"></i></span>}
                            </div>
                          </div>
                          <div className="header-status">
                            <div className="status-gol card-blue-1">
                              <i className="fal fa-chevron-down rotate-icon"></i>
                            </div>
                          </div>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFour"
                      className={`card-body1 collapse ${this.props.match.params.tab == "courses" ? "show" : ""
                        }  `}
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                    >
                      <div className="card card-body">
                        {this.props.match.params.tab == "courses" && (
                          <ListofCourse Programmes={this.state?.Programmes} />
                        )}
                      </div>
                    </div>
                  </div>
                </div> 
                <div className="card-main">
                  <div className="card-top">
                    <div className="card-header1" id="headingFive">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded={this.props.match.url.includes("/home/appeals/pending") ? "true" : "false"}
                          aria-controls="collapseFive"
                          onClick={() =>
                            this.props.history.push(this.props.match.url.includes("/home/appeals/pending") ? "/home" : "/home/appeals/pending")
                          }
                        >
                          <div className={`title-border-box`}>
                            <i className="fal fa-gavel"></i> Appeals
                            <div
                              className="d-flex ml-auto"
                            >
                              {(this.state?.allCount?.appealPendding !== null && this.state?.allCount?.appealPendding !== undefined) ? <span className="nav-fa-comments" title="Pending Appeals Amount">
                                <small className={"notify-count " + ((this.state?.allCount?.appealPendding>999 && this.state?.allCount?.appealPendding < 100000) ? "four-digit" : (this.state?.allCount?.appealPendding>9999 && this.state?.allCount?.appealPendding < 1000000) ? "five-digit" : (this.state?.allCount?.appealPendding>99 && this.state?.allCount?.appealPendding < 1000) ? "three-digit" : "")}>
                                  {this.state?.allCount?.appealPendding}
                                </small>
                                <i className="fal fa-exclamation comments"></i>
                              </span> : <span className="dashboard-loading"> <i className="fas fa-cog fa-spin"></i></span>}
                            </div>

                          </div>{" "}
                          <div className="header-status">
                            <div className="status-gol card-blue-1">
                              <i className="fal fa-chevron-down rotate-icon"></i>
                            </div>
                          </div>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFive"
                      className={`collapse ${this.props.match.params.tab == "appeals" ? "show" : ""
                        }  `}
                      aria-labelledby="headingFive"
                      data-parent="#accordionExample"
                    >
                      <div className="card card-body">
                        {this.props.match.params.tab == "appeals" && (
                          <ListofAppeals Programmes={this.state?.Programmes} />
                        )}
                      </div>
                    </div>
                  </div>
                </div> 
                <div className="card-main">
                  <div className="card-top">
                    <div className="card-header1" id="headingSix">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded={this.props.match.url.includes("/home/tickets/summary") ? "true" : "false"}
                          aria-controls="collapseSix"
                          onClick={() =>
                            this.props.history.push(this.props.match.url.includes("/home/tickets/summary") ? "/home" : "/home/tickets/summary")
                          }
                        >
                          <div className={`title-border-box`}>
                            <i className="fal fa-ticket-alt"></i> Tickets
                            <div
                              className="d-flex ml-auto"
                            >
                              {((this.state?.allCount?.totalNewTicket !== null && this.state?.allCount?.totalNewTicket !== undefined) 
                              || (this.state?.allCount?.totalDangerTicket !== null && this.state?.allCount?.totalDangerTicket !== undefined)) ? 
                              <>
                                <span className="nav-fa-comments" title="New Tickets">
                                  <small className={"notify-count " + ((this.state?.allCount?.totalNewTicket>999 && this.state?.allCount?.totalNewTicket < 100000) ? "four-digit" : (this.state?.allCount?.totalNewTicket>9999 && this.state?.allCount?.totalNewTicket < 1000000) ? "five-digit" : (this.state?.allCount?.totalNewTicket>99 && this.state?.allCount?.totalNewTicket < 1000) ? "three-digit" : "")}>
                                    {this.state?.allCount?.totalNewTicket}
                                  </small>
                                  <i className="fal fa-laptop comments"></i>
                                </span>
                                <span className="nav-fa-comments ml-3" title="Tickets in Danger Zone">
                                  <small className={"notify-count " + ((this.state?.allCount?.totalDangerTicket>999 && this.state?.allCount?.totalDangerTicket < 100000) ? "four-digit" : (this.state?.allCount?.totalDangerTicket>9999 && this.state?.allCount?.totalDangerTicket < 1000000) ? "five-digit" : (this.state?.allCount?.totalDangerTicket>99 && this.state?.allCount?.totalDangerTicket < 1000) ? "three-digit" : "")}>
                                    {this.state?.allCount?.totalDangerTicket}
                                  </small>
                                  <i className="fal fa-file-exclamation comments"></i>
                                </span>
                              </> : <span className="dashboard-loading"> <i className="fas fa-cog fa-spin"></i></span>}
                            </div>
                          </div>{" "}
                          <div className="header-status">
                            <div className="status-gol card-blue-1">
                              <i className="fal fa-chevron-down rotate-icon"></i>
                            </div>
                          </div>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseSix"
                      className={`collapse ${this.props.match.params.tab == "tickets" ? "show" : ""
                        }  `}
                      aria-labelledby="headingSix"
                      data-parent="#accordionExample"
                    >
                      <div className="card card-body">
                        {this.props.match.params.tab == "tickets" && (
                          <TicketDetailCourses
                            Departments={this.state?.Departments}
                            AssignedTo={this.state?.Lecturer}
                            Category={this.state?.Category}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-main">
                  <div className="card-top">
                    <div className="card-header1" id="headingThree">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded={this.props.match.url.includes("/home/mentored/pending_sign_off") ? "true" : "false"}
                          aria-controls="collapseThree"
                          onClick={() => this.props.history.push(this.props.match.url.includes("/home/mentored/pending_sign_off") ? "/home" : "/home/mentored/pending_sign_off")}
                        >
                          <div className={`title-border-box`}>
                            <i className="fal fa-users"></i> Students
                            <div
                              className="d-flex ml-auto"
                            >
                              {((this.state?.mentoredCount?.pending_sign_off !== null && this.state?.mentoredCount?.pending_sign_off !== undefined) 
                              || (this.state?.mentoredCount?.notes_pending_response !== null && this.state?.mentoredCount?.notes_pending_response !== undefined)) ? 
                              <>
                                <span className="nav-fa-comments" title="Students Pending Sign-off">
                                  <small className={"notify-count " + ((this.state?.mentoredCount?.pending_sign_off>999 && this.state?.mentoredCount?.pending_sign_off < 100000) ? "four-digit" : (this.state?.mentoredCount?.pending_sign_off>9999 && this.state?.mentoredCount?.pending_sign_off < 1000000) ? "five-digit" : (this.state?.mentoredCount?.pending_sign_off>99 && this.state?.mentoredCount?.pending_sign_off < 1000) ? "three-digit" : "")}>
                                    {this.state?.mentoredCount?.pending_sign_off}
                                  </small>
                                  {/* <i class="fal fa-file-signature comments"></i> */}
                                  <i className="fal fa-calendar comments"></i>
                                </span>
                                <span className="nav-fa-comments ml-3" title="Notes Pending Response">
                                  <small className={"notify-count " + ((this.state?.mentoredCount?.notes_pending_response>999 && this.state?.mentoredCount?.notes_pending_response < 100000) ? "four-digit" : (this.state?.mentoredCount?.notes_pending_response>9999 && this.state?.mentoredCount?.notes_pending_response < 1000000) ? "five-digit" : (this.state?.mentoredCount?.notes_pending_response>99 && this.state?.mentoredCount?.notes_pending_response < 1000) ? "three-digit" : "")}>
                                    {this.state?.mentoredCount?.notes_pending_response}
                                  </small>
                                  <i className="fal fa-comment-alt-dots comments"></i>
                                </span>
                              </> : <span className="dashboard-loading"> <i className="fas fa-cog fa-spin"></i></span>}
                            </div>
                          </div>{" "}
                          <div className="header-status">
                            <div className="status-gol card-blue-1">
                              <i className="fal fa-chevron-down rotate-icon"></i>
                            </div>
                          </div>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className={`collapse ${this.props.match.params.tab == "mentored" ? "show" : ""
                        }  `}
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card card-body">                      
                        {this.props.match.params.tab == "mentored" && (
                          <ListofMentored
                            Course={this.state?.Course}
                            Programmes={this.state?.Programmes}
                            Lecturer={this.state?.Lecturer}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* collapse div End */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
