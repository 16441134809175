import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Hover from "../components/common/Hover";
import { ColorRender } from "../components/systemadministration/usermanagement/CheckRole";

export default function Test() {
  const [state1, setState1] = useState(true);
  const [state2, setState2] = useState(false);
  const [state3, setState3] = useState(false);
  const [state4, setState4] = useState(false);
  const [state5, setState5] = useState(false);

  const handleState1 = () =>{
    setState1(true);
    setState2(false);
    setState3(false);
    setState4(false);
    setState5(false);
  }

  const handleState2 = () =>{
    setState1(false);
    setState2(true);
    setState3(false);
    setState4(false);
    setState5(false);
  }

  const handleState3 = () =>{
    setState1(false);
    setState2(false);
    setState3(true);
    setState4(false);
    setState5(false);
  }

  const handleState4 = () =>{
    setState1(false);
    setState2(false);
    setState3(false);
    setState4(true);
    setState5(false);
  }
  
  const handleState5 = () =>{
    setState1(false);
    setState2(false);
    setState3(false);
    setState4(false);
    setState5(true);
  }
  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap new-standard-box">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="tabs-wrap">
                  <div className="card">
                  <div class="search-filter-div mb-4">
                    <div class="search-filter-div-left">
                        <div class="system-administration-table table-responsive">
                          <div class="table-responsive-div">
                              <div id="assessment-table-main_wrapper" class="dataTables_wrapper no-footer">
                                <div id="assessment-table-main_filter" class="dataTables_filter">
                                    <label><input type="search" class="" placeholder="Search" aria-controls="assessment-table-main" value="" /></label>
                                    <div class="filter-eff filter-data-btn ml-2"><button class="filter-buttons"><i class="fal fa-filter"></i></button></div>
                                </div>
                              </div>
                          </div>
                          <div class="filter-button-group">
                              <div class="reset-btn-group">
                                <div class="button-reset dropdown-comman"><button class="btn btn-primary" title="Reset"><span title="Reset"><i class="fal fa-redo"></i>Reset</span></button></div>
                                <div class="files-export-group"><button type="button" class="btn btn-files" title="Export spreadsheet"><i class="fal fa-file-excel icon"></i></button><button type="button" class="btn btn-files" title="Export CSV"><i class="fal fa-file-csv icon"></i></button><button type="button" class="btn btn-files" title="Export PDF"><i class="fal fa-file-pdf icon"></i></button></div>
                              </div>
                          </div>
                        </div>
                    </div>
                    <div class="search-filter-div-right">
                      <div class=" filter-search-bar-blk">
                        <div class="add-ticket-blk button-reset dropdown-comman">
                          <div>
                            <button class="btn btn-primary" title="Assign Programme" data-toggle="modal" data-target="#Assign-Programme">
                            <i class="fal fa-eye"></i> Percentage View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div className="table-book-responsive">
                    <div className="table-book row m-0">
                      <div className="table-book-left">
                        <table className="table-book-main">
                          <thead>
                            <tr>
                              <th>Student</th>
                              <th>Email</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="assigned-title-block-new">
                                  <div className="assigned-title-itm">
                                    <span
                                      className={
                                        "assigned-title-blk  name-icon cat-dark-red " +
                                        ColorRender("Student")
                                      }
                                    >
                                      <img
                                        src={`http://myaie.ac/Library/Profiles/15461/15461-1683366323646.jpg`}
                                        alt="AIE"
                                      />              
                                      <span className={`profile-box-2-status Online`}>
                                        <i className="fas fa-circle"></i>
                                      </span>
                                      <Hover
                                        firstName={"Kuldeep"}
                                        lastName={"Mahawar"}
                                        photo={`http://myaie.ac/Library/Profiles/15461/15461-1683366323646.jpg`}
                                        email={"kuldeep@gamil.com"}
                                        mobile={"9999988888"}
                                        status={"Online"}
                                        activity_status={"Active"}
                                        right={true}
                                        showNumber={true} 
                                        number={"3454534543"}
                                      />
                                    </span>
                                    {/* <PermissionsGate scopes={["sgiview"]} RenderError={() => <p>{row.first_name + " " + row.last_name}</p>}> */}
                                    <Link
                                      className="as-text-blue curser"
                                      to={"/test"}
                                      title={"Kuldeep" + " " + "Mahawar"}
                                    >
                                      <span className="textLimit100">{"Kuldeep" + " " + "Mahawar"}</span>
                                    </Link>
                                    {/* </PermissionsGate> */}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span class="feature-name" title="nicos3694@gmail.com">
                                  <span class="textLimit100">nicos3694@gmail.com</span>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="assigned-title-block-new">
                                  <div className="assigned-title-itm">
                                    <span
                                      className={
                                        "assigned-title-blk  name-icon cat-dark-red " +
                                        ColorRender("Student")
                                      }
                                    >
                                      <img
                                        src={`http://myaie.ac/Library/Profiles/15461/15461-1683366323646.jpg`}
                                        alt="AIE"
                                      />              
                                      <span className={`profile-box-2-status Online`}>
                                        <i className="fas fa-circle"></i>
                                      </span>
                                      <Hover
                                        firstName={"Kuldeep"}
                                        lastName={"Mahawar"}
                                        photo={`http://myaie.ac/Library/Profiles/15461/15461-1683366323646.jpg`}
                                        email={"kuldeep@gamil.com"}
                                        mobile={"9999988888"}
                                        status={"Online"}
                                        activity_status={"Active"}
                                        right={true}
                                        showNumber={true} 
                                        number={"3454534543"}
                                      />
                                    </span>
                                    {/* <PermissionsGate scopes={["sgiview"]} RenderError={() => <p>{row.first_name + " " + row.last_name}</p>}> */}
                                    <Link
                                      className="as-text-blue curser"
                                      to={"/test"}
                                      title={"Kuldeep" + " " + "Mahawar"}
                                    >
                                      <span className="textLimit100">{"Kuldeep" + " " + "Mahawar"}</span>
                                    </Link>
                                    {/* </PermissionsGate> */}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span class="feature-name" title="nicos3694@gmail.com">
                                  <span class="textLimit100">nicos3694@gmail.com</span>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="assigned-title-block-new">
                                  <div className="assigned-title-itm">
                                    <span
                                      className={
                                        "assigned-title-blk  name-icon cat-dark-red " +
                                        ColorRender("Student")
                                      }
                                    >
                                      <img
                                        src={`http://myaie.ac/Library/Profiles/15461/15461-1683366323646.jpg`}
                                        alt="AIE"
                                      />              
                                      <span className={`profile-box-2-status Online`}>
                                        <i className="fas fa-circle"></i>
                                      </span>
                                      <Hover
                                        firstName={"Kuldeep"}
                                        lastName={"Mahawar"}
                                        photo={`http://myaie.ac/Library/Profiles/15461/15461-1683366323646.jpg`}
                                        email={"kuldeep@gamil.com"}
                                        mobile={"9999988888"}
                                        status={"Online"}
                                        activity_status={"Active"}
                                        right={true}
                                        showNumber={true} 
                                        number={"3454534543"}
                                      />
                                    </span>
                                    {/* <PermissionsGate scopes={["sgiview"]} RenderError={() => <p>{row.first_name + " " + row.last_name}</p>}> */}
                                    <Link
                                      className="as-text-blue curser"
                                      to={"/test"}
                                      title={"Kuldeep" + " " + "Mahawar"}
                                    >
                                      <span className="textLimit100">{"Kuldeep" + " " + "Mahawar"}</span>
                                    </Link>
                                    {/* </PermissionsGate> */}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span class="feature-name" title="nicos3694@gmail.com">
                                  <span class="textLimit100">nicos3694@gmail.com</span>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="table-book-mid">
                        <div className="grade-acc">
                            <div className={"grade-acc-in " + (state1 ? "active" : "")}>
                              <table className="table-book-main table-book-main-first">
                                <thead>
                                  <tr>
                                    <th>
                                      <button className="ic-table btn-grade-acc" onClick={()=>{handleState1()}}>
                                        {state1 ? <i class="fas fa-minus-circle"></i>
                                         :<i class="fas fa-plus-circle"></i>}
                                      </button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <table className="table-book-main table-book-main-contetn">
                                <thead>
                                  <tr>
                                    <th>
                                      <a href="#" className="head-link-og">Demo - Pro Tools audio test</a>
                                      <div class="tb-book-tit">Demo Assessment01<a href="#" class=" grade-info-red" data-toggle="tooltip" title="asdasda"><i class="fal fa-exclamation-circle"></i></a></div>
                                    </th>
                                    <th>
                                      Demo Assessment 02
                                    </th>
                                    <th>
                                      Demo Assessment 03
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="marks-number-info">
                                        <div className="marks-input-text">
                                          <input className="input-red"></input>/125
                                        </div>
                                        <div className="grade-info-ic-box">
                                          <div className="grade-info-ic grade-info-red"><i class="fal fa-calendar-alt"></i></div>
                                          <div className="grade-info-ic grade-info-orange"><i class="fal fa-edit"></i></div>
                                          <div className="grade-info-ic grade-info-purple"><i class="fal fa-file"></i></div>
                                          <div className="grade-info-ic grade-info-blue"><i class="fal fa-calendar"></i></div>
                                        </div>
                                      </div>
                                      <div class="as-widget-outer text-center ml-3">
                                        <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                        <div class="as-widget-text grade-info-red mt-2"><i class="fal fa-user-times"></i></div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="marks-number-info">
                                        <div className="select-dropdown-tb">
                                          <select>
                                            <option>F</option>
                                            <option>p</option>
                                          </select>
                                        </div>
                                        <div className="grade-info-ic-box">
                                          <div className="grade-info-ic grade-info-purple"><i class="fal fa-file"></i></div>
                                        </div>
                                      </div>
                                      <div class="as-widget-outer text-center ml-3">
                                        <span title="Active" class="as-green-bg as-widget">PASS</span>
                                        <div class="as-widget-text grade-info-red mt-2"><i class="fal fa-user-times"></i></div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="marks-number-info">
                                        <div className="marks-input-text marks-input-big">
                                          <input></input>/125
                                        </div>
                                        <div className="grade-info-ic-box">
                                          <div className="grade-info-ic grade-info-purple"><i class="fal fa-file"></i></div>
                                        </div>
                                      </div>
                                      <div class="as-widget-outer text-center ml-3">
                                        <span title="Active" class="as-green-bg as-widget">PASS</span>
                                        <div class="as-widget-text grade-info-red mt-2"><i class="fal fa-user-times"></i></div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="marks-number-info">
                                        <div className="marks-input-text">
                                          <input className="input-red"></input>/125
                                        </div>
                                        <div className="grade-info-ic-box">
                                          <div className="grade-info-ic grade-info-red"><i class="fal fa-calendar-alt"></i></div>
                                          <div className="grade-info-ic grade-info-orange"><i class="fal fa-edit"></i></div>
                                          <div className="grade-info-ic grade-info-purple"><i class="fal fa-file"></i></div>
                                          <div className="grade-info-ic grade-info-blue"><i class="fal fa-calendar"></i></div>
                                        </div>
                                      </div>
                                      <div class="as-widget-outer text-center ml-3">
                                        <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                        <div class="as-widget-text grade-info-red mt-2"><i class="fal fa-user-times"></i></div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="marks-number-info">
                                        <div className="select-dropdown-tb">
                                          <select>
                                            <option>F</option>
                                            <option>p</option>
                                          </select>
                                        </div>
                                        <div className="grade-info-ic-box">
                                          <div className="grade-info-ic grade-info-purple"><i class="fal fa-file"></i></div>
                                        </div>
                                      </div>
                                      <div class="as-widget-outer text-center ml-3">
                                        <span title="Active" class="as-green-bg as-widget">PASS</span>
                                        <div class="as-widget-text grade-info-red mt-2"><i class="fal fa-user-times"></i></div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="marks-number-info">
                                        <div className="marks-input-text marks-input-big">
                                          <input></input>/125
                                        </div>
                                        <div className="grade-info-ic-box">
                                          <div className="grade-info-ic grade-info-purple"><i class="fal fa-file"></i></div>
                                        </div>
                                      </div>
                                      <div class="as-widget-outer text-center ml-3">
                                        <span title="Active" class="as-green-bg as-widget">PASS</span>
                                        <div class="as-widget-text grade-info-red mt-2"><i class="fal fa-user-times"></i></div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="marks-number-info">
                                        <div className="marks-input-text">
                                          <input className="input-red"></input>/125
                                        </div>
                                        <div className="grade-info-ic-box">
                                          <div className="grade-info-ic grade-info-red"><i class="fal fa-calendar-alt"></i></div>
                                          <div className="grade-info-ic grade-info-orange"><i class="fal fa-edit"></i></div>
                                          <div className="grade-info-ic grade-info-purple"><i class="fal fa-file"></i></div>
                                          <div className="grade-info-ic grade-info-blue"><i class="fal fa-calendar"></i></div>
                                        </div>
                                      </div>
                                      <div class="as-widget-outer text-center ml-3">
                                        <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                        <div class="as-widget-text grade-info-red mt-2"><i class="fal fa-user-times"></i></div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div class="as-widget-outer text-center ml-3">
                                        <div class="as-widget-text grade-info-green mt-2"><i class="fal fa-user-plus"></i></div>
                                        <div class="as-widget-text grade-info-green mt-2">EXEMPTED <a href="#" data-toggle="tooltip" title="asdasda"><i class="fal fa-exclamation-circle"></i></a></div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="marks-number-info">
                                        <div className="marks-input-text marks-input-big">
                                          <input></input>/125
                                        </div>
                                        <div className="grade-info-ic-box">
                                          <div className="grade-info-ic grade-info-purple"><i class="fal fa-file"></i></div>
                                        </div>
                                      </div>
                                      <div class="as-widget-outer text-center ml-3">
                                        <span title="Active" class="as-green-bg as-widget">PASS</span>
                                        <div class="as-widget-text grade-info-red mt-2"><i class="fal fa-user-times"></i></div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <table className="table-book-main table-book-main-final">
                                <thead>
                                  <tr>
                                    <th>
                                      <a href="#" className="head-link">MLW Demo</a>
                                      <div class="tb-book-tit">
                                      Final Grade<a href="#" class=" grade-info-red" data-toggle="tooltip" title="asdasda"><i class="fal fa-exclamation-circle"></i></a>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className={"grade-acc-in " + (state2 ? "active" : "")}>
                              <table className="table-book-main table-book-main-first">
                                <thead>
                                  <tr>
                                    <th>
                                      <button className="ic-table btn-grade-acc" onClick={()=>{handleState2()}}>
                                        {state2 ? <i class="fas fa-minus-circle"></i>
                                         :<i class="fas fa-plus-circle"></i>}
                                      </button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <table className="table-book-main table-book-main-contetn d-none">
                                <thead>
                                  <tr>
                                    <th>
                                      <a href="#" className="head-link-og">Demo - Pro Tools audio test</a>
                                      <div class="tb-book-tit">Programme Grade<a href="#"  class=" grade-info-red" data-toggle="tooltip" title="asdasda"><i class="fal fa-exclamation-circle"></i></a></div>
                                    </th>
                                    <th>
                                      Demo Assessment 02
                                    </th>
                                    <th>
                                      Demo Assessment 03
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <table className="table-book-main table-book-main-final">
                                <thead>
                                  <tr>
                                    <th>
                                      <a href="#" className="head-link">MLW Demo</a>
                                      <div class="tb-book-tit">
                                      Final Grade<a href="#" class=" grade-info-red" data-toggle="tooltip" title="asdasda"><i class="fal fa-exclamation-circle"></i></a>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className={"grade-acc-in " + (state3 ? "active" : "")}>
                              <table className="table-book-main table-book-main-first">
                                <thead>
                                  <tr>
                                    <th>
                                      <button className="ic-table btn-grade-acc" onClick={()=>{handleState3()}}>
                                        {state3 ? <i class="fas fa-minus-circle"></i>
                                         :<i class="fas fa-plus-circle"></i>}
                                      </button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <table className="table-book-main table-book-main-contetn d-none">
                                <thead>
                                  <tr>
                                    <th>
                                      <a href="#" className="head-link-og">Demo - Pro Tools audio test</a>
                                      <div class="tb-book-tit">Programme Grade<a href="#" class=" grade-info-red" data-toggle="tooltip" title="asdasda"><i class="fal fa-exclamation-circle"></i></a></div>
                                    </th>
                                    <th>
                                      Demo Assessment 02
                                    </th>
                                    <th>
                                      Demo Assessment 03
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <table className="table-book-main table-book-main-final">
                                <thead>
                                  <tr>
                                    <th>
                                      <a href="#" className="head-link">MLW Demo</a>
                                      <div class="tb-book-tit">
                                      Final Grade<a href="#" class=" grade-info-red" data-toggle="tooltip" title="asdasda"><i class="fal fa-exclamation-circle"></i></a>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className={"grade-acc-in " + (state4 ? "active" : "")}>
                              <table className="table-book-main table-book-main-first">
                                <thead>
                                  <tr>
                                    <th>
                                      <button className="ic-table btn-grade-acc" onClick={()=>{handleState4()}}>
                                        {state4 ? <i class="fas fa-minus-circle"></i>
                                         :<i class="fas fa-plus-circle"></i>}
                                      </button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <table className="table-book-main table-book-main-contetn d-none">
                                <thead>
                                  <tr>
                                    <th>
                                      <a href="#" className="head-link-og">Demo - Pro Tools audio test</a>
                                      <div class="tb-book-tit">Programme Grade<a href="#" class=" grade-info-red" data-toggle="tooltip" title="asdasda"><i class="fal fa-exclamation-circle"></i></a></div>
                                    </th>
                                    <th>
                                      Demo Assessment 02
                                    </th>
                                    <th>
                                      Demo Assessment 03
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">PASS</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">PASS</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">PASS</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <table className="table-book-main table-book-main-final">
                                <thead>
                                  <tr>
                                    <th>
                                      <a href="#" className="head-link">MLW Demo</a>
                                      <div class="tb-book-tit">
                                      Final Grade<a href="#" class=" grade-info-red" data-toggle="tooltip" title="asdasda"><i class="fal fa-exclamation-circle"></i></a>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className={"grade-acc-in " + (state5 ? "active" : "")}>
                              <table className="table-book-main table-book-main-first">
                                <thead>
                                  <tr>
                                    <th>
                                      <button className="ic-table btn-grade-acc" onClick={()=>{handleState5()}}>
                                        {state5 ? <i class="fas fa-minus-circle"></i>
                                         :<i class="fas fa-plus-circle"></i>}
                                      </button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="ic-table">
                                      <i class="fal fa-user-times"></i>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <table className="table-book-main table-book-main-contetn d-none">
                                <thead>
                                  <tr>
                                    <th>
                                      <a href="#" className="head-link-og">Demo - Pro Tools audio test</a>
                                      <div class="tb-book-tit">Programme Grade<a href="#" class=" grade-info-red" data-toggle="tooltip" title="asdasda"><i class="fal fa-exclamation-circle"></i></a></div>
                                    </th>
                                    <th>
                                      Demo Assessment 02
                                    </th>
                                    <th>
                                      Demo Assessment 03
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-green-bg as-widget">Active</span>
                                      <div class="as-widget-text">100%</div>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <table className="table-book-main table-book-main-final">
                                <thead>
                                  <tr>
                                    <th>
                                      <a href="#" className="head-link">MLW Demo</a>
                                      <div class="tb-book-tit">
                                      Final Grade<a href="#" class=" grade-info-red" data-toggle="tooltip" title="asdasda"><i class="fal fa-exclamation-circle"></i></a>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div class="as-widget-outer text-center">
                                      <span title="Active" class="as-red-bg as-widget">FAIL</span>
                                      <div class="as-widget-text">20%</div>
                                    </div>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                        </div>
                      </div>
                      <div className="table-book-right">
                        <table className="table-book-main text-center">
                          <thead>
                            <tr>
                              <th>
                                <div className="tb-book-tit">
                                  Programme Grade 
                                  <a href="#" class=" grade-info-red" data-toggle="tooltip" title="asdasda"><i class="fal fa-exclamation-circle"></i></a>
                                  
                                </div>
                              </th>
                              <th>
                                <div className="tb-book-tit">
                                  Actions
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="as-widget-outer text-center">
                                  <span title="Active" class="as-green-bg as-widget">PASS</span>
                                  <div className="as-widget-text">100%</div>
                                </div>
                              </td>
                              <td>
                                <div class="assessment-08 btn-dropdown-grp">
                                  <div class="as-buttons d-flex">
                                    <a class="btn btn-primary rounded-circle" title="Open" href="/studentAdministration/students/open/15444/general"><i class="fal fa-folder-open"></i></a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="as-widget-outer text-center">
                                  <span title="Active" class="as-green-bg as-widget">PASS</span>
                                  <div className="as-widget-text">100%</div>
                                </div>
                              </td>
                              <td>
                                <div class="assessment-08 btn-dropdown-grp">
                                  <div class="as-buttons d-flex">
                                    <a class="btn btn-primary rounded-circle" title="Open" href="/studentAdministration/students/open/15444/general"><i class="fal fa-folder-open"></i></a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div className="as-widget-outer text-center">
                                  <span title="Active" class="as-green-bg as-widget">PASS</span>
                                  <div className="as-widget-text">100%</div>
                                </div>
                              </td>
                              <td>
                                <div class="assessment-08 btn-dropdown-grp">
                                  <div class="as-buttons d-flex">
                                    <a class="btn btn-primary rounded-circle" title="Open" href="/studentAdministration/students/open/15444/general"><i class="fal fa-folder-open"></i></a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    </div>
                    <nav className="pagination-nav">
                        <span>Rows per page:</span>
                        <div className="pagination-select">
                          <select>
                            <option>10</option>
                            <option>20</option>
                            <option>30</option>
                            <option>40</option>
                            <option>50</option>
                          </select>
                        </div>
                        <span className="mx-3">1-10 of 12</span>
                        <div className="pagination-btns">
                            <button type="button" disabled>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path><path fill="none" d="M24 24H0V0h24v24z"></path></svg>
                            </button>
                            <button type="button" disabled>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                            </button>
                            <button type="button">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
                            </button>
                            <button type="button">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg>
                            </button>
                        </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
      );
}
