import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import AuditTrail from "../components/Notification/AuditTrails";
import { Link, useHistory, useParams } from "react-router-dom";
import EditUser from "../components/systemadministration/usermanagement/EditUser";
import AddNewUser from "../components/systemadministration/usermanagement/AddNewUser";
import BulkTab from "../components/Notification/BulkNotification/BulkTab";
import EmailTab from "../components/Notification/EmailManagement/EmailTab";
import EmailGeneratedTab from "../components/Notification/EmailManagement/EmailGeneratedTab";
import EmailPendingTab from "../components/Notification/EmailManagement/EmailPendingTab";
import EmailStoppedTab from "../components/Notification/EmailManagement/EmailStoppedTab";
import { useSelector } from "react-redux";
import { GlobalIconNames } from "../utils/GlobalIconNames";

const Notification = () => {
  const { sideTab, tab, type } = useParams();
  const history = useHistory();
  const [name, setName] = useState("");
  const sideBarLinks = useSelector((state) => state.sideBarLinks);
  const pageName = sideBarLinks?.find(
    (item) => item?.pageName === "student_support"
  );
  const bulkNotificationsSubList = pageName?.options?.find(
    (item) => item?.subPageName === "bulk_notifications"
  );
  const subTabsList = bulkNotificationsSubList?.subTabs.find((item) =>
    item?.path?.match(`/${tab}/`)
  );


  const renderMainTitle = () => {
    const title = subTabsList?.name?.split(" ");
    return (
      <>
        {title[0]} <span>{title[1]}</span>
      </>
    );
  };

  return tab === "open" ? (
    <EditUser />
  ) : tab === "new" ? (
    <AddNewUser />
  ) : (
    <>
      {" "}
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        {/* {type === "add" ? (<>Add <span>Notifications</span></>) : (<>Bulk <span>Notifications</span></>)} */}
                        {type === "add" ? (
                          <>
                            Add <span>Notifications</span>
                          </>
                        ) : tab == "notifications-list" ? (
                          <>
                            Sent <span>Notifications</span>
                          </>
                        ) : tab === "pending-notifications" ? (
                          <>
                            Pending <span>Notifications</span>
                          </>
                        ) : tab === "stopped-notifications" ? (
                          <>
                            Stopped <span>Notifications</span>
                          </>
                        ) : (
                          renderMainTitle()
                        )}
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button
                            onClick={() => history.goBack()}
                            title="Back"
                            className="btn btn-white-bordered"
                          >
                            {/* <i className="fal fa-angle-left"></i>Back */}
                            {
                              GlobalIconNames("Back", "fa-angle-left", "Back")
                                .html
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tabs-wrap">
                  {type !== "add" && (
                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                      {tab === "bulk" && (
                        <li
                          className="nav-item"
                          onClick={() =>
                            history.push(
                              `/notification/bulknotification/bulk/table`
                            )
                          }
                        >
                          <Link
                            className={`nav-link ${
                              tab === "bulk" &&
                              type !== "auditTrail" &&
                              "active"
                            }`}
                            id="pills-tab01"
                            data-toggle="pill"
                            to={`/notification/bulknotification/bulk/table`}
                            role="tab"
                            aria-controls="pills-tab011"
                            aria-selected="true"
                          >
                            {
                              GlobalIconNames(
                                subTabsList?.name,
                                subTabsList?.icon,
                                subTabsList?.name
                              ).html
                            }
                          </Link>
                        </li>
                      )} 
                       {(tab === "notifications-list" || tab === "pending-notifications" || tab === "stopped-notifications") && (
                        <>
                        <li
                          className="nav-item"
                          onClick={() =>
                            history.push(
                              `/notification/bulknotification/pending-notifications/table`
                            )
                          }
                        >
                          <Link
                            className={`nav-link ${
                              tab === "pending-notifications" &&
                              type !== "auditTrail" &&
                              "active"
                            }`}
                            id="pills-tab2"
                            data-toggle="pill"
                            to={`/notification/bulknotification/pending-notifications/table`}
                            role="tab"
                            aria-controls="pills-tab12"
                            aria-selected="true"
                          >
                            {
                              GlobalIconNames(
                               "pendingnotification"
                              ).html
                            }
                          </Link>
                        </li>
                          <li
                            className="nav-item"
                            onClick={() =>
                              history.push(
                                `/notification/bulknotification/notifications-list/table`
                              )
                            }
                          >
                            <Link
                              className={`nav-link ${
                                tab === "notifications-list" &&
                                type !== "auditTrail" &&
                                "active"
                              }`}
                              id="pills-tab1"
                              data-toggle="pill"
                              to={`/notification/bulknotification/notifications-list/table`}
                              role="tab"
                              aria-controls="pills-tab11"
                              aria-selected="true"
                            >
                              {
                                GlobalIconNames(
                                  "notificationlists"
                                ).html
                              }
                            </Link>
                          </li>
                          <li
                            className="nav-item"
                            onClick={() =>
                              history.push(
                                `/notification/bulknotification/stopped-notifications/table`
                              )
                            }
                          >
                            <Link
                              className={`nav-link ${
                                tab === "stopped-notifications" &&
                                type !== "auditTrail" &&
                                "active"
                              }`}
                              id="pills-tab3"
                              data-toggle="pill"
                              to={`/notification/bulknotification/stopped-notifications/table`}
                              role="tab"
                              aria-controls="pills-tab13"
                              aria-selected="true"
                            >
                              {
                                GlobalIconNames(
                                  "stoppednotifications"
                                ).html
                              }
                            </Link>
                          </li>
                        </>
                      )}
                      {tab == "notifications-template" && (
                        <li
                          className="nav-item"
                          onClick={() =>
                            history.push(
                              `/notification/bulknotification/notifications-template/table`
                            )
                          }
                        >
                          <Link
                            className={`nav-link ${
                              tab === "notifications-template" &&
                              type !== "auditTrail" &&
                              "active"
                            }`}
                            id="pills-tab2"
                            data-toggle="pill"
                            to={`/notification/bulknotification/notifications-template/table`}
                            role="tab"
                            aria-controls="pills-tab12"
                            aria-selected="true"
                          >
                            {
                              GlobalIconNames(
                                subTabsList?.name,
                                subTabsList?.icon,
                                subTabsList?.name
                              ).html
                            }
                          </Link>
                        </li>
                      )}
                      {type !== "add" &&
                        tab !== "notifications-list" &&
                        tab !== "pending-notifications" &&
                        tab !== "stopped-notifications" && (
                          <li
                            className="nav-item"
                            onClick={() =>
                              history.push(
                                `/notification/bulknotification/${tab}/auditTrail`
                              )
                            }
                          >
                            <Link
                              className={`nav-link ${
                                type === "auditTrail" && "active"
                              }`}
                              id="pills-tab60"
                              data-toggle="pill"
                              to={`/notification/bulknotification/${tab}/auditTrail`}
                              role="tab"
                              aria-controls="pills-tab60"
                              aria-selected="true"
                            >
                              {GlobalIconNames("audittrail").html}
                            </Link>
                          </li>
                        )}
                    </ul>
                  )}

                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab11"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      {tab === "bulk" && <BulkTab />}
                      {tab === "notifications-list" && (
                        <EmailGeneratedTab setName={setName} />
                      )}
                      {tab === "notifications-template" && (
                        <EmailTab setName={setName} />
                      )}
                      {tab == "pending-notifications" && (
                        <EmailPendingTab setName={setName} />
                      )}
                      {tab === "stopped-notifications" && (
                        <EmailStoppedTab setName={setName} />
                      )}
                      {type === "auditTrail" && <AuditTrail />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
