import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { Link, useHistory } from "react-router-dom";
import AuditTrail from "../components/systemadministration/ClassManagement/AuditTrail";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import OnlineClass from "../components/systemadministration/ClassManagement/OnlineClass";
import OnlineClassChecker from "../components/systemadministration/ClassManagement/OnlineClassChecker";
import RecordingStats from "../components/systemadministration/ClassManagement/RecordingStats";
import { GlobalIconNames } from "../utils/GlobalIconNames";

const ClassManagement = () => {

    const history = useHistory();
    const [activeTab, setActiveTab] = useState('')
    const location = useLocation();
    const { tab } = useParams();
    
    useEffect(() => {
        if (tab !== '') {
            setActiveTab(tab)
        } else {
            setActiveTab('settings')
        }
    }, [tab]);

    return (
        <>
            <Header></Header>
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar></SideBar>
                <div className="sidebar-right">
                    <div className="sub-menu-content-block">
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                Class <span>Management</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                            <div className="add-new-ticket-bx">
                                                <button
                                                onClick={() => history.goBack()}
                                                title="Back"
                                                className="btn btn-white-bordered"
                                                >
                                                <i className="fal fa-angle-left"></i>Back
                                                </button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                        <li className="nav-item" onClick={() => history.push("/administration/classManagement/onlineClasses")}>
                                            <Link className={`nav-link ${activeTab === 'onlineClasses' ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="pills-tab11" aria-selected="true">
                                                <i className="fal fa-cog"></i>
                                                Class Settings
                                            </Link>
                                        </li>
                                        <li className="nav-item" onClick={() => history.push("/administration/classManagement/onlineClassChecker")}>
                                            <Link className={`nav-link ${activeTab === 'onlineClassChecker' ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="pills-tab11" aria-selected="true">
                                                <i className="fal fa-users"></i>
                                                Recording Checker
                                            </Link>
                                        </li>
                                        <li className="nav-item" onClick={() => history.push("/administration/classManagement/recordingstats")}>
                                            <Link className={`nav-link ${activeTab === 'recordingstats' ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="pills-tab11" aria-selected="true">
                                                <i className="fal fa-chart-bar"></i> Recording Stats
                                            </Link>
                                        </li>
                                        <li className="nav-item" onClick={() => history.push("/administration/classManagement/auditTrail")}>
                                            <Link className={`nav-link ${activeTab === 'auditTrail' ? 'active' : ''}`} id="pills-tab2" data-toggle="pill" role="tab" aria-controls="pills-tab12" aria-selected="true">
                                                {GlobalIconNames("audittrail").html} 
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" role="tabpanel" aria-labelledby="pills-tab1">
                                            {location.pathname.includes("/administration/classManagement/onlineClasses") && <OnlineClass />}
                                        </div>
                                        <div className="tab-pane fade active show" role="tabpanel" aria-labelledby="pills-tab1">
                                            {location.pathname.includes("/administration/classManagement/onlineClassChecker") && <OnlineClassChecker />}
                                        </div>
                                        <div className="tab-pane fade active show" role="tabpanel" aria-labelledby="pills-tab1">
                                            {location.pathname.includes("/administration/classManagement/recordingstats") && <RecordingStats />}
                                        </div>
                                        <div className="tab-pane fade  active show " role="tabpanel" aria-labelledby="pills-tab2">
                                            {location.pathname.includes("/administration/classManagement/auditTrail") && <AuditTrail />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClassManagement;
