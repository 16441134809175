import React, { useMemo, useState } from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import * as Yup from 'yup';

import { getResourceManagement } from '../../../../services/StudentService';
import { useEffect } from 'react';
import Select from 'react-select';
import { Form, FormikProvider, useFormik } from 'formik';

export default function VideoLibrary({ show, handleClose, handleSelect,selectedItem }) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);

    const getData = () => {
        const sendData = {
            exportStatus: true,
            viaFormat: ["vimeo"]
        }
        getResourceManagement(sendData).then((res) => {
            if (res.status === 200) {
                const formatResp = res.data.result.map((item) => {
                    return { label: item?.title, urlId:item.url, value: item.type==='vimeo' ? `https://player.vimeo.com/video/${item.url}` : item?.url.includes("http") ? item?.url : item?.url + (item?.video_id ? item?.video_id : '') }
                })
                setData(formatResp);
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            item_value: selectedItem.value,
            item_label: selectedItem.label,
            item_url: selectedItem.urlId,
            item: selectedItem
        },
        validationSchema: Yup.object().shape({
            item_value: Yup.string().required("Please select an resource.")
        }),
        onSubmit: (values) => {
            handleSelect(values);
        }
    });

    const { values, errors, touched, setValues, handleSubmit } = formik;

    const handleChangeItem = (item) => {
        setValues({
            ...values,
            item: item,
            item_label: item.label,
            item_value: item.value,
            item_url: item.urlId
        })
    }
    return (
        <Modal className='modal zindexadd content-assembly fade' size='xl' show={show} centered onHide={handleClose}>
            <Modal.Header className='modal-header-custom' closeButton>
                    <h5 class="modal-title">{loading ? <i className="fal fa-spin fa-cog"></i> :<i class="fal fa-plus"></i>} Video Library </h5>
            </Modal.Header>
            <ModalBody>                
            <div className="t p-30px">
                    <FormikProvider value={formik}>
                        <Form autocomplete="off" noValidate onSubmit={handleSubmit}>
                            <div className="form-group-blk mb-3">
                                <Select
                                    className={
                                        "form-control custom-select-box" +
                                        (errors.item_value &&
                                            touched.item_value
                                            ? " is-invalid"
                                            : "")
                                    }
                                    name="item_value"
                                    id="upload_item"
                                    title="Select Video"
                                    placeholder={"Select Video"}
                                    options={data}
                                    onChange={(value) => {
                                        handleChangeItem(value)
                                    }}
                                    value={values.item}
                                />
                            </div>
                            <div className="form-group form-group-save-cancel mt-4">
                                <button className="btn btn-save btn-success" type="submit" title="Save">
                                    <i className="fal fa-save"></i>
                                    Save
                                </button>

                                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => handleClose()}>
                                    <i className="fal fa-times"></i>Cancel
                                </button>
                            </div>
                        </Form>
                    </FormikProvider>
                </div>
            </ModalBody>
        </Modal>
    )
}
