import React, { useState } from "react";
import AssessmentTables from "./AssessmentBlocks/AssessmentTables";
import AssessmentForm from "./AssessmentBlocks/AssessmentForm";
import { useLocation, useParams } from "react-router-dom";
import AssessmentTabs from "./AssessmentBlocks/AssessmentTabs";

const Assessments = (props) => {
  const { type } = useParams();

  return (
    <>
      {type === "open" ? (
        <AssessmentTabs assName={props.assName} lmType={props.lmType} gradingStandard={props?.gradingStandard}/>
      ) : (
        <>
          {type === "add" && <AssessmentForm lmType={props.lmType} gradingStandard={props?.gradingStandard}/>}
          {type === "duplicate" && <AssessmentForm lmType={props.lmType} gradingStandard={props?.gradingStandard}/>}
          {type === "show" && <>
            <AssessmentTables />
            
          </>}
        </>
      )}
    </>
  );
};

export default Assessments;
