import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import Swal from "sweetalert2";
import Hover from "../../common/Hover";
import { InitialRender } from "../../common/Helper";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import StatusRender, { ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import { RenderLearningMethod } from "../../../utils/CommonGroupingItem";
import { GetAppealDropdownCancelToken, GetAppealslist, GetAppealslistCancelToken } from "../../../services/DashBoardServices";
import DataTableComponent from "../../common/DataTableComponent";
import axios from "axios";

const AppealTable = () => {
    const history = useHistory();
    const { type } = useParams();
    const [urlType, setUrlType] = useState([type.charAt(0).toUpperCase() + type.slice(1)])
    const [search, setSearch] = useState("");
    const [appealList, setAppealList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [intake, setIntake] = useState({ arr: [], checkObj: {} });
    const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
    const [programme, setProgramme] = useState({ arr: [], checkObj: [] });
    const [appealDropdown, setAppealDropdown] = useState({ programme: [], intake: [], learning: [] })
    const [tableState, setTableState] = useState({
        page: 1,
        perPage: 10,
        sortOrder: "DESC",
        sortKey: "enddate"
    })

    useEffect(() => {
        setUrlType([type.charAt(0).toUpperCase() + type.slice(1)])
    }, [type])
    useEffect(() => {
        const storedFilters = JSON.parse(localStorage.getItem("filterStateAppealsDashboard"));
        if (storedFilters) {
          setIntake(storedFilters.intake);
          setLearningMethod(storedFilters.learningMethod);
          setProgramme(storedFilters.programme);
        }
      }, []);
      useEffect(() => {
        const filterState = {
          intake,
          learningMethod,
          programme,
        };
        localStorage.setItem("filterStateAppealsDashboard", JSON.stringify(filterState));
      }, [  intake, learningMethod, programme]);
    useEffect(() => {
        const cancelTokenSources = [];

        const getData = async () => {
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);
            let data = {
                viaStatus: urlType
            }
            try {
                let res = await GetAppealDropdownCancelToken(data, source.token)
                if (res.status === 200) {
                    console.log(res);                
                    setAppealDropdown({...res.data, programme : res.data.programme, intake : res.data.yearsAndIntaks, learning : res.data.intakeType});
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.log(error);
                }
            }
        }
        getData()

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [type])


    useEffect(() => {
        const cancelTokenSources = [];
        const getData = async () => {
            setLoading(true)
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            try {
                let res = await GetAppealslistCancelToken({
                    page: tableState.page,
                    limit: tableState.perPage,
                    viaQualification: programme.arr,
                    intake: intake.arr,
                    vialearnMethod: learningMethod.arr,
                    viaStatus: urlType,
                    key: tableState.sortKey,
                    sort: tableState.sortOrder,
                    exportStaus: false,
                    viaSearch: search,
                }, source.token);
                if (res.status === 200) {
                    setAppealList(res.data.result);
                    setTotalRows(res.data.Pagination.total);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }

        getData()

        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [
        tableState,
        programme,
        intake,
        type,
        search,
        learningMethod
    ]);

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "assignmentName",
            sortable: true,
            sortField: "name",
            cell: (row) => (
                <span
                    className="overflow-ellipsis2 fst_line2"
                    title={row.assignmentName}
                >
                    <Link
                        className="as-text-blue curser textLimit100"
                        to={`/courseAdministration/coursesdetails/${row.intakeID}/assessments/open/assigned/submissionsAndResults/${row.assignmentID}/appealList/${row.student_id}`}
                    >
                        {row.assignmentName ? row.assignmentName : "-"}
                    </Link>
                </span>
            ),
        },
        {
            name: "Full Name",
            selector: "student_name",
            sortable: true,

            cell: (row) => (
                // <span className="textLimit76" title={row.student_name}>
                //   {row.student_name ? row.student_name : "-"}
                // </span>
                <>
                    {row?.student_name ? (
                        <div className="assigned-title-block-new">
                            <div className="assigned-title-itm">
                                <span
                                    className={
                                        "assigned-title-blk  name-icon cat-dark-red " +
                                        ColorRender("lecturer")
                                    }
                                >
                                    {row?.student_profile ? (
                                        <img
                                            src={`${IMAGE_URL}/${row?.student_profile.replaceAll(
                                                "/home/myaie/public_html/",
                                                ""
                                            )}`}
                                            alt="AIE"
                                        />
                                    ) : (
                                        InitialRender([
                                            row?.student_name
                                        ])
                                    )}
                                    <span className={`profile-box-2-status ${row?.student_status ? row.student_status.toLowerCase() == "online" ? "Online" : row.student_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                        <i className="fas fa-circle"></i>
                                    </span>
                                    <Hover
                                        firstName={row?.student_name}
                                        photo={row?.student_profile}
                                        email={row?.student_email}
                                        mobile={row?.student_mobile}
                                        right={true}
                                        role={"lecturer"}
                                        status={row?.student_status}
                                        activity_status={row?.student_activity_status}
                                    />
                                </span>
                                <p
                                    className="as-text-blue curser textLimit76"
                                    title={row?.student_name ? row["student_name"] : "-"}
                                    onClick={() => history.push(`/studentAdministration/students/open/${row?.student_id}/general`)}
                                >
                                    {row?.student_name ? row["student_name"] : "-"}
                                </p>
                            </div>
                        </div>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Code",
            selector: "assignmentCode",
            sortable: true,
            sortField: "code",
            cell: (row) => (
                <p className="textLimit76" title={row.assignmentCode}>
                    {row.assignmentCode ? row.assignmentCode : "-"}
                </p>
            ),
        },
        (urlType.includes("Approved") || urlType.includes("Declined")) && {
            name: "Status",
            selector: "ass_submitted_status",
            sortable: true,
            sortField: "ass_submitted_status",
            cell: (row) =>
                row.ass_submitted_status ? (
                    <StatusRender status={"assessment" + row.ass_submitted_status} />
                ) : (
                    "-"
                ),
        },
        {
            name: "Learning Method",
            selector: "study_type",
            sortable: true,
            cell: (row) => (
                <p className="learningMethodText">
                    {row && row.learnType ? RenderLearningMethod(row.learnType).html : "-"}
                </p>
            ),
        },
        {
            name: "Due Date",
            selector: "mark_due_date",
            sortable: true,
            sortField: "markduedate",
            cell: (row) => (
                <>
                    {row.mark_due_date ? (
                        <>
                            <p className="right-space">
                                {moment.unix(row.mark_due_date).format(TABLE_DATE_FORMAT)}
                            </p>
                            <p className="right-space">
                                {moment.unix(row.mark_due_date).format(TABLE_TIME_FORMAT)}
                            </p>
                        </>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        (urlType.includes("Approved") || urlType.includes("Declined")) && {
            name: "Appeal Extended Date",
            selector: "appealExtendedDate",
            sortable: true,

            cell: (row) => (
                <>
                    {row.appealExtendedDate ? (
                        <>
                            <p className="right-space">
                                {moment.unix(row.appealExtendedDate).format(TABLE_DATE_FORMAT)}
                            </p>
                            <p className="right-space">
                                {moment.unix(row.appealExtendedDate).format(TABLE_TIME_FORMAT)}
                            </p>
                        </>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        (urlType.includes("Approved") || urlType.includes("Declined")) && {
            name: "Assessment Uploaded",
            selector: "AssessmentUploaded",
            sortable: true,
            cell: (row) =>
                row && row.AssessmentUploaded ? row.AssessmentUploaded : "-",
        },
        (urlType.includes("Approved") || urlType.includes("Declined")) && {
            name: "Appeal Date",
            selector: "appealDate",
            sortable: true,

            cell: (row) => (
                <>
                    {row.appealDate ? (
                        <>
                            <p className="right-space">
                                {moment.unix(row.appealDate).format(TABLE_DATE_FORMAT)}
                            </p>
                            <p className="right-space">
                                {moment.unix(row.appealDate).format(TABLE_TIME_FORMAT)}
                            </p>
                        </>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Course",
            selector: "courseName",
            sortable: true,
            sortField: "course",
            cell: (row) => (

                <Link
                    className="as-text-blue curser feature-name"
                    to={`/courseAdministration/coursesdetails/${row.intakeID}/dashboard/show`}
                >
                    <span className="textLimit100">{row.courseName ? row.courseName : "-"}</span>
                </Link>

            ),
        },
        {
            name: "Programme",
            selector: "qualification",
            sortable: true,
            sortField: "qualification",
            cell: (row, index) => (

                <Link
                    className="as-text-blue curser feature-name"
                    to={`/courseAdministration/Programmes/programme/open/${row.qualificationID}/details`}
                >
                    <span className="textLimit100">{row.qualification ? row.qualification : "-"}</span>
                </Link>
            ),
        },
        {
            name: "Intake",
            selector: "intakeYear",
            sortable: true,
            sortField: "intake",
            cell: (row) => (
                <p>
                    {row.intakeYear || row.intakeNumber ? (
                        <>
                            {" "}
                            {row.intakeYear ? row.intakeYear : ""}-{row.intakeNumber ? row["intakeNumber"] : ""}
                        </>
                    ) : (
                        "-"
                    )}
                </p>
            ),
        },
        {
            name: "Marker",
            selector: "MARKER",
            sortable: true,
            sortField: "marker",
            cell: (row) => (
                <>
                    {row.MARKER ? (
                        <div className="assigned-title-block-new">
                            <div className="assigned-title-itm">
                                <span
                                    className={
                                        "assigned-title-blk  name-icon cat-dark-red " +
                                        ColorRender("lecturer")
                                    }
                                >
                                    {row.markerProfile ? (
                                        <img
                                            src={`${IMAGE_URL}/${row.markerProfile.replaceAll(
                                                "/home/myaie/public_html/",
                                                ""
                                            )}`}
                                            alt="AIE"
                                        />
                                    ) : (
                                        InitialRender([
                                            row.MARKER.split(" ")[0],
                                            row.MARKER.replace(/ +/g, " ").split(" ")[1],
                                        ])
                                    )}
                                    <span className={`profile-box-2-status ${row.marker_activity_status ? row.marker_activity_status.toLowerCase() == "online" ? "Online" : row.marker_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                        <i className="fas fa-circle"></i>
                                    </span>
                                    <Hover
                                        firstName={row.MARKER.split(" ")[0]}
                                        lastName={row.MARKER.replace(/ +/g, " ").split(" ")[1]}
                                        photo={row.markerProfile}
                                        email={row.markerEmail}
                                        mobile={row.markerMobile}
                                        right={false}
                                        role={"lecturer"}
                                        status={row.marker_status}
                                        activity_status={row.marker_activity_status}
                                    />
                                </span>
                                <p
                                    className="as-text-blue curser textLimit76"
                                    title={row.MARKER ? row["MARKER"] : "-"}
                                    onClick={() =>
                                        history.push(
                                            `/systemAdministration/userManagement/open/${row.MARKERid}/aboutYou`
                                        )
                                    }
                                >
                                    {row.MARKER ? row["MARKER"] : "-"}
                                </p>
                            </div>
                        </div>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Actions",
            // selector: "Action",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons d-flex">
                        <Link
                            className="btn btn-primary rounded-circle"
                            to={`/courseAdministration/coursesdetails/${row.intakeID}/assessments/open/assigned/submissionsAndResults/${row.assignmentID}/appealList/${row.student_id}`}
                            title="Open"
                        >
                            <i className="fal fa-folder-open"></i>
                        </Link>
                    </div>
                </div>
            ),
        },
    ], []);

    const exportData = (fileType, fileName) => {
        let data;

        const header = [
            "Name",
            "Full Name",
            "Code",
            // "End",
            "Status",
            "Learning Method",
            "Due Date",
            "Appeal Extended Date",
            "Assessment Uploaded",
            "Appeal Date",
            "Course",
            "Programme",
            "Intake",
            "Marker",
        ];

        if (!urlType.includes("Approved") && !urlType.includes("Declined")) {
            header.splice(header.indexOf("Full Name"), 1);
        }
        if (!urlType.includes("Approved") && !urlType.includes("Declined")) {
            header.splice(header.indexOf("Status"), 1);
        }
        if (!urlType.includes("Approved") && !urlType.includes("Declined")) {
            header.splice(header.indexOf("Appeal Extended Date"), 1);
        }
        if (!urlType.includes("Approved") && !urlType.includes("Declined")) {
            header.splice(header.indexOf("Assessment Uploaded"), 1);
        }

        if (!urlType.includes("Approved") && !urlType.includes("Declined")) {
            header.splice(header.indexOf("Appeal Date"), 1);
        }

        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        GetAppealslist({
            page: tableState.page,
            limit: tableState.perPage,
            viaQualification: programme.arr,
            intake: intake.arr,
            vialearnMethod: learningMethod.arr,
            viaStatus: urlType,
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            exportStaus: "true",
            viaSearch: search,
        }).then((res) => {
            data = res.data.result;
            data = data.map((row) => ({
                "Name": row.assignmentName,
                "Full Name": row.student_name ? row.student_name : "-",
                "Code": row.assignmentCode,
                // "End": moment
                //   .unix(row.assignmentDateClose)
                //   .format(TABLE_DATE_TIME_FORMAT),
                Status: row.ass_submitted_status ? row.ass_submitted_status : "-",
                "Learning Method": row.learnType ? RenderLearningMethod(row.learnType).text : "-",
                "Due Date": moment
                    .unix(row.mark_due_date)
                    .format(TABLE_DATE_TIME_FORMAT),
                "Appeal Extended Date": row.appealExtendedDate
                    ? moment.unix(row.appealExtendedDate).format(TABLE_DATE_TIME_FORMAT)
                    : "-",
                "Assessment Uploaded": row.AssessmentUploaded
                    ? row.AssessmentUploaded
                    : "-",
                "Appeal Date": row.appealDate
                    ? moment.unix(row.appealDate).format(TABLE_DATE_TIME_FORMAT)
                    : "-",
                "Course": row.courseName,
                Programme: row.qualification,
                Intake: `${row.intakeYear}-${row.intakeNumber}`,
                Marker: row.MARKER,
            }));

            if (fileType === "csv") {
                const csvString = Papa.unparse({ fields: header, data });
                const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

                const blobURL = window.URL.createObjectURL(blob);

                const anchor = document.createElement("a");
                anchor.download = fileName;
                anchor.href = blobURL;
                anchor.dataset.downloadurl = [
                    "text/csv",
                    anchor.download,
                    anchor.href,
                ].join(":");
                anchor.click();

                setTimeout(() => {
                    URL.revokeObjectURL(blobURL);
                }, 1000);
                Swal.close();
            } else if (fileType === "xlsx") {
                const compatibleData = data.map((row) => {
                    const obj = {};
                    header.forEach((col, index) => {
                        obj[col] = row[col];
                    });
                    return obj;
                });

                let wb = XLSX.utils.book_new();
                let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                    header,
                });
                XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                XLSX.writeFile(wb, `${fileName}.xlsx`);
                Swal.close();
                return false;
            }
            if (fileType === "pdf") {
                const compatibleData = data.map((row) => {
                    if (urlType.includes("Pending")) {
                        return [
                            row["Name"],
                            row["Code"],
                            // row["End"],
                            row["Learning Method"],
                            row["Due Date"],
                            row["Course"],
                            row["Programme"],
                            row["Intake"],
                            row["Marker"],
                        ];
                    }
                    if (urlType.includes("Approved")) {
                        return [
                            row["Name"],
                            row["Full Name"],
                            row["Code"],
                            // row["End"],
                            row["Status"],
                            row["Learning Method"],
                            row["Due Date"],
                            row["Appeal Extended Date"],
                            row["Assessment Uploaded"],
                            row["Appeal Date"],
                            row["Course"],
                            row["Programme"],
                            row["Intake"],
                            row["Marker"],
                        ];
                    }
                    if (urlType.includes("Declined")) {
                        return [
                            row["Name"],
                            row["Full Name"],
                            row["Code"],
                            // row["End"],
                            row["Status"],
                            row["Learning Method"],
                            row["Due Date"],
                            row["Appeal Extended Date"],
                            row["Assessment Uploaded"],
                            row["Appeal Date"],
                            row["Course"],
                            row["Programme"],
                            row["Intake"],
                            row["Marker"],
                        ];
                    }
                });
                const doc = new JsPDF();
                doc.autoTable({
                    head: [header],
                    body: compatibleData,
                    columnStyles: {
                        0: {
                            columnWidth: 15,
                        },
                        1: {
                            columnWidth: 10,
                        },
                        2: {
                            columnWidth: 10,
                        },
                        3: {
                            columnWidth: 10,
                        },
                        4: {
                            columnWidth: 10,
                        },
                        5: {
                            columnWidth: 15,
                        },
                        6: {
                            columnWidth: 10,
                        },
                        7: {
                            columnWidth: 15,
                        },
                        8: {
                            columnWidth: 15,
                        },
                        9: {
                            columnWidth: 15,
                        },
                        10: {
                            columnWidth: 15,
                        },
                        11: {
                            columnWidth: 15,
                        },
                        12: {
                            columnWidth: 15,
                        },
                        13: {
                            columnWidth: 15,
                        },
                    },
                    styles: {
                        minCellHeight: 10,
                        minCellWidth: 5,
                        halign: "left",
                        fontSize: 8,
                    },
                });
                doc.save(`${fileName}.pdf`);
                Swal.close();
                return false;
            }
        });
    };

    const listOfColumns = (col) => {
        let columnData = [];
        let pendingColumn = ["Name", "Code", "End Date", "Learning Method", "Due Date", "Course", "Programme", "Intake", "Marker", "Actions"];
        let ApprovedColumns = [
            "Name",
            "Full Name",
            "Code",
            "End Date",
            "Status",
            "Learning Method",
            "Due Date",
            "Appeal Extended Date",
            "Assessment Uploaded",
            "Appeal Date",
            "Course",
            "Programme",
            "Intake",
            "Marker",
            "Actions"
        ];
        let DeclinedColumns = [
            "Name",
            "Full Name",
            "Code",
            "End Date",
            "Status",
            "Learning Method",
            "Due Date",
            "Appeal Extended Date",
            "Assessment Uploaded",
            "Appeal Date",
            "Course",
            "Programme",
            "Intake",
            "Marker",
            "Actions"
        ];

        let currentColumn = col;

        if (urlType.includes("Approved")) {
            columnData = currentColumn.filter((item) => ApprovedColumns.includes(item.name));


            return columnData;
        }
        if (urlType.includes("Pending")) {
            columnData = currentColumn.filter((item) => pendingColumn.includes(item.name));
            return columnData;
        }
        if (urlType.includes("Declined")) {
            columnData = currentColumn.filter((item) => DeclinedColumns.includes(item.name));

            return columnData;
        }


    }

    return (
        <>
            <div className="tabs-wrap">
                <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li
                        className="nav-item"
                        onClick={() => {
                            history.push("/home/appeals/pending");
                        }}
                    >
                        <a
                            className={`nav-link cursor-pointer ${type === "pending" ? "active" : ""
                                }`}
                            id="pills-tab3"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-tab13"
                            aria-selected="true"
                        >
                            <i className="fa fa-exclamation"></i>Pending
                        </a>
                    </li>
                    <li
                        className="nav-item"
                        onClick={() => {
                            history.push("/home/appeals/approved");
                        }}
                    >
                        <a
                            className={`nav-link cursor-pointer ${type === "approved" ? "active" : ""
                                }`}
                            id="pills-tab2"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-tab12"
                            aria-selected="true"
                        >
                            <i className="fa fa-thumbs-up"></i> Approved
                        </a>
                    </li>

                    <li
                        className="nav-item"
                        onClick={() => {
                            history.push("/home/appeals/declined");
                        }}
                    >
                        <a
                            className={`nav-link cursor-pointer ${type === "declined" ? "active" : ""
                                }`}
                            id="pills-tab1"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-tab11"
                            aria-selected="true"
                        >
                            <i className="fa fa-thumbs-down"></i>
                            Declined
                        </a>
                    </li>
                </ul>
            </div>
            <DataTableComponent
                loading={loading}
                data={appealList}
                columns={listOfColumns(columns)}
                totalRows={totalRows}
                state={tableState}
                setState={setTableState}
                setSearch={setSearch}
                exportFunction={exportData}
                exportFileName="Appeal_List"
                isInsidePopUp
                filters={[
                    {
                        filterName: "Learning Method",
                        optionArr: appealDropdown.learning,
                        state: learningMethod,
                        setState: setLearningMethod,
                        renderLabelFunction: RenderLearningMethod,
                    },
                    {
                        filterName: "Programme",
                        optionArr: appealDropdown.programme,
                        state: programme,
                        setState: setProgramme,
                        isSearchFilter: true
                    },
                    {
                        filterName: "Intake",
                        optionArr: appealDropdown.intake,
                        state: intake,
                        setState: setIntake,
                        isOptionReversed: true,
                        isIntake: true
                    }
                ]}
            />
        </>
    );
};

export default AppealTable;
