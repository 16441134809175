const initialState = {
    permission: {}
};

const permissionReducer = (state = initialState ,action) => {

    switch(action.type) {

        case 'SET_PERMISSION' : 
        return {...state.permission,...action.payload}
        case 'UNSET_PERMISSION' :
        return state.permission = {}
        default :
        return state;
    }

}

export default permissionReducer;