import React, { useEffect, useMemo, useState } from "react";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../utils/permissionGate";
import $ from "jquery";
import {
  RenderKeyword,
  RenderQuizTopic,
} from "../../../utils/CommonStatusItems";
import {
  GradeLetterTemplateList,
  GradeLetterTemplateListCancelToken,
  GradeLetterTemplateListFilters,
  GradeLetterTemplateListFiltersCancelToken,
  deleteGradeTemplate,
} from "../../../services/GradeSettingService";
import { setHoverKeywordData, setHoverTopicData } from "../../../store/actions";
import { useDispatch } from "react-redux";
import PopupComponent from "../../common/PopupComponent";
import DataTableComponent from "../../common/DataTableComponent";
import axios from "axios";

var striptags = require("striptags");

const ListOfTemplate = ({ setSelectedTemplate, selectedTemplate, setSelectedGradeMixTemplate }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [qdata, setQData] = useState();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [topics, setTopics] = useState({ arr: [], checkObj: {} });
  const [keywordValue, setKeywordValue] = useState({ arr: [], checkObj: {} });
  const [filterData, setFilterData] = useState({
    type: [],
    keywords: [],
    topics: [],
  });
  const [deleteArr, setDeleteArr] = useState([]);
  const [updatedData, setUpdatedData] = useState(true);
  const [deleteSearch, setDeleteSearch] = useState("");
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "name",
    sortOrder: "ASC"
  })

  const { tab } = useParams();

  useEffect(() => {
    let response = hasPermission({
      scopes: ["gradingLetterTemplateView"],
      permissions: givenPermsisions,
    });
    if (!response && tab == "table") {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getData = async () => {
      setLoading(true);

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      const data = {
        page: tableState.page,
        limit: tableState.perPage,
        search: search,
        key: tableState.sortKey,
        sort: tableState.sortOrder,
        exportStatus: "false",
        viaKeywords: keywordValue.arr.length > 0 ? keywordValue.arr : [],
        viaTopics: topics.arr.length > 0 ? topics.arr : [],
      };

      try {
        const res = await GradeLetterTemplateListCancelToken(data, source.token);
        setQData(res?.data?.list_data.data);
        setTotalRows(res?.data?.list_data?.total);
        if (res.status === 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    }
    
    getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    }
  }, [
    search,
    keywordValue,
    topics,
    tableState,
    updatedData
  ]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
      const fetchData = async () => {
        try {
          const res = await GradeLetterTemplateListFiltersCancelToken(dropdownSource.token);
          setFilterData({
            ...res.data,
            keywords: res?.data?.keywordFilters,
            topics: res?.data?.topicFilters,
          });
        } catch (error) {
          if (!axios.isCancel(error)) {
            console.error(error);
          }
        }
      };
    
      fetchData();
    
      return () => {
        dropdownSource.cancel('Component unmounted');
      };
  }, []);

  const wordSpliter = (str) => {
    // const strArr = str?.toString()?.split(",");
    const strArr = Array.isArray(JSON.parse(str)) ? JSON.parse(str) : [];
    const newStrArr = strArr.length
      ? strArr?.map((item, index) => {
        return item ? (
          <p
          key={index}
            className="as-widget mr-1 mt-1"
            style={{ color: "#333333", background: "#e6e6e6" }}
          >
            {item}
          </p>
        ) : (
          "-"
        );
      })
      : "-";
    return newStrArr;
  };

  const wordSpliterTopics = (str) => {
    let htmlOutput = [];
    const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];

    const newStrArr = strArr.length
      ? strArr?.map((item) => {
        filterData.topics.map((val) => {
          if (val.value == item) {
            htmlOutput.push(RenderQuizTopic(val.label, val.color).html);
          }
          return;
        });
      })
      : "-";
    return htmlOutput.length ? htmlOutput : [];
  };
  const wordSpliterTopicsExport = (str) => {
    let htmlOutput = [];
    const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length
      ? strArr?.map((item) => {
        filterData.topics.map((val) => {
          if (val.value == item) {
            htmlOutput.push(RenderQuizTopic(val.label, val.color).text);
          }
          return;
        });
      })
      : "-";
    return htmlOutput.length ? htmlOutput : [];
  };

  const handleMouseEnter = (e, topicArr) => {
    e.preventDefault();
    dispatch(setHoverKeywordData([]));
    dispatch(setHoverTopicData(topicArr ? topicArr : []));
    $("#Topictoshow")
      .css({
        top: -5 + e.clientY,
        left: -10 + e.clientX,
        position: "absolute",
        visibility: "visible",
        opacity: 1,
        "z-index": 99999,
      })
      .show();
  };

  const handleMouseEnterKeyword = (e, keywordArr) => {
    e.preventDefault();
    dispatch(setHoverTopicData(''));
    dispatch(setHoverKeywordData(keywordArr ? keywordArr : []));
    $("#Keywordtoshow")
      .css({
        top: -5 + e.clientY,
        left: -10 + e.clientX,
        position: "absolute",
        visibility: "visible",
        opacity: 1,
        "z-index": 99999,
      })
      .show();
  };

  const handleMouseLeave = () => {
    dispatch(setHoverTopicData(''));
    dispatch(setHoverKeywordData([]));
    $("#Topictoshow").hide();
  }

  const handleMouseLeaveKeyword = () => {
    dispatch(setHoverTopicData(''));
    dispatch(setHoverKeywordData([]));
    $("#Keywordtoshow").hide();
  }

  const dataToRender = () => {
    let updatedData = [];
    let allData = deleteArr;
    if (deleteSearch.length) {
      let tempName = allData.filter((item) => {
        let includes = item.name.toLowerCase().includes(deleteSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempType = allData.filter((item) => {
        let includes = item.type.toLowerCase().includes(deleteSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempdata = [
        ...tempName,
        ...tempType,
      ];
      let unique = [...new Set(tempdata)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }
    return updatedData;
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row, index) => {
        // let question = formatQuestion(row.question);
        return (
          <Link
            className="as-text-blue curser"
            title={row?.name ? striptags(row?.name) : "-"}
            // title={row.question_name ? row.question_name.replace(/(<([^>]+)>)/gi, "").trim(" ") : "-"}
            to={`/courseAdministration/gradingTemplate/edit/${row?.id}`}
            onClick={() => {
              $("#letterTemplatemodal").modal("hide");
            }}
          >
            {row?.name ? row?.name : "-"}
            {/* {row.question.toString() ? TrimText(row.question, 30) : "-"} */}
          </Link>
        );
      },
    },
    {
      name: "Topics",
      cell: (row) =>
        row?.topics ? (
          <>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-wrap">
                {wordSpliterTopics(row?.topics).length
                  ? wordSpliterTopics(row?.topics)[0]
                  : ""}
              </div>
              {/* {row?.topics.length > 1 ? <small className="notify-count">{row?.topics?.length}</small> : ""} */}
              <div
                className={`f-m-cont-${row?.id}`}
                onMouseEnter={(e) => {
                  handleMouseEnter(e, row?.topics)
                }}
                onMouseLeave={() => handleMouseLeave()}
              >
                <span className="nav-fa-comments">
                  {wordSpliterTopics(row?.topics).length > 1 ? <small className="notify-count-template">{wordSpliterTopics(row?.topics)?.length}</small> : ""}
                  {wordSpliterTopics(row?.topics).length > 1 && <i className="fal fa-books ml-4"></i>}
                </span>

              </div>
            </div>
          </>
        ) : (
          "-"
        ),
    },
    {
      name: "Keywords",
      cell: (row) =>
        row?.keywords ? (
          <>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-wrap">{wordSpliter(row?.keywords)[0]}</div>
              <div
                className={`f-m-cont-${row?.id}`}
                onMouseEnter={(e) => {
                  handleMouseEnterKeyword(e, row?.keywords)
                }}
                onMouseLeave={() => handleMouseLeaveKeyword()}
              >
                <span className="nav-fa-comments">
                  {wordSpliter(row?.keywords).length > 1 ? <small className="notify-count-template">{wordSpliter(row?.keywords)?.length}</small> : ""}
                  {wordSpliter(row?.keywords).length > 1 && <i className="fal fa-tags ml-4"></i>}
                </span>
              </div>
            </div>
          </>
        ) : (
          "-"
        ),
    },
    {
      name: "Actions",
      maxWidth: "50px",
      cell: (row, index) => (
        <div className="assessment-08 btn-dropdown-grp">
          {tab === "table" ? <div className="as-buttons d-flex">
            <Link
              className="btn btn-primary rounded-circle"
              title="Open"
              to={`/courseAdministration/gradingTemplate/edit/${row?.id}`}
              onClick={() => {
                $("#letterTemplatemodal").modal("hide");
              }}
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            <PermissionsGate scopes={["gradingLetterTemplateDelete"]} errorProps={{ disabled: true }}>
              <button
                className="btn btn-danger rounded-circle"
                title="Delete Template"
                // data-toggle="modal"
                // data-target="#deleteTable"
                onClick={() => handleDeleteTemplate(row.id)}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div> : <div className="as-buttons d-flex">
            <button
              className="btn btn-primary rounded-circle"
              title="Select Template"
              onClick={() => {
                setSelectedTemplate(row)
                if(setSelectedGradeMixTemplate){
                  setSelectedGradeMixTemplate(row)
                }
                $("#letterTemplatemodal").modal("hide");
              }}
            >
              <i className="fal fa-plus"></i>
            </button>
          </div>}
        </div>
      ),
    },
  ]);

  const deleteColumn = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => {
        return (
          <Link
            className="as-text-blue curser"
            title={row?.name ? striptags(row?.name) : "-"}
            to={row.type === "course" ? `/courseAdministration/coursesdetails/${row.id}/gradeSettings/show` : row.type === "program" ? `/courseAdministration/Programmes/programme/open/${row.id}/gradeSetting/table` : `/courseAdministration/coursesdetails/${row.intake_id}/assessments/open/assigned/Details/${row.id}`}
            onClick={() => {
              $("#deleteTable").modal("hide");
            }}
          >
            {row?.name ? row?.name : "-"}
          </Link>
        );
      },
    },
    {
      name: "Type",
      selector: "type"
    }
  ]);

  const handleDeleteTemplate = (id) => {
    setDeleteSearch("")
    deleteGradeTemplate({ type: 1, id }).then(res => {
      if (res.data?.assignedArr.length) {
        setDeleteArr(res.data.assignedArr)
        $("#deleteTable").modal("show");
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            deleteGradeTemplate({ type: 0, id }).then((res) =>
              setUpdatedData(!updatedData)
            );
            Swal.fire("Deleted!", "Your Resource has been deleted.", "success");
          }
        });
      }
    }).catch(err => console.error(err))
  }

  const exportData = async (fileType, fileName) => {
    let data;

    const header = ["Name", "Keywords", "Topics"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    GradeLetterTemplateList({
      page: tableState.page,
      limit: tableState.perPage,
      search: search,
      key: tableState.sortKey,
      sort: tableState.sortOrder,
      exportStatus: "true",
      viaKeywords: keywordValue.arr.length > 0 ? keywordValue.arr : [],
      viaTopics: topics.arr.length > 0 ? topics.arr : [],
    }).then((res) => {
      data = res?.data?.list_data;
      data = data?.map((row) => ({
        ...row,
        Name: row?.name ? row?.name : "-",
        Keywords:
          row?.keywords &&
            row?.keywords.length > 0 &&
            Array.isArray(JSON.parse(row?.keywords))
            ? JSON.parse(row?.keywords)
              ?.map((item) => item + " ")
              .join(", ")
            : "-",
        Topics: row?.topics
          ? wordSpliterTopicsExport(row?.topics).length
            ? wordSpliterTopicsExport(row?.topics)
              .map((item) => item)
              .join()
            : "-"
          : "-",
      }));
      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        setTimeout(() => {
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [row["Name"], row["Keywords"], row["Topics"]];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    });
  };

  return (
    <>
      <DataTableComponent
        exportFileName="Grading_letter_templates"
        exportFunction={exportData}
        columns={columns}
        data={qdata}
        loading={loading}
        isInsidePopUp={tab !== "table"}
        setSearch={setSearch}
        setState={setTableState}
        state={tableState}
        totalRows={totalRows}
        filters={[
          {
            filterName: "Keywords",
            optionArr: filterData.keywords,
            state: keywordValue,
            setState: setKeywordValue,
            renderLabelFunction: RenderKeyword,
            uniqueId: "keyword"
          },
          {
            filterName: "Topic",
            optionArr: filterData.topics,
            state: topics,
            setState: setTopics,
            renderLabelFunction: RenderQuizTopic,
            uniqueId: "topic"
          }
        ]}
        tableButton={[
          tab == "table" ? (
            <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <PermissionsGate
                    scopes={["gradingLetterTemplateAdd"]}
                    RenderError={() => (
                      <button
                        disabled
                        className="btn btn-primary"
                        title="Create New"
                      >
                        <i className="fal fa-plus"></i>Create New
                      </button>
                    )}
                  >
                    <Link
                      className="btn btn-primary"
                      to={`/courseAdministration/gradingTemplate/create`}
                      title="Create New"
                    >
                      <i className="fal fa-plus"></i>Create New
                    </Link>
                  </PermissionsGate>
                </div>
              </div>
            </div>
          ) : '']}
      />
      <PopupComponent
        id="deleteTable"
        header={{
          iconName: "fa-info-circle",
          heading: "Cannot delete, the template is assigned to the following:"
        }}
        tableData={{
          columns: deleteColumn,
          data: dataToRender(),
          search: deleteSearch,
          setSearch: setDeleteSearch
        }}
      />
    </>
  );
};

export default ListOfTemplate;
