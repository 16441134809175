import React from 'react'
import AuditTrailsTable from '../common/AuditTrailsTable'
import { GetTicketAuditDataList, GetTicketAuditDataListFilters } from '../../services/SupportService'

function AuditTrail() {
  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]
  return (
    <AuditTrailsTable
      apiFunction={GetTicketAuditDataList}
      filterArrApi={GetTicketAuditDataListFilters}
      columnsToShow={columnsToShow}
      exportFileName="Ticket_module"
      isShowingResourceTypeFilter
    />
  )
}

export default AuditTrail