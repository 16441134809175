import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

// Keyframe animation for tooltip fade-in
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

// Styled component for the tooltip
const TooltipContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-in-out forwards;
`;

const CustomTooltip = ({ content, position }) => {
  const tooltipRef = React.useRef(null);

  // Calculate tooltip position based on mouse event or element position
  const calculatePosition = (event) => {
    const tooltip = tooltipRef.current;

    if (position === 'mouse') {
      const mouseX = event.clientX;
      const mouseY = event.clientY;
      tooltip.style.left = `${mouseX}px`;
      tooltip.style.top = `${mouseY}px`;
    } else if (position === 'element') {
      const elementRect = event.target.getBoundingClientRect();
      const elementX = elementRect.x + elementRect.width / 2;
      const elementY = elementRect.y;
      tooltip.style.left = `${elementX}px`;
      tooltip.style.top = `${elementY}px`;
    }
  };

  return (
    <TooltipContainer ref={tooltipRef} onMouseMove={calculatePosition}>
      {content}
    </TooltipContainer>
  );
};

CustomTooltip.propTypes = {
  content: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['mouse', 'element']).isRequired,
};

export default CustomTooltip;
