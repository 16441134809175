import React from "react";
import { useHistory } from "react-router";
import { Link, useParams } from "react-router-dom";
import DetailsTabList from "./DetailsTabList";
import RegistrationList from "./RegistrationList";
import NonRegistrationList from "./NonRegistrationList";
import RegistrationReportList from "../RegistrationReportList";
import UpdatedResultList from "../UpdatedResultList";
import MarksList from "../MarksList";
import ExportData from "../ExportData";
import RegistrationDetails from "./RegistrationDetails";
import RegistrationInvoice from "./RegistrationInvoice";
import DetailsAuditTrail from "./DetailsAuditTrail";
import NotificationTab from "./NotificationTab";
import { GlobalIconNames } from "../../../../../utils/GlobalIconNames";

const AddTab = () => {
  const history = useHistory();
  const { tab, subTab, type, subType, id,subId } = useParams();
  return (
    <>
      {/*  */}
      <div className="tabs-wrap">
        {((subTab=="notification" && subType) || !subType) && (subTab !== "details" && type !=="add")?  (
          <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
           {(subTab !== "details" && type !=="add") && <li
              className="nav-item"
              onClick={() =>
                history.push(
                  `/studentAdministration/externalExam/details/${type}/detail${
                    type == "open" && "/" + id
                  }`
                )
              }
            >
              <a
                className={`nav-link curser  ${subTab == "detail" ? "active" : ""}`}
                id="pills-tab1"
                data-toggle="pill"
                // to={`/studentAdministration/externalExam/details/${type}/detail${
                //   type == "open" && "/" + id
                // }`}
                role="tab"
                aria-controls="pills-tab1"
                aria-selected="true"
              >
                {GlobalIconNames("details").html}
              </a>
            </li>}
            {(type == "open" || subTab=="notification") && (
              <>
                <li
                  className="nav-item"
                  onClick={() =>
                    history.push(
                      `/studentAdministration/externalExam/details/open/registration/${id}`
                    )
                  }
                >
                  <a
                    className={`nav-link curser  ${
                      subTab == "registration" ? "active" : ""
                    }`}
                    id="pills-tab2"
                    data-toggle="pill2"
                    role="tab"
                    aria-controls="pills-tab2"
                    aria-selected="true"
                  >
                    {GlobalIconNames("registration").html}
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() =>
                    history.push(
                      `/studentAdministration/externalExam/details/open/nonRegistration/${id}`
                    )
                  }
                >
                  <a
                    className={`nav-link curser  ${
                      subTab == "nonRegistration" ? "active" : ""
                    }`}
                    id="pills-tab3"
                    data-toggle="pill3"
                    role="tab"
                    aria-controls="pills-tab3"
                    aria-selected="true"
                  >
                    <span title="Non-Registrations">                
                      <i className="fal fa-info-circle"></i>Non-Registrations
                    </span>
                  </a>
                </li>{" "}
                <li
                  className="nav-item"
                  onClick={() =>
                    history.push(
                      `/studentAdministration/externalExam/details/open/notification/${id}`
                    )
                  }
                >
                  <a
                    className={`nav-link curser  ${
                      subTab == "notification" ? "active" : ""
                    }`}
                    id="pills-tab4"
                    data-toggle="pill4"
                    role="tab"
                    aria-controls="pills-tab4"
                    aria-selected="true"
                  >
                    {GlobalIconNames("notifications").html}
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() =>
                    history.push(
                      `/studentAdministration/externalExam/details/open/finalMarks/${id}`
                    )
                  }
                >
                  <a
                    className={`nav-link curser  ${
                      subTab == "finalMarks" ? "active" : ""
                    }`}
                    id="pills-tab5"
                    data-toggle="pill5"
                    role="tab"
                    aria-controls="pills-tab5"
                    aria-selected="true"
                  >
                    <span title="Final Marks Import">
                    <i className="fal fa-cog"></i>Final Marks Import
                    </span>
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() =>
                    history.push(
                      `/studentAdministration/externalExam/details/open/updatedResult/${id}`
                    )
                  }
                >
                  <a
                    className={`nav-link curser  ${
                      subTab == "updatedResult" ? "active" : ""
                    }`}
                    id="pills-tab6"
                    data-toggle="pill6"
                    role="tab"
                    aria-controls="pills-tab6"
                    aria-selected="true"
                  >
                    <span title="Updated Result">
                    <i className="fal fa-list"></i>Updated Result
                    </span>
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() =>
                    history.push(
                      `/studentAdministration/externalExam/details/open/report/${id}`
                    )
                  }
                >
                  <a
                    className={`nav-link curser  ${
                      subTab == "report" ? "active" : ""
                    }`}
                    id="pills-tab9"
                    data-toggle="pill9"
                    role="tab"
                    aria-controls="pills-tab9"
                    aria-selected="true"
                  >
                    <span title="Registrations Report">
                    <i className="fal fa-ticket-alt"></i>Registrations Report
                    </span>
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() =>
                    history.push(
                      `/studentAdministration/externalExam/details/open/exportData/${id}`
                    )
                  }
                >
                  <a
                    className={`nav-link curser  ${
                      subTab == "exportData" ? "active" : ""
                    }`}
                    id="pills-tab7"
                    data-toggle="pill7"
                    role="tab"
                    aria-controls="pills-tab7"
                    aria-selected="true"
                  >
                    <span title="Export Data">
                    <i className="fal fa-window"></i>Export Data
                    </span>
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() =>
                    history.push(
                      `/studentAdministration/externalExam/details/open/audit-trail/${id}`
                    )
                  }
                >
                  <a
                    className={`nav-link curser  ${
                      subTab == "audit-trail" ? "active" : ""
                    }`}
                    id="pills-tab8"
                    data-toggle="pill8"
                    role="tab"
                    aria-controls="pills-tab8"
                    aria-selected="true"
                  >
                     {GlobalIconNames("audittrail").html}
                  </a>
                </li>
              </>
            )}
          </ul>
        ) : (
          subType!=="add_details" &&
          subTab === "registration" ? (
            <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
              <li
                className="nav-item"
                onClick={() =>
                  history.push(
                    `/studentAdministration/externalExam/details/open/registration/${id}/details/${subId}`
                  )
                }
              >
                <a
                className={`nav-link curser  ${
                  subType == "details" ? "active" : ""
                }`}
                id="pills-tab1"
                data-toggle="pill"
                role="tab"
                aria-controls="pills-tab1"
                aria-selected="true"
              >
                <i className="fal fa-info-circle"></i>Details
                </a> 
                
              </li>
              <li
                className="nav-item"
                onClick={() =>
                  history.push(
                    `/studentAdministration/externalExam/details/open/registration/${id}/invoice/${subId}`
                  )
                }
              >
                <a
                  className={`nav-link curser  ${
                    subType == "invoice" ? "active" : ""
                  }`}
                  id="pills-tab2"
                  data-toggle="pill2"
                  role="tab"
                  aria-controls="pills-tab2"
                  aria-selected="true"
                >
                  <i className="fal fa-info-circle"></i>Invoice
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() =>
                  history.push(
                    `/studentAdministration/externalExam/details/open/registration/${id}/audit-trail/${subId}`
                  )
                }
              >
                <a
                  className={`nav-link curser  ${
                    subType == "audit-trail" ? "active" : ""
                  }`}
                  id="pills-tab3"
                  data-toggle="pill3"
                  role="tab"
                  aria-controls="pills-tab3"
                  aria-selected="true"
                >
                  {GlobalIconNames("audittrail").html}
                </a>
              </li>
            </ul>
          ) : 
           ""
           
        )}

        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade active show"
            id="pills-tab1"
            role="tabpanel"
            aria-labelledby="pills-tab1"
          >
            {subTab === "detail" && <DetailsTabList />}
          </div>
          <div
            className="tab-pane fade active show"
            id="pills-tab2"
            role="tabpanel"
            aria-labelledby="pills-tab2"
          >
            {subTab === "registration" && !subType && <RegistrationList />}
          </div>
          <div
            className="tab-pane fade active show"
            id="pills-tab3"
            role="tabpanel"
            aria-labelledby="pills-tab3"
          >
            {subTab === "nonRegistration" && <NonRegistrationList />}
          </div>
          <div
            className="tab-pane fade active show"
            id="pills-tab4"
            role="tabpanel"
            aria-labelledby="pills-tab4"
          >
            {subTab === "notification" && <NotificationTab />}
          </div>
          <div
            className="tab-pane fade active show"
            id="pills-tab9"
            role="tabpanel"
            aria-labelledby="pills-tab9"
          >
            {subTab === "report" && <RegistrationReportList />}
          </div>
          <div
            className="tab-pane fade active show"
            id="pills-tab5"
            role="tabpanel"
            aria-labelledby="pills-tab5"
          >
            {subTab === "updatedResult" && <UpdatedResultList />}
          </div>
          <div
            className="tab-pane fade active show"
            id="pills-tab6"
            role="tabpanel"
            aria-labelledby="pills-tab6"
          >
            {subTab === "finalMarks" && <MarksList />}
          </div>
          <div
            className="tab-pane fade active show"
            id="pills-tab7"
            role="tabpanel"
            aria-labelledby="pills-tab6"
          >
            {subTab === "exportData" && <ExportData />}
          </div>
          <div
            className="tab-pane fade active show"
            id="pills-tab8"
            role="tabpanel"
            aria-labelledby="pills-tab8"
          >
            {subTab === "audit-trail" && <DetailsAuditTrail />}
          </div>

          <div
            className="tab-pane fade active show"
            id="pills-tab1"
            role="tabpanel"
            aria-labelledby="pills-tab1"
          >
            {subTab === "registration" && (subType === "details" || subType=="add_details") && (
              <RegistrationDetails />
            )}
          </div>
          <div
            className="tab-pane fade active show"
            id="pills-tab2"
            role="tabpanel"
            aria-labelledby="pills-tab2"
          >
            {subTab === "registration" && subType === "invoice" && (
              <RegistrationInvoice />
            )}
          </div>
          <div
            className="tab-pane fade active show"
            id="pills-tab3"
            role="tabpanel"
            aria-labelledby="pills-tab3"
          >
            {subTab === "registration" && subType === "audit-trail" && (
              <DetailsAuditTrail />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTab;
