import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import * as Yup from "yup";
import user from "../../../assets/images/default-user.png";
import { URL_REGEX } from "../../../utils/Constants";
import cog from "../../../assets/images/myProfile.svg";
import Select from "react-select";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import Tabs from "../Tabs";
import { getCurrentTimeHomePage } from "../../common/Helper";
import DetailTab from "./DetailTab";
import VisualsTab from "./VisualsTab";
// import { Day, Month, Year, Location, Company, Title } from "../DataReactSelect";

const AddPortalSetting = () => {
  const { id } = useParams();
  const history = useHistory();

  return (
    <>
      {/* <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <Tabs />
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <h4 className="text-left-align landing-heading heading_color_dynamic">
                    Portal <span>Setting</span>
                  </h4>
                </div> */}
      <div className="tabs-wrap">
        {/* <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
          <li className="nav-item" onClick={() => history.push("/administration/brandSetting/addNewBrand")}>
            <a className="nav-link " id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
              <i className="fal fa-info-circle"></i>Brand Setting
            </a>
          </li>
          <li className="nav-item" onClick={() => history.push("/administration/brandSetting/addPortalSetting")}>
            <a className="nav-link active" id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
              <i className="fal fa-info-circle"></i>Portal Setting
            </a>
          </li>
        </ul> */}
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
            {/* <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
              <li className="nav-item" onClick={() => history.push("/administration/brandSetting/addPortalSetting/detailsTab")}>
                <a className="nav-link " id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                  <i className="fal fa-info-circle"></i>Details Tab
                </a>
              </li>
              <li className="nav-item" onClick={() => history.push("/administration/brandSetting/addPortalSetting/visualTab")}>
                <a className="nav-link active" id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab1" aria-selected="true">
                  <i className="fal fa-info-circle"></i>Visuals Tab
                </a>
              </li>
            </ul> */}
            {/* <div className="card card-body-inr"> */}
            <DetailTab id={id} />
            <div className="mb-4" />
            <VisualsTab id={id} />
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AddPortalSetting;
