import axiosInstance from "../utils/axios";

export const GetTicketDepartmentSettingList = async () => {
    return await axiosInstance().get(`/getTicketDepartmentSettingList`);
};

export const GetTicketDepartmentSettingListCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getTicketDepartmentSettingList`,{ cancelToken: cancelToken });
};

export const DeleteTicketSetting = async (data) => {
    return await axiosInstance().post(`/deleteTicketSetting`, data);
};

export const AddTicketDepartmentSetting = async (data) => {
    return await axiosInstance().post(`/addTicketDepartmentSetting`, data);
};

export const GetTicketDepartmentSettingDetail = async (id) => {
    return await axiosInstance().get(`/getTicketDepartmentSettingDetail?id=${id}`);
};

export const GetTicketDepartmentSettingDetailCancelToken = async (id, cancelToken) => {
    return await axiosInstance().get(`/getTicketDepartmentSettingDetail?id=${id}`, {cancelToken: cancelToken});
};

export const EditTicketDepartmentSetting = async (data) => {
    return await axiosInstance().post(`/editTicketDepartmentSetting`, data);
};

export const GetTicketCategoriesSettingList = async () => {
    return await axiosInstance().get(`/getTicketCategoriesSettingList`);
};

export const GetTicketCategoriesSettingListCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getTicketCategoriesSettingList`, {cancelToken: cancelToken});
};

export const DeleteTicketcategoriesSetting = async (data) => {
    return await axiosInstance().post(`/deleteTicketcategoriesSetting`, data);
};

export const AddTicketCategoriesSetting = async (data) => {
    return await axiosInstance().post(`/addTicketCategoriesSetting`, data);
};

export const GetTicketCategoriesSettingDetail = async (id) => {
    return await axiosInstance().get(`/getTicketCategoriesSettingDetail?id=${id}`);
};

export const GetTicketCategoriesSettingDetailCancelToken = async (id, cancelToken) => {
    return await axiosInstance().get(`/getTicketCategoriesSettingDetail?id=${id}`,{ cancelToken: cancelToken });
};

export const EditTicketCategoriesSetting = async (data) => {
    return await axiosInstance().post(`/editTicketCategoriesSetting`, data);
};

export const GetTicketSettingAuditTrail = async (data) => {
    return await axiosInstance().post(`/getTicketSettingAuditTrail`, data);
};

export const GetTicketDepartmentSettingListFilter = async () => {
    return await axiosInstance().get(`/getTicketDepartmentSettingListFilter`);
};

export const GetTicketDepartmentSettingListFilterCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getTicketDepartmentSettingListFilter`,{ cancelToken: cancelToken });
};

export const GetTicketCategoriesSettingListFilter = async () => {
    return await axiosInstance().get(`/getTicketCategoriesSettingListFilter`);
};

export const GetTicketCategoriesSettingListFilterCancelToken = async (cancelToken) => {
    return await axiosInstance().get(`/getTicketCategoriesSettingListFilter`,{ cancelToken: cancelToken });
};