import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { RenderAttachList } from '../../../common/RenderAttachList';
import {
  IMAGE_URL,
  TABLE_DATE_FORMAT,
  TABLE_TIME_FORMAT,
} from '../../../../utils/Constants';
import moment from 'moment';
import {
  checkCheckboxRejectDetails,
  deletePdfLetter,
  SendLetter,
} from '../../../../services/RegistrationService';
import Swal from 'sweetalert2';
import PermissionsGate from "../../../../utils/permissionGate";

function Checks(props) {
  const [studentData, setStudentData] = useState();
  const [documentFileUrl, setDocumentFileUrl] = useState('');
  const [otherDocumentFormList, setOtherDocumentFormList] = useState([]);
  const [isSendingWelocmeLetter, setIsSendingWelocmeLetter] = useState(false);
  const [welcomeLetterSentBy, setWelcomeLetterSentBy] = useState([]);
  const [welcomeLetterSendDate, setWelcomeLetterSendDate] = useState([]);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const studentId = params.get('studentId');
  const formattedDate = (dateString) => {
    return moment(dateString).format('YYYY-MM-DD [at] HH:mm');
  };

  useEffect(() => {
    setStudentData(props.studentData);
    setOtherDocumentFormList(props.studentData?.welcome_letter);
    setWelcomeLetterSentBy(props.studentData?.re_reg_data?.wl_sent_by);
    setWelcomeLetterSendDate(
      formattedDate(props.studentData?.re_reg_data?.wl_sent_date)
    );
    if (
      props.studentData &&
      props.studentData.welcome_letter &&
      props.studentData.welcome_letter[0]?.url.includes(
        'home/myaie/public_html/'
      )
    ) {
      let DocLink =
        IMAGE_URL +
        '/' +
        props.studentData.welcome_letter[0]?.url.replace(
          'home/myaie/public_html/',
          ''
        );
      setDocumentFileUrl(DocLink);
    } else {
      setDocumentFileUrl('');
    }
  }, [props]);

  const formik = useFormikContext();

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        deletePdfLetter({ id: id }).then((res) => {});
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  };

  const onCheckSubject = (e) => {
    formik.setFieldValue('portalSubjectAssigned', e.target.checked);
    props.clickedCheck('check_subject', e.target.checked);
  };

  const onCheckAssigned = (e) => {
    formik.setFieldValue('ebooksAssigned', e.target.checked);
    props.clickedCheck('ebook_assigned', e.target.checked);
  };

  const onSendWelcome = () => {
    if (!formik.values.brand_id) {
      Swal.fire('Brand Required', 'Please Select Brand', 'error');
      return;
    }
    setIsSendingWelocmeLetter(true);
    // console.log("is brand selected", formik.values)
    // return;
    const formData = new FormData();
    formData.append('org_student_id', studentData?.spStudentsDetails);
    formData.append('id', studentId);
    formData.append('notify_email', formik.values.email);
    formData.append('brand_reg', formik.values.brand_id);
    SendLetter(formData)
      .then((res) => {
        // setAssemblyID(res?.data?.inserted_record);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: `${res?.data?.message || 'Updated Successfully'}`,
        }).then((res) => {
          setIsSendingWelocmeLetter(false);
          props.setrefresh(!props.refresh);
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `${err?.response?.data?.message}`,
        });
        setIsSendingWelocmeLetter(false);
      });
  };

  const basename = (path) => {
    return path.split('/').reverse()[0];
  };

  const handleAllowedTime = (order) => {
    const extraTime = {
      order: order,
      action: 'exam_results',
      id: studentId,
    };
    checkCheckboxRejectDetails(extraTime)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const formatWelcomeLetter = (date, sentBy) => {
    if (date && sentBy && date.includes('at')) {
      const part = date.split('at');
      const datePart = part[0];
      const timePart = part[1];

      const formattedDate = moment(datePart.toLocaleString()).format(
        TABLE_DATE_FORMAT
      );

      return (
        <span>
          <span>
            Updated on <b>{formattedDate}</b> at <b>{timePart}</b> by{' '}
            <b>{sentBy}</b>
          </span>
        </span>
      );
    }
    return '';
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Checks</div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>
            Portal Subjects Assigned{' '}
            {(formik.values.ApplicationStatus ==
              'Re-Registration-Study Kit Pending' &&
              !(
                formik.values.to_be_reviewed == 1 &&
                formik.values.school == 'engineering'
              )) ||
            formik.values.ApplicationStatus == 'Closed-Ordered' ||
            formik.values.ApplicationStatus == 'Study Kit Pending' ||
            (formik.values.ApplicationStatus == 'Review' &&
              formik.values.examResult == 'passed')
              ? '*'
              : ''}
          </label>
          <div className="form-group-blk mb-3">
            <label></label>
            <div className="custom-check custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                id="portalSubjectAssigned"
                name="select"
                checked={formik.values.portalSubjectAssigned}
                onChange={onCheckSubject}
              />
              <label
                className="custom-control-label"
                htmlFor="portalSubjectAssigned"
              >
                Portal Subjects Assigned
              </label>
            </div>
          </div>
        </div>
        {studentData &&
          studentData.re_reg_data &&
          studentData.re_reg_data.academy == 'engineering' && (
            <div className="col-md-4">
              <label>Ebooks Assigned</label>
              <div className="form-group-blk mb-3">
                <label></label>
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="ebooksAssigned"
                    name="select"
                    checked={formik.values.ebooksAssigned}
                    onChange={onCheckAssigned}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="ebooksAssigned"
                  >
                    Ebook Assigned by{' '}
                    <b>
                      {studentData &&
                      studentData.ebooks_assigned &&
                      studentData.ebooks_assigned
                        ? studentData.ebooks_assigned.Firstname +
                          ' ' +
                          studentData.ebooks_assigned.Lastname
                        : ''}
                    </b>{' '}
                    on{' '}
                    <b>
                      {studentData &&
                      studentData.re_reg_data &&
                      studentData.re_reg_data.ebook_order_assign_date
                        ? moment(
                            studentData?.re_reg_data?.ebook_order_assign_date
                          ).format(TABLE_DATE_FORMAT) +
                          ' ' +
                          moment(
                            studentData?.re_reg_data?.ebook_order_assign_date
                          ).format(TABLE_TIME_FORMAT)
                        : ''}
                    </b>
                  </label>
                </div>
              </div>
            </div>
          )}

        {((studentData &&
          studentData?.re_reg_data &&
          studentData?.re_reg_data.status == 'Review') ||
          (studentData &&
            studentData?.re_reg_data &&
            studentData?.re_reg_data.status == 'Re-Registration-Final') ||
          (studentData &&
            studentData?.re_reg_data &&
            studentData?.re_reg_data.status == 'Re-Registration-RCO')) && (
          <>
            <div className="col-md-4">
              <label title="SendWhen">Exam Results</label>
              <div className="form-icon-group" title="SendWhen">
                <div className="custon_rd_new">
                  <div
                    className="custom-control custom-radio custom-control-inline"
                    title="Passed"
                  >
                    <input
                      type="radio"
                      id="customRadioInline1122"
                      name="customRadioInline"
                      className="custom-control-input"
                      onClick={() => {
                        handleAllowedTime('passed');
                      }}
                      checked={formik.values.examResult == 'passed'}
                      onChange={() =>
                        formik.setFieldValue('examResult', 'passed')
                      }
                      onBlur={formik.handleBlur}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline1122"
                    >
                      Passed
                    </label>
                  </div>
                  <div
                    className="custom-control custom-radio custom-control-inline"
                    title="Failed"
                  >
                    <input
                      type="radio"
                      id="customRadioInline2211"
                      name="customRadioInline"
                      className="custom-control-input"
                      onClick={() => {
                        handleAllowedTime('failed');
                      }}
                      title="examResult"
                      checked={formik.values.examResult == 'failed'}
                      onChange={() =>
                        formik.setFieldValue('examResult', 'failed')
                      }
                      onBlur={formik.handleBlur}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadioInline2211"
                    >
                      Failed
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {studentData?.re_reg_data.exam_results && (
              <div className="col-md-4">
                <p className="mt-4">
                  Exam result Assigned by{' '}
                  <b>
                    {studentData &&
                      studentData.exam_results &&
                      studentData.exam_results &&
                      studentData.exam_results.Firstname +
                        ' ' +
                        studentData.exam_results.Lastname}
                  </b>{' '}
                  on{' '}
                  <b>
                    {studentData &&
                      studentData.re_reg_data &&
                      moment(
                        studentData?.re_reg_data?.exam_results_date
                      ).format(TABLE_DATE_FORMAT) +
                        ' ' +
                        moment(
                          studentData?.re_reg_data?.exam_results_date
                        ).format(TABLE_TIME_FORMAT)}
                  </b>
                </p>
              </div>
            )}
          </>
        )}

        {!documentFileUrl && (
          <>
            <div className="col-md-4">
              <label>&nbsp;</label>
              <div className="form-group-blk mb-3">
                <label> </label>
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="email"
                    name="select"
                    checked={formik.values.email}
                    onChange={(e) =>
                      formik.setFieldValue('email', e.target.checked)
                    }
                  />
                  <label className="custom-control-label" htmlFor="email">
                    Email
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <label>&nbsp;</label>
              <div className="form-group-blk mb-3">
                <PermissionsGate errorProps={{disabled:true}} scopes={['regwelcomelet']}>
                <button
                  className="btn btn-save btn-success"
                  type="button"
                  title="Update"
                  onClick={onSendWelcome}
                  disabled={isSendingWelocmeLetter}
                >
                  {isSendingWelocmeLetter ? (
                    <i className="fas fa-cog fa-spin"></i>
                  ) : (
                    <i className="fas fa-paper-plane"></i>
                  )}
                  Send welcome letter
                </button>
                </PermissionsGate>
              </div>
            </div>
          </>
        )}
        <div className="frm-group">
          <ul className="list-unstyled attached-file-ul m-0">
            {otherDocumentFormList &&
              otherDocumentFormList.map((item) => {
                return (
                  <li key={item.id} className="mb-2">
                    <div className="col-md-11">
                      {RenderAttachList({
                        fileName: basename(item.url),
                        // size: "1205",
                        filePath:
                          IMAGE_URL +
                          '/' +
                          item.url
                            .replace('home/myaie/public_html/', '')
                            .replace('public', ''),
                        showClose: false,
                      })}
                    </div>
                    <button
                      title="Delete"
                      type="button"
                      className="btn btn-danger rounded-circle"
                      onClick={() => handleDelete(item.id)}
                    >
                      <i className="fal fa-trash-alt"></i>
                    </button>
                  </li>
                );
              })}
          </ul>
        </div>
        {documentFileUrl && (
          <>
            <div className="col-md-6 col-lg-4">
              <label>&nbsp;</label>
              <div className="form-group-blk mb-3">
                <label> </label>
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="email"
                    name="select"
                    checked={formik.values.email}
                    onChange={(e) =>
                      formik.setFieldValue('email', e.target.checked)
                    }
                  />
                  <label className="custom-control-label" htmlFor="email">
                    Email
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <label>&nbsp;</label>
              <div className="form-group-blk mb-3">
              <PermissionsGate errorProps={{disabled:true}} scopes={['regwelcomelet']}>
                <button
                  className="btn btn-save btn-success"
                  type="button"
                  title="Update"
                  onClick={onSendWelcome}
                  disabled={isSendingWelocmeLetter}
                >
                  {isSendingWelocmeLetter ? (
                    <i className="fas fa-cog fa-spin"></i>
                  ) : (
                    <i className="fas fa-paper-plane"></i>
                  )}
                  Send welcome letter
                </button>
                </PermissionsGate>
                {welcomeLetterSendDate && welcomeLetterSentBy && (
                  <div style={{ display: 'flex', marginTop: '10px' }}>
                    <div>
                      <i
                        className="fal fa-history"
                        style={{ color: 'var(--topbar-color)' }}
                      >
                        &nbsp;
                      </i>
                    </div>
                    <div>
                      <p>
                        {formatWelcomeLetter(
                          welcomeLetterSendDate,
                          welcomeLetterSentBy
                        )}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Checks;
