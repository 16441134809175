import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import { InitialRender } from "../../common/Helper";
import {
  ColorRender,
} from "../../systemadministration/usermanagement/CheckRole";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { GetListOfPMCAuditTrail } from "../../../services/ProgramService";
import moment from "moment";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Hover from "../../common/Hover";
import { checkIfImageExists, handleTableScroll } from "../../../utils/commonFunction";
import { formatQuestion } from "../../../utils/FormatQuestion";
import HtmlParser from "react-html-parser";
import { CheckJSONString } from "../../common/CheckJSONString";
import { TrimText } from "../../common/TrimText";
import { Link } from "react-router-dom";
import { RenderAuditTrailActionType } from "../../../utils/CommonGroupingItem";

const AuditTrail = () => {
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");

  const [userData, setUserData] = useState([]);

  const [actiontype, setActionType] = useState([]);
  const [searchActionTypeCheck, setSearchActionTypeCheck] = useState({});

  const [month, setMonth] = useState([]);
  const [searchMonthCheck, setSearchMonthCheck] = useState({});

  const [year, setYear] = useState([]);
  const [searchYearCheck, setSearchYearCheck] = useState({});

  const [loading, setloading] = useState(true);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    if (id) {
      GetListOfPMCAuditTrail(id)
        .then((res) => {
          setUserData(res?.data);
          setloading(false);
        })
        .catch((err) => console.log(err));
    }
    else {
      GetListOfPMCAuditTrail()
        .then((res) => {
          setUserData(res?.data);
          setloading(false);
        })
        .catch((err) => console.log(err));
    }

  }, []);

  const sortingDate = (rowA, rowB) => {
    const date1 = new Date(rowA.description.timestamp);
    const date2 = new Date(rowB.description.timestamp);

    if (date2 > date1) {
      // console.log(`${date2} is greater than ${date1}`)
      return 1;
    } else if (date2 < date1) {
      // console.log(`${date1} is greater than ${date2}`)
      return -1;
    } else {
      // console.log(`Both dates are equal`)
      return 0;
    }
  };

  const sortingName = (rowA, rowB) => {
    const name1 = rowA.resource_type == "Features & Benefit" ? rowA.description.object.definition.Old.name ? rowA.description.object.definition.Old.name.toString() : rowA.description.object.definition.New.name.toString() : rowA.resource_type == "Assign Module" || rowA.resource_type == "Removed Module" ? rowA.description.object.definition.Old.Portal ? rowA.description.object.definition.Old.Portal.toString() : rowA.description.object.definition.New.Portal.toString() : rowA.description.object.definition.Old.SAQA_Name ? rowA.description.object.definition.Old.SAQA_Name.toString() : rowA.description.object.definition.New.SAQA_Name.toString();


    const name2 = rowB.resource_type == "Features & Benefit" ? rowB.description.object.definition.Old.name ? rowB.description.object.definition.Old.name.toString() : rowB.description.object.definition.New.name.toString() : rowB.resource_type == "Assign Module" || rowB.resource_type == "Removed Module" ? rowB.description.object.definition.Old.Portal ? rowB.description.object.definition.Old.Portal.toString() : rowB.description.object.definition.New.Portal.toString() : rowB.description.object.definition.Old.SAQA_Name ? rowB.description.object.definition.Old.SAQA_Name.toString() : rowB.description.object.definition.New.SAQA_Name.toString();
    return name1.localeCompare(name2);
  };

  const sortingUser = (rowA, rowB) => {
    const name1 = rowA.description.User.name.toString();
    const name2 = rowB.description.User.name.toString();
    return name1.localeCompare(name2);
  };

  const actionSortFunction = (rowA, rowB) => {
    const name1 = rowA.description.verb.Action;
    const name2 = rowB.description.verb.Action;
    return name1.localeCompare(name2);
  };

  const difference = (obj1, obj2) => {
    let keyFound = [];
    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        return keyFound.push(key + ":" + obj1[key]);
      }
    });
    return keyFound;
  };

  const logPop = (data) => {
    let finalData = data;
    // if (Object.keys(finalData.object.definition.Old).length && finalData.object.definition.Old.start) {
    //   // check that there is object named Old and it has key named start now updating it
    //   finalData.object.definition.Old.start = moment(finalData.object.definition.Old.start).format(TABLE_DATE_TIME_FORMAT)
    // }
    // if (Object.keys(finalData.object.definition.Old).length && finalData.object.definition.Old.end) {
    //   // check that there is object named Old and it has key named end now updating it
    //   finalData.object.definition.Old.end = moment(finalData.object.definition.Old.end).format(TABLE_DATE_TIME_FORMAT)
    // }
    // if (Object.keys(finalData.object.definition.New).length && finalData.object.definition.New.start) {
    //   // check that there is object named New and it has key named start now updating it
    //   finalData.object.definition.New.start = moment.unix(finalData.object.definition.New.start).format(TABLE_DATE_TIME_FORMAT)
    // }
    // if (Object.keys(finalData.object.definition.New).length && finalData.object.definition.New.end) {
    //   // check that there is object named Old and it has key named start now updating it
    //   finalData.object.definition.New.end = moment.unix(finalData.object.definition.New.end).format(TABLE_DATE_TIME_FORMAT)
    // }
    //Updating TimeStamp
    finalData.timestamp = moment(finalData.timestamp).format(TABLE_DATE_TIME_FORMAT)
    //Now printing updated Value
    Swal.fire({ html: `<div class="audit-trial-json"><pre>${JSON.stringify(finalData, undefined, 1)}</pre></div>` });
  }

  const columns = useMemo(() => [
    {
      name: "User",
      selector: "user",
      sortable: true,
      sortFunction: sortingUser,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className="assigned-title-blk name-icon">
              {row.description.User.URL && checkIfImageExists(`${IMAGE_URL}/${row.description.User.URL.replaceAll(
                "/home/myaie/public_html/",
                ""
              ).replace(
                "public/",
                ""
              )}`) ? (
                <img
                  src={`${IMAGE_URL}/${row.description.User.URL.replaceAll(
                    "/home/myaie/public_html/",
                    ""
                  ).replace(
                    "public/",
                    ""
                  )}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([
                  row.description.User.name.split(" ")[0],
                  row.description.User.name.split(" ")[1],
                ])
              )}

              <span
                className={`profile-box-2-status ${row.description.User.status
                  ? row.description.User.status
                  : "Offline"
                  }`}
              >
                <i className="fas fa-circle"></i>
              </span>

              <Hover
                firstName={row.description.User.name.split(" ")[0]}
                lastName={row.description.User.name.split(" ")[1]}
                photo={row.description.User.URL.replaceAll(
                  "/home/myaie/public_html/",
                  ""
                ).replace(
                  "public/",
                  ""
                )}
                email={row.description.User.mbox}
                mobile={row.description.User?.Mobile}
                status={row.description.User.status}
                right={true}
                role={"module"}
              />
              {/* <div className="assigned-title-info popup-right">
                <article>
                  <figure>
                    {row.description.User.URL ? (
                      <img src={`${IMAGE_URL}/${row.description.User.URL.replace("/home/myaie/public_html/", "")}`} alt="AIE" />
                    ) : (
                      InitialRender([row.description.User.name, row.description.User.name])
                    )}
                  </figure>
                  <figcaption>
                    <h4>{row.description.User.name}</h4>
                    <a href="javascript:void(0);" onClick={() => navigator.clipboard.writeText(row.description.User.mbox)}>
                      <i className="fal fa-envelope"></i> {row.description.User.mbox ? row.description.User.mbox : "-"}
                    </a>
                    <a href="javascript:void(0);" onClick={() => navigator.clipboard.writeText(row.description.User?.Mobile)}>
                      <i className="fal fa-phone-alt"></i> {row.description.User.Mobile ? row.description.User.Mobile : "-"}
                    </a>
                  </figcaption>
                </article>
              </div> */}
            </span>
            <p>
              <b>{row.description.User.name}</b>
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Action Type",
      sortable: true,
      selector: "action_type",
      sortFunction: actionSortFunction,
      cell: (row) => <div title={row.description.verb.Action}>{RenderAuditTrailActionType(row.description.verb.Action).html}</div>,
    },

    {
      name: "Resource Type",
      sortable: true,
      selector: "resource_Name",
      cell: (row) => row.resource_type ? <div title={row.resource_type}>{row.resource_type}</div> : "-",
    },
    {
      name: "Resource Name",
      selector: "Name",
      sortable: true,
      sortFunction: sortingName,
      cell: (row) => {
        if (row.description.verb.Action == "Deleted") {
          if (row.resource_type == "Features & Benefit") {
            // let check = CheckJSONString(row.description.object.definition.Old.feature_benefit)
            // if (!check) {
              return <div className="auditTrail-text-overflow-ellipsis" title={(row.description.object.definition.Old.name)}><p>{row.description.object.definition.Old.name}</p></div>
            // } else {
            //   return <div className="auditTrail-text-overflow-ellipsis" title={formatQuestion(row.description.object.definition.Old.feature_benefit)}>{HtmlParser(TrimText(row.description.object.definition.Old.feature_benefit.toString(), 60))}</div>
            // }
          } else if (row.resource_type == "Assign Module" || row.resource_type == "Removed Module") {
            return <div  className="auditTrail-text-overflow-ellipsis" title={row.description.object.definition.Old.Portal}> <p>{row.description.object.definition.Old.Portal}</p></div>
          } else {
            return <div className="auditTrail-text-overflow-ellipsis" title={row.description.object.definition.Old.SAQA_Name} ><p>{row.description.object.definition.Old.SAQA_Name}</p></div>
          }
        } else {
          if (row.resource_type == "Features & Benefit") {
            // let check = CheckJSONString(row.description.object.definition.New.feature_benefit)
            // if (check) {
              return <div className="auditTrail-text-overflow-ellipsis">
                <Link className="as-text-blue curser" to={`/program-management-center/programmes/features-benefits/table/${row.description.object.id}`} title={(row.description.object.definition.New.name)}>
                <div className="auditTrail-text-overflow-ellipsis"> <p>{row.description.object.definition.New.name }</p> </div>
                </Link></div>
            // } else {
            //   return <div className="auditTrail-text-overflow-ellipsis">
            //     <p className="as-text-blue curser" title={formatQuestion(row.description.object.definition.New.feature_benefit)} ><Link className="as-text-blue curser" to={`/program-management-center/programmes/features-benefits/table/${row.description.object.definition.New.programme_id}`}>
            //       <div className="auditTrail-text-overflow-ellipsis" title={formatQuestion(row.description.object.definition.New.feature_benefit)}>
            //         {formatQuestion(row.description.object.definition.New.feature_benefit)}
            //       </div>
            //     </Link></p></div>
            // }
          } else if (row.resource_type == "Assign Module" || row.resource_type == "Removed Module") {
            return <Link className="as-text-blue curser" to={`/program-management-center/programmes/assigned-modules/table/${row.description.object.id}`}>
              <div className="auditTrail-text-overflow-ellipsis" title={row.description.object.definition.New.Portal}>
                <p>{row.description.object.definition.New.Portal}</p></div>
            </Link>
          }
          return <Link className="as-text-blue curser" to={`/program-management-center/programmes/programme/open/${row.description.object.id}`}>
            <div className="auditTrail-text-overflow-ellipsis"><p title={row.description.object.definition.New.SAQA_Name}>{row.description.object.definition.New.SAQA_Name}</p></div>
          </Link>
        }
      },
    },

    {
      name: "Old Value",
      // sortable: true,
      cell: (row) => {
        const entries = Object.entries(row.description.object.definition?.Old);

        if (row.description.verb.Action === "Created") {
          return "-";
        } else if (row.description.verb.Action === "Edited") {
          const diff = difference(
            row.description.object.definition?.Old,
            row.description.object.definition?.New
          );
          return diff.length !== 0 ? (
            <div
              className="tooltip-custom"
              title={`${diff.sort().map((entry) => entry.toString())}`
                .split(",")
                .join("\n")}
            >
              {/* <span className="tooltip-custom-box">{diff.sort().map(entry => <p>{entry.toString()}</p>)}</span> */}
              {diff.sort().map((entry, index) => {
                return (
                  index < 2 && (
                    <p key={index}>
                      {entry.toString().length > 30
                        ? entry.toString().slice(0, 30) + "..."
                        : entry}
                    </p>
                  )
                );
              })}
              {diff.length > 2 ? "..." : null}
            </div>
          ) : (
            "-"
          );
        } else {
          const diff = difference(row.description.object.definition?.New, row.description.object.definition?.Old);
          // return entries.length < 2
          //   ? entries.map((entry, index) => {
          //       return (
          //         <div className="tooltip-custom" title={`${diff.sort().map(entry =>entry.toString())}`.split(',').join("\n")}>
          //           {/* <p>
          //             {diff.sort().map((entry) => (
          //               <p>{entry.toString()}</p>
          //             ))}
          //           </p> */}
          //           <p key={index}>
          //             {entry[0]}:{entry[1] ? (entry[1].toString().length > 30 ? entry[1].toString().slice(0, 30) + "..." : entry[1]) : "-"}
          //           </p>
          //         </div>
          //       );
          //     })
          //   :
          return (<div className="tooltip-custom h" title={`${Object.keys(row.description.object.definition?.Old).sort().map(entry => entry.toString() + " : " + row.description.object.definition?.Old[entry])}`.split(',').join("\n")}>
            {entries.slice(0, 2).map((entry, index) => {
              return (
                // <p>
                //   {diff.sort().map((entry) => (
                //     <p>{entry.toString()}</p>
                //   ))}
                // </p>
                <p key={index}>
                  {entry[0]}:{entry[1] ? (entry[1].toString().length > 30 ? entry[1].toString().slice(0, 30) + "..." : entry[1]) : "-"}
                </p>
              );
            })}
          </div>)
        }
      },
    },
    {
      name: "New Value",
      // sortable: true,
      cell: (row) => {
        const entries = Object.entries(row.description.object.definition?.New);
        if (row.description.verb.Action === "Edited") {
          const diff = difference(
            row.description.object.definition?.New,
            row.description.object.definition?.Old
          );
          return diff.length !== 0 ? (
            <div
              className="tooltip-custom"
              title={`${diff.sort().map((entry) => entry.toString())}`
                .split(",")
                .join("\n")}
            >
              {/* <span className="tooltip-custom-box">{diff.sort().map(entry => <p>{entry.toString()}</p>)}</span> */}
              {diff.sort().map((entry, index) => {
                return (
                  index < 2 && (
                    <p key={index}>
                      {entry.toString().length > 30
                        ? entry.toString().slice(0, 30) + "..."
                        : entry}
                    </p>
                  )
                );
              })}
              {diff.length > 2 ? "..." : null}
            </div>
          ) : (
            "-"
          );
        } else if (row.description.verb.Action === "Profile Picture Edited") {
          return (
            <p title={row.description.object.definition?.New?.picture_me}>
              {row.description.object.definition?.New?.picture_me.toString()
                .length > 30
                ? "picture_me:" +
                row.description.object.definition?.New?.picture_me
                  .toString()
                  .slice(0, 30) +
                "..."
                : row.description.object.definition?.New?.picture_me}
            </p>
          );
        } else if (row.description.verb.Action !== "Deleted") {
          return (
            <div
              className="tooltip-custom"
              title={`${entries.map(
                (entry) => entry[0] + ":" + (entry[1] ? entry[1] : "-")
              )}`
                .split(",")
                .join("\n")}
            >
              {/* <span className="tooltip-custom-box">{entries.map(entry => <p>{entry[0]}:{entry[1] ? entry[1] : "-"}</p>)}</span> */}
              {entries.map((entry, index) => {
                return (
                  index < 2 && (
                    <p key={index}>
                      {entry[0]}:
                      {entry[1]
                        ? entry[1].toString().length > 30
                          ? entry[1].toString().slice(0, 30) + "..."
                          : entry[1]
                        : "-"}
                    </p>
                  )
                );
              })}
              {entries.length > 2 ? "..." : null}
            </div>
          );
        } else {
          return "-"
        }
      },
    },
    {
      name: "Date",
      sortable: true,
      selector: "Date",
      sortFunction: sortingDate,
      cell: (row) => {
        let today = new Date().toLocaleDateString();
        return row.description.timestamp ? (
          <>
            <p>{moment(row.description.timestamp).format(TABLE_DATE_FORMAT + ",")}</p>
            <p>{moment(row.description.timestamp).format(TABLE_TIME_FORMAT)}</p>
          </>
        ) : (
          "-"
        );
      },
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              onClick={() => logPop(row.description)}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleActionTypeFilter = (e) => {
    const value = e.target.value;
    let arr = actiontype;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setActionType(arr);
    let id = e.target.id;
    setSearchActionTypeCheck({
      ...searchActionTypeCheck,
      [id]: !searchActionTypeCheck[id],
    });
  };

  const handleMonthFilter = (e) => {
    const value = e.target.value;
    let arr = month;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setMonth(arr);
    let id = e.target.id;
    setSearchMonthCheck({ ...searchMonthCheck, [id]: !searchMonthCheck[id] });
  };

  const handleYearFilter = (e) => {
    const value = e.target.value;
    let arr = year;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setYear(arr);
    let id = e.target.id;
    setSearchYearCheck({ ...searchYearCheck, [id]: !searchYearCheck[id] });
  };

  const renderMonthUI = () => {
    let arr = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return arr.map((i, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            id={index}
            value={index}
            onClick={handleMonthFilter}
            checked={searchMonthCheck[index]}
          />
          <label htmlFor={index}>
            <span className="cat">{i}</span>
          </label>
        </li>
      );
    });
  };

  const renderYearUI = () => {
    let arr = ["2022", "2021", "2020"];
    return arr.map((i, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            id={i}
            value={i}
            onClick={handleYearFilter}
            checked={searchYearCheck[i]}
          />
          <label htmlFor={i}>
            <span className="cat">{i}</span>
          </label>
        </li>
      );
    });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = userData;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.id.toString().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });

      let tempUser = allData.filter((item) => {
        let includes = item.description.User.name
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempName = allData.filter((item) => {
        let value = item.description.verb.Action == "Deleted" ?
          item.resource_type == "Features And Benefit" ?
            item.description.object.definition.Old.feature_benefit
            : item.resource_type == "Assign Module" || item.resource_type == "Removed Module" ?
              item.description.object.definition.Old.Portal
              : item.description.object.definition.Old.SAQA_Name
          : item.resource_type == "Features And Benefit" ?
            item.description.object.definition.New.feature_benefit
            : item.resource_type == "Assign Module" || item.resource_type == "Removed Module" ?
              item.description.object.definition.New.Portal
              : item.description.object.definition.New.SAQA_Name
        let includes = value && value.toLowerCase().includes(search.toLowerCase());

        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempUser, ...tempName];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (actiontype.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        const startsWith = actiontype.find((post, index) => {
          if (post.toString() === item.description.verb.Action.toString())
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (month.length) {
      let tempMonth = updatedData;
      let tempResult = tempMonth.filter((item) => {
        const startsWith = month.find((post, index) => {
          let monthNumber = new Date(item.description.timestamp).getMonth();
          if (post.toString() === monthNumber.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (year.length) {
      let tempYear = updatedData;
      let tempResult = tempYear.filter((item) => {
        const startsWith = year.find((post, index) => {
          let yearNumber = new Date(item.description.timestamp).getFullYear();
          if (post.toString() === yearNumber.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    return updatedData;
  };

  const resetFilter = () => {
    setSearch("");
    setActionType([]);
    setSearchActionTypeCheck({});
    setMonth([]);
    setSearchMonthCheck({});
    setYear([]);
    setSearchYearCheck({});
  };

  const oldValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.Old);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(
        row.description.object.definition?.Old,
        row.description.object.definition?.New
      );
      return diff.length !== 0 ? `${diff.sort()}` : "-";
    }
    // else if (row.description.verb.Action === "Profile Picture Edited") {
    //   return  row.description.object.definition?.Old?.picture_me;
    // }
    else {
      return `${entries.map(
        (entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "-"}`
      )}`;
    }
  };

  const NewValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.New);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(
        row.description.object.definition?.New,
        row.description.object.definition?.Old
      );
      return diff.length !== 0 ? `${diff.sort()}` : "-";
    } else if (row.description.verb.Action === "Profile Picture Edited") {
      return row.description.object.definition?.New?.picture_me.toString()
        .length > 30
        ? "picture_me:" + row.description.object.definition?.New?.picture_me
        : row.description.object.definition?.New?.picture_me;
    } else {
      return `${entries.map(
        (entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "-"}`
      )}`;
    }
  };

  const modifiedData = () => {
    let data = dataToRender();

    data = data?.map((row) => ({
      ...row,
      // ID: row?.id,
      User: row.description.User.name,
      "Resource Name": row.description.verb.Action == "Deleted" ?
        row.resource_type == "Features And Benefit" ?
          row.description.object.definition.Old.name
          : row.resource_type == "Assign Module" || row.resource_type == "Removed Module" ?
            row.description.object.definition.Old.Portal
            : row.description.object.definition.Old.SAQA_Name
        : row.resource_type == "Features And Benefit" ?
          row.description.object.definition.New.name
          : row.resource_type == "Assign Module" || row.resource_type == "Removed Module" ?
            row.description.object.definition.New.Portal
            : row.description.object.definition.New.SAQA_Name,
      "Resource Type": row.resource_type ? row.resource_type : "-",
      "Action Type": row.description.verb.Action,
      "Old Value":
        Object.keys(row.description.object.definition.Old).length !== 0
          ? oldValue(row)
          : "-",
      "New Value":
        Object.keys(row.description.object.definition.New).length !== 0
          ? NewValue(row)
          : "-",
      Date: new Date(row.description.timestamp).toLocaleDateString(),
    }));

    return data;
  };

  const exportData = (fileType, fileName) => {
    let data = modifiedData();

    const header = [
      "User",
      "Action Type",
      "Resource Type",
      "Resource Name",
      "Old Value",
      "New Value",
      "Date",
    ];

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      // console.log(csvString);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["User"],
          row["Resource Name"],
          row["Resource Type"],
          row["Action Type"],
          row["Old Value"],
          row["New Value"],
          row["Date"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 15,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom days-overdue-cell-width">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <div className="assessment-table-filter">
                    <div className="btn-type-filter dropdown-comman">
                      <div className="dropdown multiselect">
                        <button
                          className={`btn btn-default dropdown-toggle ${actiontype.length ? "btn-selected" : ""
                            }`}
                          type="button"
                          id="dropdownMenu1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Action Type"
                        >
                          <span>
                            Action Type <i className="fal fa-angle-down"></i>
                          </span>
                        </button>
                        <form className="dropdown-menu remove-list-style">
                          <ul aria-labelledby="dropdownMenu1">
                            <li className="cat cat-skyblue">
                              <input
                                type="checkbox"
                                id="action1"
                                value="Created"
                                onClick={handleActionTypeFilter}
                                checked={searchActionTypeCheck.action1}
                              />
                              <label htmlFor="action1">
                                <span className="cat cat-skyblue">
                                  <i className="fas fa-circle mr-1"></i> Created
                                </span>
                              </label>
                            </li>
                            <li className="cat cat-red">
                              <input
                                type="checkbox"
                                id="action3"
                                value="Deleted"
                                onClick={handleActionTypeFilter}
                                checked={searchActionTypeCheck.action3}
                              />
                              <label htmlFor="action3">
                                <span className="cat cat-red">
                                  <i className="fas fa-circle mr-1"></i> Deleted
                                </span>
                              </label>
                            </li>
                            <li className="cat cat-greenhex">
                              <input
                                type="checkbox"
                                id="action2"
                                value="Edited"
                                onClick={handleActionTypeFilter}
                                checked={searchActionTypeCheck.action2}
                              />
                              <label htmlFor="action2">
                                <span className="cat cat-greenhex">
                                  <i className="fas fa-circle mr-1"></i> Edited
                                </span>
                              </label>
                            </li>

                            {/* <li className="cat cat-yellowshade">
                              <input type="checkbox" id="action4" value="Profile Picture Edited" onClick={handleActionTypeFilter} checked={searchActionTypeCheck.action4} />
                              <label htmlFor="action4">
                                <span className="cat cat-yellowshade">
                                  <i className="fas fa-circle mr-1"></i> Picture Edited
                                </span>
                              </label>
                            </li> */}
                          </ul>
                        </form>
                      </div>
                    </div>
                    </div>

                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${month.length ? "btn-selected" : ""
                              }`}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title="Month"
                          >
                            <span>
                              Month <i className="fal fa-angle-down"></i>
                            </span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">{renderMonthUI()}</ul>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${year.length ? "btn-selected" : ""
                              }`}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title="Year"
                          >
                            <span>
                              Year <i className="fal fa-angle-down"></i>
                            </span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">{renderYearUI()}</ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "PMC Audit Trail");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "PMCAudit Trail");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "PMCAudit Trail");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            data={dataToRender()}
            defaultSortField="Date"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        )}
      </div>
    </div>
  );
};

export default AuditTrail;
