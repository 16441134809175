import React from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import AuditTrailsTable from '../common/AuditTrailsTable';
import { GetCourseAuditTrialCancelToken, GetCourseAuditTrialDropdownCancelToken } from '../../services/CourseService'
import { RenderCourseResourceType } from '../../utils/CommonGroupingItem';

function AuditTrails() {

  const { id } = useParams()

  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]

  const handleRedirect = (id, type = "", otherData = {}) => {

    switch (type) {
      case "assesment":
        return `/courseAdministration/coursesdetails/${otherData?.course_id}/assessments/open/assigned/Details/${id}`
    
      case "Course Gradebook Setting":
        return `/courseAdministration/coursesdetails/${id}/gradeSettings/show`
    
      case "submission":
        return `/courseAdministration/coursesdetails/${otherData?.course_id}/assessments/open/assigned/submissionsAndResults/${id}`
    
      case "notification":
        return `/courseAdministration/coursesdetails/${otherData?.course_id}/notifications/create/${id}`
    
      case "assemblies":
        return `/courseAdministration/coursesdetails/${id}/assemblies/show`
    
      case "onlineclass":
        return `/courseAdministration/coursesdetails/${id}/onlineClasses/show`
    
      default:
        return `/courseAdministration/coursesdetails/${id}/detailCourses/show`
    }
  }

  return (
    <AuditTrailsTable
      apiFunction={GetCourseAuditTrialCancelToken}
      apiParams={{parent_action_id: id}}
      columnsToShow={columnsToShow}
      filterArrApi={GetCourseAuditTrialDropdownCancelToken}
      actionId={id}
      exportFileName={"Course_auditTrail"}
      resourceNameRedirect={handleRedirect}
      isResourceNameShown={true}
      isShowingResourceTypeFilter
      renderResourceTypeFunction={RenderCourseResourceType}
    />
  )
}

export default AuditTrails