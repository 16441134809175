import axiosInstance from "../utils/axios";


export const GetPostFeedMessages=async (values) => {
    return await axiosInstance().get(`/getPostFeedMessages`, { params: values });
  }


  export const DownloadFileFeedService = async (values) => {
    return await axiosInstance().get("/download", {
      params: {
        url: values,
      },
      responseType: "blob",
    });
  };


  export const GetCommentMessages=async (values) => {
    return await axiosInstance().get(`/getCommentMessages`, { params: values });
  }
  export const GetSubjectContentService = async (values) => {
    return await axiosInstance().get("/getSubjectContent", { params: values });
  };
  export const GetPostFeedMessagesPaginate=async (values, page, user_id, limit=10) => {
    return await axiosInstance().get(`/getPostFeedMessagesPaginate?page=${page}&limit=${limit}&user_id=${user_id}&needOnlineClass=1`, { params: values });
  }
  export const GetSupportTeamService = async (values) => {
    return await axiosInstance().get("/getSupportTeam", { params: values });
  };