import React, { useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { FORM_DATE_FORMAT, FORM_TIME_FORMAT, TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import $ from 'jquery'
import { GetIntakeListCancelToken, getIntakeList, insertbulkNotification } from "../../../services/EmailServices";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import { RenderLearningMethod, RenderSchool } from "../../../utils/CommonGroupingItem";
import { GetCommonContentReReg } from "../../../services/ProgrammeServices";
import Str from "../../common/Str";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import HtmlParser from "react-html-parser";
import Tablefilter from "../../common/Tablefilter";
import { TrimText } from "../../common/TrimText";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";

function AddBulkNotification() {

  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [attachmentName, setAttachmentName] = useState(false);
  const [attachmentFilePreview, setAttachmentFilePreview] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [intakes, setIntakes] = useState([])
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [studyTypeArr, setStudyTypeArr] = useState([]);
  const [intakeArr, setIntakeArr] = useState([]);
  const [schoolFilterArr, setSchoolFilterArr] = useState([]);
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(search);
  const [school, setSchool] = useState({ arr: [], checkObj: {} });
  const [loading, setloading] = useState(true);
  const [notificationType, setNotificationType] = useState("qualification");
  const [sortkey, setSortKey] = useState(notificationType == "qualification" ? "name" : "intake");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [checkedAllRows, setCheckedAllRows] = useState([]);
  const [isAllSelectChecked, setIsAllSelectChecked] = useState(false);
  const [tempCheckedAllData, setTempCheckedAllData] = useState([]);
  const [addNotifications, setAddNotifications] = useState([]);
  const [checkedAllStudent, setCheckedAllStudent] = useState(false)
  const [cancelTokenSources, setCancelTokenSources] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 800);
    return () => {
        clearTimeout(timer);
    }
}, [debouncedTerm])

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  const getIntake = async () => {
    setloading(true)

    cancelTokenSources.forEach(source => {
      source.cancel('New request made');
    });

    const source = axios.CancelToken.source();
    setCancelTokenSources([...cancelTokenSources, source]);

    const data = {
      viaSearch: search,
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      src_restrict: "",
      src_type: "intakes_list_avaiable_notifications",
      type: notificationType,
      school_type: school.arr,
      type_src: learningMethod.arr,
      intake: intake.arr,
    }

    try {
      const res = await GetIntakeListCancelToken(data, source.token);
      let tempIntake = res?.data?.result
      let tempIntakeIds = tempIntake.filter((temp)=>!checkedAllRows.includes(temp.id))
      setIsAllSelectChecked(tempIntakeIds.length ? false : true)
      setIntakes(tempIntake);
      setTotalRows(res?.data?.pagination?.total);
      if (res.status == 200) {
        setloading(false);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
        setloading(false);
      }
    }
  }

  useEffect(() => {
    let response = hasPermission({ scopes: ["bnbnadd"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
    getIntake()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [
    page,
    perPage,
    sortOrder,
    sortkey,
    notificationType,
    intake,
    school,
    learningMethod,
    search
  ]);
  
  useEffect(() => {
    GetCommonContentReReg()
      .then((res) => {
        let tempIntakeArr = [];
        res.data.intake_year && res.data.intake_year.length && res.data.intake_number && res.data.intake_number.length && res.data.intake_year.map(year => {
          res.data.intake_number.map(number => {
            tempIntakeArr.push({ label: `${year.label}-${number.label}`, value: `${year.value}/${number.value}` })
          })
        })
        setIntakeArr(tempIntakeArr)
        setStudyTypeArr(res?.data?.study_type)
        setSchoolFilterArr(res?.data?.school_type)
      })
  }, [])


  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Name: "",
      IncludeAccountPayer: "",
      Important: "",
      sentGroups:{
        EmailNotification: false,
        PushNotification: false,
      },
      SendWhen: 0,
      notification_type: 0,
      SendWhenDate: "",
      IsDraft: "",
      Title: "",
      Content: "",
      FileAttachment: "",
      regards: [],
    },
    validationSchema: Yup.object({
      Title: Yup.string().trim("Name cannot include leading and trailing spaces").required("Title is required"),
      textBody: Yup.string().required("Content is required"),
      SendWhenDate: Yup.string()
        .when("SendWhen", {
          is: 2,
          then: Yup.string().required("Date And Time is required"),
        }),

      // sentGroups: Yup.string().test("required","At least one needs to be checked EmailNotification or PushNotification", function (values) {
      //  if(checkedAllStudent || ((!checkedAllStudent && (formik.values.sentGroups.EmailNotification || formik.values.sentGroups.PushNotification)))){
      //   return true
      //  }
      //  return false
      // })
      sentGroups: Yup.object().shape(
        {
          PushNotification: Yup.bool().when("EmailNotification", {
            is: (EmailNotification) => !EmailNotification,
            then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification")
          }),
          EmailNotification: Yup.bool().when("PushNotification", {
            is: (PushNotification) => !PushNotification,
            then: Yup.bool().oneOf([true], "At least one needs to be checked EmailNotification or PushNotification")
          })
        },
        [
          ["PushNotification", "EmailNotification"],
          ["EmailNotification", "PushNotification"]
        ]
      )

    }),
    onSubmit: async (values, { resetForm }) => {
      setDisabled(true);
      let tempFullStartDate = new Date(values.SendWhenDate);
      // 2022-06-16 10:50
      let tempStart =
        tempFullStartDate.getFullYear() +
        "-" +
        (tempFullStartDate.getMonth() + 1) +
        "-" +
        tempFullStartDate.getDate() +
        " " +
        tempFullStartDate.getHours() +
        ":" +
        tempFullStartDate.getMonth();
    
      // if (values.IsDraft == 0) {
        if (addNotifications.length == 0 && !checkedAllStudent) { 
          await formik.setFieldValue("isDraft", 0)
          setDisabled(false)
          await formik.setErrors({
            regards: "Please Select Atleast one Notification to value",
          });
          return;
        }
      // }

      let formData = new FormData();
      formData.append("ussub", values?.Title);
      formData.append("usmsg", values?.textBody);
      values.SendWhen == 2 && formData.append("publish_date", tempStart);
      formData.append("isnew", 1);
      formData.append("publish", values?.SendWhen == false ? 0 : 1);
      formData.append("isPushnotification", values?.sentGroups.PushNotification ? 1 : 0);
      formData.append("isEmail", values?.sentGroups.EmailNotification ? 1 : 0);
      formData.append("isDraft", values.IsDraft);
      formData.append("attachment", values?.FileAttachment);
      formData.append("isPayer", values?.IncludeAccountPayer ? 1 : 0);
      formData.append("h_importance", values?.Important ? 1 : 0);
      formData.append("regards", checkedAllStudent ? JSON.stringify([{id: "-1"},]) : JSON.stringify(addNotifications?.map((item) => {
        if(notificationType == "qualification") {
          return { id: `qualification-${item?.id}` }
        } else {
          return { id: `${item?.number}_${item?.year}_${item?.type}`}
        }
        
      })));
      formData.append("ref", "new_notification_preview");
      insertbulkNotification(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added successfully",
          });
          setDisabled(false);
          formik.setFieldValue("isDraft", 0) 
          history.push(
            `/notification/bulknotification/bulk/table`
          );
        })
        .catch((err) => {
          setDisabled(false);
          formik.setFieldValue("isDraft", 0) 
          console.log("error :", err)
        });
    },
  });

  function showCourses(courses) {
    // getIntake()
    $("#viewAvailCourses").modal('show');
  }

  function closeCourses() {
    $("#viewAvailCourses").modal('hide');
  }

  const handleSelect = (e, row) => {
    const { checked, id, name, value } = e.target;
    const ls = [...checkedAllRows];
    const lsData = [...tempCheckedAllData];
    if (checked) {
      ls.push(Number(id));
      if (notificationType === "qualification") {
        lsData.push(row);
        // lsData.push({ data: `qualification-${row?.id}`, name: row?.name, id: row?.id });
      } else {
        lsData.push(row);
        // lsData.push({ data: `${row?.number}_${row?.year}_${row?.type}`, name: row?.name, number: row?.number, year: row?.year, type: row?.type, id: row?.id });
      }
    } else {
      const index = ls.indexOf(Number(id));
      ls.splice(index, 1);
      if (notificationType === "qualification") {
        const indexData = lsData.indexOf((item) => item?.id == row?.id);
        // const indexData = lsData.indexOf((item) => item?.id === `qualification-${row?.id}`);
        lsData.splice(indexData, 1);
      } else {
        const indexData = lsData.indexOf((item) => item?.id == row.id);
        // const indexData = lsData.indexOf((item) => item?.id === `${row?.number}_${row?.year}_${row?.type}`);
        lsData.splice(indexData, 1);
      }
    }
    setCheckedAllRows([...ls]);
    setTempCheckedAllData([...lsData]);
    let allChecked = intakes.map((item) => ls.includes(item?.id)).filter(item => item)
   
    if(allChecked.length == perPage) {
      setIsAllSelectChecked(true)
    } else {
      setIsAllSelectChecked(false)
    }
  }

  const handleAllSelect = async (e) => {
    let checked = e.target?.checked
    let intakeIds = intakes.map(item => item.id)
    if (checked) {
      setCheckedAllRows(prevState => [...prevState, ...intakeIds])
      let completeArr = intakes.filter(item => intakeIds.includes(item.id))
      setTempCheckedAllData((prevState) => [...prevState, ...completeArr])
    } else {
      let arr = checkedAllRows.filter(item => !intakeIds.includes(item))
      setCheckedAllRows(prevState => [...arr])
      let completeArr = tempCheckedAllData.filter(item => !arr.includes(item.id))
      setTempCheckedAllData(completeArr)
    }
    setIsAllSelectChecked(checked)
  }


  const intakeColumns = useMemo(() => [
    {
      name: <input type={"checkbox"} name="allSelect" onChange={handleAllSelect} checked={isAllSelectChecked} />,
      cell: (row, ind) => {

        return <input
          type={"checkbox"}
          name="select"
          id={row?.id}
          onChange={(e) => handleSelect(e, row)}
          checked={checkedAllRows.includes(row?.id)}
        />
      }
    },
    {
      name: "Intake",
      selector: "intake",
      sortField: "intake",
      sortable: true,
      cell: (row) => <>{row?.year ? row?.year + "-" + row?.number : "-"}</>,
    },
    {
      name: "Learning Method",
      selector: "type",
      sortField: "type",
      sortable: true,
      cell: (row) => <p>{row.type ? RenderLearningMethod(row?.type).html : "-"}</p>,
    },
    {
      name: "School Type",
      selector: "school_type",
      sortField: "school_type",
      sortable: true,
      cell: (row) => <p>{row?.school_type ? (RenderSchool(row?.school_type).html) : "-"}</p>,
    },
  ]);

  const columns = useMemo(() => [
    {
      name: <input type={"checkbox"} name="allSelect" onChange={handleAllSelect} checked={isAllSelectChecked} />,
      cell: (row, ind) => {

        return <input
          type={"checkbox"}
          name="select"
          id={row?.id}
          onChange={(e) => handleSelect(e, row)}
          checked={checkedAllRows.includes(row?.id)}
        />
      }
    },
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) =>
        row.name ? (
          <p className="as-text-blue overflow-ellipsis2" title={row?.name}>
            {row?.name}
          </p>
        ) : (
          "-"
        ),
    },
    {
      name: "Intake",
      selector: "intake",
      sortField: "intake",
      sortable: true,
      cell: (row) => <>{row?.year ? row?.year + "-" + row?.number : "-"}</>,
    },
    {
      name: "Learning Method",
      selector: "type",
      sortField: "type",
      sortable: true,
      cell: (row) => <p>{row.type ? RenderLearningMethod(row?.type).html : "-"}</p>,
    },
    {
      name: "School Type",
      selector: "school_type",
      sortField: "school_type",
      sortable: true,
      cell: (row) => <p>{row?.school_type ? (RenderSchool(row?.school_type).html) : "-"}</p>,
    },
  ]);

  const handleSort = (column, sortDirection) => {    
    setSortKey(column?.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };
  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const addMembers = () => {
    let uniqueCheckedAlldata = [...new Set(tempCheckedAllData)]
    let uniqueCheckedAllRows = [...new Set(checkedAllRows)]
    setCheckedAllRows(uniqueCheckedAllRows)
    setTempCheckedAllData(uniqueCheckedAlldata)
    setAddNotifications(uniqueCheckedAlldata);
    closeCourses();
  }

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
 
  const resetFilter = () => {
    setSearch("");
    setDebouncedTerm("");
    setIntake({ arr: [], checkObj: {} })
    setSchool({ arr: [], checkObj: {} });
    setCheckedAllRows([]);
    setTempCheckedAllData([]);
    setLearningMethod({ arr: [], checkObj: {} });

  };

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      // use the selector function to resolve your field names by passing the sort comparitors
      const aField = rowA[selector]?.toString().toLowerCase()
      const bField = rowB[selector]?.toString()?.toLowerCase()

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }

      return direction === 'desc' ? comparison * -1 : comparison;
    });
  };

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <label htmlFor="Title">Title *</label>
            <div className="form-icon-group mb-4" title="Title">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.Title && formik.touched.Title
                    ? " is-invalid"
                    : "")
                }
                name="Title"
                id="Title"
                placeholder="Title"
                title="Title"
                value={formik.values.Title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group-blk mb-3">
              <label></label>
              <div className="custom-check custom-control custom-checkbox mt-3">
                <input
                  type="checkbox"
                  title="Include Account Payer"
                  className="custom-control-input"
                  id="IncludeAccountPayer"
                  name="select"
                  checked={formik.values.IncludeAccountPayer}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "IncludeAccountPayer",
                      e.target.checked
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="IncludeAccountPayer"
                >
                  Include Account Payer
                </label>
              </div>

            </div>

          </div>
          <div className="col-md-4">
            <div className="form-group-blk mb-3">
              <label></label>
              <div className="custom-check custom-control custom-checkbox mt-3">
                <input
                  type="checkbox"
                  title="Important"
                  className="custom-control-input"
                  id="Important"
                  name="select"
                  checked={formik.values.Important}
                  onChange={(e) =>
                    formik.setFieldValue("Important", e.target.checked)
                  }
                />
                <label className="custom-control-label" htmlFor="Important">
                  Important
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">

          {!checkedAllStudent && <div className="col-md-4">
            <label>Send Via *</label>
            <div className="form-group-blk mb-3">
              <label></label>
              <div className="custom-check custom-control custom-checkbox custom-control-inline mt-3">
                <input
                  type="checkbox"
                  title="Email"
                  className={`custom-control-input ${checkedAllStudent ? "cursor-arrow" : ""}`}
                  id="EmailNotification"
                  name="select"
                  checked={formik.values.sentGroups.EmailNotification || checkedAllStudent}
                  disabled={checkedAllStudent}
                  onChange={(e) =>
                    formik.setFieldValue("sentGroups.EmailNotification", e.target.checked)
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="EmailNotification"
                >
                  Email
                </label>
              </div>
              <div className="custom-check custom-control custom-checkbox custom-control-inline mt-3">
                <input
                  type="checkbox"
                  title="Push Notification"
                  className={`custom-control-input ${checkedAllStudent ? "cursor-arrow" : ""}`}
                  id="PushNotification"
                  name="select"
                  checked={formik.values.sentGroups.PushNotification}
                  disabled={checkedAllStudent}
                  onChange={(e) =>
                    formik.setFieldValue("sentGroups.PushNotification", e.target.checked)
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="PushNotification"
                >
                  Push Notification
                </label>
              </div>
            </div>
          </div>}
          <div className="col-md-4">
            <label title="SendWhen">Send When *</label>
            <div className="form-icon-group" title="SendWhen">
              <div className="custon_rd_new">
                <div
                  className="custom-control custom-radio custom-control-inline"
                  title="Now"
                >
                  <input
                    type="radio"
                    id="customRadioInline1"
                    name="customRadioInline"
                    className="custom-control-input"
                    checked={formik.values.SendWhen == 0}
                    onChange={() => formik.setFieldValue("SendWhen", 0)}
                    onBlur={formik.handleBlur}
                  />
                  <label className="custom-control-label" htmlFor="customRadioInline1">
                    Now
                  </label>
                </div>
                <div
                  className="custom-control custom-radio custom-control-inline"
                  title="Later"
                >
                  <input
                    type="radio"
                    id="customRadioInline2"
                    name="customRadioInline"
                    className="custom-control-input"
                    checked={!formik.values.SendWhen == 0}
                    onChange={() => formik.setFieldValue("SendWhen", 2)}
                    onBlur={formik.handleBlur}
                  />
                  <label className="custom-control-label" htmlFor="customRadioInline2">
                    Later
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group-blk mb-3">
              <label>Upload Attachment</label>
              <div className="form-group atttach-file m-0">
                <label
                  className={
                    formik.errors.FileAttachment &&
                    formik.touched.FileAttachment &&
                    "file-req is-invalid"
                  }
                >
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <input
                    type="file"
                    accept={"image/*, .docx, .doc, .xlsx, .pdf, .zip"}
                    className="form-control  form-control-aatch-file"
                    onChange={(e) => {
                      if (e.target.files.length) {
                        formik.setFieldValue("FileAttachment", e.target.files[0]);
                        setAttachmentName(e.target.files[0].name);
                        setAttachmentFilePreview(URL.createObjectURL(e.target.files[0]));
                      }
                    }}
                  />
                </label>
              </div>
              {attachmentName && (
                <>
                  <div className="frm-group">
                    <ul className="list-unstyled attached-file-ul m-0">
                      <li>
                        <a
                          href={attachmentFilePreview}
                          title={attachmentName}
                          target="_blank">
                          <i className="fal fa-file"></i>
                          &nbsp;{attachmentName}
                        </a>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            setAttachmentFilePreview("");
                            setAttachmentName(false);
                          }}
                        ><i className="fal fa-trash-alt"></i></button>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12" title="Notification To">
            <div className="form-group-blk mb-3">
              <label>Notification To</label>
              <div className="custon_rd_new">
                <div
                  className="custom-control custom-radio custom-control-inline"
                  title="Programme/Courses"
                >
                  <input
                    type="radio"
                    id="Programme_Courses"
                    name="Programme_Courses"
                    className="custom-control-input"
                    checked={formik.values.notification_type == 0}
                    onChange={() => {
                      formik.setFieldValue("notification_type", 0);
                      setSortKey("name")
                      setNotificationType("qualification")
                      setAddNotifications([])
                      setCheckedAllStudent(false)
                      formik.setFieldValue("sentGroups.EmailNotification", false)
                      formik.setFieldValue("sentGroups.PushNotification", false)
                      resetFilter();
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <label className="custom-control-label" htmlFor="Programme_Courses">
                    Programme/Courses
                  </label>
                </div>
                <div
                  className="custom-control custom-radio custom-control-inline"
                  title="Intake"
                >
                  <input
                    type="radio"
                    id="Intake"
                    name="Intake"
                    className="custom-control-input"
                    checked={formik.values.notification_type == 2}
                    onChange={() => {
                      formik.setFieldValue("notification_type", 2);
                      setNotificationType("intake")
                      setSortKey("intake")
                      setAddNotifications([]) 
                      setCheckedAllStudent(false) 
                      formik.setFieldValue("sentGroups.EmailNotification", false)
                      formik.setFieldValue("sentGroups.PushNotification", false)
                      resetFilter()
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <label className="custom-control-label" htmlFor="Intake">
                  Intake
                  </label>
                </div>
                <div
                  className="custom-control custom-radio custom-control-inline"
                  title="All Active Students"
                >
                  <input
                    type="radio"
                    id="active_students"
                    name="AllActiveStudents"
                    className="custom-control-input"
                    checked={checkedAllStudent}
                    onChange={() => {
                      setCheckedAllStudent(true)
                      formik.setFieldValue("notification_type", 3)
                      formik.setFieldValue("sentGroups.EmailNotification", true)
                      formik.setFieldValue("sentGroups.PushNotification", false)
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <label className="custom-control-label" htmlFor="active_students">
                  All Active Students
                  </label>
                </div>
              </div>
              {!checkedAllStudent && 
              <>
              <div className="form-group atttach-file m-0 mb-2">
                <label
                >
                  <i className="fal fa-poll-people"></i>
                  <span title="Choose">Choose</span>
                  <input
                    // type="file"
                    className="form-control  form-control-aatch-file"
                    onClick={() => {
                        showCourses();
                    }}
                  />
                </label>
              </div>
              <div>
                {
                  addNotifications && addNotifications.map((item, index) => {
                    return (
                      <>
                      {formik.values.notification_type == 0 ? <p className="mb-0">
                        {index + 1}{". "}{item?.name}
                      </p> : 
                      <p className="mb-0">{index + 1}{". "}{`${item.number}-${item.year}, ${RenderLearningMethod(item.type).text}`}</p>
                      }
                      </>
                    )
                  })
                }
              </div>
              </>
              }
            </div>
          </div>
          {!formik.values.SendWhen == 0 ? (
            <div className="col-md-4">
              <label>Date *</label>
              <div className="form-group-blk mb-3">
                <DatePicker
                  selected={formik.values.SendWhenDate}
                  onChange={(date) => {
                    formik.setFieldValue("SendWhenDate", date);
                  }}
                  // showTimeSelect
                  filterTime={filterPassedTime}
                  dateFormat={FORM_DATE_FORMAT}
                  timeFormat={FORM_TIME_FORMAT}
                  // className="form-control"
                  className={
                    "form-control cursor-pointer" +
                    (formik.errors.SendWhenDate && formik.touched.SendWhenDate
                      ? " is-invalid"
                      : "")
                  }
                  title="Date"
                  onBlur={formik.handleBlur}
                  placeholderText="Enter Date"
                  minDate={new Date()}
                  showYearDropdown
                  showTimeSelect
                  scrollableYearDropdown
                />
              </div>
              {formik.errors.SendWhenDate && formik.touched.SendWhenDate ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          ) : (
            ""
          )}
          <div className="col-md-12">
            <label>Content *</label>
            <div className="form-icon-group mb-4 d-block">
              <HtmlInputEditor
                name="textBody"
                isCKEditor={true}
                editorState={formik.values.textBody}
                hideSign={true}
                belowNoMargin={true}
                setEditorState={(val) => { formik.setFieldValue("textBody", val) }}
                isInvalid={formik.touched.textBody && formik.errors.textBody}
              />
            </div>
          </div>
        </div>
        <div className="form-group form-group-save-cancel">
          <button
            className="btn btn-save btn-success"
            type="submit"
            title="Save Draft"
            onClick={() => formik.setFieldValue("IsDraft", 1)}
          >
            {disabled && formik.values.IsDraft == 1 ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-save"></i>
            )}
            Save Draft
          </button>
          <button
            className="btn btn-save btn-success"
            type="submit"
            title="Send"
            onClick={() => formik.setFieldValue("IsDraft", 0)}
          >
            {disabled && formik.values.IsDraft == 0 ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fas fa-location-arrow"></i>
            )}
            Send
          </button>
          <button
            className="btn btn-save btn-success"
            type="button"
            title="Preview"
            data-toggle="modal" data-target="#notifications"
          >
              <i className="fal fa-eye"></i> Preview
          </button>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => history.goBack()}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>
          {Object.keys(formik.values).map(key => {
            if (formik.touched[key] && formik.errors[key]) {
              if(key=="sentGroups"){
                return (
                  <div className="invalid-feedback d-block">
                   {formik.errors[key].EmailNotification}
                  </div>
                )
              }
              return (
                <div className="invalid-feedback d-block">
                  {formik.errors[key]}
                </div>
              )
            }
          })}
          {/* {console.log(formik.touched?.sentGroups?.EmailNotification)}
          {formik.touched?.sentGroups?.EmailNotification && formik.errors?.sentGroups?.EmailNotification ? <div className="invalid-feedback d-block">{formik.errors?.sentGroups?.EmailNotification}</div> : null} */}
        </div>
      </form>
      {/* add assigned intake modal start */}
      <div className="topic-add-modal modal fade" id="viewAvailCourses" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom border-0">
                <h5 className="modal-title"><i className="fal fa-plus"></i> Notification To</h5>
                <button type="button" data-dismiss="modal" className="close" title="Close" 
                ><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
              </div>
              <div className="my-tickets-info-list Tickets-main-wrap">
                <div className="p-30px l-o-c-t custom-table-div filter-search-icon card card-table-custom bulk-notification-table">
                  <div className="search-filter-div">
                    <div className="search-filter-div-left">
                      <div className="system-administration-table table-responsive">
                        <div className="table-responsive-div">
                          <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                            <div id="assessment-table-main_filter" className="dataTables_filter">
                              <label>
                                <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main"onChange={e => setDebouncedTerm(e.target.value)} value={debouncedTerm} />
                              </label>
                              <div className="filter-eff filter-data-btn">
                                <button className="filter-buttons">
                                  <i className="fal fa-filter"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="filter-button-group">
                          <div className="filter-scroll">
                            <div className={`filter-scroll-inner filter-custom-new`}>
                              <Tablefilter
                                filterName="Learning Method"
                                optionArr={studyTypeArr}
                                state={learningMethod}
                                setState={setLearningMethod}
                                renderLabelFunction={RenderLearningMethod}
                              />
                              <Tablefilter
                                filterName="Intake"
                                optionArr={intakeArr}
                                state={intake}
                                setState={setIntake}
                                isOptionReversed
                              />
                              <Tablefilter
                                filterName="School"
                                optionArr={schoolFilterArr}
                                state={school}
                                setState={setSchool}
                                renderLabelFunction={RenderSchool}
                              />
                              {/* <div className="assessment-table-filter">
                                <div className="btn-type-filter dropdown-comman">
                                  <div className="dropdown multiselect">
                                    <button
                                      className={`btn btn-default dropdown-toggle ${notificationType.length ? "btn-selected" : ""}`}
                                      type="button"
                                      id="dropdownMenu15"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      title="Type"
                                    >
                                      <span>
                                        Type <i className="fal fa-angle-down"></i>
                                      </span>
                                    </button>
                                    <form className="dropdown-menu remove-list-style">
                                      <ul aria-labelledby="dropdownMenu15">
                                        <li title="Qualification">
                                          <input type="checkbox" id="type1" value="qualification" onChange={handleTypeFilter} checked={notificationType === "qualification" ? true : false} />
                                          <label htmlFor="type1">
                                            Qualification
                                          </label>
                                        </li>
                                        <li title="Intake">
                                          <input type="checkbox" id="type2" value="intake" onChange={handleTypeFilter} checked={notificationType === "intake" ? true : false} />
                                          <label htmlFor="type2">
                                            Intake
                                          </label>
                                        </li>
                                      </ul>
                                    </form>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div className="reset-btn-group">
                            <div className="button-reset dropdown-comman">
                              <button className="btn btn-primary" title="Reset" onClick={resetFilter}>
                                <i className="fal fa-redo"></i>Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                     {notificationType == "qualification" && <DataTable
                        data={intakes}
                        progressPending={loading}
                        progressComponent={<SkeletonTicketList />}
                        defaultSortField={"name"}
                        defaultSortAsc={false}
                        columns={columns}
                        pagination={true}
                        noDataComponent={Str.noRecord}
                        onSort={handleSort}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        highlightOnHover={false}
                        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                        // sortFunction={customSort}
                        sortServer
                      />}
                      {notificationType == "intake" && <DataTable
                        data={intakes}
                        progressPending={loading}
                        progressComponent={<SkeletonTicketList />}
                        defaultSortField={"intake"}
                        defaultSortAsc={false}
                        columns={intakeColumns}
                        pagination={true}
                        noDataComponent={Str.noRecord}
                        onSort={handleSort}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        highlightOnHover={false}
                        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                        // sortFunction={customSort}
                        sortServer
                      />}
                  
                  <div className="btn-sec">
                    <button
                      className="btn btn-primary"
                      type="button"
                      title="Add"
                      onClick={() => {
                        // addIds();
                        addMembers();
                      }}
                    >
                      <i className="fal fa-plus"></i>Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* add assigned intake modal ends */}

      {/* show preview modal start */}
      <div className="topic-add-modal modal fade" id="notifications" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content modal-border-update">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fas fa-bars"></i> Notification Details
                </h5>
                <button type="button" data-dismiss="modal" className="close" title="Close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
              </div>
              <div className="p-30px topic-add-modal-content">
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="form-group-blk mb-3">
                      <div className="basic-info-box-list">
                        <ul className="p-0 course-name-merge">
                          <li>
                            <span className="title">Subject/Title:&nbsp;</span>
                            <span className="word-break-all">{formik.values.Title}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="basic-info-box-list">
                      <ul className="p-0 course-name-merge">
                        <li>
                          <span className="title">Upload Attachment:&nbsp;</span>
                          {attachmentName ?
                            <a href={attachmentFilePreview} target="_blank">
                              <i className="fal fa-file"></i> &nbsp;<span title={attachmentName}>{TrimText(attachmentName,15)}</span>
                            </a> : "N/A"
                          }
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="basic-info-box-list">
                      <ul className="p-0 course-name-merge">
                        <li>
                          <span className="title">Content:&nbsp;</span>
                          <span className="word-break-all">
                            {HtmlParser(formik?.values?.textBody)}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-3">
                  <button type="button" className="btn btn-danger" data-dismiss="modal" title="Close">
                    <i className="fal fa-times"></i>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* show preview modal end */}
    </div>
  );
}

export default AddBulkNotification;
