import React from "react";

function GradeBookSaveBar({ handleClick, disabled = false, saveGradesLoading }) {
  return (
    <div className="sticky-save-footer">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <h4 className="landing-heading heading_color_dynamic mr-2">
            Save <span>Grades</span>
          </h4>
          <span>
            You have made Changes, Please save your work to update Final Grades
          </span>
        </div>
        <button
          className="btn btn-save btn-success ml-auto"
          onClick={handleClick}
          title="Save Grades and Calculate Final Totals"
          disabled={disabled}
        >
          {saveGradesLoading ? (
            <i className="fas fa-cog fa-spin"></i>
          ) : (
            <i className="fal fa-save"></i>
          )}
          Save Grades and Calculate Final Totals
        </button>
      </div>
    </div>
  );
}

export default GradeBookSaveBar;
