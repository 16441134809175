import React, { cloneElement } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const hasPermission = ({ permissions, scopes }) => {
    const scopesMap = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });
    return permissions.some((permission) => scopesMap[permission]);
};

export default function PermissionsGate({
    children,
    RenderError = () => <></>,
    errorProps = null,
    scopes = [],
    routeCheck = false
}) {
    const history = useHistory();
    const givenPermsisions = useSelector((state) => state.givenPermission);
    const permissions = [];
    Object.keys(givenPermsisions).map(function (key, index) {
        permissions.push(givenPermsisions[key]);
    });
    const permissionGranted = hasPermission({ permissions, scopes });
    if (routeCheck && !permissionGranted && !errorProps) { history.push('/noaccess'); }
    if (!permissionGranted && !errorProps) return <RenderError />;
    if (!permissionGranted && errorProps) return cloneElement(children, { ...errorProps });

    return <>{children}</>;
}