import React from "react";
import Skeleton from "react-loading-skeleton";
import { Scrollbar } from "react-scrollbars-custom";

export default function SkeletonTicketList() {
  return (
    <React.Fragment>
      <div className="Tickets-panel">
        <div className="Tickets-panel-head d-flex justify-content-between align-items-center">
          {/* <ul className="d-flex list-unstyled m-0 read-unread-tab-pill nav nav-tabs border-0">
            <li>
              <a className="active" data-toggle="pill" href="#all">
                All
              </a>
            </li>
            <li>
              <a data-toggle="pill" href="#Pending-Student">
                Pending Student
              </a>
            </li>
            <li>
              <a data-toggle="pill" href="#Pending-Student">
                Pending Academy
              </a>
            </li>
          </ul> */}
        </div>
        <div className="Tickets-body">
          <Scrollbar>
            <div className="tab-content">
              <div className="tab-pane active" id="all">
                <ul className="Tickets-list-ul list-unstyled m-0">
                  {Array(10)
                    .fill()
                    .map((item, index) => (
                      <li key={index}>
                        <Skeleton height={35} />
                        {/* <Skeleton width={350} height={150} /> */}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="tab-pane  fade" id="Pending-Student"></div>
              <div className="tab-pane  fade" id="Pending-Academy"></div>
            </div>
          </Scrollbar>
        </div>
      </div>
    </React.Fragment>
  );
}
