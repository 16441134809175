import React, { useMemo } from 'react';
import { IMAGE_URL } from '../../../utils/Constants';
import SkeletonTicketList from '../../../loaders/SkeletonTicketList';
import { RenderUserRole } from '../../../utils/CommonGroupingItem';
import DataTable from 'react-data-table-component';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { ColorRender } from '../../systemadministration/usermanagement/CheckRole';
import { InitialRender } from '../../common/Helper';
import Hover from '../../common/Hover';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { UpdateBookingStatus } from '../../../services/BookingServices';
import Swal from 'sweetalert2';

export function BookingConfirmation({
  booking_status,
  booking_logs,
  additional_info,
  onChange,
  onStatusChanged
}) {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const { id } = useParams();
  const { user_id, role_name } = user;


  const formik = useFormik({
    initialValues: {
      approve: booking_status === 'APPROVED' ? 'approve' : '',
      decline: booking_status === 'REJECTED' ? 'decline' : '',
      pending: booking_status === 'PENDING APPROVAL' ? 'pending' : '',
      user_id,
      user_role: role_name,
      additional_info: additional_info || '',
    },
    onSubmit: (values) => handleConfirmationSubmit(values),
  });

  const displayLogs = booking_logs;

  const handleConfirmationSubmit = async (values) => {
    try {
      const { approve, decline, pending, user_id, additional_info } = values;

      // console.log('approve', approve);
      // console.log('decline', decline);
      // console.log('pending', pending);


      const formData = new FormData();
      formData.append(
        'booking_status',
        approve === 'approve'
          ? 'APPROVED'
          : decline === 'decline'
          ? 'REJECTED'
          : 'PENDING APPROVAL'
      );
      formData.append('id', id);
      formData.append('user_id', user_id);
      formData.append('user_role', role_name);
      formData.append('additional_information', additional_info);

      await UpdateBookingStatus(formData);
      Swal.fire({
        title: 'Success',
        text: `Booking status changed`,
        icon: 'success',
      });
      Swal.fire({
        title: 'Success',
        text: `${
          approve
            ? 'Booking status Approved'
            : decline
            ? 'Booking status Declined'
            : 'Booking status Pending'
        }`,
        icon: 'success',
      });
      approve
        ? onChange(approve, additional_info)
        : decline
        ? onChange(decline, additional_info)
        : onChange(pending, additional_info);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Booking status cannot be updated',
        icon: 'error',
      });
    }
  };

  // const handleApprovalRequest = () => {
  //   formik.setFieldValue('approve', !formik.values.approve);
  //   formik.setFieldValue('decline', formik.values.approve);
  // };

  // const handleDeclineRequest = () => {
  //   formik.setFieldValue('decline', !formik.values.decline);
  //   formik.setFieldValue('approve', formik.values.decline);
  // };

  const handleApprovalRequest = (e) => {
    const request_type = e.target.value;

    if (request_type === 'approve') {
      formik.setFieldValue('approve', 'approve');
      formik.setFieldValue('decline', '');
      formik.setFieldValue('pending', '');
    } else if (request_type === 'decline') {
      formik.setFieldValue('decline', 'decline');
      formik.setFieldValue('approve', '');
      formik.setFieldValue('pending', '');
    } else if (request_type === 'pending'){
      formik.setFieldValue('approve', '');
      formik.setFieldValue('decline', '');
      formik.setFieldValue('pending', 'pending');
    }
    onStatusChanged(request_type);
  };

  const columns = useMemo(
    () => [
      {
        name: 'Date',
        selector: 'Date',
        sortField: 'booking_date',
        sortable: true,
        cell: (row) => {
          if (row.timestamp) {
            const bookingDate = new Date(row.timestamp);
            const options = {
              weekday: 'long',
              day: 'numeric',
              month: 'short',
              year: '2-digit',
            };

            const formattedDate = bookingDate.toLocaleDateString(
              'en-US',
              options
            );
            return <span>{formattedDate}</span>;
          } else {
            return '-';
          }
        },
      },
      {
        name: 'Submitted By',
        selector: 'submitted_by',
        sortField: 'submitted_by',
        sortable: true,
        cell: (row) =>
          row.log_user_first_name ? (
            <div className="reports-profile-view assigned-title-block-new">
              <div className="assigned-title-itm">
                <span
                  className={
                    'assigned-title-blk  name-icon cat-dark-red ' +
                    ColorRender(row.user_role)
                  }
                >
                  {row.log_user_picture_me ? (
                    <img
                      src={`${IMAGE_URL}/${row.log_user_picture_me.replace(
                        '/home/myaie/public_html/',
                        ''
                      )}`}
                      alt="AIE"
                    />
                  ) : (
                    InitialRender([
                      row.log_user_first_name,
                      row.log_user_last_name,
                    ])
                  )}
                  <span
                    className={`profile-box-2-status ${
                      row.log_user_active_status
                        ? row.log_user_active_status.toLowerCase() == 'online'
                          ? 'Online'
                          : row.log_user_active_status.toLowerCase() == 'away'
                          ? 'Away'
                          : 'Offline'
                        : 'Offline'
                    }`}
                  >
                    <i className="fas fa-circle"></i>
                  </span>
                  <div>
                    <Hover
                      firstName={row.log_user_full_name.split(' ')[0]}
                      lastName={
                        row.log_user_full_name.replace(/ +/g, ' ').split(' ')[1]
                      }
                      photo={row.log_user_picture_me}
                      email={row.log_user_email}
                      mobile={row.log_user_phone ? row.log_user_phone : ''}
                      right={false}
                      status={row.log_user_status}
                      activity_status={row.log_user_active_status}
                      role={row.user_role}
                    />
                  </div>
                </span>
                {/* <PermissionsGate scopes={['umedit']} RenderError={() => <p>{row.user_first_name}</p>}> */}
                <span
                  title={row.user_full_name}
                  className="as-text-blue curser feature-name width-155"
                  onClick={() =>
                    history.push(
                      `/systemAdministration/userManagement/open/${row.user_id}/aboutYou`
                    )
                  }
                >
                  <span className="textLimit100">
                    {row.log_user_full_name ? row['log_user_full_name'] : '-'}
                  </span>
                </span>
                {/* </PermissionsGate> */}
              </div>
            </div>
          ) : (
            '-'
          ),
      },
      {
        name: 'Role',
        selector: 'Role',
        sortField: 'role_name',
        sortable: true,
        cell: (row) =>
          row.user_role ? RenderUserRole(row.user_role).html : '-',
      },
      {
        name: 'Additional Information',
        selector: 'additionalInfo',
        sortField: 'additional_info',
        cell: (row) => (row.additional_info ? row.additional_info : '-'),
      },
    ],
    []
  );

  return (
    <>
      <div className="mb-4">
        <form onSubmit={formik.handleSubmit}>
          <div className="booking-confirmation-header">
            <i className="fal fa-calendar-alt mr-2"> </i>
            Booking Confirmation
          </div>
          <div className="booking-confirm-row">
            <div
              style={{ display: 'flex', alignItems: 'center', paddingLeft : '10px' }}
            >
              <div className="booking-sub-container">
                <div className="d-flex">
                  <div className="radio-group-custom custom-radio radio-style mr-4">
                    <input
                      type="radio"
                      id="pendingID"
                      name="pending"
                      value="pending"
                      checked={formik.values.pending === 'pending'}
                      onChange={(e) => handleApprovalRequest(e)}
                      onBlur={formik.handleBlur}
                    />
                    <label className="booking-radio-label">Pending</label>
                  </div>
                  <div className="radio-group-custom custom-radio radio-style mr-4">
                    <input
                      type="radio"
                      id="approveID"
                      name="approve"
                      value="approve"
                      checked={formik.values.approve === 'approve'}
                      onChange={(e) => handleApprovalRequest(e)}
                      onBlur={formik.handleBlur}
                    />
                    <label className="booking-radio-label">Approved</label>
                  </div>
                  <div className="radio-group-custom custom-radio radio-style mr-4">
                    <input
                      type="radio"
                      id="declineID"
                      name="decline"
                      value="decline"
                      checked={formik.values.decline === 'decline'}
                      onChange={(e) => handleApprovalRequest(e)}
                      onBlur={formik.handleBlur}
                    />
                    <label className="booking-radio-label">Declined</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="pr-2" style={{width : '100%'}}>
              <div className="form-icon-group">
                <input
                  type="text"
                  id="additionalInformation"
                  name="additional_info"
                  className="form-control"
                  placeholder="Enter Additional Information"
                  value={formik.values.additional_info}
                  onChange={(event) =>
                    formik.setFieldValue('additional_info', event.target.value)
                  }
                />
              </div>
            </div>
            <div className="d-flex align-items-center ml-2">
              <div className="form-group form-group-save-cancel">
                <button
                  className="btn-save btn-success"
                  type="submit"
                  style={{ border: '1px solid transparent', width: '107px' ,height : '42px' }}
                >
                  <i className="fas fa-plus"></i> Add Note
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
        {/* </form>
      </div> */}                  
      <div className="my-tickets-info-list Tickets-main-wrap mb-4">
        <div className="custom-table-div filter-search-icon card-table-custom program-table">
          <DataTable
            data={displayLogs}
            defaultSortField="created_date"
            defaultSortAsc={false}
            columns={columns}
            sortServer
            highlightOnHover={false}
            progressComponent={<SkeletonTicketList />}
          />
        </div>
      </div> 
    </>
  );
}
