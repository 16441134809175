import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { DeleteTicketSetting, GetTicketDepartmentSettingList, GetTicketDepartmentSettingListCancelToken, GetTicketDepartmentSettingListFilter, GetTicketDepartmentSettingListFilterCancelToken } from "../../../services/TicketsSettingServices";
import { TrimText } from "../../common/TrimText";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

function TicketTable() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [deleterow, setDeleteRow] = useState(false);
  const [ticketData, setTicketData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [departmentStatus, setDepartmentStatus] = useState([]);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [isLoaded])

  useEffect(() => {
    let response = hasPermission({
      scopes: ["ticketsettingdepartmentview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleStatusFilter = (e) => {
    const value = e.target.value;
    let arr = status;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setStatus(arr);
    let id = e.target.id;
    setSearchStatusCheck({ ...searchStatusCheck, [id]: !searchStatusCheck[id] });
  };

  const resetFilter = () => {
    setStatus([]);
    setSearchStatusCheck({});
    setSearch("");
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = ticketData;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.helper_department_id && item.helper_department_id.toString().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempbrand_name = allData.filter((item) => {
        let includes = item.title && item.title.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        // ...tempId,
        ...tempbrand_name
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.find(function (post, index) {
          if (post.toString() === item.status.toString() ? item.status.toString() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (status.length || search.length) {
      return updatedData;
    } else {
      return ticketData;
    }
  };

  useEffect(() => {
    setIsLoaded(true);
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const response = await GetTicketDepartmentSettingListCancelToken(dropdownSource.token);
        setTicketData(response?.data?.support_department);
        if(response?.status == 200){
          setIsLoaded(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoaded(false);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
 }, [deleterow]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await GetTicketDepartmentSettingListFilterCancelToken(dropdownSource.token);
        setDepartmentStatus(res?.data?.statusFilter);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();

    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [deleterow]);

  const sortingName = (rowA, rowB) => {
    const name1 = rowA?.title
      ? rowA?.title.toString()
      : "";
    const name2 = rowB?.title
      ? rowB?.title.toString()
      : "";
    return name1.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "title",
      sortable: true,
      sortFunction:sortingName,
      // minWidth: "190px",
      cell: (row) => 
            <Link title={row.title} className="as-text-blue curser feature-name" to={(`/administration/ticketSettings/department/open/${row.id}`)}>
              <span className="textLimit100">{row.title}</span>
            </Link>
    },
    {
      name: "Status", selector: "status", sortable: true, cell: (row) => (
        <p>
          <span className={row.status == "1" ? "as-green-bg as-widget" : "as-red-bg as-widget"}>{row.status == "1" ? "ACTIVE" : "INACTIVE"}</span>
        </p>
      ),
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
          <PermissionsGate scopes={["ticketsettingdepartmentedit"]} RenderError={()=>(
            <button className="btn btn-primary rounded-circle" title="Open">
            <i className="fal fa-folder-open"></i>
          </button>
          )}>
            <Link className="btn btn-primary rounded-circle" to={(`/administration/ticketSettings/department/open/${row.id}`)} title="Open">
              <i className="fal fa-folder-open"></i>
            </Link>
          </PermissionsGate>
            <PermissionsGate scopes={["ticketsettingdepartmentdelete"]} errorProps={{disabled:true}}>
              <button className="btn btn-danger rounded-circle" onClick={() => handleDelete(row.id)} title="Delete">
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("id", ID);
          DeleteTicketSetting(formData)
            .then((res) => {
              Swal.fire("Deleted!", "Ticket has been deleted.", "success");
              setDeleteRow(!deleterow)
            })
            .catch((err) =>
              Swal.fire("Error!", "Ticket is Not deleted.", "error"))
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setDeleteRow(!deleterow);
      });
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Status"];

    data = data?.map((row) => ({
      ...row,      
      Name: row?.title ? row?.title : "NA",
      Status: row?.status == "1" ? "ACTIVE" : "INACTIVE",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Name, row.Status];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom ticket__table__departments">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                    <div id="assessment-table-main_filter" className="dataTables_filter">
                      <label>
                        <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                      <div className="assessment-table-filter">
                        <div className="btn-type-filter dropdown-comman">
                          <div className="dropdown multiselect">
                            <button
                              className={`btn btn-default dropdown-toggle ${status.length ? "btn-selected" : ""}`}
                              type="button"
                              id="dropdownMenu1"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              title="Status"
                            >
                              <span>
                                Status <i className="fal fa-angle-down"></i>
                              </span>
                            </button>
                            <form className="dropdown-menu remove-list-style">
                              <ul aria-labelledby="dropdownMenu1">
                                {
                                  departmentStatus?.sort((a, b) =>{
                                    let name1 = a.label.toString().trim().toLowerCase();
                                    let name2 = b.label.toString().trim().toLowerCase();
                                    return name1.localeCompare(name2);
                                  }).map((item) => {
                                    return (
                                      <li className={item?.value == 1 ? 'item-text-green' : 'item-text-red'} key={item?.value}>
                                        <input type="checkbox" id={`action${item?.value}`} value={item?.value} onClick={handleStatusFilter} checked={searchStatusCheck[`action${item?.value}`]} />
                                        <label htmlFor={`action${item?.value}`}>
                                          <span className={item?.value == 1 ? 'as-green-bg as-widget' : 'as-red-bg as-widget'}>{item?.label}</span>
                                        </label>
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                            </form>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "TicketSetting");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "TicketSetting");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "TicketSetting");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
              <Link to={`/administration/ticketSettings/department/add`}>
                <PermissionsGate scopes={["ticketsettingdepartmentadd"]} errorProps={{ disabled: true }}>
                  <button className="btn btn-primary" title="Create New">
                    <i className="fal fa-plus"></i>Create New
                  </button>
                </PermissionsGate>
              </Link>
              </div>
            </div>
          </div>
        </div>
        {isLoaded ? <SkeletonTicketList />
          : <DataTable
            data={dataToRender()}
            defaultSortField="title"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />}
      </div>
    </div>
  );
};

export default TicketTable