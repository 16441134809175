import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import * as Yup from "yup";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
// import { convertToRaw, EditorState, convertFromRaw, convertFromHTML, ContentState } from "draft-js";
import { EditExamSettings, GetExamsSettingData } from "../../../../services/ExternalExamServices";
import draftToHtml from "draftjs-to-html";
import { formatQuestion } from "../../../../utils/FormatQuestion";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../../utils/permissionGate";
import axios from "axios";
const ExamSettingAdd = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false)
  const [editorStateSubjects, setEditorStateSubjects] = React.useState("");
  const [editorStateCentres, setEditorStateCentres] = React.useState("");
  const [editorStateRegistration, setEditorStateRegistration] = React.useState("");
  const [editorStateExam, setEditorStateExam] = React.useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["eeesview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  
  useEffect(() => {
    const source = axios.CancelToken.source(); 
    const getDetails = async () =>{
      setLoading(true)
      try {
        const res = await GetExamsSettingData(source.token);
        if(res.status==200){
          let exam=res?.data?.data;
          exam.map((value)=>{
            if(value.name=="terms"){
              const questionBlock = value.value;
                      setEditorStateExam((questionBlock));
              // setEditorExam(value.value);
            }
            else if(value.name =="registration_form_header"){
              const questionBlock = value.value;
              setEditorStateRegistration(questionBlock);
              // setEditorRegistration(value.value);
            }
            else if(value.name =="centres_text"){
              const questionBlock = value.value;
              setEditorStateCentres(questionBlock);
            }
            else if(value.name=="subjects_text"){
              const questionBlock = value.value;
              setEditorStateSubjects(questionBlock);
            }
          })
          setLoading(false)
          setDataLoading(true);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
          setDataLoading(true);
        }
      }
    }
    
    getDetails();

    return () => {
      source.cancel('Component unmounted');    
    };
    
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subjectHeader: "",
      centerHeader: "",
      examTermCondition: "",
      registrationHeader: "",
    },
    validationSchema: Yup.object({
      subjectHeader: Yup.string().test(
        "required",
        "Subjects header is required",
        (value) => {
          let feature = formatQuestion(editorStateSubjects);
          let isInvalid = feature == "-";
          return !isInvalid;
        }
      ),
      centerHeader: Yup.string().test(
        "required",
        "Centers header is Required",
        (value) => {
          let feature = formatQuestion(editorStateCentres);
          let isInvalid = feature == "-";
          return !isInvalid;
        }
      ),
      examTermCondition: Yup.string().test(
        "required",
        "Exam terms and conditions is Required",
        (value) => {
          let feature = formatQuestion(editorStateExam);
          let isInvalid = feature == "-";
          return !isInvalid;
        }
      ),
      registrationHeader: Yup.string().test(
        "required",
        "Registration form header is Required",
        (value) => {
          let feature = formatQuestion(editorStateRegistration);
          let isInvalid = feature == "-";
          return !isInvalid;
        }
      ),
    }),
    onSubmit: (values, { resetForm }) => {
      setSaveLoading(true)
      const editData={
        type:"settings",
        subjects_text:editorStateSubjects,
        centres_text:editorStateCentres,
        terms:editorStateExam,
        registration_form_header:editorStateRegistration
      }
      EditExamSettings(editData)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Added successfully",
            });
            setSaveLoading(false)
            // resetForm();
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data?.data}`,
            });
            setSaveLoading(false)
          });
          
    },
  });

  return (
    <div className="card pt-3 pl-3 pr-3 card-body-inr">
      <form >
        <div className="row">
          <div className="col-md-12">
            <div className="form-group-blk">
              <div className="form-icon-group d-block">
                <label>Subjects Header*</label>
                {dataLoading && <HtmlInputEditor
                  editorState={editorStateSubjects}
                  setEditorState={setEditorStateSubjects}
                  hideSign
                  isCKEditor
                  loading={loading}
                  isInvalid={
                    formik.touched.subjectHeader &&
                    formik.errors.subjectHeader
                  }
                />}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group-blk">
              <div className="form-icon-group d-block">
                <label>Centers Header*</label>

                {dataLoading && <HtmlInputEditor
                  editorState={editorStateCentres}
                  setEditorState={setEditorStateCentres}
                  hideSign
                  isCKEditor
                  loading={loading}
                  isInvalid={
                    formik.touched.centerHeader &&
                    formik.errors.centerHeader
                  }
                />}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group-blk">
              <div className="form-icon-group d-block">
                <label>Registration Form Header*</label>
                {dataLoading && <HtmlInputEditor
                  editorState={editorStateRegistration}
                  setEditorState={setEditorStateRegistration}
                  hideSign
                  isCKEditor
                  loading={loading}
                  isInvalid={
                    formik.touched.registrationHeader &&
                    formik.errors.registrationHeader
                  }
                />}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group-blk">
              <div className="form-icon-group d-block">
                <label>Exam Terms and Conditions*</label>
                {dataLoading && <HtmlInputEditor
                  editorState={editorStateExam}
                  setEditorState={setEditorStateExam}
                  hideSign
                  isCKEditor
                  loading={loading}
                  isInvalid={
                    formik.touched.examTermCondition &&
                    formik.errors.examTermCondition
                  }
                />}
              </div>
            </div>
          </div>
        </div>

        <div className="form-group form-group-save-cancel">
        <PermissionsGate scopes={["eeesedit"]} errorProps={{disabled:true}}>
          <button
            className="btn btn-save btn-success"
            type="submit"
            title="Save"
            disabled={saveLoading ? true : false}
            onClick={formik.handleSubmit}
          >
            {saveLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save
          </button>
          </PermissionsGate>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => formik.resetForm()}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>
          {formik.touched.subjectHeader && formik.errors.subjectHeader ? (
            <div className="invalid-feedback d-block">
              {formik.errors.subjectHeader}
            </div>
          ) : null}
          {formik.touched.centerHeader && formik.errors.centerHeader ? (
            <div className="invalid-feedback d-block">
              {formik.errors.centerHeader}
            </div>
          ) : null}
          {formik.touched.registrationHeader &&
          formik.errors.registrationHeader ? (
            <div className="invalid-feedback d-block">
              {formik.errors.registrationHeader}
            </div>
          ) : null}
          {formik.touched.examTermCondition &&
          formik.errors.examTermCondition ? (
            <div className="invalid-feedback d-block">
              {formik.errors.examTermCondition}
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
};

export default ExamSettingAdd;
