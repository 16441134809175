import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import { InitialRender } from "../../common/Helper";
import {
  ColorRender,
} from "../../systemadministration/usermanagement/CheckRole";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import {
  GetListOfPMCAuditTrail,
  GetModuleAudit,
  GetModuleAuditCancelToken,
} from "../../../services/ProgramService";
import moment from "moment";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Hover from "../../common/Hover";
import { capitalize, checkIfImageExists, handleTableScroll } from "../../../utils/commonFunction";
import { TrimText } from "../../common/TrimText";
import { Link } from "react-router-dom";
import { formatQuestion } from "../../../utils/FormatQuestion";
import axios from "axios";
import { RenderAuditTrailActionType } from "../../../utils/CommonGroupingItem";

const AuditTrail = () => {
  const history = useHistory();
  const { id } = useParams();

  const [search, setSearch] = useState("");

  const [brandData, setBrandData] = useState([]);

  const [studentDetails, setStudentDetails] = useState();

  const [actiontype, setActionType] = useState([]);
  const [searchActionTypeCheck, setSearchActionTypeCheck] = useState({});

  const [month, setMonth] = useState([]);
  const [searchMonthCheck, setSearchMonthCheck] = useState({});

  const [year, setYear] = useState([]);
  const [searchYearCheck, setSearchYearCheck] = useState({});

  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    handleTableScroll()
  }, [isLoaded])

  const fixDataFormat = (arr) => {
    let tempArr = [];
    arr.map((item) => {
      let singleItem = {
        id: item.id,
        action_id: item.action_id,
        description: JSON.parse(item.description),
        resource_type: item.resource_type,
      };
      tempArr.push(singleItem);
    });
    setBrandData(tempArr);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        if (id) {
          const res = await GetModuleAuditCancelToken(id, source.token);
              fixDataFormat(res.data.data);
              setStudentDetails(res.data.student);
              setIsLoaded(false);
        } else {
          const res = await GetModuleAuditCancelToken(false, source.token);
              fixDataFormat(res.data.data);
              setStudentDetails(res.data.student);
              setIsLoaded(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      source.cancel('Component unmounted');
    };
  }, []);

  const sortingDate = (rowA, rowB) => {
    const date1 = new Date(rowA.description.timestamp);
    const date2 = new Date(rowB.description.timestamp);

    if (date2 > date1) {
      // console.log(`${date2} is greater than ${date1}`)
      return 1;
    } else if (date2 < date1) {
      // console.log(`${date1} is greater than ${date2}`)
      return -1;
    } else {
      // console.log(`Both dates are equal`)
      return 0;
    }
  };

  const sortingName = (rowA, rowB) => {
    const name1 = (rowA.description.verb.Action === "Deleted") ?
      (rowA.resource_type == "Module Outcomes") ? rowA.description.object.definition.Old.module_outcomes.toString() :
        (rowA.resource_type == "Module Topic") ? rowA.description.object.definition.Old.topic_name.toString() :
          (rowA.resource_type == "Module Resources") ? rowA.description.object.definition.Old.name.toString()
            : rowA.description.object.definition.Old.Portal.toString()
      :
      rowA.resource_type == "Module Outcomes" ?
        rowA.description.object.definition.New.module_outcomes.toString()
        : (rowA.resource_type == "Module Topic") ?
          rowA.description.object.definition.New.topic_name.toString()
          : (rowA.resource_type == "Module Resources") ?
            rowA.description.object.definition.New.name.toString()
            : rowA.description.object.definition.New.Portal.toString()

    const name2 = rowB.resource_type == "Module Outcomes"
      ? rowB.description.object.definition.Old.module_outcomes
        ? rowB.description.object.definition.Old.module_outcomes.toString()
        : rowB.description.object.definition.New.module_outcomes.toString()
      : rowB.resource_type == "Module Topic"
        ? rowB.description.object.definition.Old.topic_name
          ? rowB.description.object.definition.Old.topic_name.toString()
          : rowB.description.object.definition.New.topic_name.toString()
        : rowB.resource_type == "Module Resources"
          ? rowB.description.object.definition.Old.name
            ? rowB.description.object.definition.Old.name.toString()
            : rowB.description.object.definition.New.name.toString()
          : rowB.description.object.definition.Old.Portal
            ? rowB.description.object.definition.Old.Portal.toString()
            : rowB.description.object.definition.New.Portal.toString()

    return name1.localeCompare(name2);
  };

  const sortingUser = (rowA, rowB) => {
    const name1 = rowA.description.User.name.toString();
    const name2 = rowB.description.User.name.toString();
    return name1.localeCompare(name2);
  };

  const actionSortFunction = (rowA, rowB) => {
    const name1 = rowA.description.verb.Action;
    const name2 = rowB.description.verb.Action;
    return name1.localeCompare(name2);
  };

  const difference = (obj1, obj2) => {
    let keyFound = [];
    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        return keyFound.push(key + ":" + obj1[key]);
      }
    });
    return keyFound;
  };

  const logPop = (data) => {
    let finalData = data;
    finalData.timestamp = moment(finalData.timestamp).format(
      TABLE_DATE_TIME_FORMAT
    );
    //Now printing updated Value
    Swal.fire({
      html: `<div class="audit-trial-json"><pre>${JSON.stringify(
        finalData,
        undefined,
        1
      )}</pre></div>`,
    });
  };

  const columns = useMemo(() => [
    {
      name: "User",
      selector: "user",
      sortable: false,
      sortFunction: sortingUser,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className="assigned-title-blk name-icon">
              {row.description && row.description.User && row.description.User.URL &&
                checkIfImageExists(
                  `${IMAGE_URL}/${row.description.User.URL.replaceAll(
                    "home/myaie/public_html/",
                    ""
                  ).replaceAll("public/", "")}`
                ) ? (
                <img
                  src={`${IMAGE_URL}/${row.description.User.URL.replaceAll(
                    "home/myaie/public_html/",
                    ""
                  ).replaceAll("public/", "")}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([
                  row.description.User.name.split(" ")[0],
                  row.description.User.name.split(" ")[1],
                ])
              )}
              <span
                className={`profile-box-2-status ${row.description ? row.description.User.status : "Offline"
                  }`}
              >
                <i className="fas fa-circle"></i>
              </span>
              <Hover
                firstName={row.description.User.name.split(" ")[0]}
                lastName={row.description.User.name.split(" ")[1]}
                photo={row.description.User.URL}
                email={row.description.User.mbox}
                mobile={row.description.User?.Mobile}
                right={true}
                status={row.description.User.status}
                role={"default"}
              />
            </span>
            <p>{row.description.User.name}</p>
          </div>
        </div>
      ),
    },
    {
      name: "Action Type",
      sortable: true,
      selector: "action_type",
      sortFunction: actionSortFunction,
      cell: (row) => {
        return RenderAuditTrailActionType(row.description.verb.Action).html;
      },
    },

    {
      name: "Resource Type",
      sortable: true,
      selector: "resource_type",
      cell: (row) => (row.resource_type ? <div title={row.resource_type}>{capitalize(row.resource_type)}</div> : "-"),
    },
    {
      name: "Resource Name",
      selector: "resource_name",
      sortable: true,
      sortFunction: sortingName,
      cell: (row) => {
        if (row.description.verb.Action === "Deleted") {
          if (row.resource_type == "Module Outcomes") {
            return (
              <div title={formatQuestion(row.description.object.definition.Old.module_outcomes).replaceAll("&nbsp;", "")} >
                <p className="textLimit100">
                  {formatQuestion(row.description.object.definition.Old.module_outcomes).replaceAll("&nbsp;", "")}
                </p>
              </div>
            );
          } else if (row.resource_type == "Module Topic") {
            return (
              <div title={formatQuestion(row.description.object.definition.Old.topic_name).replaceAll("&nbsp;", "")} >
                <p className="textLimit100">
                  {formatQuestion(row.description.object.definition.Old.topic_name).replaceAll("&nbsp;", "")}
                </p>
              </div>
            );
          }
          else if (row.resource_type == "Module Resources") {
            return (
              <div title={formatQuestion(row.description.object.definition.Old.name).replaceAll("&nbsp;", "")} >
                <p className="textLimit100">
                  {formatQuestion(row.description.object.definition.Old.name).replaceAll("&nbsp;", "")}
                </p>
              </div>
            );
          } else {
            return (
              <div title={formatQuestion(row.description.object.definition.Old.Portal).replaceAll("&nbsp;", "")} >
                <p className="textLimit100">
                  {formatQuestion(row.description.object.definition.Old.Portal).replaceAll("&nbsp;", "")}
                </p>
              </div>
            );
          }
        } else {
          if (row.resource_type == "Module Outcomes") {
            return (
              <div className="assigned-title-block-new ">
                <div className="assigned-title-itm">
                  <Link
                    className="as-text-blue curser "
                    title={formatQuestion(row.description.object.definition.New.module_outcomes).replaceAll("&nbsp;", "")}
                    to={`/program-management-center/modules/module/outcomes/${row.action_id}`}
                  >
                    <p className="textLimit100">
                      {formatQuestion(row.description.object.definition.New.module_outcomes).replaceAll("&nbsp;", "")}
                    </p>
                  </Link>
                </div>
              </div>
            );
          }
          else if (row.resource_type == "Module Topic") {
            return (
              <div className="assigned-title-block-new ">
                <div className="assigned-title-itm">
                  <Link
                    className="as-text-blue curser"
                    title={formatQuestion(row.description.object.definition.New.topic_name).replaceAll("&nbsp;", "")}
                    to={`/program-management-center/modules/module/topics/${row.action_id}`}
                  >
                    <p className="textLimit100">
                      {formatQuestion(row.description.object.definition.New.topic_name).replaceAll("&nbsp;", "")}
                    </p>
                  </Link>
                </div>
              </div>
            );
          } else if (row.resource_type == "Module Resources") {
            return (
              <div className="assigned-title-block-new ">
                <div className="assigned-title-itm">
                  <Link
                    className="as-text-blue curser"
                    title={formatQuestion(row.description.object.definition.New.name).replaceAll("&nbsp;", "")}
                    to={`/program-management-center/modules/module/resources/${row.action_id}`}
                  >
                    <p className="textLimit100">
                      {formatQuestion(row.description.object.definition.New.name).replaceAll("&nbsp;", "")}
                    </p>
                  </Link>
                </div>
              </div>
            );
          }
          else {
            return (
              <div className="assigned-title-block-new ">
                <div className="assigned-title-itm">
                  <p
                    className="as-text-blue curser textLimit100"
                    title={row.description.object.definition.New.Portal}
                    onClick={() => {
                      history.push(
                        `/program-management-center/modules/module/open/${row.action_id}`
                      );
                    }}
                  >
                    {row.description.object.definition.New.Portal}
                  </p>
                </div>
              </div>
            );
          }
        }
      },
    },

    {
      name: "Old Value",
      // sortable: true,
      cell: (row) => {
        const entries = Object.entries(row.description.object.definition?.Old);

        if (row.description.verb.Action === "Created") {
          return "-";
        } else if (row.description.verb.Action === "Edited") {
          const diff = difference(
            row.description.object.definition?.Old,
            row.description.object.definition?.New
          );
          return diff.length !== 0 ? (
            <div
              className="tooltip-custom"
              title={`${diff.sort().map((entry) => entry.toString())}`
                .split(",")
                .join("\n")}
            >
              {/* <span className="tooltip-custom-box">{diff.sort().map(entry => <p>{entry.toString()}</p>)}</span> */}
              {diff.sort().map((entry, index) => {
                return (
                  index < 2 && (
                    <p key={index}>
                      {entry.toString().length > 30
                        ? entry.toString().slice(0, 30) + "..."
                        : entry}
                    </p>
                  )
                );
              })}
              {diff.length > 2 ? "..." : null}
            </div>
          ) : (
            "NA"
          );
        } else {
          return (
            <div
              className="tooltip-custom h"
              title={`${Object.keys(row.description.object.definition?.Old)
                .sort()
                .map(
                  (entry) =>
                    entry.toString() +
                    " : " +
                    row.description.object.definition?.Old[entry]
                )}`
                .split(",")
                .join("\n")}
            >
              {entries.slice(0, 2).map((entry, index) => {
                return (
                  <p key={index}>
                    {entry[0]}:
                    {entry[1]
                      ? entry[1].toString().length > 30
                        ? entry[1].toString().slice(0, 30) + "..."
                        : entry[1]
                      : "N/A"}
                  </p>
                );
              })}
            </div>
          );
        }
      },
    },
    {
      name: "New Value",
      // sortable: true,
      cell: (row) => {
        const entries = Object.entries(row.description.object.definition?.New);
        if (row.description.verb.Action === "Edited") {
          const diff = difference(
            row.description.object.definition?.New,
            row.description.object.definition?.Old
          );
          return diff.length !== 0 ? (
            <div
              className="tooltip-custom"
              title={`${diff.sort().map((entry) => entry.toString())}`
                .split(",")
                .join("\n")}
            >
              {diff.sort().map((entry, index) => {
                return (
                  index < 2 && (
                    <p key={index}>
                      {entry.toString().length > 30
                        ? entry.toString().slice(0, 30) + "..."
                        : entry}
                    </p>
                  )
                );
              })}
              {diff.length > 2 ? "..." : null}
            </div>
          ) : (
            "-"
          );
        } else {
          return (
            <div
              className="tooltip-custom"
              title={`${entries.map(
                (entry) => entry[0] + ":" + (entry[1] ? entry[1] : "NA")
              )}`
                .split(",")
                .join("\n")}
            >
              {entries.map((entry, index) => {
                return (
                  index < 2 && (
                    <p key={index}>
                      {entry[0]}:
                      {entry[1]
                        ? entry[1].toString().length > 30
                          ? entry[1].toString().slice(0, 30) + "..."
                          : entry[1]
                        : "N/A"}
                    </p>
                  )
                );
              })}
              {entries.length > 2 ? "..." : null}
            </div>
          );
        }
      },
    },
    {
      name: "Date",
      sortable: true,
      selector: "Date",
      sortFunction: sortingDate,
      cell: (row) => {
        let today = new Date().toLocaleDateString();
        return row.description.timestamp ? (
          <div title={moment(row.description.timestamp).format(TABLE_DATE_TIME_FORMAT)}>
            <p>{moment(row.description.timestamp).format(TABLE_DATE_FORMAT + ",")}</p>
            <p>{moment(row.description.timestamp).format(TABLE_TIME_FORMAT)}</p>
          </div>
        ) : (
          "NA"
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              title="Open"
              className="btn btn-primary rounded-circle"
              onClick={() => logPop(row.description)}
            >
              <i className="fal fa-folder-open"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleActionTypeFilter = (e) => {
    const value = e.target.value;
    let arr = actiontype;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setActionType(arr);
    let id = e.target.id;
    setSearchActionTypeCheck({
      ...searchActionTypeCheck,
      [id]: !searchActionTypeCheck[id],
    });
  };

  const handleMonthFilter = (e) => {
    const value = e.target.value;
    let arr = month;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setMonth(arr);
    let id = e.target.id;
    setSearchMonthCheck({ ...searchMonthCheck, [id]: !searchMonthCheck[id] });
  };

  const handleYearFilter = (e) => {
    const value = e.target.value;
    let arr = year;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setYear(arr);
    let id = e.target.id;
    setSearchYearCheck({ ...searchYearCheck, [id]: !searchYearCheck[id] });
  };

  const renderMonthUI = () => {
    let arr = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return arr.map((i, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            id={index}
            value={index}
            onClick={handleMonthFilter}
            checked={searchMonthCheck[index]}
          />
          <label htmlFor={index}>
            <span className="cat ">{i}</span>
          </label>
        </li>
      );
    });
  };

  const renderYearUI = () => {
    let arr = ["2022", "2021", "2020"];
    return arr.map((i, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            id={i}
            value={i}
            onClick={handleYearFilter}
            checked={searchYearCheck[i]}
          />
          <label htmlFor={i}>
            <span className="cat ">{i}</span>
          </label>
        </li>
      );
    });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = brandData;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.id.toString().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });

      let tempUser = allData.filter((item) => {
        let includes = item.description.User.name
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempName = allData.filter((row) => {
        // let includes = item.description.object.definition.New.brand_name
        let value = row.description.verb.Action === "Deleted" ?
          row.resource_type == "Module Outcomes" ?
            formatQuestion(row.description.object.definition.Old.module_outcomes).replaceAll("&nbsp;", "")
            : row.resource_type == "Module Topic" ?
              formatQuestion(row.description.object.definition.Old.topic_name).replaceAll("&nbsp;", "")
              : row.resource_type == "Module Resources" ?
                formatQuestion(row.description.object.definition.Old.name).replaceAll("&nbsp;", "")
                : row.description.object.definition.Old.Portal
          :
          row.resource_type == "Module Outcomes" ?
            formatQuestion(row.description.object.definition.New.module_outcomes).replaceAll("&nbsp;", "")
            : row.resource_type == "Module Topic" ?
              formatQuestion(row.description.object.definition.New.topic_name).replaceAll("&nbsp;", "")
              : row.resource_type == "Module Resources" ?
                formatQuestion(row.description.object.definition.New.name).replaceAll("&nbsp;", "")
                : row.description.object.definition.New.Portal
        let includes = value
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [...tempUser, ...tempName];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (actiontype.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        const startsWith = actiontype.find((post, index) => {
          if (post.toString() === item.description.verb.Action.toString())
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (month.length) {
      let tempMonth = updatedData;
      let tempResult = tempMonth.filter((item) => {
        const startsWith = month.find((post, index) => {
          let monthNumber = new Date(item.description.timestamp).getMonth();
          if (post.toString() === monthNumber.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (year.length) {
      let tempYear = updatedData;
      let tempResult = tempYear.filter((item) => {
        const startsWith = year.find((post, index) => {
          let yearNumber = new Date(item.description.timestamp).getFullYear();
          if (post.toString() === yearNumber.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    return updatedData;
  };

  const resetFilter = () => {
    setSearch("");
    setActionType([]);
    setSearchActionTypeCheck({});
    setMonth([]);
    setSearchMonthCheck({});
    setYear([]);
    setSearchYearCheck({});
  };

  const oldValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.Old);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(
        row.description.object.definition?.Old,
        row.description.object.definition?.New
      );
      return diff.length !== 0 ? `${diff.sort()}` : "NA";
    }
    // else if (row.description.verb.Action === "Profile Picture Edited") {
    //   return  row.description.object.definition?.Old?.picture_me;
    // }
    else {
      return `${entries.map(
        (entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "N/A"}`
      )}`;
    }
  };

  const NewValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.New);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(
        row.description.object.definition?.New,
        row.description.object.definition?.Old
      );
      return diff.length !== 0 ? `${diff.sort()}` : "NA";
    } else if (row.description.verb.Action === "Profile Picture Edited") {
      return row.description.object.definition?.New?.picture_me.toString()
        .length > 30
        ? "picture_me:" + row.description.object.definition?.New?.picture_me
        : row.description.object.definition?.New?.picture_me;
    } else {
      return `${entries.map(
        (entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "N/A"}`
      )}`;
    }
  };

  const modifiedData = () => {
    let data = dataToRender();

    data = data?.map((row) => ({
      ...row,
      // ID: row?.id,
      User: row.description.User.name,
      "Resource Name": row.description.verb.Action === "Deleted" ?
        row.resource_type == "Module Outcomes" ?
          formatQuestion(row.description.object.definition.Old.module_outcomes).replaceAll("&nbsp;", "")
          : row.resource_type == "Module Topic" ?
            row.description.object.definition.Old.topic_name
            :
            row.description.object.definition.Old.Portal
        :
        row.resource_type == "Module Outcomes" ?
          formatQuestion(row.description.object.definition.New.module_outcomes).replaceAll("&nbsp;", "")
          : row.resource_type == "Module Topic" ?
            formatQuestion(row.description.object.definition.New.topic_name).replaceAll("&nbsp;", "")
            :
            row.description.object.definition.New.Portal,
      "Resource Type": row.resource_type ? row.resource_type : "-",
      "Action Type": row.description.verb.Action,
      // "Old Value": Object.keys(row.description.object.definition.Old).length !== 0 ? `${Object.entries(row.description.object.definition.Old).map((entry) => entry[0] + ":" + entry[1])}` : "NA",
      "Old Value":
        Object.keys(row.description.object.definition.Old).length !== 0
          ? oldValue(row)
          : "NA",
      // "New Value": Object.keys(row.description.object.definition.New).length !== 0 ? `${Object.entries(row.description.object.definition.New).map((entry) => entry[0] + ":" + entry[1])}` : "NA",
      "New Value":
        row.description &&
          Object.keys(row.description.object.definition.New).length !== 0
          ? NewValue(row)
          : "NA",
      Date: new Date(row.description.timestamp).toLocaleDateString(),
    }));

    return data;
  };

  const exportData = (fileType, fileName) => {
    let data = modifiedData();

    const header = [
      "User",
      "Action Type",
      "Resource Type",
      "Resource Name",
      "Old Value",
      "New Value",
      "Date",
    ];

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      // console.log(csvString);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["User"],
          row["Action Type"],
          row["Resource Type"],
          row["Resource Name"],
          row["Old Value"],
          row["New Value"],
          row["Date"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        columnStyles: {
          0: {
            columnWidth: 20,
          },
          1: {
            columnWidth: 24,
          },
          2: {
            columnWidth: 24,
          },
          3: {
            columnWidth: 24,
          },
          4: {
            columnWidth: 40,
          },
          5: {
            columnWidth: 40,
          },
          6: {
            columnWidth: 20,
          },
        },
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom program-table">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">

                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${actiontype.length ? "btn-selected" : ""
                              }`}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title="Action Type"
                          >
                            <span>
                              Action Type <i className="fal fa-angle-down"></i>
                            </span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">
                              <li className="cat cat-skyblue">
                                <input
                                  type="checkbox"
                                  id="action1"
                                  value="Created"
                                  onClick={handleActionTypeFilter}
                                  checked={searchActionTypeCheck.action1}
                                />
                                <label htmlFor="action1">
                                  <span className="cat cat-skyblue">
                                    <i className="fas fa-circle mr-1"></i> Created
                                  </span>
                                </label>
                              </li>
                              <li className="cat cat-red">
                                <input
                                  type="checkbox"
                                  id="action3"
                                  value="Deleted"
                                  onClick={handleActionTypeFilter}
                                  checked={searchActionTypeCheck.action3}
                                />
                                <label htmlFor="action3">
                                  <span className="cat cat-red">
                                    <i className="fas fa-circle mr-1"></i> Deleted
                                  </span>
                                </label>
                              </li>
                              <li className="cat cat-greenhex">
                                <input
                                  type="checkbox"
                                  id="action2"
                                  value="Edited"
                                  onClick={handleActionTypeFilter}
                                  checked={searchActionTypeCheck.action2}
                                />
                                <label htmlFor="action2">
                                  <span className="cat cat-greenhex">
                                    <i className="fas fa-circle mr-1"></i> Edited
                                  </span>
                                </label>
                              </li>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${month.length ? "btn-selected" : ""
                              }`}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title="Month"
                          >
                            <span>
                              Month <i className="fal fa-angle-down"></i>
                            </span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">{renderMonthUI()}</ul>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${year.length ? "btn-selected" : ""
                              }`}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title="Year"
                          >
                            <span>
                              Year <i className="fal fa-angle-down"></i>
                            </span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">{renderYearUI()}</ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>

                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Module_Audit_Trail");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Module_Audit_Trail");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Module_Audit_Trail");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoaded ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            data={dataToRender()}
            defaultSortField="Date"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        )}
      </div>
    </div>
  );
};

export default AuditTrail;
