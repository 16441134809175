import moment from 'moment';
import $ from "jquery";
import IconProgress from '../assets/images/In-Progress.png'
import IconNotStarted from '../assets/images/Not-Started.png';
import IconNotPublished from '../assets/images/Not-Published.png';
import IconFinished from '../assets/images/Finished.png';
import IconSignOff from '../assets/images/Pending-Sign-Off.png';
import { NETSUITE_DATE_TIME_FORMAT, TABLE_DATE_FORMAT, TABLE_TIME_FORMAT } from './Constants';
//import moment from 'moment-timezone';
//moment.tz.setDefault("Africa/Johannesburg");

export function addDays(date, days) {
  var date = new Date(date);
  date.setDate(date.getDate() + days);
  return date;
}

export function shortenText(content, showChar) {
  let text = content;
  if (content.length > showChar) {
    let c = content.substr(0, showChar);
    var lastIndex = c.lastIndexOf(" ");
    c = c.substring(0, lastIndex);
    text = c + "...";
  }
  return text;
}

export function getSubjectStatus(status) {
  switch (status) {
    case 'In Progress':
      var icon = IconProgress
      break
    case 'Not Started':
      var icon = IconNotStarted
      break
    case 'Finished':
      var icon = IconFinished
      break;
    case 'Pending-Sign off':
      var icon = IconSignOff
      break;
    default:
      var icon = IconNotPublished
      break;
  }
  return icon;
}

export function formatBytes(bytes,decimals) {
  if(bytes == 0) return '0 Bytes';
  var k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function subjectDuration(start, end) {
  let startDate = moment(start, "Do MMM YY");
  let endDate = moment(end, "Do MMM YY");

  let years = endDate.diff(startDate, 'year');
  startDate.add(years, 'years');

  let months = endDate.diff(startDate, 'months');
  startDate.add(months, 'months');

  let days = endDate.diff(startDate, 'days');
  let returnDate = '(';
  if (years > 0) {
    returnDate += years + 'y, ';
  }
  returnDate += months + 'm, ';
  returnDate += days + 'd)';
  return returnDate;
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function capitalize(s) {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function nationalityList() {
  let Obj = {
    'zar': 'South Africa',
    'bwa': 'Botswana',
    'lso': 'Lesotho',
    'moz': 'Mozambique',
    'nam': 'Namibia',
    'swz': 'Swaziland',
    'zmb': 'Zambia',
    'zwe': 'Zimbabwe',
    'afg': 'Afghanistan',
    'alb': 'Albania',
    'dza': 'Algeria',
    'and': 'Andorra',
    'ago': 'Angola',
    'arg': 'Argentina',
    'aus': 'Australia',
    'aut': 'Austria',
    'aze': 'Azerbaijan',
    'bdi': 'Burundi',
    'bel': 'Belgium',
    'ben': 'Benin',
    'bfa': 'Burkina Faso',
    'bgd': 'Bangladesh',
    'bgr': 'Bulgaria',
    'bhr': 'Bahrain',
    'bhs': 'Bahamas',
    'bih': 'Bosnia and Herzegovina',
    'blr': 'Belarus',
    'bmu': 'BMU',
    'bol': 'Bolivia',
    'bra': 'Brazil',
    'btn': 'Bhutan',
    'caf': 'Central African Republic',
    'can': 'Canada',
    'chl': 'Chile',
    'chn': 'China',
    'civ': 'Cote D Ivoire',
    'cmr': 'Cameroon',
    'cod': 'Demogratic Republic of the Congo',
    'col': 'Colombia',
    'com': 'Comoros',
    'cub': 'Cuba',
    'cyp': 'Cyprus',
    'cze': 'Czech Republic',
    'deu': 'Germany',
    'dji': 'Djibouti',
    'dma': 'Dominica',
    'dnk': 'Denmark',
    'dom': 'Dominican Republic',
    'lbr': 'Liberia',
    'lby': 'Libyan Arab Jamahiriya',
    'lca': 'Saint Lucia',
    'lka': 'Sri Lanka',
    'ltu': 'Lithuania',
    'lux': 'Luxembourg',
    'lva': 'Latvia',
    'mar': 'Morocco',
    'mco': 'Monaco',
    'mda': 'Republic of Moldova',
    'mdg': 'Madagascar',
    'mdv': 'Maldives',
    'mex': 'Mexico',
    'mkd': 'Macedonia',
    'mli': 'Mali',
    'mlt': 'Malta',
    'mmr': 'Myanmar',
    'mrt': 'Mauritania',
    'mus': 'Mauritius',
    'mwi': 'Malawi',
    'mys': 'Malaysia',
    'ner': 'Niger',
    'nga': 'Nigeria',
    'nic': 'Nicaragua',
    'nld': 'Netherlands',
    'nor': 'Norway',
    'npl': 'Nepal',
    'nzl': 'New Zealand',
    'omn': 'Oman',
    'pak': 'Pakistan',
    'pan': 'Panama',
    'per': 'Peru',
    'phl': 'Philippines',
    'png': 'Papua New Guinea',
    'pol': 'Poland',
    'prk': 'Democratic Peoples Republic of  Korea',
    'prt': 'Portugal',
    'pry': 'Paraguay',
    'pse': 'Palestine',
    'qat': 'Qatar',
    'rou': 'Romania',
    'rus': 'Russian Federation',
    'egy': 'Egypt',
    'eri': 'Eritrea',
    'esp': 'Spain',
    'est': 'Estonia',
    'eth': 'Ethiopia',
    'fin': 'Finland',
    'fji': 'Fiji',
    'fra': 'France',
    'gab': 'Gabon',
    'gbr': 'United Kingdom of Great Britain and Northern Ireland',
    'gha': 'Ghana',
    'gin': 'Guinea',
    'gmb': 'Gambia',
    'gnb': 'Guinea-Bissau',
    'gnq': 'Equatorial Guinea',
    'grc': 'Greece',
    'guy': 'Guyana',
    'hkg': 'Hong Kong',
    'hnd': 'Honduras',
    'hrv': 'Croatia',
    'hti': 'Haiti',
    'hun': 'Hungary',
    'idn': 'Indonesia',
    'ind': 'India',
    'irl': 'Ireland',
    'irn': 'Iran',
    'irq': 'Iraq',
    'isl': 'Iceland',
    'isr': 'Israel',
    'ita': 'Italy',
    'jam': 'Jamaica',
    'jor': 'Jordan',
    'jpn': 'Japan',
    'kaz': 'Kazakhstan',
    'ken': 'Kenya',
    'kgz': 'Kyrgyzstan',
    'kor': 'Republic of Korea',
    'kwt': 'Kuwait',
    'lbn': 'Lebanon',
    'che': 'Switzerland',
    'rwa': 'Rwanda',
    'sau': 'Saudi Arabia',
    'sdn': 'Sudan',
    'sen': 'Senegal',
    'sgp': 'Singapore',
    'slb': 'Solomon Islands',
    'sle': 'Sierra Leone',
    'slv': 'El Salvador',
    'som': 'Somalia',
    'srb': 'Serbia',
    'syc': 'Seychelles',
    'sur': 'Suriname',
    'svk': 'Slovakia',
    'svn': 'Slovenia',
    'swe': 'Sweden',
    'syr': 'Syrian Arab Republic',
    'tcd': 'Chad',
    'tgo': 'Togo',
    'tha': 'Thailand',
    'tjk': 'Tajikistan',
    'tto': 'Trinidad and Tobago',
    'tun': 'Tunisia',
    'tur': 'Turkey',
    'twn': 'Taiwan',
    'tza': 'United Republic of Tanzania',
    'uga': 'Uganda',
    'ukr': 'Ukraine',
    'ury': 'Uruguay',
    'are': 'United Arab Emirates',
    'usa': 'United States of America',
    'uzb': 'Uzbekistan',
    'vct': 'Saint Vincent and the Grenadines',
    'ven': 'Venezuela',
    'vnm': 'Vietnam',
    'yem': 'Yemen',
    'yug': 'Yogoslavia',
    'other': 'Other',
  }
  return Obj;
}

export function disabilityList() {
  let Obj = {
    'none': 'No disibility specified',
    'albinism': 'Albinism',
    'adhd': 'Attention deficit disorder with/without hyperactivity (ADHD)',
    'autism': 'Autistic spectrum disorders',
    'behavioural': 'Behavioural / conduct disorder (including Sever behavioural problems)',
    'blindness': 'Blindness',
    'cerebral_palsy': 'Cerebral palsy',
    'deafness': 'Deafness',
    'dear_blindness': 'Deaf-blindness',
    'epilepsy': 'Epilepsy',
    'hard_hearing': 'Hard of hearing',
    'mild_intellectual_disability': 'Mild to moderate intellectual disability',
    'moderate_intellectual_disabiltiy': 'Moderate to severe/profound intellectual disability',
    'partial_sightedness': 'Partial sightedness / Low Vision',
    'physical_disability': 'Physical disabiity',
    'sever_intellectual_disability': 'Severely intellectually disabled',
    'learning_disability': 'Specific learning disability',
    'psychiatric_disorder': 'Psychiatric disorder',
  }
  return Obj;
}

export function languageList() {
  let Obj = {
    'english': 'English',
    'sepedi': 'Sepedi',
    'sesotho': 'Sesotho',
    'setswana': 'Setswana',
    'siSwati': 'siSwati',
    'tshivenda': 'Tshivenda',
    'xitsonga': 'Xitsonga',
    'afrikaans': 'Afrikaans',
    'isiNdebele': 'isiNdebele',
    'isiXhosa': 'isiXhosa',
    'isiZulu': 'isiZulu',

  }
  return Obj;
}

export function loadWidgetChat(liveuser, email, userID) {
  window.LHCChatOptions = {};
  window.LHCChatOptions.opt = {
    widget_height: 448,
    widget_width: 360,
    popup_height: 520,
    popup_width: 500,
  };
  (function () {
    let po = document.createElement("script");
    po.type = "text/javascript";
    po.async = true;
    let referrer = document.referrer
      ? encodeURIComponent(
        document.referrer.substr(document.referrer.indexOf("://") + 1)
      )
      : "";
    let location = document.location
      ? encodeURIComponent(
        window.location.href.substring(window.location.protocol.length)
      )
      : "";
    //"//www.livehelper.conceptinteractive.co.za/index.php/chat/getstatus/(click)/internal/(position)/bottom_right/(ma)/br/(top)/350/(units)/pixels/(leaveamessage)/true?r=" +

    po.src =
      "https://live.myaie.ac/index.php/chat/getstatus/(click)/internal/(position)/bottom_right/(ma)/br/(top)/350/(units)/pixels/(leaveamessage)/true?r=" +
      referrer +
      "&l=" +
      location +
      "&username=" +
      liveuser +
      "&email=" +
      email +
      "&userID=" +
      userID;
    let s = document.getElementsByTagName("script")[0];

    s.parentNode.insertBefore(po, s);

    // setTimeout(function () {
    //   s.parentNode.insertBefore(po, s);
    // }, 5000);
  })();
}

/**
 * Function to change the date format
 * 
 * @param {this will get the date to be changed} date
 * @returns will return the formated date
 */
export function netSuiteDateFormat(date) {
  return moment(date, NETSUITE_DATE_TIME_FORMAT).format(TABLE_DATE_FORMAT)
}

/**
 * Function to change the time format
 * 
 * @param {this will get the date to be changed} date
 * @returns will return the formated time
 */
export function netSuiteTimeFormat(date) {
  return moment(date, NETSUITE_DATE_TIME_FORMAT).format(TABLE_TIME_FORMAT)
}

export async function checkIfImageExists(url) {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    return true
  } else {

    img.onload = () => {
      return true
    };

    img.onerror = () => {
      return false
    };
  }
}

export const customSort = (rows, selector, direction) => {
  return rows.sort((rowA, rowB) => {
    // use the selector function to resolve your field names by passing the sort comparitors
    const aField = rowA[selector]?.toString().toLowerCase()
    const bField = rowB[selector]?.toString()?.toLowerCase()

    let comparison = 0;

    if (aField > bField) {
      comparison = 1;
    } else if (aField < bField) {
      comparison = -1;
    }

    return direction === 'desc' ? comparison * -1 : comparison;
  });
};
// export async function checkIfImageExists(url) {
//   const img = new Image();
//   img.src = url;

//   let allIsWell = false;
//   if (img.complete) {
//     allIsWell = true
//   } else {
//     img.onload = () => {
//       allIsWell = true
//     };

//     img.onerror = () => {
//       allIsWell = false
//     };

//   }
//   setTimeout(() => { return allIsWell }, 1000)
// }

// Use this function if table has a scroll in it so to keep first and last column sticky
export const handleTableScroll = () => {
  setTimeout(() => {
    const element = document.getElementsByClassName("sc-hHTYSt")
    let arr = Array.from(element)
    if (arr[0]?.scrollWidth > arr[0]?.clientWidth) {
      let items = document.getElementsByClassName("l-o-c-t")
      if (!items.length) {
        let anotherItem = document.getElementsByClassName("card-table-custom")
        if (!anotherItem.length) {
          return
        }
        anotherItem[0].classList.add("when-scroll-sticky")
        return
      }
      items[0].classList.add("when-scroll-sticky")
    } else {
      let items = document.getElementsByClassName("when-scroll-sticky")
      if (!items.length) {
        return
      }
      items[0].classList.remove("when-scroll-sticky")
    }
  }, 500);
}

export const twoDecimalRound = (num) => {
  return Math.round((Number(num) + Number.EPSILON) * 100) / 100
}