import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { RenderLearningMethod } from '../../../../utils/CommonGroupingItem';
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from '../../../../utils/Constants';
import { TrimText } from '../../../common/TrimText';
import $ from "jquery";
import { handleTableScroll } from '../../../../utils/commonFunction';

const CoursesTable = (props) => {

  const [courses, setCourses] = useState({});
  const [selectedRows, setSelectedRows] = React.useState(false);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);

  useEffect(() => {
    handleTableScroll()
  }, [props])

  useEffect(() => {
    setCourses(props && props.courses ? props.courses : {});
  }, [props]);

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "course_name",
      sortable: true,
      cell: (row) =>
        row.course_name ? (
          // <p className="as-text-blue" title={row.course_name}>
          //   {TrimText(row.course_name, 20)}
          // </p>
          <span className="as-text-blue curser feature-name" title={row.course_name}>
            <span className='textLimit100'>{row.course_name}</span>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Start",
      selector: "start",
      sortable: true,
      cell: (row) => (
        <>
          {row.start ? (
            <div className="dateTime">
              <p className="right-space">{moment.unix(row.start).format(TABLE_DATE_FORMAT)}</p>
              <p>{moment.unix(row.start).format(TABLE_TIME_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "End",
      selector: "end",
      sortable: true,
      cell: (row) => (
        <>
          {row.end ? (
            <div className="dateTime">
              <p className="right-space">{moment.unix(row.end).format(TABLE_DATE_FORMAT)}</p>
              <p>{moment.unix(row.end).format(TABLE_TIME_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => <>{row.year ? row.year + "-" + row.number : "-"}</>,
    },
    {
      name: "Correspondence",
      selector: "type",
      sortable: true,
      cell: (row) => <p>{row.type ? RenderLearningMethod(row.type).html : "-"}</p>,
    },
    // {
    //   name: "Actions",
    //   selector: "",
    //   // maxWidth: "50px",
    //   cell: (row,index) => (
    //     <div className="assessment-08 btn-dropdown-grp">
    //       <div className="as-buttons d-flex">
    //       {index !== 0 && (
    //             <button title="Up" className="btn btn-primary rounded-circle" onClick={() => { moveUp(index);}}>
    //               <i className="fal fa-angle-up"></i>
    //             </button>
    //           )}
    //           {index !== courses?.getSubjects.length - 1 && (
    //             <button title="Down" className="btn btn-primary rounded-circle" onClick={() => { moveDown(index);}}>
    //             <i className="fal fa-angle-down"></i>
    //           </button>
    //         )}
    //         <button class="btn btn-primary rounded-circle " id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="button"  title="More" disabled={courses?.getSubjects.length > 1 ?false : true} >
    //           <i class="fal fa-ellipsis-h-alt"></i>
    //         </button>
    //          <div class="dropdown-menu btn-dropdown-item" aria-labelledby="dropdownMenuButton">
    //           {index !== 0 && (
    //             <button title="Up" className="btn btn-primary rounded-circle" onClick={() => { moveUp(index);}}>
    //               <i className="fal fa-angle-up"></i>
    //             </button>
    //           )}
    //           {index !== courses?.getSubjects.length - 1 && (
    //             <button title="Down" className="btn btn-primary rounded-circle" onClick={() => { moveDown(index);}}>
    //             <i className="fal fa-angle-down"></i>
    //           </button>
    //         )}
    //         </div>
    //       </div>
    //     </div>
    //   ),
    // },
  ]);

  function moveUp(index) {
    let arr = [...courses.getSubjects];
    arr = moveArrayItemToNewIndex(arr, index, index - 1);
    let obj = courses;
    obj["getSubjects"] = arr;
    setCourses({ ...obj });
    props.setCourse(obj);
  }

  function moveDown(index) {
    let arr = [...courses.getSubjects];
    arr = moveArrayItemToNewIndex(arr, index, index + 1)
    let obj = courses;
    obj["getSubjects"] = arr;
    setCourses({ ...obj });
    props.setCourse(obj);
  }

  function moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    return arr;
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = courses.getSubjects;
    // if (search.length) {
    //     let tempId = allData.filter((item) => {
    //         let includes = item.id && item.id.toLowerCase().includes(search.toLowerCase());
    //         if (includes) {
    //             return includes;
    //         } else return null;
    //     });
    //     let tempName = allData.filter((item) => {
    //         let includes = item.name.toString().toLowerCase().includes(search.toLowerCase());
    //         if (includes) {
    //             return includes;
    //         } else return null;
    //     });
    //     let data = [...tempId, ...tempName];
    //     let unique = [...new Set(data)];

    //     updatedData = unique;
    // } else {
    updatedData = allData;
    // }

    return updatedData;
  }

  function addIds(isRemove = false) {
    let obj = { ...courses };
    obj.getSubjects = selectedRows;
    if (!isRemove) {
      props.assignCourse(obj);
    } else {
      const arr1 = courses.getSubjects;
      const arr2 = selectedRows;
      function getDifference(array1, array2) {
        return array1.filter(object1 => {
          return !array2.some(object2 => {
            return object1.id === object2.id;
          });
        });
      }
      obj.getSubjects = getDifference(arr1, arr2);
      props.removeCourse(obj);
    }
    handleClearRows();
  }

function showCourses() {
  $("#viewAllCourses").modal("show");
}
  return (
        <div className="my-tickets-info-list Tickets-main-wrap">
          <div className="custom-table-div filter-search-icon card-table-custom c-a-p-c-t">
            <DataTable
              data={dataToRender()}
              defaultSortField="Firstname"
              defaultSortAsc={true}
              columns={columns}
              pagination={true}
              selectableRows
              onSelectedRowsChange={handleChange}
              clearSelectedRows={toggledClearRows}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />
            <div className="btn-sec">
              {!props.isRemoveBtn && <button className="btn btn-primary" type='button' title="Add" onClick={() => { addIds() }}><i className="fal fa-plus"></i>Add</button>}
              {props.isRemoveBtn && <>{courses && courses.getSubjects && courses.getSubjects.length > 0 && <button title="Remove" className="btn btn-danger" onClick={() => { addIds(true) }}>
                <i className="fal fa-trash-alt"></i>Remove
              </button>}<button
                title="Add Course"
                className="btn btn-danger ml-2"
                onClick={() => {
                  showCourses(true);
                  props.getAllCourses()
                }}
              >
                <i className="fal fa-plus"></i>Add Course
              </button></>}
            </div>
          </div>
        </div>
         )

}

export default CoursesTable;