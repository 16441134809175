import React from "react";
import { useParams } from "react-router-dom";
import AddNotificationList from "./AddNotificationList";
import EmailView from "./EmailView";
import GeneratedList from "./GeneratedList";

function EmailGeneratedTab() {
  const { tab, type, id } = useParams();

  return (
    <div>
      {type && type == "table" && !id && <GeneratedList />}
      {type && type == "view" && id && <EmailView email_id={id} />}
      {type === "add" && <AddNotificationList/>}
      {/* {type && type == "add" && !id && <CustomerAdd />}
  {type && type == "edit" && id && <CustomerEdit customer_id={id} />}*/}
    </div>
  );
}

export default EmailGeneratedTab;
