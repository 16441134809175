import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import hasPermission from "../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PageComponent from "../components/common/PageComponent";
import OpenCopiedIntake from "../components/courses/CourseCopy/OpenCopiedIntake";
import ListCopiedIntake from "../components/courses/CourseCopy/ListCopiedIntake";
import ListCopiedLogs from "../components/courses/CourseCopy/ListCopiedLogs";
import AuditTrails from "../components/courses/CourseCopy/AuditTrails";

const IntakeCopy = () => {
  const { tab, page, id } = useParams();
  const history = useHistory();
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["intakeCopyView"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  return (
    <PageComponent
      heading={page === "list-copied-intake" ? ["All", "Copy Process"] : page === "all-copied-logs" ? ["All", "Copy Process Log"] : ["Copy", "Intake"]}
      disableColonShown
      isBackButtonShown={tab !== "list" && tab !== "auditTrail"}
      auditTrailUrl={`/courseAdministration/intake-qualification/audittrails`}
      isAuditTrailButtonShown={page === "list-copied-intake" || page === "audittrails"}
      componentToRender={
        [
          {
            condition: page === "open-copied-intake",
            component: <OpenCopiedIntake />
          },
          {
            condition: page === "list-copied-intake",
            component: <ListCopiedIntake />
          },
          {
            condition: page === "all-copied-logs",
            component: <ListCopiedLogs />
          },
          {
            condition: page === "audittrails",
            component: <AuditTrails />
          },
        ]
      }
      tabs={page === "list-copied-intake" || page === "audittrails" ? [
        {
          tabName: page === "open-copied-intake" ? "Find Course" : "All Process",
          tabIcon: "fa-bars",
          condition: page === "open-copied-intake" || page === "list-copied-intake",
          url: page === "open-copied-intake" ? `/courseAdministration/intake-qualification/open-copied-intake/open/${id}` : `/courseAdministration/intake-qualification/list-copied-intake`,
        },
        {
          globalIconName: "auditTrail",
          condition: page === "audittrails",
          url: `/courseAdministration/intake-qualification/audittrails`,
        },
      ] :  page === "open-copied-intake" ? [
        {
        tabName: "Find Course" ,
        tabIcon: "fa-bars",
        condition: true,
        url: `/courseAdministration/intake-qualification/open-copied-intake/open/${id}`,
      }
    ] : []}
    />
    // <>
    //   <Header></Header>
    //   <div className="content-wrapper d-flex flex-wrap">
    //     <SideBar></SideBar>
    //     <div className="sidebar-right">
    //       <div className="sub-menu-content-block">
    //         <div className="sub-menu-right-block">
    //           <div className="wrap-box-right">
    //             <div className="my-tickets-heading-blk flex-direction mb-2">
    //               <div className="d-flex align-items-center ">
    //                 <div className="name-w-head d-flex align-items-center ">
    //                   <h4 className="text-left-align landing-heading heading_color_dynamic" title={tabName.split(": ")[0] === "Articulation Registration" ? tabName.split(": ")[1] : ""}>
    //                     <>{tabName.split(" ")[0]}</> <span>{tabName.split(" ")[1] ? TrimText(tabName.substr(tabName.indexOf(' ') + 1), 177) : null}</span>
    //                   </h4>
    //                 </div>
    //                     <div className="text-md-right action2-blk">
    //                       <div className="ticket-view-btn d-flex align-items-center">
    //                         <div className="add-new-ticket-bx">
    //                           <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
    //                             <i className="fal fa-angle-left"></i>Back
    //                           </button>
    //                         </div>
    //                       </div>
    //                     </div>
    //               </div>
    //             </div>
    //             <div className="tabs-wrap">
    //                 <>
    //                   <div className="tab-content" id="pills-tabContent">
    //                     <div className={"tab-pane fade active show"} id="pills-tab13" role="tabpanel" aria-labelledby="pills-tab3">
    //                       {(page === "open-copied-intake" || page === "list-copied-intake" || page === "audittrails" || page === "all-copied-logs") && <IntakeCopied changeName={changeTabName} />}
    //                     </div>
    //                   </div>
    //                 </>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
};
export default IntakeCopy;
