import React, { useState, useEffect, useMemo  } from "react";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import DataTable from "react-data-table-component";
import { TicketListIntakeSubjectCancelToken } from "../../../services/SupportService";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { GetCommonContentReReg, GetCommonContentReRegCancelToken } from "../../../services/StudentsSettingServices";
import Tablefilter from "../../common/Tablefilter";
import { RenderLearningMethod } from "../../../utils/CommonGroupingItem";
import { Link } from "react-router-dom";
import { TrimText } from "../../common/TrimText";
import $ from 'jquery';
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const CourseModalTable = ({setCourseName}) => {
  const [search, setSearch] = useState("");
  const [studyType, setStudyType] = useState([]);
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [intakeArr, setIntakeArr] = useState([]);
  const [studyTypeArr, setStudyTypeArr] = useState([]);
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortkey, setSortKey] = useState("name");
  const [sortOrder, setSortOrder] = useState("ASC");

  // const [selectedRows, setSelectedRows] = useState([]);

  const [data, setData] = useState([]);

  const [selectedID, setselectedID] = useState(null);
  const [selectedName, setselectedName] = useState(null);
  const [selectedIntake, setSelectedIntake] = useState(null)

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    const cancelTokenSources = [];

    const getData = async () => {

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetCommonContentReRegCancelToken(source.token);
        if (res.status == 200) {
          let tempArr = []
          res.data.intake_year && res.data.intake_year.length && res.data.intake_number && res.data.intake_number.length && res.data.intake_year.map(year => {
            res.data.intake_number.map(number => {
              tempArr.push({ label: `${year.label}-${number.label}`, value: `${year.value}/${number.value}` })
            })
          })
          setIntakeArr(tempArr);
          setStudyTypeArr(res.data.study_type);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }

    getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getData = async () => {
      setLoading(true);

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      const apiData = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        intake: intake.arr.length ? intake.arr : [],
        learning_method: learningMethod.arr.length ? learningMethod.arr : [],
        exportStatus: "false"
      }

      try {
        const res = await TicketListIntakeSubjectCancelToken(apiData, source.token);
        if (res.status == 200) {
          setData(res?.data.data);
          setLoading(false);
          setTotalRows(res.data?.total);
          setPerPage(res.data?.perPage);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    }

    getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [page, perPage, sortOrder, sortkey, search, learningMethod, intake]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

const dataToRender = () => {
  let updatedData = [];
  let allData = data;
  if (search.length) {
    let tempId = allData.filter((item) => {
      let includes = item.id.toString().includes(search.toLowerCase());
      if (includes) {
        return includes;
      } else return null;
    });
    let tempCoursename = allData.filter((item) => {
      let includes = item.name && item.name.toLowerCase().includes(search.toLowerCase());
      if (includes) {
        return includes;
      } else return null;
    });


    let data = [...tempId, ...tempCoursename];
    let unique = [...new Set(data)];

    updatedData = unique;
  } else {
    updatedData = allData;
  }

  if (intake.length) {
    let tempRole = updatedData;
    let tempSearch = tempRole.filter((item) => {
      const startsWith = intake.find(function (user, index) {
        if (user.toLowerCase() == (item.year ? item.year.toLowerCase() : "")) return item;
      });
      if (startsWith) {
        return startsWith;
      } else return null;
    });

    updatedData = tempSearch;
  }

  if (studyType.length) {
    let tempRole = updatedData;
    let tempSearch = tempRole.filter((item) => {
      const startsWith = studyType.find(function (user, index) {
        if (user.toLowerCase() == (item.type ? item.type.toLowerCase() : "")) return item;
      });
      if (startsWith) {
        return startsWith;
      } else return null;
    });

    updatedData = tempSearch;
  }

  if (search.length || intake.length || studyType.length) {
    return updatedData;
  } else {
    return data;
  }
};

const sortingUser = (rowA, rowB) => {
  const name1 = rowA.name.toString().trim();
  const name2 = rowB.name.toString().trim();
  return name1.localeCompare(name2);
};

const columns = useMemo(() => [
    {
      name: "",
      cell: (row) => <div onClick={() => {setselectedID(row.id);setselectedName(row.name); setSelectedIntake(row.intake_id)}} ><input type="radio" value={row.id} name="ID" /></div>,
    },
    // {
    //   name: "ID",
    //   selector: (row) => row.id,
    // },
    {
      name: "Course Name",
      selector: "name",
      sortable: true,
      sortField: "name",
      sortFunction: sortingUser,
      cell: (row) => <Link to={`/courseAdministration/coursesdetails/${row?.intake_id}/detailCourses/show`} onClick={() => {$("#add-course").modal("hide");}} className="as-text-blue curser "><span className="textLimit100" title={row.name}>{row?.name ? row?.name : "-"}</span></Link>,
    },
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      sortField: "intake",
      cell: (row) => (
        <p>
          {row.year || row.number ? (
            <>
              {" "}
              {row.year ? row.year : ""}-{row.number ? row["number"] : ""}
            </>
          ) : (
            "-"
          )}
        </p>
      ),
    },
    {
      name: "Learning Method",
      selector: "type",
      sortable: true,
      sortField: "type",
      cell: (row) => (
        <p className="learningMethodText">
          {row && row.type ? RenderLearningMethod(row.type).html : "-"}
        </p>
      ),
    },

    // {
    //   name: "Intake Number",
    //   selector: (row) => row.IntakeNumber,
    // },
   
  ],[]);



  const handleSubmit = (e) => {
    // e.preventDefault();
    // let courses = selectedRows;
    setCourseName([{name: selectedName , id : selectedIntake, intakeId : selectedIntake}]);
  }

  const resetFilter = () => {
    setSearch("");
    setLearningMethod({ arr: [], checkObj: {} });
    setIntake({ arr: [], checkObj: {} });
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card-table-custom ">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
        <div className="system-administration-table table-responsive">
          <div className="table-responsive-div">
            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                <div id="assessment-table-main_filter" className="dataTables_filter">
                  <label>
                    <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                  </label>
                  <div className="filter-eff filter-data-btn">
                    <button className="filter-buttons">
                      <i className="fal fa-filter"></i>
                    </button>
                  </div>
              </div>
            </div>
          </div>
          <div className="filter-button-group">
            <div className="filter-scroll">
                <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName={"Intake"}
                      optionArr={intakeArr}
                      state={intake}
                      setState={setIntake}
                      isOptionReversed={true}
                    />
                    <Tablefilter
                      filterName="Learning Method"
                      optionArr={studyTypeArr}
                      state={learningMethod}
                      setState={setLearningMethod}
                      renderLabelFunction={RenderLearningMethod}
                    />
                </div>
            </div>
            <div className="reset-btn-group">
              <div className="button-reset dropdown-comman">
                <button className="btn btn-primary" onClick={()=>resetFilter()} title="Reset">
                  <i className="fal fa-redo"></i>Reset
                </button>
              </div>
            </div>
          </div>
        </div>
          
           
          </div>
        </div>
       
        
        <DataTable 
          paginationDefaultPage={page}
          defaultSortField="name" 
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          columns={columns} 
          onSort={handleSort}
          pagination
          paginationServer
          data={data} 
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE} 
        />
      </div>
      <button className="btn btn-primary mt-3" onClick={handleSubmit} data-dismiss="modal" title="Submit">
        <i className="fal fa-save"></i> Submit
      </button>
      <button className="btn btn-danger mt-3 ml-2" onClick={handleSubmit} data-dismiss="modal" title="Cancel">
      <i className="fal fa-times"></i>Cancel
      </button>
    </div>
  );
};

export default CourseModalTable;
