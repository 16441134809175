import React, { useState, useMemo, useEffect } from "react";
import { getCurrentTimeHomePage, InitialRender } from "../common/Helper";
import { Link, useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {
  Color,
  ColorRender,
} from "../systemadministration/usermanagement/CheckRole";
import { GetList, GetListOfCourses } from "../../services/CourseService";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from "../../utils/Constants";
import moment from "moment";
import Swal from "sweetalert2";
import PermissionsGate from "../../utils/permissionGate";
import ClassReportTabs from "./DetailCoursesBlocks/OnlineClassesBlocks/ClassReportTabs";
import { TrimText } from "../common/TrimText";
import { GetClassParticipants, UpdateClassRecordStatus } from "../../services/CourseService";
import Hover from "../common/Hover";
import { download } from "../../utils/FileDownload";
const downloadFileAuto = require("downloadify");


const ClassReport = () => {
  const { subTab } = useParams();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false)
  const [classReport, setClassReport] = useState([]);
  const getClassData = () => {
    GetClassParticipants(subTab)
      .then((res) => {
        let tempArr = [res.data]
        setClassReport(tempArr);
        setLoading(true)
      })
      .catch((err) => {
        console.log(err);
        setLoading(true)
      });

  }


  useEffect(() => {
    getClassData();
  }, []);
  // const dataToRender = () => {
  //   let updatedData = [];
  //   let allData = ClassReportRow;
  //   if (search.length) {
  //     let tempClass = allData.filter((item) => {
  //       let includes =
  //         item.class_name &&
  //         item.class_name
  //           .toString()
  //           .toLowerCase()
  //           .includes(search.toLowerCase());
  //       if (includes) {
  //         return includes;
  //       } else return null;
  //     });
  //     let tempName = allData.filter((item) => {
  //       let includes =
  //         item.name &&
  //         item.name.toString().toLowerCase().includes(search.toLowerCase());
  //       if (includes) {
  //         return includes;
  //       } else return null;
  //     });
  //     // let tempAttendanceHealth = allData.filter((item) => {
  //     //   let includes = item.AttendanceHealth.toString().toLowerCase().includes(search.toLowerCase());
  //     //   if (includes) {
  //     //     return includes;
  //     //   } else return null;
  //     // });
  //     // let tempStatus = allData.filter((item) => {
  //     //   let includes = item.Status.toString().toLowerCase().includes(search.toLowerCase());
  //     //   if (includes) {
  //     //     return includes;
  //     //   } else return null;
  //     // });
  //     // let tempCourseStatus = allData.filter((item) => {
  //     //   let includes = item.CourseStatus.toString().toLowerCase().includes(search.toLowerCase());
  //     //   if (includes) {
  //     //     return includes;
  //     //   } else return null;
  //     // });
  //     // let data = [...tempStudent, ...tempEmail, ...tempStatus, ...tempAttendanceHealth, ...tempCourseStatus];
  //     let data = [...tempClass, ...tempName];
  //     let unique = [...new Set(data)];

  //     updatedData = unique;
  //   } else {
  //     updatedData = allData;
  //   }

  //   if (search.length) {
  //     return updatedData;
  //   } else {
  //     return ClassReportRow;
  //   }
  // };
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };
  const resetFilter = () => {
    setSearch("");
  };
  const downloadRecord = (url) => {
    downloadFileAuto("https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4", "profile_banner").then(p => {
      // => File saved as profile_banner.jpeg
    }).catch(console.error)
  }

  const onChangeStatus = (id, recordingChecked) => {


    UpdateClassRecordStatus(id, recordingChecked == 1 ? false : true).then((response) => {
      // getOnlineClassesList();
      getClassData();
    }).catch(error => {
      console.log("error", error)
    })
  };

  const columnsReport = useMemo(() => [
    // {
    //   name: "ID",
    //   selector: "id",
    //   sortable: true,
    //   cell: (row) =>
    //     row.online_classes_participants ? (
    //       <div className="ticket-id">
    //         <span
    //           className="overflow-ellipsis2"
    //           title={row.online_classes_participants.id}
    //         >
    //           <b>{TrimText(row.online_classes_participants.id, 20)}</b>
    //         </span>
    //       </div>
    //     ) : (
    //       "-"
    //     ),
    // },
    {
      name: "Class Name",
      cell: (row) =>
        row.online_classes_participants ? (
          <span className="overflow-ellipsis2">
            {row.online_classes_participants.class_title ?
              <div title={row.online_classes_participants.class_title}>
                {TrimText(row.online_classes_participants.class_title, 15)}
              </div> : "-"}
            {/* <br />
            <div title={subTab}>{TrimText(subTab, 15)}</div> */}
          </span>
        ) : (
          "-"
        ),
    },

    {
      name: "Lecturer",
      cell: (row) => (
        row.lecturer_detail && row.lecturer_detail.length ?
          row.lecturer_detail.map((val) => (<div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender(row.role_name)}>
                {row.picture_me ? <img src={`${IMAGE_URL}/${row.picture_me.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([val.Firstname, val.Lastname])}
                <span className={`profile-box-2-status ${row.Status ? row.Status : "Offline"}`}>
                  <i className="fas fa-circle"></i>
                </span>
                <Hover firstName={val.Firstname} lastName={val.Lastname} photo={val.picture_me} email={val.Email} mobile={val.Mobile} status={val.status == "Active"} right={true} />
                {/* <div className="assigned-title-info popup-right">
              <article>
                <figure className={ColorRender(row.role_name)}>
                  {row.picture_me ? <img src={`${IMAGE_URL}/${row.picture_me.replace("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.First, row.last_name])}
                </figure>
                <figcaption>
                  <h4>
                    {row.First} {row.last_name}
                  </h4>
                  <a href="javascript:void(0);" onClick={() => navigator.clipboard.writeText(row.email)}>
                    <i className="fal fa-envelope"></i> {row.email}
                  </a>
                  <a href="javascript:void(0);" onClick={() => navigator.clipboard.writeText(row.mobile)}>
                    <i className="fal fa-phone-alt"></i> {row.mobile ? row.mobile : "-"}
                  </a>
                  <h6 className={row.status == "Active" ? "as-green-bg as-widget" : row.status == "Inactive" ? "as-red-bg as-widget" : "as-blue-bg as-widget"}>{row.status}</h6>
                </figcaption>
              </article>
            </div> */}
              </span>

              <p className="as-text-blue curser " >
                {val.Firstname + " " + val.Lastname}
              </p>

            </div>
          </div>)) : "-"


      ),
    },
    {
      name: "Class Date",
      cell: (row) => (row.online_classes_participants ? <div title={row.online_classes_participants ? row.online_classes_participants.class_date + "," + row.online_classes_participants.class_from + "-" + row.online_classes_participants.class_to : ""}><p>{moment(row.online_classes_participants ? row.online_classes_participants.class_date : "").format(
        TABLE_DATE_FORMAT
      )}</p>
        <p>{
          row.online_classes_participants.class_from +
          "-" +
          row.online_classes_participants.class_to}</p></div> : "-")
    },
    {
      name: "BBB Server",
      cell: (row) => (row.online_classes_participants ?
        <p title={row.online_classes_participants?.server_display_name}>
          {row.online_classes_participants?.server_display_name}          
        </p> : "NA")
    },
    {
      name: "Class ID",
      cell: (row) => (row.online_classes_participants.o_class_id ?
        <p title={row.online_classes_participants?.o_class_id}>
          {row.online_classes_participants?.o_class_id}          
        </p> : "NA")
    },
    {
      name: "Class Status",
      cell: (row) =>
        row.class_status ? (

          <span
            className={
              row.class_status.toLowerCase() === "past"
                ? "as-past-bg as-widget mr-2"
                : row.class_status.toLowerCase() === "upcoming"
                  ? "as-upcoming-bg as-widget"
                  : "as-blue-bg as-widget"
            }
          >
            {row.class_status}
          </span>

        ) : (
          <p className="cat">-</p>
        ),
    },
    // {
    //   name: "Class Type",
    //   cell: (row) =>
    //     row?.online_classes_participants &&
    //     row?.online_classes_participants.class_type ? (
    //       <span
    //         className={
    //           row.class_type.toLowerCase() ===
    //           "hybrid"
    //             ? "as-red-bg as-widget"
    //             : row.class_type.online_classes_participants.toLowerCase() ===
    //               "virtual"
    //             ? "as-green-bg as-widget"
    //             : row.class_type.online_classes_participants.toLowerCase() ===
    //               "On Campus"
    //             ? "as-blue-bg as-widget"
    //             : ""
    //         }
    //       >
    //         {row.online_classes_participants &&
    //         row.online_classes_participants.class_type
    //           ? row.online_classes_participants.class_type
    //           : "NA"}
    //       </span>
    //     ) : (
    //       ""
    //     ),
    // },
    {
      name: "Class Recorded",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              disabled={row.class_status == "live-launched" ? false : true}
              // title="Yes"
              className={
                row?.online_classes_participants && row?.online_classes_participants.isRecorded && row?.online_classes_participants.isRecorded + "" === "1"
                  ? "btn btn-success rounded-circle btn-status-1"
                  : "btn btn-danger rounded-circle btn-status-1"
              }
              onClick={() => { onChangeStatus(row.big_blue_button_id, row.isRecordingChecked) }}
            >
              {row.view_url ? (
                <>
                  <i className="fal fa-check"></i> Yes
                </>
              ) : (
                <>
                  <i className="fal fa-times"></i> No
                </>
              )}
            </button>
          </div>
        </div>
      ),
    },
    {
      name: "Recording",
      cell: (row) => <span>
        {row.class_type == "on_campus" ? <button disabled className="btn btn-primary rounded-circle mr-1" title="View Recording"> <i className="fal fa-eye"></i></button> : <a href={row.view_url} target="_blank" > <button disabled={!row.class_type || !row.view_url} className="btn btn-primary rounded-circle mr-1" title="View Recording"> <i className="fal fa-eye"></i></button></a>}
        <button disabled={!row.class_type || !row.download_url || row.class_type == "on_campus"} className="btn btn-primary rounded-circle mr-1" title="Download Recording" onClick={() => download(row.download_url, "text")}>
          <i className="fal fa-arrow-down"></i>
        </button>
      </span>
    },
    // {
    //   name:"Download Recording",
    //   cell:(row)=>(

    //     // <Link to={{pathname: row.download_url}} target="_blank" download>

    //     // </Link>
    //     )
    // },
    {
      name: "Attendance Summary",
      cell: (row) => (
        (row.participants_total && row.non_participants_total) ? <p>
          {row.participants_total}/{row.non_participants_total} (
          {((row.participants_total / row.non_participants_total) * 100).toFixed(2)})%
        </p> : "-"
      ),
    },
    {
      name: "Class View",
      cell: (row) => (
        (row.count_views && row.counts_total) ? <>
          {row.count_views}/{row.counts_total}{" "}
        </> : "-"
      ),
    },
    {
      name: "Download",
      cell: (row) => (
        (row.count_downloads && row.counts_total) ? <>
          {" "}
          {row.count_downloads}/{row.counts_total}{" "}
        </> : "-"
      )
    },
    // {
    //   name: "Actions",
    //   cell: (row) => (
    //     <div className="assessment-08 btn-dropdown-grp">
    //       <div className="as-buttons d-flex">
    //         <button
    //           className="btn btn-primary rounded-circle"
    //           title="Download Recording"
    //         >
    //           <i className="fal fa-arrow-down"></i>
    //         </button>
    //         <button
    //           className="btn btn-primary rounded-circle"
    //           title="Class Recording"
    //         >
    //           <i className="fal fa-video"></i>
    //         </button>
    //       </div>
    //     </div>
    //   ),
    // },
  ]);

  return (
    <div>
      {/* <div className="card-new card card-profile-info-card mb-3 course-dashborad">
        <div className="card-body">
          <div className="card-header">Class Report</div>

          <div className="custom-table-div filter-search-icon card card-table-custom course-table-filter-sec">
            {loading ? (
              <DataTable
                data={classReport}
                columns={columnsReport}
                paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              />
            ) : <SkeletonTicketList />}
          </div>
        </div>
      </div> */}

      <ClassReportTabs />
    </div>
  );
};

export default ClassReport;
