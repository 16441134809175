import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import Header from '../../common/Header'
import { getCurrentTimeHomePage } from '../../common/Helper'
import SideBar from '../../common/SideBar'
import { getBrandsTabs, getBrandsTabsCancelToken, reArrangeStudentHub } from '../../../services/StudentService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { reorder } from '../../../utils/ReArrangeFunction';
import Swal from 'sweetalert2';

import SkeletonTicketList from '../../../loaders/SkeletonTicketList';
import { useSelector } from 'react-redux';
import hasPermission from '../../../utils/hasMultiplePermission';
import axios from 'axios';

export default function RearrangeTab() {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);


    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
        let response = hasPermission({
        scopes: ["stuhmreregedit"],
        permissions: givenPermsisions,
        });
        if (!response) {
        history.push("/noaccess");
        }else{
            const source = axios.CancelToken.source();
            const getData = async () => {
                try {
                    const res = await getBrandsTabsCancelToken(source.token);
                    if (res.status === 200) {
                        setData(res.data.student_hub);
                        setIsLoaded(true);
                    }
                } catch (error) {
                    if (!axios.isCancel(error)) {
                        console.error(error);
                    }
                }                
            }
            getData()

            return () => {
                source.cancel('Component unmounted');      
            }
        }
    }, []);

    const handleDragEnd = (result, idx) => {
        if (!result.destination) {
            return;
        }
        const dataList = [...data];
        const dataItem = { ...dataList[idx] }
        dataItem.AssignTabBrands = reorder(dataItem.AssignTabBrands, result.source.index, result.destination.index);
        dataList[idx] = dataItem;
        setData(dataList);
    }

    const handleSave = () => {
        try {
            setIsDisabled(true);
            const result = data.map((brand) => {
                return {
                    brand_id: brand.id,
                    tabs: brand.AssignTabBrands.map((tab) => tab.tab_id)
                }
            });

            const sendData = {
                dataSequence: result
            }
            reArrangeStudentHub(sendData).then((res) => {
                if (res.status === 200) {
                    setIsDisabled(false);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: res.data?.message,
                        onClose : ()=>{history.push("/support/student-hub-library/student-hub/list")}
                    });
                }
            }).catch((err) => {
                console.log(err?.response?.data[0]?.message);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${err?.response?.data[0]?.message}`,
                });
                console.log(err);
            })

        } catch (err) {
            console.log(err);
        }

    }

    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div className="sidebar-right">
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">Student Hub: <span>Re-arrange Tabs</span></h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                                        <i className="fal fa-angle-left"></i>Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    <div className="my-tickets-info-list Tickets-main-wrap">
                                        <div className="card card-profile-info-card">
                                            {isLoaded ? data.map((element, idx) => (
                                                <>
                                                    <DragDropContext key={idx} onDragEnd={(result) => handleDragEnd(result, idx)}>
                                                        <Droppable droppableId="droppable" direction="horizontal">
                                                            {(provided, snapshot) => (
                                                                <div ref={provided.innerRef} {...provided.droppableProps} className="d-flex re-arrange-container">
                                                                    <div className='re-arrange-header'>{element?.brand_name}</div>
                                                                    {element.AssignTabBrands.map((item, i) => (
                                                                        <Draggable key={i} draggableId={"item" + i} index={i}>
                                                                            {(provided, snapshot) => (
                                                                                <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps} className={"re-arrange-items " + (i === 0 ? "first-rearrange" : "") + (snapshot.isDragging ? " dragging" : "")}>
                                                                                    <i className={item?.StudentHubTab?.icon}></i>{" " + item?.StudentHubTab?.tab_name}
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                </div>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                    {(idx < data.length - 1) && <hr />}
                                                </>
                                            )) : <SkeletonTicketList />}
                                            <div className="form-group-save-cancel mt-3">
                                                <button className="btn btn-save btn-success" type="button" title="Save" disabled={isDisabled} onClick={() => { handleSave() }} >
                                                    {isDisabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                                                    Save
                                                </button>
                                                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()} >
                                                    <i className="fal fa-times"></i>
                                                    Cancel
                                                </button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
