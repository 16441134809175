import React from "react";
import { Link } from "react-router-dom";
import BackgroundImage from "../assets/images/login-bg.jpg";
import HeaderImage from "../assets/images/card-header-public.png";
import {
    getStudentDetails
} from "../services/GeneralService.js";
import {
    capitalize,
    nationalityList,
    disabilityList,
    languageList,
  } from "../utils/commonFunction";
import { connect } from "react-redux";


/** map actions */
// function mapDispatchToProps(dispatch) {
//   return {
//     setUser: (payload) => dispatch(setUser(payload)),
//   };
// }

/** map state */
const mapStateToProps = (state) => ({ brandVisualsConfig: state.brandVisuals.config });

//const mapStateToProps = (state) => ({ user: state.user });

class StudentVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        student: {},
        nationalityList: nationalityList(),
      };
  }
  componentDidMount() {
    const { match } = this.props;
    getStudentDetails(match.params)
    .then((res) => {
        this.setState({ student: res.data });
        //console.log('Jytoi',res,this.state.student.id)
      })
      .catch((error) => {
      });
  }
  render() {
    return (
      <>
        <div
          className="portal-login portal-public-wrap d-flex align-items-center"
          style={{ "background-image": "url(" + (this.props?.brandVisualsConfig?.background_logo ? this.props.brandVisualsConfig.background_logo : BackgroundImage) + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="card portal-login-card text-center">
                  <div className="card-header">
                    <div className="card-header-img">
                      <img src={this.props?.brandVisualsConfig?.login_header ? this.props.brandVisualsConfig.login_header : HeaderImage} />
                    </div>
                  </div>
                   <div className="card-body text-left">
                       {this.state.student==null || this.state.student=='' || Object.keys(this.state.student).length === 0 ? 
                       (<p><strong>The authenticity of the student details cannot be verified.</strong></p>):(
                      <div className="card card-profile-info-card ">
                        <div className="row">
                          <div className="form-group col-sm-6">
                            <label>Full Name</label>
                                <div className="label-selected form-control">
                                    {this.state.student.first_name+ " "+
                                    this.state.student.second_name+ " "+ 
                                    this.state.student.third_name+ " "+ 
                                    this.state.student.last_name
                                    }
                                </div>
                          </div>
                          <div className="form-group col-sm-6">
                            <label>Title</label>
                            <div className="label-selected form-control">
                                  {this.state.student.title}
                              </div>
                          </div>

                          <div className="form-group col-sm-6">
                            <label>Id Number</label>
                            <div className="label-selected form-control">
                                  {this.state.student.number}
                              </div>
                          </div>
	
                          <div className="form-group col-sm-6">
                            <label>Date Of Birth</label>
                            <div className="label-selected form-control">
                                {this.state.student.bday_day+ "/"+
                                this.state.student.bday_month+ "/"+ 
                                this.state.student.bday_year
                                }
                                </div>
                          </div>

                          <div className="form-group col-sm-6">
                            <label>Race</label>
                            <div className="label-selected form-control">
                                {capitalize(this.state.student.race)}
                            </div>
                          </div>
      
                          <div className="form-group col-sm-6">
                            <label>Nationality</label>
                            <div className="label-selected form-control">
                                {`${this.state.student.nationality}` in
                                this.state.nationalityList ==
                                true
                                ? this.state.nationalityList[
                                    this.state.student.nationality
                                    ]
                                : null}
                            </div>
                          </div>
	
                          <div className="form-group col-sm-6">
                            <label>Gender</label>
                            <div className="label-selected form-control">
                                {capitalize(this.state.student.gender)}
                            </div>
                          </div>
              
                          <div className="form-group col-sm-6">
                            <label>Home Language</label>
                            <div className="label-selected form-control">
                                  {capitalize(this.state.student.language)}
                                  </div>
                          </div>
                      </div>
                      </div>
                      )}
					        </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps) (StudentVerify);
