import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { AddaQuestion, GetAssessmentAssignedOrNotAssignedList, GetAssessmentAssignedOrNotAssignedListCancelToken, GetListOfCourses, GetListOfCoursesCancelToken, GetQuizTopics, GetQuizTopicsCancelToken, UpdateQuestion, ViewaQuestion, ViewaQuestionCancelToken } from "../../../../services/SmartAssessmentService";
import { ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState, AtomicBlockUtils } from "draft-js";
import { useFormik } from "formik";
import Select from "react-select";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { tryParseJSONObject } from "../../../../utils/TryParseJSONObject";
import Assigned from "./AssessmentTables/Assigned";
import Swal from "sweetalert2";
import * as Yup from "yup";
import $ from 'jquery';
import DisplayQuestion from "../DisplayQuestion";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../../utils/permissionGate";
import HtmlParser from "react-html-parser";
import { RenderPMCStatus } from "../../../../utils/CommonGroupingItem";
import { CKEditor } from "ckeditor4-react";
import { API_BASE, IMAGE_URL } from "../../../../utils/Constants";
import RenderOption from "./RenderOption";
import { TrimText } from "../../../common/TrimText";
import axios from "axios";

const ViewQuestion = () => {

  const { subtab, id, tabName, tab } = useParams();
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState({
    type: "assigned",
    viaStatus: [],
    page: 1,
    limit: 10,
    search: "",
    key: "quiz_name",
    sort: "ASC",
    questionId: id,
    exportStatus: false
  });
  const [selectedQ, setSelectedQ] = useState();
  const [courses, setCourses] = useState([]);
  const [selectedAns, setselectedAns] = useState([]);
  const [checkedAns, setCheckedAns] = useState([]);
  const [editorState1, setEditorState1] = React.useState(""); // question
  const [editorState2, setEditorState2] = React.useState(""); // description
  const [editorState3, setEditorState3] = React.useState({}); // options array
  const [optionArr, setOptionArr] = useState([]);
  const [assigneddata, setAssignedData] = useState();
  const [availabledata, setAvailabledata] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [questionData, setQuestionData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [totalRows2, setTotalRows2] = useState(0);
  const [totalRows1, setTotalRows1] = useState(0);
  const [possibleAnswers, setPossibleAnswers] = useState([]);
  const [selectKeywords, setSelectedKeywords] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [quizTopics, setQuizTopics] = useState([]);
  const [isEditable, setIsEditable] = useState(true);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const [loading, setLoading] = useState(true)
  const [fillOption, setFillOption] = useState(null);
  const [equationEditor, setEquationEditor] = useState("");
  const [matrixData, setMatrixData] = useState([]);
  const [orderState, setOrderState] = useState([]);
  const [viewDocument, getViewDocument] = useState([]);
  const [ckstate, setCkState] = useState({})
  const [uploadDoc, setUploadDoc] = useState([]);
  const [uploadDocName, setUploadDocName] = useState([]);
  const [uploadDocPath, setUploadDocPath] = useState([]);
  const [data2, setData2] = useState({
    type: "available",
    viaStatus: [],
    page: 1,
    limit: 10,
    search: "",
    key: "quiz_name",
    sort: "ASC",
    questionId: id,
    exportStatus: false
  });

  const multiSelectColorStyles = {      
    multiValueLabel: (styles, { data }) => ({
      ...styles,
        '& span':{
          background: 'transparent !important',
          color: '#333 !important',
        }
    }),
  };

  // useEffect(() => {
  //   GetQuizTopics().then((res) => {
  //     // let tempArr = res?.data?.sort(sort_by("options",false,(a)=>a.toUpperCase())).map(item=>{return {value:item?.id,label:HtmlParser(`<span style="color:#fff;background:${item?.color};" title=${item?.options}>${item?.options}</span>`)}});
  //     let tempArr = res?.data?.map(item=>{return {value:item?.id,label:HtmlParser(`<span style="color:#fff;background:${item?.color};" title=${item?.options}>${item?.options}</span>`)}});
  //     setQuizTopics(tempArr)
  //   }).catch((err) => {
  //     console.log("err", err)
  //   })
  // }, [])

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetQuizTopicsCancelToken({},dropdownSource.token);
        if(res.status == 200){
          let tempArr = res?.data?.map(item=>{return {value:item?.id,label:HtmlParser(`<span style="color:#fff;background:${item?.color};" title=${item?.options}>${item?.options}</span>`)}});
          setQuizTopics(tempArr)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);


  useEffect(() => {
    if (subtab === "view") {
      let response = hasPermission({ scopes: ["cquestionbview"], permissions: givenPermsisions });
      if (!response) {
        history.push("/noaccess");
      }
    }
  }, [])

  const handleKeyDown1 = (event) => {
    var keyword = selectKeywords;
    if (event.key === "Enter") {
      if (event.target.value) {
        keyword.push(event.target.value);
        event.target.value = ""
        formik.setFieldValue("keywords", keyword);
      }
    }
  };

  const removeKeyword = (index) => {
    var keyword = selectKeywords;
    var keywords = keyword.splice(index, 1)
    formik.setFieldValue("keywords", keyword);
  };

  const removeOptionAnswer = (ind,ele,index) => {
    let deleteoption = fillOption
    deleteoption[ind].splice(index,1)
    setFillOption([...fillOption])  
  };

  const arrayOfEditors = () => {
    let arr = data.map((element) => {
      if (element) {
        return element.q_option;
      }
    });
    return arr;
  }
  const initialMatrixData = (rowCount, optionCount) => {
    const matrixData = [];
    for (let i = 1; i <= rowCount; i++) {
      const rowTitle = <input type="text"
      className={"form-control"}
      name="item"
      placeholder="Row"
      title="Row"
      // value={element.q_option}
      // onChange={(e) => {
      //   handleChangeNonLinked(e, i, "q_option")
      // }}
    />;
      // const rowTitle = `Row ${i}`;
      const options = [];
      for (let j = 1; j <= optionCount; j++) {
        options.push({ text: <input type="text"
        className={"form-control"}
        name="item"
        placeholder="Column"
        title="Column"
        // value={element.q_option}
        // onChange={(e) => {
        //   handleChangeNonLinked(e, i, "q_option")
        // }}
      />, isSelected: false });
      }
      matrixData.push({ rowTitle, options });
    }
    return matrixData;
  }

  // useEffect(() => {
  //   setIsApiLoaded(false)
  //   let tempCourses = []
  //   GetListOfCourses().then((res) => {
  //     let x = { value: "", label: "" };
  //     x = res?.data?.map((data) => ({ ...x, value: `${data.id}`, label: `${data.name}` }))
  //     setCourses(x);
  //     tempCourses = x;

  //     ViewaQuestion({ qId: id }).then((res) => {
  //       setData(res?.data);
  //       setSelectedQ(res?.data[0]?.question_type)
  //       // const keywordData = res.data[0].keywords.length > 0 ? res.data[0].keywords.split(",").map(item=>item.replaceAll('\"',"").replaceAll("\\","").replaceAll('[', "").replaceAll(']', "")) : [];
  //       // const selectedTopicData = res.data[0].topic.length > 0 ? res.data[0].topic.split(",").map(item => item.replaceAll('\"', "").replaceAll("\\","").replaceAll('[', "").replaceAll(']', "")) : [];
  //       const keywordData = (res.data[0].keywords && res.data[0].keywords.length > 0 && Array.isArray(JSON.parse(res.data[0].keywords))) ? JSON.parse(res.data[0].keywords).map(item=>item) : [];
  //       const selectedTopicData = (res.data[0].topic && res.data[0].topic.length > 0 && Array.isArray((res.data[0].topic).split(","))) ? ((res.data[0].topic).split(",")).map(item=>item) : [];
  //       getViewDocument(res?.data[0]?.document)
  //       setSelectedTopic(selectedTopicData)
  //       setSelectedKeywords(keywordData)
  //       if(res.data[0].document){
  //         try{
  //           const documentFilesName = JSON.parse(res.data[0].document).map(item=>(item.split("/").reverse()[0]))
  //           setUploadDoc(JSON.parse(res.data[0].document));
  //           setUploadDocPath(JSON.parse(res.data[0].document))
  //           setUploadDocName([...documentFilesName])
  //         }catch(err){console.log(err)}
  //       }
  //       if (res?.data[0]?.question_type === "Short Answer") {
  //         if (res.data[0].q_option !== null) {
  //           setPossibleAnswers(res.data[0].q_option.split("~@~"))
  //         }
  //       }
  //       // set options array
  //       if (res?.data[0]?.question_type === "Multiple Choice Single Answer" || res?.data[0]?.question_type === "Multiple Choice Multiple Answer") {
  //         let obj = {};
  //         let multiChoice = [];
  //         let arr1 = res.data.map((element, ind) => {
  //           if (element.correct_score && element.score) {
  //             setselectedAns(`customCheck${ind}`)
  //             if(res?.data[0]?.question_type === "Multiple Choice Multiple Answer"){
  //               multiChoice.push(`customCheck${ind}`)
  //             }
  //           }
  //           let check = tryParseJSONObject(element.q_option ? element.q_option : "");
  //           if (check) {
  //             const q_optionBlock = convertFromRaw(JSON.parse(element.q_option ? element.q_option : ""));
  //             return (EditorState.createWithContent(q_optionBlock));
  //           } else {
  //             obj['customCheck' + ind] = element.q_option;
  //             return element.q_option;
  //           }
  //         });
  //         setEditorState3(obj);
  //         setOptionArr(arr1)
  //         if(res?.data[0]?.question_type === "Multiple Choice Multiple Answer"){
  //           setCheckedAns([...multiChoice])
  //         }

  //       } else if (res?.data[0]?.question_type === "Match The Column" || res?.data[0]?.question_type === "Match the Column") {
  //         setEditorState3(res.data)
  //       }
  //       // let arr = res?.data?.map((o, ind) => o.score ? true : false);
  //       // setCheckedAns(arr);

  //       // let index = arr.indexOf(true);
  //       // setselectedAns(index.toString());

  //       // set question and description
  //       if (res.data[0]) {
  //         setEditorState1(res.data[0].question);
  //         setEditorState2(res.data[0].description);
  //       }
  //       if (res?.data[0]?.question_type === "Order List") {
  //         let finalObj = {}
  //         let tempArr = res?.data.map((item, i) => {
  //           finalObj['customCheck' + i] = item.q_option;
  //           return item.q_option
  //         })
  //         setOrderState(tempArr)
  //         setEditorState3(finalObj)
  //       }
  //       if (res?.data[0]?.question_type === "Equation") {
  //         setEquationEditor(res?.data[0]?.q_option)
  //       }
  //       if (res?.data[0]?.question_type === "Choice Matrix") {
  //         let tempArr = []
  //         let arr = res?.data.map((item, i) => {
  //           let tempObj = {
  //             titleValue: item.titleValue, 
  //             optionid: item.oid,
  //             rowTitle: <input type="text"
  //                         className={"form-control"}
  //                         name="item"
  //                         placeholder="Row"
  //                         title="Row"
  //                         defaultValue={item.titleValue}
  //                         onChange={(e) => {
  //                           handleUpdateRowName(e, i, tempArr)
  //                         }}
  //                       />, 
  //             options: item?.q_option ? 
  //                       JSON.parse(item?.q_option).length ? 
  //                         JSON.parse(item?.q_option).map((ele, j) => {
  //                           return {
  //                             ...ele, 
  //                             text: <input type="text"
  //                                     className={"form-control"}
  //                                     name="item"
  //                                     placeholder="Column"
  //                                     title="Column"
  //                                     defaultValue={ele?.titleValue}
  //                                     value={matrixData[i]?.options[j].titleValue}
  //                                     onChange={(e) => {
  //                                       handleUpdateColumnName(e, i, tempArr, j)
  //                                     }}
  //                                   />
  //                           }
  //                         }) : 
  //                       [] : 
  //                     []
  //           }
  //           tempArr.push(tempObj);
  //           return;
  //         })
  //       setMatrixData(tempArr)
  //         // setMatrixData(initialMatrixData(res.data?.length, JSON.parse(res.data[0]?.q_option)?.length))
  //       }
  //       if (res?.data[0]?.question_type === "Fill in the blank") {
  //         let tempArr = res?.data.map(item => {
  //           return item?.q_option ? JSON.parse(item?.q_option) : []
  //         })
  //         setFillOption(tempArr)
  //       }
  //       setIsApiLoaded(true)
  //       setLoading(false)
  //     });
  //   })
  // }, []);

  useEffect(() => {
    const courseSource = axios.CancelToken.source();
    const viewQuesSource = axios.CancelToken.source();
    setIsApiLoaded(false)

    const fetchCourseData = async () => {
      let tempCourses = []
      try {
        const res = await GetListOfCoursesCancelToken(courseSource.token);
        if(res.status == 200){
          let x = { value: "", label: "" };
          x = res?.data?.map((data) => ({ ...x, value: `${data.id}`, label: `${data.name}` }))
          setCourses(x);
          tempCourses = x;
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const fetchViewAQuestion = async () => {
      try {
        const res = await ViewaQuestionCancelToken({ qId: id }, viewQuesSource.token);
        if(res.status == 200){
          setData(res?.data);
          setSelectedQ(res?.data[0]?.question_type)
          // const keywordData = res.data[0].keywords.length > 0 ? res.data[0].keywords.split(",").map(item=>item.replaceAll('\"',"").replaceAll("\\","").replaceAll('[', "").replaceAll(']', "")) : [];
          // const selectedTopicData = res.data[0].topic.length > 0 ? res.data[0].topic.split(",").map(item => item.replaceAll('\"', "").replaceAll("\\","").replaceAll('[', "").replaceAll(']', "")) : [];
          const keywordData = (res.data[0].keywords && res.data[0].keywords.length > 0 && Array.isArray(JSON.parse(res.data[0].keywords))) ? JSON.parse(res.data[0].keywords).map(item=>item) : [];
          const selectedTopicData = (res.data[0].topic && res.data[0].topic.length > 0 && Array.isArray((res.data[0].topic).split(","))) ? ((res.data[0].topic).split(",")).map(item=>item) : [];
          getViewDocument(res?.data[0]?.document)
          setSelectedTopic(selectedTopicData)
          setSelectedKeywords(keywordData)
          if(res.data[0].document){
            try{
              const documentFilesName = JSON.parse(res.data[0].document).map(item=>(item.split("/").reverse()[0]))
              setUploadDoc(JSON.parse(res.data[0].document));
              setUploadDocPath(JSON.parse(res.data[0].document))
              setUploadDocName([...documentFilesName])
            }catch(err){console.log(err)}
          }
          if (res?.data[0]?.question_type === "Short Answer") {
            if (res.data[0].q_option !== null) {
              setPossibleAnswers(res.data[0].q_option.split("~@~"))
            }
          }
          // set options array
          if (res?.data[0]?.question_type === "Multiple Choice Single Answer" || res?.data[0]?.question_type === "Multiple Choice Multiple Answer") {
            let obj = {};
            let multiChoice = [];
            let arr1 = res.data.map((element, ind) => {
              if (element.correct_score && element.score) {
                setselectedAns(`customCheck${ind}`)
                if(res?.data[0]?.question_type === "Multiple Choice Multiple Answer"){
                  multiChoice.push(`customCheck${ind}`)
                }
              }
              let check = tryParseJSONObject(element.q_option ? element.q_option : "");
              if (check) {
                const q_optionBlock = convertFromRaw(JSON.parse(element.q_option ? element.q_option : ""));
                return (EditorState.createWithContent(q_optionBlock));
              } else {
                obj['customCheck' + ind] = element.q_option;
                return element.q_option;
              }
            });
            setEditorState3(obj);
            setOptionArr(arr1)
            if(res?.data[0]?.question_type === "Multiple Choice Multiple Answer"){
              setCheckedAns([...multiChoice])
            }

          } else if (res?.data[0]?.question_type === "Match The Column" || res?.data[0]?.question_type === "Match the Column") {
            setEditorState3(res.data)
          }
          // let arr = res?.data?.map((o, ind) => o.score ? true : false);
          // setCheckedAns(arr);

          // let index = arr.indexOf(true);
          // setselectedAns(index.toString());

          // set question and description
          if (res.data[0]) {
            setEditorState1(res.data[0].question);
            setEditorState2(res.data[0].description);
          }
          if (res?.data[0]?.question_type === "Order List") {
            let finalObj = {}
            let tempArr = res?.data.map((item, i) => {
              finalObj['customCheck' + i] = item.q_option;
              return item.q_option
            })
            setOrderState(tempArr)
            setEditorState3(finalObj)
          }
          if (res?.data[0]?.question_type === "Equation") {
            setEquationEditor(res?.data[0]?.q_option)
          }
          if (res?.data[0]?.question_type === "Choice Matrix") {
            let tempArr = []
            let arr = res?.data.map((item, i) => {
              let tempObj = {
                titleValue: item.titleValue, 
                optionid: item.oid,
                rowTitle: <input type="text"
                            className={"form-control"}
                            name="item"
                            placeholder="Row"
                            title="Row"
                            defaultValue={item.titleValue}
                            onChange={(e) => {
                              handleUpdateRowName(e, i, tempArr)
                            }}
                          />, 
                options: item?.q_option ? 
                          JSON.parse(item?.q_option).length ? 
                            JSON.parse(item?.q_option).map((ele, j) => {
                              return {
                                ...ele, 
                                text: <input type="text"
                                        className={"form-control"}
                                        name="item"
                                        placeholder="Column"
                                        title="Column"
                                        defaultValue={ele?.titleValue}
                                        value={matrixData[i]?.options[j].titleValue}
                                        onChange={(e) => {
                                          handleUpdateColumnName(e, i, tempArr, j)
                                        }}
                                      />
                              }
                            }) : 
                          [] : 
                        []
              }
              tempArr.push(tempObj);
              return;
            })
          setMatrixData(tempArr)
            // setMatrixData(initialMatrixData(res.data?.length, JSON.parse(res.data[0]?.q_option)?.length))
          }
          if (res?.data[0]?.question_type === "Fill in the blank") {
            let tempArr = res?.data.map(item => {
              return item?.q_option ? JSON.parse(item?.q_option) : []
            })
            setFillOption(tempArr)
          }
          setIsApiLoaded(true)
          setLoading(false)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsApiLoaded(true)
          setLoading(false)
        }
      }
    };
  
    fetchCourseData();
    fetchViewAQuestion();

    return () => {
      courseSource.cancel('Component unmounted');
      viewQuesSource.cancel('Component unmounted');
    };
  }, []);


  useEffect(() => {
    const assignedSource = axios.CancelToken.source();
    setLoading1(true);

    const fetchAssignedData = async () => {
      try {
        const res = await GetAssessmentAssignedOrNotAssignedListCancelToken(data1, assignedSource.token);
        if(res.status == 200){
          setAssignedData(res?.data?.result.data);
          setTotalRows1(res?.data?.result.total)
          setLoading1(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading1(false);
        }
      }
    };
  
    fetchAssignedData();

    return () => {
      assignedSource.cancel('Component unmounted');
    };
  }, [refresh, data1]);


  useEffect(() => {
    const availableSource = axios.CancelToken.source();
    setLoading2(true);

    const fetchAvailableData = async () => {
      try {
        const res = await GetAssessmentAssignedOrNotAssignedListCancelToken(data2, availableSource.token);
        if(res.status == 200){
          setAvailabledata(res?.data?.result.data);
          setTotalRows2(res?.data?.result.total)
          setLoading2(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading2(false);
        }
      }
    };
  
    fetchAvailableData();

    return () => {
      availableSource.cancel('Component unmounted');
    };
  }, [refresh, data2]);

  // useEffect(() => {
  //   setLoading1(true);
  //   GetAssessmentAssignedOrNotAssignedList(data1).then((res) => {
  //     setAssignedData(res?.data?.result.data);
  //     setTotalRows1(res?.data?.result.total)
  //     setLoading1(false);
  //   }).catch((err) => console.error(err));
  // }, [refresh, data1])

  // useEffect(() => {
  //   setLoading2(true);
  //   GetAssessmentAssignedOrNotAssignedList(data2).then((res) => {
  //     setAvailabledata(res?.data?.result.data);
  //     setTotalRows2(res?.data?.result.total)
  //     setLoading2(false);
  //   }).catch((err) => console.error("err from cathcquiz", err));
  // }, [refresh, data2])



  const handlerefresh = () => {
    setRefresh(!refresh);
  }

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let background = RenderPMCStatus(data.value).bgColor;
      let color = RenderPMCStatus(data.value).colorCode;

      return {
        ...styles,
        backgroundColor: background,
        color: color,
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  const nqfOptions = [
    { label: "NQF 1", value: "NQF 1", data: "1" },
    { label: "NQF 2", value: "NQF 2", data: "2" },
    { label: "NQF 3", value: "NQF 3", data: "3" },
    { label: "NQF 4", value: "NQF 4", data: "4" },
    { label: "NQF 5", value: "NQF 5", data: "5" },
    { label: "NQF 6", value: "NQF 6", data: "6" },
    { label: "NQF 7", value: "NQF 7", data: "7" },
    { label: "NQF 8", value: "NQF 8", data: "8" },
    { label: "NQF 9", value: "NQF 9", data: "9" },
    { label: "NQF 10", value: "NQF 10", data: "10" },
  ];

  const statusArr = [
    {
      value: "Draft",
      label: HtmlParser(`<span class="red" title="Draft">Draft</span> `)
    },
    {
      value: "New",
      label: HtmlParser(`<span class="skyblue" title="New">New </span>`)
    },
    {
      value: "Final",
      label: HtmlParser(`<span class="green" title="Final">Final</span>`)
    },
    {
      value: "Approved",
      label: HtmlParser(`<span class="yellowshade" title="Approved">Approved</span>`)
    },
    {
      value: "Inactive",
      label: HtmlParser(`<span class="blue" title="Inactive">Inactive</span>`)
    },
  ]

  const level = [
    { value: "1", label: "Easy" },
    { value: "3", label: "Medium" },
    { value: "2", label: "Hard" }
  ];
  let selectedTopics = [];
  let topicss = quizTopics?.map(item=>{
    return selectedTopic?.map(val=>{
      if(item.value == val){
        return selectedTopics.push(item);
      }
    })
  })

  const handleUpdateRowName = (e, index, matrixData22) => {
    matrixData22[index].titleValue = e.target.value
    // let tempArr = matrixData;
    // tempArr[index].titleValue = e.target.value
    // setMatrixData(() => [...tempArr])
  }
  
  const handleUpdateColumnName = (e, index, matrixData22, secondIndex) => {
    matrixData22.map(item=> {
      return item.options[secondIndex].titleValue = e.target.value
    })
  }


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      coursename: data[0]?.name ? data[0]?.name : "",
      lid: data[0]?.lid ? data[0]?.lid : "",
      levelname: data[0]?.level_name ? data[0]?.level_name : "",
      question_type: selectedQ,
      shortAnswer: data && data[0] && data[0].q_option !== null ? data[0].q_option.split("~@~") : "",
      matchItemArr: data ? data : [],
      selectedOption: data ? data.length : 0,
      editorState3: editorState3,
      question: "",
      isSaving: false,
      question_name: data[0]?.question_name ? data[0]?.question_name : "",
      nqf_level: data[0]?.nqf_level ? data[0]?.nqf_level.includes("NQF ") ? data[0]?.nqf_level.replace("NQF ", "") : (data[0]?.nqf_level) : "",
      nqf_level_label: data[0]?.nqf_level ? data[0]?.nqf_level.includes("NQF") ? data[0]?.nqf_level : ("NQF "+data[0]?.nqf_level) : "",
      status: data[0]?.status ? data[0]?.status : "",
      score: data[0]?.correct_score ? data[0]?.correct_score : "",
      keywords: selectKeywords,
      topic: selectedTopics      
    },
    validationSchema: Yup.object({
      question_name: Yup.string().required('Name is required'),
      score:Yup.number().required("Score is required").min(1, "Score must be greater than zero."),
      status: Yup.string().required('Status is required'),
      keywords: Yup.array().min(1, "Atleast one Keyword is required").required("Keyword is required").nullable(),
      topic: Yup.array().min(1, "Atleast one Topic is required").required("Topic is required").nullable(),
      question: Yup.string().test("required", "Question is Required", (value) => {
        const editorJSON1 = editorState1;
        let isInvalid = (editorJSON1 == "")
        return !isInvalid;
      }),
      editorState3: Yup.string().test("required", "Option is required", (value) => {
        let isValid = true;
        if (selectedQ === "Multiple Choice Multiple Answer" || selectedQ === "Multiple Choice Single Answer") {
          isValid = Object.keys(editorState3).length == (formik.values.selectedOption)
          Object.keys(editorState3).map(async (key, index) => {
            if (isValid) {
              let tempEditor;
              tempEditor = editorState3[key]
              isValid = (editorState3[key] !== "")
            }
          });
          if (!isValid) {
            return isValid
          }
        }
        if (selectedQ === "Multiple Choice Multiple Answer") {
          isValid = !(!checkedAns.length) // two times ! will return a true or false value instead of thruthy and falsy value
          return isValid
        } else if (selectedQ === "Multiple Choice Single Answer") {
          isValid = !(!selectedAns) // two times ! will return a true or false value instead of thruthy and falsy value
          return isValid
        } else if (selectedQ === "Equation") {
          return equationEditor !== ""
        } else if (selectedQ === "Order List") {
          Object.keys(editorState3).map((key, index) => {
            if (isValid) {
              isValid = editorState3[key] !== ""
              // isValid = !(convertToRaw(editorState3[key].getCurrentContent()).blocks.length == 1 && convertToRaw(editorState3[key].getCurrentContent()).blocks[0].text.trim() == "")
            }
          });
          if (!isValid) {
            return isValid
          }
          // return isValid
        } else if (selectedQ === "Choice Matrix") {
          isValid = false
          let tempValueCheck = []
          matrixData.map(item => {
            let isTrue = false
            if(item?.options?.length) {
              item.options.map(val => {
                if (!isTrue) {
                  isTrue = val.isSelected
                }
              })
            }
            tempValueCheck.push(isTrue);
          })
          if (tempValueCheck.includes(false)) {
            return isValid
          } else {
            return true
          }
        } else if (selectedQ === "Match The Column" || selectedQ === "Match the Column") {
          formik.values.matchItemArr.map((item) => {
            if(item.score == "0"){
              isValid = true
            }else{
              if (item.q_option == "" || item.q_option_match == "") {
                isValid = false
                return isValid
              }
            }
          })
          return isValid;
        }
        return isValid
      }),
      shortAnswer: Yup.string()
        .when("question_type", {
          is: "Short Answer",
          then: Yup.string().required("Possible Answers is required").trim(),
        }),
    }),

    onSubmit: (values) => {
      if (values.isSaving) {
        if (selectedQ === "Fill in the blank") {
          let inputCount = editorState1.split("___").length-1;
          console.log("inside if >>>> input count", inputCount, fillOption, inputCount>fillOption)
          if(inputCount > fillOption.length){
              formik.setErrors({question: "Question can't contain input field more than number of options, Please remove one ore more ___ occurrence."})
              return;
          }
        }
        setDisabled(true)
        const editorJSON1 = editorState1;
        const editorJSON2 = editorState2;
        let val = []
        if (selectedQ === "Multiple Choice Single Answer" || selectedQ === "Multiple Choice Multiple Answer") {
          let arr = { ...editorState3 };
          Object.keys(arr).map((key, index) => {
            val.push({ 
              optionId: data[index].oid, 
              q_option: arr[key], 
              score: selectedQ === "Multiple Choice Single Answer" ? 
                (selectedAns.toString() === key.toString() ? values.score : "0") 
                : (checkedAns.includes(key.toString()) ? `${values.score / checkedAns.length}` : "0") })
          })
          values = { ...values, optArr: val, question: editorJSON1, description: editorJSON2 };
        } else if (selectedQ === "Match The Column" || selectedQ === "Match the Column") {
          let arr = []
          let valuelength = values.matchItemArr.filter((item) => item.q_option !== "" && item.q_option != undefined)
          values.matchItemArr.map(item => {
            let itemObj = {
              score: item.q_option !== "" && item.q_option != undefined ? `${values.score / valuelength.length}` : "0",
              q_option: item.q_option !== "" && item.q_option != undefined ?  item.q_option  : "",
              q_option_match: item.q_option_match !== "" && item.q_option_match != undefined ?  item.q_option_match  : ""
            };
            if(item.oid){
              itemObj.optionId = item.oid
            }
            arr.push({...itemObj})
          })
          values = { ...values, optArr: arr, question: editorJSON1, description: editorJSON2 };
        } else if (selectedQ === "Short Answer") {
          values = { ...values, shortAnswer: values.shortAnswer.join("~@~"), optArr: [{ q_option: values.shortAnswer.join("~@~"), score: values.score, optionId: data[0].oid }], question: editorJSON1, description: editorJSON2 };
        } else if (selectedQ === "Fill in the blank") {
            let optionArr = data.map((item, index) => {
            return {
                optionId: item.oid,
                q_option: JSON.stringify(fillOption[index].map(val => {
                    return val
                })),
                score: values.score / fillOption.length
            }
        })
        values = { ...values, question: editorJSON1, description: editorJSON2, optArr: optionArr };
        } else if (selectedQ  === "Choice Matrix") {
          let optionArr = matrixData.map(item => {
              return {
                  optionId: item.optionid,
                  q_option: JSON.stringify(item.options.map(val => {
                      return { isSelected: val.isSelected, score: val.isSelected ? values.score / matrixData.length : 0, titleValue: val.titleValue }
                  })),
                  score: values.score / matrixData.length,
                  titleValue: item.titleValue
              }
          })
          values = { ...values, question: editorJSON1, description: editorJSON2, optArr: optionArr , };
      } else if (selectedQ === "Order List") {
        let val = [];
        data.map((key, index) => {
            val.push({
                optionId: key.oid,
                q_option: ckstate[index],
                score: values.score / Object.keys(ckstate).length
            });
        });
        values = { ...values, optArr: val, question: editorJSON1, description: editorJSON2 };
       }
        else {
          values = { ...values, question: editorJSON1, description: editorJSON2 };
        }
        values.topic = (values.topic.map(item=>{return item?.value})).join(",");
        values.keywords = JSON.stringify(values.keywords);
        var formdata = new FormData();
        formdata.append("qid", id)
        Object.keys(values).map(key => {
            if (key == "optArr") {
                values[key].forEach((item, index) => {
                    for (let k in item) {
                      formdata.append(`${key}[${index}][${k}]`, item[k]);
                    }
                });
            } 
            else if(key != "matchItemArr"){              
              formdata.append(key, values[key])
            }
        })
        if(uploadDoc){
          uploadDoc.map((docItem)=>{
            formdata.append("document[]", docItem);
          })
        }
        if (subtab === "view") {
          // UpdateQuestion({ ...values, qid: id })
          UpdateQuestion(formdata)
            .then((res) => {
              Swal.fire({ icon: "success", title: "Success", text: "Updated successfully" });
              setDisabled(false)
            })
            .catch((err) => {
              Swal.fire({ icon: "error", title: "Error", text: `${err?.response?.data?.data}` });
              setDisabled(false)
            });
        } else {
          values = { ...values, duplicateId: id }
          AddaQuestion(values).then((res) => {
            Swal.fire({ icon: "success", title: "Success", text: "Added Successfully" });
            history.push("/courseAdministration/smartAssessment/questionBank");
            setDisabled(false)
          }).catch((err) => {
            Swal.fire({ icon: "error", title: "Error", text: err.response.data.message });
            setDisabled(false)
          })
        }
      } else {
        const editorJSON1 = editorState1;
        const editorJSON2 = editorState2;
        let option = []
        if (selectedQ === "Multiple Choice Single Answer" || selectedQ === "Multiple Choice Multiple Answer") {
          option = Object.keys(editorState3).map((key) => {
            return { q_option: editorState3[key].blocks ? JSON.stringify(editorState3[key]) : HtmlParser(editorState3[key]), q_option_match: "", oid: Math.floor(Math.random() * 10) + 1 }
          })
        } else {
          option = formik.values.matchItemArr ? formik.values.matchItemArr.map((item) => {
            return item
          }) : []
        }
        $("#previewQuestion").modal('show');
        setQuestionData({ question_type: values.question_type, question: editorJSON1, description: editorJSON2, options: option })
      }
    }
  });


  const handleOptionSelect = (rowIndex, optionIndex) => {
    const updatedMatrixData = [...matrixData];
    updatedMatrixData[rowIndex].options.forEach((option, index) => {
      if (index === optionIndex) {
        option.isSelected = true;
      } else {
        option.isSelected = false;
      }
    });
    setMatrixData(updatedMatrixData);
  }

  const handleDeleteDoc = (index) => {
    let docNamesList = [...uploadDocName];    
    let docPathsList = [...uploadDocPath];
    let docList = [...uploadDoc];

    docNamesList.splice(index,1);
    docPathsList.splice(index,1);
    docList.splice(index,1);

    setUploadDocName([...docNamesList]);
    setUploadDocPath([...docPathsList]);
    setUploadDoc([...docList]);
  };

  const renderColumn = () => {
    const handleChangeNonLinked = (e, ID, values) => {
      let tempArr = formik.values.matchItemArr;
      tempArr.map((val) => {
        if (val.oid == ID) {
          val[values] = e.target.value;
          return val;
        } else {
          return val;
        }
      })
      formik.setFieldValue("matchItemArr", tempArr)
    };

    return formik.values.matchItemArr.map((element, i) => {
      return (
        <div key={i} className="form-icon-group d-block">
          <div className="row"><div className="col-4">
            <label>Item *</label>
            <div className="form-icon-group mb-4"
              title="Label">
              <input type="text"
                className={"form-control" + (formik.errors.editorState3 && formik.touched.editorState3 ? " is-invalid" : "")}
                name="item"
                placeholder="Label"
                title="Item"
                value={element.q_option}
                onChange={(e) => {
                  handleChangeNonLinked(e, element.oid, "q_option")
                }}
              />
            </div>
          </div>
            <div className="col-4">
              <label>Match *</label>
              <div className="form-icon-group mb-4"
                title="Label">
                <input type="text"
                  className={"form-control" + (formik.errors.editorState3 && formik.touched.editorState3 ? " is-invalid" : "")}
                  name="match"
                  placeholder="Label"
                  title="Match"
                  value={element.q_option_match}
                  onChange={(e) => {
                    handleChangeNonLinked(e, element.oid, "q_option_match")
                  }}
                />
              </div>
            </div></div>
        </div>
      );
    });
  };



  const handleRemoveShortAnswer = (index) => {
    let keyword = [...formik.values.shortAnswer];
    keyword.splice(index, 1)
    formik.setFieldValue("shortAnswer", keyword);
};

const handleAddShortAnswer = (event) => {
    let answers = [...formik.values.shortAnswer];
    if (event.key === "Enter") {
        if (event.target.value) {
            answers.push(event.target.value);
            event.target.value = ""
            formik.setFieldValue("shortAnswer", answers);
        }
    }
};


  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="tabs-wrap">
          <div className="tab-content">
            <div className="tab-pane fade active show">
              {tabName == "questionBank" && tab === "question" && subtab === "view" &&
                <div className="card card-profile-info-card mb-30">
                  <div className={subtab == "view" ? "" : ""}>
                    <div className="card-body-inr card-body-info">
                    <div className="edit-icon new-card-header">
                      <div className="card-header">Basic Details</div>
                        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
                      </div>
                      <form onSubmit={formik.handleSubmit}>
                        <>
                          <div className="row">
                            <div className="col-md-6 col-lg-3">
                              <label>Question Type</label>
                              <div className="form-icon-group mb-4">
                                <input
                                  disabled
                                  type="text"
                                  className={"form-control"}
                                  name="question"
                                  id="question"
                                  title="Question Type"
                                  value={selectedQ}
                                  placeholder="Question Type"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <label htmlFor="question_name">Name *</label>
                              <div className="form-icon-group mb-4">
                                <input
                                  type="text"
                                  // className={"form-control"}
                                  className={"form-control" + (formik.errors.question_name && formik.touched.question_name ? " is-invalid" : "")}
                                  name="question_name"
                                  id="question_name"
                                  title="Name"
                                  value={formik.values.question_name}
                                  placeholder="Name"
                                  onChange={(e) => formik.setFieldValue("question_name", e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-2">
                              <label>NQF Level</label>
                              <div className="form-icon-group mb-4" title="NQF Level">
                                <Select
                                  className={"form-control custom-select-box" + (formik.errors.nqf_level && formik.touched.nqf_level ? " is-invalid" : "")}
                                  name="nqf_level"
                                  value={nqfOptions?.filter(val => val.value === formik.values.nqf_level_label)}
                                  onChange={(value) => {
                                    if (value) {
                                      formik.setFieldValue("nqf_level_label", value.value);
                                      formik.setFieldValue("nqf_level", value.data);
                                    } else {
                                      formik.setFieldValue("nqf_level_label", "");
                                      formik.setFieldValue("nqf_level", "");
                                    }
                                  }}
                                  onBlur={formik.handleBlur}
                                  options={nqfOptions}
                                  maxMenuHeight={175}
                                  placeholder={formik.values.nqf_level ? formik.values.nqf_level : "Select"}
                                  isClearable
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-2" title="Level">
                              <label>Level </label>
                              <div className="form-icon-group mb-4">
                                <Select
                                  className={"form-control custom-select-box custom-profile-page" + (formik.errors.lid && formik.touched.lid ? " is-invalid" : "")}
                                  name="lid"
                                  value={level?.filter(val => val.label === formik.values.levelname)}
                                  onChange={(value) => {
                                    if (value) {
                                      formik.setFieldValue("lid", value.value);
                                      formik.setFieldValue("levelname", value.label);
                                    } else {
                                      formik.setFieldValue("lid", "");
                                      formik.setFieldValue("levelname", "");
                                    }
                                  }}
                                  onBlur={formik.handleBlur}
                                  options={level}
                                  maxMenuHeight={175}
                                  placeholder={formik.values.lid ? formik.values.levelname : "Select"}
                                  isClearable
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-2">
                              <label>Status *</label>
                              <div className="form-icon-group mb-4" title="Status">
                                <Select
                                  styles={colourStyles}
                                  className={"form-control color-drop custom-select-box" + (formik.errors.status && formik.touched.status ? " is-invalid" : "")}
                                  name="status"
                                  value={statusArr?.filter(val => val.value === formik.values.status)}
                                  onChange={(value) => {
                                    if (value) {
                                      formik.setFieldValue("status", value.value)
                                    } else {
                                      formik.setFieldValue("status", "")
                                    }
                                  }}
                                  // menuIsOpen={true}
                                  onBlur={formik.handleBlur}
                                  options={statusArr}
                                  maxMenuHeight={175}
                                  placeholder={formik.values.status ? formik.values.status : "Select"}
                                  isClearable
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                              <label htmlFor="score">Score *</label>
                              <div className="form-icon-group mb-4">
                                <input
                                  type="number"
                                  // className={"form-control"}
                                  className={"form-control" + (formik.errors.score && formik.touched.score ? " is-invalid" : "")}
                                  name="score"
                                  id="score"
                                  title="Score"
                                  value={formik.values.score}
                                  placeholder="Score"
                                  onChange={(e) => formik.setFieldValue("score", e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-3 mb-4">
                              <label>Keywords*</label>
                              <div className="form-icon-group">
                                <div className="form-control custom-select-box">
                                  <input
                                    type="text"
                                    className={"form-control " + (formik.errors.keywords && formik.touched.keywords ? " is-invalid" : "")}
                                    name="keywords"
                                    placeholder="keywords"
                                    title="Keywords"
                                    onBlur={formik.handleBlur}
                                    onKeyDown={handleKeyDown1}
                                  />
                                </div>
                              </div>
                              {selectKeywords != "" && (
                                <ul className="tag-list mb-0">
                                  {selectKeywords?.map((ele, index) => (
                                    <li key={index}>
                                      {ele}
                                      <span onClick={() => removeKeyword(index)}>
                                        <i className="fal fa-times"></i>
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                            <div className="col-md-6 col-lg-4">
                              <div className="form-group-blk mb-3">
                                <label htmlFor="Topic">Topic*</label>
                                <Select
                                  isDisabled={!isEditable}
                                  styles={multiSelectColorStyles}
                                  className={
                                    "form-control color-drop custom-select-box is-multi" +
                                    (formik.errors.topic && formik.touched.topic
                                      ? " is-invalid"
                                      : "")
                                  }
                                  name="topic"
                                  title="topic"
                                  placeholder={formik.values.topic && formik.values.topic == null ? formik.values.topic : "Select"}
                                  onChange={(value) => formik.setFieldValue("topic", value)}
                                  value={formik.values.topic}
                                  isMulti={true}
                                  options={quizTopics}
                                  maxMenuHeight={175}
                                  onBlur={formik.handleBlur}
                                  isClearable
                                />
                              </div>
                            </div>
                          </div>
                        </>
                        <>
                          <div className="form-icon-group d-block">
                            <label>Question *
                            {formik.values.question_type=="Fill in the blank" ? 
                              <div className="text-primary"><i style={{top:'unset',right:'unset', position:'unset'}} className="fal fa-info-circle"></i> To specify a blank area, add 3 underscores, ex I will be ___ years old  in the year___</div> : ""}
                            </label>
                            {isApiLoaded ? <HtmlInputEditor editorState={editorState1} setEditorState={setEditorState1} hideSign={true} isCKEditor={true} from = "quizManagement"/> : null}
                          </div>
                          <div className="form-icon-group d-block">
                            <label>Description</label>
                            {isApiLoaded ? <HtmlInputEditor editorState={editorState2} setEditorState={setEditorState2} hideSign={true} isCKEditor={true} from = "quizManagement"/> : null}
                          </div>
                          <div className="mb-4">
                            <label htmlFor="IndexLogo">
                                Document Upload
                            </label>
                            <div className="form-group atttach-file">
                                <label>
                                    <i className="fal fa-upload"></i>
                                    <span>Upload</span>
                                    <input
                                        type="file"
                                        id="input"
                                        className="form-control  form-control-aatch-file"
                                        name="file"
                                        multiple
                                        accept=".xlsx,.pdf,.prdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        title="Upload Document"
                                        onChange={async (e) => {
                                            e.persist();
                                            if (e.target.files.length) {
                                                const fileArray = [...e.target.files]

                                                const uploadArray = [...uploadDoc, ...fileArray];                                                
                                                const FileNames = fileArray.map(item =>(item.name))                                            
                                                const FilePaths = fileArray.map(item =>(URL.createObjectURL(item)))  
                                                
                                                setUploadDoc([...uploadArray]);                                            
                                                setUploadDocPath( prev => [ ...prev, ...FilePaths]);
                                                setUploadDocName(prev=> [...prev, ...FileNames])
                                            }
                                        }}
                                    />
                                </label>
                            </div>
                            {uploadDocName.map((item, index) => (
                              <div className="document-upload" key={index}>
                                <a
                                    title={item}
                                    href={
                                      uploadDocPath[index].includes("blob")
                                            ? uploadDocPath[index]
                                            : `${IMAGE_URL}/`+`${uploadDocPath[index]}`.replaceAll(
                                                "public/",
                                                ""
                                            ).replaceAll("home/myaie/public_html/", "")
                                    }
                                    target="_blank"
                                >
                                    <i className="fal fa-file"></i>
                                    &nbsp;{item}
                                </a>
                                <button
                                  type="button"
                                  className="btn btn-danger rounded-circle btn-dropdown-item"
                                  title="Delete"
                                  onClick={() => handleDeleteDoc(index)}
                                >
                                    <i className="fal fa-trash-alt"></i>
                                </button>
                                        
                              </div>))
                        }
                        </div>
                          {/* <div className="form-icon-group d-block">
                            <label>Description</label>
                            {isApiLoaded ? <HtmlInputEditor editorState={editorState2} setEditorState={setEditorState2} hideSign={true} isCKEditor={true} from = "quizManagement"/> : null}
                          </div> */}
                        </>
                        <RenderOption
                            selectedQ={selectedQ}
                            orderListState={orderState}
                            orderList = {orderState}
                            setCkState={setCkState}
                            editorState3={editorState3}
                            setEditorState={setEditorState3}
                            selectedOption={formik.values.selectedOption}
                            multipleChoiceProps={
                              {
                                setCheckedAns:setCheckedAns,
                                selectedAns: selectedAns,
                                setselectedAns:setselectedAns,
                                checkedAns: checkedAns,
                                optionEditor:editorState3,
                                optionArr: optionArr
                              }
                            }
                            fillInBlankProps={
                              {
                                fillOption:fillOption,
                                setFillOption:setFillOption,
                                removeKeyword:removeOptionAnswer
                              }
                            }
                            matchColumnsProps={
                              {
                                matchItemArr:formik.values.matchItemArr,
                                setMatchItemArr:(value) => formik.setFieldValue("matchItemArr", value),
                                idEditorState3Invalid:formik.errors.editorState3 && formik.touched.editorState3,
                              }
                            }
                            choiceMatrixProps={
                              {
                                matrixArray:matrixData,
                                onChangeFunction:handleOptionSelect
                              }
                            }
                            shortAnswerProps={
                              {
                                isShortAnswerInvalid:formik.errors.shortAnswer && formik.touched.shortAnswer,
                                // shortAnswerProps: formik.handleBlur,
                                shortAnswer: formik.values.shortAnswer,
                                removeAnswerFunction: handleRemoveShortAnswer,
                                handleAddShortAnswer: handleAddShortAnswer,
                                shortAnswerProps: formik.handleBlur,
                              }
                            }
                            equationProps={
                              {
                                setEquationEditor:setEquationEditor,
                                equationEditor:equationEditor
                              }
                            }
                          />
                        {/* <>
                          {(selectedQ === "Multiple Choice Single Answer" || selectedQ === "Multiple Choice Multiple Answer") ? optionArray() : null}
                          {formik.values.question_type=="Fill in the blank" && Array.isArray(fillOption) && fillOption.length ? optionArray1() :null}
                        </>
                        {selectedQ=="Choice Matrix" ? (
                            <div className="matrix-table">
                              <label className="ml-1">Option *</label>
                          <table className="export-table-border-header">
                            <thead>
                              <tr>
                                <th></th>
                                {matrixData?.length && matrixData[0].options.map((option, index) => (
                                  <th key={index}>{option.text}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {matrixData?.length && matrixData.map((rowData, rowIndex) => (
                                <tr key={rowIndex}>
                                  <td>{rowData.rowTitle}</td>
                                  {rowData.options.map((option, optionIndex) => (
                                    <td key={optionIndex}>
                                      <div className="form-icon-group" title="SendWhen">
                                      <div
                                        // className="custom-control custom-radio custom-control-inline"
                                      >
                                        <input
                                          type="radio"
                                          // id={`customRadioInlineoption${optionIndex}-row${rowIndex}`}
                                          // name="customRadioInline"
                                          // className="custom-control-input"
                                          checked={option.isSelected} 
                                          onChange={() => {
                                            handleOptionSelect(rowIndex, optionIndex)
                                          }} 
                                        />
                                      </div>
                                      </div>
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          </div>) : null}
                        <div className="course-info-sec">
                          <div className="row">
                            <>
                              {selectedQ === "Short Answer" ?
                                <div className="col-md-6 col-lg-4 mb-4">
                                  <label>Possible Answers *</label>
                                  <div className="form-icon-group" title="Label">
                                    <input type="text"
                                      className={"form-control" + (formik.errors.shortAnswer && formik.touched.shortAnswer ? " is-invalid" : "")}
                                      name="shortAnswer"
                                      placeholder="Possible Answers"
                                      title="Possible Answers"
                                      // value={formik.values.shortAnswer}
                                      // onChange={(e) => {
                                      //   formik.setFieldValue("shortAnswer", e.target.value)
                                      // }}
                                      onKeyDown={handleKeyDown}
                                      onBlur={formik.handleBlur} />
                                  </div>
                                  {possibleAnswers.length > 0 && (
                                    <ul className="tag-list mb-0">
                                      {possibleAnswers?.map((ele, index) => (
                                        <li>
                                          {ele}
                                          <span onClick={() => removeAns(index)}>
                                            <i className="fal fa-times"></i>
                                          </span>
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </div> : null}
                                {selectedQ === "Equation" ? 
                                  <div className="col-md-12 mb-4">
                                    <label>Answer</label>
                                    <HtmlInputEditor editorState={equationEditor} setEditorState={setEquationEditor} hideSign={true} isCKEditor={true} from = "quizManagement"/>
                                  </div> : null
                                }
                            </>
                          </div>
                        </div> */}
                        {/* <>{selectedQ === "Match The Column" || selectedQ === "Match the Column" ? renderColumn() : null}</> */}
                        <div className="form-group form-group-save-cancel mt-4">
                          <PermissionsGate scopes={["cquestionbedit"]} errorProps={{ disabled: true }}>
                            <button type="button" className="btn btn-primary" disabled={disabled} title="Save" onClick={() => {
                              formik.setFieldValue("isSaving", true)
                              formik.handleSubmit()
                            }}>
                              {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                              Save
                            </button>
                          </PermissionsGate>
                          <button type="button" className="btn btn-primary" title="Preview" onClick={() => {
                            formik.setFieldValue("isSaving", false)
                            formik.handleSubmit()
                          }}>
                            <i className="fal fa-file-alt"></i>
                            Preview
                          </button>
                          <button type="button" className="btn btn-danger" onClick={() => history.goBack()} title="Cancel"><i className="fal fa-times"></i>Cancel</button>
                          {Object.keys(formik.errors).map((key)=> (formik.errors[key] && formik.touched[key] ? <div className="invalid-feedback d-block">{formik.errors[key]}</div> : null))}
                        </div>
                      </form>

                    </div>

                  </div>
                </div>
              }


              {tabName == "questionBank" && tab === "question" && subtab === "assignedQuizzes" && <>
                <Assigned data={assigneddata} id={id} refresh={handlerefresh} loading={loading1} availabledata={availabledata} loading2={loading2} setData2={setData2} totalRows2={totalRows2} totalRows1={totalRows1} setData={setData1} />
              </>
              }
              <div
                className="topic-add-modal modal fade"
                id="previewQuestion"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-dialog-centered modal-lg"
                  role="document"
                >

                  <div className="modal-content modal-border-update">
                    <div className="modal-body p-0">

                      <div className="modal-header modal-header-custom">
                        <h5 className="modal-title">
                          View Question
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            // formik.resetForm()
                            $("#previewQuestion").modal("hide");
                            // setEditorState1("");
                            // setShowCkEditor(false);

                            // formik.setFieldValue("type", "");
                          }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="t p-30px">
                        <DisplayQuestion question={questionData} from="viewQuestion" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewQuestion;
