import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { GetCommonContentReReg } from "../../../services/ProgrammeServices";
import hasPermission from "../../../utils/hasMultiplePermission";
import AssignedModules from "./ModulesBlock/AssignedModule";

const Modules = ({ refresh, setRefresh}) => {
  const history = useHistory()
  const [updateRow, setUpdateRow] = useState(false);
  const [dropdownVals, setDropdownVals] = useState();
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["pcview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
    GetCommonContentReReg()
      .then((res) => {
        setDropdownVals(res.data)
      })
      .catch((err) => console.log("error :", err))
  }, [])

  return <AssignedModules setUpdateRow={setUpdateRow} updateRow={updateRow} dropdowns={dropdownVals && dropdownVals} refresh={refresh} setRefresh={setRefresh} />
};

export default Modules;
