import React, { useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import AssignedQues from "./blocks/AssignedQues";
import Detail from "./blocks/Detail";
import Submission from "./blocks/Submission";
import { GetAssessmentDetail, getQuizDetails } from "../../../../services/SmartAssessmentService";
import { getCurrentTimeHomePage } from "../../../common/Helper";
import AuditTrails2 from "./blocks/AuditTrails";
import SubmissionDetails from "./blocks/SubmissionDetails"
import QuestionSummary from "./blocks/QuestionSummary";
import { Link } from "react-router-dom";
import QuizAuditTrail from "./blocks/quizAuditTrail";
import AssignedQTable from "./blocks/QuesTables/AssignedQTable";
import { TrimText } from "../../../common/TrimText";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import SubmissionList from "./blocks/SubmissionList";
import QuestionsGroupView from "./blocks/QuesTables/QuestionsGroupView";
import { SP_PASS, STUDENT_PORTAL_URL } from "../../../../utils/Constants";
import PermissionsGate from "../../../../utils/permissionGate";

const DetailAssessment = () => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState();
  const [submissionName, setSubmissionName] = useState("");
  const { tabName, tab, subtab, id, subId } = useParams();
  const quizId = useSelector(state => state.quiz)
  const search = window.location.search;
  const IDs = new URLSearchParams(search)
  const intake_id = IDs.get('intake_id');
  const assessment_id = IDs.get('assess_id');
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (id && subtab !== "submissiondetails") {
      getQuizDetails({ id: id })
        .then((res) => { setData(res.data[0]);})
        .catch((err) => console.log(err))
    }
  }, [refresh]);

  let urlQuestionResult = `/courseAdministration/assessment/quizzes/open/submissionquestionresult/${id}/${subId}`;
  let urlTimeSpent = `/courseAdministration/assessment/quizzes/open/submissiontimespent/${id}/${subId}`
  let urlQueryParams = `?intake_id=${intake_id}&assess_id=${assessment_id}`;
  let finalUrlQuestionResult = urlQuestionResult.includes(urlQueryParams) ? urlQuestionResult : `${urlQuestionResult}${urlQueryParams}`;
  let finalUrlTimeSpent = urlTimeSpent.includes(urlQueryParams) ? urlTimeSpent : `${urlTimeSpent}${urlQueryParams}`;

  return (
    <>
      <div className="my-tickets-heading-blk flex-direction">
        <div className="d-flex align-items-center ">
          <div className="name-w-head d-flex align-items-center ">
            <h4 className="text-left-align landing-heading heading_color_dynamic">
              {subtab !== "submissiondetails" && subtab !== "submissionquestionresult" && subtab !== "submissiontimespent" ? "Quiz:" : "Submission & Result:"} 
              {subtab !== "submissiondetails" && subtab !== "submissionquestionresult" && subtab !== "submissiontimespent" ? <span> {data?.quiz_name}</span> : <span> {submissionName}</span>}               
            </h4>
          </div>
          <div className="text-md-right action2-blk">
            <div className="ticket-view-btn d-flex align-items-center">
              <div className="add-new-ticket-bx">
                <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                  <i className="fal fa-angle-left"></i>Back
                </button>
              </div>
            </div>
          </div>
          {(subtab !== "submissionList" && subtab !== "submissiondetails" && subtab !== "submissionquestionresult" && subtab !== "submissiontimespent") && <div className="text-md-right action2-blk">
            <div className="ticket-view-btn d-flex align-items-center">
              <div className="add-ticket-blk button-reset dropdown-comman">
                {data?.assigned_question_no ==0 ? 
                <button title="Test Quiz" className="btn btn-primary" disabled>Test Quiz</button>
                :<a 
                  href={`${STUDENT_PORTAL_URL}/courseinfo/quizinfo/${id}/${SP_PASS}`}
                  target="_blank" 
                  title="Test Quiz" className="btn btn-primary">
                Test Quiz
                </a>
                }
              </div>
            </div>
          </div>} 
          {tabName == "quizzes" && tab === "open" && (subtab === "submissiondetails" || subtab === "submissionquestionresult" || subtab === "submissiontimespent") && <div className="text-md-right action2-blk">
            <div className="ticket-view-btn d-flex align-items-center">
              <div className="add-new-ticket-bx">
                <button onClick={() => {
                      const quizIdParam = quizId.id ? quizId.id : data?.quid;
                      const targetUrl = `/courseAdministration/assessment/quizzes/open/submissionList/${quizIdParam}/${subId}`;
                      const finalUrl = targetUrl.includes(urlQueryParams) ? targetUrl : `${targetUrl}${urlQueryParams}`;
                      history.push(finalUrl);
                }
              } title="Attempts" className="btn btn-white-bordered">
                <i class="fal fa-ballot-check"></i>Attempts
                </button>
              </div>
            </div>
          </div>}
          {tabName == "quizzes" && tab === "open" && (subtab === "submissionList" || (subtab === "submissiondetails" || subtab === "submissionquestionresult" || subtab === "submissiontimespent")) && <div className="text-md-right action2-blk">
            <div className="ticket-view-btn d-flex align-items-center">
              <div className="add-new-ticket-bx">
                <button onClick={() => history.push(`/courseAdministration/coursesdetails/${intake_id}/assessments/open/assigned/submissionsAndResults/${assessment_id}`)} title="Assessment" className="btn btn-white-bordered">
                <i class="fal fa-file-alt"></i>Assessment
                </button>
              </div>
            </div>
          </div>}
        </div>
      </div>
      <div className="tabs-wrap">
        {subtab !== "submissionList" && subtab !== "submissiondetails" && subtab !== "submissionquestionresult" && subtab !== "submissiontimespent" ?
          <ul className="nav nav-pills mb-4" id="">
            <li className="nav-item">
              <Link className={`nav-link cursor-pointer ${tabName == "quizzes" && tab === "open" && subtab === "details" ? "active" : ""}`} to={`/courseAdministration/assessment/quizzes/open/details/${id}`}>
                <i className="fal fa-file-alt"></i> Details
              </Link>
            </li>
            <li className="nav-item" >
              <Link className={`nav-link cursor-pointer ${tabName == "quizzes" && tab === "open" && (subtab === "questions"||subtab === "groupview") ? "active" : ""}`} to={`/courseAdministration/assessment/quizzes/open/questions/${id}`}>
                <i className="fal fa-laptop"></i> Questions
              </Link>
            </li>
            {/* <li className="nav-item" onClick={() => history.push("/courseAdministration/assessment/quizzes/open/submission/" + id)}>
                <Link className={`nav-link cursor-pointer ${tabName == "quizzes" && tab === "open" && (subtab === "submission" || subtab === "questionSummary") ? "active" : ""}`} to={`/courseAdministration/assessment/quizzes/open/submission/${id}`}>
                <i className="fal fa-books"></i> Submission &amp; Results
                </Link>
              </li> */}
            <li className="nav-item">
              <Link className={`nav-link cursor-pointer ${tabName == "quizzes" && tab === "open" && subtab === "audit" ? "active" : ""}`} to={`/courseAdministration/assessment/quizzes/open/audit/${id}`}>
                <i className="fal fa-history"></i> Audit Trail
              </Link>
            </li>
          </ul>
          : <></>}
          {(subtab === "submissiondetails" || subtab === "submissionquestionresult" || subtab === "submissiontimespent") && 
          <ul className="nav nav-pills mb-4" id="">
            {/* <li className="nav-item">
              <Link className={`nav-link cursor-pointer ${tabName == "quizzes" && tab === "open" && subtab === "submissiondetails" ? "active" : ""}`} to={`/courseAdministration/assessment/quizzes/open/submissiondetails/${id}/${subId}`}>
                <i className="fal fa-file-alt"></i> Details
              </Link>
            </li> */}
            <li className="nav-item" >
              <Link className={`nav-link cursor-pointer ${tabName == "quizzes" && tab === "open" && subtab === "submissionquestionresult" ? "active" : ""}`} to={finalUrlQuestionResult}>
                <i className="fal fa-file-spreadsheet"></i> Questions Results
              </Link>
            </li>
            <li className="nav-item" >
              <Link className={`nav-link cursor-pointer ${tabName == "quizzes" && tab === "open" && subtab === "submissiontimespent" ? "active" : ""}`} to={finalUrlTimeSpent}>
                <i className="fal fa-history"></i> Time Spent
              </Link>
            </li>
          </ul>
          }
        <div className="tab-content">
          <div className={"tab-pane fade active show"}>
            {tabName == "quizzes" && tab === "open" && subtab === "details" ? <Detail /> : ""}
            {tabName == "quizzes" && tab === "open" && subtab === "questions" ? <AssignedQTable refresh={()=>{setRefresh(!refresh)}} /> : ""}
            {tabName == "quizzes" && tab === "open" && subtab === "groupview" ? <QuestionsGroupView refresh={()=>{setRefresh(!refresh)}} /> : ""}
            {/* {tabName == "quizzes" && tab === "open" && subtab === "questions" ? <AssignedQues /> : ""} */}
            {tabName == "quizzes" && tab === "open" && subtab === "submission" ? <Submission /> : ""}
            {tabName == "quizzes" && tab === "open" && subtab === "questionSummary" ? <QuestionSummary /> : ""}
            {tabName == "quizzes" && tab === "open" && subtab === "audit" ? <QuizAuditTrail /> : ""}
            {/* {tabName == "quizzes" && tab === "open" && subtab === "audit" ? <AuditTrails2 /> : ""} */}
            {tabName == "quizzes" && tab === "open" && subtab === "submissionList" ? <SubmissionList setSubmissionName={setSubmissionName}/> : ""}
            {tabName == "quizzes" && tab === "open" && (subtab === "submissiondetails" || subtab === "submissionquestionresult" || subtab === "submissiontimespent") ? <SubmissionDetails subtab={subtab} setSubmissionName={setSubmissionName} submissionName={submissionName}/> : ""}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailAssessment;