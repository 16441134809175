import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { RenderLearningMethod } from "../../../../../utils/CommonGroupingItem";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from "../../../../../utils/Constants";
import PermissionsGate from "../../../../../utils/permissionGate";
import { Link } from "react-router-dom";
import $ from "jquery";
import { handleTableScroll } from "../../../../../utils/commonFunction";

const CoursesTable = (props) => {
  const [courses, setCourses] = useState({});
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);

  useEffect(() => {
    handleTableScroll()
  }, [props])

  useEffect(() => {
    setCourses(props && props.courses ? props.courses : {});
  }, [props]);

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  };

  const handleClose = () => {
    $("#viewCourses").modal("hide");
    $("#viewAvailCourses").modal("hide");
    $(".modal-backdrop.show").removeClass('show');
  }
  function showCourses() {
    $("#viewAllCourses").modal("show");
  }
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "course_name",
      sortable: true,
      cell: (row) =>
        row.course_name ? (
          <Link className="as-text-blue overflow-ellipsis2" onClick={() => handleClose()} title={row.course_name} to={`/courseAdministration/coursesdetails/${row.intake_id}/detailCourses/show`}>
            {row.course_name}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      name: "Start",
      selector: "start",
      sortable: true,
      cell: (row) => (
        <>
          {row.start ? (
            <div className="dateTime">
              <p className="right-space">{moment.unix(row.start).format(TABLE_DATE_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "End",
      selector: "end",
      sortable: true,
      cell: (row) => (
        <>
          {row.end ? (
            <div className="dateTime">
              <p className="right-space">{moment.unix(row.end).format(TABLE_DATE_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      cell: (row) => <>{row.year ? row.year + "-" + row.number : "-"}</>,
    },
    {
      name: "Correspondence",
      selector: "type",
      sortable: true,
      cell: (row) => row.type ? RenderLearningMethod(row.type).html : "-",
    }
  ]);

  function addIds(isRemove = false) {
    let obj = { ...courses };
    obj.sub_item = selectedRows;
    if (!isRemove) {
      props.assignCourse(obj);
    } else {
      const arr1 = courses.sub_item;
      const arr2 = selectedRows;
      function getDifference(array1, array2) {
        return array1.filter((object1) => {
          return !array2.some((object2) => {
            return object1.intake_id === object2.intake_id;
          });
        });
      }
      obj.sub_item = getDifference(arr1, arr2);
      props.removeCourse(obj);
    }
    handleClearRows();
  }

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card-table-custom c-a-p-c-t">
        <DataTable
          data={courses.sub_item}
          defaultSortField="start"
          defaultSortAsc={true}
          columns={columns}
          pagination={true}
          selectableRows
          onSelectedRowsChange={handleChange}
          clearSelectedRows={toggledClearRows}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
        {/* {courses && courses.sub_item && courses.sub_item.length > 0 && ( */}
          <div className="btn-sec">
            {!props.isRemoveBtn && (
              <PermissionsGate errorProps={{ disabled: true }} scopes={["sapiedit"]}>
                <button
                  className="btn btn-primary"
                  type="button"
                  title="Add"
                  onClick={() => {
                    addIds();
                  }}
                >
                  <i className="fal fa-plus"></i>Add
                </button>
              </PermissionsGate>
            )}
            {props.isRemoveBtn && (<>
              {(courses && courses.sub_item && courses.sub_item.length > 0) && <PermissionsGate errorProps={{ disabled: true }} scopes={["sapiedit"]}>
                <button
                  title="Remove"
                  className="btn btn-danger"
                  onClick={() => {
                    addIds(true);
                  }}
                >
                  <i className="fal fa-trash-alt"></i>Remove
                </button>
              </PermissionsGate>}
              <button
                title="Add Course"
                className="btn btn-danger ml-2"
                onClick={() => {
                  console.log("here")
                  showCourses(true);
                  props.getAllCourses()
                }}
              >
                <i className="fal fa-plus"></i>Add Course
              </button>
            </>
            )}
          </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default CoursesTable;
