import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import {
  DeletePmc,
  GetListofProgramCancelToken,
  ListProgrammeFilters,
} from "../../../services/ProgramService";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { TrimText } from "../../common/TrimText";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { Link } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import Swal from "sweetalert2";
import {
  RenderSchool,
} from "../../../utils/CommonGroupingItem";
import Tablefilter from "../../common/Tablefilter";
import $ from "jquery";
import { RenderPMCStatus } from "../../../utils/CommonStatusItems";
import { TABLE_ROWS_PER_PAGE } from "../../../utils/Constants";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const Program = () => {
  const history = useHistory();
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [school, setSchool] = useState({arr:[], checkObj:{}});
  const [schoolArr, setSchoolArr] = useState([]);
  const [status, setStatus] = useState({arr:[],checkObj:{}});
  const [loading, setloading] = useState(true);
  const [updateData, setUpdateData] = useState(true);
  const [filterData, setFilterData] = useState({status : [], school : []})

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    const cancelTokenSources = [];

    const getProgram = async () => {
      setloading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetListofProgramCancelToken(source.token);
        setData(res.data);
        if (res.status === 200) {
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };
  
    getProgram();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData]);

  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await ListProgrammeFilters(filterSource.token);
        if(res.status==200){
          setFilterData({...res.data, status : res.data.statusListFilter, school : res.data.SchoolListFilter})
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    
    getFilters()
    return () => {
      filterSource.cancel('Component unmounted');      
    };
  }, [])
  
  const myCodeFilter = (a, b) =>
    a.Code.toLowerCase() > b.Code.toLowerCase() ? 1 : -1;

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["pmcpview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);
  
  const myNQFFilter = (a, b) =>
    Number(a.NQF_Level) > Number(b.NQF_Level) ? 1 : -1;
  const myNameFilter = (a, b) => {
    return a.SAQA_Name.toLowerCase() > b.SAQA_Name.toLowerCase() ? 1 : -1;
  };
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "SAQA_Name",
      sortable: true,
      wrap: true,
      sortFunction: myNameFilter,
      cell: (row) => (
        <PermissionsGate
          scopes={["pmcpedit"]}
          RenderError={() => (
            <span title={row.SAQA_Name} className="feature-name">
              {" "}
              <span className="textLimit100">{row.SAQA_Name}</span>
            </span>
          )}
        >
          <Link
            to={`/program-management-center/programmes/programme/open/${row.Id}`}
            title={row.SAQA_Name}
            className="as-text-blue curser feature-name"
          >
            <span title={row.SAQA_Name} className="textLimit100">
              {row.SAQA_Name}
            </span>
          </Link>
        </PermissionsGate>
      ),
    },
    {
      name: "Code",
      selector: "Code",
      sortFunction: myCodeFilter,
      sortable: true,
      cell: (row, index) => (
        <div className="ticket-id">
          <span title={row.Code} className="overflow-ellipsis2">
            {row.Code ? TrimText(row.Code, 10) : "-"}
          </span>
        </div>
      ),
    },

    {
      name: "SAQA Link",
      selector: "SAQA_Link",
      sortable: true,
      wrap: true,
      cell: (row) =>
        row.SAQA_Link ? (
          <a
            href={
              row.SAQA_Link.includes("http")
                ? row.SAQA_Link
                : "http://" + row.SAQA_Link
            }
            target={"_blank"}
            className="as-text-blue curser"
            title={row.SAQA_Link}
          >
            {TrimText(row.SAQA_Link, 15)}
          </a>
        ) : (
          "-"
        ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      wrap: true,
      cell: (row) =>
        row?.status != null ? RenderPMCStatus(row?.status).html : "-",
    },
    {
      name: "#NQF",
      selector: "NQF_Level",
      sortFunction: myNQFFilter,
      sortable: true,
      wrap: true,
      cell: (row) => <div title={row?.NQF_Level}>{row?.NQF_Level}</div>,
    },
    {
      name: "School",
      selector: "School",
      sortable: true,
      wrap: true,
      cell: (row) => row?.School ? <span className="feature-name"><span className="textLimit100">{RenderSchool(row?.School).html}</span></span> : "-",
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["pmcpedit"]}
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                to={`/program-management-center/programmes/programme/open/${row.Id}`}
                className="btn btn-primary rounded-circle"
                title="Open"
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>

            <div className="dropdown btn-dropdown-item">
              <button
                className="btn btn-primary rounded-circle dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="More"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <PermissionsGate
                  scopes={["pmcpadd"]}
                  RenderError={() => (
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Duplicate"
                      disabled
                    >
                      <i className="fal fa-copy"></i>
                    </button>
                  )}
                >
                  <Link
                    to={`/program-management-center/programmes/programme/duplicate/${row.Id}`}
                    className="btn btn-primary rounded-circle"
                    title="Duplicate"
                  >
                    <i className="fal fa-copy"></i>
                  </Link>
                </PermissionsGate>
                <PermissionsGate
                  scopes={["pmcpview"]}
                  RenderError={() => (
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Programme View"
                      disabled
                    >
                      <i className="fal fa-eye"></i>
                    </button>
                  )}
                >
                  <Link
                    to={`/program-management-center/exportProgramme/${row.Id}`}
                    target="_blank"
                    className="btn btn-primary rounded-circle"
                    title="View Programme"
                  >
                    <i className="fal fa-eye"></i>
                  </Link>
                </PermissionsGate>

                <PermissionsGate
                  scopes={["pmcpdelete"]}
                  RenderError={() => (
                    <button
                      className="btn btn-danger rounded-circle"
                      title="Delete"
                      disabled
                    >
                      <i className="fal fa-trash-alt"></i>
                    </button>
                  )}
                >
                  <button
                    title="Delete"
                    className="btn btn-danger rounded-circle"
                    onClick={() => handleDelete(row.Id)}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ], []);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeletePmc({ id: id })
          .then((res) => {
            Swal.fire("Deleted!", "Programme has been deleted.", "success");
            setUpdateData(!updateData);
          })
          .catch((error) => console.log(error));
      }
    });
  };
  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let tempName = allData.filter((item) => {
        let includes =
          item.SAQA_Name &&
          item.SAQA_Name.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCode = allData.filter((item) => {
        let includes =
          item.Code &&
          item.Code.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempLink = allData.filter((item) => {
        let includes =
          item.SAQA_Link &&
          item.SAQA_Link.toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSchool = allData.filter((item) => {
        let includes =
          item.School &&
          item.School.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempdata = [
        ...tempName,
        ...tempCode,
        ...tempLink,
        ...tempSchool,
      ];
      let unique = [...new Set(tempdata)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }


    if (school.arr.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = school.arr.find(function (user, index) {
          if (
            user.toLowerCase() == (item.School ? item.School.toLowerCase() : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }

    if (status.arr.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = status.arr.find(function (value, index) {
          if (
            value.toLowerCase() ==
            (item.status ? item.status.toLowerCase() : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempSearch;
    }

    if (
      search.length ||
      school.arr.length ||
      status.arr.length
    ) {
      return updatedData;
    } else {
      return data;
    }
  };

  const resetFilter = () => {
    setSearch("");
    setStatus({arr:[],checkObj:{}});
    setSchool({arr:[],checkObj:{}});
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Code", "SAQA Link", "Status", "#NQF", "School"];

    data = data?.map((row) => ({
      ...row,
      Name: row?.SAQA_Name ? row?.SAQA_Name : "-",
      Code: row.Code ? row.Code : "-",
      "SAQA Link": row.SAQA_Link ? row.SAQA_Link : "-",
      Status: row.status ? RenderPMCStatus(row.status).text : "",
      "#NQF": row?.NQF_Level ? row?.NQF_Level : "-",
      School: row.School ? RenderSchool(row.School).text : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Name"],
          row["Code"],
          row["SAQA Link"],
          row["Status"],
          row["#NQF"],
          row["School"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom days-overdue-cell-width">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="School"
                      optionArr={filterData.school}
                      state={school}
                      setState={setSchool}
                      renderLabelFunction={RenderSchool}
                    />
                    <Tablefilter
                      filterName="Status"
                      optionArr={filterData.status}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={RenderPMCStatus}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={() => resetFilter()}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>

                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "PMC");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "PMC");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "PMC");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate
                  RenderError={() => (
                    <button
                      className="btn btn-primary"
                      title="Create New"
                      disabled
                    >
                      <i className="fal fa-plus"></i>Create New
                    </button>
                  )}
                  scopes={["pmcpadd"]}
                >
                  <Link
                    to={`/program-management-center/programmes/programme/add`}
                  >
                    <button className="btn btn-primary" title="Create New">
                      <i className="fal fa-plus"></i>Create New
                    </button>
                  </Link>
                </PermissionsGate>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            defaultSortField="SAQA_Name"
            data={dataToRender()}
            columns={columns}
            pagination={true}
            progressComponent={<SkeletonTicketList />}
            progressPending={loading}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        )}
      </div>
    </div>
  );
};

export default Program;
