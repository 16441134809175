export const Year = [
  { value: "2022", label: "2022" },
  { value: "2021", label: "2021" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
  { value: "2018", label: "2018" },
  { value: "2017", label: "2017" },
  { value: "2016", label: "2016" },
  { value: "2015", label: "2015" },
  { value: "2014", label: "2014" },
  { value: "2013", label: "2013" },
  { value: "2012", label: "2012" },
  { value: "2011", label: "2011" },
  { value: "2010", label: "2010" },
  { value: "2009", label: "2009" },
  { value: "2008", label: "2008" },
  { value: "2007", label: "2007" },
  { value: "2006", label: "2006" },
  { value: "2005", label: "2005" },
  { value: "2004", label: "2004" },
  { value: "2003", label: "2003" },
  { value: "2002", label: "2002" },
  { value: "2001", label: "2001" },
  { value: "2000", label: "2000" },
  { value: "1999", label: "1999" },
  { value: "1998", label: "1998" },
  { value: "1997", label: "1997" },
  { value: "1996", label: "1996" },
  { value: "1995", label: "1995" },
  { value: "1994", label: "1994" },
  { value: "1993", label: "1993" },
  { value: "1992", label: "1992" },
  { value: "1991", label: "1991" },
  { value: "1990", label: "1990" },
  { value: "1989", label: "1989" },
  { value: "1988", label: "1988" },
  { value: "1987", label: "1987" },
  { value: "1986", label: "1986" },
  { value: "1985", label: "1985" },
  { value: "1984", label: "1984" },
  { value: "1983", label: "1983" },
  { value: "1982", label: "1982" },
  { value: "1981", label: "1981" },
  { value: "1980", label: "1980" },
  { value: "1979", label: "1979" },
  { value: "1978", label: "1978" },
  { value: "1977", label: "1977" },
  { value: "1976", label: "1976" },
  { value: "1975", label: "1975" },
  { value: "1974", label: "1974" },
  { value: "1973", label: "1973" },
  { value: "1972", label: "1972" },
  { value: "1971", label: "1971" },
  { value: "1970", label: "1970" },
  { value: "1969", label: "1969" },
  { value: "1968", label: "1968" },
  { value: "1967", label: "1967" },
  { value: "1966", label: "1966" },
  { value: "1965", label: "1965" },
  { value: "1964", label: "1964" },
  { value: "1963", label: "1963" },
  { value: "1962", label: "1962" },
  { value: "1961", label: "1961" },
  { value: "1960", label: "1960" },
  { value: "1959", label: "1959" },
  { value: "1958", label: "1958" },
  { value: "1957", label: "1957" },
  { value: "1956", label: "1956" },
  { value: "1955", label: "1955" },
  { value: "1984", label: "1954" },
  { value: "1953", label: "1953" },
  { value: "1952", label: "1952" },
  { value: "1951", label: "1951" },
  { value: "1950", label: "1950" },
];

export const Month = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

export const MonthValue = [
  { value: 0, label: "January" },
  { value: 1, label: "February" },
  { value: 2, label: "March" },
  { value: 3, label: "April" },
  { value: 4, label: "May" },
  { value: 5, label: "June" },
  { value: 6, label: "July" },
  { value: 7, label: "August" },
  { value: 8, label: "September" },
  { value: 9, label: "October" },
  { value: 10, label: "November" },
  { value: 11, label: "December" },
];

export const Day = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
];

export const Hours = [
  { value: "00", label: "00" },
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
];

export const Minutes = [
  { value: "00", label: "00" },
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
  { value: "59", label: "59" },
];
export const QuizHours = [
  { value: "00h", label: "00h" },
  { value: "01h", label: "01h" },
  { value: "02h", label: "02h" },
  { value: "03h", label: "03h" },
  { value: "04h", label: "04h" },
  { value: "05h", label: "05h" },
  { value: "06h", label: "06h" },
  { value: "07h", label: "07h" },
  { value: "08h", label: "08h" },
  { value: "09h", label: "09h" },
  { value: "10h", label: "10h" },
  { value: "11h", label: "11h" },
  { value: "12h", label: "12h" },
  { value: "13h", label: "13h" },
  { value: "14h", label: "14h" },
  { value: "15h", label: "15h" },
  { value: "16h", label: "16h" },
  { value: "17h", label: "17h" },
  { value: "18h", label: "18h" },
  { value: "19h", label: "19h" },
  { value: "20h", label: "20h" },
  { value: "21h", label: "21h" },
  { value: "22h", label: "22h" },
  { value: "23h", label: "23h" },
];

export const QuizMinutes = [
  { resValue: "0m", value: "00min", label: "00min" },
  { resValue: "1m", value: "01min", label: "01min" },
  { resValue: "2m", value: "02min", label: "02min" },
  { resValue: "3m", value: "03min", label: "03min" },
  { resValue: "4m", value: "04min", label: "04min" },
  { resValue: "5m", value: "05min", label: "05min" },
  { resValue: "6m", value: "06min", label: "06min" },
  { resValue: "7m", value: "07min", label: "07min" },
  { resValue: "8m", value: "08min", label: "08min" },
  { resValue: "9m", value: "09min", label: "09min" },
  { resValue: "10m", value: "10min", label: "10min" },
  { resValue: "11m", value: "11min", label: "11min" },
  { resValue: "12m", value: "12min", label: "12min" },
  { resValue: "13m", value: "13min", label: "13min" },
  { resValue: "14m", value: "14min", label: "14min" },
  { resValue: "15m", value: "15min", label: "15min" },
  { resValue: "16m", value: "16min", label: "16min" },
  { resValue: "17m", value: "17min", label: "17min" },
  { resValue: "18m", value: "18min", label: "18min" },
  { resValue: "19m", value: "19min", label: "19min" },
  { resValue: "20m", value: "20min", label: "20min" },
  { resValue: "21m", value: "21min", label: "21min" },
  { resValue: "22m", value: "22min", label: "22min" },
  { resValue: "23m", value: "23min", label: "23min" },
  { resValue: "24m", value: "24min", label: "24min" },
  { resValue: "25m", value: "25min", label: "25min" },
  { resValue: "26m", value: "26min", label: "26min" },
  { resValue: "27m", value: "27min", label: "27min" },
  { resValue: "28m", value: "28min", label: "28min" },
  { resValue: "29m", value: "29min", label: "29min" },
  { resValue: "30m", value: "30min", label: "30min" },
  { resValue: "31m", value: "31min", label: "31min" },
  { resValue: "32m", value: "32min", label: "32min" },
  { resValue: "33m", value: "33min", label: "33min" },
  { resValue: "34m", value: "34min", label: "34min" },
  { resValue: "35m", value: "35min", label: "35min" },
  { resValue: "36m", value: "36min", label: "36min" },
  { resValue: "37m", value: "37min", label: "37min" },
  { resValue: "38m", value: "38min", label: "38min" },
  { resValue: "39m", value: "39min", label: "39min" },
  { resValue: "40m", value: "40min", label: "40min" },
  { resValue: "41m", value: "41min", label: "41min" },
  { resValue: "42m", value: "42min", label: "42min" },
  { resValue: "43m", value: "43min", label: "43min" },
  { resValue: "44m", value: "44min", label: "44min" },
  { resValue: "45m", value: "45min", label: "45min" },
  { resValue: "46m", value: "46min", label: "46min" },
  { resValue: "47m", value: "47min", label: "47min" },
  { resValue: "48m", value: "48min", label: "48min" },
  { resValue: "49m", value: "49min", label: "49min" },
  { resValue: "50m", value: "50min", label: "50min" },
  { resValue: "51m", value: "51min", label: "51min" },
  { resValue: "52m", value: "52min", label: "52min" },
  { resValue: "53m", value: "53min", label: "53min" },
  { resValue: "54m", value: "54min", label: "54min" },
  { resValue: "55m", value: "55min", label: "55min" },
  { resValue: "56m", value: "56min", label: "56min" },
  { resValue: "57m", value: "57min", label: "57min" },
  { resValue: "58m", value: "58min", label: "58min" },
  { resValue: "59m", value: "59min", label: "59min" },
];

export const QuizSeconds = [
  { resValue: "0s", value: "00sec", label: "00sec" },
  { resValue: "1s", value: "01sec", label: "01sec" },
  { resValue: "2s", value: "02sec", label: "02sec" },
  { resValue: "3s", value: "03sec", label: "03sec" },
  { resValue: "4s", value: "04sec", label: "04sec" },
  { resValue: "5s", value: "05sec", label: "05sec" },
  { resValue: "6s", value: "06sec", label: "06sec" },
  { resValue: "7s", value: "07sec", label: "07sec" },
  { resValue: "8s", value: "08sec", label: "08sec" },
  { resValue: "9s", value: "09sec", label: "09sec" },
  { resValue: "10s", value: "10sec", label: "10sec" },
  { resValue: "11s", value: "11sec", label: "11sec" },
  { resValue: "12s", value: "12sec", label: "12sec" },
  { resValue: "13s", value: "13sec", label: "13sec" },
  { resValue: "14s", value: "14sec", label: "14sec" },
  { resValue: "15s", value: "15sec", label: "15sec" },
  { resValue: "16s", value: "16sec", label: "16sec" },
  { resValue: "17s", value: "17sec", label: "17sec" },
  { resValue: "18s", value: "18sec", label: "18sec" },
  { resValue: "19s", value: "19sec", label: "19sec" },
  { resValue: "20s", value: "20sec", label: "20sec" },
  { resValue: "21s", value: "21sec", label: "21sec" },
  { resValue: "22s", value: "22sec", label: "22sec" },
  { resValue: "23s", value: "23sec", label: "23sec" },
  { resValue: "24s", value: "24sec", label: "24sec" },
  { resValue: "25s", value: "25sec", label: "25sec" },
  { resValue: "26s", value: "26sec", label: "26sec" },
  { resValue: "27s", value: "27sec", label: "27sec" },
  { resValue: "28s", value: "28sec", label: "28sec" },
  { resValue: "29s", value: "29sec", label: "29sec" },
  { resValue: "30s", value: "30sec", label: "30sec" },
  { resValue: "31s", value: "31sec", label: "31sec" },
  { resValue: "32s", value: "32sec", label: "32sec" },
  { resValue: "33s", value: "33sec", label: "33sec" },
  { resValue: "34s", value: "34sec", label: "34sec" },
  { resValue: "35s", value: "35sec", label: "35sec" },
  { resValue: "36s", value: "36sec", label: "36sec" },
  { resValue: "37s", value: "37sec", label: "37sec" },
  { resValue: "38s", value: "38sec", label: "38sec" },
  { resValue: "39s", value: "39sec", label: "39sec" },
  { resValue: "40s", value: "40sec", label: "40sec" },
  { resValue: "41s", value: "41sec", label: "41sec" },
  { resValue: "42s", value: "42sec", label: "42sec" },
  { resValue: "43s", value: "43sec", label: "43sec" },
  { resValue: "44s", value: "44sec", label: "44sec" },
  { resValue: "45s", value: "45sec", label: "45sec" },
  { resValue: "46s", value: "46sec", label: "46sec" },
  { resValue: "47s", value: "47sec", label: "47sec" },
  { resValue: "48s", value: "48sec", label: "48sec" },
  { resValue: "49s", value: "49sec", label: "49sec" },
  { resValue: "50s", value: "50sec", label: "50sec" },
  { resValue: "51s", value: "51sec", label: "51sec" },
  { resValue: "52s", value: "52sec", label: "52sec" },
  { resValue: "53s", value: "53sec", label: "53sec" },
  { resValue: "54s", value: "54sec", label: "54sec" },
  { resValue: "55s", value: "55sec", label: "55sec" },
  { resValue: "56s", value: "56sec", label: "56sec" },
  { resValue: "57s", value: "57sec", label: "57sec" },
  { resValue: "58s", value: "58sec", label: "58sec" },
  { resValue: "59s", value: "59sec", label: "59sec" },
];

export const Location = [
  { value: "Midrand", label: "Midrand" },
  { value: "Cape Town", label: "Cape Town" },
  { value: "Johannesburg", label: "Johannesburg" },
  { value: "Hatfield", label: "Hatfield" },
  { value: "Remote/Online", label: "Remote/Online" },
];

export const Company = [
  {
    value: "Academic Institute of Excellence (AIE)",
    label: "Academic Institute of Excellence (AIE)",
  },
  {
    value: "Greenside Design Center (GDC)",
    label: "Greenside Design Center (GDC)",
  },
  { value: "Learnfast", label: "Learnfast" },
  { value: "Cadco", label: "Cadco" },
];

export const Title = [
  { value: "Mr", label: "Mr" },
  { value: "Mrs", label: "Mrs" },
  { value: "Miss", label: "Miss" },
  { value: "Dr", label: "Dr" },
  { value: "Prof", label: "Prof" },
];

export const Status = [
  { value: "Online", label: "Online" },
  { value: "Offline", label: "Offline" },
  { value: "Donotdisturb", label: "Do not disturb" },
];

export const Active = [
  { value: "0", label: "Inactive" },
  { value: "1", label: "Active" },
];

export const School = [
  { value: "School 1", label: "School 1" },
  { value: "School 2", label: "School 2" },
];

export const TermPeriods = [
  { value: "Semester (2 Terms)", label: "Semester (2 Terms)" },
  { value: "Trimester (3 Terms)", label: "Trimester (3 Terms)" },
];

export const DeliveryMethod = [
  { value: "FTC", label: "FTC" },
  { value: "FTC_CPT", label: "FTC_CPT" },
  { value: "FTOL", label: "FTOL" },
  { value: "PTOL", label: "PTOL" },
];

export const ModuleType = [
  { value: "Module Type 1", label: "Module Type 1" },
  { value: "Module Type 2", label: "Module Type 2" },
];
