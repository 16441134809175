
import React, { useEffect, useState } from 'react';
import { GetAssembliesLists, AddAndUpdateAssembliesItems, DeleteAssemblyResource, GetAssembliesListsCancelToken } from '../../../../services/CourseService';
import TreeTheme from "react-sortable-tree-theme-minimal";
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import AddCategory from './AddCategory';
import AddSubCategory from './AddSubCategory';
import Skeleton from '../../../../loaders/SkeletonTicketList';
import Swal from 'sweetalert2';
import $ from 'jquery';
import EditCategory from './EditCategory';
import EditSubCategory from './EditSubCategory';
import Placeholder from './PalaceHolder';
import { TrimText } from '../../../common/TrimText';
import { IconName } from './IconNames';
import AssessmentIcon from '../../../../assets/images/assessment-icon.png';
import PermissionsGate from '../../../../utils/permissionGate';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const TitleWithIcon = ({ data, itemTitle }) => {
  const typeName = {
    1: "category",
    2: "video",
    3: "subCategory",
    4: "document",
    5: "link",
    6: "assessment",
    7: "label",
  }

  const docType = {
    0: "word",
    1: "excel",
    2: "pdf",
    3: "zip",
    4: "image",
    5: "powerpoint"
  }

  let icon_type = data.item_type === 4 ? docType[data.document_type] : typeName[data.item_type];




  return (
    <p className='assembly-list-title' title={itemTitle}>
      {<i className={"mr-2 " + IconName(icon_type)}></i>} {TrimText(itemTitle)}
    </p>
  )
}

const AssemblyListComponent = ({ id_assembly }) => {
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [parentId, setParentId] = useState("");
  const [data, setData] = useState();
  const [selectedType, setSelectedType] = useState();
  const [renderData, setRenderData] = useState();
  const [loading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const history = useHistory();
  const [childId, setchildId] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const getAssemblyListData = async () => {
      try {
        const res = await GetAssembliesListsCancelToken(id_assembly, dropdownSource.token);
          if(res.status == 200){
            let data_items = res.data.GetItem.map((item) => {
              return {
                id: item.item_id,
                title: <TitleWithIcon data={item} itemTitle={item?.item_name} />,
                type: item.item_type,
                parent_id: item.parent_id,
                data: { ...item },
                expanded: item.item_id == parentId ? true : false,
                className: "parent-node",
                children: item.GetItemchild.map((itemChild) => {
                  
                  return {
                    id: itemChild.item_id,
                    title: <TitleWithIcon data={itemChild} itemTitle={itemChild?.item_name} />,
                    type: itemChild.item_type,
                    parent_id: itemChild.parent_id,
                    data: { ...itemChild },
                    expanded: itemChild.item_id == childId ? true : false,
                    className: "child-node",
                    children: itemChild?.GetItemSubchild.map((subChild) => {
                      return {
                        id: subChild.item_id,
                        title: <TitleWithIcon data={subChild} itemTitle={subChild?.item_name} />,
                        type: subChild.item_type,
                        parent_id: subChild.parent_id,
                        expanded: false,
                        data: { ...subChild },
                        className: "subchild-node",
                      }
                    })
                  }
                })
              }
            });
            setRenderData(data_items);
            setLoading(false);
          }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    getAssemblyListData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [refresh]);

  const handleChangeData = (newData) => {
    
    setRenderData(newData);
  }

  const handleDragNode = (dragData) => {
    const { isDragging } = dragData;

    if (isDragging === false) {
      setIsDisabled(false);
    }

  }

  const handleSaveData = () => {
    setIsDisabled(true);
    let sendData = renderData.map((item) => {
      return {
        id: item.id,
        children: item.children ? item?.children.map((child) => {
          return {
            id: child.id,
            children: child.children ? child?.children.map((subChild) => {
              return {
                id: subChild.id
              }
            }) : []
          }
        }) : []
      }
    });
    const dataToSend = {
      assembly_id: id_assembly,
      param_items: sendData
    }
    AddAndUpdateAssembliesItems(dataToSend).then((res) => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `${res?.data?.message}`,
      }).then(() => {
        // getAssemblyListData();
        setRefresh(!refresh);
        // setIsDisabled(false);
      });
    }).catch((err) => {
      console.log(err);
      // setIsDisabled(false);
      Swal.fire({
        icon: "error",
        title: "error",
        text: `${err?.response?.data?.message}`,
      });
    })
  }

  const handleShowSubCategoryDialog = (nodeInfo) => {
    setSelectedType(nodeInfo.type);
    setParentId(nodeInfo.id)
    setShow(true);
    if(nodeInfo?.type === 3 ){
      setchildId(nodeInfo?.id);
      setParentId(nodeInfo?.parent_id);
    }
  }

  const handleCloseSubCategoryDialog = () => {
    setShow(false);
  }

  const handleEditItem = (nodeInfo, rowInfo) => {
    const { data, parent_id, type } = nodeInfo;
    if(rowInfo?.parentNode?.type === 3){
      setchildId(rowInfo?.parentNode?.id);
      setParentId(rowInfo?.parentNode?.parent_id);
    } else {
      setParentId(rowInfo?.parentNode?.id);
    }
    if (type === 1) {
      $("#editCategoryForm").modal("show");
    } else {
      setEditShow(true);
    }
    setData({ ...data });
  }


  const handleCloseEditDialog = () => {
    $("#editCategoryForm").modal("hide");
    setEditShow(false);
    setData();
  }

  const handleRemoveItem = (nodeInfo, rowInfo) => {
    // console.log("node", nodeInfo,rowInfo)
    const { id } = nodeInfo;
    setParentId(rowInfo?.parentNode?.id);
    if(rowInfo?.parentNode?.type === 3){
      setchildId(rowInfo?.parentNode?.id);
      setParentId(rowInfo?.parentNode?.parent_id);
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unlink it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAssemblyResource({ item_id: id }).then((res) => {
          if(res.status === 200){
            Swal.fire("Deleted!", "Your resource has been unlinked.", "success").then(()=>{
              // getAssemblyListData()
              setRefresh(!refresh);
            });
          }
        }).catch(error=>{
          console.log(error)
          Swal.fire({
            icon: "error",
            title: "error",
            text: `${error?.response?.data?.message}`,
          });
        });
      }
    });
  }

  const nodeToggle = (data) => {
  }
  {/* <div className="card card-profile-info-card mb-30">
    //   <div className="card-body">

    //     <div className="card-body-inr card-body-info">
    //       <div className="ass-bel-stuff"></div> */}
  return (
    <div className="p-15px">
      <div className="main-block" style={{ height: "auto" }}>
        <div className='tree-header'>
          <PermissionsGate errorProps={{disabled:true}} scopes={['caadd']}>
            <button title="Add Category" data-toggle="modal" data-target="#categoryForm" className='btn btn-primary'>
              <i className="fal fa-plus"></i> Add Category
            </button>
          </PermissionsGate>
        </div>
        <div className="tree-wrapper">
          {loading ?
            <Skeleton />
            :
            <SortableTree
              theme={TreeTheme}
              className="my-class"
              isVirtualized={false}
              treeData={renderData ? renderData : []}
              onChange={(treeData) => handleChangeData(treeData)}
              maxDepth={3}
              onDragStateChanged={(dragData) => handleDragNode(dragData)}
              style={{
                padding: "0px",
                width: "100%",
              }}
              onVisibilityToggle={(data) => nodeToggle(data)}
              generateNodeProps={(rowInfo) => {
                const { node, parentNode } = rowInfo;
                return {
                  class: (node.type === 1) ? "main-category-node" : node.type === 3 ? "sub-category-node" : "item-node",
                  buttons: [
                    <div className='drag-option-container'>
                      {(node.type === 3 || node.type === 1) &&
                        <PermissionsGate errorProps={{disabled:true}} scopes={["caadd"]}>
                          <button
                            label="Add Resource"
                            title="Add Resource"
                            className='drag-option btn btn-primary rounded-circle'
                            onClick={() => handleShowSubCategoryDialog(node, rowInfo)}
                          >
                            <i className="fal fa-plus"></i>
                          </button>
                        </PermissionsGate>
                      }
                      {node.type !== 6 && <PermissionsGate errorProps={{disabled:true}} scopes={["caedit"]}>
                        <button
                          className='drag-option btn btn-primary rounded-circle'
                          label="Edit"
                          title="Edit"
                          onClick={() => handleEditItem(node, rowInfo)}
                        >
                          <i className="fal fa-edit"></i>
                        </button>
                      </PermissionsGate>}
                      <PermissionsGate errorProps={{disabled:true}} scopes={["cadelete"]}>

                        <button
                          className='drag-option btn btn-danger rounded-circle'
                          label="Delete"
                          title="Unlink"
                          onClick={() => handleRemoveItem(node, rowInfo)}
                        >
                          <i className="fal fa-unlink"></i>
                        </button>
                      </PermissionsGate>
                    </div>
                  ],
                  style: { borderRadius: "10px", width: "100%" }
                }
              }}
              placeholderRenderer={Placeholder}
            />
          }
        </div>
        <AddCategory
          assembly_id={id_assembly}
          refreshList={() => { setRefresh(!refresh); }}
        />
        {parentId !== "" &&
          <AddSubCategory
            show={show}
            close={() => { handleCloseSubCategoryDialog() }}
            parent_id={selectedType === 3 ? childId : parentId}
            assembly_id={id_assembly}
            nodeType={selectedType}
            refreshList={() => { setRefresh(!refresh); }}
          />
        }

        <EditCategory
          editData={data}
          close={() => { handleCloseEditDialog() }}
          refreshList={() => { setRefresh(!refresh); }}
        />
        {editShow &&
          <EditSubCategory
            assembly_id={id_assembly}
            show={editShow}
            refreshList={() => { setRefresh(!refresh); }}
            editData={data}
            close={() => { handleCloseEditDialog() }}
          />
        }
      </div>
      <div className="form-group form-group-save-cancel mt-3 mb-0">
        <PermissionsGate errorProps={{disabled:true}} scopes={["caedit"]}>
          <button className="btn btn-primary" type="button" onClick={() => { handleSaveData() }} title="Save">
            {/* {isDisabled === false ? <i className="fal fa-save"></i> : <i className="fas fa-cog fa-spin"></i>} */}
            <i className="fal fa-save"></i>
            Save
          </button>
        </PermissionsGate>
        <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.push("/courses/content/assemblies/table")}>
          <i className="fal fa-times"></i>Cancel
        </button>
      </div>
    </div>
  );
};

export default AssemblyListComponent;