import React from 'react';
import CertificateGenerateContainer from '../CertificateContainer';

const ImageTemplate = (props) => {
  return (
    <>
      <div>
        <CertificateGenerateContainer
          {...props}
        />
      </div>
    </>
  );
};

export default ImageTemplate;
