import React, { useEffect, useState, useMemo } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import moment from 'moment-timezone'
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import Str from "../../common/Str";
import { BBBCommand, GetRecordingCeckerList, GetRecordingCeckerListCancelToken, ResubmitView } from "../../../services/ClassMgtService";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import $ from 'jquery';
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import { Link } from "react-router-dom";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

const OnlineClassChecker = () => {

  const history = useHistory();
  const [dataArr, setDataArr] = useState([]);
  const [viewBId, setViewBid] = useState("");
  const [downloadBId, setDownloadBid] = useState("");
  const [search, setSearch] = useState("");
  const [serverRes, setServerRes] = useState("");
  const [loading,setloading] = useState(true);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    setloading(true)
  
    const fetchData = async () => {
      try {
        const res = await GetRecordingCeckerListCancelToken(dropdownSource.token);
        if (res?.status == 200) {
          setDataArr(res && res.data ? res.data : []);
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);



  function dataToRender() {
    let updatedData = [];
    let allData = dataArr;
    if (search.length) {

      let tempUser = allData.filter((item) => {
        let includes = item?.class_title.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempClassid = allData.filter((item) => {
        let includes = item?.class_id.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempBBBID = allData.filter((item) => {
        let includes = item?.big_blue_button_id.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempUser,...tempClassid,...tempBBBID];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (search.length) {
      return updatedData.length > 0 ? updatedData : [];
    } else {
      return dataArr;
    }
  }
  const sortingDate = (rowA, rowB) => {
    // console.log("start_date->",rowA?.start_date);
    if (rowA?.start_date != null && rowB?.start_date != null && rowA?.start_date) {
      const name1 = new Date(rowA?.start_date);
      const name2 = new Date(rowB?.start_date);
      return name1 - name2;
    }
  };
  const sortingEndDate = (rowA, rowB) => {
    // console.log("end_date->", rowA?.end_date);
    if (rowA?.end_date != null && rowB?.end_date != null && rowA?.end_date) {
      const name1 = new Date(rowA?.end_date);
      const name2 = new Date(rowB?.end_date);
      return name1 - name2;
    }
  };

  const columns = useMemo(() => [
    // {
    //   name: "S.No.",
    //   cell: (row, index) => ++index,
    // },
    {
      name: "Title",
      selector: "class_title",
      sortable: true,
      cell: (row) => {
        return <div className="assigned-title-block-new">
          <div className="dashboard-message-count">
            <div className="assigned-title-itm overflow-ellipsis2 fst_line2">
              <Link
                className="as-text-blue curser textLimit2"
                to={`/courseAdministration/coursesdetails/${row?.intake_id}/onlineClasses/show`}
                title={row?.class_title + " " + row?.class_id}
              >
                {row?.class_title ? row?.class_title + " " + row?.class_id : "-"}
              </Link>
            </div>
          </div>
        </div>
      }
    },
    {
      name: "Class ID",
      selector: "big_blue_button_id",
      sortable: true,
      cell: (row) => {
        return <span className="feature-name"><span className="textLimit100">{row?.big_blue_button_id ? row?.big_blue_button_id : "-"}</span></span>
      }
    },
    {
      name: "Date",
      selector: "start_date",
      sortable: true,
      sortFunction: sortingDate,
      cell: (row) => (
        <>{row.start_date ?
          <div className="dateTime">
            <p className="right-space">{moment(row.start_date).format(TABLE_DATE_FORMAT)}</p>
            <p>{moment(row?.start_date).format(TABLE_TIME_FORMAT) + " - " + moment(row?.end_date).format(TABLE_TIME_FORMAT)}</p>
          </div>
          : "-"
        }</>
      )
    },
    {
      name: "View Rendering Duration",
      selector: "eviewDuration",
      // sortable: true,
      cell: (row) => (
        <p>{row?.eviewDuration ? `${row?.eviewDuration?.d}d :${row?.eviewDuration?.h}h :${row?.eviewDuration?.m}min` : "-"}</p>
      )
    },
    {
      name: "Download Rendering Duration",
      selector: "edownloadDuration",
      // sortable: true,
      cell: (row) => (
        <p>{row?.edownloadDuration ? `${row?.edownloadDuration?.d}d :${row?.edownloadDuration?.h}h :${row?.edownloadDuration?.m}min` : "-"}</p>
      )
    },
    {
      name: "Ended At",
      selector: "end_date",
      sortable: true,
      sortFunction: sortingEndDate,
      cell: (row) => {
        return <>{row.end_date ?
          <div className="dateTime">
            <p className="right-space">{moment(row.end_date).format(TABLE_DATE_FORMAT)}</p>
            <p>{moment(row.end_date).format(TABLE_TIME_FORMAT)}</p>
          </div>
          : "-"
        }</>
      }
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button className="btn btn-primary rounded-circle" onClick={() => { viewDownloadRecord(row.big_blue_button_id, row.server_id, 'view') }} title="View"><i className="fal fa-eye"></i></button>
            <button className="btn btn-primary rounded-circle" onClick={() => { viewDownloadRecord(row.big_blue_button_id, row.server_id, 'download') }} title="Download"><i className="fal fa-arrow-down"></i></button>
          </div>
        </div>
      ),
    },
  ]);

  function viewDownloadRecord(id, server_id = null, type = "view") {
    if (type == "view") {
      setViewBid(id);
      setDownloadBid("");
    } else {
      setDownloadBid(id);
      setViewBid("");
    }
    let formData = new FormData();
    formData.append("action", type);
    formData.append("bbbid", id);
    ResubmitView(formData).then(data => {
      // console.log("data ---> ", data);
      if (data && data.data) {
        if (data.data.status == 0) {
          Swal.fire("", data.data.message, "warning");
        } else if (data.data.status == 1) {
          Swal.fire("", data.data.message, "warning");
        } else if (data.data.status == 2) {
          Swal.fire({
            title: "",
            text: data.data.message,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm",
          }).then((result) => {
            if (result.isConfirmed) {
              let formDetails = new FormData();
              formDetails.append("action", "LB_runCommand");
              formDetails.append("command", data.data.command);
              formDetails.append("server_id", server_id !== null ? server_id : data.data.server_id);
              $("#roomCampusModal").modal("show");
              BBBCommand(formDetails).then(response => {
                let resMsg = response && response.data && response.data.stdout !== "" ? response.data.stdout : response.data.stderr;
                setServerRes(resMsg);
              }).catch(err => {
                console.log("err >>>> ", err);
              })
            } else {
            }
          })
        }
      }
    }).catch(error => {
      console.log("error ----> ", error);
    })
  }

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const difference = (obj1, obj2) => {
    let keyFound = [];
    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        return keyFound.push(key + ":" + obj1[key]);
      }
    });
    return keyFound;
  };

  const oldValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.Old);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(row.description.object.definition?.Old, row.description.object.definition?.New);
      return diff.length !== 0 ? `${diff.sort()}` : "NA";
    }
    // else if (row.description.verb.Action === "Profile Picture Edited") {
    //   return  row.description.object.definition?.Old?.picture_me;
    // }
    else {
      return `${entries.map((entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "N/A"}`)}`;
    }
  };

  const NewValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.New);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(row.description.object.definition?.New, row.description.object.definition?.Old);
      return diff.length !== 0 ? `${diff.sort()}` : "NA";
    } else if (row.description.verb.Action === "Profile Picture Edited") {
      return row.description.object.definition?.New?.picture_me.toString().length > 30
        ? "picture_me:" + row.description.object.definition?.New?.picture_me
        : row.description.object.definition?.New?.picture_me;
    } else {
      return `${entries.map((entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "N/A"}`)}`;
    }
  };

  const modifiedData = () => {
    let data = dataToRender();

    data = data?.map((row, ind) => ({
      ...row,
      "Title": row?.class_title ? row?.class_title + " " + row?.class_id : "-",
      "Class ID": row?.big_blue_button_id ? row?.big_blue_button_id : "-",
      "Date": row?.start_date ? moment(row?.start_date).format(TABLE_DATE_FORMAT) + " " + moment(row?.start_date).format(TABLE_TIME_FORMAT) + " - " + moment(row?.end_date).format(TABLE_TIME_FORMAT) : "-",
      "View Rendering Duration": row?.eviewDuration ? `${row?.eviewDuration?.d}d :${row?.eviewDuration?.h}h :${row?.eviewDuration?.m}min` : "-",
      "Download Rendering Duration": row?.edownloadDuration ? `${row?.edownloadDuration?.d}d :${row?.edownloadDuration?.h}h :${row?.edownloadDuration?.m}min` : "-",
      "Ended At": row?.end_date ? moment(row?.end_date).format(TABLE_DATE_FORMAT) + " " + moment(row?.end_date).format(TABLE_TIME_FORMAT) : "-",
    }));

    return data;
  };

  const exportData = (fileType, fileName) => {
    let data = modifiedData();

    const header = ["Title", "Class ID", "Date", "View Rendering Duration", "Download Rendering Duration", "Ended At"];

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      // console.log(csvString);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row["Title"], row["Class ID"], row["Date"], row["View Rendering Duration"], row["Download Rendering Duration"], row["Ended At"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        columnStyles: {
          0: {
            columnWidth: 40,
          },
          1: {
            columnWidth: 40,
          },
          2: {
            columnWidth: 24,
          },
          3: {
            columnWidth: 24,
          },
          4: {
            columnWidth: 24,
          },
          5: {
            columnWidth: 20,
          },
        },
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const resetFilter = () => {
    setSearch("");
  };

  const closeModal = () => {
    $("#roomCampusModal").modal("hide");
    setTimeout(() => { setServerRes(""); }, 1000)
  }

  return (
    <>
      <div className="card card-profile-info-card class-mgt-online-class">
        <div className="card-header pt-0">Check Recording View</div>
        <div className={`card-body `}>
          <div className="card-body-inr card-body-info">
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="form-group">
                  <label>Enter BBB ID of Class</label>
                  <input className={"form-control"} name="ViewRecording" value={viewBId} onChange={(e) => { setViewBid(e.target.value) }} />
                </div>
              </div>
            </div>
            <div className="form-group form-group-save-cancel">
              <button className="btn btn-save btn-success" type="button" title="Save" onClick={() => { viewDownloadRecord(viewBId, null, "view") }}>
                <i className="fal fa-save"></i>
                Submit
              </button>
            </div>
            {/* </form> */}
          </div>
        </div>

        <div className="card-header">Check Recording Download</div>
        <div className={`card-body `}>
          <div className="card-body-inr card-body-info">
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="form-group">
                  <label>Enter BBB ID of Class</label>
                  <input className={"form-control"} name="DownloadRecording" value={downloadBId} onChange={(e) => { setDownloadBid(e.target.value) }} />
                </div>
              </div>
            </div>
            <div className="form-group form-group-save-cancel">
              <button className="btn btn-save btn-success" type="button" title="Save" onClick={() => { viewDownloadRecord(downloadBId, null, "download") }}>
                <i className="fal fa-save"></i>
                Submit
              </button>
            </div>
            {/* </form> */}
          </div>
        </div>

        <div className="card-header">Classes in Danger Zone</div>
        <div className="my-tickets-info-list Tickets-main-wrap">
          <div className="custom-table-div filter-search-icon card-table-custom">
            <div className="search-filter-div">
              <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                        <div id="assessment-table-main_filter" className="dataTables_filter">
                          <label>
                            <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                          </label>
                          <div className="filter-eff filter-data-btn">
                            <button className="filter-buttons">
                              <i className="fal fa-filter"></i>
                            </button>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                    
                    <div className="reset-btn-group">
                      <div className="button-reset dropdown-comman">
                        <button className="btn btn-primary" onClick={resetFilter} title="Reset" >
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>

                      <div className="files-export-group">
                        <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Class_In_Danger_Zone"); }} title="Export spreadsheet" >
                          <i className="fal fa-file-excel icon"></i>
                        </button>
                        <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Class_In_Danger_Zone"); }} title="Export CSV" >
                          <i className="fal fa-file-csv icon"></i>
                        </button>
                        <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Class_In_Danger_Zone"); }} title="Export PDF" >
                          <i className="fal fa-file-pdf icon"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? <SkeletonTicketList />
              : <DataTable
                data={dataToRender()}
                columns={columns}
                pagination={true}
                noDataComponent={Str.noRecord}
                defaultSortAsc={true}
                defaultSortField="start_date"
                paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              />
            }
          </div>
        </div>
      </div>

      <div className="topic-add-modal modal fade server-modal" id="roomCampusModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-top modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="font-weight-bold">Command Result</h5>
              <button type="button" className="close" onClick={() => { closeModal() }}>
                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close"> */}
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="topic-add-modal-content text-center">
                {serverRes === "" && <div className="w-100 text-center"><i className="fas fa-cog fa-spin"></i></div>}
                {serverRes !== "" && <pre>{serverRes}</pre>}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default OnlineClassChecker;

