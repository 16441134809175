import { useEffect } from "react";
import React, { useState, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw, ContentState, Modifier, Entity, AtomicBlockUtils } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import $ from "jquery";
import embed from "embed-video";

function HtmlInputEditor({ editorState, setEditorState, readOnly, data, attachmentClick, sendClick, placeholder, subjectLine, updateSubjectLine, resetFeed, attachments, isImportant, setIsImportant, showcurservalue }) {
    const [users, setUsers] = useState('');
    const isInvalid = false;
    const [sendActive, setSendActive] = useState(false);
    const [editorActive, setEditorActive] = useState(false);
    const [currentimage, setcurrentimage] = useState(true);
    const [inputFocus, setInputFocus] = useState(false);
    const editorRefInput = useRef();
    const editorReference = useRef();

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    };

    useEffect(() => {
        const messageValue = convertToRaw(editorState.getCurrentContent());
        feedStateChange(messageValue)
    }, [attachments]);

    useEffect(() => {
        editorReference.current.focusEditor();
    }, [showcurservalue]);

    useEffect(() => {
        setIsImportant(false)
        setEditorActive(false)
    }, [resetFeed]);

    useEffect(() => {
        setTimeout(() => {
            editorRefInput.current.focus();
        }, 50);
    }, []);

    const getBase64 = async (file) => {
        
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const addCls = () => {
        setEditorActive(true)
    }

    const removeCls = (status = '') => {
        // setTimeout(() => {
        //     setEditorActive(false)
        // }, 50);
    }

    function uploadImageCallBack(file) {
        
        return new Promise((resolve, reject) => {
            getBase64(file)
                .then((data) => {
                    
                    resolve({ data: { link: data } });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    const handlePastedFiles = async (files) => {
        getBase64(files[0])
            .then((data) => {
                
                setEditorState(insertImage(data));
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const insertImage = (url) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: url });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
    };

    // const toggleImportant = () => {
    //     setIsImportant(!isImportant)
    // }

    const feedStateChange = (e) => {
        let blocks = e.blocks
        let tempTxt = ''
        blocks.map(i => {
            tempTxt = tempTxt + '' + (i.text).trim()
        })
        let entitymap = e.entityMap
        let embedArr = []
        Object.values(entitymap).forEach(entity => {
            if (entity.type === 'EMBEDDED_LINK') {
                embedArr.push(entity.data.src);
            }
        });

        if ((tempTxt != '') || (embedArr.length != 0) || (attachments?.length != 0)) {
            setSendActive(true)
        }
        else {
            setSendActive(false)
        }
    }

    return (


        <div className={(!isInvalid ? "post-input-content mb-0" : "file-req is-invalid mb-0") + ' ' + (isImportant ? "isimportant" : "")}>

            {isImportant ? <i class="fal fa-exclamation important-icon"></i> : null}
            {/* {(editorActive || inputFocus) ? */}
            <div className="post-static-input">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Add a Subject"
                    ref={editorRefInput}
                    onChange={updateSubjectLine}
                    value={subjectLine}
                    onFocus={() => { setInputFocus(true) }}
                    maxLength={140}
                    onBlur={() => {
                        setTimeout(() => {
                            setInputFocus(false)
                        }, 200);
                    }}
                />
            </div>
            {/* : null} */}
            <Editor
                readOnly={readOnly}
                editorState={editorState}
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                handlePastedFiles={handlePastedFiles}
                onChange={e => feedStateChange(e)}
                placeholder={placeholder}
                ref={editorReference}
                toolbarHidden={false} // !editorActive && !inputFocus
                onFocus={() => {
                    addCls()
                }}
                // onBlur={() => {
                //     removeCls()
                // }}
                mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: data,
                }}
                toolbar={{
                    options: ['inline', 'textAlign', 'list', 'embedded'
                        //  'blockType', 'fontSize', 'fontFamily',   'colorPicker',  'image', 'link', 'embedded', 'emoji',  'remove', 'history'
                    ],
                    inline: {
                        options: ['bold', 'italic', 'underline'],
                        italic: { className: 'i-italic-icon' },
                        bold: { className: 'i-bold-icon' },
                        underline: { className: 'i-underline-icon' },
                    },
                    list: {
                        options: ['unordered', 'ordered'],
                        unordered: { className: 'i-unordered-icon' },
                        ordered: { className: 'i-ordered-icon' },
                        // indent: { className: 'i-indent-icon' },
                        // outdent: { className: 'i-outdent-icon' },
                    },
                    textAlign: {
                        options: ['left', 'center', 'right', 'justify'],
                        left: { className: 'i-left-icon' },
                        center: { className: 'i-center-icon' },
                        right: { className: 'i-right-icon' },
                        justify: { className: 'i-justify-icon' },
                    },
                    blockType: {
                        inDropdown: false,
                        options: ['H1', 'Blockquote'],
                        className: 'i-bloctype-icon',
                        // H1: {className: 'i-h1-icon' },
                        // Blockquote: {className: 'i-blockquote-icon' },
                    },
                    image: {
                        urlEnabled: true,
                        uploadEnabled: true,
                        previewImage: true,
                        uploadCallback: uploadImageCallBack,
                        inputAccept: 'application/zip,application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,image/gif,image/jpeg,image/jpg,image/png,image/svg'
                    },
                    embedded: {
                        className: 'i-embedded-icon',
                        embedCallback: link => {
                            const detectedSrc = /<iframe.*? src="(.*?)"/.exec(embed(link));
                            return (detectedSrc && detectedSrc[1]) || link;
                        }
                    }
                }}
            />
            <div className="action-input-content">
                <button onClick={() => { setIsImportant(!isImportant) }} className={`btn btn-input-action ${isImportant ? 'active' : ''}`} title="Important"><i className="fal fa-exclamation"></i></button>
                <button onClick={attachmentClick} className="btn btn-input-action" title="Add Attachment"><i className="fal fa-paperclip"></i></button>
                {sendActive ?
                    <button onClick={sendClick} className="btn btn-input-action" title="Send"><i className="fal fa-paper-plane"></i></button>
                    :
                    <button onClick={() => { }} className="btn btn-input-action" title="Send" disabled><i className="fal fa-paper-plane"></i></button>
                }
            </div>
        </div>

    );
}

export default HtmlInputEditor;


// import { useEffect } from "react";
// // import { GetCategoryDetail } from "../../../services/StudentService";
// import "jspdf-autotable";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import React, { useState, Component } from "react";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, convertFromRaw, convertToRaw, ContentState, Modifier, Entity, AtomicBlockUtils, RichUtils } from "draft-js";
// import PropTypes from 'prop-types';
// import { BlockPicker } from 'react-color';

// class ColorPic extends Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       hexColor: "#000",
//       expanded: false
//     }
//     this.handleExpand = this.handleExpand.bind(this);
//   }


//   static propTypes = {
//     onExpandEvent: PropTypes.func,
//     onChange: PropTypes.func,
//     currentState: PropTypes.object,
//   };

//   onChange = (color) => {
//     this.setState({ hexColor: color.hex });
//     const { onChange, editorState } = this.props;
//     // const { editorState, onChange } = this.props;
//     // RichUtils.toggleInlineStyle(
//     //       editorState,
//     //       'CODE'
//     //     )
//     onChange('color', color.hex);


//     // const newState = RichUtils.toggleInlineStyle( editorState, 'CODE');
//     // const { editorState, onChange } = this.props;
//     //   const newState = RichUtils.toggleInlineStyle(
//     //     editorState,
//     //     'CODE'
//     //   );
//     //   if (newState) {
//     //     onChange(newState);
//     //   }
//   }

//   handleExpand() {
//     this.setState({ expanded: !this.state.expanded });
//   }

//   stopPropagation = (event) => {
//     event.stopPropagation();
//   };

//   renderModal = () => {
//     // const { color } = this.props.currentState;
//     return (
//       <div
//         className="rdw-link-modal custom-picker"
//         onClick={this.stopPropagation}
//       >
//         <BlockPicker color={this.state.hexColor} onChangeComplete={this.onChange} />
//       </div>
//     );
//   };

//   render() {
//     const { expanded, onExpandEvent } = this.props;
//     return (
//       <div className="rdw-link-wrapper" aria-haspopup="true" aria-label="rdw-color-picker" >
//         <div className="rdw-option-wrapper" onClick={this.handleExpand} >
//           <img src={'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTQuNDA2LjU4NWExLjk5OCAxLjk5OCAwIDAgMC0yLjgyNSAwbC0uNTQuNTRhLjc0MS43NDEgMCAxIDAtMS4wNDggMS4wNDhsLjE3NS4xNzUtNS44MjYgNS44MjUtMi4wMjIgMi4wMjNhLjkxLjkxIDAgMCAwLS4yNjYuNjAybC0uMDA1LjEwOHYuMDAybC0uMDgxIDEuODI5YS4zMDIuMzAyIDAgMCAwIC4zMDIuMzE2aC4wMTNsLjk3LS4wNDQuNTkyLS4wMjYuMjY4LS4wMTJjLjI5Ny0uMDEzLjU3OS0uMTM3Ljc5LS4zNDdsNy43Ny03Ljc3LjE0Ni4xNDRhLjc0Ljc0IDAgMCAwIDEuMDQ4IDBjLjI5LS4yOS4yOS0uNzU5IDAtMS4wNDhsLjU0LS41NGMuNzgtLjc4Ljc4LTIuMDQ0IDAtMi44MjV6TTguNzk1IDcuMzMzbC0yLjczLjUxNSA0LjQ1Mi00LjQ1MiAxLjEwOCAxLjEwNy0yLjgzIDIuODN6TTIuMDggMTMuNjczYy0xLjE0OCAwLTIuMDguMjk1LTIuMDguNjYgMCAuMzYzLjkzMi42NTggMi4wOC42NTggMS4xNSAwIDIuMDgtLjI5NCAyLjA4LS42NTkgMC0uMzY0LS45My0uNjU5LTIuMDgtLjY1OXoiLz48L2c+PC9zdmc+'} alt="" />
//         </div>
//         {this.state.expanded ? this.renderModal() : undefined}
//       </div>
//     );
//   }
// }

// function HtmlInputEditor({ editorState, setEditorState, readOnly, hideSign}) {

//   const isInvalid = false;
//   const onEditorStateChange = (editorState) => setEditorState(editorState);

//   useEffect(() => { }, []);

//   const getBase64 = async (file) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => resolve(reader.result);
//       reader.onerror = (error) => reject(error);
//     });
//   };

//   function uploadImageCallBack(file) {
//     return new Promise((resolve, reject) => {
//       getBase64(file)
//         .then((data) => {
//           // console.log("returned file in base64 is ", data);
//           resolve({ data: { link: data } });
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   }

//   function uploadImageCallBack(file) {
//     return new Promise((resolve, reject) => {
//       getBase64(file)
//         .then((data) => {
//           resolve({ data: { link: data } });
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   }

//   const handlePastedFiles = async (files) => {
//     getBase64(files[0])
//       .then((data) => {
//         setEditorState(insertImage(data));
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   const insertImage = (url) => {
//     const contentState = editorState.getCurrentContent();
//     const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", { src: url });
//     const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
//     const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
//     return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
//   };

//   const styleMap = {
//     'CODE': {
//       color: `#ffffff`
//     },
//   };

//   return (
//     <div>
//       <div className="card-body-inr card-body-info">
//         <div>
//           <div className={!isInvalid ? " mb-4 editor-static " : "file-req is-invalid mb-4"}>
//             <Editor
//               readOnly={readOnly}
//               defaultEditorState={editorState}
//               editorState={editorState}
//               editorClassName="editorClassName"
//               onEditorStateChange={onEditorStateChange}
//               handlePastedFiles={handlePastedFiles}
//               toolbar={{
//                 image: { urlEnabled: true, uploadEnabled: true, uploadCallback: uploadImageCallBack },
//                 fontFamily: {
//                   options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Segoe UI Light'],
//                 },
//                 colorPicker: { component: ColorPic },
//               }}
//               customStyleMap={styleMap}
//             />
//             {!hideSign && <div className="editor-static-blk">
//               <h4></h4>
//               <div className="editor-static-blk-info">
//                 <p>Kind Regards,</p>
//                 <br></br>
//                 <p>myAIE Student Support Team</p>
//                 <p>students@myaie.ac</p>
//                 <p>www.myaie.ac</p>
//               </div>
//             </div>}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default HtmlInputEditor;
