import React, { useEffect, useMemo, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { GetCouresListDropdownCancelToken, GetCourselist, GetCourselistCancelToken, deleteCourse } from '../../services/DashBoardServices';
import moment from 'moment';
import { IMAGE_URL, SP_PASS, TABLE_DATE_FORMAT } from '../../utils/Constants';
import { RenderCourseType, RenderLearningMethod, RenderSchool } from '../../utils/CommonGroupingItem';
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from '../../utils/permissionGate';
import Swal from "sweetalert2";
import { RenderReadStatus } from '../../utils/CommonStatusItems';
import DataTableComponent from '../common/DataTableComponent';
import axios from "axios";
import $ from "jquery";
import { ColorRender } from '../systemadministration/usermanagement/CheckRole';
import { InitialRender } from '../common/Helper';
import Hover from '../common/Hover';
import CopyCourse from '../courses/CopyCourse';
import { handleTableScroll } from '../../utils/commonFunction';

function PastCourses() {
    const history = useHistory();
    const { type } = useParams()
    const [programmeIds, setProgrammeIds] = useState({ arr: [], checkObj: [] });
    const [courseStartDate, setCourseStartDate] = useState("")
    const [courseEndDate, setCourseEndDate] = useState("")
    const [courseDateRange, setCourseDateRange] = useState({startDate:null, endDate : null})
    const [search, setSearch] = useState("");
    const [intake, setIntake] = useState({ arr: [], checkObj: {} });
    const [reply, setReply] = useState({ arr: [], checkObj: {} });
    const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
    const [school, setSchool] = useState({ arr: [], checkObj: {} });
    const [courseDropdown, setCourseDropdown] = useState({ programme: [], intake: [], learning: [], school: [], type: [] })
    const [courseType, setCourseType] = useState({ arr: [], checkObj: {} });
    const [showing, setShowing] = useState({ arr: ["1"], checkObj: { "showing1": true } });
    const [deleteRow, setDeleteRow] = useState(false);
    const [tableState, setTableState] = useState({
        page: 1,
        perPage: 10,
        sortKey: "start",
        sortOrder: "DESC"
    })
    const [courseId, setCourseId] = useState("");
    const [isShowingUser, setIsShowingUser] = useState([]);
    const [loading, setLoading] = useState(true)
    const [courseList, setCourseList] = useState([]);
    const [totalRows, setTotalRows] = useState(0);

    useEffect(() => {
        handleTableScroll()
      }, [loading])
    
      useEffect(() => {
        $(document).ready(function () {
          $(".dropdown-toggle").click(function () {
            $('.rdt_TableCell').css('z-index', 0)
            $(this).parents('.rdt_TableCell').css('z-index', 22)
          });
        });
      })
      useEffect(() => {
        const storedFilters = JSON.parse(localStorage.getItem("filterStatePastCoursesDashboard"));
        if (storedFilters) {
            setLearningMethod(storedFilters.learningMethod);
            setProgrammeIds(storedFilters.programmeIds);
            setIntake(storedFilters.intake);
            setReply(storedFilters.reply);
            setSchool(storedFilters.school);
            setCourseType(storedFilters.courseType);
        }
      }, []);
      useEffect(() => {
        const filterState = {
            learningMethod,
            programmeIds,
            intake,
            reply,
            school,
            courseType
        };
        localStorage.setItem("filterStatePastCoursesDashboard", JSON.stringify(filterState));
      }, [ learningMethod, programmeIds, intake, reply, school, courseType]);
    useEffect(() => {
        const cancelTokenDropdownSources = [];

        const fetchData = async () => {
            cancelTokenDropdownSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenDropdownSources.push(source);

            let data = {
                viaStatus: ["Past"]
            }

            try {
                const res = await GetCouresListDropdownCancelToken(data, source.token);
                setCourseDropdown({ programme: [], intake: [], learning: [], school: [], type: [] });                
                setCourseDropdown({ ...res.data, programme: res.data.programme, intake: res.data.yearsAndIntaks, learning: res.data.intakeType, school: res.data.schoolType ? res.data.schoolType : [], type: res.data.subjectTypeFilter });
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                }
            }
        }

        fetchData();

        return () => {
            cancelTokenDropdownSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };

    }, [type])

    useEffect(() => {
        const cancelTokenSources = [];
        const getDashboard = async () => {
            setLoading(true)
            cancelTokenSources.forEach(source => {
                source.cancel('New request made');
            });

            const source = axios.CancelToken.source();
            cancelTokenSources.push(source);

            let data = {
                pageNo: tableState.page,
                size: tableState.perPage,
                intake: intake.arr,
                viaLearnType: learningMethod.arr,
                key: tableState.sortKey,
                sort: tableState.sortOrder,
                viaStatus: ["Past"],
                viaSearch: search,
                viaProgramme: programmeIds.arr,
                viaMessage: reply.arr,
                viaSchoolType: school.arr,
                viaSubjectType: courseType.arr,
                viaStartDate : courseStartDate ? moment(courseStartDate, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
                viaEndDate : courseEndDate ? moment(courseEndDate, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
                viaRangeStartDate : courseDateRange?.startDate && courseDateRange?.endDate ? moment(courseDateRange?.startDate, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
                viaRangeEndDate : courseDateRange?.startDate && courseDateRange?.endDate ? moment(courseDateRange?.endDate, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
                showing: showing.arr[0]
            };
            try {
                const res = await GetCourselistCancelToken(data, source.token);
                setCourseList(res.data?.result);
                setTotalRows(res.data?.Pagination.total);
                if (res.status == 200) {
                    setLoading(false);
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error(error);
                    setLoading(false);
                }
            }
        }
        getDashboard();
        return () => {
            cancelTokenSources.forEach(source => {
                source.cancel('Component unmounted');
            });
        };
    }, [
        search,
        programmeIds,
        reply,
        learningMethod,
        intake,
        school,
        courseType,
        showing,
        deleteRow,
        tableState,
        type,
        courseStartDate,
        courseEndDate,
        courseDateRange.endDate
    ])

    const handleDeleteCourse = (id) => {
        const parameter = { intake: id };
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCourse(parameter).then((res) => {
                    if (res.status === 200) {
                        Swal.fire("Deleted!", "Your record has been deleted.", "success");
                        setDeleteRow(!deleteRow)
                    }
                });
            }
        });
    }

    const handleCopyCourse = (row) => {
        setCourseId(row.id);
        $("#copyCourse").modal({ backdrop: 'static', keyboard: false }, "show");
    }

    const handleShowingUser = (isShowing, id) => {
        let tempArr = isShowingUser;
        if (isShowing) {
          tempArr.splice(tempArr.indexOf(id), 1)
        } else {
          tempArr.push(id)
        }
        setIsShowingUser([...tempArr])
      }

    const columns = useMemo(() => [
        {
            name: "Name",
            selector: "name",
            sortField: "name",
            sortable: true,
            cell: (row) => (
                <div className="assigned-title-block-new">
                    <div className="dashboard-message-count">
                        {row.messageCount != 0 && row.messageCount != null ? <Link to={`/courseAdministration/coursesdetails/${row.id}/post-feed/show`} target="_blank" className='mr-2'>
                            <i title="You have unread messages" className="fal fa-comment-lines message-count unread-message-icon curser"></i>
                            <i title="You have unread messages" className="fal fa-comment-lines message-count hidden-icon curser"></i>
                            {(row.messageCount) > 0 ? <small className="notify-count1 unread-message-count">{(row.messageCount) < 10 ? (row.messageCount) : '9+'}</small> : null}
                        </Link> : ""}
                        <div
                            className="assigned-title-itm overflow-ellipsis2 fst_line2"
                            title={row.name}
                        >
                            <Link
                                className="as-text-blue curser textLimit2"
                                to={`/courseAdministration/coursesdetails/${row.id}/detailCourses/show`}
                                title={row.name}
                            >
                                {row.name ? row.name : "-"}
                            </Link>


                        </div>
                    </div>

                </div>
            ),
        },
        {
            name: "Start",
            selector: "start",
            sortField: "startdate",
            sortable: true,
            cell: (row) => (
                <>
                    {row.start ? (
                        <>
                            <p className="right-space">
                                {moment.unix(row.start).format(TABLE_DATE_FORMAT)}
                            </p>
                        </>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "End",
            selector: "end",
            sortable: true,
            sortField: "enddate",
            cell: (row) => (
                <>
                    {row.end ? (
                        <>
                            <p className="right-space">
                                {moment.unix(row.end).format(TABLE_DATE_FORMAT)}
                            </p>
                        </>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Learning Method",
            selector: "study_type",
            sortable: true,
            sortField: "type",
            cell: (row) => (
                <p className="learningMethodText">
                    {row && row.type ? RenderLearningMethod(row.type).html : "-"}
                </p>
            ),
        },
        {
            name: "Programme",
            selector: "qualificationName",
            sortable: true,
            cell: (row) => (
                <span className="overflow-ellipsis2 fst_line2">
                    <Link
                        className="as-text-blue curser"
                        title={row.qualificationName}
                        to={{
                            pathname: `/courseAdministration/Programmes/programme/open/${row.qualification_id}/details`,
                        }}
                    >
                        {row.qualificationName ? row.qualificationName : "-"}
                    </Link>
                    <span
                        onClick={() => {
                            history.push(
                                `/courseAdministration/Programmes/programme/open/${row.qualification_id}/details`
                            );
                        }}
                    ></span>
                </span>
            ),
        },
        {
            name: <i className="fal fa-users" title="Students" />,
            selector: "totalRows",
            sortable: true,
            sortField: "students",
            cell: (row) => <p>{row.totalRows ? row["totalRows"] : "-"}</p>,
        },
        {
            name: "Intake",
            selector: "year",
            sortable: true,
            sortField: "intake",
            cell: (row) => (
                <p>
                    {row.year || row.number ? (
                        <>
                            {" "}
                            {row.year ? row.year : ""}-{row.number ? row["number"] : ""}
                        </>
                    ) : (
                        "-"
                    )}
                </p>
            ),
        },
        {
            name: "Lecturer",
            selector: "lECTURER",
            sortable: true,
            sortField: "lecturer",
            cell: (row) => (
                <>
                    {row.lECTURER ? (
                        <div className="assigned-title-block-new">
                            <div className="assigned-title-itm">
                                <span
                                    className={
                                        "assigned-title-blk  name-icon cat-dark-red " +
                                        ColorRender("lecturer")
                                    }
                                >
                                    {row.lecturer_profile_pic ? (
                                        <img
                                            src={`${IMAGE_URL}/${row.lecturer_profile_pic.replaceAll(
                                                "/home/myaie/public_html/",
                                                ""
                                            )}`}
                                            alt="AIE"
                                        />
                                    ) : (
                                        InitialRender([
                                            row.lECTURER.split(" ")[0],
                                            row.lECTURER.replace(/ +/g, " ").split(" ")[1],
                                        ])
                                    )}
                                    <span className={`profile-box-2-status ${row.lecturer_activity_status ? row.lecturer_activity_status.toLowerCase() == "online" ? "Online" : row.lecturer_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                        <i className="fas fa-circle"></i>
                                    </span>
                                    <Hover
                                        firstName={row.lECTURER.split(" ")[0]}
                                        lastName={row.lECTURER.replace(/ +/g, " ").split(" ")[1]}
                                        photo={row.lecturer_profile_pic}
                                        email={row.lecturer_email}
                                        mobile={row.lecturer_contact ? row.lecturer_contact : ""}
                                        right={false}
                                        role={"lecturer"}
                                        status={row.lecturer_status}
                                        activity_status={row.lecturer_activity_status}
                                    />
                                </span>
                                <span
                                    title={row.lECTURER}
                                    className="as-text-blue curser feature-name width-155"
                                    onClick={() =>
                                        history.push(
                                            `/systemAdministration/userManagement/open/${row.lECTURERID}/aboutYou`
                                        )
                                    }
                                >
                                    <span className="textLimit100">{row.lECTURER ? row["lECTURER"] : "-"}</span>
                                </span>
                            </div>
                        </div>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: <i className="fal fa-chalkboard" title="Coach & Support" />,
            selector: "programme_coordinator_name",
            sortable: true,
            sortField: "coach",
            cell: (row) => {
                const programmeCoordinator = row.programme_coordinator_name ? <div className="assigned-title-block-new">
                    <div className="assigned-title-itm">
                        <span
                            className={
                                "assigned-title-blk  name-icon cat-dark-red " +
                                ColorRender("Coach")
                            }
                        >
                            {row.programme_coordinator_profile_pic ? (
                                <img
                                    src={`${IMAGE_URL}/${row.programme_coordinator_profile_pic.replace("public/", "").replaceAll(
                                        "/home/myaie/public_html/",
                                        ""
                                    )}`}
                                    alt="AIE"
                                />
                            ) : (
                                InitialRender([
                                    row.programme_coordinator_name.split(" ")[0],
                                    row.programme_coordinator_name
                                        .replace(/ +/g, " ")
                                        .split(" ")[1],
                                ])
                            )}
                            <span className={`profile-box-2-status ${row.coach_activity_status ? row.coach_activity_status.toLowerCase() == "online" ? "Online" : row.coach_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                <i className="fas fa-circle"></i>
                            </span>
                            <Hover
                                firstName={row.programme_coordinator_name.split(" ")[0]}
                                lastName={
                                    row.programme_coordinator_name
                                        .replace(/ +/g, " ")
                                        .split(" ")[1]
                                }
                                photo={row.programme_coordinator_profile_pic}
                                email={row.programme_coordinator_email}
                                mobile={
                                    row.programme_coordinator_contact
                                        ? row.programme_coordinator_contact
                                        : ""
                                }
                                status={row.coach_status}
                                activity_status={row.coach_activity_status}
                                right={false}
                                role={"Coach"}
                            />
                        </span>
                        {isShowingUser.includes(row.intakeID) || !row.SUPPORT ? <span
                            className="as-text-blue curser feature-name width-155"
                            title={row.programme_coordinator_name}
                            onClick={() =>
                                history.push(
                                    `/systemAdministration/userManagement/open/${row.COACHID}/aboutYou`
                                )
                            }
                        >
                            <span className="textLimit100">{row.programme_coordinator_name
                                ? row["programme_coordinator_name"]
                                : "-"}</span>
                        </span> : ""}
                    </div>
                </div> : ""

                const support = row.SUPPORT ? <div className="assigned-title-block-new">
                    <div className="assigned-title-itm">
                        <span
                            className={
                                "assigned-title-blk  name-icon cat-dark-red " +
                                ColorRender("student support")
                            }
                        >
                            {row.programme_support_profile_pic ? (
                                <img
                                    src={`${IMAGE_URL}/${row.programme_support_profile_pic.replace("public/", "").replaceAll(
                                        "/home/myaie/public_html/",
                                        ""
                                    )}`}
                                    alt="AIE"
                                />
                            ) : (
                                InitialRender([
                                    row.SUPPORT.split("")[0],
                                    row.SUPPORT.replace(/ +/g, " ").split(" ")[1],
                                ])
                            )}
                            <span className={`profile-box-2-status ${row.support_activity_status ? row.support_activity_status.toLowerCase() == "online" ? "Online" : row.support_activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                                <i className="fas fa-circle"></i>
                            </span>
                            <Hover
                                firstName={row.SUPPORT}
                                photo={row.programme_support_profile_pic}
                                email={row.programme_support_email}
                                mobile={
                                    row.programme_support_contact
                                        ? row.programme_coordinator_contact
                                        : ""
                                }
                                status={row.support_status}
                                activity_status={row.support_activity_status}
                                right={false}
                                role={"student support"}
                            />
                        </span>
                        {isShowingUser.includes(row.intakeID) || !row.programme_coordinator_name ? <span
                            className="as-text-blue curser feature-name width-155"
                            title={row.SUPPORT}
                            onClick={() =>
                                history.push(
                                    `/systemAdministration/userManagement/open/${row.programme_support_id}/aboutYou`
                                )
                            }
                        >
                            <span className="textLimit100">{row.SUPPORT ? row["SUPPORT"] : "-"}</span>
                        </span> : ""}
                    </div>
                </div> : ""

                return (
                    row.programme_coordinator_name && !row.SUPPORT ? programmeCoordinator :
                        !row.programme_coordinator_name && row.SUPPORT ? support :
                            !row.programme_coordinator_name && !row.SUPPORT ? "-" :
                                isShowingUser.includes(row.intakeID) ?
                                    <div>
                                        <div className="assigned-profile-tit"><span>Coach</span>{programmeCoordinator}</div>
                                        <div className="assigned-profile-tit"><span>Support</span>{support}</div>
                                        <div className="table-expand-btn" onClick={() => handleShowingUser(true, row.intakeID)}>See Less</div>
                                    </div>
                                    : <div className="assigned-profile-overlay-out">
                                        <div className="assigned-profile-overlay">{programmeCoordinator} {support} </div>
                                        <div className="table-expand-btn" onClick={() => handleShowingUser(false, row.intakeID)}>See More</div>
                                    </div>
                )
            }
        },
        {
            name: "School",
            selector: "school_type",
            sortable: true,
            sortField: "school_type",
            cell: (row) => <span className='textLimit75 width-155'>{row.school_type ? RenderSchool(row.school_type).html : "-"}</span>
        },
        {
            name: "Type",
            selector: "subject_type",
            sortable: true,
            sortField: "subject_type",
            cell: (row) => row.subject_type ? RenderCourseType(row.subject_type).html : "-"
        },
        {
            name: "Action",
            selector: "Action",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons d-flex">
                        <PermissionsGate scopes={["cedit"]} RenderError={() => (
                            <button className="btn btn-primary rounded-circle" title="Open" disabled>
                                <i className="fal fa-folder-open"></i>
                            </button>
                        )}>
                            <Link
                                className="btn btn-primary rounded-circle"
                                to={`/courseAdministration/coursesdetails/${row.id}/detailCourses/show`}
                                title="Open"
                            >
                                <i className="fal fa-folder-open"></i>
                            </Link>
                        </PermissionsGate>
                        <div className="dropdown btn-dropdown-item">
                            <button
                                className="btn btn-primary rounded-circle dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                title="More"
                            >
                                <i className="fal fa-ellipsis-h-alt"></i>
                            </button>
                            <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                            >
                                {(row.subject_type == "shortcourse" || row.subject_type == "short course") ?
                                    <PermissionsGate scopes={["cadd"]} errorProps={{ disabled: true }}>
                                        <button
                                            className="btn btn-primary rounded-circle"
                                            title="Copy To Future Date"
                                            data-toggle="modal"
                                            data-target="#copyCourse"
                                            onClick={() => { handleCopyCourse(row) }}
                                        >
                                            <i className="far fa-copy"></i>
                                        </button>
                                    </PermissionsGate>
                                    : <></>}
                                <Link
                                    className="btn btn-primary rounded-circle"
                                    to={{
                                        pathname: `https://student.myaie.ac/courseinfo/${row.qualification_id}/subject/${row.intakeID}/${SP_PASS}`,
                                    }}
                                    target="_blank"
                                    title="Student View"
                                >
                                    <i className="fal fa-eye"></i>
                                </Link>
                                <PermissionsGate scopes={["cdelete"]} errorProps={{ disabled: true }}>
                                    <button className="btn btn-danger rounded-circle" title="Delete"
                                        onClick={() => handleDeleteCourse(row.id)}
                                    >
                                        <i className="fal fa-trash-alt"></i>
                                    </button>
                                </PermissionsGate>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
    ], [isShowingUser]);

    const exportData = (fileType, fileName) => {
        let data = courseList;
        let header = [
            "Name",
            "Start",
            "End",
            "Status",
            "Learning Method",
            "Programme",
            "Intake",
            "Student",
            "Lecturer",
            "Couch",
            "Support",
            "School",
            "Type"
        ];

        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        let data1 = {
            pageNo: tableState.page,
            size: tableState.perPage,
            intake: intake.arr,
            viaLearnType: learningMethod.arr,
            exportStatus: "true",
            viaStatus: ["Past"],
            key: tableState.sortKey,
            sort: tableState.sortOrder,
            viaProgramme: programmeIds.arr,
            viaSearch: search,
            viaSchoolType: school.arr,
            viaSubjectType: courseType.arr,
            showing: showing.arr,
            viaMessage: reply.arr,
            viaStartDate : courseStartDate ? moment(courseStartDate, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
            viaEndDate : courseEndDate ? moment(courseEndDate, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
            viaRangeStartDate : courseDateRange?.startDate && courseDateRange?.endDate ? moment(courseDateRange?.startDate, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
            viaRangeEndDate : courseDateRange?.startDate && courseDateRange?.endDate ? moment(courseDateRange?.endDate, "YYYY-MM-DD").format("YYYY-MM-DD") : "",
        };
        GetCourselist(data1)
            .then((res) => {
                data = res.data.result;
                data = data?.map((row) => ({
                    Name: row.name,
                    "Start": row.start
                        ? `${moment.unix(row.start).format(TABLE_DATE_FORMAT)}`
                        : "-",
                    "End": row.end
                        ? `${moment.unix(row.end).format(TABLE_DATE_FORMAT)}`
                        : "-",
                    "Published Date": row.intakePublish
                        ? `${moment.unix(row.intakePublish).format(TABLE_DATE_FORMAT)}`
                        : "-",
                    Status: row.nestedStatus ? row.nestedStatus : "-",
                    "Learning Method": row.type ? RenderLearningMethod(row.type).text : "-",
                    Programme: row.qualificationName ? row.qualificationName : "-",
                    Intake: `${row.year}-${row.number}`,
                    Student: row.totalRows ? row.totalRows : "-",
                    Lecturer: row.lECTURER ? row.lECTURER : "-",
                    Couch: row.programme_coordinator_name
                        ? row.programme_coordinator_name
                        : "-",
                    Support: row.SUPPORT ? row.SUPPORT : "-",
                    School: row.school_type ? RenderSchool(row.school_type).text : "-",
                    Type: row.subject_type ? RenderCourseType(row.subject_type).text : "-"
                }));

                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], {
                        type: "text/csv;charset=utf-8,",
                    });

                    const blobURL = window.URL.createObjectURL(blob);

                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    setTimeout(() => {
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.forEach((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();
                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data.map((row) => {
                        return [
                            row["Name"],
                            row["Start"],
                            row["End"],
                            row["Status"],
                            row["Learning Method"],
                            row["Programme"],
                            row["Intake"],
                            row["Student"],
                            row["Lecturer"],
                            row["Couch"],
                            row["Support"],
                            row["School"],
                            row["Type"]
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();
                    return false;
                }
            })
            .catch((error) => {
                console.log("error >>>>>> ", error);
            });
    };
    return (
        <>
            <DataTableComponent
                data={courseList}
                loading={loading}
                state={tableState}
                setState={setTableState}
                setSearch={setSearch}
                totalRows={totalRows}
                columns={columns}
                exportFunction={exportData}
                exportFileName={"Course_list"}
                isInsidePopUp
                filters={[
                    {
                        filterName: "Showing",
                        optionArr: [{ label: "1 Year", value: "1" }, { label: "2 Year", value: "2" }, { label: "3 Year", value: "3" }, { label: "5 Year", value: "5" }, { label: "All", value: "-1" }],
                        state: showing,
                        setState: setShowing,
                        singleSelect: true
                    },
                    {
                        filterName: "Learning Method",
                        optionArr: courseDropdown.learning,
                        state: learningMethod,
                        setState: setLearningMethod,
                        renderLabelFunction: RenderLearningMethod
                    },
                    {
                        filterName: "Programme",
                        optionArr: courseDropdown.programme,
                        state: programmeIds,
                        setState: setProgrammeIds,
                        isSearchFilter: true
                    },
                    {
                        filterName: "Intake",
                        optionArr: courseDropdown.intake,
                        state: intake,
                        setState: setIntake,
                        isOptionReversed: true
                    },
                    {
                        filterName: "Reply",
                        optionArr: [
                            {
                                label: "Read",
                                value: "read"
                            },
                            {
                                label: "Unread",
                                value: "unread"
                            }
                        ],
                        state: reply,
                        setState: setReply,
                        renderLabelFunction: RenderReadStatus
                    },
                    {
                        filterName: "School",
                        optionArr: courseDropdown.school,
                        state: school,
                        setState: setSchool,
                        renderLabelFunction: RenderSchool
                    },
                    {
                        filterName: "Type",
                        optionArr: courseDropdown.type,
                        state: courseType,
                        setState: setCourseType,
                        renderLabelFunction: RenderCourseType
                    },
                    {
                        filterName: "Start Date",
                        state: courseStartDate,
                        setState: setCourseStartDate,
                        dateFilter:true,
                        notReset:true
                    },
                    {
                        filterName: "End Date",
                        state: courseEndDate,
                        setState: setCourseEndDate,
                        dateFilter:true,
                        notReset:true
                    },
                    {
                        filterName: "Date Range",
                        state: courseDateRange,
                        setState: setCourseDateRange,
                        dateRangeFilter:true
                    }
                ].filter(item => item)}
            />
            <CopyCourse courseId={courseId} refresh={() => { setDeleteRow(!deleteRow); setCourseId("") }} />
        </>
    );

};

export default PastCourses