import React from "react";
import { useParams } from "react-router-dom";
import ExamPeriodForm from "./ExamPeriodForm";
import ExamPeriodTable from "./ExamPeriodTable";
import ExamPeriodAuditTrail from "./ExamPeriodAuditTrail";

function ExamPeriodTab() {
  const { tab, type, id, subTab, subType } = useParams();
  
  return (
    <div>        
      {type && type == "table" && !id && <ExamPeriodTable />}
      {type && type == "add" && !id && <ExamPeriodForm />}
      {type && type == "open" && id!=='audit-trail' && <ExamPeriodForm />}
      {(type === "audit-trail" || id=="audit-trail") && <ExamPeriodAuditTrail /> }


      {/* {type && type == "add" && !id && <CustomerAdd />}
  {type && type == "edit" && id && <CustomerEdit customer_id={id} />}*/}
    </div>
  );
}

export default ExamPeriodTab;