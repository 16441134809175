import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getCurrentTimeHomePage, InitialRender } from "../../common/Helper";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import Tabs from "../../studentsupport/Tabs";
import Iframe from 'react-iframe'

const LiveChat = () => {

    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            window.open('https://www.live.myaie.ac/index.php/site_admin/', '_blank');
        }, 2000)
    }, []);

    return (
        <>
            <Header></Header>
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar></SideBar>
                <div className="sidebar-right">
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <h4 className="text-left-align landing-heading heading_color_dynamic">
                                        Live <span>Chat</span>
                                    </h4>
                                </div>
                                <div className="tabs-wrap">
                                    <div className="my-tickets-info-list Tickets-main-wrap live-chat-tab">
                                        <div className="custom-table-div filter-search-icon card card-table-custom">
                                            <div className="live-chat-box">
                                                <p>You will be redirecting on live chat in few seconds. If not redirected automatically please click on below button.
                                                    <br />
                                                    <br />
                                                    <Link to={{ pathname: "https://www.live.myaie.ac/index.php/site_admin/" }} target="_blank">
                                                        <button class="btn btn-primary" title="Live Chat" type="button">Live Chat</button>
                                                    </Link>
                                                </p>
                                            </div>
                                            {/* <Iframe url="https://www.live.myaie.ac/index.php/site_admin/user/login" id="myId" className="myClassname" display="initial" position="relative" /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LiveChat;
