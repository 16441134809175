import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import RepositionModal from '../CertificateTemplates/Certificate Modals/RepositionModal';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CertificateGenerateContainer = ({
  imageURL,
  onDrag,
  certificateDatePosition,
  courseNamePosition,
  studentNamePosition,
  onChange,
  showRepositioningElements,
  containerSize,
  originalValues,
  setStudentNamePosition,
  setCourseNamePosition,
  setCertificateDatePosition
}) => {
  const defaultRect = { left: 0, width: 0 };
  const editableIndexRef = useRef(null);

  const [certificateContainer, setCertificateContainer] = useState(defaultRect);
  const [studentName, setStudentName] = useState(defaultRect);
  const [courseName, setCourseName] = useState(defaultRect);
  const [certificateDate, setCertificateDate] = useState(defaultRect);
  const [disableDrag, setDisableDrag] = useState({
    disableStudentNameDrag: false,
    disableCourseNameDrag: false,
    disableCertificateDateDrag: false,
  });

  // const [editAndSaveElement, setEditAndSaveElement] = useState([]);
  // const [selectedEditableElementValue, setEditableDraggableElementValue] =
  //   useState('');

  const [openDialog, setOpenDialog] = useState({
    studentNameEditDialog: false,
    courseNameEditDialog: false,
    certificateDateEditDialog: false,
  });

  const containerRef = useRef();
  const studentNameRef = useRef();
  const courseNameRef = useRef();
  const certificateDateRef = useRef();

  const getContainerInterval = useRef();

  useEffect(() => {
    getContainerInterval.current = setInterval(() => {
      setCertificateContainer((previous) => {
        const newContainer = containerRef.current.getBoundingClientRect();
        return JSON.stringify(newContainer) !== JSON.stringify(previous)
          ? newContainer
          : previous;
      });

      setStudentName((previous) => {
        const newStudentName =
          studentNameRef.current &&
          studentNameRef.current.getBoundingClientRect();
        return JSON.stringify(newStudentName) !== JSON.stringify(previous)
          ? newStudentName
          : previous;
      });

      setCourseName((previous) => {
        const newCourseName =
          courseNameRef.current &&
          courseNameRef.current.getBoundingClientRect();
        return JSON.stringify(newCourseName) !== JSON.stringify(previous)
          ? newCourseName
          : previous;
      });

      setCertificateDate((previous) => {
        const certificateDate =
          certificateDateRef.current &&
          certificateDateRef.current.getBoundingClientRect();
        return JSON.stringify(certificateDate) !== JSON.stringify(previous)
          ? certificateDate
          : previous;
      });
    }, 10);

    return () => clearInterval(getContainerInterval.current);
  }, []);

  const handleEdit = (value) => {
    if (value === 'student_name') {
      setOpenDialog({
        studentNameEditDialog: true,
        courseNameEditDialog: false,
        certificateDateEditDialog: false,
      });
    } else if (value === 'course_name') {
      setOpenDialog({
        studentNameEditDialog: false,
        courseNameEditDialog: true,
        certificateDateEditDialog: false,
      });
    } else if (value === 'certificate_date') {
      setOpenDialog({
        studentNameEditDialog: false,
        courseNameEditDialog: false,
        certificateDateEditDialog: true,
      });
    }
    // setEditableDraggableElementValue(value);
    editableIndexRef.current = value;
  };

  const handleSave = (savedValue) => {
    if (savedValue === 'student_name') {
      setOpenDialog({
        ...openDialog,
        studentNameEditDialog: false,
      });
      // setDisableDrag({
      //   ...disableDrag,
      //   disableStudentNameDrag: true,
      // });
    } else if (savedValue === 'course_name') {
      setOpenDialog({
        ...openDialog,
        courseNameEditDialog: false,
      });
      // setDisableDrag({
      //   ...disableDrag,
      //   disableCourseNameDrag: true,
      // });
    } else if (savedValue === 'certificate_date') {
      setOpenDialog({
        ...openDialog,
        certificateDateEditDialog: false,
      });
      // setDisableDrag({
      //   ...disableDrag,
      //   disableCertificateDateDrag: true,
      // });
    }
  };
  const handleModalClose = () => {
    setOpenDialog({
      studentNameEditDialog: false,
      courseNameEditDialog: false,
      certificateDateEditDialog: false,
    })
  };

  return (
    <>
      <div
        ref={containerRef}
        className={Object.values(openDialog).some((value) => value) ? 'repositioning-image-container' : ''}
        style={{
          display: 'inline-block',
          position: 'relative',
          textAlign:'center',
          width: containerSize.width,
          height: containerSize.height,
        }}
      >
        {/* <img
          src={imageURL}
          alt="Template"
          style={{
            width: '100%',
            height: '100%',
          }}
        /> */}
        <LazyLoadImage
          alt={"Template"}
          height={"100%"}
          effect="blur"
          src={imageURL} // use normal <img> attributes as props
          width={"100%"} 
          />
        <div className="drag-main-container">
          {showRepositioningElements?.ShowStudentName === 1 && (
            <Draggable
              onDrag={(e, ui) => onDrag(e, ui, 'student_name')}
              defaultPosition={studentNamePosition}
              disabled={disableDrag.disableStudentNameDrag}
              // ref={studentNameRef.current}
            >
              <div
                className="drag-sub-container-one centered"
                ref={studentNameRef}
                style={{
                  position: 'absolute',
                  textAlign:'center',
                  width:'100%',
                  top: studentNamePosition.position_y + 'pt',
                  // left: studentNamePosition.position_x + 'pt',
                  left: '50%',
                  fontSize: !studentNamePosition.fontSize ? studentNamePosition.fontSize : studentNamePosition.fontSize.includes('pt') ? studentNamePosition.fontSize : studentNamePosition.fontSize + 'pt',
                  fontFamily: studentNamePosition.fontFamily?.slug,
                  color: studentNamePosition.fontColor,
                  maxWidth: studentNamePosition.maxWidth ? studentNamePosition.maxWidth + 'px' : '',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                Student Name
                {!disableDrag.disableStudentNameDrag && (
                  <i
                    className=" drag-edit-element fa fa-edit"
                    style={{
                      cursor: 'pointer',
                      margin: '0px 20px 0px 20px',
                      fontSize: '23px',
                    }}
                    onClick={() => handleEdit('student_name')}
                  ></i>
                )}
              </div>
            </Draggable>
          )}

          {showRepositioningElements?.ShowCourseName === 1 && (
            <Draggable
              onDrag={(e, ui) => onDrag(e, ui, 'course_name')}
              defaultPosition={courseNamePosition}
              disabled={disableDrag.disableCourseNameDrag}
              // ref={courseNameRef.current}
            >
              <div
                className="drag-sub-container-one centered"
                ref={courseNameRef}
                style={{
                  position: 'absolute',
                  textAlign:'center',
                  width:'100%',
                  top: courseNamePosition.position_y + 'pt',
                  // left: courseNamePosition.position_x + 'pt',
                  left: '50%',
                  fontFamily: courseNamePosition.fontFamily?.slug,
                  fontSize: !courseNamePosition.fontSize ? courseNamePosition.fontSize : courseNamePosition.fontSize.includes('pt') ? courseNamePosition.fontSize : courseNamePosition.fontSize + 'pt',
                  color: courseNamePosition.fontColor,
                  maxWidth: courseNamePosition.maxWidth ? courseNamePosition.maxWidth + 'px' : '',
                }}
              >
                Course Name
                {!disableDrag.disableCourseNameDrag && (
                  <i
                    className=" drag-edit-element fa fa-edit"
                    style={{
                      cursor: 'pointer',
                      margin: '0px 20px 0px 20px',
                      fontSize: '23px',
                    }}
                    onClick={() => handleEdit('course_name')}
                  ></i>
                )}
              </div>
            </Draggable>
          )}
          {showRepositioningElements?.ShowDate === 1 && (
            <Draggable
              onDrag={(e, ui) => onDrag(e, ui, 'certificate_date')}
              defaultPosition={certificateDatePosition}
              disabled={disableDrag.disableCertificateDateDrag}
              // ref={certificateDateRef.current}
            >
              <div
                className="drag-sub-container-one"
                ref={certificateDateRef}
                style={{
                  position: 'absolute',
                  top: certificateDatePosition.position_y + 'pt',
                  left: certificateDatePosition.position_x + 'pt',
                  fontFamily: certificateDatePosition.fontFamily?.slug,
                  fontSize: !certificateDatePosition.fontSize ? certificateDatePosition.fontSize : certificateDatePosition.fontSize.includes('pt') ? certificateDatePosition.fontSize : certificateDatePosition.fontSize + 'pt',
                  color: certificateDatePosition.fontColor,
                }}
              >
                Date
                {!disableDrag.disableCertificateDateDrag && (
                  <i
                    className=" drag-edit-element fa fa-edit"
                    style={{
                      cursor: 'pointer',
                      margin: '0px 20px 0px 20px',
                      fontSize: '23px',
                    }}
                    onClick={() => handleEdit('certificate_date')}
                  ></i>
                )}
              </div>
            </Draggable>
          )}
        </div>
      </div>
      <div>
        {openDialog.studentNameEditDialog && (
          <div>
            <RepositionModal
              onSave={(savedValue) => handleSave(savedValue)}
              dragPositions={studentNamePosition}
              onChange={onChange}
              editableIndex={editableIndexRef.current}
              onClose={handleModalClose}
              originalValues={originalValues}
              setPosition={setStudentNamePosition}
            />
          </div>
        )}
        {openDialog.courseNameEditDialog && (
          <div>
            <RepositionModal
              onSave={(savedValue) => handleSave(savedValue)}
              dragPositions={courseNamePosition}
              onChange={onChange}
              editableIndex={editableIndexRef.current}
              onClose={handleModalClose}
              originalValues={originalValues}
              setPosition={setCourseNamePosition}
            />
          </div>
        )}
        {openDialog.certificateDateEditDialog && (
          <div>
            <RepositionModal
              onSave={(savedValue) => handleSave(savedValue)}
              dragPositions={certificateDatePosition}
              onChange={onChange}
              editableIndex={editableIndexRef.current}
              onClose={handleModalClose}
              originalValues={originalValues}
              setPosition={setCertificateDatePosition}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CertificateGenerateContainer;
