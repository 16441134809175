import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import * as Yup from "yup";
import user from "../../../assets/images/default-user.png";
import { MOBILE_REGEX, NAME_REGEX } from "../../../utils/Constants";
import { AddDetail, AllBrandList, AllBrandListCancelToken, GetListOfRole, GetListOfRoleCancelToken, GetListOfUser, GetListOfUserCancelToken } from "../../../services/UserService";
import cog from "../../../assets/images/myProfile.svg";
import Select from "react-select";
import { Day, Month, Year, Location, Company, Title, Active } from "../DataReactSelect";
import axios from "axios";

const AboutYouNewUser = () => {
  const history = useHistory();
  const [roles, setRoles] = useState(["Admin", "Student Support", "Manager", "Developer", "Qualifications Coordinator", "Principle/Deputy Principle", "Lecturer", "Editor", "Sales", "Accounts"]);
  const [userData, setUserData] = useState([]);
  const [passwordType, setPasswordType] = useState("password");
  const [cPasswordType, setCPasswordType] = useState("password");
  const [brandList, setBrandList] = useState([]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const res = await GetListOfUserCancelToken({},dropdownSource.token);
        let x = {
          value: "",
          label: "",
        };
        x = res.data.map((data) => {
          return { ...x, value: `${data.UserID}`, label: `${data.Firstname + " " + data.Lastname}` };
        });
        setUserData(x);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    fetchData();
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const res = await AllBrandListCancelToken(dropdownSource.token);
        let companyData=[];
        res.data.allBrandList.map((data)=>{
          companyData.push({label:data.brand_name,value:data.id,contact:data.contact})
        })
        setBrandList(companyData);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    fetchData();
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const res = await GetListOfRoleCancelToken(dropdownSource.token);
        let x = {
          value: "",
          label: "",
        };
        x = res.data.map((data) => {
          return { ...x, value: `${data.role_id}`, label: `${data.role_name}` };
        });
        setRoles(x);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    fetchData();
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const [image, setImage] = useState(user);

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      UserID: "",
      nTitle: "",
      Firstname: "",
      Lastname: "",
      Mobile: "",
      role_id: "",
      role_name: "",
      job_title: "",
      is_active: "",
      Company: "",
      Location: "",
      Birth_year: "",
      Birth_month: "",
      Birth_day: "",
      WorkNumber: "",
      about_me: "",
      Report_to: "",
      Report_to_Name: "",
      Email: "",
      Password: "",
      Password_confirmation: "",
      photo: "",
    },
    validationSchema: Yup.object({
      nTitle: Yup.string().required("Title is required "),
      Firstname: Yup.string().matches(NAME_REGEX, "Please enter valid first name").trim("The first name cannot include leading and trailing spaces").required("First Name is required"),
      Lastname: Yup.string().matches(NAME_REGEX, "Please enter valid last name").trim("The last name cannot include leading and trailing spaces").required("Last Name is required"),
      Mobile: Yup.string()
        .matches(MOBILE_REGEX, "Phone number should be 0-9 of 10 digits with country code")
        // .required("Mobile is required")
        .min(10, "Phone number should be 0-9 of 10 digits with country code")
        .max(16, "Phone number should be 0-9 of 10 digits with country code"),
      role_id: Yup.string().required("Role is required"),
      is_active: Yup.string().required("Status is required"),
      // Email: Yup.string().email("Invalid Email address").required("Email is required"),
      // .required("Year is required")
      // .required("Month is required")
      // .required("Day is required")
      about_me: Yup.string(),
      // title: Yup.string().required("Title is required "),
      job_title: Yup.string().trim("The job title cannot include leading and trailing spaces"),
      Birth_year: Yup.string(),
      Birth_month: Yup.string(),
      Birth_day: Yup.string(),
      Company: Yup.string().required("Company is required"),
      Location: Yup.string(),
      WorkNumber: Yup.string().required().nullable(),
      Report_to: Yup.string(),
      Email: Yup.string().email("Invalid email address").required("Email is required"),
      Password: Yup.string()
        .required("Password is required")
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"),
      Password_confirmation: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("Password"), null], "Passwords must match"),
      photo: Yup.mixed()
        .test("fileSize", "File too large", (value) => !value || (value && value.size <= 5000000))
        .test("fileFormat", "Unsupported Format", (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
    }),
    onSubmit: (values, { resetForm }) => {
      // console.log("first", values);

      let formData = new FormData();
      values.photo ? formData.set("img", true) : formData.set("img", false);
      formData.set("photo", values?.photo);
      formData.set("nTitle", values?.nTitle);
      formData.set("Firstname", values?.Firstname);
      formData.set("Lastname", values?.Lastname);
      formData.set("Mobile", values?.Mobile);
      formData.set("role_id", values?.role_id);
      formData.set("job_title", values?.job_title);
      formData.set("Company", values?.Company);
      formData.set("Location", values?.Location);
      formData.set("Birth_year", values?.Birth_year);
      formData.set("Birth_month", values?.Birth_month);
      formData.set("Birth_day", values?.Birth_day);
      formData.set("WorkNumber", values?.WorkNumber);
      formData.set("about_me", values?.about_me);
      formData.set("Report_to", values?.Report_to);
      formData.set("Email", values?.Email);
      formData.set("Password", values?.Password);
      formData.set("is_active", values?.is_active);

      AddDetail(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added successfully",
          });
          // resetForm();
          setImage(user);
          history.push("/systemAdministration/userManagement/table");
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.data}`,
          });
        });
    },
  });

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="user-add-blk-main row mt-2 align-items-center">
          <div className="user-add-blk col-md-4 d-flex align-items-center mb-4">
            <div className="ml-4 profile-box-2 mr-2">
              <article>
                <figure>
                  {<img src={image} alt="AIE" />}

                  <div className="upload-drop-down-wrap">
                    <div className="dropdown">
                      <button title="Profile Picture" className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fal fa-ellipsis-v"></i>
                      </button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <ul className="list-unstyled">
                          <li title="Upload Profile Picture">
                            <div className="upload-image-option">
                              <input
                                type="file"
                                name="avtar"
                                accept="image/*"
                                onChange={(e) => {
                                  formik.setFieldValue("photo", e.target.files[0]);
                                  setImage(URL.createObjectURL(e.target.files[0]));
                                }}
                              />
                              <i className="fal fa-camera"></i>Upload Profile Picture
                            </div>
                          </li>
                          <li title="Remove Profile Picture">
                            <button type="button" onClick={() => setImage(user)}>
                              <i className="fal fa-trash-alt"></i>Remove Profile Picture
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </figure>
              </article>
            </div>
          </div>

          {/* <div className="col-md-4 mb-4"></div> */}
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="form-icon-group mb-4" title="Title">
              <div className="form-icon">
                <i className="fal fa-user-alt"></i>
              </div>
              <Select
                className={"form-control custom-select-box" + (formik.errors.nTitle && formik.touched.nTitle ? " is-invalid" : "")}
                name="nTitle"
                value={Title.filter((val)=>{
                  return val.value == formik.values.nTitle
                })}
                onChange={(value) => {
                  if (value) {
                    formik.setFieldValue("nTitle", value.value)
                  } else {
                    formik.setFieldValue("nTitle", "")
                  }
                }}
                onBlur={formik.handleBlur}
                options={Title}
                maxMenuHeight={175}
                placeholder={formik.values.nTitle ? formik.values.nTitle : "Select Title  *"}
                isClearable
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-icon-group mb-4">
              <div className="form-icon">
                <i className="fal fa-user-alt"></i>
              </div>

              <input
                type="text"
                className={"form-control" + (formik.errors.Firstname && formik.touched.Firstname ? " is-invalid" : "")}
                name="Firstname"
                placeholder="Name *"
                title="Name"
                value={formik.values.Firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Firstname && formik.touched.Firstname ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-icon-group mb-4">
              <div className="form-icon">
                <i className="fal fa-user-alt"></i>
              </div>

              <input
                type="text"
                className={"form-control" + (formik.errors.Lastname && formik.touched.Lastname ? " is-invalid" : "")}
                name="Lastname"
                placeholder="Surname *"
                title="Surname"
                value={formik.values.Lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Lastname && formik.touched.Lastname ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-icon-group mb-4">
              <div className="form-icon">
                <i className="fal fa-envelope"></i>
              </div>
              <input
                type="email"
                className={"form-control" + (formik.errors.Email && formik.touched.Email ? " is-invalid" : "")}
                name="Email"
                placeholder="Enter email address *"
                value={formik.values.Email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Email"
              />
              {formik.errors.Email && formik.touched.Email ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-icon-group mb-4">
              <div className="form-icon">
                {passwordType=="password" ?
                  <i className="fal fa-eye" onClick={()=>{setPasswordType("text")}}></i> :
                  <i class="fal fa-eye-slash" onClick={()=>{setPasswordType("password")}}></i>
                }
              </div>
              <input
                type={passwordType}
                className={"form-control" + (formik.errors.Password && formik.touched.Password ? " is-invalid" : "")}
                name="Password"
                placeholder="Password *"
                value={formik.values.Password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Password"
              />
              {formik.errors.Password && formik.touched.Password ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-icon-group mb-4">
              <div className="form-icon">
                {cPasswordType=="password" ?
                  <i className="fal fa-eye" onClick={()=>{setCPasswordType("text")}}></i> :
                  <i class="fal fa-eye-slash" onClick={()=>{setCPasswordType("password")}}></i>
                }
                
              </div>
              <input
                type={cPasswordType}
                className={"form-control" + (formik.errors.Password_confirmation && formik.touched.Password_confirmation ? " is-invalid" : "")}
                name="Password_confirmation"
                placeholder="Confirm Password *"
                value={formik.values.Password_confirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Confirm Password"
              />
              {formik.errors.Password_confirmation && formik.touched.Password_confirmation ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-icon-group mb-4">
              <div className="form-icon">
                <i className="fal fa-phone"></i>
              </div>
              <input
                type="text"
                className={"form-control" + (formik.errors.Mobile && formik.touched.Mobile ? " is-invalid" : "")}
                name="Mobile"
                title="Mobile"
                placeholder="Enter mobile number"
                value={formik.values.Mobile.replace(" ", "")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Mobile && formik.touched.Mobile ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-icon-group mb-4">
              <div className="form-icon">
                <i className="fal fa-phone-rotary"></i>
              </div>
              <input
                type="text"
                className={"form-control" + (formik.errors.WorkNumber && formik.touched.WorkNumber ? " is-invalid" : "")}
                placeholder="Work Number"
                title="Work Number"
                name="WorkNumber"
                value={formik.values.WorkNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.WorkNumber && formik.touched.WorkNumber ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
            </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-icon-group mb-4">
              <div className="form-icon">
                <i className="fal fa-calendar-alt"></i>
              </div>
              <div className="form-grp" title="Date of Birth">
                <Select
                  className="form-control custom-select-box"
                  name="Birth_year"
                  value={Year.filter((val)=>{
                    return val.value === formik.values.Birth_year
                  })}
                  onChange={(value) => {
                    if(value){
                      formik.setFieldValue("Birth_year", value.value)
                    } else {
                      formik.setFieldValue("Birth_year", "")
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={Year}
                  maxMenuHeight={175}
                  placeholder={formik.values.Birth_year ? formik.values.Birth_year : "Year"}
                  isClearable
                />

                <Select
                  className="form-control custom-select-box"
                  name="Birth_month"
                  value={Month.filter((val)=>{
                    return val.value === formik.values.Birth_month
                  })}
                  onChange={(value) => {
                    if(value){
                      formik.setFieldValue("Birth_month", value.value)
                    } else {
                      formik.setFieldValue("Birth_month", "")
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={Month}
                  maxMenuHeight={175}
                  placeholder={formik.values.Birth_month ? formik.values.Birth_month : "Month"}
                  isClearable
                />

                <Select
                  className="form-control custom-select-box"
                  name="Birth_day"
                  value={Day.filter((val)=>{
                    return val.value === formik.values.Birth_day
                  })}
                  onChange={(value) => {
                    if(value){
                      formik.setFieldValue("Birth_day", value.value)
                    } else {
                      formik.setFieldValue("Birth_day", "")
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={Day}
                  maxMenuHeight={175}
                  placeholder={formik.values.Birth_day ? formik.values.Birth_day : "Day"}
                  isClearable
                />
              </div>
            </div>
            </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-icon-group mb-4">
              <div className="form-icon">
                <i className="fal fa-user-alt"></i>
              </div>
              <textarea
                className={"form-control" + (formik.errors.about_me && formik.touched.about_me ? " is-invalid" : "")}
                placeholder="About Me"
                title="About Me"
                name="about_me"
                rows="8"
                value={formik.values.about_me}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              {formik.errors.about_me && formik.touched.about_me ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-12 col-lg-8">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="form-icon-group mb-4" title="Company">
                  <div className="form-icon">
                    <i className="fal fa-building"></i>
                  </div>
                  <Select
                    className={"form-control custom-select-box" + (formik.errors.Company && formik.touched.Company ? " is-invalid" : "")}
                    name="Company"
                    value={brandList.filter((brand)=>{
                      return brand.value==formik.values.Company
                    })}
                    onChange={(value) => {
                      if(value){
                        formik.setFieldValue("Company", value.value)
                        formik.setFieldValue("WorkNumber",value.contact)  
                      } else {
                        formik.setFieldValue("Company", "")
                        formik.setFieldValue("WorkNumber","") 
                      }
                    }}
                    onBlur={formik.handleBlur}
                    options={brandList}
                    maxMenuHeight={175}
                    placeholder={formik.values.Company ? formik.values.Company : "Company *"}
                    isClearable
                  />

                  {formik.errors.Company && formik.touched.Company ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
                </div>
              <div className="col-md-6 col-lg-6">
                <div className="form-icon-group mb-4" title="Reports To">
                  <div className="form-icon">
                    <i className="fal fa-ballot-check"></i>
                  </div>
                  <Select
                    className="form-control custom-select-box"
                    name="Report_to"
                    value={userData.filter((val)=>{
                      return val.value === formik.values.Report_to
                    })}
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("Report_to", value.value);
                        formik.setFieldValue("Report_to_Name", value.label);
                      } else {
                        formik.setFieldValue("Report_to", "");
                        formik.setFieldValue("Report_to_Name", "");
                      }
                    }}
                    onBlur={formik.handleBlur}
                    options={userData}
                    maxMenuHeight={175}
                    placeholder={formik.values.Report_to_Name ? formik.values.Report_to_Name : "Search"}
                    isClearable
                  />
                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder="Reports To"
                    title="Reports To"
                    name="Report_to"
                    value={formik.values.Report_to}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  /> */}
                  {formik.errors.Report_to && formik.touched.Report_to ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
                </div>
              <div className="col-md-6 col-lg-6">
                <div className="form-icon-group mb-4">
                  <div className="form-icon">
                    <i className="fal fa-briefcase"></i>
                  </div>
                  <input
                    type="text"
                    className={"form-control" + (formik.errors.job_title && formik.touched.job_title ? " is-invalid" : "")}
                    name="job_title"
                    placeholder="Job title"
                    title="Job Title"
                    value={formik.values.job_title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.job_title && formik.touched.job_title ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
                </div>
              <div className="col-md-6 col-lg-6">
                <div className="form-icon-group mb-4" title="Location">
                  <div className="form-icon">
                    <i className="fal fa-map-marker-alt"></i>
                  </div>
                  <Select
                    className="form-control custom-select-box"
                    name="Location"
                    value={Location.filter((val)=>{
                      return val.value === formik.values.Location
                    })}
                    onChange={(value) => {
                      if(value){
                        formik.setFieldValue("Location", value.value)
                      } else {
                        formik.setFieldValue("Location", "")
                      }
                    }}
                    onBlur={formik.handleBlur}
                    options={Location}
                    maxMenuHeight={175}
                    placeholder={formik.values.Location ? formik.values.Location : "Location"}
                    isClearable
                  />
                  {/* <select
                    className={"form-control select" + (formik.errors.Location && formik.touched.Location ? " is-invalid" : "")}
                    value={formik.values.Location}
                    name="Location"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select Location</option>
                    <option value="Midrand">Midrand</option>
                    <option value="Cape Town">Cape Town</option>
                    <option value="Johannesburg">Johannesburg</option>
                  </select> */}
                </div>
                </div>
              <div className="col-md-6 col-lg-6">
                <div className="form-icon-group mb-4" title="Role">
                  <div className="form-icon">
                    <img className="mb-2" src={cog} alt="Role" />
                  </div>
                  <Select
                    className={"form-control custom-select-box" + (formik.errors.role_id && formik.touched.role_id ? " is-invalid" : "")}
                    name="role_id"
                    value={roles.filter((val)=>{
                      return val?.value === formik.values.role_id
                    })}
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("role_id", value.value);
                        formik.setFieldValue("role_name", value.label);
                      } else {
                        formik.setFieldValue("role_id", "");
                        formik.setFieldValue("role_name", "");
                      }                  
                    }}
                    onBlur={formik.handleBlur}
                    options={roles}
                    maxMenuHeight={175}
                    placeholder={formik.values.role_name ? formik.values.role_name : "Role *"}
                    isClearable
                  />
                  {/* <select
                    className={"form-control select" + (formik.errors.role_id && formik.touched.role_id ? " is-invalid" : "")}
                    value={formik.values.role_id}
                    name="role_id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select Role</option>
                    {roles &&
                      roles.map((role, i) => (
                        <option key={i} value={role?.role_id}>
                          {role?.role_name}
                        </option>
                      ))}
                  </select> */}
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="form-icon-group mb-4" title="Status">
                  <div className="form-icon">
                    <i className="fal fa-map-marker-alt"></i>
                  </div>
                  <Select
                    className={"form-control custom-select-box" + (formik.errors.is_active && formik.touched.is_active ? " is-invalid" : "")}
                    name="Status"
                    value={Active.filter(item => item.value == formik.values.is_active)}
                    onChange={(value) => {
                      if(value){
                        formik.setFieldValue("is_active", value.value)
                      } else {
                        formik.setFieldValue("is_active", "")
                      }
                    }}
                    onBlur={formik.handleBlur}
                    options={Active}
                    maxMenuHeight={175}
                    placeholder={formik.values.is_active ? formik.values.is_active : "Status *"}
                    isClearable
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group form-group-save-cancel ml-new">
          <button className="btn btn-save btn-success" type="submit" title="Save">
            <i className="fal fa-save"></i>
            Save
          </button>
          <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => formik.resetForm()}>
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {Object.keys(formik.values).map(key => {
                if (formik.touched[key] && formik.errors[key]) {
                  return (
                    <div className="invalid-feedback d-block">
                      {formik.errors[key]}
                    </div>
                  )
                }
              })}
        </div>
      </form>
    </div>
  );
};

export default AboutYouNewUser;

{
  /* <div className="row">
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
             
              <div className="form-icon">
                <i className="fal fa-user-alt"></i>
              </div>
              <select
                className={"form-control" + (formik.errors.title && formik.touched.title ? " is-invalid" : "")}
                value={formik.values.title}
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select Title</option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
             
              <div className="form-icon">
                <i className="fal fa-user-alt"></i>
              </div>
              <input
                type="text"
                className={"form-control" + (formik.errors.Firstname && formik.touched.Firstname ? " is-invalid" : "")}
                name="Firstname"
                placeholder="Enter first name"
                value={formik.values.Firstname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Firstname && formik.touched.Firstname ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
             
              <div className="form-icon">
                <i className="fal fa-user-alt"></i>
              </div>
              <input
                type="text"
                className={"form-control" + (formik.errors.Lastname && formik.touched.Lastname ? " is-invalid" : "")}
                name="Lastname"
                placeholder="Enter last name"
                value={formik.values.Lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Lastname && formik.touched.Lastname ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div> */
}
{
  /* <div className="row">
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
              
              <div className="form-icon">
                <i className="fal fa-phone"></i>
              </div>
              <input
                type="text"
                className={"form-control" + (formik.errors.Mobile && formik.touched.Mobile ? " is-invalid" : "")}
                name="Mobile"
                placeholder="Enter mobile number"
                value={formik.values.Mobile.replace(" ", "")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Mobile && formik.touched.Mobile ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
              
              <div className="form-icon">
                <i className="fal fa-envelope"></i>
              </div>
              <input
                type="email"
                className={"form-control" + (formik.errors.Email && formik.touched.Email ? " is-invalid" : "")}
                name="Email"
                placeholder="Enter email address"
                value={formik.values.Email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.Email && formik.touched.Email ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
              <div className="form-icon">
                <i className="fal fa-phone"></i>
              </div>
              <input
                type="text"
                className={"form-control" + (formik.errors.WorkNumber && formik.touched.workNumber ? " is-invalid" : "")}
                placeholder="Work Number"
                title="Work Number"
                name="workNumber"
                value={formik.values.workNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.workNumber && formik.touched.workNumber ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div> */
}
{
  /* <div className="row">
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
              <div className="form-icon">
                <i className="fal fa-briefcase"></i>
              </div>
              <input
                type="text"
                className={"form-control" + (formik.errors.job_title && formik.touched.job_title ? " is-invalid" : "")}
                name="job_title"
                placeholder="Enter job title"
                value={formik.values.job_title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.errors.job_title && formik.touched.job_title ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
              <div className="form-icon">
                <i className="fal fa-user-alt"></i>
              </div>
              <select
                className={"form-control" + (formik.errors.is_active && formik.touched.is_active ? " is-invalid" : "")}
                value={formik.values.is_active}
                name="is_active"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select Status</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
              <div className="form-icon">
                <i className="fal fa-map-marker-alt"></i>
              </div>
              <select
                className={"form-control" + (formik.errors.Location && formik.touched.Location ? " is-invalid" : "")}
                value={formik.values.Location}
                name="Location"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select Location</option>

                <option value="Midrand">Midrand</option>
                <option value="Cape Town">Cape Town</option>
                <option value="Johannesburg">Johannesburg</option>
              </select>
            </div>
          </div>
        </div> */
}

{
  /* <div className="row">
          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
              <div className="form-icon">
                <i className="fal fa-user-alt"></i>
              </div>
              <textarea
                className={"form-control" + (formik.errors.about_me && formik.touched.about_me ? " is-invalid" : "")}
                placeholder="About Me"
                title="About Me"
                name="about_me"
                rows="4"
                value={formik.values.about_me}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              {formik.errors.about_me && formik.touched.about_me ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col">
           
            <div className="form-group form-group-blk form-icon-group">
      
              <div className="form-icon">
                <i className="fal fa-building"></i>
              </div>
              <select
                className={"form-control" + (formik.errors.Company && formik.touched.Company ? " is-invalid" : "")}
                value={formik.values.Company}
                name="Company"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select Company</option>
                <option value="Academic Institute of Excellence (AIE)">Academic Institute of Excellence (AIE)</option>
                <option value="Greenside Design Center (GDC)">Greenside Design Center (GDC)</option>
                <option value="Learnfast">Learnfast</option>
                <option value="Cadco">Cadco</option>
              </select>
            </div>
          </div>

          <div className="col">
            <div className="form-group form-group-blk form-icon-group">
          
              <div className="form-icon">
                <img src={cog} alt="Role" />
              </div>
              <select
                className={"form-control" + (formik.errors.role_id && formik.touched.role_id ? " is-invalid" : "")}
                value={formik.values.role_id}
                name="role_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select Role</option>
                {roles &&
                  roles.map((role, i) => (
                    <option key={i} value={role}>
                      {role}
                    </option>
                  ))}
              </select>

            </div>
            
          </div>
        </div> */
}
