import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { Link, useHistory } from "react-router-dom";
import AuditTrail from "../components/systemadministration/CampusManagement/AuditTrail";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import CampusList from "../components/systemadministration/CampusManagement/CampusList";
import CampusDetails from "../components/systemadministration/CampusManagement/CampusDetails";
import { TrimText } from "../components/common/TrimText";
import RoomList from "../components/systemadministration/CampusManagement/RoomList";
import { GlobalIconNames } from "../utils/GlobalIconNames";

const CampusManagement = (props) => {

    const history = useHistory();
    const [activeTab, setActiveTab] = useState('');
    const location = useLocation();
    const { tab } = useParams();
    const search = useLocation().search;
    const classId = new URLSearchParams(search).get("id");
    const campusName = new URLSearchParams(search).get("name");
    useEffect(() => {
        if (tab !== '') {
            setActiveTab(tab);
        } else {
            setActiveTab('settings');
        }
    }, [tab]);

    return (
        <>
            <Header></Header>
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar></SideBar>
                <div className="sidebar-right">
                    <div className="sub-menu-content-block">
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                {((tab == "campusList" || tab == "auditTrail") && !campusName) ? "Campus " : tab == "addCampus" ? "Add " : "Campus: "} <span title={campusName}>{((tab == "campusList" || tab == "auditTrail") && !campusName) ? "Management" : tab == "addCampus" ? "Campus" : ((tab == "campusDetails" || tab == "auditTrail" || tab == "rooms") && campusName) ? TrimText(campusName, 60) : ""}</span>
                                            </h4>
                                        </div>
                                            <div className="text-md-right action2-blk">
                                                <div className="ticket-view-btn d-flex align-items-center">
                                                    <div className="add-new-ticket-bx">
                                                        <button
                                                            onClick={() => history.goBack()}
                                                            title="Back"
                                                            className="btn btn-white-bordered"
                                                        >
                                                            <i className="fal fa-angle-left"></i>Back
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    {tab !== "addCampus" &&
                                        <ul className="nav nav-pills mb-2" id="tabs" role="tablist">
                                            <li className="nav-item" onClick={() => history.push(classId === null ? "/administration/campusManagement/campusList" : `/administration/campusManagement/campusDetails?id=${classId}&name=${campusName}`)}>
                                                <Link className={`nav-link ${activeTab === 'campusList' || activeTab === 'campusDetails' ? 'active' : ''}`} id="pills-tab1" data-toggle="pill" href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true">
                                                    {classId === null && <><i className="fal fa-list"></i>Campus List</>}
                                                    {classId !== null && GlobalIconNames("Details").html}
                                                </Link>
                                            </li>
                                            {classId !== null &&
                                                <li className="nav-item" onClick={() => history.push(classId === null ? "/administration/campusManagement/campusList" : `/administration/campusManagement/rooms?id=${classId}&name=${campusName}`)}>
                                                    <Link className={`nav-link ${activeTab == 'rooms' ? 'active' : ''}`} id="pills-tab4" data-toggle="pill" href="#pills-tab14" role="tab" aria-controls="pills-tab14" aria-selected="true">
                                                        {classId !== null && <><i className="fal fa-history"></i>Rooms</>}
                                                    </Link>
                                                </li>}

                                            <li className="nav-item" onClick={() => history.push(classId === null ? "/administration/campusManagement/auditTrail" : `/administration/campusManagement/auditTrail?id=${classId}&name=${campusName}`)}>
                                                <Link className={`nav-link ${activeTab == 'auditTrail' ? 'active' : ''}`} id="pills-tab2" data-toggle="pill" href="#pills-tab12" role="tab" aria-controls="pills-tab12" aria-selected="true">
                                                    <i className="fal fa-history"></i>Audit Trail
                                                </Link>
                                            </li>
                                        </ul>}

                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {tab == "campusList" && <CampusList />}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
                                            {location.pathname.includes("/administration/campusManagement/auditTrail") && <AuditTrail />}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab14" role="tabpanel" aria-labelledby="pills-tab4">
                                            {tab == "rooms" && <RoomList />}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
                                            {(tab == "campusDetails" || tab == "addCampus") && <CampusDetails />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CampusManagement;
