import React, { useEffect, useState } from "react";
import { ColumnChart, GroupedColumnChart } from "bizcharts";
import { GetOnlineClassGraphData } from "../../../services/CourseService";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

const DashBoard = (props) => {

  return (
    <div>
      You can start work here

      CSS will be written in custom.css file.
    </div>
  );
};

export default DashBoard;
