import React, { useEffect, useState } from "react";
import { useFormik, useFormikContext } from "formik";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import moment from 'moment'
import { TABLE_DATE_FORMAT, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import RenderPreviousValues from "./RenderPreviousValues";

const RCO = (props) => {
  const { id } = useParams();
  const [studentData, setStudentData] = useState();
  const [assignOfficerList, setAssignOfficerList] = useState([]);

  useEffect(() => {
    setStudentData(props.studentData);
    let tempArr = []
    props.studentData.assignedOfficer.length && props.studentData.assignedOfficer.map(item => {
      let tempItem = { label: item.Firstname + " " + item.Lastname, value: item.UserID }
      tempArr.push(tempItem)
    })
    setAssignOfficerList(tempArr);
  }, [props]);

  const PrelimData = [
    { label: "Do Not Proceed", value: "do_not_proceed" },
    { label: "Cancellation", value: "cancellation" },
    { label: "Transfer", value: "transfer" },
  ];

  const OfficerAssigned = [
    { label: "Heini", value: "Heini" },
    { label: "Albert", value: "Albert" },
    { label: "Aman", value: "Aman" },
  ];

  const formik = useFormikContext();

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">RCO Preliminary Progression</div>
      </div>
      <form>
        <>
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="PrelimProgression">Student Prelim Progression Selection </label>
              <div className="form-icon-group mb-4">
                <Select
                  className={"form-control custom-select-box " + (formik.errors.PrelimProgression && formik.touched.PrelimProgression ? " is-invalid" : "")}
                  name="PrelimProgression"
                  value={PrelimData.filter((value)=>{
                    return value.value==formik.values.PrelimProgression
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("PrelimProgression", value.value);
                      formik.setFieldValue("PrelimProgressionLabel", value.label);
                    } else {
                      formik.setFieldValue("PrelimProgression", '');
                      formik.setFieldValue("PrelimProgressionLabel", "");
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={PrelimData}
                  maxMenuHeight={175}
                  placeholder={formik.values.PrelimProgressionLabel ? formik.values.PrelimProgressionLabel : "Select"}
                  isClearable
                />
              </div>
              <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.pre_progress_selection} previousValue={props.studentData?.RegStudentsPreviousDetails?.pre_progress_selection} type="dropdown" typeSupportingProps={{filterArray : PrelimData}} />

              <label htmlFor="AssignedOfficer">Assigned Officer </label>
              <div className="form-icon-group mb-4">
                <Select
                  className={"form-control custom-select-box " + (formik.errors.AssignedOfficer && formik.touched.AssignedOfficer ? " is-invalid" : "")}
                  name="AssignedOfficer"
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("AssignedOfficer", value.value);
                      formik.setFieldValue("AssignedOfficerLabel", value.label);
                    } else {
                      formik.setFieldValue("AssignedOfficer", "");
                      formik.setFieldValue("AssignedOfficerLabel", "");
                    }
                  }}
                  value={assignOfficerList && assignOfficerList.filter(data => data.value == formik.values.AssignedOfficer)}
                  onBlur={formik.handleBlur}
                  options={assignOfficerList}
                  maxMenuHeight={175}
                  placeholder={formik.values.AssignedOfficer ? formik.values.AssignedOfficer : "Select"}
                  isClearable
                />
              </div>
              <RenderPreviousValues status={studentData?.re_reg_data?.status} currentValue={studentData?.re_reg_data?.assigned_officer} previousValue={props.studentData?.RegStudentsPreviousDetails?.assigned_officer} type="dropdown" typeSupportingProps={{filterArray : assignOfficerList}} />

              <label htmlFor="AssignedOfficer">Selected Outcome</label>
              <div className="form-icon-group mb-4">
                <div className="custon_rd_new">
                  <div className="custom-control custom-radio custom-control-inline" title="Transfer to future intake">
                    <input id="customRadioInline3" type="radio" className="custom-control-input" checked={formik.values.Outcome == "transfer"} onBlur={formik.handleBlur} onChange={() => formik.setFieldValue("Outcome", "transfer")} />
                    <label className="custom-control-label" htmlFor="customRadioInline3">
                      Transfer to future intake
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline" title="Cancelled">
                    <input id="customRadioInline4" type="radio" className="custom-control-input" checked={formik.values.Outcome == "cancelled"} onBlur={formik.handleBlur} onChange={() => formik.setFieldValue("Outcome", "cancelled")} />
                    <label className="custom-control-label" htmlFor="customRadioInline4">
                      Cancelled
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              {/* <label>Updated by Administrator Administrator on 28-06-2022 06:26</label> */}
              {studentData &&
                studentData.student_prelim_update &&
                studentData.re_reg_data &&
                <p class="mt-4">
                  Updated by{" "}
                  <b>
                    {studentData &&
                      studentData.student_prelim_update &&
                      studentData.student_prelim_update.Firstname + " " + studentData.student_prelim_update.Lastname}
                  </b>{" "}
                  on{" "}
                  <b>
                    {studentData &&
                      studentData.re_reg_data &&
                      moment(studentData?.re_reg_data?.qc_updated_rco_date).format(TABLE_DATE_FORMAT) + " " + moment(studentData?.re_reg_data?.qc_updated_rco_date).format(TABLE_TIME_FORMAT)}
                  </b>
                </p>
              }
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="AssignedOfficer">Notes</label>
              <div className="form-icon-group">
                <div className="form-icon-group mb-4">
                  {/* <textarea name="Notes" className="form-control" id="" rows="10"></textarea> */}
                  <textarea
                    rows={10}
                    type="text"
                    id="StudentSelection"
                    className={"form-control"}
                    name="Notes"
                    placeholder="Notes"
                    value={formik.values.rcoNotes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="Notes"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group-blk mb-3">
                <label></label>
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="CRM"
                    name="select"
                    checked={formik.values.CRM}
                    onChange={(e) => {
                      formik.setFieldValue("CRM", e.target.checked);
                      props.clickedCheck("updated_crm", e.target.checked);
                    }} />
                  <label className="custom-control-label" htmlFor="CRM">
                    Netsuite Account Updated 
                  </label>
                </div>
              </div>

              <div className="form-group-blk mb-3">
                <label></label>
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="XERO"
                    name="select"
                    checked={formik.values.XERO}
                    onChange={(e) => {
                      formik.setFieldValue("XERO", e.target.checked)
                      props.clickedCheck("updated_xero", e.target.checked);
                    }} />
                  <label className="custom-control-label" htmlFor="XERO">
                  Netsuite Opportunity Updated
                  </label>
                </div>
              </div>

              <div className="form-group-blk mb-3">
                <label></label>
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="PORTAL"
                    name="select"
                    checked={formik.values.PORTAL}
                    onChange={(e) => {
                      formik.setFieldValue("PORTAL", e.target.checked)
                      props.clickedCheck("updated_portal", e.target.checked);
                    }} />
                  <label className="custom-control-label" htmlFor="PORTAL">
                  AMI Course and Programme updated
                  </label>
                </div>
              </div>

              <div className="form-group-blk mb-3">
                <label></label>
                <div className="custom-check custom-control custom-checkbox custom-control-inline">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="QC"
                    name="select"
                    checked={formik.values.QC}
                    onChange={(e) => {
                      formik.setFieldValue("QC", e.target.checked)
                      props.clickedCheck("qc_updated", e.target.checked);
                    }} />
                  <label className="custom-control-label" htmlFor="QC">
                  Qualification Coordinator Notified
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      </form>
      {/* <hr /> */}
    </div>
  );
};

export default RCO;
