import React from 'react';
import { TABLE_DATE_FORMAT } from '../../../utils/Constants';
import moment from 'moment';
import { IMAGE_URL } from '../../../utils/Constants';
import { InitialRender } from '../../common/Helper';
import { ColorRender } from '../../systemadministration/usermanagement/CheckRole';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export const ScheduleListModal = ({
  isOpen,
  scheduleClasses,
  onClose,
  position,
}) => {
  const mode = scheduleClasses.class_type;
  const type = scheduleClasses.type;
  const history = useHistory();

  if (!isOpen || !scheduleClasses) return null;

  const modalStyle = {
    position: 'absolute',
    top: `${position.y}px`,
    left: `${position.x}px + 300px `,
    zIndex: 1000,
    background: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  };

  return (
    <>
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" style={modalStyle}>
          <div className="modal-booking-container-1">
            <div>
              {type === 'class' && (
                <span
                  className={
                    mode === 'bbb_class' ||
                    mode == 'braincert_class' ||
                    mode == 'joinme_class'
                      ? 'cat-blue'
                      : mode === 'on_campus'
                      ? 'cat-green'
                      : 'cat-red'
                  }
                >
                  {mode === 'bbb_class' ||
                  mode == 'braincert_class' ||
                  mode == 'joinme_class' ? (
                    <div
                      className="as-virtual-bg as-booking-widget text-white no-text-transformation"
                      title="Virtual"
                    >
                      <i className="fal fa-house-signal" />
                      &nbsp;Virtual
                    </div>
                  ) : mode === 'on_campus' ? (
                    <div
                      className="as-campus-bg as-booking-widget no-text-transformation"
                      title="On-campus"
                    >
                      <i className="fal fa-university" />
                      &nbsp;On Campus
                    </div>
                  ) : (
                    <div
                      className="as-hybrid-bg as-booking-widget no-text-transformation"
                      title="Hybrid"
                    >
                      <i className="fal fa-university" />
                      <i className="fal fa-plus" />
                      <i className="fal fa-house-signal" />
                      &nbsp;Hybrid
                    </div>
                  )}
                </span>
              )}
              {scheduleClasses.type === 'booking' && (
                <div className="d-flex">
                  <div
                    className="as-booking-bg as-booking-widget no-text-transformation mr-2"
                    title="Booking"
                  >
                    <i className="fal fa-calendar-day mr-1" />
                    <span style={{ fontWeight: 700, fontSize: '12px' }}>
                      Booking
                    </span>
                  </div>
                  {/* <div> */}
                  <span
                    style={{
                      backgroundColor: '#469B58',
                      color: '#fff',
                      padding: '4px 6px 4px',
                      borderRadius: '2px',
                      fontWeight: 600,
                      fontSize: '10px',
                    }}
                  >
                    APPROVED
                  </span>
                  {/* </div> */}
                </div>
              )}
            </div>
            <div>
              <i className="fal fa-users mr-2" title="Student attendance">
                &nbsp;
                {type === 'booking' &&
                  scheduleClasses.booking_for &&
                  scheduleClasses.booking_for.split(',').length}
                {type === 'class' && scheduleClasses.studentCount
                  ? scheduleClasses.studentCount.total
                  : ''}
              </i>
            </div>
          </div>
          <div
            className="pb-3 mb-3"
            style={{
              borderBottom: `1px solid #E0E0E0`,
            }}
          >
            <div className="mb-2">
              <span
                style={{
                  fontWeight: 700,
                  fontSize: '20px',
                }}
              >
                {scheduleClasses && type === 'class'
                  ? scheduleClasses?.class_title
                  : scheduleClasses?.booking_reason}
              </span>
            </div>
            <div className="modal-booking-grid">
              <div>
                <i className="fal fa-calendar-day">
                  {type === 'class' && scheduleClasses.class_date && (
                    <span className='booking-modal-date-time'>
                      {moment(scheduleClasses.class_date).format(
                        `dddd, ${TABLE_DATE_FORMAT}`
                      )}
                      , &nbsp;
                      {moment(scheduleClasses.class_from, 'HH:mm:ss').format(
                        'HH:mm'
                      )}{' '}
                      -{' '}
                      {moment(scheduleClasses.class_to, 'HH:mm:ss').format(
                        'HH:mm'
                      )}
                      {/* `${moment(scheduleClasses.class_date).format(
                    `dddd, ${TABLE_DATE_FORMAT}`
                  )}, ${scheduleClasses.class_from} - ${
                    scheduleClasses.class_to
                    }` */}
                    </span>
                  )}
                  {type === 'booking' && scheduleClasses.booking_date && (
                    <span
                     className='booking-modal-date-time'
                    >
                      {moment(scheduleClasses.booking_date).format(
                        `dddd, ${TABLE_DATE_FORMAT}`
                      )}
                      , &nbsp;
                      {moment(
                        scheduleClasses.booking_start_time,
                        'HH:mm:ss'
                      ).format('HH:mm')}{' '}
                      -
                      {' ' +
                        moment(
                          scheduleClasses.booking_end_time,
                          'HH:mm:ss'
                        ).format('HH:mm')}
                    </span>
                  )}
                </i>
              </div>
              <div>
                <i class="fal fa-university"></i>
                {type === 'class' && (
                  <span className='booking-modal-date-time'>
                    {scheduleClasses.multiple_room.length > 0
                      ? scheduleClasses?.multiple_room[0].campus_room
                          ?.room_name +
                        ', ' +
                        scheduleClasses?.multiple_room[0].campus_room
                          ?.class_campus?.campus_name
                      : '-'}
                  </span>
                )}
                {type === 'booking' && (
                  <span className='booking-modal-date-time'>
                    {scheduleClasses
                      ? scheduleClasses?.room_no +
                        ', ' +
                        scheduleClasses?.campus
                      : '-'}
                  </span>
                )}
              </div>
            </div>
            <div className="d-flex ">
              <i class="fal fa-books mr-1"></i>
              <div
                className="as-text-blue curser textLimit2"
                onClick={() =>
                  history.push(
                    `/courseAdministration/coursesdetails/${
                      type === 'class'
                        ? scheduleClasses.intake_id
                        : scheduleClasses.linked_course_id
                    }/detailCourses/show`
                  )
                }
              >
                {type === 'booking' && scheduleClasses
                  ? scheduleClasses?.linked_course
                  : scheduleClasses?.course_name}
              </div>
            </div>
          </div>
          <div>
            {type === 'class' && (
              <div className="mb-4">
                <div className="modal-booking-container-2">Lecturer</div>
                <div>
                  {scheduleClasses.lecturer && (
                    <>
                      <div className="reports-profile-view assigned-title-block-new">
                        <div className="assigned-title-itm">
                          <span
                            className={
                              'assigned-title-blk  name-icon cat-dark-red' +
                              ColorRender(
                                scheduleClasses.lecturer.fullname ?? '-'
                              )
                            }
                          >
                            {scheduleClasses.lecturer.picture_me ? (
                              <img
                                src={`${IMAGE_URL}/${scheduleClasses.lecturer.picture_me.replace(
                                  '/home/myaie/public_html/',
                                  ''
                                )}`}
                                alt="AIE"
                              />
                            ) : (
                              InitialRender([
                                scheduleClasses.lecturer.Firstname,
                                scheduleClasses.lecturer.Lastname,
                              ])
                            )}
                            <span
                              className={`profile-box-2-status ${scheduleClasses.lecturer.Status}`}
                            >
                              <i className="fas fa-circle"></i>
                            </span>
                          </span>
                          <span>&nbsp;{scheduleClasses.lecturer.fullname}</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {type === 'class' &&
              scheduleClasses.multiple_lecture.length > 0 && (
                <div className="mb-4">
                  <div className="modal-booking-container-2">
                    Guest Lecturer
                  </div>
                  <div className="d-flex">
                    {scheduleClasses.multiple_lecture.map((item) => (
                      <div className="reports-profile-view assigned-title-block-new">
                        <div className="assigned-title-itm">
                          <span
                            className={
                              'assigned-title-blk  name-icon cat-dark-red' +
                              ColorRender(
                                item.get_lecture_details.fullname ?? '-'
                              )
                            }
                          >
                            {item.get_lecture_details.picture_me ? (
                              <img
                                src={`${IMAGE_URL}/${item.get_lecture_details.picture_me.replace(
                                  '/home/myaie/public_html/',
                                  ''
                                )}`}
                                alt="AIE"
                              />
                            ) : (
                              InitialRender([
                                item.get_lecture_details.Firstname,
                                item.get_lecture_details.Lastname,
                              ])
                            )}

                            <span className={`profile-box-2-status Online`}>
                              <i className="fas fa-circle"></i>
                            </span>
                          </span>
                          <span>
                            {item.get_lecture_details.fullname ?? '-'}
                          </span>
                          &nbsp;&nbsp;&nbsp;
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            {type === 'booking' && (
              <>
                <div className="mb-4">
                  <div className="modal-booking-container-2">
                    Booking Created By
                  </div>
                  <div
                    className="d-flex"
                    style={{ justifyContent: 'space-between' }}
                  >
                    {scheduleClasses.users.length > 0 && (
                      <>
                        <div className="reports-profile-view assigned-title-block-new">
                          <div className="assigned-title-itm">
                            <span
                              className={
                                'assigned-title-blk  name-icon cat-dark-red ' +
                                ColorRender(
                                  scheduleClasses.users[
                                    scheduleClasses.users.length - 1
                                  ].first_name +
                                    ' ' +
                                    scheduleClasses.users[
                                      scheduleClasses.users.length - 1
                                    ].last_name
                                )
                              }
                            >
                              {scheduleClasses.users[
                                scheduleClasses.users.length - 1
                              ].photo ? (
                                <img
                                  src={`${IMAGE_URL}/${scheduleClasses.users[
                                    scheduleClasses.users.length - 1
                                  ].photo.replace(
                                    '/home/myaie/public_html/',
                                    ''
                                  )}`}
                                  alt="AIE"
                                />
                              ) : (
                                InitialRender([
                                  scheduleClasses.users[
                                    scheduleClasses.users.length - 1
                                  ].first_name,
                                  scheduleClasses.users[
                                    scheduleClasses.users.length - 1
                                  ].last_name,
                                ])
                              )}
                              <span className={`profile-box-2-status Online`}>
                                <i className="fas fa-circle"></i>
                              </span>
                            </span>
                            <span>
                              {scheduleClasses.users[
                                scheduleClasses.users.length - 1
                              ].first_name +
                                ' ' +
                                scheduleClasses.users[
                                  scheduleClasses.users.length - 1
                                ].last_name ?? '-'}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    {/* {scheduleClasses.users.length > 0 &&
                      scheduleClasses.users[users.length - 1]((item) => {
                        return (
                          <>
                            <div className="reports-profile-view assigned-title-block-new">
                              <div className="assigned-title-itm">
                                <span
                                  className={
                                    'assigned-title-blk  name-icon cat-dark-red ' +
                                    ColorRender(
                                      item.first_name + ' ' + item.last_name
                                    )
                                  }
                                >
                                  {item.photo ? (
                                    <img
                                      src={`${IMAGE_URL}/${item.photo.replace(
                                        '/home/myaie/public_html/',
                                        ''
                                      )}`}
                                      alt="AIE"
                                    />
                                  ) : (
                                    InitialRender([
                                      item.first_name,
                                      item.last_name,
                                    ])
                                  )}
                                  <span
                                    className={`profile-box-2-status Online`}
                                  >
                                    <i className="fas fa-circle"></i>
                                  </span>
                                </span>
                                <span>
                                  {item.first_name + ' ' + item.last_name ??
                                    '-'}
                                </span>
                              </div>
                            </div>
                          </>
                        );
                      })} */}
                  </div>
                </div>

                <div>
                  <button
                    class="btn-view-modal-booking"
                    onClick={() =>
                      history.push(
                        `/campusAdministration/bookings/edit/${scheduleClasses.id}`
                      )
                    }
                  >
                    <i class="fal fa-folder-open"></i>&nbsp;View Booking
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
