import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { StudentEduDetailsCancelToken } from '../../../../services/StudentsSettingServices';
import hasPermission from '../../../../utils/hasMultiplePermission';
import HighestQualificationDetailsBlock from './educationTabs/HighestQualificationDetailsBlock'
import HighSchoolDetailBlock from './educationTabs/HighSchoolDetailBlock'
import axios from 'axios';

function Education() {
  const { id } = useParams();

  const [educationData, setEducationData] = useState();

  const [deleteRow, setDeleteRow] = useState(false);

  const history = useHistory();

  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let response = hasPermission({ scopes: ["sedview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  useEffect(() => {
    const cancelTokenSources = [];

    const getEducationDetails = async () => {
      setLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await StudentEduDetailsCancelToken(id, source.token);
        if (res.status === 200) {
          setEducationData(res.data)
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    getEducationDetails();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [deleteRow]);
  return (
    <div className="card card-profile-info-card">
        <div className="">
          <div className="card-body-inr card-body-info">
            <HighSchoolDetailBlock subjects={educationData && educationData.subjects} loading={loading} metricCertificate={educationData && educationData.metric_certificates} student={educationData && educationData.student} setDeleteRow={setDeleteRow} deleteRow={deleteRow} />
            <hr/>
            <HighestQualificationDetailsBlock highestQualification={educationData && educationData.high_qualifications} setDeleteRow={setDeleteRow} deleteRow={deleteRow} />
          </div>
        </div>
      </div>
  )
}

export default Education