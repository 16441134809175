const initialState = {
    assessment_name: "",
  };
  
  const assessmentReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_ASSESSMENT_NAME":
        state.assessment_name = action.payload;
        return { assessment_name: state.assessment_name };
      default:
        return state;
    }
  };
  
  export default assessmentReducer;
  