import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
import { ColorRender } from "../../../systemadministration/usermanagement/CheckRole";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import Swal from "sweetalert2";
import "jspdf-autotable";
import { GetStudentTicketList, GetStudentTicketListCancelToken, GetStudentTicketListFilters, GetTicketExport } from "../../../../services/TickectService";
import { Link, useHistory, useParams } from "react-router-dom";
import { InitialRender } from "../../../common/Helper";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import DataTable from "react-data-table-component";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import Hover from "../../../common/Hover";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../../utils/permissionGate";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { RenderCategory, RenderDepartment, RenderTicketLevel } from "../../../../utils/CommonGroupingItem";
import { checkIfImageExists, handleTableScroll } from "../../../../utils/commonFunction";
import HtmlParser from "react-html-parser";
import stc from "string-to-color";
import Tablefilter from "../../../common/Tablefilter";
import { RenderProgrammeStudentStatus, RenderTicketStatus } from "../../../../utils/CommonStatusItems";
import axios from "axios";


const Tickets = ({ studentName }) => {
  const [search, setSearch] = useState("");
  const [ticketList, setTicketList] = useState([]);
  const [ticketIdList, setTicketIdList] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [department, setDepartment] = useState({ arr: [], checkObj: [] });
  const [category, setCategory] = useState({ arr: [], checkObj: [] });
  const [studentstatus, setStudentStatus] = useState({ arr: [], checkObj: {} });
  const [filterData, setFilterData] = useState({level : [], sStatus : [], department : [], category : [], assignTo : []});
  const [sortkey, setSortKey] = useState("dueDays");
  const [sortOrder, setSortOrder] = useState("DESC");
  const history = useHistory();
  const [assignedTo, setAssignedTo] = useState({ arr: [], checkObj: [] });
  const [level, setLevel] = useState({ arr: [], checkObj: {} });

  const { id } = useParams();

  useEffect(() => {
    handleTableScroll()
  }, [isLoaded])

  useEffect(() => {
    GetStudentTicketListFilters(id).then((res)=>{
      let tempArr = [];
      res.data.categoryFilter.length && res.data.categoryFilter.sort((a,b)=>{return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1}).map(item => {
        tempArr.push({
          value: item.value,
          normalLabel: item.label,
          label: HtmlParser(`<span class="custom-filter-selected cat " style="color:${stc(item.value.toString().toLowerCase().replace("-", ""))};" title="${item.label}"><i class="fas fa-circle mr-1"></i> <span>${item.label}</span></span>`)
        })
      })
      let tempDepartArr = [];
      res.data.departmentFilter.length && res.data.departmentFilter.sort((a,b)=>{return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1}).map(item => {
        tempDepartArr.push({
          value: item.value,
          normalLabel: item.label,
          label: HtmlParser(`<span class="custom-filter-selected cat " style="color:${stc(item.value.toString().toLowerCase().replace("-", ""))};" title="${item.label}"><i class="fas fa-circle mr-1"></i> <span>${item.label}</span></span>`)
        })
      })
      setFilterData({...res.data, level : res.data.levelFilter, sStatus : res.data.studentStatusFulter, category : tempArr, department : tempDepartArr, assignTo : res.data.assignToFilter})
    }).catch(err=>console.log(err))
  }, [])
  
  useEffect(() => {
    let response = hasPermission({
      scopes: ["stview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  useEffect(() => {
    const cancelTokenSources = [];

    const getTicketList = async () => {
      setIsLoaded(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const apiData = {
        pageNo: page,
        size: perPage,
        viaLevel: level.arr,
        viaAssignedTo: assignedTo.arr,
        viaStudentStatus: studentstatus.arr,
        viaDepartment: department.arr,
        viaCategory: category.arr,
        viaSearch: search,
        key: sortkey,
        sort: sortOrder,
        exportStatus: false,
        uid: id
      }
  
      try {
        const res = await GetStudentTicketListCancelToken(apiData, source.token);
        if (res.status === 200) {
          setTicketList(res.data?.ticket_list?.data);
          setTotalRows(res.data?.ticket_list.total);
          const ids = res.data?.ticket_list?.data.map(ticket => ticket.id_ticket);
          setTicketIdList(ids);
          setIsLoaded(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoaded(false);
        }
      }
    };
  
    getTicketList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [page,perPage,studentstatus,department,category,search,sortkey,sortOrder,id,level,assignedTo]);

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "AESC" : "DESC");
  };

  const columns = useMemo(() => [
    {
      name: "Ticket Subject",
      selector: "subject",
      sortable: true,
      sortField: "subject",
      cell: (row, index) => (
        <div className="">
          <PermissionsGate
            scopes={["stadd"]}
            RenderError={() => (
              <p
                // to={{ pathname: "/editticket/" + row.id_ticket }}
                disabled
                className="as-text-blue curser feature-name"
              >
                <span className="textLimit100">{row.subject}</span>
              </p>
            )}
          >
            
              <Link
                to={{ pathname: "/editticket/" + row.id_ticket }}
                className="as-text-blue curser feature-name"
              >
                <span className="textLimit100" title={row.subject}>{row.subject}</span>
              </Link>
            
          </PermissionsGate>
        </div>
      ),
    },
    {
      name: "Submitted By",
      selector: "submitted_via",
      sortable: true,
      sortField: "submitted_via",
      cell: (row, index) => (
        <>
          {row.student_first_name ? (
            <div className="assigned-title-block-new">
              <div className="assigned-title-itm">
                <span
                  className={
                    "assigned-title-blk  name-icon cat-dark-red " +
                    ColorRender(row.student_first_name)
                  }
                >
                  {row.student_photo && checkIfImageExists(`${IMAGE_URL}/${row.student_photo.replaceAll(
                        "/home/myaie/public_html/",
                        ""
                      ).replace("public/","")}`) ? (
                    <img
                      src={`${IMAGE_URL}/${row.student_photo.replaceAll(
                        "/home/myaie/public_html/",
                        ""
                      ).replace("public/","")}`}
                      alt="AIE"
                    />
                  ) : (
                    InitialRender([row.student_first_name,row.student_last_name])
                  )}
                  <span className={`profile-box-2-status ${row.activity_status ? row.activity_status.toLowerCase()=="online" ? "Online" :row.activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                    <i className="fas fa-circle"></i>
                  </span>
                  <Hover
                    firstName={row.student_first_name}
                    lastName={row.student_last_name}
                    photo={row.student_photo}
                    email={row.student_email}
                    mobile={row.student_mobile}
                    right={true}
                    status={row.student_status}
                    activity_status={row.activity_status}
                    showNumber={true} 
                    number={row.student_crm_id}
                  />
                </span>
                <Link
                  key={index}
                  to={{
                    pathname:
                      "https://www.myaie.ac/cadcocms/students_list.php?intake=" +
                      row.id_student,
                  }}
                  target="_blank"
                  className="as-text-blue curser feature-name"
                >
                  <p
                    className="textLimit100"
                  
                    title={row.student_first_name + " " + row.student_last_name}
                  >
                    {
                      row.student_first_name + " " + row.student_last_name}
                  </p>
                </Link>
              </div>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Student Status",
      selector: "student_status",
      wrap: true,
      sortable: true,
      sortField: "student_status",
      cell: (row, index) => RenderProgrammeStudentStatus(row.student_status).html,
    },
    {
      name: "Level",
      selector: "Level",
      wrap: true,
      sortable: true,
      sortField: "level",
      cell: (row) => (row.level ? RenderTicketLevel(row.level).html : "-"),
    },
    // {
    //   name: "Days",
    //   selector: "dueDays",
    //   wrap: true,
    //   sortable: true,
    //   sortField: "dueDays",
    //   cell: (row) => row.dueDays,
    // },
    {
      name: "Status",
      selector: "status",
      wrap: true,
      sortable: true,
      sortField: "status",
      cell: (row) => RenderTicketStatus(row.status).html,
    },
    {
      name: "Created",
      selector: "date_add",
      sortField: "date_add",
      sortable: true,

      cell: (row) => (
        <>
          {row.date_add ? (
            <div className="dateTime">
              <p className="right-space">
                {moment(row.date_add).format(TABLE_DATE_FORMAT)}
              </p>
              <p>{moment(row.date_add).format(TABLE_TIME_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Modified",
      selector: "last_update",
      sortable: true,
      sortField: "last_update",
      cell: (row) => (
        <>
          {row.last_update ? (
            <div className="dateTime">
              <p className="right-space">
                {moment(row.last_update).format(TABLE_DATE_FORMAT)}
              </p>
              <p>{moment(row.last_update).format(TABLE_TIME_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Assigned To",
      selector: "assign_to",
      sortable: true,
      sortField: "assign_to",
      cell: (row, index) => (
        <>
          {row.user_first_name ? (
            <div className="assigned-title-block-new">
              <div className="assigned-title-itm">
                <span
                  className={
                    "assigned-title-blk  name-icon cat-dark-red " +
                    ColorRender(row.user_first_name)
                  }
                >
                  {row.user_picture &&
                  checkIfImageExists(
                    `${IMAGE_URL}/${row.user_picture.replaceAll(
                      "/home/myaie/public_html/",
                      ""
                    )}`
                  ) ? (
                    <img
                      src={`${IMAGE_URL}/${row.user_picture.replaceAll(
                        "/home/myaie/public_html/",
                        ""
                      )}`}
                      alt="AIE"
                    />
                  ) : (
                    InitialRender([row.user_first_name, row.user_last_name])
                  )}
                  <span className={`profile-box-2-status ${row.users_activity_status ? row.users_activity_status.toLowerCase()=="online" ? "Online" :row.users_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                    <i className="fas fa-circle"></i>
                  </span>
                  <Hover
                    firstName={row.user_first_name}
                    lastName={row.user_last_name}
                    photo={row.user_picture}
                    email={row.user_email}
                    mobile={row.user_mobile}
                    status={row.users_status}
                    activity_status={row.users_activity_status}
                    right={true}
                  />
                </span>

                <Link
                  key={index}
                  to={{
                    pathname:
                      "https://www.myaie.ac/cadcocms/students_list.php?intake=" +
                      row.id_student,
                  }}
                  target="_blank"
                  className="as-text-blue curser feature-name"
                >
                  <span className="textLimit100" title={row.user_first_name + " " + row.user_last_name}>
                    {
                      row.user_first_name + " " + row.user_last_name}
                  </span>
                </Link>
              </div>
            </div>
          ) : (
            "-"
          )}
        </>
        // <div className="">
        //   <p>
        //     <span className="overflow-ellipsis2">
        //       {row.assign_to ? row.assign_to : "-"}
        //     </span>
        //   </p>
        // </div>
      ),
    },
    {
      name: "Department",
      selector: "department",
      sortField: " department",
      sortable: true,
      cell: (row) => (row.department ? <span className="textLimit76">{RenderDepartment(row.department).html}</span> : "-"),
    },
    {
      name: "Category",
      selector: "category",
      sortField: "category",
      sortable: true,
      cell: (row) => {
        return row.category ? <span className="feature-name"><span className="textLimit100">{RenderCategory(row.category).html}</span></span> : "-";
        // return "Category"
      },
    },
    {
      name: "Related Course",
      selector: "course_name",
      sortField: "course_name",
      // wrap: true,
      sortable: true,
      cell: (row) => (
        <span className="feature-name">
          {" "}
          <span className="textLimit100" title={row.course_name}>
            {row.course_name ? row.course_name :"-"}
          </span>{" "}
        </span>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["stadd"]}
              RenderError={() => (
                <button
                  title="Open"
                  className="btn btn-primary rounded-circle"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link to={{ pathname: "/editticket/" + row.id_ticket }}>
                <button title="Open" className="btn btn-primary rounded-circle">
                  <i className="fal fa-folder-open"></i>
                </button>
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ], []);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setDepartment({ arr: [], checkObj: [] });
    setCategory({ arr: [], checkObj: [] });
    setStudentStatus({ arr: [], checkObj: {} });
    setSearch("");
    setAssignedTo({ arr: [], checkObj: [] });
    setLevel({ arr: [], checkObj: {} });
  };
  
    
    const handleExportTickets = () => {
      // Call the GetTicketExport function with the ticketIdList array
      console.log(ticketIdList);
      GetTicketExport(ticketIdList)
        .then(response => {
          generatePDF(response.data);
        })
        .catch(error => {
          // Handle errors, e.g., display an error message
        });
    };
    const generatePDF = (ticketExportData) => {
      const doc = new JsPDF();
      var flag = false;
      var student = "";
      for (const ticketId in ticketExportData) {
        if (ticketExportData.hasOwnProperty(ticketId)) {
          const ticket = ticketExportData[ticketId];
          const availableWidth = doc.internal.pageSize.getWidth() - 20;
          if (flag){
            doc.addPage(); // Add a new page for each ticket
          }else{
            flag = true;
          }
          let yPos = 10; // Initial y-position for printing ticket details
          var lineHeight = 5;
          student = ticket['Poster Name'];
          // Print ticket details 
          doc.setFontSize(10);         
          doc.text(`Ticket ID: ${ticket['id']}`, 10, yPos);
          yPos += lineHeight;
          doc.text(`Student : ${ticket['Poster Name']}`, 10, yPos);
          yPos += lineHeight;
          doc.text(`Subject : ${ticket['subject']}`, 10, yPos);
          yPos += lineHeight;
          doc.text(`Department : ${ticket['department']}`, 10, yPos);
          yPos += lineHeight;
          doc.text(`Category : ${ticket['category']}`, 10, yPos);
          yPos += lineHeight * 2;
          const originalMessageLines = doc.splitTextToSize(("Original Message : " + ticket['message']), availableWidth);
          originalMessageLines.forEach((line, index) => {
            doc.text(10, yPos, line); // Adjust the line spacing as needed
            yPos += lineHeight;
          });
          yPos += lineHeight;
          if(ticket['attachment']){
              doc.setTextColor(0, 0, 255); // Set text color to blue
              doc.text(`Attachment : ${ticket['attachment']}`, 10, yPos);
              doc.setTextColor(0, 0, 0); // Reset text color to black
          }
          yPos += lineHeight *3;
     
          // Print ticket messages
          doc.setFontSize(8);
          ticket.messages.forEach((message, index) => {
            const originalMessageLines = doc.splitTextToSize((`${message['Poster Name']} (${message['send_date']}): ${message.message}`), availableWidth);
            originalMessageLines.forEach((line, index) => {
              doc.text(10, yPos + index, line); // Adjust the line spacing as needed
              yPos += lineHeight;
            });
            yPos += lineHeight
            if(message.attachment){
                const originalMessageLines = doc.splitTextToSize((`Attachment : ${message.attachment}`), availableWidth);
                originalMessageLines.forEach((line, index) => {
                  doc.setTextColor(0, 0, 255); // Set text color to blue
                  doc.text(10, yPos + index, line); // Adjust the line spacing as needed
                  doc.setTextColor(0, 0, 0); // Reset text color to black
                  yPos += lineHeight;
                });
            }
            yPos += lineHeight * 3;
          });         
        }        
      }
    
      // Save the PDF file
      doc.save('tickets_' + student + '.pdf');
    };
  const exportData = (fileType, fileName) => {
    let data;

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const header = [
      "Ticket Subject",
      "Submitted By",
      "Student Status",
      "Level",
      // "Days",
      "Status",
      "Created",
      "Modified",
      "Assigned To",
      "Department",
      "Category",
      "Related Course",
    ];
    let params = {
      pageNo: page,
      size: perPage,
      viaStudentStatus: studentstatus.arr,
      viaDepartment: department.arr,
      viaCategory: category.arr,
      viaSearch: search,
      key: sortkey,
      sort: sortOrder,
      exportStatus: true,
      uid: id
    }
    
    GetStudentTicketList(params)
      .then((res) => {
      data = res.data.ticket_list;
      
      data = data?.map((row) => ({
        ...row,
        "Submitted By": row.student_first_name && row.student_last_name ? `${row.student_first_name} ${row.student_last_name}`:"-",
        "Ticket Subject": row.subject ? row.subject : "-",
        "Student Status": row.student_status ? RenderProgrammeStudentStatus(row.student_status).text : "-",
        Level: row.level ? row.level : "-",
        // "Days": row?.dueDays,
        Status: row.status ? RenderTicketStatus(row.status).text : "-",
        Created:
          row.date_add &&
          moment(row.date_add).format(TABLE_DATE_FORMAT) +
            " " +
            moment(row.date_add).format(TABLE_TIME_FORMAT),
        Modified:
          row.last_update &&
          moment(row.last_update).format(TABLE_DATE_FORMAT) +
            " " +
            moment(row.last_update).format(TABLE_TIME_FORMAT),
        "Assigned To": row.user_first_name + " " + row.user_last_name,
        Department: row.department ? RenderDepartment(row.department).text : "-",
        Category: row.category ? row.category : "-",
        "Related Course": row.course_name ? row.course_name : "-",
      }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
      Swal.close();
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
      Swal.close();
      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Ticket Subject"],
          row["Submitted By"],
          row["Student Status"],
          row.level,
          // row["Days"],
          row["Status"],
          row["Created"],
          row["Modified"],
          row["Assigned To"],
          row.department,
          row.category,
          row["Related Course"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);
      Swal.close();
      return false;
    }
        
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(false);
      });

  };

  return (
        <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
          <div className="custom-table-div filter-search-icon card card-table-custom">
            <div className="search-filter-div">
              <div className="search-filter-div-left">
                <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div
                      id="assessment-table-main_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                        <div
                          id="assessment-table-main_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            <input
                              type="search"
                              className=""
                              placeholder="Search"
                              aria-controls="assessment-table-main"
                              onChange={handleSearchFilter}
                              value={search}
                            />
                          </label>
                          <div className="filter-eff filter-data-btn">
                            <button className="filter-buttons">
                              <i className="fal fa-filter"></i>
                            </button>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="filter-button-group">
                    <div className="filter-scroll">
                      <div className={`filter-scroll-inner  filter-custom-new`}>
                      <Tablefilter
                        filterName="Level"
                        optionArr={filterData.level}
                        state={level}
                        setState={setLevel}
                        renderLabelFunction={RenderTicketLevel}
                      />
                      <Tablefilter
                        filterName="Assigned To"
                        optionArr={filterData.assignTo}
                        state={assignedTo}
                        setState={setAssignedTo}
                        isSearchFilter
                      />
                      <Tablefilter
                        filterName="Department"
                        optionArr={filterData.department}
                        state={department}
                        setState={setDepartment}
                        renderLabelFunction={RenderDepartment}
                        isSearchFilter
                      />
                        <Tablefilter
                        filterName="Category"
                        optionArr={filterData.category}
                        state={category}
                        setState={setCategory}
                        renderLabelFunction={RenderCategory}
                        isSearchFilter
                      />
                        <Tablefilter
                        filterName="Student Status"
                        optionArr={filterData.sStatus}
                        state={studentstatus}
                        setState={setStudentStatus}
                        renderLabelFunction={RenderProgrammeStudentStatus}
                      />
                      </div>
                    </div>
                    <div className="reset-btn-group">
                      <div className="button-reset dropdown-comman">
                        <button
                          className="btn btn-primary"
                          onClick={resetFilter}
                          title="Reset"
                        >
                          <i className="fal fa-redo"></i>Reset
                        </button>
                      </div>

                      <div className="files-export-group">
                        <button
                          type="button"
                          className="btn btn-files"
                          title="Export spreadsheet"
                          onClick={() => {
                            exportData("xlsx", "Tickets");
                          }}
                        >
                          <i className="fal fa-file-excel icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          title="Export CSV"
                          onClick={() => {
                            exportData("csv", "Tickets");
                          }}
                        >
                          <i className="fal fa-file-csv icon"></i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-files"
                          title="Export PDF"
                          onClick={() => {
                            exportData("pdf", "Tickets");
                          }}
                        >
                          <i className="fal fa-file-pdf icon"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="search-filter-div-right">
                <div className=" filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman export-ticket-button">
                    <PermissionsGate scopes={["stview"]}>
                      <button
                          className="btn btn-primary"                          
                          onClick={handleExportTickets}
                          title="Export Tickets"
                        >
                          <i className="fal fa-file-export"></i>Export Tickets
                        </button>
                      
                    </PermissionsGate>
                  </div>
                  <div className="add-ticket-blk button-reset dropdown-comman">
                    <PermissionsGate
                      scopes={["stadd"]}
                      RenderError={() => (
                        <button
                          className="btn btn-primary"
                          disabled
                          // to={`/addticketStudent/${id}/${studentName}`}
                          title="Create New"
                        >
                          <i className="fal fa-plus"></i>Create New
                        </button>
                      )}
                    >
                      <Link
                        className="btn btn-primary"
                        to={`/addticketStudent/${id}/${studentName}`}
                        title="Create New"
                      >
                        <i className="fal fa-plus"></i>Create New
                      </Link>
                    </PermissionsGate>
                  </div>
                </div>
              </div>
            </div>
              <DataTable
              defaultSortField="dueDays"
              defaultSortAsc={false}
              onSort={handleSort}
              data={ticketList}
              columns={columns}
              progressPending={isLoaded}
              progressComponent={<SkeletonTicketList />}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover={false}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              />
          </div>
        </div>
        );
};

export default Tickets;
