import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import { useSelector } from "react-redux";
import { AllBrandList, addStudentReRegistrations } from "../../../services/RegistrationService";
import { MOBILE_REGEX, NAME_REGEX } from "../../../utils/Constants";
import StudentsModalTable from "../../supportadministration/addticketmodals/StudentsModalTable";

const RegisterNewStudent = () => {
  const { id, type } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [applicationFormName, setApplicationFormName] = useState();
  const [invoiceFormName, setInvoiceFormName] = useState();
  const [dropdownValues, setDropdownValues] = useState(
    useSelector((state) => state.registerDropdownValues)
  );

  const [applicationStatus, setApplicationStatus] = useState([]);
  const [status, setStatus] = useState("");

  const [financeOption, setFinanceOption] = useState([]);
  const [finance, setFinance] = useState("");

  const [schoolType, setSchoolType] = useState([]);
  const [school, setSchool] = useState("");

  const [learningMethode, setLearningMethode] = useState([]);
  const [methode, setMethode] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentID, setStudentID] = useState("");
  const [intakeArr, setIntakeArr] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [applicationFormBlob, setApplicationFormBlob] = useState("");
  const [invoiceFormBlob, setInvoiceFormBlob] = useState("");

  useEffect(() => {
    setApplicationStatus(dropdownValues.application_status);
    setFinanceOption(dropdownValues.finance_option_chosen);
    setSchoolType(dropdownValues.school_type);
    let tempIntakeArr = []
    dropdownValues.intake_year.map(year => {
      dropdownValues.intake_number.map(number => {
          tempIntakeArr.push({ value: `${year.value}/${number.label}`, label: `${year.value}-${number.label}` })
      })
    })
    setIntakeArr(tempIntakeArr)
    setLearningMethode(dropdownValues.delivery_method);

    AllBrandList().then((res)=>{
      let respData = res.data.allBrandList;
      respData = respData.map((item)=>({label : item.brand_name, value : item.id}));
      setAllBrands([...respData]);
    }).catch((err)=>{
      console.log(err)
    })
  }, []);

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      ApplicationStatus: "",
      FirstName: "",
      LastName: "",
      FinanceOption: "",
      ApplicationForm: "",
      InvoiceForm: "",
      School: "",
      intake: "",
      LearningMethod: "",
      id_student: studentID,
      Student: studentName,
      student_crm_id: "",
      RSA_id: "",
      mobile: "",
      email: "",
      brand_reg:""
    },
    validationSchema: Yup.object({
      ApplicationStatus: Yup.string().required("ApplicationStatus is required"),
      FirstName: Yup.string().matches(NAME_REGEX, "Please enter valid first name").required("First Name is required").trim(),
      LastName: Yup.string().matches(NAME_REGEX, "Please enter valid last name").required("Last Name is required").trim(),
      // FinanceOption: Yup.string().required("Finance Option is required"),
      ApplicationForm: Yup.string().required("Application Form is required"),
      InvoiceForm: Yup.string().required("Invoice Form is required"),
      School: Yup.string().required("School is required"),
      // IntakeNum: Yup.string().required("Intake Number is required"),
      // IntakeYear: Yup.string().required("Intake Year is required"),
      intake: Yup.string().when("ApplicationStatus", {
        is: "Re-Registration",
        then: Yup.string().required("Intake is required"),
      }),
      LearningMethod: Yup.string().required("Learning Method is required"),
      student_crm_id: Yup.string().when("ApplicationStatus",{
        is: "new-status",
        then: Yup.string().required("Student Number is required").trim(),
      }),
      email: Yup.string().strict(true).email("Invalid Email address").trim(),
      mobile: Yup.string().matches(MOBILE_REGEX, "Please Enter a Valid Mobile Number"),
      RSA_id: Yup.string().strict().trim(),
      id_student: Yup.string().when("ApplicationStatus", {
        is: "Re-Registration",
        then: Yup.string().required("Student Name is required"),
      }),
      brand_reg : Yup.string().when("ApplicationStatus", {
        is: "new-status",
        then: Yup.string().required("Brand is required"),
      }),
    }),
    onSubmit: (values, { resetForm }) => {
      setDisabled(true);
      let formData = new FormData();
      type == "open" && formData.append("id", id);
      formData.append("status", values?.ApplicationStatus);
      formData.append("first_name", values?.FirstName);
      formData.append("last_name", values?.LastName);
      formData.append("finance_option_chosen", values?.FinanceOption);
      formData.append("application_form", values?.ApplicationForm);
      formData.append("invoice_form", values?.InvoiceForm);
      formData.append("academy", values?.School);
      formData.append("intake_number", values?.intake.split("/")[1]);
      formData.append("intake_year", values?.intake.split("/")[0]);
      formData.append("delivery_methode", values?.LearningMethod);
      formData.append("live_portal_user_id", values?.id_student);
      if(status=="New"){
        formData.append("email", values?.email);
        formData.append("student_crm_id", values?.student_crm_id);
        formData.append("RSA_id", values?.RSA_id);
        formData.append("mobile", values?.mobile);
        formData.append("brand_reg", values?.brand_reg);

      }

      // if (type == "open") {
      //   UpdateProgramme(formData)
      //     .then((res) => {
      //       Swal.fire({
      //         icon: "success",
      //         title: "Success",
      //         text: "Updated successfully",
      //       });
      //       setDisabled(false);
      //     })
      //     .catch((err) => console.log("error :", err));
      // } else {
      addStudentReRegistrations(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Added successfully",
          });
          setDisabled(false);
          history.push("/studentadministration/registration/studentlist");
        })
        .catch((err) => {
          console.log("error :", err);
          setDisabled(false);
        });
      // }
    },
  });

  const setSelectedStudent = (names) => {
    let studentname = names.map((name) => name.first_name);
    let studentID = names.map((name) => name.id);
    setStudentName(studentname);
    setStudentID(studentID);
    formik.setFieldValue("Student", studentname);
    formik.setFieldValue("id_student", studentID);
  };

  return (
    <div className="card card-body-inr reg-st-tbl">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="ApplicationStatus">Application Status * </label>
            <div className="">
              <div className="form-icon-group mb-4" title="Application Status">
                {/* <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.ApplicationStatus && formik.touched.ApplicationStatus
                      ? " is-invalid"
                      : "")
                  }
                  name="ApplicationStatus"
                  id="ApplicationStatus"
                  placeholder="ApplicationStatus"
                  title="ApplicationStatus"
                  value="Re-Registration"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled
                /> */}
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.ApplicationStatus &&
                      formik.touched.ApplicationStatus
                      ? " is-invalid"
                      : "")
                  }
                  name="ApplicationStatus"
                  value={status || "Application Status"}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("ApplicationStatus", value.value);
                      setStatus(value.label);
                    } else {
                      formik.setFieldValue("ApplicationStatus", '');
                      setStatus('');
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={applicationStatus}
                  maxMenuHeight={175}
                  placeholder={status || "Select"}
                  isClearable
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="FirstName">First Name *</label>
            <div className="form-icon-group mb-4" title="FirstName">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.FirstName && formik.touched.FirstName
                    ? " is-invalid"
                    : "")
                }
                name="FirstName"
                id="FirstName"
                placeholder="First Name"
                title="FirstName"
                value={formik.values.FirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="LastName">Last Name *</label>
            <div className="form-icon-group mb-4" title="LastName">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.LastName && formik.touched.LastName
                    ? " is-invalid"
                    : "")
                }
                name="LastName"
                id="LastName"
                placeholder="Last Name"
                title="LastName"
                value={formik.values.LastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="FinanceOption">Finance Option </label>
            <div className="">
              <div className="form-icon-group mb-4" title="Finance Option">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.FinanceOption && formik.touched.FinanceOption
                      ? " is-invalid"
                      : "")
                  }
                  name="FinanceOption"
                  value={financeOption?.filter(val=>val.label === finance)}
                  onChange={(value) => {
                    if(value){
                      formik.setFieldValue("FinanceOption", value.value);
                      setFinance(value.label);
                    } else {
                      formik.setFieldValue("FinanceOption", "");
                      setFinance("");
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={financeOption}
                  maxMenuHeight={175}
                  placeholder={finance || "Finance Option"}
                  isClearable
                />
                {formik.errors.FinanceOption && formik.touched.FinanceOption ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label>Application Form *</label>
              <div className="form-group atttach-file m-0">
                <label
                  className={
                    formik.errors.ApplicationForm &&
                    formik.touched.ApplicationForm &&
                    "file-req is-invalid"
                  }
                >
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <input
                    type="file"
                    className="form-control  form-control-aatch-file"
                    onChange={(e) => {
                      if (e.target.files.length) {
                        let reader = new FileReader();
                        reader.onload = (e) => {
                          setApplicationFormBlob(e.target.result)
                        };
                        reader.readAsDataURL(e.target.files[0]);
                        formik.setFieldValue(
                          "ApplicationForm",
                          e.target.files[0]
                        );
                        setApplicationFormName(e.target.files[0].name);
                      } else {
                        setApplicationFormBlob("")
                      }
                    }}
                  />
                </label>
              </div>
              {applicationFormName && (
                <>
                  <div className="frm-group">
                    <ul className="list-unstyled attached-file-ul m-0">
                      <li>
                        <a href={applicationFormBlob} target="_blank">
                          <i className="fal fa-file"></i>
                          &nbsp;{applicationFormName}
                        </a>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label>Invoice Form *</label>
              <div className="form-group atttach-file m-0">
                <label
                  className={
                    formik.errors.InvoiceForm &&
                    formik.touched.InvoiceForm &&
                    "file-req is-invalid"
                  }
                >
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <input
                    type="file"
                    className="form-control  form-control-aatch-file"
                    onChange={(e) => {
                      if (e.target.files.length) {
                        let reader = new FileReader();
                        reader.onload = (e) => {
                          setInvoiceFormBlob(e.target.result)
                        };
                        reader.readAsDataURL(e.target.files[0]);
                        formik.setFieldValue("InvoiceForm", e.target.files[0]);
                        setInvoiceFormName(e.target.files[0].name);
                      } else {
                        setInvoiceFormBlob("")
                      }
                    }}
                  />
                </label>
              </div>
              {invoiceFormName && (
                <>
                  <div className="frm-group">
                    <ul className="list-unstyled attached-file-ul m-0">
                      <li>
                        <a href={invoiceFormBlob} target="_blank">
                          <i className="fal fa-file"></i>
                          &nbsp;{invoiceFormName}
                        </a>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="School">School * </label>
            <div className="">
              <div className="form-icon-group mb-4" title="School">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.School && formik.touched.School
                      ? " is-invalid"
                      : "")
                  }
                  name="School"
                  value={schoolType?.filter(val=>val.label === school)}
                  onChange={(value) => {
                    if(value){
                      formik.setFieldValue("School", value.value);
                      setSchool(value.label);
                    } else {
                      formik.setFieldValue("School", "");
                      setSchool("");
                    }                    
                  }}
                  onBlur={formik.handleBlur}
                  options={schoolType}
                  maxMenuHeight={175}
                  placeholder={school || "School"}
                  isClearable
                />
                {formik.errors.School && formik.touched.School ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="intake">{`Intake `+ (status == "Re-Registration" ? "* " :"")}</label>
            <div className="">
              <div className="form-icon-group mb-4" title="Intake">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.intake && formik.touched.intake
                      ? " is-invalid"
                      : "")
                  }
                  name="intake"
                  value={intakeArr.filter(item => {
                    return item.value == formik.values.intake
                  })}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("intake", value.value);
                    } else {
                      formik.setFieldValue("intake", "");
                    }                    
                  }}
                  onBlur={formik.handleBlur}
                  options={intakeArr}
                  maxMenuHeight={175}
                  placeholder={formik.values.intake || "Select"}
                  isClearable
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="LearningMethod">Learning Method * </label>
            <div className="">
              <div className="form-icon-group mb-4" title="Learning Method">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.LearningMethod &&
                      formik.touched.LearningMethod
                      ? " is-invalid"
                      : "")
                  }
                  name="LearningMethod"
                  value={learningMethode?.filter(val=>val.label === methode)}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("LearningMethod", value.value);
                      setMethode(value.label);
                    } else {
                      formik.setFieldValue("LearningMethod", "");
                      setMethode("");
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={learningMethode}
                  maxMenuHeight={175}
                  placeholder={methode || "Select"}
                  isClearable
                />
                {formik.errors.LearningMethod &&
                  formik.touched.LearningMethod ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className="col-md-4">
            <label htmlFor="IntakeYear">Intake Year * </label>
            <div className="">
              <div className="form-icon-group mb-4" title="Intake Year">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.IntakeYear && formik.touched.IntakeYear
                      ? " is-invalid"
                      : "")
                  }
                  name="IntakeYear"
                  value={intakeYear?.filter(val=>val.label === year)}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("IntakeYear", value.label);
                      setYear(value.label);
                    } else {
                      formik.setFieldValue("IntakeYear", "");
                      setYear("");
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={intakeYear}
                  maxMenuHeight={175}
                  placeholder={year || "Select"}
                  isClearable
                />
                {formik.errors.IntakeYear && formik.touched.IntakeYear ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="IntakeNum">Intake Number * </label>
            <div className="">
              <div className="form-icon-group mb-4" title="Intake Number">
                <Select
                  className={
                    "form-control custom-select-box " +
                    (formik.errors.IntakeNum && formik.touched.IntakeNum
                      ? " is-invalid"
                      : "")
                  }
                  name="IntakeNum"
                  value={intakeNumber?.filter(val=>val.label === number)}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("IntakeNum", value.value);
                      setNumber(value.label);
                    } else {
                      formik.setFieldValue("IntakeNum", '');
                      setNumber("");
                    }
                  }}
                  onBlur={formik.handleBlur}
                  options={intakeNumber}
                  maxMenuHeight={175}
                  placeholder={number || "Select"}
                  isClearable
                />
                {formik.errors.IntakeNum && formik.touched.IntakeNum ? (
                  <span className="exclamation">
                    <i className="fal fa-exclamation-circle"></i>
                  </span>
                ) : null}
              </div>
            </div>
          </div> */}
          {/* <div className="col-md-4">
            <div className="">
              <label>Student Name*</label>
              <div className="form-icon-group mb-4">
                <input
                  name="id_student"
                  type="text"
                  className={
                    "form-control custom-select-box" +
                    (formik.errors.id_student && formik.touched.id_student
                      ? " is-invalid"
                      : "")
                  }
                  placeholder={formik.values.Student || "Student Name"}
                  disabled
                />
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#add-student"
                  title="Select"
                >
                  Select
                </button>
              </div>
              {formik.errors.id_student && formik.touched.id_student ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div> */}
          {status=="New" && (
            <>
              <div className="col-md-6 col-lg-4 ">
                <label htmlFor="brand">Brand *</label>
                <div className="form-icon-group mb-4" title="Brand">
                  <Select
                    className={"form-control custom-select-box " + (formik.errors.brand_reg && formik.touched.brand_reg ? " is-invalid" : "")}
                    name="brand"
                    value={allBrands.filter((item) => {
                      return item.value == formik.values.brand_reg;
                    })}
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("brand_reg", value.value);
                      } else {
                        formik.setFieldValue("brand_reg", "");
                      }
                    }}
                    isClearable
                    onBlur={formik.handleBlur}
                    options={allBrands.sort((a, b) => {
                      let name1 = a.label;
                      let name2 = b.label
                      return name1.localeCompare(name2)
                    })}
                    maxMenuHeight={175}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
              <label htmlFor="student_crm_id">Student Number *</label>
              <div className="form-icon-group mb-4" title="LastName">
                <input
                  type="text"
                  className={
                    "form-control" +
                    (formik.errors.student_crm_id && formik.touched.student_crm_id
                      ? " is-invalid"
                      : "")
                  }
                  name="student_crm_id"
                  id="student_crm_id"
                  placeholder="Student Number"
                  title="Student Number"
                  value={formik.values.student_crm_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <label htmlFor="email">Email</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="Email"
                    className={"form-control" + (formik.errors.email && formik.touched.email ? " is-invalid" : "")}
                    name="email"
                    id="email"
                    placeholder="Enter Email address"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="Email"
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <label htmlFor="RSA_id">RSA ID</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="text"
                    id="RSA_id"
                    className={"form-control"}
                    name="RSA_id"
                    placeholder="Enter Passport/RSA ID"
                    value={formik.values.RSA_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="RSA ID"
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <label htmlFor="mobile">Mobile</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="text"
                    id="mobile"
                    className={"form-control" + (formik.errors.mobile && formik.touched.mobile ? " is-invalid" : "")}
                    name="mobile"
                    placeholder="Enter Mobile"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="Mobile"
                  />
                </div>
              </div>
            </>
          )}
          {status == "Re-Registration" && (
          <div className="col-md-6 col-lg-4">
            <label htmlFor="studentName">Student Name *</label>
            <div className="form-icon-group mb-4" title="studentName">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.id_student && formik.touched.id_student
                    ? " is-invalid"
                    : "")
                }
                name="Student Name*"
                id="studentName"
                placeholder="Student Name"
                title="studentName"
                value={formik.values.Student}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled
              />
              <button
                type="button"
                className="btn btn-primary ml-2 mt-1"
                data-toggle="modal"
                data-target="#add-student"
                title="Select"
              >
                Select
              </button>
            </div>
            {formik.errors.id_student && formik.touched.id_student ? (
              <span className="exclamation">
                <i className="fal fa-exclamation-circle"></i>
              </span>
            ) : null}
          </div>
          )}
        </div>
        <div className="form-group form-group-save-cancel">
          <button
            className="btn btn-save btn-success"
            type="submit"
            title="Save"
            disabled={isLoading}
            onClick={formik.handleSubmit}
          >
            {disabled ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-save"></i>
            )}
            Save
          </button>
          <button
            className="btn btn-close btn-danger"
            type="reset"
            title="Cancel"
            onClick={() => history.goBack()}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {Object.keys(formik.values).map(key => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block" key={key}>
                  {formik.errors[key]}
                </div>
              )
            }
          })}
        </div>
      </form>
      <div
        className="topic-add-modal modal fade"
        id="add-student"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Select Student</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
            <div className="modal-body">
              <StudentsModalTable selection={setSelectedStudent} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterNewStudent;
