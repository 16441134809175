import React from "react";
import { useParams } from "react-router-dom";
import EmailView from "./EmailView";
import StoppedList from "./StoppedList";

function EmailStoppedTab({setName}) {
  const { tab, type, id } = useParams();

  return (
    <div>
      {type && type == "table" && !id && <StoppedList />}
      {type && type == "view" && id && <EmailView setName={setName} email_id={id} />}
      {/* {type && type == "add" && !id && <CustomerAdd />}
  {type && type == "edit" && id && <CustomerEdit customer_id={id} />}*/}
    </div>
  );
}

export default EmailStoppedTab;
