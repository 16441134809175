import React, { useState } from "react";
import AssignedAssessments from "./AssignedAssessments";

const AssessmentTables = (props) => {

  const [zIndex, setZIndex] = useState(1);
  const [load, setLoad] = useState(false)
  
  return (
    <>
      <AssignedAssessments className={zIndex == 1 ? "z-i-h" : ""} change={props} userId={props.userId} setZIndex={setZIndex} zIndex={zIndex} load={load} setLoad={setLoad} />      
    </>
  );
};

export default AssessmentTables;
