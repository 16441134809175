/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef, useState } from "react";
import $ from "jquery";
import Logo from "../../assets/images/ami-logo-icon.svg";
import { useHistory, Link } from "react-router-dom";
import { removeUserSession } from "../../utils/Auth";
import Swal from "sweetalert2";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  GetLinkList,
  CreateOrUpdateQuickLink,
  DeleteLink,
} from "../../services/DashBoardServices";
import {
  selectTab,
  unsetWidget,
  unsetUser,
  unsetTab,
  setUserStatus,
  clearBrandVisuals,
  setBrandVisuals,
} from "../../store/actions";
import { LogoutService } from "../../services/ProfileService";
import { UpdateUserStatus } from "../../services/UserService";
import TempProfileImage from "../../assets/images/default-profile.jpeg";
import { InitalRender, originalValues } from "./Helper";
import { BASE_URL, IMAGE_URL, TABLE_DATE_FORMAT, TABLE_TIME_FORMAT } from "../../utils/Constants";
import { MainContext } from "../../contexts/MainContext";
import { getCurrentTimeHomePage } from "./Helper";
import { URL_REGEX } from "../../utils/Constants";
import moment from "moment";
import PermissionsGate from "../../utils/permissionGate";
import { GetBrandColor } from "../../services/ConfigServices";
import { RenderQuizTopic } from "../../utils/CommonStatusItems";
import { GradeLetterTemplateListFilters } from "../../services/GradeSettingService";
import { isArray } from "underscore";
import SekletonLearningMaterial from "../../loaders/SekletonLearningMaterial";

export const StaticQuickLinks = [
  {
    url_name: "NetSuite",
    url: "https://system.netsuite.com/pages/customerlogin.jsp?country=US",
    status: "static",
    permission: "qlnsview",
  },
  {
    url_name: "Payspace",
    url: "https://identity.yourhcm.com/Account/Login",
    status: "static",
    permission: "qlpsview",
  },
  {
    url_name: "Sharepoint",
    url: "https://cadcorporation.sharepoint.com/sites/AIEGroup",
    status: "static",
    permission: "qlspview",
  },
  {
    url_name: "Policies & Procedures",
    url: "https://cadcorporation.sharepoint.com/:f:/s/AIEGroup/Et6SseWDLAVEsy558eeDVwgBvtaoYWp5_hZO05UzAsUrtg?e=DRt9Df",
    status: "static",
    permission: "qlppview",
  }
];

const Header = (props) => {
  const location = useHistory();
  const dispatch = useDispatch();
  let urlSplit = location.location.pathname.split("/")
  // console.log(urlSplit,"url")
  // const [status, setStatus] = useState("online");

  const { state, status, setStatus } = useContext(MainContext);

  const user = useSelector((state) => state.user);
  const brandData= useSelector((state)=> state.brandVisuals.config);  

  const initials = user?.Firstname[0] + user?.Lastname[0];

  const [time, setTime] = useState(
    moment(Date().toLocaleString()).format(`dddd, ${TABLE_DATE_FORMAT} | ${TABLE_TIME_FORMAT}:ss`)
  );
  // const [time, setTime] = useState(
  //   moment(Date().toLocaleString()).format("dddd, MMMM Do YYYY | h:mm:ss a")
  // );
  const [quickLinks, setQuicklinks] = useState([...StaticQuickLinks]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [linkName, setLinkName] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isResponded, setIsResponded] = useState(false);
  const [requiredField, setRequiredField] = useState({
    name: false,
    url: false,
  });
  const [filterData, setFilterData] = useState({
    type: [],
    keywords: [],
    topics: [],
  });
  const [updateQuickLinkUrl, setUpdateQuickLinkUrl] = useState("");
  const [updateQuickLinkName, setUpdateQuickLinkName] = useState("");
  const [quickLinkLoading, setQuickLinkLoading] = useState(false)
  const [quickLinkId, setQuickLinkId]=useState("");
  // live helper chat
  const studentId = user.id;
  const studentCard =
    "https://www.myaie.ac/Library/Student_Registration_Profiles/" +
    studentId +
    "/documents/pdf/" +
    studentId +
    ".pdf";
  const temp_profile_photo = TempProfileImage;
  const hoverUser = useSelector((state) => state.hoverUser);
  const hoverTopic = useSelector((state) => state.hoverTopic);
  const hoverKeyword = useSelector((state) => state.hoverKeyword);
  var containsObject = false;

  for (var i = 0; i < hoverKeyword.length; i++) {
    if (typeof hoverKeyword[i] === "object") {
      containsObject = true;
      break;
   }
  } 
  const [copyText, setCopyText] = useState(0);

  const componentMounted = useRef(true);

  // live helper chat
  const Logout = () => {
    const body = {
      UserID: user.UserID,
      Status: "Offline",
    };
    UpdateUserStatus(body)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Signing out..",
          timer: 3000,
          onOpen: function () {
            Swal.showLoading();
          },
        }).then(function (result) {
          if (result.dismiss === "timer") {
            document
              .querySelectorAll(".lhc-delayed")
              .forEach((e) => e.remove());
            document
              .querySelectorAll(".hide-online-status")
              .forEach((e) => e.remove());

            document
              .querySelectorAll(".online-status-icon")
              .forEach((e) => e.remove());

            var livehelperChat = document.getElementById(
              "lhc_status_container"
            );
            if (livehelperChat) {
              livehelperChat.remove();
            }
            removeUserSession();
            
            dispatch(unsetWidget());
            dispatch(unsetUser());
            dispatch(unsetTab()); //side bar unset tab value to default value 1
            localStorage.setItem("tab", 1);
            localStorage.clear();
            location.push("/");
          }
        });
      })
      .catch(function (err) {
        var livehelperChat = document.getElementById("lhc_status_container");
        if (livehelperChat) {
          livehelperChat.remove();
        }
        removeUserSession();
        dispatch(unsetUser());
        dispatch(unsetTab()); //side bar unset tab value to default value 1
        localStorage.setItem("tab", 1);
        localStorage.clear();
        GetConfigurations()
        location.push("/");
      });

    // LogoutService()
    //   .then((res) => {
    //     Swal.fire({
    //       icon: "success",
    //       title: "Success",
    //       text: "Signing out..",
    //       timer: 3000,
    //       onOpen: function () {
    //         Swal.showLoading();
    //       },
    //     }).then(function (result) {
    //       if (result.dismiss === "timer") {
    //         document.querySelectorAll(".lhc-delayed").forEach((e) => e.remove());
    //         document.querySelectorAll(".hide-online-status").forEach((e) => e.remove());

    //         document.querySelectorAll(".online-status-icon").forEach((e) => e.remove());

    //         var livehelperChat = document.getElementById("lhc_status_container");
    //         if (livehelperChat) {
    //           livehelperChat.remove();
    //         }
    //         removeUserSession();
    //         dispatch(unsetWidget());
    //         dispatch(unsetUser());
    //         dispatch(unsetTab()); //side bar unset tab value to default value 1
    //         localStorage.setItem("tab", 1);
    //         localStorage.clear();
    //         location.push("/");
    //       }
    //     });
    //   })
    //   .catch(function (err) {
    //     var livehelperChat = document.getElementById("lhc_status_container");
    //     if (livehelperChat) {
    //       livehelperChat.remove();
    //     }
    //     removeUserSession();
    //     dispatch(unsetUser());
    //     dispatch(unsetTab()); //side bar unset tab value to default value 1
    //     localStorage.setItem("tab", 1);
    //     localStorage.clear();
    //     location.push("/");
    //   });
  };

  function GetConfigurations(){
    let domain = window.location.hostname;
    if(domain == "localhost"){
      domain = "dev.ami.myaie.ac"
    }
    const parameter = {
      domain : domain
    }
    GetBrandColor(parameter).then((res)=>{
      if(res.status===200){
        const resp = res.data;
        
        dispatch(setBrandVisuals(resp));
        if(Object.keys(this.props.brandVisualsConfig).length){
          document.body.style.setProperty("--topbar-color", resp.topbar_color);
          document.body.style.setProperty("--highlight-color", resp.highlight_color);
          document.body.style.setProperty("--sidebar-color", resp.sidebar_color);
          document.body.style.setProperty("--hover-color", resp.hover_color);
          document.body.style.setProperty("--active-color", resp.active_color);
          document.body.style.setProperty("--login-header-color", resp.login_header_color);
          document.body.style.setProperty("--button-color", resp.button_color);
          document.body.style.setProperty("--button-hover-color", resp.button_hover_color);
          document.body.style.setProperty("--button-active-color", resp.button_active_color);
        }
      }  
    }).catch((err)=>{console.log(err)})

  }
  const GetQuickLink = (id) => {
    setQuickLinkLoading(true)
    GetLinkList()
      .then((res) => {
        setIsResponded(true);
        // let updateQuickLink = res.data.data
        let divider = res.data.data.map((item, index) => {
          if (index===0) {
            return {
              ...item,
              divider: true,
            };
          }
          return item;
        });
        
        setQuicklinks([...StaticQuickLinks, ...divider]);
        setQuickLinkLoading(false)
      })
      .catch((err) => {console.log(err);setQuickLinkLoading(false)});
  };

  const AddQuickLink = (Id) => {
    setIsDisabled(true);
    !linkUrl && !updateQuickLinkUrl ? (requiredField.url = true) : (requiredField.url = false);
    !linkName && !updateQuickLinkName ? (requiredField.name = true) : (requiredField.name = false);
    if (requiredField.name === true || requiredField.url === true) {
      setIsDisabled(false);
      return;
    }
    let regex = new RegExp(URL_REGEX);
    if(updateQuickLinkUrl){
      setIsValid(regex.test(updateQuickLinkUrl));
    }else{
    setIsValid(regex.test(linkUrl));
    }
    if (isValid) {
      Swal.fire({
        title: "Please Wait !",
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      setRequiredField({ url: false });
      setRequiredField({ name: false });
      const data = new FormData();
      if(Id){
      data.append("url", updateQuickLinkUrl);
      data.append("url_name", updateQuickLinkName);
      data.append("id",Id)
      }else{
      data.append("url", linkUrl);
      data.append("url_name", linkName);
      }
      CreateOrUpdateQuickLink(data)
        .then((res) => {
          Swal.close();
          if(Id){
            Swal.fire("Updated!", "Your Link has been Updated.", "success").then(() =>
            $("#quickLinkForm").modal("hide")
          )
          }else{
             Swal.fire("Added!", "Your Link has been Added.", "success").then(() =>
              $("#quickLinkForm").modal("hide")
            );
          }
          
          GetQuickLink();
        })
        .catch((err) => {
          if(Id){
            Swal.fire("Error!", "Your Link has Not been Updated.", "error").then(
              () => $("#quickLinkForm").modal("hide")
            );
          }else {
          Swal.fire("Error!", "Your Link has Not been Added.", "error").then(
            () => $("#quickLinkForm").modal("hide")
          );
          }
        });
    }
    setIsDisabled(false);
  };

  useEffect(() => {
    $(".user-profile li:has(ul)").addClass("li-has-menu");
    $(".user-profile li:has(ul) ul").addClass("sub-menu");
  }, []);

  useEffect(() => {
    // GetQuickLink();
    setInterval(() => {
      if (componentMounted.current) { // is component still mounted?
        setTime(moment(Date().toLocaleString()).format(`dddd, ${TABLE_DATE_FORMAT} | ${TABLE_TIME_FORMAT}:ss`));
      }
    }, 1000);
    return () => { // This code runs when component is unmounted
      componentMounted.current = false; // set it to false when we leave the page
  }
  }, []);

  // useEffect(() => {
  //   $("ul.dropdown-menu").on("click", function (event) {
  //     // The event won't be propagated up to the document NODE and
  //     // therefore delegated events won't be fired
  //     console.log("the stop drop is running");
  //     event.stopPropagation();
  //   });
  // });

  const handleStatus = (e) => {
    setStatus(e.target.value);
    dispatch(setUserStatus(e.target.value));
    const body = {
      UserID: user.UserID,
      Status: e.target.value,
    };
    UpdateUserStatus(body)
      .then((res) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        }).then((result) => {});
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        }).then((result) => {});
      });
  };

  const copyEmailText = (e) => {
    setCopyText(1);
    navigator.clipboard.writeText(user.email);
    setTimeout(() => {
      setCopyText(0);
    }, 1000);
  };

  useEffect(() => {
    if(urlSplit[4] === "gradeSettings" || urlSplit[6] === "gradeSetting" || urlSplit[4] === "assessments" || urlSplit[2] === "gradingTemplate") {
    GradeLetterTemplateListFilters()
      .then((res) => {
        setFilterData({
          ...res.data,
          keywords: res?.data?.keywordFilters,
          topics: res?.data?.topicFilters,
        });
      })
      .catch((err) => console.log(err));
    }
  }, []);

  const wordSpliter = (str) => {
    // console.log(str,"string>>>>>>>>>>>>>>")
   if(typeof str == "string" && !containsObject) {
    const strArr = Array.isArray(JSON.parse(str)) ? JSON.parse(str) : [];
    const newStrArr = strArr.length
      ? strArr?.map((item) => {
          return item ? (
            <p
              className="as-widget mr-1 mt-1"
              style={{ color: "#333333", background: "#e6e6e6" }}
            >
              {item}
            </p>
          ) : (
            "-"
          );
        })
      : "-";
    return newStrArr;
   }
  };

  const wordSpliterTopics = (str) => {
    // console.log(str,"outstringg>>>>>>>>>>>>>>>>>>>>>>>>")
    if(typeof str == "string") {
      let htmlOutput = []
      const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];
      const newStrArr = strArr.length ? strArr?.map(item => {
        filterData.topics.map((val)=>{
          if(val.value == item){
            htmlOutput.push(RenderQuizTopic(val.label,val.color).html)
          }
          return;
        });   
      }) : "-";
      return htmlOutput.length ? htmlOutput : []
    }
  }

  return (
    <>
    <div className="assigned-title-blk01" id="Topictoshow" style={{pointerEvents:'none'}}>
    <div className="assigned-title-info popup-top none-bg">
      <div className="d-flex flex-wrap">
        {wordSpliterTopics(hoverTopic)?.length && typeof hoverTopic == "string" ? wordSpliterTopics(hoverTopic)?.map((item, index) => {
         return <React.Fragment key={index}>{item}</React.Fragment>
         }) : ""}
      </div>
    </div>
    </div>
    <div className="assigned-title-blk01" id="Keywordtoshow" style={{pointerEvents:'none'}}>
    <div className="assigned-title-info popup-top none-bg">
      <div className="d-flex flex-wrap">
      {!containsObject && wordSpliter(hoverKeyword)?.length ? wordSpliter(hoverKeyword)?.map((item, index) => {
         return <React.Fragment key={index}>{item}</React.Fragment>
         }) : ""}
      </div>
    </div>
    </div>
      <div className="assigned-title-blk01" id="divtoshow">
        <div className="assigned-title-info popup-top">
          <article>
            <figure className="bgBlue">
              {hoverUser && hoverUser.photo ? (
                <img
                  src={
                    BASE_URL +
                    "/" +
                    hoverUser.photo.replace("/home/myaie/public_html/", "")
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget
                      .closest(".bgBlue")
                      .append(
                        InitalRender(
                          originalValues(
                            hoverUser?.first_name + " " + hoverUser?.last_name,
                            23
                          )
                        )
                      );
                    currentTarget.remove();
                  }}
                />
              ) : (
                InitalRender(
                  originalValues(
                    hoverUser?.first_name + " " + hoverUser?.last_name
                  )
                )
              )}
              <span
                className={
                  "portal-status-icon " +
                  (hoverUser?.status === "Active" ? "online-u" : "offline-u")
                }
              ></span>
            </figure>
            <figcaption>
              <h4>
                {originalValues(
                  hoverUser?.first_name + " " + hoverUser?.last_name
                )}
              </h4>
              <p>
                <i className="fal fa-envelope"></i>{" "}
                <a
                  href="#!"
                  className="hover-color-blue"
                  onClick={() => copyEmailText(hoverUser?.email)}
                >
                  {hoverUser?.email}
                </a>
                {copyText ? (
                  <i className="fas fa-check-circle cat-greenhex"></i>
                ) : (
                  hoverUser?.email && (
                    <i
                      className="fal fa-copy hover-color-blue"
                      onClick={() => copyEmailText(hoverUser?.email)}
                    ></i>
                  )
                )}
              </p>
            </figcaption>
          </article>
        </div>
      </div>
      <header className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
              <a className="toggle-menu" href="#">
                <i></i>
                <i></i>
                <i></i>
              </a>
              <div className="logo">
                <Link to="/">
                  <img src={brandData.logo} alt="" />
                  <span className="logotext">
                    <strong>{brandData.title}</strong>
                  </span>
                </Link>
              </div>
              <div className="ml-auto header-time d-flex align-items-center mr-2">
                <p className="my-2 mr-3">{time}</p>
                <div className="dropdown">
                  <button
                    className="btn btn-sm btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    onClick={()=>GetQuickLink()}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                   {quickLinkLoading ? <i className="fas fa-cog fa-spin firstIcon m-0"></i> : <i className="fal fa-link firstIcon m-0"></i>}
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li className="dropdown-item dropdown-titile" href="#">
                      <i className="fal fa-link firstIcon mr-2"></i> Quick Links
                      <div className="dorpdown-icon-head">
                        <button
                          className="btn btn-primary rounded-circle"
                          title="Create New"
                          data-toggle="modal"
                          data-target="#quickLinkForm"
                          onClick={() => {
                            setLinkName("");
                            setLinkUrl("");
                            setUpdateQuickLinkName("")
                            setUpdateQuickLinkUrl("")
                          }}
                        >
                          <i className="fal fa-plus"></i>
                        </button>
                      </div>
                    </li>
                    { quickLinkLoading ? <SekletonLearningMaterial/> :
                      <>
                    <div className="dropdown-divider"></div>
                    {quickLinks && quickLinks?.length
                      ? quickLinks?.map((item, index) => {
                          if (item?.status === "static") {
                            return (
                              <React.Fragment key={index}>
                              <PermissionsGate scopes={[item.permission]} key={index}>
                                <li className="dropdown-item hover-li quick-links">
                                  {" "}
                                  <a
                                    title={item.url_name}
                                    href={item.url}
                                    target={"_blank"}
                                  >
                                    {item.url_name}
                                  </a>
                                </li>
                              </PermissionsGate>
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <React.Fragment key={index}>
                                {item.divider && (
                                  <div className="dropdown-divider"></div>
                                )}
                                <li className="dropdown-item hover-li quick-links">
                                  {" "}
                                  <a
                                    title={item.url_name}
                                    href={item.url}
                                    target={"_blank"}
                                  >
                                    {item.url_name}
                                  </a>
                                  <div className="dorpdown-icon-head">
                                    <button
                                      title="Edit"
                                      data-toggle="modal"
                                      data-target="#quickLinkForm"
                                      className="btn btn-sm btn-primary rounded-circle"
                                      onClick={() => {setQuickLinkId(item?.id);setUpdateQuickLinkName(item?.url_name);setUpdateQuickLinkUrl(item.url)}}
                                    >
                                      <i className="fal fa-edit"></i>
                                    </button>
                                    <button
                                      title="Delete"
                                      className="btn btn-sm btn-danger rounded-circle"
                                    >
                                      <i
                                        className="fal fa-trash-alt"
                                        onClick={() => {
                                          Swal.fire({
                                            title: "Are you sure?",
                                            text: "You won't be able to revert this!",
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText:
                                              "Yes, delete it!",
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              Swal.fire({
                                                title: "Please Wait !",
                                                allowOutsideClick: false,
                                                onBeforeOpen: () => {
                                                  Swal.showLoading();
                                                },
                                              });
                                              DeleteLink(item.id)
                                                .then((res) => {
                                                  // setDeleteRow(!deleterow);
                                                  Swal.close();
                                                  Swal.fire(
                                                    "Deleted!",
                                                    "Your Link has been deleted.",
                                                    "success"
                                                  );
                                                  GetQuickLink();
                                                })
                                                .catch((err) => {
                                                  Swal.fire({
                                                    icon: "error",
                                                    title: "Error",
                                                    text: "Not Deleted",
                                                  });
                                                  console.log("error: ", err);
                                                });
                                            }
                                          });
                                        }}
                                      ></i>
                                    </button>
                                  </div>
                                </li>
                                </React.Fragment>
                            );
                          }
                        })
                      : ""}
                      </>
                      }
                  </ul>
                </div>
              </div>
              <div className="nav-right d-flex align-content-center">
                <ul className="list-unstyled d-flex align-items-center m-0 nav-ul">
                  <li title="My Account" className="user-profile">
                    <div className="dropdown">
                      <div className="dropdown-toggle" data-toggle="dropdown">
                        <span className="user-drop-down-img">
                          {/* <img src={user.picture_me == null || user.picture_me == undefined ? temp_profile_photo : user.picture_me.replace("/home/myaie/public_html", "https://myaie.ac")} alt="" /> */}
                          {state ? (
                            <img
                              src={`${IMAGE_URL}/${state.replace(
                                "/home/myaie/public_html/",
                                ""
                              )}`}
                              alt="Your AIE Profile Photo"
                            />
                          ) : (
                            <h1 className="text-infos">{initials}</h1>
                          )}
                          <span className={`portal-status-icon ${status}`}>
                            <i className="fas fa-circle"></i>
                          </span>
                        </span>
                      </div>
                      <form className="dropdown-menu remove-list-style">
                        <ul
                          className="user-profile-dropdown-menu"
                          aria-labelledby="dropdownMenu1"
                        >
                          <li className="profile-container">
                            <div className="login-user-profile d-flex align-items-center">
                              <div className="profile-img-parent">
                                <div className="profile-img-child">
                                  {/* <img src={user.picture_me == null || user.picture_me == undefined ? temp_profile_photo : user.picture_me.replace("/home/myaie/public_html", "https://myaie.ac")} alt="" /> */}
                                  {state ? (
                                    <img
                                      src={`${IMAGE_URL}/${state.replace(
                                        "/home/myaie/public_html/",
                                        ""
                                      )}`}
                                      alt="Your AIE Profile Photo"
                                    />
                                  ) : (
                                    <h1 className="text-infos">{initials}</h1>
                                  )}
                                </div>
                              </div>
                              <div className="profile-name">
                                <div className="profile-name-text">
                                  {originalValues(
                                    user.Firstname + " " + user.Lastname
                                  )}
                                </div>
                                {/* <p className="m-0">#{user.student_crm_id}</p> */}
                              </div>
                            </div>
                          </li>

                          <li>
                            <div className="header-profile-status-blk">
                              <span
                                className={`profile-status-icon online ${status}`}
                              >
                                <i className="fas fa-circle"></i>
                              </span>
                              <select
                                className="form-control select"
                                placeholder="Status"
                                value={status}
                                onChange={handleStatus}
                              >
                                <option value="Online">Online</option>
                                <option value="Offline">Offline</option>
                                <option value="Donotdisturb">
                                  Do Not Disturb
                                </option>
                              </select>
                            </div>
                          </li>
                          <li className="divider"></li>
                          <li>
                            <Link
                              to="/profile"
                              title="Profile"
                              onClick={() => dispatch(selectTab({ tab: 2 }))}
                            >
                              <i className="fal fa-user"></i>Profile
                            </Link>
                          </li>
                          <li className="divider"></li>

                          <li>
                            <a title="Logout" onClick={() => Logout()}>
                              <i className="fal fa-sign-out"></i>Logout
                            </a>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        className="topic-add-modal modal fade"
        id="quickLinkForm"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <form>
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-link"></i> Add Quick Link
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="t p-30px">
                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Link Name</label>
                          <input
                            name="linkName"
                            type="text"
                            className={
                              "form-control" +
                              (requiredField.name ? " is-invalid" : "")
                            }
                            value={
                              updateQuickLinkName
                                ? updateQuickLinkName
                                : linkName
                            }
                            onChange={(e) => {
                              if(updateQuickLinkName){
                                setUpdateQuickLinkName(e.target.value)
                              }else{
                              setLinkName(e.target.value);
                              }
                              requiredField.name = false;
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Link Url</label>
                          <input
                            name="linkUrl"
                            type="url"
                            className={
                              "form-control" +
                              (requiredField.url ? " is-invalid" : "")
                            }
                            value={
                              updateQuickLinkUrl
                                ? updateQuickLinkUrl
                                : linkUrl
                            }
                            onChange={(e) => {
                              if(updateQuickLinkUrl){
                                setUpdateQuickLinkUrl(e.target.value)
                              }else{
                              setLinkUrl(e.target.value);
                              }
                              requiredField.url = false;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" form-group form-group-save-cancel mt-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        
                        if(quickLinkId){
                          AddQuickLink(quickLinkId)
                        }else{
                          AddQuickLink();
                        }
                      }}
                      disabled={isDisabled}
                    >
                      {isDisabled ? (
                        <i className="fas fa-cog fa-spin"></i>
                      ) : (
                        <i className="fal fa-save"></i>
                      )}
                      Save
                    </button>

                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                    >
                      <i className="fal fa-times"></i>
                      Close
                    </button>
                    {requiredField.name && !updateQuickLinkName ? (
                      <div className="invalid-feedback d-block">
                        Name is Required
                      </div>
                    ) : (
                      ""
                    )}
                    {requiredField.url && !updateQuickLinkUrl ? (
                      <div className="invalid-feedback d-block">
                        URL is Required
                      </div>
                    ) : (
                      ""
                    )}
                    {!isValid ? (
                      <div className="invalid-feedback d-block">
                        Please Enter Valid URL
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(Header);
