import React from "react";
import { useHistory, useParams } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import Account from "./StudentsTabs/Account";
import Appeals from "./StudentsTabs/Appeals";
import AuditTrail from "./StudentsTabs/AuditTrail";
import Certificates from "./StudentsTabs/Certificates";
import ClassAttendance from "./StudentsTabs/ClassAttendance";
import CoursesAndProgramme from "./StudentsTabs/CoursesAndProgramme";
import Education from "./StudentsTabs/Education";
import General from "./StudentsTabs/General";
import Licenses from "./StudentsTabs/Licenses";
import Notes from "./StudentsTabs/Notes";
import Notifications from "./StudentsTabs/Notifications";
import Personal from "./StudentsTabs/Personal";
import StudentAssessment from "./StudentsTabs/StudentAssessment";
import SubjectView from "./StudentsTabs/SubjectView";
import Tickets from "./StudentsTabs/Tickets";
import StudentGradeBook from "./StudentsTabs/GeneralTabs/StudentGradeBook";

function StudentTabs({ studentName, studentData }) {
  const history = useHistory();
  const { tab, id } = useParams();
  const bottomScroll = React.createRef();
  return (
    <div>
      <div className="tabs-wrap">
        <div className="scroll-tabs filter-scroll std-scroll-arrow">
          <button type="button" className="btn btn-arrow btn-scroll-left" onClick={() => (bottomScroll.current.scrollLeft = bottomScroll.current.scrollLeft - 100)}>
            <i className="fal fa-chevron-left"></i>
          </button>
          <div className={`filter-scroll-inner `} ref={bottomScroll}>
            <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
              <PermissionsGate scopes={["sgiview"]}>
                <li className="nav-item ml-1" onClick={() => history.push(`/studentAdministration/students/open/${id}/general`)}>
                  <a className={`nav-link cursor-pointer ${tab === "general" ? "active" : ""}`} >
                    <i className="fal fa-chart-pie-alt"></i>
                    General
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["spiview", 'spcview']}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/personal`)}>
                  <a className={`nav-link cursor-pointer ${tab === "personal" ? "active" : ""}`} >
                    <i className="fal fa-books"></i>Personal
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["sedview"]}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/education`)}>
                  <a className={`nav-link cursor-pointer ${tab === "education" ? "active" : ""}`} >
                    <i className="fal fa-file-alt"></i>Education
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["sapiview"]}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/account`)}>
                  <a className={`nav-link cursor-pointer ${tab === "account" ? "active" : ""}`} >
                    <i className="fas fa-bars"></i>Account Info
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["scpview"]}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/coursesAndProgramme`)}>
                  <a className={`nav-link cursor-pointer ${tab === "coursesAndProgramme" ? "active" : ""}`}>
                    <i className="fal fa-laptop"></i>Courses &amp; Programmes
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["sasview"]}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/assessments`)}>
                  <a className={`nav-link cursor-pointer ${tab === "assessments" ? "active" : ""}`} >
                    <i className="fal fa-file-alt"></i>Assessments
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["sasview"]}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/gradebook`)}>
                  <a className={`nav-link cursor-pointer ${tab === "gradebook" ? "active" : ""}`} >
                    <i className="fal fa-bookmark"></i>Gradebook
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["snview"]}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/notifications`)}>
                  <a className={`nav-link cursor-pointer ${tab === "notifications" ? "active" : ""}`} >
                    <i className="fal fa-bell"></i>Notifications
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["scaview"]}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/classAttendance`)}>
                  <a className={`nav-link cursor-pointer ${tab === "classAttendance" || tab === "subjectView" ? "active" : ""}`}>
                    <i className="fal fa-laptop"></i>Attendance
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["slview"]}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/licenses`)}>
                  <a className={`nav-link cursor-pointer ${tab === "licenses" ? "active" : ""}`} >
                    <i className="fal fa-ticket"></i>Licenses
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["scview"]}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/certificates`)}>
                  <a className={`nav-link cursor-pointer ${tab === "certificates" ? "active" : ""}`} >
                    <i className="fal fa-file-alt"></i>Certificates
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["saview"]}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/appeals`)}>
                  <a className={`nav-link cursor-pointer ${tab === "appeals" ? "active" : ""}`} >
                    <i className="fal fa-books"></i>Appeals
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["stview"]}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/tickets`)}>
                  <a className={`nav-link cursor-pointer ${tab === "tickets" ? "active" : ""}`} >
                    <i className="fal fa-ticket"></i>Tickets
                  </a>
                </li>
              </PermissionsGate>
              <PermissionsGate scopes={["snoview"]}>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/notes`)}>
                  <a className={`nav-link cursor-pointer ${tab === "notes" ? "active" : ""}`} >
                    <i className="fas fa-bars"></i>Notes
                  </a>
                </li>
              </PermissionsGate>
                <li className="nav-item" onClick={() => history.push(`/studentAdministration/students/open/${id}/auditTrail/employee`)}>
                  <a className={`nav-link cursor-pointer ${tab === "auditTrail" ? "active" : ""}`} >
                    <i className="fal fa-history"></i>Audit Trail
                  </a>
                </li>
            </ul>
          </div>
          <button type="button" className="btn btn-arrow btn-scroll-right" onClick={() => (bottomScroll.current.scrollLeft = bottomScroll.current.scrollLeft + 100)}>
            <i className="fal fa-chevron-right"></i>
          </button>
        </div>

        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade active show">
            {tab === "general" && <General studentDetailData={studentData} />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "personal" && <Personal />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "education" && <Education />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "account" && <Account />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "coursesAndProgramme" && <CoursesAndProgramme studentData={studentData} />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "assessments" && <StudentAssessment />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "gradebook" && <StudentGradeBook studentDetails={studentData}/>}
          </div>
          <div className="tab-pane fade active show">
            {tab === "notifications" && <Notifications studentName={studentName} />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "classAttendance" && <ClassAttendance />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "subjectView" && <SubjectView />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "licenses" && <Licenses />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "certificates" && <Certificates />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "appeals" && <Appeals />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "tickets" && <Tickets studentName={studentName} />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "notes" && <Notes />}
          </div>
          <div className="tab-pane fade active show">
            {tab === "auditTrail" && <AuditTrail />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentTabs;
