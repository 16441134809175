import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import PermissionsGate from "../../../../utils/permissionGate";
import { DeleteAssessmentSubmissionUpload, GetAssessmentSubmissionUpload, UpdateSubmission } from "../../../../services/CourseService";
import { IMAGE_URL, downloadURL } from "../../../../utils/Constants";
import JSZip from "jszip";
import axios from "axios";
import { saveAs } from 'file-saver';
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";

function SubmissionAndResultForm() {
  const { id, subSubId, subId } = useParams();
  const [statusSelected, setStatusSelected] = useState();
  const [statusArr, setStatusArr] = useState([]);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [disabled, setDisabled] = useState(false);
  const [isQuiz, setIsQuiz] = useState(false);
  const [submissionFiles, setSubmissionFiles] = useState([]);
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["casubmissionresultview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  useEffect(() => {
    const data = new FormData();
    data.append("user_id", subSubId);
    data.append("assessment_id", subId);
    data.append("submission_id", subSubId);
    GetAssessmentSubmissionUpload(data)
      .then((res) => {
        let tempStatusArr = [];
        setFile(res.data.document_url);
        setSubmissionFiles(res?.data?.submissionFiles);
        setIsQuiz(res.data?.assignments?.quiz_id);
        setAssessmentData(res.data?.assignments);
        setFileName(res.data.document_url && res.data.document_url.replaceAll("public/", "").replaceAll("home/myaie/public_html/", ""));
        // formik.setFieldValue("File", res.data.document_url && res.data.document_url.replaceAll("public/", "").replaceAll("home/myaie/public_html/", ""));
        formik.setFieldValue("Status", res.data.status);
        setStatusSelected(res.data.status);
        let sttArr = res?.data?.status_arr;
        Object.keys(sttArr).map((key, index) => {
          tempStatusArr.push({ value: key, label: sttArr[key], key: key });
        });
        setStatusArr(tempStatusArr);
      })
      .catch((error) => {
        console.log("error getAssessment: ", error);
      });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Status: "",
      File: "",
      isFileDeleted:0
    },
    validationSchema: Yup.object({
      Status: Yup.string().required("Status is required"),
      File: Yup.mixed().when("Status", {
        is: (value) => {
          if (value == "open" || isQuiz) {
            return false
          } else {
            if(submissionFiles.length==0){
              return true
            }else{
              return false
            }
          }
        },
        then: Yup.mixed().required("File is required")
      }),
    }),
    onSubmit: (values, { resetForm }) => {
      setDisabled(true)
      let formData = new FormData();
      formData.set("submission_id", subSubId);
      formData.set("document_url", file ? file : "");
      formData.set("status", values.Status);
      formData.set("isFileDeleted", values.isFileDeleted);
      UpdateSubmission(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
          setDisabled(false)
          history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}`);
        })
        .catch((error) => {
          console.log("error updateSubmission :", error);
          setDisabled(false)
        });
    },
  });

  const handleDeleteAllFile = () =>{
    setSubmissionFiles([]);
    formik.setFieldValue("isFileDeleted",1);    
  }

  const handleDownloadSubmissionFiles = () =>{
    var zip = new JSZip();
    var zipVal = {};
    const data = [...submissionFiles];
    if(Array.isArray(data) && data.length){
      setLoading(true);
      let myPromise = Promise.all(data.map(async (row,index) =>{
        if(row.path && row.path != null && row.path != ""){
          const fileExt = row.path.split("/").reverse()[0];
          const fileURL = downloadURL+`${row.path.includes("s3.af-south-1.amazonaws.com") ? "" : IMAGE_URL.replaceAll("http:","https:")+"/"}${encodeURI(row.path.replaceAll("/home/myaie/public_html/","").replace("public/",""))}`;
          try{
            const resp = await axios({
              url: fileURL,
              method: 'get',
              responseType: 'blob'              
            });
         
            zip.file(`${fileExt}`, resp.data, { binary: true })
          }catch(err){
            console.log(err);
          }
        }
        zipVal = zip;
      }));
      myPromise.then(()=>{
        zipVal.generateAsync({ type: "blob" })
          .then(function (content) {     
            setTimeout(()=>{
              setLoading(false);
            },1000);                        
            saveAs(content, `${assessmentData.name}_submission_files_` + ".zip");
          }).catch((errr) => { console.log(errr) });
      })
    }
  }

  const handleDeleteFile = () => {
    setFile("")
    setFileName("")
    formik.setFieldValue("File", "");
  };
  return (
    <div>
      <div className="card card-body-inr">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <label htmlFor="Status">Status *</label>
              <div className="">
                <div className="form-icon-group mb-4 ">
                  <Select
                    className={"form-control custom-select-box" + (formik.errors.Status && formik.touched.Status ? " is-invalid" : "")}
                    name="Status"
                    value={statusArr.filter(function (option) {
                      return option.value == formik.values.Status;
                    })}
                    onChange={(value) => {
                      if (value) {
                        formik.setFieldValue("Status", value.value);
                      } else {
                        formik.setFieldValue("Status", "");
                      }
                    }}
                    onBlur={formik.handleBlur}
                    options={statusArr}
                    maxMenuHeight={175}
                    placeholder={formik.values.Status ? formik.values.Status : "Select"}
                    isClearable
                  />
                </div>
              </div>
            </div>
            {!isQuiz ? <div className="col-md-6 col-lg-4">
              <label htmlFor="UploadFile">Upload New File {formik.values.Status == "open" ? "" : '*'}</label>
              <div className="form-group atttach-file">
                <label className={formik.errors.File && formik.touched.File && "file-req is-invalid"}>
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <input
                    type="file"
                    id="UploadFile"
                    className="form-control form-control-aatch-file"
                    name="File"
                    onChange={(e) => {
                      formik.setFieldValue("File", e.target.files[0])
                      setFile(e.target.files[0]);
                      setFileName(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                </label>
              </div>
              
              <div className="frm-group">
                <ul className="list-unstyled attached-file-ul m-0 submission-file">
                  {submissionFiles.length>0 ?
                    <li>
                      <p className="as-text-blue curser m-0" onClick={()=>{handleDownloadSubmissionFiles()}}>
                        {loading ? <i className="fal fa-cog fa-spin"></i> : <i className="fal fa-file"></i>}
                        &nbsp;Download submission
                      </p>
                      <button className="btn btn-danger position-static transform-none ml-2"  onClick={()=>{handleDeleteAllFile()}} type="button">
                        <i className="fal fa-trash-alt"></i>
                      </button>
                    </li>
                  :null}
                  {fileName?.includes("blob") && (
                    <li>
                      <a className="as-text-blue curser" href={fileName.includes("blob") ? fileName : `${downloadURL}${IMAGE_URL}/${fileName}`} target={"_blank"}>
                        <i className="fal fa-file"></i>
                        &nbsp;{fileName.includes("blob") ? file.name : fileName.split("/").reverse()[0]}
                      </a>
                      <button className="btn btn-danger position-static transform-none"  onClick={handleDeleteFile} type="button">
                        <i className="fal fa-trash-alt"></i>
                      </button>
                    </li>                
                  )}
                </ul>
              </div>
                            
            </div> : ""}
          </div>
          <div className="form-group form-group-save-cancel">
            <PermissionsGate scopes={["cedit"]} errorProps={{ disabled: true }}>
              <button className="btn btn-save btn-success" type="submit" title="Save">
                {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Save
              </button>
            </PermissionsGate>
            <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={()=> history.goBack()}>
              <i className="fal fa-times"></i>
              Cancel
            </button>
            {formik.touched.File && formik.errors.File ? <div className="invalid-feedback d-block">{formik.errors.File}</div> : null}
            {formik.touched.Status && formik.errors.Status ? <div className="invalid-feedback d-block">{formik.errors.Status}</div> : null}
          </div>
        </form>
      </div>
    </div>
  );
}

export default SubmissionAndResultForm;
