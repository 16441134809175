import React, { useState, useEffect } from "react";
import {  useFormik } from "formik";
import * as Yup from "yup";
import Header from "../common/Header";
import SideBar from "../common/SideBar";
import Select from "react-select";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import {
  AddCourse,
  GetCommonContent,
  GetCourseDetails,
  GetCrmProductListCancelToken,
  GetListCancelToken,
  GetNetsuiteProductListCancelToken,
} from "../../services/CourseService";
import { useHistory, useParams } from "react-router-dom";
import HtmlInputEditor from "../common/HtmlInputEditor";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";
import { CheckJSONString } from "../common/CheckJSONString";
import draftToHtml from "draftjs-to-html";
import { GlobalIconNames } from "../../utils/GlobalIconNames";
import { AllBrandListCancelToken, GetAllDropdownValuesCancelToken, GetQualificationOptionsCancelToken, GetSchoolListCancelToken } from "../../services/RegistrationService";
import { FORM_DATE_FORMAT, IMAGE_URL } from "../../utils/Constants";
import { TrimText } from "../common/TrimText";
import axios from "axios";

const AddNewCourse = () => {
  let history = useHistory();
  const [iconLoading, setIconLoading] = useState(true);
  const [courseDetails, setCourseDetails] = useState({});
  const [courseDataDetails, setCourseDataDetails] = useState({});
  const [correspondence, setCorrespondence] = useState([]);
  const { id, type } = useParams();
  const [crmList, setCrmList] = useState([]);
  const [netsuiteList, setNetsuiteList] = useState([]);
  const [openDate, setOpenDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = React.useState("");
  const [editorState2, setEditorState2] = React.useState("");
  const [studyTypeDropdown, setStudyTypeDropdown] = useState([])
  const [intakeArr, setIntakeArr] = useState([]);
  const [timeTableDoc, setTimeTableDoc] = useState();
  const [timeTableDocName, setTimeTableDocName] = useState("");
  const [campusArr, setCampusArr] = useState([]);
  const [studyTypeDropdownAcademic, setStudyTypeDropdownAcademic] = useState([])
  const [schoolId, setSchoolId] = useState("");
  const [allBrands, setAllBrands] = useState([]);
  const [schoolList, setSchoolList] = useState([]);

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        const data = await GetAllDropdownValuesCancelToken(source.token)
        if (data.status === 200) {
          setStudyTypeDropdown(data.data.study_type);
          setStudyTypeDropdownAcademic(data.data.study_type_2)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error :", error)
        }
      }
    }

    getData()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [])

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        const res = await GetSchoolListCancelToken(source.token)
        if (res.status == 200) {
          setSchoolList(res.data.data)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error :", error)
        }
      }
    }

    getData()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [])

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        if (schoolId) {
          const res = await GetQualificationOptionsCancelToken(schoolId, source.token)
          if (res.status == 200) {
            setNetsuiteList(res && res.data && res.data.data && res.data.data.items ? res.data.data.items : []);
          }
        } else {
          const data = await GetNetsuiteProductListCancelToken("", source.token)
          if (data.status === 200) {
            setNetsuiteList(
              data && data.data && data.data.data && data.data.data.items
                ? data.data.data.items
                : []
            );
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error :", error)
        }
      }
    }

    getData()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [schoolId])

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        const response = await AllBrandListCancelToken(source.token)
        if (response.status === 200) {
          let brands = [];
          let res = response.data?.allBrandList
          res.map((data) => {
            brands.push({ value: data.id, label: data.brand_name })
          })
          setAllBrands(brands)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error :", error)
        }
      }
    }

    getData()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        const data = await GetCrmProductListCancelToken(source.token)
        setCrmList(
          data &&
            data.data &&
            data.data.crmProducts &&
            data.data.crmProducts.results
            ? data.data.crmProducts.results
            : []
        );
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error :", error)
        }
      }
    }

    getData()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        const data = await GetListCancelToken(source.token)
        if (data.status === 200) {
          let tempIntakeArr = []
          data.data.intake_yearArr.map(year => {
            Object.keys(data.data.intakeArr).map(number => {
              tempIntakeArr.push({ value: `${year.year}/${number}`, label: `${year.year}-${number}` })
            })
          })
          setIntakeArr(tempIntakeArr);
          setCourseDetails(data && data.data ? data.data : {});
          GetCommonContent()
            .then((res) => {
              setCampusArr(res.data.program_campus);
            }).catch((err) => console.log("error: ", err));
          let tempCorrespondence = [];
          const correspondenceArr = data.data?.study_typeArr;
          Object.keys(correspondenceArr).sort().map((key, index) => {
            tempCorrespondence.push({
              value: key,
              label: correspondenceArr[key],
              key: key,
            });
          });
          setCorrespondence(tempCorrespondence);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error :", error)
        }
      }
    }

    getData()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {
      setIconLoading(true)
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        const data = await GetCourseDetails(id)
        if (data.status === 200) {
          let tempCorrespondence = [];
          const correspondenceArr = data.data?.study_typeArr;
          // console.log("data ====> ", data);
          Object.keys(correspondenceArr).sort().map((key, index) => {
            tempCorrespondence.push({
              value: key,
              label: correspondenceArr[key],
              key: key,
            });
          });
          setCorrespondence(tempCorrespondence);
          setCourseDataDetails(data && data.data ? data.data : {});

          // const blocksFromHTML = convertFromRaw(
          //   JSON.parse(data.data.general_note_info)
          // );
          // // const statethis = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
          // setEditorState(EditorState.createWithContent(blocksFromHTML));
          let generalNote = CheckJSONString(data.data.general_note_info);
          if (generalNote) {
            const blocksFromHTML2 = convertFromRaw(
              JSON.parse(data.data.general_note_info)
            );
            let dataDescr = EditorState.createWithContent(blocksFromHTML2);
            const note = draftToHtml(convertToRaw(dataDescr.getCurrentContent()));
            setEditorState(note ? note : "<p><p/>");
          } else {
            setEditorState(data.data.general_note_info);
          }

          let certificate = CheckJSONString(data.data.certificate);
          if (certificate) {
            const blocksFromHTML2 = convertFromRaw(
              JSON.parse(data.data.certificate)
            );
            let dataDescr = EditorState.createWithContent(blocksFromHTML2);
            const cert = draftToHtml(convertToRaw(dataDescr.getCurrentContent()));
            setEditorState2(cert ? cert : "<p><p/>");
          } else {
            setEditorState2(data.data.certificate);
          }
          // const blocksFromHTML2 = convertFromRaw(
          //   JSON.parse(data.data?.certificate)
          // );
          // // const statethis2 = ContentState.createFromBlockArray(blocksFromHTML2?.contentBlocks, blocksFromHTML2?.entityMap);
          // setEditorState2(EditorState.createWithContent(blocksFromHTML2));
          setIconLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error :", error)
          setIconLoading(false);
        }
      }
    }

    type == "duplicate" && getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  const getKeyOfObject = (obj, num) => {
    let value = "";
    // console.log("num ---> ", num);
    Object.keys(obj).map((key, index) => {
      // console.log("key === > ", obj[key])
      if (num.toLowerCase() == obj[key].toLowerCase()) value = key;
    });
    return value;
  };

  function getDefaultName(arr, value, type = "") {
    let name = "";
    if (type == "netsuite") {
      arr.map((ele) => {
        if (ele.id.toString() === value.toString()) name = ele.displayname;
      });
    } else {
      arr.map((ele) => {
        if (ele.UserID == value) name = ele.name;
      });
    }
    return name;
  }

  function removeSpace(name) {
    return name.replace(/ /g, "");
  }

  function addDays(date, days) {
    var date = new Date(date);
    date.setDate(date.getDate() + days);
    return date;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: courseDataDetails.subject_name
        ? courseDataDetails.subject_name
        : "",
      courseType: courseDataDetails.subject_type
        ? courseDataDetails.subject_type
        : "",
      courseTypeName: courseDataDetails.subject_type
        ? removeSpace(courseDataDetails.subject_type.toLowerCase())
        : "",
      studyType: courseDataDetails.study_type
        ? courseDataDetails.study_type
        : "",
      studyTypeName: courseDataDetails.study_type
        ? getKeyOfObject(
            courseDataDetails.study_typeArr,
            courseDataDetails.study_type
          )
        : "",
      dhetCheckbox: courseDataDetails.DHET_exam_subject == 1 ? true : false,
      correspondence: courseDataDetails.correspondence
        ? courseDataDetails.correspondence
        : "",
      correspondenceName: courseDataDetails.correspondence
        ? courseDataDetails.correspondence
        : "",
      subjectCode: courseDataDetails.subject_code
        ? courseDataDetails.subject_code
        : "",
      intakeNumber: courseDataDetails.selected_intake
        ? getKeyOfObject(
            courseDataDetails.intakeArr,
            courseDataDetails.selected_intake
          )
        : "",
      intakeYear: courseDataDetails.selected_intake_year && courseDataDetails.selected_intake
        ? `${courseDataDetails.selected_intake}/${courseDataDetails.selected_intake_year} `
        : "",
      intake: courseDataDetails.selected_intake_year
        ? courseDataDetails.selected_intake_year
        : "",
      startYear: courseDataDetails.startDate_year
        ? courseDataDetails.startDate_year
        : "",
      startMonth: courseDataDetails.startDate_month
        ? courseDataDetails.startDate_month
        : "",
      startDay: courseDataDetails.startDate_date
        ? courseDataDetails.startDate_date
        : "",
      endYear: courseDataDetails.endDate_year
        ? courseDataDetails.endDate_year
        : "",
      endMonth: courseDataDetails.endDate_month
        ? courseDataDetails.endDate_month
        : "",
      endDay: courseDataDetails.endDate_date
        ? courseDataDetails.endDate_date
        : "",
      lecturer: courseDataDetails.lecturer_id
        ? getDefaultName(
            courseDataDetails.lecturer_coach,
            courseDataDetails.lecturer_id
          )
        : "",
      lecturerId: courseDataDetails.lecturer_id
        ? courseDataDetails.lecturer_id
        : "",
      programCoordinator: courseDataDetails.coach_id
        ? getDefaultName(
            courseDataDetails.lecturer_coach,
            courseDataDetails.coach_id
          )
        : "",
      programCoordinatorId: courseDataDetails.coach_id
        ? courseDataDetails.coach_id
        : "",
      programSupport: courseDataDetails.support_id
        ? getDefaultName(
            courseDataDetails.lecturer_coach,
            courseDataDetails.support_id
          )
        : "",
      programSupportId: courseDataDetails.support_id
        ? courseDataDetails.support_id
        : "",
      enableChat: courseDataDetails.enable_chat == 1 ? true : false,
      autoEmail:
        courseDataDetails.notify_email == 0 ||
        courseDataDetails.notify_email == "" ||
        !courseDataDetails.notify_email
          ? false
          : true,
      crmProduct: "",
      crmProductId: "",
      certificateTemplate: "",
      startDate: courseDataDetails.intakeStart
        ? new Date(moment.unix(courseDataDetails.intakeStart))
        : "",
      endDate: courseDataDetails.intakeEnd
        ? new Date(moment.unix(courseDataDetails.intakeEnd))
        : "",
      netsuiteProductId: courseDataDetails.netsuiteProductId
        ? courseDataDetails.netsuiteProductId
        : "",
      netsuiteProductName: courseDataDetails.netsuiteProductId
        ? getDefaultName(
            netsuiteList,
            courseDataDetails.netsuiteProductId,
            "netsuite"
          )
        : "",
      publish_date: courseDataDetails.publish
        ? courseDataDetails.publish.toString().length == 10
          ? new Date(moment.unix(courseDataDetails.publish))
          : courseDataDetails.publish.toString().length > 12
          ? courseDataDetails.publish.toString().length > 15
            ? new Date(moment.unix(courseDataDetails.publish / 1000000))
            : new Date(moment.unix(courseDataDetails.publish / 1000))
          : new Date(moment.unix(courseDataDetails.publish))
        : "",
      overview: "",
      TimeTable:(courseDetails && courseDetails?.intakeTimeTable) ? courseDetails?.intakeTimeTable : "",
      school : courseDataDetails.school ? courseDataDetails.school: "",
      brand : courseDataDetails.brand ? courseDataDetails.brand: "",
      duration: courseDataDetails.subject_duration
        ? courseDataDetails.subject_duration
        : "",
      Campus: courseDataDetails && courseDataDetails.campus ? courseDataDetails.campus : "",
      mentoredCheckbox: courseDetails.mentored_check_box == 1 ? true : false,
      startRecommendedDate: courseDataDetails.start_recommended_date
        ? new Date(moment.unix(courseDataDetails.start_recommended_date))
        : "",
      endRecommendedDate: courseDataDetails.end_recommended_date
        ? new Date(moment.unix(courseDataDetails.end_recommended_date))
        : "",
      hideLearningMaterial : courseDataDetails?.hide_learning_material && courseDataDetails?.hide_learning_material==1 ? true : false,
      hideAssessments : courseDataDetails?.hide_assessments && courseDataDetails?.hide_assessments==1 ? true : false,
      hideDates : courseDataDetails?.hide_dates && courseDataDetails?.hide_dates==1 ? true : false
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required").nullable().trim(),
      correspondence: Yup.string().required("Should select Learning Method"),
      courseType: Yup.string().required("Should select Course Type"),
      intake: Yup.string().when(['courseType'], {
        is : (courseType) =>(courseType=== "mentored" || courseType=== "scheduled"),
        then : Yup.string().required("Intake is required")
      }),
      startDate: Yup.string().when(['correspondence'], {
        is : (correspondence) =>(correspondence!== "selfplaced"),
        then : Yup.string().required("Start Date is required.")
      }),
      endDate: Yup.string().when(['correspondence', 'courseType'], {
        is : (correspondence, courseType) =>(correspondence!== "selfplaced" && courseType!=='shortcourse'),
        then : Yup.string().required("End Date is required.")
      }),
      startRecommendedDate: Yup.string().when(['mentoredCheckbox'], {
        is : (mentoredCheckbox) =>(mentoredCheckbox),
        then : Yup.string().required("Recommended Start Date is required.")
      }),
      endRecommendedDate: Yup.string().when(['mentoredCheckbox'], {
        is : (mentoredCheckbox) =>(mentoredCheckbox),
        then : Yup.string().required("Recommended End Date is required.")
      }),
      duration: Yup.string().when(['correspondence'], {
        is : (correspondence) =>(correspondence=== "selfplaced"),
        then : Yup.string().required("Duration is required.")
      }),
      Campus: Yup.string().when(['correspondence', 'courseType'], {
        is : (correspondence, courseType) =>((correspondence=== "full" || correspondence=== "fulltimeonline") && courseType !== 'shortcourse'),
        then : Yup.string().required("Campus is required.")
      }),  
      school: Yup.string().when(['courseType'], {
        is : (courseType) =>(courseType=== "shortcourse"),
        then : Yup.string().required("School is required.")
      }),  
      brand: Yup.string().when(['courseType'], {
        is : (courseType) =>(courseType=== "shortcourse"),
        then : Yup.string().required("Brand is required.")
      })
    }),
    onSubmit: (values) => {
      // return;
      let startDate = values.startDate;
      let endDate = values.endDate;
      let publish = values.publish_date;
      let endRecommendedDate = values.endRecommendedDate
      let startRecommendedDate = values.startRecommendedDate

      startDate = startDate ? new Date(startDate).getTime().toString() / 1000 : "";
      // startDate=Date.parse(startDate)/1000;
      endDate = endDate ? new Date(endDate).getTime().toString() / 1000 : "";
      endRecommendedDate = endRecommendedDate ? new Date(endRecommendedDate).getTime().toString() / 1000 : "";
      startRecommendedDate = startRecommendedDate ? new Date(startRecommendedDate).getTime().toString() / 1000 : "";
      // if(publish==""){
      //   publish = new Date(startDate).getTime().toString()/1000;
      // }
      publish = new Date(publish).getTime().toString() / 1000;

      if (startDate > endDate) {
        formik.setErrors({
          startDate: "Start date should be less than end date",
        });
        return;
      }

      setLoading(true);

      // const overViewValue = JSON.stringify(
      //   convertToRaw(editorState.getCurrentContent())
      // );
      // const certificateValue = JSON.stringify(
      //   convertToRaw(editorState2.getCurrentContent())
      // );
      let formData = new FormData();
      formData.append("start_date", startDate);
      formData.append("end_date", endDate ? endDate : "");
      formData.append("lecturer_id", values.lecturerId ? values.lecturerId : "");
      formData.append("coach_id", values.programCoordinatorId ? values.programCoordinatorId : "");
      formData.append("support_id", values.programSupportId ? values.programSupportId : "");
      formData.append("subject_name", values.name);
      formData.append("subject_type", (values.courseType==="mentored" && values.mentoredCheckbox == false) ? "scheduled" : values.courseType);
      formData.append("year", values?.intake ? values?.intake.split("/")[0] : "");
      formData.append("number", values?.intake ? values.intake.split("/")[1] : "");
      formData.append("study_type", values.studyTypeName);
      formData.append("correspondence", values.correspondence);
      // formData.append("support_email", values?.EmailDomain);
      formData.append("DHET_exam_subject", values.dhetCheckbox == true ? 1 : 0);
      formData.append("notify_email", values.autoEmail == true ? 1 : 0);
      formData.append("enable_chat", values.enableChat == true ? 1 : 0);
      formData.append("general_note_info", editorState);
      formData.append("crm_product_id", values.crmProductId);
      formData.append("certificate", editorState2);
      formData.append("netsuiteProductId", values.netsuiteProductId ? values.netsuiteProductId: "");
      formData.append("publish", publish ? publish : startDate);
      formData.append("timeTable_attachment", values.TimeTable);
      formData.append("subject_code", values.subjectCode);
      formData.append("end_recommended_date", endRecommendedDate );
      formData.append("start_recommended_date", startRecommendedDate );
      formData.append("mentored_check_box", values.mentoredCheckbox == true ? 1 : 0);
      formData.append("subject_duration", values.duration);
      formData.append("campus", values?.Campus);
      formData.append("school", values.school);
      formData.append("brand", values.brand);
      formData.append("hide_learning_material", values.hideLearningMaterial == true ? 1 : 0);
      formData.append("hide_assessments", values.hideAssessments == true ? 1 : 0);
      formData.append("hide_dates", values.hideDates == true ? 1 : 0);
      // return;
      AddCourse(formData)
        .then((data) => {
          setLoading(false);
          Swal.fire("Added!", "Your record has been Created.", "success");
          history.push("/courseAdministration/courses/In Progress");
        })
        .catch((error) => {
          setLoading(false);
          console.log("error ---->", error);
        });
    },
  });

  const modifiyOptionsForSelect = (arr, type = "") => {
    let modifiedArr = [];
    if (
      type == "courseType" ||
      type == "studyType" ||
      type == "correspondence"
    ) {
      Object.keys(arr).map((key, index) => {
        modifiedArr.push({ value: arr[key], label: arr[key], key: key });
      });
    } else if (
      type == "lecturer" ||
      type == "programCoordinator" ||
      type == "programSupport"
    ) {
      arr.map((ele) => {
        modifiedArr.push({ value: ele.name, label: ele.name, id: ele.UserID });
      });
    } else if (type == "intakeYear") {
      arr.map((ele) => {
        modifiedArr.push({ value: ele.year, label: ele.year });
      });
      modifiedArr.sort(function (a, b) {
        return b.value - a.value;
      });
    } else if (type == "intakeNumber") {
      Object.keys(arr).map((key, index) => {
        modifiedArr.push({ value: key, label: key });
      });
    } else if (type == "crm") {
      arr.map((elem) => {
        modifiedArr.push({
          value: elem[1] + " (" + elem[4] + ") " + elem[2],
          label: elem[1] + " (" + elem[4] + ") " + elem[2],
          id: elem[0],
        });
      });
    } else if (type === "netsuite") {
      arr.map((elem) => {
        modifiedArr.push({ value: elem.id, label: elem.displayname, departmentId: elem.department });
      });
    }

    return modifiedArr;
  };

  // const monthInNumber = (name) => {
  //   let number = 0;
  //   switch (name) {

  //     case "January":
  //       number = 1;
  //       break;

  //     case "February":
  //       number = 2;
  //       break;

  //     case "March":
  //       number = 3;
  //       break;

  //     case "April":
  //       number = 4;
  //       break;

  //     case "May":
  //       number = 5;
  //       break;

  //     case "June":
  //       number = 6;
  //       break;

  //     case "July":
  //       number = 7;
  //       break;

  //     case "August":
  //       number = 8;
  //       break;

  //     case "September":
  //       number = 9;
  //       break;

  //     case "October":
  //       number = 10;
  //       break;

  //     case "November":
  //       number = 11;
  //       break;

  //     case "December":
  //       number = 12;
  //       break;

  //     default:
  //       number = name;
  //       break;
  //   }
  //   return number;
  // }
  const handleTimeTable=()=>{
    formik.setFieldValue("TimeTable", "");
    setTimeTableDoc("");
    setTimeTableDocName("");
  }

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right tabs-wrap">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        Add <span>Course</span>
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button
                            onClick={() => history.goBack()}
                            title="Back"
                            className="btn btn-white-bordered"
                          >
                            {GlobalIconNames("leftarrow")}Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-9 col-12">
                      <div className="card card-profile-info-card course-details-tab-sec">                    
                        <div className="course-info-sec">
                          <div className="edit-icon new-card-header">
                            <div className="card-header">
                              Course Information
                              {iconLoading && type == "duplicate" && (
                                <i className="fas fa-cog fa-spin ml-1"></i>
                              )}
                            </div>
                          </div>
                          <div className="card-body-inr card-body-info">
                            <div className="row">
                              <div className="col-md-6 col-lg-3">
                                <div className="form-icon-group mb-4">
                                  <label>Name *</label>
                                  <input
                                    type="text"
                                    className={
                                      "form-control" +
                                      (formik.errors.name && formik.touched.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                    name="name"
                                    title="Name"
                                    placeholder="Name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.errors.name && formik.touched.name ? (
                                    <span className="exclamation">
                                      <i className="fal fa-exclamation-circle"></i>
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-3 ">
                                <div className="form-icon-group mb-4">
                                  <label>Course Type *</label>
                                  <Select
                                    className={
                                      "form-control custom-select-box" +
                                      (formik.errors.courseType &&
                                      formik.touched.courseType
                                        ? " is-invalid"
                                        : "")
                                    }
                                    name="courseType"
                                    value={courseDetails.courseType ? courseDetails.courseType.filter((val)=>{
                                      return val.value == formik.values.courseType
                                    })  : ""}
                                    onChange={(value) => {
                                      if (value) {
                                        formik.setFieldValue("courseType", value.value);
                                        formik.setFieldValue("courseTypeName", value.key);
                                        if(value.value=="mentored" && formik.values.correspondence=="selfplaced"){
                                          formik.setFieldValue("correspondence","");
                                          formik.setFieldValue("correspondenceName","");
                                        }
                                      } else {
                                        formik.setFieldValue("courseType", "");
                                        formik.setFieldValue("courseTypeName", "");
                                      }                                    
                                    }}
                                    onBlur={formik.handleBlur}
                                    options={courseDetails.courseType}
                                    maxMenuHeight={175}
                                    placeholder={
                                      formik.values.courseType
                                        ? formik.values.courseType
                                        : "Course Type"
                                    }
                                    isClearable
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-3">
                                <div className="form-icon-group mb-4">
                                  <label>Learning Method *</label>
                                  <Select
                                    className={
                                      "form-control custom-select-box" +
                                      (formik.errors.correspondence &&
                                      formik.touched.correspondence
                                        ? " is-invalid"
                                        : "")
                                    }
                                    name="correspondence"
                                    // value={formik.values.correspondence || ""}
                                    value={correspondence.filter(function (
                                      option
                                    ) {
                                      return (
                                        option.value ==
                                        formik.values.correspondence
                                      );
                                    })}
                                    onChange={(value) => {
                                      if(value){
                                        formik.setFieldValue("correspondence",value.value);
                                        formik.setFieldValue("correspondenceName",value.key);
                                        if(value.value=="selfplaced"){
                                          formik.setFieldValue("enableChat", false)
                                        }
                                      } else {
                                        formik.setFieldValue("correspondence","");
                                        formik.setFieldValue("correspondenceName","");
                                      }
                                      
                                    }}
                                    isClearable
                                    onBlur={formik.handleBlur}
                                    options={formik.values.courseType === "shortcourse"?  studyTypeDropdown: studyTypeDropdownAcademic}
                                    maxMenuHeight={175}
                                    placeholder={
                                      formik.values.correspondence
                                        ? formik.values.correspondence
                                        : "Learning Method"
                                    }
                                  />
                                  {formik.errors.correspondence &&
                                  formik.touched.correspondence ? (
                                    <span className="exclamation">
                                      <i className="fal fa-exclamation-circle"></i>
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              {formik.values.courseType === "mentored" || formik.values.courseType === "scheduled" ? (
                                <div className="col-md-6 col-lg-3">
                                  <div className="form-icon-group mb-4">
                                    <label>
                                      Intake{" "}
                                      {formik.values.courseType !== "shortcourse" ? (
                                        <>*</>
                                      ) : null}
                                    </label>
                                    <Select
                                      className={
                                        "form-control custom-select-box" +
                                        (formik.errors.intake &&
                                          formik.touched.intake
                                          ? " is-invalid"
                                          : "")
                                      }
                                      name="intake"
                                      value={intakeArr.filter((val)=>{
                                        return val.value == formik.values.intake
                                      })}
                                      onChange={(value) =>{
                                        if(value){
                                          formik.setFieldValue("intake",value.value)
                                        } else {
                                          formik.setFieldValue("intake","")
                                        }
                                      }}
                                      isClearable
                                      onBlur={formik.handleBlur}
                                      options={intakeArr}
                                      maxMenuHeight={175}
                                      placeholder={
                                        formik.values.intake
                                          ? formik.values.intake
                                          : "Year"
                                      }
                                    />
                                  </div>
                                </div>)
                              :null}                             
                              <div className="col-md-6 col-lg-3">
                                <div className="form-icon-group mb-4">
                                  <label>Subject Code</label>
                                  <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder="Subject Code"
                                    title="Subject Code"
                                    name="subjectCode"
                                    value={formik.values.subjectCode}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.errors.subjectCode &&
                                  formik.touched.subjectCode ? (
                                    <span className="exclamation">
                                      <i className="fal fa-exclamation-circle"></i>
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              {formik.values.correspondence !== "selfplaced" ? (<>
                                <div className="col-md-6 col-lg-2">
                                  <div className="form-icon-group mb-4">
                                    <label>Start Date *</label>
                                    <DatePicker
                                      autoComplete="off"
                                      selected={formik.values.startDate}
                                      name="startDate"
                                      onChange={(date) => {
                                        formik.setFieldValue("startDate", date);
                                        setOpenDate(date);
                                      }}
                                      dateFormat={FORM_DATE_FORMAT}
                                      showYearDropdown
                                      scrollableYearDropdown
                                      dateFormatCalendar="MMMM"
                                      className={
                                        "form-control cursor-pointer" +
                                        (formik.errors.startDate &&
                                        formik.touched.startDate
                                          ? " is-invalid"
                                          : "")
                                      }
                                      title="Start Date"
                                      placeholderText="Select Start Date"
                                      timeIntervals={15}
                                      onChangeRaw={(e) => {
                                        e.preventDefault();
                                      }}
                                      minDate={new Date()}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 col-lg-2">
                                  <div className="form-icon-group mb-4">
                                    <label>End Date {formik.values.courseType == "shortcourse" ? "" : "*"}</label>
                                    <DatePicker
                                      autoComplete="off"
                                      selected={formik.values.endDate}
                                      onChange={(date) => {
                                        formik.setFieldValue("endDate", date);
                                        setOpenDate(date);
                                      }}
                                      name="endDate"
                                      dateFormat={FORM_DATE_FORMAT}
                                      showYearDropdown
                                      scrollableYearDropdown
                                      dateFormatCalendar="MMMM"
                                      className={
                                        "form-control cursor-pointer" +
                                        (formik.errors.endDate &&
                                        formik.touched.endDate
                                          ? " is-invalid"
                                          : "")
                                      }
                                      disabled={!formik.values.startDate}
                                      title="End Date"
                                      placeholderText="Select End Date"
                                      timeIntervals={15}
                                      onChangeRaw={(e) => {
                                        e.preventDefault();
                                      }}
                                      minDate={formik.values.courseType === "shortcourse" ? formik.values.startDate : addDays(formik.values.startDate, 1)}
                                    />
                                  </div>
                                </div>
                              </>)
                              :null}
                              <div className="col-md-6 col-lg-2">
                                <div className="form-icon-group mb-4">
                                  <label>Published Date</label>
                                  <DatePicker
                                    selected={
                                      formik.values.publish_date
                                        ? formik.values.publish_date
                                        : formik.values.startDate
                                    }
                                    filterTime={filterPassedTime}
                                    dateFormat={FORM_DATE_FORMAT}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dateFormatCalendar="MMMM"
                                    className={
                                      "form-control cursor-pointer" +
                                      (formik.errors.publish_date &&
                                      formik.touched.publish_date
                                        ? " is-invalid"
                                        : "")
                                    }
                                    title="Publish Date"
                                    placeholderText="Select Publish Date"
                                    timeIntervals={15}
                                    onChange={(date) => {
                                      formik.setFieldValue("publish_date", date);
                                      setOpenDate(date);
                                    }}
                                    onChangeRaw={(e) => e.preventDefault()}
                                  />
                                </div>
                              </div>
                              {formik.values.correspondence === "selfplaced" ? (
                              <div className="col-md-6 col-lg-3">
                                <div className="form-icon-group mb-4">
                                  <label>Duration (Months) *</label>
                                  <input
                                    type="number"
                                    className={
                                      "form-control" +
                                      (formik.errors.duration && formik.touched.duration
                                        ? " is-invalid"
                                        : "")
                                    }
                                    name="duration"
                                    title="Duration"
                                    placeholder="Duration"
                                    value={formik.values.duration}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.errors.duration && formik.touched.duration ? (
                                    <span className="exclamation">
                                      <i className="fal fa-exclamation-circle"></i>
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                              ): null}
                              {(formik.values.courseType === "shortcourse" ||
                                formik.values.courseType ==
                                  "Short Course") && (
                                <>
                                  <div className="col-md-6 col-lg-3">
                                    <div className="form-icon-group mb-4">
                                      <label>School *</label>
                                      <Select
                                        className={"form-control custom-select-box " + (formik.errors.school && formik.touched.school ? " is-invalid" : "")}
                                        name="school"
                                        value={schoolList.filter((item) => {
                                          return item.value == formik.values.school;
                                        })}
                                        onChange={(value) => {
                                          if (value) {
                                            formik.setFieldValue("school", value.value);
                                            setSchoolId(value.netsuite_department_id);
                                          } else {
                                            formik.setFieldValue("school", "");
                                            setSchoolId("");
                                          }
                                          formik.setFieldValue("netsuiteProductId", "");
                                          formik.setFieldValue("netsuiteProductName", "");
                                        }}
                                        // isClearable
                                        onBlur={formik.handleBlur}
                                        options={schoolList}
                                        maxMenuHeight={175}
                                        // placeholder={formik.values.school ? formik.values.school : "Select"}
                                        placeholder={"Select"}
                                      />
                                    </div> 
                                  </div>                                  
                                  <div className="col-md-6 col-lg-3">
                                    <label htmlFor="brand">Brand *</label>
                                    <div className="form-icon-group mb-4" title="Brand">
                                      <Select
                                        className={"form-control custom-select-box " + (formik.errors.brand && formik.touched.brand ? " is-invalid" : "")}
                                        name="brand"
                                        value={allBrands.filter((item) => {
                                          return item.value == formik.values.brand;
                                        })}
                                        onChange={(value) => {
                                          if (value) {
                                            formik.setFieldValue("brand", value.value);
                                          } else {
                                            formik.setFieldValue("brand", "");
                                          }
                                        }}
                                        isClearable
                                        onBlur={formik.handleBlur}
                                        options={allBrands.sort((a, b) => {
                                          let name1 = a.label;
                                          let name2 = b.label
                                          return name1.localeCompare(name2)
                                        })}
                                        maxMenuHeight={175}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-lg-5">
                                    <div className="form-icon-group mb-4">
                                      <label>Netsuite Product</label>
                                      <Select
                                        className={
                                          "form-control custom-select-box" +
                                          (formik.errors.netsuiteProductId &&
                                          formik.touched.netsuiteProductId
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="netsuiteProductId"
                                        value={
                                          formik.values.netsuiteProductId || ""
                                        }
                                        onChange={(value) => {
                                          if (value) {
                                            formik.setFieldValue("netsuiteProductId", value.value);
                                            formik.setFieldValue("netsuiteProductName", value.label);
                                          } else {
                                            formik.setFieldValue("netsuiteProductId", "");
                                            formik.setFieldValue("netsuiteProductName", "");
                                          }
                                        }}
                                        isClearable
                                        onBlur={formik.handleBlur}
                                        options={
                                          netsuiteList
                                            ? modifiyOptionsForSelect(
                                                netsuiteList,
                                                "netsuite"
                                              )
                                            : []
                                        }
                                        maxMenuHeight={175}
                                        placeholder={
                                          formik.values.netsuiteProductName
                                            ? formik.values.netsuiteProductName
                                            : "Netsuite Product"
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                              {(formik.values.courseType === "shortcourse" || formik.values.courseType === "slpmentored" ||
                                formik.values.courseType ==
                                  "Short Course") && (
                                <div className="col-md-6 col-lg-3">
                                  <div className="form-icon-group mb-4">
                                    <label>CRM Product</label>
                                    <Select
                                      className={
                                        "form-control custom-select-box" +
                                        (formik.errors.crmProduct &&
                                        formik.touched.crmProduct
                                          ? " is-invalid"
                                          : "")
                                      }
                                      name="crmProduct"
                                      value={formik.values.crmProduct || ""}
                                      onChange={(value) => {
                                        if (value) {
                                          formik.setFieldValue("crmProduct", value.value);
                                          formik.setFieldValue("crmProductId", value.id);
                                        } else {
                                          formik.setFieldValue("crmProduct", "");
                                          formik.setFieldValue("crmProductId", "");
                                        }
                                      }}
                                      onBlur={formik.handleBlur}
                                      options={
                                        crmList
                                          ? modifiyOptionsForSelect(
                                              crmList,
                                              "crm"
                                            )
                                          : []
                                      }
                                      maxMenuHeight={175}
                                      placeholder={
                                        formik.values.crmProduct
                                          ? formik.values.crmProduct
                                          : "CRM Product"
                                      }
                                      isClearable
                                    />
                                  </div>
                                </div>
                              )}
                              {formik.values.courseType != "shortcourse" && (formik.values.correspondence === "full" || formik.values.correspondence === "fulltimeonline") ? (
                                <div className="col-md-6 col-lg-2">
                                  <label htmlFor="Campus">Campus *</label>
                                  <div className="">
                                    <div className="form-icon-group mb-4 ">
                                      <Select
                                        className={
                                          "form-control custom-select-box " +
                                          (formik.errors.Campus && formik.touched.Campus
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="Campus"
                                        value={campusArr.filter(function (option) {
                                          if (formik.values.correspondence === "full") {
                                            return option.value == formik.values.Campus;
                                            } else {
                                              formik.values.Campus = "Online"
                                              return option.value == formik.values.Campus;
                                            }
                                        })}
                                        onChange={(value) => {
                                          if (value) {
                                            formik.setFieldValue("Campus", value.value);
                                          } else {
                                            formik.setFieldValue("Campus", "");
                                          }
                                        }}
                                        isClearable
                                        onBlur={formik.handleBlur}
                                        options={campusArr}
                                        maxMenuHeight={175}
                                        placeholder={
                                          formik.values.Campus ? formik.values.Campus : "Select"
                                        }
                                      />
                                      {formik.errors.Campus && formik.touched.Campus ? (
                                        <span className="exclamation">
                                          <i className="fal fa-exclamation-circle"></i>
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ): null}
                              {/* <div className="col-md-6 col-lg-3">
                                <div className="form-icon-group mt-4">
                                  <div className="custom-control custom-checkbox text-left">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="dhet"
                                      name="dhetCheckbox"
                                      checked={formik.values.dhetCheckbox}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="dhet"
                                    >
                                      DHET Exam Subject
                                    </label>
                                  </div>
                                </div>
                              </div> */}
                            {(formik.values.courseType === "slpmentored" || formik.values.courseType === "shortcourse" || formik.values.courseType ==
                                  "Short Course") && <div className="col-md-6 col-lg-4 mb-4">
                                <label htmlFor="IndexLogo" style={{ marginBottom: 13 }}>
                                  Time Table
                                </label>
                                <div className="form-group atttach-file">
                                  <label
                                    className={
                                      formik.errors.TimeTable &&
                                      formik.touched.TimeTable &&
                                      " file-req is-invalid"
                                    }
                                  >
                                    <i className="fal fa-paperclip"></i>
                                    <span>Add Attachment</span>
                                    <input
                                      type="file"
                                      id="input"
                                      className="form-control  form-control-aatch-file"
                                      name="file"
                                      title="Time Table"
                                      onChange={(e) => {
                                        if (e.target.files.length) {
                                          formik.setFieldValue("TimeTable", e.target.files[0]);
                                          setTimeTableDoc(e.target.files[0]);
                                          setTimeTableDocName(URL.createObjectURL(e.target.files[0]));
                                        }
                                      }}
                                    />
                                  </label>
                                </div>
                                {timeTableDocName && timeTableDocName !== "undefined" && (
                                  <>
                                    <div className="frm-group">
                                      <ul className="list-unstyled attached-file-ul m-0">
                                        <li>
                                        
                                            <a
                                              title={timeTableDocName}
                                              href={
                                                timeTableDocName.includes("blob")
                                                  ? timeTableDocName
                                                  : `${IMAGE_URL}/${timeTableDocName}`.replaceAll(
                                                      "/public/",
                                                      ""
                                                    ).replaceAll("home/myaie/public_html/", "")
                                              }
                                              target="_blank"
                                            >
                                              <i className="fal fa-file"></i>
                                              &nbsp;{
                                                      timeTableDoc?.name
                                                      ? TrimText(timeTableDoc?.name,40)
                                                      : TrimText(formik.values.TimeTable.split(
                                                          "/"
                                                        ).reverse()[0],40)
                                                        
                                                    }
                                            </a>
                                            <button
                                            className="btn btn-danger rounded-circle btn-dropdown-item"
                                            title="Delete"
                                            onClick={() => handleTimeTable()}
                                          >
                                            <i className="fal fa-trash-alt"></i>
                                          </button>
                                          
                                          
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                )}
                              </div>}
                            {(formik.values.courseType === "shortcourse" || formik.values.courseType === "slpmentored" ||
                              formik.values.courseType ==
                                "Short Course") && (
                              <div className="col-md-12">
                                <div className="form-icon-group mb-4">
                                  <label>Certificate Template</label>
                                  <HtmlInputEditor
                                    editorState={editorState2}
                                    setEditorState={setEditorState2}
                                    isInValid={editorState2}
                                    isCKEditor={true}
                                    hideSign={true}
                                  />
                                </div>
                              </div>
                            )}
                            </div>
                            <hr />

                            <div className="course-sec-gap course-resource-sec">
                              <div className="edit-icon new-card-header">
                                <div className="card-header">
                                  Course Resources
                                </div>
                              </div>
                              <div className="card-body-inr">
                                <div className="row">
                                {formik.values.courseType !== "shortcourse" || formik.values.correspondence !== "selfplaced" ? (
                                  <div className="col-md-6 col-lg-4">
                                    <div className="form-icon-group mb-4">
                                      <label>Lecturer</label>
                                      <Select
                                        className={
                                          "form-control custom-select-box" +
                                          (formik.errors.lecturer &&
                                          formik.touched.lecturer
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="lecturer"
                                        value={courseDetails.lecturer_coach ? modifiyOptionsForSelect(
                                              courseDetails.lecturer_coach,
                                              "lecturer"
                                            ).filter((val)=>{
                                              return val.value == formik.values.lecturer
                                            }) : ""}
                                        onChange={(value) => {
                                          if(value){
                                            formik.setFieldValue("lecturer",value.value);
                                            formik.setFieldValue("lecturerId",value.id);
                                          } else {
                                            formik.setFieldValue("lecturer","");
                                            formik.setFieldValue("lecturerId","");
                                          }
                                          
                                        }}
                                        isClearable
                                        onBlur={formik.handleBlur}
                                        options={
                                          courseDetails.lecturer_coach
                                            ? modifiyOptionsForSelect(
                                                courseDetails.lecturer_coach,
                                                "lecturer"
                                              )
                                            : []
                                        }
                                        maxMenuHeight={175}
                                        placeholder={
                                          formik.values.lecturer
                                            ? formik.values.lecturer
                                            : "Lecturer"
                                        }
                                      />
                                      {formik.errors.lecturer &&
                                      formik.touched.lecturer ? (
                                        <span className="exclamation">
                                          <i className="fal fa-exclamation-circle"></i>{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                ):null}
                                {formik.values.mentoredCheckbox ? (
                                  <div className="col-md-6 col-lg-4  d-none d-md-block d-lg-none">
                                    <div className="form-icon-group mb-4">
                                      <label>Mentor</label>
                                      <Select
                                        className={
                                          "form-control custom-select-box" +
                                          (formik.errors.lecturer &&
                                          formik.touched.lecturer
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="Mentor"
                                        value={courseDetails.lecturer_coach ? modifiyOptionsForSelect(
                                              courseDetails.lecturer_coach,
                                              "Mentor"
                                            ).filter((val)=>{
                                              return val.value == formik.values.lecturer
                                            }) : ""}
                                        onChange={(value) => {
                                          if(value){
                                            formik.setFieldValue("lecturer",value.value);
                                            formik.setFieldValue("lecturerId",value.id);
                                          } else {
                                            formik.setFieldValue("lecturer","");
                                            formik.setFieldValue("lecturerId","");
                                          }
                                          
                                        }}
                                        isClearable
                                        onBlur={formik.handleBlur}
                                        options={
                                          courseDetails.lecturer_coach
                                            ? modifiyOptionsForSelect(
                                                courseDetails.lecturer_coach,
                                                "lecturer"
                                              )
                                            : []
                                        }
                                        maxMenuHeight={175}
                                        placeholder={
                                          formik.values.lecturer
                                            ? formik.values.lecturer
                                            : "Lecturer"
                                        }
                                      />
                                      {formik.errors.lecturer &&
                                      formik.touched.lecturer ? (
                                        <span className="exclamation">
                                          <i className="fal fa-exclamation-circle"></i>{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                  ) :null}
                                  <div className="col-md-6 col-lg-4">
                                    <div className="form-icon-group mb-4">
                                      <label>Programme Coordinator</label>
                                      <Select
                                        className={
                                          "form-control custom-select-box" +
                                          (formik.errors.programCoordinator &&
                                          formik.touched.programCoordinator
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="programCoordinator"
                                        value={
                                          courseDetails.lecturer_coach ? modifiyOptionsForSelect(
                                                courseDetails.lecturer_coach,
                                                "programCoordinator"
                                              ).filter((val)=>{
                                                return val.value == formik.values.programCoordinator
                                              }) : ""
                                        }
                                        onChange={(value) => {
                                          if (value) {
                                            formik.setFieldValue("programCoordinator", value.value);
                                            formik.setFieldValue("programCoordinatorId", value.id);
                                          } else {
                                            formik.setFieldValue("programCoordinator", "");
                                            formik.setFieldValue("programCoordinatorId", "");
                                          }                                        
                                        }}
                                        isClearable
                                        onBlur={formik.handleBlur}
                                        options={
                                          courseDetails.lecturer_coach
                                            ? modifiyOptionsForSelect(
                                                courseDetails.lecturer_coach,
                                                "programCoordinator"
                                              )
                                            : []
                                        }
                                        maxMenuHeight={175}
                                        placeholder={
                                          formik.values.programCoordinator
                                            ? formik.values.programCoordinator
                                            : "Programme Coordinator"
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6 col-lg-4">
                                    <div className="form-icon-group mb-4">
                                      <label>Programme Support</label>
                                      <Select
                                        className={
                                          "form-control custom-select-box" +
                                          (formik.errors.programSupport &&
                                          formik.touched.programSupport
                                            ? " is-invalid"
                                            : "")
                                        }
                                        name="programSupport"
                                        value={courseDetails.lecturer_coach ? modifiyOptionsForSelect(
                                          courseDetails.lecturer_coach,
                                          "programCoordinator"
                                        ).filter((val)=>{
                                          return val.value == formik.values.programSupport
                                        }) : ""}
                                        onChange={(value) => {
                                          if(value){
                                            formik.setFieldValue("programSupport",value.value);
                                            formik.setFieldValue("programSupportId", value.id);
                                          } else {
                                            formik.setFieldValue("programSupport","");
                                            formik.setFieldValue("programSupportId", "");
                                          }
                                        }}
                                        isClearable
                                        onBlur={formik.handleBlur}
                                        options={
                                          courseDetails.lecturer_coach ? modifiyOptionsForSelect(
                                                courseDetails.lecturer_coach,
                                                "programCoordinator"
                                              )
                                            : []
                                        }
                                        maxMenuHeight={175}
                                        placeholder={
                                          formik.values.programSupport
                                            ? formik.values.programSupport
                                            : "Programme Support"
                                        }
                                      />
                                      {formik.errors.programSupport &&
                                      formik.touched.programSupport ? (
                                        <span className="exclamation">
                                          <i className="fal fa-exclamation-circle"></i>{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-icon-group mb-4">
                                      <label>Overview</label>
                                      <HtmlInputEditor
                                        editorState={editorState}
                                        setEditorState={setEditorState}
                                        isInValid={editorState}
                                        isCKEditor={true}
                                        hideSign={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="course-sec-gap course-setting-sec d-none d-md-block d-lg-none">
                              <div className="edit-icon new-card-header">
                                <div className="card-header">Course Settings</div>
                              </div>
                              <div className="card-body-inr">
                                <div className="row">
                                  {formik.values.correspondence !== "selfplaced" ? (
                                    <div className="col-md-4">
                                      <div className="form-icon-group mb-4">
                                        <div className="custom-control custom-checkbox text-left">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="enableCheck"
                                            name="enableChat"
                                            checked={formik.values.enableChat}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="enableCheck"
                                          >
                                            Enable Chat
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="col-md-4">
                                    <div className="form-icon-group mb-4">
                                      <div className="custom-control custom-checkbox text-left">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="mcheck"
                                          name="mentoredCheckbox"
                                          checked={formik.values.mentoredCheckbox}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                        />
                                        <label className="custom-control-label" htmlFor="mcheck">
                                          Mentored
                                        </label>
                                      </div>
                                      {formik.errors.Lastname && formik.touched.Lastname ? (
                                        <span className="exclamation">
                                          <i className="fal fa-exclamation-circle"></i>
                                        </span>
                                      ) : null}
                                      {/* End date was here */}
                                    </div>
                                  </div>
                                  {!formik.values.mentoredCheckbox  ? (
                                    <div className="col-md-4">
                                      <div className="form-icon-group mb-4">
                                        <div className="custom-control custom-checkbox text-left">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="dhet"
                                            name="dhetCheckbox"
                                            checked={formik.values.dhetCheckbox}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                          />
                                          <label className="custom-control-label" htmlFor="dhet">
                                            DHET Exam Subject
                                          </label>
                                        </div>
                                        {formik.errors.Lastname && formik.touched.Lastname ? (
                                          <span className="exclamation">
                                            <i className="fal fa-exclamation-circle"></i>
                                          </span>
                                        ) : null}
                                        {/* End date was here */}
                                      </div>
                                    </div>
                                  ): null}
                                  <div className="col-md-4">
                                    <div className="form-icon-group mb-4">
                                      <div className="custom-control custom-checkbox text-left">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="autoEmail"
                                          name="autoEmail"
                                          checked={formik.values.autoEmail}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="autoEmail"
                                        >
                                          Send automatic email
                                        </label>
                                      </div>
                                    </div>
                                  </div>                                  
                                  { formik.values.mentoredCheckbox ? (
                                    <>
                                      <div className="col-md-6">
                                        <div className="form-icon-group mb-4">
                                          <label>Recommended Start Date * </label>
                                          <DatePicker
                                            autoComplete="off"
                                            selected={formik.values.startRecommendedDate}
                                            onChange={(date) => {
                                              formik.setFieldValue("startRecommendedDate", date);
                                            }}
                                            filterTime={filterPassedTime}
                                            dateFormat={FORM_DATE_FORMAT}
                                            showYearDropdown
                                            scrollableYearDropdown
                                            dateFormatCalendar="MMMM"
                                            className={
                                              "form-control cursor-pointer" +
                                              (formik.errors.startRecommendedDate && formik.touched.startRecommendedDate
                                                ? " is-invalid"
                                                : "")
                                            }
                                            title="Start Recommended Date"
                                            onBlur={formik.handleBlur}
                                            placeholderText="Select Start Date"
                                            timeIntervals={15}
                                            onChangeRaw={(e) => e.preventDefault()}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-icon-group mb-4">
                                          <label>Recommended End Date * </label>
                                          <DatePicker
                                            autoComplete="off"
                                            selected={formik.values.endRecommendedDate}
                                            onChange={(date) => {
                                              formik.setFieldValue("endRecommendedDate", date);
                                            }}
                                            filterTime={filterPassedTime}
                                            dateFormat={FORM_DATE_FORMAT}
                                            showYearDropdown
                                            scrollableYearDropdown
                                            dateFormatCalendar="MMMM"
                                            className={
                                              "form-control cursor-pointer" +
                                              (formik.errors.endRecommendedDate && formik.touched.endRecommendedDate
                                                ? " is-invalid"
                                                : "")
                                            }
                                            disabled={!formik.values.startRecommendedDate}
                                            title="End Date"
                                            onBlur={formik.handleBlur}
                                            placeholderText="Select End Date"
                                            timeIntervals={15}
                                            onChangeRaw={(e) => e.preventDefault()}
                                            minDate={addDays(formik.values.startRecommendedDate, 1)}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : null} 
                                  <div className="col-md-4">
                                    <div className="form-icon-group mb-4">
                                      <div className="custom-control custom-checkbox text-left">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="hideLearningMaterial"
                                          name="hideLearningMaterial"
                                          checked={formik.values.hideLearningMaterial}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="hideLearningMaterial"
                                        >
                                          Hide Learning Material
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-icon-group mb-4">
                                      <div className="custom-control custom-checkbox text-left">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="hideAssessments"
                                          name="hideAssessments"
                                          checked={formik.values.hideAssessments}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="hideAssessments"
                                        >
                                          Hide Assessments
                                        </label>
                                      </div>
                                    </div>
                                  </div>       
                                  <div className="col-md-4">
                                    <div className="form-icon-group mb-4">
                                      <div className="custom-control custom-checkbox text-left">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="hideDates"
                                          name="hideDates"
                                          checked={formik.values.hideDates}
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="hideDates"
                                        >
                                          Hide Dates
                                        </label>
                                      </div>
                                    </div>
                                  </div>                                                      
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group form-group-save-cancel">
                          <button
                            className="btn btn-save btn-success"
                            type="submit"
                            title="Save"
                            disabled={loading ? true : false}
                          >
                            {loading === false ? (
                              <i className="fal fa-save"></i>
                            ) : (
                              <i class="fas fa-cog fa-spin"></i>
                            )}
                            Save
                          </button>
                          {/* <button className="btn btn-close btn-danger" type="button" title="Cancel" > */}
                          <button
                            className="btn btn-close btn-danger"
                            type="button"
                            title="Cancel"
                            onClick={() => {
                              formik.resetForm();
                              history.push("/courseAdministration/courses/list");
                            }}
                          >
                            <i className="fal fa-times"></i>
                            Cancel
                          </button>
                          {Object.keys(formik.values).map((key) => {
                            if (formik.touched[key] && formik.errors[key]) {
                              return (
                                <div className="invalid-feedback d-block">
                                  {formik.errors[key]}
                                </div>
                              );
                            }
                          })}
                          {/* {formik.touched.startDay && formik.errors.startDay ? <div className="invalid-feedback d-block">{formik.errors.startDay}</div> : null}
                          {formik.touched.startMonth && formik.errors.startMonth ? <div className="invalid-feedback d-block">{formik.errors.startMonth}</div> : null}
                          {formik.touched.endYear && formik.errors.endYear ? <div className="invalid-feedback d-block">{formik.errors.endYear}</div> : null}
                          {formik.touched.endDay && formik.errors.endDay ? <div className="invalid-feedback d-block">{formik.errors.endDay}</div> : null}
                          {formik.touched.endMonth && formik.errors.endMonth ? <div className="invalid-feedback d-block">{formik.errors.endMonth}</div> : null} */}
                        </div>                    
                      </div>
                    </div>
                    <div className="col-lg-3 d-none d-lg-block">
                      <div className="card card-profile-info-card course-details-tab-sec">                    
                        <div className="course-sec-gap course-setting-sec">
                          <div className="edit-icon new-card-header">
                            <div className="card-header">Course Settings</div>
                          </div>
                          <div className="card-body-inr">
                            <div className="row">
                              {formik.values.correspondence !== "selfplaced" ? (
                                <div className="col-md-12">
                                  <div className="form-icon-group mb-4">
                                    <div className="custom-control custom-checkbox text-left">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="enableCheck"
                                        name="enableChat"
                                        checked={formik.values.enableChat}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="enableCheck"
                                      >
                                        Enable Chat
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-12">
                                <div className="form-icon-group mb-4">
                                  <div className="custom-control custom-checkbox text-left">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="mcheck"
                                      name="mentoredCheckbox"
                                      checked={formik.values.mentoredCheckbox}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    <label className="custom-control-label" htmlFor="mcheck">
                                      Mentored
                                    </label>
                                  </div>
                                  {formik.errors.Lastname && formik.touched.Lastname ? (
                                    <span className="exclamation">
                                      <i className="fal fa-exclamation-circle"></i>
                                    </span>
                                  ) : null}
                                  {/* End date was here */}
                                </div>
                              </div>
                              {formik.values.mentoredCheckbox ? (
                                <div className="col-md-12">
                                  <div className="form-icon-group mb-4">
                                    <label>Mentor</label>
                                    <Select
                                      className={
                                        "form-control custom-select-box" +
                                        (formik.errors.lecturer &&
                                        formik.touched.lecturer
                                          ? " is-invalid"
                                          : "")
                                      }
                                      name="Mentor"
                                      value={courseDetails.lecturer_coach ? modifiyOptionsForSelect(
                                            courseDetails.lecturer_coach,
                                            "Mentor"
                                          ).filter((val)=>{
                                            return val.value == formik.values.lecturer
                                          }) : ""}
                                      onChange={(value) => {
                                        if(value){
                                          formik.setFieldValue("lecturer",value.value);
                                          formik.setFieldValue("lecturerId",value.id);
                                        } else {
                                          formik.setFieldValue("lecturer","");
                                          formik.setFieldValue("lecturerId","");
                                        }
                                        
                                      }}
                                      isClearable
                                      onBlur={formik.handleBlur}
                                      options={
                                        courseDetails.lecturer_coach
                                          ? modifiyOptionsForSelect(
                                              courseDetails.lecturer_coach,
                                              "lecturer"
                                            )
                                          : []
                                      }
                                      maxMenuHeight={175}
                                      placeholder={
                                        formik.values.lecturer
                                          ? formik.values.lecturer
                                          : "Lecturer"
                                      }
                                    />
                                    {formik.errors.lecturer &&
                                    formik.touched.lecturer ? (
                                      <span className="exclamation">
                                        <i className="fal fa-exclamation-circle"></i>{" "}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              ) :null}
                              <div className="col-md-12">
                                <div className="form-icon-group mb-4">
                                  <div className="custom-control custom-checkbox text-left">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="autoEmail"
                                      name="autoEmail"
                                      checked={formik.values.autoEmail}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="autoEmail"
                                    >
                                      Send automatic email
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {!formik.values.mentoredCheckbox  ? (
                                <div className="col-md-12">
                                  <div className="form-icon-group mb-4">
                                    <div className="custom-control custom-checkbox text-left">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="dhet"
                                        name="dhetCheckbox"
                                        checked={formik.values.dhetCheckbox}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                      <label className="custom-control-label" htmlFor="dhet">
                                        DHET Exam Subject
                                      </label>
                                    </div>
                                    {formik.errors.Lastname && formik.touched.Lastname ? (
                                      <span className="exclamation">
                                        <i className="fal fa-exclamation-circle"></i>
                                      </span>
                                    ) : null}
                                    {/* End date was here */}
                                  </div>
                                </div>
                              ): null}                                                            
                              { formik.values.mentoredCheckbox ? (
                                <>
                                  <div className="col-md-12">
                                    <div className="form-icon-group mb-4">
                                      <label>Recommended Start Date * </label>
                                      <DatePicker
                                        autoComplete="off"
                                        selected={formik.values.startRecommendedDate}
                                        onChange={(date) => {
                                          formik.setFieldValue("startRecommendedDate", date);
                                        }}
                                        filterTime={filterPassedTime}
                                        dateFormat={FORM_DATE_FORMAT}
                                        showYearDropdown
                                        scrollableYearDropdown
                                        dateFormatCalendar="MMMM"
                                        className={
                                          "form-control cursor-pointer" +
                                          (formik.errors.startRecommendedDate && formik.touched.startRecommendedDate
                                            ? " is-invalid"
                                            : "")
                                        }
                                        title="Start Recommended Date"
                                        onBlur={formik.handleBlur}
                                        placeholderText="Select Start Date"
                                        timeIntervals={15}
                                        onChangeRaw={(e) => e.preventDefault()}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-icon-group mb-4">
                                      <label>Recommended End Date * </label>
                                      <DatePicker
                                        autoComplete="off"
                                        selected={formik.values.endRecommendedDate}
                                        onChange={(date) => {
                                          formik.setFieldValue("endRecommendedDate", date);
                                        }}
                                        filterTime={filterPassedTime}
                                        dateFormat={FORM_DATE_FORMAT}
                                        showYearDropdown
                                        scrollableYearDropdown
                                        dateFormatCalendar="MMMM"
                                        className={
                                          "form-control cursor-pointer" +
                                          (formik.errors.endRecommendedDate && formik.touched.endRecommendedDate
                                            ? " is-invalid"
                                            : "")
                                        }
                                        disabled={!formik.values.startRecommendedDate}
                                        title="End Date"
                                        onBlur={formik.handleBlur}
                                        placeholderText="Select End Date"
                                        timeIntervals={15}
                                        onChangeRaw={(e) => e.preventDefault()}
                                        minDate={addDays(formik.values.startRecommendedDate, 1)}
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              <div className="col-md-12">
                                <div className="form-icon-group mb-4">
                                  <div className="custom-control custom-checkbox text-left">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="hideLearningMaterial"
                                      name="hideLearningMaterial"
                                      checked={formik.values.hideLearningMaterial}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="hideLearningMaterial"
                                    >
                                      Hide Learning Material
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-icon-group mb-4">
                                  <div className="custom-control custom-checkbox text-left">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="hideAssessments"
                                      name="hideAssessments"
                                      checked={formik.values.hideAssessments}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="hideAssessments"
                                    >
                                      Hide Assessments
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-icon-group mb-4">
                                  <div className="custom-control custom-checkbox text-left">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="hideDates"
                                      name="hideDates"
                                      checked={formik.values.hideDates}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="hideDates"
                                    >
                                      Hide Dates
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewCourse;
