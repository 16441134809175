import React, { useEffect, useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import ListOfUsers from "../components/systemadministration/usermanagement/ListOfUsers";
import AuditTrail from "../components/systemadministration/usermanagement/AuditTrail";
import { Link, useHistory, useParams } from "react-router-dom";
import AboutYou from "../components/profile/blocks/AboutYou";
import LoginDetails from "../components/profile/blocks/LoginDetails";
import UserAuditTrail from "../components/systemadministration/usermanagement/UserAuditTrail";
import AboutYouNewUser from "../components/systemadministration/usermanagement/AboutYouNewUser";
import { GetUserDetail } from "../services/UserService";

const SystemAdministration = () => {
  const { sideTab, tab, subTab, id } = useParams();
  const history = useHistory();

  const [userData, setUserData] = useState();


  useEffect(() => {
    if(id){
      GetUserDetail({ UserID: id })
      .then((res) => {
        setUserData(res.data);
      })
      .catch((err) => console.log(err));
    }
  }, [id]);

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      {tab === "new" ? (
                        <h4 className="text-left-align landing-heading heading_color_dynamic">
                          Create <span>User</span>
                        </h4>
                      ) : tab === "open" ? 
                      (
                        <h4 className="text-left-align landing-heading heading_color_dynamic">
                        Profile: <span>{userData?.Firstname + " " + userData?.Lastname}</span>
                      </h4>
                      ) : (
                        <h4 className="text-left-align landing-heading heading_color_dynamic">
                          User <span>Management</span>
                        </h4>
                      )}
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                            <i className="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  {tab !== "open" && tab !== "new" && (
                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                      <li className="nav-item" onClick={() => history.push(`/systemAdministration/userManagement/table`)}>
                        <Link
                          className={`nav-link ${tab === "table" && "active"}`}
                          id="pills-tab1"
                          data-toggle="pill"
                          to={`/systemAdministration/userManagement/table`}
                          role="tab"
                          aria-controls="pills-tab11"
                          aria-selected="true"
                        >
                          <i className="fal fa-users"></i>
                          Users
                        </Link>
                      </li>
                      <li className="nav-item" onClick={() => history.push(`/systemAdministration/userManagement/auditTrail`)}>
                        <Link
                          className={`nav-link ${tab === "auditTrail" && "active"}`}
                          id="pills-tab2"
                          data-toggle="pill"
                          to={`/systemAdministration/userManagement/auditTrail`}
                          role="tab"
                          aria-controls="pills-tab12"
                          aria-selected="true"
                        >
                          <i className="fal fa-history"></i>Audit trail
                        </Link>
                      </li>
                    </ul>
                  )}
                  {tab == "open" && (
                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                      <li className="nav-item" onClick={() => history.push(`/systemAdministration/userManagement/open/${id}/aboutYou`)}>
                        <Link
                          className={`nav-link ${subTab === "aboutYou" && "active"}`}
                          id="pills-tab1"
                          data-toggle="pill"
                          to={`/systemAdministration/userManagement/open/${id}/aboutYou`}
                          role="tab"
                          aria-controls="pills-tab1"
                          aria-selected="true"
                        >
                          <i className="fal fa-info-circle"></i>About You
                        </Link>
                      </li>
                      <li className="nav-item" onClick={() => history.push(`/systemAdministration/userManagement/open/${id}/loginDetails`)}>
                        <Link
                          className={`nav-link ${subTab === "loginDetails" && "active"}`}
                          id="pills-tab2"
                          data-toggle="pill"
                          to={`/systemAdministration/userManagement/open/${id}/loginDetails`}
                          role="tab"
                          aria-controls="pills-tab2"
                          aria-selected="false"
                        >
                          <i className="fal fa-user"></i>Login Details
                        </Link>
                      </li>
                      <li className="nav-item" onClick={() => history.push(`/systemAdministration/userManagement/open/${id}/auditTrail`)}>
                        <Link
                          className={`nav-link ${subTab === "auditTrail" && "active"}`}
                          id="pills-tab3"
                          data-toggle="pill"
                          to={`/systemAdministration/userManagement/open/${id}/auditTrail`}
                          role="tab"
                          aria-controls="pills-tab3"
                          aria-selected="false"
                        >
                          <i className="fal fa-history"></i>Audit Trail
                        </Link>
                      </li>
                    </ul>
                  )}
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      {subTab === "aboutYou" && <AboutYou />}
                    </div>
                    <div className="tab-pane fade active show" id="pills-tab22" role="tabpanel" aria-labelledby="pills-tab2">
                      {subTab === "loginDetails" && <LoginDetails />}
                    </div>
                    <div className="tab-pane fade active show" id="pills-tab33" role="tabpanel" aria-labelledby="pills-tab2">
                      {subTab === "auditTrail" && <UserAuditTrail />}
                    </div>
                  </div>
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      {tab === "table" && <ListOfUsers />}
                    </div>
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      {tab === "new" && <AboutYouNewUser />}
                    </div>
                    <div className="tab-pane fade active show" id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
                      {tab === "auditTrail" && <AuditTrail />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SystemAdministration;
