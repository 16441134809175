import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import $ from "jquery";
import { useParams } from 'react-router-dom';
import { CreateQuestionGroup } from '../../../../../../services/SmartAssessmentService';

function AddGroup({refreshData}) {
    const [isDisabled, setIsDisabled] = useState(false);
    const {id} = useParams();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            quiz_id: id,
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Group Name is required").max(255, "Name can't be more than 255 characters"),
        }),
        onSubmit: (values, { resetForm }) => {
            setIsDisabled(true);
            console.log(values);
            CreateQuestionGroup(values).then((res) => {
                setIsDisabled(false)
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: `${res?.data?.message}`,
                }).then(() => {
                    handleClose();
                    refreshData();
                });


            }).catch((err) => {
                setIsDisabled(false);
                Swal.fire({
                    icon: "error",
                    title: "error",
                    text: `${err?.response?.data?.message}`,
                });
            });
        }
    });

    const handleClose = () => {
        $("#groupViewForm").modal("hide");
        formik.resetForm();
    }
  return (
    <div
            className="modal content-assembly fade"
            id="groupViewForm"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
            >
                <div className="modal-content">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="modal-body p-0">
                            <div className="modal-header modal-header-custom">
                                <h5 class="modal-title"><i class="fal fa-plus"></i> Create Group</h5>
                                <button type="button" onClick={handleClose} class="close"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                            </div>
                            <div className="t p-30px">
                                <div className="row center-label mb-3">
                                    <div className="col-md-6 col-lg-12">
                                        <div className="form-group-blk mb-3">
                                            <label htmlFor="name">Group Name *</label>
                                            <input
                                                name="name"
                                                type="text"
                                                className={"form-control " + (formik.errors.name && formik.touched.name ? " is-invalid" : "")}
                                                placeholder='Group name'
                                                title='Group Name'
                                                id="name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group form-group-save-cancel mt-3">
                                    <button className="btn btn-primary" type="submit" title="Save" disabled={isDisabled}>
                                        {isDisabled === false ? <i className="fal fa-save"></i> : <i className="fas fa-cog fa-spin"></i>}
                                        Save
                                    </button>
                                    <button type="button" className="btn btn-close btn-danger" title="Close" onClick={handleClose} >
                                        <i className="fal fa-times"></i>
                                        Cancel
                                    </button>
                                    {formik.touched.name && formik.errors.name ? <div className="invalid-feedback d-block">{formik.errors.name}</div> : null}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
  )
}

export default AddGroup