/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useContext } from "react";
import { useHistory, Route, Redirect } from "react-router-dom";
import { getToken } from "../utils/Auth";
import { LoginCheckService } from "../services/LoginService";
import { useSelector, useDispatch } from "react-redux";
import { setUser, setWidget, unsetWidget, setImage, setUserStatus, setPermission, setGivenPermission, setRegistarationDropDownValues } from "../store/actions/index";
import { InActiveStudentAcess } from "../utils/CustomModels";
import { MainContext } from "../contexts/MainContext";
import { GetAllPermissions } from "../services/PermissionService";
import PermissionsGate from "../utils/permissionGate";
import { GetAllDropdownValues } from "../services/RegistrationService";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const location = useHistory();
  const user = useSelector((state) => state.user);
  const { setState, setStatus } = useContext(MainContext);

  useEffect(() => {
    // PermissionsGate();
    LoginCheckService()
      .then((res) => {
        dispatch(unsetWidget());
        dispatch(setWidget({ status: 1 }));
        res.data.id= res.data.user_id
        dispatch(setUser(res.data));
        if (res.data.picture_me !== null) {
          dispatch(setImage(res.data.picture_me));
          setState(res.data.picture_me);
        } else {
          dispatch(setImage(""));
        }
        getPermissions(res.data.role_id);
        getRegistrationDropdownValues();
        dispatch(setUserStatus(res.data.Status));
        setStatus(res.data.Status);

        let flag = false;
        for (let i = 0; i < InActiveStudentAcess.length; i++) {
          if (window.location.href.includes(InActiveStudentAcess[i])) {
            flag = true;
          }
        }
        if ((user.status == "On Hold" || user.status == "In Active") && !flag) {
          location.push("/status");
        }
      })
      .catch((err) => {
        let response = err.response;
        if (response.status === 401) {
          location.push("/");
        }
      });
  }, []);

  const getPermissions = (role_id) => {
    GetAllPermissions(role_id).then((res) => {
      let allPermissions = res && res.data && res.data.allPermissions ? res.data.allPermissions : [];
      let allGivenPermissions = res && res.data && res.data.givenPermissions ? res.data.givenPermissions : [];

      let allPermObj = {};
      let allGivenPermObj = [];
      allPermissions.map((elem, ind) => {
        allPermObj[elem.permission_description] = elem.permission_description;
        allGivenPermissions.map((element, index) => {
          if (element.item_id === elem.permission_id) {
            allGivenPermObj.push(elem.permission_description);
          }
        })
      })
      dispatch(setPermission(allPermObj));
      dispatch(setGivenPermission(allGivenPermObj));

    }).catch((error) => {
    })
  }

  function getRegistrationDropdownValues() {
    GetAllDropdownValues().then(res => {
      // console.log("response -=-=-> ", res);
      dispatch(setRegistarationDropDownValues(res && res.data ? res.data : []));
    }).catch(err => {
      console.log("error -=-=-> ", err);
    })
  }

  return (
    <>
      <main>
        {/* {rest.permission ? } */}
        {/* <Route {...rest} render={(props) => (getToken() ? <Component {...props} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />)} /> */}
        <Route {...rest} render={(props) => (getToken() ? rest.permission ? <PermissionsGate routeCheck={true} scopes={[rest.permission]}><Component {...props} /></PermissionsGate> : <Component {...props} /> : rest.permission ? <PermissionsGate routeCheck={true} scopes={[rest.permission]}><Redirect to={{ pathname: "/", state: { from: props.location } }} /></PermissionsGate> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />)} />
      </main>
    </>
  );
};

export default PrivateRoute;
