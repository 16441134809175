import React, { useState, useEffect } from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import Tabs from "../Tabs";
import Select from "react-select";
import { getCurrentTimeHomePage } from "../../common/Helper";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { AddAssemblies, EditAssemblies, GetDetailOfAssemblies, GetDetailOfAssembliesCancelToken, GetLinkedCourses, GetListOfAssembliesFilters, GetListOfAssembliesFiltersCancelToken, GetListOfAssignedResources, GetListOfAvailableResources } from "../../../services/CourseService";
import LinkedCourse from "./AssembliesDetailsBlocks/LinkedCourse";
import AvailableResource from "./AssembliesDetailsBlocks/AvailableResource";
import AssignedResource from "./AssembliesDetailsBlocks/AssignedResource";
import AssemblyListComponent from "./AssemblyListComponent";
import PermissionsGate from "../../../utils/permissionGate";
import axios from "axios";


const AssembliesDetails = ({ setHeadingname }) => {
  const history = useHistory();
  const { id,type } = useParams();
  // const { id } = history?.location?.state;
  const [data, setData] = useState();
  const [versionList, setVersionList] = useState();
  const [assemblyId, setAssemblyID] = useState(id);

  const [assignedData, setAssignedData] = useState();
  const [availableData, setAvailableData] = useState();
  const [linkedCourseData, setLinkedCourseData] = useState();
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);

  const [isDisabled, setIsDisabled] = useState(false);

  const fetchTableData = () => {
    GetListOfAvailableResources({ assembly_id: id }).then((res) => {
      setAvailableData(res?.data?.avl_assemblies_list);
      setIsLoading1(false)
    }).catch((err) => { console.log(err); setIsLoading1(false) });

    GetListOfAssignedResources({ assembly_id: id }).then((res) => {
      setAssignedData(res?.data?.assigned_assemblies_list);
      setIsLoading2(false)
    }).catch((err) => { console.log(err); setIsLoading2(false) });

    GetLinkedCourses({ assembly_id: +id }).then((res) => {
      // console.log("linked courses -=-=-> ", res?.data);
      setLinkedCourseData(res?.data?.linked_course_list)
      setIsLoading3(false)
    }).catch((err) => { console.log(err); setIsLoading3(false) });
  }

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const res = await GetDetailOfAssembliesCancelToken(id, dropdownSource.token);
        setData(res?.data?.assemblies_detail);
        setHeadingname(res?.data?.assemblies_detail?.name)
        localStorage.setItem("headingName", res?.data?.assemblies_detail?.name)
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    if (history.location.pathname !== "/contentassembliesdetails/create" && history.location.pathname !== "/courses/content/assemblies/add" ) {
     fetchData();
    } else {
      setData({ name: "", item_description: "", item_version: "", notes: "" });
    }
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const res = await GetListOfAssembliesFiltersCancelToken(dropdownSource.token);
        let x = { value: "", label: "" };
        x = res.data.item_version.map((e) => ({ ...x, value: `${e.title}`, label: `${e.title}` }))
        setVersionList(x);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name,
      item_description: data?.item_description,
      item_version: data?.item_version,
      id: data?.id,
      notes: data?.notes,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      item_description: Yup.string().required("Description is required"),
      item_version: Yup.string().required("Version is required"),
      notes: Yup.string().required("Notes is required"),
    }),
    onSubmit: (values) => {
      setIsDisabled(true)
      if (history.location.pathname === "/contentassembliesdetails/edit" || history.location.pathname === "/courses/content/assemblies/edit/" + id) {
        // let body = { ...values, values[""] : id }
        // console.log("values",body);
        values.id = id;
        EditAssemblies(values).then((res) => {
          setIsDisabled(false)
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `${res?.data?.message}`,
          })
        }).catch((err) => {
          setIsDisabled(false)
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.message}`,
          });
        });
      } else {
        if(type == "duplicate"){
          values.assembly_id = id
        }
        AddAssemblies(values)
          .then((res) => {
            setAssemblyID(res?.data?.inserted_record);
            setIsDisabled(false)
            Swal.fire({
              icon: "success",
              title: "Success",
              text: `${res?.data?.message}`,
            });
            history.push("/courses/content/assemblies/table");
            // history.push("/contentassembliesdetails/edit",{ id: res?.data?.inserted_record })
          })
          .catch((err) => {
            setIsDisabled(false)
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data?.message}`,
            });
          });
      }

    }
  });

  return (
    <>

      <div className="card card-profile-info-card">
        <div>
          <div className="new-card-header">
            {/* <div className="card-header">Assemblies Details</div> */}
          </div>

          <div className="card-body-inr card-body-info">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="name">Name*</label>

                    <input
                      type="text"
                      className={"form-control " + (formik.errors.name && formik.touched.name ? " is-invalid" : "")}
                      placeholder="Enter Name"
                      name="name"
                      title="Name"
                      id="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-3">
                    <label>Version*</label>

                    <Select
                      className={"form-control custom-select-box " + (formik.errors.item_version && formik.touched.item_version ? " is-invalid" : "")}
                      name="item_version"
                      value={versionList?.filter(val=>val.value === formik.values.item_version)}
                      onChange={(value) => {
                        if (value) {
                          formik.setFieldValue("item_version", value.value)
                        } else {
                          formik.setFieldValue("item_version", "")
                        }
                      }}
                      onBlur={formik.handleBlur}
                      options={versionList}
                      maxMenuHeight={175}
                      title="Version"
                      placeholder={formik.values.item_version ? formik.values.item_version : "Version"}
                      isClearable
                    />
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-3">
                    <label htmlFor="item_description">Description*</label>

                    <input
                      type="text"
                      className={"form-control " + (formik.errors.item_description && formik.touched.item_description ? " is-invalid" : "")}
                      placeholder="Enter Description"
                      name="item_description"
                      title="Description"
                      id="item_description"
                      value={formik.values.item_description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group-blk">
                    <label htmlFor="notes">Notes*</label>

                    <textarea
                      className={"form-control " + (formik.errors.notes && formik.touched.notes ? " is-invalid" : "")}
                      rows="5"
                      placeholder="Notes..."
                      value={formik.values.notes}
                      name="notes"
                      id="notes"
                      title="Notes"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></textarea>
                  </div>
                </div>
              </div>

              {formik.touched.name && formik.errors.name ? <div className="invalid-feedback d-block">{formik.errors.name}</div> : null}
              {formik.touched.item_description && formik.errors.item_description ? <div className="invalid-feedback d-block">{formik.errors.item_description}</div> : null}
              {formik.touched.item_version && formik.errors.item_version ? <div className="invalid-feedback d-block">{formik.errors.item_version}</div> : null}
              {formik.touched.notes && formik.errors.notes ? <div className="invalid-feedback d-block">{formik.errors.notes}</div> : null}

              <div className="form-group form-group-save-cancel mt-4 mb-0">
                <PermissionsGate RenderError={() => (
                  <button className="btn btn-save btn-success" type="button" disabled title="Save">
                    {isDisabled === false ? <i className="fal fa-save"></i> : <i class="fas fa-cog fa-spin"></i>}
                    Save
                  </button>
                )} scopes={["caadd", "caedit"]}>
                  <button className="btn btn-save btn-success" type="submit" title="Save">
                    {isDisabled === false ? <i className="fal fa-save"></i> : <i class="fas fa-cog fa-spin"></i>}
                    Save
                  </button>
                </PermissionsGate>

                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.push("/courses/content/assemblies/table")}>
                  <i className="fal fa-times"></i>Cancel
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>

      {/* <div className="card card-profile-info-card mb-30">
        <div className="card-body">
          <div className="new-card-header">
            <div className="card-header">Assemblies Items</div>
          </div>

          <div className="card-body-inr card-body-info">
            <AssemblyListComponent id_assembly={id}/>


          </div>
        </div>
      </div> */}
    </>
  );
};

export default AssembliesDetails;
