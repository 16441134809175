import React, { useEffect, useState } from 'react';
import downloadIcon from "../../../assets/images/download.png";
import { DownloadFileFeedService } from "../../../services/PostsAndFeedService";
import axios from 'axios';
import { RenderAttachList } from '../../common/RenderAttachListFeed';
import Swal from 'sweetalert2';


function FeedAttachments({ attachments, isEditable, allData, deletedFile }) {

    const [images, setImages] = useState([]);
    const [downloads, setDownloads] = useState([]);
    const [currentimage, setcurrentimage] = useState('');
    const [attachArr, setAttachArr] = useState([]);
    const [seeMore, setSeeMore] = useState(false);
    const [seeLess, setSeeLess] = useState(false);

    useEffect(() => {
        setAttachArr(attachments)
    }, [attachments])

    useEffect(() => {

        // if (attachArr.length) {
        const imageArr = attachArr.filter(item => {
            return item.fileType === "render"
        })
        const downloadArr = attachArr.filter(item => {
            return item.fileType === "download"
        })
        setDownloads(downloadArr);
        setImages(imageArr);
        if (imageArr.length > 0) {
            setSeeMore(true)
        }
        // }

    }, [attachArr]);

    useEffect(() => {
        if (isEditable) {
            setSeeMore(false)
            setSeeLess(false)
        }
        else if(images.length>0){
            setSeeMore(true)
        }
    }, [isEditable])

    const handleDownload = (e, file) => {
        e.preventDefault();
        DownloadFileFeedService(file.attachment)
            .then(response => {
                forceFileDownload(response, file)

            })
            .catch((err) => console.log(err))
        // axios({
        //     method: 'get',
        //     url: 'http://127.0.0.1:3333/api/v1/download',
        //     params:{ url : file.attachment},
        //     responseType: 'blob'
        //     })
        //     .then(response => {
        //         console.log(response);
        //         forceFileDownload(response, file)

        //     })
        //     .catch(() => console.log('error occured'))
        //     console.log( file );

    }
    const forceFileDownload = (response, item) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', item.originalName) //or any other extension
        document.body.appendChild(link)
        link.click()
    }

    const handleRemoveFile = (props) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                deletedFile(allData.id, props.filePath)

                let tempArr = []
                attachArr.map(i => {
                    if (props.filePath != i.attachment) {
                        tempArr.push(i)
                    }
                })
                setAttachArr(tempArr)
            }
        });
    };

    const delImg = (item) => {
        item.filePath = item.attachment
        handleRemoveFile(item)
    }

    return (
        <>
            <div >
                <div className="attachment-container">
                    <div className={`image-container`}>
                    {/* <div className={`image-container${seeMore ? ' attachment-con' : ''}`}> */}
                        <ul className="post-image-ul list_post_imgview">
                            {images.map((item) => {
                                return (
                                    <li className="post-image" key={item.id}>
                                        {isEditable ?
                                            <button onClick={() => { delImg(item) }} type="button" className="btn file-remove-btn img-del"><i class="fal fa-times hold-show"></i></button>
                                            :
                                            null
                                        }
                                        <img className='hold-show' src={item.attachment} onClick={() => { setcurrentimage(item.attachment);}} />
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    {/* {images.length && seeMore ? <div onClick={() => { setSeeMore(false); setSeeLess(true) }} class="show-more-txt">See more</div> : null}
                    {images.length && seeLess ? <div onClick={() => { setSeeLess(false); setSeeMore(true) }} class="show-more-txt">See less</div> : null} */}
                </div>
                <div className="files-list-blk files-container">
                    <ul className="files-container-ul">
                        {downloads.map((item) => {
                            return (
                                <li className="files-container-li" key={item.id}>
                                    {RenderAttachList({
                                        fileName: item.originalName,
                                        size: item.size,
                                        filePath: item.attachment,
                                        showClose: isEditable,
                                        handleRemoveFile: handleRemoveFile
                                    })}
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div onClick={() => { }} className={`image-popup-block ${currentimage != '' ? 'active' : ''}`}>
                    <div className="image-popup flexible-modal">
                        <button type="button" onClick={() => { setcurrentimage('') }} className="close-icon"><i class="fas fa-times hold-show"></i></button>
                        <div className="image-popup-img">
                            <img src={currentimage} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default React.memo(FeedAttachments);