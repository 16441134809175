import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import hasPermission from '../../utils/hasMultiplePermission';
import Header from './Header';
import SideBar from './SideBar';

const LandingPage = () => {
    const history = useHistory();
    const { page, subPage } = useParams();
    const sideLinks = useSelector(state => state.sideBarLinks);
    const givenPermsisions = useSelector((state) => state.givenPermission);
    const [links, setLinks] = useState([]);

    useEffect(() => {
        if (subPage !== undefined) {
            sideLinks.map((e, i) => {
                e.options.map((item, index) => {
                    if (item.subPageName === subPage) {
                        setLinks(item.subTabs);
                    }
                })
            })
        } else {
            sideLinks.map((e, i) => {
                if (e?.pageName === page) {
                    setLinks(e.options);
                }
            })
        }

    }, [page, subPage]);

    function checkPermissions(arr = []) {
        return hasPermission({ scopes: arr, permissions: givenPermsisions });
    }

    const renderBreadcrumb = () => {
        if (subPage === undefined) {
            return (
                <div className="my-tickets-heading-blk flex-direction pt-2 mb-30px">
                    <div className="d-flex align-items-center">
                        <div className="name-w-head d-flex align-items-center">
                            <h3 className="text-left-align landing-heading heading_color_dynamic text-capitalize">
                                {page.split("_")[0]} {page.split("_")[1] ? <span>{page.split("_")[1]}</span> : ""}
                            </h3>
                            <div className="text-md-right action2-blk">
                                <div className="ticket-view-btn d-flex align-items-center">
                                <div className="add-new-ticket-bx">
                                    <button
                                    onClick={() => history.goBack()}
                                    title="Back"
                                    className="btn btn-white-bordered"
                                    >
                                    <i className="fal fa-angle-left"></i>Back
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="my-tickets-heading-blk flex-direction pt-2 mb-30px">
                    <div className="d-flex align-items-center">
                        <div className="name-w-head d-flex align-items-center">
                            <h3 className="text-left-align landing-heading heading_color_dynamic text-capitalize">
                                {/* {page.split("_").join(" ")} */}
                                {page.split("_")[0]} {page.split("_")[1] ? <span>{page.split("_")[1]} {" > "}{" "}
                                {subPage.split("_")[0]}{" "}{subPage.split("_")[1] ? subPage.split("_")[1] : ""}</span> : ""}
                            </h3>
                        </div>
                        <div className="text-md-right action2-blk">
                            <div className="ticket-view-btn d-flex align-items-center">
                                <div className="add-new-ticket-bx">
                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                        <i className="fal fa-angle-left"></i>Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar></SideBar>
                <div className="sidebar-right">
                    <div className='profile-wrapper new-right-container px-3'>
                    {renderBreadcrumb()}
                    <ul className="home-ul-list list-unstyled">
                        {links && links.length ? links.map((ele, ind) => {
                            if (!ele.permissions?.length || checkPermissions(ele.permissions)) {
                                return (
                                    <li key={ind}>
                                        <Link
                                            to={ele?.path ? ele?.path : `/links/${page}/${ele?.subPageName}`}
                                            className="home-link"
                                            title={ele.name}
                                        >
                                            <div className="card home-bx-card">
                                                <div className="icon">
                                                    <i className={ele.icon + " fa-3x"}></i>
                                                </div>
                                                <div className="home-bx-card-info">
                                                    <h3>{ele.name} </h3>
                                                    <p>{ele.description} </p>
                                                </div>
                                                    
                                            </div>
                                        </Link>
                                    </li>
                                )
                            }
                        }) : null}
                    </ul>

                    </div>
                    
                </div>
            </div>
        </>
    )

}

export default LandingPage;