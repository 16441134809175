import React from 'react'
import { AsyncPaginate } from "react-select-async-paginate";
const FilterComponent = ({ value, onChange, dataResponse, backendResponse, typeCheck }) => {

  const loadOptions = async (search, prevOptions) => {
    let options = dataResponse;
    let filteredOptions;
    if (!search) {
      filteredOptions = options;
    } else {
      const searchLower = search.toLowerCase();
      filteredOptions = options.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(prevOptions.length, prevOptions.length + 10);

    return { options: slicedOptions, hasMore, };
  };

  const handleFilterChange = (e) => {
    onChange(e);
    let arr = [];
    if (e) {
      e.map((val) => {
        if (typeCheck == "frontend") {
          arr.push(val.label)
        } else {
          arr.push(val.value);
        }
      });

      backendResponse(arr);
    } else {
      backendResponse([]);
    }
  };

  return (<AsyncPaginate value={value} loadOptions={loadOptions} onChange={handleFilterChange} menuIsOpen={true} isMulti />);
  
};

export default FilterComponent