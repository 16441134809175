import React, { useState } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import { UploadClassRec } from "../../../../services/CourseService";
import $ from 'jquery';
import PermissionsGate from "../../../../utils/permissionGate";

const ClassRecordingForm = (props) => {
  //   2.  Upload   -  Block  1.  Video Type (Dropdown list)  1.  Vimeo   2.  Youtube   (  Consider terminology, maybe “Online”)
  //   3.  File  2.  Vimeo Video Code (Text input)   –  Only visible if” Type” is Vimeo
  //   3.  URL (Text input)   –  Only visible if “Type” is Youtube
  //   4.  File upload  input   –  Only visible if Type is “  File


  const [state, setState] = useState("");
  const videotype = [
    { value: "Vimeo", label: "Vimeo" },
    { value: "Youtube", label: "Youtube" },
    { value: "File", label: "File" },
  ];
  const [fileValue, setFileValue] = useState();
  const [url, setUrl] = useState("");
  const [file, setFile] = useState({})
  const [fileName, setFileName] = useState("")
  const [error, setError] = useState("");

  // console.log("classId ++++++++ ", props)

  // useEffect(() => {

  // }, [])

  // function videoSelected(data) {
  //   if (data.video_type == 0 && data.file_type == "Manually") {
  //     setLink("http://vimeo.com/" + data.name, "_blank");
  //   } else if (data.video_type == 0 && data.file_type == "Recording") {
  //     setLink(data.record_url);
  //   }
  //   setState(data.value);
  // }

  // console.log("fileValue ===> ", fileValue);

  function upload() {
    if (state === "File" && !file) {
      setError("File is Required")
      return
    } else if (state === "Vimeo" && !url) {
      setError("Id is Required")
      return
    } else if (state === "Vimeo" && !((/^\d+$/).test(url))) {
      setError("Please Enter Valid Id")
      return
    } else if (state === "Youtube" && !url) {
      setError("URL is Required")
      return
    } else if (state === "Youtube" && !((/^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/).test(url))) {
      setError("Please Enter Valid URL")
      return
    } else if (!state) {
      setError("Video Type is Required")
      return
    }
    setError("")
    let formData = new FormData();
    formData.append("video_type", state == "Vimeo" ? 0 : state == "Youtube" ? 1 : 2);
    formData.append("portal_class_id", props.classId);
    formData.append("item_resource_url", state === "File" ? fileValue : url);
    // return
    UploadClassRec(formData).then(data => {
      if(data.status == 200) {
        Swal.fire({icon: "success",title: "Success",text: "Added successfully"});
        // $('#classrecording').modal('hide')
        setFileValue("");
        setUrl("")
        setFileName("")
        setFile()
        setError("")
        setState("")
        props.setUpdateRecordingList(!props.updateRecordingList)
      }
    }).catch(error => {
      console.log("error ---> ", error);
      Swal.fire({icon: "error",title: "Error",text: "Something Went Wrong"});
    })
  }

  function valueSelected(value) {
    if(value){
      setState(value.value)
    } else {
      setState("")
    }
    setFileValue("");
    setUrl("")
    setFileName("")
    setFile()
    setError("")
  }

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Upload</div>
      </div>
      <div className="topic-add-modal-content">
        <div className="row center-label">
          <div className="col-md-12">
            <div className="form-group-blk mb-3">
              <label>Video Type</label>
              <Select
                className={"form-control custom-select-box" + (error === "Video Type is Required" ? " is-invalid" : "")}
                value={videotype?.filter(val=>val.value === state)}
                onChange={(value) => valueSelected(value)}
                // onChange={(value) => {setState(value.value) }}
                options={videotype}
                maxMenuHeight={175}
                placeholder={state ? state : "Select video type"}
                isClearable
              />
            </div>
          </div>
          {state === "Youtube" ? <div className="col-md-12">
            <div className="form-group-blk mb-3">
              <label>URL</label>
              <input
                type="text"
                title="Enter URL"
                className={"form-control" + (error ? " is-invalid" : "")}
                placeholder="Enter URL"
                name="URL"
                id="URL"
                value={url || ""}
                onChange={e => setUrl(e.target.value)}
              />
            </div>
          </div> : state === "Vimeo" ? <><div className="col-md-4">
            <div className="form-group-blk mb-3">
              <label>Vimeo URL</label>
              <input
                type="text"
                title="Vimeo URL"
                className={"form-control"}
                placeholder="Vimeo URL"
                name="Vimeo URL"
                id="VimeoURL"
                value={"https://player.vimeo.com/video/"}
                disabled
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="form-group-blk mb-3">
              <label>Vimeo Id</label>
              <input
                type="text"
                title="Enter Vimeo Id"
                className={"form-control" + (error ? " is-invalid" : "")}
                placeholder="Enter Vimeo Id"
                name="Vimeo Id"
                id="VimeoId"
                value={url || ""}
                onChange={e => setUrl(e.target.value)}
              />
            </div>
          </div>
          </> : state === "File" ? <div className="col-md-12">
            <div className="form-group-blk mb-3">
              <label>Upload File</label>
              <div className="form-group atttach-file">
                <label>
                  <i className="fal fa-paperclip"></i>
                  <span>Add Attachment</span>
                  <input
                    title={
                      fileName
                        ? fileName
                        : "No File Uploaded"
                    }
                    type="file"
                    id="input"
                    className={"form-control  form-control-aatch-file" + (error ? " is-invalid" : "")}
                    name="file[]"
                    accept="video/mp4,video/x-m4v,video/*"
                    onChange={(e) => {
                      if (e.target.files.length) {
                        let reader = new FileReader();
                        reader.onload = (e) => {
                          setFile(e.target.result);
                        };
                        reader.readAsDataURL(e.target.files[0]);
                        // formik.setFieldValue(
                        //   "LetterheadFooter",
                        //   e.target.files[0]
                        // );
                        setFileValue(e.target.files[0]);
                        setFileName(e.target.files[0].name);
                      } else {
                        setFile(false);
                        setFileName("")
                        setFileValue()
                      }
                    }}
                  />
                </label>
              </div>
              {fileName && (
                <div className="frm-group">
                  <ul className="list-unstyled attached-file-ul m-0">
                    <li>
                      <a href={file} target="_blank">
                        <i className="fal fa-file"></i>
                        &nbsp;{fileName}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div> : ""}
        </div>
      </div>
      <div className="form-group form-group-save-cancel mt-3">
      <PermissionsGate scopes={["cedit"]} errorProps={{ disabled: true }}>
        <button type="button" className="btn btn-save btn-success" onClick={() => { upload() }}>
          <i className="fal fa-save"></i>
          Submit
        </button>
        </PermissionsGate>
        <button type="button" className="btn btn-close btn-danger" data-dismiss="modal">
          <i className="fal fa-times"></i>
          Close
        </button>
        <div className="invalid-feedback d-block">
          {error}
        </div>
      </div>
    </div>
  );
};

export default ClassRecordingForm;
