import axiosInstance from "../utils/axios";

export const AddProgram = async (values) => {
  return await axiosInstance().post(`/addProgramme`, values);
};

export const GetListofProgram = async () => {
  return await axiosInstance().post(`/listProgramme`, {});
};

export const GetListofProgramCancelToken = async (cancelToken) => {
  return await axiosInstance().post(`/listProgramme`, {}, {cancelToken});
};

export const GetListOfPMCAuditTrail = async (id) => {
  return await axiosInstance().get(`/listPMCAuditTrail${id?`?action_id=${id}`:""}`);
};
export const GetSchoolList = async () => {
  return await axiosInstance().get(`/getSchoolList`);
};
export const GetProgramDetail = async (values) => {
  return await axiosInstance().post(`/getPMCDetail`, values);
};

export const GetProgramDetailCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getPMCDetail`, values, {cancelToken});
};

export const GetListofModule = async () => {
  return await axiosInstance().post(`/listModules`, {});
};

export const GetListofModuleCancelToken = async (cancelToken) => {
  return await axiosInstance().post(`/listModules`, {}, {
    cancelToken: cancelToken
  });
};

export const GetProgramAssignedOrNotAssignedList = async (values) => {
  return await axiosInstance().post(`/getProgramAssignedOrNotAssignedList`, values);
};
export const GetProgramAssignedOrNotAssignedListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getProgramAssignedOrNotAssignedList`, values, {cancelToken});
};

export const AddModuleToProgramme = async (values) => {
  return await axiosInstance().post(`/addModuleToProgramme`, values);
};

export const RemoveModuleFromProgramme = async (values) => {
  return await axiosInstance().post(`/removeModuleFromProgramme`, values);
};

export const AddModule = async (values) => {
  return await axiosInstance().post(`/addModule`, values);
};

export const UpdateProgramme = async (values) => {
  return await axiosInstance().post(`/updateProgramme`, values);
};

export const GetModuleDetail = async (values) => {
  return await axiosInstance().post(`/getModuleDetail`, values);
};

export const GetModuleDetailCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getModuleDetail`, values, {cancelToken});
};

export const UpdateModule = async (values) => {
  return await axiosInstance().post(`/updateModule`, values);
};

export const CreateTopic = async (values) => {
  return await axiosInstance().post(`/createTopic`, values);
};

export const CreateFeaturesBenefits = async (values) => {
  return await axiosInstance().post(`/addEditBenefitAndFeature`, values);
};

export const ListTopics = async (values) => {
  return await axiosInstance().post(`/listTopics`, values);
};

export const ListTopicsCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/listTopics`, values, {cancelToken});
};

export const ListFeaturesBenefits = async (values,availble) => {
  return await axiosInstance().get(`/getBenefitAndFeatureList?programme_id=${values}&available=${availble}`);
};

export const ListFeaturesBenefitsCancelToken = async (values,availble,cancelToken) => {
  return await axiosInstance().get(`/getBenefitAndFeatureList?programme_id=${values}&available=${availble}`, {
    cancelToken: cancelToken
  });
};

export const DeleteTopic = async (values) => {
  return await axiosInstance().post(`/deleteTopic`, values);
};

export const DeleteProgramme = async (values) => {
  return await axiosInstance().post(`/deleteModule`, values);
};

export const getCommonContent = async (cancelToken) => {
  return await axiosInstance().get("/getCommonContent", {cancelToken});
};

export const GetModuleAudit = async (id) => {
  return await axiosInstance().get(`/getModuleAudit${id?`?action_id=${id}`:""}`);
};

export const GetModuleAuditCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getModuleAudit${id?`?action_id=${id}`:""}`, {
    cancelToken: cancelToken
  });
};

export const GetModuleOutcomesList = async (id) => {
  return await axiosInstance().get(`/getModuleOutcomesList?module_id=${id}`);
};

export const GetModuleOutcomesListCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getModuleOutcomesList?module_id=${id}`, {cancelToken});
};

export const AddEditModuleOutcomes = async (data) => {
  return await axiosInstance().post(`/addEditModuleOutcomes`, data);
};

export const DeleteModuleOutcomes = async (data) => {
  return await axiosInstance().post(`/deleteModuleOutcomes`, data);
};

export const DeleteBenefitAndFeature = async (data) => {
  return await axiosInstance().post(`/deleteBenefitAndFeature`, data);
};

export const DeletePmc  = async (data) => {
  return await axiosInstance().post(`/deletePmc`, data);
};

export const GetPMCPdf  = async (data) => {
  return await axiosInstance().post(`/getPMCPdf`, data);
};

export const GetProgrammeResourcesList = async (id) => {
  return await axiosInstance().get(`/getModuleResourcesList?module_id=${id}`);
};

export const GetProgrammeResourcesListCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getModuleResourcesList?module_id=${id}`, {cancelToken});
};

export const DeleteProgrammeResources  = async (data) => {
  return await axiosInstance().post(`/deleteModuleResources`, data);
};

export const AddEditModuleResources  = async (data) => {
  return await axiosInstance().post(`/addEditModuleResources`, data);
};

export const AssignedFeatureAndBenefit  = async (data) => {
  return await axiosInstance().post(`/assignedFeatureAndBenefit`, data);
};

export const UnlinkFeatureAndBenefit = async (data) => {
  return await axiosInstance().post(`/unlinkFeatureAndBenefit`, data);
};

export const ListModulesFilters = async () => {
  return await axiosInstance().get("/listModulesFilters");
};

export const ListModulesFiltersCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/listModulesFilters`, {
    cancelToken: cancelToken
  });
};

export const ListProgrammeFilters = async (cancelToken) => {
  return await axiosInstance().get("/listProgrammeFilters",{cancelToken});
};