import { tryParseJSONObject } from "./TryParseJSONObject";

export const formatQuestion = (question) => {
  let text;
  let check = tryParseJSONObject(question);
  if (check) {
    const questionBlock = JSON.parse(question).blocks;
    if(questionBlock){
      text = questionBlock.map((block) => (!block.text.trim() && "\n") || block.text).join("\n");
    }else{
      text = question ? question.toString().replace(/(<([^>]+)>)/gi, "").replace(/(?:\\[rn]|[\r\n]+)+/g, "") : "-";
    }

  } else {
    text = question ? question.toString().replace(/(<([^>]+)>)/gi, "").replace(/(?:\\[rn]|[\r\n]+)+/g, "") : "-";
  }
  return text.replace("\n", "");
};
