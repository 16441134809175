const initialState = {
  image: {},
};

const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_IMAGE":
      state.image = action.payload;
      return { image: state.image };
    default:
      return state;
  }
};

export default imageReducer;
