import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { GetCommonContent, GetStudentDropdown, StudentAccountDetailsCancelToken } from '../../../../services/StudentsSettingServices';
import hasPermission from '../../../../utils/hasMultiplePermission';
import AddressBlock from './AccountTabs/AddressBlock';
import BasicDetailsBlock from './AccountTabs/BasicDetailsBlock';
import ContactDetailsBlock from './AccountTabs/ContactDetailsBlock';
import DocumentsBlock from './AccountTabs/DocumentsBlock';
import LoginDetail from './AccountTabs/LoginDetail';
import GenerateStatement from './AccountTabs/GenerateStatement';
import axios from "axios";

function Account() {

  const { id } = useParams();

  const [deleteRow, setDeleteRow] = useState(true);

  const [sData, setSData] = useState();
  const [dropdownValues, setDropdownValues] = useState();
  const [genderArr, setGenderArr] = useState([]);

  const givenPermsisions = useSelector((state) => state.givenPermission);
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let response = hasPermission({ scopes: ["sapiview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])


  useEffect(() => {
    setLoading(true);
    GetStudentDropdown()
    .then((res) => setDropdownValues(res.data))
    .catch((err) => {
      console.log("err :", err)
    })
    GetCommonContent()
    .then((res) => {
      setDropdownValues(res.data)
      setLoading(false);
    })
    .catch((err) => {
      console.log("err :", err)
      setLoading(false);
    })
  }, [deleteRow])
  useEffect(() => {
    const cancelTokenSources = [];

    const getAccountDetail = async () => {
      // setloading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await StudentAccountDetailsCancelToken(id, source.token);
        if (res.status === 200) {
          setSData(res.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          // setloading(false);
        }
      }
    };
  
    getAccountDetail();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [deleteRow])
  
  return (
    <div className="card card-profile-info-card">
        <div className="">
          <div className="card-body-inr card-body-info">
            <BasicDetailsBlock setDeleteRow={setDeleteRow} loading={loading} deleteRow={deleteRow} studentData={sData && sData.payer} raceArr={dropdownValues ? dropdownValues.race : []} genderArr={dropdownValues ? dropdownValues.gender : []} />
            <hr/>
            <ContactDetailsBlock setDeleteRow={setDeleteRow} deleteRow={deleteRow} studentData={sData && sData.payer} />
            <hr/>
            <AddressBlock setDeleteRow={setDeleteRow} deleteRow={deleteRow} studentData={sData && sData.payer} />
            <hr/>
            <LoginDetail studentData={sData && sData.payer}/>
            <hr/>
            <GenerateStatement studentData={sData && sData.student}/>
            <hr/>
            <DocumentsBlock setDeleteRow={setDeleteRow} deleteRow={deleteRow} studentData={sData} docList={sData && sData.pdoc} />
          </div>
        </div>
      </div>
  )
}

export default Account