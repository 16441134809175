import { useFormik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import { IMAGE_URL } from '../../../../utils/Constants'
import { RenderAttachList } from '../../../common/RenderAttachList'
import { deletePdfLetter, StudentFileUploads } from "../../../../services/RegistrationService";
import Swal from "sweetalert2";

function QuoteAndPayment(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [studentData, setStudentData] = useState();
  const [documentFileUrl, setDocumentFileUrl] = useState("");
  const [otherDocumentFormList, setOtherDocumentFormList] = useState([]);


  const search = window.location.search;
  const params = new URLSearchParams(search);
  const studentId = params.get('studentId');


  useEffect(() => {
    setStudentData(props.studentData);
    setOtherDocumentFormList(props.studentData?.quote_payment)
    if (
      props.studentData &&
      props.studentData.quote_payment &&
      props.studentData.quote_payment[0]?.url.includes("/home/myaie/public_html/")
    ) {
      let DocLink = IMAGE_URL + "/" + props.studentData.quote_payment[0]?.url.replace("/home/myaie/public_html/", "");
      DocLink = DocLink.replace("//Library", "/Library");
      setDocumentFileUrl(DocLink);
    } else {
      setDocumentFileUrl("");
    }
  }, [props]);

  const formik = useFormikContext();

  const handledocEdit = (e) => {
    formik.setFieldValue("invoiceFormQuote", e.target.files[0])
    const formData = new FormData();
    formData.append("student_id", studentId)
    formData.append("type", "inv_form")
    formData.append("file_input", e.target.files[0])
    StudentFileUploads(formData)
      .then((res) => {
        // setAssemblyID(res?.data?.inserted_record);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message || "Updated Successfully"}`,
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.message}`,
        });
      });
    // setSelectedDocumentName(e.target.files[0].name)
  };



  const handleDelete = (id) => {
    // console.log(ID);
    // let ID = props.studentData.welcome_letter[0].id
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePdfLetter({ id: id }).then((res) => {});

        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const deleteImg = () => {
    formik.setFieldValue("invoiceFormQuote", "")
  }

  const basename = (path) => {
    return path.split("/").reverse()[0];
  };


  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Quote and Payment</div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label htmlFor="invoiceFormQuote">Invoice Form</label>
          <div className="form-group atttach-file ">
            <label className={formik.errors.invoiceFormQuote && formik.touched.invoiceFormQuote && "file-req is-invalid"}>
              <i className="fal fa-paperclip"></i>
              <span>Invoice Form</span>
              <input type="file" name="invoiceFormQuote" className="form-control  form-control-aatch-file" onChange={handledocEdit} />
            </label>
          </div>
          {formik.values.invoiceFormQuote && (
            <>
              <div className="frm-group">
                <ul className="list-unstyled attached-file-ul m-0">
                  <li>
                    {formik.values.invoiceFormQuote.name.toString()}
                    <button className="btn btn-danger" onClick={deleteImg}>
                      <i className="fal fa-trash-alt"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
        <div className="frm-group">
          <ul className="list-unstyled attached-file-ul m-0">
            {otherDocumentFormList && otherDocumentFormList.map((item) => {
              return (
                <li key={item.id} className="mb-2">
                  <div className="col-md-11">
                    {RenderAttachList({
                      fileName: basename(item.url),
                      // size: "1205",
                      filePath: IMAGE_URL + "/" + item.url.replace("home/myaie/public_html/", "").replace("public", ""),
                      showClose: false,
                    })}
                  </div>
                  <button title="Delete" type="button" className="btn btn-danger rounded-circle" onClick={() => handleDelete(item.id)}>
                    <i className="fal fa-trash-alt"></i>
                  </button>

                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default QuoteAndPayment;
