import React, { useState, useEffect, useMemo } from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";
import Tabs from "../Tabs";
import { getCurrentTimeHomePage } from "../../common/Helper";
import DataTable from "react-data-table-component";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import {
  AddAssembliesResources,
  GetAssemblyResourceDetail,
  EditAssemblyResourceDetail,
  GetListOfItemsResources,
  GetAssignedAssembliesToResource,
  DeleteAssignedAssemblyResource,
} from "../../../services/CourseService";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  GetListOfResources,
  DeleteAssemblyResource,
} from "../../../services/CourseService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import {
  ResourceType,
  ResourceTypeIcon,
} from "../../systemadministration/usermanagement/CheckRole";
import Select from "react-select";
import $ from "jquery";
import { RenderAttachList } from "../../common/RenderAttachList";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT, zipURL } from "../../../utils/Constants";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { handleTableScroll } from "../../../utils/commonFunction";

const ResourcePoolDetails = ({ setHeadingname }) => {

  const { tab, type, id, resourcePoolId, subTabId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const selectedTab = location.pathname;

  const [resourceDetail, setResourceDetail] = useState("");
  const [resources, setResources] = useState([]);
  const [linkedAssemblies, setLinkedAssemblies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBlock, setSelectedBlock] = useState("");
  const [deleteRow, setDeleteRow] = useState(false);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [selectedVideoType, setSelectedVideoType] = useState("");
  const [selectedSubData, setSelectedSubData] = useState("");
  const [selectedAddSubCatData, setSelectedAddSubCatData] = useState("");
  const [selectedDocument, setSelectedDocument] = useState("");

  const [selectedTypeEdit, setSelectedTypeEdit] = useState("");
  const [selectedDocumentTypeEdit, setSelectedDocumentTypeEdit] = useState("");
  const [selectedVideoTypeEdit, setSelectedVideoTypeEdit] = useState("");
  const [selectedDocumentEdit, setSelectedDocumentEdit] = useState("");
  const [documentFileUrl, setDocumentFileUrl] = useState("");

  const [selectedDocumentName, setSelectedDocumentName] = useState("");
  const [acceptName, setAcceptName] = useState("");
  const [acceptNameEdit, setAcceptNameEdit] = useState("");
  const [subParentItemId, setSubParentItemId] = useState("");
  const [itemType, setItemType] = useState([
    { value: 2, label: "Video" },
    { value: 3, label: "Sub Category" },
    { value: 4, label: "Document" },
    { value: 5, label: "Link" },
    { value: 6, label: "Assessment" },
    { value: 7, label: "Label" },
  ]);
  const [itemSubCatType, setItemSubCatType] = useState([
    { value: 2, label: "Video" },
    { value: 4, label: "Document" },
    { value: 5, label: "Link" },
    { value: 6, label: "Assessment" },
    { value: 7, label: "Label" },
  ]);
  const [documentType, setDocumentType] = useState([
    { value: 0, label: "Word" },
    { value: 1, label: "Excel" },
    { value: 2, label: "PDF" },
    { value: 3, label: "ZIP" },
    { value: 4, label: "Image" },
  ]);
  const [videoType, setVideoType] = useState([
    { value: 0, label: "Vimeo" },
    { value: 1, label: "Youtube" },
  ]);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  // useEffect(() => {
  //   // GetListOfAssembliesFilters().then((res) => {
  //   //   let x = {value: "",label: ""};
  //   //   x = res.data.item_version.map((e) => ({...x, value: `${e.title}`, label :`${e.title}` }))
  //   //   setVersionList(res?.data?.item_version);
  //   // })
  //   //   .catch((err) => console.log(err));

  //   type !== "create"
  //     ? GetAssemblyResourceDetail({ item_id: resourcePoolId }).then((res) => {
  //         setResourceDetail(res?.data?.resouce_detail);
  //       })
  //     : setResourceDetail("");
  // }, []);

  useEffect(() => {
    if (type !== "add") {
      getData();
    } else {
      setLoading(false);
      setResourceDetail("");
    }
  }, [deleteRow]);

  const getData = () => {
    GetAssemblyResourceDetail({ item_id: id }).then((res) => {
      setResourceDetail(res?.data?.resouce_detail);
      setHeadingname(res?.data?.resouce_detail?.item_name)
    });
    GetListOfItemsResources(id).then((res) => {
      setResources(res?.data?.assemblies_item_list?.data);
      setLoading(false);
    });
    GetAssignedAssembliesToResource(id).then((res) => {
      setLinkedAssemblies(res?.data?.list);
      setLoading(false);
    });
  };

  const deleteresource = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteAssignedAssemblyResource({ assign_id: id }).then((res) => {
            Swal.fire("Deleted!", `${res?.data?.message}`, "success");
            getData();
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      item_name: resourceDetail?.item_name,
      item_desc: resourceDetail?.item_desc,
    },
    validationSchema: Yup.object({
      item_name: Yup.string().required("Name is required"),
      item_desc: Yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      values.parent_id = 0;
      values.item_type = 1;
      // values.duplicate = "copy";
      // values.duplicate_id = id;

      if (type == "add" || type == "duplicate") {
        AddAssembliesResources(values)
          .then((res) => {
            // setAssemblyID(res?.data?.inserted_record);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: `${res?.data?.message}`,
            });
            history.push("/courses/content/resource-pool/table");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data?.message}`,
            });
          });
      } else {
        values.item_id = id;
        EditAssemblyResourceDetail(values)
          .then((res) => {
            // setAssemblyID(res?.data?.inserted_record);
            Swal.fire({
              icon: "success",
              title: "Success",
              text: `${res?.data?.message}`,
            });
            history.push("/courses/content/resource-pool/table");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data?.message}`,
            });
          });
      }
    },
  });

  const modalFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      item_type: "",
      item_name: "",
      item_desc: "",
      document_type: "",
      video_type: "",
      vimeoUrl: "",
      Url: "",
      label_desc: "",
      showDesc: true,
    },
    validationSchema: Yup.object({
      item_type: Yup.string().required("Type is required"),
      item_name: Yup.string().required("Name is required"),
      item_desc: Yup.string().required("Description is required"),
      // document_type: Yup.string().required("Type is required"),
      // video_type: Yup.string().required("Type is required"),
      // vimeoUrl: Yup.string().required("url is required"),
      // Url: Yup.string().required("url is required"),
      // label_desc: Yup.string().required("url is required"),
      // showDesc: Yup.string().required("url is required"),
    }),
    onSubmit: (values) => {
      values.parent_id = id;
      // values.item_type = modalFormik.values.item_type;


      const form = new FormData();
      form.append("item_type", values.item_type);
      form.append("item_name", values.item_name);
      form.append("item_desc", values.item_desc);
      form.append("parent_id", id);
      if (values.item_type == 2) {
        form.append("video_type", values.video_type);
        if (values.video_type == 0) {
          form.append("item_url", values.vimeoUrl);
        } else {
          form.append("youtube_url", values.Url);
        }
      }
      if (values.item_type == 4) {
        form.append("document_resource", selectedDocument);
        form.append("document_type", values.document_type);
      }
      if (values.item_type == 5) {
        form.append("link_url", values.Url);
      }
      if (values.item_type == 7) {
        form.append("label_show_description", values.showDesc == true ? 1 : 0);
      }

      AddAssembliesResources(form)
        .then((res) => {
          // setAssemblyID(res?.data?.inserted_record);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `${res?.data?.message}`,
          });
          // setSelectedDocument("");
          setDeleteRow(true);
          $("#launch").modal("hide");
          setSelectedType("");
          // history.push("/content/resource-pool");
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.message}`,
          });
        });
    },
  });

  const addSubModalFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      item_type: "",
      item_name: "",
      item_desc: "",
      document_type: "",
      video_type: "",
      vimeoUrl: "",
      Url: "",
      label_desc: "",
      showDesc: true,
    },
    validationSchema: Yup.object({
      item_type: Yup.string().required("Type is required"),
      item_name: Yup.string().required("Name is required"),
      item_desc: Yup.string().required("Description is required"),
      // document_type: Yup.string().required("Type is required"),
      // video_type: Yup.string().required("Type is required"),
      // vimeoUrl: Yup.string().required("url is required"),
      // Url: Yup.string().required("url is required"),
      // label_desc: Yup.string().required("url is required"),
      // showDesc: Yup.string().required("url is required"),
    }),
    onSubmit: (values) => {
      // values.item_type = modalFormik.values.item_type;

      // console.log("values=== after addSubModalFormik", values);

      const form = new FormData();
      form.append("item_type", values.item_type);
      form.append("item_name", values.item_name);
      form.append("item_desc", values.item_desc);
      form.append("parent_id", selectedAddSubCatData.item_id);
      // form.append("item_id", selectedAddSubCatData.item_id);
      if (values.item_type == 2) {
        form.append("video_type", values.video_type);
        if (values.video_type == 0) {
          form.append("item_url", values.vimeoUrl);
        } else {
          form.append("youtube_url", values.Url);
        }
      }
      if (values.item_type == 4) {
        form.append("document_resource", selectedDocument);
        form.append("document_type", values.document_type);
      }
      if (values.item_type == 5) {
        form.append("link_url", values.Url);
      }
      if (values.item_type == 7) {
        form.append("label_show_description", values.showDesc == true ? 1 : 0);
      }

      AddAssembliesResources(form)
        .then((res) => {
          // setAssemblyID(res?.data?.inserted_record);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `${res?.data?.message}`,
          });
          // setSelectedDocument("");
          setDeleteRow(true);
          setSelectedType("");
          addSubModalFormik.handleReset();
          $("#addSubCatModal").modal("hide");
          // history.push("/content/resource-pool");
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.message}`,
          });
        });
    },
  });

  const subModalFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      item_type: selectedSubData?.item_type,
      item_name: selectedSubData?.item_name,
      item_desc: selectedSubData?.item_desc,
      document_type: selectedSubData?.document_type,
      video_type: selectedSubData?.video_type,
      vimeoUrl: selectedSubData?.item_url,
      Url: selectedSubData?.youtube_url
        ? selectedSubData?.youtube_url
        : selectedSubData?.link_url,
      showDesc: selectedSubData?.label_show_description == 0 ? false : true,
    },
    // validationSchema: Yup.object({
    //   item_type: Yup.string().required("Type is required"),
    //   item_name: Yup.string().required("Name is required"),
    //   item_desc: Yup.string().required("Description is required"),
    //   document_type: Yup.string().required("Type is required"),
    //   video_type: Yup.string().required("Type is required"),
    //   vimeoUrl: Yup.string().required("url is required"),
    //   Url: Yup.string().required("url is required"),
    // }),
    onSubmit: (values) => {
      values.parent_id = id;
      // values.item_type = modalFormik.values.item_type;


      const form = new FormData();
      form.append("item_type", values.item_type);
      form.append("item_name", values.item_name);
      form.append("item_desc", values.item_desc);
      form.append("parent_id", subParentItemId ? subParentItemId : id);
      form.append("item_id", selectedSubData.item_id);

      if (values.item_type == 2) {
        form.append("video_type", values.video_type);
        if (values.video_type == 0) {
          form.append("item_url", values.vimeoUrl);
        } else {
          form.append("youtube_url", values.Url);
        }
      }
      if (values.item_type == 4) {
        if (selectedDocumentEdit) {
          form.append("document_resource", selectedDocumentEdit);
          // form.append("document_name",selectedDocumentName)
        }

        form.append("document_type", values.document_type);
      }
      if (values.item_type == 5) {
        form.append("link_url", values.Url);
      }
      if (values.item_type == 7) {
        form.append("label_show_description", values.showDesc == true ? 1 : 0);
      }

      EditAssemblyResourceDetail(form)
        .then((res) => {
          // setAssemblyID(res?.data?.inserted_record);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `${res?.data?.message}`,
          });
          // history.push("/content/resource-pool/open/"+resourcePoolId);
          setDeleteRow(true);
          setSelectedDocumentEdit("");
          setSubParentItemId("");
          $("#subDataModal").modal("hide");
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.message}`,
          });
        });
    },
  });

  // const AddSubCatModalFormik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     item_type: selectedSubData?.item_type,
  //     item_name: selectedSubData?.item_name,
  //     item_desc: selectedSubData?.item_desc,
  //     document_type: selectedSubData?.document_type,
  //     video_type: selectedSubData?.video_type,
  //     vimeoUrl: selectedSubData?.item_url,
  //     Url: selectedSubData?.youtube_url
  //       ? selectedSubData?.youtube_url
  //       : selectedSubData?.link_url,
  //     showDesc: selectedSubData?.label_show_description == 0 ? false : true,
  //   },
  //   // validationSchema: Yup.object({
  //   //   item_type: Yup.string().required("Type is required"),
  //   //   item_name: Yup.string().required("Name is required"),
  //   //   item_desc: Yup.string().required("Description is required"),
  //   //   document_type: Yup.string().required("Type is required"),
  //   //   video_type: Yup.string().required("Type is required"),
  //   //   vimeoUrl: Yup.string().required("url is required"),
  //   //   Url: Yup.string().required("url is required"),
  //   // }),
  //   onSubmit: (values) => {
  //     console.log("values===", values);
  //     values.parent_id = resourcePoolId;
  //     // values.item_type = modalFormik.values.item_type;

  //     console.log("values=== after", values);

  //     const form = new FormData();
  //     form.append("item_type", values.item_type);
  //     form.append("item_name", values.item_name);
  //     form.append("item_desc", values.item_desc);
  //     form.append("parent_id", resourcePoolId);
  //     form.append("item_id", selectedSubData.item_id);

  //     if (values.item_type == 2) {
  //       form.append("video_type", values.video_type);
  //       if (values.video_type == 0) {
  //         form.append("item_url", values.vimeoUrl);
  //       } else {
  //         form.append("youtube_url", values.Url);
  //       }
  //     }
  //     if (values.item_type == 4) {
  //       if (selectedDocumentEdit) {
  //         form.append("document_resource", selectedDocumentEdit);
  //         // form.append("document_name",selectedDocumentName)
  //       }

  //       form.append("document_type", values.document_type);
  //     }
  //     if (values.item_type == 5) {
  //       form.append("link_url", values.Url);
  //     }
  //     if (values.item_type == 7) {
  //       form.append("label_show_description", values.showDesc == true ? 1 : 0);
  //     }

  //     EditAssemblyResourceDetail(form)
  //       .then((res) => {
  //         // setAssemblyID(res?.data?.inserted_record);
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success",
  //           text: `${res?.data?.message}`,
  //         });
  //         // history.push("/content/resource-pool/open/"+resourcePoolId);
  //         setSelectedDocumentEdit("");
  //         setDeleteRow(true);
  //         $("#subDataModal").modal("hide");
  //       })
  //       .catch((err) => {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error",
  //           text: `${err?.response?.data?.message}`,
  //         });
  //       });
  //   },
  // });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (e) => {

    if (!e.destination) return;

    // let tempData = Array.from(users);
    // let [source_data] = tempData.splice(e.source.index, 1);
    // tempData.splice(e.destination.index, 0, source_data);

    const sourceIndex = e.source.index;
    const destIndex = e.destination.index;

    if (e.type === "outer") {
      let tempData = reorder(resources, sourceIndex, destIndex);
      setResources(tempData);
    }

    if (e.type === "inner") {
      const element = e.draggableId.split("+");
      // console.log("element",element[0])
      let newarray = resources[element[0]].sub_items;
      let tempdata = reorder(newarray, sourceIndex, destIndex);

      const newUsers = JSON.parse(JSON.stringify(resources));
      newUsers[element[0]].sub_items = tempdata;

      setResources(newUsers);

      // change the index of dummy array of user
      // [a[3], a[4]] = [a[4], a[3]]
      // [a[e.source.index],a[e.destination.index]] = [a[e.destination.index],a[e.source.index]]
    }
  };

  const handleBlock = (i) => {
    if (selectedBlock == "lowerBlock-" + i) {
      setSelectedBlock("");
    } else {
      setSelectedBlock("lowerBlock-" + i);
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAssemblyResource({ item_id: id }).then((res) =>
          setDeleteRow(!deleteRow)
        );
        Swal.fire("Deleted!", "Your resource has been deleted.", "success");
      }
    });
  };

  const handledoc = (e) => {
    setSelectedDocument(e.target.files[0]);
    setSelectedDocumentName(e.target.files[0].name);
  };

  const handledocEdit = (e) => {
    setSelectedDocumentEdit(e.target.files[0]);
    // setSelectedDocumentName(e.target.files[0].name)
  };

  const onSelectSubData = (resource, subItemResourceId) => {
    let type = ResourceType(resource?.item_type.toString());
    setSelectedTypeEdit(type);
    let docType =
      resource?.document_type == 0
        ? "Word"
        : resource?.document_type == 1
          ? "Excel"
          : resource?.document_type == 2
            ? "PDF"
            : resource?.document_type == 3
              ? "ZIP"
              : resource?.document_type == 4
                ? "Image"
                : resource?.document_type == 5
                ? "Powerpoint"
                : "";
    setSelectedDocumentTypeEdit(docType);
    let videoType =
      resource?.video_type == 0
        ? "Vimeo"
        : resource?.video_type == 1
          ? "Youtube"
          : "";
    setSelectedVideoTypeEdit(videoType);
    setSelectedSubData(resource);

    // if(resource && resource.documentName && resource.link.includes("https")){
    //   // link = resource.link
    //   setDocumentFileUrl(resource.documentName)
    // } else{
    if (
      resource &&
      resource.documentName &&
      resource.documentName.includes("public/")
    ) {
      let DocLink = zipURL + "/" + resource.documentName.replace("public/", "");
      setDocumentFileUrl(DocLink);
    } else {
      setDocumentFileUrl("");
    }
    // else if(resource && resource.documentName && !resource.documentName.includes("public/") && resource.type=="Link") {
    //   link = resource.link;
    // } else if(resource && resource.link && !resource.link.includes("public/")) {
    //   link = IMAGE_URL + "/" + resource.link;
    // }

    // }
    if (resource?.document_type == 5) {
      setAcceptNameEdit(".pptx, .ppt");
    }else if (resource?.document_type == 4) {
      setAcceptNameEdit("image/*");
    } else if (resource?.document_type == 0) {
      setAcceptNameEdit(".docx, .doc");
    } else if (resource?.document_type == 1) {
      setAcceptNameEdit(".xlsx");
    } else if (resource?.document_type == 2) {
      setAcceptNameEdit(".pdf");
    } else if (resource?.document_type == 3) {
      setAcceptNameEdit(".zip");
    }
    subItemResourceId && setSubParentItemId(subItemResourceId);
  };

  const dataToRender2 = () => {
    // console.log("linkedAssemblies",linkedAssemblies)

    let updatedData = [];
    let allData = linkedAssemblies;
    if (search.length) {
      let tempName = allData.filter((item) => {
        let includes = item.name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempIntake = allData.filter((item) => {
        let includes = item.intake_year && item.intake_year.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCourseType = allData.filter((item) => {
        let includes = item.item_version && item.item_version.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudyType = allData.filter((item) => {
        let includes = item.updated_at && item.updated_at.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });


      let data = [...tempName, ...tempIntake, ...tempCourseType, ...tempStudyType];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search.length) {
      return updatedData;
    } else {
      return allData;
    }
  };

  const resetFilter = () => {
    setSearch("");

  };

  const resetFilter2 = () => {
    setSearch2("");

  };

  const columns2 = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      // maxWidth: "80px",
      // minWidth: "80px",
      cell: (row) => (
        <>
          {/* <a onClick={() => history.push("/courses/content/assemblies/edit/" + row.assembly_id, { id: row.assembly_id })} className="as-text-blue curser "> */}
          <Link className="as-text-blue curser" to={{ pathname: `/courses/content/assemblies/edit/${row.assembly_id}`, state: { id: row.assembly_id } }} title={row.name ? row.name : "-"}>
            {row.name ? row.name : "-"}
          </Link>
          {/* </a> */}
        </>
      ),
    },
    {
      name: "Created By",
      selector: "user_name",
      sortable: true,
      cell: (row) => (<span title={row.user_name ? row.user_name : "-"}>{row.user_name ? row.user_name : "-"}</span>),
    },
    {
      name: "Created Date",
      selector: "updated_at",
      sortable: true,
      // cell: (row) => (row.updated_at ? row.updated_at : "NA"),
      cell: (row) => {
        return (
          <p>
            {row.updated_at ? (
              <div className="dateTime" title={moment(row.updated_at).format(TABLE_DATE_FORMAT) + " " + moment(row.updated_at).format(TABLE_TIME_FORMAT)}>
                <p className="right-space">{moment(row.updated_at).format(TABLE_DATE_FORMAT)}</p>
                <p>{moment(row.updated_at).format(TABLE_TIME_FORMAT)}</p>
              </div>
            ) : (
              "-"
            )}
          </p>
        )
      }
    },
    {
      name: "Version",
      selector: "item_version",
      sortable: true,
      cell: (row) => (<span title={row.item_version ? row.item_version : "NA"}>{row.item_version ? row.item_version : "NA"}</span>),
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <Link className="mr-1" to={{ pathname: `/courses/content/assemblies/edit/${row.assembly_id}`, state: { id: row.assembly_id } }} >
              <button className="btn btn-primary rounded-circle" title="Open" type="button">
                {/* <button className="btn btn-primary rounded-circle" title="Open" onClick={() => history.push("/courses/content/assemblies/edit/" + row.assembly_id, { id: row.assembly_id })}> */}
                <i className="fal fa-folder-open"></i>
              </button>
            </Link>
            <button className="btn btn-danger rounded-circle" title="Unlink" onClick={() => deleteresource(row?.assign_id)} >
              <i className="fal fa-trash-alt"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const columns = useMemo(() => [
    // {
    //   name: "",
    //   selector: "",
    //   sortable: false,
    //   maxWidth: "50px",
    //   minWidth: "50px",
    //   cell: (row, index) => (
    //     <div className="topic-icon">
    //       <i className="fal fa-arrows"></i>
    //     </div>
    //   ),
    // },
    // {
    //   name: "ID",
    //   selector: "item_id",
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="ticket-id">
    //       <span className="overflow-ellipsis2">
    //         <b>#{row.item_id}</b>
    //       </span>
    //     </div>
    //   ),
    // },
    {
      name: "Name",
      selector: "item_name",
      sortable: true,
      // wrap: true,
      cell: (row) => <Link to={{ pathname: `/content/resource-pool/open/${id}/sub-category/open/${row.item_id}`, state: { subCatDataDetail: row } }} className="as-text-blue curser " title={row.item_name}>
        {row.item_name}
      </Link>,
    },
    {
      name: "Type",
      selector: "item_type",
      // sortable: true,
      // wrap: true,
      cell: (row) => (
        <span title={ResourceType(row?.item_type.toString())}>
          {ResourceType(row?.item_type.toString())}
        </span>
      ),
    },
    {
      name: "Action",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <Link className={row?.item_type !== 3 ? "mr-1" : ""} to={{ pathname: `/content/resource-pool/open/${id}/sub-category/open/${row.item_id}`, state: { subCatDataDetail: row } }} >
              <button className="btn btn-primary rounded-circle" title="Open">
                {/* <button className="btn btn-primary rounded-circle" title="Open" onClick={() => history.push(`/content/resource-pool/open/${id}/sub-category/open/${row.item_id}`, { subCatDataDetail: row })}> */}
                <i className="fal fa-folder-open"></i>
              </button>
            </Link>
            {row?.item_type == 3 ? (
              <div className="dropdown btn-dropdown-item">
                <button
                  className="btn btn-primary rounded-circle dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  title="More"
                >
                  <i className="fal fa-ellipsis-h-alt"></i>
                </button>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    className="btn btn-primary rounded-circle"
                    title="Add"
                    data-toggle="modal"
                    data-target="#addSubCatModal"
                    onClick={() =>
                      // history.push(
                      //   `/content/resource-pool/duplicateAsNew/${resource.item_id}`
                      // )
                      // setSelectedSubData(resource)
                      setSelectedAddSubCatData(row)
                    }
                  >
                    <i className="fal fa-plus"></i>
                  </button>
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Delete"
                    onClick={() => handleDelete(row.item_id)}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            ) : (
              <button
                className="btn btn-danger rounded-circle"
                title="Delete"
                onClick={() => handleDelete(row.item_id)}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            )}
          </div>
        </div>
      ),
    },
  ]);

  const dataToRender = () => {
    let updatedData = [];
    // console.log("resources from dataToRender", resources);
    let allData = resources;
    if (search?.length) {
      let tempId = allData.filter((item) => {
        let includes = item.item_id.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempTabName = allData.filter((item) => {
        let includes =
          item.item_name &&
          item.item_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempType = allData.filter((item) => {
        let includes =
          item.item_type &&
          ResourceType(item?.item_type.toString()).toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        ...tempId,
        ...tempTabName,
        ...tempType,
        // ...tempVisible,
        // ...tempIcon,
        // ...tempLinkedBrand,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (search.length) {
      return updatedData;
    } else {
      return allData;
    }
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleSearchFilter2 = (e) => {
    const value = e.target.value;
    setSearch2(value);
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Type"];
    data = data?.map((row) => ({
      ...row,
      Name: row?.item_name || "-",
      Type: ResourceType(row?.item_type.toString()) || "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Name, row.Type];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const exportData2 = (fileType, fileName) => {
    let data = dataToRender2();
    const header = ["Name", "Created By", "Created Date", "Version"];
    data = data?.map((row) => ({
      ...row,
      Name: row?.name || "-",
      "Created By": row?.user_name || "-",
      "Created Date": moment(row.updated_at).format(TABLE_DATE_FORMAT) + " " + moment(row.updated_at).format(TABLE_TIME_FORMAT),
      "Version": row?.item_version || "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.name, row["user_name"], moment(row.updated_at).format(TABLE_DATE_FORMAT) + " " + moment(row.updated_at).format(TABLE_TIME_FORMAT), row["item_version"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <>
      <div className="card card-profile-info-card mb-30">
        <div className="card-body">
          <div className="new-card-header">
            <div className="card-header">Resource Details</div>
          </div>

          {/* <div className="card-body-inr card-body-info"></div> */}
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group-blk mb-3">
                  <label htmlFor="item_name">Name</label>
                  <input
                    type="text"
                    className={"form-control" + (formik.errors.item_name && formik.touched.item_name ? " is-invalid" : "")}
                    placeholder="Enter Name"
                    name="item_name"
                    id="item_name"
                    title="Name"
                    value={formik.values.item_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group-blk mb-3">
                  <label htmlFor="item_desc">Description</label>

                  <input
                    type="text"
                    className={"form-control" + (formik.errors.item_desc && formik.touched.item_desc ? " is-invalid" : "")}
                    placeholder="Enter Description"
                    name="item_desc"
                    id="item_desc"
                    title="Description"
                    value={formik.values.item_desc}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
            <div className="form-group form-group-save-cancel mt-4">
              <button className="btn btn-save btn-success" type="submit" title="Save" disabled={!formik.dirty}>
                <i className="fal fa-save"></i>Save
              </button>

              <button
                className="btn btn-close btn-danger"
                onClick={() => history.push("/courses/content/resource-pool/table")}
                type="button"
                title="Cancel"
              >
                <i className="fal fa-times"></i>Cancel
              </button>
              {formik.touched.item_name && formik.errors.item_name ? <div className="invalid-feedback d-block">{formik.errors.item_name}</div> : null}
              {formik.touched.item_desc && formik.errors.item_desc ? <div className="invalid-feedback d-block">{formik.errors.item_desc}</div> : null}
            </div>
          </form>

          <>
            {/* {type != "add" && (
              <>
                <div className="card-header mt-4">List of Resources</div>
                <div className="custom-table-div filter-search-icon card card-table-custom custom-table-expand-blk mb-4 ass-bel-stuff">
                  <div className="new-datatable-blk">
                    <div className="filter-search-bar-blk top-0">
                      <div className="filter-button-group">
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                        <div className="button-reset dropdown-comman">
                          <button className="btn btn-primary" title="Reset" onClick={resetFilter}>
                            <i className="fal fa-redo"></i>Reset
                          </button>
                        </div>

                        <div className="files-export-group">
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("xlsx", "Resources List");
                            }}
                            title="Export spreadsheet"
                          >
                            <i className="fal fa-file-excel icon"></i>
                          </button>

                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("csv", "Resources List");
                            }}
                            title="Export CSV"
                          >
                            <i className="fal fa-file-csv icon"></i>
                          </button>

                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("pdf", "Resources List");
                            }}
                            title="Export PDF"
                          >
                            <i className="fal fa-file-pdf icon"></i>
                          </button>
                        </div>
                      </div>
                      <div className="add-ticket-blk button-reset dropdown-comman">
                        <button
                          className="btn btn-primary"
                          title="New Resource"
                          onClick={() => history.push("/content/resource-pool/create/" + id + "/sub-category/add/")}
                        >
                          <i className="fal fa-plus"></i>New Resource
                        </button>
                      </div>
                    </div>
                    <div className="system-administration-table table-responsive">
                      <div className="table-responsive-div">
                        <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                          <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                            <div id="assessment-table-main_filter" className="dataTables_filter">
                              <label>
                                <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" value={search} onChange={handleSearchFilter} />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <SkeletonTicketList />
                  ) : (
                    <DataTable data={dataToRender()} defaultSortField="item_name" defaultSortAsc={true} noDataComponent={Str.noRecord} columns={columns} pagination={true} />
                  )}

                  
                  <div className="card-header mt-2">Linked Assemblies</div>
                  <div className="custom-table-div filter-search-icon card card-table-custom new-datatable-blk ">
                    <div className="filter-search-bar-blk">
                      <div className="filter-button-group">
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>

                        <div className="button-reset dropdown-comman">
                          <button className="btn btn-primary" onClick={resetFilter2} title="Reset" >
                            <i className="fal fa-redo"></i>Reset
                          </button>
                        </div>

                        <div className="files-export-group">
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData2("xlsx", "Linked Assemblies");
                            }}
                            title="Export spreadsheet"
                          >
                            <i className="fal fa-file-excel icon"></i>
                          </button>

                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData2("csv", "Linked Assemblies");
                            }}
                            title="Export CSV"
                          >
                            <i className="fal fa-file-csv icon"></i>
                          </button>

                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData2("pdf", "Linked Assemblies");
                            }}
                            title="Export PDF"
                          >
                            <i className="fal fa-file-pdf icon"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="system-administration-table table-responsive">
                      <div className="table-responsive-div">
                        <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                          <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                            <div id="assessment-table-main_filter" className="dataTables_filter">
                              <label>
                                <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" value={search2} onChange={handleSearchFilter2} />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    {!loading ? (
                      <DataTable data={dataToRender2()} defaultSortField="updated_at" defaultSortAsc={true} noDataComponent={Str.noRecord} columns={columns2} pagination={true} />
                    ) : (
                      <SkeletonTicketList />
                    )}
                  </div>
                </div>
              </>
            )} */}

            {/* {type !== "create" && resources.length > 0 ? (
                          <div className="custom-table-expandable App mt-5">
                            <DragDropContext onDragEnd={handleDragEnd}>
                              <table className="table borderd">
                                <thead>
                                  <tr>
                                    <th />
                                    <th />
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Actions </th>
                                  </tr>
                                </thead>
                                <Droppable
                                  droppableId="droppable-1"
                                  type="outer"
                                >
                                  {(provider) => (
                                    <tbody
                                      className="text-capitalize"
                                      ref={provider.innerRef}
                                      {...provider.droppableProps}
                                    >
                                      {resources?.map((resource, index) => (
                                        <Draggable
                                          key={index}
                                          draggableId={`${resource.item_id}`}
                                          index={index}
                                        >
                                          {(provider) => {
                                            return (
                                              <>
                                                <tr
                                                  {...provider.draggableProps}
                                                  ref={provider.innerRef}
                                                >
                                                  <td
                                                    {...provider.dragHandleProps}
                                                  >
                                                    <i className="fal fa-arrows"></i>
                                                  </td>
                                                  <td>
                                                    {resource?.item_type ==
                                                    3 ? (
                                                      <button
                                                        type="button"
                                                        className="btn expand-btn"
                                                        data-toggle="toggle"
                                                        onClick={() => {
                                                          handleBlock(index);
                                                        }}
                                                      >
                                                        <i className="fas fa-caret-down"></i>
                                                      </button>
                                                    ) : (
                                                      ResourceTypeIcon(
                                                        resource?.item_type.toString()
                                                      )
                                                    )}
                                                  </td>

                                                  <td>{resource.item_name}</td>
                                                  <td>
                                                    {resource?.item_type
                                                      ? ResourceType(
                                                          resource?.item_type.toString()
                                                        )
                                                      : "-"}
                                                  </td>
                                                  <td>
                                                    <div className="assessment-08 btn-dropdown-grp">
                                                      <div className="as-buttons">
                                                        <button
                                                          className="btn btn-primary rounded-circle"
                                                          title="Open"
                                                          data-toggle="modal"
                                                          data-target="#subDataModal"
                                                          onClick={() =>
                                                            // history.push(
                                                            //   `/content/resource-pool/open/${resourcePoolId}/sub-category/open/${resource.item_id}`
                                                            // )
                                                            onSelectSubData(
                                                              resource
                                                            )
                                                          }
                                                        >
                                                          <i className="fal fa-folder-open"></i>
                                                        </button>
                                                        {resource?.item_type ==
                                                        3 ? (
                                                          <div className="dropdown btn-dropdown-item">
                                                            <button
                                                              className="btn btn-primary rounded-circle dropdown-toggle"
                                                              type="button"
                                                              id="dropdownMenuButton"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false"
                                                            >
                                                              <i className="fal fa-ellipsis-h-alt"></i>
                                                            </button>

                                                            <div
                                                              className="dropdown-menu"
                                                              aria-labelledby="dropdownMenuButton"
                                                            >
                                                              <button
                                                                className="btn btn-primary rounded-circle"
                                                                title="Duplicate"
                                                                data-toggle="modal"
                                                                data-target="#addSubCatModal"
                                                                onClick={() =>
                                                                  // history.push(
                                                                  //   `/content/resource-pool/duplicateAsNew/${resource.item_id}`
                                                                  // )
                                                                  // setSelectedSubData(resource)
                                                                  setSelectedAddSubCatData(
                                                                    resource
                                                                  )
                                                                }
                                                              >
                                                                <i className="fal fa-plus"></i>
                                                              </button>
                                                              <button
                                                                className="btn btn-danger rounded-circle"
                                                                title="Delete"
                                                                onClick={() =>
                                                                  handleDelete(
                                                                    resource.item_id
                                                                  )
                                                                }
                                                              >
                                                                <i className="fal fa-trash-alt"></i>
                                                              </button>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <button
                                                            className="btn btn-danger rounded-circle"
                                                            title="Delete"
                                                            onClick={() =>
                                                              handleDelete(
                                                                resource.item_id
                                                              )
                                                            }
                                                          >
                                                            <i className="fal fa-trash-alt"></i>
                                                          </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </td>
                                                </tr>

                                                {selectedBlock ==
                                                  `lowerBlock-${index}` && (
                                                  <tr className="hideTr">
                                                    <td colSpan={6}>
                                                      {resource?.sub_items
                                                        ?.length > 0 ? (
                                                        <Droppable
                                                          droppableId={`droppable-inner-${index}`}
                                                          type="inner"
                                                        >
                                                          {(nprovider) => (
                                                            <div className="custom-table-div card-table-custom">
                                                              <div
                                                                className="rdt_Table"
                                                                ref={
                                                                  nprovider.innerRef
                                                                }
                                                                {...nprovider.droppableProps}
                                                              >
                                                                {resource?.sub_items?.map(
                                                                  (
                                                                    item,
                                                                    index1
                                                                  ) => (
                                                                    <Draggable
                                                                      key={
                                                                        index1
                                                                      }
                                                                      draggableId={`${index}+${item.item_id}`}
                                                                      index={
                                                                        index1
                                                                      }
                                                                    >
                                                                      {(
                                                                        nprovider
                                                                      ) => {
                                                                        // console.log("nested", nprovider.innerRef);
                                                                        return (
                                                                          <div
                                                                            className="rdt_TableRow"
                                                                            {...nprovider.draggableProps}
                                                                            ref={
                                                                              nprovider.innerRef
                                                                            }
                                                                          >
                                                                            <div
                                                                              className="rdt_TableCell"
                                                                              {...nprovider.dragHandleProps}
                                                                            >
                                                                              <i className="fal fa-arrows"></i>
                                                                            </div>
                                                                            <div className="rdt_TableCell">
                                                                              {
                                                                                item.item_name
                                                                              }
                                                                            </div>
                                                                            <div className="expand-btn1">
                                                                            <div className="assessment-08 btn-dropdown-grp">
                                                                              <div className="as-buttons">
                                                                                <button
                                                                                  className="btn btn-primary rounded-circle"
                                                                                  title="Open"
                                                                                  data-toggle="modal"
                                                                                  data-target="#subDataModal"
                                                                                  onClick={() =>
                                                                                    // history.push(
                                                                                    //   `/content/resource-pool/open/${resourcePoolId}/sub-category/open/${resource.item_id}`
                                                                                    // )
                                                                                    onSelectSubData(
                                                                                      item, resource.item_id
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <i className="fal fa-folder-open"></i>
                                                                                </button>
                                                                                <button
                                                                                    className="btn btn-danger rounded-circle"
                                                                                    title="Delete"
                                                                                    onClick={() =>
                                                                                      handleDelete(
                                                                                        item.item_id
                                                                                      )
                                                                                    }
                                                                                  >
                                                                                    <i className="fal fa-trash-alt"></i>
                                                                                  </button>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                            
                                                                          </div>
                                                                        );
                                                                      }}
                                                                    </Draggable>
                                                                  )
                                                                )}
                                                              </div>
                                                            </div>
                                                          )}
                                                        </Droppable>
                                                      ) : (
                                                        "No data"
                                                      )}
                                                    </td>
                                                  </tr>
                                                )}
                                              </>
                                            );
                                          }}
                                        </Draggable>
                                      ))}
                                    </tbody>
                                  )}
                                </Droppable>
                              </table>
                            </DragDropContext>

                            <div className="table-pagination">
                              <nav className="dKvXPZ rdt_Pagination">
                                <span className="kkNaNQ exREar">
                                  Rows per page:
                                </span>
                                <div className="gENuGq">
                                  <select
                                    aria-label="Rows per page:"
                                    className="sc-iwjdpV gKsGGb"
                                  >
                                    <option value="10" selected="">
                                      10
                                    </option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="25">25</option>
                                    <option value="30">30</option>
                                  </select>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M7 10l5 5 5-5z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                  </svg>
                                </div>
                                <span className="kkNaNQ bqKZDG">1-2 of 2</span>
                                <div className="hGeawu">
                                  <button
                                    id="pagination-first-page"
                                    type="button"
                                    aria-label="First Page"
                                    aria-disabled="true"
                                    disabled
                                    className="iNniLt"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      aria-hidden="true"
                                      role="presentation"
                                    >
                                      <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                      <path
                                        fill="none"
                                        d="M24 24H0V0h24v24z"
                                      ></path>
                                    </svg>
                                  </button>
                                  <button
                                    id="pagination-previous-page"
                                    type="button"
                                    aria-label="Previous Page"
                                    aria-disabled="true"
                                    disabled
                                    className="iNniLt"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      aria-hidden="true"
                                      role="presentation"
                                    >
                                      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                      <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                    </svg>
                                  </button>
                                  <button
                                    id="pagination-next-page"
                                    type="button"
                                    aria-label="Next Page"
                                    aria-disabled="true"
                                    disabled
                                    className="iNniLt"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      aria-hidden="true"
                                      role="presentation"
                                    >
                                      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                      <path
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                    </svg>
                                  </button>
                                  <button
                                    id="pagination-last-page"
                                    type="button"
                                    aria-label="Last Page"
                                    aria-disabled="true"
                                    disabled
                                    className="iNniLt"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      aria-hidden="true"
                                      role="presentation"
                                    >
                                      <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                      <path
                                        fill="none"
                                        d="M0 0h24v24H0V0z"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>
                              </nav>
                            </div>
                          </div>
                        ) : loading ? (
                          <SkeletonTicketList />
                        ) : (
                          <div>There are no records</div>
                        )} */}
          </>
          {/* ) : loading ? (
                      <SkeletonTicketList />
                    ) : null} */}
        </div>
      </div>

      {/* add new resource modal popup */}

      <div className="topic-add-modal modal fade" id="launch" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="topic-add-modal-content text-center font-weight-bold">Add Item</div>
              <hr />
              <form onSubmit={modalFormik.handleSubmit}>
                <div className="topic-add-modal-content">
                  <div className="row">
                    <div className="col-2 font-weight-bold">Item Type</div>
                    <div className="col-10">
                      <div className="form-group-blk mb-3">
                        <Select
                          className={"form-control custom-select-box " + (modalFormik.errors.item_type && modalFormik.touched.item_type ? " is-invalid" : "")}
                          name="item_type"
                          value={selectedType || ""}
                          onChange={(value) => {
                            modalFormik.setFieldValue("item_type", value.value);
                            setSelectedType(value.label);
                          }}
                          onBlur={modalFormik.handleBlur}
                          options={itemType}
                          maxMenuHeight={175}
                          placeholder={selectedType ? selectedType : "Select Type"}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-2 font-weight-bold">Name</div>
                    <div className="col-10">
                      <div className="form-group-blk mb-3">
                        <input
                          type="text"
                          className={"form-control " + (modalFormik.errors.item_name && modalFormik.touched.item_name ? " is-invalid" : "")}
                          placeholder="Enter Name"
                          name="item_name"
                          title="Name"
                          value={modalFormik.values.item_name}
                          onChange={modalFormik.handleChange}
                          onBlur={modalFormik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-2 font-weight-bold">Description</div>
                    <div className="col-10">
                      <div className="form-group-blk mb-3">
                        <input
                          type="text"
                          className={"form-control " + (modalFormik.errors.item_desc && modalFormik.touched.item_desc ? " is-invalid" : "")}
                          placeholder="Enter Description"
                          name="item_desc"
                          title="Description"
                          value={modalFormik.values.item_desc}
                          onChange={modalFormik.handleChange}
                          onBlur={modalFormik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                {selectedType == "Document" && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">Document Type</div>
                        <div className="col-10">
                          <div className="form-group-blk mb-3">
                            <Select
                              className={"form-control custom-select-box " + (modalFormik.errors.document_type && modalFormik.touched.document_type ? " is-invalid" : "")}
                              name="document_type"
                              value={selectedDocumentType || ""}
                              onChange={(value) => {
                                modalFormik.setFieldValue("document_type", value.value);
                                setSelectedDocumentType(value.label);
                                if (value.label == "Image") {
                                  setAcceptName("image/*");
                                } else if (value.label == "Word") {
                                  setAcceptName(".docx, .doc");
                                } else if (value.label == "Excel") {
                                  setAcceptName(".xlsx");
                                } else if (value.label == "PDF") {
                                  setAcceptName(".pdf");
                                } else if (value.label == "ZIP") {
                                  setAcceptName(".zip");
                                }else if (value.label == "Powerpoint") {
                                  setAcceptName(".pptx, .ppt");
                                }
                              }}
                              onBlur={modalFormik.handleBlur}
                              options={documentType}
                              maxMenuHeight={175}
                              placeholder={selectedDocumentType ? selectedDocumentType : "Select Type"}
                            />
                          </div>
                          <input type="file" name="document" accept={acceptName} onChange={handledoc} disabled={selectedDocumentType ? false : true} />
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                {selectedType == "Video" && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">Video Type</div>
                        <div className="col-10">
                          <div className="form-group-blk mb-3">
                            <Select
                              className={"form-control custom-select-box " + (modalFormik.errors.video_type && modalFormik.touched.video_type ? " is-invalid" : "")}
                              name="video_type"
                              value={selectedVideoType || ""}
                              onChange={(value) => {
                                modalFormik.setFieldValue("video_type", value.value);
                                setSelectedVideoType(value.label);
                              }}
                              onBlur={modalFormik.handleBlur}
                              options={videoType}
                              maxMenuHeight={175}
                              placeholder={selectedVideoType ? selectedVideoType : "Select Type"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                {selectedVideoType == "Vimeo" && selectedType == "Video" && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">Vimeo Video Code</div>
                        <div className="col-5">
                          <div className="form-group-blk mb-3">
                            <input
                              type="text"
                              title="Vimeo Video Code"
                              className={"form-control "}
                              placeholder="Enter Vimeo Url"
                              name="vimeoBaseUrl"
                              value={"https://player.vimeo.com/video/"}
                              disabled
                            // onChange={modalFormik.handleChange}
                            // onBlur={modalFormik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="form-group-blk mb-3">
                            <input
                              type="text"
                              className={"form-control " + (modalFormik.errors.vimeoUrl && modalFormik.touched.vimeoUrl ? " is-invalid" : "")}
                              placeholder="Enter Vimeo Url"
                              name="vimeoUrl"
                              title="Vimeo Url"
                              value={modalFormik.values.vimeoUrl}
                              onChange={modalFormik.handleChange}
                              onBlur={modalFormik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold"></div>
                        <div className="col-5"></div>
                        <div className="col-5">
                          <div className="form-group-blk mb-3">(Ex: https://player.vimeo.com/video/xxxxxxxx)</div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                {selectedVideoType == "Youtube" && selectedType == "Video" && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">URL</div>
                        <div className="col-10">
                          <div className="form-group-blk mb-3">
                            <input
                              type="text"
                              title="Url"
                              className={"form-control " + (modalFormik.errors.Url && modalFormik.touched.Url ? " is-invalid" : "")}
                              placeholder="Enter Youtube Url"
                              name="Url"
                              value={modalFormik.values.Url}
                              onChange={modalFormik.handleChange}
                              onBlur={modalFormik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </>
                )}
                {selectedType == "Link" && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">URL</div>
                        <div className="col-10">
                          <div className="form-group-blk mb-3">
                            <input
                              type="text"
                              title="Url"
                              className={"form-control " + (modalFormik.errors.Url && modalFormik.touched.Url ? " is-invalid" : "")}
                              placeholder="Enter Youtube Url"
                              name="Url"
                              value={modalFormik.values.Url}
                              onChange={modalFormik.handleChange}
                              onBlur={modalFormik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </>
                )}

                {selectedType == "Label" && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">Description</div>
                        <div className="col-10">
                          <div className="custom-control custom-checkbox text-left mt-2">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="showDesc"
                              name="showDesc"
                              title="Description"
                              checked={subModalFormik.values.showDesc}
                              onChange={subModalFormik.handleChange}
                              onBlur={subModalFormik.handleBlur}
                            />
                            <label className="custom-control-label" htmlFor="showDesc"></label>
                            {/* <textarea
                    cols={10}
                        type="text"
                        className={"form-control " + (modalFormik.errors.label_desc && modalFormik.touched.label_desc ? " is-invalid" : "")}
                        placeholder="Enter Description"
                        name="label_desc"
                        value={modalFormik.values.label_desc}
                        onChange={modalFormik.handleChange}
                        onBlur={modalFormik.handleBlur}
                      /> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </>
                )}

                <div className="form-group form-group-save-cancel mt-4">
                  <button className="btn btn-save btn-success" type="submit" title="Save">
                    <i className="fal fa-save"></i>Save
                  </button>

                  <button className="btn btn-close btn-danger" type="button" title="Cancel" data-dismiss="modal">
                    <i className="fal fa-times"></i>Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* add new resource modal popup */}

      {/* Add new sub category modal popup */}
      <div className="topic-add-modal modal fade" id="addSubCatModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="topic-add-modal-content text-center font-weight-bold">Add Item</div>
              <hr />
              <form onSubmit={addSubModalFormik.handleSubmit}>
                <div className="topic-add-modal-content">
                  <div className="row">
                    <div className="col-2 font-weight-bold">Item Type</div>
                    <div className="col-10">
                      <div className="form-group-blk mb-3">
                        <Select
                          className={"form-control custom-select-box " + (addSubModalFormik.errors.item_type && addSubModalFormik.touched.item_type ? " is-invalid" : "")}
                          name="item_type"
                          value={selectedType || ""}
                          onChange={(value) => {
                            addSubModalFormik.setFieldValue("item_type", value.value);
                            setSelectedType(value.label);
                          }}
                          onBlur={addSubModalFormik.handleBlur}
                          options={itemSubCatType}
                          maxMenuHeight={175}
                          placeholder={selectedType ? selectedType : "Select Type"}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-2 font-weight-bold">Name</div>
                    <div className="col-10">
                      <div className="form-group-blk mb-3">
                        <input
                          type="text"
                          className={"form-control " + (addSubModalFormik.errors.item_name && addSubModalFormik.touched.item_name ? " is-invalid" : "")}
                          placeholder="Enter Name"
                          name="item_name"
                          title="Name"
                          value={addSubModalFormik.values.item_name}
                          onChange={addSubModalFormik.handleChange}
                          onBlur={addSubModalFormik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-2 font-weight-bold">Description</div>
                    <div className="col-10">
                      <div className="form-group-blk mb-3">
                        <input
                          type="text"
                          title="Description"
                          className={"form-control " + (addSubModalFormik.errors.item_desc && addSubModalFormik.touched.item_desc ? " is-invalid" : "")}
                          placeholder="Enter Description"
                          name="item_desc"
                          value={addSubModalFormik.values.item_desc}
                          onChange={addSubModalFormik.handleChange}
                          onBlur={addSubModalFormik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                {selectedType == "Document" && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">Document Type</div>
                        <div className="col-10">
                          <div className="form-group-blk mb-3">
                            <Select
                              className={"form-control custom-select-box " + (addSubModalFormik.errors.document_type && addSubModalFormik.touched.document_type ? " is-invalid" : "")}
                              name="document_type"
                              value={selectedDocumentType || ""}
                              onChange={(value) => {
                                addSubModalFormik.setFieldValue("document_type", value.value);
                                setSelectedDocumentType(value.label);
                                if (value.label == "Image") {
                                  setAcceptName("image/*");
                                } else if (value.label == "Word") {
                                  setAcceptName(".docx, .doc");
                                } else if (value.label == "Excel") {
                                  setAcceptName(".xlsx");
                                } else if (value.label == "PDF") {
                                  setAcceptName(".pdf");
                                } else if (value.label == "ZIP") {
                                  setAcceptName(".zip");
                                }else if (value.label == "Powerpoint") {
                                  setAcceptName(".pptx, .ppt");
                                }
                              }}
                              onBlur={addSubModalFormik.handleBlur}
                              options={documentType}
                              maxMenuHeight={175}
                              placeholder={selectedDocumentType ? selectedDocumentType : "Select Type"}
                            />
                          </div>
                          <input type="file" name="document" accept={acceptName} onChange={handledoc} disabled={selectedDocumentType ? false : true} />
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                {selectedType == "Video" && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">Video Type</div>
                        <div className="col-10">
                          <div className="form-group-blk mb-3">
                            <Select
                              className={"form-control custom-select-box " + (addSubModalFormik.errors.video_type && addSubModalFormik.touched.video_type ? " is-invalid" : "")}
                              name="video_type"
                              value={selectedVideoType || ""}
                              onChange={(value) => {
                                addSubModalFormik.setFieldValue("video_type", value.value);
                                setSelectedVideoType(value.label);
                              }}
                              onBlur={addSubModalFormik.handleBlur}
                              options={videoType}
                              maxMenuHeight={175}
                              placeholder={selectedVideoType ? selectedVideoType : "Select Type"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                {selectedVideoType == "Vimeo" && selectedType == "Video" && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">Vimeo Video Code</div>
                        <div className="col-5">
                          <div className="form-group-blk mb-3">
                            <input
                              type="text"
                              title="Vimeo Video Code"
                              className={"form-control "}
                              placeholder="Enter Vimeo Url"
                              name="vimeoBaseUrl"
                              value={"https://player.vimeo.com/video/"}
                              disabled
                            // onChange={addSubModalFormik.handleChange}
                            // onBlur={addSubModalFormik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="form-group-blk mb-3">
                            <input
                              type="text"
                              className={"form-control " + (addSubModalFormik.errors.vimeoUrl && addSubModalFormik.touched.vimeoUrl ? " is-invalid" : "")}
                              placeholder="Enter Vimeo Url"
                              name="vimeoUrl"
                              title="Vimeo Url"
                              value={addSubModalFormik.values.vimeoUrl}
                              onChange={addSubModalFormik.handleChange}
                              onBlur={addSubModalFormik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold"></div>
                        <div className="col-5"></div>
                        <div className="col-5">
                          <div className="form-group-blk mb-3">(Ex: https://player.vimeo.com/video/xxxxxxxx)</div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                {selectedVideoType == "Youtube" && selectedType == "Video" && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">URL</div>
                        <div className="col-10">
                          <div className="form-group-blk mb-3">
                            <input
                              type="text"
                              className={"form-control " + (addSubModalFormik.errors.Url && addSubModalFormik.touched.Url ? " is-invalid" : "")}
                              placeholder="Enter Youtube Url"
                              name="Url"
                              title="Url"
                              value={addSubModalFormik.values.Url}
                              onChange={addSubModalFormik.handleChange}
                              onBlur={addSubModalFormik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </>
                )}
                {selectedType == "Link" && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">URL</div>
                        <div className="col-10">
                          <div className="form-group-blk mb-3">
                            <input
                              type="text"
                              className={"form-control " + (addSubModalFormik.errors.Url && addSubModalFormik.touched.Url ? " is-invalid" : "")}
                              placeholder="Enter Youtube Url"
                              title="Url"
                              name="Url"
                              value={addSubModalFormik.values.Url}
                              onChange={addSubModalFormik.handleChange}
                              onBlur={addSubModalFormik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </>
                )}

                {selectedType == "Label" && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">Description</div>
                        <div className="col-10">
                          <div className="custom-control custom-checkbox text-left mt-2">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="showDesc"
                              name="showDesc"
                              title="Description"
                              checked={subModalFormik.values.showDesc}
                              onChange={subModalFormik.handleChange}
                              onBlur={subModalFormik.handleBlur}
                            />
                            <label className="custom-control-label" htmlFor="showDesc"></label>
                            {/* <textarea
                    cols={10}
                        type="text"
                        className={"form-control " + (addSubModalFormik.errors.label_desc && addSubModalFormik.touched.label_desc ? " is-invalid" : "")}
                        placeholder="Enter Description"
                        name="label_desc"
                        value={addSubModalFormik.values.label_desc}
                        onChange={addSubModalFormik.handleChange}
                        onBlur={addSubModalFormik.handleBlur}
                      /> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </>
                )}

                <div className="form-group form-group-save-cancel mt-4">
                  <button className="btn btn-save btn-success" type="submit" title="Save">
                    <i className="fal fa-save"></i>Save
                  </button>

                  <button className="btn btn-close btn-danger" type="button" title="Cancel" data-dismiss="modal">
                    <i className="fal fa-times"></i>Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Add new sub category modal popup */}

      {/* add sub modal popup */}
      <div className="topic-add-modal modal fade" id="subDataModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="topic-add-modal-content text-center font-weight-bold">Add Item</div>
              <hr />
              {/* 2: Video, 3: Sub Category, 4: Document, 5: Link, 6: Assessment, 7: Label}
               */}
              <form onSubmit={subModalFormik.handleSubmit}>
                <div className="topic-add-modal-content">
                  <div className="row">
                    <div className="col-2 font-weight-bold">Item Type</div>
                    <div className="col-10">
                      <div className="form-group-blk ">
                        <Select
                          className={"form-control custom-select-box " + (subModalFormik.errors.item_type && subModalFormik.touched.item_type ? " is-invalid" : "")}
                          name="item_type"
                          value={selectedTypeEdit || ""}
                          onChange={(value) => {
                            subModalFormik.setFieldValue("item_type", value.value);
                            setSelectedTypeEdit(value.label);
                          }}
                          onBlur={subModalFormik.handleBlur}
                          options={itemType}
                          maxMenuHeight={175}
                          placeholder={selectedTypeEdit ? selectedTypeEdit : "Select Type"}
                          isDisabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-2 font-weight-bold">Name</div>
                    <div className="col-10">
                      <div className="form-group-blk mb-3">
                        <input
                          type="text"
                          className={"form-control " + (subModalFormik.errors.item_name && subModalFormik.touched.item_name ? " is-invalid" : "")}
                          placeholder="Enter Name"
                          name="item_name"
                          title="Name"
                          value={subModalFormik.values.item_name}
                          onChange={subModalFormik.handleChange}
                          onBlur={subModalFormik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-2 font-weight-bold">Description</div>
                    <div className="col-10">
                      <div className="form-group-blk mb-3">
                        <input
                          type="text"
                          className={"form-control " + (subModalFormik.errors.item_desc && subModalFormik.touched.item_desc ? " is-invalid" : "")}
                          placeholder="Enter Description"
                          name="item_desc"
                          title="Description"
                          value={subModalFormik.values.item_desc}
                          onChange={subModalFormik.handleChange}
                          onBlur={subModalFormik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                {selectedSubData.item_type == 4 && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">Document Type</div>
                        <div className="col-10">
                          <div className="form-group-blk mb-3">
                            <Select
                              className={"form-control custom-select-box " + (subModalFormik.errors.document_type && subModalFormik.touched.document_type ? " is-invalid" : "")}
                              name="document_type"
                              value={subModalFormik.values.document_type || selectedDocumentTypeEdit || ""}
                              onChange={(value) => {
                                subModalFormik.setFieldValue("document_type", value.value);
                                setSelectedDocumentTypeEdit(value.label);
                                if (value.label == "Image") {
                                  setAcceptNameEdit("image/*");
                                } else if (value.label == "Word") {
                                  setAcceptNameEdit(".docx, .doc");
                                } else if (value.label == "Excel") {
                                  setAcceptNameEdit(".xlsx");
                                } else if (value.label == "PDF") {
                                  setAcceptNameEdit(".pdf");
                                } else if (value.label == "ZIP") {
                                  setAcceptNameEdit(".zip");
                                } else if (value.label == "Powerpoint") {
                                  setAcceptNameEdit(".ppt, .pptx");
                                }
                              }}
                              onBlur={subModalFormik.handleBlur}
                              options={documentType}
                              maxMenuHeight={175}
                              placeholder={selectedDocumentTypeEdit ? selectedDocumentTypeEdit : "Select Type"}
                              isDisabled={true}
                            />
                          </div>
                          <div></div>
                        </div>
                        <div className="row align-items-center">
                          <div className="col-2"></div>
                          <div className="col-5">
                            <input type="file" name="document" accept={acceptNameEdit} onChange={handledocEdit} disabled={selectedDocumentTypeEdit ? false : true} />
                          </div>
                          <div className="col-5">
                            {!selectedDocumentEdit &&
                              documentFileUrl &&
                              RenderAttachList({
                                fileName: documentFileUrl?.substring(documentFileUrl?.lastIndexOf("/") + 1),
                                // size: "1205",
                                filePath: documentFileUrl,
                                showClose: false,
                                docType: selectedDocumentTypeEdit,
                              })}
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </>
                )}
                {selectedSubData.item_type == 2 && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">Video Type</div>
                        <div className="col-10">
                          <div className="form-group-blk mb-3">
                            <Select
                              className={"form-control custom-select-box " + (subModalFormik.errors.video_type && subModalFormik.touched.video_type ? " is-invalid" : "")}
                              name="video_type"
                              value={subModalFormik.values.document_type || selectedVideoTypeEdit || ""}
                              onChange={(value) => {
                                subModalFormik.setFieldValue("video_type", value.value);
                                setSelectedVideoTypeEdit(value.label);
                              }}
                              onBlur={subModalFormik.handleBlur}
                              options={videoType}
                              maxMenuHeight={175}
                              placeholder={selectedVideoTypeEdit ? selectedVideoTypeEdit : "Select Type"}
                              isDisabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                {selectedSubData.video_type == 0 && selectedSubData.item_type == 2 && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">Vimeo Video Code</div>
                        <div className="col-5">
                          <div className="form-group-blk mb-3">
                            <input
                              type="text"
                              className={"form-control "}
                              placeholder="Enter Vimeo Code"
                              name="vimeoBaseUrl"
                              title="Vimeo Video Code"
                              value={"https://player.vimeo.com/video/"}
                              disabled
                            // onChange={subModalFormik.handleChange}
                            // onBlur={subModalFormik.handleBlur}
                            />
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="form-group-blk mb-3">
                            <input
                              type="text"
                              className={"form-control " + (subModalFormik.errors.vimeoUrl && subModalFormik.touched.vimeoUrl ? " is-invalid" : "")}
                              placeholder="Enter Vimeo Url"
                              name="vimeoUrl"
                              title="Vimeo Url"
                              value={subModalFormik.values.vimeoUrl}
                              onChange={subModalFormik.handleChange}
                              onBlur={subModalFormik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold"></div>
                        <div className="col-5"></div>
                        <div className="col-5">
                          <div className="form-group-blk mb-3">(Ex: https://player.vimeo.com/video/xxxxxxxx)</div>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}
                {selectedSubData.video_type == 1 && selectedSubData.item_type == 2 && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">URL</div>
                        <div className="col-10">
                          <div className="form-group-blk mb-3">
                            <input
                              type="text"
                              className={"form-control " + (subModalFormik.errors.Url && subModalFormik.touched.Url ? " is-invalid" : "")}
                              placeholder="Enter Youtube Url"
                              name="Url"
                              title="Url"
                              value={subModalFormik.values.Url}
                              onChange={subModalFormik.handleChange}
                              onBlur={subModalFormik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </>
                )}
                {selectedSubData.item_type == 5 && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">URL</div>
                        <div className="col-10">
                          <div className="form-group-blk mb-3">
                            <input
                              type="text"
                              className={"form-control " + (subModalFormik.errors.Url && subModalFormik.touched.Url ? " is-invalid" : "")}
                              placeholder="Enter Link Url"
                              name="Url"
                              title="Url"
                              value={subModalFormik.values.Url}
                              onChange={subModalFormik.handleChange}
                              onBlur={subModalFormik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr />
                  </>
                )}

                {selectedSubData.item_type == 7 && (
                  <>
                    <div className="topic-add-modal-content">
                      <div className="row">
                        <div className="col-2 font-weight-bold">Show Description</div>
                        <div className="col-10">
                          <div className="custom-control custom-checkbox text-left mt-2">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="showDesc"
                              name="showDesc"
                              title="Show Description"
                              checked={subModalFormik.values.showDesc}
                              onChange={subModalFormik.handleChange}
                              onBlur={subModalFormik.handleBlur}
                            />
                            <label className="custom-control-label" htmlFor="showDesc"></label>
                          </div>
                          {/* <div className="form-group-blk mb-3">
                    <textarea
                    cols={10}
                        type="text"
                        className={"form-control " + (subModalFormik.errors.Url && subModalFormik.touched.Url ? " is-invalid" : "")}
                        placeholder="Enter Youtube Url"
                        name="Url"
                        value={subModalFormik.values.Url}
                        onChange={subModalFormik.handleChange}
                        onBlur={subModalFormik.handleBlur}
                      />
                      </div> */}
                        </div>
                      </div>
                    </div>

                    <hr />
                  </>
                )}

                <div className="form-group form-group-save-cancel mt-4">
                  <button className="btn btn-save btn-success" type="submit" title="Save">
                    <i className="fal fa-save"></i>Save
                  </button>

                  <button className="btn btn-close btn-danger" type="button" title="Cancel" data-dismiss="modal">
                    <i className="fal fa-times"></i>Cancel
                  </button>
                </div>
              </form>
            </div>
            {type !== "add" && resources.length > 0 ? (
              <DataTable
                data={dataToRender()}
                defaultSortField="name"
                defaultSortAsc={true}
                noDataComponent={Str.noRecord}
                columns={columns}
                pagination={true}
                paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              />
            ) : (
              <SkeletonTicketList />
            )}
          </div>
        </div>
      </div>
      {/* add sub modal popup */}
    </>
  );
};

export default ResourcePoolDetails;
