import React, { useState } from 'react';
import PermissionsGate from '../../utils/permissionGate';

export const CustomButton = ({
  icon,
  fontColor,
  text,
  backgroundColor,
  shape,
  onClick,
}) => {
  const buttonStyles = {
    backgroundColor: backgroundColor,
    color: fontColor,
    borderRadius: shape === 'circle' ? '50px' : '2px',
  };

  const handleClick = () => {
    // Calling onClick function if provided
    if (onClick) {
      onClick();
    }
  };

  return (
    <button className="cert-btn" style={buttonStyles} onClick={handleClick}>
      {icon && <i className={'fal ' + icon}></i>}
      {shape !== 'circle' && <span className="cert-btn-text">{text}</span>}
    </button>
  );
};

export const CustomLoadingButton = ({
  icon,
  fontColor,
  text,
  backgroundColor,
  shape,
  onClick,
  permissions = []
}) => {
  const [loading, setLoading] = useState(false);

  const buttonStyles = {
    backgroundColor: backgroundColor,
    color: fontColor,
    borderRadius: shape === 'circle' ? '50px' : '2px',
  };

  const handleClick = async () => {
    setLoading(true);

    if (onClick) {
      await onClick();
    }

    setLoading(false);
  };

  return (
    <PermissionsGate scopes={permissions} errorProps={{disabled: true}}>
    <button
      className="cert-btn"
      style={buttonStyles}
      onClick={handleClick}
      disabled={loading}
    >
      {loading ? (
        <i className="fa fa-spinner fa-spin"></i>
      ) : (
        <>
          {icon && <i className={'fal ' + icon}></i>}
          {shape !== 'circle' && <span className="cert-btn-text">{text}</span>}
        </>
      )}
    </button>
    </PermissionsGate>
  );
};
