import React, { useEffect, useState } from 'react';
import InProgress from './../../../assets/images/In-Progress.png';
import NotStarted from './../../../assets/images/Not-Started.png';
import { TABLE_DATE_FORMAT, IMAGE_URL } from '../../../utils/Constants';
import moment from 'moment';
import { InitialRender } from '../../common/Helper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const ScheduleFilterHeader = ({ headerName }) => {
  return (
    <>
      <div className="schedule-filter-header">
        <i
          className="fal fa-university"
          style={{
            marginRight: '10px',
            fontSize: '20px',
          }}
        ></i>
        <h4 className="schedule-typography">{headerName}</h4>
      </div>
    </>
  );
};

export const StyledTableCell = ({
  class_name = '',
  mode = '',
  campus_room = [] || '',
  campus_name = '',
  isBookingAvailable = false,
}) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  return (
    <div className="table-main-container">
      <div className="table-container-1 d-flex mb-1">
        <div className="table-content-1">{class_name}</div>
        <div className="table-content-2 ml-3">
          {!isBookingAvailable && (
            <span
              className={
                mode === 'bbb_class' ||
                mode === 'braincert_class' ||
                mode === 'joinme_class'
                  ? 'cat-blue'
                  : mode === 'on_campus'
                  ? 'cat-green'
                  : 'cat-red'
              }
            >
              {mode === 'bbb_class' ||
              mode === 'braincert_class' ||
              mode === 'joinme_class' ? (
                <div
                  className="as-virtual-bg as-booking-widget text-white no-text-transformation"
                  title="Virtual"
                >
                  <i
                    className="fal fa-house-signal"
                    style={{ color: '#fff' }}
                  />
                  &nbsp;Virtual
                </div>
              ) : mode === 'on_campus' ? (
                <div
                  className="as-campus-bg as-booking-widget text-white no-text-transformation"
                  title="On-campus"
                >
                  <i className="fal fa-university" style={{ color: '#fff' }} />
                  &nbsp;On Campus
                </div>
              ) : (
                <div
                  className="as-hybrid-bg as-booking-widget text-white no-text-transformation"
                  title="Hybrid"
                >
                  <i className="fal fa-university" style={{ color: '#fff' }} />
                  <i className="fal fa-plus" style={{ color: '#fff' }} />
                  <i
                    className="fal fa-house-signal"
                    style={{ color: '#fff' }}
                  />
                  &nbsp;Hybrid
                </div>
              )}
            </span>
          )}
          {isBookingAvailable && (
            <span className="cat-red">
              <div
                className="as-booking-bg as-widget no-text-transformation"
                title="Booking"
              >
                <i className="fal fa-calendar-day" />
                Booking
              </div>
            </span>
          )}
        </div>
      </div>
      {campus_room &&
        Array.isArray(campus_room) &&
        campus_room.length > 0 &&
        campus_room.slice(0, showAll ? campus_room.length : 2).map((room) => (
          <div className="table-container-2" key={room.campus_id}>
            <div>
              <i
                className="fal fa-university"
                style={{
                  marginRight: '5px',
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: 300,
                }}
              ></i>
              <span style={{ fontWeight: 350 }}>
                {room.campus_room ? room.campus_room?.room_name + ', ' : '-'}{' '}
                {room.campus_room
                  ? room.campus_room?.class_campus?.campus_name
                  : '-'}
              </span>
            </div>
          </div>
        ))}
      {!isBookingAvailable && campus_room.length > 2 && (
        <div>
          <button
            className="btn p-0"
            type="button"
            onClick={toggleShowAll}
            style={{
              fontWeight: 350,
              color: '#467FCF',
            }}
          >
            {showAll ? '...See Less' : '...See More'}{' '}
            {/* <i class="fal fa-chevron-down rotate-icon"></i> */}
          </button>
        </div>
      )}
      {isBookingAvailable && (
        <div className="table-container-2">
          <div>
            <i
              className="fal fa-university"
              style={{
                marginRight: '5px',
                fontSize: '14px',
                color: '#000',
                fontWeight: 300,
              }}
            ></i>
            <span style={{ fontWeight: 350 }}>
              {campus_room + ', '} {campus_name}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export const StyledPagination = ({
  totalrecords = 10,
  onPageChange,
  onPerPageChange,
  lastPage,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [perPageRecord, setPerPageRecord] = useState(10);
  const [firstPageIndex, setFirstPageIndex] = useState(1);

  useEffect(() => {
    if (pageNumber >= 1) {
      onPageChange(pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    onPerPageChange(perPageRecord);
  }, [perPageRecord]);

  const handleNextPage = () => {
    if (firstPageIndex + perPageRecord <= totalrecords) {
      setPageNumber((pageNumber) => pageNumber + 1);
      setFirstPageIndex((firstPageIndex) => firstPageIndex + perPageRecord);
    }
  };

  const handlePreviousPage = () => {
    if (firstPageIndex - perPageRecord >= 0) {
      setPageNumber((pageNumber) => pageNumber - 1);
      setFirstPageIndex((firstPageIndex) => firstPageIndex - perPageRecord);
    }
  };

  const handlePerPageSelection = (event) => {
    const newPerPage = parseInt(event.target.value, 10);
    setPerPageRecord(newPerPage);
    onPerPageChange(perPageRecord);
  };
  const handleFirstPageSelection = () => {
    setPageNumber(1);
    setFirstPageIndex(1);
  };

  const handleLastPageSelection = () => {
    setPageNumber(lastPage);
    setFirstPageIndex((lastPage - 1) * perPageRecord + 1);
  };

  return (
    <>
      <div className="schedule-pagination-container">
        <div className="schedule-pagination-sub-container-1">
          <div className="schedule-pagination-content-1">Rows per page:</div>
          <div>
            <select
              className="schedule-pagination-select"
              value={perPageRecord}
              onChange={handlePerPageSelection}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={40}>40</option>
            </select>
          </div>
        </div>
        <div className="schedule-pagination-sub-container-2">
          <div>
            {firstPageIndex} -{' '}
            {Math.min(firstPageIndex + perPageRecord - 1, totalrecords)} of{' '}
            {totalrecords}
          </div>
        </div>
        <div className="schedule-pagination-btn-container">
          <button
            className="schedule-pagination-btn"
            onClick={handleFirstPageSelection}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
              <path fill="none" d="M24 24H0V0h24v24z"></path>
            </svg>
          </button>
          <button
            className="schedule-pagination-btn"
            onClick={handlePreviousPage}
          >
            <i className="fal fa-chevron-left"></i>
          </button>
          <button className="schedule-pagination-btn" onClick={handleNextPage}>
            <i className="fal fa-chevron-right"></i>
          </button>
          <button
            className="schedule-pagination-btn"
            onClick={handleLastPageSelection}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
              <path fill="none" d="M0 0h24v24H0V0z"></path>
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export const StyledRoomFilters = ({
  room_header = '',
  availability = '',
  nextAvailableSlotStartTime = ' ',
  nextAvailableSlotEndTime = ' ',
  nextScheduledClassStartTime = '',
  nextScheduledClassEndTime = '',
  scheduledClassDate = '',
  availableSlotDate = '',
}) => {
  return (
    <>
      <div style={{ width: '100%' }}>
        <div class="schedule-room-filters-grid_1 mb-3">
          <div>{room_header}</div>
          <div className="schedule-room-status d-flex">
            {availability}
            {availability === 'In Progress' ? (
              <>
                <img
                  src={InProgress}
                  alt="CLASS-IN-PROGRESS"
                  style={{ height: 24, marginLeft: '10px' }}
                />
              </>
            ) : availability === 'Available' ? (
              <>
                <img
                  src={NotStarted}
                  alt="NOT STARTED"
                  style={{ height: 24, marginLeft: '10px' }}
                />
              </>
            ) : (
              <img src={''} />
            )}
          </div>
        </div>

        <div class="schedule-room-filters-grid_2 schedule-small-typography">
          <div>
            <i class="fal fa-calendar-alt mr-1"></i>
            Next Schedule Class
          </div>
          <div>
            <i class="fal fa-calendar-alt mr-1"></i>
            Next Available Slot
          </div>

          <div>
            {nextScheduledClassStartTime + ' - ' + nextScheduledClassEndTime}
          </div>
          <div>
            {nextAvailableSlotStartTime + ' - ' + nextAvailableSlotEndTime}
          </div>

          <div>
            {moment(scheduledClassDate).format(`dddd, ${TABLE_DATE_FORMAT}`)}
          </div>
          <div>
            {moment(availableSlotDate).format(`dddd, ${TABLE_DATE_FORMAT}`)}
          </div>
        </div>
      </div>
    </>
  );
};

const UserProfileComponent = ({ user }) => {
  const history = useHistory();
  return (
    <div className="assigned-title-itm mb-2">
      <span className={`assigned-title-blk  name-icon cat-dark-red`}>
        {user.photo ? (
          <img
            src={`${IMAGE_URL}/${user.photo.replace(
              '/home/myaie/public_html/',
              ''
            )}`}
            alt="User"
          />
        ) : (
          InitialRender([user.first_name, user.last_name])
        )}
        <span className="profile-box-2-status Online">
          <i className="fas fa-circle"></i>
        </span>
      </span>
      <span
        className="as-text-blue curser feature-name width-155"
        onClick={() =>
          history.push(
            `/systemAdministration/userManagement/open/${user.id}/aboutYou`
          )
        }
      >
        {user.first_name} {user.last_name}
      </span>
    </div>
  );
};

const OverlappingPhotos = ({ users }) => {
  return (
    <div>
      {users.slice(0, 1).map((user) => (
        <>
          <span className={`assigned-title-blk  name-icon cat-dark-red`}>
            {user.photo ? (
              <img
                src={`${IMAGE_URL}/${user.photo.replace(
                  '/home/myaie/public_html/',
                  ''
                )}`}
                alt="User"
              />
            ) : (
              InitialRender([user.first_name, user.last_name])
            )}
            <span className="profile-box-2-status Online">
              <i className="fas fa-circle"></i>
            </span>
          </span>
          {/* <span className="as-text-blue curser feature-name width-155">
        {user.first_name} {user.last_name}
      </span> */}
        </>
      ))}
    </div>
  );
};

export const ShowUsers = ({ users, onShowAll }) => {
  const [showAll, setShowAll] = useState(false);
  const history = useHistory();

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    onShowAll(showAll);
  }, [showAll]);

  return (
    <div>
      {!showAll && (
        <div className="assigned-title-itm">
          <OverlappingPhotos users={users} />
          {users.length > 1 ? (
            <span
              className="as-text-blue curser"
              onClick={() => setShowAll(true)}
            >
              {users[0].first_name} {users[0].last_name}
              <span style={{ fontWeight: 700 }}>
                {users.length > 1 && ` +${users.length - 1} Others`}
              </span>
            </span>
          ) : (
            <>
              <span
                className="as-text-blue curser"
                onClick={() =>
                  history.push(
                    `/systemAdministration/userManagement/open/${users[0].id}/aboutYou`
                  )
                }
              >
                {users[0].first_name} {users[0].last_name}
              </span>
            </>
          )}
        </div>
      )}

      {showAll && (
        <div className="users-details">
          {users.map((user, index) => (
            <UserProfileComponent user={user} key={index} />
          ))}
          <div className="as-text-blue curser" onClick={handleShowAll} style={{fontWeight : 700}}>
            See Less...
          </div>
        </div>
      )}
    </div>
  );
};
