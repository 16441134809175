import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/custom.css";
import "./assets/css/responsive.css";
import "./assets/css/global.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import axios from "axios";
// import { getToken, removeUserSession } from "./utils/Auth";

import 'jquery-ui-dist/jquery-ui';
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./store/index";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { MainContextProvider } from "./contexts/MainContext";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

const peristedState = loadState();

const store = createStore(
  rootReducer,
  peristedState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // Others reducers...
);

store.subscribe(() => {
  saveState(store.getState());
});
// console.log = function () {};
// console.error = function () {};
// console.warn = function () {};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MainContextProvider>
        <MathJaxContext>
         <App />
        </MathJaxContext>
      </MainContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
