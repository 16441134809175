import React from 'react'
import { GetGradeLetterAuditTrialCancelToken, GetGradeLetterAuditTrialDropdownCancelToken, getGradeLetterAuditTrial, getGradeLetterAuditTrialDropdown } from '../../../services/GradeSettingService'
import AuditTrailsTable from '../../common/AuditTrailsTable';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

function AuditTrails() {

  const { id } = useParams()

  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]

  const handleRedirect = (id, type, otherData) => {
    return `/courseAdministration/gradingTemplate/edit/${id}`
  }

  return (
    <AuditTrailsTable
      apiFunction={GetGradeLetterAuditTrialCancelToken}
      columnsToShow={columnsToShow}
      apiParams={id ? {parent_action_id: id} : {}}
      filterArrApi={GetGradeLetterAuditTrialDropdownCancelToken}
      actionId={id}
      exportFileName={"Grading_letter_template"}
      resourceNameRedirect={handleRedirect}
    />
  )
}

export default AuditTrails