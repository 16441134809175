import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import Str from "../../../../common/Str";
import { TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../../utils/Constants";
import { handleTableScroll } from "../../../../../utils/commonFunction";

function CRMData({ studentDetailData,tempData,loading }) {
  const [search, setSearch] = useState("");

  const [studentData, setStudentData] = useState([]);

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  const fixDataFormet = (obj) => {
    // let result = []
    let tempObj = obj;
    // console.log("tempObj",tempObj)
    let check = tempObj?.results || {};
    let check1 = Object.keys(check).map((keys)=>{
      // console.log("resultttttttttttt",check[keys]);
      if(check[keys] && check[keys] !== "" && check[keys] != null){
        if(Array.isArray(check[keys]) && check[keys][0]){
          const firstName = check[keys][0]["first_name"] || "";
          const lastName = check[keys][0]["last_name"] || "";
          return {...check[keys][0],status: check[keys][0]["sales_stage"], type : keys, name : check[keys][0]["name"] ? check[keys][0]["name"] : firstName+" "+lastName};
        }
        if(Array.isArray(check[keys]) && check[keys][1]){
          const firstName = check[keys][1]["first_name"] || "";
          const lastName = check[keys][1]["last_name"] || "";
          return {...check[keys][1],status: check[keys][1]["sales_stage"], type : keys, name : check[keys][1]["name"] ? check[keys][1]["name"] : firstName+" "+lastName};
        }
        const firstName = check[keys]["first_name"] || "";
        const lastName = check[keys]["last_name"] || "";
        return {...check[keys],type : keys, name : check[keys]["name"] ? check[keys]["name"] : firstName+" "+lastName};
      } else {
        return;
      }
    })
    let resultData = check1.filter(element=> { return element !== undefined });
    // resultData.splice(2,1);
    // console.log("resultttttttttttt",check);
    // console.log("resultttttttttttt", resultData);
    setStudentData(resultData);
  };

  useEffect(() => {
    if (tempData && tempData.getCRMData) {
      fixDataFormet(tempData.getCRMData);
    }
  }, [tempData]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = studentData;
    if (search.length) {
      let tempName = allData.filter((item) => {
        let includes = item.name?.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      // let tempId = allData.filter((item) => {
      //   let includes = item.id.toString().toLowerCase().includes(search.toString().toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempDate = allData.filter((item) => {
        let includes = item.date_entered ? moment(item.date_entered).format(TABLE_DATE_TIME_FORMAT).toLowerCase().includes(search.toString().toLowerCase()) : "";
        if (includes) {
          return includes;
        } else return null;
      });
      let tempAssignTo = allData.filter((item) => {
        let includes = item.date_entered ? item.assingnee.toLowerCase().includes(search.toString().toLowerCase()) : "";
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        ...tempName,
        // ...tempId,
        ...tempDate,
        ...tempAssignTo
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (search.length) {
      return updatedData;
    } else {
      return studentData;
    }
  };

  const renderTypes = (type) =>{
    if (type === "account"){
      return <span className="feature-name">
        <span className="textLimit100">
          <span className={"cat cat-red"} title={"Account"}>Account</span>
        </span>
      </span>
    } else if(type === "contact") {
      return <span className="feature-name">
      <span className="textLimit100">
        <span className={"cat cat-blue"} title={"Contact"}>Contact</span>
        </span>
    </span>
    } else if(type === "opportunity"){
      return <span className="feature-name">
        <span className="textLimit100">
          <span className={"cat cat-green"} title={"Opportunity"}>Opportunity</span>
          </span>
      </span>
    }
  }

  const renderActionUrl = (row) => {
    // console.log("studentDetailData->", row);
    if (row?.type === "account") {
      return `https://www.crm.aie.ac/index.php?module=Accounts&action=DetailView&record=${studentDetailData?.crm_account_id}`;
    } else if (row?.type === "contact") {
      return `https://www.crm.aie.ac/index.php?module=Contacts&action=DetailView&record=${studentDetailData?.crm_contact_id}`;
    } else if (row?.type === "opportunity") {
      return `https://www.crm.aie.ac/index.php?action=DetailView&module=Opportunities&record=${row?.id}&return_module=Opportunities&return_action=DetailView&offset=7`;
    }
  }

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => (
        <a title={row.name} href={renderActionUrl(row)} target="_blank" className="as-text-blue curser feature-name">
          <span className="textLimit100">{row.name ? row.name : "-"}</span>
        </a>
      ),
    },
    {
      name: "Date Created",
      selector: "date_entered",
      sortable: true,
      cell: (row) => (
        <>
          {row.date_entered ? (
            <div className="dateTime" title={moment(row.date_entered).format(TABLE_DATE_TIME_FORMAT)}>
              <p className="right-space">{moment(row.date_entered).format(TABLE_DATE_FORMAT)}</p>
              <p>{moment(row.date_entered).format(TABLE_TIME_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      // cell: (row) => <span className="feature-name">
      //   <span className="textLimit100">{row.type ? row.type == "account" ?
      //     <span className={"cat cat-red"} title={"Account"}>Account</span> : <span className={"cat cat-blue"} title={"Contact"}>Contact
      //     </span> : "-"}</span>
      // </span>
      cell: (row)=>renderTypes(row?.type),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => (
        <span className="feature-name">
          <span className="textLimit100" title={row?.status}>
            {row?.status ? row?.status :"-"}
          </span>
        </span>
      ),
    },
    {
      name: "Lead Source",
      selector: "lead_source",
      sortable: true,
      cell: (row) => (
        <span className="feature-name">
          <span className="textLimit100" title={row?.leadSource}>
            {row?.lead_source ? row?.lead_source :"-"}
          </span>
        </span>
      ),
    },
    {
      name: "Assign To",
      selector: "assingnee",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        <span className="feature-name">
          <span className="textLimit100" title={row.assingnee}>
            {row.assingnee ? row.assingnee :"-"}
          </span>
        </span>
      ),
    },
    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            {/* <PermissionsGate scopes={["umedit"]}> */}
            <a href={renderActionUrl(row)} target="_blank" className="btn btn-primary rounded-circle" title="Open">
              <i className="fal fa-folder-open"></i>
            </a>
            {/* </PermissionsGate> */}
          </div>
        </div>
      ),
    },
  ], []);

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Date Created", "Type", "Assign To"];

    data = data?.map((row) => ({
      ...row,
      Name: row.name ? row.name : "-",
      "Date Created": row.date_entered ? moment(row.date_entered).format(TABLE_DATE_TIME_FORMAT) : "-",
      Type: row.type ? row.type : "-",
      "Assign To": row.assingnee ? row.assingnee : "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Name, row["Date Created"], row.Type, row["Assign To"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };
  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">CRM Data</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <div className="custom-table-div filter-search-icon card-table-custom course-table-filter-sec marLeft135 top-5">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                  <div className="table-responsive-div">
                    <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                      <div id="assessment-table-main_filter" className="dataTables_filter">
                        <label>
                          <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-button-group student-zindex">
                      <div className="filter-scroll">
                          <div className={`filter-scroll-inner filter-custom-new`}>

                          </div>
                      </div>
                      <div className="reset-btn-group">
                        <div className="button-reset dropdown-comman">
                          <button className="btn btn-primary" onClick={() => setSearch("")} title="Reset">
                            <i className="fal fa-redo"></i>Reset
                          </button>
                        </div>
                        <div className="files-export-group">
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("xlsx", "CRM_Data_list");
                            }}
                            title="Export spreadsheet"
                          >
                            <i className="fal fa-file-excel icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("csv", "CRM_Data_list");
                            }}
                            title="Export CSV"
                          >
                            <i className="fal fa-file-csv icon"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-files"
                            onClick={() => {
                              exportData("pdf", "CRM_Data_list");
                            }}
                            title="Export PDF"
                          >
                            <i className="fal fa-file-pdf icon"></i>
                          </button>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
              </div>
          </div>
      </div>

        {loading ? <SkeletonTicketList />
          : <DataTable
            data={dataToRender()}
            defaultSortField="date_entered"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />}
      </div>
    </div>
  );
}

export default CRMData;
