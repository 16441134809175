const initialState = {
    exam_center_name : "",
}

const examCenterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_CENTER_NAME" :
            state.exam_center_name = action.payload;
            return {exam_center_name : state.exam_center_name}
        default :
            return state;
    }
};

export default examCenterReducer;