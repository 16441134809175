import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { Link, useParams, useHistory } from "react-router-dom";
import CustomerTab from "../components/systemadministration/NetsuiteManagement/CustomerTab";
import MergedDataTab from "../components/systemadministration/NetsuiteManagement/MergedDataTab";

const NetsuiteManagement = () => {
  const history = useHistory();
  const { tab, type, id, subTab } = useParams();

  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div class="d-flex align-items-center ">
                    <div class="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        Netsuite <span>Management</span>
                      </h4>
                    </div>
                      <div class="text-md-right action2-blk">
                        <div class="ticket-view-btn d-flex align-items-center">
                          <div class="add-new-ticket-bx">
                            <button
                              onClick={() => history.goBack()}
                              title="Back"
                              class="btn btn-white-bordered"
                            >
                              <i class="fal fa-angle-left"></i>Back
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab11"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      {tab === "customers" && <CustomerTab />}
                      {(tab === "mergedData" ||
                        tab==="mergedDataRegistration" ||
                        subTab === "student" ||
                        subTab === "registration" ||
                        subTab === "auditTrail") && <MergedDataTab />}
                      {/* {subTab === "auditTrail" && <AuditTrail />} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NetsuiteManagement;
