import React, { useEffect, useState } from 'react'
import { GetQualificationOptions, GetSchoolListCancelToken } from '../../../../services/RegistrationService';
import axios from 'axios';

function RenderPreviousValues({ status, currentValue, previousValue, type = "", typeSupportingProps = {} }) {

  const [qualName, setQualName] = useState("");

  useEffect(() => {
    const cancelTokenSources = [];

    const getQualList = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        let res = await GetSchoolListCancelToken(source.token)
        if (res.status == 200) {
          const respData = res.data.data;
          respData.forEach(item => {
            if (item.value === typeSupportingProps.oldSchoolValue) {
              GetQualificationOptions(item.netsuite_department_id).then(res => {
                setQualName(res.data.data.items.find(val => val.id === previousValue)?.displayname || false)
              })
            }
          })
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    if (type === "dropdown" && typeSupportingProps.isQualification && !qualName && previousValue != currentValue) {
      getQualList();
    }

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };

  }, [previousValue])
  if (status !== "Re-Posted" && status !== "Re-Reg Re-Posted") {
    return <></>
  }

  // if (typeSupportingProps.from === "date") {
  //   console.log("slkdfj", currentValue, "<<<>>>", previousValue, typeSupportingProps)
  // }
  // if (!previousValue) {
  //   return <></>
  // }
  if (type === "date") {
    if (previousValue == null && isNaN(currentValue)) {
      return <></>
    }
    return previousValue === currentValue ? "" : <div className="prev-value-re-reg">Old Value: {previousValue}</div>
  }
  if (type === "renderFromFunction") {
    return previousValue === currentValue ? "" : <div className="prev-value-re-reg">Old Value: {typeSupportingProps.renderFunction(previousValue).text}</div>
  }
  if (type === "allowedTime") {
    if (previousValue == currentValue) {
      return <></>
    }
    return +previousValue ? <div className="prev-value-re-reg">Old Value: Yes</div> : <div className="prev-value-re-reg">Old Value: No</div>
  }
  if (type === "dropdown") {
    if (typeSupportingProps.isQualification) {
      return previousValue == currentValue ? "" : <div className="prev-value-re-reg">Old Value: {qualName ?? previousValue}</div>
    }
    let obj = typeSupportingProps.filterArray.find(item => item.value.toString() == (previousValue || "").toString())
    return previousValue == currentValue ? "" : <div className="prev-value-re-reg">Old Value: {obj?.label ?? previousValue}</div>
  }
  return (
    previousValue == currentValue ? "" : <div className="prev-value-re-reg">Old Value: {previousValue}</div>
  )
}

export default RenderPreviousValues