import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Yup from "yup";
import {
  GetFilters,
  CreateCertificateTemplate,
} from '../../../services/CertificateServices';
import Swal from 'sweetalert2';
import { convertBase64ToBlob } from '../../common/ImageConversions';
import hasPermission from '../../../utils/hasMultiplePermission';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import HtmlParser from 'react-html-parser';

const AddCertificateTemplate = () => {
  const history = useHistory();
  const [saveLoading, setSaveLoading] = useState(false);
  const [filters, setFilters] = useState({
    brands: [],
    size: [],
    topics: [],
  });
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ['ccertadd'],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push('/noaccess');
    }
  }, []);

  useEffect(() => {
    const getFilters = async () => {
      const response = await GetFilters();
      const { brands, sizeOptions, topics } = response.data;
      let tempArr = topics?.map(item=> ({ 
        value: item?.value, 
        label: HtmlParser(`<span style="color:#fff;background:${item?.color};" title=${item?.options}>${item?.options}</span>`)
      }));
      setFilters({ brands, size: sizeOptions, topics: tempArr });
    };
    getFilters();
  }, []);

  // Only Images are allowed for uploading in template
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const formik = useFormik({
    initialValues: {
      name: "",
      brand: "",
      size: "",
      keywords: [],
      topics: "",
      showStudentName: "",
      showCourseName: "",
      showDate: "",
      backgroundImage: "",
      backgroundImageName: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is Required"),
      brand: Yup.string().required("Brand is Required"),
      size: Yup.string().required("Size is Required"),
      keywords: Yup.array().min(1, "Atleast one keyword is required").required(),
      topics: Yup.array().min(1, "Atleast one topic is required").required("Atleast one topic is required"),
      backgroundImage: Yup.string().required("Background Image is Required"),
    }),
    // this function is responsible for creating a new certificate template
    // Function calls the CreateCertificateTemplate API endpoint
    onSubmit: ({
      name,
      brand,
      size,
      topics,
      showStudentName,
      showCourseName,
      showDate,
      backgroundImage,
      keywords
    }, { resetForm }) => {
      setSaveLoading(true)
      const blob = convertBase64ToBlob(backgroundImage);
      const file = new File([blob], 'image.png', { type: 'image/png' });
      const formData = new FormData()
      formData.append('Name', name);
      formData.append('Brand_id', brand);
      formData.append('Size', size);
      formData.append('Keywords', keywords.join(','));
      topics.map((item, index) => {
        formData.append(`Topics[${index}]`, item.value);
      })
      formData.append('ShowStudentName', showStudentName ? 1 : 0);
      formData.append('ShowCourseName', showCourseName ? 1 : 0);
      formData.append('ShowDate', showDate ? 1 : 0);
      formData.append('BackgroundImage', file);

      CreateCertificateTemplate(formData).then(res => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message}`,
        });
        resetForm()
        history.goBack()
        setSaveLoading(false)
      }).catch(error => {
        setSaveLoading(false)
        console.error("error", error)
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${error?.response?.data?.message}`,
        });
      });
    }
  })

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      formik.setFieldValue("backgroundImageName", file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        formik.setFieldValue("backgroundImage", reader.result)
      };
    }
  };

  const handleDeleteFile = () => {
    document.getElementById("input").value = ''
    formik.setFieldValue("backgroundImage", "")
    formik.setFieldValue("backgroundImageName", "")
  }

  const handleKeyDown = (event) => {
    var keyword = formik.values.keywords;
    if (event.key === "Enter") {
      if (event.target.value.trim()) {
        keyword.push(event.target.value);
        event.target.value = ""
        formik.setFieldValue("keywords", keyword);
      }

    }
  };

  const removeKeyword = (index) => {
    const newKeywords = [...formik.values.keywords];
    newKeywords.splice(index, 1);
    formik.setFieldValue("keywords", [...newKeywords]);
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="row">
        <div className="col-lg-9 col-12 card-with-small-second-card">
          <div
            className="card card-profile-info-card course-details-tab-sec"
            id="parentElem"
          >
            <div className="course-info-sec">
              <div className="edit-icon new-card-header">
                <div className="card-header">
                  Certificate Information
                  {/* {iconLoading && <i className="fas fa-cog fa-spin ml-1"></i>} */}
                </div>
              </div>
              <div className="card-body-inr card-body-info">
                <div className="row">
                  <div className="col-md-4 col-lg-4">
                    <label htmlFor="name">Name *</label>
                    <div className="form-icon-group mb-4" title="Name">
                      <input
                        type="text"
                        className={
                          "form-control"
                          + (formik.errors.name && formik.touched.name
                            ? " is-invalid"
                            : "")
                        }
                        name="name"
                        id="name"
                        placeholder="Name"
                        title="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 ">
                    <div className="form-group-blk mb-3">
                      <label>Brand *</label>
                      <Select
                        className={
                          'form-control custom-select-box'
                          + (formik.errors.brand &&
                            formik.touched.brand
                            ? ' is-invalid'
                            : '')
                        }
                        name="brand"
                        value={filters.brands.filter(item => item.value === formik.values.brand)}
                        options={filters.brands || []}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            formik.setFieldValue('brand', selectedOption.value);
                          } else {
                            formik.setFieldValue('brand', selectedOption.value);
                          }
                        }}
                        isClearable
                        onBlur={formik.handleBlur}
                        maxMenuHeight={175}
                        placeholder={formik.values.brand || 'Brand'}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 ">
                    <div className="form-group-blk mb-3">
                      <label>Size *</label>
                      <Select
                        className={
                          'form-control custom-select-box'
                          + (formik.errors.size &&
                            formik.touched.size
                            ? ' is-invalid'
                            : '')
                        }
                        name="size"
                        value={formik.values.size || ""}
                        options={filters.size}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            formik.setFieldValue('size', selectedOption.value);
                          } else {
                            formik.setFieldValue('size', selectedOption.value);
                          }
                        }}
                        isClearable
                        onBlur={formik.handleBlur}
                        maxMenuHeight={175}
                        placeholder={formik.values.size || 'Size'}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <label>Keywords *</label>
                    <div className="form-icon-group">
                      <div className="form-control custom-select-box">
                        <input
                          type="text"
                          className={
                            "form-control "
                            + (formik.errors.keywords && formik.touched.keywords
                              ? " is-invalid"
                              : "")
                          }
                          name="keywords"
                          placeholder="Keywords"
                          title="keywords"
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                    {formik.values.keywords.length > 0 && (
                      <ul className="tag-list">
                        {formik.values.keywords?.map((ele, index) => (
                          <li key={index}>
                            {ele}
                            <span
                              onClick={() => removeKeyword(index)}
                            >
                              <i className="fal fa-times"></i>
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="form-group-blk mb-3">
                      <label>Topics *</label>
                      <Select
                        className={
                          'form-control color-drop custom-select-box is-multi '
                          + (formik.errors.topics &&
                            formik.touched.topics
                            ? ' is-invalid'
                            : '')
                        }
                        name="topics"
                        value={formik.values.topics}
                        options={filters.topics || []}
                        onChange={(selectedOption) => {
                          if (selectedOption) {
                            formik.setFieldValue('topics', selectedOption);
                          } else {
                            formik.setFieldValue('topics', []);
                          }
                        }}
                        isClearable
                        isMulti
                        onBlur={formik.handleBlur}
                        maxMenuHeight={175}
                        placeholder={formik.values.topics.length ? formik.values.topics : 'Topics'}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-2 mb-2">
                  <div className='mr-3'>
                    <label>Show Student Name</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={formik.values.showStudentName}
                        onChange={(e) => formik.setFieldValue("showStudentName", e.target.checked)}
                        name='showStudentName'
                        id='showStudentName'
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className='mr-3'>
                    <label>Show Course Name</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={formik.values.showCourseName}
                        onChange={(e) => formik.setFieldValue("showCourseName", e.target.checked)}
                        name='showCourseName'
                        id='showCourseName'
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className='mr-3'>
                    <label>Show Date</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={formik.values.showDate}
                        onChange={(e) => formik.setFieldValue("showDate", e.target.checked)}
                        name='showDate'
                        id='showDate'
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group form-group-save-cancel">
              <button
                className="btn btn-save btn-success"
                type="button"
                title="Save"
                onClick={formik.handleSubmit}
              >
                {saveLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Save
              </button>
              <button
                className="btn btn-close btn-danger"
                type="button"
                title="Cancel"
                onClick={formik.resetForm}
              >
                <i className="fal fa-times"></i>Cancel
              </button>

              {Object.keys(formik.values).map((key) => {
                if (formik.touched[key] && formik.errors[key]) {
                  return (
                    <div className="invalid-feedback d-block" key={key}>
                      {formik.errors[key]}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className="col-lg-3 right-side-cols">
          <div
            className="card card-profile-info-card course-details-tab-sec"
            id="parentElem"
          >
            <div className="course-sec-gap course-setting-sec">
              {/* <div className="edit-icon new-card-header">
                <div className="card-header">Course Settings</div>
              </div> */}
              <div className='row'>
                <div className="col-md-12 col-lg-12">
                  <label htmlFor="IndexLogo">Background Image *</label>
                  <div className="form-group atttach-file mb-2">
                    <label>
                      <i className="fal fa-paperclip"></i>
                      <span>Add Attachment</span>
                      <input
                        // title={
                        //   letterheadHeaderName.name
                        //     ? letterheadHeaderName.name
                        //     : "No File Uploaded"
                        // }
                        type="file"
                        id="input"
                        className="form-control  form-control-aatch-file"
                        name="backgroundImage"
                        accept={SUPPORTED_FORMATS}
                        onChange={handleUploadImage}
                      />
                    </label>
                  </div>
                  {formik.values.backgroundImage && (
                    <>
                      <span className='file-delete-btn'>
                        {formik.values.backgroundImageName || "Certificate Template"}
                        <button className="btn btn-danger rounded-circle" title="Delete" type="button"
                          onClick={handleDeleteFile}
                        >
                          <i className="fal fa-trash-alt"></i>
                        </button>
                      </span>
                      <div className="form-icon-group mt-2" title={formik.values.backgroundImageName || "Certificate Template"}>
                        <figure className="upload-img-size">
                          <img
                            className=""
                            src={formik.values.backgroundImage}
                            alt={formik.values.backgroundImageName || "Certificate Template"}
                          />
                        </figure>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
};
export default AddCertificateTemplate;
