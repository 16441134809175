import React from "react";
import BackgroundImage from "../assets/images/login-bg.jpg";
import LoginImage from "../assets/images/my-admin-portal-logo.png";
import BgImage from "../assets/images/login-gradient-bg.png";
import logoImage from "../assets/images/admin-logo.png";
import LoginForm from "../components/LoginForm";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function LoginPage() {
  const brandVisualConfig = useSelector(state => state.brandVisuals.config);
  return (
    <div className="animate">
      <div className="new-custom-login portal-login d-flex align-items-center" style={{ // backgroundImage: `url(${BackgroundImage})`,
          backgroundColor: "#10284b",
        }}
      >
        <img src={brandVisualConfig.background_logo ? brandVisualConfig.background_logo : null}/>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="portal-login-card text-center">
                <div className="card-header">
                  <div className="card-header-img card-header-text">
                    <img src={brandVisualConfig.login_header ? brandVisualConfig.login_header : null}/>
                    {/* <div className="header-text">
                      <h4>AMI</h4>
                      <p>ADMINISTRATION & MANAGEMENT INTRANET</p>
                    </div> */}
                  </div>
                </div>
                <div className="card-body">
                  <LoginForm></LoginForm>
                </div>
                <div className="card-footer">
                  <Link title="Forgot your Password?" to="/forgotPassword">
                    Forgot your Password?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
