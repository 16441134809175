import React from 'react'
import moment from "moment"
import { DonutChart, ColumnChart } from 'bizcharts';
import OnlineClassFunctions from "./OnlineClassFunctions"
import { DATE_FORMAT, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_TIME_FORMAT } from '../../../utils/Constants'
import { RenderClassStatus, RenderClassType } from '../../../utils/CommonStatusItems';
import { Link } from 'react-router-dom';
import UserColumn from '../../common/UserColumn';
import { GlobalIconNames } from '../../../utils/GlobalIconNames';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Str from '../../common/Str';
import Swal from 'sweetalert2';
import { getClassFiles } from '../../../services/DashBoardServices';
import CopyToClipboard from '../../CopyToClipboard';

export const CourseInfoBlock = ({ basicInfo, id }) => {
    let currentTimeZone = moment.tz.guess()
    const last_modified_time = moment.tz(basicInfo.last_modified, "YYYY-MM-DD hh:mm:ss")._d;
    return <div>
        <div className="card-header">Course Information</div>
        <div className="card-body-inr card-body-info">
            <div className="basic-info-box">
                <div className="row">
                    <div className="col-md-2 col-lg-2">
                        <div className="basic-info-box-list">
                            <div className="course-name-merge">
                                <span className="title ">{GlobalIconNames("onlyiconcourses")} Course name: </span>
                                <span>{basicInfo.course_name ? basicInfo.course_name : "-"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-lg-2">
                        <div className="basic-info-box-list">
                            <span className="title">{GlobalIconNames("onlyiconaudittrail")} Last modified: </span>
                            <span>{basicInfo.last_modified ? moment(last_modified_time).tz(currentTimeZone).format(TABLE_DATE_TIME_FORMAT) : "- "} ({basicInfo.last_modified_name ? basicInfo.last_modified_name : ""})</span>
                        </div>
                    </div>
                    <div className="col-md-2 col-lg-2">
                        <div className="basic-info-box-list">
                            <span className="title ">{GlobalIconNames("onlyiconstudents")} Students: </span>
                            <Link to={{ pathname: "/courseAdministration/coursesdetails/" + id + "/students/show" }}>
                                <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : <i className="fas fa-cog fa-spin"></i>}</span>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-2 col-lg-2">
                        <div className="basic-info-box-list">
                            <span className="title">{GlobalIconNames("onlyicontickets")} Tickets: </span>
                            <Link to={{ pathname: "/courseAdministration/coursesdetails/" + id + "/tickets/show" }}>
                                <span>{basicInfo.amnt_tkt || basicInfo.amnt_tkt == 0 ? basicInfo.amnt_tkt : <i className="fas fa-cog fa-spin"></i>}</span>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-2 col-lg-2">
                        <div className="basic-info-box-list">
                            <Link to={`/courseAdministration/coursesdetails/${id}/assessments/add`}>
                                <button className="btn btn-primary Lmtime" title="Create New Assessments">
                                    <i className="fal fa-file-alt"></i>Create New Assessments
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-2 col-lg-2">
                        <div className="basic-info-box-list">
                            <Link to={`/courseAdministration/coursesdetails/${id}/notifications/create`} >
                                <button className="btn btn-primary" title="Send Notification">
                                    <i className="fal fa-bell"></i>Send Notification
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export const UpcomingImpDateBlock = ({ upcomingImpDate, loading }) => {
    return <div className="col-md-4 col-lg-4">
        <div className="card-new card card-profile-info-card mb-30 dashboard-blocks">
            <div className="card-header">Upcoming Important Dates {loading ? <i className="fas fa-cog fa-spin"></i> : ""}</div>
            <div className="dates-container">
                <table>
                    <tbody>
                        {upcomingImpDate.map((item, index) => {
                            return <tr key={index} className='parent-imp-dates-ellipsis'>
                                <td className="imp-dates-ellipsis" title={item.title ?? ""}><i className={`fas ${OnlineClassFunctions({}).getIconNameBasedOnType(item.type)}`} />  &nbsp;{OnlineClassFunctions({}).getLabelNameBasedOnType(item.type)}: {item.title ?? "NA"}</td>
                                <td className='imp-dates-ellipsis-dates' title={item.date ? moment.unix(item.date).format(DATE_FORMAT) : ""}>{item.date ? moment.unix(item.date).format(DATE_FORMAT) : "-"}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

export const SubmissionSummaryBlock = ({ submissionSummaryData, summarySelectedDays, setSummarySelectedDays, loading }) => {
    return <div className="col-md-4 col-lg-4 submission-summary">
        <div className="card-new card card-profile-info-card mb-30">
            <div className="card-header">Submission Summary {loading ? <i className="fas fa-cog fa-spin"></i> : ""}</div>
            <div className="days-indication">
                <span className={summarySelectedDays === 7 ? "active cursor-default" : "cursor-pointer"} onClick={() => setSummarySelectedDays(7)}>7 Days</span>
                <span className={summarySelectedDays === 30 ? "active cursor-default" : "cursor-pointer"} onClick={() => setSummarySelectedDays(30)}>30 Days</span>
                <span className={summarySelectedDays === 60 ? "active cursor-default" : "cursor-pointer"} onClick={() => setSummarySelectedDays(60)}>60 Days</span>
            </div>
            {loading ? <DonutChart
                data={[]}
                // width={510}
                height={180}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                }}
                title={{
                    visible: false,
                    text: "Submission Summary",
                }}
                offestY={50}
                radius={1}

                angleField="value"
                colorField="category"
                label={{
                    visible: false,
                    type: "spider",
                    labelHeight: 28,
                    content: (v) => `${v.category}\n${v.value}`,
                }}
            /> : <DonutChart
                data={submissionSummaryData}
                // width={510}
                height={180}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                }}
                title={{
                    visible: false,
                    text: "Submission Summary",
                }}
                offestY={50}
                radius={1}

                angleField="value"
                colorField="category"
                label={{
                    visible: false,
                    type: "spider",
                    labelHeight: 28,
                    content: (v) => `${v.category}\n${v.value}`,
                }}
            />}
        </div>
    </div>
}

export const TodayClassesBlock = ({ todayClasses, setOnlineClass, setLaunchDetail, setLaunchLoading, setPortalId, id, setRecurring, setClassId, setAnalysisBtn, updateTodayClassesList, setUpdateTodayClassesList, loading }) => {
    return <div className="col-md-4 col-lg-4">
        <div className="card-new card card-profile-info-card mb-30 dashboard-blocks">
            <div className="card-header">Today's Classes {loading ? <i className="fas fa-cog fa-spin"></i> : ""}</div>
            <div className="class-container course-dashboard-blocks">
                {todayClasses.length || loading ? "" : Str.noRecord}
                <table>
                    <tbody>
                        {todayClasses.map((item, index) => {
                            return <tr key={index}>
                                <td>{moment(item.class_from, "hh:mm a").format(TABLE_TIME_FORMAT)} - {moment(item.class_to, "hh:mm a").format(TABLE_TIME_FORMAT)}</td>
                                <td>
                                    <div className='today-class-link'>{item.class_title}</div>
                                    <span>{RenderClassType(`icononly${item.class_type}`).html}</span>
                                    <span>{RenderClassStatus(item.status).html}&nbsp; {RenderClassStatus(item.subStatus).html}</span>
                                    <span>{item.status == "Live" && item.subStatus == "Launched" ? `${item.nested_started_at ? ("at " + moment.unix(item.nested_started_at).format(TABLE_TIME_FORMAT)) : ""} ${item.nestedStarted_by ? (" by " + item.nestedStarted_by) : ""}` : item.subStatus == "Ended" ? `${item.nested_ended_at ? ("at " + moment.unix(item.nested_ended_at).format(TABLE_TIME_FORMAT)) : ""} ${item.nestedEnded_by ? (" by " + item.nestedEnded_by) : ""}` : ""}</span>
                                </td>
                                <td>{OnlineClassFunctions({ setOnlineClass, setLaunchDetail, setLaunchLoading, setPortalId, setAnalysisBtn }).upcomingImpDateColumn(item, id, setRecurring, setClassId, updateTodayClassesList, setUpdateTodayClassesList)}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}
export const AverageClassRatingBlock = ({ averageClassRatingDays, setAverageClassRatingDays, avgRatingArr, avgRating, loading }) => {
    const options = {
        chart: {
            type: 'spline',
            height: 160
        },
        title: {
            text: ''
        },
        series: [
            {
                data: avgRatingArr,
                name: "Students Class Ratings"
            }
        ],
        yAxis: {
            title: {
                text: ""
            }
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false,
                    inside: true
                }
            }
        },
    };
    return <div className="col-md-3 col-lg-3">
        <div className="card-new card card-profile-info-card mb-30 relative-class min-height-col">
            <div className="card-header">Average Class Rating {loading ? <i className="fas fa-cog fa-spin"></i> : ""}</div>
            <div className='dashboard-course-rating'>
                {OnlineClassFunctions({}).printRatingStars(avgRating ?? 0)}
            </div>
            <div className="days-indication avg-days-indication">
                <span className={averageClassRatingDays === 7 ? "active cursor-default" : "cursor-pointer"} onClick={() => setAverageClassRatingDays(7)}>7 Days</span>
                <span className={averageClassRatingDays === 30 ? "active cursor-default" : "cursor-pointer"} onClick={() => setAverageClassRatingDays(30)}>30 Days</span>
                <span className={averageClassRatingDays === 60 ? "active cursor-default" : "cursor-pointer"} onClick={() => setAverageClassRatingDays(60)}>60 Days</span>
            </div>
            {/* <div className="line-chart-dashboard"> */}
            <div className="">
                <HighchartsReact highcharts={Highcharts} options={options} />
                {/* {avgRatingArr.length ? <ResponsiveContainer aspect={2} width={350}>
                    <LineChart data={avgRatingArr}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="main_avg_rating" stroke="#82ca9d" />
                    </LineChart>
                </ResponsiveContainer> : ""} */}
            </div>
        </div>
    </div>
}

export const OnlineClassesBlock = ({ onlineClass, onlineClassSelectedDays, setOnlineClassSelectedDays, loading }) => {
    return <div className="col-md-4 col-lg-4">
        <div className="card-new card card-profile-info-card mb-30">
            <div className="card-header">Online Classes {loading ? <i className="fas fa-cog fa-spin"></i> : ""}</div>
            <div className="days-indication">
                <span className={onlineClassSelectedDays === 7 ? "active cursor-default" : "cursor-pointer"} onClick={() => setOnlineClassSelectedDays(7)}>7 Days</span>
                <span className={onlineClassSelectedDays === 30 ? "active cursor-default" : "cursor-pointer"} onClick={() => setOnlineClassSelectedDays(30)}>30 Days</span>
                <span className={onlineClassSelectedDays === 365 ? "active cursor-default" : "cursor-pointer"} onClick={() => setOnlineClassSelectedDays(365)}>1 Year</span>
            </div>
            <ColumnChart
                data={onlineClass}
                title={{
                    visible: false,
                    text: "Online Classes",
                }}
                // width={510}
                height={190}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                }}
                xAxis={{
                    label: {
                        visible: true,
                        autoRotate: true,
                    },
                    title: {
                        visible: false,
                        text: "",
                        style: {
                            fontSize: 14,
                            fill: "black",
                            lineWidth: 4,
                            fontWeight: 600,
                        },
                    },
                }}
                yAxis={{
                    label: {
                        visible: true,
                        autoRotate: true,
                    },
                    title: {
                        visible: false,
                        text: "Total Students (In numbers)",
                        style: {
                            fontSize: 14,
                            fill: "black",
                            lineWidth: 4,
                            fontWeight: 600,
                        },
                    },
                }}
                autoFit
                padding="auto"
                xField="status"
                yField="Classes"
                meta={{
                    type: {
                        alias: "category",
                    },
                    Students: {
                        alias: "Students",
                    },
                }}
            />
        </div>
    </div>
}

export const ClassAttendanceBlock = ({ attendanceDays, setAttendanceDays, attendanceArr, loading }) => {
    return <div className="col-md-5 col-lg-5">
        <div className="card-new card card-profile-info-card mb-30 ">
            <div className="card-header">Class Attendance {loading ? <i className="fas fa-cog fa-spin"></i> : ""}</div>
            <div className="days-indication">
                <span className={attendanceDays === 7 ? "active cursor-default" : "cursor-pointer"} onClick={() => setAttendanceDays(7)}>7 Days</span>
                <span className={attendanceDays === 30 ? "active cursor-default" : "cursor-pointer"} onClick={() => setAttendanceDays(30)}>30 Days</span>
                <span className={attendanceDays === 365 ? "active cursor-default" : "cursor-pointer"} onClick={() => setAttendanceDays(365)}>1 Years</span>
            </div>
            <ColumnChart
                data={attendanceArr}
                title={{
                    visible: false,
                    text: "Class Attendance",
                }}
                // width={650}
                height={190}
                options={{
                    responsive: true,
                    maintainAspectRatio: true,
                }}
                xAxis={{
                    label: {
                        visible: true,
                        autoRotate: true,
                    },
                    title: {
                        visible: false,
                        text: "",
                        style: {
                            fontSize: 14,
                            fill: "black",
                            lineWidth: 4,
                            fontWeight: 600,
                        },
                    },
                }}
                yAxis={{
                    label: {
                        visible: true,
                        autoRotate: true,
                        text: "sadfgsdf"
                    },
                    title: {
                        visible: false,
                        text: "Total Students (In numbers)",
                        style: {
                            fontSize: 14,
                            fill: "black",
                            lineWidth: 4,
                            fontWeight: 600,
                        },
                    },
                }}
                autoFit
                padding="auto"
                xField="date"
                yField="Attendance"
                meta={{
                    type: {
                        alias: "category",
                    },
                    Students: {
                        alias: "Students",
                    },
                }}
            />
        </div>
    </div>
}
export const LaunchPopupBlock = ({ showMore, setShowMore, launchDetail, setLoader, portalId, analysisBtn, onlineClass, loader, setAnalysisBtn, setUpdateTodayClassesList, updateTodayClassesList, setLaunchDetail }) => {
    return <div className="modal-body class-launch-modal">
        <div className="d-flex">
            <h4 className="landing-heading">{launchDetail.class_title}</h4>
            <div className="as-widget no-text-transformation ml-auto" title="Hybrid">
                {launchDetail.class_type ? RenderClassType(launchDetail.class_type).html : ""}
            </div>
        </div>
        <div className="time-date-bar d-flex align-items-center mt-2">
            <div className="time-date-bar-in">
                {launchDetail.class_date ? <><i className="fal fa-calendar-alt" title='Date and Time'></i>
                    {` ${moment(launchDetail.class_date).format(
                        `dddd, ${TABLE_DATE_FORMAT}`
                    )} ${launchDetail.class_from} - ${launchDetail.class_to
                        }`}</>
                    : ""}
            </div>
            <div className="ml-3">
                {launchDetail.status ? RenderClassStatus(launchDetail.status).html : ""}
                {launchDetail.subStatus ? RenderClassStatus(launchDetail.subStatus).html : ""}
            </div>
        </div>
        <div className="room-name my-2">
            {launchDetail.multiple_room?.length ? launchDetail.multiple_room.map((item, index) => {
                return <span className="mr-2" key={index}><i className="fal fa-university" title='Campus'></i> {item.campus_room?.room_no}, {item.campus_room?.room_description}</span>
            }) : ""}
        </div>
        <div className="room-name my-2 d-flex">
            {launchDetail.arrAttendance ?
                <span className="mr-2">
                    <i className="fal fa-users" title='Student Attendance'></i>{" "}
                    {launchDetail.arrAttendance?.getattend} /{" "}{launchDetail.arrAttendance?.total} ({((launchDetail.arrAttendance?.getattend / launchDetail.arrAttendance?.total) * 100) ? ((launchDetail.arrAttendance?.getattend / launchDetail.arrAttendance?.total) * 100).toFixed(2) : 0}%)
                </span>
                : ""
            }
            {launchDetail.arrAttendanceCampus ? <span className="mr-2">
                <i className="fal fa-university" title='Campus Attendance'></i>{" "}
                {launchDetail.arrAttendanceCampus?.getattend} /{" "} {launchDetail.arrAttendanceCampus?.total} (
                {launchDetail.arrAttendanceCampus?.attendpercentage > 0
                    ? launchDetail.arrAttendanceCampus?.attendpercentage.toFixed(2)
                    : 0}
                %)
            </span> : ""}
            {launchDetail.arrAttendanceOnline ? <span className="mr-2">
                <i className="fal fa-house-signal" title='Remote Attendance'></i>{" "}
                {launchDetail.arrAttendanceOnline?.getattend} /{" "} {launchDetail.arrAttendanceOnline?.total} (
                {launchDetail.arrAttendanceOnline?.attendpercentage > 0
                    ? launchDetail.arrAttendanceOnline?.attendpercentage.toFixed(2)
                    : 0}
                %)
            </span> : ""}
            {launchDetail.subStatus === "Ended" ? <div className="icon-w-text mr-2">
                <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
                <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
                <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
                <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
                <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
            </div> : ""}
        </div>
        <div className="mt-3 d-flex align-items-center flex-wrap">
            {(launchDetail.launch_show == 1 || launchDetail.on_campus_show == 1) && !((launchDetail.launch_disable == 1 || (launchDetail.status === "Past" || launchDetail.status === "Cancelled")) && ((launchDetail.on_campus_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled"))) ? <div
                className={
                    "box-style-new2 " +
                    ((launchDetail.launch_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled")
                        ? ""
                        : " bg-sidebar")
                }
            >
                {!loader.includes(1) && (
                    <button
                        className="btn btn-success mr-2 mt-2"
                        disabled={(launchDetail.launch_disable == 1 || (launchDetail.status === "Past" || launchDetail.status === "Cancelled")) && ((launchDetail.on_campus_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled"))}
                        onClick={() => {
                            if (launchDetail && (launchDetail.launch_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled"))) {
                                OnlineClassFunctions({ launchDetail, loader, setLoader, setAnalysisBtn }).launchVClass(launchDetail.id, 1, true, updateTodayClassesList, setUpdateTodayClassesList)
                                return
                            }
                            if (launchDetail && (launchDetail.on_campus_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled"))) {
                                launchDetail.class_type == "hybrid"
                                    ? OnlineClassFunctions({ launchDetail, loader, setLoader, setAnalysisBtn }).launchVClass(launchDetail.id, 1, true, updateTodayClassesList, setUpdateTodayClassesList)
                                    : OnlineClassFunctions({ launchDetail, loader, setLoader, setAnalysisBtn }).launchCampusClass("start")
                            }
                        }}
                    >
                        <i className="fal fa-rocket"></i> Launch Class
                    </button>
                )}
            </div> : ""}
            {(launchDetail.end_meeting_show == 1 || launchDetail.end_campus_show == 1) && !(launchDetail.end_meeting_disable == 1 && launchDetail.end_campus_disable == 1) ? (
                <div
                    className={
                        "box-style-new2 " +
                        (launchDetail.end_meeting_disable == 1
                            ? ""
                            : " bg-sidebar")
                    }
                >
                    {!loader.includes(3) && (
                        <button
                            // className={
                            //   launchDetail.end_meeting_disable == 0 &&
                            //   "dashboard_hover"
                            // }
                            className="btn btn-danger mr-2 mt-2"
                            disabled={launchDetail.end_meeting_disable == 1 && launchDetail.end_campus_disable == 1}
                            onClick={() => {
                                if (launchDetail &&
                                    launchDetail.end_meeting_disable == 0) {
                                    OnlineClassFunctions({ launchDetail, loader, setLoader, setAnalysisBtn }).launchVClass(launchDetail.id, 3, false, updateTodayClassesList, setUpdateTodayClassesList)
                                    return
                                }
                                if (launchDetail && launchDetail.end_campus_disable == 0) {
                                    launchDetail.status == "hybrid" ? OnlineClassFunctions({ launchDetail, loader, setLoader, setAnalysisBtn }).launchVClass(launchDetail.id, 3, false, updateTodayClassesList, setUpdateTodayClassesList) : OnlineClassFunctions({ launchDetail, loader, setLoader, setAnalysisBtn }).launchCampusClass("end")
                                }
                            }}
                        >
                            <i className="fal fa-window-close"></i> End Class
                        </button>
                    )}
                </div>
            ) : ""}
            {launchDetail.status === "Past" && launchDetail.subStatus === "Ended" ? <>
                <button
                    className="btn btn-success btn-sidebar mr-2 mt-2"
                    title="Class Analytics"
                    disabled={analysisBtn.disable}
                    onClick={() => window.open(analysisBtn.link, "_blank")}
                >
                    <i className="fal fa-analytics"></i> Class Analytics
                </button>
                <button
                    className="btn btn-primary btn-sidebar mr-2 mt-2"
                    title="Class Attendance"
                    onClick={() => window.open(`/classReport/summary/${onlineClass.course_id}/${onlineClass.class_id}`, "_blank")}
                >
                    <i className="fal fa-users"></i> Class Attendance
                </button>
                {launchDetail.viewRecording?.status === "YES" ? <a
                    className="btn btn-primary btn-sidebar mr-2 pt-1 mt-2"
                    title="Watch Video"
                    href={launchDetail.viewRecording?.link}
                    target="_blank"
                    download
                >
                    <i className="fal fa-play"></i> Watch Video
                </a> : <button
                    className="btn btn-primary btn-sidebar mr-2 pt-1 mt-2"
                    title="Watch Video"
                    disabled
                >
                    <i className="fal fa-play"></i> Watch Video
                </button>}
                <button
                    className="btn btn-primary btn-sidebar mr-2 mt-2"
                    title="Class Files"
                    onClick={() => {
                        Swal.fire({
                            title: "Loading",
                            onOpen: function () {
                                Swal.showLoading();
                            },
                        });
                        getClassFiles({ "big_blue_button_id": launchDetail.big_blue_button_id }).then(res => {
                            let arr = res.data.data.Contents || [];
                            let Name = res.data.data.Name;
                            if (arr.length) {
                                arr.forEach(item => {
                                    const aTag = document.createElement("a");
                                    aTag.target = "_blank"
                                    aTag.href = `https://${Name}/${item.Key}`;
                                    aTag.setAttribute("download", item.Key);
                                    document.body.appendChild(aTag);
                                    aTag.click();
                                })
                                Swal.close();
                            } else {
                                Swal.close();
                                Swal.fire("No Files", "There are no Files Uploaded in this class", "success");
                            }
                        }).catch(err => {
                            Swal.close();
                            console.error("error: ", err)
                            Swal.fire("Error!", "Something Went Wrong", "error");
                        })
                    }}
                ><i className="fal fa-file"></i> Class Files</button>
            </> : <>
                <button
                    className="btn btn-primary mr-2 mt-2"
                    title="System Check"
                    onClick={() => window.open("https://test.8x8.vc/", "_blank")}
                    disabled={launchDetail.launch_show == 0}
                >
                    <i className="fal fa-check-double"></i> System Check
                </button>
                <button
                    className="btn btn-primary btn-orange mr-2 mt-2"
                    title="Speed Test"
                    onClick={() => window.open("https://speedtest.mybroadband.co.za/", "_blank")}
                >
                    <i className="fal fa-tachometer-slowest"></i> Speed Test
                </button>
            </>}
            {launchDetail.status === "Past" ? "" : <br />}
            {launchDetail.subStatus === "Launched" ? <div className={"box-style-css box-style-new3 active d-flex mb-0 align-items-center " + (launchDetail.status === "Past" ? "" : "mt-2")}>
                <h4 className="mb-0 mr-1">
                    <i className="fal fa-user-check"></i> Join as Guest&nbsp;
                    <i className="fal fa-link"
                        disabled={launchDetail.launch_show == 0}
                        role={launchDetail.launch_show != 0 && "button"}
                        onClick={() => OnlineClassFunctions({ launchDetail, setAnalysisBtn, updateTodayClassesList, setUpdateTodayClassesList }).joinAsAdmin("copy")}
                    >
                    </i>
                </h4>
                <div className="input-box-style-2">
                    <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        disabled={launchDetail.launch_show == 0}
                        value={launchDetail.lerner ?? ""}
                        onChange={(e) => setLaunchDetail({ ...launchDetail, lerner: e.target.value ?? "" })}
                    />
                    <button
                        disabled={launchDetail.launch_show == 0}
                        className="btn btn-submit"
                        onClick={() => OnlineClassFunctions({ launchDetail, setAnalysisBtn, updateTodayClassesList, setUpdateTodayClassesList }).joinAsAdmin("guest")}
                    >
                        <i className="fal fa-chevron-right"></i>
                    </button>
                </div>
            </div> : ""}
        </div>
        <div className="launcher-info-bar d-flex py-3 mt-3">
            {launchDetail.courseName ? <div className="launcher-info-bar-in mr-3">
                <p className="mb-1"><b>Courses</b></p>
                <Link to={`/courseAdministration/coursesdetails/${launchDetail.intakeID}/detailCourses/show`} className="as-text-blue curser">{launchDetail.courseName}</Link>
            </div> : ""}
            {launchDetail.lecture_name ? <div className="launcher-info-bar-in mr-3">
                <p className="mb-1"><b>Lecturer</b></p>
                <UserColumn
                    firstName={launchDetail.lecturer.Firstname}
                    lastName={launchDetail.lecturer.Lastname}
                    imgUrl={launchDetail.lecturer.picture_me}
                    email={launchDetail.lecturer.Email}
                    mobile={launchDetail.lecturer.Mobile}
                    userId={launchDetail.lecturer.UserID}
                    userActiveStatus={launchDetail.lecturer.Status}
                    isPopUpRight={true}
                />
            </div> : ""}
            {launchDetail.multiple_lecture?.length ? <div className="launcher-info-bar-in mr-3">
                <p className="mb-1"><b>Guest Lecturers</b></p>
                {showMore ? <div>
                    <div className="assigned-profile-tit">{launchDetail.multiple_lecture.length ? launchDetail.multiple_lecture.map((data, index) => <UserColumn
                        firstName={data.get_lecture_details.Firstname}
                        lastName={data.get_lecture_details.Lastname}
                        imgUrl={data.get_lecture_details.picture_me}
                        email={data.get_lecture_details.Email}
                        mobile={data.get_lecture_details.Mobile}
                        userId={data.get_lecture_details.UserID}
                        userActiveStatus={data.get_lecture_details.Status}
                        isUserNameHidden={launchDetail.multiple_lecture.length === 1 ? false : !showMore}
                        isPopUpRight={true}
                        key={index}
                    />) : "-"}</div>
                    {launchDetail.multiple_lecture.length && launchDetail.multiple_lecture.length > 1 ? <div
                        className="table-expand-btn"
                        onClick={() => setShowMore(!showMore)}>
                        See Less
                    </div> : ""}
                </div> : <div className="assigned-profile-overlay-out">
                    <div className="assigned-profile-overlay">{launchDetail.multiple_lecture.length ? launchDetail.multiple_lecture.map((data, index) => <UserColumn
                        firstName={data.get_lecture_details.Firstname}
                        lastName={data.get_lecture_details.Lastname}
                        imgUrl={data.get_lecture_details.picture_me}
                        email={data.get_lecture_details.Email}
                        mobile={data.get_lecture_details.Mobile}
                        userId={data.get_lecture_details.UserID}
                        userActiveStatus={data.get_lecture_details.Status}
                        isUserNameHidden={launchDetail.multiple_lecture.length === 1 ? false : !showMore}
                        isPopUpRight={true}
                        key={index}
                    />) : "-"}</div>
                    {launchDetail.multiple_lecture.length && launchDetail.multiple_lecture.length > 1 ? <div
                        className="table-expand-btn"
                        onClick={() => setShowMore(!showMore)}>
                        See More
                    </div> : ""}
                </div>}
            </div> : ""}
        </div>
        {launchDetail.class_description ? <div className={`${launchDetail.big_blue_button_id ? "launcher-desc" : ""} py-3`}>
            {launchDetail.class_description}
        </div> : ""}
        {launchDetail.big_blue_button_id ? <div className="pt-3 ">
            <b>BBB: {(launchDetail?.big_blue_button_id + (launchDetail?.bbbname && ` - ${launchDetail?.bbbname}`))} <CopyToClipboard data={(launchDetail?.big_blue_button_id + (launchDetail?.bbbname && ` - ${launchDetail?.bbbname}`))} /></b>
        </div> : ""}
    </div>
}