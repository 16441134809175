import React from "react";
import Skeleton from "react-loading-skeleton";
const SkeletonMessage = (props) => {
    return (
       
      <div className="card-body post-wrap-body p-0 skelaton-custom">
          {Array(props && props.count ? props.count : 1)
            .fill()
            .map((item, index) => (
              <div className="media user-post-main  border d-flex border-0" key={index}>
                <div className="media-icon rounded-circle">
                    <div className="user-profile-pic">
                    <Skeleton circle={true} height={180}/>
                    </div>
                    {/* <div className="online-status">
                        <i data-v-54ded044="" className="fas fa-check"></i>
                    </div> */}
                </div>
                <div className="media-body card">
                    <div className="card-header">
                        <h6><Skeleton width={50} /><small><Skeleton width={100} /></small></h6>
                        <p className="m-0" style={{ fontSize: 20, lineHeight: 2 }}><Skeleton count={2} /></p>
                    </div>
                    {/* <div className="card-body">
                        comments
                    </div> */}
                </div>
              </div>													
  
            ))}
      </div>
    );
  };
  export default SkeletonMessage;
