import axiosInstance from "../utils/axios";

export const AboutService = async (values) => {
  return await axiosInstance().post(`/updateAbout`, values);
};
export const LoginDetailService = async (values) => {
  return await axiosInstance().post(`/updateLogin`, values);
};

export const ContactDetailService = async (values) => {
  return await axiosInstance().post(`/updateContacts`, values);
};

export const AddressDetailService = async (values) => {
  return await axiosInstance().post(`/updateAddress`, values);
};
export const moveFileToDbService = async (values) => {
  return await axiosInstance().post(`/moveFileToDb`, values);
};

export const HighSchooldDetailService = async (values) => {
  return await axiosInstance().post(`/updateHighSchool`, values);
};

export const SubjectDetailService = async (values) => {
  return await axiosInstance().post(`/updateSubject`, values);
};
export const GetSubjectDetailService = async () => {
  return await axiosInstance().get(`/getHighSubjects`);
};

export const QualificationDetailService = async (values) => {
  return await axiosInstance().post(`/updateQualification`, values);
};

export const GetQualificaitonDetailService = async () => {
  return await axiosInstance().get(`/getHighQualification`);
};
export const UpdateResponsible = async (values) => {
  return await axiosInstance().post(`/updateResponsible`, values);
};
export const GetPayerDetailService = async () => {
  return await axiosInstance().get(`/getPayerDetails`);
};
/** payer */
export const PayerAboutService = async (values) => {
  return await axiosInstance().post(`/updatePayerAbout`, values);
};

export const PayerContactDetailService = async (values) => {
  return await axiosInstance().post(`/updatePayerContacts`, values);
};

export const PayerAddressDetailService = async (values) => {
  return await axiosInstance().post(`/updatePayerAddress`, values);
};
export const ProfileCoverDetailService = async (values) => {
  return await axiosInstance().post(`/updateProfileCover`, values);
};

export const ProfileImageDetailService = async (values) => {
  return await axiosInstance().post(`/updateProfileImage`, values);
};

export const GetMyLicensesService = async () => {
  return await axiosInstance().get(`/getMyLicenses`);
};

export const GetMyCertificateService = async () => {
  return await axiosInstance().get(`/getMyCertificate`);
};
export const ProfileCertificateService = async (values) => {
  return await axiosInstance().post(`/updateMyCertificate`, values);
};

export const RemoveMyHighQualificationService = async (values) => {
  return await axiosInstance().post(`/removeMyHighQualification`, values);
};
export const RemoveMyHighQualificationCertificateService = async (values) => {
  return await axiosInstance().post(`/removeMyHighQualificationCertificate`, values);
};

export const ProfilePersonalDocService = async (values) => {
  return await axiosInstance().post(`/updateMyPersonalDocs`, values);
};
export const RemoveMyPersonalDocumentsService = async (values) => {
  return await axiosInstance().post(`/removeMyPersonalDocument`, values);
};
export const GetMyPersonalDocService = async (values) => {
  return await axiosInstance().get(`/getMyPersonalDocuments`, {
    params: values,
  });
};

export const getStudentDetails = async () => {
  return await axiosInstance().post(`studentDetails`);
};

export const RemoveProfileCover = async (values) => {
  return await axiosInstance().post(`/removeProfileCover`, values);
};

export const RemoveProfileImage = async (values) => {
  return await axiosInstance().post(`/removeProfileImage`, values);
};

export const countryList = async () => {
  return await axiosInstance().get(`/countries`);
};

export const LogoutService = async () => {
  return await axiosInstance().get(`/logout`);
};
