import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import Str from "../../../../common/Str";
import { TrimText } from "../../../../common/TrimText";
import Swal from "sweetalert2";
import {assignedQuizToQuestion, GetAssessmentAssignedOrNotAssignedList, GetQuizFilters, GetQuizFiltersCancelToken, GetQuizTopics } from "../../../../../services/SmartAssessmentService";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import { QuizStatusRender } from "../../../../systemadministration/usermanagement/CheckRole";
import { TABLE_ROWS_PER_PAGE } from "../../../../../utils/Constants";
import Tablefilter from "../../../../common/Tablefilter";
import { RenderQuizStatus, RenderQuizTopic, RenderQuizType } from "../../../../../utils/CommonStatusItems";
import { handleTableScroll } from "../../../../../utils/commonFunction";
import axios from "axios";

const Available = ({ data, id, refresh, loading, setData2, totalRows2 }) => {
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });

  const [sortkey, setSortKey] = useState("quiz_name");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [topics, setTopics] = useState({ arr: [], checkObj: {} });
  const [quizType, setQuizType] = useState({ arr: [], checkObj: {} }); 
  const [filterData, setFilterData] = useState({status : [], topic : [], type : []});
  const [gearId, setGearId] = useState("");

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  // useEffect(() => {
  //   GetQuizFilters(id,"available").then((res)=>{
  //     setFilterData({...res.data, status : res.data.quizStatusFilters, topic : res.data.topicFilters, type : res.data.quizTypeFilter})
  //   }).catch(err=>console.log(err))
  // }, [])

  useEffect(() => {
    const availableSource = axios.CancelToken.source();

    const fetchAvailableData = async () => {
      try {
        const res = await GetQuizFiltersCancelToken(id,"available", availableSource.token);
        if(res.status == 200){
          setFilterData({...res.data, status : res.data.quizStatusFilters, topic : res.data.topicFilters, type : res.data.quizTypeFilter})
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchAvailableData();

    return () => {
      availableSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    setData2({
      type: "available",
      viaStatus: status.arr.length ? status.arr : [],
      page: page,
      limit: perPage,
      search: search,
      key: sortkey,
      sort: sortOrder,
      questionId: id,
      exportStatus: false,
      viaTopics: topics.arr.length > 0 ? topics.arr : [],
      // viaQuizType: quizType.arr.length > 0 ? quizType.arr : [],
    })
  }, [status, perPage, page, search, sortkey, sortOrder,topics, quizType])

  const handleFilter = (e, typeName = "") => {
    const value = e.target.value;
    if (typeName === "search") {
      setSearch(value);
    } 
  };

  const addresource = (ID) => {
    setGearId(ID);
    assignedQuizToQuestion({ quizId: ID, questionId: id, deleteQulification: "false" }).then((res) => {
      // Swal.fire({ icon: "success", title: "Success", text: "Added Successfully" });
      refresh();
    }).catch(err => Swal.fire({ icon: "error", title: "Error", text: err.response.data.message }))
    .finally(()=>{setGearId("")});

  }




  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };


  const resetFilter = () => {
    setSearch("");
    setStatus({ arr: [], checkObj: {} });
    setTopics({ arr: [], checkObj: {} });
    setQuizType({ arr: [], checkObj: {} });
  };

  const wordSpliter = (str, type) => {
    // const strArr = str?.toString()?.split(",");
    const strArr = Array.isArray(JSON.parse(str)) ? JSON.parse(str) : [];
    const newStrArr = strArr.length ? strArr?.map(item => {      
      return item ? <p className="as-widget mr-1 mt-1" style={{color: '#333333', background: '#e6e6e6'}}>{item}</p> : '-';
    }) : "-";
    return newStrArr;
  }


  const wordSpliterTopics = (str) => {
    let htmlOutput = []
    const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length ? strArr?.map(item => {
      filterData.topic.map((val)=>{
        if(val.value == item){
          htmlOutput.push(RenderQuizTopic(val.label,val.color).html)
        }
        return;
      });   
    }) : "-";
    return htmlOutput.length ? htmlOutput : []
  }
  const wordSpliterTopicsExport = (str) => {
    let htmlOutput = []
    const strArr = str && Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length ? strArr?.map(item => {
      filterData.topic.map((val)=>{
        if(val.value == item){
          htmlOutput.push(RenderQuizTopic(val.label,val.color).text)
        }
        return;
      });   
    }) : "-";
    return htmlOutput.length ? htmlOutput.join(",") : []
  }

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "quiz_name",
      // sortFunction: nameSort,
      sortField: "quiz_name",
      sortable: true,
      cell: (row) => <span className="as-text-blue curser" onClick={() => addresource(row.quid)} title={row.quiz_name}><p className="textLimit150"> {row.quiz_name} </p></span>
    },
    // {
    //   name: "Quiz Type",
    //   selector: "quiz_type",
    //   sortField: "quiz_type",
    //   sortable: false,
    //   cell: (row) => (row.quiz_type && filterData.type.length ? RenderQuizType(filterData.type.find(item => item.value==row.quiz_type)).html : "-")
    // },
    {
      name: "#Question",
      selector: "noq",
      sortField: "noq",
      sortable: true,
      cell: (row) => row.noq
    },
    {
      name: "Status",
      selector: "Status",
      sortField: "Status",
      sortable: true,
      cell: (row) => row.status ? RenderQuizStatus(row.status).html : "-",
    },
    {
      name: "Keywords",
      // selector: "keywords",
      // sortField: "keywords",
      // sortable: true,
      cell: (row) => row?.keywords ? <div className="d-flex flex-wrap">{wordSpliter(row?.keywords,"keywords")}</div> : '-',
    },
    {
      name: "Topics",
      cell: (row) => row?.topic ? <div className="d-flex flex-wrap">{wordSpliterTopics(row?.topic).length ? wordSpliterTopics(row?.topic).map((item, index) => <React.Fragment key={index}>{item} &nbsp;</React.Fragment>) : ""}</div> : '-',
    },
    {
      name: "Actions",
      maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <button className="btn btn-primary rounded-circle" title="Add" onClick={() => addresource(row.quid)} >
              {gearId==row.quid ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-plus"></i>}
            </button>


          </div>
        </div>
      ),
    },
  ])

  const exportData = async (fileType, fileName) => {
    let data;
    const header = ["Name", "Question", "Status", "Keywords", "Topics"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    GetAssessmentAssignedOrNotAssignedList({
      type: "available",
      viaStatus: status.arr.length ? status.arr : [],
      page: page,
      limit: perPage,
      search: search,
      key: sortkey,
      sort: sortOrder,
      questionId: id,
      exportStatus: true,
      viaTopics: topics.arr.length > 0 ? topics.arr : [],
      // viaQuizType: quizType.arr.length > 0 ? quizType.arr : []
    })
      .then((res) => {
        data = res?.data.result
        data = data?.map((row) => {
          return({
          ...row,
          Name: row?.quiz_name ? row?.quiz_name : "-",
          // "Quiz Type" : row?.quiz_type ? RenderQuizType(filterData.type.find(item => item.value==row.quiz_type)).text : "-",
          Question: row?.noq ? row?.noq : "-",
          Status: row.status ? RenderQuizStatus(row.status).text : "-",
          Keywords: (row?.keywords && row?.keywords.length > 0 && Array.isArray(JSON.parse(row?.keywords)))  ? JSON.parse(row?.keywords)?.map(item=>item).join(", ") : "-",
          Topics: (row?.topic && wordSpliterTopicsExport(row?.topic).length) ? wordSpliterTopicsExport(row?.topic) : "-" ,
        })});
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

          const blobURL = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["quiz_name"],
              row["noq"],
              row["Status"],
              row["Keywords"],
              row["Topics"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })


  };

  return (
    <div className="modal-body my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="custom-table-div filter-search-icon card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">

            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                    <div id="assessment-table-main_filter" className="dataTables_filter">
                      <label>
                        <input type="search" placeholder="Search" aria-controls="assessment-table-main" onChange={(e) => handleFilter(e, "search")} value={search} />
                      </label>
                    </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                  <Tablefilter
                    filterName="Status"
                    optionArr={filterData.status}
                    state={status}
                    setState={setStatus}
                    renderLabelFunction={RenderQuizStatus}
                  />
                  <Tablefilter
                    filterName="Topic"
                    optionArr={filterData.topic}
                    state={topics}
                    setState={setTopics}
                    renderLabelFunction={RenderQuizTopic}
                    uniqueId = "availTopic"
                  />
                  {/* <Tablefilter
                    filterName="Quiz Type"
                    optionArr={filterData.type}
                    state={quizType}
                    setState={setQuizType}
                    renderLabelFunction={RenderQuizType}
                    uniqueId = "availQuizType"                    
                  /> */}
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Available Quizzes"); }} title="Export spreadsheet">
                      <i className="fal fa-file-excel"></i>
                    </button>
                    <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Available Quizzes"); }} title="Export CSV">
                      <i className="fal fa-file-csv"></i>
                    </button>
                    <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Available Quizzes"); }} title="Export PDF">
                      <i className="fal fa-file-pdf"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        {/* {console.log("data from av outer",data)} */}
        <DataTable
          data={data}
          defaultSortField="quiz_name"
          defaultSortAsc={false}
          columns={columns}
          pagination
          noDataComponent={Str.noRecord}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          paginationServer
          onSort={handleSort}
          paginationTotalRows={totalRows2}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
    </div>
  );
};

export default Available;
