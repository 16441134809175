import React, { useEffect, useRef, useState, useCallback } from 'react';
// import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Calendar from '@toast-ui/react-calendar';
import 'tui-calendar/dist/tui-calendar.css';
import moment from 'moment';
import {
  GetBookingsCalendarView,
  GetClassesCalendarView,
} from '../../../services/ScheduleManagementService';
import { TABLE_DATE_FORMAT } from '../../../utils/Constants';
import * as XLSX from 'xlsx';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import Papa from 'papaparse';
import SkeletonTicketList from '../../../loaders/SkeletonTicketList';
import { shortenText } from '../../../utils/commonFunction';
import { BookingModal } from './BookingModal';

export const CalendarView = ({
  campusID = 0,
  roomID = 0,
  lecturerId__ = '',
  courseId__ = 0,
  onListView,
  campusName_,
  roomName,
}) => {
  const [scheduledClasses, setScheduledClasses] = useState([]);
  const [calendarView, setCalendarView] = useState('day');
  const [day, setDay] = useState('daily');
  const calendarRef = useRef(null);
  const [isClassesLoaded, setIsClassesLoaded] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [customDay, setCustomDay] = useState('');
  const [todayCalendar, setTodayCalendar] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalPosition, setModalPosition] = useState(null);

  let COMMON_CUSTOM_THEME = {
    'common.holiday.color': '#bbb',
    'month.holidayExceptThisMonth.color': '#bbb',
    'week.today.color': '#467fcf',
    'month.schedule.top': '0px',
    'month.schedule.marginTop': '0px',
    'month.schedule.marginLeft': '0px',
    'month.schedule.marginRight': '0px',
    'week.timegridOneHour.height': '50px',
  };

  useEffect(() => {
    const classesBookings = async () => {
      try {
        const classesListResponse = await GetClassesCalendarView(
          customDay === 'custom' ? 'custom' : day,
          campusID,
          roomID,
          lecturerId__,
          courseId__,
          startDate,
          endDate
        );

        const bookingListResponse = await GetBookingsCalendarView(
          customDay === 'custom' ? 'custom' : day,
          campusID,
          roomID,
          courseId__,
          startDate,
          endDate
        );

        const filterApproveBookings = bookingListResponse.data?.classes.filter(
          (item) => item.booking_status === 'APPROVED'
        );

        const displayClassesOnCalendar = classesListResponse?.data?.classes.map(
          (item) => {
            return {
              id: String(item.id),
              calendarId: '1',
              title: item.class_title,
              category: 'time',
              start: moment(
                `${item.class_date} ${item.class_from}`,
                'YYYY-MM-DD hh:mm A'
              ).toDate(),
              end: moment(
                `${item.class_date} ${item.class_to}`,
                'YYYY-MM-DD hh:mm A'
              ).toDate(),
              raw: { ...item, type: 'class' },
            };
          }
        );
        const displayBookingsOnCalendar = filterApproveBookings.map((item) => {
          return {
            id: String(item.id),
            calendarId: '1',
            title: item.booking_reason,
            category: 'time',
            start: moment(
              `${item.booking_date} ${item.booking_start_time}`,
              'YYYY-MM-DD hh:mm A'
            ).toDate(),
            end: moment(
              `${item.booking_date} ${item.booking_end_time}`,
              'YYYY-MM-DD hh:mm A'
            ).toDate(),
            raw: { ...item, type: 'booking', viewType: day },
          };
        });
        const combinedData = [
          ...displayClassesOnCalendar,
          ...displayBookingsOnCalendar,
        ];
        setIsClassesLoaded(true);
        setScheduledClasses(combinedData);
      } catch (error) {
        console.log('error', error);
      }
    };
    classesBookings();
  }, [campusID, roomID, day, startDate, endDate, customDay]);

  const handleListView = () => {
    onListView(false);
  };

  const handleNextRange = (customDay_) => {
    console.log('call----', customDay_, day);
    setCustomDay(customDay_);
    let newStartDate, newEndDate;

    if (customDay_ === 'custom' && day === 'daily') {
      newStartDate = new Date(startDate);
      newStartDate.setDate(newStartDate.getDate() + 1);
      newStartDate.setHours(23, 59, 59, 999);
      newEndDate = new Date(newStartDate);
      newEndDate.setHours(23, 59, 59, 999);
    } else if (customDay_ === 'custom' && day === 'weekly') {
      newStartDate = new Date(startDate);
      newStartDate.setDate(newStartDate.getDate() + 7);
      newEndDate = new Date(endDate);
      newEndDate.setDate(newEndDate.getDate() + 7);
    } else if (customDay_ === 'custom' && day === 'monthly') {
      newStartDate = new Date(startDate);
      newStartDate.setMonth(newStartDate.getMonth() + 1);
      newStartDate.setDate(1);
      newEndDate = new Date(
        newStartDate.getFullYear(),
        newStartDate.getMonth() + 1,
        0
      );
      newEndDate.setHours(23, 59, 59, 999); //
      // console.log('inside next ---', customDay_, day);
      // newStartDate = new Date(startDate);
      // newStartDate.setMonth(newStartDate.getMonth() + 1);
      // newEndDate = new Date(endDate);
      // newEndDate.setMonth(newEndDate.getMonth() + 1);
      // newEndDate.setDate(
      //   new Date(
      //     newEndDate.getFullYear(),
      //     newEndDate.getMonth() + 1,
      //     0
      //   ).getDate()
      // );
      // newEndDate.setHours(23, 59, 59, 999);
    }

    calendarRef.current.calendarInst.next();
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setTodayCalendar(false);
  };

  const handlePreviousRange = (customDay_) => {
    setCustomDay(customDay_);
    let newStartDate, newEndDate;

    if (customDay_ === 'custom' && day === 'daily') {
      newStartDate = new Date(startDate);
      newStartDate.setDate(newStartDate.getDate() - 1);
      newStartDate.setHours(23, 59, 59, 999);
      newEndDate = new Date(newStartDate);
      newEndDate.setHours(23, 59, 59, 999);
    } else if (customDay_ === 'custom' && day === 'weekly') {
      newStartDate = new Date(startDate);
      newStartDate.setDate(newStartDate.getDate() - 7);
      newEndDate = new Date(endDate);
      newEndDate.setDate(newEndDate.getDate() - 7);
    } else if (customDay_ === 'custom' && day === 'monthly') {
      newStartDate = new Date(startDate);
      newStartDate.setMonth(newStartDate.getMonth() - 1);
      newStartDate.setDate(1);
      newEndDate = new Date(
        newStartDate.getFullYear(),
        newStartDate.getMonth() + 1,
        0
      );
      newEndDate.setHours(23, 59, 59, 999);
      // console.log('inside prev', customDay_,day);
      // newStartDate = new Date(startDate);
      // newStartDate.setMonth(newStartDate.getMonth() - 1);
      // newEndDate = new Date(endDate);
      // newEndDate.setMonth(newEndDate.getMonth() - 1);
      // newEndDate.setDate(
      //   new Date(
      //     newEndDate.getFullYear(),
      //     newEndDate.getMonth() + 1,
      //     0
      //   ).getDate()
      // );
      // newEndDate.setHours(23, 59, 59, 999);
    }

    calendarRef.current.calendarInst.prev();
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setTodayCalendar(false);
  };

  const handleCalendarView = (e) => {
    setCustomDay('');
    const view = e.target.value;
    setCalendarView(view);
    let newStartDate, newEndDate;

    if (view === 'day') {
      setDay('daily');
      newStartDate = new Date();

      newEndDate = new Date();
    } else if (view === 'week') {
      setDay('weekly');
      const today = new Date();
      newStartDate = new Date(today.setDate(today.getDate() - today.getDay()));
      newEndDate = new Date(newStartDate);
      newEndDate.setDate(newStartDate.getDate() + 6);
      newEndDate.setHours(23, 59, 59, 999);
    } else if (view === 'month') {
      setDay('monthly');
      const today = new Date();
      newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
      newEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      newEndDate.setHours(23, 59, 59, 999);
    }

    calendarRef.current.calendarInst.today();
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const exportData = (fileType, fileName) => {
    const header = [
      'Date & Time',
      'Course',
      'Start Time',
      'End Time',
      'Class Type',
    ];

    const flatData = scheduledClasses.map((schedule) => {
      const formattedDate = moment(schedule.start).format('YYYY-MM-DD hh:mm A');
      const startTime = moment(schedule.start).format('hh:mm A');
      const endTime = moment(schedule.end).format('hh:mm A');
      const classType =
        schedule.raw.classType === 'hybrid'
          ? 'Hybrid'
          : schedule.raw.classType === 'on_campus'
          ? 'On Campus'
          : 'Online';

      return {
        'Date & Time': formattedDate,
        Course: schedule.title,
        'Start Time': startTime,
        'End Time': endTime,
        'Class Type': classType,
      };
    });

    if (fileType === 'csv') {
      const csvString = Papa.unparse({
        fields: header,
        data: flatData.map((row) => header.map((col) => row[col])),
      });
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8,' });

      const blobURL = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        'text/csv',
        anchor.download,
        anchor.href,
      ].join(':');
      anchor.click();

      setTimeout(() => {
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === 'xlsx') {
      const compatibleData = flatData.map((row) => {
        const obj = {};
        header.forEach((col) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData);
      XLSX.utils.book_append_sheet(wb, ws1, 'React Table Data');
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    } else if (fileType === 'pdf') {
      const compatibleData = flatData.map((row) => [
        row['Date & Time'],
        row['Course'],
        row['Start Time'],
        row['End Time'],
        row['Class Type'],
      ]);

      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: 'left',
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);
    }
  };

  const moveToToday = () => {
    if (calendarView === 'day') {
      setStartDate(new Date());
      setEndDate(new Date());
    } else if (calendarView === 'week') {
      const today = new Date();
      const firstDayOfWeek = new Date(
        today.setDate(today.getDate() - today.getDay())
      );
      const lastDayOfWeek = new Date(firstDayOfWeek);
      lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
      lastDayOfWeek.setHours(23, 59, 59, 999);
      setStartDate(firstDayOfWeek);
      setEndDate(lastDayOfWeek);
    } else if (calendarView === 'month') {
      const today = new Date();
      const firstDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      );
      lastDayOfMonth.setHours(23, 59, 59, 999);
      setStartDate(firstDayOfMonth);
      setEndDate(lastDayOfMonth);
    }
    calendarRef.current.calendarInst.today();
    setTodayCalendar(true);
  };

  const calendarOptions = {
    disableDblClick: true,
    disableClick: true,
    isReadOnly: true,
    taskView: false,
    scheduleView: ['time'],
    month: {
      daynames: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
    },
    week: {
      daynames: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
    },
    theme: COMMON_CUSTOM_THEME,
    template: {
      time: (schedule) => {
        const { type, booking_status, viewType, id } = schedule.raw;
        if (type === 'class') {
          return `<div class="meeting-title-box past-meeting-title-box">
            <h6 class="meeting-title">${schedule.title?.trim()}
            ${
              schedule.raw.class_type === 'hybrid'
                ? `<span class="d-inline-block cat-red"><i class="fal fa-university"></i>
              <i class="fal fa-house-signal"></i></span>`
                : schedule.raw.class_type === 'on_campus'
                ? `<span class="d-inline-block cat-red"><i class="fal fa-university"></i>`
                : `<i class="fal fa-house-signal"></i></span>`
            }
            </h6>
            </div>`;
        } else if (type === 'booking') {
          let template;
          const statusClass = booking_status === 'APPROVED' && 'pending';
          // const borderColor = booking_status === 'APPROVED' && '#469B58';

          if (viewType === 'monthly') {
            template = `<div class="booking-title-box ${statusClass} booking-border-color" style="cursor: pointer;" data-bid="${id}"">
                             <div class="booking-header-monthly">
                                <div class="booking-name-monthly">
                                  ${shortenText(schedule.title?.trim(), 10)}
                                </div>
                                <div class="booking-status-monthly">
                                  <span style="background-color: #469B58; 
                                    color: #fff;
                                    padding: 4px 6px 4px;
                                    border-radius: 2px;
                                    font-weight: 600;
                                    font-size: 8px;"
                                    >
                                    ${booking_status}
                                  </span>
                                </div>
                             </div>
                             <div class="booking-details-monthly">
                                <i class="fal fa-university"></i> 
                                <span>${schedule.raw.room_no}, ${shortenText(
              schedule.raw.campus?.trim(),
              8
            )}</span>
                             </div>
                          </div>`;
          } else if (viewType === 'daily') {
            template = `<div class="booking-title-box-daily ${statusClass} booking-border-color" style="cursor: pointer;" data-bid="${id}"">
                  <div class="booking-title-section">
                   <div class="booking-header-daily">
                     <div class="booking-name-daily">
                       ${schedule.title}  
                     </div>
                     <div class="booking-details-daily">
                       <i class="fal fa-university"></i> 
                       <span>${schedule.raw.room_no}, ${shortenText(
              schedule.raw.campus?.trim(),
              8
            )}</span>
                     </div>
                   </div>
                   <div class="status">
                    <i class="fas fa-circle"
                      title="${booking_status}"
                      style="color:#469B58 !important">
                    </i>
                   </div>
                  </div> 
                 </div>`;
          } else {
            template = `<div class="booking-title-box ${statusClass} booking-border-color" style="cursor: pointer;" data-bid="${id}"">
                            <div class="booking-title-section">
                             <div class="booking-header-weekly">
                                <div class="booking-name-weekly">
                                  ${shortenText(schedule.title?.trim(), 10)}
                                </div>
                                <div class="booking-details-weekly">
                                  <i class="fal fa-university"></i> 
                                  <span>${schedule.raw.room_no}, ${shortenText(
              schedule.raw.campus?.trim(),
              8
            )}</span>
                                </div>
                             </div>
                             <div class="status">
                              <i class="fas fa-circle"
                                title="${booking_status}"
                                style="color:#469B58 !important">
                              </i>
                             </div>
                            </div>
                         </div>`;
          }

          return template;
        }
      },
    },
  };

  const handleExcludeWeekends = (e) => {
    if (calendarView == 'month') {
      calendarRef.current &&
        calendarRef.current.calendarInst &&
        calendarRef.current.calendarInst.setOptions(
          { month: { workweek: e.currentTarget.checked } },
          true
        );
      calendarRef.current &&
        calendarRef.current.calendarInst &&
        calendarRef.current.calendarInst.changeView(`month`, true);
    } else if (calendarView == 'week') {
      calendarRef.current &&
        calendarRef.current.calendarInst &&
        calendarRef.current.calendarInst.setOptions(
          { week: { workweek: e.currentTarget.checked } },
          true
        );
      calendarRef.current &&
        calendarRef.current.calendarInst &&
        calendarRef.current.calendarInst.changeView(`week`, true);
    }
  };

  const handleEventClick = (event) => {
    const scheduleElement = document.querySelector(
      `[data-schedule-id='${event.schedule.id}']`
    );
    if (scheduleElement) {
      const rect = scheduleElement.getBoundingClientRect();
      const position = {
        x: rect.left,
        y: rect.top,
      };
      setSelectedEvent(event.schedule);
      setModalPosition(position);
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <div className="meeting-custom-calendar pl-5 pr-5">
        <div className="reset-btn-group d-flex schedule-typography-1 mb-4">
          {campusName_ ? campusName_ : ''} {roomName ? ' - ' + roomName : ''}
        </div>

        <div
          className="button-grp form-group"
          style={{ justifyContent: 'space-between' }}
        >
          <div className="d-flex">
            <div>
              <button
                title="Today"
                className={`btn btn-white-bordered ${
                  todayCalendar ? 'active' : ''
                }
              `}
                id="today-button"
                onClick={moveToToday}
              >
                <i className="fal fa-calendar-day"></i>Today
              </button>
              <button
                title="Previous"
                className="btn btn-white-bordered"
                id="prev-button"
                onClick={() => handlePreviousRange('custom')}
                //   onClick={() => moveToPrevRange()}
              >
                <i className="far fa-arrow-left"></i>
              </button>
              <button
                title="Next"
                className="btn btn-white-bordered"
                id="next-button"
                onClick={() => handleNextRange('custom')}
              >
                <i className="far fa-arrow-right"></i>
              </button>
            </div>
            <div className="current-date">
              {moment(startDate).format(`dddd, ${TABLE_DATE_FORMAT}`)} -{' '}
              {moment(endDate).format(`dddd, ${TABLE_DATE_FORMAT}`)}
            </div>
            <div className="files-export-group ml-3">
              <button
                type="button"
                className="btn btn-files mr-2"
                onClick={() => {
                  exportData('xlsx', 'ScheduleClassesList');
                }}
                title="Export spreadsheet"
              >
                <i className="fal fa-file-excel icon"></i>
              </button>
              <button
                type="button"
                className="btn btn-files mr-2"
                onClick={() => {
                  exportData('csv', 'ScheduledClassesList');
                }}
                title="Export CSV"
              >
                <i className="fal fa-file-csv icon"></i>
              </button>
              <button
                type="button"
                className="btn btn-files"
                onClick={() => {
                  exportData('pdf', 'ScheduleClassesList');
                }}
                title="Export PDF"
              >
                <i className="fal fa-file-pdf icon"></i>
              </button>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            {/* <select
              className="form-control"
              //   onChange={(e) => setCalendarViewFunc(e)}
            >
              <option value="month">Monthly</option>
              <option value="week">Week</option>
              <option value="day">Daily</option>
              <option value="upcoming">List</option>
            </select> */}
            <div className="calendar-view-buttons">
              <button
                title="Monthly"
                className={`btn btn-white-bordered ${
                  calendarView === 'month' ? 'active' : ''
                }`}
                value="month"
                id="monthly-button"
                onClick={(e) => handleCalendarView(e)}
              >
                <i className="fal fa-calendar-alt"></i>Monthly
              </button>
              <button
                title="Weekly"
                className={`btn btn-white-bordered ${
                  calendarView === 'week' ? 'active' : ''
                }`}
                value="week"
                id="weekly-button"
                onClick={(e) => handleCalendarView(e)}
              >
                <i className="fal fa-calendar-week"></i>Weekly
              </button>
              <button
                title="Daily"
                className={`btn btn-white-bordered ${
                  calendarView == 'day' ? 'active' : ''
                }`}
                value="day"
                id="daily-button"
                onClick={(e) => handleCalendarView(e)}
              >
                <i className="fal fa-calendar-day"></i>Daily
              </button>
              <button
                title="List"
                className={`btn btn-white-bordered ${
                  calendarView == 'upcoming' ? 'active' : ''
                }`}
                value="upcoming"
                id="list-button"
                onClick={handleListView}
              >
                <i className="fal fa-list"></i>List
              </button>
            </div>
            <div className="custom-control custom-checkbox text-left">
              <input
                type="checkbox"
                className="custom-control-input"
                onClick={(e) => handleExcludeWeekends(e)}
                id="exclude-weekend"
              />
              <label className="custom-control-label" htmlFor="exclude-weekend">
                Exclude Weekends
              </label>
            </div>
          </div>
        </div>
        <div className="calendar-box pt-3">
          {isClassesLoaded ? (
            <Calendar
              height="800px"
              ref={calendarRef}
              view={calendarView}
              schedules={scheduledClasses}
              {...calendarOptions}
              onClickSchedule={handleEventClick}
            />
          ) : (
            <SkeletonTicketList />
          )}
        </div>
        {isModalOpen && (
          <BookingModal
            isOpen={isModalOpen}
            eventDetails={selectedEvent}
            onClose={() => setIsModalOpen(false)}
            position={modalPosition}
            startDate_={startDate}
            endDate_={endDate}
            customDay_="custom"
          />
        )}
      </div>
    </>
  );
};
