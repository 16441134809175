import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import Str from "../../../common/Str";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { DeleteAssignedAssemblies } from "../../../../services/CourseService";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import moment from "moment";
import {ColorRender } from "../../../systemadministration/usermanagement/CheckRole";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Hover from "../../../common/Hover";
import PermissionsGate from "../../../../utils/permissionGate";
import { useSelector } from "react-redux";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import Tablefilter from "../../../common/Tablefilter";
import { RenderCourseType, RenderLearningMethod } from "../../../../utils/CommonGroupingItem";
import { RenderCourseStatus } from "../../../../utils/CommonStatusItems";
import { InitialRender } from "../../../common/Helper";
import { handleTableScroll } from "../../../../utils/commonFunction";

const LinkedCourse = ({ data, getData, isLoading3, filterData,tabName, setFetchTable, fetchTable }) => {

  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });

  useEffect(() => {
    handleTableScroll()
  }, [isLoading3])
  
  const deleteresource = (idd) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unlink it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAssignedAssemblies(idd).then((res) => {
          Swal.fire("Unlinked!", `${res?.data?.message}`, "success");
          // getData();
          setFetchTable(!fetchTable)
        })
      }
    }).catch(error => console.log(error));

  }

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "subject_name",
      sortable: true,
      // maxWidth: "80px",
      // minWidth: "80px",
      cell: (row) => (
        <div >
          <Link className="as-text-blue curser feature-name" to={`/courseAdministration/coursesdetails/${row.intakeID}/dashboard/show`} title={row.subject_name}>
            <span className="textLimit100">{row.subject_name ? row.subject_name : "-"}</span>
          </Link>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => (
        <p className={RenderCourseStatus(row.status).text}>
          {RenderCourseStatus(row.status).html}
          {/* <span className={row.status ? "as-green-bg as-widget" : "as-red-bg as-widget"}>{row.status}</span> */}
        </p>
      ),
    },
    {
      name: "Intake",
      selector: "intake_year",
      sortable: true,
      cell: (row) => <span title={row.intake_year && row.intake_number ? row.intake_year + "-" + row.intake_number  : ""}>{row.intake_year && row.intake_number ? row.intake_year + "-" + row.intake_number  : ""}</span>
    },
    {
      name: "Course Type",
      selector: "course_type",
      sortable: true,
      cell: (row) => row.course_type ? RenderCourseType(row.course_type).html : "-",
    },
    {
      name: "Learning Method",
      selector: "correspondence",
      sortable: true,
      cell: (row) => (<span title={RenderLearningMethod(row.correspondence).text}>{row.correspondence ? RenderLearningMethod(row.correspondence).html : "-"}</span>),
    },
    {
      name: "Programme Coordinator",
      selector: "programme_coordinator",
      sortable: true,
      cell: (row) => (
        row.programme_coordinator ?
          <>
            {/* <Hover firstName={row.programme_coordinator.split(" ")[0]} lastName={row.programme_coordinator.split(" ")[row.programme_coordinator.split(" ").length - 1]} photo={row.programme_coordinator_profile_pic} email={row.programme_coordinator_email} mobile={row.programme_coordinator_contact} wholeUi={true} status={row.programme_coordinator_status ? row.programme_coordinator_status : ""} right={true} />
            {TrimText(row.programme_coordinator)} */}
            <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender("programme_coordinator")}>
                {row.programme_coordinator_profile_pic ? <img src={`${IMAGE_URL}/${row.programme_coordinator_profile_pic.replace("public/","").replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.programme_coordinator.split(" ")[0], row.programme_coordinator.split(" ")[row.programme_coordinator.split(" ").length - 1]])}
                <span className={`profile-box-2-status ${row.programme_coordinator_activity_status ? row.programme_coordinator_activity_status.toLowerCase()=="online" ? "Online" :row.programme_coordinator_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                  <i className="fas fa-circle"></i>
                </span>
                <Hover firstName={row.programme_coordinator.split(" ")[0]} lastName={row.programme_coordinator.split(" ")[row.programme_coordinator.split(" ").length - 1]} photo={row.programme_coordinator_profile_pic} email={row.programme_coordinator_email} mobile={row.programme_coordinator_contact} status={row.programme_coordinator_status} activity_status={row.programme_coordinator_activity_status} right={true} />
              </span>
              <PermissionsGate scopes={["sview"]} RenderError={() => <p>{row.programme_support.split(" ")[0] + " " + row.programme_support.split(" ")[row.programme_support.split(" ").length - 1]}</p>}>
                <Link className="as-text-blue curser " to={`/studentAdministration/students/open/${row.id}/general`}>
                  {row?.programme_coordinator && <span className="textLimit76" title={row?.programme_coordinator.split(" ")[0] + " " + row?.programme_coordinator.split(" ")[row?.programme_coordinator.split(" ").length - 1]}>{row?.programme_coordinator.split(" ")[0] + " " + row?.programme_coordinator.split(" ")[row?.programme_coordinator.split(" ").length - 1]}</span>}
                </Link>
              </PermissionsGate>
            </div>
          </div>
          </>
          : "-"
      ),
    },
    {
      name: "Programme Support",
      selector: "programme_support",
      sortable: true,
      cell: (row) => (
        row.programme_support ?
          <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span className={"assigned-title-blk  name-icon cat-dark-red " + ColorRender("programme_support")}>
                {row.programme_support_profile_pic ? <img src={`${IMAGE_URL}/${row.programme_support_profile_pic.replace("public/","").replaceAll("home/myaie/public_html/", "")}`} alt="AIE" /> : InitialRender([row.programme_support.split(" ")[0], row.programme_support.split(" ")[row.programme_support.split(" ").length - 1]])}
                <span className={`profile-box-2-status ${row.programme_support_activity_status ? row.programme_support_activity_status.toLowerCase()=="online" ? "Online" :row.programme_support_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                  <i className="fas fa-circle"></i>
                </span>
                <Hover firstName={row.programme_support.split(" ")[0]} lastName={row.programme_support.split(" ")[row.programme_support.split(" ").length - 1]} photo={row.programme_support_profile_pic} email={row.programme_support_email} mobile={row.programme_support_contact} status={row.programme_support_status} activity_status={row.programme_support_activity_status} right={true} />
              </span>
              <PermissionsGate scopes={["sview"]} RenderError={() => <p>{row.programme_support.split(" ")[0] + " " + row.programme_support.split(" ")[row.programme_support.split(" ").length - 1]}</p>}>
                <Link className="as-text-blue curser " to={`/studentAdministration/students/open/${row.id}/general`}>
                  <span className="textLimit76" title={row.programme_support.split(" ")[0] + " " + row.programme_support.split(" ")[row.programme_support.split(" ").length - 1]}>{row.programme_support.split(" ")[0] + " " + row.programme_support.split(" ")[row.programme_support.split(" ").length - 1]}</span>
                </Link>
              </PermissionsGate>
            </div>
          </div>
          : "-"
      ),
    },
    {
      name: "Start",
      selector: "start_date",
      sortable: true,
      cell: (row) =>
        row.start_date ? (
          <span title={moment(row.start_date).format(TABLE_DATE_FORMAT)}>
            <p className="right-space">{moment(row.start_date).format(TABLE_DATE_FORMAT)}</p>
            {/* <p>{moment(row.start_date).format(TABLE_TIME_FORMAT)}</p> */}
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "End",
      selector: "end_date",
      sortable: true,
      cell: (row) =>
        row.end_date ? (
          <span title={moment(row.end_date).format(TABLE_DATE_FORMAT)}>
            <p className="right-space">{moment(row.end_date).format(TABLE_DATE_FORMAT)}</p>
            {/* <p>{moment(row.end_date).format(TABLE_TIME_FORMAT)}</p> */}
          </span>
        ) : (
          "-"
        ),
      // (row.end_date ? row.end_date : "-")
    },    
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <Link
              className="btn btn-primary rounded-circle"
              title="Open"
              to={`/courseAdministration/coursesdetails/${row.intakeID}/dashboard/show`}
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            <PermissionsGate RenderError={() => (
              <button className="btn btn-danger rounded-circle" title="Delete" disabled>
                <i className="fal fa-trash-alt"></i>
              </button>
            )} scopes={["cadelete"]}>
              <button className="btn btn-danger rounded-circle" title="Unlink" onClick={() => deleteresource(row.ref_id)}>
                <i className="fal fa-unlink"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let tempName = allData.filter((item) => {
        let includes = item.subject_name.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStatus = allData.filter((item) => {
        let includes = item.status && item.status.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempIntake = allData.filter((item) => {
        let includes = item.intake_year && item.intake_year.toString().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCourseType = allData.filter((item) => {
        let includes = item.course_type && item.course_type.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudyType = allData.filter((item) => {
        let includes = item.study_type && item.study_type.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempCorrespondence = allData.filter((item) => {
        let includes = item.correspondence && item.correspondence.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStartDate = allData.filter((item) => {
        let includes = item.start_date && item.start_date.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempEndDate = allData.filter((item) => {
        let includes = item.end_date && item.end_date.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempProgramCoordinator = allData.filter((item) => {
        let includes = item.programme_coordinator && item.programme_coordinator.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempProgramSupport = allData.filter((item) => {
        let includes = item.programme_support && item.programme_support.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });


      let data = [...tempName, ...tempIntake, ...tempCourseType, ...tempStudyType, ...tempStatus, ...tempCorrespondence, ...tempStartDate, ...tempEndDate, ...tempProgramCoordinator, ...tempProgramSupport];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.arr.find(function (post, index) {
          if (post.toLowerCase() === item.status.toLowerCase() ? item.status.toLowerCase() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }


    if (learningMethod.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = learningMethod.arr.find((post) => {
          if (post.toLowerCase() === item.correspondence.toLowerCase() ? item.correspondence.toLowerCase() : "") return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (intake.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = intake.arr.find((post) => {
          if (post.toString() === (item.intake_year.toString() && item.intake_number.toString()  ? `${item.intake_year.toString()}/${item.intake_number.toString()}` : "")) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }


    if (status.arr.length || search.length || learningMethod.arr.length || intake.arr.length) {
      return updatedData;
    } else {
      return allData;
    }


  };

  const resetFilter = () => {
    setSearch("");
    setStatus({ arr: [], checkObj: {} });
    setSearchStatusCheck({});
    setLearningMethod({ arr: [], checkObj: {} })
    setIntake({ arr: [], checkObj: {} });
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Name", "Status", "Intake", "Course Type", "Learning Method", "Programme Coordinator", "Program Support", "Start", "End"];

    data = data?.map((row) => {
      return ({
        ...row,
        Name: row?.subject_name ? row?.subject_name : "-",
        Status: row?.status ? RenderCourseStatus(row?.status).text : "-",
        Intake:
            row.intake_year && row.intake_number
              ? `${row.intake_year} - ${row.intake_number}`
              : "-",
        "Course Type": row?.course_type ? row?.course_type : "-",
        // "Learning Method": row?.study_type ? row?.study_type : "NA",
        "Learning Method": row?.correspondence ? RenderLearningMethod(row?.correspondence).text : "-",
        "Start": row?.start_date ? moment(row?.start_date).format(TABLE_DATE_FORMAT) : "-",
        "End": row?.end_date ?  moment(row?.end_date).format(TABLE_DATE_FORMAT) : "-",
        "Programme Coordinator": row?.programme_coordinator ? row?.programme_coordinator : "-",
        "Program Support": row?.programme_support ? row?.programme_support : "-",
      })
    });

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Name, row.Status, row.Intake, row["Course Type"], row["Learning Method"], row["Programme Coordinator"], row["Program Support"], row["Start"], row["End"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="custom-table-div filter-search-icon card card-table-custom">
      <div className="search-filter-div">
        <div className="search-filter-div-left">
          <div className="system-administration-table table-responsive">
            <div className="table-responsive-div">
              <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                <div id="assessment-table-main_filter" className="dataTables_filter">
                  <label>
                    <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                  </label>
                  <div className="filter-eff filter-data-btn">
                    <button className="filter-buttons">
                      <i className="fal fa-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-button-group">
              <div className="filter-scroll">
                <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Status"
                      optionArr={filterData.status}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={RenderCourseStatus}
                    />

                    <Tablefilter
                      filterName="Learning Method"
                      optionArr={filterData.learning}
                      state={learningMethod}
                      setState={setLearningMethod}
                      renderLabelFunction={RenderLearningMethod}
                    />

                    <Tablefilter
                      filterName={"Intake"}
                      optionArr={filterData.intake}
                      state={intake}
                      setState={setIntake}
                      isOptionReversed={true}
                    />
                </div>
              </div>
              <div className="reset-btn-group">
                <div className="button-reset dropdown-comman">
                  <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                    <i className="fal fa-redo"></i>Reset
                  </button>
                </div>

                <div className="files-export-group">
                  <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Linked Courses"); }} title="Export spreadsheet" >
                    <i className="fal fa-file-excel icon"></i>
                  </button>

                  <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Linked Courses"); }} title="Export CSV" >
                    <i className="fal fa-file-csv icon"></i>
                  </button>

                  <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Linked Courses"); }} title="Export PDF" >
                    <i className="fal fa-file-pdf icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading3 ? <SkeletonTicketList /> :
        <DataTable
          data={dataToRender()}
          defaultSortField="start_date"
          defaultSortAsc={false}
          noDataComponent={Str.noRecord}
          columns={columns}
          pagination={true}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />}
    </div>
  );
};

export default LinkedCourse;