import React, { useState, useMemo, useEffect } from "react";
import { InitialRender } from "../../../common/Helper";
import DatePicker from "react-datepicker";
import { Link, useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import $ from "jquery";
import "jspdf-autotable";
import {
  ColorRender,
  StatusRenderList,
} from "../../../systemadministration/usermanagement/CheckRole";
import {
  AddOrUpdateStudentNote,
  AddTimer,
  GetStudentAttaendance,
  OnCampusCheckOutIn,
} from "../../../../services/CourseService";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import { FORM_TIME_FORMAT, IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import moment from "moment";
import Swal from "sweetalert2";
import PermissionsGate from "../../../../utils/permissionGate";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { TrimText } from "../../../common/TrimText";
import Hover from "../../../common/Hover";
import { myCompare } from "../../../common/MyCompare";
import { RenderAttendanceStatus, RenderStatusForAttendance } from "../../../../utils/CommonStatusItems";
import Tablefilter from "../../../common/Tablefilter";
import { RenderAttendanceType } from "../../../../utils/CommonGroupingItem";
import { handleTableScroll } from "../../../../utils/commonFunction";

const Attendance = (props) => {
  const history = useHistory();
  const { cid, intake } = useParams();
  const [letterheadHeaderName, setLetterheadHeaderName] = useState({name: ""});
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState([]);
  const [searchStatusCheck, setSearchStatusCheck] = useState({ arr: [], checkObj: {} });
  const [subject, setSubject] = useState([]);
  const [subjectFilter, setSubjectFilter] = useState([]);
  const [searchSubject, setSearchSubject] = useState({});
  const [qualification, setQualification] = useState([]);
  const [qualificationFilter, setQualificationFilter] = useState([]);
  const [searchQualification, setSearchQualification] = useState({});
  const [attendanceStatus, setAttendanceStatus] = useState([]);
  const [attendanceFilter, setAttendanceFilter] = useState({ arr: [], checkObj: {} });
  const [searchAttendance, setSearchAttendance] = useState({});
  const [attendanceTypeData, setAttendanceTypeData] = useState([]);
  const [attendanceType, setAttendanceType] = useState({ arr: [], checkObj: {} });
  const [searchAttendanceType, setSearchAttendanceType] = useState({});
  const [student_id, setStudent_id] = useState("");
  const [portal_id, setPortal_id] = useState("");
  const [loading, setLoading] = useState(true);

  const [attendanceList, setAttendanceList] = useState([]);
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })


  useEffect(() => {
    setLoading(true)
    GetStudentAttaendance(cid)
      .then((res) => {
        props.setClassTitle(res.data.ClassName.class_title)
        setAttendanceList((res?.data?.data).sort(myCompare));
        setSubject((res?.data.Subject).sort(myCompare));
        setQualification((res?.data.Qualification).sort(myCompare));
        setAttendanceStatus((res?.data?.AttendanceStatus).sort(myCompare));
        setAttendanceTypeData((res?.data?.AttendanceType).sort(myCompare));
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
      });
  }, []);

  const studentFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      details: "",
      attachment: "",
    },
    validationSchema: Yup.object({
      details: Yup.string().required("Details is required").trim(),
      attachment: Yup.mixed().required("Attachment is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const formInputs = new FormData();
      formInputs.append("id_student", student_id);
      formInputs.append("id_intake", intake);
      formInputs.append("note_date", new Date());
      formInputs.append("note_content", values.details);
      formInputs.append("photo", values.attachment);

      AddOrUpdateStudentNote(formInputs)
        .then((res) => {
          resetForm();
          setLetterheadHeaderName("");
          $("#studentnote").modal("hide");
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
          studentFormik.resetForm();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startTime: "",
      endTime: "",

      attendance_id: "",
    },
    validationSchema: Yup.object({
      startTime: Yup.string().required("Start time is required"),
      endTime: Yup.string().required("End time is required"),
    }),
    onSubmit: (values) => {
      let startDate = values.startTime,
        endDate = values.endTime;

      if (startDate > endDate) {
        formik.setErrors({
          startTime: "Start Time should be less than End Time",
        });
        return;
      }
      let data = new FormData();
      data.append("UserID", student_id);
      data.append("portal_class_id", portal_id);
      data.append(
        "start_time",
        new Date(values.startTime).getHours() +
        ":" +
        new Date(values.startTime).getMinutes()
      );
      data.append(
        "end_time",
        new Date(values.endTime).getHours() +
        ":" +
        new Date(values.endTime).getMinutes()
      );

      AddTimer(data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
          $("#attendanceOpen").modal("hide");
          formik.resetForm()
        })
        .catch((err) => {
          console.log(err);
        });
      //   let formData = new FormData();
    },
  });

  const showRating = (value) => {
    try {
      let num = parseInt(value);
      let arr = Array(num).fill(<i class="fa fa-star" style={{ color: "#FFD700", marginRight: "2px" }} />)
      return arr
    } catch (error) {
      console.log("error: ", error)
      return []
    }
  }

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "first_name",
      sortable: true,
      cell: (row) => (
        <>
          <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span
                className={
                  "assigned-title-blk  name-icon cat-dark-red " +
                  ColorRender(row.role_name)
                }
              >
                {row.picture_me ? (
                  <img
                    src={`${IMAGE_URL}/${row.picture_me.replaceAll(
                      "/home/myaie/public_html/",
                      ""
                    )}`}
                    alt="AIE"
                  />
                ) : (
                  InitialRender([row.first_name, row.last_name])
                )}
                <span className={`profile-box-2-status ${row.activity_status ? row.activity_status.toLowerCase() == "online" ? "Online" : row.activity_status.toLowerCase() == "away" ? "Away" : "Offline" : "Offline"}`}>
                  <i className="fas fa-circle"></i>
                </span>
                <Hover
                  firstName={row.first_name}
                  lastName={row.last_name}
                  photo={row.picture_me}
                  email={row.student_email}
                  mobile={row.mobile}
                  right={true}
                  status={row.status}
                  activity_status={row.activity_status}
                  showNumber={true}
                  number={row.student_crm_id}
                />
              </span>
              <Link
                to={`/studentAdministration/students/open/${row.student_id}/general`}
                className="as-text-blue curser feature-name"
              >
                <span className="textLimit100">{row.first_name + " " + row.last_name}</span>
              </Link>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "#Student No.",
      selector: "student_crm_id",
      sortable: true,
      cell: (row) => (
        <span className="overflow-ellipsis2">
          {row.student_crm_id ? row.student_crm_id : "-"}
        </span>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) =>
        row.status ? (
          <span className={StatusRenderList(row.status)}>{row.status}</span>
        ) : (
          "-"
        ),
    },
    {
      name: "Subject",
      selector: "name",
      sortable: true,
      cell: (row) =>
        row.name ?
          <span className="overflow-ellipsis2 fst_line2">
            <Link
              className="feature-name as-text-blue curser"
              title={row.name}
              to={`/courseAdministration/coursesdetails/${row.intake_id}/detailCourses/show`}
            >
              <span className="textLimit100">{row.name}</span>

            </Link>
          </span> : "-"
    },
    {
      name: "Programme",
      selector: "course",
      sortable: true,
      cell: (row) =>
        row.course ? (
          <span className="overflow-ellipsis2 fst_line2">
            <Link
              className="feature-name as-text-blue curser"
              title={row.course}
              to={`/courseAdministration/Programmes/programme/open/${row.qualification_id}/details`}
            >
              <span className="textLimit100">{row.course}</span>

            </Link>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Attendance Status",
      selector: "attendance_status",
      sortable: true,
      cell: (row) => row.attendance_status ? RenderAttendanceStatus(row.attendance_status).html : "-",
    },
    {
      name: "Attendance Type",
      selector: "attendance_type",
      sortable: true,
      cell: (row) => (
        row.attendance_type ? RenderAttendanceType(row.attendance_type).html : "-"
      ),
    },
    {
      name: "Rating",
      selector: "rating_html_final",
      sortable: true,
      cell: (row) => (
        row.rating_html_final ? <>
          {showRating(row.rating_html_final).map(item => item)}
          <span className="rating-comment" title={row.student_comment}>{row.student_comment}</span>
        </> : <p>-</p>
        // <p>{row.rating_html_final ? row.rating_html_final : "-"}</p>
      ),
    },
    {
      name: "View",
      selector: "view_recording_date",
      sortable: true,
      cell: (row) =>
        row.recording ? (
          <>
            {" "}
            <p>
              {row.recording.toString().length == 10
                ? moment.unix(row.recording).format(TABLE_DATE_FORMAT)
                : moment(parseInt(`${row.recording}`)).format(TABLE_DATE_FORMAT)}
            </p>
            <p>
              {row.recording.toString().length == 10
                ? moment.unix(row.recording).format(TABLE_TIME_FORMAT)
                : moment(parseInt(`${row.recording}`)).format(TABLE_TIME_FORMAT)}
            </p>
          </>
        ) : (
          "-"
        ),
    },
    {
      name: "Download",
      selector: "download_recording_date",
      sortable: true,
      cell: (row) =>
        row.download_recording_date ? (
          <>
            {" "}
            <p>
              {row.download_recording_date.toString().length == 10
                ? moment.unix(row.download_recording_date).format(TABLE_DATE_FORMAT)
                : moment(parseInt(`${row.download_recording_date}`)).format(
                  TABLE_DATE_FORMAT
                )}
            </p>
            <p>
              {row.download_recording_date.toString().length == 10
                ? moment.unix(row.download_recording_date).format(TABLE_TIME_FORMAT)
                : moment(parseInt(`${row.download_recording_date}`)).format(
                  TABLE_TIME_FORMAT
                )}
            </p>
          </>
        ) : (
          "-"
        ),
    },

    {
      name: "Insights",
      // selector: "insight",
      // sortable: true,
      // cell: (row) => <span className="feature-name"><span className="textLimit100">{row.insight} </span></span>,
      cell: (row) => <span>
      {row?.duration ? row?.duration : "0"}
      {" "}
      {row?.percentage ? `(${row?.percentage}%)` : "(0%)"}
      <br />
      {row?.time_in ? `I: ${moment.unix(row?.time_in).format(TABLE_DATE_TIME_FORMAT)}` : "I: 0"}
      <br />
      {row?.time_out ? `O: ${moment.unix(row?.time_out).format(TABLE_DATE_TIME_FORMAT)}` : "O: 0"}
    </span>,
    },

    {
      name: "Actions",

      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons ">
            <Link
              title="open"
              className="btn btn-primary rounded-circle"
              to={`/studentAdministration/students/open/${row.student_id}/general`}
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            {row.end_time && moment.unix(row.end_time)._d < new Date() ? <div className="dropdown btn-dropdown-item ">
              <button
                className="btn btn-primary rounded-circle dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="More"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {row.start_time &&
                  row.end_time &&
                  moment.unix(row.start_time)._d <= new Date() &&
                  moment.unix(row.end_time)._d >= new Date() ? (
                  (row.class_type == "on_campus" ||
                    row.class_type == "hybrid") &&
                    row.join_type != "online" &&
                    row.join_type == "campus" &&
                    row.time_out == "" ? (
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Check Out"
                      onClick={() => {
                        setStudent_id(row.user_id);
                        handleCampus("exit", row.user_id, row.portal_class_id);
                      }}
                    >
                      <i className="fal fa-inbox-out"></i>
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Check in here if you are attending the class on campus."
                      onClick={() => {
                        setPortal_id(row.portal_class_id);
                        setStudent_id(row.user_id);
                        handleCampus("enter", row.user_id, row.portal_class_id);
                      }}
                    >
                      <i className="fal fa-inbox-in"></i>
                    </button>
                  )
                ) : (
                  ""
                )}

                <button
                  data-toggle="modal"
                  data-target="#attendanceOpen"
                  className="btn btn-primary rounded-circle"
                  title="Edit Timer"
                  onClick={() => {
                    setPortal_id(row.portal_class_id);
                    setStudent_id(row.user_id);
                  }}
                >
                  <i className="fal fa-edit"></i>
                </button>

                <button
                  data-toggle="modal"
                  data-target="#studentnote"
                  className="btn btn-primary rounded-circle"
                  title="Create Note"
                  onClick={() => setStudent_id(row.user_id)}
                // onClick={() => setStudentNote(true)}
                >
                  <i className="fal fa-plus"></i>
                </button>
              </div>
            </div> : row.start_time &&
              row.end_time &&
              moment.unix(row.start_time)._d <= new Date() &&
              moment.unix(row.end_time)._d >= new Date() ? (
              (row.class_type == "on_campus" ||
                row.class_type == "hybrid") &&
                row.join_type != "online" &&
                row.join_type == "campus" &&
                row.time_out == "" ? (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Check Out"
                  onClick={() => {
                    setStudent_id(row.user_id);
                    handleCampus("exit", row.user_id, row.portal_class_id);
                  }}
                >
                  <i className="fal fa-inbox-out"></i>
                </button>
              ) : (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Check in here if you are attending the class on campus."
                  onClick={() => {
                    setPortal_id(row.portal_class_id);
                    setStudent_id(row.user_id);
                    handleCampus("enter", row.user_id, row.portal_class_id);
                  }}
                >
                  <i className="fal fa-inbox-in"></i>
                </button>
              )
            ) : ""}
          </div>
        </div>
      ),
    },
  ]);
  {
  }
  const handleCampus = (val, userid, portalid) => {
    let obj = {
      UserID: userid,
      portal_class_id: portalid,
      action: val,
    };
    OnCampusCheckOutIn(obj)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        });
      })
      .catch((err) => {
        Swal.fire({
          text: err.response.data.message,
          icon: "warning",
        });
      });
  };
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleStatusFilter = (e) => {
    const value = e.target.value;
    let arr = status;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setStatus(arr);
    let id = e.target.id;

    setSearchStatusCheck({
      ...searchStatusCheck,
      [id]: !searchStatusCheck[id],
    });
  };

  const handleQualificationFilter = (e) => {
    const value = e.target.value;
    let arr = qualificationFilter;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setQualificationFilter(arr);
    let id = e.target.id;
    setSearchQualification({
      ...searchQualification,
      [id]: !searchQualification[id],
    });
  };

  const handleSubjectFilter = (e) => {
    const value = e.target.value;
    let arr = subjectFilter;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }

    setSubjectFilter(arr);
    let id = e.target.id;
    setSearchSubject({
      ...searchSubject,
      [id]: !searchSubject[id],
    });
  };

  const resetFilter = () => {
    setStatus([]);
    setQualificationFilter([]);
    setSearchQualification({});
    setSearchStatusCheck({});
    setSearchSubject({});
    setSubjectFilter([]);
    setSearch("");
    setAttendanceFilter({ arr: [], checkObj: {} })
    setAttendanceType({ arr: [], checkObj: {} })
    setSearchAttendance({})
    setSearchAttendanceType({})
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = attendanceList;

    if (search.length) {
      let Id = allData.filter((item) => {
        let includes =
          item.user_id &&
          item.user_id.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let status = allData.filter((item) => {
        let includes =
          item.status &&
          item.status.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let subject_name = allData.filter((item) => {
        let includes =
          item.first_name &&
          (item.first_name + " " + item.last_name).toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let student_crm_id = allData.filter((item) => {
        let includes =
          item.student_crm_id &&
          item.student_crm_id.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let qualifications = allData.filter((item) => {
        let includes =
          item.course &&
          item.course.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let name = allData.filter((item) => {
        let includes =
          item.name &&
          item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let attendance_type = allData.filter((item) => {
        let includes =
          item.attendance_type &&
          item.attendance_type
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let attendance_status = allData.filter((item) => {
        let includes =
          item.attendance_status &&
          item.attendance_status
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase());

        if (includes) {
          return includes;
        } else return null;
      });

      let intake_year = allData.filter((item) => {
        let includes =
          item.intake_year &&
          item.intake_year.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let course_type = allData.filter((item) => {
        let includes =
          item["course_type"] &&
          item["course_type"].toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let study_type = allData.filter((item) => {
        let includes =
          item["study_type"] &&
          item["study_type"].toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        ...Id,
        ...name,
        ...status,
        ...attendance_type,
        ...attendance_status,
        ...subject_name,
        ...qualifications,
        ...intake_year,
        ...course_type,
        ...study_type,
        ...student_crm_id,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (status.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.find((post) => {
          if (post.toLowerCase() === item.status.toLowerCase() ? item : "")
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }
    if (attendanceType.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = attendanceType.arr.find(function (post, index) {
          if (
            post.toString().toLowerCase() ===
            item.attendance_type.toString().toLowerCase()
          )
            return item;
        });

        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (attendanceFilter.arr.length) {
      let tempStatus = updatedData;

      let tempResult = tempStatus.filter((item) => {
        const startsWith = attendanceFilter.arr.find(function (post, index) {
          if (
            post.toString().toLowerCase() ===
            item.attendance_status.toString().toLowerCase()
          )
            return item;
        });

        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (qualificationFilter.length) {
      let tempStatus = updatedData;

      let tempResult = tempStatus.filter((item) => {
        const startsWith = qualificationFilter.find(function (post, index) {
          if (post.toLowerCase() === item.course.toString().toLowerCase())
            return item;
        });

        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }
    if (subjectFilter.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = subjectFilter.find(function (post, index) {
          if (post.toLowerCase() === item.name.toLowerCase()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }
    if (
      subjectFilter.length ||
      attendanceType.arr.length ||
      attendanceFilter.arr.length ||
      status.length ||
      qualificationFilter.length ||
      search.length
    ) {
      return updatedData;
    } else {
      return attendanceList;
    }
  };
  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = [
      "Name",
      "Student No.",
      "Status",
      "Subject",
      "Qualification",
      "Attendance Status",
      "Attendance Type",
      "Rating",
      "Recording View",
      "Recording Download",
      "Insights",
    ];
    data = data?.map((row) => ({
      ...row,
      Name:
        row?.first_name && row.last_name
          ? row?.first_name + " " + row.last_name
          : "-",
      "Student No.": row?.student_crm_id ? row?.student_crm_id : "-",
      Status: row?.status ? row?.status : "-",
      Subject: row.name ? row.name : "-",
      Qualification: row.course ? row.course : "-",
      "Attendance Status": row.attendance_status ? RenderAttendanceStatus(row.attendance_status).text : "-",
      "Attendance Type": row.attendance_type ? row.attendance_type : "-",
      Rating: row.rating_html_final ? row.rating_html_final : "-",
      "Recording View": row.recording
        ? row.recording.toString().length == 10
          ? moment.unix(row.recording).format(TABLE_DATE_FORMAT)
          : moment(parseInt(`${row.recording}`)).format(TABLE_DATE_FORMAT) +
            row.recording.toString().length ==
            10
            ? moment.unix(row.recording).format(TABLE_TIME_FORMAT)
            : moment(parseInt(`${row.recording}`)).format(TABLE_TIME_FORMAT)
        : "-",
      "Recording Download": row.download_recording_date
        ? row.download_recording_date.toString().length == 10
          ? moment.unix(row.download_recording_date).format(TABLE_DATE_FORMAT)
          : moment(parseInt(`${row.download_recording_date}`)).format(
            TABLE_DATE_FORMAT
          ) +
            row.download_recording_date.toString().length ==
            10
            ? moment.unix(row.download_recording_date).format(TABLE_TIME_FORMAT)
            : moment(parseInt(`${row.download_recording_date}`)).format(TABLE_TIME_FORMAT)
        : "-",
      Insights: row.insight ? row.insight : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      setTimeout(() => {
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Name"],
          row["Student No."],
          row["Status"],
          row["Subject"],
          row["Qualification"],
          row["Attendance Status"],
          row["Attendance Type"],
          row["Rating"],
          row["Insights"],
          row["Recording View"],
          row["Recording Download"],
        ];
      });

      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
      <div className="search-filter-div">
        <div className="search-filter-div-left">
          <div className="system-administration-table table-responsive">
            <div className="table-responsive-div">
              <div
                id="assessment-table-main_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <div
                  id="assessment-table-main_filter"
                  className="dataTables_filter"
                >
                  <label>
                    <input
                      type="search"
                      className=""
                      placeholder="Search"
                      aria-controls="assessment-table-main"
                      onChange={handleSearchFilter}
                      value={search}
                    />
                  </label>
                  <div className="filter-eff filter-data-btn">
                    <button className="filter-buttons">
                      <i className="fal fa-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-button-group">

              {/* Subject Filter*/}
              <div className="filter-scroll">

                <div className={`filter-scroll-inner  filter-custom-new`}>
                  <div className="assessment-table-filter">
                    <div className="btn-type-filter dropdown-comman">
                      <div className="dropdown multiselect">
                        <button
                          className={`btn btn-default dropdown-toggle ${subjectFilter.length ? "btn-selected" : ""
                            }`}
                          type="button"
                          id="dropdownMenu1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Subject"
                        >
                          <span>
                            Subject <i className="fal fa-angle-down"></i>
                          </span>
                        </button>
                        <form className="dropdown-menu remove-list-style">
                          <ul aria-labelledby="dropdownMenu1">
                            {subject
                              ? subject.sort(myCompare).map((val, i) => (
                                <li className="" key={i}>
                                  <input
                                    type="checkbox"
                                    id={"subject_" + i}
                                    value={val.label}
                                    onClick={handleSubjectFilter}
                                    // checked={searchAttendanceHealthCheck.action1}
                                    checked={searchSubject["subject_" + i]}
                                  />
                                  <label
                                    htmlFor={"subject_" + i}
                                    title={val.label}
                                  >
                                    <span className="cat ">
                                      {TrimText(val.label, 15)}
                                    </span>
                                  </label>
                                </li>
                              ))
                              : "NA"}
                          </ul>
                        </form>
                      </div>
                    </div>
                  </div>

                  {/*Qualification Filter */}
                  <div className="assessment-table-filter">
                    <div className="btn-type-filter dropdown-comman">
                      <div className="dropdown multiselect">
                        <button
                          className={`btn btn-default dropdown-toggle ${qualificationFilter.length ? "btn-selected" : ""
                            }`}
                          type="button"
                          id="dropdownMenu1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Qualification"
                        >
                          <span>
                            Qualification <i className="fal fa-angle-down"></i>
                          </span>
                        </button>
                        <form className="dropdown-menu remove-list-style">
                          <ul aria-labelledby="dropdownMenu1">
                            {qualification
                              ? qualification.sort(myCompare).map((val, ind) => (
                                <li className="" key={ind}>
                                  <input
                                    type="checkbox"
                                    id={val.label}
                                    value={val.label}
                                    onClick={handleQualificationFilter}
                                    checked={searchQualification[val.label]}
                                  />
                                  <label htmlFor={val.label} title={val.label}>
                                    <span className="cat ">
                                      {TrimText(val.label, 15)}
                                    </span>
                                  </label>
                                </li>
                              ))
                              : "NA"}
                          </ul>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* Atttendance Status Filter */}
                  <Tablefilter
                    filterName="Attendance Status"
                    optionArr={attendanceStatus}
                    state={attendanceFilter}
                    setState={setAttendanceFilter}
                    renderLabelFunction={RenderAttendanceStatus}
                  />
                  {/*Attendance Type Filter */}
                  <Tablefilter
                    filterName="Attendance Type"
                    optionArr={attendanceTypeData}
                    state={attendanceType}
                    setState={setAttendanceType}
                    renderLabelFunction={RenderAttendanceType}
                  />

                  {/*Status Filter */}
                  <Tablefilter
                    filterName="Status"
                    optionArr={[
                      {label : "ACTIVE", value: "Active"},
                      {label : "In Active", value: "In Active"},
                      {label : "In Progress", value: "In Progress"},
                      {label : "On Hold", value: "On Hold"},
                      {label : "Past", value: "Past"},
                      {label : "Upcoming", value: "Upcoming"},
                    ]}
                    state={searchStatusCheck}
                    setState={setSearchStatusCheck}
                    renderLabelFunction={RenderStatusForAttendance}
                  />

                  {/* End of Study Type filter */}
                  {/* <div className="assessment-table-filter">
                    <div className="btn-type-filter dropdown-comman">
                      <div className="dropdown multiselect">
                        <button
                          className={`btn btn-default dropdown-toggle ${status.length ? "btn-selected" : ""
                            }`}
                          type="button"
                          id="dropdownMenu3"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Status"
                        >
                          <span>
                            Status <i className="fal fa-angle-down"></i>
                          </span>
                        </button>
                        <form className="dropdown-menu remove-list-style">
                          <ul aria-labelledby="dropdownMenu3">
                            <li className="cat cat-red">
                              <input
                                type="checkbox"
                                id="action14"
                                value="ACTIVE"
                                onClick={handleStatusFilter}
                                checked={searchStatusCheck.action14}
                              />
                              <label htmlFor="action14">
                                <span className="as-green-bg as-widget">
                                  Active
                                </span>
                              </label>
                            </li>
                            <li className="cat cat-red">
                              <input
                                type="checkbox"
                                id="action15"
                                value="In Active"
                                onClick={handleStatusFilter}
                                checked={searchStatusCheck.action15}
                              />
                              <label htmlFor="action15">
                                <span className="as-red-bg as-widget">
                                  In Active
                                </span>
                              </label>
                            </li>
                            <li className="cat cat-yellowshade">
                              <input
                                type="checkbox"
                                id="action11"
                                value="In Progress"
                                onClick={handleStatusFilter}
                                checked={searchStatusCheck.action11}
                              />
                              <label htmlFor="action11">
                                <span className="as-yellowshade-bg as-widget">
                                  In Progress
                                </span>
                              </label>
                            </li>
                            <li className="cat cat-red">
                              <input
                                type="checkbox"
                                id="action19"
                                value="On Hold"
                                onClick={handleStatusFilter}
                                checked={searchStatusCheck.action19}
                              />
                              <label htmlFor="action19">
                                <span className="as-yellow-bg as-widget">
                                  On Hold
                                </span>
                              </label>
                            </li>
                            <li className="cat cat-red">
                              <input
                                type="checkbox"
                                id="action13"
                                value="Past"
                                onClick={handleStatusFilter}
                                checked={searchStatusCheck.action13}
                              />
                              <label htmlFor="action13">
                                <span className="as-yellow-bg as-widget">Past</span>
                              </label>
                            </li>

                            <li className="cat cat-blue">
                              <input
                                type="checkbox"
                                id="action12"
                                value="Upcoming"
                                onClick={handleStatusFilter}
                                checked={searchStatusCheck.action12}
                              />
                              <label htmlFor="action12">
                                <span className="as-blue-bg as-widget">
                                  Upcoming
                                </span>
                              </label>
                            </li>
                          </ul>
                        </form>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="reset-btn-group">
                <div className="button-reset dropdown-comman">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={resetFilter}
                    title="Reset"
                  >
                    <i className="fal fa-redo"></i>Reset
                  </button>
                </div>

                <div className="files-export-group">
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("xlsx", "Attendance");
                    }}
                    title="Export spreadsheet"
                  >
                    <i className="fal fa-file-excel icon"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-files"
                    onClick={() => {
                      exportData("csv", "Attendance");
                    }}
                    title="Export CSV"
                  >
                    <i className="fal fa-file-csv icon"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-files"
                    title="Export PDF"
                    onClick={() => {
                      exportData("pdf", "Attendance");
                    }}
                  >
                    <i className="fal fa-file-pdf icon"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {!loading ? (
        <DataTable
          data={dataToRender()}
          columns={columns}
          pagination={true}
          keyField="Name"
          defaultSortAsc={true}
          defaultSortField="first_name"
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      ) : (
        <SkeletonTicketList />
      )}
      <div
        className="topic-add-modal modal fade"
        id="attendanceOpen"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-clock"></i> Timer
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="t p-30px">

                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Start Time*</label>
                          <DatePicker
                            placeholderText="Select time"
                            selected={formik.values.startTime}
                            onChange={(date) => {
                              formik.setFieldValue("startTime", date);
                            }}
                            className={
                              "form-control" +
                              (formik.errors.startTime && formik.touched.startTime
                                ? " is-invalid"
                                : "")
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat={FORM_TIME_FORMAT}
                            timeFormat={FORM_TIME_FORMAT}
                            onChangeRaw={(e) => e.preventDefault()}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>End Time*</label>
                          <DatePicker
                            selected={formik.values.endTime}
                            placeholderText="Select time"
                            onChange={(date) => {
                              formik.setFieldValue("endTime", date);
                            }}
                            className={
                              "form-control " +
                              (formik.errors.endTime && formik.touched.endTime
                                ? " is-invalid"
                                : "")
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat={FORM_TIME_FORMAT}
                            timeFormat={FORM_TIME_FORMAT}
                            onChangeRaw={(e) => e.preventDefault()}
                            disabled={!formik.values.startTime}
                            minTime={new Date(formik.values.startTime).setTime(
                              new Date(formik.values.startTime).getTime() +
                              15 * 60000
                            )}
                            maxTime={new Date().setHours(23, 59, 0, 0)}
                          />

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel mt-3">
                    <PermissionsGate
                      scopes={["cedit"]}
                      errorProps={{ disabled: true }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={formik.handleSubmit}
                        title="Save"
                      >
                        <i className="fal fa-save"></i>Save
                      </button>
                    </PermissionsGate>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      title="Close"
                      onClick={() => formik.resetForm()}
                    >
                      <i className="fal fa-times"></i>
                      Close
                    </button>
                    {formik.touched.startTime && formik.errors.startTime ? (
                      <div className="invalid-feedback d-block">
                        {formik.errors.startTime}
                      </div>
                    ) : null}
                    {formik.touched.endTime && formik.errors.endTime ? (
                      <div className="invalid-feedback d-block">
                        {formik.errors.endTime}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Student Note Popup */}
      <div
        className="topic-add-modal modal fade"
        id="studentnote"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fal fa-plus"></i> Student Note
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

              </div>
              <div className="t p-30px">
                <div className="topic-add-modal-content">
                  <div className="row center-label">
                    <div className="col-md-12">
                      <div className="form-group-blk mb-3">
                        <label>Details*</label>
                        <input
                          type="text"
                          title="Details"
                          value={studentFormik.values.details}
                          name="details"
                          className={
                            "form-control" +
                            (studentFormik.touched.details &&
                              studentFormik.errors.details
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Details"
                          onChange={studentFormik.handleChange}
                        />
                      </div>

                    </div>
                    <div className="col-md-12">
                      <div className="form-group-blk mb-3">
                        <label>Add Attachment*</label>
                        <div className="form-group atttach-file">
                          <label
                            className={
                              "file-req" +
                              (studentFormik.touched.attachment &&
                                studentFormik.errors.attachment
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <i className="fal fa-paperclip"></i>
                            <span>Add Attachment</span>
                            <input
                              type="file"
                              id="input"
                              className="form-control  form-control-aatch-file"
                              accept={SUPPORTED_FORMATS}
                              name="attachment"
                              onChange={(e) => {
                                if (e.target.files.length) {
                                  studentFormik.setFieldValue(
                                    "attachment",
                                    e.target.files[0]
                                  );

                                  setLetterheadHeaderName({
                                    name: e.target.files[0].name,
                                  });
                                }
                              }}
                            />
                          </label>
                        </div>
                        {letterheadHeaderName.name && (
                          <>

                            <div className="frm-group pt-2">

                              <p>{letterheadHeaderName.name}</p>

                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-3">
                  <PermissionsGate
                    scopes={["cedit"]}
                    errorProps={{ disabled: true }}
                  >
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={studentFormik.handleSubmit}
                    >
                      <i className="fal fa-save"></i>
                      Save
                    </button>
                  </PermissionsGate>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    title="Close"
                    onClick={() => {
                      studentFormik.resetForm();
                    }}
                  >
                    <i className="fal fa-times"></i>
                    Close
                  </button>

                  {studentFormik.touched.details && studentFormik.errors.details ? (
                    <div className="invalid-feedback d-block">
                      {studentFormik.errors.details}
                    </div>
                  ) : null}
                  {studentFormik.touched.attachment &&
                    studentFormik.errors.attachment ? (
                    <div className="invalid-feedback d-block">
                      {studentFormik.errors.attachment}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
