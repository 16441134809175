import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import { deleteQuiz, GetListOfAssessment, getQuiz, GetQuizCancelToken, GetQuizFilter, GetQuizFilterCancelToken, GetQuizTopics } from "../../../../services/SmartAssessmentService";
import Str from "../../../common/Str";
import { TrimText } from "../../../common/TrimText";
import moment from "moment";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import PermissionsGate from "../../../../utils/permissionGate";
import { QuizStatusRender } from "../../../systemadministration/usermanagement/CheckRole";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { TABLE_ROWS_PER_PAGE, TABLE_DATE_FORMAT } from "../../../../utils/Constants";
import Tablefilter from "../../../common/Tablefilter";
import axios from "axios";
import { RenderDate, RenderQuizStatus, RenderQuizTopic, RenderQuizType } from "../../../../utils/CommonStatusItems";
import $ from "jquery";
import { handleTableScroll } from "../../../../utils/commonFunction";

const AssessmentList = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [topics, setTopics] = useState({ arr: [], checkObj: {} });
  const [quizType, setQuizType] = useState({ arr: [], checkObj: {} });
  const [Month, setMonth] = useState({ arr: [], checkObj: {} });
  const [Year, setYear] = useState({ arr: [], checkObj: {} });

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortkey, setSortKey] = useState("last_modified_date");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [page, setPage] = useState(1);
  const [deleterow, setDeleteRow] = useState(false);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [debouncedTerm, setDebouncedTerm] = useState(search);
  const [filterData, setFilterData] = useState({status : [], topic : [], type : [], Month : [], Year : []});

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateQuizzes"));
    if (storedFilters) {
        setStatus(storedFilters.status);
        setTopics(storedFilters.topics);
        setMonth(storedFilters.Month);
        setYear(storedFilters.Year);   
        setFilterData(storedFilters.filterData);
    }
  }, []);
  useEffect(() => {
    const filterState = {
        status,
        topics,
        Month,
        Year,
        filterData
    };
    localStorage.setItem("filterStateQuizzes", JSON.stringify(filterState));
  }, [ status, topics, Month, Year, filterData]);
  useEffect(() => {
    let response = hasPermission({ scopes: ["cquizmview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])

  useEffect(() => {
    GetQuizFilter().then((res)=>{
      setFilterData({...res.data, status : res.data.quizStatusFilters, topic : res.data.topicFilters, type : res.data.quizTypeFilter, Month: res.data.month, Year: res.data.year})
    }).catch(err=>console.log(err))
  }, [])
  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  useEffect(() => {

    const cancelTokenSources = [];

    const getQuizList = async () => {
      setLoading(true)
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source);
  
      const data = {
        page: page,
        limit: perPage,
        viaSearch: search ? search : "",
        key: sortkey,
        sort: sortOrder,
        exportStatus: false,
        viaMonth: Month.arr.length > 0 ? Month.arr : [],
        viaYear: Year.arr.length > 0 ? Year.arr : [],
        viaStatus: status.arr.length > 0 ? status.arr : [],
        viaTopics: topics.arr.length > 0 ? topics.arr : [],
        viaQuizType: quizType.arr.length > 0 ? quizType.arr : [],
      }
  
      try {
        const res = await GetQuizCancelToken(data, source.token);
        setData(res.data?.data)
        setTotalRows(res.data?.total);
        if (res.status == 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    }

    getQuizList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [page, perPage, deleterow, search, sortOrder, sortkey, status, topics, quizType, Month, Year])

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetQuizFilterCancelToken(dropdownSource.token);
        setFilterData({...res.data, status : res.data.quizStatusFilters, topic : res.data.topicFilters, Month: res.data.month, Year: res.data.year, type : res.data.quizTypeFilter ? res.data.quizTypeFilter : []})
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };
  
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };
  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const wordSpliter = (str, type) => {
    // const strArr = str?.toString()?.split(",");
    const strArr = Array.isArray(JSON.parse(str)) ? JSON.parse(str) : [];
    const newStrArr = strArr.length ? strArr.map((item, index) => {      
      return item ? <p className="as-widget mr-1 mt-1" key={index} style={{color: '#333333', background: '#e6e6e6'}}>{item}</p> : '-';
    }) : "-";
    return newStrArr;
  }

  const wordSpliterTopics = (str) => {
    let htmlOutput = []
    const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length ? strArr?.map(item => {
      filterData.topic.map((val)=>{
        if(val.value == item){
          htmlOutput.push(RenderQuizTopic(val.label,val.color).html)
        }
        return;
      });   
    }) : "-";
    return htmlOutput.length ? htmlOutput : []
  }
  const wordSpliterTopicsExport = (str) => {
    let htmlOutput = []
    const strArr = str && Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length ? strArr?.map(item => {
      filterData.topic.map((val)=>{
        if(val.value == item){
          htmlOutput.push(RenderQuizTopic(val.label,val.color).text)
        }
        return;
      });   
    }) : "-";
    return htmlOutput.length ? htmlOutput.join(",") : []
  }

  // Function to extract months and return as objects with value and label
  function extractMonths(dateArray) {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const monthsArray = [];
    dateArray.forEach((monthObj) => {
        const month = monthObj.value;
          const monthName = monthNames[month - 1];
            monthsArray.push({
              value: month,
              label: monthName,
            });
    });
  
    return monthsArray;
  }

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "quiz_name",
      // sortFunction: nameSort,
      sortField: "quiz_name",
      sortable: true,
      cell: (row) => (
        <PermissionsGate scopes={["cquizmview"]} errorProps={{ disabled: true }}>
          <Link className="as-text-blue curser" to={"/courseAdministration/assessment/quizzes/open/details/" + row.quid} title={row.quiz_name}>
            <p className="textLimit150"> {row.quiz_name} </p>
          </Link>
        </PermissionsGate>
      )
    },
    // {
    //   name: "Quiz Type",
    //   selector: "quiz_type",
    //   sortField: "quiz_type",
    //   sortable: false,
    //   cell: (row) => (row.quiz_type && filterData.type.length && row.quiz_type.split(",").length ? row.quiz_type.split(",").map((element) =>(RenderQuizType(filterData.type.find(item => item.value==element)).html))  : "-")
    // },
    {
      name: "#Question",
      selector: "noq",
      sortField: "noq",
      sortable: true,
      cell: (row) => row.noq
    },
    {
      name: "Status",
      selector: "Status",
      sortField: "Status",
      sortable: true,
      cell: (row) => row.status ? RenderQuizStatus(row.status).html : "-",
    },
    {
      name: "Keywords",
      // selector: "keywords",
      // sortField: "keywords",
      // sortable: true,
      cell: (row) => row?.keywords ? <div className="d-flex flex-wrap">{wordSpliter(row?.keywords,"keywords")}</div> : '-',
    },
    {
      name: "Topics",
      cell: (row) => row?.topic ? <div className="d-flex flex-wrap">{wordSpliterTopics(row?.topic).length ? wordSpliterTopics(row?.topic).map((item, index) => <React.Fragment key={index}>{item} &nbsp;</React.Fragment>) : ""}</div> : '-',
    },
    {
      name: "Last Modified Date",
      selector: "last_modified_date",
      sortField: "last_modified_date",
      sortable: true,
      cell: (row) => {
        const moment = require('moment');
        // Check if the last_modified_date exists
        if (row.last_modified_date) {
          // Use Moment.js to format the date
          const formattedDate = moment(row.last_modified_date).format(TABLE_DATE_FORMAT).toString() === "Invalid date" ? "-" : moment(row.last_modified_date).format(TABLE_DATE_FORMAT);
      
          return formattedDate;
        } else {
          return "-";
        }
      },
    },  
    {
      name: "Actions",
      maxWidth: "50px",
      cell: (row) => (
        
        
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <PermissionsGate scopes={["cquizmview"]}>
              <Link className="btn btn-primary rounded-circle" title="Open" to={"/courseAdministration/assessment/quizzes/open/details/" + row.quid}>
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
             {hasPermission({ scopes: ["cquizmadd"], permissions: givenPermsisions }) && hasPermission({ scopes: ["cquizmdelete"], permissions: givenPermsisions }) ?
             
             <div className="dropdown btn-dropdown-item">
              <button className="btn btn-primary rounded-circle dropdown-toggle" title="More" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <PermissionsGate scopes={["cquizmadd"]}>
                  <Link className="btn btn-primary rounded-circle" title="Duplicate" to={"/courseAdministration/quizzes/duplicateassessment/" + row.quid}>
                    <i className="fal fa-copy"></i>
                  </Link>
                </PermissionsGate>
                <PermissionsGate scopes={["cquizmdelete"]}>
                  <button className="btn btn-danger rounded-circle" title="Delete"
                    onClick={() => handleDelete(row.quid)}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
            :
             hasPermission({ scopes: ["cquizmadd"], permissions: givenPermsisions }) ? 
             <PermissionsGate scopes={["cquizmadd"]}>
              <Link className="btn btn-primary rounded-circle" title="Duplicate" to={"/courseAdministration/quizzes/duplicateassessment/" + row.quid}>
                <i className="fal fa-copy"></i>
              </Link>
            </PermissionsGate> : hasPermission({ scopes: ["cquizmdelete"], permissions: givenPermsisions }) ?
              <PermissionsGate scopes={["cquizmdelete"]}>
                <button className="btn btn-danger rounded-circle" title="Delete"
                  onClick={() => handleDelete(row.quid)}
                >
                  <i className="fal fa-trash-alt"></i>
                </button>
              </PermissionsGate> :""
            }


            
          </div>
        </div>
      ),
    },
  ]);


  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteQuiz({ id: id }).then((res) => setDeleteRow(!deleterow));
        Swal.fire("Deleted!", "Your record has been deleted.", "success");
      }
    });
  };

  const handleSearch = (e, typeName = "") => {
    setSearch(e.target.value);    
  };

  const resetFilter = () => {
    setSearch("");
    setDebouncedTerm("")
    setStatus({ arr: [], checkObj: {} });
    setTopics({ arr: [], checkObj: {} });
    setQuizType({ arr: [], checkObj: {} });
    setMonth({ arr: [], checkObj: {} });
    setYear({ arr: [], checkObj: {} });
    setFilterData({status : [], topic : [], type : [], Month : [], Year : []})
  };

  const exportData = async (fileType, fileName) => {
    // let data = dataToRender();

    let data;

    const header = ["Name", "Question", "Status", "Keywords", "Topics"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    getQuiz({
      page: page,
      limit: perPage,
      viaSearch: search ? search : "",
      key: sortkey,
      sort: sortOrder,
      exportStatus: "true",
      viaStatus: status.arr.length > 0 ? status.arr : [],
      viaTopics: topics.arr.length > 0 ? topics.arr : [],
      // viaQuizType: quizType.arr.length > 0 ? quizType.arr : [],
    })
      .then((res) => {
        data = res?.data
        data = data?.map((row) => ({
          ...row,
          Name: row?.quiz_name ? row?.quiz_name : "-",
          // "Quiz Type" : row?.quiz_type && row.quiz_type.split(",").length ? row.quiz_type.split(",").map(element => RenderQuizType(filterData.type.find(item => item.value==element)).text).join(",")  : "-",
          Question: row?.noq ? row?.noq : "-",
          Status: row.status ? RenderQuizStatus(row.status).text : "-",
          Keywords: (row?.keywords && row?.keywords.length > 0 && Array.isArray(JSON.parse(row?.keywords))) ? JSON.parse(row?.keywords)?.map(item=>item).join(", ") : "-",
          Topics: (row?.topic && wordSpliterTopicsExport(row?.topic).length) ? wordSpliterTopicsExport(row?.topic) : "-" ,
        }));
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

          const blobURL = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["quiz_name"],
              row["noq"],
              row["Status"],
              row["Keywords"],
              row["Topics"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })


  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" placeholder="Search" aria-controls="assessment-table-main"  onChange={e => setDebouncedTerm(e.target.value)} value={debouncedTerm} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">

                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Status"
                      optionArr={filterData.status}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={RenderQuizStatus}
                    />
                    <Tablefilter
                      filterName="Topic"
                      optionArr={filterData.topic}
                      state={topics}
                      setState={setTopics}
                      renderLabelFunction={RenderQuizTopic}
                      uniqueId = "topic"
                    />
                    <Tablefilter
                      filterName="Month"
                      optionArr={extractMonths(filterData.Month || [])}
                      state={Month}
                      setState={setMonth}
                    />
                    <Tablefilter
                      filterName="Year"
                      optionArr={filterData.Year || []}
                      state={Year}
                      setState={setYear}
                      renderLabelFunction={RenderDate}
                    />
                    {/* <Tablefilter
                      filterName="Quiz Type"
                      optionArr={filterData.type}
                      state={quizType}
                      setState={setQuizType}
                      renderLabelFunction={RenderQuizType}
                      uniqueId = "quizType"
                    /> */}
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>

                  <div className="files-export-group">
                    <button type="button" className="btn btn-files" onClick={() => { exportData("xlsx", "Quiz Management") }} title="Export spreadsheet">
                      <i className="fal fa-file-excel"></i>
                    </button>
                    <button type="button" className="btn btn-files" onClick={() => { exportData("csv", "Quiz Management") }} title="Export CSV">
                      <i className="fal fa-file-csv"></i>
                    </button>
                    <button type="button" className="btn btn-files" onClick={() => { exportData("pdf", "Quiz Management") }} title="Export PDF">
                      <i className="fal fa-file-pdf"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate scopes={["cquizmadd"]} RenderError={() => (
                  <button className="btn btn-save" title="Create New" disabled>
                    <i className="fal fa-plus"></i>Create New
                  </button>
                )}>
                  <button className="btn btn-primary" title="Create New" onClick={() => history.push("/courseAdministration/assessment/quiz/addquiz")}>
                    <i className="fal fa-plus"></i>Create New
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          data={data}
          // data={dataToRender()}
          defaultSortField="last_modified_date"
          defaultSortAsc={false}
          columns={columns}
          pagination
          noDataComponent={Str.noRecord}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          onSort={handleSort}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationServer
          paginationTotalRows={totalRows}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
    </div>

  );
};

export default AssessmentList;
