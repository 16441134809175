import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../../../../loaders/SkeletonTicketList";
import Str from "../../../../../common/Str";
import { QuizStatusRender } from "../../../../../systemadministration/usermanagement/CheckRole";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import {assignedQuizToQuestion, GetQuestionAssignedOrNotAssignedList, GetQuestionAssignedOrNotAssignedListCancelToken, ListQuestionFilter, ListQuestionFilterCancelToken} from "../../../../../../services/SmartAssessmentService";
import PermissionsGate from "../../../../../../utils/permissionGate";
import HtmlParser from "react-html-parser";
import striptags from "striptags";
import { TABLE_ROWS_PER_PAGE, TABLE_DATE_FORMAT } from "../../../../../../utils/Constants";
import {useParams} from "react-router";
import { RenderQuestionLevel, RenderQuestionType } from "../../../../../../utils/CommonGroupingItem";
import { RenderDate, RenderQuizTopic, wordSpliter } from "../../../../../../utils/CommonStatusItems";
import Tablefilter from "../../../../../common/Tablefilter";
import { TrimText } from "../../../../../common/TrimText";
import { handleTableScroll } from "../../../../../../utils/commonFunction";
import axios from "axios";

const QBTable = ({refresh}) => {
  const {id} =useParams();
  const [search, setSearch] = useState("");
  const [type, setType] = useState({ arr: [], checkObj: {} });
  const [level, setLevel] = useState({ arr: [], checkObj: {} });
  const [sortkey, setSortKey] = useState("last_modified_date");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [topics2, setTopics2] = useState({ arr: [], checkObj: {} });
  const [Month, setMonth] = useState({ arr: [], checkObj: {} });
  const [Year, setYear] = useState({ arr: [], checkObj: {} });
  const [filterDataAvail, setfilterDataAvail] = useState({type : [], level : [], topics : [], Month : [], Year : []})
  const [qBData, setQBData] = useState([]);
  const [totalRows2, setTotalRows2] = useState(0);
  const [loading, setLoading] = useState(false)
  const [gearId, setGearId] = useState("");
  const [refreshList, setRefreshList] = useState(false);
  const [debouncedTerm, setDebouncedTerm] = useState("")

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await ListQuestionFilterCancelToken(id,"notassigned",dropdownSource.token);
        if(res.status == 200){
          setfilterDataAvail({...res.data, type : res.data.questionTypeFilters, level : res.data.questionLevelFilter, topics : res.data.topicFilters, Month : res.data.month, Year : res.data.year})
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getQuestionAssignedOrNotAssignedList = async () => {
      setLoading(true)
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const data = {
        type: "notassigned",
        viaType: type.arr.length ? type.arr : [],
        viaLevel: level.arr.length ? level.arr : [],
        page: page,
        limit: perPage,
        viaSearch: search,
        key: sortkey,
        sort: sortOrder,
        quizId: id,
        viaTopics: topics2.arr.length > 0 ? topics2.arr : [],
        viaMonth: Month.arr.length > 0 ? Month.arr : [],
        viaYear: Year.arr.length > 0 ? Year.arr : []
      }
  
      try {
        const res = await GetQuestionAssignedOrNotAssignedListCancelToken(data, source.token);
        if (res.status === 200) {
          setQBData(res.data.res.data);
          setTotalRows2(res.data.res.total)
          setLoading(false)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false)
        }
      }
    };
  
    getQuestionAssignedOrNotAssignedList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [type, level, perPage, page, search, sortkey, sortOrder, topics2, Month, Year, refreshList]);

  
  const wordSpliterTopics = (str) => {
    let htmlOutput = []
    const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length ? strArr?.map(item => {
      filterDataAvail.topics.map((val)=>{
        if(val.value == item){
          htmlOutput.push(RenderQuizTopic(val.label,val.color).html)
        }
        return;
      });   
    }) : "-";
    return htmlOutput.length ? htmlOutput : []
  }
  const wordSpliterTopicsExport = (str) => {
    let htmlOutput = []
    const strArr = str && Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length ? strArr?.map(item => {
      filterDataAvail.topics.map((val)=>{
        if(val.value == item){
          htmlOutput.push(RenderQuizTopic(val.label,val.color).text)
        }
        return;
      });   
    }) : "-";
    return htmlOutput.length ? htmlOutput.join(",") : []
  }

  function extractMonths(dateArray) {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const monthsArray = [];
    dateArray.forEach((monthObj) => {
        const month = monthObj.value;
          const monthName = monthNames[month - 1];
            monthsArray.push({
              value: month,
              label: monthName,
            });
    });
  
    return monthsArray;
  }

  const columns2 = useMemo(() => [
    {
      name: "Name",
      selector: "question_name",
      sortField: "question_name",
      // sortFunction: sortName,
      sortable: true,
      cell: (row, index) => {
        let question = row.question_name;
        return <span className="as-text-blue curser feature-name" title={striptags(question)} onClick={() => addresource(row.qid)} >
          <span className="textLimit100">{question.trim() ? HtmlParser(question) : "-"}</span>
        </span>
      },
    },
    {
      name: "Type",
      selector: "question_type",
      sortField: "question_type",
      sortable: true,
      cell: (row, index) => (
        <>
          {row.question_type ? RenderQuestionType(row.question_type.trim()).html : "-"}
        </>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row, index) => (
        <>
          {row.status ? QuizStatusRender(row) : "-"}
        </>
      ),
    },
    {
      name: "Level",
      selector: "level_name",
      sortField: "level_name",
      sortable: true,
      cell: (row, index) =>
        row.level_name ? RenderQuestionLevel(row.level_name).html : "-",
    },
    {
      name: "NQF Level",
      selector: "nqf_level",
      sortField: "nqf_level",
      sortable: true,
      cell: (row, index) =>
        row.nqf_level ? <span>NQF {row.nqf_level.replace("NQF ","")}</span>: "-",
    },
    {
      name: "#Linked Quizzes",
      selector: "AssigendToassessmentCount",
      sortField: "AssigendToassessmentCount",
      sortable: true,
      cell: (row, index) => <p>{row?.AssigendToassessmentCount?.toString() ? row?.AssigendToassessmentCount : "-"}</p>,
    },
    {
      name: "Last Modified Date",
      selector: "last_modified_date",
      sortField: "last_modified_date",
      sortable: true,
      cell: (row) => {
        const moment = require('moment');
        // Check if the last_modified_date exists
        if (row.last_modified_date) {
          // Use Moment.js to format the date
          const formattedDate = moment(row.last_modified_date).format(TABLE_DATE_FORMAT).toString() === "Invalid date" ? "-" : moment(row.last_modified_date).format(TABLE_DATE_FORMAT);
      
          return formattedDate;
        } else {
          return "-";
        }
      },
    },
    {
      name: "Keywords",
      cell: (row) => row?.keywords ? <div className="d-flex flex-wrap">{wordSpliter(row?.keywords,"keywords")}</div> : '-',
    },
    {
      name: "Topics",
      cell: (row) => row?.topic ? <div className="d-flex flex-wrap">{wordSpliterTopics(row?.topic).length ? wordSpliterTopics(row?.topic).map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>) : ""}</div> : '-',
    },
    {
      name: "Actions",
      maxWidth: "50px",
      cell: (row, index) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <PermissionsGate scopes={["cquizmadd"]} errorProps={{ disabled: true }}>
              <button className="btn btn-primary rounded-circle" title="Add" onClick={() => addresource(row.qid)} >
                {gearId==row.qid ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-plus"></i>}
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const addresource = (ID) => {
    setGearId(ID);
    assignedQuizToQuestion({ quizId: id, questionId: ID, deleteQulification: "false" }).then((res) => {
      // Swal.fire({ icon: "success", title: "Success", text: "Added Successfully" });
      refresh();
      setRefreshList(!refreshList);
    }).catch(err => Swal.fire({ icon: "error", title: "Error", text: err.response.data.message }))
    .finally(()=>{setGearId("")});

  }

  const handleFilter = (e, typeName = "") => {
    const value = e.target.value;
    if (typeName == "search") {
      setDebouncedTerm(value);
    }
  };

  function resetFilter() {
    setSearch("");
    setDebouncedTerm("")
    setType({ arr: [], checkObj: {} });
    setLevel({ arr: [], checkObj: {} });
    setTopics2({ arr: [], checkObj: {} });
    setMonth({ arr: [], checkObj: {} });
    setYear({ arr: [], checkObj: {} });
  }


  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };


  const exportData = async (fileType, fileName) => {
    let data;
    const header = ["Name", "Type", "Status", "Level", "NQF Level", "#Linked Quizzes","Last Modified Date", "Keywords", "Topics"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    GetQuestionAssignedOrNotAssignedList({
      type: "notassigned",
      quizId: id,
      viaType: type.arr.length ? type.arr : [],
      viaLevel: level.arr.length ? level.arr : [],
      page: page,
      limit: perPage,
      viaSearch: search,
      key: sortkey,
      sort: sortOrder,
      exportStatus: true,
      viaTopics: topics2.arr.length > 0 ? topics2.arr : [],
      viaMonth: Month.arr.length > 0 ? Month.arr : [],
      viaYear: Year.arr.length > 0 ? Year.arr : [],
    })
      .then((res) => {
        // console.log(" res from exportStatus", res);
        data = res.data.res
        data = data?.map((row) => ({
          ...row,
          Name: row?.question_name ? TrimText(row?.question_name, 20) : "-",
          Type: row?.question_type ? row?.question_type : "-",
          Status: row?.status ? row?.status : "-",
          Level: row.level_name ? row.level_name : "-",
          "NQF Level": row?.nqf_level ? `NQF ${row?.nqf_level.replace("NQF ","")}` : "-",
          "#Linked Quizzes": row?.AssigendToassessmentCount?.toString() ? row?.AssigendToassessmentCount : "-",
          // "% Correct": row.Correct_Percentage,
          Keywords: (row?.keywords && row?.keywords.length > 0 && Array.isArray(JSON.parse(row?.keywords))) ? JSON.parse(row?.keywords)?.map(item=>item).join(", ") : "-",
          Topics: (row?.topic && wordSpliterTopicsExport(row?.topic).length) ? wordSpliterTopicsExport(row?.topic) : "-" ,
          last_modified_date: row?.last_modified_date ? row?.last_modified_date : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

          const blobURL = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Name"],
              row["Type"],
              row["Status"],
              row["Level"],
              row["NQF Level"],
              row["#Linked Quizzes"],
              // row["% Correct"],
              row["#Last Modified Date"],
              row["Keywords"],
              row["Topics"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })

  };

  return (
    <div className="modal-body my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="custom-table-div filter-search-icon card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={(e) => { handleFilter(e, "search") }} value={debouncedTerm} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                  <Tablefilter
                    filterName="Type"
                    optionArr={filterDataAvail.type}
                    state={type}
                    setState={setType}
                    renderLabelFunction={RenderQuestionType}
                  />
                  <Tablefilter
                    filterName="Level"
                    optionArr={filterDataAvail.level}
                    state={level}
                    setState={setLevel}
                    renderLabelFunction={RenderQuestionLevel}
                  />
                  <Tablefilter
                    filterName="Topic"
                    optionArr={filterDataAvail.topics}
                    state={topics2}
                    setState={setTopics2}
                    renderLabelFunction={RenderQuizTopic}
                    uniqueId = "availTopic"
                  />
                  <Tablefilter
                    filterName="Month"
                    optionArr={extractMonths(filterDataAvail.Month)}
                    state={Month}
                    setState={setMonth}
                  />
                  <Tablefilter
                    filterName="Year"
                    optionArr={filterDataAvail.Year}
                    state={Year}
                    setState={setYear}
                    renderLabelFunction={RenderDate}
                  />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button type="button" className="btn btn-files" title="Export spreadsheet" onClick={() => { exportData("xlsx", "Available Questions"); }}>
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button type="button" className="btn btn-files" title="Export CSV" onClick={() => { exportData("csv", "Available Questions"); }} >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button type="button" className="btn btn-files" title="Export PDF" onClick={() => { exportData("pdf", "Available Questions"); }}>
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          data={qBData}
          defaultSortField="last_modified_date"
          defaultSortAsc={false}
          columns={columns2}
          pagination={true}
          noDataComponent={Str.noRecord}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          paginationServer
          onSort={handleSort}
          paginationTotalRows={totalRows2}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
    </div>
  );
};

export default QBTable;