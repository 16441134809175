import React from "react";
import { useParams } from "react-router-dom";
import StartsToday from "./Assessments/StartsToday";
import OpenAssessments from "./Assessments/OpenAssessments";
import Upcoming from "./Assessments/Upcoming";
import MarkingDueToday from "./Assessments/MarkingDueToday";
import MarkingOverDue from "./Assessments/MarkingOverDue";
import UpcomingMarking from "./Assessments/UpcomingMarking";

const ListOfAssessments = () => {

  const { type } = useParams();

  const componentMap = {
    Today: <StartsToday />,
    Open: <OpenAssessments />,
    upcoming: <Upcoming />,
    Making_due_today: <MarkingDueToday />,
    Making_over_due: <MarkingOverDue />,
    Upcoming_marking: <UpcomingMarking />,
  };

  return componentMap[type]
}
export default ListOfAssessments;
