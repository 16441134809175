import React, { useState, useMemo, useEffect } from "react";
import { InitialRender } from "../common/Helper";
import { Link, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import { useFormik } from "formik";
import * as Yup from "yup";
import "jspdf-autotable";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../utils/Constants";
import moment from "moment";
import Swal from "sweetalert2";
import PermissionsGate from "../../utils/permissionGate";
import {
  GetMentoredStudentsList,
  SendQuickNotification,
  InsertSignOff,
  GetMentoredStudentsListCancelToken,
  GetMentoredStudentsDropdownCancelToken,
} from "../../services/DashBoardServices";
import { ColorRender } from "../systemadministration/usermanagement/CheckRole";
import { TrimText } from "../common/TrimText";
import Hover from "../common/Hover";
import $ from "jquery";
import HtmlInputEditor from "../common/HtmlInputEditor";
import FilterComponent from "../common/FilterComponent";
import Tablefilter from "../common/Tablefilter";
import { formatQuestion } from "../../utils/FormatQuestion";
import { RenderCourseType } from "../../utils/CommonGroupingItem";
import axios from "axios";
import { handleTableScroll } from "../../utils/commonFunction";

function InProgressSLPStudents({ subjectStatus }) {
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const [value, onChange] = useState([]);
  const [validation, setValidation] = useState(false)
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [courseType, setCourseType] = useState({ arr: [], checkObj: {} });
  const [dashboardList, setDashBoardList] = useState([]);
  const [virtualDropdown, setVirtualDropdown] = useState({course : [], programme : [], status : [], mStatus: [], lecturer :[], course_type :[]});
  const [courseIds, setCourseIds] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [sortkey, setSortKey] = useState("start");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [signOffData, setSignOffData] = useState({});
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const notificationInitialState = {
    class_id: "",
    subject: "",
    message: "",
    attachment: {},
    class_name: "",
  };
  const [notificationFields, setNotificationFields] = useState(notificationInitialState);
  const [showCkEditor, setShowCkEditor] = useState(false);
  const [editorState, setEditorState] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileName, setSelectedFileName] = useState("");
  const [reload, setReload] = useState(false)

   useEffect(() => {
     setPage(1);
   }, [location]);

   useEffect(() => {
    let timeOut = setTimeout(() => {
      setSearch(debouncedTerm);
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [debouncedTerm]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    const cancelTokenSources = [];

    const getDashboard = async () => {
      setLoading(true);

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      const data = {
        viaLecturer: [],
        pageNo: page,
        size: perPage,
        key: sortkey,
        sort: sortOrder,
        viaCourse: courseIds.length > 0 ? courseIds : [],
        viaProgramme: [],
        viaSubjectStatus: [],
        viaMentoringStatus: [],
        viaStatus: [],
        viaSearch: search,
        mtsStatus: subjectStatus,
        courseType: courseType.arr.length ? courseType.arr : []
      };
  
      try {
        const res = await GetMentoredStudentsListCancelToken(data, source.token);
        setDashBoardList(res.data?.result);
        setTotalRows(res.data?.Pagination?.total);
        if (res.status === 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
    
    getDashboard();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [
    search,
    status,
    page,
    perPage,
    sortkey,
    sortOrder,
    courseIds,
    courseType,
    reload
  ]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();

    const fetchData = async () => {
      let data ={
        mtsStatus: subjectStatus
      }
      try {
        const res = await GetMentoredStudentsDropdownCancelToken(data, dropdownSource.token);
        setVirtualDropdown({...res.data, course : [] ,programme : [], status : [], mStatus : [], lecturer : [], course_type : []})
        setVirtualDropdown({...res.data, course : res.data.Course ,programme : res.data.Programme, status : res.data.studentStatus, mStatus : res.data.MentoringStatus, lecturer : res.data.lecturer, course_type : res.data.courseType})
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const approveSend = () => {
    const dataParam = {
      table_id: signOffData?.table_id,
      id: signOffData?.stuid,
      intake_real: signOffData?.intake_real,
      feedback: notificationFields.subject,
      status: 1, // Changed to static 1 from static 3 because of backend developer need
    };

    InsertSignOff(dataParam)
      .then((res) => {
        if (res.status == 200) {
          $("#sign-off").modal("hide");
          setNotificationFields(notificationInitialState)
          setValidation(false);
          Swal.fire("Success!", "Successfully approved.", "success").then(()=>{
            // getDashboard()
            setReload(!reload)
          });
        }
      })
      .catch((err) => console.error(err));
  };

  const declineSend = () => {
    const dataParam = {
      table_id: signOffData?.table_id,
      id: signOffData?.stuid,
      intake_real: signOffData?.intake_real,
      feedback: notificationFields.subject,
      status: 4,
    };

    InsertSignOff(dataParam)
      .then((res) => {
        if (res.status == 200) {
          $("#sign-off").modal("hide");
          setNotificationFields(notificationInitialState);
          setValidation(false);
          Swal.fire("Success!", "Successfully declined.", "success").then(()=>{
            // getDashboard()
            setReload(!reload)
          });
        }
      })
      .catch((err) => console.error(err));
  };

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
   
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const resetFilter = () => {
    setSearch("");
    setDebouncedTerm("");
    setStatus({ arr: [], checkObj: {} });
    setCourseType({ arr: [], checkObj: {} })
    setCourseIds([]);
    onChange([]);
  };

  const columns = useMemo(() => [
    {
      name: "Student",
      selector: "studentFname",
      sortable: true,
      sortField: "student",
      cell: (row) => (
        <>
          {row.studentName ? (
            <div className="assigned-title-block-new">
              <div className="assigned-title-itm">
                <span
                  className={
                    "assigned-title-blk  name-icon cat-dark-red " +
                    ColorRender("student support")
                  }
                >
                  {row.studentImage ? (
                    <img
                      src={`${IMAGE_URL}/${row.studentImage.replaceAll(
                        "/home/myaie/public_html/",
                        ""
                      )}`}
                      alt="AIE"
                    />
                  ) : (
                    InitialRender([row.studentFname, row.studentLname])
                  )}
                  <span className={`profile-box-2-status ${row.student_activity_status ? row.student_activity_status.toLowerCase()=="online" ? "Online" :row.student_activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                    <i className="fas fa-circle"></i>
                  </span>
                  <Hover
                    firstName={row.studentFname}
                    lastName={row.studentLname}
                    photo={row.studentImage}
                    email={row.studentEmail}
                    mobile={row.studentMobile}
                    right={true}
                    role={"lecturer"}
                    status={row.studentSatus}
                    activity_status={row.student_activity_status}
                    showNumber={true} 
                    number={row.student_crm_id}
                  />
                </span>
                <Link
                 title={row.studentName}
                 className="as-text-blue curser feature-name"
                 to={ `/studentAdministration/students/open/${row.stuid}/personal`}
                >
               
                  <span className="textLimit100" title={row.studentName}>{row["studentName"]}</span>
                </Link>
              </div>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Course Type",
      selector: "subject_type",
      sortable: true,
      sortField: "subject_type",
      cell: (row) => row?.subject_type ? RenderCourseType(row?.subject_type).html : "-"
    },
    {
      name: "Course",
      selector: "courseName",
      sortable: true,
      sortField: "course",
      cell: (row) => (
        row.courseName ? <span className="" title={row.courseName}>
          <Link
            to={`/courseAdministration/coursesdetails/${row.intakeID}/dashboard/show`}
            className="as-text-blue curser font-weight-bold"
          >
            {TrimText(row.courseName,35)}
          </Link>
        </span> : "-"
      ),
    },
    {
      name: "Date Started",
      selector: "NestedStartDate",
      sortable: true,
      sortField: "NestedStartDate",
      cell: (row) => (
        <>
          {row?.NestedStartDate ? <p className="right-space">
            {row?.NestedStartDate ? row.NestedStartDate.toString().length == 10 ? `${moment.unix(row.NestedStartDate).format(TABLE_DATE_FORMAT)} at ${moment.unix(row.NestedStartDate).format(TABLE_TIME_FORMAT)}` : `${moment(row.NestedStartDate).format(TABLE_DATE_FORMAT)} at ${moment(row.NestedStartDate).format(TABLE_TIME_FORMAT)}` : "-"}
          </p> : "-"}
        </>
      ),
    },
    {
      name: "End Date",
      selector: "NestedEndDate",
      sortable: true,
      sortField: "NestedEndDate",
      cell: (row) => (
        <>
          {row?.NestedEndDate ? <p className="right-space">
          {row?.NestedEndDate ? row.NestedEndDate.toString().length == 10 ? `${moment.unix(row.NestedEndDate).format(TABLE_DATE_FORMAT)} at ${moment.unix(row.NestedEndDate).format(TABLE_TIME_FORMAT)}` : `${moment(row.NestedEndDate).format(TABLE_DATE_FORMAT)} at ${moment(row.NestedEndDate).format(TABLE_TIME_FORMAT)}` : "-"}
          </p> : "-"}
        </>
      ),
    }, 
    {
      name: "Email",
      selector: "studentEmail",
      cell: (row) => (
        <>
          <div className="d-flex">
            <span title="Email" className="mr-2"><i className="fal fa-envelope as-text-blue"></i></span>
            <span title="Email" className="mr-2">{row?.studentEmail}</span>
        </div>
        </>
      ),
    },
    {
      name: "Progress",
      selector: "lecturer_name",
      // sortable: true,
      // sortField: "lecturer",
      cell: (row) => (
        <>
        <div className="d-flex">
        <span title="Progress of learning material" className="mr-2"><i className="fal fa-books as-text-blue mr-1"></i>{row?.progress?.learning_material_percentage}%</span>
        <span title="Progress of assessments"><i className="fal fa-ballot-check as-text-blue mr-1"></i>{row?.progress?.assessment_percentage}%</span>
        </div>
        </>
      ),
    },
    {
      name: "Actions",
      selector: "Action",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
              <button
                className="btn btn-primary rounded-circle"
                type="button"
                title="Add Comment"
                data-toggle="modal"
                data-target="#sign-off"
                onClick={() => {
                  setSignOffData(row);
                }}
                disabled={row.subject_type !== "mentored"}
              >
                 <i className="fal fa-comment"></i>
              </button>
            <div className="dropdown btn-dropdown-item">
              <button
                className="btn btn-primary rounded-circle dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="More"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <Link
                  className="btn btn-primary rounded-circle"
                  to={`/studentAdministration/students/open/${row.stuid}/general`}
                  title="Open"
                >
                 <i className="fal fa-folder-open"></i>
                </Link>
               
                {/* DO NOT DELETE BELOW COMMENTED BLOCK */}
                <button
                  className="btn btn-primary rounded-circle"
                  title="Quick Notification"
                  data-toggle="modal"
                  onClick={() => {
                    setShowCkEditor(true);
                    setSelectedFile("");
                    setSelectedFileName("");
                    setEditorState("")
                    $("#notifications1234").modal("show");
                    setNotificationFields((prevState) => {
                      return {
                        ...prevState,
                        class_id: row.course_id,
                        class_name: row.courseName,
                      };
                    });
                  }}
                  target="_blank"
                >
                  <i className="fal fa-bell"></i>
                </button>
                 <Link
                  className="btn btn-primary rounded-circle"
                  title="Create Ticket"
                  to={`/addticket`}
                >
                  <i className="fal fa-ticket-alt"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ], []);
  function cancelQuickNotification() {
    setNotificationFields({ ...notificationInitialState });
    setEditorState("");
    setSelectedFile("");
    setSelectedFileName("");
    formik.resetForm();
    setShowCkEditor(false);
  }

  const exportData = (fileType, fileName) => {
    let data;
    const header = [
      "Student",
      "Course Type",
      "Course",
      "Date Started",
      "End Date",
      "Progress",
      "Email"
    ];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    GetMentoredStudentsList({
      viaLecturer: [],
      key: sortkey,
      sort: sortOrder,
      viaCourse: courseIds.length > 0 ? courseIds : [],
      viaProgramme: [],
      viaSubjectStatus: [],
      viaMentoringStatus: [],
      viaStatus: [],
      mtsStatus: subjectStatus,
      exportStatus: "true",
      courseType: courseType.arr.length ? courseType.arr : []
    }).then((res) => {
      data = res?.data?.result || [];
      data = data.map((row) => ({
        "Student": row?.studentFname ? row["studentFname"] + " " + row["studentLname"] : "-",
        "Course Type": row?.subject_type ? RenderCourseType(row["subject_type"]).text : "-",
        Course: row?.courseName ? row["courseName"] : "-",
        "Date Started": row?.NestedStartDate ? row.NestedStartDate.toString().length == 10 ? `${moment.unix(row.NestedStartDate).format(TABLE_DATE_FORMAT)} at ${moment.unix(row.NestedStartDate).format(TABLE_TIME_FORMAT)}` : `${moment(row.NestedStartDate).format(TABLE_DATE_FORMAT)} at ${moment(row.NestedStartDate).format(TABLE_TIME_FORMAT)}` : "-", 
        "End Date":  row?.NestedEndDate ? row.NestedEndDate.toString().length == 10 ? `${moment.unix(row.NestedEndDate).format(TABLE_DATE_FORMAT)} at ${moment.unix(row.NestedEndDate).format(TABLE_TIME_FORMAT)}` : `${moment(row.NestedEndDate).format(TABLE_DATE_FORMAT)} at ${moment(row.NestedEndDate).format(TABLE_TIME_FORMAT)}` : "-", 
        "Progress": `Learning Material ${row?.progress?.learning_material_percentage ? row?.progress?.learning_material_percentage + " %" : "-"} | Assessment ${row?.progress?.assessment_percentage ? row?.progress?.assessment_percentage + "%" : "-"}`,
        "Email": row?.studentEmail ? row["studentEmail"] : "-",
      }));

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
       
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        setTimeout(() => {
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.forEach((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [
            row["Student"],
            row["Course Type"],
            row["Course"],
            row["Date Started"],
            row["End Date"],
            row["Progress"],
            row["Email"],
            
          ];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    });
  };

   const formik = useFormik({
     enableReinitialize: true,
     initialValues: {
       class_name: notificationFields.class_name,
       subject: "",
       attachment: "",
       content:"",
     },
     validationSchema: Yup.object({
       subject: Yup.string().required("Subject is required"),
       attachment: Yup.string().required("Attachment is required").trim(),

       content: Yup.string()
       .test("required", "Content is required", (value) => {
         if ((formatQuestion(editorState).replaceAll("&nbsp;", " ")).trim() == "" && !editorState.includes("<img")) {
           return false
         }
         if (!editorState) {
           return false
         }
         return true;
       })
       .trim()
     }),
     onSubmit: (values, { resetForm }) => {
        setDisabled(true);

       let formData = new FormData();
       formData.append("subject", values.subject);
       formData.append("message",editorState);
       formData.append("attachment", values.attachment);
       formData.append("class_id", notificationFields.class_id);
       formData.append("isEmail", 1);
       formData.append("isPushnotificationActive", 1);

       SendQuickNotification(formData)
         .then((data) => {
           if (data.status == 200) {
              setDisabled(false);
             setNotificationFields({
               class_id: "",
               subject: "",
               message: "",
               attachment: {},
               class_name: "",
             });
             $("#notifications1234").modal("hide");
             formik.resetForm("");
             setEditorState("");
             setSelectedFile("")
             setSelectedFileName("")
             setShowCkEditor(false);
             Swal.fire("Notification!", "Successfully notified.", "success");
            //  getDashboard();
            setReload(!reload)
           }
         })
         .catch((error) => {
           console.log("error ---> ", error);
         });
     },
   });
  return (
    <>
         <div
        className={`custom-table-div filter-search-icon card-table-custom new-table-bo`}
      >
          <div className="search-filter-div">
            <div className="search-filter-div-left">

              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                      <div
                        id="assessment-table-main_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={handleSearchFilter}
                            value={debouncedTerm}
                          />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="filter-scroll">
                    <div className={`filter-scroll-inner  filter-custom-new`}>
                        {/* Course filter */}
                        <div className="assessment-table-filter">
                          <div className="btn-type-filter dropdown-comman assesment-dropdown">
                            <div className="dropdown multiselect">
                              <button
                                className={`btn btn-default dropdown-toggle ${
                                  value?.length ? "btn-selected" : ""
                                } `}
                                type="button"
                                id="dropdownMenu112"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                                title="Course"
                              >
                                <span>
                                  Course <i className="fal fa-angle-down"></i>
                                </span>
                              </button>
                              <form className="dropdown-menu remove-list-style">
                                <ul aria-labelledby="dropdownMenu112">
                                  <div className="dropdown-filter-pagination">
                                    {virtualDropdown && virtualDropdown.course && virtualDropdown.course.length ? (
                                      <FilterComponent
                                        value={value}
                                        onChange={onChange}
                                        dataResponse={virtualDropdown.course}
                                        backendResponse={setCourseIds}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </ul>
                              </form>
                            </div>
                          </div>
                        </div>
                        {/* End of Course filter */}

                        {/* filter on Type start */}
                        <Tablefilter
                           filterName="Course Type"
                           optionArr={virtualDropdown?.course_type}
                           state={courseType}
                           setState={setCourseType}
                           renderLabelFunction={RenderCourseType}
                        />  
                    </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>

                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "In Progress Students");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "In Progress Students");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "In Progress Students");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>

        <DataTable
          paginationDefaultPage={page}
          data={dashboardList}
          columns={columns}
          pagination={true}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          defaultSortAsc={false}
          defaultSortField="start"
          onSort={handleSort}
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          sortServer
        />
      </div>
      {/* added by yash notification popup to send and listing start */}

      { showCkEditor && <div
        className="topic-add-modal modal fade zindexadd show"
        style={{ display: "block" }}
        id="notifications1234"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content modal-border-update">
              <form onSubmit={formik.handleSubmit}>
                <div className="modal-body p-0">
             
                  <div className="modal-header modal-header-custom">
                    <h5 className="modal-title">
                      <i className="fal fa-bell"></i> Quick Notifications
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        formik.resetForm()
                        $("#notifications").modal("hide");
                        setEditorState("");
                        setShowCkEditor(false);
                        setSelectedFile("");
                        setSelectedFileName("");
                        formik.setFieldValue("attachment","")
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>

                  </div>
                  <div className="t p-30px">
                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Class Name</label>
                          <input
                              type="text"
                              className={"form-control form-control-aatch-file"}
                              disabled
                              title="Class Name"
                              value={formik.values.class_name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Subject *</label>
                          <input
                            type="text"
                            title="Subject"
                            name="subject"
                            className={
                              "form-control form-control-aatch-file" +
                              (formik.errors.subject && formik.touched.subject
                                ? " is-invalid"
                                : "")
                            }
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          
                          />
                          
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Content *</label>
                          <HtmlInputEditor
                            editorState={editorState}
                            setEditorState={setEditorState}
                            hideSign={true}
                            isCKEditor={true}
                            isInvalid={
                              formik.touched.content &&
                              formik.errors.content
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Attachment *</label>
                          <div className="form-group atttach-file m-0 d-flex align-items-center">
                          <label
                            className={
                              formik.errors.attachment &&
                              formik.touched.attachment &&
                              "file-req is-invalid"
                            }
                          >
                            <i className="fal fa-paperclip"></i>
                            <span>Add Attachment</span>
                            <input
                              type="file"
                              className="form-control  form-control-aatch-file"
                              onChange={(e) => {
                                if (e.target.files.length) {
                                  formik.setFieldValue(
                                    "attachment",
                                    e.target.files[0]
                                  );
                                  setSelectedFile(URL.createObjectURL(e.target.files[0]));
                                  setSelectedFileName(e.target.files[0].name)
                                }
                              }}
                              placeholder="File"
                            />
                          </label>
                          </div>
                          {selectedFileName ? (
                            <Link
                              className="mt-1 d-flex"
                              to={{ pathname: selectedFile }}
                              target="_blank"
                            >
                              {selectedFileName}
                            </Link>
                          ) :null}
                        </div>

                      </div>

                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel mt-3">
                    <PermissionsGate
                      scopes={["cedit"]}
                      errorProps={{ disabled: true }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={disabled}
                      >
                        {disabled ? (
                          <i className="fas fa-cog fa-spin"></i>
                        ) : (
                          <i className="fal fa-check"></i>
                        )}
                        Send
                      </button>
                    </PermissionsGate>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={() => {
                        cancelQuickNotification();
                      }}
                    >
                      <i className="fal fa-times"></i>
                      Close
                    </button>
                    {Object.keys(formik.values).map(key => {
                      if (formik.touched[key] && formik.errors[key]) {
                        return (
                          <div className="invalid-feedback d-block" key={key}>
                            {formik.errors[key]}
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
                </div>
              </form>
          </div>
        </div>
      </div>}

      {/* added by yash notification popup to send and listing end */}

      {/* added by ign-off popup to send and listing start */}

      <div
        className="topic-add-modal modal fade"
        id="sign-off"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                <i className="fal fa-comment"></i> <label>Requires Comment</label>
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="t p-30px">
                <div className="topic-add-modal-content">
                  <div className="row center-label">
                    <div className="col-md-12">
                      <div className="form-group-blk mb-3">
                          <label>Comment *</label>
                          <textarea
                            type="text"
                            rows={10}
                            className="form-control form-control-aatch-file"
                            value={notificationFields.subject}
                            onChange={(e) => {
                              setNotificationFields({...notificationFields, ["subject"]: e.target.value });
                            }}
                          />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-3">
                <button
                  type="button"
                  className="btn btn-save btn-success"
                  onClick={() => {
                    approveSend();
                    setValidation(true);
                  }}
                >
                  Send
                </button>

                <button
                  type="button"
                  className="btn btn-close btn-danger"
                  onClick={() => {
                    declineSend();
                    setValidation(true);
                  }}
                >
                  Decline
                </button>
                {!notificationFields.subject.length && validation && (
                  <div className="invalid-feedback d-block">
                    Feedback is required
                  </div>
                )}
                </div>
              </div>

             
            </div>
          </div>
        </div>
      </div>

      {/* added by sign-off popup to send and listing end */}
    </>
  )
}

export default InProgressSLPStudents