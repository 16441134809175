import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { Link, useHistory } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import {
  GetAllDropdownValues,
  reRegistrationList,
  reRegistrationDelete,
  GetReRegistrationListCancelToken,
  GetAllDropdownValuesCancelToken,
} from "../../../services/RegistrationService";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import Tablefilter from "../../common/Tablefilter";
import { RenderSchool } from "../../../utils/CommonGroupingItem";
import { RenderAssessmentStatus } from "../../../utils/CommonStatusItems";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import axios from "axios";
import { handleTableScroll } from "../../../utils/commonFunction";

const ListReRegistration = () => {

  const history = useHistory();

  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);

  const [intakeArr, setIntakeArr] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [deleteRow, setDeleteRow] = useState(false);
  const [dropdownValues, setDropdownValues] = useState(
    useSelector((state) => state.registerDropdownValues)
  );
  const [schoolArr, setSchoolArr] = useState([]);
  const [searchSchoolCheck, setSearchSchoolCheck] = useState({});
  const [intake, setIntake] = useState({ arr: [], checkObj: {} })
  const [status, setStatus] = useState({ arr: [], checkObj: {} })
  const [school, setSchool] = useState({ arr: [], checkObj: {} })

  useEffect(() => {
    handleTableScroll()
  }, [loading])
  useEffect(() => {
    const cancelTokenSources = [];

    const getData = async () => {
      setLoading(true)
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetReRegistrationListCancelToken(source.token);
        setStudentList(res?.data?.res ? res?.data?.res : []);
        if (res.status === 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
    getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [deleteRow]);
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateReregList"));
    if (storedFilters) {
      setStatus(storedFilters.status);
      setIntake(storedFilters.intake);
      setSchool(storedFilters.school);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      status,
      school,
      intake
    };
    localStorage.setItem("filterStateReregList", JSON.stringify(filterState));
  }, [  status, school, intake]);
  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetAllDropdownValuesCancelToken(dropdownSource.token);
        let tempIntakeArr = []
        res.data.intake_year && res.data.intake_year.length && res.data.intake_number && res.data.intake_number.length && res.data.intake_year.map(year => {
          res.data.intake_number.map(number => {
            tempIntakeArr.push({ label: `${year.label}-${number.label}`, value: `${year.value}/${number.value}` })
          })
        })
        setIntakeArr(tempIntakeArr);
        setSchoolArr(res.data.school_type);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [deleteRow]);

  const getData = () => {
    setLoading(true)
    GetAllDropdownValues().then((res) => {
      let tempIntakeArr = []
      res.data.intake_year && res.data.intake_year.length && res.data.intake_number && res.data.intake_number.length && res.data.intake_year.map(year => {
        res.data.intake_number.map(number => {
          tempIntakeArr.push({ label: `${year.label}-${number.label}`, value: `${year.value}/${number.value}` })
        })
      })
      setIntakeArr(tempIntakeArr);
      setSchoolArr(res.data.school_type);
    });

    reRegistrationList()
      .then((res) => {
        setStudentList(res?.data?.res ? res?.data?.res : []);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error =-=--> ", error);
        setLoading(false);
      });
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = studentList;
    if (search.length) {
      let tempFirstname = allData.filter((item) => {
        let includes =
          item.academy_type &&
          item.academy_type.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempLastname = allData.filter((item) => {
        let includes =
          item.description &&
          item.description.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempEmail = allData.filter((item) => {
        let includes =
          item.intake_year.toString().toLowerCase() &&
          item.intake_year
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentId = allData.filter((item) => {
        let includes =
          item.start_date &&
          moment
            .unix(item.start_date)
            .format(TABLE_DATE_FORMAT)
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSchool = allData.filter((item) => {
        let includes =
          item.end_date &&
          moment
            .unix(item.end_date)
            .format(TABLE_DATE_FORMAT)
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStatus = allData.filter((item) => {
        let includes =
          item.reg_status &&
          item.reg_status.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        ...tempFirstname,
        ...tempLastname,
        ...tempEmail,
        ...tempStudentId,
        ...tempSchool,
        ...tempStatus,
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (school.arr.length) {
      let tempRole = updatedData;
      let tempSearch = tempRole.filter((item) => {
        const startsWith = school.arr.find(function (user, index) {
          if (
            user.toLowerCase() ===
            (item.academy_type ? item.academy_type.toLowerCase() : "")
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempSearch;
    }

    if (intake.arr.length) {
      let tempStatus = updatedData;
      let tempIntakeYear = tempStatus.filter((item) => {
        const startsIntakeYear = intake.arr.find(function (post, index) {
          if (post === (item.intake_year && item.intake_number ? item.intake_year + "/" + item.intake_number : "")) return item;
        });
        if (startsIntakeYear) {
          return startsIntakeYear;
        } else return null;
      });

      updatedData = tempIntakeYear;
    }

    if (status.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.arr.find(function (post, index) {
          let status = item.reg_status ? item.reg_status.toLowerCase() : "";
          if (post.toLowerCase() === status) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }
    if (
      search.length ||
      school.arr.length ||
      status.arr.length ||
      intake.arr.length
    ) {
      return updatedData;
    } else {
      return studentList;
    }
  };

  function getValue(arrName, label) {
    let labelName = "";
    for (let item of dropdownValues[arrName]) {
      if (item.value.toString() == label.toString()) {
        labelName = item.label;
        break;
      }
    }

    if (labelName === "" && arrName === "re_registration_status") return label;
    return labelName;
  }

  const sortingIntake = (rowA, rowB) => {
    const intake1 = (rowA?.intake_year && rowA.intake_number)
    ? rowA.intake_year + "-" + rowA.intake_number
    : "";
    const intake2 = (rowB?.intake_year && rowB.intake_number)
    ? rowB.intake_year + "-" + rowB.intake_number
    : "";        
    return intake1.localeCompare(intake2);
  };
  const sortingDescription = (rowA, rowB) => {
    const name1 = rowA?.description ? rowA.description : "";
    const name2 = rowB?.description ? rowB.description : "";        
    return name1.localeCompare(name2);
  };
  const columns = useMemo(() => [
    {
      name: "Description",
      selector: "description",
      sortable: true,
      sortFunction:sortingDescription,
      cell: (row) => (
        <Link
          className="as-text-blue curser feature-name"
          title={row.description}
          to={`/courseAdministration/registration/open-re-registration/open/${row.id}?description=${row.description}`}
        >
          <span className="textLimit100">{row.description}</span>
        </Link>
      ),
    },
    {
      name: "School",
      selector: "academy_type",
      sortable: true,
      cell: (row) => row.academy_type ? <span className="feature-name"><span className="textLimit100">{RenderSchool(row.academy_type).html} </span></span> : "-"
    },
    {
      name: "Intake",
      selector: "intake_year",
      sortable: true,
      sortFunction:sortingIntake,
      cell: (row) => row.intake_year + "-" + row.intake_number,
    },
    {
      name: "Start",
      selector: "start_date",
      sortable: true,
      cell: (row) => (
        <>
          {row.start_date ? (
            <div className="">
              <p className="right-space">
                {row.start_date.toString().length == 10
                  ? moment.unix(row.start_date).format(TABLE_DATE_FORMAT)
                  : moment(parseInt(`${row.start_date}`)).format(TABLE_DATE_FORMAT)}
              </p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "End",
      selector: "end_date",
      sortable: true,
      cell: (row) => (
        <>
          {row.end_date ? (
            <div className="">
              <p className="right-space">
                {row.end_date.toString().length == 10
                  ? moment.unix(row.end_date).format(TABLE_DATE_FORMAT)
                  : moment(parseInt(`${row.end_date}`)).format(TABLE_DATE_FORMAT)}
              </p>              
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },

    {
      name: "Status",
      selector: "reg_status",
      sortable: true,
      cell: (row) => row.reg_status ? RenderAssessmentStatus(row.reg_status).html : "-",
    },

    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08  btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              to={`/courseAdministration/registration/open-re-registration/open/${row.id}?description=${row.description}`}
              title="Open"
              className="btn btn-primary rounded-circle"
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            <button
              title="Delete"
              className="btn btn-danger rounded-circle"
              onClick={() => handleDelete(row.id)}
            >
              <i className="fal fa-trash-alt"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          reRegistrationDelete(ID).then((res) => {
            setDeleteRow(!deleteRow);
          });

          Swal.fire("Deleted!", "Re-Registration has been deleted.", "success");
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        setDeleteRow(!deleteRow);
        history.push("/administration/brandSetting/list");
      });
  };

  function resetFilter() {
    setSearch("");
    setSchool({ arr: [], checkObj: {} })
    setIntake({ arr: [], checkObj: {} })
    setStatus({ arr: [], checkObj: {} })
  }

  const handleFilters = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = [
      "Description",
      "School",
      "Intake",
      "Start",
      "End",
      "Status",
    ];

    data = data?.map((row) => ({
      ...row,
      School: row.academy_type ? getValue("academy", row.academy_type) : "-",
      Description: row?.description ? row?.description : "-",
      Intake: row.intake_year
        ? row.intake_year + "-" + row.intake_number
        : "-",
      "Start": row.start_date ? moment.unix(row.start_date).format(TABLE_DATE_FORMAT): "-",
      "End": row.end_date ? moment.unix(row.end_date).format(TABLE_DATE_FORMAT) : "-",
      Status: row.reg_status ? row.reg_status : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      setTimeout(() => {
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Description"],
          row["School"],
          row["Intake"],
          row["Start"],
          row["End"],
          row["Status"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">

        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={(e) => handleFilters(e)}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName={"Status"}
                      optionArr={
                        [
                          { value: "Open", label: "Open" },
                          { value: "Closed", label: "Closed" },
                        ]
                      }
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={RenderAssessmentStatus}
                    />
                    <Tablefilter
                      filterName={"School"}
                      optionArr={schoolArr}
                      state={school}
                      setState={setSchool}
                      renderLabelFunction={RenderSchool}
                    />
                    <Tablefilter
                      filterName={"Intake"}
                      optionArr={intakeArr}
                      state={intake}
                      setState={setIntake}
                      isOptionReversed={true}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      title="Reset"
                      onClick={resetFilter}
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Registration List");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Registration List");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Registration List");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <Link
                  to={`/courseAdministration/registration/open-re-registration/add`}
                >
                  <button className="btn btn-primary" title="Create New">
                    <i className="fal fa-plus"></i>Create New
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {loading === true ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            data={dataToRender()}
            defaultSortField={"start_date"}
            defaultSortAsc={false}
            columns={columns}
            pagination={true}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        )}
      </div>
    </div>
  );
};

export default ListReRegistration;
