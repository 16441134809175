import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import Select from "react-select";
import "jspdf-autotable";
import { GetAllDropdownValues, AddOrUpdateReregistration, reRegistrationDetails, GetAllDropdownValuesCancelToken } from "../../../services/RegistrationService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FORM_DATE_FORMAT } from "../../../utils/Constants";
import { useHistory } from "react-router-dom";
import { AddOrUpdateArticulationReregistration, articulationDetails } from "../../../services/programArticulationService";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../utils/permissionGate";
import axios from "axios";


var school = [];

const OpenArticulationRegistration = () => {
  const { page, tab, id } = useParams();
  const history = useHistory();
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [registrationDetail, setRegistrationDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const [schoolType, setSchoolType] = useState([]);
  const [intakeArr, setIntakeArr] = useState([]);
  const [disabled, setDisabled] = useState(false)
  const [articulationStatus, setArticulationStatus] = useState(false)
  const [studyTypeDropdown, setStudyTypeDropdown] = useState([])
  const [datesValues, setDatesValues] = useState({
    start_date: new Date(),
    end_date: new Date(),
    early_bird_end_date: new Date()
  })

  let registrationStatus = [
    { value: "Open", label: "Open" },
    { value: "Closed", label: "Closed" },
  ]

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await GetAllDropdownValuesCancelToken(dropdownSource.token);
        if(res.status==200){
          setSchoolType(res?.data?.school_type);
          setStudyTypeDropdown(res.data.study_type);
          school = res?.data?.school_type;
          let tempIntakeArr = []
          res.data.intake_year.map(year => {
            res.data.intake_number.map(number => {
                tempIntakeArr.push({ value: `${year.value}/${number.label}`, label: `${year.value}-${number.label}` })
            })
          })
          setIntakeArr(tempIntakeArr)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    getFilters();
    return () => {
      dropdownSource.cancel('Component unmounted');    
    };
  }, []);

  useEffect(() => {
    if (!(page == "open-articulation-registration" && tab == "open")) {
      let response = hasPermission({
        scopes: ["cparadd"],
        permissions: givenPermsisions,
      });
      if (!response) {
        history.push("/noaccess");
      }
    }
  }, [])

  useEffect(()=>{
    const detailSource = axios.CancelToken.source();
    const getData = async () => {
      try {
        if (page == "open-articulation-registration" && tab == "open") {
          const res = await articulationDetails(id, detailSource.token);
          if(res.status==200){
            setRegistrationDetail(res?.data?.data);
            setArticulationStatus(res?.data?.data?.status)
            // modalFormik.setFieldValue("item_desc", res?.data?.data?.description);
            // modalFormik.setFieldValue("item_status", res?.data?.data?.status);
            setDatesValues({
              start_date: new Date(moment.unix(res?.data?.data?.start_date.length > 10 ? res?.data?.data?.start_date / 1000 : res?.data?.data?.start_date)),
              end_date: new Date(moment.unix(res?.data?.data?.end_date.length > 10 ? res?.data?.data?.end_date / 1000000 : res?.data?.data?.end_date)),
              hard_launch_date: new Date(moment.unix(res?.data?.data?.hard_launch_date))
            })
            // modalFormik.setFieldValue("start_date", new Date(moment.unix(res?.data?.data?.start_date.length > 10 ? res?.data?.data?.start_date / 1000 : res?.data?.data?.start_date)));
            // modalFormik.setFieldValue("end_date", new Date(moment.unix(res?.data?.data?.end_date.length > 10 ? res?.data?.data?.end_date / 1000000 : res?.data?.data?.end_date)));
            if(res?.data?.data?.hard_launch_date){
              // modalFormik.setFieldValue("hard_launch_date", new Date(moment.unix(res?.data?.data?.hard_launch_date)));
            }
          }          
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    getData();
    return () => {
      detailSource.cancel('Component unmounted');    
    };
  },[])
  
  const modalFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      item_status: registrationDetail && registrationDetail.status ? registrationDetail.status : "",
      item_desc: registrationDetail && registrationDetail.description ? registrationDetail.description : "",
      correspondence: registrationDetail.correspondence ? registrationDetail.correspondence : "",
      start_date: registrationDetail && registrationDetail.start_date ? new Date(moment.unix(registrationDetail?.start_date.length > 10 ? registrationDetail?.start_date / 1000 : registrationDetail?.start_date)) : "",
      end_date: registrationDetail && registrationDetail.end_date ? new Date(moment.unix(registrationDetail?.end_date.length > 10 ? registrationDetail?.end_date / 1000000 : registrationDetail?.end_date)) : "",
      intake: registrationDetail && registrationDetail?.intake_year && registrationDetail?.intake_number ? `${registrationDetail?.intake_year}/${registrationDetail?.intake_number}` : "",
      hard_launch_date: registrationDetail && registrationDetail.hard_launch_date ? new Date(moment.unix(registrationDetail?.hard_launch_date)) : "",
      assignedArr: [],
    },
    validationSchema: Yup.object({
      item_status: Yup.string().required("Articulation Registration status should be required"),
      item_desc: Yup.string().required("Description is required").trim(),
      correspondence: Yup.string().required("Should select Learning Method"),
      start_date: Yup.string().required("Start Date is required"),
      end_date: Yup.string().required("End Date is required"),
      intake: Yup.string().required("Intake is required"),
      hard_launch_date: Yup.string().required("Hard launch date is required"),
    }),
    onSubmit: (values) => {
      let tempFullStartDate = new Date(values.start_date);      
      let tempFullHardDate = new Date(values.hard_launch_date);      
      if (tempFullStartDate > tempFullHardDate) {
        modalFormik.setFieldError(
          "hard_launch_date",
          "Hard launch date should precede start date"
        );
        return;
      }
      setLoading(true);
      setDisabled(true);
      let formData = new FormData();
      formData.append("status", values.item_status);
      formData.append("start_date", new Date(values.start_date).getTime() / 1000);
      formData.append("end_date", new Date(values.end_date).getTime() / 1000);
      formData.append("intake_year", values.intake.split('/')[0]);
      formData.append("intake_number", values.intake.split('/')[1]);
      formData.append("description", values.item_desc);
      formData.append("correspondence", values.correspondence);
      formData.append("hard_launch_date", new Date(values.hard_launch_date).getTime() / 1000);
      id && formData.append("id", id);

      AddOrUpdateArticulationReregistration(formData)
        .then((res) => {
          Swal.fire({ icon: "success", title: "Success", text: `${res?.data?.message}` }).then(()=>{
            if(tab=='add'){
              history.push(`/courseAdministration/articulation/open-articulation-registration/open/${res.data.insert_Id}?description=${values.item_desc}`)
            }
            setDisabled(false);
            setLoading(false);
          });
        })
        .catch((err) => {
          Swal.fire({ icon: "error", title: "Error", text: `${err?.response?.data?.message}` });
          setDisabled(false);
          setLoading(false);
        });
    },
  });
  const handleReset = (resetForm) => {
    resetForm();
  };

  return (
    <>
      <div className="tabs-wrap">
        <div className="card card-profile-info-card mb-30 filter-table-bg">
          <div className="card-body">
            <div className="card-body-inr card-body-info"></div>
            <form onSubmit={modalFormik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-3" title="Articulation Registration Status">
                    <label>Articulation Registration Status *</label>
                    <Select
                      
                      className={"form-control custom-select-box " + (modalFormik.errors.item_status && modalFormik.touched.item_status ? " is-invalid" : "")}
                      name="item_status"
                      value={registrationStatus.filter((val) => {
                        return val.value == modalFormik.values.item_status
                      })}
                      onChange={(value) => {
                        if (value) {
                          modalFormik.setFieldValue("item_status", value.value);
                        } else {
                          modalFormik.setFieldValue("item_status", "");
                        }
                      }}
                      onBlur={modalFormik.handleBlur}
                      options={registrationStatus}
                      maxMenuHeight={175}
                      placeholder={modalFormik.values.item_status ? modalFormik.values.item_status : "Select Type"}
                      isClearable
                    />
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-3" title="Description">
                    <label>Description *</label>

                    <input
                      type="text"
                      className={"form-control " + (modalFormik.errors.item_desc && modalFormik.touched.item_desc ? " is-invalid" : "")}
                      placeholder="Enter Description"
                      name="item_desc"
                      value={modalFormik.values.item_desc}
                      onChange={modalFormik.handleChange}
                      onBlur={modalFormik.handleBlur}
                    />
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                <div className="form-group-blk mb-3" title="Learning Method">
                    <label>Learning Method *</label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (modalFormik.errors.correspondence &&
                          modalFormik.touched.correspondence
                          ? " is-invalid"
                          : "")
                      }
                      name="correspondence"
                      // value={formik.values.correspondence || ""}
                      value={studyTypeDropdown.filter(function (option) {
                        return option.value == modalFormik.values.correspondence;
                      })}
                      onChange={(value) => {
                        if(value){
                          modalFormik.setFieldValue("correspondence", value.value);
                          modalFormik.setFieldValue("correspondenceName", value.key);
                        } else {
                          modalFormik.setFieldValue("correspondence", "");
                          modalFormik.setFieldValue("correspondenceName", "");
                        } 
                      }}
                      isClearable
                      onBlur={modalFormik.handleBlur}
                      options={studyTypeDropdown}
                      maxMenuHeight={175}
                      placeholder={
                        modalFormik.values.correspondence
                          ? modalFormik.values.correspondence
                          : "Learning Method"
                      }
                    />
                    {modalFormik.errors.correspondence &&
                      modalFormik.touched.correspondence ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-3" title="Intake">
                    <label>Intake *</label>
                    <Select
                      className={"form-control custom-select-box " + (modalFormik.errors.intake && modalFormik.touched.intake ? " is-invalid" : "")}
                      name="intake"
                      value={intakeArr.filter((val) => {
                        return val.value == modalFormik.values.intake
                      })}
                      onChange={(value) => {
                        if (value) {
                          modalFormik.setFieldValue("intake", value.value);
                        } else {
                          modalFormik.setFieldValue("intake", "");
                        }
                      }}
                      onBlur={modalFormik.handleBlur}
                      options={intakeArr}
                      maxMenuHeight={175}
                      placeholder={modalFormik.values.intake ? modalFormik.values.intake : "Select Intake"}
                      isClearable
                    />
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-3" title="Start Date">
                    <label>Start Date *</label>
                    <DatePicker
                      selected={modalFormik.values.start_date}
                      onChange={(date) => {
                        modalFormik.setFieldValue("start_date", date);
                      }}
                      dateFormat={FORM_DATE_FORMAT}
                      className={"form-control w-100 " + (modalFormik.errors.start_date && modalFormik.touched.start_date ? " is-invalid" : "")}
                      onChangeRaw={(e) => e.preventDefault()}
                      minDate={new Date()}
                      placeholderText="Select Start Date"
                      showYearDropdown
                      scrollableYearDropdown
                      dateFormatCalendar="MMMM"
                    />
                  </div>
                </div>

                <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-3" title="End Date">
                    <label>End Date *</label>
                    <DatePicker
                      autoComplete="off"
                      disabled={!modalFormik.values.start_date}
                      selected={modalFormik.values.end_date}
                      showYearDropdown
                      scrollableYearDropdown
                      dateFormatCalendar="MMMM"
                      onChange={(date) => {
                        modalFormik.setFieldValue("end_date", date);
                      }}
                      dateFormat={FORM_DATE_FORMAT}
                      className={"form-control w-100 " + (modalFormik.errors.end_date && modalFormik.touched.end_date ? " is-invalid" : "")}
                      onChangeRaw={(e) => e.preventDefault()}
                      minDate={new Date(modalFormik.values.start_date).setDate(new Date(modalFormik.values.start_date).getDate() + 1)}
                      placeholderText="Select End Date"

                    />
                  </div>
                </div>
               
                <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-3" title="Hard Launch Date">
                    <label>Hard Launch Date *</label>
                    <DatePicker
                      selected={modalFormik.values.hard_launch_date}
                      onChange={(date) => {
                        modalFormik.setFieldValue("hard_launch_date", date);
                      }}
                      dateFormat={FORM_DATE_FORMAT}
                      className={"form-control w-100 " + (modalFormik.errors.hard_launch_date && modalFormik.touched.hard_launch_date ? " is-invalid" : "")}
                      onChangeRaw={(e) => e.preventDefault()}                      
                      placeholderText="Select Hard Launch Date"
                      minDate={new Date(modalFormik.values.start_date)}
                      showYearDropdown
                      scrollableYearDropdown
                      dateFormatCalendar="MMMM"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group form-group-save-cancel mt-4">
                <PermissionsGate
                  scopes={[id ? "cparedit" : "cparadd"]}
                  errorProps={{disabled: true}}
                >
                <button className="btn btn-save btn-success" type="button" title="Save" onClick={modalFormik.handleSubmit} disabled={!modalFormik.dirty}>
                  {loading === false ? <i className="fal fa-save"></i> : <i className="fas fa-cog fa-spin"></i>}Save
                </button>
                </PermissionsGate>

                <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => {
                  handleReset(modalFormik.resetForm)
                  if(id){
                    modalFormik.setFieldValue("start_date", datesValues.start_date)
                    modalFormik.setFieldValue("end_date", datesValues.end_date)
                    modalFormik.setFieldValue("hard_launch_date", datesValues.hard_launch_date);
                    modalFormik.setFieldValue("item_status", articulationStatus);
                  }  
                }}>
                  <i className="fal fa-times"></i>Cancel
                </button>
              </div>
              {Object.keys(modalFormik.values).map(key => {
                if (modalFormik.touched[key] && modalFormik.errors[key]) {
                  return (
                    <div className="invalid-feedback d-block">
                      {modalFormik.errors[key]}
                    </div>
                  )
                }
              })}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpenArticulationRegistration;
