import React from 'react'
import { GetGrademixAuditTrialCancelToken, GetGrademixAuditTrialDropdownCancelToken } from '../../../services/ProgrammeServices';
import AuditTrailsTable from '../../common/AuditTrailsTable';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { RenderCertificateType } from '../../../utils/CommonGroupingItem';

function GradeMixAuditTrail() {

  const { id, subId } = useParams()

  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]

  const handleRedirect = (id, type, otherData) => {
    switch (type) {
      case "Programme Gradebook Setting":
        return `/courseAdministration/Programmes/programme/open/${id}/grade/table`
    
      default:
        return `/courseAdministration/Programmes/programme/open/${id}/details`
    }
  }
 
  return (
    <AuditTrailsTable
      apiFunction={GetGrademixAuditTrialCancelToken}
      apiParams={{parent_action_id: id, action_id: subId}}
      columnsToShow={columnsToShow}
      filterArrApi={GetGrademixAuditTrialDropdownCancelToken}
      actionId={subId}
      exportFileName={"Programme_auditTrail"}
      resourceNameRedirect={handleRedirect}
      isResourceNameShown={true}
      isShowingResourceTypeFilter
      renderResourceTypeFunction={RenderCertificateType}
    />
  )
}

export default GradeMixAuditTrail