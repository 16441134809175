import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import {
  GetStudentDropdown,
  UpdateStudentPersonalInfo,
} from "../../../../../services/StudentsSettingServices";
import Swal from "sweetalert2";
import { myCompare } from "../../../../common/MyCompare";
import PermissionsGate from "../../../../../utils/permissionGate";
function AddressBlock({ studentData, loading }) {
  const { id } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [province, setProvince] = useState([]);
  const [country, setCountry] = useState([]);

  const fixDataFormet = (obj) => {
    let tempArr = [];
    Object.keys(obj).map((item) => {
      tempArr.push({ value: item, label: obj[item] });
    });
    return tempArr;
  };

  useEffect(() => {
    GetStudentDropdown().then((res) => {
      setProvince(fixDataFormet(res.data.province));
      setCountry(fixDataFormet(res.data.country));
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      StreetAddress: studentData && studentData.ph_street !== null ? studentData.ph_street : "",
      City: studentData && studentData.ph_city !== null ? studentData.ph_city : "",
      Province: studentData && studentData.ph_province !==null ? studentData.ph_province : "",
      Country: studentData && studentData.ph_country !==null ? country?.find(item=>item?.label === studentData?.ph_country)?.label : "",
      PostalCode: studentData && studentData.ph_zip !== null ? studentData.ph_zip : "",
      PostalStreetAddress: studentData && studentData.post_street !== null ? studentData.post_street : "",
      PostalCity: studentData && studentData.post_city !== null ? studentData.post_city : "",
      PostalProvince: studentData && studentData.post_province !== null ? studentData.post_province : "",
      PostalCountry: studentData && studentData.post_country !== null ? studentData.post_country : "",
      PostalCodePostal: studentData && studentData.post_zip !== null ? studentData.post_zip : "",
    },
    validationSchema: Yup.object({
      StreetAddress: Yup.string().required("Street Address is required").trim(),
      City: Yup.string().required("City is required").trim(),
      Province: Yup.string().required("Province is required").trim(),
      Country: Yup.string().required("Country is required").trim(),
      PostalCode: Yup.string().required("Postal Code is required").trim().test(
        'Is positive?', 
        'Postal Code Must Be Positve Number', 
        (value) => value > 0
      ).min(5,"Please Enter Minimum 5 Digit").max(10, "Please Enter Maximum 10 Digit"),
      PostalStreetAddress: Yup.string()
        .required("Postal Street Address is required")
        .trim(),
      PostalCity: Yup.string().required("Postal City is required").trim(),
      PostalProvince: Yup.string()
        .required("Postal Province is required")
        .trim(),
      PostalCountry: Yup.string().required("Postal Country is required").trim(),
      PostalCodePostal: Yup.string().required("Postal Code is required").trim().test(
        'Is positive?', 
        'Postal Code Must Be Positve Number', 
        (value) => value > 0
      ).min(5,"Please Enter Minimum 5 Digit").max(10, "Please Enter Maximum 10 Digit"),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let formData = new FormData();
      formData.append("section_type", "address_info");
      formData.append("student_id", id);
      formData.append("ph_street", values.StreetAddress);
      formData.append("ph_city", values.City);
      formData.append("ph_province", values.Province);
      formData.append("ph_country", values.Country);
      formData.append("ph_zip", values.PostalCode);
      formData.append("post_street", values.PostalStreetAddress);
      formData.append("post_city", values.PostalCity);
      formData.append("post_province", values.PostalProvince);
      formData.append("post_country", values.PostalCountry);
      formData.append("post_zip", values.PostalCodePostal);
      UpdateStudentPersonalInfo(formData)
        .then((res) => {
          setDisabled(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
        })
        .catch((err) => {
          setDisabled(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
          });
        });
    },
  });

  const handleSameAsHome = async () => {
    await formik.setFieldValue(
      "PostalStreetAddress",
      formik.values.StreetAddress
    );
    await formik.setFieldValue("PostalCity", formik.values.City);
    await formik.setFieldValue("PostalProvince", formik.values.Province);
    await formik.setFieldValue("PostalCountry", formik.values.Country);
    await formik.setFieldValue("PostalCodePostal", formik.values.PostalCode);
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Address</div>
        {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <div className="edit-icon new-card-header">
        <div className="card-subheading">Home</div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="StreetAddress">Street Address *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="StreetAddress"
                className={
                  "form-control" +
                  (formik.errors.StreetAddress && formik.touched.StreetAddress
                    ? " is-invalid"
                    : "")
                }
                name="StreetAddress"
                placeholder="Enter Street Address"
                value={formik.values.StreetAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Street Address"
              />
              {formik.errors.StreetAddress && formik.touched.StreetAddress ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="City">City *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="City"
                className={
                  "form-control" +
                  (formik.errors.City && formik.touched.City
                    ? " is-invalid"
                    : "")
                }
                name="City"
                placeholder="Enter City"
                value={formik.values.City}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="City"
              />
              {formik.errors.City && formik.touched.City ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Province">Province *</label>
            <div className="form-icon-group mb-4" title="Province">
              <Select
                className={
                  "form-control custom-select-box " +
                  (formik.errors.Province && formik.touched.Province
                    ? " is-invalid"
                    : "")
                }
                name="Province"
                value={province.filter((item) => {
                  return item.value == formik.values.Province;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("Province", value.value);
                  } else {
                    formik.setFieldValue("Province", "");
                  }                  
                }}
                onBlur={formik.handleBlur}
                options={province.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={
                  formik.values.Province ? formik.values.Province : "Select"
                }
                isClearable
              />
              {formik.errors.Province && formik.touched.Province ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Country">Country *</label>
            <div className="form-icon-group mb-4" title="Country">
              <Select
                className={
                  "form-control custom-select-box " +
                  (formik.errors.Country && formik.touched.Country
                    ? " is-invalid"
                    : "")
                }
                name="Country"
                value={country.filter((item) => {
                  return item.value == formik.values.Country;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("Country", value.value);
                  } else {
                    formik.setFieldValue("Country", "");
                  }
                }}
                onBlur={formik.handleBlur}
                options={country.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={
                  formik.values.Country ? formik.values.Country : "Select"
                }
                isClearable
              />
              {formik.errors.Country && formik.touched.Country ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="PostalCode">Postal Code *</label>
            <div className="form-icon-group mb-4">
              <input
                type="number"
                id="PostalCode"
                className={
                  "form-control" +
                  (formik.errors.PostalCode && formik.touched.PostalCode
                    ? " is-invalid"
                    : "")
                }
                name="PostalCode"
                placeholder="Enter Postal Code"
                value={formik.values.PostalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Postal Code"
              />
              {formik.errors.PostalCode && formik.touched.PostalCode ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div>
        
        <div className=" new-card-header">
          <div className="card-subheading">Postal Address</div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label>&nbsp;</label>
              <div className="form-group form-group-save-cancel">
                <button
                  className="btn btn-save btn-success"
                  type="button"
                  title="Same As Home"
                  onClick={() => handleSameAsHome()}
                >
                  <i className="fal fa-copy"></i>
                  Same As Home
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="PostalStreetAddress">Street Address *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="PostalStreetAddress"
                className={
                  "form-control" +
                  (formik.errors.PostalStreetAddress &&
                  formik.touched.PostalStreetAddress
                    ? " is-invalid"
                    : "")
                }
                name="PostalStreetAddress"
                placeholder="Enter Street Address"
                value={formik.values.PostalStreetAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Street Address"
              />
              {formik.errors.PostalStreetAddress &&
              formik.touched.PostalStreetAddress ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="PostalCity">City *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="PostalCity"
                className={
                  "form-control" +
                  (formik.errors.PostalCity && formik.touched.PostalCity
                    ? " is-invalid"
                    : "")
                }
                name="PostalCity"
                placeholder="Enter PostalCity"
                value={formik.values.PostalCity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="City"
              />
              {formik.errors.PostalCity && formik.touched.PostalCity ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4" title="Province">
            <label htmlFor="PostalProvince">Province *</label>
            <div className="form-icon-group mb-4">
              <Select
                className={
                  "form-control custom-select-box " +
                  (formik.errors.PostalProvince && formik.touched.PostalProvince
                    ? " is-invalid"
                    : "")
                }
                name="PostalProvince"
                value={province.filter((item) => {
                  return item.value == formik.values.PostalProvince;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("PostalProvince", value.value);
                  } else {
                    formik.setFieldValue("PostalProvince", "");
                  }                  
                }}
                onBlur={formik.handleBlur}
                options={province.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={
                  formik.values.PostalProvince
                    ? formik.values.PostalProvince
                    : "Select"
                }
                isClearable
              />
              {formik.errors.PostalProvince && formik.touched.PostalProvince ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4" title="Country">
            <label htmlFor="PostalCountry">Country *</label>
            <div className="form-icon-group mb-4">
              <Select
                className={
                  "form-control custom-select-box " +
                  (formik.errors.PostalCountry && formik.touched.PostalCountry
                    ? " is-invalid"
                    : "")
                }
                name="PostalCountry"
                value={country.filter((item) => {
                  return item.value == formik.values.PostalCountry;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("PostalCountry", value.value);
                  } else {
                    formik.setFieldValue("PostalCountry", "");
                  }                  
                }}
                onBlur={formik.handleBlur}
                options={country.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={
                  formik.values.PostalCountry
                    ? formik.values.PostalCountry
                    : "Select"
                }
                isClearable
              />
              {formik.errors.PostalCountry && formik.touched.PostalCountry ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="PostalCodePostal">Postal Code *</label>
            <div className="form-icon-group mb-4">
              <input
                type="number"
                id="PostalCodePostal"
                className={
                  "form-control" +
                  (formik.errors.PostalCodePostal &&
                  formik.touched.PostalCodePostal
                    ? " is-invalid"
                    : "")
                }
                name="PostalCodePostal"
                placeholder="Enter Postal Code"
                value={formik.values.PostalCodePostal}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Postal Code"
              />
              {formik.errors.PostalCodePostal &&
              formik.touched.PostalCodePostal ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group form-group-save-cancel">
          <PermissionsGate errorProps={{ disabled: true }} scopes={["spiedit"]}>
          <button
            className="btn btn-save btn-success"
            type="submit"
            title="Save"
            disabled={disabled || !formik.dirty || isLoaded}
          >
            {disabled ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-save"></i>
            )}
            Save
          </button>
          </PermissionsGate>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => formik.resetForm()}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>
          {Object.keys(formik.values).map(key => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block">
                  {formik.errors[key]}
                </div>
              )
            }
          })}
          {/* {formik.touched.StreetAddress && formik.errors.StreetAddress ? (
            <div className="invalid-feedback d-block">
              {formik.errors.StreetAddress}
            </div>
          ) : null}
          {formik.touched.City && formik.errors.City ? (
            <div className="invalid-feedback d-block">{formik.errors.City}</div>
          ) : null}
          {formik.touched.Province && formik.errors.Province ? (
            <div className="invalid-feedback d-block">
              {formik.errors.Province}
            </div>
          ) : null}
          {formik.touched.Country && formik.errors.Country ? (
            <div className="invalid-feedback d-block">
              {formik.errors.Country}
            </div>
          ) : null}
          {formik.touched.PostalCode && formik.errors.PostalCode ? (
            <div className="invalid-feedback d-block">
              {formik.errors.PostalCode}
            </div>
          ) : null}
          {formik.touched.PostalStreetAddress &&
          formik.errors.PostalStreetAddress ? (
            <div className="invalid-feedback d-block">
              {formik.errors.PostalStreetAddress}
            </div>
          ) : null}
          {formik.touched.PostalCity && formik.errors.PostalCity ? (
            <div className="invalid-feedback d-block">
              {formik.errors.PostalCity}
            </div>
          ) : null}
          {formik.touched.PostalProvince && formik.errors.PostalProvince ? (
            <div className="invalid-feedback d-block">
              {formik.errors.PostalProvince}
            </div>
          ) : null}
          {formik.touched.PostalCountry && formik.errors.PostalCountry ? (
            <div className="invalid-feedback d-block">
              {formik.errors.PostalCountry}
            </div>
          ) : null}
          {formik.touched.PostalCodePostal && formik.errors.PostalCodePostal ? (
            <div className="invalid-feedback d-block">
              {formik.errors.PostalCodePostal}
            </div>
          ) : null} */}
        </div>
      </form>
    </div>
  );
}

export default AddressBlock;
