import React, { useState, useEffect } from "react";
import { getEmailDetails } from "../../../services/EmailServices";
const EmailView = ({ email_id }) => {
  const [emailDetails, setEmailDetails] = useState([]);
  useEffect(() => {
    getEmailDetails(email_id)
      .then((res) => {
        setEmailDetails(res?.data?.details);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div>
      <div className="card card-body-inr">
        <div className="card card-body-inr">
          <div className="row">
            <div className="col-md-3">
              {" "}
              <label className="font-weight-bold" htmlFor="to mail">
                To Mail
              </label>
            </div>
            <div className="col-md-9">
              <p>{emailDetails?.to}</p>
            </div>
          </div>
        </div>
        <div className="card card-body-inr">
          <div className="row">
            <div className="col-md-3">
              {" "}
              <label className="font-weight-bold" htmlFor="sent">
                Sent
              </label>
            </div>
            <div className="col-md-9">
              <p>{emailDetails?.sent}</p>
            </div>
          </div>
        </div>
        <div className="card card-body-inr">
          <div className="row">
            <div className="col-md-3">
              {" "}
              <label className="font-weight-bold" htmlFor="subject">
                Subject
              </label>
            </div>
            <div className="col-md-9">
              <p>{emailDetails?.subject}</p>
            </div>
          </div>
        </div>
        <div className="card card-body-inr">
          <div className="row">
            <div className="col-md-3">
              {" "}
              <label className="font-weight-bold" htmlFor="body">
                Body
              </label>
            </div>
            <div className="col-md-9">
              <p>{emailDetails?.body}</p>
            </div>
          </div>
        </div>
        <div className="card pt-3 pl-3 pr-3 mb-3 card-body-inr">
          <div className="row">
            <div className="col-md-3">
              {" "}
              <label className="font-weight-bold" htmlFor="attachment">
                Attachment
              </label>
            </div>
            <div className="col-md-9">
              {emailDetails?.attachment ? (
                <img src={emailDetails?.attachment} alt="attachment" />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailView;
