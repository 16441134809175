import React, { useMemo, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
import moment from "moment";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../../utils/Constants";
import { InitialRender } from "../../../../common/Helper";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import Str from "../../../../common/Str";
import { AssessmentAuditTrail, getAuditTrailOfQuiz, getAuditTrailOfQuizCancelToken } from "../../../../../services/SmartAssessmentService";
import Hover from "../../../../common/Hover";
import { useSelector } from "react-redux";
import hasPermission from "../../../../../utils/hasMultiplePermission";
import generateArrayOfYears from "../../../../common/generateArrayOfYears"
import { TrimText } from "../../../../common/TrimText";
import { Link } from "react-router-dom";
import { RenderAuditTrailActionType } from "../../../../../utils/CommonGroupingItem";
import axios from "axios";
import { handleTableScroll } from "../../../../../utils/commonFunction";

const QuizAuditTrail = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");

  const [actiontype, setActionType] = useState([]);
  const [searchActionTypeCheck, setSearchActionTypeCheck] = useState({});

  const [month, setMonth] = useState([]);
  const [searchMonthCheck, setSearchMonthCheck] = useState({});

  const [year, setYear] = useState([]);
  const [searchYearCheck, setSearchYearCheck] = useState({});

  const [loading, setloading] = useState(true);

  const [data, setData] = useState([]);

  const givenPermsisions = useSelector((state) => state.givenPermission);
  const { tab, subtab, id } = useParams()

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    let response = hasPermission({ scopes: ["cquizmview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  useEffect(() => {
    const cancelTokenSources = [];

    const getData = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        if (tab == "open" && subtab == "audit") {
          const res = await getAuditTrailOfQuizCancelToken({ "action_id": id }, source.token);
          let tempArr = []
          res.data.getAuditTrial.map((item) => {
            tempArr.push({ ...item, description: JSON.parse(item.description) })
          })
          setData(tempArr);
          if (res.status === 200) {
            setloading(false);
          }
        } else {
          const res = await getAuditTrailOfQuizCancelToken({}, source.token);
          let tempArr = []
          res.data.getAuditTrial.map((item) => {
            tempArr.push({ ...item, description: JSON.parse(item.description) })
          })
          setData(tempArr);
          if (res.status === 200) {
            setloading(false);
          }
        }
       
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }
    }

    getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  const resetFilter = () => {
    setSearch("");
    setActionType([]);
    setSearchActionTypeCheck({});
    setMonth([]);
    setSearchMonthCheck({});
    setYear([]);
    setSearchYearCheck({});
  };

  const renderMonthUI = () => {
    let arr = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return arr.map((i, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            id={index}
            value={index}
            onClick={handleMonthFilter}
            checked={searchMonthCheck[index]}
          />

          <label htmlFor={index}>
            <span className="cat">{i}</span>
          </label>
        </li>
      );
    });
  };

  const renderYearUI = () => {
    let arr = generateArrayOfYears().splice(0, 3);

    return arr.map((i, index) => {
      return (
        <li key={index}>
          <input
            type="checkbox"
            id={i}
            value={i}
            onClick={handleYearFilter}
            checked={searchYearCheck[i]}
          />

          <label htmlFor={i}>
            <span className="cat">{i}</span>
          </label>
        </li>
      );
    });
  };

  const sortingDate = (rowA, rowB) => {
    const date1 = new Date(rowA.description.timestamp);
    const date2 = new Date(rowB.description.timestamp);

    if (date2 > date1) {
      // console.log(`${date2} is greater than ${date1}`)
      return 1;
    } else if (date2 < date1) {
      // console.log(`${date1} is greater than ${date2}`)
      return -1;
    } else {
      // console.log(`Both dates are equal`)
      return 0;
    }
  };

  const sortingName = (rowA, rowB) => {
    const name1 = rowA.description.verb.Action === "Deleted" ?
      rowA.description.object.definition.Old.quiz_name && rowA.description.object.definition.Old.quiz_name.trim() ? rowA.description.object.definition.Old.quiz_name.trim().toString() : ""
      : rowA.description.object.definition.New.quiz_name && rowA.description.object.definition.New.quiz_name.trim() ? rowA.description.object.definition.New.quiz_name.trim().toString() : "";
    const name2 = rowB.description.verb.Action === "Deleted" ?
      rowB.description.object.definition.Old.quiz_name && rowB.description.object.definition.Old.quiz_name.trim() ? rowB.description.object.definition.Old.quiz_name.trim().toString() : ""
      : rowB.description.object.definition.New.quiz_name && rowB.description.object.definition.New.quiz_name.trim() ? rowB.description.object.definition.New.quiz_name.trim().toString() : "";
    return name1.localeCompare(name2);
  };

  const sortingUser = (rowA, rowB) => {
    const name1 = rowA.description.User.name.toString();
    const name2 = rowB.description.User.name.toString();
    return name1.localeCompare(name2);
  };

  const difference = (obj1, obj2) => {
    let keyFound = [];
    Object.keys(obj1).forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        return keyFound.push(key + ":" + obj1[key]);
      }
    });
    return keyFound;
  };
  const sortAction = (a, b) => {
    return a.description.verb.Action.toLowerCase() >
      b.description.verb.Action.toLowerCase()
      ? 1
      : -1;
  };

  const logPop = (data) => {
    let finalData = data;
    finalData.timestamp = moment(finalData.timestamp).format(TABLE_DATE_TIME_FORMAT)
    //Now printing updated Value
    Swal.fire({ html: `<div class="audit-trial-json"><pre>${JSON.stringify(finalData, undefined, 1)}</pre></div>` });

  }

  const columns = useMemo(() => [
    {
      name: "User",
      selector: "user",
      sortable: true,
      sortFunction: sortingUser,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <span className="assigned-title-blk name-icon">
              {row.description.User.URL ? (
                <img
                  src={`${IMAGE_URL}/${row.description.User.URL.replace("https://myaie.ac", "").replaceAll("/home/myaie/public_html/", "").replaceAll("/home/myaie/public_html/", "").replace("public/", "").replace("adminapi.myaie.ac17278/", "").replace("devadminapi.myaie.ac1788/", "")}`}
                  alt="AIE"
                />
              ) : (
                InitialRender([
                  row.description.User.name,
                  row.description.User.name,
                ])
              )}

              <span
                className={`profile-box-2-status ${row.description.User.status
                    ? row.description.User.status
                    : "Offline"
                  }`}
              >
                <i className="fas fa-circle"></i>
              </span>
              <Hover firstName={row.description.User.name.split(" ")[0]} lastName={row.description.User.name.split(" ")[1]} photo={`${row.description.User.URL.replace("https://myaie.ac", "").replaceAll("/home/myaie/public_html/", "").replaceAll("/home/myaie/public_html/", "").replace("public/", "").replace("adminapi.myaie.ac17278/", "").replace("devadminapi.myaie.ac1788/", "")}`} email={row.description.User.mbox} mobile={row.description.User.Mobile} status={!row.description.User.status || row.description.User.status =="offline" ? false : true} right={true} />
            </span>
            <p>
              <b>{row.description.User.name}</b>
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Action Type",
      sortable: true,
      selector: "Action",
      sortFunction: sortAction,
      cell: (row) => RenderAuditTrailActionType(row.description.verb.Action).html,
    },

    {
      name: "Resource Type",
      // sortable: true,
      cell: (row) => row.resource_type == "questionAssigned" ? "Question Assigned" : row.resource_type == "questionUnassigned" ? "Question Unassigned" : "Quiz",
    },
    // {
    //   name: "Resource Name",
    //   selector: "Name",
    //   sortable: true,
    //   sortFunction: sortingName,
    //   cell: (row) => (
    //     <p
    //       className="as-text-blue curser"
    //       onClick={() =>
    //         {row.action_type == "Deleted" ? history.push(
    //           "/courseAdministration/assessment/quizzes/open/details/" +
    //           row.description.object.definition.Old.quid
    //         ) : history.push(
    //             "/courseAdministration/assessment/quizzes/open/details/" +
    //             row.description.object.definition.New.quiz_name
    //           ) }
    //       }
    //     >
    //       {row.action_type == "Deleted" ? row.description.object.definition.Old.quiz_name && TrimText(row.description.object.definition.Old.quiz_name, 20) : TrimText(row.description.object.definition.New.quiz_name, 20)}
    //     </p>
    //   ),
    // },
    {
      name: "Resource Name",
      selector: "Name",
      sortable: true,
      sortFunction: sortingName,
      cell: (row) => {
        if (row.description.verb.Action === "Deleted") {
          return (
            <span title={row.description.object.definition.Old.quiz_name} className="feature-name">
              <span className="textLimit100">
                {row.description.object.definition.Old.quiz_name}
              </span>
            </span>
          );
        } else {
          return (
            <Link
              className="as-text-blue curser feature-name"
              title={row.description.object.definition.New.quiz_name}
              to={"/courseAdministration/assessment/quizzes/open/details/" +
                row.description.object.definition.New.quid}
            >
              <span className="textLimit100">
                {row.description.object.definition.New.quiz_name}
              </span>
            </Link>
          );
        }

      },
    },

    {
      name: "Old Value",
      cell: (row) => {
        const entries = Object.entries(row.description.object.definition?.Old);
        if (row.description.verb.Action === "Created") {
          return "-";
        } else if (row.description.verb.Action === "Edited") {
          const diff = difference(
            row.description.object.definition?.Old,
            row.description.object.definition?.New
          );
          return diff.length !== 0 ? (
            <div
              className="tooltip-custom"
              title={`${diff.sort().map((entry) => entry.toString())}`
                .split(",")
                .join("\n")}
            >
              {/* <span className="tooltip-custom-box">{diff.sort().map(entry => <p>{entry.toString()}</p>)}</span> */}
              {diff.sort().map((entry, index) => {
                return (
                  index < 2 && (
                    <p key={index}>
                      {entry.toString().length > 30
                        ? entry.toString().slice(0, 30) + "..."
                        : entry}
                    </p>
                  )
                );
              })}
              {diff.length > 2 ? "..." : null}
            </div>
          ) : (
            "-"
          );
        } else {
          // return entries.map((entry, index) => (
          //   <p key={index} title={String(entry[1])}>
          //     {entry[0]}:
          //     {entry[1]
          //       ? entry[1].toString().length > 30
          //         ? entry[1].toString().slice(0, 30) + "..."
          //         : entry[1]
          //       : "-"}
          //   </p>
          // ));
          return (
            <div
              className="tooltip-custom h"
              title={`${Object.keys(row.description.object.definition?.Old)
                .sort()
                .map(
                  (entry) =>
                    entry.toString() +
                    " : " +
                    row.description.object.definition?.Old[entry]
                )}`
                .split(",")
                .join("\n")}
            >
              {entries.slice(0, 2).map((entry, index) => {

                return (
                  <p key={index}>
                    {entry[0]}:
                    {entry[1]
                      ? entry[1].toString().length > 30
                        ? entry[1].toString().slice(0, 30) + "..."
                        : entry[1]
                      : "-"}
                  </p>
                );
              })}
            </div>
          );
        }

      },
    },
    {
      name: "New Value",
      cell: (row) => {
        const entries = Object.entries(row.description.object.definition?.New);
        if (row.description.verb.Action === "Edited") {
          const diff = difference(
            row.description.object.definition?.New,
            row.description.object.definition?.Old
          );
          return diff.length !== 0 ? (
            <div
              className="tooltip-custom"
              title={`${diff.sort().map((entry) => entry.toString())}`
                .split(",")
                .join("\n")}
            >
              {/* <span className="tooltip-custom-box">{diff.sort().map(entry => <p>{entry.toString()}</p>)}</span> */}
              {diff.sort().map((entry, index) => {
                return (
                  index < 2 && (
                    <p key={index}>
                      {entry.toString().length > 30
                        ? entry.toString().slice(0, 30) + "..."
                        : entry}
                    </p>
                  )
                );
              })}
              {diff.length > 2 ? "..." : null}
            </div>
          ) : (
            "-"
          );
        } else if (row.description.verb.Action === "Profile Picture Edited") {
          return (
            <p title={row.description.object.definition?.New?.picture_me}>
              {row.description.object.definition?.New?.picture_me.toString()
                .length > 30
                ? "picture_me:" +
                row.description.object.definition?.New?.picture_me
                  .toString()
                  .slice(0, 30) +
                "..."
                : row.description.object.definition?.New?.picture_me}
            </p>
          );
        } else if (row.description.verb.Action === "Deleted") {
          return "-"
        } else {
          return (
            <div
              className="tooltip-custom"
              title={`${entries.map(
                (entry) => entry[0] + ":" + (entry[1] ? entry[1] : "-")
              )}`
                .split(",")
                .join("\n")}
            >
              {/* <span className="tooltip-custom-box">{entries.map(entry => <p>{entry[0]}:{entry[1] ? entry[1] : "-"}</p>)}</span> */}
              {entries.map((entry, index) => {
                return (
                  index < 2 && (
                    <p key={index}>
                      {entry[0]}:
                      {entry[1]
                        ? entry[1].toString().length > 30
                          ? entry[1].toString().slice(0, 30) + "..."
                          : entry[1]
                        : "-"}
                    </p>
                  )
                );
              })}
              {entries.length > 2 ? "..." : null}
            </div>
          );
        }
      },
    },
    {
      name: "Date",
      sortable: true,
      selector: "Date",
      sortFunction: sortingDate,
      cell: (row) => {
        let today = new Date().toLocaleDateString();
        return row.description.timestamp ? (
          <>
            <p>{moment(row.description.timestamp).format(TABLE_DATE_FORMAT + ",")}</p>
            <p>{moment(row.description.timestamp).format(TABLE_TIME_FORMAT)}</p>
          </>
        ) : (
          "-"
        );
      },
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              title="Open"
              className="btn btn-primary rounded-circle"
              onClick={() => logPop(row.description)}
            >
              <i className="fal fa-folder-open"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let tempUser = allData.filter((item) => {
        let includes = item.description.User.name
          .toLowerCase()
          .toString()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempResourceName = allData.filter((item) => {
        let includes =
          item.description.verb.Action &&
            item.description.verb.Action == "Deleted"
            ? item.description.object.definition.Old.quiz_name
              .toLowerCase()

              .includes(search.toLowerCase())
            : item.description.object.definition.New.quiz_name
              .toLowerCase()

              .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDate = allData.filter((item) => {
        let includes =
          item.description.timestamp &&
          moment(item.description.timestamp)
            .format(TABLE_DATE_TIME_FORMAT)
            .toString()
            .toLowerCase()

            .includes(search.toLowerCase());

        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...tempUser, ...tempResourceName, ...tempDate];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (actiontype.length) {
      let tempAction = updatedData;
      let tempResult = tempAction.filter((item) => {
        const startsWith = actiontype.find((post, index) => {
          if (post.toString() === item.description.verb.Action.toString())
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (month.length) {
      let tempMonth = updatedData;
      let tempResult = tempMonth.filter((item) => {
        const startsWith = month.find((post, index) => {
          let monthNumber = new Date(item.description.timestamp).getMonth();
          if (post.toString() === monthNumber.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (year.length) {
      let tempYear = updatedData;
      let tempResult = tempYear.filter((item) => {
        const startsWith = year.find((post, index) => {
          let yearNumber = new Date(item.description.timestamp).getFullYear();
          if (post.toString() === yearNumber.toString()) return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (search.length || month.length || year.length || actiontype.length) {
      return updatedData;
    } else {
      return data;
    }
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleActionTypeFilter = (e) => {
    const value = e.target.value;
    let arr = actiontype;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setActionType(arr);
    let id = e.target.id;
    setSearchActionTypeCheck({
      ...searchActionTypeCheck,
      [id]: !searchActionTypeCheck[id],
    });
  };

  const handleMonthFilter = (e) => {
    const value = e.target.value;
    let arr = month;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setMonth(arr);
    let id = e.target.id;
    setSearchMonthCheck({ ...searchMonthCheck, [id]: !searchMonthCheck[id] });
  };

  const handleYearFilter = (e) => {
    const value = e.target.value;
    let arr = year;
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1);
    } else {
      arr.push(value);
    }
    setYear(arr);
    let id = e.target.id;
    setSearchYearCheck({ ...searchYearCheck, [id]: !searchYearCheck[id] });
  };

  const oldValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.Old);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(
        row.description.object.definition?.Old,
        row.description.object.definition?.New
      );
      return diff.length !== 0 ? `${diff.sort()}` : "-";
    } else {
      return `${entries.map(
        (entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "-"}`
      )}`;
    }
  };

  const NewValue = (row) => {
    const entries = Object.entries(row.description.object.definition?.New);
    if (row.description.verb.Action === "Edited") {
      const diff = difference(
        row.description.object.definition?.New,
        row.description.object.definition?.Old
      );
      return diff.length !== 0 ? `${diff.sort()}` : "-";
    } else if (row.description.verb.Action === "Profile Picture Edited") {
      return row.description.object.definition?.New?.picture_me.toString()
        .length > 30
        ? "picture_me:" + row.description.object.definition?.New?.picture_me
        : row.description.object.definition?.New?.picture_me;
    } else {
      return `${entries.map(
        (entry, index) => `${entry[0]}: ${entry[1] ? entry[1] : "-"}`
      )}`;
    }
  };

  const modifiedData = () => {
    let data = dataToRender();
    let name;

    data = data?.map((row) => ({
      ...row,
      User: row.description.User.name,
      "Resource Name": row.description.verb.Action === "Deleted" ? row.description.object.definition.Old.quiz_name
        : row.description.object.definition.New.quiz_name,
      "Resource Type": row.resource_type == "questionAssigned" ? "Question Assigned" : row.resource_type == "questionUnassigned" ? "Question Unassigned" : "Quiz",
      "Action Type": row.description.verb.Action,
      "Old Value":
        Object.keys(row.description.object.definition.Old).length !== 0
          ? oldValue(row)
          : "-",
      "New Value":
        Object.keys(row.description.object.definition.New).length !== 0
          ? NewValue(row)
          : "-",
      Date: new Date(row.description.timestamp).toLocaleDateString(),
    }));

    return data;
  };

  const exportData = (fileType, fileName) => {
    let data = modifiedData();

    const header = [
      "User",
      "Action Type",
      "Resource Type",
      "Resource Name",
      "Old Value",
      "New Value",
      "Date",
    ];

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      // console.log(csvString);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["User"],
          row["Action Type"],
          row["Resource Type"],
          row["Resource Name"],
          row["Old Value"],
          row["New Value"],
          row["Date"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        columnStyles: {
          0: {
            columnWidth: 20,
          },
          1: {
            columnWidth: 24,
          },
          2: {
            columnWidth: 24,
          },
          3: {
            columnWidth: 24,
          },
          4: {
            columnWidth: 40,
          },
          5: {
            columnWidth: 40,
          },
          6: {
            columnWidth: 20,
          },
        },
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman">
                        <div className="dropdown multiselect">
                          <button
                            title="Action Type"
                            className={`btn btn-default dropdown-toggle  ${actiontype.length ? "btn-selected" : ""
                              }`}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span>
                              {" "}
                              Action Type <i className="fal fa-angle-down"></i>
                            </span>
                          </button>

                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">
                              <li className="cat cat-skyblue">
                                <input
                                  type="checkbox"
                                  id="action1"
                                  value="Created"
                                  onClick={handleActionTypeFilter}
                                  checked={searchActionTypeCheck.action1}
                                />

                                <label htmlFor="action1">
                                  <span className="cat cat-skyblue">
                                    <i className="fas fa-circle mr-1"></i> Created
                                  </span>
                                </label>
                              </li>

                              <li className="cat cat-red">
                                <input
                                  type="checkbox"
                                  id="action3"
                                  value="Deleted"
                                  onClick={handleActionTypeFilter}
                                  checked={searchActionTypeCheck.action3}
                                />

                                <label htmlFor="action3">
                                  <span className="cat cat-red">
                                    <i className="fas fa-circle mr-1"></i> Deleted
                                  </span>
                                </label>
                              </li>

                              <li className="cat cat-greenhex">
                                <input
                                  type="checkbox"
                                  id="action2"
                                  value="Edited"
                                  onClick={handleActionTypeFilter}
                                  checked={searchActionTypeCheck.action2}
                                />

                                <label htmlFor="action2">
                                  <span className="cat cat-greenhex">
                                    <i className="fas fa-circle mr-1"></i> Edited
                                  </span>
                                </label>
                              </li>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman">
                        <div className="dropdown multiselect">
                          <button
                            title="Month"
                            className={`btn btn-default dropdown-toggle ${month.length ? "btn-selected" : ""
                              }`}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span>
                              {" "}
                              Month <i className="fal fa-angle-down"></i>
                            </span>
                          </button>

                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">{renderMonthUI()}</ul>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman">
                        <div className="dropdown multiselect">
                          <button
                            title="Year"
                            className={`btn btn-default dropdown-toggle ${year.length ? "btn-selected" : ""
                              }`}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span>
                              {" "}
                              Year <i className="fal fa-angle-down"></i>
                            </span>
                          </button>

                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">{renderYearUI()}</ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>

                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Quiz Management Audit Trail");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Quiz Management Audit Trail");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>

                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Quiz Management Audit Trail");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading ? <SkeletonTicketList /> :
          <DataTable
            data={dataToRender()}
            // progressPending={loading}
            // progressComponent={<SkeletonTicketList />}
            defaultSortField="Date"
            defaultSortAsc={true}
            columns={columns}
            pagination={true}
            noDataComponent={Str.noRecord}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />}
      </div>
    </div>
  );
};

export default QuizAuditTrail;