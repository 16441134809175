import React, { useEffect, useMemo, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import $ from "jquery"
import Swal from "sweetalert2";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import moment from "moment";
import { Link, useHistory, useParams } from "react-router-dom";
import { GetAssignmentQuestionSummary, GetAssignmentQuestionSummaryCancelToken, Get_assessment_submission } from '../../../../../services/SmartAssessmentService';
import { InitialRender } from '../../../../common/Helper';
import { IMAGE_URL, TABLE_ROWS_PER_PAGE } from '../../../../../utils/Constants';
import Hover from '../../../../common/Hover';
import DataTable from 'react-data-table-component';
import SkeletonTicketList from '../../../../../loaders/SkeletonTicketList';
import Str from '../../../../common/Str';
import PermissionsGate from "../../../../../utils/permissionGate";
import { formatQuestion } from '../../../../../utils/FormatQuestion';
import { TrimText } from '../../../../common/TrimText';
import HtmlParser from 'react-html-parser';
import { secToMin } from '../../../../common/SecnodsToMinutes';
import hasPermission from '../../../../../utils/hasMultiplePermission';
import { useSelector } from 'react-redux';
import { RenderQuestionLevel, RenderQuestionType } from '../../../../../utils/CommonGroupingItem';
import { handleTableScroll } from '../../../../../utils/commonFunction';
import axios from 'axios';

function QuestionSummary() {
    const history = useHistory();
    const { id } = useParams();
    const [search, setSearch] = useState("");

    const [questionType, setQuestionType] = useState([]);
    const [searchQuestionTypeCheck, setSearchQuestionTypeCheck] = useState({});
    const [questionLevel, setQuestionLevel] = useState([]);
    const [searchQuestionLevelCheck, setSearchQuestionLevelCheck] = useState({});
    const [result, setResult] = useState([]);
    const [searchResultCheck, setSearchResultCheck] = useState({});

    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);
    const [detail, setDetail] = useState([]);
    const [submissionId, setSubmissionId] = useState();
    const [disabled, setDisabled] = useState(false)
    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
        handleTableScroll()
      }, [loading])

    useEffect(() => {
        let response = hasPermission({ scopes: ["cquestionbview"], permissions: givenPermsisions });
        if (!response) {
            history.push("/noaccess");
        }
    }, [])

    function isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const fixDataFormat = (arr) => {
        let tempArr = [];
        arr.map((item) => {
            let isBlock = isJsonString(item.question)
            tempArr.push({ ...item, question: isBlock ? formatQuestion(item.question) : item.question });
        });
        setData(tempArr);
    };

    useEffect(() => {
        const dropdownSource = axios.CancelToken.source();
      
        const fetchData = async () => {
          try {
            const res = await GetAssignmentQuestionSummaryCancelToken(id,dropdownSource.token);
            if(res.status == 200){
                fixDataFormat(res.data.question_list)
                // setDetail(res.data.header)
                setLoading(false)
            }
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error(error);
              setLoading(false)
            }
          }
        };
      
        fetchData();
      
        return () => {
          dropdownSource.cancel('Component unmounted');
        };
      }, []);

    const formik = useFormik({
        initialValues: {
            comment: "",
        },
        validationSchema: Yup.object({
            comment: Yup.string().required("Comment is required").trim(),
        }),
        onSubmit: (values) => {
            setDisabled(true);
            const data = new FormData();
            data.append("comment", values.comment);
            data.append("assignment_id", id);
            data.append("stdid", submissionId);
            data.append("action_type", "save_comment");
            // AddAndGetTicketComment(data)
            //     .then((response) => {
            //         $("#notifications").modal("hide");
            //         Swal.fire({
            //             icon: "success",
            //             title: "Success",
            //             text: "Saved comment successfully",
            //         });
            //         setDisabled(false)
            //     })
            //     .catch((error) => {
            //         console.log("AddAndGetTicketComment error :", error);
            //     });
        }
    })


    const dataToRender = () => {
        let updatedData = [];
        let allData = data;
        if (search.length) {
            let tempname = allData.filter((item) => {
                let includes = item.question && item.question.toLowerCase().includes(search.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });

            let data = [...tempname];
            let unique = [...new Set(data)];
            updatedData = unique;
        } else {
            updatedData = allData;
        }
        if (questionType.length) {
            let tempQuestionType = updatedData;
            let tempResult = tempQuestionType.filter((item) => {
                const startsWith = questionType.find(function (post, index) {
                    if (post.toString() === item.question_type ? item.question_type.toString() : "") return item;
                });
                if (startsWith) {
                    return startsWith;
                } else return null;
            });

            updatedData = tempResult;
        }
        if (questionLevel.length) {
            let tempStatus = updatedData;
            let tempResult = tempStatus.filter((item) => {
                const startsWith = questionLevel.find(function (post, index) {
                    if (post === item.level_name ? item.level_name : "") return item;
                });
                if (startsWith) {
                    return startsWith;
                } else return null;
            });

            updatedData = tempResult;
        }
        // if (result.length) {
        //     let tempRes = updatedData;
        //     let tempResult = tempRes.filter((item) => {
        //         const startsWith = result.find(function (post, index) {
        //             if (post.toString() === item.result ? item.result.toString() : "") return item;
        //         });
        //         if (startsWith) {
        //             return startsWith;
        //         } else return null;
        //     });

        //     updatedData = tempResult;
        // }
        if (questionType.length || search.length || questionLevel.length || result.length) {
            return updatedData;
        } else {
            return data;
        }
    };

    const sortCommonAns = (rowA, rowB) => {
        let nameA = formatQuestion(rowA.comman_ans.commanAns).trim().toLowerCase()
        let nameB = formatQuestion(rowB.comman_ans.commanAns).trim().toLowerCase()
        return nameA.localeCompare(nameB);
    }

    const sortQuestionName = (rowA, rowB) => {
        let nameA = formatQuestion(rowA.question).trim().toLowerCase()
        let nameB = formatQuestion(rowB.question).trim().toLowerCase()
        return nameA.localeCompare(nameB);
    }

    const columns = useMemo(() => [
        {
            name: "ID",
            selector: "qid",
            sortable: true,
            cell: (row, index) => (
                <div className="ticket-id">
                    <span className="overflow-ellipsis2">
                        <b>#{row.qid}</b>
                    </span>
                </div>
            ),
        },
        {
            name: "Question",
            selector: "question",
            sortable: true,
            // minWidth: "190px",
            sortFunction: sortQuestionName,
            cell: (row) => (
                <Link to={`/courseAdministration/smartAssessment/questionBank/question/view/${row.qid}`} title={formatQuestion(row.question)} className="as-text-blue curser">{row.question ? TrimText(formatQuestion(row.question), 20) : "-"}
                </Link>
            ),
        },
        {
            name: "Question Type",
            selector: "question_type",
            sortable: true,
            cell: (row) => (row.question_type ? RenderQuestionType(row.question_type).html : "-")
        },
        {
            name: "Question Level",
            selector: "level_name",
            sortable: true,
            cell: (row, index) => (
                <>{row.level_name ? RenderQuestionLevel(row.level_name).html : "-"}</>
                // <p>{row.level ? row.level : "-"}</p>
            ),
        },
        {
            name: "Most Common Answer",
            selector: "comman_ans",
            sortFunction: sortCommonAns,
            sortable: true,
            cell: (row) => (
                <p>{row.comman_ans ?
                    <div title={formatQuestion(row.comman_ans.commanAns)}>
                        {TrimText(formatQuestion(row.comman_ans.commanAns), 20)}
                    </div>
                    : "-"}
                </p>
            )
        },
        {
            name: "Correct Answer",
            selector: "correct_ans",
            sortable: false,
            cell: (row) => (
                <p>{row.correct_ans ?
                    row.correct_ans.map((item) => {
                        return (
                            <div title={formatQuestion(item.q_option)}>{TrimText(formatQuestion(item.q_option), 20)}</div>)
                    })
                    : "-"}
                </p>
            )
        },
        {
            name: "Average Time Taken",
            selector: "averagetime",
            sortable: true,
            cell: (row) => row.averagetime ? <div className='last-column-table'>{secToMin(row.averagetime)}</div> : <div className='last-column-table'>-</div>
        },
    ]);

    const handleSearchFilter = (e) => {
        const value = e.target.value;
        setSearch(value);
    };

    const handleQuestionTypeCheck = (e) => {
        const value = e.target.value;
        let arr = questionType;
        if (arr.includes(value)) {
            arr.splice(arr.indexOf(value), 1);
        } else {
            arr.push(value);
        }
        setQuestionType(arr);
        let id = e.target.id;
        setSearchQuestionTypeCheck({ ...searchQuestionTypeCheck, [id]: !searchQuestionTypeCheck[id] });
    };
    const handleResultFilter = (e) => {
        const value = e.target.value;
        let arr = result;
        if (arr.includes(value)) {
            arr.splice(arr.indexOf(value), 1);
        } else {
            arr.push(value);
        }
        setResult(arr);
        let id = e.target.id;
        setSearchResultCheck({ ...searchResultCheck, [id]: !searchResultCheck[id] });
    };

    const handleQuestionLevelFilter = (e) => {
        const value = e.target.value;
        let arr = questionLevel;
        if (arr.includes(value)) {
            arr.splice(arr.indexOf(value), 1);
        } else {
            arr.push(value);
        }
        setQuestionLevel(arr);
        let id = e.target.id;
        setSearchQuestionLevelCheck({ ...searchQuestionLevelCheck, [id]: !searchQuestionLevelCheck[id] });
    };

    const resetFilter = () => {
        setSearch("");
        setQuestionType([]);
        setSearchQuestionTypeCheck({});
        setQuestionLevel([]);
        setSearchQuestionLevelCheck({});
        setResult([]);
        setSearchResultCheck({});
    };

    const exportData = async (fileType, fileName) => {
        let data = dataToRender();

        const header = ["ID", "Question No", "Question", "Question Type", "Question Level", "Most Common Answer", "Correct Answer", "Average Time Taken"];

        data = data?.map((row) => ({
            ...row,
            ID: row?.qid,
            "Question No": row?.fullName ? row.fullName : "-",
            "Question": row?.question ? row.question : "-",
            "Question Type": row?.question_type ? row.question_type : "-",
            "Question Level": row?.level_name ? row.level_name : "-",
            "Most Common Answer": row?.mark ? row.mark : "-",
            "Correct Answer": row?.score_obtained ? row?.score_obtained : "-",
            "Average Time Taken": row?.score_obtained ? row?.score_obtained : "-",
        }));


        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });

            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(blobURL);
            }, 1000);
        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);

            // Returning false as downloading of file is already taken care of
            return false;
        }
        if (fileType === "pdf") {
            const compatibleData = data.map((row) => {
                return [row["ID"], row["Question"], row["Question Type"], row["Question Level"], row["Most Common Answer"], row["Correct Answer"], row["Average Time Taken"]];
            });
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    // valign: "center",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }
    };
    return (
        <div className="card card-profile-info-card mb-30">
            <div className={`card-body`}>
                <div className="my-tickets-info-list Tickets-main-wrap">
                    <div className="edit-icon new-card-header">
                        <div className="card-header">Question Summary</div>
                    </div>
                </div>

                <div className="custom-table-div filter-search-icon card card-table-custom course-table-filter-sec">
                    <div className="filter-search-bar-blk">
                        <div className="filter-button-group">
                            <div className="filter-eff filter-data-btn">
                                <button className="filter-buttons">
                                    <i className="fal fa-filter"></i>
                                </button>
                            </div>
                            <div className="assessment-table-filter">
                                <div className="btn-type-filter dropdown-comman">
                                    <div className={`dropdown multiselect `}>
                                        <button className={`btn btn-default dropdown-toggle ${questionType.length ? "btn-selected" : ""}`} type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" title="Question Type" >
                                            <span>
                                                Question Type <i className="fal fa-angle-down"></i>
                                            </span>
                                        </button>
                                        <form className="dropdown-menu remove-list-style">
                                            <ul aria-labelledby="dropdownMenu1">
                                                <li className="cat cat-skyblue" title="Multiple Choice Single Answer">
                                                    <input
                                                        type="checkbox"
                                                        id="action1"
                                                        value="Multiple Choice Single Answer"
                                                        onClick={handleQuestionTypeCheck}
                                                        checked={searchQuestionTypeCheck.action1}
                                                    />
                                                    <label htmlFor="action1">
                                                        <span className="cat cat-skyblue">
                                                            <i className="fas fa-circle mr-1"></i>{TrimText("Multiple Choice Single Answer", 20)}
                                                        </span>
                                                    </label>
                                                </li>
                                                <li className="cat cat-greenhex" title="Multiple Choice Multiple Answer">
                                                    <input
                                                        type="checkbox"
                                                        id="action2"
                                                        value="Multiple Choice Multiple Answer"
                                                        onClick={handleQuestionTypeCheck}
                                                        checked={searchQuestionTypeCheck.action2}
                                                    />
                                                    <label htmlFor="action2">
                                                        <span className="cat cat-greenhex">
                                                            <i className="fas fa-circle mr-1"></i>{TrimText("Multiple Choice Multiple Answer", 20)}
                                                        </span>
                                                    </label>
                                                </li>
                                                <li className="cat cat-blue" title="Match The Column">
                                                    <input
                                                        type="checkbox"
                                                        id="action3"
                                                        value="Match The Column"
                                                        onClick={handleQuestionTypeCheck}
                                                        checked={searchQuestionTypeCheck.action3}
                                                    />
                                                    <label htmlFor="action3">
                                                        <span className="cat cat-blue">
                                                            <i className="fas fa-circle mr-1"></i>{TrimText("Match The Column", 20)}
                                                        </span>
                                                    </label>
                                                </li>

                                                <li className="cat cat-yellowshade" title="Short Answer">
                                                    <input
                                                        type="checkbox"
                                                        id="action4"
                                                        value="Short Answer"
                                                        onClick={handleQuestionTypeCheck}
                                                        checked={searchQuestionTypeCheck.action4}
                                                    />
                                                    <label htmlFor="action4">
                                                        <span className="cat cat-yellowshade">
                                                            <i className="fas fa-circle mr-1"></i>{TrimText("Short Answer", 20)}
                                                        </span>
                                                    </label>
                                                </li>
                                                <li className="cat cat-red" title="Long Answer">
                                                    <input
                                                        type="checkbox"
                                                        id="action5"
                                                        value="Long Answer"
                                                        onClick={handleQuestionTypeCheck}
                                                        checked={searchQuestionTypeCheck.action5}
                                                    />
                                                    <label htmlFor="action5">
                                                        <span className="cat cat-red">
                                                            <i className="fas fa-circle mr-1"></i>{TrimText("Long Answer", 20)}
                                                        </span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="assessment-table-filter">
                                <div className="btn-type-filter dropdown-comman">
                                    <div className="dropdown multiselect">
                                        <button className={`btn btn-default dropdown-toggle ${questionLevel.length ? "btn-selected" : ""}`} type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Type">
                                            <span>
                                                Level <i className="fal fa-angle-down"></i>
                                            </span>
                                        </button>
                                        <form className="dropdown-menu remove-list-style">
                                            <ul aria-labelledby="dropdownMenu1">
                                                <li className="cat cat-red">
                                                    <input type="checkbox" id="actionLevelav11" value="Difficult" onClick={(e) => { handleQuestionLevelFilter(e); }} checked={searchQuestionLevelCheck.actionLevelav11} />
                                                    <label htmlFor="actionLevelav11">
                                                        <span className="cat cat-red">
                                                            <i className="fas fa-circle mr-1"></i>Difficult
                                                        </span>
                                                    </label>
                                                </li>
                                                <li className="cat cat-yellowshade">
                                                    <input type="checkbox" id="actionLevelav12" value="Easy" onClick={(e) => { handleQuestionLevelFilter(e); }} checked={searchQuestionLevelCheck.actionLevelav12} />
                                                    <label htmlFor="actionLevelav12">
                                                        <span className="cat cat-yellowshade">
                                                            <i className="fas fa-circle mr-1"></i>Easy
                                                        </span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="assessment-table-filter">
                                <div className="btn-type-filter dropdown-comman">
                                    <div className="dropdown multiselect">
                                        <button title="Result" className={`btn btn-default dropdown-toggle ${result.length ? "btn-selected" : ""}`} type="button" id="dropdownLevel" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span>
                                                Result <i className="fal fa-angle-down"></i>
                                            </span>
                                        </button>
                                        <form className="dropdown-menu remove-list-style">
                                            <ul aria-labelledby="dropdownLevel">
                                                <li className="cat cat-skyblue">
                                                    <input type="checkbox" id="Correct" value="Correct" onClick={handleResultFilter} checked={searchResultCheck.Correct} />
                                                    <label htmlFor="Correct">
                                                        <span className="cat cat-skyblue">
                                                            <i className="fas fa-circle mr-1"></i>
                                                            Correct
                                                        </span>
                                                    </label>
                                                </li>
                                                <li className="cat cat-skyblue">
                                                    <input type="checkbox" id="Incorrect" value="Incorrect" onClick={handleResultFilter} checked={searchResultCheck.Incorrect} />
                                                    <label htmlFor="Incorrect">
                                                        <span className="cat cat-orange">
                                                            <i className="fas fa-circle mr-1"></i>
                                                            Incorrect
                                                        </span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </form>
                                    </div>
                                </div>
                            </div> */}

                            <div className="button-reset dropdown-comman">
                                <button className="btn btn-primary" title="Reset" onClick={resetFilter}>
                                    <i className="fal fa-redo"></i>Reset
                                </button>
                            </div>
                            <div className="files-export-group">
                                <button type="button" className="btn btn-files" title="Export spreadsheet" onClick={() => { exportData("xlsx", "Question Summary") }}>
                                    <i className="fal fa-file-excel icon"></i>
                                </button>
                                <button type="button" className="btn btn-files" title="Export CSV" onClick={() => { exportData("csv", "Question Summary") }}>
                                    <i className="fal fa-file-csv icon"></i>
                                </button>
                                <button type="button" className="btn btn-files" title="Export PDF" onClick={() => { exportData("pdf", "Question Summary") }}>
                                    <i className="fal fa-file-pdf icon"></i>
                                </button>
                            </div>
                        </div>
                        <div className="text-md-right action2-blk">
                            <div className="ticket-view-btn d-flex align-items-center">
                                <div className="add-new-ticket-bx">
                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                        <i className="fal fa-angle-left"></i>Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="system-administration-table table-responsive">
                        <div className="table-responsive-div">
                            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                        <label>
                                            <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        data={dataToRender()}
                        defaultSortField="question"
                        defaultSortAsc={true}
                        columns={columns}
                        pagination={true}
                        noDataComponent={Str.noRecord}
                        progressPending={loading}
                        progressComponent={<SkeletonTicketList />}
                        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    />
                </div>
            </div>
            <div className="topic-add-modal modal fade" id="notifications" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">

                        <form>
                            <div className="modal-body">
                                <div className="topic-add-modal-content text-center font-weight-bold">
                                    <i class="fal fa-comment"></i> Add Comment
                                </div>
                                <hr />
                                <div className="topic-add-modal-content">

                                    <div className="row">
                                        <div className="col-4 font-weight-bold">Comment</div>
                                        <div className="col-8">
                                            <textarea name="comment" type="text" className={"form-control" + (formik.errors.comment && formik.touched.comment ? " is-invalid" : "")} value={formik.values.comment} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className=" form-group form-group-save-cancel ml-3">
                                <PermissionsGate scopes={["cquestionbedit"]} errorProps={{ disabled: true }}>
                                <button type="button" className="btn btn-primary" onClick={formik.handleSubmit} disabled={disabled} title="Save">
                                    {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                                    Save
                                </button>
                                </PermissionsGate>
                                <button type="button" className="btn btn-danger" data-dismiss="modal" title="Close">
                                    <i className="fal fa-times"></i>
                                    Close
                                </button>
                                {formik.touched.comment && formik.errors.comment ? <div className="invalid-feedback d-block">{formik.errors.comment}</div> : null}
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuestionSummary