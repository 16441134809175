import React from "react";
import { useParams } from "react-router-dom";
import Features from "../components/pmc/FeaturesAndBenefits/Features";
import Module from "../components/pmc/Module";
import Programs from "../components/pmc/Programs";

const ProgramManagementCenter = () => {
  const { sideTab } = useParams();

  return (
    <>
    {sideTab == "programmes" && <Programs/>}
    {sideTab == "modules" && <Module/>}
    {sideTab=="features" && <Features/>}
    </>
  );
};

export default ProgramManagementCenter;
