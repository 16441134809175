import React from "react";
import QuestionGroup from "./QuestionGroup";

function QuestionsGroupView({refresh}) {
  return (
    <div className="card card-profile-info-card">
      <div className="">
        <div className="card-body-inr card-body-info">
          <div className="ass-bel-stuff">
            <QuestionGroup refreshData={()=>{refresh()}}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionsGroupView;
