import axiosInstance from '../utils/axios';

export const GetCertificateTemplates = async (data) => {
  return await axiosInstance().post('/getCertificateTemplates', data);
};

export const GetFilters = async () => {
  return await axiosInstance().get('/getFilters');
};

export const GetCertificateTemplatesFilters = async () => {
  return await axiosInstance().get('/getCertificateTemplatesFilters');
};

export const CreateCertificateTemplate = async (data) => {
  return await axiosInstance().post('/createCertificateTemplate', data);
};

export const updateCertificateTemplateById = async (data) => {
  return await axiosInstance().put('/updateCertificateTemplateById', data);
};

export const updateIntakeCertTemplateById = async (data) => {
  const response = await axiosInstance().patch('/updateIntakeCertTemplate', data);
  return response.data;
};

export const saveCertificateTemplate = async (data) => {
  const response = await axiosInstance().patch(
    `/saveCertificateTemplateById`,
    data
  );
  return response.data;
};

export const deleteCertificateTemplateById = async (data) => {
  return await axiosInstance().post(`deleteCertificateTemplateById/${data}`);
};

export const getCertificateTemplateById = async (id) => {
  return await axiosInstance().get(`/getCertificateTemplateById/${id}`);
};

export const getCertificateTemplateByCourseId = async (id) => {
  return await axiosInstance().get(`/getCertificateTemplateByCourseId/${id}`);
};


export const GetCertificateStatusById = async (id) => {
  return await axiosInstance().get('/getCertificateStatusById/' + id);
};

export const GetCertificateStatus = async (studentIDs, courseId) => {
  return await axiosInstance().post('/postCertificateStatus', {
    student_ids: studentIDs,
    intake_id: courseId,
  });
};

export const GetStudentCertificateById = async (studentId, intake_id) => {
  return await axiosInstance().get(
    `/getStudentCertificateById/${studentId}/${intake_id}`,
    {
      responseType: 'arraybuffer',
    }
  );
};

export const GenerateCertificate = async (data) => {
  return await axiosInstance().post('/generateStudentCertificate', data);
};

export const GetAssignedCertificateCourses = async (data) => {
  return await axiosInstance().post(`/getAssignedCertificateCourses`, data);
};

export const GetAssignedCertificateCoursesFilters = async (id, isAssigned) => {
  return await axiosInstance().get(`/getAssignedCertificateCoursesFilters`, { params: { id, isAssigned } });
};

export const UnAssginedCertificate = async (id) => {
  return await axiosInstance().post(`/unAssginedCertificate`, { id });
};

export const AssginedCertificate = async (data) => {
  return await axiosInstance().post(`/assginedCertificate`, data);
};

export const GetCertificateAuditTrails = async (data) => {
  return await axiosInstance().post(`/getCertificateAuditTrails`, data);
};

export const GetCertificateAuditFilters = async (params) => {
  return await axiosInstance().get(`/getCertificateAuditFilters`, {params});
};

export const PreviewCertificate = async (params) => {
  return await axiosInstance().get(`/previewCertificate`, {params});
};

export const GetCertificatesDetails = async (params) => {
  return await axiosInstance().get(`/getCertificatesDetails`, {params});
};

export const GetCertificatesFonts = async () => {
  return await axiosInstance().get(`/getCertificatesFonts`);
};
