import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import $ from "jquery";
import Swal from "sweetalert2";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import { RenderLearningMethod } from "../../../../utils/CommonGroupingItem";
import { GetCourselist } from "../../../../services/DashBoardServices";
import { GlobalIconNames } from "../../../../utils/GlobalIconNames";
import Tablefilter from "../../../common/Tablefilter";
import { RenderCourseStatus, RenderReadStatus } from "../../../../utils/CommonStatusItems";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Str from "../../../common/Str";
import PermissionsGate from "../../../../utils/permissionGate";
import { handleTableScroll } from "../../../../utils/commonFunction";

const AllCourseTable = (props) => {
    const [courses, setCourses] = useState([]);

    useEffect(() => {
    handleTableScroll()
  }, [props])

    useEffect(() => {
        setCourses(props && props.courses ? props.courses : []);
    }, [props]);

    const handleClose = () => {
        $("#viewCourses").modal("hide");
        $("#viewAvailCourses").modal("hide");
        $(".modal-backdrop.show").remove()
    }


    const handleSort = (column, sortDirection) => {
        props.setSortKey(column.sortField);
        props.setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };

    const handlePageChange = (pageNo) => {
        props.setPageNo(pageNo);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        props.setPerPage(newPerPage);
    };

    const handleSelect = (e, row) => {
        const { checked, id, name, value } = e.target;
        const ls = [...props.selectedRows];
        const lsData = [...props.tempCheckedAllData];
        if (checked) {
            ls.push(row);
            lsData.push(Number(id));
        } else {
            const index = ls.indexOf(row);
            const dataIndex = lsData.indexOf(Number(id));
            ls.splice(index, 1);
            lsData.splice(dataIndex, 1);
        }
        props.setSelectedRows([...ls]);
        props.setTempCheckedAllData([...lsData]);
    }

    const columns = useMemo(() => [
        {
            name: "",
            cell: (row, ind) => {

                return <input
                    type={"checkbox"}
                    name="select"
                    id={row?.id}
                    onChange={(e) => handleSelect(e, row)}
                    checked={props.tempCheckedAllData.includes(row?.id)}
                />
            }
        },
        {
            name: "Name",
            selector: "name",
            sortField: "name",
            sortable: true,
            cell: (row) =>
                row.name ? (
                    <Link className="as-text-blue overflow-ellipsis2" onClick={() => handleClose()} title={row.name} to={`/courseAdministration/coursesdetails/${row.intakeID}/detailCourses/show`}>
                        {row.name}
                    </Link>
                ) : (
                    "-"
                ),
        },
        {
            name: "Start",
            selector: "start",
            sortField: "start",
            sortable: true,
            cell: (row) => (
                <>
                    {row.start ? (
                        <div className="dateTime">
                            <p className="right-space">{moment.unix(row.start).format(TABLE_DATE_FORMAT)}</p>
                        </div>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "End",
            selector: "end",
            sortField: "end",
            sortable: true,
            cell: (row) => (
                <>
                    {row.end ? (
                        <div className="dateTime">
                            <p className="right-space">{moment.unix(row.end).format(TABLE_DATE_FORMAT)}</p>
                        </div>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            name: "Intake",
            selector: "year",
            sortField: "intake",
            sortable: true,
            cell: (row) => <>{row.year ? row.year + "-" + row.number : "-"}</>,
        },
        {
            name: "Correspondence",
            selector: "type",
            sortField: "type",
            sortable: true,
            cell: (row) => row.type ? RenderLearningMethod(row.type).html : "-",
        }
    ]);

    function addIds() {
        let subItems = props.programmeCourse.getSubjects ? props.programmeCourse.getSubjects : []

        const ifAlreadyExist = (mainArr = [], obj) => {
            let found = false;
            mainArr.map((elem, ind) => {
                if (elem.intake_id === obj.intakeID) {
                    found = true;
                }
            });
            return found;
        }

        if (props.selectedRows.length) {
            props.setAllCourseError(false)
            props.selectedRows.map(item => {
                if (ifAlreadyExist(subItems, item)) {
                    Swal.fire({
                        title: "Are you sure?",
                        text: "This course is already assigned, are you sure to move this course ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, move it!",
                    }).then(res => {
                        if (res.isConfirmed) {
                            props.closeAllCourses()
                        }
                    })
                    return;
                } else {
                    subItems.push({
                        intake: item.intakeID,
                        course_id: item.id,
                        course_name: item.name,
                        start: item.start,
                        end: item.end,
                        number: item.number,
                        year: item.year,
                        type: item.type
                    })
                    props.closeAllCourses()
                }
            })
        } else {
            props.setAllCourseError("Atleast Select One Course")
        }
        props.setSelectedProgramme({ ...props.programmeCourse, getSubjects: subItems })
    }

    const resetFilter = () => {
        props.setStatus({ arr: ["In Progress"], checkObj: { "statusIn Progress": true } })
        props.setLearningMethod({ arr: [], checkObj: {} })
        props.setProgramme({ arr: [], checkObj: [] })
        props.setIntake({ arr: [], checkObj: {} })
        props.setReply({ arr: [], checkObj: {} })
    }

    const exportData = (fileType, fileName) => {
        let data = courses;
        const header = [
          "Name",
          "Start",
          "End",
          "Intake",
          "Correspondence",
        ];
    
        Swal.fire({
          title: "File downloading",
          onOpen: function () {
            Swal.showLoading();
          },
        });
    
        const data1 = {
          pageNo: props.page,
          size: props.perPage,
          viaIntakeYear: props.intake.arr,
          viaLearnType: props.learningMethod.arr,
          exportStatus: "true",
          viaStatus: props.status.arr,
          key: props.sortkey,
          sortOrder: props.sortOrder,
          viaProgramme: props.programme.arr,
          viaSearch: props.search,
        };
    
        GetCourselist(data1)
          .then((res) => {
            data = res.data.result;
            data = data?.map((row) => ({
              Name: row.name,
              "Start": row.start
                ? `${moment.unix(row.start).format(TABLE_DATE_FORMAT)} - ${moment
                  .unix(row.start)
                  .format(TABLE_TIME_FORMAT)}`
                : "-",
              "End": row.end
                ? `${moment.unix(row.end).format(TABLE_DATE_FORMAT)} - ${moment
                  .unix(row.end)
                  .format(TABLE_TIME_FORMAT)}`
                : "-",
                Intake: `${row.year} - ${row.number}`,
              "Correspondence": row.type ? RenderLearningMethod(row.type).text : "-",
            }));
    
            if (fileType === "csv") {
              const csvString = Papa.unparse({ fields: header, data });
              const blob = new Blob([csvString], {
                type: "text/csv;charset=utf-8,",
              });
    
              const blobURL = window.URL.createObjectURL(blob);
    
              const anchor = document.createElement("a");
              anchor.download = fileName;
              anchor.href = blobURL;
              anchor.dataset.downloadurl = [
                "text/csv",
                anchor.download,
                anchor.href,
              ].join(":");
              anchor.click();
    
              setTimeout(() => {
                URL.revokeObjectURL(blobURL);
              }, 1000);
              Swal.close();
            } else if (fileType === "xlsx") {
              const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                  obj[col] = row[col];
                });
                return obj;
              });
    
              let wb = XLSX.utils.book_new();
              let ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
              XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
              XLSX.writeFile(wb, `${fileName}.xlsx`);
              Swal.close();
              return false;
            }
            if (fileType === "pdf") {
              const compatibleData = data.map((row) => {
                return [
                  row["Name"],
                  row["Start"],
                  row["End"],
                  row["Intake"],
                  row["Correspondence"],
                ];
              });
              const doc = new JsPDF();
              doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                  minCellHeight: 10,
                  minCellWidth: 5,
                  halign: "left",
                  fontSize: 8,
                },
              });
              doc.save(`${fileName}.pdf`);
              Swal.close();
              return false;
            }
          })
          .catch((error) => {
            console.log("error >>>>>> ", error);
          });
      };

    return (
        <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="custom-table-div filter-search-icon card-table-custom c-a-p-c-t">
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div
                                    id="assessment-table-main_wrapper"
                                    className="dataTables_wrapper no-footer"
                                >
                                    <div
                                        id="assessment-table-main_filter"
                                        className="dataTables_filter"
                                    >
                                        <label>
                                            <input
                                                type="search"
                                                className=""
                                                placeholder="Search"
                                                aria-controls="assessment-table-main"
                                                onChange={e => props.setCourseSearch(e.target.value)}
                                                value={props.search}
                                            />
                                        </label>
                                        <div className="filter-eff filter-data-btn ml-2">
                                            <button className="filter-buttons">
                                                {GlobalIconNames("filter")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner  filter-custom-new`}>
                                        <Tablefilter
                                            filterName="Status"
                                            optionArr={[{ label: "Upcoming", value: "Upcoming" }, { label: "In Progress", value: "In Progress" }, { label: "past", value: "past" }]}
                                            state={props.status}
                                            setState={props.setStatus}
                                            renderLabelFunction={RenderCourseStatus}
                                            singleSelect
                                        />
                                        <Tablefilter
                                            filterName="Learning Method"
                                            optionArr={props.learningArr}
                                            state={props.learningMethod}
                                            setState={props.setLearningMethod}
                                            renderLabelFunction={RenderLearningMethod}
                                        />
                                        <Tablefilter
                                            filterName={"Programme"}
                                            optionArr={props.programmeArr}
                                            state={props.programme}
                                            setState={props.setProgramme}
                                            isSearchFilter
                                        />
                                        <Tablefilter
                                            filterName={"Intake"}
                                            optionArr={props.intakeArr}
                                            state={props.intake}
                                            setState={props.setIntake}
                                            isOptionReversed={true}
                                            uniqueId={"allCoursePopup"}
                                        />
                                        <Tablefilter
                                            filterName={"Reply"}
                                            optionArr={[{ label: "Read", value: "read" }, { label: "Unread", value: "unread" }]}
                                            state={props.reply}
                                            setState={props.setReply}
                                            renderLabelFunction={RenderReadStatus}
                                        />
                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button
                                            className="btn btn-primary"
                                            onClick={resetFilter}
                                            title="Reset"
                                        >
                                            {GlobalIconNames("reset").html}
                                        </button>
                                    </div>
                                    <div className="files-export-group">
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            title="Export spreadsheet"
                                            onClick={() => {
                                                exportData("xlsx", "Course_List");
                                            }}
                                        >
                                            {GlobalIconNames("excel")}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            title="Export CSV"
                                            onClick={() => {
                                                exportData("csv", "Course_List");
                                            }}
                                        >
                                            {GlobalIconNames("csv")}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-files"
                                            title="Export PDF"
                                            onClick={() => {
                                                exportData("pdf", "Course_List");
                                            }}
                                        >
                                            {GlobalIconNames("pdf")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {<DataTable
                    data={courses}
                    defaultSortField="start"
                    defaultSortAsc={false}
                    columns={columns}
                    pagination={true}
                    paginationServer
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    noDataComponent={Str.noRecord}
                    paginationDefaultPage={props.pageNo}
                    progressPending={props.loading}
                    progressComponent={<SkeletonTicketList />}
                    onSort={handleSort}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    highlightOnHover={false}
                    paginationTotalRows={props.totalRows}
                />}
                {courses && courses.length > 0 && (
                    <div className="btn-sec">
                        <PermissionsGate errorProps={{ disabled: true }} scopes={["sapiedit"]}>
                            <button
                                className="btn btn-primary"
                                type="button"
                                title="Add"
                                onClick={() => {
                                    addIds();
                                }}
                            >
                                <i className="fal fa-plus"></i>Add
                            </button>
                        </PermissionsGate>
                        <div className="invalid-feedback d-block">{props.allCourseError ? props.allCourseError : ""} </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllCourseTable;
