import React from 'react'
import { useParams } from 'react-router-dom'
import ListOfNotification from './NotificationTabs/ListOfNotification'
import NotificationForm from './NotificationTabs/NotificationForm';

function Notifications({studentName}) {
  const { subType } = useParams();
  return (
    <div>
      {!subType ? <ListOfNotification/> : subType == "addNotification" ? <NotificationForm studentName={studentName}/> : subType == "editNotification" && <NotificationForm studentName={studentName}/>}
    </div>
  )
}

export default Notifications