import axiosInstance from "../utils/axios";

export const GetProgramsList = async (data) => {
  return await axiosInstance().post(`/getProgramsLists`, data);
};

export const GetProgramsListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getProgramsLists`, values, {
    cancelToken: cancelToken
  });
};

export const GetListOfUserAuditTrail = async () => {
  return await axiosInstance().post(`/listUserData`, {});
};

export const GetProgramsDetails = async (id) => {
  return await axiosInstance().get(`getProgramsDetail?id=${id}`);
};

export const GetProgramsDetailsCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`getProgramsDetail?id=${id}`, {cancelToken});
};

export const GetProgrameTypes = async () => {
  return await axiosInstance().get(`getProgramType`);
};

export const GetSchoolList = async () => {
  return await axiosInstance().get(`/getSchoolList`);
};

export const GetSchoolListCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getSchoolList`, {cancelToken});
};

export const DeleteProgramme = async (id) => {
  return await axiosInstance().get(`deleteProgramme?id=${id}`);
};

export const CreateProgramme = async (data) => {
  return await axiosInstance().post(`createQualification`, data);
};

export const UpdateProgramme = async (data) => {
  return await axiosInstance().post(`editQualification`, data);
};

export const GetProgrammeStudent = async (id) => {
  return await axiosInstance().get(`qualificationStudentList?id=${id}`);
};

export const GetProgrammeStudentCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`qualificationStudentList?id=${id}`, {cancelToken});
};

export const GetProgramsNotificationList = async (id) => {
  return await axiosInstance().get(`getProgramsNotificationList?intake=${id}`);
};

export const GetProgramsNotificationListCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`getProgramsNotificationList`, {params: {intake: id}, cancelToken});
};

export const GetProgramsNotificationDetails = async (id) => {
  return await axiosInstance().get(`getProgramsNotificationDetails?id=${id}`);
};

export const GetQualificationProgramAuditTrail = async () => {
  return await axiosInstance().get(`getQualificationProgramAuditTrail`);
};

export const GetSingleQualificationProgramAuditTrail = async (id) => {
  return await axiosInstance().get(`getQualificationProgramAuditTrail?action_id=${id}`);
};

export const GetProgrammeModules = async (data, id) => {
  return await axiosInstance().get(`getSubjectProgramAssignedOrNotAssignedList?qualification=${id}&type=${data}`);
};

export const GetProgrammeModulesCancelToken = async (data, id, cancelToken) => {
  return await axiosInstance().get(`getSubjectProgramAssignedOrNotAssignedList`, { params:{qualification: id, type: data}, cancelToken: cancelToken });
};

export const AssignSubjectToProgram = async (data) => {
  return await axiosInstance().post(`assignSubjectToProgram`, data);
};

export const DeleteAssignSubjectToProgram = async (data) => {
  return await axiosInstance().post(`deleteAssignSubjectToProgram`, data);
};

export const DeleteProgrameNotification = async (id) => {
  return await axiosInstance().get(`deleteNotifications?id=${id}`);
};

export const GetCommonContent = async (id) => {
  return await axiosInstance().get(`getCommonContent`);
};

export const GetCommonContentCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getCommonContent`, {
    cancelToken: cancelToken
  });
};

export const GetNetsuiteProductList = async (id) => {
  return await axiosInstance().get(`getNetsuiteProductList?itemtype=Service`);
};

export const GetNetsuiteProductListCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`getNetsuiteProductList?itemtype=Service`, {cancelToken});
};

export const ExportGrade = async (id) => {
  return await axiosInstance().get(`exportQualificationAssessment?qualification=${id}`);
};

export const UpdateStudentNotification = async (data) => {
  return await axiosInstance().post(`updateStudentNotification`, data);
};

export const PreviewStudentNotification = async (data) => {
  return await axiosInstance().post(`previewStudentNotification`, data);
};

export const GetCommonContentReReg = async () => {
  return await axiosInstance().get(`/getCommonContent?api_section=re_registration`);
};

export const GetProgramsListFilter = async (id) => {
  return await axiosInstance().get(`getProgramsList_filter`);
};

export const GetProgramsListFilterCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getProgramsList_filter`, {
    cancelToken: cancelToken
  });
};


export const GetSubjectProgramAssignedOrNotAssignedListFilter = async (data, id) => {
  return await axiosInstance().get(`getSubjectProgramAssignedOrNotAssignedListFilter?qualification=${id}&type=${data}`);
};

export const getProgramAuditTrial = async (data) => {
  return await axiosInstance().post(`getProgramAuditTrial`, data);
};

export const GetProgramAuditTrialCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getProgramAuditTrial`, values, {
    cancelToken: cancelToken
  });
};

export const GetGrademixAuditTrialCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getGrademixAuditTrial`, values, {
    cancelToken: cancelToken
  });
};

export const getProgramAuditTrialDropdown = async (params) => {
  return await axiosInstance().get(`getProgramAuditTrialDropdown`, { params });
};

export const GetProgramAuditTrialDropdownCancelToken = async (params,cancelToken) => {
  return await axiosInstance().get(`/getProgramAuditTrialDropdown`, { params, cancelToken: cancelToken });
};

export const GetGrademixAuditTrialDropdownCancelToken = async (params,cancelToken) => {
  return await axiosInstance().get(`/getGrademixAuditTrialDropdown`, { params, cancelToken: cancelToken });
};
