import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import LibraryDetails from "./LibraryDetails";
import LibraryList from "./LibraryList";import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";

function LibraryTab({setBorrowerName}) {
  const { tab, type, id, editType } = useParams();
  const history = useHistory();

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["slibraryborrowersview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  return (
    <div>
      {type && type == "table" && !id && <LibraryList />}
      {type && type == "detail" && id && <LibraryDetails setBorrowerName={setBorrowerName} />}      
    </div>
  );
}

export default LibraryTab;
