import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import Tabs from "../components/systemadministration/Tabs"
import { Link, useHistory } from "react-router-dom";
import Settings from "../components/systemadministration/General/Settings";
import AuditTrail from "../components/systemadministration/General/AuditTrail";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";

const General = () => {

    const history = useHistory();
    const [activeTab, setActiveTab] = useState('')
    const location = useLocation();
    const { tab } = useParams();

    useEffect(() => {
        if (tab != '') {
            setActiveTab(tab)
        } else {
            setActiveTab('settings')
        }
    }, [tab]);

    return (
        <>
            <Header></Header>
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar></SideBar>
                <div className="sidebar-right">
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                General
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                            <div className="add-new-ticket-bx">
                                                <button
                                                onClick={() => history.goBack()}
                                                title="Back"
                                                className="btn btn-white-bordered"
                                                >
                                                <i className="fal fa-angle-left"></i>Back
                                                </button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                        <li className="nav-item" onClick={() => history.push("/administration/general/settings")}>
                                            {/* <PermissionsGate scopes={["bmview"]}> */}
                                            <Link className={`nav-link ${activeTab == 'settings' ? 'active' : ''}`} id="pills-tab1" data-toggle="pill" to={"/administration/general/settings"} href="#pills-tab11" role="tab" aria-controls="pills-tab11" aria-selected="true">
                                                <i className="fal fa-cog"></i>
                                                Settings
                                            </Link>
                                            {/* </PermissionsGate> */}
                                        </li>
                                        <li className="nav-item" onClick={() => history.push("/administration/general/auditTrail")}>
                                            {/* <PermissionsGate scopes={["bmView"]}> */}
                                            <Link className={`nav-link ${activeTab == 'auditTrail' ? 'active' : ''}`} id="pills-tab2" data-toggle="pill" to={"/administration/general/auditTrail"} href="#pills-tab12" role="tab" aria-controls="pills-tab12" aria-selected="true">
                                                <i className="fal fa-history"></i>Audit Trail
                                            </Link>
                                            {/* </PermissionsGate> */}
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                                            {location.pathname.includes("/administration/general/settings") && <Settings />}
                                            {location.pathname.includes("/administration/general/auditTrail") && <AuditTrail setTab={setActiveTab} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default General;
