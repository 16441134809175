import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { GetExamListAuditFilters, getExamListAuditTrails } from "../../../../../services/ExternalExamServices";
import { useSelector } from "react-redux";
import hasPermission from "../../../../../utils/hasMultiplePermission";
import AuditTrailsTable from "../../../../common/AuditTrailsTable";

function DetailsAuditTrail() {
  const { id, type, subType, subTab, subId } = useParams();
  const history = useHistory();
  const givenPermsisions = useSelector((state) => state.givenPermission);
  let param = {exam_id : id};
  let reg_params = {action_id:id, exam_id:subId};  
  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"];

  useEffect(() => {
    let response = hasPermission({ scopes: ["eerleview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, []);
  
  return (
    <AuditTrailsTable 
      apiFunction={getExamListAuditTrails}
      filterArrApi={GetExamListAuditFilters}
      columnsToShow={columnsToShow}
      exportFileName={"Exam_Audit_Trail"}
      isResourceNameShown={true}
      apiParams={(type=="open" && subTab !== "registration") ? param : (subTab === "registration" && subType === "audit-trail") ? reg_params :{action_id: id}}
      actionId={id}
      isShowingResourceTypeFilter
    />
  );
}

export default DetailsAuditTrail;
