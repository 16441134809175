import moment from "moment";
import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import Swal from "sweetalert2";
import {
  DeleteAssignSubjectToProgram,
  GetCommonContentReReg,
  GetProgrammeModules,
  GetProgrammeModulesCancelToken,
  GetSubjectProgramAssignedOrNotAssignedListFilter,
} from "../../../../services/ProgrammeServices";
import PermissionsGate from "../../../../utils/permissionGate";
import {
  RenderCourseType,
  RenderLearningMethod,
} from "../../../../utils/CommonGroupingItem";
import AvailableModules from "./AvailableModules";
import Tablefilter from "../../../common/Tablefilter";
import { TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import axios from "axios";
import { handleTableScroll } from "../../../../utils/commonFunction";

const AssignedModules = ({ updateRow, setUpdateRow, dropdowns, refresh, setRefresh }) => {
  const { id } = useParams();

  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} })
  const [intake, setIntake] = useState({ arr: [], checkObj: {} })
  const [courseType, setCourseType] = useState({ arr: [], checkObj: {} })
  const [search, setSearch] = useState("");
  const [intakeArr, setIntakeArr] = useState([]);
  const [assessmentList, setAssessmentList] = useState([]);
  const [isListLoaded, setIsListLoaded] = useState(true);

  const [learnmethod, setLearnMethod] = useState([]);
  const [courseTypeArr, setCourseTypeArr] = useState([])
  const [filterData, setFilterData] = useState({ learning: [], intake: [], type: [] })

  useEffect(() => {
    handleTableScroll()
  }, [isListLoaded])


  useEffect(() => {
    GetCommonContentReReg()
      .then((res) => {
        let tempArr = []
        setLearnMethod(res.data.study_type);
        setCourseTypeArr(res.data.type_course);
        res.data.intake_year && res.data.intake_year.length && res.data.intake_number && res.data.intake_number.length && res.data.intake_year.map(year => {
          res.data.intake_number.map(number => {
            tempArr.push({ value: `${year.value}/${number.value}`, label: `${year.label}-${number.label}` })
          })
        })
        setIntakeArr(tempArr)
      })
      .catch((err) => console.log("error :", err))
  }, []);

  useEffect(() => {
    let listType = "assigned";
    GetSubjectProgramAssignedOrNotAssignedListFilter(listType, id).then((res) => {
      setFilterData({ ...res.data, learning: res.data.intakeTypeFilter, type: res.data.subjectTypeFilter, intake: res.data.yearsAndIntaksFilter })
    })
  }, [])


  useEffect(() => {
    const cancelTokenSources = [];

    const getCourseList = async () => {
      setIsListLoaded(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      const listType = "assigned";
      try {
        let res = await GetProgrammeModulesCancelToken(listType, id, source.token)
        if (res.status === 200) {
          setAssessmentList(res.data.programs);
          setIsListLoaded(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          setIsListLoaded(false);
          console.log("error <<<<<< : ", error);
        }
      }
    }
    getCourseList()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateRow]);

  const dataToRender = () => {
    const searchData = search.toLowerCase();
    const filteredData = assessmentList.filter(item => {
      const numberMatch = item.number && item.number.toString().includes(searchData);
      const nameMatch = item.name && item.name.toString().toLowerCase().includes(searchData);
      const yearMatch = item.year && item.year.toLowerCase().includes(searchData);
      return numberMatch || nameMatch || yearMatch;
    });
  
    if (intake.arr.length) {
      const intakeFilter = filteredData.filter(item => {
        return intake.arr.includes(item.year && item.number ? item.year + "/" + item.number : "");
      });
      return intakeFilter;
    }
  
    if (courseType.arr.length) {
      const typeFilter = filteredData.filter(item => {
        return courseType.arr.includes(item.subject_type ? item.subject_type.toLowerCase() : "");
      });
      return typeFilter;
    }
  
    if (learningMethod.arr.length) {
      const methodFilter = filteredData.filter(item => {
        return learningMethod.arr.includes(item.type ? item.type.toLowerCase() : "");
      });
      return methodFilter;
    }
  
    return filteredData;
  };
  

  const sortingDate = (rowA, rowB) => {
    let date1 = new Date(
      rowA.start.toString().length == 10
        ? moment.unix(rowA.start).format(TABLE_DATE_FORMAT)
        : moment(parseInt(`${rowA.start}`)).format(TABLE_DATE_FORMAT)
    );
    let date2 = new Date(
      rowB.start.toString().length == 10
        ? moment.unix(rowB.start).format(TABLE_DATE_FORMAT)
        : moment(parseInt(`${rowB.start}`)).format(TABLE_DATE_FORMAT)
    );

    if (date2 > date1) {
      // console.log(`${date2} is greater than ${date1}`)
      return 1;
    } else if (date2 < date1) {
      // console.log(`${date1} is greater than ${date2}`)
      return -1;
    } else {
      // console.log(`Both dates are equal`)
      return 0;
    }
  };
  const sortingDateEnd = (rowA, rowB) => {
    let date1 = new Date(
      rowA.end.toString().length == 10
        ? moment.unix(rowA.end).format(TABLE_DATE_FORMAT)
        : moment(parseInt(`${rowA.end}`)).format(TABLE_DATE_FORMAT)
    );
    let date2 = new Date(
      rowB.end.toString().length == 10
        ? moment.unix(rowB.end).format(TABLE_DATE_FORMAT)
        : moment(parseInt(`${rowB.end}`)).format(TABLE_DATE_FORMAT)
    );

    if (date2 > date1) {
      // console.log(`${date2} is greater than ${date1}`)
      return 1;
    } else if (date2 < date1) {
      // console.log(`${date1} is greater than ${date2}`)
      return -1;
    } else {
      // console.log(`Both dates are equal`)
      return 0;
    }
  };

  const columns = useMemo(() => [
    {
      name: "Course Name",
      selector: "name",
      sortable: true,
      cell: (row) => {
        return row.name ? (
          <Link
            title={row.name}
            className="as-text-blue curser feature-name"
            href="javascript:void(0);"
            to={`/courseAdministration/coursesdetails/${row.course}/dashboard/show`}
          >
            <span title={row.name} className="textLimit100">{row.name}</span>
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      name: "Learning Method",
      selector: "type",
      sortable: true,
      cell: (row) => (
        <p>{row.type ? RenderLearningMethod(row.type).html : "-"}</p>
      ),
    },
    {
      name: "Start",
      selector: "start",
      sortable: true,
      sortFunction: sortingDate,
      cell: (row) => (
        row.start ? (
          <div className="dateTime">
            <p className="right-space">
              {row.start.toString().length == 10
                ? moment.unix(row.start).format(TABLE_DATE_FORMAT)
                : moment(parseInt(`${row.start}`)).format(TABLE_DATE_FORMAT)}
            </p>
            {/* <p>
              {row.start.toString().length == 10
                ? moment.unix(row.start).format(TABLE_TIME_FORMAT)
                : moment(parseInt(`${row.start}`)).format(TABLE_TIME_FORMAT)}
            </p> */}
          </div>
        ) : (
          "-"
        )
      ),
    },
    {
      name: "End",
      selector: "end",
      sortable: true,
      sortFunction: sortingDateEnd,
      cell: (row) => (
        row.end ? (
          <div className="dateTime">
            <span className="right-space">
              {row.end.toString().length == 10
                ? moment.unix(row.end).format(TABLE_DATE_FORMAT)
                : moment(parseInt(`${row.end}`)).format(TABLE_DATE_FORMAT)}
            </span>
            {/* <span>
              {row.end.toString().length == 10
                ? moment.unix(row.end).format(TABLE_TIME_FORMAT)
                : moment(parseInt(`${row.end}`)).format(TABLE_TIME_FORMAT)}
            </span> */}
          </div>
        ) : (
          "-"
        )
      ),
    },
    {
      name: "Intake",
      selector: "year",
      sortable: true,
      cell: (row) => <p>{row.year ? row.year + "-" + row.number : "-"}</p>,
    },
    {
      name: "Course Type",
      selector: "subject_type",
      sortable: true,
      cell: (row) => (
        <p>
          {row.subject_type ? RenderCourseType(row.subject_type).html : "-"}
        </p>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["cview"]}
              RenderError={() => (
                <button
                  className="btn btn-primary rounded-circle"
                  title="Open"
                  disabled
                >
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                className="btn btn-primary rounded-circle"
                title="Open"
                to={`/courseAdministration/coursesdetails/${row.course}/dashboard/show`}
              >
                <i className="fal fa-folder-open"></i>
              </Link>
            </PermissionsGate>
            <PermissionsGate
              scopes={["pcdelete"]}
              errorProps={{ disabled: true }}
            >
              <button
                className="btn btn-danger rounded-circle"
                title="unlink"
                onClick={() => handleDelete(row.course)}
              >
                <i className="fal fa-unlink"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Unlink it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("qualification", id);
        formData.append("intake", ID);
        DeleteAssignSubjectToProgram(formData)
          .then((res) => {
            Swal.fire("Unlinked!", "Course has been unlinked.", "success");
            setUpdateRow(!updateRow);
            setRefresh(!refresh)
          })
          .catch((err) => console.log("error :", err));
      }
    });
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = [
      "Course Name",
      "Learning Method",
      "Start",
      "End",
      "Intake",
      "Course Type",
    ];
    data = data?.map((row) => ({
      ...row,
      // ID: row.id ? row.id : "",
      "Course Name": row?.name ? row?.name : "-",
      "Course Type": row?.type ? RenderCourseType(row.subject_type).text : "-",
      "Start": row?.start
        ? row.start.toString().length == 10
          ? moment.unix(row.start).format(TABLE_DATE_FORMAT)
          : moment(parseInt(`${row.start}`)).format(TABLE_DATE_FORMAT)
        : "-",
      "End": row?.end
        ? row.end.toString().length == 10
          ? moment.unix(row.end).format(TABLE_DATE_FORMAT)
          : moment(parseInt(`${row.end}`)).format(TABLE_DATE_FORMAT)
        : "-",
      Intake: row.number ? row.year + "-" + row.number : "-",
      "Learning Method": row?.subject_type
        ? RenderLearningMethod(row.type).text
        : "-",
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [
          row["Course Name"],
          row["Learning Method"],
          row["Start"],
          row["End"],
          row["Intake"],
          row["Course Type"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        columnStyles: {
          0: {
            columnWidth: 45,
          },
          1: {
            columnWidth: 30,
          },
          2: {
            columnWidth: 20,
          },
          3: {
            columnWidth: 20,
          },
          4: {
            columnWidth: 20,
          },
          5: {
            columnWidth: 30,
          },
        },
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const resetFilter = () => {
    setSearch("");
    setIntake({ arr: [], checkObj: {} });
    setLearningMethod({ arr: [], checkObj: {} });
    setCourseType({ arr: [], checkObj: {} });
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="custom-table-div filter-search-icon card card-table-custom course-table-filter-sec">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn ml-2">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Learning Method"
                      optionArr={filterData.learning}
                      state={learningMethod}
                      setState={setLearningMethod}
                      renderLabelFunction={RenderLearningMethod}
                      uniqueId={"assignedLearningMethod"}
                    />
                    <Tablefilter
                      filterName="Intake"
                      optionArr={filterData.intake}
                      state={intake}
                      setState={setIntake}
                      uniqueId={"assignedIntake"}
                      isOptionReversed={true}
                    />
                    <Tablefilter
                      filterName="Course Type"
                      optionArr={filterData.type}
                      state={courseType}
                      setState={setCourseType}
                      renderLabelFunction={RenderCourseType}
                      uniqueId={"assignedCourseType"}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export spreadsheet"
                      onClick={() => {
                        exportData("xlsx", "Assigned-Course");
                      }}
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export CSV"
                      onClick={() => {
                        exportData("csv", "Assigned-Course");
                      }}
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export PDF"
                      onClick={() => {
                        exportData("pdf", "Assigned-Course");
                      }}
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate
                  RenderError={() => (
                    <button
                      className="btn btn-primary"
                      title="Create New"
                      disabled
                    >
                      <i className="fal fa-plus"></i> Add Course
                    </button>
                  )}
                  scopes={["pcadd"]}
                >
                  <Link
                    to={`#`}
                  >
                    <button className="btn btn-primary" title="Create New" data-toggle="modal" data-target="#Edit-Course">
                      <i className="fal fa-plus"></i> Add Course
                    </button>
                  </Link>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>

        {isListLoaded ? (
          <SkeletonTicketList />
        ) : (
          <DataTable
            data={dataToRender()}
            columns={columns}
            pagination={true}
            defaultSortField="start"
            defaultSortAsc={false}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        )}

      </div>
      <div
        // ref={editorRef}
        className="topic-add-modal modal fade"
        id="Edit-Course"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title"><i className="fal fa-plus"></i> Add Course</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvailableModules dropdowns={dropdowns} updateRow={updateRow} setUpdateRow={setUpdateRow} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignedModules;
