const initialState = [];

const sideBarLinksReducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SET':
            return [...action.payload];
        default:
            return state;
    }

}

export default sideBarLinksReducer;