import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import StatusRender, { ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";
import { InitialRender } from "../../common/Helper";
import Tablefilter from "../../common/Tablefilter";
import Swal from "sweetalert2";
import Hover from "../../common/Hover";
import { RenderLearningMethod } from "../../../utils/CommonGroupingItem";
import { GetAppealDropdown, GetAppealDropdownCancelToken, GetAppealslist, GetAppealslistCancelToken, GetfilterlistDashboard } from "../../../services/DashBoardServices";
import FilterComponent from "../../common/FilterComponent";
import UserColumn from "../../common/UserColumn";
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";

function AppealsList() {
  const history = useHistory();
  const location = useLocation();
  const { type } = useParams();
  const [search, setSearch] = useState("");
  const [value, onChange] = useState([]);
  const [status, setStatus] = useState([
    type.charAt(0).toUpperCase() + type.slice(1),
  ]);
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [searchLearningCheck, setSearchLearningCheck] = useState({});
  const [studyType, setStudyType] = useState([]);
  const [intakeNum, setIntakeNum] = useState([]);
  const [searchIntakeCheck, setSearchIntakeCheck] = useState({});
  const [searchQualificationCheck, setSearchQualificationCheck] = useState({});
  const [searchIntakeNumCheck, setSearchIntakeNumCheck] = useState({});
  const [dashboardList, setDashBoardList] = useState([]);
  const [courseDetails, setCourseDetails] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [programme, setProgramme] = useState([]);
  const bottomScroll = React.createRef();

  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [sortkey, setSortKey] = useState("enddate");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [intake, setIntake] = useState({ arr: [], checkObj: {} });
  const [intakeArr, setIntakeArr] = useState([]);
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
  const [appealDropdown, setAppealDropdown] = useState({programme :[], intake : [] , learning : []})
  const [dropDownLoading, setDropDownLoading] = useState(false)
  const [isShowingAllUser, setIsShowingAllUser] = useState([]);

  useEffect(() => {
    handleTableScroll()
  }, [loading]) 
  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateAppeals"));
    if (storedFilters) {
      setIntake(storedFilters.intake);
      setQualification(storedFilters.qualification);
      setLearningMethod(storedFilters.learningMethod);
      onChange(storedFilters.value);
      
    }
  }, []);
  useEffect(() => {
    const filterState = {
      intake,
      learningMethod,
      qualification,
      value
    };
    localStorage.setItem("filterStateAppeals", JSON.stringify(filterState));
  }, [  intake, learningMethod, qualification, value]);
  useEffect(() => {
    const cancelTokenSources = [];
    
    const fetchData = async () => {
      setLoading(true);
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        let values = {
          page: page,
          limit: perPage,
          viaQualification: qualification,
          intake: intake.arr,
          vialearnMethod: learningMethod.arr,
          viaStatus: status,
          key: sortkey,
          sort: sortOrder,
          exportStaus: false,
          viaSearch: search,
        }
        const res = await GetAppealslistCancelToken(values,source.token);

        if(res.status == 200){
          setDashBoardList(res.data.result);
          setTotalRows(res.data.Pagination.total);
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    fetchData();
  
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [
    page,
    perPage,
    qualification,
    searchQualificationCheck,
    intake,
    studyType,
    searchLearningCheck,
    status,
    searchStatusCheck,
    sortkey,
    sortOrder,
    search,
    learningMethod
  ]);

  // useEffect(() => {
  //   setDropDownLoading(true)
  //   let data={
  //     viaStatus: status
  //   }
  //   GetAppealDropdown(data)
  //   .then((data) => {
  //     setAppealDropdown({...data.data, programme : data.data.programme, intake : data.data.yearsAndIntaks, learning : data.data.intakeType});
  //     setDropDownLoading(false)
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     setDropDownLoading(false)
  //   });
  // }, [status])

  useEffect(() => {
    setDropDownLoading(true)
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        let values = {
          viaStatus: status
        }
        const data = await GetAppealDropdownCancelToken(values,dropdownSource.token);
        if(data.status == 200){
          setAppealDropdown({...data.data, programme : data.data.programme, intake : data.data.yearsAndIntaks, learning : data.data.intakeType});
          setDropDownLoading(false)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setDropDownLoading(false)
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [status]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");

    setQualification([]);
    setSearchQualificationCheck({});

    // setStatus([]);
    setSearchStatusCheck({});

    setIntake({ arr: [], checkObj: {} });
    setSearchIntakeCheck({});

    setIntakeNum([]);
    setSearchIntakeNumCheck({});

    setStudyType([]);
    setSearchLearningCheck({});
    onChange([]);
    setLearningMethod({ arr: [], checkObj: {} })
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "assignmentName",
      sortable: true,
      sortField: "name",
      cell: (row) => (
        <span
          className="overflow-ellipsis2 fst_line2"
          >
          <Link
            className="as-text-blue curser textLimit2"
            title={row.assignmentName}
            to={`/courseAdministration/coursesdetails/${row.intakeID}/assessments/open/assigned/submissionsAndResults/${row.assignmentID}/appealList/${row.student_id}`}
          >
            {row.assignmentName ? row.assignmentName : "-"}
          </Link>
        </span>
      ),
    },
    {
      name: "Full Name",
      selector: "student_name",
      sortable: true,

      cell: (row) => (
        // <span className="textLimit76" title={row.student_name}>
        //   {row.student_name ? row.student_name : "-"}
        // </span>
        <>
          {row?.student_name ? (
            <div className="assigned-title-block-new">
              <div className="assigned-title-itm">
                <span
                  className={
                    "assigned-title-blk  name-icon cat-dark-red " +
                    ColorRender("lecturer")
                  }
                >
                  {row?.student_profile ? (
                    <img
                      src={`${IMAGE_URL}/${row?.student_profile.replaceAll(
                        "/home/myaie/public_html/",
                        ""
                      )}`}
                      alt="AIE"
                    />
                  ) : (
                    InitialRender([
                      row?.student_name
                    ])
                  )}
                  <span className={`profile-box-2-status ${row?.student_status ? row.student_status.toLowerCase()=="online" ? "Online" :row.student_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                    <i className="fas fa-circle"></i>
                  </span>
                  <Hover
                    firstName={row?.student_name}
                    photo={row?.student_profile}
                    email={row?.student_email}
                    mobile={row?.student_mobile}
                    right={true}
                    role={"lecturer"}
                    status={row?.student_status} 
                    activity_status={row?.student_activity_status}
                  />
                </span>
                <p
                  className="as-text-blue curser textLimit76"
                  title={row?.student_name ? row["student_name"] : "-"}
                  onClick={() => history.push(`/studentAdministration/students/open/${row?.student_id}/general`)}
                >
                  {row?.student_name ? row["student_name"] : "-"}
                </p>
              </div>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Code",
      selector: "assignmentCode",
      sortable: true,
      sortField: "code",
      cell: (row) => (
        <p className="textLimit76" title={row.assignmentCode}>
          {row && row.assignmentCode ? row.assignmentCode : "-"}
        </p>
      ),
    },
    // {
    //   name: "End",
    //   selector: "assignmentDateClose",
    //   sortable: true,
    //   sortField: "enddate",
    //   cell: (row) => (
    //     <div>
    //       {row.assignmentDateClose ? (
    //         <>
    //           <p className="right-space">
    //             {moment.unix(row.assignmentDateClose).format(TABLE_DATE_FORMAT)}
    //           </p>
    //           <p className="right-space">
    //             {moment.unix(row.assignmentDateClose).format(TABLE_TIME_FORMAT)}
    //           </p>
    //         </>
    //       ) : (
    //         "-"
    //       )}
    //     </div>
    //   ),
    // },
    status.includes("Approved") && {
      name: "Status",
      selector: "ass_submitted_status",
      sortable: true,
      sortField: "ass_submitted_status",
      cell: (row) =>
        row.ass_submitted_status ? (
          <StatusRender status={"assessment" + row.ass_submitted_status} />
        ) : (
          "-"
        ),
    },
    {
      name: "Learning Method",
      selector: "study_type",
      sortable: true,
      cell: (row) => (
        <p className="learningMethodText">
          {row && row.learnType ? RenderLearningMethod(row.learnType,true).html : "-"}
        </p>
      ),
    },
    {
      name: "Due Date",
      selector: "mark_due_date",
      sortable: true,
      sortField: "markduedate",
      cell: (row) => (
        <>
          {row.mark_due_date ? (
            <>
              <p className="right-space">
                {moment.unix(row.mark_due_date).format(TABLE_DATE_FORMAT)}
              </p>
              <p className="right-space">
                {moment.unix(row.mark_due_date).format(TABLE_TIME_FORMAT)}
              </p>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    status.includes("Approved") && {
      name: "Appeal Extended Date",
      selector: "appealExtendedDate",
      sortable: true,

      cell: (row) => (
        <>
          {row.appealExtendedDate ? (
            <>
              <p className="right-space">
                {moment.unix(row.appealExtendedDate).format(TABLE_DATE_FORMAT)}
              </p>
              <p className="right-space">
                {moment.unix(row.appealExtendedDate).format(TABLE_TIME_FORMAT)}
              </p>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    status.includes("Approved") && {
      name: "Assessment Uploaded",
      selector: "AssessmentUploaded",
      sortable: true,
      cell: (row) =>
        row && row.AssessmentUploaded ? row.AssessmentUploaded : "-",
    },
    (status.includes("Approved") || status.includes("Declined")) && {
      name: "Appeal Date",
      selector: "appealDate",
      sortable: true,

      cell: (row) => (
        <>
          {row.appealDate ? (
            <>
              <p className="right-space">
                {moment.unix(row.appealDate).format(TABLE_DATE_FORMAT)}
              </p>
              <p className="right-space">
                {moment.unix(row.appealDate).format(TABLE_TIME_FORMAT)}
              </p>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Course",
      selector: "courseName",
      sortable: true,
      sortField: "course",
      cell: (row) => (
        <span className="overflow-ellipsis2 fst_line2">
          <Link
            className="as-text-blue curser"
            title={row.courseName}
            to={`/courseAdministration/coursesdetails/${row.intakeID}/dashboard/show`}
          >
            {row.courseName ? row.courseName : "-"}
          </Link>
        </span>
      ),
    },
    {
      name: "Programme",
      selector: "qualification",
      sortable: true,
      sortField: "qualification",
      cell: (row, index) => (
        <span className="overflow-ellipsis2 fst_line2">
          <Link
            className="as-text-blue curser"
            title={row.qualification}
            to={`/courseAdministration/Programmes/programme/open/${row.qualificationID}/details`}
          >
            {row.qualification ? row.qualification : "-"}
          </Link>
        </span>
      ),
    },
    {
      name: "Intake",
      selector: "intakeYear",
      sortable: true,
      sortField: "intake",
      cell: (row) => (
        <p>
          {row.intakeYear || row.intakeNumber ? (
            <>
              {" "}
              {row.intakeYear ? row.intakeYear : ""}-{row.intakeNumber ? row["intakeNumber"] : ""}
            </>
          ) : (
            "-"
          )}
        </p>
      ),
    },
    {
      name: "Marker",
      selector: "MARKER",
      sortable: true,
      sortField: "marker",
      cell: (row) => <UserColumn
        multipleUserStacks
        isShowingAllUser={isShowingAllUser}
        setIsShowingAllUser={setIsShowingAllUser}
        usersArray={row.markerList}
        rowId={row.id}
        usersArrayData={{
          firstName: "Firstname",
          lastName: "Lastname",
          imgUrl: "picture_me",
          email: "Email",
          mobile: "Mobile",
          userId: "UserID",
          userActiveStatus: "Status",
          right: false
          // role: "lecturer"
        }}
      />
    },
    {
      name: "Actions",
      // selector: "Action",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              className="btn btn-primary rounded-circle"
              to={`/courseAdministration/coursesdetails/${row.intakeID}/assessments/open/assigned/submissionsAndResults/${row.assignmentID}/appealList/${row.student_id}`}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data;

    const header = [
      "Name",
      "Full Name",
      "Code",
      // "End",
      "Status",
      "Learning Method",
      "Due Date",
      "Appeal Extended Date",
      "Assessment Uploaded",
      "Appeal Date",
      "Course",
      "Programme",
      "Intake",
      "Marker",
    ];

    if (!status.includes("Approved")) {
      header.splice(header.indexOf("Full Name"), 1);
    }
    if (!status.includes("Approved")) {
      header.splice(header.indexOf("Status"), 1);
    }
    if (!status.includes("Approved")) {
      header.splice(header.indexOf("Appeal Extended Date"), 1);
    }
    if (!status.includes("Approved")) {
      header.splice(header.indexOf("Assessment Uploaded"), 1);
    }

    if (!status.includes("Approved") && !status.includes("Declined")) {
      header.splice(header.indexOf("Appeal Date"), 1);
    }

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    GetAppealslist({
      page: page,
      limit: perPage,
      viaQualification: qualification,
      intake: intake.arr,
      vialearnMethod: learningMethod.arr,
      viaStatus: status,
      key: sortkey,
      sort: sortOrder,
      exportStaus: "true",
      viaSearch: search,
    }).then((res) => {
      data = res.data.result;
      data = data.map((row) => ({
        "Name": row.assignmentName,
        "Full Name": row.student_name ? row.student_name : "-",
        "Code": row.assignmentCode,
        // "End": moment
        //   .unix(row.assignmentDateClose)
        //   .format(TABLE_DATE_TIME_FORMAT),
        Status: row.ass_submitted_status ? row.ass_submitted_status : "-",
        "Learning Method": row.learnType ? RenderLearningMethod(row.learnType).text : "-",
        "Due Date": moment
          .unix(row.mark_due_date)
          .format(TABLE_DATE_TIME_FORMAT),
        "Appeal Extended Date": row.appealExtendedDate
          ? moment.unix(row.appealExtendedDate).format(TABLE_DATE_TIME_FORMAT)
          : "-",
        "Assessment Uploaded": row.AssessmentUploaded
          ? row.AssessmentUploaded
          : "-",
        "Appeal Date": row.appealDate
          ? moment.unix(row.appealDate).format(TABLE_DATE_TIME_FORMAT)
          : "-",
        "Course": row.courseName,
        Programme: row.qualification,
        Intake: `${row.intakeYear}-${row.intakeNumber}`,
        Marker: row.MARKER,
      }));

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        setTimeout(() => {
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.map((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          if (status.includes("Pending")) {
            return [
              row["Name"],
              row["Code"],
              // row["End"],
              row["Learning Method"],
              row["Due Date"],
              row["Course"],
              row["Programme"],
              row["Intake"],
              row["Marker"],
            ];
          }
          if (status.includes("Approved")) {
            return [
              row["Name"],
              row["Full Name"],
              row["Code"],
              // row["End"],
              row["Status"],
              row["Learning Method"],
              row["Due Date"],
              row["Appeal Extended Date"],
              row["Assessment Uploaded"],
              row["Appeal Date"],
              row["Course"],
              row["Programme"],
              row["Intake"],
              row["Marker"],
            ];
          }
          if (status.includes("Declined")) {
            return [
              row["Name"],
              row["Code"],
              // row["End"],
              row["Learning Method"],
              row["Due Date"],
              row["Appeal Date"],
              row["Course"],
              row["Programme"],
              row["Intake"],
              row["Marker"],
            ];
          }
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,
          columnStyles: {
            0: {
              columnWidth: 15,
            },
            1: {
              columnWidth: 10,
            },
            2: {
              columnWidth: 10,
            },
            3: {
              columnWidth: 10,
            },
            4: {
              columnWidth: 10,
            },
            5: {
              columnWidth: 15,
            },
            6: {
              columnWidth: 10,
            },
            7: {
              columnWidth: 15,
            },
            8: {
              columnWidth: 15,
            },
            9: {
              columnWidth: 15,
            },
            10: {
              columnWidth: 15,
            },
            11: {
              columnWidth: 15,
            },
            12: {
              columnWidth: 15,
            },
            13: {
              columnWidth: 15,
            },
          },
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    });
  };

  const handlePageChange = (pageNo) => {
    setPage(() => pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(() => newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column?.selector);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const listOfColumns = (col) => {
    let columnData = [];
    let pendingColumn = ["Name", "Code", "End Date", "Learning Method", "Due Date", "Course", "Programme", "Intake", "Marker", "Actions"];
    let ApprovedColumns = [
      "Name",
      "Full Name",
      "Code",
      "End Date",
      "Status",
      "Learning Method",
      "Due Date",
      "Appeal Extended Date",
      "Assessment Uploaded",
      "Appeal Date",
      "Course",
      "Programme",
      "Intake",
      "Marker",
      "Actions"
    ];
    let DeclinedColumns = [
      "Name",
      "Code",
      "End Date",
      "Learning Method",
      "Due Date",
      "Appeal Date",
      "Course",
      "Programme",
      "Intake",
      "Marker",
      "Actions"
    ];
    let currentColumn = col;
    if (status.includes("Approved")) {
      columnData = currentColumn.filter((item) => ApprovedColumns.includes(item.name));


      return columnData;
    }
    if (status.includes("Pending")) {
      columnData = currentColumn.filter((item) => pendingColumn.includes(item.name));
      return columnData;
    }
    if (status.includes("Declined")) {
      columnData = currentColumn.filter((item) => DeclinedColumns.includes(item.name));
      return columnData;
    }
  }

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        {/* filters */}
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={(e) => handleSearchFilter(e)}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    {/* Learning methord Type filter */}
                    <Tablefilter
                      filterName="Learning Method"
                      optionArr={appealDropdown.learning}
                      state={learningMethod}
                      setState={setLearningMethod}
                      renderLabelFunction={RenderLearningMethod}
                    />
                    {/* Qualification filter */}
                    <div className="assessment-table-filter">
                      <div className="btn-type-filter dropdown-comman assesment-dropdown">
                        <div className="dropdown multiselect">
                          <button
                            className={`btn btn-default dropdown-toggle ${value?.length ? "btn-selected" : ""
                              } `}
                            type="button"
                            id="dropdownMenu1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            title="Programme"
                          >
                            <span>
                              Programme <i className="fal fa-angle-down"></i>
                            </span>
                          </button>
                          <form className="dropdown-menu remove-list-style">
                            <ul aria-labelledby="dropdownMenu1">
                              <div className="dropdown-filter-pagination">
                                {appealDropdown && appealDropdown.programme && appealDropdown.programme.length ? (
                                  <FilterComponent
                                    value={value}
                                    onChange={onChange}
                                    dataResponse={appealDropdown.programme}
                                    backendResponse={setQualification}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>
                    {/* End of Qualification filter */}

                    {/* Intake year filter */}
                    <Tablefilter
                      filterName={"Intake"}
                      optionArr={appealDropdown.intake}
                      state={intake}
                      setState={setIntake}
                      isOptionReversed={true}
                      isIntake={true}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>

                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Appeals");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Appeals");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Appeals");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="topic-add-modal modal fade" id="notifications" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="topic-add-modal-content text-center font-weight-bold">
                  <i className="fal fa-comment"></i> Appeal
                </div>
                <hr />
                <div className="card-header">Assessment Details</div>
                <div className="card-body-inr card-body-info">
                  <div className="basic-info-box">
                    <div className="row">
                      <div className="col-md-6 col-lg-3">
                        <div className="basic-info-box-list">
                          <ul>
                            <li>
                              <span className="title">Assessment Name :</span>
                            </li>
                            <li>
                              <span className="title ">Opening Date :</span>
                            </li>
                            <li>
                              <span className="title ">Due Date :</span>
                              {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                              {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                              {/* </Link> */}
                            </li>
                            <li>
                              <span className="title ">Appeal Submission Date :</span>
                              {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                              {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                              {/* </Link> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="basic-info-box-list">
                          <ul>
                            <li>
                              <span className="title">Assessment Status:</span>
                              {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/tickets/show" }}> */}
                              {/* <span>{basicInfo.amnt_tkt || basicInfo.amnt_tkt == 0 ? basicInfo.amnt_tkt : "-"}</span> */}
                              {/* </Link> */}
                            </li>
                            <li>
                              <span className="title">Code:</span>
                              {/* <span>{basicInfo.last_modified ? basicInfo.last_modified : "-"}</span> */}
                              {/* <span>17 Jan ’22 11:17 am (Smith Jhone)</span> */}
                            </li>
                            <li>
                              <span className="title ">Your Result :</span>
                              {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                              {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                              {/* </Link> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card-header">Assessment Detiail</div>
                    <div className="card-body-inr card-body-info">
                      <div className="basic-info-box"></div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="basic-info-box-list">
                            <ul>
                              <li>
                                <span className="title">Reason for not submitting assessment:</span>
                                {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/tickets/show" }}> */}
                                {/* <span>{basicInfo.amnt_tkt || basicInfo.amnt_tkt == 0 ? basicInfo.amnt_tkt : "-"}</span> */}
                                {/* </Link> */}
                              </li>
                              <li>
                                <span className="title">Description:</span>
                                {/* <span>{basicInfo.last_modified ? basicInfo.last_modified : "-"}</span> */}
                                {/* <span>17 Jan ’22 11:17 am (Smith Jhone)</span> */}
                              </li>
                              <li>
                                <span className="title ">Supporting File :</span>
                                {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                                {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                                {/* </Link> */}
                              </li>
                              <li>
                                <span className="title ">Message Student :</span>
                                <textarea />
                                {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                                {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                                {/* </Link> */}
                              </li>
                              <li>
                                <span className="title ">Extended assessment date :</span>
                                <input type="date" />
                                {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                                {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                                {/* </Link> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <PermissionsGate scopes={["cedit"]} errorProps={{ disabled: true }}> */}
                <button type="button" className="btn btn-primary" data-dismiss="modal">
                  <i className="fal fa-check"></i>
                  Send Response
                </button>
                {/* </PermissionsGate> */}
                <button type="button" className="btn btn-danger" data-dismiss="modal">
                  <i className="fal fa-times"></i>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          paginationDefaultPage={page}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          data={dashboardList}
          columns={listOfColumns(columns)}
          defaultSortAsc={false}
          defaultSortField="assignmentDateClose"
          onSort={handleSort}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
    </div>
  );
}

export default AppealsList;
