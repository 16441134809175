import React from "react";
import { Link } from "react-router-dom";
import BackgroundImage from "../assets/images/login-bg.jpg";
import HeaderImage from "../assets/images/my-admin-portal-logo.png";
import BgImage from "../assets/images/login-gradient-bg.png";
import logoImage from "../assets/images/admin-logo.png";
import ResetPasswordForm from "../components/ResetPasswordForm";
import { connect } from "react-redux";

/** map state */
const mapStateToProps = (state) => ({ brandVisualsConfig: state.brandVisuals.config });

class ResetPassword extends React.Component {
  constructor(props){
    super(props);

  }
  componentDidMount(){
    // console.log(this.props)
  }
  render() {
    return (
      <>
        <div
          className="new-custom-login portal-login portal-forgot-password d-flex align-items-center"
          style={{
            // "background-image": "url(" + BackgroundImage + ")"
            backgroundColor: "#10284b",
          }}
        >
        <img src={this.props?.brandVisualsConfig?.background_logo ? this.props.brandVisualsConfig.background_logo : null}/>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="portal-login-card text-center">
                  <div className="card-header">
                    <div className="card-header-img card-header-text">
                      <img src={this.props?.brandVisualsConfig?.login_header ? this.props.brandVisualsConfig.login_header : null}/>
                      {/* <div className="header-text">
                        <h4>AMI</h4>
                        <p>ADMINISTRATION & MANAGEMENT INTRANET</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-body-inr">
                      <div className="portal-login-info">
                        {/* <!-- <h1>COMMUNICATOR</h1> --> */}
                        <h3>Forgot your Password</h3>
                        <h4>Please enter the email address associated with your account and we'll send you a link to reset your password.</h4>
                      </div>
                      <div className="portal-login-form">
                        <ResetPasswordForm />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Link title="Back to Login" to="/">
                      Back to Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}


export default connect(mapStateToProps) (ResetPassword);
