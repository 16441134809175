import React, { createRef, useCallback, useEffect, useState, useMemo, useImperativeHandle } from "react";
import ReactHtmlParser from "react-html-parser";
import TextareaAutosize from "react-autosize-textarea";
import FeedComments from "./FeedComments";
import FeedAttachments from "./FeedAttachments";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SocketConnection from "../../../utils/SocketConnection";
import _ from "underscore";
import { FilePond, File, registerPlugin, setOptions } from "react-filepond";
import "filepond/dist/filepond.min.css";
import {
  API_BASE,
  IMAGE_URL,
  TABLE_DATE_FORMAT,
TABLE_DATE_TIME_FORMAT,
TABLE_TIME_FORMAT,
} from "../../../utils/Constants";
import { getToken, removeUserSession } from "../../../utils/Auth";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../../assets/css/filePond.css";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "../../../utils/defaultStyle";
import defaultMentionStyle from "../../../utils/defaultMentionStyle";
import { classStatusWidgets } from "./../../common/Helper";
import UserHover from "./../../common/UserHover";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
  Modifier,
  Entity,
  CompositeDecorator,
  convertFromHTML,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HtmlInputEditorShow from "../../common/HtmlInputEditorShow";
import HtmlInputEditor from "../../common/HtmlInputEditorReply";
import moment from "moment";
import $ from "jquery";
import Swal from "sweetalert2";
import {
  GetMentionedUserDetails,
  readCommentMessages,
} from "../../../services/QualificationService";
import draftToHtml from "draftjs-to-html";
import renderHTML from "react-render-html";
import { setHoverUserData } from "../../../store/actions";
import { ShowMoreHide } from "../../common/ShowMoreHide";
import { useRef } from "react";
import htmlToDraft from "html-to-draftjs";
import Hover from "../../common/Hover";
import ami_logo from "../../../assets/images/ami-logo-icon.svg"
import { PopupContent } from "semantic-ui-react";
import { Popup } from "semantic-ui-react";
import "../../../assets/js/rating.js";
import { rollsWidget } from "./../../common/Helper";
import { useHistory } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import hasPermission from "../../../utils/hasMultiplePermission";
import { forwardRef } from "react";
import { Link } from "react-router-dom";
import { ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import HoverChat from "../../common/HoverChat";

registerPlugin(FilePondPluginImagePreview);

const FeedMessage = forwardRef((props, ref) => {
  const user = useSelector((state) => state.user);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [chat, setChat] = useState(null);
  const commentTxt = createRef();
  const btnAttachment = createRef();
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const filePondRef = createRef();
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [showFilePond, setShowFilePond] = useState(false);
  const [removeFile, setRemoveFile] = useState(null);
  const [taggedUsers, setTaggedUsers] = useState([]);
  const [users, setUsers] = useState("");
  const [copyText, setCopyText] = useState(0);
  // const [showReplyBox, setShowReplyBox] = useState(true);
  const [isImportant, setIsImportant] = useState(false);
  const [isMention, setIsMention] = useState(false);
  const [isImportantReply, setIsImportantReply] = useState(false);
  const [showcursure, setShowCurser] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [showHideCOmm, setShowHideCOmm] = useState("");
  const [cssObj, setCssObj] = useState({ display: "none" });
  const [hoverUser, setHoverUser] = useState({});
  const [mentionedUserData, setMentionedUserData] = useState([]);
  const dispatch = useDispatch();
  const [row, setClasses] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [userRole, setUserRole] = useState("");
  const history = useHistory();
  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("")

  const originalValues = useCallback((s) => {
    try {
      return decodeURIComponent(s);
    } catch (error) {
      
      return s;
    }
  }, []);
  const InitalRender = useMemo(() => data => {
    if (!data) {
      return 'NA';
    }
    const initials = data
      .split(" ")
      .filter(Boolean) // Removes empty strings from array
      .map(word => word[0].toUpperCase())
      .join("")
      .slice(0, 2);
    return initials || 'NA';
  }, []);

  useEffect(() => {
    setClasses(props?.feed?.ClassArray != null && props?.feed?.ClassArray);
  }, [props.roomId]);

  var isSystemBot = props?.feed?.ClassArray != null && props?.feed?.ClassArray ? true : false;
  moment.tz.setDefault("Africa/Johannesburg");
  var currentTime = moment().unix();  
  var sst = moment(row.class_from, ["h:mm A"]).format("HH:mm:ss");
  var eet = moment(row.class_to, ["h:mm A"]).format("HH:mm:ss");
  var st = moment(row.class_date + " " + sst);
  var et = moment(row.class_date + " " + eet);
  var start_time = st.unix();
  var end_time = et.unix();
  var start_time_live = moment(st).subtract(15, "minutes").unix();
  var returnValue;
  var returnValue1;
  var star = "";
  var notStar = "";
  var avgRating = row.ratingArr && row.ratingArr.avg_rating != null ? row.ratingArr.avg_rating : 0;
  var remainingRating = 5 - avgRating;
  for (let index = 1; index <= avgRating; index++) {
    star += `<i class="fas fa-star" key={row.id}></i>`;
  }
  for (let index = 1; index <= remainingRating; index++) {
    notStar += `<i class="fal fa-star" key={row.id}></i>`;
  }
  // row.student_rating > 0
  //   ? (returnValue = (
  //     <div className="col-12 meeting-rating-start-col m-0" key={row.id}>
  //       <div className="meeting-rating-start d-flex ">
  //         <span className="stars-system-bot">
  //           {ReactHtmlParser(star)}
  //           {ReactHtmlParser(notStar)}
  //         </span>
  //       </div>
  //     </div>
  //   ))
  //   : (returnValue = (
  //     <div className="col-12 meeting-rating-start-col" key={row.id}>
  //       <div className="meeting-rating-start d-flex">
  //         <input
  //           type="number"
  //           className="rating"
  //           id={`class_rating_${row.id}`}
  //         />
  //         <span className="ml-2">How Was Your Class ?</span>
  //       </div>
  //     </div>
  //   ));
  // row.student_rating > 0
  //   ? (returnValue1 = (
  //     <div className="col-12 meeting-leave-comment-col m-0" key={row.id}>
  //       <span> {row.student_comment?.trim()}</span>{" "}
  //     </div>
  //   ))
  //   : (returnValue1 = (
  //     <div className="col-12 meeting-leave-comment-col" key={row.id}>
  //       <div className="meeting-leave-comment">
  //         <textarea
  //           rows="2"
  //           className="form-control"
  //           placeholder="leave a comment"
  //           id={`class_comment_${row.id}`}
  //         ></textarea>
  //         <a
  //           className="btn btn-primary submit-rating-global-list-view w-100"
  //           data-id={`${row.id}`}
  //           // onClick={() => submitRatingGlobal(row?.id)}
  //         >
  //           Submit Feedback
  //         </a>
  //       </div>
  //     </div>
  //   ));

    

    // useEffect(() => {
    //   let mounted = true
    //   GetSupportTeamService({ sid: props.subjectId })
    //     .then((res) => {
    //       if (mounted) {
    //         setTeam(res.data.team);
    //         if (res.data.team) {
    //           let tempObj = Object.keys(res.data.team).map(item => {
    //             return {
    //               ...res.data.team[item], role: item
    //             }
    //           });
  
    //           let finalObj = tempObj.find(item => {
    //             return item.id == props.feed.admin_id
    //           })
    //           setUserRole(finalObj ? finalObj.role : props.feed.admin_id === null ? "student" : props.feed.adminuser.job_title)
    //           // let userTitle = Object.keys(teamArr[0]).find((item)=>{
    //           // if(props.feed.admin_id != null){
    //           //   if(teamArr[0][item].id == props.feed.admin_id) {
    //           //     console.log("conssosos", item)
    //           //     setUserRole(item)
    //           //   }
    //           // } else if(props.feed.admin_id == null){
    //           //   setUserRole("student")
    //           // } else {
    //           //   setUserRole(props.feed.adminuser.job_title)
    //           // }
    //           // })
    //         }
    //       }
    //     })
    //     .catch((error) => { });
  
    //   return function cleanup() {
    //     mounted = false
    //   }
    // }, [props.feed]);
    const removeTagsFromStr = (str) => {
      str = str.replace(/<[^>]+>/g, " ");
      str = str.replace(/&amp;/g, "&");
      if (str === null || str === "") return false;
      else str = str.toString();
  
      return str.replace(/(<([^>]+)>)/gi, "");
    };
    const getTextContentLength = (text, charLen = 100) => {
      text = removeTagsFromStr(text);
      // For Text that is shorter than desired length
      if (text.length <= charLen) return text;
  
      // If text is longer than desired length & showMore is true
      if (text.length > charLen && showMore) {
        return (
          <>
            <p>
              {text}&nbsp;
              <a
                href
                className="class-schedule h5 p"
                onClick={() => setShowMore(false)}
              >
                Show less
              </a>
            </p>
          </>
        );
      }
      // If text is longer than desired length & showMore is false
      if (text.length > charLen) {
        return (
          <>
            <p>
              {text.slice(0, charLen)}&nbsp;
              <a
                href
                className="class-schedule h5"
                onClick={() => setShowMore(true)}
              >
                Show more
              </a>
            </p>
          </>
        );
      }
    };
  const HandleSpan = (props) => {
    return (
      <span style={styles.handle} data-offset-key={props.offsetKey}>
        {props.children}
      </span>
    );
  };

  function handleStrategy(contentBlock, callback, contentState) {
    findWithRegex(HANDLE_REGEX, contentBlock, callback);
  }

  const compositeDecorator = new CompositeDecorator([
    { strategy: handleStrategy, component: HandleSpan },
  ]);
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty(compositeDecorator)
  );
  const compositeDecorator2 = new CompositeDecorator([
    { strategy: handleStrategy, component: HandleSpan },
  ]);
  const [editorState2, setEditorState2] = React.useState(() =>
    EditorState.createEmpty()
  );
  const HANDLE_REGEX = /\@[\w]+/g;
  const styles = {
    editor: {
      border: "1px solid #ddd",
      cursor: "text",
      fontSize: 16,
      minHeight: "40px",
      padding: 10,
      background: "#000",
    },
    handle: {
      color: "rgba(98, 177, 254, 1.0)",
      direction: "ltr",
      unicodeBidi: "bidi-override",
    },
  };

  function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  }

  const copyEmailText = (text) => {
    setCopyText(1);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setCopyText(0);
    }, 1000);
  };

  useEffect(() => {
    $(document).ready(function () {
      // $("body").mousemove(function (e) {
      //   if (e.target.className !== "wysiwyg-mention") $("#divtoshow").css({ position: "absolute", visibility: 'hidden', opacity: 0, 'z-index': 0 }).hide();
      // })
      $("#divtoshow").hover(
        function (a) {
          $("#divtoshow")
            .css({
              top: -5 + a.clientY,
              left: -10 + a.clientX,
              position: "absolute",
              visibility: "visible",
              opacity: 1,
              "z-index": 999,
            })
            .show();
        },
        function () {
          $("#divtoshow")
            .css({
              position: "absolute",
              visibility: "hidden",
              opacity: 0,
              "z-index": 0,
            })
            .hide();
        }
      );

      $(".class-list-row").on("click", function () {
        const t = setTimeout(() => {
          $("input.rating[type=number], div.rating").each(function () {
            $(this).rating();
          });

        return () =>{ clearTimeout(t)}
        }, 100);
      });

      $(`body`).on("click", ".wysiwyg-mention", function (a) {
        a.preventDefault();
        return false;
      });
      $(`.f-m-cont-${props.ind} .wysiwyg-mention`).hover(
        async function (a) {
          // console.clear()
          // console.log("hover called")
          a.preventDefault();
          let thi = $(this);
          let user_id = thi.attr("href");
          if (!user_id || user_id === null || user_id === "" || user_id === "#")
            return;
          await GetMentionedUserDetails(user_id)
            .then((data) => {
              dispatch(
                setHoverUserData(
                  data && data.data && data.data.data ? data.data.data : {}
                )
              );
              // $("#divtoshow").css({ top: 10 + a.clientY, left: 250 + a.clientX, position: "absolute", visibility: 'visible', opacity: 1, 'z-index': 999 }).show();
              $("#divtoshow")
                .css({
                  top: -5 + a.clientY,
                  left: -10 + a.clientX,
                  position: "absolute",
                  visibility: "visible",
                  opacity: 1,
                  "z-index": 999,
                })
                .show();
            })
            .catch((error) => {
              $("#divtoshow")
                .css({
                  top: a.clientY,
                  left: a.clientX,
                  position: "absolute",
                  visibility: "hidden",
                  opacity: 0,
                  "z-index": 0,
                })
                .hide();
            });
        },
        function () {
          // dispatch(setHoverUserData({}));
          $("#divtoshow")
            .css({
              position: "absolute",
              visibility: "hidden",
              opacity: 0,
              "z-index": 0,
            })
            .hide();
        }
      );
    });
  }, []);

  // function saveMenInfo(id, details) {
  //   console.log("insideeeeeeeeeeeee ----> ", mentionedUserData);
  //   console.log("detials ========> ", details);
  //   setMentionedUserData(prev => [...prev, details])
  // }

  // function saveMenInfo(id, details) {
  //   console.clear();

  //   // console.log("userData ---> ", temp);
  //   // let prev = [...mentionedUserData];
  //   // console.log("prevvvvvvvvvvvvv>>>> ", prev);
  //   // let arr = prev.push(temp);
  //   // console.log("arrrrrrrrr ---> ", arr);
  //   // localStorage.setItem('mentions', JSON.stringify(arr));
  //   setMentionedUserData(prev => {
  //     console.log("mentionedUserData ---> ", prev);
  //     let temp = {};
  //     let arr = [...prev];
  //     prev.map(i => {
  //       console.log("iiiiiiiiiiiiiiiiiiiii: ", i);
  //       if (i && (i?.id.toString() !== id.toString())) {
  //         temp = { ...details, id: id };
  //         arr.push(temp);
  //       }
  //     })
  //     if(!prev.length) temp = { ...details, id: id };
  //     localStorage.setItem('mentions', JSON.stringify(arr));

  //     return [...prev, temp]
  //   });
  // }

  // function getMenInfo(id) {
  //   console.log("getMenInfo called: ", id);
  //   let details = {};
  //   console.log("state inside  >>>> ", mentionedUserData);
  //   mentionedUserData.map((e, i) => {
  //     console.log("eeeeeeeeeeeeeeee > ", e);
  //     if (e.id.toString() === id.toString()) {
  //       details = e;
  //     }
  //   })
  //   console.log("details: =-=-=-> ", details);
  //   if (Object.keys(details).length) {
  //     // setHoverUser(details);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  useEffect(() => {
    if (props.taggingUsers && props.taggingUsers.length > 0) {
      let usersArr = props.taggingUsers;
      usersArr.map((i) => {
        i.text = i.display;
        i.value = i.display;
        i.url = i.id + "-" + i.type;
      });
      setUsers(usersArr);
    }
  }, []);

  useEffect(() => {
    $("#spanhovering").hover(
      function (event) {
        $("#divtoshow").css({ top: event.clientY, left: event.clientX }).show();
      },
      function () {
        $("#divtoshow").hide();
      }
    );
  }, []);

  useEffect(() => {
    let tempBlock = convertToRaw(editorState2.getCurrentContent());
    if (
      (tempBlock && tempBlock.blocks && tempBlock.blocks.length > 1) ||
      (tempBlock && tempBlock.blocks && tempBlock.blocks[0].text != "")
    ) {
      setComment("yes");
    } else {
      setComment("");
    }
  }, [editorState2]);

  useEffect(() => {
    if (props.feed.comments) {
      setComments(props.feed.comments);
    }
 let blockMessage = props.feed.message != null &&  props.feed.message != "" ? props.feed.message : "<p></p>"
    let isBlock = isJsonString(blockMessage);    
    if (isBlock && blockMessage) {
      try {
        const blocksFromHTML = convertFromRaw(JSON.parse(blockMessage));
        setEditorState(EditorState.createWithContent(blocksFromHTML));
      } catch (error) {
        // const blocksFromHTML = convertFromRaw(JSON.parse(blockMessage));
        setEditorState(blockMessage);
        console.log("try catch error:", error);        
      }
    } else {
      // const blocksFromHTML = convertFromHTML(props.feed.message);
      // const state = ContentState.createFromBlockArray(
      //   blocksFromHTML.contentBlocks,
      //   blocksFromHTML.entityMap,
      // )
      // setEditorState(EditorState.createWithContent(state));

      const blocksFromHtml = htmlToDraft(blockMessage);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }

    // if (!props.feed.readMessages?.id) {
    //   if (props.feed.user_id != user.id) {
    //     setIsNew(true);
    //   }
    //   readComment(props.feed.id, null);
    // }
  }, []);

  useEffect(() => {
    setIsImportant(props.feed.important == 1 ? true : false);
  }, [props.feed.important]);

  useEffect(() => {
    let blockMessage = props.feed.message != null &&  props.feed.message != "" ? props.feed.message : ""
    setIsMention(
      blockMessage.includes(`@${user.Firstname} ${user.Lastname}`)
        ? true
        : false
    );
  }, [props.feed.message]);


  const readComment = (msg, comment) => {
    readCommentMessages({
      message_id: msg,
      comment_id: comment,
      user_id: user.id,
    }).then((data) => {
      // console.log("the data of read response for subject is ", data);
      // props.reduceCount(1, props.feed.room_id)
    });
  };

  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const updateUserInfo = (arr) => {
    if (arr.user == null) {
      arr.user = arr.adminuser;
      arr.user_id = arr.admin_id;
      arr.userType = "admin";
    }
    return arr;
  };

  const editorRef = useRef(null);
  const [editorShow, setStateEditorShow] = useState(false);
  const [editorMainThreadShow, setStateMainThreadEditorShow] = useState(false);
  useImperativeHandle(ref, ()=>({
    handleClickOutside(event){
      // if (editorRef.current && !editorRef.current.contains(event.target) && event.target.classList.contains('rdw-embedded-modal-btn') != true) {
      if (
        editorRef.current &&
        !editorRef.current.contains(event.target) &&
        event.target.classList.contains("rdw-embedded-modal-btn") != true &&
        event.target.classList.contains("rdw-editor-toolbar") != true &&
        event.target.classList.contains("rdw-option-wrapper") != true &&
        event.target.classList.contains("rdw-editor-main") != true &&
        event.target.classList.contains("rdw-suggestion-option") != true &&
        event.target.classList.contains("edit_main_thread") != true &&
        event.target.classList.contains("public-DraftStyleDefault-block") !=
          true &&
        event.target.classList.contains("post-input-content") != true &&
        event.target.classList.contains("rdw-embedded-modal-link-input") !=
          true &&
        event.target.classList.contains("hold-show") != true &&
        event.target.classList.contains("swal2-cancel") != true &&
        event.target.classList.contains("swal2-confirm") != true) {
          setStateEditorShow(false);
          setStateMainThreadEditorShow(false);
          props.setOpenReplyId("");
          setAttachments([])
          return false;
      } 
      else if (editorRef.current && editorRef.current.contains(event.target)) {
        setStateEditorShow(true);
        return true
      }
    }
  }))

  
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);

  const editPost = (item) => {
    setUserId(item.user_id);
    let data = item.admin_id ? "admin" : "student"
    setUserType(data)
    const t = setTimeout(() => {
      props.setShowHidePostEdit(item.id);
      setStateMainThreadEditorShow(true);
    }, 200);

    return ()=>{clearTimeout(t)}
  };

  useEffect(() => {
    let finalObj = props.teamObj.find((item) => {
      // console.log(item?.id,"itemid")
      // console.log(props.feed.admin_id,"props.feed.admin_id")
      return item.id == props.feed.admin_id;
    });
    // console.log(finalObj?.role,"final")
    // setUserRole(
    //   finalObj
    //     ? finalObj.role
    //     : props.feed.admin_id === null
    //     ? "student"
    //     : props.feed.adminuser.job_title
    // );
    // let userTitle = Object.keys(teamArr[0]).find((item)=>{
    // if(props.feed.admin_id != null){
    //   if(teamArr[0][item].id == props.feed.admin_id) {
    //     console.log("conssosos", item)
    //     setUserRole(item)
    //   }
    // } else if(props.feed.admin_id == null){
    //   setUserRole("student")
    // } else {
    //   setUserRole(props.feed.adminuser.job_title)
    // }
    // })
    setUserRole(finalObj ? finalObj?.role : props?.feed?.admin_id === null ? "student" : props?.feed?.adminuser?.job_title);
  }, [props.feed]);
  useEffect(() => {
    if (chat) {
      chat.on("message", (message) => {
        // console.log("message socket-----> ", message);
        if (
          message &&
          message.message_type &&
          message.message_type == "edited"
        ) {
          let updatedMsg = updateUserInfo(message);
          updateEdited(updatedMsg);
        } else {
          let tempArr = comments;
          let updatedMsg = updateUserInfo(message);
          // if(user.id==updatedMsg.user.id){}
          tempArr.push(updatedMsg);
          setComments([...tempArr]);
        }
      });
      chat.on("likeDislike", (message) => {
        // console.log("likeDislike socket-----> ", message);
        let arr = comments;
        arr.map((e, i) => {
          if (e.id === message.data.comment_id) {
            arr[i].likes = message.likes;
            arr[i].dislikes = message.dislikes;
            arr[i].love = message.love;
            arr[i].laugh = message.laugh;
            arr[i].surprised = message.surprised;
            arr[i].sad = message.sad;
            arr[i].angry = message.angry;
            arr[i].likes.map((i) => {
              return updateUserInfo(i);
            });
            arr[i].dislikes.map((i) => {
              return updateUserInfo(i);
            });
            arr[i].love.map((i) => {
              return updateUserInfo(i);
            });
            arr[i].laugh.map((i) => {
              return updateUserInfo(i);
            });
            arr[i].surprised.map((i) => {
              return updateUserInfo(i);
            });
            arr[i].sad.map((i) => {
              return updateUserInfo(i);
            });
            arr[i].angry.map((i) => {
              return updateUserInfo(i);
            });
          }
          if (arr.length - 1 === i) setComments((pre) => [...arr]);
        });
      });
      chat.on("messageDelete", (message) => {
        // console.log("messageDelete socket-----> ", message);
        let arr = comments;
        arr.map((e, i) => {
          if (e.id === message.comment_id) {
            arr.splice(i, 1);
          }
        });
        setComments((pre) => [...arr]);
      });
    }
  }, [chat]);

  const updateEdited = (data) => {
    let arr = comments;
    arr.map((e, i) => {
      if (e.id === data.id) {
        e.comment = data.comment;
        e.important = data.important;
        e.attachments = data.attachments;
      }
    });
    setComments((pre) => [...arr]);
  };

  const chatSubscription = async () => {
    if (props.feed.id) {
      const messageID = props.feed.id;
      const roomID = props.feed.room_id;

      //if (subscription) subscription.close();

      let subscription = await SocketConnection.subscribe(
        `adminmessage:${messageID}-${roomID}`,
        "test"
      );

      await setChat(subscription);
    }
  };

  useEffect(() => {
    chatSubscription();
  }, [props.feed.id]);

  useEffect(() => {
    if (chat) {
      chat.close();
    }
  }, []);

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const sendComment = () => {
    // const messageValue = JSON.stringify(convertToRaw(editorState2.getCurrentContent()));
    const messageValue = draftToHtml(
      convertToRaw(editorState2.getCurrentContent())
    );
    // console.log('the message value is ', messageValue)
    chat.emit("message", {
      message: messageValue,
      feed: props.feed.id,
      type: "comment",
      files: attachments,
      sizes: sizes,
      taggedUsers,
      important: isImportantReply,
      user_id: user.UserID,
      role: "admin",
    });

    setEditorState2(
      EditorState.push(editorState2, ContentState.createFromText(""))
    );
    setComment("");
    setAttachments([]);
    setSizes([]);
    setFiles([]);
    setShowFilePond(false);
    setTimeout(() => {
      props.setOpenReplyId("");
      // setStateEditorShow(false)
    }, 1000);
    //commentTxt.current.value = "";
  };

  const likeDislikeHandle = (data) => {
    data.user_id = user.UserID;
    data.role = "admin";
    chat.emit("likeDislike", data);
  };

  const deleteHandle = (data) => {
    data.user_id = user.UserID;
    data.role = data?.admin_id ? "admin" : "student";
    // console.log("about to deleting message");
    chat.emit("messageDelete", data);
  };

  const editHandle = (
    messageValue,
    comment_id,
    tempImportant,
    attachments,
    sizes,
    deletedFiles,
    message_id,
    userId,
    userType
  ) => {
    // console.log('the message value is 3', messageValue, comment_id, tempImportant, attachments, sizes, deletedFiles, message_id)
    chat.emit("message", {
      message: messageValue,
      sizes: sizes,
      feed: props.feed.id,
      type: "comment",
      message_type: "edited",
      comment_id: comment_id,
      message_id: message_id,
      files: attachments,
      deletedFiles: deletedFiles,
      taggedUsers,
      important: tempImportant,
      user_id: userId && userId!=="" ? userId : user.userID,
      role: userType && userType!=="" ? userType : "admin",
    });
  };

  const handleAttachment = () => {
    btnAttachment.current.click();
  };

  const handleUploadedFiles = (response) => {
    // setAttachments((attachments) => [...attachments, response]);
    // console.log('the uploaded file is like this ', response)
    let newObj = {};
    let blocks = isJsonString(response);
    if (blocks) {
      newObj = JSON.parse(response);
      setAttachments((attachments) => [...attachments, newObj.fullDocFilePath]);
      setSizes((pre) => [...pre, newObj.size]);
    }
    // console.log('the new res is here ', newObj)
  };

  const handleFiles = (event) => {
    const ufiles = event.target.files;
    if (ufiles.length > 0) {
      for (var i = 0; i < ufiles.length; i++) {
        if (ufiles[i]) {
          if (filePondRef.current) {
            filePondRef.current
              .addFile(ufiles[i])
              .then((file) => console.log("success"))
              .catch((error) => console.log(error));
          }
        }
      }
    }
  };

  const handleUserTagged = (user) => {
    const extendedList = taggedUsers;
    setTaggedUsers([...extendedList, user]);
  };

  const handleDragEnter = (event) => {
    setShowFilePond(true);
  };

  const attachmentsArrUpdate = (file) => {
    let tempAttach = attachments;
    for (var i = 0; i < tempAttach.length; i++) {
      if (tempAttach[i].includes(file.split(".")[0])) {
        tempAttach.splice(i, 1);
        break;
      }
    }
    // console.log('tempAttach value would be ', tempAttach)
    if (tempAttach.length < 1) {
      setTimeout(() => {
        setShowFilePond(false);
        setShowCurser(!showcursure);
      }, 500);
    }
  };

  const showHideReply = () => {
    props.setOpenReplyId(props.feed.id);
    setAttachments([])
    // setShowReplyBox(false)
  };

  useEffect(() => {
    $(".post-by").on("click", function (e) {
      if (e.target !== this) return;
      props.resetEditor(true);
    });
  }, []);

  const subjectCon = () => {
    if (props.feed.id != props.showHidePostEdit) {
      props.resetEditor(true);
    }
  };

  const deleteConfirm = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete comment!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.deleteHandle({
          comment_id: null,
          message_type: "deleted",
          message_id: item.id,
          table_type: "message",
          deletedFiles: [],
          user_id: item.user_id,
        });
      }
    });
  };

  const renderActionList = useCallback((usersArr) => {
    return usersArr.map((i, ind) => {
      if (i.user == null) {
        i.user = i.adminuser;
        i.user_id = i.admin_id;
        i.userType = "admin";
      }
      return (
        <li key={ind}>
          <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span
                className={
                  "assigned-title-blk  name-icon cat-dark-red " +
                  ColorRender(i?.user?.role_name)
                }
              >
                {i?.user?.photo ? (
                  <img
                    src={`${i?.user?.photo.replaceAll(
                      "/home/myaie/public_html/",
                      ""
                    ).replace("public/","")}`}
                    alt="AIE"
                  />
                ) : (
                  InitalRender((originalValues(i?.user?.fullname)))
                )}

                <span className={`profile-box-2-status ${i?.user?.activity_status ? i?.user?.activity_status.toLowerCase()=="online" ? "Online" :i?.user?.activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                    <i className="fas fa-circle"></i>
                </span>

                <HoverChat
                  fullName={i?.user?.fullname}
                  firstName={i?.user?.first_name}
                  lastName={i?.user?.last_name}
                  photo={i?.user?.photo}
                  email={i?.user?.email}
                  mobile={i?.user?.Mobile}
                  status={(i?.user?.status && i?.user?.id && !i?.user?.UserID) ? i?.user?.status : i?.user?.is_active}
                  activity_status={i?.user?.activity_status}
                  right={true}
                  showNumber={i?.user?.student_crm_id ? true : false} 
                  number={i?.user?.student_crm_id}
                />
              </span>            
            </div>
          </div>
          <div className="info-drop">
            <span title={i?.user?.first_name ? i?.user?.first_name : "Unknown User" }>{i?.user?.first_name ? i?.user?.first_name : "Unknown User" }</span>
            <a
              href="#!"
              title={`${isLiked(i?.user?.id, props.feed.likes ? props.feed.likes : [])
                  ? "Like"
                  : isDisliked(
                    i?.user?.id,
                    props.feed.dislikes ? props.feed.dislikes : []
                  )
                    ? "Dislike"
                    : isLoved(i?.user?.id, props.feed.love ? props.feed.love : [])
                      ? "Love"
                      : isLaughed(
                        i?.user?.id,
                        props.feed.laugh ? props.feed.laugh : []
                      )
                        ? "Laugh"
                        : isSurprised(
                          i?.user?.id,
                          props.feed.surprised ? props.feed.surprised : []
                        )
                          ? "Surprised"
                          : isSad(i?.user?.id, props.feed.sad ? props.feed.sad : [])
                            ? "Sad"
                            : isAngry(i?.user?.id, props.feed.angry ? props.feed.angry : [])
                              ? "Angry"
                              : "NA"
                }`}
            >
              <i
                className={`fad ${isLiked(i?.user?.id, props.feed.likes ? props.feed.likes : [])
                    ? "fa-thumbs-up"
                    : isDisliked(
                      i?.user?.id,
                      props.feed.dislikes ? props.feed.dislikes : []
                    )
                      ? "fa-thumbs-down" 
                      : isLoved(i?.user?.id, props.feed.love ? props.feed.love : [])
                        ? "fa-heart"
                        : isLaughed(
                          i?.user?.id,
                          props.feed.laugh ? props.feed.laugh : []
                        )
                          ? "fa-laugh"
                          : isSurprised(
                            i?.user?.id,
                            props.feed.surprised ? props.feed.surprised : []
                          )
                            ? "fa-surprise"
                            : isSad(i?.user?.id, props.feed.sad ? props.feed.sad : [])
                              ? "fa-frown"
                              : isAngry(
                                i?.user?.id,
                                props.feed.angry ? props.feed.angry : []
                              )
                                ? "fa-angry"
                                : ""
                  }`}
              ></i>
            </a>
          </div>
        </li>
      );
    });
  },[props.feed]);

  const isLiked = (login_user, likeArr) => {
    if (likeArr.length) {
      for (var i = 0; i < likeArr.length; i++) {
        if (likeArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isDisliked = (login_user, likeArr) => {
    if (likeArr.length) {
      for (var i = 0; i < likeArr.length; i++) {
        if (likeArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };
  const isLoved = (login_user, loveArr) => {
    if (loveArr.length) {
      for (var i = 0; i < loveArr.length; i++) {
        if (loveArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };
  const isLaughed = (login_user, laughArr) => {
    if (laughArr.length) {
      for (var i = 0; i < laughArr.length; i++) {
        if (laughArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };
  const isSurprised = (login_user, surpriseArr) => {
    if (surpriseArr.length) {
      for (var i = 0; i < surpriseArr.length; i++) {
        if (surpriseArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isSad = (login_user, sadArr) => {
    if (sadArr.length) {
      for (var i = 0; i < sadArr.length; i++) {
        if (sadArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  const isAngry = (login_user, angryArr) => {
    if (angryArr.length) {
      for (var i = 0; i < angryArr.length; i++) {
        if (angryArr[i].user_id == login_user) {
          return true;
        }
      }
    }
    return false;
  };

  // const editPost = (item) => {
  //   // console.log('the editable comment is ', item)
  //   setTimeout(() => {
  //     props.setShowHidePostEdit(item.id)
  //   }, 200);

  // };

  const importantHandlePost = (postData, value) => {
    // console.log('data in the other part is ', postData, value)
    props.updatePostImportant(postData, value);
    // let tempArr = importantArr[0]
    // tempArr[postData.id] = value
    // setImportantArr(pre => [tempArr])

    // let tempData = data
    // tempData.map(i => {
    //   if (i.id == postData.id) {
    //     i.important = value
    //   }
    // })
    // globalIconHandle(tempData)

    // setData(tempData)
  };

  const postEditedHandle = (
    messageValue,
    allData_id,
    attachments,
    sizes,
    subject
  ) => {
    props.sendClick(messageValue, allData_id, attachments, sizes, subject, userId, userType);
  };

  const rendercolorforstatus = (status) => {
    let str = status ? status.toLowerCase() : status;
      switch (str) {
        case "past":
          return "red";
        case "upcoming":
          return "orange";
        case "live":
          return "green";
        case "cancelled":
          return "red-cancelled";
        default:
          return "";
      }
  }

  const checkStatusAndRenderColor = (status,type) => {
    let str = status ? status.toLowerCase() : status;
    // console.log("statussssssss-----",str);
    if(type === "img"){
      switch (str) {
        case "past":
          return "as-past-bg";
        case "upcoming":
          return "as-upcoming-bg";
        case "live":
          return "as-live-bg";
        case "cancelled":
          return "as-red-bg-status-cancelled";
        default:
          return "";
      }
    } else {
      switch (str) {
        case "past":
          return "border-past";
        case "upcoming":
          return "border-upcoming";
        case "live":
          return "border-live";
        case "cancelled":
          return "border-cancelled";
        default:
          return "";
      }
    }
  }

  return (
    <>
      {props.showDate === true ? (
        <div
          onClick={() => {
            subjectCon(props.feed.id);
          }}
          className="or-line-post-ps"
        >
          <div className="or-line-post">
            <span className="post-by-time">
            {moment(props.feed.updated_at, "Do MMMM YYYY, h:mm:ss a").format(`dddd, ${TABLE_DATE_FORMAT}`)}
            </span>
          </div>
        </div>
      ) : null}      
      <div
          className={`post-by user-profile f-m-cont-main f-m-cont-${
            props.ind
          } ${props.islastIndex ? " lastIndex " : ""}`}
        >
          <div className="post-by-left assigned-title-block-new">
            <div className="assigned-title-itm">
              <span
                className={
                  "assigned-title-blk  name-icon cat-dark-red " +
                  ColorRender(props?.feed?.user?.role_name) + (isSystemBot ? " isSystemRobot" : "")
                }
              >
                {!isSystemBot && props?.feed?.user?.photo ? (
                  <img
                    src={`${props.feed.user.photo.replaceAll(
                      "/home/myaie/public_html/",
                      ""
                    ).replace("public/","")}`}
                    alt="AIE"
                  />
                ) : isSystemBot ? (
                  <span className="w-100 h-100">
                    <div className="system-bot-inner-L">
                      <figure className={`ch2__sts system-bot-figure-${rendercolorforstatus(row.nestedClassStaus)}`}>
                        <img src={ami_logo} className={checkStatusAndRenderColor(row?.nestedClassStaus,"img")}></img>
                      </figure>
                    </div>
                  </span>
                ) : (
                  InitalRender((originalValues(props?.feed?.user?.fullname)))
                )}
                {!isSystemBot && <span className={`profile-box-2-status ${props?.feed?.user?.activity_status ? props?.feed?.user?.activity_status.toLowerCase()=="online" ? "Online" :props?.feed?.user?.activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                    <i className="fas fa-circle"></i>
                  </span>}

                {!isSystemBot && <HoverChat
                    fullName={props?.feed?.user?.fullname}
                    firstName={props?.feed?.user?.first_name}
                    lastName={props?.feed?.user?.last_name}
                    photo={props?.feed?.user?.photo}
                    email={props?.feed?.user?.email}
                    mobile={props?.feed?.user?.Mobile}
                    status={(props?.feed?.user?.status && props?.feed?.user?.id && !props?.feed?.user?.UserID) ? props?.feed?.user?.status : props?.feed?.user?.is_active}
                    activity_status={props?.feed?.user?.activity_status}
                    right={true}
                    showNumber={props?.feed?.user?.student_crm_id ? true : false} 
                    number={props?.feed?.user?.student_crm_id}
                  />}
              </span>            
            </div>
          </div>
          
          <div className="post-by-right attach-tag-rt" id="clickbox">
            <div className="ps-by-card">
              <div className={"post-reaction-rel01 " + checkStatusAndRenderColor(row?.nestedClassStaus,"status")}>
                <div className="post-reat-new">
                  <div className="post-reat-head">
                    <div className="post-reat-icon">
                      <a
                        href="#!"
                        title="Like"
                        onClick={() => {
                          props.likeDislikeHandle({
                            comment_id: props.feed.id,
                            table_type: "message",
                            comment_type: 1,
                            message_id: props.feed.id,
                          });
                        }}
                      >
                        <i
                          className={`${
                            isLiked(
                              user.id,
                              props.feed.likes ? props.feed.likes : []
                            )
                              ? "fad"
                              : "fal"
                          } fa-thumbs-up`}
                        ></i>
                      </a>
                      <a
                        href="#!"
                        title="Unlike"
                        onClick={() => {
                          props.likeDislikeHandle({
                            comment_id: props.feed.id,
                            table_type: "message",
                            comment_type: 2,
                            message_id: props.feed.id,
                          });
                        }}
                      >
                        <i
                          className={`${
                            isDisliked(
                              user.id,
                              props.feed.dislikes ? props.feed.dislikes : []
                            )
                              ? "fad"
                              : "fal"
                          } fa-thumbs-down`}
                        ></i>
                      </a>
                      <a
                        href="#!"
                        title="Love"
                        onClick={() => {
                          props.likeDislikeHandle({
                            comment_id: props.feed.id,
                            table_type: "message",
                            comment_type: 3,
                            message_id: props.feed.id,
                          });
                        }}
                      >
                        <i
                          className={`${isLoved(
                            user.id,
                            props.feed.love ? props.feed.love : []
                          )
                              ? "fad"
                              : "fal"
                            } fa-heart`}
                        ></i>
                      </a>
                      <a
                        href="#!"
                        title="Laugh"
                        onClick={() => {
                          props.likeDislikeHandle({
                            comment_id: props.feed.id,
                            table_type: "message",
                            comment_type: 4,
                            message_id: props.feed.id,
                          });
                        }}
                      >
                        <i
                          className={`${isLaughed(
                            user.id,
                            props.feed.laugh ? props.feed.laugh : []
                          )
                              ? "fad"
                              : "fal"
                            } fa-laugh`}
                        ></i>
                      </a>
                      <a
                        href="#!"
                        title="Surprise"
                        onClick={() => {
                          props.likeDislikeHandle({
                            comment_id: props.feed.id,
                            table_type: "message",
                            comment_type: 5,
                            message_id: props.feed.id,
                          });
                        }}
                      >
                        <i
                          className={`${isSurprised(
                            user.id,
                            props.feed.surprised ? props.feed.surprised : []
                          )
                              ? "fad"
                              : "fal"
                            } fa-surprise`}
                        ></i>
                      </a>
                      <a
                        href="#!"
                        title="Sad"
                        onClick={() => {
                          props.likeDislikeHandle({
                            comment_id: props.feed.id,
                            table_type: "message",
                            comment_type: 6,
                            message_id: props.feed.id,
                          });
                        }}
                      >
                        <i
                          className={`${isSad(user.id, props.feed.sad ? props.feed.sad : [])
                              ? "fad"
                              : "fal"
                            } fa-frown`}
                        ></i>
                      </a>
                      <a
                        href="#!"
                        title="Angry"
                        onClick={() => {
                          props.likeDislikeHandle({
                            comment_id: props.feed.id,
                            table_type: "message",
                            comment_type: 7,
                            message_id: props.feed.id,
                          });
                        }}
                      >
                        <i
                          className={`${isAngry(
                            user.id,
                            props.feed.angry ? props.feed.angry : []
                          )
                              ? "fad"
                              : "fal"
                            } fa-angry`}
                        ></i>
                      </a>
                    </div>
                    <div className="post-reat-action">
                    {((user.id == props?.feed?.user?.id) && props.feed.userType == "admin") || (hasPermission({ scopes: ["cchatempmsgedit"], permissions: givenPermsisions }) && props.feed.userType == "admin") &&
                      <a
                      className="main_thread_edit"
                      onClick={() => editPost(props.feed)}
                      href="#"
                    >
                      <i className="fal fa-edit"></i>
                    </a>}
                    {((user.id == props?.feed?.user?.id) && props.feed.userType == "admin") || (hasPermission({ scopes: ["cchatempmsgdelete"], permissions: givenPermsisions }) && props.feed.userType == "admin") &&
                      <a
                      className=""
                      href="#"
                      onClick={() => deleteConfirm(props.feed)}
                    >
                      <i className="fal fa-trash-alt"></i>
                    </a>}
                    {props.feed.userType != "admin" && hasPermission({ scopes: ["cchatstumsgedit"], permissions: givenPermsisions }) &&
                      <a
                      className="main_thread_edit"
                      onClick={() => editPost(props.feed)}
                      href="#"
                    >
                      <i className="fal fa-edit"></i>
                    </a>}
                    {props.feed.userType != "admin" && hasPermission({ scopes: ["cchatstumsgdelete"], permissions: givenPermsisions }) &&
                      <a
                      className=""
                      href="#"
                      onClick={() => deleteConfirm(props.feed)}
                    >
                      <i className="fal fa-trash-alt"></i>
                    </a>}
                    </div>

                    {/* {(user.id == props.feed.user.id || employe permission) &&
                    props.feed.userType == "admin" ? (
                      <div className="post-reat-action">
                        <PermissionsGate scopes={["cchatempmsgedit"]}>
                          <a
                            className="main_thread_edit"
                            onClick={() => editPost(props.feed)}
                            href="#"
                          >
                            <i className="fal fa-edit"></i>
                          </a>
                        </PermissionsGate>
                        <PermissionsGate scopes={["cchatempmsgdelete"]}>
                          <a
                            className=""
                            href="#"
                            onClick={() => deleteConfirm(props.feed.id)}
                          >
                            <i className="fal fa-trash-alt"></i>
                          </a>
                        </PermissionsGate>
                      </div>
                      
                    ) : type != admin && permission for studnet <div className="post-reat-action">
                        <PermissionsGate scopes={["cchatstumsgedit"]}>
                          <a
                            className="main_thread_edit"
                            onClick={() => editPost(props.feed)}
                            href="#"
                          >
                            <i className="fal fa-edit"></i>
                          </a>
                        </PermissionsGate>
                        <PermissionsGate scopes={["cchatstumsgdelete"]}>
                          <a
                            className=""
                            href="#"
                            onClick={() => deleteConfirm(props.feed.id)}
                          >
                            <i className="fal fa-trash-alt"></i>
                          </a>
                        </PermissionsGate>
                      </div> : null} */}
                    <div className="post-reat-more">
                      <a href="#!" title="more">
                        <i
                          className="fas fa-ellipsis-h"
                          onClick={() => {
                            // props.feed.likes.length + props.feed.dislikes.length > 0 && props.setHideList(props.feed.id)
                            props.setHideList(props.feed.id);
                          }}
                        ></i>
                      </a>
                    </div>
                  </div>
                  <div
                    className={`post-reat-list ${
                      props.hideList == props.feed.id ? "" : " d-none"
                    }`}
                  >
                    <h4>
                    Reactions (
                      {(props.feed && props.feed.likes && props.feed.likes.length
                        ? props.feed.likes.length
                        : 0) + (props?.feed?.dislikes?.length
                          ? props.feed.dislikes.length
                          : 0) + (props?.feed?.love?.length
                            ? props.feed.love.length
                            : 0) + (props?.feed?.laugh?.length
                              ? props.feed.laugh.length
                              : 0) + (props?.feed?.surprised?.length
                                ? props.feed.surprised.length
                                : 0) + (props?.feed?.sad?.length
                                  ? props.feed.sad.length
                                  : 0) + (props?.feed?.angry?.length
                                    ? props.feed.angry.length
                                    : 0)}
                      )
                    </h4>
                    <ul>
                      {renderActionList(
                        props.feed && props.feed.likes ? props.feed.likes : []
                      )}
                      {renderActionList(
                        props.feed && props.feed.dislikes
                          ? props.feed.dislikes
                          : []
                      )}
                      {renderActionList(
                        props.feed && props.feed.love ? props.feed.love : []
                      )}
                      {renderActionList(
                        props.feed && props.feed.laugh ? props.feed.laugh : []
                      )}
                      {renderActionList(
                        props.feed && props.feed.surprised
                          ? props.feed.surprised
                          : []
                      )}
                      {renderActionList(
                        props.feed && props.feed.sad ? props.feed.sad : []
                      )}
                      {renderActionList(
                        props.feed && props.feed.angry ? props.feed.angry : []
                      )}
                    </ul>
                  </div>
                </div>

                <div
                  onClick={subjectCon}
                  className={
                    `${
                      isImportant ? "important-tag" : ""
                    } ps-by-card-header desc_box` +
                    (props?.feed?.user?.id === user.id ? " mypost" : "")
                  }
                >
                  <div className="post-reat-new">
                    <div className="post-reat-head">
                      <div className="post-reat-icon">
                        <a
                          href="#!"
                          title="Like"
                          onClick={() => {
                            // !isLiked(user.id, props.feed.likes ? props.feed.likes : []) &&
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 1,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <i
                            class={`${
                              isLiked(
                                user.id,
                                props.feed.likes ? props.feed.likes : []
                              )
                                ? "fad"
                                : "fal"
                            } fa-thumbs-up`}
                          ></i>
                        </a>
                        <a
                          href="#!"
                          title="Unlike"
                          onClick={() => {
                            // !isDisliked(user.id, props.feed.dislikes ? props.feed.dislikes : []) &&
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 2,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <i
                            class={`${
                              isDisliked(
                                user.id,
                                props.feed.dislikes ? props.feed.dislikes : []
                              )
                                ? "fad"
                                : "fal"
                            } fa-thumbs-down`}
                          ></i>
                        </a>
                        <a
                          href="#!"
                          title="Love"
                          onClick={() => {
                            // !isDisliked(user.id, props.feed.dislikes ? props.feed.dislikes : []) &&
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 3,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <i
                            className={`${isLoved(
                              user.id,
                              props.feed.love ? props.feed.love : []
                            )
                                ? "fad"
                                : "fal"
                              } fa-heart`}
                          ></i>
                        </a>
                        <a
                          href="#!"
                          title="Laugh"
                          onClick={() => {
                            // !isDisliked(user.id, props.feed.dislikes ? props.feed.dislikes : []) &&
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 4,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <i
                            className={`${isLaughed(
                              user.id,
                              props.feed.laugh ? props.feed.laugh : []
                            )
                                ? "fad"
                                : "fal"
                              } fa-laugh`}
                          ></i>
                        </a>
                        <a
                          href="#!"
                          title="Surprised"
                          onClick={() => {
                            // !isDisliked(user.id, props.feed.dislikes ? props.feed.dislikes : []) &&
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 5,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <i
                            className={`${isSurprised(
                              user.id,
                              props.feed.surprised ? props.feed.surprised : []
                            )
                                ? "fad"
                                : "fal"
                              } fa-surprise`}
                          ></i>
                        </a>
                        <a
                          href="#!"
                          title="Sad"
                          onClick={() => {
                            // !isDisliked(user.id, props.feed.dislikes ? props.feed.dislikes : []) &&
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 6,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <i
                            className={`${isSad(
                              user.id,
                              props.feed.sad ? props.feed.sad : []
                            )
                                ? "fad"
                                : "fal"
                              } fa-frown`}
                          ></i>
                        </a>
                        <a
                          href="#!"
                          title="Angry"
                          onClick={() => {
                            // !isDisliked(user.id, props.feed.dislikes ? props.feed.dislikes : []) &&
                            props.likeDislikeHandle({
                              comment_id: props.feed.id,
                              table_type: "message",
                              comment_type: 7,
                              message_id: props.feed.id,
                            });
                          }}
                        >
                          <i
                            className={`${isAngry(
                              user.id,
                              props.feed.angry ? props.feed.angry : []
                            )
                                ? "fad"
                                : "fal"
                              } fa-angry`}
                          ></i>
                        </a>
                      </div>
                      <div className="post-reat-action">
                    {(((user.id == props?.feed?.user?.id) && props.feed.userType == "admin") || (hasPermission({ scopes: ["cchatempmsgedit"], permissions: givenPermsisions }) && props.feed.userType == "admin")) && !isSystemBot &&
                      <a
                      class="main_thread_edit"
                      onClick={() => editPost(props.feed)}
                      href="#"
                    >
                      <i class="fal fa-edit"></i>
                    </a>}
                    {(((user.id == props?.feed?.user?.id) && props.feed.userType == "admin") || (props.feed.userType == "admin" && hasPermission({ scopes: ["cchatempmsgdelete"], permissions: givenPermsisions }))) && !isSystemBot &&
                      <a
                      class=""
                      href="#"
                      onClick={() => deleteConfirm(props.feed)}
                    >
                      <i class="fal fa-trash-alt"></i>
                    </a>}
                    {props.feed.userType != "admin" && hasPermission({ scopes: ["cchatstumsgedit"], permissions: givenPermsisions }) &&
                      <a
                      class="main_thread_edit"
                      onClick={() => editPost(props.feed)}
                      href="#"
                    >
                      <i class="fal fa-edit"></i>
                    </a>}
                    {props.feed.userType != "admin" && hasPermission({ scopes: ["cchatstumsgdelete"], permissions: givenPermsisions }) &&
                      <a
                      class=""
                      href="#"
                      onClick={() => deleteConfirm(props.feed)}
                    >
                      <i class="fal fa-trash-alt"></i>
                    </a>}
                    </div>
                      {/* {user.id == props.feed.user.id &&
                      props.feed.userType == "admin" && !isSystemBot ? (
                        <div className="post-reat-action">
                          <a
                            class=""
                            onClick={() => editPost(props.feed)}
                            href="#"
                          >
                            <i class="fal fa-edit"></i>
                          </a>
                          <a
                            class=""
                            href="#"
                            onClick={() => deleteConfirm(props.feed.id)}
                          >
                            <i class="fal fa-trash-alt"></i>
                          </a>
                        </div>
                      ) : null} */}
                      <div className="post-reat-more">
                        <a href="#!" title="more">
                          <i
                            class="fas fa-ellipsis-h"
                            onClick={() => {
                              // props.feed.likes.length + props.feed.dislikes.length > 0 && props.setHideList(props.feed.id)
                              // console.log("porpsss", props.feed, props.hideList, props.feed.id)
                              props.setHideList(
                                props.hideList ? "" : props.feed.id
                              );
                            }}
                          ></i>
                        </a>
                      </div>
                    </div>
                    <div
                      className={`post-reat-list ${
                        props.hideList == props.feed.id ? "" : " d-none"
                      }`}
                    >
                      <h4>
                      Reactions (
                      {(props.feed && props.feed.likes && props.feed.likes.length
                        ? props.feed.likes.length
                        : 0) + (props?.feed?.dislikes?.length
                          ? props.feed.dislikes.length
                          : 0) + (props?.feed?.love?.length
                            ? props.feed.love.length
                            : 0) + (props?.feed?.laugh?.length
                              ? props.feed.laugh.length
                              : 0) + (props?.feed?.surprised?.length
                                ? props.feed.surprised.length
                                : 0) + (props?.feed?.sad?.length
                                  ? props.feed.sad.length
                                  : 0) + (props?.feed?.angry?.length
                                    ? props.feed.angry.length
                                    : 0)}
                      )
                      </h4>
                      <ul>
                      {renderActionList(
                        props.feed && props.feed.likes ? props.feed.likes : []
                      )}
                      {renderActionList(
                        props.feed && props.feed.dislikes
                          ? props.feed.dislikes
                          : []
                      )}
                      {renderActionList(
                        props.feed && props.feed.love ? props.feed.love : []
                      )}
                      {renderActionList(
                        props.feed && props.feed.laugh ? props.feed.laugh : []
                      )}
                      {renderActionList(
                        props.feed && props.feed.surprised
                          ? props.feed.surprised
                          : []
                      )}
                      {renderActionList(
                        props.feed && props.feed.sad ? props.feed.sad : []
                      )}
                      {renderActionList(
                        props.feed && props.feed.angry ? props.feed.angry : []
                      )}
                    </ul>
                    </div>
                  </div>
                  {!isSystemBot && <h5 className="comment-con">
                    <div className="mob-profile">
                      {props.feed.user || props.feed.adminuser ? (
                        <div
                          className={
                            "post-by user-profile f-m-cont-main f-m-cont-" +
                            props.ind
                          }
                        >
                          <div className="post-by-left assigned-title-block-new">
                            <div className="assigned-title-itm">
                              <span
                                className={
                                  "assigned-title-blk  name-icon cat-dark-red " +
                                  ColorRender(props.feed.user.role_name) + (isSystemBot ? " isSystemRobot" : "")
                                }
                              >
                                {!isSystemBot && props.feed.user.photo ? (
                                  <img
                                    src={`${props.feed.user.photo.replaceAll(
                                      "/home/myaie/public_html/",
                                      ""
                                    ).replace("public/","")}`}
                                    alt="AIE"
                                  />
                                ) : isSystemBot ? (
                                  <span className="w-100 h-100">
                                    <div className="system-bot-inner-L">
                                      <figure className={`ch2__sts system-bot-figure-${rendercolorforstatus(row.nestedClassStaus)}`}>
                                        <img src={ami_logo} className={checkStatusAndRenderColor(row?.nestedClassStaus,"img")}></img>
                                      </figure>
                                    </div>
                                  </span>
                                ) : (
                                  InitalRender((originalValues(props.feed.user.fullname)))
                                )}
                                {!isSystemBot && <span className={`profile-box-2-status ${props.feed.user.activity_status ? props.feed.user.activity_status.toLowerCase()=="online" ? "Online" :props.feed.user.activity_status.toLowerCase()== "away"? "Away" :"Offline":"Offline"}`}>
                                    <i className="fas fa-circle"></i>
                                  </span>}

                                {!isSystemBot && <HoverChat
                                    fullName={props.feed.user.fullname}
                                    firstName={props.feed.user.first_name}
                                    lastName={props.feed.user.last_name}
                                    photo={props.feed.user.photo}
                                    email={props.feed.user.email}
                                    mobile={props.feed.user.Mobile}
                                    status={(props.feed.user.status && props.feed.user.id && !props.feed.user.UserID) ? props.feed.user.status : props.feed.user.is_active}
                                    activity_status={props.feed.user.activity_status}
                                    right={true}
                                    showNumber={props.feed.user.student_crm_id ? true : false} 
                                    number={props.feed.user.student_crm_id}
                                  />}
                              </span>            
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="profile-title-box-main">
                    {!isSystemBot &&  <div className="profile-title-box">
                        <a>{props?.feed?.user?.fullname}</a>
                        <span className="post-by-time">
                        {/* {`${moment(props.feed.created_at , "Do MMM YY").format(TABLE_DATE_FORMAT)}, ${moment(props.feed.created_at , "Do MMM YY, HH:mm a").format(TABLE_TIME_FORMAT)}`} */}
                        {moment(props.feed.created_at, "Do MMMM YYYY, h:mm:ss a").format(
                "dddd"
              ) +
                ", " +
                moment(props.feed.created_at, "Do MMMM YYYY, h:mm:ss a").format(
                  TABLE_DATE_TIME_FORMAT
                )}
                        </span>
                        <div class="new-comment">{isNew ? "New" : null}</div>
                        {/* <small>{userRole ? rollsWidget(userRole) : ""}</small> */}
                        {rollsWidget(userRole)}
                      </div>}
                      <div className="mob-profile-right">
                        {(props.feed.likes &&
                          props.feed.likes.length &&
                          !isSystemBot) ||
                          (props.feed.dislikes &&
                            props.feed.dislikes.length &&
                            !isSystemBot) ||
                          (props.feed.love &&
                            props.feed.love.length &&
                            !isSystemBot) ||
                          (props.feed.laugh &&
                            props.feed.laugh.length &&
                            !isSystemBot) ||
                          (props.feed.surprised &&
                            props.feed.surprised.length &&
                            !isSystemBot) ||
                          (props.feed.sad &&
                            props.feed.sad.length &&
                            !isSystemBot) ||
                          (props.feed.angry &&
                            props.feed.angry.length &&
                            !isSystemBot) ? (
                          <div className="post-reaction-blk">
                            {props.feed.likes.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: props.feed.id,
                                      table_type: "message",
                                      comment_type: 1,
                                      message_id: props.feed.id,
                                    });
                                  }}
                                  className="fad fa-thumbs-up"
                                  title="Like"
                                ></i>{" "}
                                <span>{props.feed.likes.length}</span>
                              </a>
                            ) : null}
                            {props.feed.dislikes.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: props.feed.id,
                                      table_type: "message",
                                      comment_type: 2,
                                      message_id: props.feed.id,
                                    });
                                  }}
                                  className="fad fa-thumbs-down"
                                  title="Dislike"
                                ></i>{" "}
                                <span>{props.feed.dislikes.length}</span>
                              </a>
                            ) : null}
                            {props.feed.love.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: props.feed.id,
                                      table_type: "message",
                                      comment_type: 3,
                                      message_id: props.feed.id,
                                    });
                                  }}
                                  className="fad fa-heart"
                                  title="Love"
                                ></i>{" "}
                                <span>{props.feed.love.length}</span>
                              </a>
                            ) : null}
                            {props.feed.laugh.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: props.feed.id,
                                      table_type: "message",
                                      comment_type: 4,
                                      message_id: props.feed.id,
                                    });
                                  }}
                                  className="fad fa-laugh"
                                  title="Laugh"
                                ></i>{" "}
                                <span>{props.feed.laugh.length}</span>
                              </a>
                            ) : null}
                            {props.feed.surprised.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: props.feed.id,
                                      table_type: "message",
                                      comment_type: 5,
                                      message_id: props.feed.id,
                                    });
                                  }}
                                  className="fad fa-surprise"
                                  title="Surprised"
                                ></i>{" "}
                                <span>{props.feed.surprised.length}</span>
                              </a>
                            ) : null}
                            {props.feed.sad.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: props.feed.id,
                                      table_type: "message",
                                      comment_type: 6,
                                      message_id: props.feed.id,
                                    });
                                  }}
                                  className="fad fa-frown"
                                  title="Sad"
                                ></i>{" "}
                                <span>{props.feed.sad.length}</span>
                              </a>
                            ) : null}
                            {props.feed.angry.length ? (
                              <a href="#!">
                                <i
                                  onClick={() => {
                                    props.likeDislikeHandle({
                                      comment_id: props.feed.id,
                                      table_type: "message",
                                      comment_type: 7,
                                      message_id: props.feed.id,
                                    });
                                  }}
                                  className="fad fa-angry"
                                  title="Angry"
                                ></i>{" "}
                                <span>{props.feed.angry.length}</span>
                              </a>
                            ) : null}
                          </div>
                        ) : null}

                        {!isSystemBot && (
                          <div className="mob-profile-dotted">
                            <i class="fas fa-ellipsis-v"></i>
                          </div>
                        )}

                        {!(props.showHidePostEdit == props.feed.id) && (
                          <>
                            <div className="post-static-input tag-point-list">
                              <div className="row_icons_fas ls_box mob-discuss-icons  mob-post-static">
                                {isMention ? (
                                  <div
                                    className=" post-reaction-icon "
                                    title="Tag"
                                  >
                                    <i className="fal fa-at"></i>
                                  </div>
                                ) : null}
                                {isImportant ? (
                                  <i
                                    className="fal fa-exclamation important-icon"
                                    title="Important"
                                  ></i>
                                ) : null}
                                {(
                                  props.feed.attachments &&
                                    props.feed.attachments.length
                                    ? props.feed.attachments.length
                                    : 0
                                ) ? (
                                  <i
                                    className="fal fa-paperclip attachment-icon"
                                    title="Attachment"
                                  ></i>
                                ) : null}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </h5>}
                  {isImportant ? (
                    <p className="important-txt">IMPORTANT! </p>
                  ) : null}

                  {!isSystemBot && !(
                    props.showHidePostEdit == props.feed.id &&
                    editorMainThreadShow == true
                  ) && (
                    <>
                      <div className="post-static-input ">
                        <div className="row_icons_fas ls_box desk-post-static">
                          {isMention ? (
                            <div className=" post-reaction-icon " title="Tag">
                              <i className="fal fa-at"></i>
                            </div>
                          ) : null}
                          {isImportant ? (
                            <i
                              className="fal fa-exclamation important-icon"
                              title="Important"
                            ></i>
                          ) : null}
                          {(
                            props.feed.attachments &&
                            props.feed.attachments.length
                              ? props.feed.attachments.length
                              : 0
                          ) ? (
                            <i
                              className="fal fa-paperclip attachment-icon"
                              title="Attachment"
                            ></i>
                          ) : null}
                        </div>
                         {props.feed.subject && <ShowMoreHide
                          show={props.feed.subject ? props.feed.subject : ""}
                          class={" post-subject-bold"}
                        />}
                      </div>
                       {props.feed.message && <ShowMoreHide
                        show={props.feed.message ? props.feed.message : ""}
                        attachInfo={
                          props.feed.attachments &&
                          props.feed.attachments.length ? (
                            <FeedAttachments
                              attachments={props.feed.attachments}
                              isEditable={
                                props.feed.id == props.showHidePostEdit
                                  ? true
                                  : false
                              }
                              allData={props.feed}
                              deletedFile={props.deletedFile}
                            />
                          ) : null
                        }
                      />}

                      {/* {props.feed.attachments && props.feed.attachments.length ? (
                      <FeedAttachments
                        attachments={props.feed.attachments}
                        isEditable={props.feed.id == props.showHidePostEdit ? true : false}
                        allData={props.feed}
                        deletedFile={props.deletedFile}
                      />
                    ) : null} */}
                    </>
                  )}
                  {props.showHidePostEdit == props.feed.id &&
                    editorMainThreadShow == false && (
                      <>
                        <div className="post-static-input ">
                          <div className="row_icons_fas ls_box">
                            {isMention ? (
                              <div className=" post-reaction-icon " title="Tag">
                                <i className="fal fa-at"></i>
                              </div>
                            ) : null}
                            {isImportant ? (
                              <i
                                className="fal fa-exclamation important-icon"
                                title="Important"
                              ></i>
                            ) : null}
                            {(
                              props.feed.attachments &&
                                props.feed.attachments.length
                                ? props.feed.attachments.length
                                : 0
                            ) ? (
                              <i
                                className="fal fa-paperclip attachment-icon"
                                title="Attachment"
                              ></i>
                            ) : null}
                          </div>
                          <ShowMoreHide
                            show={props.feed.subject ? props.feed.subject : ""}
                            class={" post-subject-bold"}
                          />
                        </div>
                        <ShowMoreHide
                          show={props.feed.message ? props.feed.message : ""}
                          attachInfo={
                            props.feed.attachments &&
                              props.feed.attachments.length ? (
                              <FeedAttachments
                                attachments={props.feed.attachments}
                                isEditable={
                                  props.feed.id == props.showHidePostEdit
                                    ? true
                                    : false
                                }
                                allData={props.feed}
                                deletedFile={props.deletedFile}
                              />
                            ) : null
                          }
                        />
                      </>
                    )}
                  {props.showHidePostEdit == props.feed.id &&
                    editorMainThreadShow == true && (
                      <>
                        <HtmlInputEditorShow
                          editorState={editorState}
                          setEditorState={setEditorState}
                          isInValid={editorState}
                          isImportant={isImportant}
                          isMention={isMention}
                          setIsImportant={importantHandlePost}
                          isAttachment={
                            props.feed.attachments &&
                            props.feed.attachments.length
                              ? props.feed.attachments.length
                              : 0
                          }
                          data={props.feed.message}
                          setCurrentVideo={props.setCurrentVideo}
                          showHidePostEdit={props.showHidePostEdit}
                          userData={users}
                          allData={props.feed}
                          sendClick={postEditedHandle}
                        />
                        {props.feed.attachments &&
                        props.feed.attachments.length ? (
                          <FeedAttachments
                            attachments={props.feed.attachments}
                            isEditable={
                              props.feed.id == props.showHidePostEdit
                                ? true
                                : false
                            }
                            allData={props.feed}
                            deletedFile={props.deletedFile}
                          />
                        ) : null}
                      </>
                    )}
                    {isSystemBot && row?.id && (
                    <div className="system-bot">
                      <div className="">
                        <div className="system-bot-inner-R-content">
                          <div className="system-bot-inner-R-left">
                            <div className="system-bot-inner-R-head">
                              <div className="system-bot-inner-R-title">
                                <h4 className={`${row?.canceled == 1 ? "cancelled-class-title" : ""}`}>
                                  {/* {row?.canceled == 1 ? "Class has been cancelled" : row?.class_title ? row?.class_title : "NA"} */}
                                  {row?.class_title ? row?.class_title : "NA"}
                                </h4>
                                {row.class_type == "hybrid" ? (
                                  <small className="tag">
                                    <i class="fal fa-university"></i>{" "}
                                    <i class="fal fa-plus"></i>{" "}
                                    <i class="fal fa-house-signal"></i>Hybrid
                                  </small>
                                ) : row.class_type == "on_campus" ? (
                                  <small className="tag-purple">
                                    <i class="fal fa-university"></i> On Campus
                                  </small>
                                ) : (
                                  <small className="tag-green">
                                    <i class="fal fa-house-signal"></i> Virtual
                                  </small>
                                )}
                                {/* <div className={`${row?.canceled == 1 ? "canceled-date-time-box mr-1" : "chat-date-time-box mr-1"}`}> */}
                                <div className={`${"chat-date-time-box mr-1" + (row?.canceled == 1 ? " text__strike__through" : "")}`}>
                                  <i class="fal fa-calendar-alt"></i>
                                  <span className="ml-1">
                                    {moment(row.showDate, "dddd Do MMMM 'YYYY").format(
                                      "dddd, DD MMM YY"
                                    )}
                                    ,
                                  </span>
                                  <span>
                                    {moment(row.class_from, "HH:mm a").format(
                                      " HH:mm "
                                    )}
                                    -
                                    {moment(row.class_to, "HH:mm a").format(
                                      " HH:mm"
                                    )}{" "}
                                  </span>
                                </div>
                                <small className="ch2__sts">{classStatusWidgets(row?.nestedClassStaus)}</small>
                                <small className="ml-1">{classStatusWidgets(row?.nestedClassSubStaus)}</small>
                              </div>
                              <div className="system-actions-r">
                                <div className="post-reaction-blk  system-actions mb-3">
                                  {props.feed.likes.length ? (
                                    <a href="#!">
                                      <i
                                        onClick={() => {
                                          props.likeDislikeHandle({
                                            comment_id: props.feed.id,
                                            table_type: "message",
                                            comment_type: 1,
                                            message_id: props.feed.id,
                                          });
                                        }}
                                        className="fad fa-thumbs-up"
                                        title="Like"
                                      ></i>{" "}
                                      <span>{props.feed.likes.length}</span>
                                    </a>
                                  ) : null}
                                  {props.feed.dislikes.length ? (
                                    <a href="#!">
                                      <i
                                        onClick={() => {
                                          props.likeDislikeHandle({
                                            comment_id: props.feed.id,
                                            table_type: "message",
                                            comment_type: 2,
                                            message_id: props.feed.id,
                                          });
                                        }}
                                        className="fad fa-thumbs-down"
                                        title="Dislike"
                                      ></i>{" "}
                                      <span>{props.feed.dislikes.length}</span>
                                    </a>
                                  ) : null}
                                  {props.feed.love.length ? (
                                    <a href="#!">
                                      <i
                                        onClick={() => {
                                          props.likeDislikeHandle({
                                            comment_id: props.feed.id,
                                            table_type: "message",
                                            comment_type: 3,
                                            message_id: props.feed.id,
                                          });
                                        }}
                                        className="fad fa-heart"
                                        title="Love"
                                      ></i>{" "}
                                      <span>{props.feed.love.length}</span>
                                    </a>
                                  ) : null}
                                  {props.feed.laugh.length ? (
                                    <a href="#!">
                                      <i
                                        onClick={() => {
                                          props.likeDislikeHandle({
                                            comment_id: props.feed.id,
                                            table_type: "message",
                                            comment_type: 4,
                                            message_id: props.feed.id,
                                          });
                                        }}
                                        className="fad fa-laugh"
                                        title="Laugh"
                                      ></i>{" "}
                                      <span>{props.feed.laugh.length}</span>
                                    </a>
                                  ) : null}
                                  {props.feed.surprised.length ? (
                                    <a href="#!">
                                      <i
                                        onClick={() => {
                                          props.likeDislikeHandle({
                                            comment_id: props.feed.id,
                                            table_type: "message",
                                            comment_type: 5,
                                            message_id: props.feed.id,
                                          });
                                        }}
                                        className="fad fa-surprise"
                                        title="Surprised"
                                      ></i>{" "}
                                      <span>{props.feed.surprised.length}</span>
                                    </a>
                                  ) : null}
                                  {props.feed.sad.length ? (
                                    <a href="#!">
                                      <i
                                        onClick={() => {
                                          props.likeDislikeHandle({
                                            comment_id: props.feed.id,
                                            table_type: "message",
                                            comment_type: 6,
                                            message_id: props.feed.id,
                                          });
                                        }}
                                        className="fad fa-frown"
                                        title="Sad"
                                      ></i>{" "}
                                      <span>{props.feed.sad.length}</span>
                                    </a>
                                  ) : null}
                                  {props.feed.angry.length ? (
                                    <a href="#!">
                                      <i
                                        onClick={() => {
                                          props.likeDislikeHandle({
                                            comment_id: props.feed.id,
                                            table_type: "message",
                                            comment_type: 7,
                                            message_id: props.feed.id,
                                          });
                                        }}
                                        className="fad fa-angry"
                                        title="Angry"
                                      ></i>{" "}
                                      <span>{props.feed.angry.length}</span>
                                    </a>
                                  ) : null}
                                </div>

                                <div className="mob-profile-dotted sys-bott-dotted">
                                  <i class="fas fa-ellipsis-v"></i>
                                </div>
                              </div>
                            </div>

                           {!row.canceled == 1 && <div className="system-bot-inner-R-body">
                              <div className="class-schedule">
                                <h5>
                                  {row?.class_description
                                    ? getTextContentLength(
                                      row.class_description?.trim(),
                                      110
                                    )
                                    : "NA"}
                                </h5>

                                {end_time < currentTime ? (
                                  <>
                                    {/* <Popup
                                      on="click"
                                      hideOnScroll
                                      open={isOpen}
                                      onOpen={() => setIsOpen(!isOpen)}
                                      onClose={() => setIsOpen(false)}
                                      flowing
                                      position="bottom left"
                                      trigger={ */}
                                        <div className="class-list-row row">
                                          {/* {true && ( */}
                                          {row.ratingArr && row.ratingArr.avg_rating && row.ratingArr.avg_rating > 0 && (
                                            <div
                                              className="col-12 meeting-rating-start-col m-0"
                                              key={row.id}
                                            >
                                              <div className="meeting-rating-start d-flex align-items-center rate-class">
                                                {/* <a href={`/classReport/summary/${row.id}/${row.class_id}`} target={"_blank"}><span className="stars-system-bot"> */}
                                                <Link to={`/classReport/attendenceReport/${row.id}/${row.class_id}`} target="_blank">
                                                  <span className="stars-system-bot">
                                                  {ReactHtmlParser(star)}
                                                  {ReactHtmlParser(notStar)}
                                                {/* </span></a> */}
                                                </span>
                                                </Link>
                                                {/* {row?.student_rating > 0 ? null : <span>Rate this class</span>} */}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                       {/* }
                                     > */}
                                      {/* <PopupContent>
                                        <div className="row">
                                          {row.percentage >= "1" && row.student_rating >= 0 ? (
                                            returnValue
                                          ) : (
                                            <div
                                              className="col-12 meeting-rating-start-col m-0"
                                              key={row.id}
                                            >
                                              <div className="meeting-rating-start d-flex ">
                                                <span className="stars-system-bot">
                                                  {ReactHtmlParser(star)}
                                                  {ReactHtmlParser(notStar)}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                          {row.percentage >= "1" && row.student_rating >= 0 ? (
                                            returnValue1
                                          ) : (
                                            <div
                                              className="col-12 meeting-leave-comment-col m-0"
                                              key={row.id}
                                            >
                                              <span>
                                                {" "}
                                                {row.student_comment?.trim()}
                                              </span>{" "}
                                            </div>
                                          )}
                                        </div>
                                      </PopupContent> */}
                                    {/* </Popup> */}

                                    {/* {row.percentage >= "1" ? (
                                      <div className="status-show">
                                        <b>
                                          present{" "}
                                          {row.join_type == "campus"
                                            ? "(on Campus) "
                                            : "(Online) "}
                                        </b>
                                        <span className="status-percentage">{`${row?.duration ? row?.duration : "00:00:00"} ${row?.percentage
                                            ? "(" + row?.percentage + ")%"
                                            : ""
                                          }`}</span>
                                      </div>
                                    ) : (
                                      <div className="status-show-absent">
                                        <b>Absent</b>
                                  
                                      </div>
                                    )} */}
                                  </>
                                ) : (
                                  ""  
                                )}
                                <div className="d-flex justify-content-between">
                                  <div className="class__schedule__venues">
                                  {row?.campus_name_room &&
                                  row?.campus_name_room != "" && (
                                    <ul className="class-schedule-list mt-2">
                                      {ReactHtmlParser(
                                        row.campus_name_room.replaceAll(
                                          "fa-li ",
                                          ""
                                        )
                                      )}
                                    </ul>
                                  )}

                                {row?.Firstname && row?.Lastname && (
                                  <div className="name-user">
                                    <p>
                                      <i class="far fa-chalkboard-teacher"></i>
                                      {`${row?.Firstname} ${row?.Lastname}`}
                                    </p>
                                  </div>
                                )}
                                  </div>
                                  <div className="btn-group-box mt-auto">
                                {row?.canceled == 1 ? (
                                  <div className="here">
                                    {row.recordings != "" &&
                                      row.recordings != null ? (
                                      <ul className="meeting-btns-list">
                                        {ReactHtmlParser(row.recordings)}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : end_time < currentTime ? (
                                  <div className="here">
                                    {row.recordings != "" &&
                                      row.recordings != null ? (
                                      <ul className="meeting-btns-list">
                                        {ReactHtmlParser(row.recordings)}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                ) : start_time_live <= currentTime &&
                                  end_time >= currentTime ? (
                                  <>
                                    {/* {row.class_type == "hybrid" ? (
                                      <div className="here">
                                        {row.disable_online == "" ? (
                                          <button
                                            data-id={`${row.id}`}
                                            title="Join the class virtually if you are not on campus."
                                            // onClick={() => joinVirtualClassGlobal(row.id)}
                                            className={`btn btn-primary mr-2 join-virtual-classes-list-view`}
                                          >
                                            <i class="fal fa-wifi"></i>
                                            Join Virtual Class
                                          </button>
                                        ) : (
                                          <button
                                            data-id={`${row.id}`}
                                            title="Join the class virtually if you are not on campus."
                                            // onClick={() => joinVirtualClassGlobal(row.id)}
                                            disabled
                                            className={`btn btn-primary mr-2 join-virtual-classes-list-view disabled`}
                                          >
                                            <i class="fal fa-wifi"></i>
                                            Join Virtual Class
                                          </button>
                                        )}

                                        {row.disable_campus != "" &&
                                          row.hide_checkin != "" ? (
                                          <button
                                            data-id={`${row.id}`}
                                            title="Check in here if you are attending the class on campus."
                                            disabled
                                            // onClick={() => joinCampusClassGlobal(row?.id)}
                                            style={{ display: "none" }}
                                            className={`btn btn-primary mr-2 join-campus-classes-list-view disabled`}
                                          >
                                            <i className="fal fa-university"></i>
                                            Check In On Campus
                                          </button>
                                        ) : row.disable_campus != "" &&
                                          row.hide_checkin == "" ? (
                                          <button
                                            data-id={`${row.id}`}
                                            title="Check in here if you are attending the class on campus."
                                            // onClick={() => joinCampusClassGlobal(row?.id)}
                                            disabled
                                            className={`btn btn-primary mr-2 join-campus-classes-list-view disabled`}
                                          >
                                            <i className="fal fa-university"></i>
                                            Check In On Campus
                                          </button>
                                        ) : row.disable_campus == "" &&
                                          row.hide_checkin != "" ? (
                                          <button
                                            data-id={`${row.id}`}
                                            title="Check in here if you are attending the class on campus."
                                            style={{ display: "none" }}
                                            // onClick={() => joinCampusClassGlobal(row?.id)}
                                            className={`btn btn-primary mr-2 join-campus-classes-list-view`}
                                          >
                                            <i className="fal fa-university"></i>
                                            Check In On Campus
                                          </button>
                                        ) : (
                                          <button
                                            data-id={`${row.id}`}
                                            title="Check in here if you are attending the class on campus."
                                            className={`btn btn-primary mr-2 join-campus-classes-list-view`}
                                            // onClick={() => joinCampusClassGlobal(row?.id)}
                                          >
                                            <i className="fal fa-university"></i>
                                            Check In On Campus
                                          </button>
                                        )}

                                        {row.hide_checkout == "" ? (
                                          <button
                                            data-id={`${row.id}`}
                                            title="On Campus Check Out"
                                            className="btn mr-2 exit-campus-class exit-campus-classes-list-view"
                                            // onClick={() => exitCampusClassGlobal(row?.id)}
                                          >
                                            <i
                                              class="fal fa-window-close"
                                              aria-hidden="true"
                                            ></i>
                                            On Campus Check Out
                                          </button>
                                        ) : (
                                          <button
                                            data-id={`${row.id}`}
                                            title="On Campus Check Out"
                                            style={{ display: "none" }}
                                            // onClick={() => exitCampusClassGlobal(row?.id)}
                                            className="btn mr-2 exit-campus-class exit-campus-classes-list-view"
                                          >
                                            <i
                                              className="fal fa-window-close"
                                              aria-hidden="true"
                                            ></i>
                                            On Campus Check Out
                                          </button>
                                        )}
                                      </div>
                                    ) : row.class_type == "on_campus" ? (
                                      <div className="">
                                        {row.hide_checkin == "" ? (
                                          <button
                                            data-id={`${row.id}`}
                                            title="Check in here if you are attending the class on campus."
                                            class="btn btn-primary mr-2 join-campus-classes-list-view"
                                            // onClick={() => joinCampusClassGlobal(row?.id)}
                                          >
                                            <i className="fal fa-university"></i>
                                            Check In On Campus
                                          </button>
                                        ) : (
                                          <button
                                            data-id={`${row.id}`}
                                            title="Check in here if you are attending the class on campus."
                                            // onClick={() => joinCampusClassGlobal(row?.id)}
                                            style={{ display: "none" }}
                                            className="btn btn-primary mr-2 join-campus-class join-campus-classes-list-view"
                                          >
                                            <i className="fal fa-university"></i>
                                            Check In On Campus
                                          </button>
                                        )}

                                        {row.hide_checkout == "" ? (
                                          <button
                                            data-id={`${row.id}`}
                                            title="On Campus Check Out"
                                            className="btn mr-2 exit-campus-class exit-campus-classes-list-view"
                                            // onClick={() => exitCampusClassGlobal(row?.id)}
                                          >
                                            <i
                                              className="fal fa-window-close"
                                              aria-hidden="true"
                                            ></i>
                                            On Campus Check Out
                                          </button>
                                        ) : (
                                          <button
                                            data-id={`${row.id}`}
                                            title="On Campus Check Out"
                                            className="btn mr-2 exit-campus-class exit-campus-classes-list-view"
                                            style={{ display: "none" }}
                                            // onClick={() => exitCampusClassGlobal(row?.id)}
                                          >
                                            <i
                                              className="fal fa-window-close"
                                              aria-hidden="true"
                                            ></i>
                                            On Campus Check Out
                                          </button>
                                        )}
                                      </div>
                                    ) : (
                                      <div className="">
                                        <button
                                          data-id={`${row.id}`}
                                          title="Join the class virtually if you are not on campus."
                                          // onClick={() => joinVirtualClassGlobal(row.id)}
                                          className="btn btn-primary mr-2 join-virtual-classes-list-view "
                                        >
                                          <i class="fal fa-wifi"></i>
                                          Join Virtual Class
                                        </button>
                                      </div>
                                    )} */}
                                  </>
                                ) : (
                                  <>
                                    {/* {row.class_type == "hybrid" ? (
                                      <div className="">
                                        <button
                                          data-id={`${row.id}`}
                                          title="Join the class virtually if you are not on campus."
                                          // onClick={() => joinVirtualClassGlobal(row.id)}
                                          className={`btn btn-primary mr-2 join-virtual-class join-virtual-classes-list-view`}
                                          disabled
                                        >
                                          <i className="fal fa-house-signal"></i>
                                          <span className="btn-text">
                                            Join Virtual Class
                                          </span>
                                        </button>
                                        <button
                                          data-id={`${row.id}`}
                                          title="Check in here if you are attending the class on campus."
                                          disabled
                                          className={`btn btn-primary mr-2 join-campus-class join-campus-classes-list-view`}
                                          // onClick={() => joinCampusClassGlobal(row?.id)}
                                        >
                                          <i className="fal fa-university"></i>
                                          <span className="btn-text">
                                            Check In On Campus
                                          </span>
                                        </button>
                                      </div>
                                    ) : row.class_type == "on_campus" ? (
                                      <div className="">
                                        <button
                                          data-id={`${row.id}`}
                                          disabled
                                          title="Check in here if you are attending the class on campus."
                                          class="btn btn-primary mr-2 join-campus-class join-campus-classes-list-view"
                                          // onClick={() => joinCampusClassGlobal(row?.id)}
                                        >
                                          <i className="fal fa-university"></i>
                                          <span className="btn-text">
                                            Check In On Campus
                                          </span>
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="">
                                        <button
                                          data-id={`${row.id}`}
                                          disabled
                                          title="Join the class virtually if you are not on campus."
                                          // onClick={() => joinVirtualClassGlobal(row.id)}
                                          className="btn btn-primary mr-2 join-virtual-class join-virtual-classes-list-view "
                                        >
                                          <i className="fal fa-house-signal"></i>
                                          <span className="btn-text">
                                            Join Virtual Class
                                          </span>
                                        </button>
                                      </div>
                                    )} */}
                                  </>
                                )}
                              </div>
                                </div>
                              </div>
                            </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="ps-by-card-body spacing_lt-rt">
                {comments && comments.length ? (
                  <FeedComments
                    comments={comments}
                    user={user}
                    online={props.online}
                    setCurrentVideo={props.setCurrentVideo}
                    likeDislikeHandle={likeDislikeHandle}
                    deleteHandle={deleteHandle}
                    editHandle={editHandle}
                    userData={users}
                    updateMsg={props.updateMsg}
                    setShowHideEdit={props.setShowHideEdit}
                    showHideEditArr={props.showHideEditArr}
                    resetEditor={subjectCon}
                    readMessages={
                      props.feed.readMessages ? props.feed.readMessages : null
                    }
                    reduceCount={props.reduceCount}
                    room_id={props.feed.room_id}
                    setShowHideCOmm={setShowHideCOmm}
                    showHideCOmm={showHideCOmm}
                    updateReadMessages={props.updateReadMessages}
                    teamObj={props.teamObj}
                  />
                ) : null}
              </div>
              {/* {showReplyBox === true ? */}
              <div ref={editorRef}>
                {/* {(props.openReplyId !== props.feed.id) ? */}
                  {!editorShow ? (
                    <div
                      className={"reply-static "+props.ind+" reply-"+props.ind}
                      onClick={() => {
                        showHideReply();
                      }}
                    >
                      <i class="fal fa-reply"></i>Reply
                    </div>) : (
                      <div className={"ps-by-card-footer new-reply-box reply-box-toggle-"+props.ind} name={"reply-button"}>
                        <div className="card-footer">
                          <div className="card-left assigned-title-blk online-status">
                            <div className="card-left-by-img">
                              {user.picture_me ? (
                                <img
                                  src={
                                    IMAGE_URL +
                                    user.picture_me
                                      .replace("/home/myaie/public_html", "")
                                      .replace("uploads/", "/uploads/")
                                  }
                                  alt={user.Firstname + " " + user.Lastname}
                                />
                              ) : (
                                <div>
                                  {" "}
                                  {InitalRender(
                                    user.Firstname + " " + user.Lastname
                                  )}
                                </div>
                              )}
                            </div>
                            <span
                              className={
                                // "portal-status-icon online-u"
                                `portal-status-icon ${
                                  user?.Status?.toLowerCase() == "online"
                                    ? "online-u"
                                    : user?.Status?.toLowerCase() == "donotdisturb"
                                    ? "donotdisturb-u"
                                    : "offline-u"
                                }`
                              }
                            ></span>                            
                            <Hover
                              fullName={user.fullname}
                              firstName={user.Firstname}
                              lastName={user.Lastname}
                              photo={user.picture_me}
                              email={user.Email}
                              mobile={user.Mobile}
                              status={user.is_active}
                              activity_status={user.Status}
                              right={true}
                              showNumber={user.student_crm_id ? true : false} 
                              number={user.student_crm_id}
                            />
                          </div>
                          <div className="card-right">
                            <div className="" onDragEnter={handleDragEnter}>
                              <HtmlInputEditor
                                editorState={editorState2}
                                setEditorState={setEditorState2}
                                isInValid={editorState2}
                                data={users}
                                placeholder="Reply"
                                attachmentClick={handleAttachment}
                                sendClick={sendComment}
                                attachments={attachments}
                                // setShowReplyBox={setShowReplyBox}
                                setIsImportant={setIsImportantReply}
                                isImportant={isImportantReply}
                                showcurseragain={showcursure}
                              />
                              <input
                                type="file"
                                ref={btnAttachment}
                                style={{ display: "none" }}
                                onChange={(e) => handleFiles(e)}
                              />
                            </div>
                            <div
                              className={
                                !showFilePond ? "hideFilePond" : "showFilePond"
                              }
                            >
                              <FilePond
                                name="post"
                                allowMultiple={true}
                                maxFiles={10}
                                ref={filePondRef}
                                files={files}
                                onupdatefiles={setFiles}
                                beforeRemoveFile={(f) => {
                                  setRemoveFile(f.file.name);
                                  attachmentsArrUpdate(f.file.name);
                                }}
                                // onremovefile={(err, file)=>{console.log('the removed file is ', file)}}
                                onaddfile={(err, fileItem) => {
                                  setShowFilePond(true);
                                }}
                                server={{
                                  url: API_BASE,
                                  process: {
                                    url: "/addFeedAttachments",
                                    headers: {
                                      Authorization: `Bearer ${getToken()}`,
                                    },
                                    onload: (response) => {
                                      handleUploadedFiles(response);
                                    },
                                    ondata: (formData) => {
                                      formData.append("datasize", true);
                                      return formData;
                                    },
                                  },
                                  revert: {
                                    url: `/removeFeedAttachments/${removeFile}`,
                                    headers: {
                                      Authorization: `Bearer ${getToken()}`,
                                    },
                                    onload: (response) => {
                                      setRemoveFile(null);
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
              </div>
            </div>
          </div>
        </div>      
    </>
  );
});

export default React.memo(FeedMessage);
