import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../../../utils/Constants";
import GeneratePasswordImg from "../../../../../assets/images/pass-generate.svg";
import { StudentUpdateAccountData } from "../../../../../services/StudentsSettingServices";
import Swal from "sweetalert2";
import PermissionsGate from "../../../../../utils/permissionGate";

function LoginDetail({ studentData }) {
  const { id } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false
  })

  const handleGeneratePassword = async () => {
    let pw = "";
    let password = "";
    let c = "abcdefghijklmnopqrstuvwxyz";
    let specialChar = "@$!%*#?&";
    let v = "aeiou";
    let alphas = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let a = c + v + specialChar + alphas;

    pw += c.charAt(Math.floor(Math.random() * c.length - 1) + 1);
    pw += v.charAt(Math.floor(Math.random() * v.length - 1) + 1);
    pw += a.charAt(Math.floor(Math.random() * a.length - 1) + 1);
    pw += alphas.charAt(Math.floor(Math.random() * alphas.length - 1) + 1);
    pw += specialChar.charAt(Math.floor(Math.random() * specialChar.length - 1) + 1);
    pw += Math.floor(Math.random() * 9999) + 1000;
    await formik.setFieldValue("Password", pw);
    await formik.setFieldValue("ConfirmPassword", pw);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Email: studentData ? studentData.email : "",
      Password: "",
      ConfirmPassword: "",
    },
    validationSchema: Yup.object({
      Email: Yup.string().matches(EMAIL_REGEX, "Please Enter Valid Email"),
      Password: Yup.string().required("Password is required").trim() .matches(
        PASSWORD_REGEX,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
      ConfirmPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("Password"), null], "Passwords must match"),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let formData = new FormData();
      formData.append("section", "contact_section");
      formData.append("student_id", id);
      formData.append("email", values.Email);
      formData.append("password", values.Password);
      StudentUpdateAccountData(formData)
        .then((res) => {
          setDisabled(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
        })
        .catch((err) => {
          setDisabled(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
          });
        });
    },
  });

  const handlePassword = (val) => {
    if (val == "ConfirmPassword") {
      setShowPassword((prevVal) => ({ ...prevVal, confirmPassword: !showPassword.confirmPassword }))
    } else {
      setShowPassword((prevVal) => ({ ...prevVal, password: !showPassword.password }))
    }
  }

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Login Details</div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Email">Email </label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="Email"
                className={"form-control" + (formik.errors.Email && formik.touched.Email ? " is-invalid" : "")}
                name="Email"
                placeholder="Enter Email"
                value={formik.values.Email}
                onChange={formik.handleChange}
                disabled
                onBlur={formik.handleBlur}
                title="Email"
              />
              {formik.errors.Email && formik.touched.Email ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Password">Password *</label>
            <div className="form-icon-group mb-4">
              <input
                type={!showPassword.password ? "password" : "text"}
                id="Password"
                className={"form-control" + (formik.errors.Password && formik.touched.Password ? " is-invalid" : "")}
                name="Password"
                placeholder="Enter Password"
                value={formik.values.Password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Password"
              />
              {formik.values.Password && (showPassword.password ? <i class="fal fa-eye" onClick={()=>handlePassword("password")} ></i> : <i class="fal fa-eye-slash mr-3" onClick={()=>handlePassword("password")} ></i>)}
              {formik.errors.Password && formik.touched.Password ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="ConfirmPassword">Confirm Password *</label>
            <div className="form-icon-group mb-4">
              <input
                type={!showPassword.confirmPassword ? "password" : "text"}
                id="ConfirmPassword"
                className={"form-control" + (formik.errors.ConfirmPassword && formik.touched.ConfirmPassword ? " is-invalid" : "")}
                name="ConfirmPassword"
                placeholder="Confirm Password"
                value={formik.values.ConfirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Confirm Password"
              />
              {formik.values.ConfirmPassword && (showPassword.confirmPassword  ? <i class="fal fa-eye" onClick={()=>handlePassword("ConfirmPassword")}></i> : <i class="fal fa-eye-slash mr-3" onClick={()=>handlePassword("ConfirmPassword")}></i>)}
              {formik.errors.ConfirmPassword && formik.touched.ConfirmPassword ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="form-group-blk mb-3">
              <label>&nbsp;</label>
              <div className="form-group form-group-save-cancel">
                <button className="btn btn-save btn-success rtimg-icon-btn" type="button" title="Generate Secure Password" onClick={handleGeneratePassword}>
                  <img src={GeneratePasswordImg} alt="" />
                  Generate Secure Password
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group form-group-save-cancel">
          <PermissionsGate errorProps={{ disabled: true }} scopes={["spiedit"]}>
            <button className="btn btn-save btn-success" type="submit" title="Save" disabled={disabled || !formik.dirty || isLoaded}>
              {disabled ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
              Save
            </button>
          </PermissionsGate>
          <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()}>
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {formik.touched.Email && formik.errors.Email ? <div className="invalid-feedback d-block">{formik.errors.Email}</div> : null}
          {formik.touched.Password && formik.errors.Password ? <div className="invalid-feedback d-block">{formik.errors.Password}</div> : null}
          {formik.touched.ConfirmPassword && formik.errors.ConfirmPassword ? <div className="invalid-feedback d-block">{formik.errors.ConfirmPassword}</div> : null}
        </div>
      </form>
    </div>
  );
}

export default LoginDetail;
