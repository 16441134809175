import { BorderRight } from "@material-ui/icons";
import React, { useEffect, useMemo, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import HtmlParser from "react-html-parser";
import { useParams } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import { GetListofModule, GetPMCPdf } from "../../../services/ProgramService";
import {
  RenderFeatureAndBenefits,
  RenderLearningMethod,
  RenderModuleTerm,
  RenderModuleType,
  RenderSchool,
  RenderTypeInModule,
} from "../../../utils/CommonGroupingItem";
import { formatQuestion } from "../../../utils/FormatQuestion";
import Str from "../../common/Str";
const customStyles = {
  rows: {
    style: {
      minHeight: "32px", // override the row height
      minWidth: "32px",
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      border: "1px solid black",
      paddingBottom: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      border: "1px solid black",
      borderTop: "0px",
      BorderRight: "0px",
    },
  },
};

export const ExportPDF = () => {
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [moduleDetailsData, setModuleDetailsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetListofModule()
      .then((res) => {
        let moduleData = res?.data.map((mod) => ({
          value: mod.Id,
          label: mod.Portal,
        }));
        moduleData.push({ value: 0, label: "None" });
        setModuleDetailsData(moduleData);
      })
      .catch((err) => console.log(err));
    document.getElementsByTagName("main")[0].classList.add("header-padding");
  }, []);

  useEffect(() => {
    setLoading(true);
    GetPMCPdf({ PMC_ID: id })
      .then((res) => {
        setDetails(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("error :", err);
        setLoading(false);
      });
  }, [id]);

  const typeSort = (a, b) => {
    return a &&
      a.type.toString().toLowerCase() > b &&
      b.type.toString().toLowerCase()
      ? 1
      : -1;
  };

  const featuresColumns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      // sortable: true,
      cell: (row) =>
        row.name ? (
          <span title={row.name} className="line-break">
            {row.name}
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Features & Benefits",
      selector: "feature_benefit",
      // sortable: true,
      cell: (row) =>
        row.feature_benefit ? (
          <span className="line-break">{HtmlParser(row.feature_benefit)}</span>
        ) : (
          "-"
        ),
    },
    {
      name: "Type",
      selector: "type",
      // sortable: true,
      // sortFunction: typeSort,
      wrap: true,
      cell: (row) =>
        row.type ? (
          <span
          // className={`as-${RenderFeatureAndBenefits(row.type).color
          //   }-bg as-widget`}
          >
            {" "}
            {RenderFeatureAndBenefits(row.type).text}{" "}
          </span>
        ) : (
          "-"
        ),
    },
  ]);
  const TopicColumns = useMemo(() => [
    {
      name: "Topic",
      selector: "topic_name",
      // sortable: true,
      cell: (row) =>
        row.topic_name ? (
          <span
            // title={row.topic_name}
            className="line-break"
          >
            {HtmlParser(row.topic_name)}
          </span>
        ) : (
          "-"
        ),
    },
  ]);
  const outcomeColumns = useMemo(() => [
    {
      name: "Outcomes",
      // selector: "module_outcomes",
      // sortable: true,
      cell: (row) =>
        row.module_outcomes ? (
          <span
            // title={row.module_outcomes}
            className="line-break"
          >
            {HtmlParser(row.module_outcomes)}
          </span>
        ) : (
          "-"
        ),
    },
  ]);

  return (
    <div className="content-wrapper ">
      <div className="card card-profile-info-card ">
        {loading ? (
          <SkeletonTicketList />
        ) : (
          <div className="card-body export-font-change">
            <div className="card-body-inr card-body-info">
              <section className="my-tickets-info-list Tickets-main-wrap">
                <div className="edit-icon new-card-header programme-name">
                  <div className="card-header programme-font export-heading">
                    {details["SAQA_Name"]}
                  </div>
                </div>
                <div className="edit-icon new-card-header edit-icon-margin">
                  <div className="card-header export-heading-width">
                    <span className="export-heading-border">General</span>
                  </div>
                </div>
                <br />
                <form>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group-blk">
                        <label htmlFor="name" className="font-weight-bold d-flex">
                          <span className="export-white-space">SAQA Registered Name &nbsp;</span>
                          : <span className="export-word-break">&nbsp;{details["SAQA_Name"] ? details["SAQA_Name"] : "N/A"}</span>
                        </label>{" "}
                      
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group-blk">
                        <label htmlFor="name" className="font-weight-bold d-flex">
                          <span className="export-white-space">Short Name &nbsp;</span>
                            : <span className="export-word-break">&nbsp;{details["name"] ? details["name"] : "N/A"}</span>
                        </label>
                        {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Shorted Name"
                        name="name"
                        id="name"
                        title="Shorted Name"
                        value={details["name"]}
                      /> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group-blk">
                        <label htmlFor="code" className="font-weight-bold">
                          Code
                        </label>{" "}
                        : {details.Code ? details.Code : "N/A"}
                        {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Enter code"
                        name="Code"
                        id="code"
                        title="Code"
                        value={details.Code}
                      /> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group-blk" title="NQF Level">
                        <label htmlFor="level" className="font-weight-bold">
                          NQF Level{" "}
                        </label>{" "}
                        : {details.NQF_Level ? details.NQF_Level : "N/A"}
                        {/* <input
                        className="form-control"
                        name="NQF_Level"
                        value={details.NQF_Level}
                        placeholder="Select NQF Level"
                      /> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group-blk" title="School">
                        <label htmlFor="school" className="font-weight-bold d-flex">
                        <span className="export-white-space">School &nbsp;</span>
                        : <span className="export-word-break">&nbsp;{details.School ? RenderSchool(details.School).text : "N/A"}</span>
                        </label>
                        {/* <input
                        className="form-control"
                        name="School"
                        id="school"
                        value={details.School}
                        placeholder="Select School"
                      /> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group-blk">
                        <label htmlFor="credit" className="font-weight-bold">
                          Credits
                        </label>{" "}
                        : {details.Credits ? details.Credits : "N/A"}
                        {/* <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Credits"
                        name="Credits"
                        id="credit"
                        title="Credits"
                        value={details.Credits}
                      /> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group-blk">
                        <label htmlFor="link" className="font-weight-bold d-flex">
                        <span className="export-white-space">SAQA Link &nbsp;</span>
                        :<span className="export-word-break">&nbsp;{details["SAQA_Link"] ? (
                          <a target={"_blank"} href={details["SAQA_Link"]}>
                            {details["SAQA_Link"]}
                          </a>
                        ) : (
                          "N/A"
                        )}</span>
                        </label>
                        {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Enter SAQA Link"
                        name="SAQA_Link"
                        id="link"
                        title="SAQA Link"
                        value={details["SAQA_Link"]}
                      /> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group-blk" title="Status">
                        <label className="font-weight-bold">Status </label> :{" "}
                        {details.status ? details.status : "N/A"}
                        {/* <input
                        className="form-control"
                        name="status"
                        value={details.status}
                        maxMenuHeight={175}
                        placeholder="Select Status"
                      /> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group-blk" title="Learning Method">
                        <label className="font-weight-bold">
                          Learning Method{" "}
                        </label>{" "}
                        :{" "}
                        {details.Delivery_Methods
                          ? RenderLearningMethod(details.Delivery_Methods).text
                          : "N/A"}
                        {/* <input
                        className="form-control"
                        name="Delivery_Methods"
                        value={details.Delivery_Methods}
                        placeholder="Select Learning Method"
                      /> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group-blk" title="Term Periods">
                        <label
                          htmlFor="timeperiod"
                          className="font-weight-bold"
                        >
                          Term Periods{" "}
                        </label>{" "}
                        : {details.Term_Period ? details.Term_Period : "N/A"}
                        {/* <input
                        className="form-control"
                        name="termPeriod"
                        value={details.termPeriod}
                        id="timeperiod"
                        maxMenuHeight={175}
                        placeholder="Select Term Period"
                      /> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group-blk">
                        <label htmlFor="duration" className="font-weight-bold">
                          Duration (Weeks)
                        </label>{" "}
                        : {details.Duration ? details.Duration : "N/A"}
                        {/* <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Duration (Weeks)"
                        name="Duration"
                        id="duration"
                        title="Duration (Weeks)"
                        value={details.Duration}
                      /> */}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group-blk">
                        <label
                          htmlFor="duration"
                          className="font-weight-bold export-html-top"
                        >
                          Purpose & Rationale
                        </label>{" "}
                        :{" "}
                        <div className="export-data-font-size">
                          {details.Description
                            ? HtmlParser(details.Description)
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group-blk">
                        <label
                          htmlFor="duration"
                          className="font-weight-bold export-html-top"
                        >
                          Admission Requirements
                        </label>{" "}
                        :{" "}
                        <div>
                          {details.admission_req
                            ? HtmlParser(details.admission_req)
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group-blk">
                        <label
                          htmlFor="duration"
                          className="font-weight-bold export-html-top"
                        >
                          {" "}
                          Special Admission Requirements{" "}
                        </label>{" "}
                        :{" "}
                        <div>
                          {details.special_admission_req
                            ? HtmlParser(details.special_admission_req)
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group-blk">
                        <label
                          htmlFor="duration"
                          className="font-weight-bold export-html-top"
                        >
                          Career Opportunity
                        </label>{" "}
                        :{" "}
                        <div>
                          {details.career_opportunities
                            ? HtmlParser(details.career_opportunities)
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group-blk">
                        <label
                          htmlFor="duration"
                          className="font-weight-bold export-html-top"
                        >
                          Articulation Option
                        </label>{" "}
                        :{" "}
                        <div>
                          {details.articultion_option
                            ? HtmlParser(details.articultion_option)
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group-blk">
                        <label
                          htmlFor="duration"
                          className="font-weight-bold export-html-top "
                        >
                          Regulatory Information
                        </label>{" "}
                        :{" "}
                        <div>
                          {details.regulatory_inform
                            ? HtmlParser(details.regulatory_inform)
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group-blk mb-3">
                        <label
                          htmlFor="duration"
                          className="font-weight-bold export-html-top"
                        >
                          Important Notes
                        </label>{" "}
                        :{" "}
                        <div>
                          {details.important_notes
                            ? HtmlParser(details.important_notes)
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </section>

              <section className="my-tickets-info-list Tickets-main-wrap">
                <div className="edit-icon new-card-header mb-0">
                  <div className="card-header export-heading-width">
                    <span className="export-heading-border border-bottom-0">
                      Features and Benefits
                    </span>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-12">
                    <div className="custom-table-div filter-search-icon card card-table-custom export-border">
                      <DataTable
                        data={details.ProgrammFeaturesAndBenefits}
                        columns={featuresColumns}
                        pagination={false}
                        customStyles={customStyles}
                        noDataComponent={Str.noRecord}
                        defaultSortAsc={true}
                        defaultSortField={"name"}
                      />
                    </div>
                  </div>
                </div> */}

                <table className="export-table-border-header">
                  <thead>
                    <tr className="export-table-tr export-table-th">
                      <th>Name</th>
                      <th style={{ width: "75%" }}>Features & Benefits</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  {details.ProgrammFeaturesAndBenefits.length ? (
                    details.ProgrammFeaturesAndBenefits.map((row) => (
                      <tbody>
                        <tr>
                          <td className="export-vertical-align">{row.AssignedFeaturesAndBenefit.name}</td>
                          <td>{HtmlParser(row.AssignedFeaturesAndBenefit.feature_benefit)}</td>
                          <td className="export-vertical-align">
                            {RenderFeatureAndBenefits(row.AssignedFeaturesAndBenefit.type).text}
                          </td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={3}>There are no records to show</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </section>
              
             <section className="my-tickets-info-list Tickets-main-wrap">
              <div className="card-header export-heading-width pb-0">
                <span className="export-heading-border border-bottom-0">Modules</span>
              </div>
              {details && details?.PmcRltnModule.length ? (
                details?.PmcRltnModule.map((moduleDetail, index) => (
                  <>
                   <div className="card-sub-list">
                   <div className="module-title">
                      <span className="module-module-heading">
                        {" "}
                        <div className="form-group-blk">
                          <label className="font-weight-bold text-white">
                            Module Name
                          </label>{" "}
                          :{" "}
                          {moduleDetail &&
                          moduleDetail.ProgrammModules &&
                          moduleDetail.ProgrammModules[0] &&
                          moduleDetail.ProgrammModules[0].Portal
                            ? moduleDetail.ProgrammModules[0].Portal
                            : "N/A"}
                        </div>
                      </span>
                    </div>

                    <div className="export-module-box-border">

                      <div>
                        <div className="my-tickets-info-list Tickets-main-wrap">
                          <form>
                            {moduleDetail &&
                              moduleDetail.ProgrammModules &&
                              moduleDetail.ProgrammModules[0] && (
                                <div className="row">
                                  {/* <div className="col-md-3">
                                    <div className="form-group-blk">
                                      <label className="font-weight-bold">
                                        Module Name
                                      </label>{" "}
                                      :{" "}
                                      {moduleDetail &&
                                      moduleDetail.ProgrammModules &&
                                      moduleDetail.ProgrammModules[0] &&
                                      moduleDetail.ProgrammModules[0].Portal
                                        ? moduleDetail.ProgrammModules[0].Portal
                                        : "N/A"}
                                    </div>
                                  </div> */}
                                  <div className="col-md-3">
                                    <div
                                      className="form-group-blk"
                                      title="Status"
                                    >
                                      <label className="font-weight-bold">
                                        Status
                                      </label>{" "}
                                      :{" "}
                                      {moduleDetail &&
                                      moduleDetail.ProgrammModules &&
                                      moduleDetail.ProgrammModules[0] &&
                                      moduleDetail.ProgrammModules[0]
                                        .module_status
                                        ? moduleDetail.ProgrammModules[0]
                                            .module_status
                                        : "N/A"}
                                      {/* <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Status"
                              name="status"
                              id="status"
                              title="Status"
                              value={
                                moduleDetail.ProgrammModules[0].module_status
                              }
                            /> */}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group-blk">
                                      <label
                                        className="font-weight-bold"
                                        htmlFor="code"
                                      >
                                        Code
                                      </label>{" "}
                                      :{" "}
                                      {moduleDetail &&
                                      moduleDetail.ProgrammModules &&
                                      moduleDetail.ProgrammModules[0] &&
                                      moduleDetail.ProgrammModules[0].Code
                                        ? moduleDetail.ProgrammModules[0].Code
                                        : "N/A"}
                                      {/* <input
                              type="text"
                              className="form-control"
                              placeholder="Enter code"
                              name="Code"
                              id="code"
                              title="Code"
                              maxLength={20}
                              value={moduleDetail.ProgrammModules[0].Code}
                            /> */}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      className="form-group-blk"
                                      title="Duration"
                                    >
                                      <label className="font-weight-bold">
                                        Duration (Weeks)
                                      </label>{" "}
                                      :{" "}
                                      {moduleDetail &&
                                      moduleDetail.ProgrammModules &&
                                      moduleDetail.ProgrammModules[0] &&
                                      moduleDetail.ProgrammModules[0].Duration
                                        ? moduleDetail.ProgrammModules[0]
                                            .Duration
                                        : "N/A"}
                                      {/* <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Duration"
                              name="Duration"
                              id="duration"
                              title="Duration"
                              maxLength={20}
                              value={moduleDetail.ProgrammModules[0].Duration}
                            /> */}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group-blk">
                                      <label className="font-weight-bold d-flex">
                                      <span className="export-white-space">Marketing Name &nbsp;</span>
                                      : <span className="export-word-break">&nbsp;{moduleDetail.ProgrammModules[0]
                                        .Marketing_Name
                                        ? moduleDetail.ProgrammModules[0]
                                            .Marketing_Name
                                        : "N/A"}</span>
                                      </label>
                                      {/* <input
                              type="text"
                              className="form-control"
                              placeholder="Enter marketing name"
                              title="Enter marketing name"
                              name="Marketing_Name"
                              value={
                                moduleDetail.ProgrammModules[0].Marketing_Name
                              }
                            /> */}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group-blk ">
                                      <label className="font-weight-bold">
                                        Credits
                                      </label>{" "}
                                      :{" "}
                                      {moduleDetail.ProgrammModules[0].credits
                                        ? moduleDetail.ProgrammModules[0]
                                            .credits
                                        : "N/A"}
                                      {/* <input
                              type="number"
                              className="form-control"
                              placeholder="Enter credits"
                              title="Enter credits"
                              name="credits"
                              value={moduleDetail.ProgrammModules[0].credits}
                            /> */}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      className="form-group-blk "
                                      title="Module Type"
                                    >
                                      <label className="font-weight-bold">
                                        Module Type
                                      </label>{" "}
                                      :{" "}
                                      {moduleDetail.ProgrammModules[0]
                                        .Module_Type
                                        ? RenderModuleType(
                                            moduleDetail.ProgrammModules[0]
                                              .Module_Type
                                          ).text
                                        : "N/A"}
                                      {/* <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Module Type"
                              title="Enter Module Type"
                              name="module_type"
                              value={
                                moduleDetail.ProgrammModules[0].Module_Type
                              }
                            /> */}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      className="form-group-blk "
                                      title="Term"
                                    >
                                      <label className="font-weight-bold">
                                        Term
                                      </label>{" "}
                                      :{" "}
                                      {moduleDetail.ProgrammModules[0].term
                                        ? RenderModuleTerm(
                                            moduleDetail.ProgrammModules[0].term
                                          ).text
                                        : "N/A"}
                                      {/* <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Term"
                              title="Enter Term"
                              name="term"
                              value={moduleDetail.ProgrammModules[0].term}
                            /> */}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      className="form-group-blk "
                                      title="Type"
                                    >
                                      <label className="font-weight-bold">
                                        Type
                                      </label>{" "}
                                      :{" "}
                                      {moduleDetail.ProgrammModules[0].type
                                        ? RenderTypeInModule(
                                            moduleDetail.ProgrammModules[0].type
                                          ).text
                                        : "N/A"}
                                      {/* <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Type"
                              title="Enter Type"
                              name="type"
                              value={moduleDetail.ProgrammModules[0].type}
                            /> */}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      className="form-group-blk "
                                      title="Pre-Requisites"
                                    >
                                      <label className="font-weight-bold">
                                        Pre-Requisites
                                      </label>{" "}
                                      :{" "}
                                      {moduleDetail.ProgrammModules[0]
                                        .pre_requisites
                                        ? JSON.parse(
                                            moduleDetail.ProgrammModules[0]
                                              .pre_requisites
                                          ).map((item, index) => {
                                            let filtered =
                                              moduleDetailsData.filter(
                                                (val) => val.value == item
                                              );
                                            if (filtered.length) {
                                              return (
                                                filtered[0].label +
                                                (index <
                                                JSON.parse(
                                                  moduleDetail
                                                    .ProgrammModules[0]
                                                    .pre_requisites
                                                ).length -
                                                  1
                                                  ? ", "
                                                  : " ")
                                              );
                                            } else {
                                              return "";
                                            }
                                          })
                                        : ""}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div
                                      className="form-group-blk "
                                      title="Co-Requisites"
                                    >
                                      <label className="font-weight-bold">
                                        Co-Requisites
                                      </label>{" "}
                                      :{" "}
                                      {moduleDetail.ProgrammModules[0]
                                        .co_requisites
                                        ? JSON.parse(
                                            moduleDetail.ProgrammModules[0]
                                              .co_requisites
                                          ).map((item, index) => {
                                            let filtered =
                                              moduleDetailsData.filter(
                                                (val) => val.value == item
                                              );
                                            if (filtered.length) {
                                              return (
                                                filtered[0].label +
                                                (index <
                                                JSON.parse(
                                                  moduleDetail
                                                    .ProgrammModules[0]
                                                    .co_requisites
                                                ).length -
                                                  1
                                                  ? ", "
                                                  : " ")
                                              );
                                            } else {
                                              return "";
                                            }
                                          })
                                        : ""}
                                      {/* <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Co-Requisites"
                              title="Enter Co-Requisites"
                              name="co_requisites"
                              value={
                                moduleDetail.ProgrammModules[0].co_requisites
                              }
                            /> */}
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <div className="form-group-blk module-margin2">
                                      <label className="font-weight-bold">
                                        Description :
                                      </label>
                                      <div>
                                        {moduleDetail.ProgrammModules[0]
                                          .Description ? (
                                          HtmlParser(
                                            moduleDetail.ProgrammModules[0]
                                              .Description
                                          )
                                        ) : (
                                          <p>N/A</p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group-blk module-margin2">
                                      <label className="font-weight-bold export-html-top">
                                        Prescribed Textbooks :
                                      </label>
                                      <div>
                                        {moduleDetail.ProgrammModules[0]
                                          .prescribed_text ? (
                                          HtmlParser(
                                            moduleDetail.ProgrammModules[0]
                                              .prescribed_text
                                          )
                                        ) : (
                                          <p>N/A</p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </form>
                        </div>

                        <div className="my-tickets-info-list Tickets-main-wrap">
                          {/* <div className="row">
                        <div className="col-md-12">
                          <div className="custom-table-div filter-search-icon card card-table-custom export-border">
                            <DataTable
                              data={moduleDetail.PmcTopic}
                              columns={TopicColumns}
                              pagination={false}
                              noDataComponent={Str.noRecord}
                              defaultSortAsc={true}
                              customStyles={customStyles}
                              // defaultSortField={""}
                            />
                          </div>
                        </div>
                      </div> */}

                          <table className="export-table-border-header">
                            <thead>
                              <tr className="export-table-tr export-table-th">
                                <th>Topics</th>
                              </tr>
                            </thead>

                            {moduleDetail.PmcTopic.length ? (
                              moduleDetail.PmcTopic.map((row) => (
                                <tbody>
                                  <tr>
                                    <td> {HtmlParser(row.topic_name)}</td>
                                  </tr>
                                </tbody>
                              ))
                            ) : (
                              <tbody>
                                <tr>
                                  <td> There are no records to show</td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>

                        <div className="my-tickets-info-list Tickets-main-wrap export-outcome">
                          {/* <div className="row">
                        <div className="col-md-12">
                          <div className="custom-table-div filter-search-icon card card-table-custom export-border">
                            <DataTable
                              data={moduleDetail.PmcModuleOutcomes}
                              columns={outcomeColumns}
                              pagination={false}
                              noDataComponent={Str.noRecord}
                              defaultSortAsc={true}
                              customStyles={customStyles}
                              // defaultSortField={""}
                            />
                          </div>
                        </div>
                      </div> */}

                          <table className="export-table-border-header">
                            <thead>
                              <tr>
                                <th className="export-table-tr export-table-th">
                                  Outcomes
                                </th>
                              </tr>
                            </thead>
                            {moduleDetail.PmcModuleOutcomes.length ? (
                              moduleDetail.PmcModuleOutcomes.map((row) => (
                                <tbody>
                                  <tr>
                                    <td className="image-content">{HtmlParser(row.module_outcomes)}</td>
                                  </tr>
                                </tbody>
                              ))
                            ) : (
                              <tbody>
                                <tr>
                                  <td>There are no records to show</td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                        <div className="my-tickets-info-list Tickets-main-wrap export-outcome">
                          <table className="export-table-border-header">
                            <thead>
                              <tr>
                                <th className="export-table-tr export-table-th">
                                  Resources
                                </th>
                                <th className="export-table-tr export-table-th">
                                  SAQA Link
                                </th>
                              </tr>
                            </thead>
                            {moduleDetail.ModuleResources.length ? (
                              moduleDetail.ModuleResources.map((row) => (
                                <tbody>
                                  <tr>
                                    <td className="image-content">{row.name}</td>
                                    <td className="image-content">{row.saqa_link}</td>
                                  </tr>
                                </tbody>
                              ))
                            ) : (
                              <tbody>
                                <tr>
                                  <td>There are no records to show</td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                    </div>         
                  </>
                ))
              ) : (
                <div className="export-module-box-border">
                  There are no records to show
                </div>
              )}
              </section>  

            </div>
          </div>
        )}
      </div>
    </div>
  );
};
