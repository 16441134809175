import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import "jspdf-autotable";
import JsPDF from "jspdf";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import Swal from 'sweetalert2';
import Str from '../../../common/Str';
import DataTable from 'react-data-table-component';
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import { DeleteAssignedAssemblyResource, GetAssignedAssembliesToResource } from '../../../../services/CourseService';
import moment from 'moment';
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from '../../../../utils/Constants';
import { handleTableScroll } from '../../../../utils/commonFunction';

const LinkedAssemblies = () => {

    const { tab, type, id, resourcePoolId, subTabId } = useParams();
    const history = useHistory();
    const [search, setSearch] = useState("");
    const [search2, setSearch2] = useState("");
    const [loading, setLoading] = useState(true);
    const [deleteRow, setDeleteRow] = useState(false);
    const [linkedAssemblies, setLinkedAssemblies] = useState([]);

    useEffect(() => {
        handleTableScroll()
      }, [loading])

    useEffect(() => {
        if (type !== "add") {
            getData();
        } else {
            setLoading(false);
        }
    }, [deleteRow]);

    const getData = () => {
        GetAssignedAssembliesToResource(id).then((res) => {
            setLinkedAssemblies(res?.data?.list);
            setLoading(false);
        });
    };

    const columns2 = useMemo(() => [
        {
            name: "Name",
            selector: "name",
            sortable: true,
            cell: (row) => (
                <>
                    <Link className="as-text-blue curser" to={{ pathname: `/courses/content/assemblies/edit/${row.assembly_id}`, state: { id: row.assembly_id } }} title={row.name ? row.name : "-"}>
                        {row.name ? row.name : "-"}
                    </Link>
                </>
            ),
        },
        {
            name: "Created By",
            selector: "user_name",
            sortable: true,
            cell: (row) => (<span title={row.user_name ? row.user_name : "-"}>{row.user_name ? row.user_name : "-"}</span>),
        },
        {
            name: "Created Date",
            selector: "updated_at",
            sortable: true,
            cell: (row) => {
                return (
                    <p>
                        {row.updated_at ? (
                            <div className="dateTime" title={moment(row.updated_at).format(TABLE_DATE_FORMAT) + " " + moment(row.updated_at).format(TABLE_TIME_FORMAT)}>
                                <p className="right-space">{moment(row.updated_at).format(TABLE_DATE_FORMAT)}</p>
                                <p>{moment(row.updated_at).format(TABLE_TIME_FORMAT)}</p>
                            </div>
                        ) : (
                            "-"
                        )}
                    </p>
                )
            }
        },
        {
            name: "Version",
            selector: "item_version",
            sortable: true,
            cell: (row) => (<span title={row.item_version ? row.item_version : "NA"}>{row.item_version ? row.item_version : "NA"}</span>),
        },
        {
            name: "Actions",
            selector: "",
            cell: (row) => (
                <div className="assessment-08">
                    <div className="as-buttons">
                        <Link className="mr-1" to={{ pathname: `/courses/content/assemblies/edit/${row.assembly_id}`, state: { id: row.assembly_id } }} >
                            <button className="btn btn-primary rounded-circle" title="Open" type="button">
                                <i className="fal fa-folder-open"></i>
                            </button>
                        </Link>
                        <button className="btn btn-danger rounded-circle" title="Unlink" onClick={() => deleteresource(row?.assign_id)} >
                            <i className="fal fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
            ),
        },
    ]);

    const dataToRender2 = () => {
        let updatedData = [];
        let allData = linkedAssemblies;
        if (search2.length) {
            let tempName = allData.filter((item) => {
                let includes = item?.name?.toString().toLowerCase().includes(search2.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });

            let tempIntake = allData.filter((item) => {
                let includes = item?.intake_year && item?.intake_year?.toString().includes(search2.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempCourseType = allData.filter((item) => {
                let includes = item?.item_version && item?.item_version?.toString().toLowerCase().includes(search2.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });
            let tempStudyType = allData.filter((item) => {
                let includes = item?.updated_at && item?.updated_at?.toString().toLowerCase().includes(search2.toLowerCase());
                if (includes) {
                    return includes;
                } else return null;
            });


            let data = [...tempName, ...tempIntake, ...tempCourseType, ...tempStudyType];
            let unique = [...new Set(data)];

            updatedData = unique;
        } else {
            updatedData = allData;
        }

        if (search2.length) {
            return updatedData;
        } else {
            return allData;
        }
    };

    const handleSearchFilter2 = (e) => {
        const value = e.target.value;
        setSearch2(value);
    };

    const exportData2 = (fileType, fileName) => {
        let data = dataToRender2();
        const header = ["Name", "Created By", "Created Date", "Version"];
        data = data?.map((row) => ({
            ...row,
            Name: row?.name || "-",
            "Created By": row?.user_name || "-",
            "Created Date": moment(row.updated_at).format(TABLE_DATE_FORMAT) + " " + moment(row.updated_at).format(TABLE_TIME_FORMAT),
            "Version": row?.item_version || "-",
        }));
        // console.log(typeof data);
        // console.log(typeof result);

        if (fileType === "csv") {
            const csvString = Papa.unparse({ fields: header, data });
            const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });
            const blobURL = window.URL.createObjectURL(blob);

            // Create new tag for download file
            const anchor = document.createElement("a");
            anchor.download = fileName;
            anchor.href = blobURL;
            anchor.dataset.downloadurl = [
                "text/csv",
                anchor.download,
                anchor.href,
            ].join(":");
            anchor.click();

            // Remove URL.createObjectURL. The browser should not save the reference to the file.
            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                URL.revokeObjectURL(blobURL);
            }, 1000);
        } else if (fileType === "xlsx") {
            const compatibleData = data.map((row) => {
                const obj = {};
                header.map((col, index) => {
                    obj[col] = row[col];
                });
                return obj;
            });

            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);

            // Returning false as downloading of file is already taken care of
            return false;
        }
        if (fileType === "pdf") {
            const compatibleData = data.map((row) => {
                return [row.name, row["user_name"], moment(row.updated_at).format(TABLE_DATE_FORMAT) + " " + moment(row.updated_at).format(TABLE_TIME_FORMAT), row["item_version"]];
            });
            const doc = new JsPDF();
            doc.autoTable({
                head: [header],
                body: compatibleData,
                styles: {
                    minCellHeight: 10,
                    minCellWidth: 5,
                    halign: "left",
                    // valign: "center",
                    fontSize: 8,
                },
            });
            doc.save(`${fileName}.pdf`);

            return false;
        }
    };

    const deleteresource = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteAssignedAssemblyResource({ assign_id: id }).then((res) => {
                    Swal.fire("Deleted!", `${res?.data?.message}`, "success");
                    getData();
                });
            }
        }).catch((error) => console.log(error));
    };

    const resetFilter2 = () => {
        setSearch2("");
    };

    return (

        <div className="custom-table-div filter-search-icon card card-table-custom custom-table-expand-blk ass-bel-stuff">
            <div className="custom-table-div filter-search-icon card card-table-custom new-datatable-blk ">
                <div className="filter-search-bar-blk">
                    <div className="filter-button-group">
                        <div className="filter-eff filter-data-btn">
                            <button className="filter-buttons">
                                <i className="fal fa-filter"></i>
                            </button>
                        </div>

                        <div className="button-reset dropdown-comman">
                            <button className="btn btn-primary" onClick={resetFilter2} title="Reset" >
                                <i className="fal fa-redo"></i>Reset
                            </button>
                        </div>

                        <div className="files-export-group">
                            <button
                                type="button"
                                className="btn btn-files"
                                onClick={() => {
                                    exportData2("xlsx", "Linked Assemblies");
                                }}
                                title="Export spreadsheet"
                            >
                                <i className="fal fa-file-excel icon"></i>
                            </button>

                            <button
                                type="button"
                                className="btn btn-files"
                                onClick={() => {
                                    exportData2("csv", "Linked Assemblies");
                                }}
                                title="Export CSV"
                            >
                                <i className="fal fa-file-csv icon"></i>
                            </button>

                            <button
                                type="button"
                                className="btn btn-files"
                                onClick={() => {
                                    exportData2("pdf", "Linked Assemblies");
                                }}
                                title="Export PDF"
                            >
                                <i className="fal fa-file-pdf icon"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="system-administration-table table-responsive">
                    <div className="table-responsive-div">
                        <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                <div id="assessment-table-main_filter" className="dataTables_filter">
                                    <label>
                                        <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" value={search2} onChange={handleSearchFilter2} />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {!loading ? (
                    <DataTable
                        data={dataToRender2()}
                        defaultSortField="updated_at"
                        defaultSortAsc={true}
                        noDataComponent={Str.noRecord}
                        columns={columns2}
                        pagination={true}
                        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                    />
                ) : (
                    <SkeletonTicketList />
                )}
            </div>
        </div>

    )

}

export default LinkedAssemblies;