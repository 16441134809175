import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GetReRegistrationStudentListDetail, GetStudentListDetail, NetSuiteCustomerList, NetSuiteCustomerService, UpdateCustomerNetsuiteId, UpdateReRigisterStudentCustomerId } from "../../../services/NetSuiteService";
import moment from "moment";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../utils/Constants";

function NetsuiteDetails() {
  const { id, tab } = useParams();
  const [studentData, setStudentData] = useState(false);
  const [netsuiteId, setNetsuiteId] = useState(false);
  const [userEmail, setUserEmail] = useState(false);
  const [deleterow, setDeleteRow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [data, setData] = useState([]);
  const [openFileSearch, setOpenFileSearch] = useState("");

  useEffect(() => {
    setIsLoaded(true)
    if (tab === "mergedDataRegistration") {
      GetReRegistrationStudentListDetail(id)
        .then((res) => {
          setUserEmail(res.data.data.email)
          if (res.data.data.CustomerID) {
            let formData = new FormData();
            formData.append("id", res.data.data.CustomerID);
            setNetsuiteId(res.data.data.CustomerID);
            NetSuiteCustomerList(formData)
              .then((res) => {
                setStudentData(res.data.data);
              })
              .catch((err) => {
                console.log("error", err);
              });
          } else {
            setNetsuiteId(null)
          }
        })
        .catch((err) => {
          console.log("error :", err);
        });
    }
    else {
      GetStudentListDetail(id)
        .then((res) => {
          setUserEmail(res.data.data.email)
          if (res.data.data.netsuite_id) {
            let formData = new FormData();
            formData.append("id", res.data.data.netsuite_id);
            setNetsuiteId(res.data.data.netsuite_id);
            NetSuiteCustomerList(formData)
              .then((res) => {
                setStudentData(res.data.data);
              })
              .catch((err) => {
                console.log("error", err);
              });
          } else {
            setNetsuiteId(null)
          }
        })
        .catch((err) => {
          console.log("error :", err);
        });
    }
  }, [deleterow]);

  useEffect(() => {
    setIsLoaded(true);
    let formData = new FormData();
    if (netsuiteId) {
      formData.append("id", netsuiteId);
      NetSuiteCustomerList(formData)
        .then((response) => {
          setData(response && response.data && response.data?.data && response.data?.data?.items && response.data?.data?.items.length && response?.data?.data?.items[0]);

          setIsLoaded(false)
        })
        .catch((error) => {
          console.log("error :", error);
        });
    } else if (userEmail) {
      formData.append("email", userEmail);
      NetSuiteCustomerList(formData)
        .then((response) => {
          setData(response && response.data && response.data?.data && response.data?.data?.items && response.data?.data?.items.length && response?.data?.data?.items);

          setIsLoaded(false)
        })
        .catch((error) => {
          console.log("error :", error)
        });
    }
  }, [deleterow, netsuiteId]);

  const columns = useMemo(() => [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      cell: (row) => (
        <div className="ticket-id">
          <span className="overflow-ellipsis2">
            <b>#{row?.id}</b>
          </span>
        </div>
      ),
    },
    {
      name: "Name",
      selector: "altname",
      sortable: true,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <p className="as-text-blue curser">{row?.altname ? row?.altname : '-'}</p>
          </div>
        </div>
      ),
    },
    // {
    //   name: "Name",
    //   selector: "firstname",
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="assigned-title-block-new">
    //       <div className="assigned-title-itm">
    //           {/* <a href="javascript:void(0);" className="as-text-blue curser " onClick={() => handleEdit(row, row.id)}>
    //             {row.lastname}
    //           </a> */}
    //           {row.lastname}
    //       </div>
    //     </div>
    //   ),
    // },
    { name: "Email", selector: "email", sortable: true, cell: (row) => (row?.email ? row?.email : "-") },
    {
      name: "Date Created",
      selector: "datecreated",
      sortable: true,
      // cell: (row) => (row?.datecreated ? row?.datecreated : "-")
      cell: (row) => (row?.datecreated && row?.datecreated != 0 ? `${moment(Number(row?.datecreated)).format(TABLE_DATE_FORMAT)} ${moment(Number(row?.datecreated)).format(TABLE_TIME_FORMAT)} ` : "-"),
    },
    {
      name: "Action",
      selector: "",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button className="btn btn-primary rounded-circle" data-toggle="modal" data-target="#findTable" onClick={() => handleLink(row?.id)}>
              <i className="fal fa-link"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const handleLink = (netSuiteid) => {
    setIsLoaded(true)
    const data = new FormData();

    if (tab === "mergedDataRegistration") {
      data.append("CustomerID", netSuiteid);
      data.append("id", id);
      UpdateReRigisterStudentCustomerId(data).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Linked Successfuly",
        })
        setDeleteRow(!deleterow)
      })
        .catch((err) => {
          console.log("error :", err.response);
          Swal.fire({ icon: "Warn", text: err.response.data[0].message, title: "Warning" });
          setDeleteRow(!deleterow)
        });
    }
    else {
      data.append("netsuite_id", netSuiteid);
      data.append("user_id", id);
      UpdateCustomerNetsuiteId(data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Linked Successfuly",
          })
          setDeleteRow(!deleterow)
        })
        .catch((err) => {
          console.log("error :", err.response);
          Swal.fire({ icon: "Warn", text: err.response.data[0].message, title: "Warning" });
          setDeleteRow(!deleterow)
        });
    }

    setIsLoaded(false)
  };

  const dataToRender2 = () => {
    let updatedData = [];
    let allData = Array.isArray(data) ? data : [];
    if (openFileSearch.length) {
      let tempId = allData.filter((item) => {
        let includes =
          item.id &&
          item.id.toLowerCase().includes(openFileSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempName = allData.filter((item) => {
        let includes =
          item.altname &&
          item.altname.toLowerCase().includes(openFileSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempEmail = allData.filter((item) => {
        let includes =
          item.email &&
          item.email.toLowerCase().includes(openFileSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let tempDate = allData.filter((item) => {
        let includes =
          item.datecreated &&
          item.datecreated.toLowerCase().includes(openFileSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [
        ...tempId,
        ...tempName,
        ...tempEmail,
        ...tempDate,
      ];
      let unique = [...new Set(data)];
      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (openFileSearch.length) {
      return updatedData;
    } else {
      return allData;
    }
  };

  const exportData2 = (fileType, fileName) => {
    let data = dataToRender2();
    const header = ["ID", "Name", "Email", "Date Created"];

    data = data?.map((row, i) => ({
      ...row,
      ID: row?.id ? row?.id : '-',
      Name: row?.altname ? row?.altname : "-",
      Email: row?.email ? row?.email : "-",
      "Date Created": (row?.datecreated && row?.datecreated != 0 ? `${moment(row?.datecreated).format(TABLE_DATE_FORMAT)} ${moment(row?.datecreated).format(TABLE_TIME_FORMAT)} ` : "-"),
    }));

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data?.map((row) => {
        return [
          row?.id ? row?.id : '-',
          row?.altname ? row?.altname : "-",
          row?.email ? row?.email : "-",
          (row?.datecreated && row?.datecreated != 0) ? `${moment(row?.datecreated).format(TABLE_DATE_FORMAT)} ${moment(row?.datecreated).format(TABLE_TIME_FORMAT)} ` : "-",
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const handleOpenFileSearchFilter = (e) => {
    const value = e.target.value;
    setOpenFileSearch(value);
  };

  const resetOpenFileFilter = () => {
    setOpenFileSearch("");
  };

  return (
    <div className="card card-profile-info-card">
      <div className={`card-body `}>
        <div className="card-header">Netsuite Details</div>
        {isLoaded ? <SkeletonTicketList /> :
          netsuiteId ? (
            <div className="card-body-inr card-body-info">
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li>
                        <span className="title">Date Created :</span>
                        <span> {data.datecreated ? data.datecreated : "-"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li>
                        <span className="title">Last Modified Date :</span>
                        <span> {data.lastmodifieddate ? data.lastmodifieddate : "-"}</span>
                        {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/tickets/show" }}>
                          </Link> */}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li>
                        <span className="title">Address :</span>
                        <span> {data.address ? "NA" : "NA"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li>
                        <span className="title ">Date Closed :</span>
                        <span> {data.dateclosed ? data.dateclosed : "NA"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li>
                        <span className="title">Email :</span>
                        <span> {data.email ? data.email : "-"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li>
                        <span className="title">Probability :</span>
                        <span> {data.probability ? data.probability : "NA"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li>
                        <span className="title">Is inactive :</span>
                        <span> {data.isinactive ? (data.isinactive === "T" ? "YES" : "NO") : "NA"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li>
                        <span className="title">Home Address :</span>
                        <span> {data.homeAddress ? data.homeAddress : "NA"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li>
                        <span className="title">Balance :</span>
                        <span> {data.balancesearch ? data.balancesearch.toString() : "-"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li>
                        <span className="title ">Netsuite Link :</span>
                        <a className="Netsuite-Link-Width" href={`https://7535324.app.netsuite.com/app/common/entity/custjob.nl?id=${netsuiteId}`} target="_blank">
                          {netsuiteId ? `https://7535324.app.netsuite.com/app/common/entity/custjob.nl?id=${netsuiteId}` : "NA"}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="basic-info-box-list">
                    <ul>
                      <li>
                        <span className="title">Shipping Address :</span>
                        <span> {studentData.shippingAddress ? studentData.shippingAddress : "NA"}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="modal fade" id="findTable" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-body p-0">
                      {/* <div className="topic-add-modal-content text-center font-weight-bold">
                      <i className="fal fa-comment"></i> Find Customer
                    </div>
                    <hr /> */}
                      <div className="modal-header modal-header-custom">
                        <h5 className="modal-title">
                          <i className="fal fa-comment"></i> Find Customer
                        </h5>
                        <button type="button" data-dismiss="modal" className="close" title="Close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
                      </div>

                      <div className="p-30px l-o-c-t custom-table-div filter-search-icon card card-table-custom">
                        <div className="search-filter-div">
                          <div className="search-filter-div-left">
                            <div className="system-administration-table table-responsive">
                              <div className="table-responsive-div">
                                <div
                                  id="assessment-table-main_wrapper"
                                  className="dataTables_wrapper no-footer"
                                >
                                  <div
                                    id="assessment-table-main_filter"
                                    className="dataTables_filter"
                                  >
                                    <label>
                                      <input
                                        type="search"
                                        className=""
                                        placeholder="Search"
                                        aria-controls="assessment-table-main"
                                        onChange={handleOpenFileSearchFilter}
                                        value={openFileSearch}
                                      />
                                    </label>
                                    <div className="filter-eff filter-data-btn">
                                      <button className="filter-buttons">
                                        <i className="fal fa-filter"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="filter-button-group">
                                <div className="button-reset dropdown-comman">
                                  <button
                                    className="btn btn-primary"
                                    onClick={resetOpenFileFilter}
                                    title="Reset"
                                  >
                                    <i className="fal fa-redo"></i>Reset
                                  </button>
                                </div>
                                <div className="files-export-group">
                                  <button
                                    type="button"
                                    className="btn btn-files"
                                    onClick={() => {
                                      exportData2("xlsx", "email");
                                    }}
                                    title="Export spreadsheet"
                                  >
                                    <i className="fal fa-file-excel icon"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-files"
                                    onClick={() => {
                                      exportData2("csv", "email");
                                    }}
                                    title="Export CSV"
                                  >
                                    <i className="fal fa-file-csv icon"></i>
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-files"
                                    onClick={() => {
                                      exportData2("pdf", "email");
                                    }}
                                    title="Export PDF"
                                  >
                                    <i className="fal fa-file-pdf icon"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {isLoaded ? <SkeletonTicketList />
                          : <DataTable
                            data={dataToRender2()}
                            defaultSortField="id"
                            defaultSortAsc={false}
                            columns={columns}
                            pagination={true}
                            noDataComponent={Str.noRecord}
                            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                          />}
                      </div>
                      {/* <div className="topic-add-modal-content">
                  <div className="row">
                    <div className="col-4 font-weight-bold">Comment</div>
                    <div className="col-8">
                      <textarea type="text" className="form-control"  />
                    </div>
                  </div>
                </div> */}
                    </div>

                    {/* <div className="modal-footer"> */}
                    {/* <PermissionsGate scopes={["cedit"]} errorProps={{ disabled: true }}> */}
                    {/* <button type="button" className="btn btn-primary" data-dismiss="modal" >
                          <i className="fal fa-check"></i>
                          Post
                          </button> */}
                    {/* </PermissionsGate> */}
                    {/* <button type="button" className="btn btn-danger" data-dismiss="modal">
                        <i className="fal fa-times"></i>
                        Close
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
              <button className="btn btn-primary" data-toggle="modal" data-target="#findTable">
                Find Customer
              </button>
            </div>
          )}
      </div>
    </div>
  );
}

export default NetsuiteDetails;
