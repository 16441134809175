import axiosInstance from "../utils/axios";

export const GetMyUnreadChatNotificationCount = async () => {
    return await axiosInstance().get("/getAllUnreadCommentMessagesCount");
};

export const readCommentMessages = async (values) => {
    return await axiosInstance().post(`/readCommentMessages`, values);
};
export const GetMentionedUserDetails = async (id) => {
    return await axiosInstance().post(`/getStudentProfile?id=${id}`)
  }