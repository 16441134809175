import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import Papa from 'papaparse';
import { ListofSchedules } from './ListSchedule';

export const DisplaySchedule = ({
  campusID = '',
  roomID = '',
  lecturerID = '',
  courseID = '',
  onResetButtonClick,
  onCalendarView,
  campusName = '',
  roomName = '',
  // isLecturerFilterChecked_ = false,
  // isCourseFilterChecked_ = false
}) => {
  const [calendarEvent, setCalendarEvent] = useState('today');
  const [fileExportData, setFileExportData] = useState([]);

  let headerText = '';

  if (campusID === 0 && roomID === 0) {
    headerText =
      calendarEvent === 'today'
        ? 'Today'
        : calendarEvent === 'upcoming'
        ? 'Upcoming Classes & Bookings'
        : 'Past Classes & Bookings';
  } else if (campusID !== 0 && roomID === 0) {
    headerText = campusName;
  } else if (campusID !== 0 && roomID !== 0) {
    headerText = `${campusName} - ${roomName}`;
  }
  const handleEvent = (eventDay) => {
    if (eventDay === 'calendar') {
      onCalendarView(true,eventDay);
    }
    else {
      onCalendarView(false, eventDay);
    }
    setCalendarEvent(eventDay);
  };

  const handleExportData_ = (data) => {
    setFileExportData(data);
  };


  const exportData = (fileType, fileName) => {
    const header = ['Date & Time', 'Venue', 'Course', 'Count', 'Lecturer'];

    let flatData = [];

    fileExportData.forEach((entry) => {
      entry.schedules.forEach((schedule) => {
        const formattedDate = new Date(
          `${schedule.class_date} ${schedule.class_from}`
        ).toLocaleString();
        const lecturerName = schedule.lecturer
          ? `${schedule.lecturer.Firstname} ${schedule.lecturer.Lastname}`
          : '-';
        flatData.push({
          'Date & Time': formattedDate,
          Venue:
            schedule.multiple_room?.map((room) => room.campus_room.room_name) ||
            '-',
          Course: schedule.course_name || '-',
          Count: schedule.studentCount ? schedule.studentCount.total : '-',
          Lecturer: lecturerName,
        });
      });

      entry.bookings.forEach((booking) => {
        const formattedDate = new Date(
          `${booking.booking_date} ${booking.booking_start_time}`
        ).toLocaleString();
        const user =
          booking.users.length > 0
            ? `${booking.users[0].first_name} ${booking.users[0].last_name}`
            : '-';
        flatData.push({
          'Date & Time': formattedDate,
          Venue: booking.room_no || '-',
          Course: booking.linked_course || '-',
          Count: '-',
          Lecturer: user,
        });
      });
    });

    if (fileType === 'csv') {
      const csvString = Papa.unparse({
        fields: header,
        data: flatData.map((row) => header.map((col) => row[col])),
      });
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8,' });

      const blobURL = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        'text/csv',
        anchor.download,
        anchor.href,
      ].join(':');
      anchor.click();

      setTimeout(() => {
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === 'xlsx') {
      const compatibleData = flatData.map((row) => {
        const obj = {};
        header.forEach((col) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData);
      XLSX.utils.book_append_sheet(wb, ws1, 'React Table Data');
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      return false;
    } else if (fileType === 'pdf') {
      const compatibleData = flatData.map((row) => [
        row['Date & Time'],
        row['Venue'],
        row['Course'],
        row['Count'],
        row['Lecturer'],
      ]);

      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: 'left',
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  return (
    <>
      <div className="meeting-custom-calendar">
        <div className="button-grp form-group mb-4">
          <button
            title="Today"
            className={`btn btn-white-bordered ${
              calendarEvent === 'today' ? 'active' : ''
            }`}
            id="today-button"
            value="today"
            onClick={() => handleEvent('today')}
          >
            <i className="fal fa-calendar-day"></i>
            Today
          </button>
          <div className="schedule-date-header">{headerText}</div>
          <div className="reset-btn-group d-flex">
            <div className="button-reset dropdown-comman">
              <button
                className="btn btn-primary"
                onClick={() => {
                  onResetButtonClick();
                  setCalendarEvent('today');
                }}
                title="Reset"
              >
                <i className="fal fa-redo"></i>Reset
              </button>
            </div>
            <div className="files-export-group ml-3">
              <button
                type="button"
                className="btn btn-files mr-2"
                onClick={() => {
                  exportData('xlsx', 'ScheduleClassesBookingList');
                }}
                title="Export spreadsheet"
              >
                <i className="fal fa-file-excel icon"></i>
              </button>
              <button
                type="button"
                className="btn btn-files mr-2"
                onClick={() => {
                  exportData('csv', 'ScheduleClassesBookingList');
                }}
                title="Export CSV"
              >
                <i className="fal fa-file-csv icon"></i>
              </button>
              <button
                type="button"
                className="btn btn-files"
                onClick={() => {
                  exportData('pdf', 'ScheduleClassesBookingList');
                }}
                title="Export PDF"
              >
                <i className="fal fa-file-pdf icon"></i>
              </button>
            </div>
          </div>
          <div className="current-date"></div>

        
          <div className="calendar-view-buttons">
            <button
              title="Upcoming"
              className={`btn btn-white-bordered ${
                calendarEvent == 'upcoming' ? 'active' : ''
              }`}
              value="upcoming"
              id="upcoming-button"
              onClick={() => handleEvent('upcoming')}
            >
              <i className="fal fa-arrow-right"></i>Upcoming
            </button>

            <button
              title="Calendar"
              className={`btn btn-white-bordered`}
              value="calendar"
              id="weekly-button"
              onClick={() => handleEvent('calendar')}
            >
              <i className="fal fa-calendar-alt"></i>Calendar
            </button>
            <button
              title="Past"
              className={`btn btn-white-bordered ${
                calendarEvent == 'past' ? 'active' : ''
              }`}
              value="past"
              id="past-button"
              onClick={() => handleEvent('past')}
            >
              <i className="fal fa-history"></i>Past
            </button>
          </div>
        </div>
        <ListofSchedules
          calendarEventDay={calendarEvent}
          campusId__={campusID}
          roomId__={roomID}
          lecturerId__={lecturerID}
          courseId__={courseID}
          onExportData={(data) => handleExportData_(data)}
          key={campusID}
          // isCourseFilterChecked__={isCourseFilterChecked_}
          // isLecturerFilterChecked__={isLecturerFilterChecked_}
        />
      </div>
    </>
  );
};
