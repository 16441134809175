import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";

import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";

import Str from "../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";

import PermissionsGate from "../../../../utils/permissionGate";
import { DeleteExamCenter, ExamCenterListData, ExamCenterListDataFilter } from "../../../../services/ExternalExamServices";
import { RenderCenterStatus } from "../../../../utils/CommonStatusItems";
import Tablefilter from "../../../common/Tablefilter";
import hasPermission from "../../../../utils/hasMultiplePermission";
import axios from "axios";

const CentreList = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [searchStatusCheck, setSearchStatusCheck] = useState({});
  const [userData, setUserData] = useState([]);
  const [loading, setloading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortkey, setSortKey] = useState("name");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [statusData, setStatusData] = useState([]);
  const [debouncedTerm, setDebouncedTerm] = useState("")
  const [refresh, setRefresh] = useState(false);
  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  useEffect(()=>{
    const filterSource = axios.CancelToken.source();
    const getFilters = async () =>{
      try {
        const res = await ExamCenterListDataFilter(filterSource.token);
        if(res.status===200){
          setStatusData(res.data.FilterStatus)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    
    getFilters();
    return () => {
      filterSource.cancel('Component unmounted');    
    };
  },[])

  useEffect(() => {
    let response = hasPermission({ scopes: ["eeecview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, [])
  

  useEffect(() => {
    const cancelTokenSources = [];
    const getCentreList = async () => {
      setloading(true);

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      
      cancelTokenSources.push(source);

      const centre = {
        page: page,
        limit: perPage,
        key: sortkey,
        sort: sortOrder,
        search: search,
        viaStatus: status.arr,
      };

      try {
        const res = await ExamCenterListData(centre, source.token);
        if(res.status==200){
          setUserData(res?.data?.data?.data);
          setTotalRows(res.data?.data?.total);
          setloading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setloading(false);
        }
      }      
    };

    getCentreList();

    return () => {
      cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
      });
    }

  }, [page, perPage, sortOrder, sortkey, search, status, searchStatusCheck, refresh]);

  

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  // TODO Searching
  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  // TODO Filtering

  // TODO Reset Filter

  const resetFilter = () => {
    setStatus({ arr: [], checkObj: {} });
    setSearchStatusCheck({});
    setDebouncedTerm("")
    setSearch("");
  };

  const handleDelete = (id) =>{
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteExamCenter({id}).then((res)=>{
          if(res.status==200){
            Swal.fire("Deleted!", "Your record has been deleted.", "success");
            setRefresh(!refresh)
          }
        });
        
      }
    });
  }

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <PermissionsGate scopes={["eeecedit"]} RenderError={() => (
          <p>{row?.name.trim() ? <span className="feature-exam_period"> <span className="textLimit100">{row.name}</span> </span> : "-"}</p>
        )}>
        <Link
        title={row.name}
           className="as-text-blue curser feature-name"
           to={`/studentAdministration/externalExam/examCentres/edit/form/${row.id}`}
        >
          <span className="textLimit100">
          {row.name}
          </span>
        </Link>
        </PermissionsGate>
      ),
    },
    {
      name: "Code",
      selector: "code",
      sortField: "code",
      sortable: true,
      cell: (row) => <p title={row.code}>{row.code ? row.code : "-"}</p>,
    },

    {
      name: "Status",
      selector: "center_status",
      sortable: true,
      sortField: "center_status",
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => row.center_status ? RenderCenterStatus(row.center_status).html : "-",
    },

    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <PermissionsGate scopes={["eeecedit"]} RenderError={()=>(
              <button 
                title="Open"
                disabled
                className="btn btn-primary rounded-circle"
                >              
                  <i className="fal fa-folder-open"></i>              
              </button>
            )}>
              <Link 
                title="Open"
                className="btn btn-primary rounded-circle"
                to={`/studentAdministration/externalExam/examCentres/edit/form/${row.id}`}>              
                  <i className="fal fa-folder-open"></i>              
              </Link>
            </PermissionsGate>
            <PermissionsGate scopes={["eeecdelete"]} errorProps={{ disabled: true }}>
              <button
                title="Delete"
                className="btn btn-danger rounded-circle"
                onClick={() => handleDelete(row.id)}
              >
                <i className="fal fa-trash-alt"></i>
              </button>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  // TODO Excel
  // TODO Csv
  // TODO Pdf

  const exportData = (fileType, fileName) => {
    let data = [];
    const header = ["Name", "Code", "Status"];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const centre = {
      page: page,
      limit: perPage,
      key: sortkey,
      sort: sortOrder,
      search: search,
      viaStatus: status.arr,
      exportStatus: "true"
    };
    ExamCenterListData(centre)
      .then((res) => {
        data = res?.data?.data;
        data = data?.map((row) => ({
          ...row,
          Name: row?.name ?row?.name :"-",
          Code: row?.code ? row.code :"-",
          Status: row?.center_status ? row?.center_status :"-",
        }));
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          console.log(csvString);
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [row.name, row.code, row["Status"]];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });

    // console.log(typeof data);
    // console.log(typeof result);
  };

  return (    
    <div className="my-tickets-info-list Tickets-main-wrap filter-scrolable right-align-action">
      <div className="custom-table-div filter-search-icon card card-table-custom appeals-tables">
      <div className="search-filter-div">
      <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={e => setDebouncedTerm(e.target.value)} value={debouncedTerm} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Status"
                      uniqueId="ExamStatus"
                      optionArr={statusData}
                      state={status}
                      setState={setStatus}
                      renderLabelFunction={RenderCenterStatus}
                    />
                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Exam Centres");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Exam Centres");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Exam Centres");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate scopes={["eeecadd"]} RenderError={()=>(
                  <button className="btn btn-primary" title="Create New" disabled>                    
                    <i className="fal fa-plus"></i>Create New                    
                  </button>
                )}>
                  <Link className="btn btn-primary" title="Create New" to={`/studentAdministration/externalExam/examCentres/add`}>                    
                      <i className="fal fa-plus"></i>Create New                    
                  </Link>
                </PermissionsGate>
              </div>
            </div>

          </div>
      </div>
  
      <DataTable
        paginationDefaultPage={page}
        progressPending={loading}
        data={userData}
        defaultSortField={sortkey}
        sortServer
        defaultSortAsc={false}
        columns={columns}
        pagination={true}
        noDataComponent={Str.noRecord}
        onSort={handleSort}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        highlightOnHover={false}
        progressComponent={<SkeletonTicketList />}
      />
      </div>
    </div>
  );
};

export default CentreList;
