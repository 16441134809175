import axiosInstance from "../utils/axios";

export const GetListOfQuestions = async (values) => {
  return await axiosInstance().post(`/listQuestions`, values);
};

export const GetListOfQuestionsCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/listQuestions`, values, {
    cancelToken: cancelToken
  });
};

export const GetListOfCourses = async () => {
  return await axiosInstance().get(`/listCourses`);
};

export const GetListOfCoursesCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/listCourses`, {cancelToken: cancelToken});
};

export const GetListOfAssessment = async (values) => {
  return await axiosInstance().post(`/listAssessment`, values);
};

export const getQuiz = async (values) => {
  return await axiosInstance().post(`/getQuiz`, values);
};

export const GetQuizCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getQuiz`, values, {
    cancelToken: cancelToken
  });
};

export const AddsmartAssessment = async (values) => {
  return await axiosInstance().post(`/addAssessment`, values);
};

export const AssessmentAuditTrail = async () => {
  return await axiosInstance().post(`/listAuditTrailOfAssessment`, {});
};

export const getAuditTrailOfQuiz = async (values) => {
  return await axiosInstance().post(`/getAuditTrailOfQuiz`, values);
};

export const getAuditTrailOfQuizCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getAuditTrailOfQuiz`, values, {
    cancelToken: cancelToken
  });
};

export const GetAssessmentDetail = async (values) => {
  return await axiosInstance().post(`/getAssessmentDetail`, values);
};

export const GetQuestionAssignedOrNotAssignedList = async (values) => {
  return await axiosInstance().post(`/getQuizQuestionAssignedOrNotAssignedList`, values);
};

export const GetQuestionAssignedOrNotAssignedListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getQuizQuestionAssignedOrNotAssignedList`, values, {cancelToken: cancelToken});
};
// export const GetQuestionAssignedOrNotAssignedList = async (values) => {
//   return await axiosInstance().post(`/getQuestionAssignedOrNotAssignedList`, values);
// };

export const DeleteQuestionFromAssessment = async (values) => {
  return await axiosInstance().post(`/deleteQuestionFromAssessment`, values);
};

export const deleteQuiz = async (values) => {
  return await axiosInstance().post(`/deleteQuiz`, values);
};

export const AddQuestionToAssessment = async (values) => {
  return await axiosInstance().post(`/addQuestionToAssessment`, values);
};

export const UpdateAssessment = async (values) => {
  return await axiosInstance().post(`/updatedAssessment`, values);
};

export const createQuiz = async (values) => {
  return await axiosInstance().post(`/createQuiz`, values);
};

export const updateQuiz = async (values) => {
  return await axiosInstance().post(`/updateQuiz`, values);
};

export const GetAssessmentAssignedOrNotAssignedList = async (values) => {
  return await axiosInstance().post(`/getQuizAssignedOrNotAssignedList`, values);
};

export const GetAssessmentAssignedOrNotAssignedListCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getQuizAssignedOrNotAssignedList`, values, {cancelToken: cancelToken});
};

export const AddaQuestion = async (values) => {
  return await axiosInstance().post(`/addQuestion`, values);
};

export const GetQuizTopics = async (values) => {
  return await axiosInstance().post(`/getQuizTopics`, values);
};

export const GetQuizTopicsCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getQuizTopics`, values, {
    cancelToken: cancelToken
  });
};

export const ViewaQuestion = async (values) => {
  return await axiosInstance().post(`/viewQuestion`, values);
};

export const ViewaQuestionCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/viewQuestion`, values, {cancelToken: cancelToken});
};

export const getQuizDetails = async (values) => {
  return await axiosInstance().post(`/getQuizDetails`, values);
};

export const getQuizDetailsCancelToken = async (values, cancelToken) => {
  return await axiosInstance().post(`/getQuizDetails`, values, {cancelToken: cancelToken});
};

export const getSmartAssessmentSubmissionResult = async (values) => {
  return await axiosInstance().post(`/getSmartAssessmentSubmissionResult`, values);
};

export const DeleteaQuestion = async (values) => {
  return await axiosInstance().post(`/deleteQuestion`, values);
};

export const GetAuditTrailOfAssessment = async (values) => {
  return await axiosInstance().post(`/getAuditTrailOfAssessment`, values);
};

export const ListAuditTrailOfQuestion = async (id = null) => {
  return await axiosInstance().post(`/listAuditTrailOfQuestion?id=${id}`, {});
};

export const ListAuditTrailOfQuestionCancelToken = async (id = null, cancelToken) => {
  return await axiosInstance().post(`/listAuditTrailOfQuestion?id=${id}`, {}, {
    cancelToken: cancelToken
  });
};

export const UpdateQuestion = async (values) => {
  return await axiosInstance().post(`/updateQuestion`, values);
};

export const Get_assessment_submission = async (id) => {
  return await axiosInstance().get(`/get_assessment_submission?assessment_id=${id}`);
};

export const GetSmartAssessmentSubmissionResult = async (id) => {
  return await axiosInstance().get(`/getSmartAssessmentSubmissionResult?assessment_id=${id}`);
};

export const GetSmartAssessmentSubmissionResultCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getSmartAssessmentSubmissionResult?assessment_id=${id}`, {cancelToken: cancelToken});
};

export const AddAndGetTicketComment = async (data) => {
  return await axiosInstance().post("addAndGetTicketComment", data);
}

export const GetAssignmentQuestionSummary = async (id) => {
  return await axiosInstance().get(`/getAssignmentQuestionSummary?assignment_id=${id}`);
}

export const GetAssignmentQuestionSummaryCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/getAssignmentQuestionSummary?assignment_id=${id}`,{cancelToken: cancelToken});
}

export const LongAndShortAns = async (data) => {
  return await axiosInstance().post("/longAndShortAns", data);
}

export const QuizQuestion = async (data) => {
  return await axiosInstance().post("/quizQuestion", data);
}

export const assignedQuizToQuestion = async (data) => {
  return await axiosInstance().post("/assignedQuizToQuestion", data);
}

export const QuestionDetails = async (id) => {
  return await axiosInstance().get(`/questionDetails?qid=${id}`);
}

export const QuestionDetailsCancelToken = async (id, cancelToken) => {
  return await axiosInstance().get(`/questionDetails?qid=${id}`, {cancelToken: cancelToken});
}

export const GetStudentDropdownList = async (id) => {
  return await axiosInstance().get(`/getStudentDropdownList`);
}

export const GetStudentDropdownListCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getStudentDropdownList`, {cancelToken: cancelToken});
}

// export const GetQuizTopics = async (id) => {
//   return await axiosInstance().post(`/getQuizTopics`);
// }

export const GetQuizAttempts = async ({quid,id,subId,aid})=>{
  return await axiosInstance().get(`/getQuizAttempts?id=${id}&quid=${quid}&aid=${aid}`);
}

export const GetQuizAttemptsCancelToken = async ({quid,id,subId,aid,cancelToken})=>{
  return await axiosInstance().get(`/getQuizAttempts?id=${id}&quid=${quid}&aid=${aid}`,{cancelToken:cancelToken});
}

export const GetQuizFilter = async () => {
  return await axiosInstance().get(`/getQuizFilter`);
}

export const GetQuizFilterCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/getQuizFilter`, {
    cancelToken: cancelToken
  });
};

export const ListQuestionFilters = async (data) => {
  return await axiosInstance().get(`/listQuestionFilters`);
}

export const ListQuestionFiltersCancelToken = async (cancelToken) => {
  return await axiosInstance().get(`/listQuestionFilters`, {
    cancelToken: cancelToken
  });
};

export const ListQuestionFilter = async (id,type) => {
  return await axiosInstance().get(`/listQuestionFilters?quizId=${id}&type=${type}`);
}

export const ListQuestionFilterCancelToken = async (id, type, cancelToken) => {
  return await axiosInstance().get(`/listQuestionFilters?quizId=${id}&type=${type}`, {cancelToken: cancelToken});
}

export const GetQuizFilters = async (id,type) => {
  return await axiosInstance().get(`/getQuizFilter?questionId=${id}&type=${type}`);
}

export const GetQuizFiltersCancelToken = async (id,type, cancelToken) => {
  return await axiosInstance().get(`/getQuizFilter?questionId=${id}&type=${type}`,{cancelToken: cancelToken});
}

export const GetGroupsAndQuestions = async (param) => {
  return await axiosInstance().get(`/getGroupsAndQuestions`, {params:param});
}

export const GetGroupsAndQuestionsCanceToken = async (param, cancelToken) => {
  return await axiosInstance().get(`/getGroupsAndQuestions`, {params:param, cancelToken: cancelToken});
}

export const CreateQuestionGroup = async (data) => {
  return await axiosInstance().post(`/createQuestionGroup`, data);
}

export const AssignedQuestionToGroup = async (data) => {
  return await axiosInstance().post(`/assignedQuestionToGroup`, data);
}

export const AssignBulkQuestion = async (values) =>{
  return await axiosInstance().post(`/bulkAssignQuestionTOoQuiz`, values);
}

export const UpdateSubmissionQuestionScore = async (values) =>{
  return await axiosInstance().post(`/updateSubmissionQuestionScore`, values);
}

export const QuestionGroupDelete = async (values) =>{
  return await axiosInstance().post(`/questionGroupDelete`, values);
}