import React, { createRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router";
import Select from "react-select";
import { GetCampusDropdown, GetCampusDropdownCancelToken, addResourceManagement, getResourceDetails, getResourceDetailsCancelToken } from "../../../services/StudentService";
import { useParams } from "react-router-dom";
import PermissionsGate from "../../../utils/permissionGate";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "../../../assets/css/filePond.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { IMAGE_URL, FORM_DATE_FORMAT, downloadURL } from "../../../utils/Constants";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import AddResourceNewDesign from "./AddResourceNewDesign";
import { GetListOfBrandSetting, GetListOfBrandSettingCancelToken } from "../../../services/BrandServices";
import axios from "axios";

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation, FilePondPluginFileValidateType);

const EditResourceManagement = ({ setResourceName }) => {
    const { id } = useParams();
    const history = useHistory();
    const [isLoaded, setIsLoaded] = useState(true);
    const [files, setFiles] = useState([]);
    const [filesUrl, setFilesUrl] = useState([]);
    const [thumbnailFile, setThumbnailFile] = useState([]);
    const [thumbnailurl, setThumbnailurl] = useState("");
    const [typeArr, setTypeArr] = useState([]);
    const [condition, setCondition] = useState([]);
    const [campus, setCampus] = useState([]);
    const [language, setLanguage] = useState([]);
    const [resourceData, setResourceData] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);
    const [keywordsValues, setKeywordsValues] = useState([]);
    const [hashValues, setHashValues] = useState([]);
    const [thumbnailFiles, setThumbnailFiles] = useState([]);
    const [brands, setBrands] = useState([]);
    const [filePreview, setFilePreview] = useState()

    const CommonDropdownData = useSelector(state => state.registerDropdownValues && Object.keys(state.registerDropdownValues).length ?
        state.registerDropdownValues : {});

    function setDropdownData() {
        if (Object.keys(CommonDropdownData).length) {
            if (CommonDropdownData.LibraryResourceType) {
                setTypeArr(CommonDropdownData?.LibraryResourceType);
                if (CommonDropdownData?.LibraryResourceType.filter((data) => (data.value == "Book")).length) {
                    setValues({ ...values, type: "Book", domainName: "Book" })
                }
            }
            if (CommonDropdownData.BookConditionsType) {
                setCondition(CommonDropdownData?.BookConditionsType)
            }
            if (CommonDropdownData.language) {
                setLanguage(CommonDropdownData?.language)
            }
            // if (CommonDropdownData.campus) {
            //     setCampus(CommonDropdownData?.campus)
            // }
            setIsLoaded(true);
        }
    }

    useEffect(() => {
        const cancelTokenSources = [];
    
        const getResourceData = async () => {
            setDropdownData();
      
          cancelTokenSources.forEach(source => {
            source.cancel('New request made');
          });
      
          const source = axios.CancelToken.source();
          cancelTokenSources.push(source);
      
          try {
            const res = await getResourceDetailsCancelToken(id, source.token);
            if (res.status === 200 && res.data.resourceDetails) {

                const resource = res.data?.resourceDetails;
                if(resource.thumbnail_type==0){
                    if (resource.thumbnail) {
                        setThumbnailFiles(downloadURL + IMAGE_URL + "/" + resource?.thumbnail.replace("public/", "").replace("home/myaie/public_html/", ""))
                        setThumbnailurl(resource?.thumbnail)
                    } else {
                        setThumbnailurl(resource?.thumbnail)
                    }
                }
                if ((resource.type === "Document" || resource.type === "pdf") && resource.document) {
                    setFilesUrl(resource?.url)
                    setFiles(downloadURL + IMAGE_URL + "/" + resource?.document.replace("public/", "").replace("home/myaie/public_html/", ""))
                    resource.thumbnail = resource?.url.includes("http") ? resource?.url.replace("public/", "") : IMAGE_URL + "/" + resource?.url.replace("public/", "").replace("home/myaie/public_html/", "");
                }
                if (resource.type === "pdf" && !resource.document && resource.url) {
                    setFilesUrl(resource?.url)
                    setFiles(resource?.url.includes("http") ? downloadURL + resource?.url.replace("public/", "") : downloadURL + IMAGE_URL + "/" + resource?.url.replace("public/", "").replace("home/myaie/public_html/", ""))
                    resource.thumbnail = resource?.url.includes("http") ? resource?.url.replace("public/", "") : IMAGE_URL + "/" + resource?.url.replace("public/", "").replace("home/myaie/public_html/", "");
                }
                if (resource.type === "Book" && resource.thumbnail) {
                    resource.thumbnail = resource?.thumbnail.includes("http") ? resource?.thumbnail.replace("public/", "") : IMAGE_URL + "/" + resource.thumbnail.replace("public/", "").replace("home/myaie/public_html/", "");
                }
                if (resource.topic) {
                    resource.topic = resource.topic.split(",")
                }
                if (resource.hash) {
                    resource.hash = resource.hash.split(",")
                    setHashValues(resource.hash)
                }
                if (resource.keywords) {
                    resource.keywords = resource.keywords.split(",")
                    setKeywordsValues(resource.keywords)
                }
                if(resource.type === "vimeo" && resource.duration){
                    const durationArr = resource.duration.split(" ").map((part) =>{
                        if(part.includes('h')){
                            resource.hours = part;
                        }
                        if(part.includes('m')){
                            resource.minutes = part;
                        }
                        if(part.includes('s')){
                            resource.seconds = part;
                        }
                    })
                }
                setResourceData(resource)
                setResourceName(resource.title);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Record not Available",
                }).then(() => { history.goBack() });
            }
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error(error);
              setIsLoaded(false);
            }
          }
        };
      
        getResourceData();
    
        return () => {
          cancelTokenSources.forEach(source => {
            source.cancel('Component unmounted');
          });
        };
    }, []);

    useEffect(() => {
        setDropdownData();
        const dropdownSource = axios.CancelToken.source();
      
        const fetchData = async () => {
          try {
            const res = await GetListOfBrandSettingCancelToken(dropdownSource.token);
            const x = res.data?.brand_setting_list.map((data) => {
                return {                  
                  value: `${data.id}`,
                  label: `${data.brand_name}`,                  
                };
            });
            setBrands(x)
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error(error);
            }
          }
        };
      
        fetchData();
      
        return () => {
          dropdownSource.cancel('Component unmounted');
        };
    }, []);

    useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
    
    const fetchData = async () => {
        try {
        const res = await GetCampusDropdownCancelToken(dropdownSource.token);
        setCampus(res.data.dropdown)
        } catch (error) {
        if (!axios.isCancel(error)) {
            console.error(error);
        }
        }
    };
    
    fetchData();
    
    return () => {
        dropdownSource.cancel('Component unmounted');
    };
    }, []);

    // useEffect(() => {
    //     setDropdownData();
    //     GetListOfBrandSetting().then((res)=>{            
    //         const x = res.data?.brand_setting_list.map((data) => {
    //             return {                  
    //               value: `${data.id}`,
    //               label: `${data.brand_name}`,                  
    //             };
    //         });
    //         setBrands(x)
    //     }).catch((err)=>{console.log(err)})
    //     GetCampusDropdown().then(res=>{
    //         if(res.status===200){
    //             setCampus(res.data.dropdown)
    //         }
    //     }).catch((err)=>console.log(err))
    //     getResourceDetails(id)
    //         .then((res) => {
    //             if (res.status === 200 && res.data.resourceDetails) {

    //                 const resource = res.data?.resourceDetails;
    //                 if(resource.thumbnail_type==0){
    //                     if (resource.thumbnail) {
    //                         setThumbnailFiles(downloadURL + IMAGE_URL + "/" + resource?.thumbnail.replace("public/", "").replace("home/myaie/public_html/", ""))
    //                         setThumbnailurl(resource?.thumbnail)
    //                     } else {
    //                         setThumbnailurl(resource?.thumbnail)
    //                     }
    //                 }
    //                 if ((resource.type === "Document" || resource.type === "pdf") && resource.document) {
    //                     setFilesUrl(resource?.url)
    //                     setFiles(downloadURL + IMAGE_URL + "/" + resource?.document.replace("public/", "").replace("home/myaie/public_html/", ""))
    //                     resource.thumbnail = resource?.url.includes("http") ? resource?.url.replace("public/", "") : IMAGE_URL + "/" + resource?.url.replace("public/", "").replace("home/myaie/public_html/", "");
    //                 }
    //                 if (resource.type === "pdf" && !resource.document && resource.url) {
    //                     setFilesUrl(resource?.url)
    //                     setFiles(resource?.url.includes("http") ? downloadURL + resource?.url.replace("public/", "") : downloadURL + IMAGE_URL + "/" + resource?.url.replace("public/", "").replace("home/myaie/public_html/", ""))
    //                     resource.thumbnail = resource?.url.includes("http") ? resource?.url.replace("public/", "") : IMAGE_URL + "/" + resource?.url.replace("public/", "").replace("home/myaie/public_html/", "");
    //                 }
    //                 if (resource.type === "Book" && resource.thumbnail) {
    //                     resource.thumbnail = resource?.thumbnail.includes("http") ? resource?.thumbnail.replace("public/", "") : IMAGE_URL + "/" + resource.thumbnail.replace("public/", "").replace("home/myaie/public_html/", "");
    //                 }
    //                 if (resource.topic) {
    //                     resource.topic = resource.topic.split(",")
    //                 }
    //                 if (resource.hash) {
    //                     resource.hash = resource.hash.split(",")
    //                     setHashValues(resource.hash)
    //                 }
    //                 if (resource.keywords) {
    //                     resource.keywords = resource.keywords.split(",")
    //                     setKeywordsValues(resource.keywords)
    //                 }
    //                 if(resource.type === "vimeo" && resource.duration){
    //                     const durationArr = resource.duration.split(" ").map((part) =>{
    //                         if(part.includes('h')){
    //                             resource.hours = part;
    //                         }
    //                         if(part.includes('m')){
    //                             resource.minutes = part;
    //                         }
    //                         if(part.includes('s')){
    //                             resource.seconds = part;
    //                         }
    //                     })
    //                 }
    //                 setResourceData(resource)
    //                 setResourceName(resource.title);
    //             } else {
    //                 Swal.fire({
    //                     icon: "error",
    //                     title: "Error",
    //                     text: "Record not Available",
    //                 }).then(() => { history.goBack() });
    //             }
    //         })
    //         .catch((err) => { console.log(err); setIsLoaded(false) });
    // }, []);

    const ResourceValidation = Yup.object({
        title: Yup.string().required("Title is required").max(255, "Title is too big"),
        brand: Yup.array().min(1, "At least one linked brand is required").required(),
        keywords: Yup.array().when("type", {
            is: (type) => type,
            then: Yup.array().required("Keyword value is required")
        }),
        type: Yup.string().required("Type is required"),

        document: Yup.mixed().test("required", "Document is required", function (value) {
            let type = this.parent.type
            if (type !== "document" && type !== "pdf") {
                return true
            }
            return files.length ? true : false
        }),

        textBody: Yup.string().when("type", {
            is: (type) => type == "Article" || type == "article" || type == "Label",
            then: Yup.string().required("Content is required")
        }),

        // condition: Yup.string().when("type", {
        //     is: (type) => type == "Book",
        //     then: Yup.string().required("Condition is required"),
        // }),

        videoId: Yup.string().when("type", {
            is: (type) => type == "vimeo",
            then: Yup.string().required("Video Id is required"),
        }),

        url: Yup.string().when("type", {
            is: (type) => type == "link" || type == "white_paper" || type == "Youtube" || type == "ebook",
            then: Yup.string().required("URL is required"),
        }),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            type: resourceData?.type || "",
            dewey: resourceData?.dewey || "",
            author: resourceData?.author || "",
            barcode: resourceData?.barcode || "",
            title: resourceData?.title || "",
            subtitle: resourceData?.sub_title || "",
            condition: resourceData?.book_condition || "",
            isbn: resourceData?.isbn || "",
            place: resourceData?.place || "",
            date: resourceData?.publish_date ? new Date(moment.unix(resourceData?.publish_date)) : "",
            language: resourceData?.language || "",
            publisher: resourceData?.publisher || "",
            series: resourceData?.series || "",
            campus: resourceData?.campus || "",
            hash: hashValues,
            description: resourceData?.description || "",
            keywords: keywordsValues,
            topic: resourceData?.topic || [],
            TimeLimitHours: resourceData?.hours || "00h",
            TimeLimitMinutes: resourceData?.minutes || "0m",
            TimeLimitSeconds: resourceData?.seconds || "0s",
            videoId: resourceData?.video_id ? resourceData?.video_id : resourceData?.url || "",
            url: resourceData?.url || "",
            published: resourceData?.published === 1 ? true : false,
            featured: resourceData?.featured === 1 ? true : false,
            thumbnail: resourceData?.thumbnail ? resourceData?.thumbnail : [],
            thumbnail_link: resourceData?.thumbnail_type==1 ? resourceData?.thumbnail : "",
            thumbnail_type: resourceData?.thumbnail_type ? resourceData?.thumbnail_type : 0,
            fileUrl: resourceData?.url || "",
            textBody: resourceData.text_body || "",
            document: resourceData.document || [],
            brand: resourceData.brand ? resourceData.brand.split(",") : [],
        },
        validationSchema: ResourceValidation,
        onSubmit: (values) => {
            setIsDisabled(true)
            let date = values.date

            if (values.date) {
                date = new Date(date).getTime().toString() / 1000;
            }
            const data = new FormData();
            data.append("thumbnail_type", values.thumbnail_type);
            if(values.thumbnail_type==1){
                data.append("thumbnail", values.thumbnail_link)
            }else{
                if (thumbnailFiles.length && thumbnailurl && thumbnailurl.includes(thumbnailFiles[0].file.name)) {
                    data.append("thumbnail", thumbnailurl)
                } else {
                    thumbnailFiles.length ? data.append("thumbnail", thumbnailFiles[0].file) : data.append("thumbnail", "")
                }
            }
            data.append("topic", values.topic.join(","))
            data.append("keywords", values.keywords.join(","))
            data.append("hash", values.hash.join(","))
            data.append("sub_title", values.subtitle)
            data.append("type", values.type)
            data.append("title", values.title)
            data.append("id", id)
            data.append("description", values.description)
            data.append("author", values.author)
            data.append("brand", values.brand.join(","))
            if (values.type == "Book") {
                data.append("publisher", values.publisher)
                data.append("dewey", values.dewey)
                data.append("place", values.place)
                data.append("barcode", values.barcode)
                data.append("isbn", values.isbn)
                data.append("series", values.series)
                data.append("publish_date", date)
                data.append("language", values.language)
                data.append("published", values.published == true ? 1 : 0)
                data.append("campus", values.campus)
                data.append("book_condition", values.condition)                
            }
            if(values.type=="ebook"){
                data.append("publisher", values.publisher)
                // data.append("dewey", values.dewey)
                data.append("place", values.place)
                data.append("barcode", values.barcode)
                data.append("isbn", values.isbn)
                data.append("series", values.series)
                data.append("publish_date", date)
                data.append("language", values.language)
                data.append("published", values.published == true ? 1 : 0)
                data.append("url", values.url)                
            }
            if(values.type == "link" || values.type == "Link"){
                data.append("publisher", values.publisher)
                data.append("dewey", values.dewey)
                data.append("place", values.place)
                data.append("barcode", values.barcode)
                data.append("isbn", values.isbn)
                data.append("series", values.series)
                data.append("publish_date", date)
            }
            if (values.type == "vimeo") {
                data.append("url", "https://player.vimeo.com/video/")
                data.append("video_id", values.videoId)
                data.append("published", values.published == true ? 1 : 0)
                data.append("featured", values.featured == true ? 1 : 0)
                // data.append("hours", values.TimeLimitHours)
                // data.append("minutes", values.TimeLimitMinutes)
                data.append("duration", `${values.TimeLimitHours} ${values.TimeLimitMinutes} ${values.TimeLimitSeconds}`)
                data.append("language", values.language)
            }
            if (values.type == "link" || values.type == "Link" || values.type == "white_paper") {
                data.append("url", values.url)
                data.append("published", values.published == true ? 1 : 0)
                data.append("language", values.language)
            }
            if (values.type == "Document" || values.type == "pdf") {
                data.append("published", values.published == true ? 1 : 0)
                data.append("language", values.language)

                if (files && filesUrl.includes(files[0].file.name)) {
                    data.append("document", filesUrl)
                } else {
                    files.length && data.append("document", files[0].file)
                }
            }
            if (values.type == "Youtube") {
                data.append("published", values.published == true ? 1 : 0)
                data.append("url", values.url)
            }
            if (values.type == "Article" || values.type == "article") {
                data.append("text_body", values.textBody)
                data.append("published", values.published == true ? 1 : 0)
                data.append("language", values.language)
            }
            if (values.type == "Label") {
                data.append("text_body", values.textBody)
                data.append("published", values.published == true ? 1 : 0)
            }
            addResourceManagement(data)
                .then((res) => {
                    setIsDisabled(false)
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated successfully",
                        // onClose: goToListing
                    });
                })
                .catch((err) => {
                    setIsDisabled(false)
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: `${err?.response?.data?.data}`,
                    });
                });
        },
    });

    const { values, errors, touched, setValues, setFieldValue, handleChange, handleSubmit, handleBlur } = formik;

    const givenPermsisions = useSelector((state) => state.givenPermission);

    useEffect(() => {
        let response = hasPermission({
            scopes: ["sresourcemedit"],
            permissions: givenPermsisions,
        });
        if (!response) {
            history.push("/noaccess");
        }
    }, []);


    const goToListing = () => {
        history.push(`/support/student-hub-library/resource-management/table`)
    }


    function addDays(date, days) {
        var date = new Date(date);
        date.setDate(date.getDate() + days);
        return date;
    }


    const handleKeyDown = (event) => {
        let hashs = hashValues;
        if (event.key === "Enter") {
            if (event.target.value) {
                hashs.push(event.target.value);
                event.target.value = ""
                setHashValues(hashs)
                setFieldValue("hash", hashs);
            }

        }
    };

    const handleKeyDown1 = (event) => {
        let keywords = keywordsValues;
        if (event.key === "Enter") {
            event.preventDefault()
            if (event.target.value) {
                keywords.push(event.target.value);
                event.target.value = ""
                setKeywordsValues(keywords)
                setFieldValue("keywords", keywords);
            }

        }
    };

    const removeHash = (index) => {
        let keyword = values.hash;
        keyword.splice(index, 1)
        setHashValues(keyword)
        setFieldValue("hash", keyword);
    };

    const removeKeywords = (index) => {
        let keyword = values.keywords;
        keyword.splice(index, 1)
        setKeywordsValues(keyword)
        setFieldValue("keywords", keyword);
    };
    return (
        <>
            <AddResourceNewDesign
                isLoaded={isLoaded}
                values={values}
                errors={errors}
                touched={touched}
                setValues={setValues}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                removeHash={removeHash}
                typeArr={typeArr}
                language={language}
                setFiles={setThumbnailFile}
                setThumbnailurl={setThumbnailurl}
                files={thumbnailFile}
                thumbnailFiles={thumbnailFiles}
                setThumbnailFiles={setThumbnailFiles}
            />

            <div className="card card-profile-info-card">
                <div className="card-body-inr card-body-info">
                    <div className="edit-icon new-card-header">
                        <div className="card-header">Additional Information</div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk">
                                    <label>Linked Brands*</label>
                                    <Select
                                        className={"form-control color-drop custom-select-box is-multi" + (errors.brand && touched.brand ? " is-invalid" : "")}
                                        name="topic"
                                        value={brands.filter(val => (values.brand && values.brand.includes(val.value)))}
                                        onChange={(value) => {
                                            let tempArr = []
                                            if (value?.length) {
                                                value.map(item => {
                                                    tempArr.push(item.value)
                                                })
                                            }
                                            setFieldValue("brand", tempArr);
                                        }}
                                        placeholder={values.brand.length ? values.brand : "Select..."}
                                        onBlur={handleBlur}
                                        options={brands}
                                        maxMenuHeight={175}
                                        isClearable
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                            {/* Vimeo, Label, Youtube and Article Section */}
                            {(values.type == "vimeo" || values.type == "Label" || values.type == "Youtube" || values.type == "Article" || values.type == "article") && 
                            <>
                                <div className="col-md-6 col-lg-3">
                                    <label>Keywords*</label>
                                    <div className="form-icon-group">
                                        <div className="form-control custom-select-box">

                                            <input
                                                type="text"
                                                className={
                                                    "form-control " +
                                                    (errors.keywords && touched.keywords
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                name="keywords"
                                                placeholder="keywords"
                                                title="keywords"
                                                onBlur={handleBlur}
                                                onKeyDown={handleKeyDown1}
                                            />
                                        </div>
                                    </div>
                                    {values.keywords.length > 0 && (
                                        <ul className="tag-list">
                                            {values.keywords?.map((ele, index) => (
                                                <li key={index}>
                                                    {ele}
                                                    <span onClick={() => removeKeywords(index)}>
                                                        <i className="fal fa-times"></i>
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="col-md-6 col-lg-3">
                                    <label>Hash</label>
                                    <div className="form-icon-group">
                                        <div className="form-control custom-select-box">

                                            <input
                                                type="text"
                                                className={
                                                    "form-control " +
                                                    (errors.hash && touched.hash
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                name="hash"
                                                placeholder="Hash"
                                                title="Hash"
                                                onBlur={handleBlur}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </div>
                                    </div>
                                    {values.hash && values.hash.length > 0 && (
                                        <ul className="tag-list">
                                            {values.hash?.map((ele, index) => (
                                                <li key={index}>
                                                    {ele}
                                                    <span onClick={() => removeHash(index)}>
                                                        <i className="fal fa-times"></i>
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </>
                            }
                            
                            {/* Book and link Section */}
                            {(values.type == "Book" || values.type == "link" || values.type == "ebook") &&
                                <>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Publisher</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    className={"form-control" + (errors.publisher && touched.publisher ? " is-invalid" : "")}
                                                    name="publisher"
                                                    placeholder="Publisher"
                                                    title="Publisher" value={values.publisher}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {values.type=="Book" &&<div className="col-md-6 col-lg-3">

                                        <div className="form-group" >
                                            <label>Campus</label>
                                            <div className="form-icon-group mb-4">
                                                <div className="form-control custom-select-box">

                                                    <Select
                                                        className={"form-control custom-select-box" + (errors.campus && touched.campus ? " is-invalid" : "")}
                                                        name="campus"
                                                        value={campus?.filter(val => val.value === values.campus)}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                setValues({ ...values, campus: value.value, })
                                                            } else {
                                                                setValues({ ...values, campus: "", })
                                                            }
                                                        }}
                                                        placeholder={values.campus && (campus && campus.length) ? campus.filter(item => item.value == values.campus)[0].label : "Select..."}
                                                        onBlur={handleBlur}
                                                        options={campus}
                                                        maxMenuHeight={175}
                                                        isClearable
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {values.type!="ebook" && <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Dewey</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    className={"form-control" + (errors.dewey && touched.dewey ? " is-invalid" : "")}
                                                    name="dewey"
                                                    placeholder="Dewey"
                                                    title="Dewey"
                                                    value={values.dewey}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Pub Location</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    className={"form-control" + (errors.place && touched.place ? " is-invalid" : "")}
                                                    name="place"
                                                    placeholder="Pub Location"
                                                    title="Pub Location"
                                                    value={values.place}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group" >
                                            <label>Barcode</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    className={"form-control" + (errors.barcode && touched.barcode ? " is-invalid" : "")}
                                                    name="barcode"
                                                    placeholder="Barcode"
                                                    title="Barcode"
                                                    value={values.barcode}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>ISBN</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    className={"form-control" + (errors.isbn && touched.isbn ? " is-invalid" : "")}
                                                    name="isbn"
                                                    placeholder="ISBN"
                                                    title="ISBN"
                                                    value={values.isbn}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Series</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    className={"form-control" + (errors.series && touched.series ? " is-invalid" : "")}
                                                    name="series"
                                                    placeholder="Series"
                                                    title="Series"
                                                    value={values.series}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {values.type=="Book" && <div className="col-md-6 col-lg-3">
                                        <div className="form-group" >
                                            <label>Condition</label>
                                            <div className="form-icon-group mb-4">
                                                <div className="form-control custom-select-box">

                                                    <Select
                                                        className={"form-control custom-select-box" + (errors.condition && touched.condition ? " is-invalid" : "")}
                                                        name="condition"
                                                        value={condition?.filter(val => val.value === values.condition)}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                setValues({ ...values, condition: value.value, })
                                                            } else {
                                                                setValues({ ...values, condition: "", })
                                                            }
                                                        }}
                                                        placeholder={values.condition && condition.length ? (condition.filter(item => item.value === values.condition)[0]).label : "Select..."}
                                                        onBlur={handleBlur}
                                                        options={condition}
                                                        maxMenuHeight={175}
                                                        isClearable
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="col-md-6 col-lg-3">

                                        <div className="form-group">
                                            <label>Pub Date</label>
                                            <DatePicker
                                                autoComplete="off"
                                                selected={values.date}
                                                onChange={(date) => {
                                                    setFieldValue("date", date);
                                                }}
                                                name="date"
                                                dateFormat={"yyyy"}
                                                showYearPicker
                                                dateFormatCalendar="MMMM"
                                                className={
                                                    "form-control cursor-pointer" +
                                                    (errors.date &&
                                                        touched.date
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                title="Pub Date"
                                                placeholderText="Pub Date"
                                                timeIntervals={15}
                                                onChangeRaw={(e) => {
                                                    e.preventDefault();
                                                }}
                                                minDate={addDays(values.startDate, 1)}
                                            />
                                        </div>
                                    </div>
                                    {(values.type=="Book" || values.type == "ebook") && <>
                                        <div className="col-md-6 col-lg-3">
                                            <label>Keywords*</label>
                                            <div className="form-icon-group">
                                                <div className="form-control custom-select-box">

                                                    <input
                                                        type="text"
                                                        className={
                                                            "form-control " +
                                                            (errors.keywords && touched.keywords
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        name="keywords"
                                                        placeholder="keywords"
                                                        title="keywords"
                                                        onBlur={handleBlur}
                                                        onKeyDown={handleKeyDown1}
                                                    />
                                                </div>
                                            </div>
                                            {values.keywords.length > 0 && (
                                                <ul className="tag-list">
                                                    {values.keywords?.map((ele, index) => (
                                                        <li key={index}>
                                                            {ele}
                                                            <span onClick={() => removeKeywords(index)}>
                                                                <i className="fal fa-times"></i>
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                        {values.type == "ebook" && <div className="col-md-6 col-lg-3">
                                            <div className="form-group">
                                                <label>URL*</label>
                                                <div className="form-icon-group mb-4">
                                                    <input
                                                        type="text"
                                                        title="Url"
                                                        className={"form-control " + (errors.url && touched.url ? " is-invalid" : "")}
                                                        placeholder="URL"
                                                        name="url"
                                                        value={values.url}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </div>}
                                    </>}
                                </>
                            }
                            
                            {/* Document Section */}
                            {
                                (values.type == "Document" || values.type == "pdf") &&
                                <>
                                    <div className="col-md-6 col-lg-3">
                                        <label>Keywords*</label>
                                        <div className="form-icon-group">
                                            <div className="form-control custom-select-box">

                                                <input
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (errors.keywords && touched.keywords
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                    name="keywords"
                                                    placeholder="keywords"
                                                    title="keywords"
                                                    onBlur={handleBlur}
                                                    onKeyDown={handleKeyDown1}
                                                />
                                            </div>
                                        </div>
                                        {values.keywords.length > 0 && (
                                            <ul className="tag-list">
                                                {values.keywords?.map((ele, index) => (
                                                    <li key={index}>
                                                        {ele}
                                                        <span onClick={() => removeKeywords(index)}>
                                                            <i className="fal fa-times"></i>
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Upload Document*</label>
                                            <div className="">
                                                <div className="drag_card_btn">
                                                    <FilePond
                                                        name="thumbnail"
                                                        allowMultiple={true}
                                                        maxFiles={1}
                                                        files={files}
                                                        allowFileTypeValidation={true}
                                                        onupdatefiles={(e)=>{
                                                            setFiles(e);
                                                            formik.setFieldValue("document",e && e.length && e[0].file ? e[0].file : [])
                                                            if (e.length) {
                                                                let reader = new FileReader();
                                                                reader.onload = (e) => {
                                                                    setFilePreview(e.target.result);
                                                                };
                                                                // reader.readAsDataURL(e[0].file);
                                                            }
                                                        }}
                                                        // server={downloadURL}
                                                        // onaddfile={(err, fileItem) => {
                                                        //     if (err) {
                                                        //         setFieldValue("document", [])
                                                        //     } else {
                                                        //         setFieldValue("document", fileItem)
                                                        //     }
                                                        // }}
                                                        acceptedFileTypes={['application/pdf']}

                                                    />
                                                </div>
                                                {files && files[0] && (
                                                    filePreview ? (
                                                    <div className="frm-group">
                                                        <ul className="list-unstyled attached-file-ul m-0">
                                                            <li>
                                                                <a href="#" onClick={() => {
                                                                    var type = 'application/pdf';
                                                                    let blob = null;
                                                                    const blobURL = URL.createObjectURL(pdfBlobConversion(filePreview, 'application/pdf'));
                                                                    const theWindow = window.open(blobURL);
                                                                    const theDoc = theWindow.document;
                                                                    const theScript = document.createElement('script');
                                                                    function injectThis() {
                                                                        window.print();
                                                                    }
                                                                    theScript.innerHTML = 'window.onload = ${injectThis.toString()};';
                                                                    theDoc.body.appendChild(theScript);
                                                                    function pdfBlobConversion(b64Data, contentType) {
                                                                        contentType = contentType || '';
                                                                        var sliceSize = 512;
                                                                        b64Data = b64Data.replace(/^[^,]+,/, '');
                                                                        b64Data = b64Data.replace(/\s/g, '');
                                                                        var byteCharacters = window.atob(b64Data);
                                                                        var byteArrays = [];

                                                                        for (var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize) {
                                                                            var slice = byteCharacters.slice(offset, offset + sliceSize);

                                                                            var byteNumbers = new Array(slice.length);
                                                                            for (var i = 0; i < slice.length; i++) {
                                                                                byteNumbers[i] = slice.charCodeAt(i);
                                                                            }

                                                                            var byteArray = new Uint8Array(byteNumbers);

                                                                            byteArrays.push(byteArray);
                                                                        }

                                                                        var blob = new Blob(byteArrays, { type: contentType });
                                                                        return blob;
                                                                    }
                                                                }}>
                                                                    <i className="fal fa-file"></i>
                                                                    &nbsp;{files && files[0] ? files && files[0]?.file?.name : ""}
                                                                </a>
                                                                <button className="btn btn-danger" onClick={() => {
                                                                    setFilePreview()
                                                                    setFiles([])
                                                                }} type={"button"}>
                                                                    <i className="fal fa-trash-alt"></i>
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    ) : (
                                                    <div className="frm-group">
                                                        <ul className="list-unstyled attached-file-ul m-0">
                                                            <li>
                                                                <a href={(filesUrl.includes("http") ? filesUrl : IMAGE_URL + filesUrl).replace("home/myaie/public_html", "").replace("public", "")} target="_blank">
                                                                    <i className="fal fa-file"></i>
                                                                    &nbsp;{files && files[0] ? files && files[0]?.file?.name : ""}
                                                                </a>
                                                                <button className="btn btn-danger" onClick={() => {
                                                                    setFilePreview()
                                                                    setFiles([])
                                                                }} type={"button"}>
                                                                    <i className="fal fa-trash-alt"></i>
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <label>Hash</label>
                                        <div className="form-icon-group">
                                            <div className="form-control custom-select-box">
                                                <input
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (errors.hash && touched.hash
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                    name="hash"
                                                    placeholder="Hash"
                                                    title="Hash"
                                                    onBlur={handleBlur}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>
                                        </div>
                                        {values.hash && values.hash.length > 0 && (
                                            <ul className="tag-list">
                                                {values.hash?.map((ele, index) => (
                                                    <li key={index}>
                                                        {ele}
                                                        <span onClick={() => removeHash(index)}>
                                                            <i className="fal fa-times"></i>
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </>
                            }
                            
                            {/* Link and White Paper Section */}
                            {
                                (values.type == "link" || values.type == "Link" || values.type == "white_paper") &&
                                <>
                                    <div className="col-md-6 col-lg-3">
                                        <label>Keywords*</label>
                                        <div className="form-icon-group">
                                            <div className="form-control custom-select-box">

                                                <input
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (errors.keywords && touched.keywords
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                    name="keywords"
                                                    placeholder="keywords"
                                                    title="keywords"
                                                    onBlur={handleBlur}
                                                    onKeyDown={handleKeyDown1}
                                                />
                                            </div>
                                        </div>
                                        {values.keywords.length > 0 && (
                                            <ul className="tag-list">
                                                {values.keywords?.map((ele, index) => (
                                                    <li key={index}>
                                                        {ele}
                                                        <span onClick={() => removeKeywords(index)}>
                                                            <i className="fal fa-times"></i>
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>URL*</label>
                                            <div className="form-icon-group mb-4">
                                                <input
                                                    type="text"
                                                    title="Url"
                                                    className={"form-control " + (errors.url && touched.url ? " is-invalid" : "")}
                                                    placeholder="URL"
                                                    name="url"
                                                    value={values.url}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <label>Hash</label>
                                        <div className="form-icon-group">
                                            <div className="form-control custom-select-box">

                                                <input
                                                    type="text"
                                                    className={
                                                        "form-control " +
                                                        (errors.hash && touched.hash
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                    name="hash"
                                                    placeholder="Hash"
                                                    title="Hash"
                                                    onBlur={handleBlur}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>
                                        </div>
                                        {values.hash && values.hash.length > 0 && (
                                            <ul className="tag-list">
                                                {values.hash?.map((ele, index) => (
                                                    <li key={index}>
                                                        {ele}
                                                        <span onClick={() => removeHash(index)}>
                                                            <i className="fal fa-times"></i>
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                </>
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="d-flex">
                                    <div className="form-group mr-3 mt-4">
                                        <div className="custom-control custom-checkbox text-left">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="published"
                                                name="published"
                                                checked={values.published}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <label className="custom-control-label" htmlFor="published">
                                                Published{(values.type == "vimeo" || values.type == "link" || values.type == "Link" || values.type == "white_paper") && "?"}
                                            </label>
                                        </div>

                                    </div>
                                    {(values.type == "vimeo" || values.type == "Youtube") && <div className="form-group  mt-4">
                                        <div className="custom-control custom-checkbox text-left">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="featured"
                                                name="featured"
                                                checked={values.featured}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <label className="custom-control-label" htmlFor="featured">
                                                Featured?
                                            </label>
                                        </div>

                                    </div>}
                                </div>
                            </div>
                        </div>

                        <div className="form-group-save-cancel ">
                            <PermissionsGate errorProps={{ disabled: true }} scopes={["shedit"]}>
                                <button className="btn btn-save btn-success" type="button" onClick={handleSubmit} title="Save" >
                                    {isDisabled === false ? <i className="fal fa-save"></i> : <i className="fas fa-cog fa-spin"></i>}
                                    Save
                                </button>
                            </PermissionsGate>
                            <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => history.goBack()} >
                                <i className="fal fa-times"></i>
                                Cancel
                            </button>
                        </div>

                        <div className="form-group form-group-save-cancel">
                            {Object.keys(errors).map((key) => (touched[key] && errors[key] ? (<div key={key} className="invalid-feedback d-block">{errors[key]}</div>) : null))}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default EditResourceManagement;