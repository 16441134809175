import React from "react";
import { useParams, useHistory,Link } from "react-router-dom";
import { GlobalIconNames } from "../../../../utils/GlobalIconNames";
import AddCentre from "./AddCentre";
import CentreList from "./CentreList";
import ExamCenterAuditTrail from "./ExamCenterAuditTrail";

function CentreTab() {
  const { tab, type, id, subTab } = useParams();
  const history = useHistory();
  return (
    <div>
      
        
      {(tab == "examCentres" && type == "edit") && 
        <div className="tabs-wrap">
          <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
            <li
              className="nav-item"
              onClick={() =>
                history.push(
                  `/studentAdministration/externalExam/examCentres/edit/form/${id}`
                )
              }
            >
              <Link
                className={`nav-link ${
                  (tab === "examCentres" && type=== "edit" && subTab =="form") ? "active" : ""
                }`}
                id="pills-tab5"
                data-toggle="pill"
                to={
                  `/studentAdministration/externalExam/examCentres/edit/form/${id}`
                }
                href="#pills-tab15"
                role="tab"
                aria-controls="pills-tab15"
                aria-selected="true"
              >
                {GlobalIconNames("details").html}
              </Link>
            </li>
            <li
              className="nav-item"
              onClick={() =>
                history.push(
                  `/studentAdministration/externalExam/examCentres/edit/audit-trail/${id}`
                )
              }
            >
              <Link
                className={`nav-link ${
                  (tab === "examCentres" && type=== "edit" && subTab =="audit-trail") ? "active" : ""
                }`}
                id="pills-tab5"
                data-toggle="pill"
                to={
                  `/studentAdministration/externalExam/examCentres/edit/audit-trail/${id}`
                }
                href="#pills-tab15"
                role="tab"
                aria-controls="pills-tab15"
                aria-selected="true"
              >
                {GlobalIconNames("audit-trail").html}
              </Link>
            </li>
          </ul>
        </div>
        
      }
      {type && type == "table" && !id && <CentreList />}
      {type && type == "add"   && <AddCentre />}
      {type && type=="edit" && subTab=="form" && <AddCentre />}
      {type && (type=="audit-trail" || subTab=="audit-trail") && <ExamCenterAuditTrail />}
    </div>
  );
}

export default CentreTab;
