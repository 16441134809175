import React from 'react'
import { useParams } from 'react-router-dom';
import Pending from './Appeal/Pending';
import Approved from './Appeal/Approved';
import Declined from './Appeal/Declined';

function ListofAppeals() {
  const { type } = useParams();

  const componentMap = {
    pending: <Pending />,
    approved: <Approved />,
    declined: <Declined />
  };

  return componentMap[type]
}

export default ListofAppeals
