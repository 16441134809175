import React from "react";
import { TrimText } from "../../common/TrimText";
export const checkRole = (LoggedInUserRole, UserRole) => {
  var response;
  switch (LoggedInUserRole) {
    case "Admin":
      var arr = [
        "Admin",
        "Super Admin",
        "Portal Admin",
        "Student Support",
        "Manager",
        "Developer",
        "Qualifications Coordinator",
        "Principle/Deputy Principle",
        "Deputy Principal",
        "Lecturer",
        "Content Editor",
        "Sales",
        "Accounts",
        "Programme Coordinator",
      ];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Super Admin":
      var arr = [
        "Admin",
        "Super Admin",
        "Portal Admin",
        "Student Support",
        "Manager",
        "Developer",
        "Qualifications Coordinator",
        "Principal/Deputy Principal",
        "Deputy Principal",
        "Lecturer",
        "Content Editor",
        "Sales",
        "Accounts",
      ];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Portal Admin":
      var arr = [
        "Admin",
        "Super Admin",
        "Portal Admin",
        "Student Support",
        "Manager",
        "Developer",
        "Qualifications Coordinator",
        "Principal/Deputy Principal",
        "Deputy Principal",
        "Lecturer",
        "Content Editor",
        "Sales",
        "Accounts",
      ];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Student Support":
      var arr = [
        "Student Support",
        "Manager",
        "Developer",
        "Qualifications Coordinator",
        "Principal/Deputy Principal",
        "Deputy Principal",
        "Lecturer",
        "Content Editor",
        "Sales",
        "Accounts",
        "Programme Coordinator",
      ];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Manager":
      var arr = ["Accounts", "Lecturer", "Qualifications Coordinator", "Content Editor", "Sales"];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Developer":
      var arr = ["Student Support", "Manager", "Developer", "Qualifications Coordinator", "Principal/Deputy Principal", "Deputy Principal", "Lecturer", "Content Editor", "Sales", "Accounts"];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Qualifications Coordinator":
      var arr = ["Lecturer", "Content Editor"];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Principal/Deputy Principal":
      var arr = ["Lecturer", "Qualifications Coordinator", "Content Editor"];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Deputy Principal":
      var arr = ["Lecturer", "Qualifications Coordinator", "Editor"];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    case "Programme Coordinator":
      var arr = ["Lecturer", "Content Editor"];
      if (arr.includes(UserRole)) {
        response = "enabled";
      } else {
        response = "disabled";
      }
      return response;

    default:
      return "disabled";
  }
};

export const Color = (role_name) => {
  switch (role_name) {
    case "Admin":
      return "cat-skyblue";

    case "Super Admin":
      return "cat-skyblue";

    case "Portal Admin":
      return "cat-skyblue";

    case "Student Support":
      return "cat-greenhex";

    case "Manager":
      return "cat-blue";

    case "Developer":
      return "cat-red";

    case "Qualifications Coordinator":
      return "cat-lightblue";

    case "Principal/Deputy Principal":
      return "cat-yellowshade";

    case "Deputy Principal":
      return "cat-yellowshade";

    case "Lecturer":
      return "cat-greenhex";

    case "Editor":
      return "cat-lightblue";

    case "Sales":
      return "cat-blue";

    case "Accounts":
      return "cat-red";
  }
};

export const ColorCode = (data) => {
  switch (data) {
    case "Admin":
      return RoleUi({ color: "cat-skyblue", text: "Admin" });

    case "Super Admin":
      return RoleUi({ color: "cat-skyblue", text: "Super Admin" });

    case "Portal Admin":
      return RoleUi({ color: "cat-skyblue", text: "Portal Admin" });

    case "Student Support":
      return RoleUi({ color: "cat-greenhex", text: "Student Support" });

    case "Manager":
      return RoleUi({ color: "cat-blue", text: "Manager" });

    case "Developer":
      return RoleUi({ color: "cat-red", text: "Developer" });

    case "Qualifications Coordinator":
      return RoleUi({ color: "cat-lightblue", text: "Qualifications Coordinator" });

    // case "Principle/Deputy Principle":
    //   return RoleUi({ color: "cat-yellowshade", text: "Principle/Deputy Principle" });

    case "Deputy Principal":
      return RoleUi({ color: "cat-yellowshade", text: "Deputy Principal" });

    case "Lecturer":
      return RoleUi({ color: "cat-greenhex", text: "Lecturer" });

    case "Editor":
      return RoleUi({ color: "cat-lightblue", text: "Editor" });

    case "Content Editor":
      return RoleUi({ color: "", text: "Content Editor" });

    case "Programme Coordinator":
      return RoleUi({ color: "", text: "Programme Coordinator" });

    case "Sales":
      return RoleUi({ color: "cat-blue", text: "Sales" });

    case "Accounts":
      return RoleUi({ color: "cat-red", text: "Accounts" });

    case "Midrand":
      return RoleUi({ color: "cat-skyblue", text: "Midrand" });

    case "Johannesburg":
      return RoleUi({ color: "cat-greenhex", text: "Johannesburg" });

    case "Cape Town":
      return RoleUi({ color: "cat-blue", text: "Cape Town" });
    case "Hatfield":
        return RoleUi({ color: "cat-yellowshade", text: "Hatfield" });
    case "Remote/Online":
      return RoleUi({ color: "cat-yellowshade", text: "Remote/Online" });

    case "User Created":
      return RoleUi({ color: "cat-skyblue", text: "User Created" });

    case "Edited":
      return RoleUi({ color: "cat-greenhex", text: "Edited" });

    case "User Deleted":
      return RoleUi({ color: "cat-red", text: "User Deleted" });

    case "Profile Picture Edited":
      return RoleUi({ color: "cat-yellowshade", text: "Profile Picture Edited" });

    case "Academic Institute of Excellence (AIE)":
      return CompanyUi({ color: "cat-skyblue", text: "Academic Institute of Excellence (AIE)" });

    case "Learnfast":
      return CompanyUi({ color: "cat-blue", text: "Learnfast" });

    case "Cadco":
      return CompanyUi({ color: "cat-red", text: "Cadco" });

    case "Greenside Design Center (GDC)":
      return CompanyUi({ color: "cat-greenhex", text: "Greenside Design Center (GDC)" });
  }
};

const RoleUi = ({ color, text }) => {
  return (
    <p className={"cat " + color}>
      <i className="fas fa-circle mr-1"></i> {text}
    </p>
  );
};

const CompanyUi = ({ color, text }) => {
  return (
    <p className={"cat " + color} title={text}>
      <i className="fas fa-circle mr-1"></i> {TrimText(text, 15)}
    </p>
  );
};

export const ColorRender = (props) => {
  let str = props ? props.toLowerCase() : props;
  var replaced = str ? str.replace(/-/g, " ") : str;
  switch (replaced) {
    case "admin":
      return "bgSkyblue";

    case "super admin":
      return "bgSkyblue";

    case "portal admin":
      return "bgSkyblue";

    case "student support":
      return "bgGreenHex";

    case "lecturer":
      return "bgGreenHex";

    case "manager":
      return "bgBlue";

    case "sales":
      return "bgBlue";

    case "developer":
      return "bgRed";

    case "accounts":
      return "bgRed";

    case "qualifications coordinator":
      return "bgBlue";

    case "editor":
      return "bgBlue";

    case "principle deputy principle":
      return "bgYellow";

    default:
      return "bgDarkRed";
  }
};

export const OldToNew = (role_name) => {
  switch (role_name) {
    case "Admin":
      return "Admin";

    case "Super Admin":
      return "Admin";

    case "Portal Admin":
      return "Admin";

    case "Student Support":
      return "Student Support";

    case "Manager":
      return "Manager";

    case "Developer":
      return "Developer";

    case "Qualifications Coordinator":
      return "Qualifications Coordinator";

    case "Principal":
      return "Principal";

    case "Deputy Principal":
      return "Deputy Principal";

    case "Lecturer":
      return "Lecturer";

    case "Editor":
      return "Editor";

    case "Sales":
      return "Sales";

    case "Accounts":
      return "Accounts";
  }
};
