import React, { useEffect, useState } from "react";
import Header from "../../common/Header";
import SideBar from "../../common/SideBar";

import { useSelector } from "react-redux";
import { getCurrentTimeHomePage } from "../../common/Helper";
// import Tabs from "../Tabs";
import { useHistory } from "react-router";
import { GetListOfRole, GetUserDetail, UpdateUserDetail } from "../../../services/UserService";
import { OldToNew } from "./CheckRole";
import { useLocation, useParams } from "react-router-dom";

import user from "../../../assets/images/default-user.png";

import { GetCategoryDetail, GetListOfStudentHubCategories, GetStudentTabListDetail } from "../../../services/StudentService";
import TabDataDetail from "./tabData";
import CategoryAction from "./CategoryAction";
import Tabs from "../../studentsupport/Tabs";

const CategoriesTabList = () => {
  const location = useLocation();
  const history = useHistory();
  const { tabId, id } = useParams() 
  
  // const [edit, setEdit] = useState(true);
  const [tablistData, setTablistData] = useState([]);
  // const [roles, setRoles] = useState(["Admin", "Student Support", "Manager", "Developer", "Qualifications Coordinator", "Principle/Deputy Principle", "Lecturer", "Editor", "Sales", "Accounts"]);

  // TODO API

  useEffect(() => {
    if(location.pathname != `/support/student-hub-library/student-hub/${tabId}/section/create/new`){
      GetCategoryDetail({ categoryId: id })
      .then((res) => {
        setTablistData(res.data?.hub_categories);
      })
      .catch((err) => console.log(err));
    }
  }, []);

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar />
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            {/* <Tabs /> */}
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                <div class="d-flex align-items-center ">
                  <div class="name-w-head d-flex align-items-center ">
                  {location.pathname != `/support/student-hub-library/student-hub/${tabId}/section/create/new` ?                   
                  <h4 class="text-left-align landing-heading heading_color_dynamic">Section: <span>{tablistData?.categoryName }</span></h4>
                  :                   
                    <h4 class="text-left-align landing-heading heading_color_dynamic">Create <span>Section</span></h4>
                  }
                  </div>
                  <div class="text-md-right action2-blk">
                    <div class="ticket-view-btn d-flex align-items-center">
                      <div class="add-new-ticket-bx">
                        <button onClick={()=>history.goBack()} title="Back" class="btn btn-white-bordered">
                          <i class="fal fa-angle-left"></i>Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                  {/* <h4 className="text-left-align landing-heading heading_color_dynamic">
                    Category <span>{tablistData?.categoryName}</span>
                  </h4> */}
                </div>
                {/*  */}

                <div className="tabs-wrap">                  
                  <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
                      <CategoryAction />
                    </div>
                  </div>
                </div>

                {/*  */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriesTabList;
