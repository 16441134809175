import React, {useMemo} from "react";
import { IMAGE_URL} from "../../../utils/Constants";
import { GetStudentAuditFiltersCancelToken, GetStudentAuditTrailDataCancelToken } from "../../../services/StudentsSettingServices";
import { Link } from "react-router-dom";
import AuditTrailsTable from "../../common/AuditTrailsTable";
import { useParams } from "react-router-dom";
import { RenderStudentResourceType } from "../../../utils/CommonGroupingItem";

function AuditTrail() {
  const { id, subType } = useParams();
  
  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"]


  return (
    <AuditTrailsTable 
    apiFunction={GetStudentAuditTrailDataCancelToken}
    filterArrApi={GetStudentAuditFiltersCancelToken}
    columnsToShow={columnsToShow}
    exportFileName={"Student_auditTrail"}
    isResourceNameShown={true}
    apiParams={{parent_action_id: id}}
    actionId={id}
    isShowingResourceTypeFilter
    renderResourceTypeFunction={RenderStudentResourceType}
    />
  );
}

export default AuditTrail;
