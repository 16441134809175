import React, { useState } from "react";
import Header from "../components/common/Header";
import SideBar from "../components/common/SideBar";
import { getCurrentTimeHomePage } from "../components/common/Helper";
// import AuditTrail from "../components/systemadministration/usermanagement/AuditTrail";
// import ListOfBrands from "../components/systemadministration/BrandManagement/ListOfBrands";
import Tabs from "../components/systemadministration/Tabs"
import { Link, useHistory } from "react-router-dom";
import PermissionsGate from "../utils/permissionGate";
import AuditTrail from "../components/systemadministration/ServerManagement/AuditTrail";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import ServerSettings from "../components/systemadministration/ServerManagement/ServerSettings";

const ServerManagement = (props) => {

    const history = useHistory();
    const [activeTab, setActiveTab] = useState('')
    const location = useLocation();
    const { tab } = useParams();

    useEffect(() => {
        if (tab !== '') {
            setActiveTab(tab)
        } else {
            setActiveTab('settings')
        }
    }, [tab]);

    return (
        <>
            <Header></Header>
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar></SideBar>
                <div className="sidebar-right">
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            <h4 className="text-left-align landing-heading heading_color_dynamic">
                                                Server <span>Management</span>
                                            </h4>
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                            <div className="add-new-ticket-bx">
                                                <button
                                                onClick={() => history.goBack()}
                                                title="Back"
                                                className="btn btn-white-bordered"
                                                >
                                                <i className="fal fa-angle-left"></i>Back
                                                </button>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrap">
                                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                                        <li className="nav-item" onClick={() => history.push("/administration/serverManagement/serverSettings")}>
                                            {/* <PermissionsGate scopes={["bmview"]}> */}
                                            <Link className={`nav-link ${activeTab == 'serverSettings' ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="pills-tab11" aria-selected="true">
                                                {/* to={"/administration/serverManagement/serverSettings"} */}
                                                <i className="fal fa-cog"></i>
                                                Server Settings
                                            </Link>
                                            {/* </PermissionsGate> */}
                                        </li>
                                        <li className="nav-item" onClick={() => history.push("/administration/serverManagement/auditTrail")}>
                                            {/* <PermissionsGate scopes={["bmView"]}> */}
                                            <Link className={`nav-link ${activeTab == 'auditTrail' ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="pills-tab12" aria-selected="true">
                                                {/* to={"/administration/serverManagement/auditTrail"}  */}
                                                <i className="fal fa-history"></i>Audit Trail
                                            </Link>
                                            {/* </PermissionsGate> */}
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade active show" role="tabpanel">
                                            {location.pathname.includes("/administration/serverManagement/serverSettings") && <ServerSettings />}
                                        {/* </div>
                                        <div className="tab-pane fade  active show" role="tabpanel" aria-labelledby="pills-tab2"> */}
                                            {location.pathname.includes("/administration/serverManagement/auditTrail") && <AuditTrail />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ServerManagement;
