import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import SkeletonTicketList from "../../../../../loaders/SkeletonTicketList";
import Str from "../../../../common/Str";
import StatusRender from "../../../../systemadministration/usermanagement/CheckRole";
import { TrimText } from "../../../../common/TrimText";
import { IMAGE_URL, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../../utils/Constants";
import { RenderRegistrationStatus } from "../../../../../utils/CommonStatusItems";
import { RenderLearningMethod } from "../../../../../utils/CommonGroupingItem";
import { InitialRender } from "../../../../common/Helper";
import Hover from "../../../../common/Hover";
import { handleTableScroll } from "../../../../../utils/commonFunction";

function RegistrationTab({ studentData, qualList, registerLoading }) {
  const [search, setSearch] = useState("");

  const dropdownValues = useSelector((state) => state.registerDropdownValues)

  useEffect(() => {
    handleTableScroll()
  }, [registerLoading])


  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const SetQualificationValue = (value) => {
    // console.log("value",dropdownValues.qualification,value);
    let val = false;
    if (isNaN(+value)) {
      let filteredArr = dropdownValues && dropdownValues.qualification.filter(item => item.value == value)
      // console.log("filteredArr",filteredArr);
      val = filteredArr && filteredArr[0] ? filteredArr[0].label : value
    } else {
      let filteredArr = qualList.filter(item => item.id == value)
      // console.log("filteredArr",filteredArr);
      val = filteredArr && filteredArr[0] ? filteredArr[0].displayname : value
    }
    return val || value;
  }

  const dataToRender = () => {
    let updatedData = [];
    let allData = studentData;
    if (search.length) {
      // let tempId = allData.filter((item) => {
      //   let includes = item.id.toString().toLowerCase().includes(search.toLowerCase());
      //   if (includes) {
      //     return includes;
      //   } else return null;
      // });
      let tempQualification = allData.filter((item) => {
        let includes = SetQualificationValue(item.qualification).toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempDate = allData.filter((item) => {
        let includes = item.date ? moment(item.date).format(TABLE_DATE_TIME_FORMAT).toString().toLowerCase().includes(search.toString().toLowerCase()) : "";
        if (includes) {
          return includes;
        } else return null;
      });
      let tempSalesPerson = allData.filter((item) => {
        let includes = item.sale_person ? item.sale_person.toLowerCase().includes(search.toString().toLowerCase()) : "";
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        // ...tempId,
        ...tempQualification,
        ...tempDate,
        ...tempSalesPerson
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (search.length) {
      return updatedData;
    } else {
      return studentData;
    }
  };  
  const sortingDate = (rowA, rowB) => {
    if (rowA?.date != null && rowB?.date != null && rowA?.date) {
      const name1 = new Date(rowA?.date);
      const name2 = new Date(rowB?.date);
      return name1 - name2;
    }
  };

  const columns = useMemo(() => [
    {
      name: "Programme",
      selector: "qualification",
      sortable: true,
      cell: (row) => (
        <Link
          to={`/studentadministration/registration/open/registration?studentId=${row.id}&studentName=${row.first_name} ${row.last_name}`}
          className="as-text-blue curser feature-name"
          title={SetQualificationValue(row.qualification)}
        >
          <span className="textLimit100">{row.qualification ? SetQualificationValue(row.qualification) : "-"}</span>
        </Link>
      ),
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      sortFunction: sortingDate,
      cell: (row) => (
        <>
          {row.date ? (
            <div className="dateTime" title={moment(row.date).format(TABLE_DATE_TIME_FORMAT)}>
              <p className="right-space">{moment(row.date).format(TABLE_DATE_FORMAT)}</p>
              <p>{moment(row.date).format(TABLE_TIME_FORMAT)}</p>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    { name: "Intake", selector: "intake_year", sortable: true, cell: (row) => (row.intake_year ? row.intake_year + "-" + row.intake_number : "-") },
    // { name: "Intake Number", selector: "intake_number", sortable: true, cell: (row) => (row.intake_number ? row.intake_number : "-") },
    { name: "Learning Method", selector: "delivery_methode", sortable: true, cell: (row) => (row.delivery_methode ? <span title={row.delivery_methode}>{RenderLearningMethod(row.delivery_methode).html}</span>: "-") },
    {
      name: "Sales Person",
      selector: "sale_person",
      sortable: true,
      // minWidth: "190px",
      cell: (row) => (
        // <span className="feature-name">
        //   <span className="textLimit100" title={row.sale_person}>
        //     {row.sale_person}
        //   </span>
        // </span>
        <>
          {row.sales_person_first_name ? (
            <div className="assigned-title-block-new">
              <div className="assigned-title-itm">
                <span className={"assigned-title-blk  name-icon bgBlue "}>
                  {row.sales_photo ? (<img src={`${IMAGE_URL}/${row.sales_photo.replaceAll("public/","").replaceAll("/home/myaie/public_html/", "")}`} alt="AIE" />) : (
                    InitialRender([row.sales_person_first_name, row.sales_person_last_name,])
                  )}
                  <span className={`profile-box-2-status ${row.sales_person_activity_status && row.sales_person_activity_status.toLowerCase()=="online" ? "Online" : "Offline"}`}>
                  <i className="fas fa-circle"></i>
                  </span>
                  <Hover firstName={row.sales_person_first_name} lastName={row.sales_person_last_name} photo={row.sales_photo} email={row.sales_person_email} mobile={row.sales_person_mobile} right={true} role="Sales" status={row.sales_person_status} activity_status={row.sales_person_activity_status}/>
                </span>
                <Link className="as-text-blue curser textLimit76 " to={`/systemAdministration/userManagement/open/${row.sale_person_id}/aboutYou`}>
                  {row.sales_person_first_name}
                </Link>
              </div>
            </div>
          ) : (
            "NA"
          )}
        </>
      ),
    },
    {
      name: "Status",
      selector: "status", sortable: true,
      cell: (row) => row.status ? RenderRegistrationStatus(row.status).html : "-"
    },
    { name: "Type", selector: "type", sortable: true, cell: (row) => <span className="feature-name"><span className="textLimit100">{row.type ? row.type=="Re-Registration" ?  
      <span className={"cat cat-red" } title={"Re-Registration"}>Re-Registration</span>: <span className={"cat cat-blue"} title={"New"}>New
      </span>:"-"}</span></span>
    },
    {
      name: "Action",
      selector: "",
      // maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            {/* <PermissionsGate scopes={["umedit"]}> */}
            <Link
              to={`/studentadministration/registration/open/registration?studentId=${row.id}&studentName=${row.first_name} ${row.last_name}`}
              className="btn btn-primary rounded-circle"
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            {/* </PermissionsGate> */}
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = ["Programme", "Date", "Intake", "Learning Method", "Sales Person", "Status", "Type"];

    data = data?.map((row) => ({
      ...row,
      Programme: SetQualificationValue(row.qualification),
      Date: row.date ? moment(row.date).format(TABLE_DATE_TIME_FORMAT) : "-",
      "Intake": row?.intake_year ? row.intake_year + "-" + row.intake_number : "-",
      "Learning Method": row.delivery_methode ? row.delivery_methode : "-",
      "Sales Person": row.sale_person ? row.sale_person : "-",
      Status: row.status ? RenderRegistrationStatus(row.status).text : "-",
      Type: row.type ? row.type : "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Programme, row.Date, row["Intake"], row["Learning Method"], row["Sales Person"], row.Status, row.Type];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };
  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Registrations</div>
        {(registerLoading.data || registerLoading.qualfications) && <i className="fas fa-cog fa-spin ml-2"></i>}
      </div>
      <div className="custom-table-div filter-search-icon card-table-custom">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group student-zindex">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner filter-custom-new`}>

                  </div>
                </div>
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={() => setSearch("")} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Registration_list");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Registration_list");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Registration_list");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
            </div>
          </div>
        </div>

        {registerLoading.data || registerLoading.qualfications ? <SkeletonTicketList /> : <DataTable
          data={dataToRender()}
          defaultSortField="date"
          defaultSortAsc={true}
          columns={columns}
          pagination={true} noDataComponent={Str.noRecord}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />}
      </div>
    </div>
  );
}

export default RegistrationTab;
