import $ from "jquery";
import "jspdf-autotable";
import JsPDF from "jspdf";
import moment from "moment";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import React, { useEffect, useMemo, useState } from "react";
import CoursesTable from "./CoursesTable";
import { Link } from "react-router-dom";
import listIcon from "../../../../../assets/images/list-tree.svg";
import PermissionsGate from "../../../../../utils/permissionGate";
import { RenderCorrespondence, RenderProgrammeOrCourseType } from "../../../../../utils/CommonGroupingItem";
import DataTableComponent from "../../../../common/DataTableComponent";
import { GetAvailableSubject } from "../../../../../services/StudentsSettingServices";
import Swal from "sweetalert2";
import { TABLE_DATE_FORMAT } from "../../../../../utils/Constants";

function AvailableQualificationAndCourses(props) {
 const [selectedCourses, setSelectedCourses] = useState([]);

 const [filterSelected,setFilterSelected] = useState([]);

useEffect(() => {
  setFilterSelected(props.data);
  return () => {
    return
  }
}, [props.data]);

useEffect(() => {
  setFilterSelected(filterSelected);
  return () => {
    return
  }
}, [filterSelected]);

  const closeAssign = () => {
    $("#Assign-Programme").modal("hide");
  }

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "course",
      sortField: "course",
      sortable: true,
      cell: (row) => (
        <span>
          {row.course ? (
            row.programs_course_type !== "programs" ? (<Link
              //to={`/courseAdministration/coursesdetails/${row.id}/dashboard/show`}
              className="as-text-blue curser feature-name"
              onClick={() => {
                 //$("#Assign-Programme").modal("hide")
                 
                 //Assigning the course in Courses&Programmes
                 props.assignCourse(row, true);
                 //Removing the record from the modal table
                 const updatedData = filterSelected.filter((item) => item.id !== row.id);
                 setFilterSelected([...updatedData]);
                }}
              // onClick={() => {
              //   showCourses(row);
              // }}
              title={row.course}
            >
              <span className="textLimit100">{row.course}</span>
            </Link>) : (
              <a
                href="#!"
                className="as-text-blue curser feature-name"
                onClick={() => {
                  props.assignCourse(row, true);
                  const updatedData = filterSelected.filter((item) => item.id !== row.id);
                  setFilterSelected([...updatedData]);
                 // showCourses(row);
                }}
                title={row.course}
              >
                <span className="textLimit100">{row.course}</span>
              </a>)
          ) : (
            "-"
          )}
        </span>
      ),
    },
    {
      name: "Start",
      selector: "start",
      sortField: "start",
      sortable: true,
      cell: (row) => (
        <>
          {row.start ? (
            <div className="dateTime">
              {/* {new Date(row?.start)} */}
              {/* <p className="right-space">{new Date(row.start)}</p>
                        <p>{moment(row.start).format(TABLE_TIME_FORMAT)}</p> */}
              <p className="right-space">{moment.unix(row.start).format(TABLE_DATE_FORMAT)}</p>
              {/* <p>{moment.unix(row.start).format(TABLE_TIME_FORMAT)}</p> */}
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "End",
      selector: "end",
      sortField: "end",
      sortable: true,
      cell: (row) => (
        <>
          {row.end ? (
            <div className="dateTime">
              <p className="right-space">{moment.unix(row.end).format(TABLE_DATE_FORMAT)}</p>
              {/* <p>{moment.unix(row.end).format(TABLE_TIME_FORMAT)}</p> */}
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      name: "Intake",
      selector: "year",
      sortField: "intake",
      sortable: true,
      // maxWidth: "120px",
      // minWidth: "120px",
      cell: (row) => <>{row.year ? row.year + "-" + row.number : "-"}</>,
    },
    {
      name: "Type",
      selector: "programs_course_type",
      sortField: "course_type",
      sortable: true,
      cell: (row) => <>{row.programs_course_type ? RenderProgrammeOrCourseType(row.programs_course_type).html : "-"}</>,
    },
    {
      name: "Learning Method",
      selector: "type",
      sortField: "type",
      sortable: true,
      cell: (row) => <div>{row.type ? RenderCorrespondence(row.type).html : "-"}</div>,
    },
    {
      name: "Actions",
      selector: "",
      // maxWidth: "50px",
      cell: (row) =>
        row.programs_course_type == "programs" ?
          (<div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons ">
              
              <PermissionsGate errorProps={{ disabled: true }} scopes={["scpadd"]}>
                    <button
                      title="Add"
                      className="btn btn-primary rounded-circle"
                      onClick={() => {
                        props.assignCourse(row, true);
                        //console.log(row.id)
                        const updatedData = filterSelected.filter((item) => item.id !== row.id);
                        // console.log("Data after filtering",updatedData);
                        // console.log("Spread",[...updatedData])
                        setFilterSelected([...updatedData]);
                        //deleteRow(row.id);
                       
                      }}
                    >
                      <i className="fal fa-plus"></i>
                    </button>
              </PermissionsGate>  
              {/* <button title="View" className="btn btn-primary rounded-circle" onClick={() => { props.assignCourse(row, true) }}>
                            <i className="fal fa-plus"></i>
                  </button> */}
              <div className="dropdown btn-dropdown-item ">
                <button className="btn btn-primary rounded-circle dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="More">
                  <i className="fal fa-ellipsis-h-alt"></i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <PermissionsGate
                    RenderError={() => (
                      <button className="btn btn-primary rounded-circle" disabled title="Open">
                        <i className="fal fa-folder-open"></i>
                      </button>
                    )}
                    scopes={["capview"]}
                  >
                    <Link className="btn btn-primary rounded-circle" to={{ pathname: `/courseAdministration/Programmes/programme/open/${row.id}/details` }} title="Open">
                      <i className="fal fa-folder-open" onClick={() => { closeAssign() }}></i>
                    </Link>
                  </PermissionsGate>
                  <button
                    title="Manage Subjects"
                    className="btn btn-primary rounded-circle"
                    onClick={() => {
                      showCourses(row);
                    }}
                  >
                    <img src={listIcon} height="15px" width="15px" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          ) : (
            <div className="assessment-08 btn-dropdown-grp">
              <div className="as-buttons ">
              <PermissionsGate errorProps={{ disabled: true }} scopes={["sapiedit"]}>
                  <button
                    title="Add"
                    className="btn btn-primary rounded-circle"
                    onClick={() => {
                      props.assignCourse(row, true);
                      const updatedData = filterSelected.filter((item) => item.id !== row.id);
                      // console.log("Data after filtering",updatedData);
                      // console.log("Spread",[...updatedData])
                      setFilterSelected([...updatedData]);
                    }}
                  >
                    <i className="fal fa-plus"></i>
                  </button>
                </PermissionsGate>
                <PermissionsGate
                  RenderError={() => (
                    <button className="btn btn-primary rounded-circle" disabled title="Open">
                      <i className="fal fa-folder-open"></i>
                    </button>
                  )}
                  scopes={["capview"]}
                >
                  <Link className="btn btn-primary rounded-circle" onClick={() => { $("#Assign-Programme").modal("hide") }} to={{ pathname: `/courseAdministration/coursesdetails/${row.id}/dashboard/show` }} title="Open">
                    <i className="fal fa-folder-open"></i>
                  </Link>
                </PermissionsGate>
              </div>
            </div>
          ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    const apiData = {
      page: props.tableState.page,
      limit: props.tableState.perPage,
      key: props.tableState.sortKey,
      sort: props.tableState.sortOrder,
      search: props.search,
      viaInatke: props.availableIntake.arr,
      viaType: props.availableType.arr,
      exportStatus: "true",
      student_id: props.id
    };
    GetAvailableSubject(apiData)
      .then((res) => {
        let data = res.data.assign_subject;
        const header = ["Name", "Start", "End", "Intake", "Type", "Learning Method"];

        data = data?.map((row) => ({
          ...row,
          Name: row.course,
          "Start": row.start ? moment.unix(row.start).format(TABLE_DATE_FORMAT) : "-",
          "End": row.end ? moment.unix(row.end).format(TABLE_DATE_FORMAT) : "-",
          Intake: row.year ? row.year + "-" + row.number : "-",
          Type: row.programs_course_type ? RenderProgrammeOrCourseType(row.programs_course_type).text : "-",
          "Learning Method": row.type ? RenderCorrespondence(row.type).text : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();

          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [row.Name, row["Start"], row["End"], row.Intake, row.Type, row["Learning Method"]];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();

          return false;
        }
      })
  };

  function showCourses(courses) {
    setSelectedCourses(courses);
    $("#viewAvailCourses").modal("show");
  }

  function closeCourses() {
    setSelectedCourses([]);
    $("#viewAvailCourses").modal("hide");
  }

  function setAvailable(data) {
    setSelectedCourses([]);
    props.assignCourse(data);
  }

  return (
    <>
      <DataTableComponent
        data={filterSelected}
        loading={props.loading}
        state={props.tableState}
        setState={props.setTableState}
        setSearch={props.setSearch}
        totalRows={props.totalRows}
        columns={columns}
        exportFunction={exportData}
        exportFileName={"Available_Programme_List"}
        isInsidePopUp
        filters={[
          {
            filterName: "Intake",
            optionArr: props.filterDataAvail.intake,
            state: props.availableIntake,
            setState: props.setAvailableIntake,
            isOptionReversed: true,
            uniqueId: "availableIntake",
          },
          {
            filterName: "Type",
            optionArr: props.filterDataAvail.type,
            state: props.availableType,
            setState: props.setAvailableType,
            renderLabelFunction: RenderProgrammeOrCourseType,
            uniqueId: "availableType",

          }
        ]}
      />
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="custom-table-div filter-search-icon card-table-custom">
          <div className="topic-add-modal modal fade zindexadd " id="viewAvailCourses" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header modal-header-custom">
                  <h5>Courses</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => { closeCourses() }}
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">×
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <CoursesTable courses={selectedCourses} assignCourse={setAvailable} isRemoveBtn={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AvailableQualificationAndCourses;
