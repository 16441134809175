import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import {
  EditorState,
  convertFromRaw,
} from "draft-js";

import "draft-js/dist/Draft.css";
import "draft-js-mention-plugin/lib/plugin.css";
import Swal from "sweetalert2";
import * as Yup from "yup";
import PermissionsGate from "../../../utils/permissionGate";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import {
  emailListDetails,
  emailDetailsEdit,
  emailListDetailsCancelToken,
} from "../../../services/EmailServices";
import { useSelector } from "react-redux";
import hasPermission from "../../../utils/hasMultiplePermission";
import { formatQuestion } from "../../../utils/FormatQuestion";
import axios from "axios";

const EmailEdit = ({ emailId, setName }) => {
  const { id, type } = useParams();
  const history = useHistory();
  const [details, setDetails] = useState([]);
  const [editorState, setEditorState] = React.useState("");
  const [editorStateFooter, setEditorStateFooter] = React.useState("");
  const [editorLoading, setEditorLoading] = useState(false)

  const [disabled, setDisabled] = useState(false);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  // TODO Api Call

  useEffect(() => {
    let response
    if (type == "edit") {
      response = hasPermission({ scopes: ["bnntedit"], permissions: givenPermsisions });
    } else if (type == "add") {
      response = hasPermission({ scopes: ["bnntadd"], permissions: givenPermsisions });
    }
    if (!response) {
      history.push("/noaccess")
    }
  }, [])

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await emailListDetailsCancelToken(id, dropdownSource.token);
        if (res.status == 200){
          setDetails(res?.data?.details);
          setName(res?.data?.details?.subject);
          setEditorState(res?.data?.details?.header);
          setEditorStateFooter(res?.data?.details?.footer);
          setEditorLoading(true);
        };
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setEditorState("");
          setEditorStateFooter("true");
        }
      }
    };
    if (id !== undefined) {
     fetchData();
    }

    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      subject: details?.subject ? details?.subject : "",
      tags: details?.tags ? details?.tags : '',
      description: details?.info ? details?.info : '',
      bodyContent: '',
      footerContent: '',
    },
    validationSchema: Yup.object({
      subject: Yup.string()
        .trim("The Subject cannot include leading and trailing spaces")
        .required("Subject is required"),
      // bodyContent: Yup.string().required("Body content is required"),
      // footerContent: Yup.string().required("Footer content is required"),
      bodyContent: Yup.string()
        .test("required", "Body Content is required", (value) => {
          if (
            formatQuestion(editorState).replaceAll("&nbsp;", " ").trim() ==
              "" &&
            !editorState.includes("<img")
          ) {
            return false;
          }
          if (!editorState) {
            return false;
          }
          return true;
        })
        .trim(),
      // footerContent: Yup.string()
      //   .test("required", "Footer Content is required", (value) => {
      //     if (
      //       formatQuestion(editorStateFooter).replaceAll("&nbsp;", " ").trim() ==
      //         "" &&
      //       !editorStateFooter.includes("<img")
      //     ) {
      //       return false;
      //     }
      //     if (!editorStateFooter) {
      //       return false;
      //     }
      //     return true;
      //   })
      //   .trim(),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let formData = new FormData();
      // formData.append("method", "POST");
      type == "edit" && formData.append("id", id);
      // type == "add" && formData.append("info", formik.values.description);
      // type == "add" && formData.append("tags", formik.values.tags);
      formData.append("info", formik.values.description ? formik.values.description : '');
      formData.append("tags", formik.values.tags ? formik.values.tags : "");
      
      formData.append("subject", values?.subject);
      // formData.append("header", header);
      // formData.append("from_email", "email@gmail.com");
      // formData.append("sms_content", "new data");
      // formData.append("footer", footer);
      formData.append("header", editorState);
      formData.append("footer", editorStateFooter);


      emailDetailsEdit(formData).then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data updated successfully",
        })
          .then(async (result) => {
            // history.push(`/notification/email/list/table`);
            setDisabled(false);
            if(type=="add"){
              history.push(`/notification/bulknotification/notifications-template/table`);
            }
           
            
          })
          .catch(function (err) {
            // history.push(`/notification/email/list/table`);
            // history.push(`/notification/bulknotification/notifications-template/table`);
            setDisabled(false);
          });
      });
    },
  });

  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12">
            <label htmlFor="subject">Description</label>
            <div className="form-icon-group mb-4" title="Subject">
              <input
                // readOnly={type == "add" ? false : true}
                type="text"
                className={
                  "form-control" +
                  (formik.errors.description && formik.touched.description
                    ? " is-invalid"
                    : "")
                }
                name="description"
                id="description"
                placeholder="Description"
                title="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-12">
            <label htmlFor="subject">Subject*</label>
            <div className="form-icon-group mb-4" title="Subject">
              <input
                type="text"
                className={
                  "form-control" +
                  (formik.errors.subject && formik.touched.subject
                    ? " is-invalid"
                    : "")
                }
                name="subject"
                id="subject"
                placeholder="Subject"
                title="Subject"
                value={formik.values.subject}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="col-12">
            <label htmlFor="tags">Available Tags</label>
            <div className="form-icon-group mb-4">
              <textarea
                className={
                  "form-control" +
                  (formik.errors.tags && formik.touched.tags
                    ? " is-invalid"
                    : "")
                }
                placeholder="Tags"
                // readOnly={type == "add" ? false : true}
                title="Tags"
                name="tags"
                rows="8"
                value={formik.values.tags}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
            </div>
          </div>
          <div className="col-12">
            <label htmlFor="header">Body Content*</label>
           {(editorLoading || type=="add") && <HtmlInputEditor
              editorState={editorState}
              setEditorState={setEditorState}
              isInvalid={
                formik.touched.bodyContent &&
                formik.errors.bodyContent
              }
              isCKEditor={true}
              hideSign={true}
            />}
          </div>
          <div className="col-12">
            <label htmlFor="footer">Footer Content</label>
           {(editorLoading || type=="add") && <HtmlInputEditor
              editorState={editorStateFooter}
              setEditorState={setEditorStateFooter}
              isInvalid={
                formik.touched.footerContent &&
                formik.errors.footerContent
              }
              isCKEditor={true}
              hideSign={true}
            />}
          </div>
        </div>
        <div className="form-group form-group-save-cancel">
          <PermissionsGate
            scopes={["bmedit"]}
            errorProps={{ disabled: true }}
          ></PermissionsGate>
          <button
            className="btn btn-save btn-success"
            type="submit"
            title="Save"
          // disabled={!formik.dirty}
          >
            {disabled ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-save"></i>
            )}
            Save
          </button>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            // onClick={() => history.push("/administration/email/list/table")}
            onClick={() => history.goBack()}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>
          {Object.keys(formik.values).map((key) => {
            if (formik.touched[key] && formik.errors[key]) {
              return (
                <div className="invalid-feedback d-block">
                  {formik.errors[key]}
                </div>
              );
            }
          })}
        </div>
      </form>
    </div>
  );
};

export default EmailEdit;
