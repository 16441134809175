import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import {
  GetStudentDropdown,
  StudentUpdateAccountData,
} from "../../../../../services/StudentsSettingServices";
import Swal from "sweetalert2";
import { myCompare } from "../../../../common/MyCompare";
import PermissionsGate from "../../../../../utils/permissionGate";

function AddressBlock({ setDeleteRow, deleteRow, studentData }) {
  const { id } = useParams();
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [province, setProvince] = useState([]);
  const [country, setCountry] = useState([]);

  const fixDataFormet = (obj) => {
    let tempArr = [];
    Object.keys(obj).map((item) => {
      tempArr.push({ value: item, label: obj[item] });
    });
    return tempArr;
  };

  useEffect(() => {
    GetStudentDropdown().then((res) => {
      setProvince(fixDataFormet(res.data.province));
      setCountry(fixDataFormet(res.data.country));
    });
  }, []);

  const tempStatusArr = [];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      StreetAddress: studentData && studentData.ph_street!=null ? studentData.ph_street : "",
      City: studentData && studentData.ph_city!=null ? studentData.ph_city : "",
      Province: studentData && studentData.ph_province!=null ? studentData.ph_province : "",
      Country: studentData && studentData.ph_country!=null ? studentData.ph_country : "",
      PostalCode: studentData && studentData.ph_zip!=null ? studentData.ph_zip : "",
    },
    validationSchema: Yup.object({
      StreetAddress: Yup.string().required("Street Address is required").trim(),
      City: Yup.string().required("City is required").trim().matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this City"),
      Province: Yup.string().required("Province is required").trim(),
      Country: Yup.string().required("Country is required").trim(),
      PostalCode: Yup.string().required("Postal Code is required").trim().test(
        'Is positive?', 
        'Postal Code Cannot Be a Negative Number', 
        (value) => value > 0
      ).min(5,"Please Enter Minimum 5 Digit").max(10, "Please Enter Maximum 10 Digit"),
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let formData = new FormData();
      formData.append("section", "address_section");
      formData.append("student_id", id);
      formData.append("ph_street", values.StreetAddress);
      formData.append("ph_city", values.City);
      formData.append("ph_province", values.Province);
      formData.append("ph_country", values.Country);
      formData.append("ph_zip", values.PostalCode);
      StudentUpdateAccountData(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          });
          setDisabled(false);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err?.response?.data?.message,
          });
          setDisabled(false);
          console.log("error :", err);
        });
    },
  });

  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
      <div className="edit-icon new-card-header">
        <div className="card-header">Address</div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 col-lg-4">
            <label htmlFor="StreetAddress">Street Address *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="StreetAddress"
                className={
                  "form-control" +
                  (formik.errors.StreetAddress && formik.touched.StreetAddress
                    ? " is-invalid"
                    : "")
                }
                name="StreetAddress"
                placeholder="Enter Street Address"
                value={formik.values.StreetAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Street Address"
              />
              {formik.errors.StreetAddress && formik.touched.StreetAddress ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="City">City *</label>
            <div className="form-icon-group mb-4">
              <input
                type="text"
                id="City"
                className={
                  "form-control" +
                  (formik.errors.City && formik.touched.City
                    ? " is-invalid"
                    : "")
                }
                name="City"
                placeholder="Enter City"
                value={formik.values.City}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="City"
              />
              {formik.errors.City && formik.touched.City ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Province">Province *</label>
            <div className="form-icon-group mb-4" title="Province">
              <Select
                className={
                  "form-control custom-select-box " +
                  (formik.errors.Province && formik.touched.Province
                    ? " is-invalid"
                    : "")
                }
                name="Province"
                value={province.filter((item) => {
                  return item.value == formik.values.Province;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("Province", value.value);
                  } else {
                    formik.setFieldValue("Province", "");
                  }
                }}
                onBlur={formik.handleBlur}
                options={province.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={
                  formik.values.Province ? formik.values.Province : "Select"
                }
                isClearable
              />
              {formik.errors.Province && formik.touched.Province ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="Country">Country *</label>
            <div className="form-icon-group mb-4" title="Country">
              <Select
                className={
                  "form-control custom-select-box " +
                  (formik.errors.Country && formik.touched.Country
                    ? " is-invalid"
                    : "")
                }
                name="Country"
                value={country.filter((item) => {
                  return item.value == formik.values.Country;
                })}
                onChange={(value) => {
                  if(value){
                    formik.setFieldValue("Country", value.value);
                  } else {
                    formik.setFieldValue("Country", "");
                  }
                }}
                onBlur={formik.handleBlur}
                options={country.sort(myCompare)}
                maxMenuHeight={175}
                placeholder={
                  formik.values.Country ? formik.values.Country : "Select"
                }
                isClearable
              />
              {formik.errors.Country && formik.touched.Country ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <label htmlFor="PostalCode">Postal Code *</label>
            <div className="form-icon-group mb-4">
              <input
                type="number"
                id="PostalCode"
                className={
                  "form-control" +
                  (formik.errors.PostalCode && formik.touched.PostalCode
                    ? " is-invalid"
                    : "")
                }
                name="PostalCode"
                placeholder="Enter country"
                value={formik.values.PostalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="Postal Code"
              />
              {formik.errors.PostalCode && formik.touched.PostalCode ? (
                <span className="exclamation">
                  <i className="fal fa-exclamation-circle"></i>
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form-group form-group-save-cancel">
          <PermissionsGate errorProps={{ disabled: true }} scopes={["sapiedit"]}>
          <button
            className="btn btn-save btn-primary"
            type="submit"
            title="Save"
            disabled={disabled || !formik.dirty || isLoaded}
          >
            {disabled ? (
              <i className="fas fa-cog fa-spin"></i>
            ) : (
              <i className="fal fa-save"></i>
            )}
            Save
          </button>
          </PermissionsGate>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => formik.resetForm()}
          >
            <i className="fal fa-times"></i>
            Cancel
          </button>

          {formik.touched.StreetAddress && formik.errors.StreetAddress ? (
            <div className="invalid-feedback d-block">
              {formik.errors.StreetAddress}
            </div>
          ) : null}
          {formik.touched.City && formik.errors.City ? (
            <div className="invalid-feedback d-block">{formik.errors.City}</div>
          ) : null}
          {formik.touched.Province && formik.errors.Province ? (
            <div className="invalid-feedback d-block">
              {formik.errors.Province}
            </div>
          ) : null}
          {formik.touched.Country && formik.errors.Country ? (
            <div className="invalid-feedback d-block">
              {formik.errors.Country}
            </div>
          ) : null}
          {formik.touched.PostalCode && formik.errors.PostalCode ? (
            <div className="invalid-feedback d-block">
              {formik.errors.PostalCode}
            </div>
          ) : null}
        </div>
      </form>
    </div>
  );
}

export default AddressBlock;
