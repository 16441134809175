import React from 'react'
import { useEffect } from 'react'
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AuditTrailsTable from '../../common/AuditTrailsTable';
import { GetCopyIntakeAuditTrialCancelToken, GetCopyIntakeAuditTrialDropdownCancelToken, getCopyIntakeAuditTrial, getCopyIntakeAuditTrialDropdown } from '../../../services/copyIntakeServices';

function AuditTrails() {
  const history = useHistory();
  const givenPermsisions = useSelector((state) => state.givenPermission);
  useEffect(() => {
    let response = hasPermission({ scopes: ["intakeCopyView"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  const columnsToShow = ["Action Type", "Resource Name"]

  return (
    <AuditTrailsTable
      apiFunction={GetCopyIntakeAuditTrialCancelToken}
      apiParams={{}}
      columnsToShow={columnsToShow}
      filterArrApi={GetCopyIntakeAuditTrialDropdownCancelToken}
      // actionId={id}
      exportFileName={"Intake_copy_auditTrail"}
      // resourceNameRedirect={handleRedirect}
      isResourceNameShown={true}
    />
  )
}

export default AuditTrails