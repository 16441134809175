import React, { useEffect, useState } from "react";
import { Link, Prompt, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ColorRender } from "../../systemadministration/usermanagement/CheckRole";
import { InitialRender } from "../../common/Helper";
import { TrimText } from "../../common/TrimText";
import { RenderQuizAttemptResultStatus } from "../../../utils/CommonStatusItems";
import Hover from "../../common/Hover";
import {
  IMAGE_URL,
  STUDENT_FILES_DOWNLOAD,
  TABLE_ROWS_PER_PAGE,
  downloadURL,
} from "../../../utils/Constants";
import hasPermission from "../../../utils/hasMultiplePermission";
import PermissionsGate from "../../../utils/permissionGate";
import {
  assessmentExemptionToggle,
  getCourseAssessment,
  getCourseGradebook,
  lockGradeProgrammeGrademixToggle,
  lockGradeProgrammeToggle,
  updateCousesScore,
} from "../../../services/GradeSettingService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Swal from "sweetalert2";
import Str from "../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import GradeBookSaveBar from "../../common/GradeBookSaveBar";
import ExcelJS from "exceljs";
import { RenderLearningMethodGradebook } from "../../../utils/CommonGroupingItem";
import {
  getGradeMixAssessments,
  gradebookOfgradeMix,
  updateGradeMixScore,
} from "../../../services/GradeMixService";
import $ from "jquery";
import JSZip from "jszip";
import axios from "axios";
import { saveAs } from "file-saver";
import { formatBytes } from "../../../utils/commonFunction";

let options = [
  { label: "Red", value: "0" },
  { label: "Yellow", value: "49" },
  { label: "Green", value: "100" },
];

const stdIds = [];

function GradeMixGradeBook(props) {
  const { id, subId } = useParams();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState({ list: false, heading: false });
  const [assessmentHeader, setAssessmentHeader] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [percentageView, setPercentageView] = useState(false);
  const [paginationState, setPaginationState] = useState({
    from: 0,
    to: 0,
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
  });
  const [tableState, setTableState] = useState({ page: 1, limit: 10 });
  const [errorMessage, setErrorMessage] = useState("");
  const [tooltipValues, setTooltipValues] = useState();
  const [oldValue, setOldValue] = useState();
  const [tableWidth, setTableWidth] = useState(0);
  const [saveGradesLoading, setSaveGradesLoading] = useState(false);
  const [updateValueArr, setUpdateValueArr] = useState([]);
  const [isCourseEdited, setIsCourseEdited] = useState(0);
  const [isDownloadAll, setIsDownloadAll] = useState(false);
  const [isDownloadBriefs, setIsDownloadBriefs] = useState(false);
  const [studentID, setStudentID] = useState("");
  const [studentName, setStudentName] = useState("");
  const [finalTotal, setFinalTotal] = useState(0);
  const [zipfilename, setZipFileName] = useState("");
  const [FinalLoadedData, setFinalLoadedData] = useState(0);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const downloadRef = React.useRef(null);
  const [isGradeLocked, setIsGradeLocked] = useState(0);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["pgmixgbview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("swallActive", function (e) {
      checkbtnValue(e.detail);
    });

    return () => {
      window.removeEventListener("swallActive", function (e) {
        checkbtnValue(e.detail);
      });
    };
  }, []);

  const shouldBlockNavigation = updateValueArr.length;

  const handleNavigation = (location, page, states) => {
    if (shouldBlockNavigation) {
      if (Swal.isVisible()) {
        return false;
      }
      Swal.fire({
        title: "Warning",
        text: "You Have Unsaved Changes Left! Do you want to save changes",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No, Leave",
        confirmButtonText: "Yes, Save Changes",
      }).then((value) => {
        if (value.isConfirmed) {
          Swal.fire({
            title: "Please wait",
            text: "Saving changes...",
            allowOutsideClick: false,
            onBeforeOpen: () => {
              Swal.showLoading();
              const values = {
                id: subId,
                assessment: updateValueArr,
              };
              updateGradeMixScore(values)
                .then((res) => {
                  setUpdateValueArr([]);
                  Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Updated Successfully",
                  }).then(() => {
                    if (location) {
                      // window.location.assign(location.pathname); // Redirect to the desired location
                      history.push(location.pathname);
                    } else if (!location && page) {
                      if (page == "exempted") {
                        handleExemption(...states);
                      } else {
                        setTableState((prevState) => ({
                          ...prevState,
                          page:
                            page === "firstPage"
                              ? 1
                              : page === "prevPage"
                              ? paginationState.currentPage - 1
                              : page === "nextPage"
                              ? +paginationState.currentPage + 1
                              : paginationState.lastPage,
                        }));
                      }
                    }
                  });
                })
                .catch((err) => {
                  Swal.close(); // Close the "Saving changes..." popup if there was an error
                  Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: `${err?.response?.data?.data}`,
                  });
                });
            },
          });
        } else {
          setUpdateValueArr([]);
          if (location) {
            // window.location.assign(location.pathname); // Redirect to the desired location
            history.push(location.pathname);
          } else if (!location && page) {
            if (page == "exempted") {
              handleExemption(...states);
            } else {
              setTableState((prevState) => ({
                ...prevState,
                page:
                  page === "firstPage"
                    ? 1
                    : page === "prevPage"
                    ? paginationState.currentPage - 1
                    : page === "nextPage"
                    ? +paginationState.currentPage + 1
                    : paginationState.lastPage,
              }));
            }
          }
        }
      });

      return false; // Prevent the default navigation behavior
    } else if (page == "exempted") {
      handleExemption(...states);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (shouldBlockNavigation) {
        event.preventDefault();
        // event.returnValue = ''; // This is necessary for Chrome/Firefox
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldBlockNavigation]);

  useEffect(() => {
    setLoading((prevState) => ({ ...prevState, heading: true }));
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await getGradeMixAssessments(subId, dropdownSource.token);
        if(res.status == 200){
          setAssessmentHeader(res.data?.getAssessments);
          setLoading((prevState) => ({ ...prevState, heading: false }));
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading((prevState) => ({ ...prevState, heading: false }));
        }
      }
    };
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getGradeMixGradebook = async () => {
      setLoading((prevState) => ({ ...prevState, list: true }));
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const Values = {
        id: subId,
        key: "",
        sort: "DESC",
        exportStatus: "false",
        search: search,
        page: tableState.page,
        limit: tableState.limit,
      };
  
      try {
        const res = await gradebookOfgradeMix(Values, source.token);
        if (res.status === 200) {
          let pagination = res?.data?.pagination;
          setIsCourseEdited(res?.data?.details?.allUpdatedCourse);
          setUserData(
            res?.data?.result.map((item) => ({ ...item, isDis: true, isSupplementary: item.assessmentArr.some((supplement) => supplement.isSupplementaryForThisStudent) }))
          );
          setPaginationState({
            from: pagination?.from,
            to: pagination?.to,
            total: pagination?.total,
            perPage: pagination?.per_page,
            currentPage: pagination?.current_page,
            lastPage: pagination?.last_page,
          });
          setTooltipValues(res?.data?.details);
          setZipFileName(res?.data?.details?.name);
          setIsGradeLocked(res?.data.details.lock_grades)
          setLoading((prevState) => ({ ...prevState, list: false }));
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading((prevState) => ({ ...prevState, list: false }));
        }
      }
    };
  
    getGradeMixGradebook();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData, id, tableState, search]);

  useEffect(() => {
    let timeOut = setTimeout(() => {
      setSearch(debouncedTerm);
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [debouncedTerm]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  const resetFilter = () => {
    setDebouncedTerm("");
  };

  const handleExemption = (assId, stdId, val) => {
    let data = {
      id: assId,
      student_id: stdId,
      grant_exemption: val,
    };
    assessmentExemptionToggle(data).then((res) => {
      setUpdateData(!updateData);
      Swal.fire("Updated!", res.data.message, "success");
    });
  };

  // const handleUpdateMarks = (stdId, assId, gradeType, grade, maxMark) => {
  //   if (grade === oldValue) {
  //     setOldValue();
  //     return;
  //   }
  //   if (
  //     (grade > maxMark && gradeType == "3") ||
  //     (gradeType == "0" && grade > 100)
  //   ) {
  //     setErrorMessage("Sorry you can't put mark more then Total mark");
  //     return;
  //   }
  //   setErrorMessage("");

  //   // for showing loading effect update below state
  //   let updateArray = updateValueArr;

  //   updateArray.push({
  //     student_id: stdId,
  //     assessmnet_id: assId,
  //     grade_type: gradeType,
  //     grade: grade ? grade : 0,
  //   });
  //   let tempArr = userData;
  //   let isdisabledcheck = tempArr.map((item) => {
  //     if (item.id === stdId) {
  //       return { ...item, isDis: false };
  //     }
  //     return { ...item };
  //   });
  //   setUserData(isdisabledcheck);
  //   setUpdateValueArr(updateArray);
  // };

  const handleUpdateMarks = (stdId, assId, gradeType, grade, maxMark) => {
    if (grade === oldValue) {
      setOldValue();
      return;
    }
    if (
      (grade > maxMark && gradeType === "3") ||
      (gradeType === "0" && grade > maxMark)
    ) {
      setErrorMessage("Sorry you can't put mark more than Total mark");
      return;
    }
    setErrorMessage("");

    let updateArray = updateValueArr;
  
    const existingIndex = updateArray.findIndex(
      (item) => item.assessmnet_id === assId
    );
  
    if (existingIndex !== -1) {
      updateArray[existingIndex] = {
        ...updateArray[existingIndex],
        student_id: stdId,
        grade_type: gradeType,
        grade: grade ? grade : 0,
      };
    } else {
      updateArray.push({
        student_id: stdId,
        assessmnet_id: assId,
        grade_type: gradeType,
        grade: grade ? grade : 0,
      });
    }
  
    let tempArr = userData;
    let isdisabledcheck = tempArr.map((item) => {
      if (item.id === stdId) {
        return { ...item, isDis: false };
      }
      return { ...item };
    });
    setUserData(isdisabledcheck);
    setUpdateValueArr(updateArray);
  };
  

  const exportData = (fileType, fileName) => {
    const Values = {
      id: subId,
      key: "",
      sort: "DESC",
      exportStatus: "true",
      search: search,
      page: tableState.page,
      limit: tableState.limit,
    };
    gradebookOfgradeMix(Values)
      .then((res) => {
        const respData = res?.data?.result;
        const header = [
          "Student Name",
          "Student Number",
          "Assessment Name",
          "Assessment Grade",
          "Assessment Result",
          "Combined Grade",
          "Combined Result",
        ];
        const data = [];
        respData.map((item) => {
          let dataItem = {
            "Student Name": `${item.full_name}`,
            "Student Number": item?.student_num
              ? item?.student_num
              : item?.student_crm_id,
            "Assessment Name": "",
            "Assessment Grade": "",
            "Assessment Result": "",
            "Combined Grade": percentageView
              ? (item?.scoreRecieved && item?.scoreRecieved != "N/A") ||
                item?.scoreRecieved === 0
                ? typeof item?.scoreRecieved == "string" &&
                  item?.scoreRecieved.includes("%")
                  ? Math.round(
                      parseFloat(item?.scoreRecieved.replace("%", ""))
                    ).toString() + "%"
                  : typeof item?.scoreRecieved == "string" &&
                    !item?.scoreRecieved.includes("%")
                  ? Math.round(parseFloat(item?.scoreRecieved)).toString() + "%"
                  : item?.scoreRecieved
                  ? item?.scoreRecieved
                  : "N/A"
                : "N/A"
              : tooltipValues.grading_type != 3
              ? typeof item?.gradeText == "string" &&
                item?.gradeText.includes("%")
                ? Math.round(
                    parseFloat(item?.gradeText.replace("%", ""))
                  ).toString() + "%"
                : item?.gradeText
                ? item?.gradeText
                : "N/A"
              : item?.getAssessmentCalculate_sum_ass_mark +
                " / " +
                item.getAssessmentCalculate_sum_ass_total_mark,
            "Combined Result":
              item?.pass_status == "N/A"
                ? "N/A"
                : RenderQuizAttemptResultStatus(item?.pass_status).text,
          };
          data.push(dataItem);
          item.assessmentArr &&
            item.assessmentArr.map((row, index) => {
              let subDataItem = {
                "Student Name": "",
                "Student Number": "",
                "Assessment Name": assessmentHeader[index]?.name,
                "Assessment Grade": row?.grant_exemption
                  ? "Exempted"
                  : percentageView
                  ? row?.ass_percentage + "%"
                  : row?.grading_type == "0"
                  ? row?.ass_percentage + "%"
                  : row?.grading_type == "3"
                  ? `${row?.ass_mark}/${row?.ass_total_mark}`
                  : row?.gradeText,
                "Assessment Result":
                  row?.assessmentMarkingStatus?.toString() !==
                    "NOT SUBMITTED" && row.ass_total_mark
                    ? RenderQuizAttemptResultStatus(row.pass_status).text
                    : "N/A",
                "Combined Grade": "",
                "Combined Result": "",
              };
              data.push(subDataItem);
            });
        });
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, { header });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Student Name"],
              row["Student Number"],
              row["Assessment Name"],
              row["Assessment Grade"],
              row["Assessment result"],
              row["Combined Grade"],
              row["Combined Result"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => console.error(err));
  };

  const exportXlsxData = async (fileName, courseName) => {
    try {
      const Values = {
        id: subId,
        key: "",
        sort: "DESC",
        exportStatus: "true",
        search: search,
        page: tableState.page,
        limit: tableState.limit,
      };

      const res = await gradebookOfgradeMix(Values);
      const respData = res?.data?.result;
      // let learning_method = res?.data?.details?.type
      let SoR_Name = res?.data?.details?.sor_name;
      const header = [
        "Name",
        "Surname",
        "ID Number",
        "Student Number",
        // "Study Method",
        "SOR Name",
        ...assessmentHeader.map((assessment) => assessment?.name),
        "Final Mark",
      ];

      const data = respData.flatMap((item) => {
        const rowData = [
          item.first_name,
          item.last_name,
          item?.number ? item?.number : " ",
          item?.student_crm_id
            ? item?.student_crm_id
            : item?.student_num
            ? item?.student_num
            : " ",
          // learning_method ? RenderLearningMethodGradebook(learning_method).text : " ",
          SoR_Name ? SoR_Name : " ",
          ...assessmentHeader.map((assessment) => {
            const assessmentData = item.assessmentArr.find(
              (row) => row.assessment_id === assessment?.assignment_id
            );
            if (assessmentData) {
              return assessmentData?.grant_exemption
                ? "Exempted"
                : percentageView
                ? assessmentData?.ass_percentage + "%"
                : assessmentData?.grading_type == "0"
                ? assessmentData?.ass_percentage + "%"
                : assessmentData?.grading_type == "3"
                ? `${assessmentData?.ass_mark}/${assessmentData?.ass_total_mark}`
                : assessmentData?.gradeText;
            }
            return "";
          }),
          percentageView
            ? (item?.scoreRecieved && item?.scoreRecieved != "N/A") ||
              item?.scoreRecieved === 0
              ? typeof item?.scoreRecieved == "string" &&
                item?.scoreRecieved.includes("%")
                ? Math.round(
                    parseFloat(item?.scoreRecieved.replace("%", ""))
                  ).toString() + "%"
                : typeof item?.scoreRecieved == "string" &&
                  !item?.scoreRecieved.includes("%")
                ? Math.round(parseFloat(item?.scoreRecieved)).toString() + "%"
                : item?.scoreRecieved
                ? item?.scoreRecieved
                : "N/A"
              : "N/A"
            : tooltipValues.grading_type != 3
            ? typeof item?.gradeText == "string" &&
              item?.gradeText.includes("%")
              ? Math.round(
                  parseFloat(item?.gradeText.replace("%", ""))
                ).toString() + "%"
              : item?.gradeText
              ? item?.gradeText
              : "N/A"
            : item?.getAssessmentCalculate_sum_ass_mark +
              " / " +
              item.getAssessmentCalculate_sum_ass_total_mark,
        ];
        return [rowData];
      });

      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet("Excel Data");

      ws.views = [
        {
          state: "frozen",
          ySplit: 1,
          xSplit: 1,
        },
      ];
      ws.properties.showGridLines = true;

      const columnStyles = [];
      header.forEach((col) => {
        const style = {};
        if (
          [
            "Name",
            "Surname",
            "ID Number",
            "Student Number",
            "SOR Name",
          ].includes(col)
        ) {
          style.font = { bold: true };
          style.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFDBDBDB" }, // Light gray background color
          };
          style.border = {
            top: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
            right: { style: "thin", color: { argb: "FF000000" } },
          };
        } else if (col === "Final Mark") {
          style.font = { color: { argb: "FFFFFF" } };
          style.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF7030A0" }, // Purple background color
          };
          style.border = {
            top: { style: "thin", color: { argb: "FF000000" } }, // Black top border
            bottom: { style: "thin", color: { argb: "FF000000" } }, // Black bottom border
            left: { style: "thin", color: { argb: "FF000000" } }, // Black left border
            right: { style: "thin", color: { argb: "FF000000" } }, // Black right border
          };
        } else {
          style.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFDBDBDB" }, // Light gray background color
          };
          style.border = {
            top: { style: "thin", color: { argb: "FF000000" } },
            bottom: { style: "thin", color: { argb: "FF000000" } },
            left: { style: "thin", color: { argb: "FF000000" } },
            right: { style: "thin", color: { argb: "FF000000" } },
          };
        }
        columnStyles.push(style);
      });
      const staticRow = ws.addRow([courseName, "", "", "", ""]);
      staticRow.height = 16;
      staticRow.getCell(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF66CCFF" },
      };
      staticRow.getCell(1).font = { bold: true };
      staticRow.getCell(1).alignment = {
        horizontal: "center",
        vertical: "bottom",
      };
      staticRow.getCell(1).border = {};
      staticRow.getCell(5).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF203764" },
      };

      ws.mergeCells(`A${staticRow.number}:D${staticRow.number}`);

      const headerRow = ws.addRow(header);
      headerRow.eachCell((cell, colNumber) => {
        if (columnStyles[colNumber - 1].font) {
          cell.font = columnStyles[colNumber - 1].font;
        }
        cell.fill = columnStyles[colNumber - 1].fill;
        cell.border = columnStyles[colNumber - 1].border;
      });

      data.forEach((row) => {
        const dataRow = ws.addRow(row);
        dataRow.eachCell((cell, colNumber) => {
          cell.border = columnStyles[colNumber - 1].border;
        });
      });

      const buffer = await wb.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `${fileName}.xlsx`;
      a.click();

      Swal.close();
    } catch (err) {
      console.log(err);
    }
  };

  const slider = document.querySelector(".table-book-mid");

  useEffect(() => {
    if (slider) {
      let mouseDown = false;
      let startX, scrollLeft;

      let startDragging = function (e) {
        mouseDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      };
      let stopDragging = function (event) {
        mouseDown = false;
      };

      slider.addEventListener("mousemove", (e) => {
        e.preventDefault();
        if (!mouseDown) {
          return;
        }
        const x = e.pageX - slider.offsetLeft;
        const scroll = x - startX;
        slider.scrollLeft = scrollLeft - scroll;
      });

      // Add the event listeners
      slider.addEventListener("mousedown", startDragging, false);
      slider.addEventListener("mouseup", stopDragging, false);
      slider.addEventListener("mouseleave", stopDragging, false);
    }
  }, [slider]);

  const handleResize = () => {
    let pageWidth = document.querySelector(
      ".my-tickets-info-list"
    )?.offsetWidth;
    setTableWidth(pageWidth);
  };

  useEffect(() => {
    var resizeRequested = false;
    handleResize();
    window.addEventListener(
      "resize",
      function () {
        if (!resizeRequested) {
          resizeRequested = true;
          window.requestAnimationFrame(function () {
            setTableWidth(
              document.querySelector(".my-tickets-info-list")?.clientWidth
            );
            resizeRequested = false;
          });
        }
      },
      100
    );
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleWidth = () => {
    const leftTableWidth =
      document.querySelector(".table-book-left").clientWidth;
    const midTableWidth = document.querySelector(".grade-acc-in").clientWidth;
    const rightTableWidth =
      document.querySelector(".table-book-right").clientWidth;
    let maxAllowedWidth = tableWidth - leftTableWidth - rightTableWidth;
    let lastTableClass =
      document.querySelector(".table-book-right").firstChild.className;
    let firstTableClass =
      document.querySelector(".table-book-left").firstChild.className;
    if (maxAllowedWidth < midTableWidth) {
      document.querySelector(".table-book-mid").style.overflowY = "auto";
      document.querySelector(".table-book-right").style.maxWidth =
        "fit-content";
      if (!lastTableClass.includes("border-shadow-custom-table")) {
        document.querySelector(
          ".table-book-right"
        ).firstChild.className = `${lastTableClass} border-shadow-custom-table`;
      }
      if (!firstTableClass.includes("border-shadow-custom-table")) {
        document.querySelector(
          ".table-book-left"
        ).firstChild.className = `${firstTableClass} border-shadow-custom-table`;
      }
    } else {
      document.querySelector(".table-book-mid").style.overflowY = "unset";
      document.querySelector(".table-book-right").style.maxWidth = "unset";
      document.querySelector(".table-book-right").firstChild.className =
        lastTableClass.replaceAll("border-shadow-custom-table", "");
      document.querySelector(".table-book-left").firstChild.className =
        firstTableClass.replaceAll("border-shadow-custom-table", "");
    }
  };
  let firstLoaded = true;
  useEffect(() => {
    if (userData?.length && tableWidth) {
      if (firstLoaded) {
        setTimeout(() => {
          handleWidth();
          firstLoaded = false;
        }, 500);
      } else {
        handleWidth();
      }
    }
  }, [userData, tableWidth]);

  const handleSaveGrades = (stdID) => {
    Swal.fire({
      title: "Updating",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    setSaveGradesLoading(true);
    const values = {
      id: subId,
      assessment: updateValueArr,
      student_id: stdID,
    };
    if (!stdID) {
      delete values.student_id;
    }
    updateGradeMixScore(values)
      .then((res) => {
        setSaveGradesLoading(false);
        if (stdID) {
          const filteredArr = updateValueArr.filter(
            (item) => item.student_id !== stdID
          );
          setUpdateValueArr(filteredArr);
        } else {
          setUpdateValueArr([]);
        }
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated Successfully",
        }).then((res) => setUpdateData(!updateData));
      })
      .catch((err) => {
        setSaveGradesLoading(false);
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      });
  };

  const handleSaveSingleGrade = (stdId) => {
    let otherIds = stdIds.filter((item) => item !== stdId);
    if (otherIds.length) {
      Swal.fire({
        title: "Are You Sure?",
        text: "You have unsaved data in other students",
        icon: "warning",
        showCancelButton: false,
        showConfirmButton: false,
        html: `
          <div class="custom-swal-button">
            <p>You have unsaved data in other students!</p>
            <button class="swal2-confirm swal2-styled" onclick='triggerSwall({type: "saveAll", updatedArr: ${JSON.stringify(
              updateValueArr
            )}})' >Save All</button>
            <button class="swal2-confirm swal2-styled" onclick='triggerSwall({type: "save", stdId: ${stdId}, updatedArr: ${JSON.stringify(
          updateValueArr
        )}})'>Save</button>
            <button class="swal2-cancel swal2-styled" onclick="triggerSwall({type: 'cancel'})" style="background-color: rgb(221, 51, 51);">Cancel</button>
          </div>`,
      });
    } else {
      handleSaveGrades(stdId, updateValueArr);
    }
  };

  const checkbtnValue = (values) => {
    if (values.type === "saveAll") {
      handleSaveGrades(undefined, values.updatedArr);
    } else if (values.type === "save") {
      handleSaveGrades(values.stdId, values.updatedArr);
    }
    Swal.close();
  };

  const handleDownloadAllToggle = () => {
    setIsDownloadAll(!isDownloadAll);
  };

  const handleDownloadBriefsToggle = () => {
    setIsDownloadBriefs(!isDownloadBriefs);
  };

  const handleStudentDownload = async (rowData, st_data, details) => {
    var zip = new JSZip();
    var zipVal = {};
    let totalSize = 0;
    let data = rowData;
    if (Array.isArray(data) && data.length) {
      let sizeArr = Array(data.length).fill(0);
      let myPromise = Promise.all(
        data.map(async (row, index) => {
          if (row.path && row.path != null && row.path != "") {
            const size = parseFloat(row?.size);
            if (!isNaN(size)) {
              totalSize += size;
              setFinalTotal(totalSize.toFixed(1));
            }
            const fileExt = row.path.split("/").reverse()[0];
            const fileURL =
              downloadURL + 
              `${
                row.path.includes("s3.af-south-1.amazonaws.com")
                  ? ""
                  : IMAGE_URL.replaceAll("http:", "https:") + "/"
              }${encodeURI(
                row.path
                  .replaceAll("/home/myaie/public_html/", "")
                  .replace("public/", "")
              )}`;
            let loadedFromArr = 0;
            try {
              const resp = await axios({
                url: fileURL,
                method: "get",
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                  const { loaded, total } = progressEvent;
                  sizeArr[index] = loaded;
                  loadedFromArr = 0;
                  sizeArr.map((item) => (loadedFromArr = loadedFromArr + item));
                  if (totalSize > loadedFromArr) {
                    setFinalLoadedData(loadedFromArr);
                  } else {
                    setFinalLoadedData(totalSize);
                  }
                },
              });

              zip.file(`${fileExt}`, resp.data, { binary: true });
            } catch (err) {
              console.log(err, "===>");
            }
          }
          zipVal = zip;
        })
      );

      myPromise.then(() => {
        zipVal
          .generateAsync({ type: "blob" })
          .then(function (content) {
            setTimeout(() => {
              $("#downloadAssessment").modal("hide");
              setFinalLoadedData(0);
              setFinalTotal(0);
            }, 1000);
            saveAs(
              content,
              `${st_data.assessment_name}_${details.full_name}_${details.student_crm_id}` +
                ".zip"
            );
          })
          .catch((errr) => {
            console.log(errr);
          });
      });
    } else {
      if (data && data != null && data != "") {
        const fileExt = data.path.split("/").reverse()[0];
        const fileURL =
          downloadURL +
          `${
            data.path.includes("s3.af-south-1.amazonaws.com")
              ? ""
              : IMAGE_URL.replaceAll("http:", "https:") + "/"
          }${encodeURI(
            data.path
              .replaceAll("/home/myaie/public_html/", "")
              .replace("public/", "")
          )}`;
        try {
          const resp = await axios({
            url: fileURL,
            method: "get",
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const size = parseFloat(data?.size);
              if (!isNaN(size)) {
                totalSize = size;
              }
              setFinalTotal(totalSize.toFixed(1));
              if (totalSize > loaded) {
                setFinalLoadedData(loaded);
              } else {
                setFinalLoadedData(totalSize);
              }
            },
          });
          const href = URL.createObjectURL(resp.data);
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", fileExt); //or any other extension
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            $("#downloadAssessment").modal("hide");
            setFinalLoadedData(0);
            setFinalTotal(0);
          }, 1000);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  function calculateFileSize(data, maxSizePerArray) {
    let totalSize = 0;
    let urlArray = [];
    let checkTotal = 0;
    let urlArrays = [[]]; // Start with one empty array
    let currentArrayIndex = 0;
    let currentArraySize = 0;

    function addToCurrentArray(path, size, fileName, currentSize, assessment_name, id) {
      const urlInfo = {
        path: path,
        size: size,
        loaded_Size: 0,
        flname: fileName,
        assessment_name: assessment_name,
        id: id
      };
      urlArrays[currentArrayIndex].push(urlInfo);
      urlArray.push(urlInfo);
      currentArraySize += currentSize;
    }

    for (const item of data) {
      if(isDownloadAll){
      if (item.multipleFiles && item.multipleFiles.length) {
        for (const file of item.multipleFiles) {
          checkTotal++;
          const size = parseFloat(file.size);
          if (!isNaN(size)) {
            totalSize += size;
            if (currentArraySize + size <= maxSizePerArray) {
              addToCurrentArray(file.path, file.size, item.flname, size, item.name, item.id);
            } else {
              if (urlArrays.length > 1) {
                addToCurrentArray(file.path, file.size, item.flname, size, item.name, item.id);
              } else {
                currentArrayIndex++;
                urlArrays.push([]);
                currentArraySize = 0;
                addToCurrentArray(file.path, file.size, item.flname, size, item.name, item.id);
              }
            }
          }
        }
      }
      }
      
      if(isDownloadBriefs || isDownloadAll){
        if (item.briefFiles && item.briefFiles.length) {
          for (const file of item.briefFiles) {
            checkTotal++;
            const size = parseFloat(file.size);
            if (!isNaN(size)) {
              totalSize += size;
              if (currentArraySize + size <= maxSizePerArray) {
                addToCurrentArray(file.path, file.size, item.flname, size, item.name, item.id);
              } else {
                if (urlArrays.length > 1) {
                  addToCurrentArray(file.path, file.size, item.flname, size, item.name, item.id);
                } else {
                  currentArrayIndex++;
                  urlArrays.push([]);
                  currentArraySize = 0;
                  addToCurrentArray(file.path, file.size, item.flname, size, item.name, item.id);
                }
              }
            }
          }
        }
      }
      if (item.quizSubmitedDocs && item.quizSubmitedDocs.length) {
        for (const file of item.quizSubmitedDocs) {
          checkTotal++;
          const size = 0;
          if (!isNaN(size)) {
            urlArrays[currentArrayIndex].push({
              path: file.docs,
              size: 0,
              loaded_Size: 0,
              flname: item.flname,
            });
          }
        }
      }
    }
    return {
      totalSize,
      urlArray,
      checkTotal,
      urlArrays,
    };
  }

  const MAX_CONCURRENT_DOWNLOADS = 5; // Limit the number of parallel downloads

  const handleDownloadAssessment = async () => {
    let files =
      userData.filter((item) => item?.id == studentID).length &&
      userData
        .filter((item) => item?.id == studentID)[0]
        .assessmentArr.map((ass, index) => (
          {
          id: ass.assessment_id,
          name: ass.assessment_name,
          multipleFiles: ass.multipleFiles,
          briefFiles: ass.briefFiles
          }
        ));

    try {
      let data = files;
      let demoData = files;
      const header = ["ROW", "SID", "CID", "STUDENT", "SURNAME", "ASSIGNMENT", "ASSESSMENT NAME", "HIGHEST MARK ACHIEVABLE", "MARKED ACHIEVED"];

      data = data?.map((row) => ({
        ...row,
        ROW: row?.id ? row?.id : "",
        CID: row.intake ? row.intake : "",
        SID: row?.student ? row?.student : "",
        STUDENT: row?.first_name ? row?.first_name : "",
        SURNAME: row?.last_name ? row?.last_name : "",
        ASSIGNMENT: row?.crm_id ? row?.crm_id : "",
        // TOTALMARK: row?.assignment ? row?.assignment : "-",
        "ASSESSMENT NAME": zipfilename ? zipfilename.trim() : "",
        "HIGHEST MARK ACHIEVABLE": row.ass_total_mark>=0 ? row.ass_total_mark : "",
        // ATTACHMENT: row?.ass_weighting.toString() ? row?.ass_weighting.toString() == "0" ? "0" : row?.ass_weighting : "-",
        // "MARKED ACHIEVED": row.ass_mark ?  row.ass_mark : "-",
        // REMARK: row.path || row.return ? `${row.path ? row.path : ""} ${row.return ? row.return : ""}` : "",
        "MARKED ACHIEVED" : (row.ass_mark != null && row.ass_mark.toString() && row?.grade != 1 ) ? row.ass_mark
        : (row?.mark != null && row?.mark.toString() && row?.grade == 1 && row?.mark.toString().replace(/%\s?/g, '') >= 49)  ? "Green"
        : (row?.mark != null && row?.mark.toString() && row?.grade == 1 && row?.mark.toString().replace(/%\s?/g, '') <= 49)
        && (row?.mark.toString() && row?.grade == 1 && row?.mark.toString().replace(/%\s?/g, '') >= 0) ? "Yellow"
        : row?.mark != null && row?.mark.toString() && row?.grade == 1 && row?.mark.toString().replace(/%\s?/g, '') <= 0 ? "Red" :  "",
      }));

      const maxSizePerArray = 1536 * 1024 * 1024;
      let fileDetails = calculateFileSize(demoData, maxSizePerArray);
      setFinalTotal(fileDetails.totalSize.toFixed(1))
      let loadedArr = JSON.parse(JSON.stringify(fileDetails.urlArrays))

        let zipPromises = [];
        for (let i = 0; i < fileDetails.urlArrays.length; i += MAX_CONCURRENT_DOWNLOADS) {
            const batch = fileDetails.urlArrays.slice(i, i + MAX_CONCURRENT_DOWNLOADS);
            const batchPromises = batch.map(async (urlArray, index) => {
                let zip = new JSZip();
                let sizeArr = Array(urlArray.length).fill(0);
                let loadedBytes = 0;

                let promise = Promise.all(
                    urlArray.map(async (row, rowIndex) => {
                      const fileExt = (row.path.split("/").reverse()[0]).trim().replace(/[\t\r\n]/g, '');
                      
                      let fileURL = 
                      downloadURL + 
                      `${row.path.includes("s3.af-south-1.amazonaws.com") ? "" : IMAGE_URL.replaceAll("http:","https:") + "/"}${row.path.replaceAll(
                        "/home/myaie/public_html/", "").replace("public/", "")}`;
                        console.log(fileExt,fileURL)
                      // if(isHybrid===true){
                      //   fileURL = downloadURL + STUDENT_FILE_DOWNLOAD + `/${row.path.replaceAll(
                      //     "/home/myaie/public_html/", "").replace("public/", "")}`;
                      // }
                        let loadedFromArr = 0
                        try {
                          const resp = await axios({
                            url: fileURL,
                            method: 'get',
                            responseType: 'blob',
                            onDownloadProgress: (progressEvent) => {
                              const { loaded, total } = progressEvent;
                              loadedArr[index][rowIndex] = loaded
                              loadedFromArr = 0
                              loadedArr.map(item => item.map(subItem => {
                                if (typeof subItem === "number") {
                                  loadedFromArr = loadedFromArr + subItem
                                }
                              }))
                              loadedBytes = loaded;
                              if(fileDetails.totalSize > loadedFromArr) {
                                setFinalLoadedData(loadedFromArr)
                              }else{
                                setFinalLoadedData(fileDetails.totalSize)
                              }
                            }
                          });
                            zip.folder(`${`${row.assessment_name}(${row.id})` + "_" + zipfilename.trim().replace(/[\t\r\n]/g, '')}`).file(`${fileExt}`, resp.data, { binary: true });
                        } catch (err) {
                          console.log(err,"zip generation error")
                        }
                    })
                );

                promise = promise.then(() => {
                  const textFileData = data.filter((item) => item.dataAns);
                  if(textFileData.length){
                    textFileData.map((row)=>{
                      let fileContent = ""
                      if(Array.isArray(row.dataAns) && row.dataAns.length){
                        row.dataAns.map((element)=>{
                          fileContent+= "Question : " +  element.questionName + "Answer : " +  element.UserAnswer + "\n" + "\n";
                        })
                      }
                      if(fileContent!=""){
                        zip.folder(`${row.flname + "_" + zipfilename.trim().replace(/[\t\r\n]/g, '')}`).file("question.txt", fileContent);
                      }
                    })
                  }
                  // const csvString = Papa.unparse({ fields: header, data });
                  // zip.file(`${zipfilename + "_" + index + ".csv"}`, csvString);
                    return zip.generateAsync({ type: "blob" });
                });

                try {
                    const zipBlob = await promise;
                    const zipFileName = `${studentName}_${zipfilename}_${i / MAX_CONCURRENT_DOWNLOADS + index + 1}_.zip`;
                    saveAs(zipBlob, zipFileName);
                } catch (err) {
                    console.error("Error generating or downloading zip files:", err);
                }
            });
            zipPromises.push(Promise.all(batchPromises));
        }

        await Promise.all(zipPromises).then((res) => {
          $("#downloadAssessment").modal("hide")
          setFinalLoadedData(0)
          setFinalTotal(0)
          });

    } catch (error) {
        console.error("Error generating or downloading zip files:", error);
        $("#downloadAssessment").modal("hide")
        setStudentID("")
        setStudentName("")
    } finally {
        setFinalLoadedData(0);
        setFinalTotal(0);
        setStudentID("")
        setStudentName("")
        setIsDownloadAll(false);
        setIsDownloadBriefs(false);
        setTimeout(() => {
          $("#downloadSoRModal").modal("hide");
          $("#downloadAssessment").modal("hide");
        }, 1000);
    }
  };

  return (
    <>
      <Prompt when={!!shouldBlockNavigation} message={handleNavigation} />
      <div className="my-tickets-info-list Tickets-main-wrap course-gradebook grademix-gradebook">
        <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={debouncedTerm}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      title="Reset"
                      onClick={resetFilter}
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportXlsxData(
                          `${tooltipValues?.name} Grade Mix`,
                          // props.courseName
                          `${tooltipValues?.name} Grade Mix`
                        );
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", `Grade Book`);
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", `Grade Book`);
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <div>
                    <PermissionsGate
                      scopes={["pgmixgblgedit"]}
                      errorProps={{ disabled: true }}
                    >
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          lockGradeProgrammeGrademixToggle(id, isGradeLocked ? 0 : 1, subId).then(res => {
                            Swal.fire({
                              icon: "success",
                              title: "Success",
                              text: res.data.message,
                            });
                            setUpdateData(!updateData)
                          })
                            .catch(err => {
                              console.error("error :", err);
                              Swal.fire({
                                icon: "error",
                                title: "Error",
                                text: err.response.data.message,
                              });
                            })
                        }}
                        title={isGradeLocked ? "Unlock Grades" : "Lock Grades"}
                      >
                        {isGradeLocked ? <><i className="fal fa-unlock"></i>Unlock Grades</> : <><i className="fal fa-lock"></i>Lock Grades</>}
                      </button>
                    </PermissionsGate>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading.heading || loading.list ? (
            <SkeletonTicketList />
          ) : userData?.length ? (
            <div className="table-book-responsive">
              <div className="table-book row m-0">
                <div className={"table-book-left "}>
                  <table
                    className={
                      "table-book-main table-book-grademix " +
                      (assessmentHeader?.length < 9
                        ? ""
                        : "border-shadow-custom-table")
                    }
                  >
                    <thead>
                      <tr>
                        <th>Student</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="assigned-title-block-new">
                              <div className="assigned-title-itm">
                                <span
                                  className={
                                    "assigned-title-blk  name-icon cat-dark-red " +
                                    ColorRender(item.role_name)
                                  }
                                >
                                  {item.picture_me ? (
                                    <img
                                      src={`${IMAGE_URL}/${item.picture_me
                                        .replace("public/", "")
                                        .replaceAll(
                                          "/home/myaie/public_html/",
                                          ""
                                        )}`}
                                      alt="AIE"
                                    />
                                  ) : (
                                    InitialRender([
                                      item.full_name.split(" ")[0],
                                      item.full_name.split(" ").length
                                        ? item.full_name.split(" ")[1]
                                        : "",
                                    ])
                                  )}
                                  <span
                                    className={`profile-box-2-status ${
                                      item.activity_status
                                        ? item.activity_status.toLowerCase() ==
                                          "online"
                                          ? "Online"
                                          : item.activity_status.toLowerCase() ==
                                            "away"
                                          ? "Away"
                                          : "Offline"
                                        : "Offline"
                                    }`}
                                  >
                                    <i className="fas fa-circle"></i>
                                  </span>
                                  <Hover
                                    firstName={item.full_name.split(" ")[0]}
                                    lastName={
                                      item.full_name.split(" ").length
                                        ? item.full_name.split(" ")[1]
                                        : ""
                                    }
                                    photo={item.picture_me}
                                    email={item.email}
                                    mobile={item.mobile}
                                    status={item.status}
                                    activity_status={item.activity_status}
                                    showNumber={true}
                                    number={item.student_crm_id}
                                    right={true}
                                  />
                                </span>
                                <PermissionsGate
                                  scopes={["casvview"]}
                                  RenderError={() => (
                                    <p title={item.full_name}>
                                      {item.full_name}
                                    </p>
                                  )}
                                >
                                  <Link
                                    className="as-text-blue curser "
                                    to={`/studentAdministration/students/open/${item.id}/general`}
                                  >
                                    <span
                                      className="font-weight-bold"
                                      title={item.full_name}
                                    >
                                      {item.full_name}
                                    </span>
                                  </Link>
                                </PermissionsGate>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div
                  className={
                    "table-book-mid " +
                    (assessmentHeader?.length < 9
                      ? "less-items"
                      : "overflow-auto")
                  }
                >
                  <div className="grade-acc">
                    <div className={"grade-acc-in active"}>
                      <table className="table-book-main table-book-grademix table-book-main-contetn grademix-gradebook">
                        <thead>
                          <tr>
                            {assessmentHeader?.length
                              ? assessmentHeader.map((item, index) => (
                                  <th key={index}>
                                    <div className="tb-book-tit">
                                      <Link
                                        className="curser"
                                        to={`/courseAdministration/coursesdetails/${item?.intake}/assessments/open/assigned/Details/${item?.assignment_id}`}
                                        title={item?.name}
                                      >
                                        <p>
                                          {TrimText(item?.name, 25)}
                                          {userData[0]?.assessmentArr[index]
                                            ?.pass_required ? (
                                            <span
                                              href="#"
                                              className=" grade-info-red"
                                              data-toggle="tooltip"
                                              title={"Pass Required"}
                                            >
                                              <i className="fal fa-exclamation-circle"></i>
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      </Link>
                                    </div>
                                  </th>
                                ))
                              : ""}
                          </tr>
                        </thead>
                        <tbody>
                          {userData.map((item, i) => (
                            <tr key={i}>
                              {item.assessmentArr.map((ass, index) => {
                                return (
                                  <td key={index} className={(ass?.ass_type == 8 && !ass.isSupplementaryForThisStudent) ? "supplement-td" : ""}>
                                    {(ass?.ass_type == 8 && !ass.isSupplementaryForThisStudent) ? (
                                      <div className="as-widget-text mt-2 supplement-grademix-margin">N/A</div>
                                    ) : (ass?.ass_type == 9 && !ass.isAssignedSickStudent) ? (
                                      <div className={`as-widget-text mt-2 supplement-grademix-margin`}>N/A</div>
                                    ) : (
                                      <div className={`d-flex ${ass.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
                                        <div className="marks-number-info">
                                          <div className="marks-input-text">
                                            {ass.grading_type === 0 ? (
                                              <div className="resultstatus_flex">
                                                <div className="form-icon-group status-select-form percentage-box">
                                                  <PermissionsGate
                                                    scopes={["pgmixgbedit"]}
                                                    errorProps={{
                                                      disabled: true,
                                                    }}
                                                  >
                                                    <input
                                                      className={
                                                        // ass.ass_percentage ==
                                                        // "0"
                                                        //   ? "input-red"
                                                        //   :
                                                         "percentage-input"
                                                      }
                                                      type="number"
                                                      // title={
                                                      //   ass.ass_percentage
                                                      // }
                                                      defaultValue={
                                                        ass.ass_mark
                                                      }
                                                      onBlur={(e) => {
                                                        stdIds.push(item.id);
                                                        handleUpdateMarks(
                                                          item.id,
                                                          ass.assessment_id,
                                                          0,
                                                          e?.target?.value,
                                                          ass.ass_total_mark
                                                        );
                                                      }}
                                                      disabled={
                                                        ass.lock_grades ==
                                                          "1" || ass.quiz_id || ass.isSickForThisStudent
                                                      }
                                                      onMouseUp={(e) =>
                                                        setOldValue(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </PermissionsGate>
                                                  {
                                                    <span className="ml-1">
                                                      {/* % */}
                                                      <span>/ {ass.ass_total_mark}</span>
                                                    </span>
                                                  }
                                                  <span className="percentage">{`| ${ass.ass_percentage}`}%</span>
                                                </div>
                                              </div>
                                            ) : ass.grading_type === 1 ? (
                                              <div className="form-icon-group marks-input-text">
                                                <PermissionsGate
                                                  scopes={["pgmixgbedit"]}
                                                  errorProps={{
                                                    disabled: true,
                                                  }}
                                                >
                                                  <select
                                                    className={
                                                      "mid-scroll-custom-table-select " +
                                                      // (ass.ass_percentage == "0"
                                                      //   ? "percentage-select-red"
                                                      //   :
                                                      ""
                                                      // )
                                                    }
                                                    defaultValue={
                                                      ass?.grade == 0
                                                        ? "0"
                                                        : ass?.grade > 0 &&
                                                          ass?.grade <= 49
                                                        ? "49"
                                                        : "100"
                                                    }
                                                    disabled={
                                                      ass.lock_grades == "1" ||
                                                      ass.quiz_id || ass.isSickForThisStudent
                                                    }
                                                    onChange={(e) => {
                                                      stdIds.push(item.id);
                                                      handleUpdateMarks(
                                                        item.id,
                                                        ass.assessment_id,
                                                        1,
                                                        e?.target?.value,
                                                        ass.ass_total_mark
                                                      );
                                                    }}
                                                    onMouseUp={(e) =>
                                                      setOldValue(
                                                        e.target.value
                                                      )
                                                    }
                                                  >
                                                    {options?.length
                                                      ? options.map(
                                                          (opt, index) => (
                                                            <option
                                                              key={index}
                                                              value={opt.value}
                                                            >
                                                              {opt.label}
                                                            </option>
                                                          )
                                                        )
                                                      : ""}
                                                  </select>
                                                </PermissionsGate>
                                              </div>
                                            ) : ass.grading_type === 2 ? (
                                              <div className="form-icon-group marks-input-text">
                                                <PermissionsGate
                                                  scopes={["pgmixgbedit"]}
                                                  errorProps={{
                                                    disabled: true,
                                                  }}
                                                >
                                                  <select
                                                    className={
                                                      "mid-scroll-custom-table-select " +
                                                      // (ass.ass_percentage == "0"
                                                      //   ? "percentage-select-red"
                                                      //   :
                                                      ""
                                                      // )
                                                    }
                                                    defaultValue={ass?.grade}
                                                    disabled={
                                                      ass.lock_grades == "1" ||
                                                      ass.quiz_id || ass.isSickForThisStudent
                                                    }
                                                    onChange={(e) => {
                                                      stdIds.push(item.id);
                                                      handleUpdateMarks(
                                                        item.id,
                                                        ass.assessment_id,
                                                        2,
                                                        e?.target?.value,
                                                        ass.ass_total_mark
                                                      );
                                                    }}
                                                    onMouseUp={(e) =>
                                                      setOldValue(
                                                        e.target.value
                                                      )
                                                    }
                                                  >
                                                    {ass?.letterGrades &&
                                                    ass?.letterGrades?.length
                                                      ? ass?.letterGrades.map(
                                                          (opt, index) => (
                                                            <option
                                                              key={index}
                                                              value={opt.value}
                                                            >
                                                              {opt.label}
                                                            </option>
                                                          )
                                                        )
                                                      : ""}
                                                  </select>
                                                </PermissionsGate>
                                              </div>
                                            ) : (
                                              <div className="resultstatus_flex">
                                                <div className="form-icon-group status-select-form">
                                                  <PermissionsGate
                                                    scopes={["pgmixgbedit"]}
                                                    errorProps={{
                                                      disabled: true,
                                                    }}
                                                  >
                                                    <input
                                                      className={
                                                        // ass.ass_mark == "0"
                                                        //   ? "input-red"
                                                        //   :
                                                        ""
                                                      }
                                                      type="number"
                                                      disabled={
                                                        ass.lock_grades ==
                                                          "1" || ass.quiz_id || ass.isSickForThisStudent
                                                      }
                                                      defaultValue={
                                                        ass.ass_mark
                                                      }
                                                      onBlur={(e) => {
                                                        stdIds.push(item.id);
                                                        handleUpdateMarks(
                                                          item.id,
                                                          ass.assessment_id,
                                                          3,
                                                          e?.target?.value,
                                                          ass.ass_total_mark
                                                        );
                                                      }}
                                                      onMouseUp={(e) =>
                                                        setOldValue(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </PermissionsGate>
                                                  {
                                                    <span className="min-w-40">
                                                      / {ass.ass_total_mark}
                                                    </span>
                                                  }
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        {(ass?.multipleFiles &&
                                        ass?.multipleFiles.length > 0) && (
                                          <span
                                            className={`d-flex align-items-center as-text-blue curser mr-1 ${ass.isSickForThisStudent ? "supplementry-pointer-events" : ""}`}
                                            title="Download Assessments"
                                            data-toggle="modal"
                                            data-target="#downloadAssessment"
                                            onClick={() =>
                                              handleStudentDownload(
                                                ass?.multipleFiles?.length &&
                                                  ass.multipleFiles,
                                                ass,
                                                item
                                              )
                                            }
                                          >
                                            Download Submissions
                                          </span>
                                        ) 
                                      }
                                      </div>
                                    ) 
                                    }
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "table-book-right " +
                    (assessmentHeader?.length < 9
                      ? "many-items"
                      : "max-width-fit-content")
                  }
                >
                  <table
                    className={
                      "table-book-main table-book-grademix text-center " +
                      (assessmentHeader?.length < 9
                        ? ""
                        : "border-shadow-custom-table")
                    }
                  >
                    <thead>
                      <tr>
                        <th className="second-last-cell">
                          <span className="right-border-grade"></span>
                          <div className="tb-book-tit">
                            Combined Grade
                            <span
                              className=" grade-info-red"
                              data-toggle="tooltip"
                              title={
                                tooltipValues?.passReqAssessment?.length
                                  ? `Required pass mark on ${tooltipValues?.passReqAssessment?.toString()} and a final grade above ${
                                      tooltipValues?.requiredPassMarkforGradeMix
                                    }`
                                  : `Requires a final grade above ${tooltipValues?.requiredPassMarkforGradeMix}`
                              }
                            >
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div className="tb-book-tit text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <span
                              className={
                                "right-border-grade " +
                                (userData.length - 1 == index
                                  ? "last-cell-border"
                                  : "")
                              }
                            ></span>
                            {item?.pass_status == "N/A" &&
                            (item?.scoreRecieved != "N/A" ||
                              !item?.gradeText) ? (
                              <div className="as-widget-outer text-center">
                                N/A
                              </div>
                            ) : (
                              <div className="as-widget-outer text-center">
                                   {item?.pass_status == "N/A"
                                  ? "N/A"
                                  : RenderQuizAttemptResultStatus(
                                      item?.pass_status
                                    ).html}
                                <div className="as-widget-text">
                                  {percentageView
                                    ? (item?.scoreRecieved &&
                                        item?.scoreRecieved != "N/A") ||
                                      item?.scoreRecieved === 0
                                      ? typeof item?.scoreRecieved ==
                                          "string" &&
                                        item?.scoreRecieved.includes("%")
                                        ? Math.round(
                                            parseFloat(
                                              item?.scoreRecieved.replace(
                                                "%",
                                                ""
                                              )
                                            )
                                          ).toString() + "%"
                                        : typeof item?.scoreRecieved ==
                                            "string" &&
                                          !item?.scoreRecieved.includes("%")
                                        ? Math.round(
                                            parseFloat(item?.scoreRecieved)
                                          ).toString() + "%"
                                        : typeof item?.scoreRecieved ===
                                          "number"
                                        ? item?.scoreRecieved + "%"
                                        : item?.scoreRecieved
                                        ? item?.scoreRecieved
                                        : "N/A"
                                      : "N/A"
                                    : tooltipValues.grading_type != 3
                                    ? typeof item?.gradeText == "string" &&
                                      item?.gradeText.includes("%")
                                      ? Math.round(
                                          parseFloat(
                                            item?.gradeText.replace("%", "")
                                          )
                                        ).toString() + "%"
                                      : typeof item?.gradeText === "number"
                                      ? item?.gradeText + "%"
                                      : item?.gradeText
                                      ? item?.gradeText
                                      : "N/A"
                                    : item?.getAssessmentCalculate_sum_ass_mark +
                                      " / " +
                                      item.getAssessmentCalculate_sum_ass_total_mark}
                                </div>
                                {/* <div></div> */}
                              </div>
                            )}
                          </td>
                          <td className="justify-content-end d-flex calendar-cart-item-inner">
                            <div className="assessment-08 btn-dropdown-grp">
                              <div className="as-buttons d-flex">
                                <PermissionsGate
                                  scopes={["casvview"]}
                                  RenderError={() => (
                                    <button
                                      disabled
                                      className="btn btn-primary rounded-circle"
                                      title="Download Assessments"
                                    >
                                      <i className="fal fa-download download-SoR-icon"></i>
                                    </button>
                                  )}
                                >
                                  <button
                                    className="btn btn-primary rounded-circle"
                                    data-toggle="modal"
                                    data-target="#downloadSoRModal"
                                    onClick={() => {setStudentID(item?.id);setStudentName(item?.full_name)}}
                                    // disabled={bulkPdfDownloading}
                                    title="Download Assessments"
                                  >
                                    <i className="fal fa-download download-SoR-icon"></i>
                                  </button>
                                </PermissionsGate>
                                <div className="dropdown btn-dropdown-item">
                                  <button
                                    className="btn btn-primary rounded-circle dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    title="More"
                                  >
                                    <i className="fal fa-ellipsis-h-alt"></i>
                                  </button>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <PermissionsGate
                                      scopes={["casvview"]}
                                      RenderError={() => (
                                        <button
                                          disabled
                                          className="btn btn-primary rounded-circle"
                                          title="Open"
                                        >
                                          <i className="fal fa-folder-open"></i>
                                        </button>
                                      )}
                                    >
                                      <Link
                                        className="btn btn-primary rounded-circle"
                                        title="Open"
                                        to={`/studentAdministration/students/open/${item.id}/general`}
                                        target="_blank"
                                      >
                                        <i className="fal fa-folder-open"></i>
                                      </Link>
                                    </PermissionsGate>
                                    <PermissionsGate
                                      scopes={["casvview"]}
                                      RenderError={() => (
                                        <button
                                          disabled
                                          className="btn btn-primary rounded-circle"
                                          title="Save Grades"
                                        >
                                          <i className="fal fa-save"></i>
                                        </button>
                                      )}
                                    >
                                      <button
                                        className="btn btn-primary rounded-circle"
                                        title="Save Grades"
                                        disabled={item?.isDis}
                                        onClick={
                                          () => handleSaveSingleGrade(item?.id)
                                          // handleSaveGrades(item?.student_id)
                                        }
                                      >
                                        <i className="fal fa-save"></i>
                                      </button>
                                    </PermissionsGate>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="custom-table-msg">{Str.noRecord}</div>
          )}
          <nav className="pagination-nav">
            <div>
              {errorMessage ? (
                <div className="invalid-feedback d-block">{errorMessage}</div>
              ) : (
                ""
              )}
            </div>
            <div className="second-child">
              <span>Rows per page:&nbsp;</span>
              <div className="pagination-select">
                <select
                  defaultValue={tableState?.limit}
                  onChange={(e) => {
                    let value = e?.target?.value;
                    setTableState((prevState) => ({
                      ...prevState,
                      limit: value ? value : 10,
                      page: 1,
                    }));
                  }}
                >
                  {TABLE_ROWS_PER_PAGE.map((page, index) => (
                    <option key={index}>{page}</option>
                  ))}
                </select>
              </div>
              <span className="mx-3">
                {paginationState.from}-
                {paginationState.to > paginationState.total
                  ? paginationState.total
                  : paginationState.to}{" "}
                of {paginationState.total}
              </span>
              <div className="pagination-btns">
                <button
                  type="button"
                  disabled={
                    paginationState.currentPage == 1 ||
                    paginationState.currentPage == 0
                  }
                  onClick={() => {
                    if (updateValueArr?.length) {
                      handleNavigation(null, "firstPage");
                    } else {
                      setTableState((prevState) => ({ ...prevState, page: 1 }));
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                  </svg>
                </button>
                <button
                  type="button"
                  disabled={
                    paginationState.currentPage == 1 ||
                    paginationState.currentPage == 0
                  }
                  onClick={() => {
                    if (updateValueArr?.length) {
                      handleNavigation(null, "prevPage");
                    } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page: paginationState.currentPage - 1,
                      }));
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button
                  type="button"
                  disabled={
                    paginationState.currentPage == paginationState.lastPage
                  }
                  onClick={() => {
                    if (updateValueArr?.length) {
                      handleNavigation(null, "nextPage");
                    } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page: +paginationState.currentPage + 1,
                      }));
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button
                  type="button"
                  disabled={
                    paginationState.currentPage == paginationState.lastPage
                  }
                  onClick={() => {
                    if (updateValueArr?.length) {
                      handleNavigation(null, "lastPage");
                    } else {
                      setTableState((prevState) => ({
                        ...prevState,
                        page: paginationState.lastPage,
                      }));
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </nav>
        </div>
        {userData?.length && (updateValueArr?.length || isCourseEdited) ? (
          <GradeBookSaveBar
            handleClick={() => handleSaveGrades()}
            disabled={errorMessage}
            saveGradesLoading={saveGradesLoading}
          />
        ) : (
          <></>
        )}
        <div
          className="topic-add-modal modal fade"
          id="downloadSoRModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div
            className="modal-600 modal-dialog modal-dialog-centered modal-m"
            role="document"
          >
            <div className="modal-content modal-border-update sor">
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-download"></i> Submission Download
                    Options
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-toggle="modal"
                    data-target="#downloadSoRModal"
                    onClick={() => {
                      setStudentID("");
                      setStudentName("")
                      setIsDownloadAll(false);
                      setIsDownloadBriefs(false);
                      // setBulkDownload(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="t p-17px">
                  <i className="fal fa-cog SoR-icon"></i>
                  <span className="SoR-settings"> Settings</span>
                  <div className="toogles-inline mt-3 d-flex align-items">
                    <div className="d-flex align-items mr-3">
                      <label className="mb-0">Download All Assessments</label>
                      <div className="toggle-switch ml-2">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isDownloadAll}
                            onChange={handleDownloadAllToggle}
                          />
                          <span className="slider slider-round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="d-flex align-items mr-3">
                      <label className="mb-0">Download Assessment Briefs</label>
                      <div className="toggle-switch ml-2">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isDownloadBriefs}
                            onChange={handleDownloadBriefsToggle}
                          />
                          <span className="slider slider-round"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="form-group form-group-save-cancel mt-4">
                    <button
                      className="btn btn-save btn-success"
                      type="button"
                      title="Download"
                      data-toggle="modal"
                      data-target="#downloadAssessment"
                      disabled={!isDownloadAll && !isDownloadBriefs}
                      onClick={() => {
                        if (studentID) {
                          handleDownloadAssessment();
                        }
                      }}
                    >
                      <i className="fal fa-download"></i> Download
                    </button>
                    <button
                      className="btn btn-close btn-danger"
                      type="button"
                      title="Cancel"
                      onClick={() => {
                        setStudentID("");
                        setStudentName("")
                        setIsDownloadAll(false);
                        setIsDownloadBriefs(false);
                        // setBulkDownload(false);
                        $("#downloadSoRModal").modal("hide");
                      }}
                    >
                      <i className="fal fa-times"></i>{" "}
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="downloading-progress modal fade"
          id="downloadAssessment"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          ref={downloadRef}
          data-backdrop="static"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="swal2-header">
                  <div className="swal2-icon swal2-info swal2-icon-show d-flex">
                    <div className="swal2-icon-content">i</div>
                  </div>
                  <h2 className="swal2-title d-flex mb-4">Downloading...</h2>
                  <div className="progress w-100 mb-2">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow={
                        FinalLoadedData && finalTotal > 0
                          ? ((FinalLoadedData / finalTotal) * 100).toFixed(1)
                          : 0
                      }
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width:
                          FinalLoadedData && finalTotal > 0
                            ? `${((FinalLoadedData / finalTotal) * 100).toFixed(
                                1
                              )}%`
                            : `0%`,
                      }}
                    >
                      {FinalLoadedData && finalTotal > 0
                        ? ((FinalLoadedData / finalTotal) * 100).toFixed(2)
                        : 0}
                      %
                    </div>
                  </div>
                  <>
                    File Size :{" "}
                    {FinalLoadedData && finalTotal > 0
                      ? `${formatBytes(FinalLoadedData, 1)} / ${formatBytes(
                          finalTotal,
                          1
                        )}`
                      : `0 / 0`}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GradeMixGradeBook;
