import axiosInstance from "../utils/axios";

export const getFilterDetails = async (cancelToken) => {
  return await axiosInstance().get("/getCommonContent",{cancelToken});
};

export const DetailExamListData = async (values, cancelToken) => {
  return await axiosInstance().post(`/examListData`,values, {cancelToken});
};

export const ExamsRegistrationsList = async (values, cancelToken) => {
  return await axiosInstance().post(`/examsRegistrationsList`,values,{cancelToken});
};

export const ExamsRegistrationsListFilter = async (params, cancelToken) => {
  return await axiosInstance().get(`/examsRegistrationsListFilter`,{params,cancelToken});
};

export const ExamsNotRegistrationsList = async (values, cancelToken) => {
  return await axiosInstance().post(`/examsNotRegistrationsList`,values, {cancelToken});
};

export const ExamsNotRegistrationsListFilter = async (values, cancelToken) => {
  return await axiosInstance().get(`/examsNotRegistrationsListFilter?exam=${values}`, {cancelToken});
};

export const ExamCenterListData = async (values, cancelToken) => {
  return await axiosInstance().post(`/examCenterListData`,values, {cancelToken});
};

export const ExamCenterListDataFilter = async (cancelToken) => {
  return await axiosInstance().get(`/examCenterListDataFilter`,{cancelToken});
};

export const ExamsRegReportList = async (values, cancelToken) => {
  return await axiosInstance().post(`/examsRegReportList`,values,{cancelToken});
};

export const ExamsFinalMarksImportUpdatedList = async (values, cancelToken) => {
  return await axiosInstance().post(`/examsFinalMarksImportUpdatedList`,values,{cancelToken});
};

export const ExamsNotificationList = async (values, cancelToken) => {
  return await axiosInstance().post(`/examsNotificationList`,values, {cancelToken});
};

export const GetExamsSettingData = async (cancelToken) => {
  return await axiosInstance().get(`/getexamsSettingData`,{cancelToken});
};

export const EditExamSettings = async (values) => {
  return await axiosInstance().post(`/editExamSettings`,values);
};

export const AddEditCenter = async (values) => {
  return await axiosInstance().post(`/addEditCenter`,values);
};

export const GetEditCenterData = async (id, cancelToken) => {
  return await axiosInstance().get(`/getEditCenterData?id=${id}`, {cancelToken});
};

export const NotAssignedIntakelist = async (id, cancelToken) => {
  return await axiosInstance().get(`/notAssignedIntakelist?uid=${id}`, {cancelToken});
};

export const GeteditExamData = async (id, cancelToken) => {
  return await axiosInstance().get(`/geteditExamData?uid=${id}`, {cancelToken});
};

export const AddeditExamData = async (values) => {
  return await axiosInstance().post(`/addEditExamData`,values);
};

export const GetExamPeriodList = async (cancelToken) => {
  return await axiosInstance().get(`/getExamPeriodList`,{cancelToken});
};

export const ExamsRegistrationsListEdit = async (param) => {
  return await axiosInstance().get(`/examsRegistrationsListEdit`, {params:param});
};

export const ExamsRegistrationsUpdate = async (values) => {
  return await axiosInstance().post(`/examsRegistrationsUpdate`,values);
};

export const ExamRegistrationInvoice = async (id,subId) => {
  return await axiosInstance().get(`/examRegistrationInvoice?registration=${subId}&exam=${id}`);
};

export const PrintInvoiceData = async (id,subId,type)=>{
  return await axiosInstance().get(`/printInvoiceData?registration=${id}&exam=${subId}&type=${type}`);
}

export const SendInvoiceNotification = async (values) => {
  return await axiosInstance().post(`/sendInvoiceNotification`,values);
};

export const Exams_invoices_edit = async (values) => {
  return await axiosInstance().post(`/exams_invoices_edit`,values);
};

export const StudentQuickEmailNotification = async (values) => {
  return await axiosInstance().post(`/studentQuickEmailNotification`,values);
};

export const DeleteExamRegistration = async (values) => {
  return await axiosInstance().post(`/deleteExamRegistration`,values);
};

export const getExamsNotification = async (id, subId, cancelToken) => {
  return await axiosInstance().get(`/getExamsNotification?id=${id}&exam=${subId}`, {cancelToken});
};

export const SendDraftExamNotification = async (values) => {
  return await axiosInstance().post(`/sendDraftExamNotification`,values);
};

export const ExamsFinalMarksImportList = async (values, cancelToken) => {
  return await axiosInstance().post(`/examsFinalMarksImportList`,values,{cancelToken});
};

export const ExamsFinalUpdatedion = async (values) => {
  return await axiosInstance().post(`/examsFinalUpdatedion`,values);
};

export const ExamsMarksUpdate = async (values) => {
  return await axiosInstance().post(`/examsFinalMarksImportFlaggedFourfive`,values);
};

export const ExamsMarksFinalUpdate = async (values) => {
  return await axiosInstance().post(`/examsFinalUpdatedation`,values);
};

export const ImportExamFinalMarksFile = async (values) => {
  return await axiosInstance().post(`/importExamFinalMarksFile`,values);
};

export const ExamsRegCenterExportList = async (id, cancelToken) => {
  return await axiosInstance().get(`/examsRegCenterExportList?exam=${id}`,{cancelToken});
};

export const GetExamRegistrationStudentList = async (id) => {
  return await axiosInstance().get(`/getExamRegistrationStudentList?exam=${id}`);
};

export const CalculateTermMark = async (params) => {
  return await axiosInstance().get(`/calculateTermMark`,{params});
};


export const GetExamRegistrationData = async (id,examId) => {
  return await axiosInstance().get(`/getExamRegistrationData?student_id=${id}&exam=${examId}`);
};

export const GetExamCenterDropDownList = async (id) => {
  return await axiosInstance().get(`/getExamCenterDropDownList`);
};

export const AddEditExamPeriod = async (values) => {
  return await axiosInstance().post(`/addEditExamPeriod`,values);
};

export const DeleteExamPeriod = async (id) => {
  return await axiosInstance().post(`/deleteExamPeriod`,id);
};

export const GetEditExamPeriodData = async (id, cancelToken) => {
  return await axiosInstance().get(`/getEditExamPeriodData?id=${id}`,{cancelToken});
};

export const ExamsRegExportData = async (param) => {
  return await axiosInstance().get(`/examsRegExportData`, {params:param});
};

export const SendDraftQuickNotification = async (values) => {
  return await axiosInstance().post(`/sendDraftQuickNotification`, values);
}

export const NotAssignedIntakelistFilter = async (cancelToken) => {
  return await axiosInstance().get(`/notAssignedIntakelistFilter`, {cancelToken});
};

export const ExamsFinalMarksImportListFilter = async (id, cancelToken) => {
  return await axiosInstance().get(`/examsFinalMarksImportListFilter?exam=${id}`,{cancelToken});
};

export const ExamsFinanceExportData = async (param) => {
  return await axiosInstance().get(`/examsFinanceExportData`,{params:param});
};

export const GetExamPeriodAuditTrails = async (data, cancelToken) => {
  return await axiosInstance().post(`/getExamPeriodAuditTrails`,data, {cancelToken});
};

export const GetExamPeriodAuditFilters = async (params, cancelToken) => {
  return await axiosInstance().get(`/getExamPeriodAuditFilters`, {params,cancelToken});
};

export const GetExamSettingsAuditTrails = async (data, cancelToken) => {
  return await axiosInstance().post(`/getExamSettingsAuditTrails`,data,{cancelToken});
};

export const GetExamSettingsAuditFilters = async (params, cancelToken) => {
  return await axiosInstance().get(`/getExamSettingsAuditFilters`,{params,cancelToken});
};

export const GetExamCentresAuditTrails = async (data, cancelToken) => {
  return await axiosInstance().post(`/getExamCentresAuditTrails`,data,{cancelToken});
};

export const GetExamCentresAuditFilters = async (params, cancelToken) => {
  return await axiosInstance().get(`/getExamCentresAuditFilters`,{params, cancelToken});
};

export const getExamListAuditTrails = async (data, cancelToken) => {
  return await axiosInstance().post(`/getExamListAuditTrails`,data, {cancelToken});
};

export const GenerateMarks = async (data) => {
  return await axiosInstance().post(`/generateMarks`,data);
};

export const GetExamListAuditFilters = async (params, cancelToken) => {
  return await axiosInstance().get(`/getExamListAuditFilters`,{params, cancelToken});
};

export const ImportSingleUpdate = async (data) => {
  return await axiosInstance().post(`/importSingleUpdate`,data);
};

export const UpdateResult = async (data) => {
  return await axiosInstance().post(`/udpateResult`,data);
};

export const DeleteExamCenter = async (data) => {
  return await axiosInstance().post(`/deleteExamCenter`,data);
};

export const DeleteExam = async (data) => {
  return await axiosInstance().post(`/deleteExam`,data);
}

export const SendNotificationExam = async (data) => {
  return await axiosInstance().post(`/sendNotificationExam`,data);
};

export const GetExamsFinalAllFiles = async (data, cancelToken) => {
  return await axiosInstance().post(`/getExamsFinalAllFiles`,data, {cancelToken});
};

export const deleteExamsFinalAllFiles = async (data) => {
  return await axiosInstance().post(`/deleteExamsFinalAllFiles`,data);
};