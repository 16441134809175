import React, { useState, useMemo, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import Str from "../../../common/Str";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { FORM_DATE_FORMAT, TABLE_DATE_FORMAT, TABLE_DATE_TIME_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  ApproveOrDeclineAppeal,
  GetAppealsListCancelToken,
  ViewAppeal,
} from "../../../../services/StudentsSettingServices";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { handleTableScroll } from "../../../../utils/commonFunction";
import axios from "axios";

function Appeals() {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [assId, setAssId] = useState("");
  const [studentId, setStudentId] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startDateApprove, setStartDateApprove] = useState(new Date());
  const [appealsDetails, setAppealsDetails] = useState({});
  const [asessmentDetails, setAssessmentDetails] = useState({});
  const [studentMessage, setStudentMessage] = useState("");
  const [studentMessageApprove, setStudentMessageApprove] = useState("");
  const [studentMessageDecline, setStudentMessageDecline] = useState("");
  const [deleterow, setDeleteRow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState([]);
  const [courseName, setCourseName] = useState([]);
  const [programmeName, setProgrammeName] = useState([]);
  const [intake, setIntake] = useState([]);
  const [intakeNum, setIntakeNum] = useState([]);
  const [marker, setMarker] = useState([]);
  const { id } = useParams();

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    handleTableScroll()
  }, [isLoaded])

  useEffect(() => {
    let response = hasPermission({ scopes: ["saview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  useEffect(() => {
    const cancelTokenSources = [];

    const getAppealList = async () => {
      setIsLoaded(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await GetAppealsListCancelToken(id, source.token);
        if (res.status === 200) {
          setData(res.data?.appeal_list.map(item=>{
            return {...item,status: item.status === "Appeal-Pending" ? "Pending" : item.status}
          }));
          setIsLoaded(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoaded(false);
        }
      }
    };
  
    getAppealList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [deleterow]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleGetAppealDetails = (ID, subId) => {
    setAssId(subId);
    setStudentId(ID);
    ViewAppeal(ID, subId)
      .then((res) => {
        setStudentMessage((res.data.student_appeal.student_message!=null && res.data.student_appeal.student_message!="null") ? res.data.student_appeal.student_message : "");
        setStartDate(res.data.student_appeal.ass_ext_close_date ? new Date(moment.unix(res.data.student_appeal.ass_ext_close_date)) : "");
        setAssessmentDetails(res.data.assignment);
        // setStartDate(res.data.student_appeal.ass_ext_close_date)
        setAppealsDetails(res.data);
      })
      .catch((err) => console.log("err :", err));
  };

  const resetFilter = () => {
    setSearch("");
    setStatus([]);
    setCourseName([]);
    setProgrammeName([]);
    setIntake([]);
    setIntakeNum([]);
    setMarker([]);
  };

  const dataToRender = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let tempAssignmentName = allData.filter((item) => {
        let includes =
          item.assignmentName &&
          item.assignmentName.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempAssignmentNumber = allData.filter((item) => {
        let includes =
          item.assignment_id &&
          item.assignment_id.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempAssessmentCode = allData.filter((item) => {
        let includes =
          item.assignmentCode &&
          item.assignmentCode.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempAssessmentReason = allData.filter((item) => {
        let includes =
          item.reason &&
          item.reason.toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempAssessmentOpenDate = allData.filter((item) => {
        let includes =
          item.assignmentDateOpen &&
          moment.unix(item.assignmentDateOpen).format(TABLE_DATE_TIME_FORMAT).toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempAssessmentCloseDate = allData.filter((item) => {
        let includes =
          item.assessment_close_date &&
          moment.unix(item.assessment_close_date).format(TABLE_DATE_TIME_FORMAT).toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [
        // ...tempId,
        ...tempAssignmentName,
        ...tempAssignmentNumber,
        ...tempAssessmentCode,
        ...tempAssessmentReason,
        ...tempAssessmentOpenDate,
        ...tempAssessmentCloseDate
      ];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }
    if (status.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.find(function (post, index) {
          if (
            post.toString().toLowerCase() ===
              item.status.toString().toLowerCase()
              ? item.status.toString().toLowerCase()
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (courseName.length) {
      let tempCourseName = updatedData;
      let tempCourseNameResult = tempCourseName.filter((item) => {
        const startsWith = courseName.find(function (post, index) {
          if (
            post.toString().toLowerCase() ==
            item.courseName.toString().toLowerCase()
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempCourseNameResult;
    }

    if (programmeName.length) {
      let tempProgrammeName = updatedData;
      let tempProgrammeNameResult = tempProgrammeName.filter((item) => {
        const startsWith = programmeName.find(function (post, index) {
          if (
            post.toString().toLowerCase() ==
            item.qualification.toString().toLowerCase()
          ) {
            return item;
          } else {
          }
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempProgrammeNameResult;
    }

    if (intake.length) {
      let tempintake = updatedData;
      let tempintakeResult = tempintake.filter((item) => {
        const startsWith = intake.find(function (post, index) {
          if (
            post.toString().toLowerCase() == item.intakeYear
              ? item.intakeYear.toString().toLowerCase()
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempintakeResult;
    }

    if (intakeNum.length) {
      let tempintakeNum = updatedData;
      let tempintakeNumResult = tempintakeNum.filter((item) => {
        const startsWith = intakeNum.find(function (post, index) {
          if (
            post.toString().toLowerCase() == item.intakeNumber
              ? item.intakeNumber.toString().toLowerCase()
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempintakeNumResult;
    }

    if (marker.length) {
      let tempMarker = updatedData;
      let tempMarkerResult = tempMarker.filter((item) => {
        const startsWith = marker.find(function (post, index) {
          if (
            post.toString().toLowerCase() ==
            item.MARKER.toString().toLowerCase()
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempMarkerResult;
    }
    if (
      search.length ||
      status.length ||
      courseName.length ||
      programmeName.length ||
      marker.length ||
      intakeNum.length ||
      intake.length
    ) {
      return updatedData;
    } else {
      return data;
    }
  };

  const sortingName = (rowA, rowB) =>{
    const name1 = rowA.assignmentName ? rowA.assignmentName.trim().toLowerCase() : ""
    const name2 = rowB.assignmentName ? rowB.assignmentName.trim().toLowerCase() : ""
    return name1.localeCompare(name2);
  }

  const columns = useMemo(() => [
    {
      name: "Assessment Name",
      selector: "assignmentName",
      sortable: true,
      sortFunction:sortingName,
      cell: (row) => (
            <span
              data-toggle="modal"
              data-target="#notifications1"
              onClick={() => {
                // history.push(`/courseAdministration/coursesdetails/${row.intakeID}/assessments/open/assigned/submissionsAndResults/${row.id}`, {prev : true, stdId: row.student_id})
                handleGetAppealDetails(row?.student_id, row?.assignment_id);
              }}
              // onClick={() => window.open(`https://aiesuperadmin.devtechnosys.tech/courseAdministration/coursesdetails/${row.intakeID}/assessments/open/assigned/submissionsAndResults/${row.assignmentID}`, {prev : true, stdId: row.student_id})}
              className="as-text-blue curser feature-name"
              title={row.assignmentName}
            >
              <span className="textLimit100">{row.assignmentName}</span>
            </span>
          ),
    },
    {
      name: "No.",
      selector: "assignment_id",
      sortable: true,
      cell: (row, index) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">{row.assignment_id}</div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) =>
        row.status ? (
          <span
            className={
              row.status === "Approved" || row.status === "Approve"
                ? "as-orange-bg as-widget"
                : row.status === "Declined"
                  ? "as-green-bg as-widget"
                  : "as-red-bg as-widget"
            }
          >
            {row.status}
          </span>
        ) : (
          <p className="cat">-</p>
        ),
    },
    {
      name: "Code",
      selector: "assignmentCode",
      sortable: true,
      cell: (row) => <span className="feature-name"><span className="textLimit100">{row.assignmentCode ? row.assignmentCode : "-"}</span></span>,
    },
    {
      name: "Open Date",
      selector: "assignmentDateOpen",
      sortable: true,
      cell: (row) =>
      <span className="feature-name">
      {row.assignmentDateOpen ? (
        <div className="dateTime">
          <span className="right-space textLimit100">
            {moment.unix(row.assignmentDateOpen).format(TABLE_DATE_FORMAT)}
          </span>
          <span className="textLimit100">{moment.unix(row.assignmentDateOpen).format(TABLE_TIME_FORMAT)}</span>
        </div>
      ) : (
        "-"
      )}
    </span>
    },
    // {
    //   name: "Open Date",
    //   selector: "assignmentDateOpen",
    //   sortable: true,
    //   cell: (row) =>
    //     row.assignmentDateOpen
    //       ? moment.unix(row.assignmentDateOpen).format(TABLE_DATE_TIME_FORMAT)
    //       : "NA",
    // },
    // {
    //   name: "Due Date",
    //   selector: "assessment_close_date",
    //   sortable: true,
    //   cell: (row) =>
    //     row.assessment_close_date
    //       ? moment.unix(row.assessment_close_date).format(TABLE_DATE_TIME_FORMAT)
    //       : "NA",
    // },
    {
      name: "Due Date",
      selector: "assignmentDateClose",
      sortable: true,
      cell: (row) =>
        (
          <span className="feature-name">
          {row.assignmentDateClose ? (
            <div className="dateTime">
              <span className="right-space textLimit100">
                {moment.unix(row.assignmentDateClose).format(TABLE_DATE_FORMAT)}
              </span>
              <span className="textLimit100">{moment.unix(row.assignmentDateClose).format(TABLE_TIME_FORMAT)}</span>
            </div>
          ) : (
            "-"
          )}
        </span>
        )
    },


    {
      name: "Reason",
      selector: "reason",
      sortable: true,
      cell: (row) => row.reason ? row.reason :"-",
    },

    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <button
              className="btn btn-primary rounded-circle"
              data-toggle="modal"
              data-target="#notifications1"
              onClick={() => {
                // setAppealsDetails(row)
                handleGetAppealDetails(row?.student_id, row?.assignment_id);
              }}
              // onClick={() => window.open(`https://aiesuperadmin.devtechnosys.tech/courseAdministration/coursesdetails/${row.intakeID}/assessments/open/assigned/submissionsAndResults/${row.assignmentID}`, {prev : true, stdId: row.student_id})}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </button>
            {/* {row.status == "Appeal-Pending" && <button className="btn btn-primary rounded-circle" data-toggle="modal" title="View Appeal"
                  data-target="#notifications">
              <i className="fal fa-folder-open"></i>
            </button>} */}
            {/* <button className="btn btn-danger rounded-circle" onClick={() => handleDelete(row.id)}>
                    <i className="fal fa-trash-alt"></i>
                  </button> */}
          </div>
        </div>
      ),
    },
  ], []);

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    const header = [
      "Assessment Name",
      "No",
      "Status",
      "Code",
      "Open Date",
      "Due Date",
      "Reason",
    ];

    data = data?.map((row, index) => ({
      ...row,
      No: row.assignment_id ? row.assignment_id :"-",
      "Assessment Name": row.assignmentName ? row.assignmentName : "",
      Status: row.status ? row.status : "-",
      Code: row.assignmentCode ? row.assignmentCode : "-",
      "Open Date": row.assignmentDateOpen
        ? moment.unix(row.assignmentDateOpen).format(TABLE_DATE_TIME_FORMAT)
        : "-",
      "Due Date": row.assessment_close_date
        ? moment.unix(row.assessment_close_date).format(TABLE_DATE_TIME_FORMAT)
        : "-",
      Reason: row.reason ? row.reason : "-",
    }));
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row, index) => {
        return [
          row["Assessment Name"],
          row["No"],
          row.status,
          row["Code"],
          row["Open Date"],
          row["Due Date"],
          row["Reason"],
        ];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const handleSendResponseApprove = () => {
    // let tempDate = startDate.setDate(startDate.getDate() + 3)

    const data = new FormData();
    data.append("student_id", studentId);
    data.append("student_message", studentMessageApprove);
    data.append("assessment_id", assId);
    data.append("assessment_close_date", startDateApprove);
    data.append("status", "Approve");
    ApproveOrDeclineAppeal(data)
      .then((res) => {})
      .catch((err) => console.log("error : ", err));

    setDeleteRow(true);
  };

  const handleSendResponseDecline = () => {
    // let tempDate = startDate.setDate(startDate.getDate() + 3)

    const data = new FormData();
    data.append("student_id", studentId);
    data.append("student_message", studentMessageApprove);
    data.append("assessment_id", assId);
    data.append("status", "Decline");
    ApproveOrDeclineAppeal(data)
      .then((res) => {})
      .catch((err) => console.log("error : ", err));
    setDeleteRow(true);
  };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="custom-table-div filter-search-icon card card-table-custom">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                      <div
                        id="assessment-table-main_filter"
                        className="dataTables_filter"
                      >
                        <label>
                          <input
                            type="search"
                            className=""
                            placeholder="Search"
                            aria-controls="assessment-table-main"
                            onChange={handleSearchFilter}
                            value={search}
                          />
                        </label>
                        <div className="filter-eff filter-data-btn">
                          <button className="filter-buttons">
                            <i className="fal fa-filter"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                </div>
                <div className="filter-button-group">
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("xlsx", "Appeals");
                        }}
                        title="Export spreadsheet"
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("csv", "Appeals");
                        }}
                        title="Export CSV"
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        onClick={() => {
                          exportData("pdf", "Appeals");
                        }}
                        title="Export PDF"
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="topic-add-modal modal fade"
            id="notifications"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <div className="topic-add-modal-content text-center font-weight-bold">
                    <i className="fal fa-comment"></i> Appeal
                  </div>
                  <hr />
                  {/* <div className="card-body"> */}
                  <div className="card-header">Assessment Detiail</div>
                  <div className="card-body-inr card-body-info">
                    <div className="basic-info-box">
                      <div className="row">
                        <div className="col-md-6 col-lg-3">
                          <div className="basic-info-box-list">
                            <ul>
                              <li>
                                <span className="title">Assessment Name :</span>
                                {/* <span>{basicInfo.course_name ? basicInfo.course_name : "-"}</span> */}
                              </li>
                              <li>
                                <span className="title ">Opening Date :</span>
                                {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                                {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                                {/* </Link> */}
                              </li>
                              <li>
                                <span className="title ">Due Date :</span>
                                {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                                {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                                {/* </Link> */}
                              </li>
                              <li>
                                <span className="title ">
                                  Appeal Submission Date :
                                </span>
                                {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                                {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                                {/* </Link> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <div className="basic-info-box-list">
                            <ul>
                              <li>
                                <span className="title">
                                  Assessment Status:
                                </span>
                                {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/tickets/show" }}> */}
                                {/* <span>{basicInfo.amnt_tkt || basicInfo.amnt_tkt == 0 ? basicInfo.amnt_tkt : "-"}</span> */}
                                {/* </Link> */}
                              </li>
                              <li>
                                <span className="title">Code:</span>
                                {/* <span>{basicInfo.last_modified ? basicInfo.last_modified : "-"}</span> */}
                                {/* <span>17 Jan ’22 11:17 am (Smith Jhone)</span> */}
                              </li>
                              <li>
                                <span className="title ">Your Result :</span>
                                {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                                {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                                {/* </Link> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="card-header">Assessment Detiail</div>
                      <div className="card-body-inr card-body-info">
                        <div className="basic-info-box"></div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="basic-info-box-list">
                              <ul>
                                <li>
                                  <span className="title">
                                    Reason for not submitting assessment:
                                  </span>
                                  {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/tickets/show" }}> */}
                                  {/* <span>{basicInfo.amnt_tkt || basicInfo.amnt_tkt == 0 ? basicInfo.amnt_tkt : "-"}</span> */}
                                  {/* </Link> */}
                                </li>
                                <li>
                                  <span className="title">Description:</span>
                                  {/* <span>{basicInfo.last_modified ? basicInfo.last_modified : "-"}</span> */}
                                  {/* <span>17 Jan ’22 11:17 am (Smith Jhone)</span> */}
                                </li>
                                <li>
                                  <span className="title ">
                                    Supporting File :
                                  </span>
                                  {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                                  {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                                  {/* </Link> */}
                                </li>
                                <li>
                                  <span className="title ">
                                    Message Student :
                                  </span>
                                  <textarea />
                                  {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                                  {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                                  {/* </Link> */}
                                </li>
                                <li>
                                  <span className="title ">
                                    Extended assessment date :
                                  </span>
                                  <input type="date" />
                                  {/* <Link to={{ pathname: "/courseAdministration/coursesdetails/" + props.userId + "/students/show" }}> */}
                                  {/* <span>{basicInfo.amnt_of_stdnt || basicInfo.amnt_of_stdnt == 0 ? basicInfo.amnt_of_stdnt : "-"}</span> */}
                                  {/* </Link> */}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {/* <PermissionsGate scopes={["cedit"]} errorProps={{ disabled: true }}> */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    title="Send Response"
                  >
                    <i className="fal fa-check"></i>
                    Send Response
                  </button>
                  {/* </PermissionsGate> */}
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    title="Close"
                  >
                    <i className="fal fa-times"></i>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          {isLoaded ? (
            <SkeletonTicketList />
          ) : (
            <DataTable
              data={dataToRender()}
              defaultSortField="assignmentDateOpen"
              defaultSortAsc={true}
              columns={columns}
              pagination={true}
              noDataComponent={Str.noRecord}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />
          )}
        </div>
      </div>

      <div
        className="topic-add-modal modal fade"
        id="notifications1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content modal-border-update">
         
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fal fa-comment"></i> Appeal 
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="t p-30px">
                <div className="topic-add-modal-content">
                  <div className="row center-label">
                  <div className='col-md-12'>
                    <h5 className="font-weight-bold">Assessment Detail</h5>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="basic-info-box-list modal-info-list">
                          <ul>
                            <li>
                              <span className="title">Assessment Name :</span>
                              <span>
                                {asessmentDetails && asessmentDetails.name}
                              </span>
                            </li>
                            <li>
                              <span className="title ">Opening Date :</span>
                              <span>
                                {asessmentDetails && asessmentDetails.open_date_time && moment.unix(asessmentDetails.open_date_time).format(TABLE_DATE_TIME_FORMAT)}
                              </span>
                            </li>
                            <li>
                              <span className="title ">Due Date :</span>
                              <span>
                                {asessmentDetails && asessmentDetails.close_date_time && moment.unix(asessmentDetails.close_date_time).format(TABLE_DATE_TIME_FORMAT)}
                              </span>
                            </li>
                            {/* <li>
                              <span className="title ">
                                Appeal Submission Date :&nbsp;
                              </span>
                              <span>
                                {appealsDetails &&
                                  appealsDetails.student_appeal &&
                                  moment
                                    .unix(
                                      Number(appealsDetails?.student_appeal?.submit_date)
                                    )
                                    .format(TABLE_DATE_TIME_FORMAT)}
                              </span>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="basic-info-box-list modal-info-list">
                          <ul>
                            <li>
                              <span className="title">Assessment Status:</span>
                              <span>
                                {appealsDetails && appealsDetails.student_appeal && appealsDetails?.student_appeal?.appeal_status}
                              </span>
                            </li>
                            <li>
                              <span className="title">Code:</span>
                              <span>
                                {asessmentDetails && asessmentDetails.code}
                              </span>
                            </li>
                            {/* <li>
                              <span className="title ">Your Result :</span>
                              <span>Your Result</span>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row center-label">
                  <div className='col-md-12'>
                    <h5 className="font-weight-bold">Registered Appeal</h5>
                    </div>
                    <div className="row">
                      
                      <div className="col-md-12">
                        <div className="basic-info-box-list modal-info-list">
                          <ul>
                            <li>
                            <span className="title">
                            Reason for not submitting assessment :&nbsp;
                          </span>
                          <span>
                            {appealsDetails &&
                              appealsDetails.student_appeal &&
                              appealsDetails.student_appeal.ass_reason}
                          </span>
                            </li>
                          </ul>
                        </div>
                      </div>&nbsp;
                      <div className="col-md-12">
                        <div className="basic-info-box-list modal-info-list">
                          <ul>
                            <li>
                            <span className="title">Description: </span>
                            <span className="text-break">
                              {appealsDetails &&
                                appealsDetails.student_appeal &&
                                appealsDetails.student_appeal.description}
                            </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="basic-info-box-list modal-info-list">
                          <ul className="mb-0">
                            {/* {appealsDetails?.student_appeal?.ass_status !=
                                    "Closed-Not Submitted" && ( */}
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="form-group-blk mb-3">
                                          <li>
                                            <span className="title ">
                                              Message Student :
                                            </span>
                                            <textarea
                                              disabled
                                              type="text"
                                              className="form-control"
                                              value={studentMessage}
                                              onChange={(e) =>
                                                setStudentMessage(e.target.value)
                                              }
                                            />
                                          </li>
                                          </div>
                                        </div>
                                        <div className="col-md-12">
                                          <div className="form-group-blk mb-0">
                                          <li>
                                            <span className="title ">
                                              Extended assessment date :&nbsp;
                                            </span>
                                            <span className="form-group-blk w-100 mb-0">
                                              <DatePicker
                                                disabled
                                                selected={startDate}
                                                onChange={(date) => {
                                                  setStartDate(date);
                                                }}
                                                minDate={new Date()}
                                                className="form-control w-100"
                                                dateFormat={FORM_DATE_FORMAT}
                                              />
                                            </span>
                                          </li>
                                          </div>
                                        </div>
                                      </div>
                            {/* )} */}
                          </ul>
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                        <div className="basic-info-box-list modal-info-list">
                          <ul>
                            {appealsDetails?.student_appeal?.ass_status !=
                                      "declined" && (
                                          <li>
                                            <span className="title ">
                                              Message Student :
                                            </span>
                                            <textarea
                                            disabled
                                              type="text"
                                              className="form-control"
                                              value={studentMessage}
                                              onChange={(e) =>
                                                setStudentMessage(e.target.value)
                                              }
                                            />
                                          </li>
                            )}
                          </ul>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* <div className="form-group form-group-save-cancel mt-3"> */}
                  {/* {appealsDetails?.student_appeal?.ass_status ==
                  "Closed-Submitted" && (
                    <>
                      <button
                        type="button"
                        className="btn btn-success"
                        data-dismiss="modal"
                        data-target="#approve"
                        data-toggle="modal"
                        title="Approve"
                      //  onClick={handleSendResponse}
                      >
                        <i className="fal fa-check"></i>
                        Approve
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-dismiss="modal"
                        title="Decline"
                        // onClick={handleSendResponse}
                        data-target="#decline"
                        data-toggle="modal"
                      >
                        <i className="fal fa-check"></i>
                        Decline
                      </button>
                    </>
                  )} */}

                {/* {appealsDetails?.student_appeal?.ass_status ==
                  "Closed-Not Submitted" && ( */}
                    {/* <button
                      type="button"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      title="Close"
                    >
                      <i className="fal fa-times"></i>
                      Close
                    </button> */}
                  {/* )} */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="topic-add-modal modal fade"
        id="approve"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="topic-add-modal-content text-center font-weight-bold">
                <i className="fal fa-comment"></i> Appeal
              </div>
              <hr />

              {/* <div className="card-header mb-4">Assessment Detail</div> */}
              <div className="card-body-inr card-body-info">
                <div className="basic-info-box">
                  {/* <div className="card-header mb-4">Registered Appeal</div> */}
                  <div className="card-body-inr card-body-info">
                    <div className="basic-info-box"></div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="basic-info-box-list modal-info-list">
                          <form>
                            <ul>
                              <li>
                                <span className="title">Message Student :</span>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  value={studentMessageApprove}
                                  onChange={(e) =>
                                    setStudentMessageApprove(e.target.value)
                                  }
                                />
                              </li>
                              <li>
                                <span className="title ">
                                  Extended assessment date :
                                </span>
                                <DatePicker
                                  selected={startDateApprove}
                                  onChange={(date) => {
                                    setStartDateApprove(date);
                                  }}
                                  className="form-control w-100"
                                />
                              </li>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleSendResponse}>
                  <i className="fal fa-check"></i>
                  Send Response
                </button> */}

              <>
                <button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={handleSendResponseApprove}
                >
                  <i className="fal fa-check"></i>
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  <i className="fal fa-times"></i>
                  Cancel
                </button>
              </>
            </div>
          </div>
        </div>
      </div>

      <div
        className="topic-add-modal modal fade"
        id="decline"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="topic-add-modal-content text-center font-weight-bold">
                <i className="fal fa-comment"></i> Appeal
              </div>
              <hr />

              {/* <div className="card-header mb-4">Assessment Detail</div> */}
              <div className="card-body-inr card-body-info">
                <div className="basic-info-box">
                  {/* <div className="card-header mb-4">Registered Appeal</div> */}
                  <div className="card-body-inr card-body-info">
                    <div className="basic-info-box"></div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="basic-info-box-list modal-info-list">
                          <form>
                            <ul>
                              <li>
                                <span className="title">Message Student :</span>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  value={studentMessageDecline}
                                  onChange={(e) =>
                                    setStudentMessageDecline(e.target.value)
                                  }
                                />
                              </li>
                            </ul>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <>
                <button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={handleSendResponseDecline}
                  title="Save"
                >
                  <i className="fal fa-check"></i>
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  title="Cancel"
                >
                  <i className="fal fa-times"></i>
                  Cancel
                </button>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Appeals;
