// this component only holds the creation api 
// the booking form is a common component that is being used by the CreateBooking.js and EditBooking.js 
// the form api's and everything related to form are kept in the BookingForm.js 
// We use the callback concept here that brings the form values in this component

import Swal from "sweetalert2";
import { AddBooking } from "../../../services/BookingServices";
import BookingForm from "./BookingForm";
import React from "react";
import { useSelector } from 'react-redux';
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";


export default function CreateBooking() {
    const user = useSelector((state) => state.user);
    const { user_id, role_name } = user;
    const history = useHistory();

    const initialValues = {
        name: "",
        campus: "",
        campus_id: 0,
        room_no: "",
        booking_date: "",
        booking_start_time: "",
        booking_end_time: "",
        linked_course: "",
        linked_course_id: 0,
        booking_reason: "",
        request_type: "employee",
        booking_for: [],
        additional_information: ""
    }

    const createBooking = async (values) => {
        try {
            const {
                name,
                campus,
                campus_id,
                room_no,
                booking_date,
                booking_start_time,
                booking_end_time,
                linked_course,
                linked_course_id,
                booking_reason,
                request_type,
                booking_for,
                additional_information,
                room_id
            } = values;

            const formattedDate = moment(booking_date).format('YYYY-MM-DD');
            const formattedStartTime = moment(booking_start_time, 'HH:mm').format('HH:mm:ss');
            const formattedEndTime = moment(booking_end_time, 'HH:mm').format('HH:mm:ss');

            const formData = new FormData();

            formData.append('name', name);
            formData.append('campus', campus);
            formData.append('campus_id', campus_id);
            formData.append('room_no', room_no);
            formData.append('room_id', room_id);
            formData.append('booking_date', formattedDate);
            formData.append('booking_start_time', formattedStartTime);
            formData.append('booking_end_time', formattedEndTime);
            formData.append('linked_course', linked_course);
            formData.append('linked_course_id', linked_course_id);
            formData.append('booking_reason', booking_reason);
            formData.append('request_type', request_type);
            formData.append('booking_for', booking_for);
            formData.append('booking_status', "PENDING APPROVAL");
            formData.append('user_id', user_id);
            formData.append('role_name', role_name);
            formData.append('additional_information', additional_information);

            const response = await AddBooking(formData);
            const inserted_id = response.data.inserted_record;
            Swal.fire({
                title: 'Success',
                text: 'Booking Created Successfully',
                icon: "success"
            });
            history.push(`/campusAdministration/bookings/edit/${inserted_id}`);

        }
        catch (error) {

            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error'
            });
        }
    }
    return (
        <>
            <BookingForm initialValues={initialValues} onSubmit={createBooking} />
        </>
    )
}