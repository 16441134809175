import React, { useState } from "react";
import { useSelector } from "react-redux";

const MainContext = React.createContext();

const MainContextProvider = (props) => {
  const image = useSelector((state) => state.image);
  const userstatus = useSelector((state) => state.userstatus);

  const image_url = image !== null || image.image !== undefined || image.image !== "undefined" ? image.image : "";

  // console.log("userstatus", userstatus.userstatus);

  const [state, setState] = useState(image_url);
  const [status, setStatus] = useState(userstatus.userstatus);

  return <MainContext.Provider value={{ state, setState, status, setStatus }}>{props.children}</MainContext.Provider>;
};

export { MainContextProvider, MainContext };
