import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import $ from "jquery";
import { AssignedSubject, GetAssignedSubjectCancelToken, GetAvailableSubjectCancelToken, GetAvailableSubjectFilters } from "../../../../services/StudentsSettingServices";
import { useHistory, useParams } from "react-router-dom";
import AssignedProgrmasAndCourses from "./CourseTabs/AssignedProgramsAndCourses";
import AvailableQualificationAndCourses from "./CourseTabs/AvailableQualificationAndCourses";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { GetAllDropdownValues } from "../../../../services/RegistrationService";
import axios from "axios";

function CoursesAndProgramme() {
  const { id } = useParams();
  const [availQualAndCourse, setAvailQualAndCourse] = useState();
  const [assignQualAndCourse, setAssignQualAndCourse] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingAvailable, setLoadingAvailable] = useState(true)
  const [intakeArr, setIntakeArr] = useState([]);
  const [totalRows, setTotalRows] = useState(0)
  const [availableSearch, setAvailableSearch] = useState("");
  const [tableState, setTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "start",
    sortOrder: "DESC",
  })
  const [availableIntake, setAvailableIntake] = useState({ arr: [], checkObj: {} });
  const [availableType, setAvailableType] = useState({ arr: [], checkObj: {} })
  const [filterData, setFilterData] = useState({intake : [], type : []})
  const [filterDataAvail, setFilterDataAvail] = useState({intake : [], type : []})
  const [updateData, setUpdateData] = useState(true);

  const history = useHistory();

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({ scopes: ["scpview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getAssignedSubjectList = async () => {
      setLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await GetAssignedSubjectCancelToken(id, source.token);
        if (res.status === 200) {
          setAssignQualAndCourse(res.data.assign_subject ? res.data.assign_subject : []);
          setFilterData({...res.data, type : res.data.programmeTypeFilter, intake : res.data.yearsAndIntaksFilter})
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    getAssignedSubjectList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData])


  useEffect(() => {
    const cancelTokenSources = [];

    const getAvailableSubjectList = async () => {
      setLoadingAvailable(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const apiData = {
          page: tableState.page,
          limit: tableState.perPage,
          key: tableState.sortKey,
          sort: tableState.sortOrder,
          viaSearch: availableSearch,
          viaInatke: availableIntake.arr,
          viaType: availableType.arr,
          exportStatus: "false",
          student_id: id,
        };
        const res = await GetAvailableSubjectCancelToken(apiData, source.token);
        if (res.status === 200) {
          setAvailQualAndCourse(res.data.assign_subject);
          setTotalRows(res.data.pagination.total)
          setLoadingAvailable(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoadingAvailable(false);
        }
      }
    };
  
    getAvailableSubjectList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData, tableState, availableSearch, availableIntake, availableType])

  useEffect(() => {
    GetAllDropdownValues().then((res) => {
      let tempIntakeArr = [];
      res.data.intake_year && res.data.intake_year.length && res.data.intake_number && res.data.intake_number.length && res.data.intake_year.map(year => {
        res.data.intake_number.map(number => {
          tempIntakeArr.push({ label: `${year.label}-${number.label}`, value: `${year.value}/${number.value}` })
        })
      })
      setIntakeArr(tempIntakeArr);
    });
  }, [updateData])

  useEffect(() => {
    GetAvailableSubjectFilters(id).then((res)=>{
      setFilterDataAvail({...res.data, intake : res.data.yearsAndIntaksFilter, type : res.data.courseTypeFilter})
    }).catch(err=>console.log(err))
  }, [])
  


  // function getData() {
  //   setLoading(true);
  //   setAvailQualAndCourse([]);
  //   setAssignQualAndCourse([]);

   
  // }

  function isAlreadyExists(course) {
    // alert(id);
    let found = false;
    assignQualAndCourse.map((elem, ind) => {
      if (elem.id === course.id) {
        found = true;
      }
    });
    return found;
  }

  const onDelete = (course) => {
    assignQualAndCourse.map((elem, ind) => {
      if (elem.id === course.id) {
        assignQualAndCourse.splice(ind, 1);
        setAssignQualAndCourse([...assignQualAndCourse]);
        closeChildModal();
      }
    });
  };

  function assignCourse(course, isAll = false) {
    if (!isAlreadyExists(course)) {
      setAssignQualAndCourse([...assignQualAndCourse, course]);
      closeChildModal();
    } else if (isAll) {
      Swal.fire({
        title: "Are you sure?",
        text: "This qualification is already assigned, are you sure to move this course ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, move it!",
      }).then(() => {
        assignQualAndCourse.map((elem, ind) => {
          if (elem.id === course.id) {
            assignQualAndCourse.splice(ind, 1);
            setAssignQualAndCourse([...assignQualAndCourse, course]);
            closeChildModal();
          }
        });
      });
    } else {
      let subjects = { ...course };

      subjects.sub_item = [];
      let tempArr = [];
      assignQualAndCourse.map((elem, ind) => {
        if (elem.id === course.id) {
          assignQualAndCourse[ind].sub_item.map((e, i) => {
            subjects.sub_item = mergeArray(assignQualAndCourse[ind].sub_item, course.sub_item);
          });
          tempArr = assignQualAndCourse;
          let selectedObj = tempArr.find(item => item.id == course.id)
          const index = tempArr.indexOf(selectedObj);
          if (index !== -1) {
            tempArr[index] = subjects;
          }
          setAssignQualAndCourse([...tempArr]);
          closeChildModal();
        }
      });
    }
  }

  function mergeArray(arr1, arr2) {
    // let uniqueArr = a =>
    arr2.map(item => {
      let included = false;
      for (let i = 0; i < arr1.length; i++) {
        const element = arr1[i];
        if (element.intake_id === item.intake_id) {
          included = true
        }
      }
      if (!included) {
        arr1.push(item)
      }
    })
    return arr1
    // let mergedArr = [...arr1, ...arr2];
    // return [...new Set(mergedArr.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));
    // return [...new Set(mergedArr)];;
  }

  function closeChildModal() {
    $("#viewAvailCourses").modal("hide");
  }

  function assignCourses() {
    Swal.fire({
      title: "Please Wait !",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    let tempArr = [];

    assignQualAndCourse.map((elem, ind) => {
      let obj = {
        id: null,
        children: [],
        programs_course_type: ""
      };
      obj.programs_course_type = elem.programs_course_type;
      obj.id = elem.id;
      if (elem.programs_course_type == "programs") {
        elem.sub_item.map((e, i) => {
          obj.children.push({ ref: obj.id, id: e.intake_id });
        });
      }
      tempArr.push(obj);
    });

    let formData = new FormData();
    formData.append("subjects", JSON.stringify(tempArr));
    formData.append("student_id", id);
    AssignedSubject(formData)
      .then((res) => {
        Swal.close()
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        });
        setUpdateData(!updateData)
      })
      .catch((err) => {
        Swal.close()
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Something went wrong",
        });
        console.log("error ---> ", err);
      });
  }

  return (
    // <div>CourseAndProgrammeTab</div>
    <>
      <div className="card card-profile-info-card p-a-c">
        <div className="">
          <div className="card-body-inr card-body-info">
            <AssignedProgrmasAndCourses data={assignQualAndCourse} setData={setAssignQualAndCourse} onDelete={onDelete} loading={loading} intakeArr={intakeArr} filterData={filterData}/>
            <div
              // ref={editorRef}
              className="topic-add-modal modal fade"
              id="Assign-Programme"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered available-assessment-modal"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-plus"></i> Assign Programme</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
                  <div className="modal-body">
                    <AvailableQualificationAndCourses
                      data={availQualAndCourse}
                      isAlreadyExists={isAlreadyExists}
                      assignCourse={assignCourse}
                      loading={loadingAvailable}
                      intakeArr={intakeArr}
                      availableIntake={availableIntake}
                      setAvailableIntake={setAvailableIntake}
                      availableType={availableType}
                      setAvailableType={setAvailableType}
                      tableState={tableState}
                      setTableState={setTableState}
                      totalRows={totalRows}
                      setSearch={setAvailableSearch}
                      id={id}
                      filterDataAvail={filterDataAvail}
                    />
                  </div>
                </div>
              </div>
            </div>
            {!loading && <div className="btn-sec t-b-s">
              <div className="form-group form-group-save-cancel">
                <button
                  className="btn btn-save btn-success"
                  type="button"
                  title="Save"
                  onClick={() => {
                    assignCourses();
                  }}
                >
                  <i className="fal fa-save"></i>Save
                </button>
                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  title="Cancel"
                  onClick={() => {
                    setUpdateData(!updateData)
                  }}
                >
                  <i className="fal fa-times"></i>Cancel
                </button>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
}

export default CoursesAndProgramme;
