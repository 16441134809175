import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { DeleteAssignedAssemblyResource } from "../../../../services/CourseService";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import { Link } from "react-router-dom";
import { TrimText } from "../../../common/TrimText";
import AvailableResource from "./AvailableResource";
import AssemblyListComponent from "../AssemblyListComponent";

const AssignedResource = ({ data, getData, isLoading2, isLoading3, availableResData, setFetchTable, fetchTable }) => {
  const { id } = useParams();

  // console.log("assigned resources -=-=-=-=-> ", data);

  const history = useHistory();
  const [search, setSearch] = useState("");
  // const [isLoading, setIsLoading] = useState(true);

  const deleteresource = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteAssignedAssemblyResource({ assign_id: row.id }).then((res) => {
          Swal.fire("Deleted!", `${res?.data?.message}`, "success");
          // getData();
          setFetchTable(!fetchTable)
        })
      }
    }).catch(error => { console.log(error) });

  }

  const sortingName = (rowA, rowB) => {
    // const name1 = rowA?.item_detail?.item_name
    // const name2 = rowB?.item_detail?.item_name
    const name1 = rowA && rowA.item_detail && rowA.item_detail.item_name ? rowA.item_detail.item_name : ""
    const name2 = rowB && rowB.item_detail && rowB.item_detail.item_name ? rowB.item_detail.item_name : ""
    return name1.localeCompare(name2);
  };

  const columns2 = useMemo(() => [
    // {
    //   name: "ID",
    //   selector: "item_id",
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="ticket-id">
    //       <span className="overflow-ellipsis2">
    //         <b>#{row.item_id}</b>
    //       </span>
    //     </div>
    //   ),
    // },
    {
      name: "Category",
      selector: "item_detail",
      sortFunction: sortingName,
      cell: (row) => (
        <div className="assigned-title-block-new">
          <div className="assigned-title-itm">
            <div>
              <Link className="as-text-blue curser" to={"/courses/content/resource-pool/open/" + row.item_id} title={row && row.item_detail && row.item_detail.item_name ? row.item_detail.item_name : ""}>
                {row && row.item_detail && row.item_detail.item_name ? TrimText(row.item_detail.item_name) : "-"}
              </Link>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: "Resources",
      selector: "Category",
      cell: (row) => (<span title={row.item_detail?.__meta__ ? row.item_detail?.__meta__?.items_count : "0"}>{row.item_detail?.__meta__ ? row.item_detail?.__meta__?.items_count : "0"}</span>),
    },
    {
      name: "Linked Assemblies",
      selector: "LinkedAssemblies",
      cell: (row) => (<span title={row.item_detail?.__meta__ ? row.item_detail?.__meta__?.assembly_count : "0"}>{row.item_detail?.__meta__ ? row.item_detail?.__meta__?.assembly_count : "0"}</span>),
    },
    {
      name: "Action",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <Link className="btn btn-primary rounded-circle" title="Open" to={"/courses/content/resource-pool/open/" + row.item_id}>
              <i className="fal fa-folder-open"></i>
            </Link>
            <button className="btn btn-danger rounded-circle" title="Delete" onClick={() => deleteresource(row)}>
              <i className="fal fa-trash-alt"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const dataToRender2 = () => {
    let updatedData = [];
    let allData = data;
    if (search.length) {
      let tempCategory = allData.filter((item) => {
        let includes = item.item_detail.item_name.toString().toLowerCase().includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [...tempCategory];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = data;
    }

    if (search.length) {
      return updatedData;
    } else {
      return data;
    }
    // return allData;
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender2();
    const header = ["Category", "Resources", "Linked Assemblies"];

    data = data?.map((row) => {
      return ({
        ...row,
        Category: row?.item_detail?.item_name ? row?.item_detail?.item_name : "N/A",
        Resources: row?.item_detail?.__meta__?.items_count ? row?.item_detail?.__meta__?.items_count : "N/A",
        "Linked Assemblies": row?.item_detail?.__meta__?.assembly_count ? row?.item_detail?.__meta__?.assembly_count : "N/A",
      })
    });
    // console.log(typeof data);
    // console.log(typeof result);

    if (fileType === "csv") {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === "xlsx") {
      const compatibleData = data.map((row) => {
        const obj = {};
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === "pdf") {
      const compatibleData = data.map((row) => {
        return [row.Category, row.Resources, row["Linked Assemblies"]];
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: "left",
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch("");
  };

  return (
    <>
      
        {/* <div className="filter-search-bar-blk">
          <div className="filter-button-group w-100">
            <div className="filter-eff filter-data-btn">
              <button className="filter-buttons">
                <i className="fal fa-filter"></i>
              </button>
            </div>

            <div className="button-reset dropdown-comman">
              <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                <i className="fal fa-redo"></i>Reset
              </button>
            </div>

            <div className="files-export-group">
              <button
                type="button"
                className="btn btn-files"
                onClick={() => { exportData("xlsx", "Assigned Resources"); }}
                title="Export spreadsheet"
              >
                <i className="fal fa-file-excel icon"></i>
              </button>

              <button
                type="button"
                className="btn btn-files"
                onClick={() => { exportData("csv", "Assigned Resources"); }}
                title="Export CSV"
              >
                <i className="fal fa-file-csv icon"></i>
              </button>

              <button
                type="button"
                className="btn btn-files"
                onClick={() => { exportData("pdf", "Assigned Resources"); }}
                title="Export PDF"
              >
                <i className="fal fa-file-pdf icon"></i>
              </button>
            </div>
            <div className="add-ticket-blk button-reset dropdown-comman">
              <button className="btn btn-primary" data-toggle="modal" data-target="#availResModal" title="Available Resources" >
                <i className="fal fa-plus"></i>Available Resources
              </button>
            </div>
          </div>
        </div> */}
        <AssemblyListComponent id_assembly={id}/>
        {/* <div className="system-administration-table table-responsive">
          <div className="table-responsive-div">
            <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
              <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                <div id="assessment-table-main_filter" className="dataTables_filter">
                  <label>
                    <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={handleSearchFilter} value={search} />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* {isLoading2 ? <SkeletonTicketList /> : <DataTable data={dataToRender2()} columns={columns2} pagination={true} defaultSortAsc={true} defaultSortField="item_detail" />} */}
      {/* Modal to show table of child courses */}
      {/* <div className="topic-add-modal modal fade" id="availResModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered pmc-available-modal c-a-a-r" role="document" >
          <div className="modal-content">
            <div className="modal-body">
              <AvailableResource data={availableResData} getData={getData} isLoading3={isLoading3} />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default AssignedResource;
