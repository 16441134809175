import React, { useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { SendMenualNotification,StudentGeneralNotificationPreview,UpdateStudentGeneralDetails } from "../../../../../services/StudentsSettingServices";
import Swal from "sweetalert2";
import PermissionsGate from "../../../../../utils/permissionGate";

export default function EmailTab({ studentData, smsLog, emailLog, statusLog, loading, updateData, setUpdateData, brandError, setBrandError }) {
    const history = useHistory();
    const { id } = useParams();
    const [disabled, setDisabled] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            EmailNotification: false,
            emailNotification: false,
        },
        onSubmit: (values) => {
            setDisabled(true);
            let formData = new FormData();
            formData.append("student_id", id);
            formData.append("student_no", values.StudentNumber);
            formData.append("student_status", values.StudentStatus);
            formData.append("brand", values.Brand);
            UpdateStudentGeneralDetails(formData)
                .then((res) => {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Updated successfully",
                    });
                    setDisabled(false);
                    setUpdateData(!updateData);
                })
                .catch((err) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                    setDisabled(false);
                    setUpdateData(!updateData);
                    console.log("err", err);
                });
        },
    });

    const handleSendNotification = async () => {
        if (formik.values.EmailNotification) {
            const formData = new FormData();
            formData.append("student_id", id);
            formData.append("email_notify", 1);
            SendMenualNotification(formData)
                .then((res) => {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Send successfully",
                    });
                    setUpdateData(!updateData);
                })
                .catch((err) => console.log("err", err));
        } else {
            formik.setErrors({
                EmailNotification: "Please Check Email Notification",
            });
        }
    };

    const handlePreviewNotification = async () => {
        const params = {
            student_id: id,
        }
        StudentGeneralNotificationPreview(params)
            .then((res) => {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Send successfully",
                });
                setUpdateData(!updateData);
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: err.response.data[0].message,
                });
                console.log("err", err)
            });
    };

    return (
        <div className="my-tickets-info-list Tickets-main-wrap">
            <div className="edit-icon new-card-header">
                <div className="card-header">Manual Notification</div>
                {loading && <i className="fas fa-cog fa-spin ml-2"></i>}
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <div className="form-group-blk mb-3">
                            <div className="custom-check custom-control custom-checkbox" title="Email Notification">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="EmailNotification"
                                    name="select"
                                    checked={formik.values.EmailNotification}
                                    onChange={(e) => formik.setFieldValue("EmailNotification", e.target.checked)}
                                />
                                <label className="custom-control-label" htmlFor="EmailNotification">
                                    Email Notification
                                </label>
                            </div>
                            <p>{emailLog}</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        {/* <label>&nbsp;</label> */}
                        <div className="form-group form-group-save-cancel">
                            <PermissionsGate errorProps={{ disabled: true }} scopes={["sgiedit"]}>
                                <button
                                    className="btn btn-save btn-primary"
                                    type="button"
                                    title="Send Manual Notification"
                                    disabled={!formik.values.EmailNotification}
                                    onClick={() => {
                                        handleSendNotification();
                                    }}
                                >
                                    <i className="fa fa-paper-plane"></i>
                                    Send Manual Notification
                                </button>
                            </PermissionsGate>
                            <PermissionsGate errorProps={{ disabled: true }} scopes={["sgiedit"]}>
                                <button
                                    className="btn btn-save btn-primary"
                                    type="button"
                                    title="Preview"
                                    disabled={loading}
                                    onClick={() => {
                                        handlePreviewNotification();
                                    }}
                                >
                                    <i className="fal fa-file-alt"></i>
                                    Preview
                                </button>
                            </PermissionsGate>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
