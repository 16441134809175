import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { useHistory, useParams } from "react-router";
import {
  IMAGE_URL,
  TABLE_DATE_FORMAT,
  TABLE_DATE_TIME_FORMAT,
  TABLE_ROWS_PER_PAGE,
  TABLE_TIME_FORMAT,
} from "../../utils/Constants";
import { InitialRender } from "../common/Helper";
import SkeletonTicketList from "../../loaders/SkeletonTicketList";
import PermissionsGate from "../../utils/permissionGate";
import Swal from "sweetalert2";
import Hover from "../common/Hover";
import {
  GetDropdownCategory,
  GetDropdownDepartments,
  GetDropdownLecturer,
  GetListTicketFilters,
  GetListTicketFiltersCancelToken,
  GetlistTicketForDashboard,
  GetlistTicketForDashboardCancelToken,
} from "../../services/DashBoardServices";
import { TrimText } from "../common/TrimText";
import { checkIfImageExists, handleTableScroll } from "../../utils/commonFunction";
import { Link } from "react-router-dom";
import Tablefilter from "../common/Tablefilter";
import {
  RenderProgrammeStudentStatus,
  RenderTicketStatus,
} from "../../utils/CommonStatusItems";
import {
  RenderCategory,
  RenderDepartment,
  RenderTicketLevel,
} from "../../utils/CommonGroupingItem";
import HtmlParser from "react-html-parser";
import axios from "axios";

const stc = require("string-to-color");

const Tickets = () => {
  const history = useHistory();
  const { tab } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [search, setSearch] = useState("");
  const [department, setDepartment] = useState({ arr: [], checkObj: [] });
  const [category, setCategory] = useState({ arr: [], checkObj: [] });
  const [assignedTo, setAssignedTo] = useState({ arr: [], checkObj: [] });
  const [level, setLevel] = useState({ arr: [], checkObj: {} });
  const [studentstatus, setStudentStatus] = useState({ arr: [], checkObj: {} });

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [sortkey, setSortKey] = useState("dueDays");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterData, setFilterData] = useState({
    level: [],
    sStatus: [],
    department: [],
    category: [],
    assignTo: [],
  });
  const [showing, setShowing] = useState([10]);
  const [searchShowing, setSearchShowing] = useState({ action41: true });
  const [debouncedTerm, setDebouncedTerm] = useState("");

  useEffect(() => {
    handleTableScroll()
  }, [loading])
  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [debouncedTerm]);

  useEffect(() => {
    const cancelTokenSources = [];

    const fetchUsers = async () => {
      setLoading(true);

      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      let params = {
        pageNo: page,
        size: perPage,
        viaStudentStatus: studentstatus.arr,
        viaLevel: level.arr,
        viaDepartment: department.arr,
        viaCategory: category.arr,
        viaAssignedTo: assignedTo.arr,
        viaSearch: search,
        key: sortkey,
        sort: sortOrder,
        exportStatus: false,
        viaTicket: [tab],
        showing: tab === "all" ? showing : 0,
      };

      try {
        const res = await GetlistTicketForDashboardCancelToken(
          params,
          source.token
        );
        setData(res?.data?.data);
        setTotalRows(res?.data?.total);
        if (res.status === 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };

    fetchUsers();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [
    tab,
    page,
    perPage,
    department,
    search,
    assignedTo,
    level,
    studentstatus,
    sortkey,
    sortOrder,
    category,
    showing,
  ]);

  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateTickets"));
    if (storedFilters) {
      setFilterData(storedFilters.filterData);      
      setAssignedTo(storedFilters.assignedTo);
      setCategory(storedFilters.category);
      setLevel(storedFilters.level);
      setStudentStatus(storedFilters.studentstatus);
      setDepartment(storedFilters.department);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      department,
      assignedTo,
      category,
      level,
      studentstatus,
      filterData
    };
    localStorage.setItem("filterStateTickets", JSON.stringify(filterState));
  }, [ department, assignedTo, category, level, studentstatus, filterData]);


  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const res = await GetListTicketFiltersCancelToken(
          tab,
          dropdownSource.token
        );
        let tempArr = [];
        res.data.categoryFilter.length &&
          res.data.categoryFilter.map((item) => {
            tempArr.push({
              value: item.value,
              normalLabel: item.label,
              label: HtmlParser(
                `<span class="custom-filter-selected cat " style="color:${stc(
                  item.value.toString().toLowerCase().replace("-", "")
                )};" title="${
                  item.label
                }"><i class="fas fa-circle mr-1"></i> <span>${
                  item.label
                }</span></span>`
              ),
            });
          });
        let tempDepartArr = [];
        res.data.departmentFilter.length &&
          res.data.departmentFilter.map((item) => {
            tempDepartArr.push({
              value: item.value,
              normalLabel: item.label,
              label: HtmlParser(
                `<span class="custom-filter-selected cat " style="color:${stc(
                  item.value.toString().toLowerCase().replace("-", "")
                )};" title="${
                  item.label
                }"><i class="fas fa-circle mr-1"></i> <span>${
                  item.label
                }</span></span>`
              ),
            });
          });
        setFilterData({
          level: [],
          sStatus: [],
          category: [],
          department: [],
          assignTo: [],
        });
        setFilterData({
          ...res.data,
          level: res.data.levelFilter ? res.data.levelFilter : [],
          sStatus: res.data.studentStatusFulter
            ? res.data.studentStatusFulter
            : [],
          category: tempArr ? tempArr : [],
          department: tempDepartArr ? tempDepartArr : [],
          assignTo: res.data.assignToFilter ? res.data.assignToFilter : [],
        });
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();

    return () => {
      dropdownSource.cancel("Component unmounted");
    };
  }, [tab]);

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "AESC" : "DESC");
  };

  const columns = useMemo(() => [
    {
      name: "Ticket Number",
      selector: "id_ticket",
      sortable: true,
      sortField: "id_ticket",
      cell: (row) => (
        <span className="feature-name">
          <span className="textLimit100">{row.id_ticket}</span>
        </span>
      ),
    },
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      wrap: true,
      sortField: "subject",
      cell: (row) => (
        <Link
          className="as-text-blue curser"
          to={`/editticket/${row.id_ticket}`}
        >
          <span className="textLimit50" title={row.subject}>
            {row.subject ? row.subject : "-"}
          </span>
        </Link>
      ),
    },
    {
      name: "Submitted By",
      selector: "student_first_name",
      sortable: true,
      sortField: "student_first_name",
      cell: (row) =>
        row.student_first_name && row.student_last_name ? (
          <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span className={"assigned-title-blk  name-icon cat-dark-red "}>
                {row.imgUrl &&
                checkIfImageExists(
                  `${IMAGE_URL}/${row.imgUrl
                    .replaceAll("/home/myaie/public_html/", "")
                    .replaceAll("public/", "")}`
                ) ? (
                  <img
                    src={`${IMAGE_URL}/${row.imgUrl
                      .replaceAll("/home/myaie/public_html/", "")
                      .replaceAll("public/", "")}`}
                    alt="AIE"
                  />
                ) : (
                  InitialRender([row.student_first_name, row.student_last_name])
                )}
                <span
                  className={`profile-box-2-status ${
                    row.submited_activity_status &&
                    row.submited_activity_status.toLowerCase() == "online"
                      ? "Online"
                      : "Offline"
                  }`}
                >
                  <i className="fas fa-circle"></i>
                </span>
                <Hover
                  firstName={row?.student_first_name}
                  lastName={row?.student_last_name}
                  photo={row.imgUrl}
                  email={row?.student_email}
                  mobile={row.student_mobile}
                  right={true}
                  status={row.submited_status}
                  activity_status={row.submited_activity_status}
                  showNumber={true}
                  number={row.student_crm_id}
                />
              </span>
              <span
                className="textLimit50"
                title={`${row?.student_first_name} ${row.student_last_name}`}
              >
                {" "}
                {`${row?.student_first_name} ${row.student_last_name}`}
              </span>
            </div>
          </div>
        ) : (
          "-"
        ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      sortField: "status",
      cell: (row) => RenderTicketStatus(row.status).html,
    },
    {
      name: "Level",
      selector: "level",
      sortable: true,
      sortField: "level",
      cell: (row) => (row.level ? RenderTicketLevel(row.level).html : "-"),
    },
    {
      name: "Student Status",
      selector: "sstatus",
      sortable: true,
      sortField: "sstatus",
      cell: (row) => RenderProgrammeStudentStatus(row.sstatus).html,
    },
    {
      name: "Department",
      selector: "department",
      wrap: true,
      sortable: true,
      sortField: "department",
      cell: (row) =>
        row.department ? (
          <span className="textLimit76">
            {RenderDepartment(row.department).html}
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Days Overdue",
      selector: "dueDays",
      sortable: true,
      sortField: "dueDays",
      cell: (row) =>
        row.dueDays ? (
          <span title={row.dueDays}>{TrimText(row.dueDays, 20)}</span>
        ) : (
          "-"
        ),
    },
    {
      name: "Assign To",
      selector: "Firstname",
      sortable: true,
      sortField: "Firstname",
      cell: (row) =>
        row.Firstname ? (
          <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span className={"assigned-title-blk  name-icon cat-dark-red "}>
                {row.picture_me &&
                checkIfImageExists(
                  `${IMAGE_URL}/${row.picture_me
                    .replaceAll("/home/myaie/public_html/", "")
                    .replaceAll("public/", "")}`
                ) ? (
                  <img
                    src={`${IMAGE_URL}/${row.picture_me
                      .replaceAll("/home/myaie/public_html/", "")
                      .replaceAll("public/", "")}`}
                    alt="AIE"
                  />
                ) : (
                  InitialRender([
                    row.Firstname ? row.Firstname : "N",
                    row.Lastname ? row.Lastname : "A",
                  ])
                )}
                <span
                  className={`profile-box-2-status ${
                    row.assign_to_activity_status &&
                    row.assign_to_activity_status.toLowerCase() == "online"
                      ? "Online"
                      : "Offline"
                  }`}
                >
                  <i className="fas fa-circle"></i>
                </span>
                <Hover
                  firstName={row?.Firstname}
                  lastName={row?.Lastname}
                  photo={row.picture_me}
                  email={row?.Email}
                  mobile={row.Mobile}
                  right={true}
                  status={row.assign_to_status}
                  activity_status={row.assign_to_activity_status}
                />
              </span>
              <span
                onClick={() =>
                  history.push(
                    `/systemAdministration/userManagement/open/${row.assign_to}/aboutYou`
                  )
                }
                className="as-text-blue curser textLimit76"
                title={
                  row.Firstname
                    ? `${row.Firstname} ${row.Lastname ? row.Lastname : ""}`
                    : ""
                }
              >
                {row.Firstname
                  ? `${row.Firstname} ${row.Lastname ? row.Lastname : ""}`
                  : "-"}
              </span>
            </div>
          </div>
        ) : (
          "-"
        ),
    },
    {
      name: "Category",
      selector: "category",
      wrap: true,
      sortable: true,
      sortField: "category",
      cell: (row) =>
        row.category ? (
          <span className="feature-name">
            {" "}
            <span className="textLimit100" title={row.category}>
              {RenderCategory(row.category).html}
            </span>
          </span>
        ) : (
          "-"
        ),
    },
    {
      name: "Created",
      selector: "date_add",
      sortable: true,
      sortField: "date_add",
      cell: (row) => (
        <>
          <p>{moment(row.date_add).format(TABLE_DATE_FORMAT + ",")}</p>
          <p>{moment(row.date_add).format(TABLE_TIME_FORMAT)}</p>
        </>
      ),
    },
    {
      name: "Modified",
      selector: "last_update",
      sortable: true,
      sortField: "last_update",
      cell: (row) => (
        <>
          <p>{moment(row.last_update).format(TABLE_DATE_FORMAT + ",")}</p>
          <p>{moment(row.last_update).format(TABLE_TIME_FORMAT)}</p>
        </>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            <Link
              title="Open"
              className="btn btn-primary rounded-circle"
              to={"/editticket/" + row.id_ticket}
            >
              <i className="fal fa-folder-open"></i>
            </Link>
          </div>
        </div>
      ),
    },
  ]);

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  const resetFilter = () => {
    setDepartment({ arr: [], checkObj: [] });
    setAssignedTo({ arr: [], checkObj: [] });
    setCategory({ arr: [], checkObj: [] });
    setLevel({ arr: [], checkObj: {} });
    setStudentStatus({ arr: [], checkObj: {} });
    setSearch("");
    setDebouncedTerm("");
    setShowing([10]);
    setSearchShowing({ action41: true });
  };

  const exportData = (fileType, fileName) => {
    let data;

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const header = [
      "Ticket Number",
      "Subject",
      "Submitted By",
      "Status",
      "Level",
      "Student Status",
      "Department",
      "Days Overdue",
      "Assigned To",
      "Category",
      "Created",
      "Modified",
    ];
    GetlistTicketForDashboard({
      page: page,
      size: perPage,
      key: sortkey,
      sort: sortOrder,
      exportStatus: "true",
      viaDepartment: department.arr,
      viaLevel: level.arr,
      viaStudentStatus: studentstatus.arr,
      viaCategory: category.arr,
      viaAssignedTo: assignedTo.arr,
      viaSearch: search,
      viaTicket: [tab],
      showing: tab === "all" ? showing : 0,
    })
      .then((res) => {
        data = res?.data;
        data = data?.map((row) => ({
          ...row,
          "Ticket Number": row?.id_ticket ? row?.id_ticket : "-",
          Subject: row?.subject ? row?.subject : "-",
          Status: RenderTicketStatus(row?.status).text,
          Department: row?.department
            ? RenderDepartment(row?.department).text
            : "-",
          "Submitted By": `${row.student_first_name} ${row.student_last_name}`,
          Created: row.date_add
            ? moment(row.date_add).format(TABLE_DATE_TIME_FORMAT)
            : "-",
          Modified: row.last_update
            ? moment(row.last_update).format(TABLE_DATE_TIME_FORMAT)
            : "-",
          "Assigned To": `${row.Firstname} ${row.Lastname}`,
          "Student Status": row.sstatus
            ? RenderProgrammeStudentStatus(row.sstatus).text
            : "-",
          Category: row.category,
          "Days Overdue": row.dueDays ? row.dueDays : "-",
          Level: row.level ? row.level : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          // console.log(csvString);
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);

          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Ticket Number"],
              row["Subject"],
              row["Submitted By"],
              row["Status"],
              row["Level"],
              row["Student Status"],
              row["Department"],
              row["Days Overdue"],
              row["Assigned To"],
              row["Category"],
              row["Created"],
              row["Modified"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);

          Swal.close();
          return false;
        }
        Swal.close();
      })
      .catch((err) => {
        Swal.close();
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.message}`,
        });
      });
  };

  const handleShowingFilter = (e) => {
    const value = e.target.value;
    if (showing.includes(value)) {
      setShowing([]);
    } else {
      setShowing([value]);
    }

    let id = e.target.id;
    if (id == "action41") {
      setSearchShowing({ action41: !searchShowing.action41 });
    } else {
      setSearchShowing({
        [id]: !searchShowing[id],
        action41: false,
      });
    }
  };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="custom-table-div filter-search-icon card card-table-custom ">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={debouncedTerm}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="filter-scroll">
                    <div className={`filter-scroll-inner  filter-custom-new`}>
                      {tab == "all" && (
                        <div className="assessment-table-filter">
                          <div className="btn-type-filter dropdown-comman">
                            <div className="dropdown multiselect">
                              <button
                                className={`btn btn-default dropdown-toggle ${
                                  showing.length ? "btn-selected" : ""
                                }`}
                                type="button"
                                id="dropdownMenu5"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                title="Showing"
                              >
                                <span>
                                  Showing <i className="fal fa-angle-down"></i>
                                </span>
                              </button>
                              <form className="dropdown-menu remove-list-style">
                                <ul aria-labelledby="dropdownMenu5">
                                  <li className="">
                                    <input
                                      type="checkbox"
                                      id="action41"
                                      value="10"
                                      onClick={handleShowingFilter}
                                      checked={searchShowing["action41"]}
                                    />
                                    <label htmlFor="action41">
                                      <span className="cat ">10 days</span>
                                    </label>
                                  </li>
                                  <li className="">
                                    <input
                                      type="checkbox"
                                      id="action42"
                                      value="20"
                                      onClick={handleShowingFilter}
                                      checked={searchShowing["action42"]}
                                    />
                                    <label htmlFor="action42">
                                      <span className="cat ">20 days</span>
                                    </label>
                                  </li>
                                  <li className="">
                                    <input
                                      type="checkbox"
                                      id="action43"
                                      value="30"
                                      onClick={handleShowingFilter}
                                      checked={searchShowing["action43"]}
                                    />
                                    <label htmlFor="action43">
                                      <span className="cat ">30 days</span>
                                    </label>
                                  </li>
                                  <li className="">
                                    <input
                                      type="checkbox"
                                      id="action45"
                                      value="90"
                                      onClick={handleShowingFilter}
                                      checked={searchShowing["action45"]}
                                    />
                                    <label htmlFor="action45">
                                      <span className="cat ">90 days</span>
                                    </label>
                                  </li>
                                  <li className="">
                                    <input
                                      type="checkbox"
                                      id="action44"
                                      value="-1"
                                      onClick={handleShowingFilter}
                                      checked={searchShowing["action44"]}
                                    />
                                    <label htmlFor="action44">
                                      <span className="cat ">All</span>
                                    </label>
                                  </li>
                                </ul>
                              </form>
                            </div>
                          </div>
                        </div>
                      )}
                      <Tablefilter
                        filterName="Level"
                        optionArr={filterData.level}
                        state={level}
                        setState={setLevel}
                        renderLabelFunction={RenderTicketLevel}
                      />

                      <Tablefilter
                        filterName="Student Status"
                        optionArr={filterData.sStatus}
                        state={studentstatus}
                        setState={setStudentStatus}
                        renderLabelFunction={RenderProgrammeStudentStatus}
                      />
                      <Tablefilter
                        filterName="Department"
                        optionArr={filterData.department}
                        state={department}
                        setState={setDepartment}
                        renderLabelFunction={RenderDepartment}
                        
                      />
                      <Tablefilter
                        filterName="Category"
                        optionArr={filterData.category}
                        state={category}
                        setState={setCategory}
                        renderLabelFunction={RenderCategory}
                        
                      />
                      <Tablefilter
                        filterName="Assigned To"
                        optionArr={filterData.assignTo}
                        state={assignedTo}
                        setState={setAssignedTo}
                        
                      />
                    </div>
                  </div>
                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman" title="Reset">
                      <button className="btn btn-primary" onClick={resetFilter}>
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>

                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        title="Export spreadsheet"
                        onClick={() => {
                          exportData("xlsx", "Tickets");
                        }}
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        title="Export CSV"
                        onClick={() => {
                          exportData("csv", "Tickets");
                        }}
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        title="Export PDF"
                        onClick={() => {
                          exportData("pdf", "Tickets");
                        }}
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
                <PermissionsGate
                  errorProps={{ disabled: true }}
                  scopes={["tadd"]}
                >
                  <div
                    className="add-ticket-blk button-reset dropdown-comman"
                    title="New Ticket"
                  >
                    <Link className="btn btn-primary" to={"/addticket"}>
                      <i className="fal fa-plus"></i>New Ticket
                    </Link>
                  </div>
                </PermissionsGate>
              </div>
            </div>
          </div>

          <DataTable
            defaultSortField="dueDays"
            defaultSortAsc={false}
            onSort={handleSort}
            data={data}
            columns={columns}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        </div>
      </div>
    </>
  );
};

export default Tickets;
