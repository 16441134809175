import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SkeletonTicketList from '../../../loaders/SkeletonTicketList';
import { ShowUsers, StyledTableCell } from './Custom';
import { Link } from 'react-router-dom';
import { ColorRender } from '../../systemadministration/usermanagement/CheckRole';
import {
  GetListofSchedules,
  GetListofScheduleBookings,
} from '../../../services/ScheduleManagementService';
import { TABLE_DATE_FORMAT, IMAGE_URL } from '../../../utils/Constants';
import { StyledPagination } from './Custom';
import { InitialRender } from '../../common/Helper';
import { ScheduleListModal } from './ListScheduleModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const ListofSchedules = ({
  calendarEventDay = 'today',
  campusId__,
  roomId__,
  lecturerId__,
  courseId__,
  onExportData,
}) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [listofSchedules, setListofSchedules] = useState([]);
  const [originalScheduleData, setOriginalScheduleData] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [totalRecords_, setTotalRecords] = useState(0);
  const [lastPage_, setLastPage_] = useState(1);
  const [classSchedule, setClassSchedule] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalPosition, setModalPosition] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const history = useHistory();

  const groupByDate = (data, dateKey) => {
    return data.reduce((acc, item) => {
      const date = item[dateKey];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
  };

  useEffect(() => {
    const getListofSchedules = async () => {
      try {
        setIsDataLoaded(false);

        const scheduleListResponse = await GetListofSchedules(
          page,
          perPage,
          calendarEventDay,
          campusId__,
          roomId__,
          lecturerId__,
          courseId__
        );
        // const bookingListResponse = [];
        const bookingListResponse = await GetListofScheduleBookings(
          page,
          perPage,
          calendarEventDay,
          campusId__,
          roomId__,
          courseId__,
          lecturerId__
        );

        const scheduleData = scheduleListResponse?.data?.classes ?? [];
        const bookingData = bookingListResponse?.data?.data ?? [];
        const studentCount = scheduleListResponse?.data?.classCount ?? [];
        const classesTotal = scheduleListResponse?.data?.total;
        const bookingTotal = bookingListResponse?.data?.total;
        const classesLastPage = scheduleListResponse?.data.lastPage;
        const bookingLastPage = bookingListResponse?.data?.lastPage;

        const totalrecords = classesTotal + bookingTotal;
        const lastPage = Math.max(classesLastPage, bookingLastPage);

        const groupedSchedules = groupByDate(scheduleData, 'class_date');
        const groupedBookings = groupByDate(bookingData, 'booking_date');

        const day = new Date().toISOString().split('T')[0];

        let combinedData = {};

        if (calendarEventDay === 'today') {
          const todaySchedules = groupedSchedules[day] || [];
          const todayBookings = groupedBookings[day] || [];

          combinedData[day] = {
            schedules: todaySchedules.map((schedule, index) => ({
              ...schedule,
              studentCount: studentCount[index] || 0,
            })),
            bookings: todayBookings,
          };
        } else if (calendarEventDay === 'upcoming') {
          Object.keys(groupedSchedules).forEach((date) => {
            if (date > day) {
              combinedData[date] = {
                schedules: groupedSchedules[date].map((schedule, index) => ({
                  ...schedule,
                  studentCount: studentCount[index] || 0,
                })),
                bookings: groupedBookings[date] || [],
              };
            }
          });
          Object.keys(groupedBookings).forEach((date) => {
            if (date > day && !combinedData[date]) {
              combinedData[date] = {
                schedules: [],
                bookings: groupedBookings[date],
              };
            }
          });
        } else if (calendarEventDay === 'past') {
          Object.keys(groupedSchedules).forEach((date) => {
            if (date < day) {
              combinedData[date] = {
                schedules: groupedSchedules[date].map((schedule, index) => ({
                  ...schedule,
                  studentCount: studentCount[index] || 0,
                })),
                bookings: groupedBookings[date] || [],
              };
            }
          });
          Object.keys(groupedBookings).forEach((date) => {
            if (date < day && !combinedData[date]) {
              combinedData[date] = {
                schedules: [],
                bookings: groupedBookings[date],
              };
            }
          });
        }

        const combinedDataArray = Object.entries(combinedData)
          .map(([date, data]) => ({
            date,
            ...data,
          }))
          .sort((a, b) => {
            if (
              calendarEventDay === 'upcoming' ||
              calendarEventDay === 'today'
            ) {
              return a.date > b.date ? 1 : -1;
            } else if (calendarEventDay === 'past') {
              return a.date > b.date ? -1 : 1;
            }
          });

        setListofSchedules(combinedDataArray);
        setOriginalScheduleData(combinedDataArray);
        onExportData(combinedDataArray);
        setTotalRecords(totalrecords);
        setLastPage_((prevLastPage) =>
          prevLastPage !== lastPage ? lastPage : prevLastPage
        );

        setIsDataLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };
    getListofSchedules();
  }, [
    calendarEventDay,
    campusId__,
    roomId__,
    perPage,
    page,
    lecturerId__,
    courseId__,
    // lastPage_
  ]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(1);
  };

  const isEmpty = listofSchedules.every(
    (item) => item.schedules.length === 0 && item.bookings.length === 0
  );

  const handleClassModal = (event, schedule) => {
    const rect = event.target.getBoundingClientRect();
    const position = {
      x: rect.left,
      y: rect.top,
    };
    setClassSchedule({ ...schedule, type: 'class' });
    setModalPosition(position);
    setOpenModal(true);
  };

  const handleBookingModal = (event, schedule) => {
    const rect = event.target.getBoundingClientRect();
    const position = {
      x: rect.left,
      y: rect.top,
    };
    setClassSchedule({ ...schedule, type: 'booking' });
    setModalPosition(position);
    setOpenModal(true);
  };

  const toggleShowAll = (showAll__) => {
    setShowAll(showAll__);
    setOpenModal(false);
  };


  return (
    <>
      {!isDataLoaded && <SkeletonTicketList />}
      <div className="Class-Shedule-List-View mb-4">
        <div className="table-responsive">
          {isDataLoaded && isEmpty && (
            <>
              <table className="table table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>No classes or events found.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="class-list-row">
                    <td>
                      We could not find any events. Please confirm with your
                      Support Team or try again later.
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {isDataLoaded && !isEmpty && (
            <table className="table Class-Shedule-main-table table table-bordered">
              <tbody>
                {listofSchedules.length > 0 &&
                  listofSchedules.map((item, index) => (
                    <>
                      <tr className="thead-dark" key={index}>
                        <th colSpan="6">
                          {moment(item.date).format(
                            `dddd, ${TABLE_DATE_FORMAT}`
                          )}
                        </th>
                      </tr>
                      {item.schedules.length > 0 &&
                        item.schedules?.map((schedule, scheduleIndex) => (
                          <>
                            <tr
                              className="class-list-row class-past-row schedule-list-first schedule-list-rest"
                              key={`${index}-${scheduleIndex}`}
                              onClick={(event) =>
                                !showAll && handleClassModal(event, schedule)
                              }
                            >
                              <td>
                                {moment(schedule.class_from, 'h:mm a').format(
                                  'HH:mm A'
                                )}{' '}
                                -{' '}
                                {moment(schedule.class_to, 'h:mm a').format(
                                  'HH:mm A'
                                )}
                              </td>
                              <td>
                                <h6 className="schedule-meeting-title">
                                  <StyledTableCell
                                    class_name={schedule.class_title ?? ''}
                                    mode={schedule.class_type ?? ''}
                                    campus_room={schedule.multiple_room ?? []}
                                  />
                                </h6>
                              </td>
                              <td>
                                <Link
                                  className="as-text-blue curser textLimit2"
                                  to={`/courseAdministration/coursesdetails/${schedule.intake_id}/detailCourses/show`}
                                  title={schedule.course_name}
                                >
                                  {schedule.course_name
                                    ? schedule.course_name
                                    : '-'}
                                </Link>
                              </td>
                              <td style={{ width: '10%' }}>
                                <i
                                  className="fal fa-users mr-2"
                                  title="Student attendance"
                                ></i>
                                {schedule.studentCount.total ?? 0}
                              </td>
                              <td>
                                {schedule.lecturer && (
                                  <div className="reports-profile-view assigned-title-block-new">
                                    <div className="assigned-title-itm">
                                      <span
                                        className={
                                          'assigned-title-blk  name-icon cat-dark-red' +
                                          ColorRender(
                                            schedule.lecturer.fullname ?? '-'
                                          )
                                        }
                                      >
                                        {schedule.lecturer.picture_me ? (
                                          <img
                                            src={`${IMAGE_URL}/${schedule.lecturer.picture_me.replace(
                                              '/home/myaie/public_html/',
                                              ''
                                            )}`}
                                            alt="AIE"
                                          />
                                        ) : (
                                          InitialRender([
                                            schedule.lecturer.Firstname,
                                            schedule.lecturer.Lastname,
                                          ])
                                        )}
                                        <span
                                          className={`profile-box-2-status ${schedule.lecturer.Status}`}
                                        >
                                          <i className="fas fa-circle"></i>
                                        </span>
                                      </span>
                                      <span className="as-text-blue curser feature-name width-155"
                                    onClick={() =>
                                        history.push(
                                            `/systemAdministration/userManagement/open/${schedule.lecturer.UserID}/aboutYou`
                                        )
                                    }>
                                        &nbsp;{schedule.lecturer.fullname}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          </>
                        ))}
                      {item.bookings.length > 0 &&
                        item.bookings.map((booking, booking_index) => (
                          <>
                            <tr
                              className="class-list-row class-past-row schedule-list-first schedule-list-rest"
                              key={`${index}-${booking_index}`}
                              onClick={(event) =>
                                handleBookingModal(event, booking)
                              }
                            >
                              <td>
                                {moment(
                                  booking.booking_start_time,
                                  'h:mm a'
                                ).format('HH:mm A')}{' '}
                                -{' '}
                                {moment(
                                  booking.booking_end_time,
                                  'h:mm a'
                                ).format('HH:mm A')}
                              </td>
                              <td>
                                <h6 className="schedule-meeting-title">
                                  <StyledTableCell
                                    class_name={booking.booking_reason ?? '-'}
                                    campus_room={booking.room_no ?? '-'}
                                    campus_name={booking.campus ?? '-'}
                                    isBookingAvailable={true}
                                  />
                                </h6>
                              </td>
                              <td>
                                <Link
                                  className="as-text-blue curser textLimit2"
                                  to={`/courseAdministration/coursesdetails/${booking.linked_course}/detailCourses/show`}
                                  title={booking.linked_course}
                                >
                                  {booking.linked_course
                                    ? booking.linked_course
                                    : '-'}
                                </Link>
                              </td>
                              <td style={{ width: '10%' }}>
                                <i
                                  className="fal fa-users mr-2"
                                  title="Student attendance"
                                ></i>
                                {booking.booking_for !== null
                                  ? booking.booking_for.split(',').length
                                  : 0}
                              </td>

                              <td>
                                {booking.users.length > 0 && (
                                  <>
                                   <ShowUsers users={booking.users} onShowAll={(showAll_) => toggleShowAll(showAll_)}/>
                                  </>
                                )}
                              </td>
                            </tr>
                          </>
                        ))}
                    </>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <StyledPagination
        totalrecords={totalRecords_}
        lastPage={lastPage_}
        // rowsPerPage={}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageChange}
      />
      {openModal && (
        <ScheduleListModal
          isOpen={openModal}
          scheduleClasses={classSchedule}
          onClose={() => setOpenModal(false)}
          position={modalPosition}
          // startDate_=''
          // endDate_=''
          // customDay_=""
        />
      )}
    </>
  );
};
