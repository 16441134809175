import React, { useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import { IMAGE_URL, URL_REGEX } from "../../../utils/Constants";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Str from "../../common/Str";
import { Link, useLocation } from "react-router-dom";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import PermissionsGate from "../../../utils/permissionGate";
import { AddBannerSetting, EditBannersetting, getBannerSettingDetail, getBannerSettingDetailCancelToken, GetListOfBannersettingList, GetListOfBannersettingListCancelToken } from "../../../services/BannerService";
import axios from "axios";


let Id = "";
let Action = "";
const BannerForm = ({setBannerName}) => {
  const {id, type} = useParams()
  const history = useHistory();
  const location = useLocation();
  const [tablistData, setTablistData] = useState([]);
  const [linkedBrandList, setLinkedBrandList] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [perviewImgBanner, setPreviewImgBanner] = useState({ image: false });
  const [bannerImg, setBannerImg] = useState();
  const [bannerImgName, setBannerImgName] = useState({ name: "" });
  const [updateData, setUpdateData] = useState(true);

  // getting list of brand name start
  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await GetListOfBannersettingListCancelToken(dropdownSource.token);
        if(res.status == 200){
          let x = {
            value: "",
            label: "",
          };
          x = res.data?.brand_setting_list.map((data) => {
            return {
              ...x,
              value: `${data.brand_name}`,
              label: `${data.brand_name}`,
            };
          });
          setLinkedBrandList(x);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);
  //  end

  // prefield start
  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await getBannerSettingDetailCancelToken({id: id}, dropdownSource.token);
        if(res.status == 200){
          if(res.data.details){
            setTablistData(res.data?.details)
            let tempData = res.data?.details.domain.split(",");
            let newArr = {value:"", label:""};
            newArr = tempData.map((item) => {
              return {
                ...newArr,
                value : item,
                label : item
              }
            })
            // setSelectedDomain(res.data?.details.domain?.split(","));
            formik.setFieldValue('domain', res  && res.data && res.data?.details && res.data?.details.domain ? res.data?.details.domain.split(",") : []);
            setPreviewImgBanner({image : IMAGE_URL + "/" + res.data?.details?.banner_img.replace("home/myaie/public_html/","").replace("public/","")})
            setBannerImgName({ name: basename(res.data?.details?.banner_img.replace("home/myaie/public_html/","").replace("public/","")) })
            if(res.data?.details?.banner_heading){
              setBannerName(res.data?.details?.banner_heading)
            }
          }
          else {
            Swal.fire({
              icon: "Info",
              title: "Info",
              text: "No record found with this id",
            })
  
            history.push("/support/studentBanners/table")
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoaded(false)
        }
      }
    };
    if (type !== "create"){
     fetchData();
    }

    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, [updateData]);
  // prefield end

  const handleFirstValue = (data) => {
    let newArr = {value:"", label:""};
        newArr = data.map((item) => {
          return {
            ...newArr,
            value : item,
            label : item
          }
        })
        return newArr
      }

  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      banner_heading: tablistData?.banner_heading ? tablistData?.banner_heading : "",
      banner_subject: tablistData?.banner_subject ? tablistData?.banner_subject : "",
      domain: tablistData.domain ? handleFirstValue(tablistData.domain.split(",")) : "",
      banner_url: tablistData?.banner_url ? tablistData?.banner_url : "",
      visible: tablistData?.status && tablistData?.status == 1 ? true : false,
      BannerImg: tablistData.banner_img ? tablistData.banner_img.replace("home/myaie/public_html/","") : ""
    },
    validationSchema: Yup.object({
      banner_heading: Yup.string().required("Heading is required"),
      banner_subject: Yup.string().required("Subject is required"),
      domain: Yup.string().required("Brand is required").nullable(),
      banner_url: Yup.string().required("url is required").url("Please Enter valid URL").matches(URL_REGEX, "url is not valid"),
      BannerImg: Yup.string().required("Banner Image Is Required")
    }),
    onSubmit: (values) => {
      let tempdomainArr = values.domain;
      let tempdomainValue = [];
      tempdomainValue = tempdomainArr.map((item) =>{
        return item.value;
      })
      let formData = new FormData();
      formData.set("banner_img", values.BannerImg);
      formData.set("banner_heading", values?.banner_heading);
      formData.set("banner_subject", values?.banner_subject);
      formData.set("banner_url", values?.banner_url);
      formData.set("domain", tempdomainValue.join());
      formData.set("status", values?.visible ? 1 : 0);
      if (type == "create") {
        AddBannerSetting(formData)
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Updated successfully",
            }).then(()=>{
              history.push("/support/studentBanners/table")
            });
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `${err?.response?.data?.data}`,
            });
          });
      } else {
      formData.set("id", id);
      EditBannersetting(formData)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated successfully",
          }).then(()=>{
            history.push("/support/studentBanners/table")
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.data}`,
          });
        });
      }
    },
  });

  const handleSkillsChange = (selectedOption) => {
    formik.setFieldValue('domain', selectedOption);
  };

  const basename = (path) => {
    return path.split("/").reverse()[0];
  };

  return (
    <div className="card card-profile-info-card">
      <div>
        {/* <div className="card-header">Tab Data</div> */}
        <div className="card-body-inr card-body-info">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="form-group" >
                <label>Heading*</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.banner_heading && formik.touched.banner_heading
                        ? " is-invalid"
                        : "")
                    }
                    // name="tab_name"
                    name="banner_heading"
                    placeholder="Heading"
                    title="Heading"
                    value={formik.values.banner_heading}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.banner_heading && formik.touched.banner_heading ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="form-group" >
                <label>Subject*</label>
                <div className="form-icon-group mb-4">
                  <input
                    type="text"
                    className={
                      "form-control" +
                      (formik.errors.banner_subject && formik.touched.banner_subject
                        ? " is-invalid"
                        : "")
                    }
                    name="banner_subject"
                    placeholder="Subject"
                    title="Subject"
                    value={formik.values.banner_subject}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.banner_subject && formik.touched.banner_subject ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
              <div className="form-group" >
                <label>Brand Name*</label>
                <div className="form-icon-group mb-4">
                  <div className="form-control custom-select-box" title="Brand Name">
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (formik.errors.domain && formik.touched.domain
                          ? " is-invalid"
                          : "")
                      }
                      name="domain"
                      value={formik.values.domain}
                      // value={linkedBrandList.filter((obj) =>
                      //   formik.values.domain?.includes(obj.value) ? obj : ""
                      // )}
                      onChange={handleSkillsChange}
                      onBlur={formik.handleBlur}
                      options={linkedBrandList}
                      maxMenuHeight={175}
                      placeholder="Brand Name"
                      isMulti={true}
                    />
                    {formik.errors.domain && formik.touched.domain ? (
                      <span className="exclamation">
                        <i className="fal fa-exclamation-circle"></i>
                      </span>
                    ) : null}
                  </div>
                </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <label htmlFor="DomainURL">URL *</label>
                <div className="form-icon-group mb-4">
                  {/** TODO Valid URL */}
                  <input
                    type="text"
                    className={"form-control" + (formik.errors.banner_url && formik.touched.banner_url ? " is-invalid" : "")}
                    name="banner_url"
                    id="banner_url"
                    placeholder="Banner URL"
                    value={formik.values.banner_url}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="Banner_url"
                  />
                  {formik.errors.banner_url && formik.touched.banner_url ? (
                    <span className="exclamation">
                      <i className="fal fa-exclamation-circle"></i>
                    </span>
                  ) : null}
                </div>
              </div>
               
              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                <label htmlFor="IndexLogo">Status</label>
                  <div className="custom-control custom-checkbox text-left" title="Status">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="visible"
                      name="visible"
                      checked={formik.values.visible}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label className="custom-control-label" htmlFor="visible">
                      Visible
                    </label>
                  </div>
                </div>
              </div>
            
              <div className="col-md-6 col-lg-4 imagestyle">
                <label htmlFor="IndexLogo">Banner Image *</label>
                <div className="form-group atttach-file">
                  <label
                  className={
                    formik.errors.BannerImg &&
                    formik.touched.BannerImg
                      ? "file-req is-invalid"
                      : ""
                  }>
                    <i className="fal fa-paperclip"></i>
                    <span>Add Attachment</span>
                    <input
                     title={bannerImgName.name ? bannerImgName.name : "No File Uploaded"}
                      type="file"
                      accept="image/*"
                      id="input"
                      className={"form-control  form-control-aatch-file"}
                      // accept={SUPPORTED_FORMATS}
                      name="file[]"
                      onChange={(e) => {
                          let reader = new FileReader();
                          reader.onload = (e) => {
                            setPreviewImgBanner({ image: e.target.result });
                          };
                          reader.readAsDataURL(e.target.files[0]);
                          formik.setFieldValue("BannerImg", e.target.files[0]);
                          setBannerImg(e.target.files[0]);
                          setBannerImgName({ name: e.target.files[0].name });
                   
                      }}
                    />
                  </label>
                </div>
                {bannerImgName.name && (
                <>
                <div className="form-icon-group mt-2" title="Banner">
                  <figure className="upload-img-size" title="Banner">
                    <img className="" src={perviewImgBanner?.image} alt="Banner Brake Image" />
                  </figure>
                </div>
                <div className="frm-group">
                  <ul className="list-unstyled attached-file-ul m-0">
                    <li>
                      <p>
                        <i className="fal fa-file"></i>
                        &nbsp;{bannerImgName.name}
                      </p>
                    </li>
                  </ul>
                </div>
                </>
                )}
              </div>
            </div>
            <div className="form-group-save-cancel">
                <PermissionsGate errorProps={{ disabled: true }} scopes={["shedit"]}>
                  <button
                    className="btn btn-save btn-success"
                    type="submit"
                    title="Save"
                    disabled={!formik.dirty}
                  >
                    <i className="fal fa-save"></i>
                    Save
                  </button>
                  </PermissionsGate>
                  <button
                    className="btn btn-close btn-danger"
                    type="button"
                    title="Cancel"
                    onClick={() => history.goBack()}
                  >
                    <i className="fal fa-times"></i>
                    Cancel
                  </button>
                </div>
            <div className="form-group form-group-save-cancel">
            {Object.keys(formik.values).map(key => {
                if (formik.touched[key] && formik.errors[key]) {
                  return (
                    <div className="invalid-feedback d-block">
                      {formik.errors[key]}
                    </div>
                  )
                }
              })}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BannerForm;
