import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { GetExamPeriodAuditFilters, GetExamPeriodAuditTrails } from "../../../../services/ExternalExamServices";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import AuditTrailsTable from "../../../common/AuditTrailsTable";

function ExamPeriodAuditTrail() {
  const { subTab } = useParams();
  const history = useHistory();  
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const columnsToShow = ["Action Type", "Resource Type", "Resource Name"];

  useEffect(() => {
    let response = hasPermission({ scopes: ["eeepview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess")
    }
  }, []);

  return (
    <AuditTrailsTable 
      apiFunction={GetExamPeriodAuditTrails}
      filterArrApi={GetExamPeriodAuditFilters}
      columnsToShow={columnsToShow}
      exportFileName={"Exam_Period_Audit_Trail"}
      isResourceNameShown={true}
      apiParams={{action_id: subTab}}
      actionId={subTab}
      isShowingResourceTypeFilter
    />
  );
}

export default ExamPeriodAuditTrail;
